import { IOperationCropV2 } from '../types/IOperationCrops';

export const sortNumbersAndNulls = function (a: number | null, b: number | null) {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

export const sortOperationCrops = (
  operationCrops: IOperationCropV2[],
  sortByMarketingFirst: boolean,
  productionCycle?: string
) => {
  let crops = operationCrops;
  if (productionCycle) {
    crops = crops.filter((oc) => oc.productionCycle === productionCycle);
  }

  const sortMarketingAndHedging = (a: IOperationCropV2, b: IOperationCropV2) => {
    if (sortByMarketingFirst) {
      return (
        sortNumbersAndNulls(a.marketingCropType, b.marketingCropType) || sortNumbersAndNulls(a.hedgingCropType, b.hedgingCropType)
      );
    }
    return (
      sortNumbersAndNulls(a.hedgingCropType, b.hedgingCropType) || sortNumbersAndNulls(a.marketingCropType, b.marketingCropType)
    );
  };

  return crops.sort((a, b) => {
    const aAcres = a.acres ? a.acres.toNumber() : 0;
    const bAcres = b.acres ? b.acres.toNumber() : 0;
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    return (
      Number(a.hidden) - Number(b.hidden) ||
      sortNumbersAndNulls(bAcres, aAcres) ||
      sortMarketingAndHedging(a, b) ||
      (aName < bName ? -1 : aName > bName ? 1 : 0)
    );
  });
};
