import React from 'react';
import './Button.css';

function Button(args: ButtonType) {
  return <input type="button" {...args} />;
}

type ButtonType = {
  className?: string;
  value?: string;
  onClick?: (data: any) => void;
  disabled?: boolean;
  hidden?: boolean;
  type?: string;
  title?: string;
};

export default Button;
