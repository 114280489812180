import axios from 'axios';
import config from '../../config';
import { IRiskScenario } from '../../types/IRiskScenario';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRiskScenarios = createAsyncThunk('scenarios/fetchRiskScenarios', async (operationId: number) => {
  const resp = await axios.get(`${config.backend.basePath}/risk_scenario/operation/${operationId}`);
  return resp.data;
});

export const createRiskScenario = createAsyncThunk('scenarios/createRiskScenario', async (scenario: IRiskScenario) => {
  const resp = await axios.post(`${config.backend.basePath}/risk_scenario`, scenario);
  return resp.data;
});

export const updateRiskScenario = createAsyncThunk('scenarios/updateRiskScenario', async (scenario: IRiskScenario) => {
  const resp = await axios.put(`${config.backend.basePath}/risk_scenario`, scenario);
  return resp.data;
});
