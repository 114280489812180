import { useContext } from 'react';
import { AppContext } from '../shared/AppContext';

export default function useAppContext(): AppContext {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('AppContext is not set');
  }

  return context;
}
