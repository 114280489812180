import React from 'react';
import { useQuery } from '@tanstack/react-query';
import config from '../config';
import apiClient from '../shared/apiClient';
import { QueryKey } from './QueryKey';
import { IAdvisor } from '../types/IAdvisor';
import { isNil } from 'lodash';

const fetch = async (advisorId: string, code: string): Promise<IAdvisor> => {
  const response = await apiClient.get<IAdvisor>(`${config.backend.basePath}/advisors/verification`, {
    params: {
      id: advisorId,
      verificationCode: code,
    },
  });
  return response.data;
};

export const useAdvisorVerification = (advisorId: string | undefined, code: string | undefined) => {
  const enabled = !isNil(advisorId) && !isNil(code);
  if (!enabled) return;
  const query = useQuery([QueryKey.AdvisorVerification], () => fetch(advisorId, code), {
    select: React.useCallback((data: IAdvisor) => {
      return data;
    }, []),
    enabled,
  });
  return query;
};
