import { useQuery } from '@tanstack/react-query';
import config from '../config';
import { isValidSymbol } from '../models/CropSymbols/cropSymbols';
import apiClient from '../shared/apiClient';
import { prepareQuotes, QuotesStoreItem } from '../store/Quotes/quotesSlice';
import { IQuote } from '../types/IQuote';
import { QueryKey } from './QueryKey';

const fetchQuotes = async (barchartSymbols: string[] | null): Promise<QuotesStoreItem> => {
  if (!barchartSymbols || !barchartSymbols.length) {
    return {};
  }

  // TODO: see if we can go all the way and validate the actual symbol...
  const validSymbols = barchartSymbols.filter((x) => isValidSymbol(x));

  if (!validSymbols.length) {
    return {};
  }

  const response = await apiClient.get<IQuote[]>(`${config.backend.basePath}/market_data/quotes`, {
    params: {
      symbols: validSymbols.join(','),
      fields: 'previousClose',
    },
  });

  // ensure that all symbols requested have a result, or else we can get a runaway request loop
  const result = validSymbols.map((s) => response.data.find((x) => x.symbol === s) || ({ symbol: s } as IQuote));

  return prepareQuotes(result);
};

export function useQuotes(barchartSymbols: string[] | null) {
  const query = useQuery([QueryKey.Quotes, barchartSymbols], () => fetchQuotes(barchartSymbols), {});
  return query;
}
