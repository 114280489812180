import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

export const fetchSymbolLookUps = createAsyncThunk('symbolLookUps/fetchSymbolLookUps', async (tradingCodes: string[]) => {
  tradingCodes = tradingCodes.filter((code) => !!code);

  const response = await Promise.all(
    tradingCodes.map((code) =>
      axios.get(`${config.backend.basePath}/market_data/get_symbol_look_up`, {
        params: {
          keyword: code,
          exchanges: 'CBOT,MGEX,KCBT',
        },
      })
    )
  );
  return response.map((response) => response.data);
});
