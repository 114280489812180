import React from 'react';
import { faArrowDown, faArrowRight, FontAwesomeIcon, IconDefinition } from '../../../../components/faIcons';

export type MatrixItemCell = {
  value: string;
  class: string;
  title: string;
};

type MatrixProps = {
  data: MatrixItemCell[][];
};

export function Matrix(props: MatrixProps) {
  const { data } = props;

  return (
    <table className="live-matrix-table">
      <tbody className="live-matrix-tbody">
        <tr className="live-matrix-heading">
          <td>
            Est. Yield <FontAwesomeIcon icon={faArrowDown as IconDefinition} />
          </td>
          <td colSpan={data[0].length}>
            Est. Fall Price <FontAwesomeIcon icon={faArrowRight as IconDefinition} />
          </td>
        </tr>
        {data.map((row: MatrixItemCell[], index) => (
          <tr key={index}>
            {row.map((item, index) => (
              <td key={index} className={item.class} title={item.title}>
                {item.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
