import { FuturesContractType, PositionType, StrategyStatus, IPosition, OptionContractType } from '../../types/IPosition';
import { IStrategy } from '../../types/IStrategy';
import { CommodityInformation } from '@harvestiq/constants';
import { isEmpty, sum } from 'lodash';
import { PriceByTradingCode } from './myPositions';
import { IOperationCropV2 } from '../../types/IOperationCrops';
import { getTotalProtectedBushels, isProtected } from '../protectedPosition';
import {
  getFuturesGainLossPerBushelByContractType,
  getGainLossPerBushelByTypeAndProductionCycle,
  getOptionGainLossPerBushelByContractType,
} from '../gainLoss';
import { calculateWeightedAverageProtectedFloor } from '../protectedFloor';
import { getGainLoss } from './strategyDetails';

interface IContractsForSummaryType {
  protected: IStrategy[];
  speculation: IStrategy[];
}

export function groupContractsForSummaryByStatus(
  strategies: IStrategy[],
  statuses: StrategyStatus[] = []
): IContractsForSummaryType {
  const result: IContractsForSummaryType = {
    protected: [],
    speculation: [],
  };

  strategies.forEach((strategy) => {
    let targetArray;
    if (isProtected(strategy.legs)) {
      targetArray = result.protected;
    } else {
      targetArray = result.speculation;
    }

    const contractWithFilteredLegs = filterLegsInContractByStatus(strategy, statuses);
    if (contractWithFilteredLegs.legs.length) {
      targetArray.push(contractWithFilteredLegs);
    }
  });

  return result;
}

function filterLegsInContractByStatus(contract: IStrategy, statuses: StrategyStatus[] = []): IStrategy {
  return {
    ...contract,
    legs: contract.legs.filter((leg) => leg.status && statuses.includes(leg.status)),
  };
}

export function getTotalGainLossByStatus(leg: IPosition, spotPrice: number): number {
  let result = 0;

  switch (leg.status) {
    case StrategyStatus.Open:
      result = getGainLoss(leg);
      break;
    case StrategyStatus.Expired:
      result = getExpiredLegTotalGainLoss(leg);
      break;
    case StrategyStatus.Closed:
      result = getClosedLegTotalGainLoss(leg, spotPrice);
      break;
  }

  return result;
}

export function getExpiredLegTotalGainLoss(leg: IPosition): number {
  const quantitySold = Number(leg.quantitySold) || 0;
  let result = 0;

  if (leg.status !== StrategyStatus.Expired) {
    return result;
  }

  switch (leg.type) {
    case PositionType.Option:
      result =
        Number(leg.premiumCost) *
        (leg.contractType === OptionContractType.SellCall || leg.contractType === OptionContractType.SellPut ? 1 : -1);
      break;
    case PositionType.Futures:
      result = 0;
      break;
  }

  result *= quantitySold;

  return result;
}

export function getClosedLegTotalGainLoss(leg: IPosition, spotPrice: number): number {
  const quantitySold = Number(leg.quantitySold || 0);
  let result = 0;

  if (leg.status !== StrategyStatus.Closed) {
    return result;
  }

  switch (leg.type) {
    case PositionType.Option:
      result = getOptionGainLossPerBushelByContractType(leg, spotPrice);
      break;
    case PositionType.Futures:
      result = getFuturesGainLossPerBushelByContractType(leg, spotPrice);
      break;
  }

  result *= quantitySold;

  return result;
}

export function calculateAverageContractGainLoss(
  strategies: IStrategy[],
  crop: CommodityInformation,
  spotPrices: PriceByTradingCode,
  additionalContracts: IStrategy[] = []
) {
  return (
    [...strategies, ...additionalContracts]
      .flatMap((contract) =>
        contract.legs.map((leg) => ({
          ...leg,
          productionCycle: contract.productionCycle,
        }))
      )
      .map((leg) => Number(leg.quantitySold || 0) * getGainLossPerBushelByTypeAndProductionCycle(leg, crop, spotPrices))
      .reduce((a, b) => a + b, 0) / getTotalBushelsContracted(strategies)
  );
}

interface IStrategySummaryRow {
  totalBushels: number;
  netGainLoss: number;
  estimatedValue: number;
}

export interface IStrategySummaryData {
  marketedForRiskAssessment: IStrategySummaryRow;
  protectedForRiskAssessment: IStrategySummaryRow;
  protected: IStrategySummaryRow;
  speculation: IStrategySummaryRow;
  total: IStrategySummaryRow;
}

export function calculateTotalWeightedAverageProtectedPrice(contracts: IStrategy[], estimatedBasis: number) {
  const weightedAvgFloor = calculateWeightedAverageProtectedFloor(contracts);
  return weightedAvgFloor + estimatedBasis;

  // return (
  //   sum(
  //     contracts.map(
  //       (c) =>
  //         calculateContractAverageProtectedPrice(c, estimatedBasis) *
  //         getTotalBushels(c.legs)
  //     )
  //   ) / getTotalBushelsContracted(contracts, [StrategyStatus.Open])
  // );
}

export function calculateClosedWeightedAverageGainLoss(contracts: IStrategy[]) {
  const totalGainLoss = sum(contracts.flatMap((c) => c.legs).map((leg) => leg.gainLoss || 0));

  const totalBushels = getTotalBushelsContracted(contracts, [StrategyStatus.Closed, StrategyStatus.Expired]);

  return totalBushels ? totalGainLoss / totalBushels : 0;
}

export function isLongLeg(leg: IPosition) {
  return (
    (leg.type === PositionType.Option &&
      (leg.contractType === OptionContractType.BuyPut || leg.contractType === OptionContractType.BuyCall)) ||
    (leg.type === PositionType.Futures && leg.contractType === FuturesContractType.Buy)
  );
}

/**
 * Gets the logical total bushels across an array of legs (assuming they all belong to a single strategy/contract)
 * @param legs
 * @param statuses
 * @returns
 */
export function getTotalBushels(legs: IPosition[], statuses: StrategyStatus[] = []) {
  const filteredLegs = legs.filter((l) => !statuses.length || (l.status && statuses.includes(l.status)));

  if (!filteredLegs.length) {
    return 0;
  }

  const protectedBushels = getTotalProtectedBushels(filteredLegs);

  // there were some protected legs in the position
  if (protectedBushels > 0) {
    return protectedBushels;
  }

  // if its purely speculative, then give the max of the long legs
  const longLegs = filteredLegs.filter(isLongLeg);
  const totalBushels = (longLegs.length ? longLegs : filteredLegs).map((l) => l.quantitySold || 0);
  return totalBushels.length ? Math.max(...totalBushels) : 0;
}

export function getTotalBushelsContracted(contracts: IStrategy[], statuses: StrategyStatus[] = []) {
  const mergedContracts = contracts.filter((x) => x.isMerged);
  const unmergedContracts = contracts.filter((x) => !x.isMerged);

  const mergedTotal = sum(mergedContracts.flatMap((c) => c.legs.map((l) => l.quantitySold || 0)));
  const unmergedTotal = sum(unmergedContracts.map((c) => getTotalBushels(c.legs, statuses)));

  // return _.sum(contracts.map((c) => c.isMerged
  //   ? _.sum(c.legs.flatMap(l => l.quantitySold || 0))
  //   : getTotalBushels(c.legs, statuses)));

  return sum([mergedTotal, unmergedTotal]);
}

export function makeDefaultSummaryRow(): IStrategySummaryRow {
  return {
    totalBushels: 0,
    netGainLoss: 0,
    estimatedValue: 0,
  };
}

export function calculateStrategySummaryMarketedRow(contracts: IStrategy[]): IStrategySummaryRow {
  const summaryRow = makeDefaultSummaryRow();

  if (!contracts.length) {
    return summaryRow;
  }

  summaryRow.totalBushels = getTotalBushelsContracted(contracts, [StrategyStatus.Closed, StrategyStatus.Expired]);
  summaryRow.netGainLoss = calculateClosedWeightedAverageGainLoss(contracts) || 0;
  summaryRow.estimatedValue = summaryRow.totalBushels * summaryRow.netGainLoss;

  return summaryRow;
}

export function calculateStrategySummaryProtectedRow(
  contracts: IStrategy[],
  operationCrop: IOperationCropV2
): IStrategySummaryRow {
  const summaryRow = makeDefaultSummaryRow();

  if (!contracts.length) {
    return summaryRow;
  }

  const basis = Number(operationCrop.price?.estimatedBasis || 0);
  const openContracts = groupContractsForSummaryByStatus(contracts, [StrategyStatus.Open]);
  const applicableContracts = openContracts.protected.filter((contract) => isProtected(contract.legs));
  summaryRow.totalBushels = getTotalBushelsContracted(applicableContracts, [StrategyStatus.Open]);
  summaryRow.netGainLoss = calculateTotalWeightedAverageProtectedPrice(applicableContracts, basis);

  summaryRow.estimatedValue = summaryRow.totalBushels * summaryRow.netGainLoss;

  return summaryRow;
}

export function calculateStrategySummaryRow(
  strategies: IStrategy[],
  spotPrices: PriceByTradingCode,
  crop: CommodityInformation,
  additionalContracts: IStrategy[] = []
) {
  const summaryRow = makeDefaultSummaryRow();

  if (!strategies.length) {
    return summaryRow;
  }

  summaryRow.totalBushels = getTotalBushelsContracted(strategies);
  summaryRow.netGainLoss = calculateAverageContractGainLoss(strategies, crop, spotPrices, additionalContracts);
  summaryRow.estimatedValue = summaryRow.netGainLoss * summaryRow.totalBushels;

  return summaryRow;
}

export function calculateStrategySummaryData(
  strategies: IStrategy[], // TODO: FR-400, eventually take in the IStrategyWithQuoteData
  spotPrices: PriceByTradingCode,
  crop: CommodityInformation,
  operationCrop: IOperationCropV2
): IStrategySummaryData {
  const initialRow = makeDefaultSummaryRow();
  const result: IStrategySummaryData = {
    marketedForRiskAssessment: { ...initialRow },
    protectedForRiskAssessment: { ...initialRow },
    protected: { ...initialRow },
    speculation: { ...initialRow },
    total: { ...initialRow },
  };

  const filteredStrategies = strategies.filter((strategy) => strategy.cropId === operationCrop.hedgingCropType);

  if (!filteredStrategies.length || isEmpty(spotPrices)) {
    return result;
  }

  const openContracts = groupContractsForSummaryByStatus(filteredStrategies, [StrategyStatus.Open]);
  const closedContracts = groupContractsForSummaryByStatus(filteredStrategies, [StrategyStatus.Closed, StrategyStatus.Expired]);
  result.marketedForRiskAssessment = calculateStrategySummaryMarketedRow(filteredStrategies);
  result.protectedForRiskAssessment = calculateStrategySummaryProtectedRow(filteredStrategies, operationCrop);

  result.protected = calculateStrategySummaryRow(openContracts.protected, spotPrices, crop, closedContracts.protected);
  result.speculation = calculateStrategySummaryRow(openContracts.speculation, spotPrices, crop, closedContracts.speculation);

  const additionalContractsForTotal = [
    ...(openContracts.protected.length ? closedContracts.protected : []),
    ...(openContracts.speculation.length ? closedContracts.speculation : []),
  ];
  result.total = calculateStrategySummaryRow(
    [...openContracts.protected, ...openContracts.speculation],
    spotPrices,
    crop,
    additionalContractsForTotal
  );

  return result;
}
