import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../types/Store';
import { LoadingStatuses } from '../LoadingStatuses';
import { IBudget } from '../../types/IBudget';
import ErrorToast from '../../components/Toast/ErrorToast';
import { createBudget, createOldBudget, deleteBudget, fetchBudgets, fetchDefaultUniversityBudgets, updateBudget } from './thunks';
import SuccessToast from '../../components/Toast/SuccessToast';
import { mapValues } from 'lodash';

type BudgetsInitialStateType = {
  budgets: IBudget[];
  status: string;
  error: string | null;
};

const initialState: BudgetsInitialStateType = {
  budgets: [],
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'budgets',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBudgets.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchBudgets.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.budgets = action.payload;
    },
    [fetchBudgets.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load budgets!');
    },
    [fetchDefaultUniversityBudgets.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchDefaultUniversityBudgets.fulfilled.toString()]: (state, action) => {
      state.status = action.payload.length ? LoadingStatuses.Idle : LoadingStatuses.Failed;
      state.budgets = [...state.budgets, ...prepareUniversityBudgets(action.payload)];
    },
    [fetchDefaultUniversityBudgets.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load default university budgets!');
    },
    [createBudget.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [createBudget.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.budgets = [...state.budgets, action.payload];
      SuccessToast('Budget is saved');
    },
    [createBudget.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to create budget!');
    },
    [createOldBudget.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [createOldBudget.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.budgets = [...state.budgets, action.payload];
      SuccessToast('Budget is saved');
    },
    [createOldBudget.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to create budget!');
    },
    [updateBudget.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [updateBudget.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.budgets = updateStrategy(state.budgets, action.payload);
      SuccessToast('Budget is updated');
    },
    [updateBudget.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to update budget!');
    },
    [deleteBudget.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [deleteBudget.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.budgets = updateStrategy(state.budgets, action.payload, true);
    },
    [deleteBudget.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to deactivate budget!');
    },
  },
});

function prepareUniversityBudgets(budgets: IBudget[]): IBudget[] {
  const reorder = (value: any) => value.map((item: any, index: any) => ({ orderId: index, ...item }));

  return budgets.map((budget) => ({
    ...budget,
    cropBudgets: mapValues(budget.cropBudgets, reorder),
  }));
}

function updateStrategy(state: IBudget[], updatedBudget: IBudget, isDelete = false) {
  const newState = [...state];
  const previousBudget = newState.find((budget) => budget.id === updatedBudget.id);

  if (previousBudget) {
    if (isDelete) {
      // remove deleted budget
      newState.splice(newState.indexOf(previousBudget), 1);
    } else {
      // update budget
      newState[newState.indexOf(previousBudget)] = { ...previousBudget, ...updatedBudget };
    }
  } else {
    // create new budget
    newState.push(updatedBudget);
  }
  return newState;
}

export default slice.reducer;

export const selectBudgets = (state: RootState) => state.budgets.budgets;
