import { AppThunk } from '../../types/Store';
import axios from 'axios';
import config from '../../config';
import { addNewStrategy, changeLoadingStatus, deleteStrategy, editStrategy, setStrategies } from './strategiesSlice';
import ErrorToast from '../../components/Toast/ErrorToast';
import { IStrategy } from '../../types/IStrategy';
import { LoadingStatuses } from '../LoadingStatuses';

export const getStrategiesThunk = (operationId: number, callback?: () => void): AppThunk => {
  return (dispatch) => {
    dispatch(changeLoadingStatus(LoadingStatuses.Loading));
    axios
      .get(`${config.backend.basePath}/strategies/operation/${operationId}`)
      .then((response) => {
        // these properties are being added to the API response
        const data = response.data.map((i: any) => ({
          ...i,
          tradeDate: i.booked,
          originalPrice: i.premiumCost || i.futuresPrice,
        }));
        dispatch(setStrategies(data));
        dispatch(changeLoadingStatus(LoadingStatuses.Idle));
        callback && callback();
      })
      .catch((error) => {
        dispatch(changeLoadingStatus(LoadingStatuses.Failed));
        console.error('Failed to load strategies', error);
        ErrorToast('Failed to load strategy');
      });
  };
};

export const createStrategiesThunk = (strategy: IStrategy, callback: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .post<IStrategy>(`${config.backend.basePath}/strategies`, strategy)
      .then((response) => {
        dispatch(addNewStrategy(response.data));
        callback();
      })
      .catch((error) => {
        console.error('Failed to create strategy', error);
        ErrorToast('Failed to create strategy');
      });
  };
};

export const updateStrategiesThunk = (strategy: IStrategy, callback: () => void, operationId?: number): AppThunk => {
  return (dispatch) => {
    axios
      .patch<IStrategy>(`${config.backend.basePath}/strategies`, strategy)
      .then((response) => {
        dispatch(editStrategy(response.data));
        operationId && dispatch(getStrategiesThunk(operationId));
        callback();
      })
      .catch((error) => {
        console.error('Failed to update strategy', error);
        ErrorToast('Failed to update strategy');
      });
  };
};

export const deleteStrategiesThunk = (data: IStrategy, callback?: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .delete(`${config.backend.basePath}/strategies`, { data })
      .then(() => {
        dispatch(deleteStrategy(data));
        callback && callback();
      })
      .catch((error) => {
        console.error('Failed to delete strategy', error);
        ErrorToast('Failed to delete strategy');
      });
  };
};
