import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export function nowUtc(): Date {
  return nowUtcDayjs().toDate();
}

// Mock this to mock all "now" datetimes
export function nowUtcDayjs(): Dayjs {
  return dayjs.utc();
}

export function nowCdt(): Date {
  return nowCdtDayjs().toDate();
}

export function nowCdtDayjs(): Dayjs {
  return nowUtcDayjs().tz('America/Chicago');
}

export { Dayjs };
export default dayjs;
