import React from 'react';
import { formatNumber } from '../../../utils/formatNumber';

import { ArcGauge, ArcGaugeProps } from '@progress/kendo-react-gauges';
import styles from './ArcGaugeWithTitle.module.scss';

export enum ArcGaugeColors {
  BLUE = '#0058e9',
  GREEN = '#37b400',
  ORANGE = '#ffc000',
  RED = '#f31700',
}

const colors = [
  {
    to: 25,
    color: ArcGaugeColors.BLUE,
  },
  {
    from: 25,
    to: 50,
    color: ArcGaugeColors.GREEN,
  },
  {
    from: 50,
    to: 75,
    color: ArcGaugeColors.ORANGE,
  },
  {
    from: 75,
    color: ArcGaugeColors.RED,
  },
];

type ArcGaugeWithLabelsProps = {
  title: string;
  centerTitle: string;
  bottomTitle?: string;
  kpi?: { label: string; value: string; color: string };
  value: number;
  color?: string;
};

const ArcGaugeWithTitle = ({ title, centerTitle, bottomTitle, kpi, value, color }: ArcGaugeWithLabelsProps) => {
  const arcOptions: ArcGaugeProps = {
    value,
    color,
    ...(!color && { colors: colors }),
    centerRender: (value, color) => {
      return (
        <>
          <h6 className={`${styles['center-value']}`} style={{ color: color }}>
            {formatNumber(value, 2)}%
          </h6>
        </>
      );
    },
  };

  return (
    <div className={`${styles['arcgauge-container']}`}>
      <h3 className={`${styles['top-title']}`}>{title}</h3>
      <ArcGauge {...arcOptions} />
      <div className={`${styles['center-title']} fs-5`}>{centerTitle}</div>
      <div className={`${styles['bottom-title']}`}>{bottomTitle}</div>
      {kpi && (
        <div className={`${styles['kpi']}`}>
          {kpi.label}: <span style={{ color: color }}>{kpi.value}</span>
        </div>
      )}
    </div>
  );
};

export default ArcGaugeWithTitle;
