import { isEmpty, uniq } from 'lodash';
import React from 'react';
import Tooltip from '../../components/common-next/Tooltip/Tooltip';
import Loader from '../../components/common/Loader/Loader';
import CropsWarningRow from '../../components/common/Warnings/CropsWarningRow';
import { TradeButtonGroup } from './components/TradeButtonGroup';
import ErrorToast from '../../components/Toast/ErrorToast';
import useCurrentProductionCycle from '../../customHooks/useCurrentProductionCycle';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { getFuturesTradingCode } from '../../models/CropSymbols/cropSymbols';
import { getCropFutures } from '../../models/getMarketData';
import { useCurrentHedgingCrops, useCurrentMarketingCrops, useCurrentOperationCrops } from '../../queries/operationCrops';
import { useOperationCropSummaries } from '../../queries/operationCropSummaries';
import { useOperationSummary } from '../../queries/operationSummary';
import { useQuotes } from '../../queries/useQuotes';
import { useSymbolLookUps } from '../../queries/useSymbolLookUps';
import { getCommodities, CommodityInformation, CommodityGroup } from '@harvestiq/constants';
import HistoryChart from './components/HistoryChart';
import { OperationCropSummaryCard } from './components/OperationCropSummaryCard/OperationCropSummaryCard';
import OperationSummaryCard from './components/OperationSummaryCard';
import ProfitMatrix from './components/ProfitMatrix/ProfitMatrix';
import { useCropContractSummaries } from '../../queries/cropContractSummary';
import { useCropPrices } from '../../queries/marketData';
import styles from './Dashboard.module.scss';

import './Dashboard.css';
import CropsFuturesPrices from './components/CropsFuturesPrices';

export default function DashboardCropsPage() {
  const crops = getCommodities();
  const currentYear = useCurrentProductionCycle();
  const previousYear = (Number(currentYear) - 1).toString();
  const nextYear = (Number(currentYear) + 1).toString();

  const operationCropsQuery = useCurrentOperationCrops();
  const operationCrops = operationCropsQuery.data || [];

  const hedgingCropsQuery = useCurrentHedgingCrops();
  const hedgingCrops = hedgingCropsQuery.data || [];

  const marketingCropsQuery = useCurrentMarketingCrops();

  const operationSummaryQuery = useOperationSummary({ productionCycle: currentYear });

  const operationCropSummariesQuery = useOperationCropSummaries({});
  const operationCropSummaries = operationCropSummariesQuery.data || [];
  if (operationCropSummariesQuery.isError) {
    ErrorToast('Failed to load crop summaries!');
  }

  const cropContractsSummariesQuery = useCropContractSummaries({});
  const cropContractsSummaries = cropContractsSummariesQuery.data || [];
  if (cropContractsSummariesQuery.isError) {
    ErrorToast('Failed to load crop summaries!');
  }

  const symbolLookUpsQuery = useSymbolLookUps();
  const symbolLookUps = symbolLookUpsQuery.data || {};
  if (symbolLookUpsQuery.isError) {
    ErrorToast('Failed to load symbol look ups!');
  }

  const cropFutures = getCropFutures(hedgingCrops, symbolLookUps);
  const previousYearTradingCodes = hedgingCrops.map((crop: CommodityInformation) =>
    getFuturesTradingCode(previousYear, crop, cropFutures)
  );
  const currentYearTradingCodes = hedgingCrops.map((crop: CommodityInformation) =>
    getFuturesTradingCode(currentYear, crop, cropFutures)
  );
  const nextYearTradingCodes = hedgingCrops.map((crop: CommodityInformation) =>
    getFuturesTradingCode(nextYear, crop, cropFutures)
  );
  const allTradingCodes = uniq(
    [...previousYearTradingCodes, ...currentYearTradingCodes, ...nextYearTradingCodes].filter(
      (tradingCode: string) => !!tradingCode
    )
  );

  const quotesQuery = useQuotes(allTradingCodes);
  if (quotesQuery.isError) {
    ErrorToast('Failed to load futures prices!');
  }

  const cropPrices = useCropPrices({ cropTypes: hedgingCrops.map((c) => c.id) });

  const cropsForDisplaying = isEmpty(hedgingCrops) ? [crops[0]] : hedgingCrops;
  const isTablet = useMobileView(BREAKPOINTS.TABLET_VIEW);
  const isSmallLaptop = useMobileView(BREAKPOINTS.SMALL_LAPTOP_VIEW);
  const displaySize = isTablet ? '-mobile' : isSmallLaptop ? '-small-laptop' : '';

  if (
    operationCropsQuery.isLoading ||
    operationCropSummariesQuery.isLoading ||
    cropPrices.isLoading ||
    symbolLookUpsQuery.isLoading ||
    operationSummaryQuery.isLoading ||
    quotesQuery.isLoading ||
    hedgingCropsQuery.isLoading ||
    marketingCropsQuery.isLoading
  ) {
    return (
      <div className={`dashboard-root${displaySize}`}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(operationCrops) && operationCropsQuery.isSuccess) {
    return (
      <div className="warning-wrapper">
        <CropsWarningRow selectedYear={currentYear} />
      </div>
    );
  }

  return (
    <Tooltip
      anchorElement="target"
      position="auto"
      showCallout={false}
      parentTitle={true}
      content={(props: any) => <TooltipTemplate title={props.title} />}
    >
      <>
        <div className={`${styles['dashboard']} d-flex pt-3 gap-3`}>
          {isTablet && <TradeButtonGroup wrap={true} />}
          <OperationSummaryCard operationSummaryQuery={operationSummaryQuery} type={CommodityGroup.CROPS} />
          <CropsFuturesPrices cropPricesQuery={cropPrices} commodityInformation={hedgingCrops} />
        </div>

        <div className={`dashboard-root${displaySize} no-sidebar`}>
          {operationCrops.map((opCrop) => {
            const summaryData = operationCropSummaries?.find((s) => s.operationCropId === opCrop.id);
            const ccData = cropContractsSummaries.find((cc) => cc.operationCropId === opCrop.id);
            return (
              <OperationCropSummaryCard
                key={opCrop.id}
                operationCrop={opCrop}
                operationCropSummary={summaryData}
                cropContractSummary={ccData}
              />
            );
          })}
          {!!operationCrops.length && !isTablet && (
            <ProfitMatrix
              operationCropsQuery={operationCropsQuery}
              queryCropSummaries={operationCropSummariesQuery}
              marketingCropsQuery={marketingCropsQuery}
              quotesQuery={quotesQuery}
              symbolLookUpsQuery={symbolLookUpsQuery}
            />
          )}
          {!isTablet && (
            <HistoryChart
              crops={cropsForDisplaying}
              cropFuturesExpirations={getCropFutures(cropsForDisplaying, symbolLookUps)}
              hedgingCrops={hedgingCrops}
              quotesQuery={quotesQuery}
            />
          )}
        </div>
      </>
    </Tooltip>
  );
}

const TooltipTemplate = (props: any) => {
  let title = props.title;
  // check if it's a matrix cell
  if (
    title.includes('Unsold Revenue') &&
    title.includes('Sold Revenue') &&
    title.includes('Hedging') &&
    title.includes('Insurance Indemnity')
  ) {
    const soldRevenue = title.slice(0, props.title.search('Unsold'));
    title = title.replace(soldRevenue, '');
    const unsoldRevenue = title.slice(0, title.search('Hedging'));
    title = title.replace(unsoldRevenue, '');
    const hedgingGL = title.slice(0, title.search('Insurance'));
    title = title.replace(hedgingGL, '');
    const insuranceIndemnity = title;
    return (
      <div>
        {soldRevenue}
        <br />
        {unsoldRevenue}
        <br />
        {hedgingGL}
        <br />
        {insuranceIndemnity}
      </div>
    );
  } else {
    return <div>{title}</div>;
  }
};
