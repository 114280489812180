import React from 'react';
import Button from '../../../components/common/Button/Button';
import { useHistory } from 'react-router-dom';
import useCurrentRole from '../../../customHooks/useCurrentRole';
import config from '../../../config';
import { isEmpty } from 'lodash';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import { useAllHedgingCrops } from '../../../queries/operationCrops';
import { useTradingAccountConfigs } from '../../../queries/tradingAccountConfigs';
import useIsFeatureAccessible from '../../../customHooks/useIsFeatureAccessible';
interface TradeButtonGroupProps {
  wrap?: boolean;
}

export function TradeButtonGroup(props: TradeButtonGroupProps) {
  const history = useHistory();
  const currentOperation = useCurrentOperation();
  const currentRole = useCurrentRole();
  const tradingAccountConfigsQry = useTradingAccountConfigs();
  const availableCrops = useAllHedgingCrops().data ?? [];
  const isDevEnv = config.env === 'development';

  const tradingAccountConfigs = tradingAccountConfigsQry.data || [];

  const onClick = (path: string) => {
    history.push(path);
  };
  const hasAtLeastOneCrop = !isEmpty(availableCrops);

  const showTradeButtons =
    ((tradingAccountConfigsQry.data && currentOperation.tradingEnabled) ?? true) && (currentRole?.executeTrades ?? false);
  const hasStoneX = !!tradingAccountConfigs.find((tac) => tac.dataProvider === 4 && tac.additionalFields.hasTradingCredentials);
  const showTradePreview = !hasStoneX && !isDevEnv;
  const readyToTrade = (hasAtLeastOneCrop && hasStoneX) || isDevEnv;
  const tradeButtonTitle = readyToTrade ? '' : 'Must have at least one operation crop and a StoneX integration';
  const pathname = history.location.pathname;

  const isFeatureAccessible = useIsFeatureAccessible('isCropsMenuV2Enabled');

  const content = (
    <div className="sidebar-item ">
      {showTradeButtons && (
        <>
          <Button
            title={tradeButtonTitle}
            value="Trade"
            className="fr-button backGround-red sidebar-button"
            onClick={() => {
              showTradePreview ? onClick(`${pathname}/trade-preview`) : onClick(`${pathname}/trade`);
            }}
            disabled={!readyToTrade && !showTradePreview}
          />
          {/* don't show this if they aren't ready. let them only see the trade button & teaser at first */}
          {readyToTrade && (
            <Button
              title={tradeButtonTitle}
              value="View Trade Activity"
              className="fr-button backGround-red sidebar-button mb-4"
              onClick={() => {
                showTradePreview ? onClick(`${pathname}/trade-preview`) : onClick(`${pathname}/trade-activity`);
              }}
            />
          )}
        </>
      )}
      <Button
        value="Cash Bids"
        className="fr-button background-gray sidebar-button"
        onClick={() => onClick(isFeatureAccessible ? '/crops/cash-bids' : '/cash-bids')}
      />
    </div>
  );
  return props.wrap ? <div className="card mb-4">{content}</div> : content;
}
