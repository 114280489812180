import { isEmpty } from 'lodash';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';

import { faTriangleExclamation, FontAwesomeIcon } from '../../../components/faIcons';
import Loader from '../../../components/common/Loader/Loader';
import { RedGreen } from '../../../components/RedGreen/RedGreen';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import useMobileView, { BREAKPOINTS } from '../../../customHooks/useMobileView';
import { formatNumber } from '../../../utils/formatNumber';
import { OperationSummary } from '../../../queries/operationSummary';
import config from '../../../config';
import useIsFeatureAccessible from '../../../customHooks/useIsFeatureAccessible';
import styles from '../Dashboard.module.scss';

import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import { CommodityGroup } from '@harvestiq/constants';

const dashboardSalesBar = config.featureFlags.dashboardSalesBar;

const operationLinks = {
  soldRevenue: {
    [CommodityGroup.CROPS]: '/cash-sales/summary',
    [CommodityGroup.LIVESTOCK]: '/livestock/lots',
  },
  soldRevenueV2: {
    [CommodityGroup.CROPS]: '/crops/cash-sales/summary',
    [CommodityGroup.LIVESTOCK]: '/livestock/lots',
  },
  unsoldProjected: {
    [CommodityGroup.CROPS]: '/settings/prices',
    [CommodityGroup.LIVESTOCK]: '/livestock/lots',
  },
  hedgeGainLoss: {
    [CommodityGroup.CROPS]: '/my-positions/1/positions', // corn
    [CommodityGroup.LIVESTOCK]: '/my-positions/11/positions', // live cattle
  },
  costOfProduction: {
    [CommodityGroup.CROPS]: '/settings/costs',
    [CommodityGroup.LIVESTOCK]: '/livestock/lots',
  },
};

interface OperationSummaryCardProps {
  operationSummaryQuery: UseQueryResult<OperationSummary>;
  type: CommodityGroup.CROPS | CommodityGroup.LIVESTOCK;
}

export default function OperationSummaryCard({ operationSummaryQuery, type }: OperationSummaryCardProps) {
  const { data, isLoading, isError, isFetching } = operationSummaryQuery;
  const currency = useCurrentCurrency();
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const fontSize = isMobileView ? '14px' : '15px';
  const isCrops = type === CommodityGroup.CROPS;
  const uom = isCrops ? UnitOfMeasure.Acres : UnitOfMeasure.Head;
  const isFeatureAccessible = useIsFeatureAccessible('isCropsMenuV2Enabled');

  if (isLoading || ((!data || isEmpty(data)) && isFetching)) {
    return <Loader />;
  }

  if (!data || isError) {
    const errorSymbol = <FontAwesomeIcon icon={faTriangleExclamation} className="me-3" />;
    return (
      <div className={`${styles['section']} card dashboard-risk-summary`}>
        <div className="card-title mb-3">{type} Operation Totals</div>
        <Alert variant="danger" className="d-flex align-items-center">
          <div className="flex-shrink-0 me-2">{errorSymbol}</div>
          <div>There was a problem loading the operation summary.</div>
        </Alert>
      </div>
    );
  }

  return (
    <div className={`${styles['section']} card dashboard-risk-summary`}>
      <div className="card-title mb-3">{type} Operation Totals</div>
      <div className=" d-grid gap-2" style={{ fontSize: fontSize }}>
        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">
            <Link
              to={isFeatureAccessible ? operationLinks.soldRevenueV2[type] : operationLinks.soldRevenue[type]}
              className="operation-summary-link"
            >
              Sold Revenue
            </Link>
            {dashboardSalesBar && data.totalOversoldRevenueValue.greaterThan(0) && (
              <>
                **
                <br></br>
                <div style={{ fontSize: '8px' }}>**includes oversold revenue</div>
              </>
            )}
          </div>

          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{formatNumber(data.totalSoldRevenueValue, 2, currency)}</b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{`${formatNumber(data.totalSoldRevenuePerUOMPrice, 2, currency)}/${uom}`}</b>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">
            <Link to={operationLinks.unsoldProjected[type]} className="operation-summary-link">
              Unsold Projected
            </Link>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{formatNumber(data.totalUnsoldRevenueValue, 2, currency)}</b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{`${formatNumber(data.totalUnsoldRevenuePerUOMPrice, 2, currency)}/${uom}`}</b>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">
            <Link to={operationLinks.hedgeGainLoss[type]} className="operation-summary-link">
              Hedging Gain/Loss
            </Link>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{formatNumber(data.totalGainLossValue, 2, currency, true)}</b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{`${formatNumber(data.totalGainLossPerUOMPrice, 2, currency, true)}/${uom}`}</b>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">
            <Link to="/settings/crops" className="operation-summary-link">
              Estimated Insurance
            </Link>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{formatNumber(isCrops ? data.insuranceIndemnityValue : data.insuranceTotalValue, 2, currency)}</b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{`${formatNumber(
              isCrops ? data.insuranceIndemnityPerUOMPrice : data.insurancePerUOMValue,
              2,
              currency
            )}/${uom}`}</b>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">
            <Link to={operationLinks.costOfProduction[type]} className="operation-summary-link">
              Cost of Production
            </Link>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{formatNumber(data.totalCostsValue, 2, currency)}</b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>{`${formatNumber(data.totalCostsPerUOMPrice, 2, currency)}/${uom}`}</b>
          </div>
        </div>

        <div className="row">
          <div className="col m-2" style={{ borderBottom: '1px solid black' }}></div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">{!isCrops && 'Expected '}Profit</div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>
              <RedGreen content={formatNumber(data.totalProfitValue, 2, currency)} number={data.totalProfitValue.toNumber()} />
            </b>
          </div>
          <div className="col-6 col-md-4 text-end mt-auto">
            <b>
              <RedGreen
                content={`${formatNumber(data.totalProfitPerUOMPrice, 2, currency)}/${uom}`}
                number={data.totalProfitPerUOMPrice.toNumber()}
              />
            </b>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 text-start mt-auto">Daily Change</div>
          <div className="col-6 col-md-4 text-end mt-auto" style={{ fontSize: '0.9em' }}>
            <RedGreen
              content={formatNumber(data.totalProfitValueChange, 2, currency, true)}
              number={data.totalProfitValueChange.toNumber()}
            />
          </div>
          <div className="col-6 col-md-4 text-end mt-auto" style={{ fontSize: '0.9em' }}>
            <RedGreen
              content={`${formatNumber(data.totalProfitPerUOMPriceChange, 2, currency, true)}/${uom}`}
              number={data.totalProfitPerUOMPriceChange.toNumber()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
