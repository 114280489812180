import React from 'react';
import './DeleteButton.css';
import { FontAwesomeIcon, faTrashAlt, type IconProp } from '../../faIcons';

export default function DeleteButton({ onClick, disabled, text }: DeleteButtonProps) {
  return (
    <button className="delete-button" onClick={onClick} disabled={disabled} type="button">
      <FontAwesomeIcon icon={faTrashAlt as IconProp} color={disabled ? '#E0E4E8' : '#AB141D'} />
      {text && <span>{text}</span>}
    </button>
  );
}

type DeleteButtonProps = {
  disabled?: boolean;
  onClick: any;
  text?: string;
};
