import React from 'react';
import { useEffect, useState } from 'react';
import { BuySellType } from '@farmersrisk/shared/enums';
import { InstrumentType } from '@harvestiq/constants';
import { StepProps } from './TradeModal';
import DropDownList, { DropDownListChangeEvent } from '../common-next/DropDownList/DropDownList';
import NumericTextBox, { NumericTextBoxChangeEvent } from '../common-next/NumericTextBox/NumericTextBox';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import DialogContent from '../common-next/Dialog/DialogContent';
import Button from '../common-next/Button/Button';
import Hint from '../common-next/Hint/Hint';
import { Alert } from 'react-bootstrap';
import { InputChangeEvent } from '../common-next/Input/Input';
import TradeModalStepOneFutures from './TradeModalStepOneFutures';
import TradeModalStepOneOptions from './TradeModalStepOneOptions';
import { useIdleTimerContext } from 'react-idle-timer';
import TimeoutDisplay from '../common-next/TimeoutDisplay/TimeoutDisplay';
import { TradeExecutionIntent } from '../CQG/CQGProvider';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { allowedOrderDurationTypeData, allowedOrderTypeData } from '../../models/orderTypes';
import { getInstrumentTypeSelectors } from '../../shared/positionsSelectors';
import { FontAwesomeIconProps, faCircleArrowRight } from '../faIcons';
import CommodityDropDown from '../formControls/CommodityDropDown/CommodityDropDown';
import { TradeOrderState, useTradeOrderContext } from './TradeOrderProvider';
import { StringKeyOf } from 'type-fest';
import { useContracts } from './queries';
import { getDisplayQuantity } from './helpers';

//const FORCE_MWE_FOR_DEVELOPMENT = config.env === 'development' && process.env.REACT_APP_DO_NOT_FORCE_MWE !== 'true';
const arrowRightIcon: FontAwesomeIconProps = { icon: faCircleArrowRight };

export default function TradeModalStepOne(props: StepProps) {
  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const [showMobileWarningMessage, setShowMobileWarningMessage] = useState(isMobile);
  const orderContext = useTradeOrderContext();

  const timer = useIdleTimerContext();

  const commodity = orderContext.selectedCommodity?.value;
  const instrumentType = orderContext.instrumentType?.value;

  const contracts = useContracts({ commodity, instrumentType });

  // DERIVED STATE
  const cropIsSelected = !!orderContext.selectedCommodity?.value;
  const instrumentTypeIsSelected = !!orderContext.instrumentType?.value;
  const contractIsSelected = !!orderContext.contractSelector?.value;
  const buySellIsSelected = !!orderContext.buySell?.value;
  const allAreSelected = cropIsSelected && instrumentTypeIsSelected && contractIsSelected && buySellIsSelected;
  const instrumentTypeOptions = commodity ? getInstrumentTypeSelectors(commodity) : [];

  const isShortDated =
    orderContext.instrumentType.value === InstrumentType.ShortDatedCall ||
    orderContext.instrumentType.value === InstrumentType.ShortDatedPut;
  const cropIsValid = !(isShortDated && !orderContext.selectedCommodity?.value.cqgShortDatedTradingCode);

  const selectedOption = orderContext.selectedOption;
  const underlyingFuture = selectedOption?.underlyingFutureBarchartSymbol;
  const expirationDate = selectedOption?.expirationDate;

  const canProceed = allAreSelected && !props.isErrorState;

  // this is pretty much the 'didMount' of this component
  // so this is where you should put any logic that needs to run when the component is first rendered
  useEffect(() => {
    if (!props.isActive) return;

    orderContext.changeOrderValue('intent', TradeExecutionIntent.INITIALIZE);
    orderContext.changeOrder({
      orderLimitPrice: undefined,
      orderStopPrice: undefined,
    });
  }, [props.isActive]);

  useEffect(() => {
    if (
      contracts.length > 0 &&
      orderContext.contractSelector &&
      !contracts.find((contract) => contract.label == orderContext.contractSelector?.label)
    ) {
      orderContext.changeOrderValue('contractSelector', contracts[0]);
    }
  }, [contracts]);

  // change to ZOD!!
  const FORM_KEYS: Array<StringKeyOf<TradeOrderState>> = [
    'instrumentType',
    'selectedCommodity',
    'contractSelector',
    'selectedOption',
    'buySell',
    'orderType',
    'orderDurationType',
    'orderStopPrice',
    'orderLimitPrice',
    'intent',
    'cqgSymbol',
    'contractsQuantity',
    'orderPlacementTimestamp',
    'isMarketOpen',
    'marketHours',
  ];

  const FORM_NAMES: { [key: string]: StringKeyOf<TradeOrderState> } = FORM_KEYS.reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {} as { [key: string]: StringKeyOf<TradeOrderState> });

  // CALLBACKS
  function inputOnChange(e: InputChangeEvent | NumericTextBoxChangeEvent | DropDownListChangeEvent) {
    const key = FORM_NAMES[e.target.name ?? ''];
    if (key) {
      orderContext.changeOrderValue(key, e.target.value);
    }
  }

  // HELPER COMPONENTS
  const mobileWarningMessage = (
    <div className="alert alert-warning alert-dismissible fade show" role="alert">
      Our trading experience is not yet optimized for mobile devices. For an optimal experience, use a desktop computer.
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => setShowMobileWarningMessage(false)}
      ></button>
    </div>
  );

  // Reset the idle timer when this step is reached.
  timer.start();

  return (
    <DialogContent>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          if (canProceed) {
            props.next && props.next();
          }
          return false;
        }}
      >
        {showMobileWarningMessage && mobileWarningMessage}
        <DialogContentRow className="pb-3">
          <DropDownList
            label="Order Type"
            name={FORM_NAMES.orderType}
            data={allowedOrderTypeData}
            dataItemKey="value"
            textField="label"
            value={orderContext.orderType}
            onChange={inputOnChange}
            required={true}
            className="col-12 col-md-6"
            stretch
          />
          <DropDownList
            label="Order Duration"
            name={FORM_NAMES.orderDurationType}
            data={allowedOrderDurationTypeData}
            dataItemKey="value"
            textField="label"
            value={orderContext.orderDurationType}
            onChange={inputOnChange}
            required={true}
            className="col-12 col-md-6"
            stretch
          />
        </DialogContentRow>
        <DialogContentRow className="pt-2">
          <div className="col-12 col-md-8">
            <CommodityDropDown
              name={FORM_NAMES.selectedCommodity}
              value={orderContext.selectedCommodity}
              onChange={inputOnChange}
              required={true}
              valid={cropIsValid}
              validationMessage="This commodity cannot be short dated"
              stretch
            />
          </div>
          <div className="col-12 col-md-4">
            <div className="d-flex">
              <NumericTextBox
                name={'contractsQuantity'}
                label={'Contracts'}
                value={orderContext.contractsQuantity}
                step={1}
                min={1}
                max={80}
                onChange={inputOnChange}
                required={true}
                className="quantity-value"
                stretch={true}
              />
            </div>
            <Hint className="col d-flex justify-content-end mute">
              {getDisplayQuantity(commodity, orderContext.contractsQuantity)}
            </Hint>
          </div>
        </DialogContentRow>
        <DialogContentRow className="pt-0 pb-0">
          <DropDownList
            label="Instrument Type"
            name={FORM_NAMES.instrumentType}
            data={instrumentTypeOptions}
            dataItemKey="value"
            textField="label"
            value={orderContext.instrumentType}
            onChange={inputOnChange}
            required={true}
            disabled={!cropIsSelected}
            className="col-12 col-md-5"
            stretch
          />
          <DropDownList
            label={orderContext.instrumentType.value === InstrumentType.Futures ? 'Contract' : 'Contract Month'}
            name={FORM_NAMES.contractSelector}
            data={contracts}
            dataItemKey="value"
            textField="label"
            value={orderContext.contractSelector}
            onChange={inputOnChange}
            required={true}
            disabled={!instrumentTypeIsSelected || !cropIsSelected}
            className="col-12 col-md-4"
            stretch
          />
          <DropDownList
            label="Buy/Sell"
            name={FORM_NAMES.buySell}
            data={[
              { value: BuySellType.Buy, label: 'Buy' },
              { value: BuySellType.Sell, label: 'Sell' },
            ]}
            dataItemKey="value"
            textField="label"
            value={orderContext.buySell}
            onChange={inputOnChange}
            disabled={!contractIsSelected || !instrumentTypeIsSelected || !cropIsSelected}
            required={true}
            className="col-12 col-md-3"
            stretch
          />
        </DialogContentRow>
        <ExpirationUnderlyingFutures underlyingFuture={underlyingFuture} expirationDate={expirationDate} />
        {orderContext.instrumentType.value === InstrumentType.Futures ? (
          <TradeModalStepOneFutures isActive={props.isActive ?? false} />
        ) : (
          <TradeModalStepOneOptions isActive={props.isActive ?? false} />
        )}
        <DialogContentRow center justify className="pt-4">
          <Button
            className="col-4"
            themeColor="secondary"
            size="x-small"
            onClick={(e) => {
              e.preventDefault();
              if (props.showTradingHistoryModal) {
                props.showTradingHistoryModal();
              }
            }}
          >
            View Recent Trades
          </Button>

          <Button
            type="submit"
            themeColor={canProceed ? 'secondary' : 'light'}
            size="large"
            iconProps={arrowRightIcon}
            fontWeight="bold"
            className={`text-end col-6 review ${canProceed ? '' : 'button-disabled'}`}
          >
            Review Order
          </Button>
        </DialogContentRow>
        {props.isErrorState && props.message && (
          <DialogContentRow className="py-0">
            <Alert variant="danger" className="mb-0">
              {props.message}
            </Alert>
          </DialogContentRow>
        )}
      </form>
      <TimeoutDisplay onClose={props.onClose} message={'This modal will close in'} />
    </DialogContent>
  );
}

const ExpirationUnderlyingFutures = (props: { underlyingFuture: string | undefined; expirationDate: string | undefined }) => {
  if (!props.underlyingFuture && !props.expirationDate) {
    return <DialogContentRow center className="pt-1 pb-1"></DialogContentRow>;
  }
  return (
    <DialogContentRow center className="pt-1 pb-1">
      <div className="d-flex align-items-center justify-content-between">
        {props.underlyingFuture && <span>{props.underlyingFuture ? `Underlying Future: ${props.underlyingFuture}` : ' '}</span>}
        {props.expirationDate && <span> {props.expirationDate ? `Exp: ${props.expirationDate}` : ' '}</span>}
      </div>
    </DialogContentRow>
  );
};
