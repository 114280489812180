/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { ContractMetadata, OptionMaturityMetadata, SecurityMetadata } from './metadata_2';

export const protobufPackage = 'symbol_browsing_2';

/**
 * Instrument client type.
 * Values of this type are known to be stable in contrast to symbol category ids
 * and so they can be hard-coded in client code.
 * Only spread-related types are supported so far.
 */
export enum InstrumentClientType {
  INSTRUMENT_CLIENT_TYPE_UNKNOWN = 0,
  INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE = 1,
  INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR = 2,
  INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY = 3,
  INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY = 4,
  INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY = 5,
  INSTRUMENT_CLIENT_TYPE_SPREAD_PACK = 6,
  INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY = 7,
  INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR = 8,
  INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR = 9,
  INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP = 11,
  INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR = 12,
  INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK = 14,
  UNRECOGNIZED = -1,
}

export function instrumentClientTypeFromJSON(object: any): InstrumentClientType {
  switch (object) {
    case 0:
    case 'INSTRUMENT_CLIENT_TYPE_UNKNOWN':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_UNKNOWN;
    case 1:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE;
    case 2:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR;
    case 3:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY;
    case 4:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY;
    case 5:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY;
    case 6:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_PACK':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK;
    case 7:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY;
    case 8:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR;
    case 9:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR;
    case 11:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP;
    case 12:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR;
    case 14:
    case 'INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK':
      return InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return InstrumentClientType.UNRECOGNIZED;
  }
}

export function instrumentClientTypeToJSON(object: InstrumentClientType): string {
  switch (object) {
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_UNKNOWN:
      return 'INSTRUMENT_CLIENT_TYPE_UNKNOWN';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_BUNDLE';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_CONDOR';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_DOUBLE_BUTTERFLY';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_FUTURES_INTER_COMMODITY';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_BUTTERFLY';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_PACK';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_PACK_BUTTERFLY';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_REDUCED_TICK_CALENDAR';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_CALENDAR';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_STRIP';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_REVERSE_CALENDAR';
    case InstrumentClientType.INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK:
      return 'INSTRUMENT_CLIENT_TYPE_SPREAD_MONTH_VS_PACK';
    case InstrumentClientType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Symbol structure.
 * It can represent either a product (root symbol), a security,
 * an option maturity or a contract (leaf symbol).
 * Only one of corresponding *_metadata fields can be specified.
 * Each symbol except product ones has a link to its parent
 * (see Symbol.parent_symbol_id field):
 * parent symbol for a security symbol is a product symbol,
 * parent symbol for a contract symbol (non-option) is a security symbol,
 * parent symbol of an option strike contract symbol is an option maturity symbol,
 * parent symbol for an option maturity symbol is a security symbol.
 */
export interface Symbol {
  /** Symbol Identifier. */
  id: string;
  /** Symbol readable name. */
  name: string;
  /** Symbol description. */
  description: string;
  /**
   * CFI code (Classification of Financial Instruments, ISO 10962) if appropriate.
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  cfiCode: string;
  /** True if this symbol has child symbols (false for leafs of the symbol tree). */
  hasChildSymbols: boolean;
  /**
   * Deleted flag is used in updates when the symbol is either removed (e.g. expired) or
   * no longer meets request filtering criterias (e.g. SymbolListRequest.category_id filter).
   * Note: list of categories in category_id field (see below) may be cleared when symbol is removed.
   */
  deleted: boolean;
  /**
   * Last trading date for derivatives if applicable.
   * (local exchange date in time format, use date part only).
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  lastTradingDate: number;
  /**
   * Month letter and 2-digit year identifying the maturity month of the symbol.
   * Note: look at comment for maturity_month_year from ContractMetadata for further info.
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  maturityMonthYear: string;
  /**
   * Name of a group of symbols that share the same properties (e.g. commodity name for futures and options).
   * Deprecated and should not be used. Use nested metadata messages.
   *
   * @deprecated
   */
  instrumentGroupName: string;
  /**
   * ID (Symbol.id) of the parent symbol (if this is not the root symbol - product).
   * If this field is empty, product_metadata field is set.
   */
  parentSymbolId: string;
  /**
   * List of categories (SymbolCategory.id) of this symbol, if any.
   * See SymbolCategory and SymbolCategoryRequest messages.
   */
  categoryIds: string[];
  /**
   * Deprecated and should not be used. Use metadata_2.SecurityMetadata.source_instrument_group_name or
   * ContractMetadata.source_contract_id instead.
   *
   * @deprecated
   */
  sourceSymbolId: string;
  /** Rank value of the symbol for sorting peer symbols in user interfaces. Higher value means greater priority. */
  rank: number;
  /**
   * Meta-data of a product if this symbol describes one (aka Symbol Root Key).
   * If this field is set, this symbol doesn't have a parent symbol.
   */
  productMetadata: ProductMetadata | undefined;
  /** Meta-data of a security if this symbol describes one (aka Symbol Prefix Key). */
  securityMetadata: SecurityMetadata | undefined;
  /** Meta-data of options maturity group if this symbol describes one (aka Option Lead Key). */
  optionMaturityMetadata: OptionMaturityMetadata | undefined;
  /** Contract meta-data if a symbol is a specific contract (leaf of the symbol tree). */
  contractMetadata: ContractMetadata | undefined;
}

/** Symbol category. */
export interface SymbolCategory {
  /**
   * Category identifier.
   * Note: this identifier is not guaranteed to be stable, so categories should be
   * obtained e.g. via SymbolCategoryListRequest or SymbolCategoryListByInstrumentTypeRequest
   * instead of being saved using this id between sessions.
   */
  id: string;
  /** Category name. */
  name: string;
  /** Category description. */
  description: string;
  /** Category parent identifier (SymbolCategory.id). Omitted for root categories. */
  parentId: string;
  /** Indicates whether this category can be used as a filter for getting a list of symbols. */
  canFilter: boolean;
  /**
   * If the category is an exchange then this field defines exchange id.
   * See ExchangeMetadata.exchange_id.
   */
  exchangeId: number;
  /** If the category is a OTC contributor then this field defines contributor ID. */
  contributorId: string;
  /**
   * If the category is an Asset Class then this field defines instrument business type id.
   * To get a list of all instrument business type id values, send a SymbolCategoryListRequest
   * with category_id corresponding to Asset Class, then for each id from SymbolCategoryListReport's
   * symbol_categories field, send SymbolCategoryListRequest with this id as the value of category_id
   * or send SymbolCategoryListRequest with a big enough depth parameter value (see SymbolCategoryListRequest.depth)
   * in order to get all symbol categories.
   */
  instrumentBusinessTypeId: number;
  /** Deleted flag is used in updates when the category is removed. */
  deleted: boolean;
}

/**
 * Request for a symbol category [sub-]tree for a particular root. each category can have a list of sub-categories.
 * A list of roots can be requested using an empty list of category IDs.
 */
export interface SymbolCategoryListRequest {
  /**
   * Category Identifier (SymbolCategory.id) to request corresponding sub-tree.
   * Do not specify category ID to get categories from roots.
   */
  categoryId: string;
  /** Optional depth. One level is returned if not specified. */
  depth: number;
}

/** Report with a symbol category tree for a particular root. */
export interface SymbolCategoryListReport {
  /** List of categories linked to their parents. */
  symbolCategories: SymbolCategory[];
}

/** Request for a specific category by ID. */
export interface SymbolCategoryRequest {
  /** Category Identifier (SymbolCategory.id) to request corresponding category. */
  categoryId: string;
}

/** Report with a category. */
export interface SymbolCategoryReport {
  symbolCategory: SymbolCategory | undefined;
}

/** Request for a list of category symbols matching input instrument client type. */
export interface SymbolCategoryListByInstrumentTypeRequest {
  /**
   * Instrument client type.
   * This field is associated with InstrumentClientType.Type enum.
   * INSTRUMENT_CLIENT_TYPE_UNKNOWN is used if this field is empty.
   */
  instrumentClientType: number;
}

/** Report with a list of category symbols matching input instrument client type. */
export interface SymbolCategoryListByInstrumentTypeReport {
  symbolCategories: SymbolCategory[];
}

/**
 * Request for a list of symbols according to a filter.
 * At least one filter field has to be specified.
 * Number of symbols in response is limited (default is 10000).
 * Note: Symbols related to option strikes are not returned unless symbol id of
 * corresponding option maturity symbol is specified as a parent_symbol_id in the request.
 */
export interface SymbolListRequest {
  /**
   * Optional category filter (SymbolCategory.id) controlling how multiple
   * symbol categories are applied in the filter.
   * Categories within the same tree (having the same root) are applied by "OR" in the category filter
   * (e.g. two exchanges, either matches). Otherwise categories are applied by "AND" (e.g. exchange and asset).
   * See SymbolCategory and SymbolCategoryListRequest messages.
   */
  categoryIds: string[];
  /**
   * Number of levels in the symbol tree to return from the top of the symbol tree
   * (i.e. from product level) or from the parent symbol (if specified).
   * Symbols from deeper levels are excluded from the results.
   * One level is returned if the field is omitted.
   */
  depth: number;
  /**
   * Parent symbol id (Symbol.id) filter to return only child symbols of this parent.
   * If this field is specified, depth must be one or omitted.
   */
  parentSymbolId: string;
}

/** Report with a list of found symbols. */
export interface SymbolListReport {
  /** List of symbols. */
  symbols: Symbol[];
}

/** Request for a specific symbol by ID. */
export interface SymbolRequest {
  /** ID (Symbol.id) of a symbol to request. */
  symbolId: string;
  /**
   * True if deleted symbols should also be reported.
   * Note that symbol marked as deleted will be available for 30 days (by default) from its last trading date.
   */
  includeDeleted: boolean;
}

/** Report with a symbol. */
export interface SymbolReport {
  symbol: Symbol | undefined;
}

export interface ProductMetadata {
  productId: string;
}

/** Request for a list of product symbols matching a search criteria (see Symbol.product_metadata). */
export interface ProductSearchRequest {
  /**
   * Term to search matched product symbols on.
   * Search term needs to be filled and its length must be greater than 3 (by default) if category_id filter is empty.
   * Searching is done on the text associated to the being searched product symbols.
   * Matching is supported only by "starts with" pattern.
   * Multi-word input (whitespace delimited) is applied by "or".
   * I.e. it matches a symbol if any word from the search term matches.
   */
  searchTerm: string;
  /**
   * Optional category (SymbolCategory.id) filter controlling how multiple
   * symbol categories are applied in the filter.
   * Categories within the same tree (having the same root) are applied by "OR" in the category filter
   * (e.g. two exchanges, either matches). Otherwise categories are applied by "AND" (e.g. exchange and asset).
   * See SymbolCategory and SymbolCategoryListRequest messages.
   */
  categoryIds: string[];
}

/** Report with a list of found product symbols. */
export interface ProductSearchReport {
  /** List of product symbols. */
  symbols: Symbol[];
}

function createBaseSymbol(): Symbol {
  return {
    id: '',
    name: '',
    description: '',
    cfiCode: '',
    hasChildSymbols: false,
    deleted: false,
    lastTradingDate: 0,
    maturityMonthYear: '',
    instrumentGroupName: '',
    parentSymbolId: '',
    categoryIds: [],
    sourceSymbolId: '',
    rank: 0,
    productMetadata: undefined,
    securityMetadata: undefined,
    optionMaturityMetadata: undefined,
    contractMetadata: undefined,
  };
}

export const Symbol = {
  encode(message: Symbol, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.cfiCode !== '') {
      writer.uint32(34).string(message.cfiCode);
    }
    if (message.hasChildSymbols === true) {
      writer.uint32(40).bool(message.hasChildSymbols);
    }
    if (message.deleted === true) {
      writer.uint32(56).bool(message.deleted);
    }
    if (message.lastTradingDate !== 0) {
      writer.uint32(64).sint64(message.lastTradingDate);
    }
    if (message.maturityMonthYear !== '') {
      writer.uint32(74).string(message.maturityMonthYear);
    }
    if (message.instrumentGroupName !== '') {
      writer.uint32(82).string(message.instrumentGroupName);
    }
    if (message.parentSymbolId !== '') {
      writer.uint32(90).string(message.parentSymbolId);
    }
    for (const v of message.categoryIds) {
      writer.uint32(98).string(v!);
    }
    if (message.sourceSymbolId !== '') {
      writer.uint32(106).string(message.sourceSymbolId);
    }
    if (message.rank !== 0) {
      writer.uint32(136).uint32(message.rank);
    }
    if (message.productMetadata !== undefined) {
      ProductMetadata.encode(message.productMetadata, writer.uint32(114).fork()).ldelim();
    }
    if (message.securityMetadata !== undefined) {
      SecurityMetadata.encode(message.securityMetadata, writer.uint32(122).fork()).ldelim();
    }
    if (message.optionMaturityMetadata !== undefined) {
      OptionMaturityMetadata.encode(message.optionMaturityMetadata, writer.uint32(130).fork()).ldelim();
    }
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Symbol {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbol();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        case 4:
          message.cfiCode = reader.string();
          break;
        case 5:
          message.hasChildSymbols = reader.bool();
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        case 8:
          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.maturityMonthYear = reader.string();
          break;
        case 10:
          message.instrumentGroupName = reader.string();
          break;
        case 11:
          message.parentSymbolId = reader.string();
          break;
        case 12:
          message.categoryIds.push(reader.string());
          break;
        case 13:
          message.sourceSymbolId = reader.string();
          break;
        case 17:
          message.rank = reader.uint32();
          break;
        case 14:
          message.productMetadata = ProductMetadata.decode(reader, reader.uint32());
          break;
        case 15:
          message.securityMetadata = SecurityMetadata.decode(reader, reader.uint32());
          break;
        case 16:
          message.optionMaturityMetadata = OptionMaturityMetadata.decode(reader, reader.uint32());
          break;
        case 6:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Symbol {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      cfiCode: isSet(object.cfiCode) ? String(object.cfiCode) : '',
      hasChildSymbols: isSet(object.hasChildSymbols) ? Boolean(object.hasChildSymbols) : false,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      lastTradingDate: isSet(object.lastTradingDate) ? Number(object.lastTradingDate) : 0,
      maturityMonthYear: isSet(object.maturityMonthYear) ? String(object.maturityMonthYear) : '',
      instrumentGroupName: isSet(object.instrumentGroupName) ? String(object.instrumentGroupName) : '',
      parentSymbolId: isSet(object.parentSymbolId) ? String(object.parentSymbolId) : '',
      categoryIds: Array.isArray(object?.categoryIds) ? object.categoryIds.map((e: any) => String(e)) : [],
      sourceSymbolId: isSet(object.sourceSymbolId) ? String(object.sourceSymbolId) : '',
      rank: isSet(object.rank) ? Number(object.rank) : 0,
      productMetadata: isSet(object.productMetadata) ? ProductMetadata.fromJSON(object.productMetadata) : undefined,
      securityMetadata: isSet(object.securityMetadata) ? SecurityMetadata.fromJSON(object.securityMetadata) : undefined,
      optionMaturityMetadata: isSet(object.optionMaturityMetadata)
        ? OptionMaturityMetadata.fromJSON(object.optionMaturityMetadata)
        : undefined,
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: Symbol): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.cfiCode !== undefined && (obj.cfiCode = message.cfiCode);
    message.hasChildSymbols !== undefined && (obj.hasChildSymbols = message.hasChildSymbols);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.lastTradingDate !== undefined && (obj.lastTradingDate = Math.round(message.lastTradingDate));
    message.maturityMonthYear !== undefined && (obj.maturityMonthYear = message.maturityMonthYear);
    message.instrumentGroupName !== undefined && (obj.instrumentGroupName = message.instrumentGroupName);
    message.parentSymbolId !== undefined && (obj.parentSymbolId = message.parentSymbolId);
    if (message.categoryIds) {
      obj.categoryIds = message.categoryIds.map((e) => e);
    } else {
      obj.categoryIds = [];
    }
    message.sourceSymbolId !== undefined && (obj.sourceSymbolId = message.sourceSymbolId);
    message.rank !== undefined && (obj.rank = Math.round(message.rank));
    message.productMetadata !== undefined &&
      (obj.productMetadata = message.productMetadata ? ProductMetadata.toJSON(message.productMetadata) : undefined);
    message.securityMetadata !== undefined &&
      (obj.securityMetadata = message.securityMetadata ? SecurityMetadata.toJSON(message.securityMetadata) : undefined);
    message.optionMaturityMetadata !== undefined &&
      (obj.optionMaturityMetadata = message.optionMaturityMetadata
        ? OptionMaturityMetadata.toJSON(message.optionMaturityMetadata)
        : undefined);
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Symbol>, I>>(base?: I): Symbol {
    return Symbol.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Symbol>, I>>(object: I): Symbol {
    const message = createBaseSymbol();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.cfiCode = object.cfiCode ?? '';
    message.hasChildSymbols = object.hasChildSymbols ?? false;
    message.deleted = object.deleted ?? false;
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.maturityMonthYear = object.maturityMonthYear ?? '';
    message.instrumentGroupName = object.instrumentGroupName ?? '';
    message.parentSymbolId = object.parentSymbolId ?? '';
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    message.sourceSymbolId = object.sourceSymbolId ?? '';
    message.rank = object.rank ?? 0;
    message.productMetadata =
      object.productMetadata !== undefined && object.productMetadata !== null
        ? ProductMetadata.fromPartial(object.productMetadata)
        : undefined;
    message.securityMetadata =
      object.securityMetadata !== undefined && object.securityMetadata !== null
        ? SecurityMetadata.fromPartial(object.securityMetadata)
        : undefined;
    message.optionMaturityMetadata =
      object.optionMaturityMetadata !== undefined && object.optionMaturityMetadata !== null
        ? OptionMaturityMetadata.fromPartial(object.optionMaturityMetadata)
        : undefined;
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    return message;
  },
};

function createBaseSymbolCategory(): SymbolCategory {
  return {
    id: '',
    name: '',
    description: '',
    parentId: '',
    canFilter: false,
    exchangeId: 0,
    contributorId: '',
    instrumentBusinessTypeId: 0,
    deleted: false,
  };
}

export const SymbolCategory = {
  encode(message: SymbolCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.parentId !== '') {
      writer.uint32(34).string(message.parentId);
    }
    if (message.canFilter === true) {
      writer.uint32(40).bool(message.canFilter);
    }
    if (message.exchangeId !== 0) {
      writer.uint32(48).sint32(message.exchangeId);
    }
    if (message.contributorId !== '') {
      writer.uint32(58).string(message.contributorId);
    }
    if (message.instrumentBusinessTypeId !== 0) {
      writer.uint32(72).uint32(message.instrumentBusinessTypeId);
    }
    if (message.deleted === true) {
      writer.uint32(64).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        case 4:
          message.parentId = reader.string();
          break;
        case 5:
          message.canFilter = reader.bool();
          break;
        case 6:
          message.exchangeId = reader.sint32();
          break;
        case 7:
          message.contributorId = reader.string();
          break;
        case 9:
          message.instrumentBusinessTypeId = reader.uint32();
          break;
        case 8:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategory {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      parentId: isSet(object.parentId) ? String(object.parentId) : '',
      canFilter: isSet(object.canFilter) ? Boolean(object.canFilter) : false,
      exchangeId: isSet(object.exchangeId) ? Number(object.exchangeId) : 0,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      instrumentBusinessTypeId: isSet(object.instrumentBusinessTypeId) ? Number(object.instrumentBusinessTypeId) : 0,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: SymbolCategory): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.canFilter !== undefined && (obj.canFilter = message.canFilter);
    message.exchangeId !== undefined && (obj.exchangeId = Math.round(message.exchangeId));
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    message.instrumentBusinessTypeId !== undefined &&
      (obj.instrumentBusinessTypeId = Math.round(message.instrumentBusinessTypeId));
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategory>, I>>(base?: I): SymbolCategory {
    return SymbolCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategory>, I>>(object: I): SymbolCategory {
    const message = createBaseSymbolCategory();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.parentId = object.parentId ?? '';
    message.canFilter = object.canFilter ?? false;
    message.exchangeId = object.exchangeId ?? 0;
    message.contributorId = object.contributorId ?? '';
    message.instrumentBusinessTypeId = object.instrumentBusinessTypeId ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseSymbolCategoryListRequest(): SymbolCategoryListRequest {
  return { categoryId: '', depth: 0 };
}

export const SymbolCategoryListRequest = {
  encode(message: SymbolCategoryListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== '') {
      writer.uint32(10).string(message.categoryId);
    }
    if (message.depth !== 0) {
      writer.uint32(16).uint32(message.depth);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categoryId = reader.string();
          break;
        case 2:
          message.depth = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListRequest {
    return {
      categoryId: isSet(object.categoryId) ? String(object.categoryId) : '',
      depth: isSet(object.depth) ? Number(object.depth) : 0,
    };
  },

  toJSON(message: SymbolCategoryListRequest): unknown {
    const obj: any = {};
    message.categoryId !== undefined && (obj.categoryId = message.categoryId);
    message.depth !== undefined && (obj.depth = Math.round(message.depth));
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListRequest>, I>>(base?: I): SymbolCategoryListRequest {
    return SymbolCategoryListRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListRequest>, I>>(object: I): SymbolCategoryListRequest {
    const message = createBaseSymbolCategoryListRequest();
    message.categoryId = object.categoryId ?? '';
    message.depth = object.depth ?? 0;
    return message;
  },
};

function createBaseSymbolCategoryListReport(): SymbolCategoryListReport {
  return { symbolCategories: [] };
}

export const SymbolCategoryListReport = {
  encode(message: SymbolCategoryListReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbolCategories) {
      SymbolCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbolCategories.push(SymbolCategory.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListReport {
    return {
      symbolCategories: Array.isArray(object?.symbolCategories)
        ? object.symbolCategories.map((e: any) => SymbolCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SymbolCategoryListReport): unknown {
    const obj: any = {};
    if (message.symbolCategories) {
      obj.symbolCategories = message.symbolCategories.map((e) => (e ? SymbolCategory.toJSON(e) : undefined));
    } else {
      obj.symbolCategories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListReport>, I>>(base?: I): SymbolCategoryListReport {
    return SymbolCategoryListReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListReport>, I>>(object: I): SymbolCategoryListReport {
    const message = createBaseSymbolCategoryListReport();
    message.symbolCategories = object.symbolCategories?.map((e) => SymbolCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolCategoryRequest(): SymbolCategoryRequest {
  return { categoryId: '' };
}

export const SymbolCategoryRequest = {
  encode(message: SymbolCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== '') {
      writer.uint32(10).string(message.categoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categoryId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryRequest {
    return { categoryId: isSet(object.categoryId) ? String(object.categoryId) : '' };
  },

  toJSON(message: SymbolCategoryRequest): unknown {
    const obj: any = {};
    message.categoryId !== undefined && (obj.categoryId = message.categoryId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryRequest>, I>>(base?: I): SymbolCategoryRequest {
    return SymbolCategoryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryRequest>, I>>(object: I): SymbolCategoryRequest {
    const message = createBaseSymbolCategoryRequest();
    message.categoryId = object.categoryId ?? '';
    return message;
  },
};

function createBaseSymbolCategoryReport(): SymbolCategoryReport {
  return { symbolCategory: undefined };
}

export const SymbolCategoryReport = {
  encode(message: SymbolCategoryReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbolCategory !== undefined) {
      SymbolCategory.encode(message.symbolCategory, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbolCategory = SymbolCategory.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryReport {
    return {
      symbolCategory: isSet(object.symbolCategory) ? SymbolCategory.fromJSON(object.symbolCategory) : undefined,
    };
  },

  toJSON(message: SymbolCategoryReport): unknown {
    const obj: any = {};
    message.symbolCategory !== undefined &&
      (obj.symbolCategory = message.symbolCategory ? SymbolCategory.toJSON(message.symbolCategory) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryReport>, I>>(base?: I): SymbolCategoryReport {
    return SymbolCategoryReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryReport>, I>>(object: I): SymbolCategoryReport {
    const message = createBaseSymbolCategoryReport();
    message.symbolCategory =
      object.symbolCategory !== undefined && object.symbolCategory !== null
        ? SymbolCategory.fromPartial(object.symbolCategory)
        : undefined;
    return message;
  },
};

function createBaseSymbolCategoryListByInstrumentTypeRequest(): SymbolCategoryListByInstrumentTypeRequest {
  return { instrumentClientType: 0 };
}

export const SymbolCategoryListByInstrumentTypeRequest = {
  encode(message: SymbolCategoryListByInstrumentTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentClientType !== 0) {
      writer.uint32(8).uint32(message.instrumentClientType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListByInstrumentTypeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListByInstrumentTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instrumentClientType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListByInstrumentTypeRequest {
    return { instrumentClientType: isSet(object.instrumentClientType) ? Number(object.instrumentClientType) : 0 };
  },

  toJSON(message: SymbolCategoryListByInstrumentTypeRequest): unknown {
    const obj: any = {};
    message.instrumentClientType !== undefined && (obj.instrumentClientType = Math.round(message.instrumentClientType));
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeRequest>, I>>(
    base?: I
  ): SymbolCategoryListByInstrumentTypeRequest {
    return SymbolCategoryListByInstrumentTypeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeRequest>, I>>(
    object: I
  ): SymbolCategoryListByInstrumentTypeRequest {
    const message = createBaseSymbolCategoryListByInstrumentTypeRequest();
    message.instrumentClientType = object.instrumentClientType ?? 0;
    return message;
  },
};

function createBaseSymbolCategoryListByInstrumentTypeReport(): SymbolCategoryListByInstrumentTypeReport {
  return { symbolCategories: [] };
}

export const SymbolCategoryListByInstrumentTypeReport = {
  encode(message: SymbolCategoryListByInstrumentTypeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbolCategories) {
      SymbolCategory.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolCategoryListByInstrumentTypeReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolCategoryListByInstrumentTypeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbolCategories.push(SymbolCategory.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolCategoryListByInstrumentTypeReport {
    return {
      symbolCategories: Array.isArray(object?.symbolCategories)
        ? object.symbolCategories.map((e: any) => SymbolCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SymbolCategoryListByInstrumentTypeReport): unknown {
    const obj: any = {};
    if (message.symbolCategories) {
      obj.symbolCategories = message.symbolCategories.map((e) => (e ? SymbolCategory.toJSON(e) : undefined));
    } else {
      obj.symbolCategories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeReport>, I>>(
    base?: I
  ): SymbolCategoryListByInstrumentTypeReport {
    return SymbolCategoryListByInstrumentTypeReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolCategoryListByInstrumentTypeReport>, I>>(
    object: I
  ): SymbolCategoryListByInstrumentTypeReport {
    const message = createBaseSymbolCategoryListByInstrumentTypeReport();
    message.symbolCategories = object.symbolCategories?.map((e) => SymbolCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolListRequest(): SymbolListRequest {
  return { categoryIds: [], depth: 0, parentSymbolId: '' };
}

export const SymbolListRequest = {
  encode(message: SymbolListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.categoryIds) {
      writer.uint32(10).string(v!);
    }
    if (message.depth !== 0) {
      writer.uint32(16).uint32(message.depth);
    }
    if (message.parentSymbolId !== '') {
      writer.uint32(26).string(message.parentSymbolId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categoryIds.push(reader.string());
          break;
        case 2:
          message.depth = reader.uint32();
          break;
        case 3:
          message.parentSymbolId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolListRequest {
    return {
      categoryIds: Array.isArray(object?.categoryIds) ? object.categoryIds.map((e: any) => String(e)) : [],
      depth: isSet(object.depth) ? Number(object.depth) : 0,
      parentSymbolId: isSet(object.parentSymbolId) ? String(object.parentSymbolId) : '',
    };
  },

  toJSON(message: SymbolListRequest): unknown {
    const obj: any = {};
    if (message.categoryIds) {
      obj.categoryIds = message.categoryIds.map((e) => e);
    } else {
      obj.categoryIds = [];
    }
    message.depth !== undefined && (obj.depth = Math.round(message.depth));
    message.parentSymbolId !== undefined && (obj.parentSymbolId = message.parentSymbolId);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolListRequest>, I>>(base?: I): SymbolListRequest {
    return SymbolListRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolListRequest>, I>>(object: I): SymbolListRequest {
    const message = createBaseSymbolListRequest();
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    message.depth = object.depth ?? 0;
    message.parentSymbolId = object.parentSymbolId ?? '';
    return message;
  },
};

function createBaseSymbolListReport(): SymbolListReport {
  return { symbols: [] };
}

export const SymbolListReport = {
  encode(message: SymbolListReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbols) {
      Symbol.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolListReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolListReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbols.push(Symbol.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolListReport {
    return { symbols: Array.isArray(object?.symbols) ? object.symbols.map((e: any) => Symbol.fromJSON(e)) : [] };
  },

  toJSON(message: SymbolListReport): unknown {
    const obj: any = {};
    if (message.symbols) {
      obj.symbols = message.symbols.map((e) => (e ? Symbol.toJSON(e) : undefined));
    } else {
      obj.symbols = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolListReport>, I>>(base?: I): SymbolListReport {
    return SymbolListReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolListReport>, I>>(object: I): SymbolListReport {
    const message = createBaseSymbolListReport();
    message.symbols = object.symbols?.map((e) => Symbol.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSymbolRequest(): SymbolRequest {
  return { symbolId: '', includeDeleted: false };
}

export const SymbolRequest = {
  encode(message: SymbolRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbolId !== '') {
      writer.uint32(10).string(message.symbolId);
    }
    if (message.includeDeleted === true) {
      writer.uint32(16).bool(message.includeDeleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbolId = reader.string();
          break;
        case 2:
          message.includeDeleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolRequest {
    return {
      symbolId: isSet(object.symbolId) ? String(object.symbolId) : '',
      includeDeleted: isSet(object.includeDeleted) ? Boolean(object.includeDeleted) : false,
    };
  },

  toJSON(message: SymbolRequest): unknown {
    const obj: any = {};
    message.symbolId !== undefined && (obj.symbolId = message.symbolId);
    message.includeDeleted !== undefined && (obj.includeDeleted = message.includeDeleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolRequest>, I>>(base?: I): SymbolRequest {
    return SymbolRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolRequest>, I>>(object: I): SymbolRequest {
    const message = createBaseSymbolRequest();
    message.symbolId = object.symbolId ?? '';
    message.includeDeleted = object.includeDeleted ?? false;
    return message;
  },
};

function createBaseSymbolReport(): SymbolReport {
  return { symbol: undefined };
}

export const SymbolReport = {
  encode(message: SymbolReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbol !== undefined) {
      Symbol.encode(message.symbol, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbol = Symbol.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolReport {
    return { symbol: isSet(object.symbol) ? Symbol.fromJSON(object.symbol) : undefined };
  },

  toJSON(message: SymbolReport): unknown {
    const obj: any = {};
    message.symbol !== undefined && (obj.symbol = message.symbol ? Symbol.toJSON(message.symbol) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolReport>, I>>(base?: I): SymbolReport {
    return SymbolReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolReport>, I>>(object: I): SymbolReport {
    const message = createBaseSymbolReport();
    message.symbol = object.symbol !== undefined && object.symbol !== null ? Symbol.fromPartial(object.symbol) : undefined;
    return message;
  },
};

function createBaseProductMetadata(): ProductMetadata {
  return { productId: '' };
}

export const ProductMetadata = {
  encode(message: ProductMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productId !== '') {
      writer.uint32(10).string(message.productId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.productId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductMetadata {
    return { productId: isSet(object.productId) ? String(object.productId) : '' };
  },

  toJSON(message: ProductMetadata): unknown {
    const obj: any = {};
    message.productId !== undefined && (obj.productId = message.productId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductMetadata>, I>>(base?: I): ProductMetadata {
    return ProductMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductMetadata>, I>>(object: I): ProductMetadata {
    const message = createBaseProductMetadata();
    message.productId = object.productId ?? '';
    return message;
  },
};

function createBaseProductSearchRequest(): ProductSearchRequest {
  return { searchTerm: '', categoryIds: [] };
}

export const ProductSearchRequest = {
  encode(message: ProductSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.searchTerm !== '') {
      writer.uint32(10).string(message.searchTerm);
    }
    for (const v of message.categoryIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductSearchRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.searchTerm = reader.string();
          break;
        case 2:
          message.categoryIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductSearchRequest {
    return {
      searchTerm: isSet(object.searchTerm) ? String(object.searchTerm) : '',
      categoryIds: Array.isArray(object?.categoryIds) ? object.categoryIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ProductSearchRequest): unknown {
    const obj: any = {};
    message.searchTerm !== undefined && (obj.searchTerm = message.searchTerm);
    if (message.categoryIds) {
      obj.categoryIds = message.categoryIds.map((e) => e);
    } else {
      obj.categoryIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSearchRequest>, I>>(base?: I): ProductSearchRequest {
    return ProductSearchRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductSearchRequest>, I>>(object: I): ProductSearchRequest {
    const message = createBaseProductSearchRequest();
    message.searchTerm = object.searchTerm ?? '';
    message.categoryIds = object.categoryIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseProductSearchReport(): ProductSearchReport {
  return { symbols: [] };
}

export const ProductSearchReport = {
  encode(message: ProductSearchReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.symbols) {
      Symbol.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductSearchReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductSearchReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbols.push(Symbol.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProductSearchReport {
    return { symbols: Array.isArray(object?.symbols) ? object.symbols.map((e: any) => Symbol.fromJSON(e)) : [] };
  },

  toJSON(message: ProductSearchReport): unknown {
    const obj: any = {};
    if (message.symbols) {
      obj.symbols = message.symbols.map((e) => (e ? Symbol.toJSON(e) : undefined));
    } else {
      obj.symbols = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSearchReport>, I>>(base?: I): ProductSearchReport {
    return ProductSearchReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductSearchReport>, I>>(object: I): ProductSearchReport {
    const message = createBaseProductSearchReport();
    message.symbols = object.symbols?.map((e) => Symbol.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
