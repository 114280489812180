import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import Decimal from 'decimal.js';
import { OrderStatusMap } from '../TradeModal/TradeModalConfirmation';
import { getCommodities } from '@harvestiq/constants';
import { formatNumber } from '../../utils/formatNumber';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import { CQGSymbolUtils } from '@harvestiq/symbology';
import Button from '../common-next/Button/Button';
import { FontAwesomeIcon, faCalendar, faCircleArrowRight, faReceipt } from '../faIcons';
import { ContractState, SimpleOrderStatus } from '../../store/CQG/orderStatusReducer';
import { CQGHelpers } from '../CQG/helpers';
import { getCancelableStatuses } from '@farmersrisk/cqg/cqgClient/helpers';
import { useHistory } from 'react-router-dom';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { ContractMetadata } from '@farmersrisk/cqg/cqgMessages/WebAPI/metadata_2';
import { OrderStatus_Status } from '@farmersrisk/cqg/cqgMessages/common/shared_1';
import { CancelTradeButton } from '../common-next/CancelTradeButton/CancelTradeButton';
import { getOrderDurationTypeLabel, getOrderTypeLabel } from '../../models/orderTypes';
import { FUTURES_MONTH_CODES } from '@harvestiq/symbology';
import { useCQGContext } from '../CQG/useCQGContext';
import useCQGService from '../CQG/useCQGService';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(advanced);
dayjs.extend(timezone);

const DATE_FORMAT = 'M/DD/YY h:mm:ss A';
export interface TradeActivityRowProps {
  orderStatus: SimpleOrderStatus;
  contractMetadataMap: ContractState | undefined;
  className?: string;
}

export default function TradeActivityRow(props: TradeActivityRowProps) {
  const isPhone = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const cqgContext = useCQGContext();
  const cqgService = useCQGService();
  const [isCanceling, setIsCanceling] = React.useState(false);
  const { orderStatus, contractMetadataMap, className } = props;
  const currency = useCurrentCurrency();
  const crops = getCommodities();
  const shortDatedTradingCodes = crops.filter((crop) => crop.cqgShortDatedTradingCode);
  const cqgShortDatedCodes = shortDatedTradingCodes.map((crop) => crop.cqgShortDatedTradingCode!);

  // Format the timestamp (seconds since epoch) to a readable date
  const formattedTradeTimestamp = orderStatus.submissionUtcTimestamp.valueOf()
    ? dayjs.utc(orderStatus.submissionUtcTimestamp).local().format(DATE_FORMAT)
    : '---';

  // Get the appropriate contract metadata
  let contractMetadata: ContractMetadata | undefined;
  if (orderStatus.order && contractMetadataMap) {
    contractMetadata = contractMetadataMap[orderStatus.order.contractId] as ContractMetadata;
  }
  let contractCode = contractMetadata?.instrumentGroupName.split('.')[0];

  let cropType = contractMetadata?.instrumentGroupName.split('.')[2];

  const cropName = crops.find((crop) => crop.cqgShortDatedTradingCode === cropType || crop.cqgTradingCode === cropType)?.name;

  const cqgSymbol = contractMetadata?.cqgContractSymbol;

  // We will not display a price for futures.
  let strike = '--';
  if (contractCode !== 'F') {
    const strikePrice = contractMetadata?.strike;
    if (strikePrice) {
      const strikePriceDollars = new Decimal(strikePrice).dividedBy(1000).toNumber();
      strike = formatNumber(strikePriceDollars, 4, currency);
    }
  }

  // Write out the contract type.
  let contractType = '--';
  if (contractCode === 'F') {
    contractType = 'Future';
  } else if (contractCode === 'C') {
    contractType = 'Call';
    cqgShortDatedCodes.forEach((cqgShortDatedCode) => {
      if (contractMetadata?.instrumentGroupName.includes(cqgShortDatedCode)) {
        cropType = 'Short Dated Call';
      }
    });
  } else if (contractCode === 'P') {
    contractType = 'Put';
    cqgShortDatedCodes.forEach((cqgShortDatedCode) => {
      if (contractMetadata?.instrumentGroupName.includes(cqgShortDatedCode)) {
        cropType = 'Short Dated Put';
      }
    });
  }

  let badgeStatus = '';
  const cancelable = getCancelableStatuses().includes(orderStatus.status);

  // Determine the badge color
  switch (orderStatus.status) {
    case OrderStatus_Status.IN_TRANSIT:
    case OrderStatus_Status.WORKING:
    case OrderStatus_Status.APPROVE_REQUIRED:
    case OrderStatus_Status.IN_CANCEL:
    case OrderStatus_Status.IN_MODIFY:
    case OrderStatus_Status.ACTIVEAT:
    case OrderStatus_Status.APPROVED_BY_EXCHANGE:
    case OrderStatus_Status.MATCHED:
    case OrderStatus_Status.PARTIALLY_MATCHED:
      badgeStatus = 'warning';
      break;
    case OrderStatus_Status.REJECTED:
    case OrderStatus_Status.EXPIRED:
    case OrderStatus_Status.CANCELLED:
    case OrderStatus_Status.APPROVE_REJECTED:
    case OrderStatus_Status.DISCONNECTED:
    case OrderStatus_Status.SUSPENDED:
    case OrderStatus_Status.TRADE_BROKEN:
      badgeStatus = 'danger';
      break;
    case OrderStatus_Status.FILLED:
      badgeStatus = 'success';
      break;
    default:
      badgeStatus = 'info';
  }

  const badgeClassName = `badge badge-${badgeStatus}`;

  // Map the maturity date to a readable date
  const maturityMonthYear = contractMetadata?.maturityMonthYear;
  let maturityMonthName = '';
  if (maturityMonthYear) {
    const maturityMonth = maturityMonthYear[0];
    let monthIndex = 0;
    for (let key in Object.keys(FUTURES_MONTH_CODES)) {
      if (FUTURES_MONTH_CODES[key] === maturityMonth) {
        monthIndex = Number(key);
        break;
      }
    }
    maturityMonthName = dayjs().month(monthIndex).format('MMM').toUpperCase() + maturityMonthYear.substring(1);
    // get the month short name from letter.
  }

  // Create a reject message, ignore the limit price for now.
  let rejectMessage = '';
  if (orderStatus.rejectMessage) {
    rejectMessage =
      orderStatus.rejectMessage.indexOf('ExchangeRejectCode') > -1
        ? orderStatus.rejectMessage.split('ExchangeRejectCode')[0]
        : orderStatus.rejectMessage;

    if (rejectMessage.includes("price '")) {
      const rejectMessageFirst = rejectMessage.split("'")[0];
      const rejectMessageLast = rejectMessage.split("price '")[1].split("'")[1];
      rejectMessage = `${rejectMessageFirst}  ${rejectMessageLast}`;
    }
  }

  let tradePrice = '--';

  if (orderStatus.tradePrice) {
    const tradePriceRaw = new Decimal(orderStatus.tradePrice).dividedBy(100).toNumber();
    tradePrice = formatNumber(tradePriceRaw, 5, currency);
  }

  let stopPrice = '--';
  if (orderStatus.order?.scaledStopPrice) {
    if (contractMetadata) {
      const rawStopPrice = new Decimal(orderStatus.order?.scaledStopPrice)
        .times(contractMetadata.correctPriceScale)
        .dividedBy(100)
        .toNumber();
      stopPrice = formatNumber(rawStopPrice, 5, currency);
    }
  }

  let limitPrice = '--';
  if (orderStatus.order?.scaledLimitPrice) {
    const scaledPriceVal = orderStatus.order?.scaledLimitPrice;
    if (contractMetadata) {
      const limitPriceRaw = new Decimal(scaledPriceVal).times(contractMetadata.correctPriceScale).dividedBy(100).toNumber();
      limitPrice = formatNumber(limitPriceRaw, 5, currency);
    }
  }

  const orderType = getOrderTypeLabel(orderStatus.order?.orderType);
  const duration = getOrderDurationTypeLabel(orderStatus.order?.duration);

  const history = useHistory();
  const offsetTradeButton = (
    cqgSymbol: string,
    orderStatus: SimpleOrderStatus,
    size?: null | 'xx-small' | 'x-small' | 'small' | 'medium' | 'large',
    className?: string
  ) => (
    <Button
      themeColor="secondary"
      className={`mt-1 ${className}`}
      size={size}
      onClick={() => {
        let pathname = history.location.pathname;
        let routes = pathname.split('/');
        routes.pop();
        pathname = routes.join('/');
        const contractsQuantity = orderStatus.order!.qty!.significand;
        const buySell = orderStatus.order!.side === 1 ? 2 : 1;
        const frSymbol = CQGSymbolUtils.getFRSymbol(cqgSymbol);
        history.push(`${pathname}/trade/${frSymbol}/${buySell}/${contractsQuantity}`);
      }}
    >
      <b>Offset Trade</b> <FontAwesomeIcon className="pr-2" icon={faCircleArrowRight} style={{ color: '#1162ab' }} />
    </Button>
  );

  const offsetTradeIsPossible =
    orderStatus.status === OrderStatus_Status.FILLED &&
    cqgSymbol &&
    orderStatus.order &&
    orderStatus.order.qty &&
    orderStatus.order.side;

  const shortLongPrefix = orderStatus.order?.side === 1 ? 'Long' : 'Short';

  const onCancelClick = () => {
    setIsCanceling(true);
    CQGHelpers.cancelOrder({ client: cqgContext?.client(), resetClient: cqgService?.resetClient, orderStatus })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsCanceling(false);
      });
  };

  return (
    <div className={`${className} col-12 col-md-6 mb-2 mt-2`}>
      <div className={`card ${isPhone ? ' ms-2 me-2' : ''}`}>
        <h5 className="card-title">
          <span className="title">Order {orderStatus.orderId}</span>{' '}
          <span className={badgeClassName}>{OrderStatusMap[orderStatus.status]}</span>
        </h5>
        <div></div>
        <p className="card-subtitle mb-2 text-muted">{formattedTradeTimestamp}</p>
        <p className="card-text fw-semibold mb-1">{cropName}</p>
        <p className="card-text mb-1">
          <FontAwesomeIcon fixedWidth icon={faReceipt} style={{ color: '#1162ab' }} /> <span className="ms-1">{orderType}</span>
        </p>
        <p className="card-text mb-1">
          <FontAwesomeIcon fixedWidth icon={faCalendar} /> <span className="ms-1">{duration}</span>
        </p>
        {tradePrice !== '--' && <p className="card-text mb-1">Trade Price: {tradePrice}</p>}
        {limitPrice !== '--' && <p className="card-text mb-1">Limit Price: {limitPrice}</p>}
        {stopPrice !== '--' && <p className="card-text mb-1">Stop Price: {stopPrice}</p>}
        <ul className="mb-0">
          <li>
            {maturityMonthYear && maturityMonthName} {contractType}
          </li>
          <li>
            <span className="fw-lighter">{shortLongPrefix}</span>:{' '}
            <span className="fw-normal">{orderStatus.order?.qty?.significand}</span>
          </li>
          {contractCode !== 'F' && (
            <li>
              <span>Strike: {strike}</span>
            </li>
          )}
        </ul>
        {cancelable && <CancelTradeButton isCanceling={isCanceling} onClick={onCancelClick} />}
        {orderStatus.rejectMessage && (
          <p className="card-text mt-2">
            <b>Note:</b> {rejectMessage}
          </p>
        )}
        {offsetTradeIsPossible && offsetTradeButton(cqgSymbol, orderStatus, 'small')}
      </div>
    </div>
  );
}
