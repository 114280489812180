/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { NamedValue } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';
import { ContractMetadata } from './metadata_2';
import { AlgoStrategyDefinition, StrategyDefinition } from './strategy_definition_2';

export const protobufPackage = 'strategy_2';

/** Request for algo strategies definitions. */
export interface AlgoStrategyDefinitionRequest {
  /** List of algo strategies to request definitions (abbreviations). */
  algoStrategies: string[];
}

/** Report with algo strategies definitions. */
export interface AlgoStrategyDefinitionReport {
  /** List of algo strategy definitions for given abbreviations. */
  algoStrategyDefinitions: AlgoStrategyDefinition[];
}

/** Request to define a strategy as a contract. */
export interface StrategyDefinitionRequest {
  /** Strategy to define. */
  strategyDefinition: StrategyDefinition | undefined;
  /**
   * Account ID in CQG trade routing system.
   * Some exchanges require an account ID in the process of defining a UDS. For those exchanges this is a
   * required field.
   */
  accountId: number;
  /**
   * Exchange specific extra attributes.
   * List of supported attributes can be found in document ExchangeExtraAttributesForStrategyDefinition.adoc.
   */
  exchangeExtraAttributes: NamedValue[];
}

/** Report of the contract metadata assigned to the strategy. */
export interface StrategyDefinitionReport {
  /**
   * Assigned strategy metadata.
   * Note: In case of exchange strategies field 'strategy_definition' in contract_metadata always matches the definition
   * received from the exchange, therefore it can be slightly different than field 'strategy_definition' in
   * StrategyDefinitionRequest.
   * Example: user wants to define UDS(A + B - C), but similar UDS(A - C + B) already exists on exchange.
   */
  contractMetadata: ContractMetadata | undefined;
}

/** Describes a leg order fill that contributes to a synthetic strategy order fill. */
export interface StrategyLegFill {
  /** Order id for the leg order (the original OrderStatus.order_id) */
  legOrderId: string;
  /** Leg_execution_id of the corresponding Trade message for the leg order fill. */
  legExecutionId: string;
  /**
   * Quantity of the leg order fill used for this synthetic strategy order fill,
   * decimal (fractional quantities are possible for legs with non-integer quantity ratio).
   * This is correct quantity (ContractMetadata.volume_scale must not be applied).
   */
  qty: number;
}

/** Details of hung quantity on a synthetic strategy order or existing hang resolution. */
export interface SyntheticHang {
  /** Unique hang identifier supplied by server. */
  hangId: number;
  /**
   * Note: use hung_qty field instead.
   *
   * @deprecated
   */
  uint32HungQty: number;
  /** Quantity (of the synthetic strategy order) hung, 0 indicates that hang is resolved. */
  hungQty: Decimal | undefined;
  /**
   * Orders participating in a synthetic strategy order hang.
   * Note: this is the original OrderStatus.order_id.
   */
  orderIds: string[];
}

/** Synthetic strategy order's outright properties. */
export interface SyntheticStrategyProperties {
  /** Synthetic strategy trading parameters. */
  strategyTradingParameters: StrategyTradingParameters | undefined;
  /**
   * Parent synthetic order id that led to this order's (automatic) placement;
   * allows client to show the link to user.
   * Note: this is the original synthetic order's ID (OrderStatus.order_id), not affected by any modifications.
   */
  originOrderId: string;
  /**
   * Id of the originally placed by trader root synthetic strategy order
   * (The same as origin_order_id for the root immediate legs).
   */
  rootOrderId: string;
  /**
   * Node index of the synthetic order (specified by root_order_id);
   * allows client to associate this order with a particular node of the synthetic strategy
   * (either a leg or a nested strategy).
   * LegDefinition.node_index or StrategyDefinition.node_index value.
   */
  nodeIndex: number;
  /**
   * When released is set to true, it indicates that the synthetic strategy leg has been released for manual control.
   * I.e. it is no longer linked to the synthetic strategy order.
   */
  released: boolean;
}

/** Defines how a placed synthetic strategy order will be executed. */
export interface StrategyTradingParameters {
  /** Parameters applied only to a MKT order. */
  marketOrderParameters: StrategyMarketOrderParameters[];
  /** Parameters applied only to a LMT order. */
  limitOrderParameters: StrategyLimitOrderParameters[];
  /** Parameters applied only to a STP order. */
  stopOrderParameters: StrategyStopOrderParameters[];
  /** Parameters applied only to an aggregation strategy. */
  aggregationParameters: StrategyAggregationParameters[];
  /** Individual strategy leg parameters. */
  legParameters: StrategyOrderLegParameters[];
}

/** Parameters associated with a concrete leg. */
export interface StrategyOrderLegParameters {
  /**
   * Node index in the strategy definition the parameters are related to
   * (StrategyDefinition.node_index or LegDefinition.node_index value).
   */
  nodeIndex: number;
  /** Account id to place an order on the leg. If not set, the account id of the order used instead. */
  accountId: number;
  /**
   * Specifies what the order is intended to do: open or close (reduce) a position. One of OpenCloseInstruction enum.
   * Applicable only if ContractMetadata.position_tracking
   * is either LONG_SHORT_WITH_IMPLIED_CLOSE or LONG_SHORT_WITH_EXPLICIT_CLOSE.
   * If not set, the open/close instruction of the order used instead.
   */
  openCloseInstruction: number;
  /**
   * Speculation type of the leg order. One of SpeculationType enum.
   * If not set, the speculation type of the order used instead.
   */
  speculationType: number;
}

/** Synthetic strategy MKT order parameters */
export interface StrategyMarketOrderParameters {
  /** Node index in the strategy definition the parameters are related to (StrategyDefinition.node_index). */
  nodeIndex: number;
  /**
   * For Iceberg orders:
   * 'true' means replenish qty on the filled leg immediately upon receiving primary fill;
   * 'false' means to 'freeze' the filled leg (don't add any more qty to it) until SS order
   *         is filled or its qty changes for some other reason, e.g. replace request.
   */
  replenishOnPrimaryFill: boolean;
}

/** LMT order parameters of the root or a nested strategy. */
export interface StrategyLimitOrderParameters {
  /** Node index in the strategy definition the parameters are related to (StrategyDefinition.node_index value). */
  nodeIndex: number;
  /**
   * Defines whether replenish quantity on primary fill or not.
   * For Iceberg orders:
   *  'true' means replenish quantity on the filled leg immediately upon receiving primary fill;
   *  'false' means to 'freeze' the filled leg (don't add any more qty to it) until SS order
   *          is filled or its quantity changes for some other reason, e.g. replace request.
   */
  replenishOnPrimaryFill: boolean;
  /**
   * Defines mode of overfill handling for this limit SS order. One of OverfillMode enums.
   * AUTOMATIC_HEDGING is default.
   */
  overfillMode: number;
  /**
   * Defines sizes of what leg orders must be aligned to strategy lots.One of AlignToStrategyLots enums.
   * NONE is default.
   */
  alignToStrategyLots: number;
  /**
   * The way prices for secondary leg orders are calculated. One of PricingMethod enums.
   * PRICING_METHOD_AVERAGE_ORDER_PRICE is default.
   */
  pricingMethod: number;
  /** Price shifting parameters. */
  priceShifting: PriceShifting | undefined;
  /** Parameters for strategy legs. */
  legDescriptions: LimitOrderLegDescription[];
}

/** Mode of overfill handling. */
export enum StrategyLimitOrderParameters_OverfillMode {
  /** OVERFILL_MODE_MANUAL - Overfills are allowed and managed by trader. */
  OVERFILL_MODE_MANUAL = 1,
  /** OVERFILL_MODE_AUTOMATIC_HEDGING - Overfills are allowed and must be hedged by the server according to strategy quantity ratios. */
  OVERFILL_MODE_AUTOMATIC_HEDGING = 2,
  /** OVERFILL_MODE_AVOID_OVERFILLS - Minimize probability of overfills. */
  OVERFILL_MODE_AVOID_OVERFILLS = 3,
  UNRECOGNIZED = -1,
}

export function strategyLimitOrderParameters_OverfillModeFromJSON(object: any): StrategyLimitOrderParameters_OverfillMode {
  switch (object) {
    case 1:
    case 'OVERFILL_MODE_MANUAL':
      return StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_MANUAL;
    case 2:
    case 'OVERFILL_MODE_AUTOMATIC_HEDGING':
      return StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_AUTOMATIC_HEDGING;
    case 3:
    case 'OVERFILL_MODE_AVOID_OVERFILLS':
      return StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_AVOID_OVERFILLS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyLimitOrderParameters_OverfillMode.UNRECOGNIZED;
  }
}

export function strategyLimitOrderParameters_OverfillModeToJSON(object: StrategyLimitOrderParameters_OverfillMode): string {
  switch (object) {
    case StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_MANUAL:
      return 'OVERFILL_MODE_MANUAL';
    case StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_AUTOMATIC_HEDGING:
      return 'OVERFILL_MODE_AUTOMATIC_HEDGING';
    case StrategyLimitOrderParameters_OverfillMode.OVERFILL_MODE_AVOID_OVERFILLS:
      return 'OVERFILL_MODE_AVOID_OVERFILLS';
    case StrategyLimitOrderParameters_OverfillMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Defines sizes of what leg orders must be aligned to strategy lots (opposite to work proportionally). */
export enum StrategyLimitOrderParameters_AlignToStrategyLots {
  /** ALIGN_TO_STRATEGY_LOTS_NONE - Both primary and secondary leg orders could work its sizes proportionally (without aligning it to strategy lot). */
  ALIGN_TO_STRATEGY_LOTS_NONE = 1,
  /** ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY - Secondary leg orders must align its size to strategy lots. */
  ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY = 2,
  /** ALIGN_TO_STRATEGY_LOTS_ALL - Both primary and secondary leg orders align its sizes to strategy lots. */
  ALIGN_TO_STRATEGY_LOTS_ALL = 3,
  UNRECOGNIZED = -1,
}

export function strategyLimitOrderParameters_AlignToStrategyLotsFromJSON(
  object: any
): StrategyLimitOrderParameters_AlignToStrategyLots {
  switch (object) {
    case 1:
    case 'ALIGN_TO_STRATEGY_LOTS_NONE':
      return StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_NONE;
    case 2:
    case 'ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY':
      return StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY;
    case 3:
    case 'ALIGN_TO_STRATEGY_LOTS_ALL':
      return StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_ALL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyLimitOrderParameters_AlignToStrategyLots.UNRECOGNIZED;
  }
}

export function strategyLimitOrderParameters_AlignToStrategyLotsToJSON(
  object: StrategyLimitOrderParameters_AlignToStrategyLots
): string {
  switch (object) {
    case StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_NONE:
      return 'ALIGN_TO_STRATEGY_LOTS_NONE';
    case StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY:
      return 'ALIGN_TO_STRATEGY_LOTS_SECONDARY_ONLY';
    case StrategyLimitOrderParameters_AlignToStrategyLots.ALIGN_TO_STRATEGY_LOTS_ALL:
      return 'ALIGN_TO_STRATEGY_LOTS_ALL';
    case StrategyLimitOrderParameters_AlignToStrategyLots.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** The way prices for secondary orders are calculated. */
export enum StrategyLimitOrderParameters_PricingMethod {
  /** PRICING_METHOD_INDEPENDENT_LOT_PRICE - Respect each strategy lot price independently. */
  PRICING_METHOD_INDEPENDENT_LOT_PRICE = 1,
  /** PRICING_METHOD_AVERAGE_ORDER_PRICE - Respect average strategy order price. */
  PRICING_METHOD_AVERAGE_ORDER_PRICE = 2,
  UNRECOGNIZED = -1,
}

export function strategyLimitOrderParameters_PricingMethodFromJSON(object: any): StrategyLimitOrderParameters_PricingMethod {
  switch (object) {
    case 1:
    case 'PRICING_METHOD_INDEPENDENT_LOT_PRICE':
      return StrategyLimitOrderParameters_PricingMethod.PRICING_METHOD_INDEPENDENT_LOT_PRICE;
    case 2:
    case 'PRICING_METHOD_AVERAGE_ORDER_PRICE':
      return StrategyLimitOrderParameters_PricingMethod.PRICING_METHOD_AVERAGE_ORDER_PRICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyLimitOrderParameters_PricingMethod.UNRECOGNIZED;
  }
}

export function strategyLimitOrderParameters_PricingMethodToJSON(object: StrategyLimitOrderParameters_PricingMethod): string {
  switch (object) {
    case StrategyLimitOrderParameters_PricingMethod.PRICING_METHOD_INDEPENDENT_LOT_PRICE:
      return 'PRICING_METHOD_INDEPENDENT_LOT_PRICE';
    case StrategyLimitOrderParameters_PricingMethod.PRICING_METHOD_AVERAGE_ORDER_PRICE:
      return 'PRICING_METHOD_AVERAGE_ORDER_PRICE';
    case StrategyLimitOrderParameters_PricingMethod.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** STP order parameters of the root or a nested strategy. */
export interface StrategyStopOrderParameters {
  /** Node index in the strategy definition the parameters are related to (StrategyDefinition.node_index value). */
  nodeIndex: number;
  /** The side of strategy price that STP orders watch for. One of StopSide enums. */
  stopSide: number;
  /**
   * For Iceberg orders:
   *  'true' means replenish qty on the filled leg immediately upon receiving primary fill;
   *  'false' means to 'freeze' the filled leg (don't add any more qty to it) until SS order
   *          is filled or its qty changes for some other reason, e.g. replace request.
   */
  replenishOnPrimaryFill: boolean;
}

/** Enumeration of sides of strategy price that STP orders watch for. */
export enum StrategyStopOrderParameters_StopSide {
  /** STOP_SIDE_BID - Bid price of strategy must be matched against STP price. */
  STOP_SIDE_BID = 1,
  /** STOP_SIDE_ASK - Ask price of strategy must be matched against STP price. */
  STOP_SIDE_ASK = 2,
  UNRECOGNIZED = -1,
}

export function strategyStopOrderParameters_StopSideFromJSON(object: any): StrategyStopOrderParameters_StopSide {
  switch (object) {
    case 1:
    case 'STOP_SIDE_BID':
      return StrategyStopOrderParameters_StopSide.STOP_SIDE_BID;
    case 2:
    case 'STOP_SIDE_ASK':
      return StrategyStopOrderParameters_StopSide.STOP_SIDE_ASK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyStopOrderParameters_StopSide.UNRECOGNIZED;
  }
}

export function strategyStopOrderParameters_StopSideToJSON(object: StrategyStopOrderParameters_StopSide): string {
  switch (object) {
    case StrategyStopOrderParameters_StopSide.STOP_SIDE_BID:
      return 'STOP_SIDE_BID';
    case StrategyStopOrderParameters_StopSide.STOP_SIDE_ASK:
      return 'STOP_SIDE_ASK';
    case StrategyStopOrderParameters_StopSide.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface StrategyAggregationParameters {
  /** Node index in the strategy definition the parameters are related to (StrategyDefinition.node_index). */
  nodeIndex: number;
  /** Mode of overfill handling. One of AggrOverfillMode enums. */
  overfillMode: number;
  /** Price shifting parameters. */
  priceShifting: PriceShifting | undefined;
  /** Aggregation legs parameters. */
  patternLegs: AggregationLegDescription[];
}

/** Mode of overfill handling. */
export enum StrategyAggregationParameters_AggrOverfillMode {
  /**
   * AGGR_OVERFILL_MODE_AVOID_OVERFILLS - Minimize probability of overfills: don't place market taking LMT
   * until existing market making LMT on other legs have quantity reduction acknowledge.
   */
  AGGR_OVERFILL_MODE_AVOID_OVERFILLS = 1,
  /**
   * AGGR_OVERFILL_MODE_ACCEPT_OVERFILL - Place market taking LMTs and then attempt to reduce market making LMT quantity on other legs.
   * There is a window of time where leg orders may be working that total more than the strategy order quantity.
   */
  AGGR_OVERFILL_MODE_ACCEPT_OVERFILL = 2,
  /**
   * AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION - Place market taking LMT and wait to reduce market making LMT qty on other legs until
   * the market taking LMT are filled (or cancelled after timeout).
   * There is a much larger window of time where leg orders
   * may be working that total more than the strategy order qty,
   * but market making LMT don't lose their place in queue.
   */
  AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION = 3,
  UNRECOGNIZED = -1,
}

export function strategyAggregationParameters_AggrOverfillModeFromJSON(
  object: any
): StrategyAggregationParameters_AggrOverfillMode {
  switch (object) {
    case 1:
    case 'AGGR_OVERFILL_MODE_AVOID_OVERFILLS':
      return StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_AVOID_OVERFILLS;
    case 2:
    case 'AGGR_OVERFILL_MODE_ACCEPT_OVERFILL':
      return StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_ACCEPT_OVERFILL;
    case 3:
    case 'AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION':
      return StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyAggregationParameters_AggrOverfillMode.UNRECOGNIZED;
  }
}

export function strategyAggregationParameters_AggrOverfillModeToJSON(
  object: StrategyAggregationParameters_AggrOverfillMode
): string {
  switch (object) {
    case StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_AVOID_OVERFILLS:
      return 'AGGR_OVERFILL_MODE_AVOID_OVERFILLS';
    case StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_ACCEPT_OVERFILL:
      return 'AGGR_OVERFILL_MODE_ACCEPT_OVERFILL';
    case StrategyAggregationParameters_AggrOverfillMode.AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION:
      return 'AGGR_OVERFILL_MODE_PRESERVE_QUEUE_POSITION';
    case StrategyAggregationParameters_AggrOverfillMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Description of aggregation strategy legs. */
export interface AggregationLegDescription {
  /** Node index in the strategy definition the parameters are related to (LegDefinition.node_index value). */
  nodeIndex: number;
  /**
   * Note: use working_threshold instead.
   *
   * @deprecated
   */
  uint32WorkingThreshold: number;
  /**
   * Threshold value for available quotes quantity to be considered
   * (the quantities under this value are ignored).
   */
  workingThreshold: Decimal | undefined;
  /** The leg is executed in market taking mode. */
  marketTakingParameters: MarketTakingParameters | undefined;
  /** The leg is executed in market making mode. */
  marketMakingParameters: MarketMakingParameters | undefined;
}

/** Parameters for strategy price change depending on filled strategy size. */
export interface PriceShifting {
  /** step (in ticks) of each price change. */
  priceStep: number;
  /**
   * Note: use filled_qty_delta instead.
   *
   * @deprecated
   */
  uint32FilledQtyDelta: number;
  /** Size of strategy order that must be filled to perform next change of strategy order price. */
  filledQtyDelta: Decimal | undefined;
}

/** LMT order parameters for a leg. */
export interface LimitOrderLegDescription {
  /**
   * Node index in the strategy definition the parameters are related to
   * (StrategyDefinition.node_index or LegDefinition.node_index value).
   */
  nodeIndex: number;
  /**
   * All the attributes below are mutually exclusive.
   * Sets the leg as working and place LMT orders.
   */
  primaryOrderLimit: PrimaryOrdersLimit | undefined;
  /** Sets the leg as working and place sniper orders. */
  primaryOrderSniper: PrimaryOrdersSniper | undefined;
  /** Sets the leg as not working and place LMT orders. */
  secondaryOrdersLimit: SecondaryOrdersLimit | undefined;
  /** Sets the leg as not working and place MKT orders. */
  secondaryOrdersMarket: SecondaryOrdersMarket | undefined;
  /** Sets the leg as not working and place payup orders. */
  secondaryOrdersPayup: SecondaryOrdersPayUp | undefined;
  /** Sets the leg as not working and place trailing orders. */
  secondaryOrdersTrailing: SecondaryOrdersTrailing | undefined;
  /**
   * Indicates how many lots (in fractions) should be filled before the second leg order is placed.
   * Allowed range 0-1.
   * Default is off, the secondary leg placed after all lots are filled on the primary leg.
   */
  proportionalExecutionRatio: number;
  /**
   * Determines the size of working orders based on a percentage of the resting volume
   * available in the queue of the monitored leg.
   * Allowed range 0-1000.
   * Default is 1.
   */
  volumeMultiplier: number;
  /**
   * Note: use work_threshold field instead.
   *
   * @deprecated
   */
  uint32WorkThreshold: number;
  /**
   * Tells the system to work the order if and only if
   * the available quantity in the monitored leg is and remains greater than this value.
   * Default is 0.
   */
  workThreshold: Decimal | undefined;
}

/** Parameters of LMT orders on a leg which is currently working. */
export interface PrimaryOrdersLimit {
  /**
   * Note: use min_qty_increment field instead.
   *
   * @deprecated
   */
  uint32MinQtyIncrement: number;
  /**
   * Place an order only if the quantity available in the monitored leg is at least this much and
   * to modify a working order only if the quantity available in the monitored leg increases by at least this much.
   * Default is 1.
   */
  minQtyIncrement: Decimal | undefined;
  /**
   * Queue holders (stacked orders) are additional orders placed in the queue
   * at specified price levels away from the initial working order.
   * Sets the minimum number of orders to include in the queue.
   * Allowed values: 1-10, default is 1.
   */
  queueHoldersMinSize: number;
  /**
   * Sets the maximum number of orders to include in the queue.
   * Allowed values: 1-10 and not less than queue_holders_min_size, default is 1.
   */
  queueHoldersMaxSize: number;
  /**
   * Indicates how many price levels (ticks) to skip between orders in the queue.
   * Allowed range is 1-5, default is 1.
   */
  queueHoldersDistance: number;
  /**
   * Indicates how far the market should run
   * before maximizing size of a recently placed order that is now part of the group of stacked orders.
   * Must be less than queue_holders_min_size, default is 0.
   */
  orderSizeRestoreThreshold: number;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty: number;
  /** Primary Iceberg order visible size in lots. */
  visibleQty: Decimal | undefined;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty: number;
  /** Primary Iceberg order min visible size in lots. */
  minVisibleQty: Decimal | undefined;
  /**
   * Maximal distance between the market price and the old/new limit price of working primary leg
   * orders, which allows modifications of these orders' prices(in ticks).
   * Zero value is acceptable and means that the orders are modified at any distance.
   * 0 is default.
   */
  workingOrdersPriceRange: number;
  /**
   * Minimal difference between the old and the new limit prices of working
   * primary leg orders, which allows modifications of these orders' prices (in ticks).
   */
  minPriceChange: number;
  /** Maximum number of leg orders per price level. */
  primaryOrdersFifoQueueSize: number;
  /**
   * Period of updates to the working leg in milliseconds, such that every N ms,
   * the leg is put where it ought to be placed based upon current prices.
   * Default is to use the server settings.
   */
  forceUpdatePeriod: number;
  /** Flag that shows whether observed markets must be used for primary orders size calculation or not. */
  ignoreObservedMarkets: boolean;
  /**
   * Specifies (in ticks) maximum distance
   * primary order price could be moved from target one while creating best bid/ask.
   */
  maxDimming: number;
  /**
   * Mode of handling primary leg orders with target price far from market. One of FarFromMarketMode enums.
   * Default is to use the server settings.
   */
  farFromMarketMode: number;
  /**
   * Defines if and where server must work quantity that cannot lean onto current best bid/offer.
   * Allowed range 0-9, default is to use the server setting.
   */
  ticksAwayToWork: number;
  /** How to work orders considering DOM data. One of DOMUsageMode enums. TOP_ONLY is default. */
  domUsage: number;
  /**
   * Work up to this number of orders to get required volume if dom_usage is DOM_USAGE_MODE_MULTILEVELS.
   * Allowed range 1-10, default is 1.
   */
  domMultilevelQty: number;
}

/** Mode of working primary orders with price far from market. */
export enum PrimaryOrdersLimit_FarFromMarketMode {
  /** FAR_FROM_MARKET_MODE_NO_ORDERS - Primary orders will be canceled (won't be placed) if a new target price is far from market. */
  FAR_FROM_MARKET_MODE_NO_ORDERS = 1,
  /** FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY - Primary orders will be moved away from market (will be left untouched) if a new target price is far from market. */
  FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY = 2,
  /** FAR_FROM_MARKET_MODE_DONT_MOVE - Primary orders will be left untouched if current and a new target price are both far from market. */
  FAR_FROM_MARKET_MODE_DONT_MOVE = 3,
  UNRECOGNIZED = -1,
}

export function primaryOrdersLimit_FarFromMarketModeFromJSON(object: any): PrimaryOrdersLimit_FarFromMarketMode {
  switch (object) {
    case 1:
    case 'FAR_FROM_MARKET_MODE_NO_ORDERS':
      return PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_NO_ORDERS;
    case 2:
    case 'FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY':
      return PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY;
    case 3:
    case 'FAR_FROM_MARKET_MODE_DONT_MOVE':
      return PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_DONT_MOVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PrimaryOrdersLimit_FarFromMarketMode.UNRECOGNIZED;
  }
}

export function primaryOrdersLimit_FarFromMarketModeToJSON(object: PrimaryOrdersLimit_FarFromMarketMode): string {
  switch (object) {
    case PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_NO_ORDERS:
      return 'FAR_FROM_MARKET_MODE_NO_ORDERS';
    case PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY:
      return 'FAR_FROM_MARKET_MODE_MOVE_AWAY_ONLY';
    case PrimaryOrdersLimit_FarFromMarketMode.FAR_FROM_MARKET_MODE_DONT_MOVE:
      return 'FAR_FROM_MARKET_MODE_DONT_MOVE';
    case PrimaryOrdersLimit_FarFromMarketMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Modes of handling DOM data. */
export enum PrimaryOrdersLimit_DOMUsageMode {
  /** DOM_USAGE_MODE_TOP_ONLY - Use only top levels of DOMs and produce a single level of primary orders. */
  DOM_USAGE_MODE_TOP_ONLY = 1,
  /** DOM_USAGE_MODE_MULTILEVELS - Use multiple levels of DOMs and produce multiple levels of primary orders. */
  DOM_USAGE_MODE_MULTILEVELS = 2,
  /**
   * DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE - Produce a single level of primary orders of the full quantity
   * corresponding to the worst DOM prices with a sufficient cumulative quantity.
   */
  DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE = 3,
  /**
   * DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE - Produce a single level of primary orders of the full quantity corresponding
   * to average DOM prices with a sufficient cumulative quantity.
   */
  DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE = 4,
  UNRECOGNIZED = -1,
}

export function primaryOrdersLimit_DOMUsageModeFromJSON(object: any): PrimaryOrdersLimit_DOMUsageMode {
  switch (object) {
    case 1:
    case 'DOM_USAGE_MODE_TOP_ONLY':
      return PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_TOP_ONLY;
    case 2:
    case 'DOM_USAGE_MODE_MULTILEVELS':
      return PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_MULTILEVELS;
    case 3:
    case 'DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE':
      return PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE;
    case 4:
    case 'DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE':
      return PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PrimaryOrdersLimit_DOMUsageMode.UNRECOGNIZED;
  }
}

export function primaryOrdersLimit_DOMUsageModeToJSON(object: PrimaryOrdersLimit_DOMUsageMode): string {
  switch (object) {
    case PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_TOP_ONLY:
      return 'DOM_USAGE_MODE_TOP_ONLY';
    case PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_MULTILEVELS:
      return 'DOM_USAGE_MODE_MULTILEVELS';
    case PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE:
      return 'DOM_USAGE_MODE_FULL_SIZE_WORST_PRICE';
    case PrimaryOrdersLimit_DOMUsageMode.DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE:
      return 'DOM_USAGE_MODE_FULL_SIZE_AVERAGE_PRICE';
    case PrimaryOrdersLimit_DOMUsageMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Sniper rule means don't place any primary order.
 * It just watches markets waiting for the moment when the strategy order's LMT price is available.
 * Then secondary orders on all legs must be placed.
 */
export interface PrimaryOrdersSniper {
  /** One of LegDuration enums. */
  legDuration: number;
}

/** Duration for sniper leg orders */
export enum PrimaryOrdersSniper_LegDuration {
  LEG_DURATION_DAY = 1,
  /** LEG_DURATION_FAK - Fill and kill */
  LEG_DURATION_FAK = 2,
  /** LEG_DURATION_FOK - Fill or kill */
  LEG_DURATION_FOK = 3,
  UNRECOGNIZED = -1,
}

export function primaryOrdersSniper_LegDurationFromJSON(object: any): PrimaryOrdersSniper_LegDuration {
  switch (object) {
    case 1:
    case 'LEG_DURATION_DAY':
      return PrimaryOrdersSniper_LegDuration.LEG_DURATION_DAY;
    case 2:
    case 'LEG_DURATION_FAK':
      return PrimaryOrdersSniper_LegDuration.LEG_DURATION_FAK;
    case 3:
    case 'LEG_DURATION_FOK':
      return PrimaryOrdersSniper_LegDuration.LEG_DURATION_FOK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PrimaryOrdersSniper_LegDuration.UNRECOGNIZED;
  }
}

export function primaryOrdersSniper_LegDurationToJSON(object: PrimaryOrdersSniper_LegDuration): string {
  switch (object) {
    case PrimaryOrdersSniper_LegDuration.LEG_DURATION_DAY:
      return 'LEG_DURATION_DAY';
    case PrimaryOrdersSniper_LegDuration.LEG_DURATION_FAK:
      return 'LEG_DURATION_FAK';
    case PrimaryOrdersSniper_LegDuration.LEG_DURATION_FOK:
      return 'LEG_DURATION_FOK';
    case PrimaryOrdersSniper_LegDuration.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** LMT secondary orders parameters. */
export interface SecondaryOrdersLimit {
  /** Offset to adjust secondary order price at the moment of place. Default is 0. */
  initialOffset: number;
}

/** MKT secondary orders parameters. */
export interface SecondaryOrdersMarket {}

/** Pay up secondary orders parameters. */
export interface SecondaryOrdersPayUp {
  /** Offset to modify the price of a hung secondary order. */
  offset: number;
  /** Offset to adjust the secondary order price at the moment of placement. */
  initialOffset: number;
  /**
   * One of the conditions below must be set.
   * Condition to check that pointed time interval is passed.
   */
  conditionTimeout: number;
  /**
   * Note: use condition_absolute_volume field instead.
   *
   * @deprecated
   */
  uint32ConditionAbsoluteVolume: number;
  /** Condition to check the opposite market volume. */
  conditionAbsoluteVolume: Decimal | undefined;
  /** Condition to check the ratio between the opposite side and secondary order sizes. */
  conditionOppositeVolumeRatio: number;
  /** Condition to check the opposite side and the local side volumes ratio. */
  conditionBbaVolumeRatio: number;
}

/** Trailing secondary orders parameters. */
export interface SecondaryOrdersTrailing {
  /** Trailing offset. */
  trailingOffset: number;
  /** Peg that price must follow. One of Order.TrailingPeg enum. */
  trailingPeg: number;
  /** Offset to adjust secondary order price at the moment of place. */
  initialOffset: number;
  /** Maximum distance to trail away from the initial price. */
  maxTrail: number;
  /**
   * Note: use condition_absolute_volume field instead.
   *
   * @deprecated
   */
  uint32ConditionAbsoluteVolume: number;
  /** Condition that checks the opposite market volume. */
  conditionAbsoluteVolume: Decimal | undefined;
  /** Condition that checks relation between opposite side and secondary order sizes. */
  conditionOppositeVolumeRatio: number;
  /** Condition that checks the opposite side and the local side volumes ratio. */
  conditionBbaVolumeRatio: number;
}

/**
 * Aggregation market taking mode:
 * the order is held on the server until the price becomes available in at least one market;
 * at which time, the server sends an order to the exchange.
 */
export interface MarketTakingParameters {
  /** Allocation percent (in range 0...1) for the leg (must total 100% among all legs). */
  allocationPercent: number;
  /**
   * Time period (in ms) that the order can work (after exchange ack)
   * before the server considers it timed out and cancels it (0 means 'never',
   * leaves order working until filled, cancelled, or expired).
   */
  workingTimeout: number;
  /** Type of order placed for the leg. One of MarketTakingLimitOrderType enums. */
  orderType: number;
}

/** Type of order placed for the leg. */
export enum MarketTakingParameters_MarketTakingLimitOrderType {
  MARKET_TAKING_LIMIT_ORDER_TYPE_LMT = 1,
  MARKET_TAKING_LIMIT_ORDER_TYPE_MKT = 2,
  UNRECOGNIZED = -1,
}

export function marketTakingParameters_MarketTakingLimitOrderTypeFromJSON(
  object: any
): MarketTakingParameters_MarketTakingLimitOrderType {
  switch (object) {
    case 1:
    case 'MARKET_TAKING_LIMIT_ORDER_TYPE_LMT':
      return MarketTakingParameters_MarketTakingLimitOrderType.MARKET_TAKING_LIMIT_ORDER_TYPE_LMT;
    case 2:
    case 'MARKET_TAKING_LIMIT_ORDER_TYPE_MKT':
      return MarketTakingParameters_MarketTakingLimitOrderType.MARKET_TAKING_LIMIT_ORDER_TYPE_MKT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MarketTakingParameters_MarketTakingLimitOrderType.UNRECOGNIZED;
  }
}

export function marketTakingParameters_MarketTakingLimitOrderTypeToJSON(
  object: MarketTakingParameters_MarketTakingLimitOrderType
): string {
  switch (object) {
    case MarketTakingParameters_MarketTakingLimitOrderType.MARKET_TAKING_LIMIT_ORDER_TYPE_LMT:
      return 'MARKET_TAKING_LIMIT_ORDER_TYPE_LMT';
    case MarketTakingParameters_MarketTakingLimitOrderType.MARKET_TAKING_LIMIT_ORDER_TYPE_MKT:
      return 'MARKET_TAKING_LIMIT_ORDER_TYPE_MKT';
    case MarketTakingParameters_MarketTakingLimitOrderType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Aggregation market making mode: the order is sent immediately to the exchanges based on the allocation percent.
 * The server then manages the orders to get them filled as quickly as possible at the given price.
 */
export interface MarketMakingParameters {
  /**
   * Allocation percent (in range 0...1) for the leg (doesn't have to total 100% allocation among all legs).
   * Default is 0, the market making mode is turned off for the leg.
   */
  allocationPercent: number;
  /**
   * Note: use visible_qty instead.
   *
   * @deprecated
   */
  uint32VisibleQty: number;
  /**
   * Note: use min_visible_qty instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty: number;
  /**
   * Note: use native_visible_qty instead.
   *
   * @deprecated
   */
  uint32NativeVisibleQty: number;
  /** Visible size in lots. */
  visibleQty: Decimal | undefined;
  /** Min visible size in lots. */
  minVisibleQty: Decimal | undefined;
  /** Visible size of native Iceberg. */
  nativeVisibleQty: Decimal | undefined;
}

/**
 * Request to resolve previously reported hung quantity on a synthetic strategy order by
 * 1) placing orders to offset filled leg position(s) at current mkt price(s) and (simultaneously)
 * 2) cancelling the hung quantity.
 */
export interface SyntheticLiquidate {
  /** Id of an account that is used to place an order. */
  accountId: number;
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** Id of the hang from the SyntheticHang message. */
  hangId: number;
  /**
   * Client side time when a request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
}

/**
 * Request to resolve previously reported hung quantity on a synthetic strategy order by
 * 1) placing LMT orders to offset filled leg position(s) at their fill price(s) and (simultaneously)
 * 2) cancelling the hung quantity.
 */
export interface SyntheticScratch {
  /** Id of an account that is used to place an order. */
  accountId: number;
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** Id of the hang from the SyntheticHang message. */
  hangId: number;
  /**
   * Client side time when a request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
}

/**
 * Modify an order to get an immediate fill
 * (on either the entire order or previously reported hanging quantity on a synthetic strategy order).
 */
export interface GoMarket {
  /** Id of an account that is used to place an order. */
  accountId: number;
  /** Order id assigned by the server after the last modification. */
  orderId: string;
  /**
   * Id of the hang from the SyntheticHang message.
   * This is a required field for a synthetic strategy order.
   */
  hangId: number;
  /**
   * Client side time when a request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
}

function createBaseAlgoStrategyDefinitionRequest(): AlgoStrategyDefinitionRequest {
  return { algoStrategies: [] };
}

export const AlgoStrategyDefinitionRequest = {
  encode(message: AlgoStrategyDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.algoStrategies) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlgoStrategyDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlgoStrategyDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.algoStrategies.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AlgoStrategyDefinitionRequest {
    return {
      algoStrategies: Array.isArray(object?.algoStrategies) ? object.algoStrategies.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: AlgoStrategyDefinitionRequest): unknown {
    const obj: any = {};
    if (message.algoStrategies) {
      obj.algoStrategies = message.algoStrategies.map((e) => e);
    } else {
      obj.algoStrategies = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlgoStrategyDefinitionRequest>, I>>(base?: I): AlgoStrategyDefinitionRequest {
    return AlgoStrategyDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AlgoStrategyDefinitionRequest>, I>>(object: I): AlgoStrategyDefinitionRequest {
    const message = createBaseAlgoStrategyDefinitionRequest();
    message.algoStrategies = object.algoStrategies?.map((e) => e) || [];
    return message;
  },
};

function createBaseAlgoStrategyDefinitionReport(): AlgoStrategyDefinitionReport {
  return { algoStrategyDefinitions: [] };
}

export const AlgoStrategyDefinitionReport = {
  encode(message: AlgoStrategyDefinitionReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.algoStrategyDefinitions) {
      AlgoStrategyDefinition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlgoStrategyDefinitionReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlgoStrategyDefinitionReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.algoStrategyDefinitions.push(AlgoStrategyDefinition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AlgoStrategyDefinitionReport {
    return {
      algoStrategyDefinitions: Array.isArray(object?.algoStrategyDefinitions)
        ? object.algoStrategyDefinitions.map((e: any) => AlgoStrategyDefinition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AlgoStrategyDefinitionReport): unknown {
    const obj: any = {};
    if (message.algoStrategyDefinitions) {
      obj.algoStrategyDefinitions = message.algoStrategyDefinitions.map((e) =>
        e ? AlgoStrategyDefinition.toJSON(e) : undefined
      );
    } else {
      obj.algoStrategyDefinitions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AlgoStrategyDefinitionReport>, I>>(base?: I): AlgoStrategyDefinitionReport {
    return AlgoStrategyDefinitionReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AlgoStrategyDefinitionReport>, I>>(object: I): AlgoStrategyDefinitionReport {
    const message = createBaseAlgoStrategyDefinitionReport();
    message.algoStrategyDefinitions = object.algoStrategyDefinitions?.map((e) => AlgoStrategyDefinition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyDefinitionRequest(): StrategyDefinitionRequest {
  return { strategyDefinition: undefined, accountId: 0, exchangeExtraAttributes: [] };
}

export const StrategyDefinitionRequest = {
  encode(message: StrategyDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strategyDefinition !== undefined) {
      StrategyDefinition.encode(message.strategyDefinition, writer.uint32(10).fork()).ldelim();
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    for (const v of message.exchangeExtraAttributes) {
      NamedValue.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.strategyDefinition = StrategyDefinition.decode(reader, reader.uint32());
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.exchangeExtraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyDefinitionRequest {
    return {
      strategyDefinition: isSet(object.strategyDefinition) ? StrategyDefinition.fromJSON(object.strategyDefinition) : undefined,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      exchangeExtraAttributes: Array.isArray(object?.exchangeExtraAttributes)
        ? object.exchangeExtraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StrategyDefinitionRequest): unknown {
    const obj: any = {};
    message.strategyDefinition !== undefined &&
      (obj.strategyDefinition = message.strategyDefinition ? StrategyDefinition.toJSON(message.strategyDefinition) : undefined);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    if (message.exchangeExtraAttributes) {
      obj.exchangeExtraAttributes = message.exchangeExtraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.exchangeExtraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyDefinitionRequest>, I>>(base?: I): StrategyDefinitionRequest {
    return StrategyDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyDefinitionRequest>, I>>(object: I): StrategyDefinitionRequest {
    const message = createBaseStrategyDefinitionRequest();
    message.strategyDefinition =
      object.strategyDefinition !== undefined && object.strategyDefinition !== null
        ? StrategyDefinition.fromPartial(object.strategyDefinition)
        : undefined;
    message.accountId = object.accountId ?? 0;
    message.exchangeExtraAttributes = object.exchangeExtraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyDefinitionReport(): StrategyDefinitionReport {
  return { contractMetadata: undefined };
}

export const StrategyDefinitionReport = {
  encode(message: StrategyDefinitionReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyDefinitionReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyDefinitionReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyDefinitionReport {
    return {
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: StrategyDefinitionReport): unknown {
    const obj: any = {};
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyDefinitionReport>, I>>(base?: I): StrategyDefinitionReport {
    return StrategyDefinitionReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyDefinitionReport>, I>>(object: I): StrategyDefinitionReport {
    const message = createBaseStrategyDefinitionReport();
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    return message;
  },
};

function createBaseStrategyLegFill(): StrategyLegFill {
  return { legOrderId: '', legExecutionId: '', qty: 0 };
}

export const StrategyLegFill = {
  encode(message: StrategyLegFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.legOrderId !== '') {
      writer.uint32(10).string(message.legOrderId);
    }
    if (message.legExecutionId !== '') {
      writer.uint32(18).string(message.legExecutionId);
    }
    if (message.qty !== 0) {
      writer.uint32(25).double(message.qty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyLegFill {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyLegFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.legOrderId = reader.string();
          break;
        case 2:
          message.legExecutionId = reader.string();
          break;
        case 3:
          message.qty = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyLegFill {
    return {
      legOrderId: isSet(object.legOrderId) ? String(object.legOrderId) : '',
      legExecutionId: isSet(object.legExecutionId) ? String(object.legExecutionId) : '',
      qty: isSet(object.qty) ? Number(object.qty) : 0,
    };
  },

  toJSON(message: StrategyLegFill): unknown {
    const obj: any = {};
    message.legOrderId !== undefined && (obj.legOrderId = message.legOrderId);
    message.legExecutionId !== undefined && (obj.legExecutionId = message.legExecutionId);
    message.qty !== undefined && (obj.qty = message.qty);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyLegFill>, I>>(base?: I): StrategyLegFill {
    return StrategyLegFill.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyLegFill>, I>>(object: I): StrategyLegFill {
    const message = createBaseStrategyLegFill();
    message.legOrderId = object.legOrderId ?? '';
    message.legExecutionId = object.legExecutionId ?? '';
    message.qty = object.qty ?? 0;
    return message;
  },
};

function createBaseSyntheticHang(): SyntheticHang {
  return { hangId: 0, uint32HungQty: 0, hungQty: undefined, orderIds: [] };
}

export const SyntheticHang = {
  encode(message: SyntheticHang, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hangId !== 0) {
      writer.uint32(8).sint32(message.hangId);
    }
    if (message.uint32HungQty !== 0) {
      writer.uint32(16).uint32(message.uint32HungQty);
    }
    if (message.hungQty !== undefined) {
      Decimal.encode(message.hungQty, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.orderIds) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticHang {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticHang();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hangId = reader.sint32();
          break;
        case 2:
          message.uint32HungQty = reader.uint32();
          break;
        case 4:
          message.hungQty = Decimal.decode(reader, reader.uint32());
          break;
        case 3:
          message.orderIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SyntheticHang {
    return {
      hangId: isSet(object.hangId) ? Number(object.hangId) : 0,
      uint32HungQty: isSet(object.uint32HungQty) ? Number(object.uint32HungQty) : 0,
      hungQty: isSet(object.hungQty) ? Decimal.fromJSON(object.hungQty) : undefined,
      orderIds: Array.isArray(object?.orderIds) ? object.orderIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SyntheticHang): unknown {
    const obj: any = {};
    message.hangId !== undefined && (obj.hangId = Math.round(message.hangId));
    message.uint32HungQty !== undefined && (obj.uint32HungQty = Math.round(message.uint32HungQty));
    message.hungQty !== undefined && (obj.hungQty = message.hungQty ? Decimal.toJSON(message.hungQty) : undefined);
    if (message.orderIds) {
      obj.orderIds = message.orderIds.map((e) => e);
    } else {
      obj.orderIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticHang>, I>>(base?: I): SyntheticHang {
    return SyntheticHang.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SyntheticHang>, I>>(object: I): SyntheticHang {
    const message = createBaseSyntheticHang();
    message.hangId = object.hangId ?? 0;
    message.uint32HungQty = object.uint32HungQty ?? 0;
    message.hungQty = object.hungQty !== undefined && object.hungQty !== null ? Decimal.fromPartial(object.hungQty) : undefined;
    message.orderIds = object.orderIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSyntheticStrategyProperties(): SyntheticStrategyProperties {
  return { strategyTradingParameters: undefined, originOrderId: '', rootOrderId: '', nodeIndex: 0, released: false };
}

export const SyntheticStrategyProperties = {
  encode(message: SyntheticStrategyProperties, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strategyTradingParameters !== undefined) {
      StrategyTradingParameters.encode(message.strategyTradingParameters, writer.uint32(10).fork()).ldelim();
    }
    if (message.originOrderId !== '') {
      writer.uint32(18).string(message.originOrderId);
    }
    if (message.rootOrderId !== '') {
      writer.uint32(26).string(message.rootOrderId);
    }
    if (message.nodeIndex !== 0) {
      writer.uint32(32).uint32(message.nodeIndex);
    }
    if (message.released === true) {
      writer.uint32(40).bool(message.released);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticStrategyProperties {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticStrategyProperties();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.strategyTradingParameters = StrategyTradingParameters.decode(reader, reader.uint32());
          break;
        case 2:
          message.originOrderId = reader.string();
          break;
        case 3:
          message.rootOrderId = reader.string();
          break;
        case 4:
          message.nodeIndex = reader.uint32();
          break;
        case 5:
          message.released = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SyntheticStrategyProperties {
    return {
      strategyTradingParameters: isSet(object.strategyTradingParameters)
        ? StrategyTradingParameters.fromJSON(object.strategyTradingParameters)
        : undefined,
      originOrderId: isSet(object.originOrderId) ? String(object.originOrderId) : '',
      rootOrderId: isSet(object.rootOrderId) ? String(object.rootOrderId) : '',
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      released: isSet(object.released) ? Boolean(object.released) : false,
    };
  },

  toJSON(message: SyntheticStrategyProperties): unknown {
    const obj: any = {};
    message.strategyTradingParameters !== undefined &&
      (obj.strategyTradingParameters = message.strategyTradingParameters
        ? StrategyTradingParameters.toJSON(message.strategyTradingParameters)
        : undefined);
    message.originOrderId !== undefined && (obj.originOrderId = message.originOrderId);
    message.rootOrderId !== undefined && (obj.rootOrderId = message.rootOrderId);
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.released !== undefined && (obj.released = message.released);
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticStrategyProperties>, I>>(base?: I): SyntheticStrategyProperties {
    return SyntheticStrategyProperties.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SyntheticStrategyProperties>, I>>(object: I): SyntheticStrategyProperties {
    const message = createBaseSyntheticStrategyProperties();
    message.strategyTradingParameters =
      object.strategyTradingParameters !== undefined && object.strategyTradingParameters !== null
        ? StrategyTradingParameters.fromPartial(object.strategyTradingParameters)
        : undefined;
    message.originOrderId = object.originOrderId ?? '';
    message.rootOrderId = object.rootOrderId ?? '';
    message.nodeIndex = object.nodeIndex ?? 0;
    message.released = object.released ?? false;
    return message;
  },
};

function createBaseStrategyTradingParameters(): StrategyTradingParameters {
  return {
    marketOrderParameters: [],
    limitOrderParameters: [],
    stopOrderParameters: [],
    aggregationParameters: [],
    legParameters: [],
  };
}

export const StrategyTradingParameters = {
  encode(message: StrategyTradingParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.marketOrderParameters) {
      StrategyMarketOrderParameters.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.limitOrderParameters) {
      StrategyLimitOrderParameters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.stopOrderParameters) {
      StrategyStopOrderParameters.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.aggregationParameters) {
      StrategyAggregationParameters.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.legParameters) {
      StrategyOrderLegParameters.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyTradingParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyTradingParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.marketOrderParameters.push(StrategyMarketOrderParameters.decode(reader, reader.uint32()));
          break;
        case 2:
          message.limitOrderParameters.push(StrategyLimitOrderParameters.decode(reader, reader.uint32()));
          break;
        case 3:
          message.stopOrderParameters.push(StrategyStopOrderParameters.decode(reader, reader.uint32()));
          break;
        case 4:
          message.aggregationParameters.push(StrategyAggregationParameters.decode(reader, reader.uint32()));
          break;
        case 5:
          message.legParameters.push(StrategyOrderLegParameters.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyTradingParameters {
    return {
      marketOrderParameters: Array.isArray(object?.marketOrderParameters)
        ? object.marketOrderParameters.map((e: any) => StrategyMarketOrderParameters.fromJSON(e))
        : [],
      limitOrderParameters: Array.isArray(object?.limitOrderParameters)
        ? object.limitOrderParameters.map((e: any) => StrategyLimitOrderParameters.fromJSON(e))
        : [],
      stopOrderParameters: Array.isArray(object?.stopOrderParameters)
        ? object.stopOrderParameters.map((e: any) => StrategyStopOrderParameters.fromJSON(e))
        : [],
      aggregationParameters: Array.isArray(object?.aggregationParameters)
        ? object.aggregationParameters.map((e: any) => StrategyAggregationParameters.fromJSON(e))
        : [],
      legParameters: Array.isArray(object?.legParameters)
        ? object.legParameters.map((e: any) => StrategyOrderLegParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StrategyTradingParameters): unknown {
    const obj: any = {};
    if (message.marketOrderParameters) {
      obj.marketOrderParameters = message.marketOrderParameters.map((e) =>
        e ? StrategyMarketOrderParameters.toJSON(e) : undefined
      );
    } else {
      obj.marketOrderParameters = [];
    }
    if (message.limitOrderParameters) {
      obj.limitOrderParameters = message.limitOrderParameters.map((e) =>
        e ? StrategyLimitOrderParameters.toJSON(e) : undefined
      );
    } else {
      obj.limitOrderParameters = [];
    }
    if (message.stopOrderParameters) {
      obj.stopOrderParameters = message.stopOrderParameters.map((e) => (e ? StrategyStopOrderParameters.toJSON(e) : undefined));
    } else {
      obj.stopOrderParameters = [];
    }
    if (message.aggregationParameters) {
      obj.aggregationParameters = message.aggregationParameters.map((e) =>
        e ? StrategyAggregationParameters.toJSON(e) : undefined
      );
    } else {
      obj.aggregationParameters = [];
    }
    if (message.legParameters) {
      obj.legParameters = message.legParameters.map((e) => (e ? StrategyOrderLegParameters.toJSON(e) : undefined));
    } else {
      obj.legParameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyTradingParameters>, I>>(base?: I): StrategyTradingParameters {
    return StrategyTradingParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyTradingParameters>, I>>(object: I): StrategyTradingParameters {
    const message = createBaseStrategyTradingParameters();
    message.marketOrderParameters = object.marketOrderParameters?.map((e) => StrategyMarketOrderParameters.fromPartial(e)) || [];
    message.limitOrderParameters = object.limitOrderParameters?.map((e) => StrategyLimitOrderParameters.fromPartial(e)) || [];
    message.stopOrderParameters = object.stopOrderParameters?.map((e) => StrategyStopOrderParameters.fromPartial(e)) || [];
    message.aggregationParameters = object.aggregationParameters?.map((e) => StrategyAggregationParameters.fromPartial(e)) || [];
    message.legParameters = object.legParameters?.map((e) => StrategyOrderLegParameters.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyOrderLegParameters(): StrategyOrderLegParameters {
  return { nodeIndex: 0, accountId: 0, openCloseInstruction: 0, speculationType: 0 };
}

export const StrategyOrderLegParameters = {
  encode(message: StrategyOrderLegParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.openCloseInstruction !== 0) {
      writer.uint32(24).uint32(message.openCloseInstruction);
    }
    if (message.speculationType !== 0) {
      writer.uint32(32).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyOrderLegParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyOrderLegParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.openCloseInstruction = reader.uint32();
          break;
        case 4:
          message.speculationType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyOrderLegParameters {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      openCloseInstruction: isSet(object.openCloseInstruction) ? Number(object.openCloseInstruction) : 0,
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
    };
  },

  toJSON(message: StrategyOrderLegParameters): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.openCloseInstruction !== undefined && (obj.openCloseInstruction = Math.round(message.openCloseInstruction));
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyOrderLegParameters>, I>>(base?: I): StrategyOrderLegParameters {
    return StrategyOrderLegParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyOrderLegParameters>, I>>(object: I): StrategyOrderLegParameters {
    const message = createBaseStrategyOrderLegParameters();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.accountId = object.accountId ?? 0;
    message.openCloseInstruction = object.openCloseInstruction ?? 0;
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseStrategyMarketOrderParameters(): StrategyMarketOrderParameters {
  return { nodeIndex: 0, replenishOnPrimaryFill: false };
}

export const StrategyMarketOrderParameters = {
  encode(message: StrategyMarketOrderParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.replenishOnPrimaryFill === true) {
      writer.uint32(16).bool(message.replenishOnPrimaryFill);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyMarketOrderParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyMarketOrderParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.replenishOnPrimaryFill = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyMarketOrderParameters {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      replenishOnPrimaryFill: isSet(object.replenishOnPrimaryFill) ? Boolean(object.replenishOnPrimaryFill) : false,
    };
  },

  toJSON(message: StrategyMarketOrderParameters): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.replenishOnPrimaryFill !== undefined && (obj.replenishOnPrimaryFill = message.replenishOnPrimaryFill);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyMarketOrderParameters>, I>>(base?: I): StrategyMarketOrderParameters {
    return StrategyMarketOrderParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyMarketOrderParameters>, I>>(object: I): StrategyMarketOrderParameters {
    const message = createBaseStrategyMarketOrderParameters();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.replenishOnPrimaryFill = object.replenishOnPrimaryFill ?? false;
    return message;
  },
};

function createBaseStrategyLimitOrderParameters(): StrategyLimitOrderParameters {
  return {
    nodeIndex: 0,
    replenishOnPrimaryFill: false,
    overfillMode: 0,
    alignToStrategyLots: 0,
    pricingMethod: 0,
    priceShifting: undefined,
    legDescriptions: [],
  };
}

export const StrategyLimitOrderParameters = {
  encode(message: StrategyLimitOrderParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.replenishOnPrimaryFill === true) {
      writer.uint32(16).bool(message.replenishOnPrimaryFill);
    }
    if (message.overfillMode !== 0) {
      writer.uint32(24).uint32(message.overfillMode);
    }
    if (message.alignToStrategyLots !== 0) {
      writer.uint32(32).uint32(message.alignToStrategyLots);
    }
    if (message.pricingMethod !== 0) {
      writer.uint32(40).uint32(message.pricingMethod);
    }
    if (message.priceShifting !== undefined) {
      PriceShifting.encode(message.priceShifting, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.legDescriptions) {
      LimitOrderLegDescription.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyLimitOrderParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyLimitOrderParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.replenishOnPrimaryFill = reader.bool();
          break;
        case 3:
          message.overfillMode = reader.uint32();
          break;
        case 4:
          message.alignToStrategyLots = reader.uint32();
          break;
        case 5:
          message.pricingMethod = reader.uint32();
          break;
        case 6:
          message.priceShifting = PriceShifting.decode(reader, reader.uint32());
          break;
        case 7:
          message.legDescriptions.push(LimitOrderLegDescription.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyLimitOrderParameters {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      replenishOnPrimaryFill: isSet(object.replenishOnPrimaryFill) ? Boolean(object.replenishOnPrimaryFill) : false,
      overfillMode: isSet(object.overfillMode) ? Number(object.overfillMode) : 0,
      alignToStrategyLots: isSet(object.alignToStrategyLots) ? Number(object.alignToStrategyLots) : 0,
      pricingMethod: isSet(object.pricingMethod) ? Number(object.pricingMethod) : 0,
      priceShifting: isSet(object.priceShifting) ? PriceShifting.fromJSON(object.priceShifting) : undefined,
      legDescriptions: Array.isArray(object?.legDescriptions)
        ? object.legDescriptions.map((e: any) => LimitOrderLegDescription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StrategyLimitOrderParameters): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.replenishOnPrimaryFill !== undefined && (obj.replenishOnPrimaryFill = message.replenishOnPrimaryFill);
    message.overfillMode !== undefined && (obj.overfillMode = Math.round(message.overfillMode));
    message.alignToStrategyLots !== undefined && (obj.alignToStrategyLots = Math.round(message.alignToStrategyLots));
    message.pricingMethod !== undefined && (obj.pricingMethod = Math.round(message.pricingMethod));
    message.priceShifting !== undefined &&
      (obj.priceShifting = message.priceShifting ? PriceShifting.toJSON(message.priceShifting) : undefined);
    if (message.legDescriptions) {
      obj.legDescriptions = message.legDescriptions.map((e) => (e ? LimitOrderLegDescription.toJSON(e) : undefined));
    } else {
      obj.legDescriptions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyLimitOrderParameters>, I>>(base?: I): StrategyLimitOrderParameters {
    return StrategyLimitOrderParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyLimitOrderParameters>, I>>(object: I): StrategyLimitOrderParameters {
    const message = createBaseStrategyLimitOrderParameters();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.replenishOnPrimaryFill = object.replenishOnPrimaryFill ?? false;
    message.overfillMode = object.overfillMode ?? 0;
    message.alignToStrategyLots = object.alignToStrategyLots ?? 0;
    message.pricingMethod = object.pricingMethod ?? 0;
    message.priceShifting =
      object.priceShifting !== undefined && object.priceShifting !== null
        ? PriceShifting.fromPartial(object.priceShifting)
        : undefined;
    message.legDescriptions = object.legDescriptions?.map((e) => LimitOrderLegDescription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyStopOrderParameters(): StrategyStopOrderParameters {
  return { nodeIndex: 0, stopSide: 0, replenishOnPrimaryFill: false };
}

export const StrategyStopOrderParameters = {
  encode(message: StrategyStopOrderParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.stopSide !== 0) {
      writer.uint32(16).uint32(message.stopSide);
    }
    if (message.replenishOnPrimaryFill === true) {
      writer.uint32(24).bool(message.replenishOnPrimaryFill);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyStopOrderParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyStopOrderParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.stopSide = reader.uint32();
          break;
        case 3:
          message.replenishOnPrimaryFill = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyStopOrderParameters {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      stopSide: isSet(object.stopSide) ? Number(object.stopSide) : 0,
      replenishOnPrimaryFill: isSet(object.replenishOnPrimaryFill) ? Boolean(object.replenishOnPrimaryFill) : false,
    };
  },

  toJSON(message: StrategyStopOrderParameters): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.stopSide !== undefined && (obj.stopSide = Math.round(message.stopSide));
    message.replenishOnPrimaryFill !== undefined && (obj.replenishOnPrimaryFill = message.replenishOnPrimaryFill);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyStopOrderParameters>, I>>(base?: I): StrategyStopOrderParameters {
    return StrategyStopOrderParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyStopOrderParameters>, I>>(object: I): StrategyStopOrderParameters {
    const message = createBaseStrategyStopOrderParameters();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.stopSide = object.stopSide ?? 0;
    message.replenishOnPrimaryFill = object.replenishOnPrimaryFill ?? false;
    return message;
  },
};

function createBaseStrategyAggregationParameters(): StrategyAggregationParameters {
  return { nodeIndex: 0, overfillMode: 0, priceShifting: undefined, patternLegs: [] };
}

export const StrategyAggregationParameters = {
  encode(message: StrategyAggregationParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.overfillMode !== 0) {
      writer.uint32(16).uint32(message.overfillMode);
    }
    if (message.priceShifting !== undefined) {
      PriceShifting.encode(message.priceShifting, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.patternLegs) {
      AggregationLegDescription.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyAggregationParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyAggregationParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.overfillMode = reader.uint32();
          break;
        case 3:
          message.priceShifting = PriceShifting.decode(reader, reader.uint32());
          break;
        case 4:
          message.patternLegs.push(AggregationLegDescription.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyAggregationParameters {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      overfillMode: isSet(object.overfillMode) ? Number(object.overfillMode) : 0,
      priceShifting: isSet(object.priceShifting) ? PriceShifting.fromJSON(object.priceShifting) : undefined,
      patternLegs: Array.isArray(object?.patternLegs)
        ? object.patternLegs.map((e: any) => AggregationLegDescription.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StrategyAggregationParameters): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.overfillMode !== undefined && (obj.overfillMode = Math.round(message.overfillMode));
    message.priceShifting !== undefined &&
      (obj.priceShifting = message.priceShifting ? PriceShifting.toJSON(message.priceShifting) : undefined);
    if (message.patternLegs) {
      obj.patternLegs = message.patternLegs.map((e) => (e ? AggregationLegDescription.toJSON(e) : undefined));
    } else {
      obj.patternLegs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyAggregationParameters>, I>>(base?: I): StrategyAggregationParameters {
    return StrategyAggregationParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyAggregationParameters>, I>>(object: I): StrategyAggregationParameters {
    const message = createBaseStrategyAggregationParameters();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.overfillMode = object.overfillMode ?? 0;
    message.priceShifting =
      object.priceShifting !== undefined && object.priceShifting !== null
        ? PriceShifting.fromPartial(object.priceShifting)
        : undefined;
    message.patternLegs = object.patternLegs?.map((e) => AggregationLegDescription.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAggregationLegDescription(): AggregationLegDescription {
  return {
    nodeIndex: 0,
    uint32WorkingThreshold: 0,
    workingThreshold: undefined,
    marketTakingParameters: undefined,
    marketMakingParameters: undefined,
  };
}

export const AggregationLegDescription = {
  encode(message: AggregationLegDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.uint32WorkingThreshold !== 0) {
      writer.uint32(16).uint32(message.uint32WorkingThreshold);
    }
    if (message.workingThreshold !== undefined) {
      Decimal.encode(message.workingThreshold, writer.uint32(42).fork()).ldelim();
    }
    if (message.marketTakingParameters !== undefined) {
      MarketTakingParameters.encode(message.marketTakingParameters, writer.uint32(26).fork()).ldelim();
    }
    if (message.marketMakingParameters !== undefined) {
      MarketMakingParameters.encode(message.marketMakingParameters, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AggregationLegDescription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAggregationLegDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.uint32WorkingThreshold = reader.uint32();
          break;
        case 5:
          message.workingThreshold = Decimal.decode(reader, reader.uint32());
          break;
        case 3:
          message.marketTakingParameters = MarketTakingParameters.decode(reader, reader.uint32());
          break;
        case 4:
          message.marketMakingParameters = MarketMakingParameters.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AggregationLegDescription {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      uint32WorkingThreshold: isSet(object.uint32WorkingThreshold) ? Number(object.uint32WorkingThreshold) : 0,
      workingThreshold: isSet(object.workingThreshold) ? Decimal.fromJSON(object.workingThreshold) : undefined,
      marketTakingParameters: isSet(object.marketTakingParameters)
        ? MarketTakingParameters.fromJSON(object.marketTakingParameters)
        : undefined,
      marketMakingParameters: isSet(object.marketMakingParameters)
        ? MarketMakingParameters.fromJSON(object.marketMakingParameters)
        : undefined,
    };
  },

  toJSON(message: AggregationLegDescription): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.uint32WorkingThreshold !== undefined && (obj.uint32WorkingThreshold = Math.round(message.uint32WorkingThreshold));
    message.workingThreshold !== undefined &&
      (obj.workingThreshold = message.workingThreshold ? Decimal.toJSON(message.workingThreshold) : undefined);
    message.marketTakingParameters !== undefined &&
      (obj.marketTakingParameters = message.marketTakingParameters
        ? MarketTakingParameters.toJSON(message.marketTakingParameters)
        : undefined);
    message.marketMakingParameters !== undefined &&
      (obj.marketMakingParameters = message.marketMakingParameters
        ? MarketMakingParameters.toJSON(message.marketMakingParameters)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AggregationLegDescription>, I>>(base?: I): AggregationLegDescription {
    return AggregationLegDescription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AggregationLegDescription>, I>>(object: I): AggregationLegDescription {
    const message = createBaseAggregationLegDescription();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.uint32WorkingThreshold = object.uint32WorkingThreshold ?? 0;
    message.workingThreshold =
      object.workingThreshold !== undefined && object.workingThreshold !== null
        ? Decimal.fromPartial(object.workingThreshold)
        : undefined;
    message.marketTakingParameters =
      object.marketTakingParameters !== undefined && object.marketTakingParameters !== null
        ? MarketTakingParameters.fromPartial(object.marketTakingParameters)
        : undefined;
    message.marketMakingParameters =
      object.marketMakingParameters !== undefined && object.marketMakingParameters !== null
        ? MarketMakingParameters.fromPartial(object.marketMakingParameters)
        : undefined;
    return message;
  },
};

function createBasePriceShifting(): PriceShifting {
  return { priceStep: 0, uint32FilledQtyDelta: 0, filledQtyDelta: undefined };
}

export const PriceShifting = {
  encode(message: PriceShifting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.priceStep !== 0) {
      writer.uint32(8).int32(message.priceStep);
    }
    if (message.uint32FilledQtyDelta !== 0) {
      writer.uint32(16).uint32(message.uint32FilledQtyDelta);
    }
    if (message.filledQtyDelta !== undefined) {
      Decimal.encode(message.filledQtyDelta, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PriceShifting {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePriceShifting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.priceStep = reader.int32();
          break;
        case 2:
          message.uint32FilledQtyDelta = reader.uint32();
          break;
        case 3:
          message.filledQtyDelta = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PriceShifting {
    return {
      priceStep: isSet(object.priceStep) ? Number(object.priceStep) : 0,
      uint32FilledQtyDelta: isSet(object.uint32FilledQtyDelta) ? Number(object.uint32FilledQtyDelta) : 0,
      filledQtyDelta: isSet(object.filledQtyDelta) ? Decimal.fromJSON(object.filledQtyDelta) : undefined,
    };
  },

  toJSON(message: PriceShifting): unknown {
    const obj: any = {};
    message.priceStep !== undefined && (obj.priceStep = Math.round(message.priceStep));
    message.uint32FilledQtyDelta !== undefined && (obj.uint32FilledQtyDelta = Math.round(message.uint32FilledQtyDelta));
    message.filledQtyDelta !== undefined &&
      (obj.filledQtyDelta = message.filledQtyDelta ? Decimal.toJSON(message.filledQtyDelta) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PriceShifting>, I>>(base?: I): PriceShifting {
    return PriceShifting.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PriceShifting>, I>>(object: I): PriceShifting {
    const message = createBasePriceShifting();
    message.priceStep = object.priceStep ?? 0;
    message.uint32FilledQtyDelta = object.uint32FilledQtyDelta ?? 0;
    message.filledQtyDelta =
      object.filledQtyDelta !== undefined && object.filledQtyDelta !== null
        ? Decimal.fromPartial(object.filledQtyDelta)
        : undefined;
    return message;
  },
};

function createBaseLimitOrderLegDescription(): LimitOrderLegDescription {
  return {
    nodeIndex: 0,
    primaryOrderLimit: undefined,
    primaryOrderSniper: undefined,
    secondaryOrdersLimit: undefined,
    secondaryOrdersMarket: undefined,
    secondaryOrdersPayup: undefined,
    secondaryOrdersTrailing: undefined,
    proportionalExecutionRatio: 0,
    volumeMultiplier: 0,
    uint32WorkThreshold: 0,
    workThreshold: undefined,
  };
}

export const LimitOrderLegDescription = {
  encode(message: LimitOrderLegDescription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeIndex !== 0) {
      writer.uint32(8).uint32(message.nodeIndex);
    }
    if (message.primaryOrderLimit !== undefined) {
      PrimaryOrdersLimit.encode(message.primaryOrderLimit, writer.uint32(18).fork()).ldelim();
    }
    if (message.primaryOrderSniper !== undefined) {
      PrimaryOrdersSniper.encode(message.primaryOrderSniper, writer.uint32(26).fork()).ldelim();
    }
    if (message.secondaryOrdersLimit !== undefined) {
      SecondaryOrdersLimit.encode(message.secondaryOrdersLimit, writer.uint32(34).fork()).ldelim();
    }
    if (message.secondaryOrdersMarket !== undefined) {
      SecondaryOrdersMarket.encode(message.secondaryOrdersMarket, writer.uint32(42).fork()).ldelim();
    }
    if (message.secondaryOrdersPayup !== undefined) {
      SecondaryOrdersPayUp.encode(message.secondaryOrdersPayup, writer.uint32(50).fork()).ldelim();
    }
    if (message.secondaryOrdersTrailing !== undefined) {
      SecondaryOrdersTrailing.encode(message.secondaryOrdersTrailing, writer.uint32(58).fork()).ldelim();
    }
    if (message.proportionalExecutionRatio !== 0) {
      writer.uint32(65).double(message.proportionalExecutionRatio);
    }
    if (message.volumeMultiplier !== 0) {
      writer.uint32(73).double(message.volumeMultiplier);
    }
    if (message.uint32WorkThreshold !== 0) {
      writer.uint32(80).uint32(message.uint32WorkThreshold);
    }
    if (message.workThreshold !== undefined) {
      Decimal.encode(message.workThreshold, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitOrderLegDescription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitOrderLegDescription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.nodeIndex = reader.uint32();
          break;
        case 2:
          message.primaryOrderLimit = PrimaryOrdersLimit.decode(reader, reader.uint32());
          break;
        case 3:
          message.primaryOrderSniper = PrimaryOrdersSniper.decode(reader, reader.uint32());
          break;
        case 4:
          message.secondaryOrdersLimit = SecondaryOrdersLimit.decode(reader, reader.uint32());
          break;
        case 5:
          message.secondaryOrdersMarket = SecondaryOrdersMarket.decode(reader, reader.uint32());
          break;
        case 6:
          message.secondaryOrdersPayup = SecondaryOrdersPayUp.decode(reader, reader.uint32());
          break;
        case 7:
          message.secondaryOrdersTrailing = SecondaryOrdersTrailing.decode(reader, reader.uint32());
          break;
        case 8:
          message.proportionalExecutionRatio = reader.double();
          break;
        case 9:
          message.volumeMultiplier = reader.double();
          break;
        case 10:
          message.uint32WorkThreshold = reader.uint32();
          break;
        case 11:
          message.workThreshold = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LimitOrderLegDescription {
    return {
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      primaryOrderLimit: isSet(object.primaryOrderLimit) ? PrimaryOrdersLimit.fromJSON(object.primaryOrderLimit) : undefined,
      primaryOrderSniper: isSet(object.primaryOrderSniper) ? PrimaryOrdersSniper.fromJSON(object.primaryOrderSniper) : undefined,
      secondaryOrdersLimit: isSet(object.secondaryOrdersLimit)
        ? SecondaryOrdersLimit.fromJSON(object.secondaryOrdersLimit)
        : undefined,
      secondaryOrdersMarket: isSet(object.secondaryOrdersMarket)
        ? SecondaryOrdersMarket.fromJSON(object.secondaryOrdersMarket)
        : undefined,
      secondaryOrdersPayup: isSet(object.secondaryOrdersPayup)
        ? SecondaryOrdersPayUp.fromJSON(object.secondaryOrdersPayup)
        : undefined,
      secondaryOrdersTrailing: isSet(object.secondaryOrdersTrailing)
        ? SecondaryOrdersTrailing.fromJSON(object.secondaryOrdersTrailing)
        : undefined,
      proportionalExecutionRatio: isSet(object.proportionalExecutionRatio) ? Number(object.proportionalExecutionRatio) : 0,
      volumeMultiplier: isSet(object.volumeMultiplier) ? Number(object.volumeMultiplier) : 0,
      uint32WorkThreshold: isSet(object.uint32WorkThreshold) ? Number(object.uint32WorkThreshold) : 0,
      workThreshold: isSet(object.workThreshold) ? Decimal.fromJSON(object.workThreshold) : undefined,
    };
  },

  toJSON(message: LimitOrderLegDescription): unknown {
    const obj: any = {};
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.primaryOrderLimit !== undefined &&
      (obj.primaryOrderLimit = message.primaryOrderLimit ? PrimaryOrdersLimit.toJSON(message.primaryOrderLimit) : undefined);
    message.primaryOrderSniper !== undefined &&
      (obj.primaryOrderSniper = message.primaryOrderSniper ? PrimaryOrdersSniper.toJSON(message.primaryOrderSniper) : undefined);
    message.secondaryOrdersLimit !== undefined &&
      (obj.secondaryOrdersLimit = message.secondaryOrdersLimit
        ? SecondaryOrdersLimit.toJSON(message.secondaryOrdersLimit)
        : undefined);
    message.secondaryOrdersMarket !== undefined &&
      (obj.secondaryOrdersMarket = message.secondaryOrdersMarket
        ? SecondaryOrdersMarket.toJSON(message.secondaryOrdersMarket)
        : undefined);
    message.secondaryOrdersPayup !== undefined &&
      (obj.secondaryOrdersPayup = message.secondaryOrdersPayup
        ? SecondaryOrdersPayUp.toJSON(message.secondaryOrdersPayup)
        : undefined);
    message.secondaryOrdersTrailing !== undefined &&
      (obj.secondaryOrdersTrailing = message.secondaryOrdersTrailing
        ? SecondaryOrdersTrailing.toJSON(message.secondaryOrdersTrailing)
        : undefined);
    message.proportionalExecutionRatio !== undefined && (obj.proportionalExecutionRatio = message.proportionalExecutionRatio);
    message.volumeMultiplier !== undefined && (obj.volumeMultiplier = message.volumeMultiplier);
    message.uint32WorkThreshold !== undefined && (obj.uint32WorkThreshold = Math.round(message.uint32WorkThreshold));
    message.workThreshold !== undefined &&
      (obj.workThreshold = message.workThreshold ? Decimal.toJSON(message.workThreshold) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LimitOrderLegDescription>, I>>(base?: I): LimitOrderLegDescription {
    return LimitOrderLegDescription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LimitOrderLegDescription>, I>>(object: I): LimitOrderLegDescription {
    const message = createBaseLimitOrderLegDescription();
    message.nodeIndex = object.nodeIndex ?? 0;
    message.primaryOrderLimit =
      object.primaryOrderLimit !== undefined && object.primaryOrderLimit !== null
        ? PrimaryOrdersLimit.fromPartial(object.primaryOrderLimit)
        : undefined;
    message.primaryOrderSniper =
      object.primaryOrderSniper !== undefined && object.primaryOrderSniper !== null
        ? PrimaryOrdersSniper.fromPartial(object.primaryOrderSniper)
        : undefined;
    message.secondaryOrdersLimit =
      object.secondaryOrdersLimit !== undefined && object.secondaryOrdersLimit !== null
        ? SecondaryOrdersLimit.fromPartial(object.secondaryOrdersLimit)
        : undefined;
    message.secondaryOrdersMarket =
      object.secondaryOrdersMarket !== undefined && object.secondaryOrdersMarket !== null
        ? SecondaryOrdersMarket.fromPartial(object.secondaryOrdersMarket)
        : undefined;
    message.secondaryOrdersPayup =
      object.secondaryOrdersPayup !== undefined && object.secondaryOrdersPayup !== null
        ? SecondaryOrdersPayUp.fromPartial(object.secondaryOrdersPayup)
        : undefined;
    message.secondaryOrdersTrailing =
      object.secondaryOrdersTrailing !== undefined && object.secondaryOrdersTrailing !== null
        ? SecondaryOrdersTrailing.fromPartial(object.secondaryOrdersTrailing)
        : undefined;
    message.proportionalExecutionRatio = object.proportionalExecutionRatio ?? 0;
    message.volumeMultiplier = object.volumeMultiplier ?? 0;
    message.uint32WorkThreshold = object.uint32WorkThreshold ?? 0;
    message.workThreshold =
      object.workThreshold !== undefined && object.workThreshold !== null ? Decimal.fromPartial(object.workThreshold) : undefined;
    return message;
  },
};

function createBasePrimaryOrdersLimit(): PrimaryOrdersLimit {
  return {
    uint32MinQtyIncrement: 0,
    minQtyIncrement: undefined,
    queueHoldersMinSize: 0,
    queueHoldersMaxSize: 0,
    queueHoldersDistance: 0,
    orderSizeRestoreThreshold: 0,
    uint32VisibleQty: 0,
    visibleQty: undefined,
    uint32MinVisibleQty: 0,
    minVisibleQty: undefined,
    workingOrdersPriceRange: 0,
    minPriceChange: 0,
    primaryOrdersFifoQueueSize: 0,
    forceUpdatePeriod: 0,
    ignoreObservedMarkets: false,
    maxDimming: 0,
    farFromMarketMode: 0,
    ticksAwayToWork: 0,
    domUsage: 0,
    domMultilevelQty: 0,
  };
}

export const PrimaryOrdersLimit = {
  encode(message: PrimaryOrdersLimit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uint32MinQtyIncrement !== 0) {
      writer.uint32(8).uint32(message.uint32MinQtyIncrement);
    }
    if (message.minQtyIncrement !== undefined) {
      Decimal.encode(message.minQtyIncrement, writer.uint32(146).fork()).ldelim();
    }
    if (message.queueHoldersMinSize !== 0) {
      writer.uint32(16).uint32(message.queueHoldersMinSize);
    }
    if (message.queueHoldersMaxSize !== 0) {
      writer.uint32(24).uint32(message.queueHoldersMaxSize);
    }
    if (message.queueHoldersDistance !== 0) {
      writer.uint32(32).uint32(message.queueHoldersDistance);
    }
    if (message.orderSizeRestoreThreshold !== 0) {
      writer.uint32(40).uint32(message.orderSizeRestoreThreshold);
    }
    if (message.uint32VisibleQty !== 0) {
      writer.uint32(48).uint32(message.uint32VisibleQty);
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(154).fork()).ldelim();
    }
    if (message.uint32MinVisibleQty !== 0) {
      writer.uint32(56).uint32(message.uint32MinVisibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(162).fork()).ldelim();
    }
    if (message.workingOrdersPriceRange !== 0) {
      writer.uint32(64).uint32(message.workingOrdersPriceRange);
    }
    if (message.minPriceChange !== 0) {
      writer.uint32(72).uint32(message.minPriceChange);
    }
    if (message.primaryOrdersFifoQueueSize !== 0) {
      writer.uint32(80).uint32(message.primaryOrdersFifoQueueSize);
    }
    if (message.forceUpdatePeriod !== 0) {
      writer.uint32(88).uint32(message.forceUpdatePeriod);
    }
    if (message.ignoreObservedMarkets === true) {
      writer.uint32(96).bool(message.ignoreObservedMarkets);
    }
    if (message.maxDimming !== 0) {
      writer.uint32(104).uint32(message.maxDimming);
    }
    if (message.farFromMarketMode !== 0) {
      writer.uint32(112).uint32(message.farFromMarketMode);
    }
    if (message.ticksAwayToWork !== 0) {
      writer.uint32(120).int32(message.ticksAwayToWork);
    }
    if (message.domUsage !== 0) {
      writer.uint32(128).uint32(message.domUsage);
    }
    if (message.domMultilevelQty !== 0) {
      writer.uint32(136).uint32(message.domMultilevelQty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrimaryOrdersLimit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrimaryOrdersLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.uint32MinQtyIncrement = reader.uint32();
          break;
        case 18:
          message.minQtyIncrement = Decimal.decode(reader, reader.uint32());
          break;
        case 2:
          message.queueHoldersMinSize = reader.uint32();
          break;
        case 3:
          message.queueHoldersMaxSize = reader.uint32();
          break;
        case 4:
          message.queueHoldersDistance = reader.uint32();
          break;
        case 5:
          message.orderSizeRestoreThreshold = reader.uint32();
          break;
        case 6:
          message.uint32VisibleQty = reader.uint32();
          break;
        case 19:
          message.visibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.uint32MinVisibleQty = reader.uint32();
          break;
        case 20:
          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 8:
          message.workingOrdersPriceRange = reader.uint32();
          break;
        case 9:
          message.minPriceChange = reader.uint32();
          break;
        case 10:
          message.primaryOrdersFifoQueueSize = reader.uint32();
          break;
        case 11:
          message.forceUpdatePeriod = reader.uint32();
          break;
        case 12:
          message.ignoreObservedMarkets = reader.bool();
          break;
        case 13:
          message.maxDimming = reader.uint32();
          break;
        case 14:
          message.farFromMarketMode = reader.uint32();
          break;
        case 15:
          message.ticksAwayToWork = reader.int32();
          break;
        case 16:
          message.domUsage = reader.uint32();
          break;
        case 17:
          message.domMultilevelQty = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PrimaryOrdersLimit {
    return {
      uint32MinQtyIncrement: isSet(object.uint32MinQtyIncrement) ? Number(object.uint32MinQtyIncrement) : 0,
      minQtyIncrement: isSet(object.minQtyIncrement) ? Decimal.fromJSON(object.minQtyIncrement) : undefined,
      queueHoldersMinSize: isSet(object.queueHoldersMinSize) ? Number(object.queueHoldersMinSize) : 0,
      queueHoldersMaxSize: isSet(object.queueHoldersMaxSize) ? Number(object.queueHoldersMaxSize) : 0,
      queueHoldersDistance: isSet(object.queueHoldersDistance) ? Number(object.queueHoldersDistance) : 0,
      orderSizeRestoreThreshold: isSet(object.orderSizeRestoreThreshold) ? Number(object.orderSizeRestoreThreshold) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? Number(object.uint32VisibleQty) : 0,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? Number(object.uint32MinVisibleQty) : 0,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      workingOrdersPriceRange: isSet(object.workingOrdersPriceRange) ? Number(object.workingOrdersPriceRange) : 0,
      minPriceChange: isSet(object.minPriceChange) ? Number(object.minPriceChange) : 0,
      primaryOrdersFifoQueueSize: isSet(object.primaryOrdersFifoQueueSize) ? Number(object.primaryOrdersFifoQueueSize) : 0,
      forceUpdatePeriod: isSet(object.forceUpdatePeriod) ? Number(object.forceUpdatePeriod) : 0,
      ignoreObservedMarkets: isSet(object.ignoreObservedMarkets) ? Boolean(object.ignoreObservedMarkets) : false,
      maxDimming: isSet(object.maxDimming) ? Number(object.maxDimming) : 0,
      farFromMarketMode: isSet(object.farFromMarketMode) ? Number(object.farFromMarketMode) : 0,
      ticksAwayToWork: isSet(object.ticksAwayToWork) ? Number(object.ticksAwayToWork) : 0,
      domUsage: isSet(object.domUsage) ? Number(object.domUsage) : 0,
      domMultilevelQty: isSet(object.domMultilevelQty) ? Number(object.domMultilevelQty) : 0,
    };
  },

  toJSON(message: PrimaryOrdersLimit): unknown {
    const obj: any = {};
    message.uint32MinQtyIncrement !== undefined && (obj.uint32MinQtyIncrement = Math.round(message.uint32MinQtyIncrement));
    message.minQtyIncrement !== undefined &&
      (obj.minQtyIncrement = message.minQtyIncrement ? Decimal.toJSON(message.minQtyIncrement) : undefined);
    message.queueHoldersMinSize !== undefined && (obj.queueHoldersMinSize = Math.round(message.queueHoldersMinSize));
    message.queueHoldersMaxSize !== undefined && (obj.queueHoldersMaxSize = Math.round(message.queueHoldersMaxSize));
    message.queueHoldersDistance !== undefined && (obj.queueHoldersDistance = Math.round(message.queueHoldersDistance));
    message.orderSizeRestoreThreshold !== undefined &&
      (obj.orderSizeRestoreThreshold = Math.round(message.orderSizeRestoreThreshold));
    message.uint32VisibleQty !== undefined && (obj.uint32VisibleQty = Math.round(message.uint32VisibleQty));
    message.visibleQty !== undefined && (obj.visibleQty = message.visibleQty ? Decimal.toJSON(message.visibleQty) : undefined);
    message.uint32MinVisibleQty !== undefined && (obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty));
    message.minVisibleQty !== undefined &&
      (obj.minVisibleQty = message.minVisibleQty ? Decimal.toJSON(message.minVisibleQty) : undefined);
    message.workingOrdersPriceRange !== undefined && (obj.workingOrdersPriceRange = Math.round(message.workingOrdersPriceRange));
    message.minPriceChange !== undefined && (obj.minPriceChange = Math.round(message.minPriceChange));
    message.primaryOrdersFifoQueueSize !== undefined &&
      (obj.primaryOrdersFifoQueueSize = Math.round(message.primaryOrdersFifoQueueSize));
    message.forceUpdatePeriod !== undefined && (obj.forceUpdatePeriod = Math.round(message.forceUpdatePeriod));
    message.ignoreObservedMarkets !== undefined && (obj.ignoreObservedMarkets = message.ignoreObservedMarkets);
    message.maxDimming !== undefined && (obj.maxDimming = Math.round(message.maxDimming));
    message.farFromMarketMode !== undefined && (obj.farFromMarketMode = Math.round(message.farFromMarketMode));
    message.ticksAwayToWork !== undefined && (obj.ticksAwayToWork = Math.round(message.ticksAwayToWork));
    message.domUsage !== undefined && (obj.domUsage = Math.round(message.domUsage));
    message.domMultilevelQty !== undefined && (obj.domMultilevelQty = Math.round(message.domMultilevelQty));
    return obj;
  },

  create<I extends Exact<DeepPartial<PrimaryOrdersLimit>, I>>(base?: I): PrimaryOrdersLimit {
    return PrimaryOrdersLimit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrimaryOrdersLimit>, I>>(object: I): PrimaryOrdersLimit {
    const message = createBasePrimaryOrdersLimit();
    message.uint32MinQtyIncrement = object.uint32MinQtyIncrement ?? 0;
    message.minQtyIncrement =
      object.minQtyIncrement !== undefined && object.minQtyIncrement !== null
        ? Decimal.fromPartial(object.minQtyIncrement)
        : undefined;
    message.queueHoldersMinSize = object.queueHoldersMinSize ?? 0;
    message.queueHoldersMaxSize = object.queueHoldersMaxSize ?? 0;
    message.queueHoldersDistance = object.queueHoldersDistance ?? 0;
    message.orderSizeRestoreThreshold = object.orderSizeRestoreThreshold ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.visibleQty =
      object.visibleQty !== undefined && object.visibleQty !== null ? Decimal.fromPartial(object.visibleQty) : undefined;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.minVisibleQty =
      object.minVisibleQty !== undefined && object.minVisibleQty !== null ? Decimal.fromPartial(object.minVisibleQty) : undefined;
    message.workingOrdersPriceRange = object.workingOrdersPriceRange ?? 0;
    message.minPriceChange = object.minPriceChange ?? 0;
    message.primaryOrdersFifoQueueSize = object.primaryOrdersFifoQueueSize ?? 0;
    message.forceUpdatePeriod = object.forceUpdatePeriod ?? 0;
    message.ignoreObservedMarkets = object.ignoreObservedMarkets ?? false;
    message.maxDimming = object.maxDimming ?? 0;
    message.farFromMarketMode = object.farFromMarketMode ?? 0;
    message.ticksAwayToWork = object.ticksAwayToWork ?? 0;
    message.domUsage = object.domUsage ?? 0;
    message.domMultilevelQty = object.domMultilevelQty ?? 0;
    return message;
  },
};

function createBasePrimaryOrdersSniper(): PrimaryOrdersSniper {
  return { legDuration: 0 };
}

export const PrimaryOrdersSniper = {
  encode(message: PrimaryOrdersSniper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.legDuration !== 0) {
      writer.uint32(8).uint32(message.legDuration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrimaryOrdersSniper {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrimaryOrdersSniper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.legDuration = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PrimaryOrdersSniper {
    return { legDuration: isSet(object.legDuration) ? Number(object.legDuration) : 0 };
  },

  toJSON(message: PrimaryOrdersSniper): unknown {
    const obj: any = {};
    message.legDuration !== undefined && (obj.legDuration = Math.round(message.legDuration));
    return obj;
  },

  create<I extends Exact<DeepPartial<PrimaryOrdersSniper>, I>>(base?: I): PrimaryOrdersSniper {
    return PrimaryOrdersSniper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrimaryOrdersSniper>, I>>(object: I): PrimaryOrdersSniper {
    const message = createBasePrimaryOrdersSniper();
    message.legDuration = object.legDuration ?? 0;
    return message;
  },
};

function createBaseSecondaryOrdersLimit(): SecondaryOrdersLimit {
  return { initialOffset: 0 };
}

export const SecondaryOrdersLimit = {
  encode(message: SecondaryOrdersLimit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.initialOffset !== 0) {
      writer.uint32(9).double(message.initialOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecondaryOrdersLimit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecondaryOrdersLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.initialOffset = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecondaryOrdersLimit {
    return { initialOffset: isSet(object.initialOffset) ? Number(object.initialOffset) : 0 };
  },

  toJSON(message: SecondaryOrdersLimit): unknown {
    const obj: any = {};
    message.initialOffset !== undefined && (obj.initialOffset = message.initialOffset);
    return obj;
  },

  create<I extends Exact<DeepPartial<SecondaryOrdersLimit>, I>>(base?: I): SecondaryOrdersLimit {
    return SecondaryOrdersLimit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SecondaryOrdersLimit>, I>>(object: I): SecondaryOrdersLimit {
    const message = createBaseSecondaryOrdersLimit();
    message.initialOffset = object.initialOffset ?? 0;
    return message;
  },
};

function createBaseSecondaryOrdersMarket(): SecondaryOrdersMarket {
  return {};
}

export const SecondaryOrdersMarket = {
  encode(_: SecondaryOrdersMarket, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecondaryOrdersMarket {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecondaryOrdersMarket();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SecondaryOrdersMarket {
    return {};
  },

  toJSON(_: SecondaryOrdersMarket): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SecondaryOrdersMarket>, I>>(base?: I): SecondaryOrdersMarket {
    return SecondaryOrdersMarket.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SecondaryOrdersMarket>, I>>(_: I): SecondaryOrdersMarket {
    const message = createBaseSecondaryOrdersMarket();
    return message;
  },
};

function createBaseSecondaryOrdersPayUp(): SecondaryOrdersPayUp {
  return {
    offset: 0,
    initialOffset: 0,
    conditionTimeout: 0,
    uint32ConditionAbsoluteVolume: 0,
    conditionAbsoluteVolume: undefined,
    conditionOppositeVolumeRatio: 0,
    conditionBbaVolumeRatio: 0,
  };
}

export const SecondaryOrdersPayUp = {
  encode(message: SecondaryOrdersPayUp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offset !== 0) {
      writer.uint32(9).double(message.offset);
    }
    if (message.initialOffset !== 0) {
      writer.uint32(17).double(message.initialOffset);
    }
    if (message.conditionTimeout !== 0) {
      writer.uint32(24).uint32(message.conditionTimeout);
    }
    if (message.uint32ConditionAbsoluteVolume !== 0) {
      writer.uint32(32).uint32(message.uint32ConditionAbsoluteVolume);
    }
    if (message.conditionAbsoluteVolume !== undefined) {
      Decimal.encode(message.conditionAbsoluteVolume, writer.uint32(58).fork()).ldelim();
    }
    if (message.conditionOppositeVolumeRatio !== 0) {
      writer.uint32(41).double(message.conditionOppositeVolumeRatio);
    }
    if (message.conditionBbaVolumeRatio !== 0) {
      writer.uint32(49).double(message.conditionBbaVolumeRatio);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecondaryOrdersPayUp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecondaryOrdersPayUp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.offset = reader.double();
          break;
        case 2:
          message.initialOffset = reader.double();
          break;
        case 3:
          message.conditionTimeout = reader.uint32();
          break;
        case 4:
          message.uint32ConditionAbsoluteVolume = reader.uint32();
          break;
        case 7:
          message.conditionAbsoluteVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.conditionOppositeVolumeRatio = reader.double();
          break;
        case 6:
          message.conditionBbaVolumeRatio = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecondaryOrdersPayUp {
    return {
      offset: isSet(object.offset) ? Number(object.offset) : 0,
      initialOffset: isSet(object.initialOffset) ? Number(object.initialOffset) : 0,
      conditionTimeout: isSet(object.conditionTimeout) ? Number(object.conditionTimeout) : 0,
      uint32ConditionAbsoluteVolume: isSet(object.uint32ConditionAbsoluteVolume)
        ? Number(object.uint32ConditionAbsoluteVolume)
        : 0,
      conditionAbsoluteVolume: isSet(object.conditionAbsoluteVolume)
        ? Decimal.fromJSON(object.conditionAbsoluteVolume)
        : undefined,
      conditionOppositeVolumeRatio: isSet(object.conditionOppositeVolumeRatio) ? Number(object.conditionOppositeVolumeRatio) : 0,
      conditionBbaVolumeRatio: isSet(object.conditionBbaVolumeRatio) ? Number(object.conditionBbaVolumeRatio) : 0,
    };
  },

  toJSON(message: SecondaryOrdersPayUp): unknown {
    const obj: any = {};
    message.offset !== undefined && (obj.offset = message.offset);
    message.initialOffset !== undefined && (obj.initialOffset = message.initialOffset);
    message.conditionTimeout !== undefined && (obj.conditionTimeout = Math.round(message.conditionTimeout));
    message.uint32ConditionAbsoluteVolume !== undefined &&
      (obj.uint32ConditionAbsoluteVolume = Math.round(message.uint32ConditionAbsoluteVolume));
    message.conditionAbsoluteVolume !== undefined &&
      (obj.conditionAbsoluteVolume = message.conditionAbsoluteVolume
        ? Decimal.toJSON(message.conditionAbsoluteVolume)
        : undefined);
    message.conditionOppositeVolumeRatio !== undefined &&
      (obj.conditionOppositeVolumeRatio = message.conditionOppositeVolumeRatio);
    message.conditionBbaVolumeRatio !== undefined && (obj.conditionBbaVolumeRatio = message.conditionBbaVolumeRatio);
    return obj;
  },

  create<I extends Exact<DeepPartial<SecondaryOrdersPayUp>, I>>(base?: I): SecondaryOrdersPayUp {
    return SecondaryOrdersPayUp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SecondaryOrdersPayUp>, I>>(object: I): SecondaryOrdersPayUp {
    const message = createBaseSecondaryOrdersPayUp();
    message.offset = object.offset ?? 0;
    message.initialOffset = object.initialOffset ?? 0;
    message.conditionTimeout = object.conditionTimeout ?? 0;
    message.uint32ConditionAbsoluteVolume = object.uint32ConditionAbsoluteVolume ?? 0;
    message.conditionAbsoluteVolume =
      object.conditionAbsoluteVolume !== undefined && object.conditionAbsoluteVolume !== null
        ? Decimal.fromPartial(object.conditionAbsoluteVolume)
        : undefined;
    message.conditionOppositeVolumeRatio = object.conditionOppositeVolumeRatio ?? 0;
    message.conditionBbaVolumeRatio = object.conditionBbaVolumeRatio ?? 0;
    return message;
  },
};

function createBaseSecondaryOrdersTrailing(): SecondaryOrdersTrailing {
  return {
    trailingOffset: 0,
    trailingPeg: 0,
    initialOffset: 0,
    maxTrail: 0,
    uint32ConditionAbsoluteVolume: 0,
    conditionAbsoluteVolume: undefined,
    conditionOppositeVolumeRatio: 0,
    conditionBbaVolumeRatio: 0,
  };
}

export const SecondaryOrdersTrailing = {
  encode(message: SecondaryOrdersTrailing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.trailingOffset !== 0) {
      writer.uint32(9).double(message.trailingOffset);
    }
    if (message.trailingPeg !== 0) {
      writer.uint32(16).uint32(message.trailingPeg);
    }
    if (message.initialOffset !== 0) {
      writer.uint32(25).double(message.initialOffset);
    }
    if (message.maxTrail !== 0) {
      writer.uint32(33).double(message.maxTrail);
    }
    if (message.uint32ConditionAbsoluteVolume !== 0) {
      writer.uint32(40).uint32(message.uint32ConditionAbsoluteVolume);
    }
    if (message.conditionAbsoluteVolume !== undefined) {
      Decimal.encode(message.conditionAbsoluteVolume, writer.uint32(66).fork()).ldelim();
    }
    if (message.conditionOppositeVolumeRatio !== 0) {
      writer.uint32(49).double(message.conditionOppositeVolumeRatio);
    }
    if (message.conditionBbaVolumeRatio !== 0) {
      writer.uint32(57).double(message.conditionBbaVolumeRatio);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecondaryOrdersTrailing {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecondaryOrdersTrailing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.trailingOffset = reader.double();
          break;
        case 2:
          message.trailingPeg = reader.uint32();
          break;
        case 3:
          message.initialOffset = reader.double();
          break;
        case 4:
          message.maxTrail = reader.double();
          break;
        case 5:
          message.uint32ConditionAbsoluteVolume = reader.uint32();
          break;
        case 8:
          message.conditionAbsoluteVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.conditionOppositeVolumeRatio = reader.double();
          break;
        case 7:
          message.conditionBbaVolumeRatio = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecondaryOrdersTrailing {
    return {
      trailingOffset: isSet(object.trailingOffset) ? Number(object.trailingOffset) : 0,
      trailingPeg: isSet(object.trailingPeg) ? Number(object.trailingPeg) : 0,
      initialOffset: isSet(object.initialOffset) ? Number(object.initialOffset) : 0,
      maxTrail: isSet(object.maxTrail) ? Number(object.maxTrail) : 0,
      uint32ConditionAbsoluteVolume: isSet(object.uint32ConditionAbsoluteVolume)
        ? Number(object.uint32ConditionAbsoluteVolume)
        : 0,
      conditionAbsoluteVolume: isSet(object.conditionAbsoluteVolume)
        ? Decimal.fromJSON(object.conditionAbsoluteVolume)
        : undefined,
      conditionOppositeVolumeRatio: isSet(object.conditionOppositeVolumeRatio) ? Number(object.conditionOppositeVolumeRatio) : 0,
      conditionBbaVolumeRatio: isSet(object.conditionBbaVolumeRatio) ? Number(object.conditionBbaVolumeRatio) : 0,
    };
  },

  toJSON(message: SecondaryOrdersTrailing): unknown {
    const obj: any = {};
    message.trailingOffset !== undefined && (obj.trailingOffset = message.trailingOffset);
    message.trailingPeg !== undefined && (obj.trailingPeg = Math.round(message.trailingPeg));
    message.initialOffset !== undefined && (obj.initialOffset = message.initialOffset);
    message.maxTrail !== undefined && (obj.maxTrail = message.maxTrail);
    message.uint32ConditionAbsoluteVolume !== undefined &&
      (obj.uint32ConditionAbsoluteVolume = Math.round(message.uint32ConditionAbsoluteVolume));
    message.conditionAbsoluteVolume !== undefined &&
      (obj.conditionAbsoluteVolume = message.conditionAbsoluteVolume
        ? Decimal.toJSON(message.conditionAbsoluteVolume)
        : undefined);
    message.conditionOppositeVolumeRatio !== undefined &&
      (obj.conditionOppositeVolumeRatio = message.conditionOppositeVolumeRatio);
    message.conditionBbaVolumeRatio !== undefined && (obj.conditionBbaVolumeRatio = message.conditionBbaVolumeRatio);
    return obj;
  },

  create<I extends Exact<DeepPartial<SecondaryOrdersTrailing>, I>>(base?: I): SecondaryOrdersTrailing {
    return SecondaryOrdersTrailing.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SecondaryOrdersTrailing>, I>>(object: I): SecondaryOrdersTrailing {
    const message = createBaseSecondaryOrdersTrailing();
    message.trailingOffset = object.trailingOffset ?? 0;
    message.trailingPeg = object.trailingPeg ?? 0;
    message.initialOffset = object.initialOffset ?? 0;
    message.maxTrail = object.maxTrail ?? 0;
    message.uint32ConditionAbsoluteVolume = object.uint32ConditionAbsoluteVolume ?? 0;
    message.conditionAbsoluteVolume =
      object.conditionAbsoluteVolume !== undefined && object.conditionAbsoluteVolume !== null
        ? Decimal.fromPartial(object.conditionAbsoluteVolume)
        : undefined;
    message.conditionOppositeVolumeRatio = object.conditionOppositeVolumeRatio ?? 0;
    message.conditionBbaVolumeRatio = object.conditionBbaVolumeRatio ?? 0;
    return message;
  },
};

function createBaseMarketTakingParameters(): MarketTakingParameters {
  return { allocationPercent: 0, workingTimeout: 0, orderType: 0 };
}

export const MarketTakingParameters = {
  encode(message: MarketTakingParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.allocationPercent !== 0) {
      writer.uint32(9).double(message.allocationPercent);
    }
    if (message.workingTimeout !== 0) {
      writer.uint32(16).uint32(message.workingTimeout);
    }
    if (message.orderType !== 0) {
      writer.uint32(24).uint32(message.orderType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketTakingParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketTakingParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.allocationPercent = reader.double();
          break;
        case 2:
          message.workingTimeout = reader.uint32();
          break;
        case 3:
          message.orderType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketTakingParameters {
    return {
      allocationPercent: isSet(object.allocationPercent) ? Number(object.allocationPercent) : 0,
      workingTimeout: isSet(object.workingTimeout) ? Number(object.workingTimeout) : 0,
      orderType: isSet(object.orderType) ? Number(object.orderType) : 0,
    };
  },

  toJSON(message: MarketTakingParameters): unknown {
    const obj: any = {};
    message.allocationPercent !== undefined && (obj.allocationPercent = message.allocationPercent);
    message.workingTimeout !== undefined && (obj.workingTimeout = Math.round(message.workingTimeout));
    message.orderType !== undefined && (obj.orderType = Math.round(message.orderType));
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketTakingParameters>, I>>(base?: I): MarketTakingParameters {
    return MarketTakingParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketTakingParameters>, I>>(object: I): MarketTakingParameters {
    const message = createBaseMarketTakingParameters();
    message.allocationPercent = object.allocationPercent ?? 0;
    message.workingTimeout = object.workingTimeout ?? 0;
    message.orderType = object.orderType ?? 0;
    return message;
  },
};

function createBaseMarketMakingParameters(): MarketMakingParameters {
  return {
    allocationPercent: 0,
    uint32VisibleQty: 0,
    uint32MinVisibleQty: 0,
    uint32NativeVisibleQty: 0,
    visibleQty: undefined,
    minVisibleQty: undefined,
    nativeVisibleQty: undefined,
  };
}

export const MarketMakingParameters = {
  encode(message: MarketMakingParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.allocationPercent !== 0) {
      writer.uint32(9).double(message.allocationPercent);
    }
    if (message.uint32VisibleQty !== 0) {
      writer.uint32(16).uint32(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== 0) {
      writer.uint32(24).uint32(message.uint32MinVisibleQty);
    }
    if (message.uint32NativeVisibleQty !== 0) {
      writer.uint32(32).uint32(message.uint32NativeVisibleQty);
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(42).fork()).ldelim();
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(50).fork()).ldelim();
    }
    if (message.nativeVisibleQty !== undefined) {
      Decimal.encode(message.nativeVisibleQty, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketMakingParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketMakingParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.allocationPercent = reader.double();
          break;
        case 2:
          message.uint32VisibleQty = reader.uint32();
          break;
        case 3:
          message.uint32MinVisibleQty = reader.uint32();
          break;
        case 4:
          message.uint32NativeVisibleQty = reader.uint32();
          break;
        case 5:
          message.visibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.nativeVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketMakingParameters {
    return {
      allocationPercent: isSet(object.allocationPercent) ? Number(object.allocationPercent) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? Number(object.uint32VisibleQty) : 0,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? Number(object.uint32MinVisibleQty) : 0,
      uint32NativeVisibleQty: isSet(object.uint32NativeVisibleQty) ? Number(object.uint32NativeVisibleQty) : 0,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      nativeVisibleQty: isSet(object.nativeVisibleQty) ? Decimal.fromJSON(object.nativeVisibleQty) : undefined,
    };
  },

  toJSON(message: MarketMakingParameters): unknown {
    const obj: any = {};
    message.allocationPercent !== undefined && (obj.allocationPercent = message.allocationPercent);
    message.uint32VisibleQty !== undefined && (obj.uint32VisibleQty = Math.round(message.uint32VisibleQty));
    message.uint32MinVisibleQty !== undefined && (obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty));
    message.uint32NativeVisibleQty !== undefined && (obj.uint32NativeVisibleQty = Math.round(message.uint32NativeVisibleQty));
    message.visibleQty !== undefined && (obj.visibleQty = message.visibleQty ? Decimal.toJSON(message.visibleQty) : undefined);
    message.minVisibleQty !== undefined &&
      (obj.minVisibleQty = message.minVisibleQty ? Decimal.toJSON(message.minVisibleQty) : undefined);
    message.nativeVisibleQty !== undefined &&
      (obj.nativeVisibleQty = message.nativeVisibleQty ? Decimal.toJSON(message.nativeVisibleQty) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketMakingParameters>, I>>(base?: I): MarketMakingParameters {
    return MarketMakingParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketMakingParameters>, I>>(object: I): MarketMakingParameters {
    const message = createBaseMarketMakingParameters();
    message.allocationPercent = object.allocationPercent ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.uint32NativeVisibleQty = object.uint32NativeVisibleQty ?? 0;
    message.visibleQty =
      object.visibleQty !== undefined && object.visibleQty !== null ? Decimal.fromPartial(object.visibleQty) : undefined;
    message.minVisibleQty =
      object.minVisibleQty !== undefined && object.minVisibleQty !== null ? Decimal.fromPartial(object.minVisibleQty) : undefined;
    message.nativeVisibleQty =
      object.nativeVisibleQty !== undefined && object.nativeVisibleQty !== null
        ? Decimal.fromPartial(object.nativeVisibleQty)
        : undefined;
    return message;
  },
};

function createBaseSyntheticLiquidate(): SyntheticLiquidate {
  return { accountId: 0, orderId: '', hangId: 0, whenUtcTime: 0, whenUtcTimestamp: undefined };
}

export const SyntheticLiquidate = {
  encode(message: SyntheticLiquidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.orderId !== '') {
      writer.uint32(18).string(message.orderId);
    }
    if (message.hangId !== 0) {
      writer.uint32(24).sint32(message.hangId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(32).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticLiquidate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticLiquidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.orderId = reader.string();
          break;
        case 3:
          message.hangId = reader.sint32();
          break;
        case 4:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SyntheticLiquidate {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      hangId: isSet(object.hangId) ? Number(object.hangId) : 0,
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
    };
  },

  toJSON(message: SyntheticLiquidate): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.hangId !== undefined && (obj.hangId = Math.round(message.hangId));
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticLiquidate>, I>>(base?: I): SyntheticLiquidate {
    return SyntheticLiquidate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SyntheticLiquidate>, I>>(object: I): SyntheticLiquidate {
    const message = createBaseSyntheticLiquidate();
    message.accountId = object.accountId ?? 0;
    message.orderId = object.orderId ?? '';
    message.hangId = object.hangId ?? 0;
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseSyntheticScratch(): SyntheticScratch {
  return { accountId: 0, orderId: '', hangId: 0, whenUtcTime: 0, whenUtcTimestamp: undefined };
}

export const SyntheticScratch = {
  encode(message: SyntheticScratch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.orderId !== '') {
      writer.uint32(18).string(message.orderId);
    }
    if (message.hangId !== 0) {
      writer.uint32(24).sint32(message.hangId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(32).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticScratch {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticScratch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.orderId = reader.string();
          break;
        case 3:
          message.hangId = reader.sint32();
          break;
        case 4:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SyntheticScratch {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      hangId: isSet(object.hangId) ? Number(object.hangId) : 0,
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
    };
  },

  toJSON(message: SyntheticScratch): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.hangId !== undefined && (obj.hangId = Math.round(message.hangId));
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticScratch>, I>>(base?: I): SyntheticScratch {
    return SyntheticScratch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SyntheticScratch>, I>>(object: I): SyntheticScratch {
    const message = createBaseSyntheticScratch();
    message.accountId = object.accountId ?? 0;
    message.orderId = object.orderId ?? '';
    message.hangId = object.hangId ?? 0;
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseGoMarket(): GoMarket {
  return { accountId: 0, orderId: '', hangId: 0, whenUtcTime: 0, whenUtcTimestamp: undefined };
}

export const GoMarket = {
  encode(message: GoMarket, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.orderId !== '') {
      writer.uint32(18).string(message.orderId);
    }
    if (message.hangId !== 0) {
      writer.uint32(24).sint32(message.hangId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(32).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoMarket {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoMarket();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.orderId = reader.string();
          break;
        case 3:
          message.hangId = reader.sint32();
          break;
        case 4:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GoMarket {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      hangId: isSet(object.hangId) ? Number(object.hangId) : 0,
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
    };
  },

  toJSON(message: GoMarket): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.hangId !== undefined && (obj.hangId = Math.round(message.hangId));
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<GoMarket>, I>>(base?: I): GoMarket {
    return GoMarket.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GoMarket>, I>>(object: I): GoMarket {
    const message = createBaseGoMarket();
    message.accountId = object.accountId ?? 0;
    message.orderId = object.orderId ?? '';
    message.hangId = object.hangId ?? 0;
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
