import { IStrategy } from '../types/IStrategy';
import { getProtectedPositionsType, getTotalProtectedBushels, isProtected, ProtectedPositionsTypes } from './protectedPosition';
import { IPositionProtectionInfo, isLongCallOption, isLongPutOption, isShortCallOption, isShortFutures } from './strategiesTypes';
import { sum } from 'lodash';
import { FuturesContractType, PositionType, OptionContractType } from '../types/IPosition';

export function calculateWeightedAverageProtectedFloor(strategies: IStrategy[]): number {
  const protectedStrategies = strategies.filter((strategy) => isProtected(strategy.legs));

  if (!protectedStrategies.length) {
    return 0;
  }

  let result = 0;
  let totalContractedBushels = 0;

  protectedStrategies.forEach((strategy) => {
    const protectionType = getProtectedPositionsType(strategy.legs);
    if (protectionType) {
      const protectedFloor = calculateFloorByProtectionType(strategy.legs, protectionType);
      if (protectedFloor) {
        const totalBushels = getTotalProtectedBushels(strategy.legs);
        result += protectedFloor * totalBushels;
        totalContractedBushels += totalBushels;
      }
    }
  });

  if (!totalContractedBushels) {
    return 0;
  }

  result = result / totalContractedBushels;

  return result;
}

export function calculateFloorByProtectionType(legs: IPositionProtectionInfo[], protectionType: ProtectedPositionsTypes): number {
  let result = 0;

  switch (protectionType) {
    case ProtectedPositionsTypes.SINGLE_LEG_LONG_PUT:
      result = calculateSingleLegLongPutFloor(legs);
      break;
    case ProtectedPositionsTypes.SINGLE_LEG_SHORT_FUTURES:
      result = calculateSingleLegShortFuturesFloor(legs);
      break;
    case ProtectedPositionsTypes.SYNTHETIC_LONG_PUT:
      result = calculateSyntheticLongPutFloor(legs);
      break;
    case ProtectedPositionsTypes.SYNTHETIC_SHORT_FUTURES:
      result = calculateSyntheticShortFuturesFloor(legs);
      break;
    case ProtectedPositionsTypes.SYNTHETIC_SHORT_FUTURES_REVERSAL:
      result = calculateSyntheticShortFuturesFloor(legs);
      break;
    case ProtectedPositionsTypes.SYNTHETIC_PUT_SPREAD:
      result = calculateSyntheticPutSpreadFloor(legs);
      break;
    case ProtectedPositionsTypes.BASIC_PROTECTION:
      result = calculateBasicProtectionFloor(legs);
  }

  return result;
}

function calculateSingleLegLongPutFloor(legs: IPositionProtectionInfo[]): number {
  const [leg] = legs;

  const strikePrice = Number(leg.strikePrice || 0);
  const premiumCost = Number(leg.premiumCost || 0);

  return strikePrice - premiumCost;
}

function calculateSingleLegShortFuturesFloor(legs: IPositionProtectionInfo[]): number {
  const [leg] = legs;
  const futuresPrice = Number(leg.futuresPrice || 0);
  return futuresPrice;
}

function calculateSyntheticLongPutFloor(legs: IPositionProtectionInfo[]): number {
  const futuresLeg = legs.find(isShortFutures);
  const optionLeg = legs.find(isLongCallOption);

  const futuresPrice = Number(futuresLeg?.futuresPrice || 0);
  const premiumCost = Number(optionLeg?.premiumCost || 0);

  return futuresPrice - premiumCost;
}

function calculateSyntheticShortFuturesFloor(legs: IPositionProtectionInfo[]): number {
  const longPutOption = legs.find(isLongPutOption);
  const shortCallOption = legs.find(isShortCallOption);

  const strikePrice = Number(longPutOption?.strikePrice || 0);
  const longPutOptionPremiumCost = Number(longPutOption?.premiumCost || 0);
  const shortCallOptionPremiumCost = Number(shortCallOption?.premiumCost || 0);

  return strikePrice + shortCallOptionPremiumCost - longPutOptionPremiumCost;
}

function calculateSyntheticPutSpreadFloor(legs: IPositionProtectionInfo[]): number {
  const shortFutures = legs.find(isShortFutures);
  const longCallOption = legs.find(isLongCallOption);
  const shortCallOption = legs.find(isShortCallOption);

  const futuresPrice = Number(shortFutures?.futuresPrice || 0);
  const longCallPremiumCost = Number(longCallOption?.premiumCost || 0);
  const shortCallPremiumCost = Number(shortCallOption?.premiumCost || 0);

  return futuresPrice + shortCallPremiumCost - longCallPremiumCost;
}

function calculateBasicProtectionFloor(legs: IPositionProtectionInfo[]): number {
  const shortFutures = legs.filter(isShortFutures);
  const longPutOptions = legs.filter(isLongPutOption);
  const allProtectiveLegs = [...shortFutures, ...longPutOptions];
  const nonProtectiveLegs = legs.filter((x) => !allProtectiveLegs.includes(x));

  // easy stuff first
  // just short futures
  if (shortFutures.length && !longPutOptions.length && !nonProtectiveLegs.length) {
    return weightedAverage(shortFutures);
  }

  // just long puts
  if (longPutOptions.length && !shortFutures.length && !nonProtectiveLegs.length) {
    return weightedAverage(longPutOptions);
  }

  // just speculation
  if (!longPutOptions.length && !shortFutures.length && nonProtectiveLegs.length) {
    return 0;
  }

  // OK, now handle mixed situations
  const protectedFloor = weightedAverage(allProtectiveLegs);
  // use protectedBushels?
  const protectedQuantity = sum(allProtectiveLegs.map((x) => x.quantitySold || 0));

  if (!nonProtectiveLegs.length) {
    return protectedFloor;
  }

  // this should be a positive value. premiums of long options should be positive.
  // premiums of short options should be negative as to reduce the total
  const nonProtectivePremiumsAndFees = sum(
    nonProtectiveLegs.map((x) => {
      if (x.type === PositionType.Futures && x.contractType === FuturesContractType.Buy) {
        return (x.futuresPrice || 0) * (x.quantitySold || 0);
      }
      if (x.type === PositionType.Option) {
        if (x.contractType === OptionContractType.BuyCall) {
          return (x.premiumCost || 0) * (x.quantitySold || 0);
        }
        if (x.contractType === OptionContractType.SellPut) {
          return (0 - (x.premiumCost || 0)) * (x.quantitySold || 0);
        }
        if (x.contractType === OptionContractType.SellCall) {
          return (0 - (x.premiumCost || 0)) * (x.quantitySold || 0);
        }
      }
      return 0;
    })
  );

  // yeah, we kinda have to reverse out of the weighted avg floor to do this last bit
  // ... we could clean up the architecture here to avoid this
  const totalProtectionAmount = protectedFloor * protectedQuantity;
  const totalProtectionLessFeesAndPremiums = totalProtectionAmount - nonProtectivePremiumsAndFees;

  return totalProtectionLessFeesAndPremiums / protectedQuantity;
}

function weightedAverage(legs: IPositionProtectionInfo[]) {
  const dividend = sum(
    legs.map((x) => {
      if (x.type === PositionType.Futures && x.contractType === FuturesContractType.Sell) {
        return (x.quantitySold || 0) * (x.futuresPrice || 0);
      } else if (x.type === PositionType.Option && x.contractType === OptionContractType.BuyPut) {
        return (x.quantitySold || 0) * ((x.strikePrice || 0) - (x.premiumCost || 0));
      }

      return 0;
    })
  );
  const divisor = sum(legs.map((x) => x.quantitySold || 0));

  return divisor > 0 ? dividend / divisor : 0;
}
