import React from 'react';
import { RequireAtLeastOne } from '../../models/helpers';
import styles from './RedGreen.module.scss';

interface RedGreenProps {
  sign?: 'positive' | 'negative';
  number?: number;
  content: string | JSX.Element;
  reversed?: boolean;
}

export function RedGreen(props: RequireAtLeastOne<RedGreenProps, 'sign' | 'number'>) {
  const { sign = 'positive', number = 0, content, reversed = false } = props;
  let classSign = sign;
  if (number < 0) {
    classSign = 'negative';
  }
  if (reversed) {
    classSign = classSign === 'negative' ? 'positive' : 'negative';
  }
  return <span className={`${styles['fr-redgreen']} ${classSign}`}>{content}</span>;
}
