import React, { useEffect, useMemo } from 'react';
import TabSwitcher from '../../common/TabSwitcher/TabSwitcher';
import { BudgetType, budgetTypeNames } from '../../../types/IBudget';
import Tab from '../../common/TabSwitcher/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { selectBudgetType, updateCostsPageBudgetType } from '../../../store/PagesConfigs/Costs/costsSlice';
import { LoadingStatuses } from '../../../store/LoadingStatuses';
import { fetchDefaultUniversityBudgets } from '../../../store/Budgets/thunks';
import { RootState } from '../../../types/Store';
import { createSelector } from '@reduxjs/toolkit';
import { selectBudgets } from '../../../store/Budgets/BudgetsSlice';

const makeSelectActiveBudget = () =>
  createSelector(
    selectBudgets,
    (_: RootState, productionCycle: string) => productionCycle,
    (budgets, productionCycle) =>
      budgets.filter((budget) => budget.year === productionCycle && budget.operationId !== null && budget.isActive)[0]
  );

const makeSelectDefaultUniversityBudgets = () =>
  createSelector(selectBudgets, (budgets) =>
    budgets.filter((budget) => budget.budgetType === BudgetType.University && budget.operationId === null && budget.isActive)
  );

function CostsBudgetModeSwitcher() {
  const productionCycle = useSelector((state: RootState) => state.productionCycles.selectedYear);
  const selectedBudgetType = useSelector(selectBudgetType);
  const budgetStatus = useSelector((state: RootState) => state.budgets.status);
  const selectDefaultUniversityBudgets = useMemo(makeSelectDefaultUniversityBudgets, []);
  const defaultUniversityBudgets = useSelector((state: RootState) => selectDefaultUniversityBudgets(state));
  const selectActiveBudget = useMemo(makeSelectActiveBudget, [productionCycle]);
  const activeBudget = useSelector((state: RootState) => selectActiveBudget(state, productionCycle));
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (selectedBudgetType === BudgetType.University || selectedBudgetType === BudgetType.Custom) &&
      budgetStatus !== LoadingStatuses.Loading &&
      budgetStatus !== LoadingStatuses.Failed &&
      !defaultUniversityBudgets.length
    ) {
      dispatch(fetchDefaultUniversityBudgets());
    }
  }, [dispatch, budgetStatus, selectedBudgetType, defaultUniversityBudgets]);

  useEffect(() => {
    if (!selectedBudgetType && activeBudget) {
      dispatch(updateCostsPageBudgetType(activeBudget.budgetType));
    }
  }, [dispatch, selectedBudgetType, activeBudget]);

  return (
    <TabSwitcher
      children={Object.keys(budgetTypeNames)
        .map((budgetType) => Number(budgetType))
        .map((budgetType) => (
          <Tab
            key={budgetType}
            tabClassName="tab-button"
            selectedClassName="tab-button-selected"
            onClick={() => dispatch(updateCostsPageBudgetType(budgetType))}
            name={budgetTypeNames[budgetType]}
            active={budgetType === selectedBudgetType}
          />
        ))}
    />
  );
}

export default CostsBudgetModeSwitcher;
