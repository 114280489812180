import { UseQueryResult } from '@tanstack/react-query';

import { CommodityInformation } from '@harvestiq/constants';
import { GetCropPricesOutput } from '../../../queries/marketData';
import FuturesPrices from './FuturesPrices';
import { transformCommodityInformationIntoFuturesPrices } from './FuturesPrices';

interface CropsFuturesPricesProps {
  cropPricesQuery: UseQueryResult<GetCropPricesOutput>;
  commodityInformation: CommodityInformation[];
}

function CropsFuturesPrices({ cropPricesQuery, commodityInformation }: CropsFuturesPricesProps) {
  if (!cropPricesQuery.data) {
    return <></>;
  }
  const cropPrices = cropPricesQuery.data;

  const quotesLastUpdate = Object.values(cropPrices)[0]?.newMarketPrices?.serverTimestampStr || '';

  const filteredCommodityInformation = commodityInformation.filter(
    ({ isLivestock }) => !isLivestock // filter out livestock
  );

  const futuresPrices = transformCommodityInformationIntoFuturesPrices(filteredCommodityInformation, cropPrices);

  return (
    <FuturesPrices prices={futuresPrices} oldPriceLabel="Old Crop" newPriceLabel="New Crop" quotesLastUpdate={quotesLastUpdate} />
  );
}

export default CropsFuturesPrices;
