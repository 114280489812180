import styles from './TradeButton.module.scss';

import { ButtonsPopupSettings, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { isEmpty } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../../config';
import { useAllHedgingCrops } from '../../../queries/operationCrops';
import useCanTrade from '../../CQG/useCanTrade';
import SplitButton from '../../common-next/SplitButton/SplitButton';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import useCurrentRole from '../../../customHooks/useCurrentRole';

enum ButtonType {
  Trade,
  TradeActivity,
}

export function TradeButton() {
  const isDev = config.env === 'development';
  const history = useHistory();
  const currentLocation = history.location.pathname;
  const hedgingCropsQry = useAllHedgingCrops();
  const canTradeQry = useCanTrade();
  const currentOperation = useCurrentOperation();
  const currentRole = useCurrentRole();

  const hedgingCrops = hedgingCropsQry.data || [];
  const hasAtLeastOneCrop = !isEmpty(hedgingCrops);
  const canTrade = canTradeQry.canTrade || false;

  const showTradeButtons = canTrade || ((currentOperation.tradingEnabled ?? true) && (currentRole?.executeTrades ?? false));
  const showTradePreview = !canTrade && !isDev;
  const readyToTrade = (hasAtLeastOneCrop && canTrade) || isDev;

  const items = [{ text: 'Trade', type: ButtonType.Trade }];
  if (readyToTrade) {
    items.push({ text: 'View Trade Activity', type: ButtonType.TradeActivity });
  }
  const popupSettings: ButtonsPopupSettings = {
    anchorAlign: { horizontal: 'right', vertical: 'bottom' },
    popupAlign: { horizontal: 'right', vertical: 'top' },
  };
  const onItemClick = (e: SplitButtonItemClickEvent) => {
    const isTradeActivity = e.item.type === ButtonType.TradeActivity;
    showTrade(isTradeActivity);
  };
  const showTrade = (isActivity?: boolean) => {
    const target = isActivity ? 'trade-activity' : 'trade';
    if (showTradePreview) {
      history.push(`${currentLocation}/trade-preview`);
    } else {
      history.push(`${currentLocation}/${target}`);
    }
  };

  if (!showTradeButtons) return null;

  return (
    <div className={`${styles['fr-nav-trade-button']} d-print-none`}>
      <SplitButton
        text="Trade"
        items={items}
        textField="text"
        size="medium"
        popupSettings={popupSettings}
        onItemClick={onItemClick}
        onButtonClick={() => {
          showTrade();
        }}
        disabled={!readyToTrade && !showTradePreview}
      />
    </div>
  );
}
