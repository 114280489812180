import React from 'react';
import './SelectWithLabel.css';
import Select, { components } from 'react-select';
import { FontAwesomeIcon, IconDefinition, faCaretDown } from '../../faIcons';

const userAgent = navigator.userAgent;
const browserIsChrome = !!userAgent.match(/chrome|chromium|crios/i);

const customStyles = (withError = false) => {
  return {
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    container: (styles: any, state: any) => ({
      ...styles,
      marginTop: '4px',
      width: 'inherit',
      padding: 0,
      backgroundColor: state.isDisabled ? '#e0e4e845' : '#FFFFFF',
      border: `1px solid ${withError ? '#AB141D' : '#E0E4E8'}`,
      outline: state.isFocused ? (browserIsChrome ? '5px auto -webkit-focus-ring-color' : '5px auto Highlight') : '',
      borderRadius: '2px',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      cursor: 'pointer',
    }),
    control: () => ({
      cursor: 'default',
      width: 'inherit',
      height: '38px',
      display: 'flex',
      justifyContent: 'space-between',
    }),
    singleValue: (styles: any) => ({ ...styles, color: '#000000' }),
    dropdownIndicator: (styles: any) => ({ ...styles, color: '#000000' }),
    option: (styles: any, state: any) => ({
      ...styles,
      textAlign: 'left',
      color: state.isFocused ? '#AB141D' : '#000000',
      backgroundColor: state.isSelected ? 'rgba(171, 20, 29, 0.1)' : '#FFFFFF',
      transition: '0.15s',
      cursor: 'pointer',
    }),
  };
};

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon={faCaretDown as IconDefinition} />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export default function SelectWithLabel<T = string>(props: SelectWithLabelProps<T>) {
  const {
    label,
    options,
    selected,
    onChange,
    disabled,
    validationErrorMessage,
    className = '',
    redBorder = false,
    isSearchable = false,
    isClearable = false,
    backspaceRemovesValue = false,
    required = false,
    onInputChange = () => {},
    menuPlacement = 'auto',
    width,
    autoFocus,
  } = props;
  return (
    <div className={`select-with-label ${className}`.trim()} style={{ width }}>
      <label>
        {label}
        {required && <span className="required-label">*</span>}
      </label>
      <div
        style={{
          cursor: disabled ? 'not-allowed' : 'default',
          width: 'inherit',
        }}
      >
        <Select
          autoFocus={autoFocus}
          options={options}
          onChange={onChange}
          components={{ DropdownIndicator }}
          value={selected}
          styles={customStyles(redBorder)}
          isDisabled={disabled}
          input
          isSearchable={isSearchable}
          onInputChange={onInputChange}
          menuPlacement={menuPlacement}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
        />
      </div>
      {validationErrorMessage && <span className="input-with-label-error-message">{validationErrorMessage}</span>}
    </div>
  );
}

type SelectWithLabelProps<T> = {
  label: string;
  options: SelectorTypes<T>[];
  selected: any;
  onChange?: (e: SelectorTypes<T>) => void;
  disabled?: boolean;
  validationErrorMessage?: string | null;
  className?: string;
  redBorder?: boolean;
  isSearchable?: boolean;
  onInputChange?: (value: string) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  isClearable?: boolean;
  backspaceRemovesValue?: boolean;
  required?: boolean;
  width?: string | number;
  autoFocus?: boolean;
};

export type SelectorTypes<Type = string> = {
  value: Type;
  label: string;
};
