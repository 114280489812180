import { createSlice } from '@reduxjs/toolkit';
import ErrorToast from '../../components/Toast/ErrorToast';
import { IHistoryQuote } from '../../types/IHistoryQuote';
import { LoadingStatuses } from '../LoadingStatuses';
import { fetchHistoryQuotes } from './thunks';

export type HistoryQuotesStoreItem = {
  [symbol: string]: IHistoryQuote[];
};

type HistoryQuotesSliceInitialStateType = {
  historyQuotes: HistoryQuotesStoreItem;
  status: string;
  error: string | null;
};

const initialState: HistoryQuotesSliceInitialStateType = {
  historyQuotes: {},
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'historyQuotes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchHistoryQuotes.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchHistoryQuotes.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.historyQuotes = { ...state.historyQuotes, ...prepareHistoryQuotes(action.meta.arg, action.payload) };
    },
    [fetchHistoryQuotes.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load prices history!');
    },
  },
});

export function prepareHistoryQuotes(arg: object, historyQuotes: IHistoryQuote[]): HistoryQuotesStoreItem {
  return { [JSON.stringify(arg)]: historyQuotes };
}

export default slice.reducer;
