import { UseQueryResult } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { DropDownListChangeEvent } from '../../../../components/common-next/DropDownList/DropDownList';
import OperationCropDropDownNavigator from '../../../../components/common-next/DropDownList/OperationCropDropDownNavigator';
import Loader from '../../../../components/common/Loader/Loader';
import Tab from '../../../../components/common/TabSwitcher/Tab';
import TabSwitcher from '../../../../components/common/TabSwitcher/TabSwitcher';
import CropsWarningRow from '../../../../components/common/Warnings/CropsWarningRow';
import useCropBudgets from '../../../../customHooks/useBudget';
import useCurrentCurrency from '../../../../customHooks/useCurrentCurrency';
import useCurrentProductionCycle from '../../../../customHooks/useCurrentProductionCycle';
import { getFuturesTradingCode } from '../../../../models/CropSymbols/cropSymbols';
import { getCropFutures } from '../../../../models/getMarketData';
import {
  areCostsFieldsMissing,
  areOperationCropFieldsMissing,
  getMissingOperationCropFields,
} from '../../../../models/operationCropMissingFields';
import {
  getMinPriceRangeValueByCrop,
  getPriceClass,
  getProfitTypes,
  ProfitTypeOption,
  roundOneDecimal,
} from '../../../../models/profitMatrix';
import {
  calculateMatrixItems,
  getMatrixPriceRange,
  getMatrixYieldRange,
  getPriceStepByCrop,
  MatrixItem,
  MatrixRanges,
  prepareSpotPrices,
  ProfitType,
} from '../../../../models/RiskAssessment/riskAssessment';
import { QuotesStoreItem } from '../../../../store/Quotes/quotesSlice';
import { SymbolLookUpsStoreItem } from '../../../../store/SymbolLookUps/symbolLookUpsSlice';
import { CommodityInformation } from '@harvestiq/constants';
import { Currency } from '@farmersrisk/shared/constants/currencies';
import { IOperationCropV2 } from '../../../../types/IOperationCrops';
import { formatNumber } from '../../../../utils/formatNumber';
import { Matrix, MatrixItemCell } from './Matrix';
import { MatrixRangesSliders } from './MatrixRangesSliders';
import './ProfitMatrix.css';
import { ProfitMatrixWarnings } from './ProfitMatrixWarnings';
import { OperationCropSummary } from '../../../../queries/operationCropSummaries';

type ProfitMatrixProps = {
  operationCropsQuery: UseQueryResult<IOperationCropV2[] | undefined>;
  queryCropSummaries: UseQueryResult<OperationCropSummary[] | undefined>;
  marketingCropsQuery: UseQueryResult<CommodityInformation[] | undefined>;
  quotesQuery: UseQueryResult<QuotesStoreItem | undefined>;
  symbolLookUpsQuery: UseQueryResult<SymbolLookUpsStoreItem | undefined>;
};

export default function ProfitMatrix(props: ProfitMatrixProps) {
  const { operationCropsQuery, queryCropSummaries, marketingCropsQuery, quotesQuery, symbolLookUpsQuery } = props;
  const productionCycle = useCurrentProductionCycle();
  const [ranges, setRanges] = useState<MatrixRanges>();
  const currency = useCurrentCurrency();
  const cropBudgets = useCropBudgets(productionCycle);
  const profitTypes: ProfitTypeOption[] = getProfitTypes(currency);
  const [selectedProfitType, setSelectedProfitType] = useState<ProfitTypeOption>(profitTypes[0]);
  const [isInsuranceEnabled, setDisplayInsurance] = useState(true);

  const symbolLookUps = symbolLookUpsQuery.data || {};
  const quotes = quotesQuery.data || {};
  const cropSummaries = queryCropSummaries.data || [];
  const operationCrops = operationCropsQuery.data || [];
  const marketingCrops = marketingCropsQuery.data || [];

  const [selectedCrop, setSelectedCrop] = useState<CommodityInformation>(marketingCrops[0]);

  const cropFutures = getCropFutures(marketingCrops, symbolLookUps);

  const setPriceAndYieldRanges = (ranges: MatrixRanges) => {
    setRanges(ranges);
  };

  useEffect(() => {
    if (!marketingCrops.includes(selectedCrop)) {
      setSelectedCrop(marketingCrops[0]);
    }
  }, [selectedCrop, marketingCrops]);

  if (
    quotes.isLoading ||
    symbolLookUpsQuery.isLoading ||
    queryCropSummaries.isLoading ||
    operationCropsQuery.isLoading ||
    marketingCropsQuery.isLoading ||
    (isEmpty(quotes) && quotesQuery.isFetching) ||
    (isEmpty(cropSummaries) && queryCropSummaries.isFetching)
  ) {
    return (
      <div className="card live-matrix">
        <Loader />
      </div>
    );
  }

  const operationCrop = operationCrops.find(
    (item) => item.marketingCropType === selectedCrop?.id && item.productionCycle === productionCycle
  );
  if (!operationCrop) {
    return (
      <div className="card live-matrix">
        <CropsWarningRow message={'Operation crop missing!'} />
      </div>
    );
  }
  const cropSummary = cropSummaries.find((x) => x.operationCropId === operationCrop.id);
  if (!cropSummary) {
    return (
      <div className="card live-matrix">
        <CropsWarningRow message={'Crop summary missing!'} />
      </div>
    );
  }
  const missingFields = getMissingOperationCropFields(
    operationCrop,
    cropBudgets[operationCrop.id] ?? cropBudgets[selectedCrop.id]
  );
  const spotPrices = prepareSpotPrices(quotes);
  const spotPrice = spotPrices[getFuturesTradingCode(operationCrop.productionCycle, selectedCrop, cropFutures)];

  const areAnyFieldsMissing = areOperationCropFieldsMissing(missingFields) || areCostsFieldsMissing(missingFields);
  const allFieldsPresent = !areAnyFieldsMissing;

  const matrixData = getMatrixData(
    allFieldsPresent,
    spotPrice,
    currency,
    ranges,
    operationCrop,
    cropSummary,
    selectedProfitType,
    isInsuranceEnabled
  );

  const switchProfitType = (profitType: ProfitTypeOption) => {
    setSelectedProfitType(profitType);
  };

  const switchCrop = (e: DropDownListChangeEvent) => {
    const value = e.value.value as IOperationCropV2;
    const crop = marketingCrops.find((x) => x.id === value.marketingCropType);
    if (crop) {
      setSelectedCrop(crop);
    }
  };

  const profitTypeTabs = () =>
    profitTypes.map((item) => (
      <Tab
        key={item.value}
        tabClassName="tab-button"
        selectedClassName="tab-button-selected"
        onClick={() => switchProfitType(item)}
        name={item.label}
        active={selectedProfitType?.value === item.value}
      />
    ));

  const priceStep = getPriceStepByCrop(selectedCrop.id);

  return (
    <div className="d-print-none card live-matrix">
      <div className="live-matrix-header">
        <label>Net Profit Matrix</label>
        <div className="live-matrix-insurance">
          <label>
            <Switch
              onChange={(value) => setDisplayInsurance(value)}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#1262AB"
              checked={isInsuranceEnabled}
            />
            <span>Include Crop Insurance</span>
          </label>
        </div>
      </div>
      <div className="live-matrix-tool-bar">
        <div className="first-row">
          {!!marketingCrops.length && selectedCrop && (
            <OperationCropDropDownNavigator
              includeSummary={false}
              onChange={switchCrop}
              selectedOperationCropId={selectedCrop.id}
              onlyCropsWithMarketingTypes={true}
            />
          )}
        </div>
        {allFieldsPresent && (
          <div className="second-row">
            {selectedCrop && productionCycle && (
              <MatrixRangesSliders
                priceStep={priceStep}
                spotPrice={spotPrice}
                expectedYield={operationCrop?.relevantYieldUOMPerAcre.toNumber() ?? 0}
                setRanges={setPriceAndYieldRanges}
                minPrice={getMinPriceRangeValueByCrop(selectedCrop.id, spotPrice, priceStep)}
              />
            )}
            {selectedProfitType && <TabSwitcher wrapperClassName="wrapper-profit-type-tabs" children={profitTypeTabs()} />}
          </div>
        )}
      </div>
      {!matrixData?.length && missingFields ? (
        <ProfitMatrixWarnings missingFields={missingFields} />
      ) : (
        <div className="overflow-auto">{!!matrixData?.length && <Matrix data={matrixData} />}</div>
      )}
    </div>
  );
}

export const defaultYieldStep = 5;

function getMatrixData(
  allFieldsPresent: boolean,
  spotPrice: number,
  currency: Currency,
  ranges: MatrixRanges | undefined,
  operationCrop: IOperationCropV2,
  cropSummary: OperationCropSummary,
  selectedProfitType: ProfitTypeOption,
  isInsuranceEnabled: boolean
): MatrixItemCell[][] {
  if (!allFieldsPresent || !spotPrice || !ranges) {
    return [];
  }

  const relevantYield = operationCrop.relevantYieldUOMPerAcre.toNumber();
  const matrixItems = calculateMatrixItems(operationCrop, cropSummary, ranges, selectedProfitType.value, isInsuranceEnabled);

  const priceRange = getMatrixPriceRange(ranges.priceRange.firstValue, operationCrop.marketingCropType, ranges.priceRange.length);
  const yieldRange = getMatrixYieldRange(ranges.yieldRange.firstValue, defaultYieldStep, ranges.yieldRange.length);

  return buildMatrixItemCells(matrixItems, priceRange, yieldRange, currency, selectedProfitType.value, spotPrice, relevantYield);
}

function buildMatrixItemCells(
  matrixItems: MatrixItem[][],
  priceRange: number[],
  yieldRange: number[],
  currency: Currency,
  profitType: ProfitType,
  spotPrice = 0,
  relevantYield = 0
) {
  const firstCell: MatrixItemCell = {
    value: '',
    class: '',
    title: '',
  };

  const result: MatrixItemCell[][] = [[firstCell]];
  let currentPriceIndex = -1;

  priceRange.forEach((item, index) => {
    if (roundOneDecimal(item) === roundOneDecimal(spotPrice)) {
      currentPriceIndex = index;
    }

    result[0].push({
      value: formatNumber(item, 2, currency),
      class: item === spotPrice ? 'highlighted-title' : '',
      title: '',
    });
  });

  matrixItems.forEach((row, rowIndex) => {
    const rowTitle = {
      value: yieldRange[rowIndex].toString(),
      class: yieldRange[rowIndex] === relevantYield ? 'highlighted-title' : '',
      title: '',
    };
    const newCells = row.map((item, index) => {
      const displayValue = profitType === ProfitType.TOTAL ? item.profitValue : item.profitPricePerAcre;
      const tooltip = `
Sold Revenue: ${formatNumber(item.soldRevenueValue.toNumber(), 0, currency)}
Unsold Revenue: ${formatNumber(item.unsoldRevenueValue.toNumber(), 0, currency)}
Hedging G/L: ${formatNumber(item.totalGainLossValue.toNumber(), 0, currency)}
Insurance Indemnity: ${formatNumber(item.insuranceIndemnityValue.toNumber(), 0, currency)}
      `.trim();
      return {
        value: formatNumber(displayValue.toNumber(), 0, currency),
        class: getPriceClass(displayValue.toNumber(), yieldRange[rowIndex] === relevantYield || currentPriceIndex === index),
        title: tooltip,
      };
    });
    result.push([rowTitle, ...newCells]);
  });

  return result;
}
