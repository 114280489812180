import {
  areCostsFieldsMissing,
  areOperationCropFieldsMissing,
  arePriceFieldsMissing,
  getMessageFromCostsMissingFields,
  getMessageFromOperationCropMissingFields,
  getMessageFromPriceMissingFields,
  MissingFields,
} from '../../../../models/operationCropMissingFields';
import WarningRow from '../../../../components/common/Warnings/WarningRow';
import React from 'react';
import { ICropContract } from '../../../../types/ICropContract';
import CropsWarningRow from '../../../../components/common/Warnings/CropsWarningRow';

type ProfitMatrixWarningsProps = {
  missingFields: MissingFields;
  cropContracts?: ICropContract[];
};

export function ProfitMatrixWarnings(props: ProfitMatrixWarningsProps) {
  const { missingFields, cropContracts } = props;
  const operationCropFieldsMissingMessage = getMessageFromOperationCropMissingFields(missingFields);
  const priceFieldsMissingMessage = getMessageFromPriceMissingFields(missingFields);
  const costsFieldsMissingMessage = getMessageFromCostsMissingFields(missingFields);

  return (
    <>
      {areOperationCropFieldsMissing(missingFields) && operationCropFieldsMissingMessage && (
        <CropsWarningRow message={operationCropFieldsMissingMessage} />
      )}
      {cropContracts && arePriceFieldsMissing(missingFields, cropContracts) && priceFieldsMissingMessage && (
        <WarningRow message={priceFieldsMissingMessage} linkTo="/settings/prices" linkCaption="Go to Prices" />
      )}
      {areCostsFieldsMissing(missingFields) && costsFieldsMissingMessage && (
        <WarningRow message={costsFieldsMissingMessage} linkTo="/settings/costs" linkCaption="Go to Costs" />
      )}
    </>
  );
}
