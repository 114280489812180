import styles from './CropSymbol.module.scss';

import React from 'react';
import { CommodityId, getCommodityByCommodityId } from '@harvestiq/constants';
import { luminance } from '../../../utils/color';

interface CropSymbolProps {
  cropType: CommodityId;
  size?: 'small' | 'medium';
}

export default function CropSymbol(props: CropSymbolProps) {
  const { cropType, size = 'medium' } = props;

  // TODO: get correct colors for crop types
  const cropTypeColorMap = {
    [CommodityId.UNKNOWN]: '#000000',
    [CommodityId.CORN]: '#FFDC85',
    [CommodityId.SOYBEANS]: '#98ac43',
    [CommodityId.KC_WINTER_WHEAT_HRW]: '#d6c8b2',
    [CommodityId.MN_SPRING_WHEAT_HRSW]: '#9b6e4d',
    [CommodityId.CHI_SOFT_RED_WINTER_SRW]: '#7a5a3f',
    [CommodityId.OATS]: '#f1e9d2',
    [CommodityId.SORGHUM]: '#5b4225',
    [CommodityId.ZUC]: '#000000',
    [CommodityId.LEAN_HOGS]: '#000000',
    [CommodityId.FEEDER_CATTLE]: '#000000',
    [CommodityId.LIVE_CATTLE]: '#000000',
    [CommodityId.SOYBEAN_MEAL]: '#000000',
  };

  const backgroundColor = cropTypeColorMap[cropType];
  // text color based on WCAG spec for contrast
  const color = luminance(backgroundColor) > 0.5 ? '#000000' : '#ffffff';

  return (
    <div className={`${styles['fr-crop-symbol']} fr-crop-symbol-${size}`} style={{ backgroundColor, color }}>
      <b>{getCommodityByCommodityId(cropType)?.tradingCode}</b>
    </div>
  );
}
