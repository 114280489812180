/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { Text } from '../common/shared_1';
import { Quote } from './market_data_2';

export const protobufPackage = 'historical_2';

/** Bar building tick type enumeration. */
export enum BarBuildingTickType {
  /** BAR_BUILDING_TICK_TYPE_BID - Bid. */
  BAR_BUILDING_TICK_TYPE_BID = 1,
  /** BAR_BUILDING_TICK_TYPE_ASK - Ask. */
  BAR_BUILDING_TICK_TYPE_ASK = 2,
  /** BAR_BUILDING_TICK_TYPE_TRADE - Trade. */
  BAR_BUILDING_TICK_TYPE_TRADE = 3,
  /** BAR_BUILDING_TICK_TYPE_SETTLEMENT - Settlement. */
  BAR_BUILDING_TICK_TYPE_SETTLEMENT = 4,
  UNRECOGNIZED = -1,
}

export function barBuildingTickTypeFromJSON(object: any): BarBuildingTickType {
  switch (object) {
    case 1:
    case 'BAR_BUILDING_TICK_TYPE_BID':
      return BarBuildingTickType.BAR_BUILDING_TICK_TYPE_BID;
    case 2:
    case 'BAR_BUILDING_TICK_TYPE_ASK':
      return BarBuildingTickType.BAR_BUILDING_TICK_TYPE_ASK;
    case 3:
    case 'BAR_BUILDING_TICK_TYPE_TRADE':
      return BarBuildingTickType.BAR_BUILDING_TICK_TYPE_TRADE;
    case 4:
    case 'BAR_BUILDING_TICK_TYPE_SETTLEMENT':
      return BarBuildingTickType.BAR_BUILDING_TICK_TYPE_SETTLEMENT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BarBuildingTickType.UNRECOGNIZED;
  }
}

export function barBuildingTickTypeToJSON(object: BarBuildingTickType): string {
  switch (object) {
    case BarBuildingTickType.BAR_BUILDING_TICK_TYPE_BID:
      return 'BAR_BUILDING_TICK_TYPE_BID';
    case BarBuildingTickType.BAR_BUILDING_TICK_TYPE_ASK:
      return 'BAR_BUILDING_TICK_TYPE_ASK';
    case BarBuildingTickType.BAR_BUILDING_TICK_TYPE_TRADE:
      return 'BAR_BUILDING_TICK_TYPE_TRADE';
    case BarBuildingTickType.BAR_BUILDING_TICK_TYPE_SETTLEMENT:
      return 'BAR_BUILDING_TICK_TYPE_SETTLEMENT';
    case BarBuildingTickType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Bar report status code enumeration. */
export enum BarReportStatusCode {
  /**
   * BAR_REPORT_STATUS_CODE_SUCCESS - / success codes  (0 - 99)
   * Request is processed without subscription (subscription was not requested).
   */
  BAR_REPORT_STATUS_CODE_SUCCESS = 0,
  /**
   * BAR_REPORT_STATUS_CODE_SUBSCRIBED - Request is processed and subscription is established or restored after disconnection.
   * If the subscription is restored, the following data will be returned to the user:
   * 1. TimeBarRequest-s - all missed bars;
   * 2. NonTimedBarRequest-s - NonTimedBarRequest.bar_range.count last bars.
   */
  BAR_REPORT_STATUS_CODE_SUBSCRIBED = 1,
  /** BAR_REPORT_STATUS_CODE_DROPPED - The request or subscription is dropped by the client. */
  BAR_REPORT_STATUS_CODE_DROPPED = 2,
  /** BAR_REPORT_STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  BAR_REPORT_STATUS_CODE_UPDATE = 3,
  /**
   * BAR_REPORT_STATUS_CODE_DISCONNECTED - Subscription or request processing status that indicates that currently processing is impossible
   * because of communication issues.
   * NOTE: Clients should not resubscribe or resend requests in this case, the server will restore processing with
   * sending BAR_REPORT_STATUS_CODE_SUCCESS or BAR_REPORT_STATUS_CODE_SUBSCRIBED status
   * once communication issues are resolved.
   * If client is not interested in this request or subscription any longer it should send REQUEST_TYPE_DROP request.
   */
  BAR_REPORT_STATUS_CODE_DISCONNECTED = 4,
  /**
   * BAR_REPORT_STATUS_CODE_INVALIDATED - Bars are invalidated for specified time range due to data correction.
   * Re-request historical bars and/or re-subscribe to bar updates to receive valid bar data.
   * NOTE: Subscription is not dropped.
   */
  BAR_REPORT_STATUS_CODE_INVALIDATED = 5,
  /**
   * BAR_REPORT_STATUS_CODE_FAILURE - / failure codes (100+), subscription (if any) is dropped in case of failure.
   * General failure.
   */
  BAR_REPORT_STATUS_CODE_FAILURE = 101,
  /** BAR_REPORT_STATUS_CODE_ACCESS_DENIED - The user is not allowed to access this data. */
  BAR_REPORT_STATUS_CODE_ACCESS_DENIED = 103,
  /** BAR_REPORT_STATUS_CODE_NOT_FOUND - Requested information is not found. */
  BAR_REPORT_STATUS_CODE_NOT_FOUND = 104,
  /** BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE - Requested data is outside of allowed range. */
  BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE = 105,
  /** BAR_REPORT_STATUS_CODE_INVALID_PARAMS - Parameters of a bar request are invalid. */
  BAR_REPORT_STATUS_CODE_INVALID_PARAMS = 106,
  /** BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION - The limit of active requests has been violated. */
  BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION = 107,
  /** BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The limit of simultaneous historical subscriptions has been violated. */
  BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 108,
  /** BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION = 109,
  /** BAR_REPORT_STATUS_CODE_NOT_SUPPORTED - Bar request without subscription on updates is not supported for specified contract. */
  BAR_REPORT_STATUS_CODE_NOT_SUPPORTED = 110,
  /** BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE - Requested time bar update interval is outside of allowed range. */
  BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE = 111,
  UNRECOGNIZED = -1,
}

export function barReportStatusCodeFromJSON(object: any): BarReportStatusCode {
  switch (object) {
    case 0:
    case 'BAR_REPORT_STATUS_CODE_SUCCESS':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUCCESS;
    case 1:
    case 'BAR_REPORT_STATUS_CODE_SUBSCRIBED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIBED;
    case 2:
    case 'BAR_REPORT_STATUS_CODE_DROPPED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_DROPPED;
    case 3:
    case 'BAR_REPORT_STATUS_CODE_UPDATE':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE;
    case 4:
    case 'BAR_REPORT_STATUS_CODE_DISCONNECTED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_DISCONNECTED;
    case 5:
    case 'BAR_REPORT_STATUS_CODE_INVALIDATED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALIDATED;
    case 101:
    case 'BAR_REPORT_STATUS_CODE_FAILURE':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_FAILURE;
    case 103:
    case 'BAR_REPORT_STATUS_CODE_ACCESS_DENIED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACCESS_DENIED;
    case 104:
    case 'BAR_REPORT_STATUS_CODE_NOT_FOUND':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_FOUND;
    case 105:
    case 'BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE;
    case 106:
    case 'BAR_REPORT_STATUS_CODE_INVALID_PARAMS':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALID_PARAMS;
    case 107:
    case 'BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION;
    case 108:
    case 'BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 109:
    case 'BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 110:
    case 'BAR_REPORT_STATUS_CODE_NOT_SUPPORTED':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_SUPPORTED;
    case 111:
    case 'BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE':
      return BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BarReportStatusCode.UNRECOGNIZED;
  }
}

export function barReportStatusCodeToJSON(object: BarReportStatusCode): string {
  switch (object) {
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUCCESS:
      return 'BAR_REPORT_STATUS_CODE_SUCCESS';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIBED:
      return 'BAR_REPORT_STATUS_CODE_SUBSCRIBED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_DROPPED:
      return 'BAR_REPORT_STATUS_CODE_DROPPED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE:
      return 'BAR_REPORT_STATUS_CODE_UPDATE';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_DISCONNECTED:
      return 'BAR_REPORT_STATUS_CODE_DISCONNECTED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALIDATED:
      return 'BAR_REPORT_STATUS_CODE_INVALIDATED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_FAILURE:
      return 'BAR_REPORT_STATUS_CODE_FAILURE';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACCESS_DENIED:
      return 'BAR_REPORT_STATUS_CODE_ACCESS_DENIED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_FOUND:
      return 'BAR_REPORT_STATUS_CODE_NOT_FOUND';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE:
      return 'BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALID_PARAMS:
      return 'BAR_REPORT_STATUS_CODE_INVALID_PARAMS';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION:
      return 'BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return 'BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return 'BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_SUPPORTED:
      return 'BAR_REPORT_STATUS_CODE_NOT_SUPPORTED';
    case BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE:
      return 'BAR_REPORT_STATUS_CODE_UPDATE_INTERVAL_OUTSIDE_ALLOWED_RANGE';
    case BarReportStatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters for Time and Sales request. */
export interface TimeAndSalesParameters {
  /** contract id for Time and Sales request. */
  contractId: number;
  /** This field is associated with Level enum type. */
  level: number;
  /** Time and Sales period time to start from. */
  fromUtcTime: number;
  /**
   * Optional Time and Sales period finish time.
   * Latest allowed and available time is used if it is not specified.
   */
  toUtcTime: number;
  /** Includes Off Market trade quotes in report. */
  includeOffMarketTrades: boolean;
  /** Quotes from report include trade attributes. */
  includeTradeAttributes: boolean;
}

/** Level or requested Time and Sales data. */
export enum TimeAndSalesParameters_Level {
  /** LEVEL_TRADES - Get trades with volumes and settlement quotes. */
  LEVEL_TRADES = 1,
  /** LEVEL_TRADES_BBA_VOLUMES - Get trades, settlement and best ask & bid quotes with volumes. */
  LEVEL_TRADES_BBA_VOLUMES = 3,
  UNRECOGNIZED = -1,
}

export function timeAndSalesParameters_LevelFromJSON(object: any): TimeAndSalesParameters_Level {
  switch (object) {
    case 1:
    case 'LEVEL_TRADES':
      return TimeAndSalesParameters_Level.LEVEL_TRADES;
    case 3:
    case 'LEVEL_TRADES_BBA_VOLUMES':
      return TimeAndSalesParameters_Level.LEVEL_TRADES_BBA_VOLUMES;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TimeAndSalesParameters_Level.UNRECOGNIZED;
  }
}

export function timeAndSalesParameters_LevelToJSON(object: TimeAndSalesParameters_Level): string {
  switch (object) {
    case TimeAndSalesParameters_Level.LEVEL_TRADES:
      return 'LEVEL_TRADES';
    case TimeAndSalesParameters_Level.LEVEL_TRADES_BBA_VOLUMES:
      return 'LEVEL_TRADES_BBA_VOLUMES';
    case TimeAndSalesParameters_Level.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Time and sales request (30 days history limit). */
export interface TimeAndSalesRequest {
  /** Request ID, should be unique among currently processed requests. */
  requestId: number;
  /** parameters of a request, ignored for a REQUEST_TYPE_DROP operation. */
  timeAndSalesParameters: TimeAndSalesParameters | undefined;
  /** This field is associated with RequestType enum type. REQUEST_TYPE_GET operation is processed by default. */
  requestType: number;
}

/** Type of request. */
export enum TimeAndSalesRequest_RequestType {
  /** REQUEST_TYPE_GET - Request data. */
  REQUEST_TYPE_GET = 1,
  /** REQUEST_TYPE_DROP - Drop request before server completes it. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function timeAndSalesRequest_RequestTypeFromJSON(object: any): TimeAndSalesRequest_RequestType {
  switch (object) {
    case 1:
    case 'REQUEST_TYPE_GET':
      return TimeAndSalesRequest_RequestType.REQUEST_TYPE_GET;
    case 3:
    case 'REQUEST_TYPE_DROP':
      return TimeAndSalesRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TimeAndSalesRequest_RequestType.UNRECOGNIZED;
  }
}

export function timeAndSalesRequest_RequestTypeToJSON(object: TimeAndSalesRequest_RequestType): string {
  switch (object) {
    case TimeAndSalesRequest_RequestType.REQUEST_TYPE_GET:
      return 'REQUEST_TYPE_GET';
    case TimeAndSalesRequest_RequestType.REQUEST_TYPE_DROP:
      return 'REQUEST_TYPE_DROP';
    case TimeAndSalesRequest_RequestType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Time and sales report */
export interface TimeAndSalesReport {
  /** ID of a corresponding request. */
  requestId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /**
   * List of the requested Time and Sales as quotes.
   * Note that in order to simplify linking of T&S with real time quotes by a client server will atomically include
   * all quotes with the same time-stamp into T&S without splitting the group.
   */
  quotes: Quote[];
  /** List of corrections. */
  corrections: Quote[];
  /**
   * Time up to which the quotes were included into this report,
   * if there were several with the same time-stamp equal to this time then all are included into the report (UTC).
   * This time is set only for 'up-to-current' requests.
   */
  upToUtcTime: number;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
  /**
   * Optional failure details.
   *
   * @deprecated
   */
  textMessage: string;
  /** Optional failure details. */
  details: Text | undefined;
  /** Request contains dates partially outside of allowed historical data depth. */
  truncated: boolean;
  /**
   * Off Market trade quotes are included.
   * Always False if user didn't request Off Market trades.
   */
  offMarketTradesIncluded: boolean;
  /**
   * Trade attributes are included.
   * Always False if user didn't request trade attributes.
   */
  tradeAttributesIncluded: boolean;
}

/** Result code. */
export enum TimeAndSalesReport_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes  (0 - 99)
   * Request is processed successfully.
   */
  RESULT_CODE_SUCCESS = 0,
  /** RESULT_CODE_DROPPED - The request is dropped by the client. */
  RESULT_CODE_DROPPED = 2,
  /**
   * RESULT_CODE_DISCONNECTED - Request processing status that indicates that currently processing is impossible because of communication issues.
   * NOTE: Clients should not resend requests in this case, the server will restore processing with
   * sending RESULT_CODE_SUCCESS status once communication issues are resolved.
   * If client is not interested in this request any longer it should send REQUEST_TYPE_DROP request.
   */
  RESULT_CODE_DISCONNECTED = 4,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /** RESULT_CODE_ACCESS_DENIED - The user is not allowed to access this instrument data. */
  RESULT_CODE_ACCESS_DENIED = 103,
  /** RESULT_CODE_NOT_FOUND - Requested information is not found. */
  RESULT_CODE_NOT_FOUND = 104,
  /** RESULT_CODE_OUTSIDE_ALLOWED_RANGE - Requested data is outside of allowed range. */
  RESULT_CODE_OUTSIDE_ALLOWED_RANGE = 105,
  /** RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION - The limit of the active historical requests has been violated. */
  RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION = 106,
  /** RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION = 107,
  /** RESULT_CODE_NOT_SUPPORTED - T&S request is not supported for specified contract. */
  RESULT_CODE_NOT_SUPPORTED = 110,
  /** RESULT_CODE_INVALID_PARAMS - Parameters of a T&S request are invalid. */
  RESULT_CODE_INVALID_PARAMS = 111,
  UNRECOGNIZED = -1,
}

export function timeAndSalesReport_ResultCodeFromJSON(object: any): TimeAndSalesReport_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_SUCCESS;
    case 2:
    case 'RESULT_CODE_DROPPED':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_DROPPED;
    case 4:
    case 'RESULT_CODE_DISCONNECTED':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_DISCONNECTED;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_FAILURE;
    case 103:
    case 'RESULT_CODE_ACCESS_DENIED':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_ACCESS_DENIED;
    case 104:
    case 'RESULT_CODE_NOT_FOUND':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_FOUND;
    case 105:
    case 'RESULT_CODE_OUTSIDE_ALLOWED_RANGE':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_OUTSIDE_ALLOWED_RANGE;
    case 106:
    case 'RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION;
    case 107:
    case 'RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 110:
    case 'RESULT_CODE_NOT_SUPPORTED':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_SUPPORTED;
    case 111:
    case 'RESULT_CODE_INVALID_PARAMS':
      return TimeAndSalesReport_ResultCode.RESULT_CODE_INVALID_PARAMS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TimeAndSalesReport_ResultCode.UNRECOGNIZED;
  }
}

export function timeAndSalesReport_ResultCodeToJSON(object: TimeAndSalesReport_ResultCode): string {
  switch (object) {
    case TimeAndSalesReport_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_DROPPED:
      return 'RESULT_CODE_DROPPED';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_DISCONNECTED:
      return 'RESULT_CODE_DISCONNECTED';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_ACCESS_DENIED:
      return 'RESULT_CODE_ACCESS_DENIED';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_FOUND:
      return 'RESULT_CODE_NOT_FOUND';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_OUTSIDE_ALLOWED_RANGE:
      return 'RESULT_CODE_OUTSIDE_ALLOWED_RANGE';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION:
      return 'RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return 'RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_SUPPORTED:
      return 'RESULT_CODE_NOT_SUPPORTED';
    case TimeAndSalesReport_ResultCode.RESULT_CODE_INVALID_PARAMS:
      return 'RESULT_CODE_INVALID_PARAMS';
    case TimeAndSalesReport_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Continuation parameters for TimeBarRequest. */
export interface ContinuationParameters {
  /** This field is associated with ContinuationType enum type. */
  continuationType: number;
  /** Equalize closes (opens for bonds). Supported for CONTINUATION_TYPE_ACTIVE continuation only. */
  equalize: boolean;
}

export enum ContinuationParameters_ContinuationType {
  /** CONTINUATION_TYPE_ACTIVE - Rollover with trading activity (corresponds with ContractMetadata.is_most_active). */
  CONTINUATION_TYPE_ACTIVE = 1,
  /** CONTINUATION_TYPE_STANDARD - Rollover at expiration (non-Delayed and non-EOD contracts only). */
  CONTINUATION_TYPE_STANDARD = 2,
  UNRECOGNIZED = -1,
}

export function continuationParameters_ContinuationTypeFromJSON(object: any): ContinuationParameters_ContinuationType {
  switch (object) {
    case 1:
    case 'CONTINUATION_TYPE_ACTIVE':
      return ContinuationParameters_ContinuationType.CONTINUATION_TYPE_ACTIVE;
    case 2:
    case 'CONTINUATION_TYPE_STANDARD':
      return ContinuationParameters_ContinuationType.CONTINUATION_TYPE_STANDARD;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ContinuationParameters_ContinuationType.UNRECOGNIZED;
  }
}

export function continuationParameters_ContinuationTypeToJSON(object: ContinuationParameters_ContinuationType): string {
  switch (object) {
    case ContinuationParameters_ContinuationType.CONTINUATION_TYPE_ACTIVE:
      return 'CONTINUATION_TYPE_ACTIVE';
    case ContinuationParameters_ContinuationType.CONTINUATION_TYPE_STANDARD:
      return 'CONTINUATION_TYPE_STANDARD';
    case ContinuationParameters_ContinuationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters of a time bar request. */
export interface TimeBarParameters {
  /** Contract id for a bar request. */
  contractId: number;
  /** This field is associated with BarUnit enum type. */
  barUnit: number;
  /** Number of units per a single bar for Intra-day bars (must be within 24 hours period). */
  unitNumber: number;
  /**
   * Bars period time to start from.
   * 1) For daily and multi-day bars represents starting trade date (time part is ignored), inclusive.
   * 2) For intra-day bars represents starting time, inclusive.
   * 3) Start time is automatically aligned to the closest starting time of selected unit.
   *    For example if bar_unit is BAR_UNIT_MONTH and from_utc_time is November 08
   *    bar starting time will be set the first working day of November.
   */
  fromUtcTime: number;
  /**
   * Optional bars period finish time.
   * Latest allowed and available time is used if it is not specified.
   * 1) For daily and multi-day bars represents trade date of the last returned bar (time part is ignored), inclusive.
   * 2) For intra-day bars represents finish time, exclusive.
   * 3) Finish time is automatically aligned to the closest finish time of selected unit.
   *    For example if bar_unit is BAR_UNIT_MONTH and to_utc_time is November 08
   *    bar finish time will be set to the last working day of November.
   */
  toUtcTime: number;
  /**
   * Use close_sources instead.
   *
   * @deprecated
   */
  deprecatedClosePricePreference: number;
  /**
   * List of sources for closing price in bar building.
   * Order of sources defines what is used if several sources are available (from highest to lowest priority).
   * This field is associated with metadata_2.CloseSource enum type.
   * When list is empty, default close sources are used (ContractMetadata.close_sources).
   * If list is not empty, it must contain CLOSE_SOURCE_LAST_QUOTE, otherwise request is rejected.
   * Note: can be non-empty for requests with bar_unit equal to BAR_UNIT_DAY or greater, otherwise request is rejected.
   */
  closeSources: number[];
  /** Continuation parameters. */
  continuationParameters: ContinuationParameters | undefined;
  /**
   * List of tick types to be used in bar building.
   * This field is associated with BarBuildingTickType enum type.
   * When list is empty, commodity default tick types are used.
   * Note: can be non-empty for intra-day bar requests only, otherwise request is rejected.
   * Note: BAR_BUILDING_TICK_TYPE_SETTLEMENT tick type can only be used in combination with other type(s),
   * otherwise request is rejected.
   */
  tickTypes: number[];
  /**
   * Update interval, in milliseconds, after which bar is reported, if changed.
   * Minimum is 1 second for Intra-day bars and 1 minute for historical bars.
   * Maximum is 1 hour for both.
   * Maximum update interval for Intra-day bars is also limited by chosen bar_unit and bar_number values
   * (e.g. if bar_unit is MINUTE and bar_number is 10 update interval can't exceed 600 seconds).
   * Default is 1 second for Intra-day bars or 1 minute for historical bars, if omitted.
   */
  updateInterval: number;
}

/** Bar unit type. */
export enum TimeBarParameters_BarUnit {
  /** BAR_UNIT_YEAR - yearly bars. */
  BAR_UNIT_YEAR = 1,
  /** BAR_UNIT_SEMI_ANNUAL - semi-annual bars. */
  BAR_UNIT_SEMI_ANNUAL = 2,
  /** BAR_UNIT_QUARTER - quarterly bars. */
  BAR_UNIT_QUARTER = 3,
  /** BAR_UNIT_MONTH - monthly bars. */
  BAR_UNIT_MONTH = 4,
  /** BAR_UNIT_WEEK - weekly bars. */
  BAR_UNIT_WEEK = 5,
  /** BAR_UNIT_DAY - daily bars. */
  BAR_UNIT_DAY = 6,
  /** BAR_UNIT_HOUR - hourly bars. */
  BAR_UNIT_HOUR = 7,
  /** BAR_UNIT_MIN - minute bars. */
  BAR_UNIT_MIN = 8,
  UNRECOGNIZED = -1,
}

export function timeBarParameters_BarUnitFromJSON(object: any): TimeBarParameters_BarUnit {
  switch (object) {
    case 1:
    case 'BAR_UNIT_YEAR':
      return TimeBarParameters_BarUnit.BAR_UNIT_YEAR;
    case 2:
    case 'BAR_UNIT_SEMI_ANNUAL':
      return TimeBarParameters_BarUnit.BAR_UNIT_SEMI_ANNUAL;
    case 3:
    case 'BAR_UNIT_QUARTER':
      return TimeBarParameters_BarUnit.BAR_UNIT_QUARTER;
    case 4:
    case 'BAR_UNIT_MONTH':
      return TimeBarParameters_BarUnit.BAR_UNIT_MONTH;
    case 5:
    case 'BAR_UNIT_WEEK':
      return TimeBarParameters_BarUnit.BAR_UNIT_WEEK;
    case 6:
    case 'BAR_UNIT_DAY':
      return TimeBarParameters_BarUnit.BAR_UNIT_DAY;
    case 7:
    case 'BAR_UNIT_HOUR':
      return TimeBarParameters_BarUnit.BAR_UNIT_HOUR;
    case 8:
    case 'BAR_UNIT_MIN':
      return TimeBarParameters_BarUnit.BAR_UNIT_MIN;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TimeBarParameters_BarUnit.UNRECOGNIZED;
  }
}

export function timeBarParameters_BarUnitToJSON(object: TimeBarParameters_BarUnit): string {
  switch (object) {
    case TimeBarParameters_BarUnit.BAR_UNIT_YEAR:
      return 'BAR_UNIT_YEAR';
    case TimeBarParameters_BarUnit.BAR_UNIT_SEMI_ANNUAL:
      return 'BAR_UNIT_SEMI_ANNUAL';
    case TimeBarParameters_BarUnit.BAR_UNIT_QUARTER:
      return 'BAR_UNIT_QUARTER';
    case TimeBarParameters_BarUnit.BAR_UNIT_MONTH:
      return 'BAR_UNIT_MONTH';
    case TimeBarParameters_BarUnit.BAR_UNIT_WEEK:
      return 'BAR_UNIT_WEEK';
    case TimeBarParameters_BarUnit.BAR_UNIT_DAY:
      return 'BAR_UNIT_DAY';
    case TimeBarParameters_BarUnit.BAR_UNIT_HOUR:
      return 'BAR_UNIT_HOUR';
    case TimeBarParameters_BarUnit.BAR_UNIT_MIN:
      return 'BAR_UNIT_MIN';
    case TimeBarParameters_BarUnit.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Request for time bars with optional subscription.
 * In case of subscription updates are sent when a bar opens, closes or when it is corrected.
 * Corrected bars are sent even if they are outside of requested time bounds.
 * Range can be expanded with another request if needed.
 */
export interface TimeBarRequest {
  /** request or subscription ID. */
  requestId: number;
  /** parameters of a request, ignored for a REQUEST_TYPE_DROP operation. */
  timeBarParameters: TimeBarParameters | undefined;
  /** This field is associated with RequestType enum type. REQUEST_TYPE_GET operation is processed by default. */
  requestType: number;
}

/** Type of a bar request. */
export enum TimeBarRequest_RequestType {
  /** REQUEST_TYPE_GET - Request bars without subscription. */
  REQUEST_TYPE_GET = 1,
  /**
   * REQUEST_TYPE_SUBSCRIBE - Request bars with subscription for updates.
   * Note: to_utc_time parameter must not be specified to subscribe.
   * Note: subscription requests for deleted instruments (ContractMetadata.deleted=True) are rejected.
   */
  REQUEST_TYPE_SUBSCRIBE = 2,
  /** REQUEST_TYPE_DROP - Drop request before server completes it and/or unsubscribe if already subscribed for updates. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function timeBarRequest_RequestTypeFromJSON(object: any): TimeBarRequest_RequestType {
  switch (object) {
    case 1:
    case 'REQUEST_TYPE_GET':
      return TimeBarRequest_RequestType.REQUEST_TYPE_GET;
    case 2:
    case 'REQUEST_TYPE_SUBSCRIBE':
      return TimeBarRequest_RequestType.REQUEST_TYPE_SUBSCRIBE;
    case 3:
    case 'REQUEST_TYPE_DROP':
      return TimeBarRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TimeBarRequest_RequestType.UNRECOGNIZED;
  }
}

export function timeBarRequest_RequestTypeToJSON(object: TimeBarRequest_RequestType): string {
  switch (object) {
    case TimeBarRequest_RequestType.REQUEST_TYPE_GET:
      return 'REQUEST_TYPE_GET';
    case TimeBarRequest_RequestType.REQUEST_TYPE_SUBSCRIBE:
      return 'REQUEST_TYPE_SUBSCRIBE';
    case TimeBarRequest_RequestType.REQUEST_TYPE_DROP:
      return 'REQUEST_TYPE_DROP';
    case TimeBarRequest_RequestType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Continuation segment. */
export interface ContinuationSegment {
  /** Short contract symbol in CQG dialect. */
  cqgShortContractSymbol: string;
  /**
   * Contract id.
   * Note: in case of receiving an unknown id, the contract metadata can be obtained via ContractMetadataRequest.
   */
  contractId: number;
  /** Full contract symbol in client dialect. */
  contractSymbol: string;
}

/** Time bar. */
export interface TimeBar {
  /** Bar start time (UTC). */
  barUtcTime: number;
  /** Open price. */
  scaledOpenPrice: number;
  /** High price. */
  scaledHighPrice: number;
  /** Low price. */
  scaledLowPrice: number;
  /** Close or last price (if the bar is not closed yet). */
  scaledClosePrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Bar volume. */
  volume: Decimal | undefined;
  /**
   * Trade date (in time format), it is set only for the first bar in a requested period
   * and for each first bar of the following trade dates for intra-day bars.
   * Trade date of the bar first day for daily and multi-day bars.
   */
  tradeDate: number;
  /**
   * Note: use 'commodity_volume' instead.
   *
   * @deprecated
   */
  scaledCommodityVolume: number;
  /** Commodity volume where available, for daily and multi-day bars only. */
  commodityVolume: Decimal | undefined;
  /**
   * Note: use 'open_interest' instead.
   *
   * @deprecated
   */
  scaledOpenInterest: number;
  /** Open interest, for daily and multi-day bars only. */
  openInterest: Decimal | undefined;
  /**
   * Note: use 'commodity_open_interest' instead.
   *
   * @deprecated
   */
  scaledCommodityOpenInterest: number;
  /** Commodity open interest, for daily and multi-day bars only. */
  commodityOpenInterest: Decimal | undefined;
  /** Settlement price, provided for daily bars only. */
  scaledSettlementPrice: number;
  /** Tick volume. */
  tickVolume: number;
  /** Commodity tick volume where available, for daily and multi-day bars only. */
  commodityTickVolume: number;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   * Used only for continuation bars with BAR_UNIT_MONTH and smaller BarUnit.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/**
 * Time bar data response or update.
 * Each report contains set of bars sorted in descending order by bar_utc_time.
 * Reports related to a specific request ordered chronologically from the most recent one to the past.
 * Warning: bar updates are not synchronized with real time data so a real time quote that is included into
 * a bar might be received either before or after the bar update,
 * use time of a quote for possible real-time bar updates.
 */
export interface TimeBarReport {
  /** ID of a corresponding request or subscription. */
  requestId: number;
  /** This field is associated with BarReportStatusCode enum type. */
  statusCode: number;
  /** List of time bars. */
  timeBars: TimeBar[];
  /**
   * Time up to which the quotes were included into bars,
   * if there were several with the same time-stamp equal to this time then all are included into the last bar (UTC).
   * This time is set for 'up-to-current' bar requests and only if report contains current (incomplete) bar.
   */
  upToUtcTime: number;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
  /**
   * Optional failure details.
   *
   * @deprecated
   */
  textMessage: string;
  /** Request contains dates partially outside of allowed historical data depth. */
  truncated: boolean;
  /** Optional failure details. */
  details: Text | undefined;
  /**
   * True means that we reached start of data that we store for this contract. Earlier data is not available.
   * Note: can be True only for intra-day bar requests, for daily and multi-day bar requests it's not provided.
   */
  reachedStartOfData: boolean;
}

/** Range of bars. */
export interface BarRange {
  /** Boundary time of the requested range (see enum TimeDirection for the details). */
  utcTime: number;
  /**
   * Number of requested bars.
   * Note: The maximal number of bars that can be requested in one message is limited, 100000 by default.
   */
  count: number;
  /** Boundary index of the requested range (see enum TimeDirection for the details). */
  index: number;
  /**
   * This field is associated with TimeDirection enum type.
   * TIME_DIRECTION_BACKWARD is used by default.
   */
  timeDirection: number;
}

/** Time direction. */
export enum BarRange_TimeDirection {
  /**
   * TIME_DIRECTION_BACKWARD - 'utc_time' and 'index' define the right bound of the requested range.
   * If 'utc_time' is not specified then latest allowed and available time is used.
   * The right bound is exclusive: the report will contain specified count of bars preceding the boundary.
   * Each report will contain set of bars sorted in descending order by (bar_utc_time, index).
   * Reports related to a specific request will be ordered chronologically from the most recent one to the past.
   */
  TIME_DIRECTION_BACKWARD = 1,
  UNRECOGNIZED = -1,
}

export function barRange_TimeDirectionFromJSON(object: any): BarRange_TimeDirection {
  switch (object) {
    case 1:
    case 'TIME_DIRECTION_BACKWARD':
      return BarRange_TimeDirection.TIME_DIRECTION_BACKWARD;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BarRange_TimeDirection.UNRECOGNIZED;
  }
}

export function barRange_TimeDirectionToJSON(object: BarRange_TimeDirection): string {
  switch (object) {
    case BarRange_TimeDirection.TIME_DIRECTION_BACKWARD:
      return 'TIME_DIRECTION_BACKWARD';
    case BarRange_TimeDirection.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for non-time based bars with optional subscription (3 month history limit). */
export interface NonTimedBarRequest {
  /** Request ID. */
  requestId: number;
  /**
   * This field is associated with RequestType enum type.
   * REQUEST_TYPE_GET operation is processed by default.
   */
  requestType: number;
  /** Continuation parameters. */
  continuationParameters: ContinuationParameters | undefined;
  /**
   * Contract id.
   * Note: must be specified for REQUEST_TYPE_GET or REQUEST_TYPE_SUBSCRIBE types of request.
   */
  contractId: number;
  /**
   * Specifies bar range of a request.
   * Note: must be specified for REQUEST_TYPE_GET or REQUEST_TYPE_SUBSCRIBE types of request.
   */
  barRange: BarRange | undefined;
  /**
   * List of tick types to be used in bar building.
   * This field is associated with BarBuildingTickType enum type.
   * When list is empty, commodity default tick types are used.
   * Note: Requests with BAR_BUILDING_TICK_TYPE_SETTLEMENT tick type in a list are rejected.
   */
  tickTypes: number[];
  /**
   * / Only one of following optional fields has to be specified.
   * Constant volume bar request parameters.
   */
  constantVolumeBarParameters: ConstantVolumeBarParameters | undefined;
  /** P&F request parameters. */
  pointAndFigureParameters: PointAndFigureParameters | undefined;
  /** Renko bar request parameters. */
  renkoBarParameters: RenkoBarParameters | undefined;
  /** Range bar request parameters. */
  rangeBarParameters: RangeBarParameters | undefined;
  /** Tick bar request parameters. */
  tickBarParameters: TickBarParameters | undefined;
}

/** Type of request action. */
export enum NonTimedBarRequest_RequestType {
  /** REQUEST_TYPE_GET - Request bars without subscription. */
  REQUEST_TYPE_GET = 1,
  /**
   * REQUEST_TYPE_SUBSCRIBE - Request bars with subscription for updates.
   * Note: utc_time parameter of BarRange must not be specified to subscribe.
   * Note: subscription requests for deleted instruments (ContractMetadata.deleted=True) are rejected.
   */
  REQUEST_TYPE_SUBSCRIBE = 2,
  /** REQUEST_TYPE_DROP - Drop request before server completes it and/or unsubscribe if already subscribed for updates. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function nonTimedBarRequest_RequestTypeFromJSON(object: any): NonTimedBarRequest_RequestType {
  switch (object) {
    case 1:
    case 'REQUEST_TYPE_GET':
      return NonTimedBarRequest_RequestType.REQUEST_TYPE_GET;
    case 2:
    case 'REQUEST_TYPE_SUBSCRIBE':
      return NonTimedBarRequest_RequestType.REQUEST_TYPE_SUBSCRIBE;
    case 3:
    case 'REQUEST_TYPE_DROP':
      return NonTimedBarRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return NonTimedBarRequest_RequestType.UNRECOGNIZED;
  }
}

export function nonTimedBarRequest_RequestTypeToJSON(object: NonTimedBarRequest_RequestType): string {
  switch (object) {
    case NonTimedBarRequest_RequestType.REQUEST_TYPE_GET:
      return 'REQUEST_TYPE_GET';
    case NonTimedBarRequest_RequestType.REQUEST_TYPE_SUBSCRIBE:
      return 'REQUEST_TYPE_SUBSCRIBE';
    case NonTimedBarRequest_RequestType.REQUEST_TYPE_DROP:
      return 'REQUEST_TYPE_DROP';
    case NonTimedBarRequest_RequestType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters of a Renko bar request. */
export interface RenkoBarParameters {
  /** Size of single brick. Can take a value from 1 to 1000000000. */
  brickSize: number;
  /**
   * Maximum number of null bricks that can be generated as a single brick in the case of price gaps.
   * Can take a value from 0 to 200. Default values is 0.
   */
  maxNullBricks: number;
  /**
   * This field is associated with StartPoint enum type.
   * START_POINT_SESSION is processed by default.
   */
  startPoint: number;
}

/** Start point values. */
export enum RenkoBarParameters_StartPoint {
  /** START_POINT_SESSION - Renko calculation resets at the beginning of session. */
  START_POINT_SESSION = 0,
  /** START_POINT_WEEK - Renko calculation resets at the beginning of week (corresponds to start of weekly timed bar) */
  START_POINT_WEEK = 1,
  /** START_POINT_MONTH - Renko calculation resets at the beginning of month (corresponds to start of monthly timed bar). */
  START_POINT_MONTH = 2,
  UNRECOGNIZED = -1,
}

export function renkoBarParameters_StartPointFromJSON(object: any): RenkoBarParameters_StartPoint {
  switch (object) {
    case 0:
    case 'START_POINT_SESSION':
      return RenkoBarParameters_StartPoint.START_POINT_SESSION;
    case 1:
    case 'START_POINT_WEEK':
      return RenkoBarParameters_StartPoint.START_POINT_WEEK;
    case 2:
    case 'START_POINT_MONTH':
      return RenkoBarParameters_StartPoint.START_POINT_MONTH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RenkoBarParameters_StartPoint.UNRECOGNIZED;
  }
}

export function renkoBarParameters_StartPointToJSON(object: RenkoBarParameters_StartPoint): string {
  switch (object) {
    case RenkoBarParameters_StartPoint.START_POINT_SESSION:
      return 'START_POINT_SESSION';
    case RenkoBarParameters_StartPoint.START_POINT_WEEK:
      return 'START_POINT_WEEK';
    case RenkoBarParameters_StartPoint.START_POINT_MONTH:
      return 'START_POINT_MONTH';
    case RenkoBarParameters_StartPoint.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters of a Range bar request. */
export interface RangeBarParameters {
  /** Size of single range bar. Can take a value from 1 to 1000000000. */
  rangeSize: number;
  /**
   * This field is associated with StartPoint enum type.
   * START_POINT_SESSION is processed by default.
   */
  startPoint: number;
}

/** Start point values. */
export enum RangeBarParameters_StartPoint {
  /** START_POINT_SESSION - Range bar calculation resets at the beginning of session. */
  START_POINT_SESSION = 0,
  /** START_POINT_WEEK - Range bar calculation resets at the beginning of week. */
  START_POINT_WEEK = 1,
  /** START_POINT_MONTH - Range bar calculation resets at the beginning of month. */
  START_POINT_MONTH = 2,
  UNRECOGNIZED = -1,
}

export function rangeBarParameters_StartPointFromJSON(object: any): RangeBarParameters_StartPoint {
  switch (object) {
    case 0:
    case 'START_POINT_SESSION':
      return RangeBarParameters_StartPoint.START_POINT_SESSION;
    case 1:
    case 'START_POINT_WEEK':
      return RangeBarParameters_StartPoint.START_POINT_WEEK;
    case 2:
    case 'START_POINT_MONTH':
      return RangeBarParameters_StartPoint.START_POINT_MONTH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RangeBarParameters_StartPoint.UNRECOGNIZED;
  }
}

export function rangeBarParameters_StartPointToJSON(object: RangeBarParameters_StartPoint): string {
  switch (object) {
    case RangeBarParameters_StartPoint.START_POINT_SESSION:
      return 'START_POINT_SESSION';
    case RangeBarParameters_StartPoint.START_POINT_WEEK:
      return 'START_POINT_WEEK';
    case RangeBarParameters_StartPoint.START_POINT_MONTH:
      return 'START_POINT_MONTH';
    case RangeBarParameters_StartPoint.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters of a Tick bar request. */
export interface TickBarParameters {
  /** Use 0-plus and 0-minus ticks when building bars. */
  useFlatTicks: boolean;
}

/**
 * Non-time based bar data response or update.
 * Each report contains set of bars. The order of bars depends on BarRange.time_direction specified in the request.
 * The order of reports related to a specific request depends on BarRange.time_direction specified in the request.
 */
export interface NonTimedBarReport {
  /** ID of the request. */
  requestId: number;
  /** This field is associated with BarReportStatusCode enum type. */
  statusCode: number;
  /** Optional failure details. */
  details: Text | undefined;
  /** Time from which bars are invalidated. */
  invalidatedFromUtcTime: number;
  /**
   * Time up to which bars are invalidated.
   * If 'invalidated_from_utc_time' is set and 'invalidated_to_utc_time' isn't set, then bars are invalidated
   * up to current time. In this case, bar updates stopped for this subscription.
   */
  invalidatedToUtcTime: number;
  /**
   * Time up to which the quotes were included into bars,
   * if there were several with the same time-stamp equal to this time
   * then all are included into the report (UTC).
   * This time is set only for requests with unspecified 'utc_time' parameter of message BarRange and only if report
   * contains current (incomplete) bar.
   */
  upToUtcTime: number;
  /** It is set only if the number of requested bars exceeded limit. */
  truncatedByBarCount: boolean;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
  /** True means that we reached start of data that we store for this contract. Earlier data is not available. */
  reachedStartOfData: boolean;
  /**
   * / Only one of the following repeated fields is specified.
   * Constant volume bars.
   */
  constantVolumeBars: ConstantVolumeBar[];
  /** Point and figure bars. */
  pointAndFigureBars: PointAndFigureBar[];
  /** Renko bars. */
  renkoBars: RenkoBar[];
  /** Range bars. */
  rangeBars: RangeBar[];
  /** Tick bars. */
  tickBars: TickBar[];
}

/** Parameters of a Constant Volume Bar request. */
export interface ConstantVolumeBarParameters {
  /**
   * Note: use volume_level instead.
   *
   * @deprecated
   */
  obsoleteVolumeLevel: number;
  /**
   * Volume covered by each bar. Cannot be less than ContractMetadata's trade_size_increment
   * and higher than trade_size_increment*1000000000.
   */
  volumeLevel: Decimal | undefined;
  /**
   * Determines volume type. If false (default value) then bars are built based on exchange volume,
   * otherwise they are built based on ticks (number of inside price changes).
   * Note: if use_tick_volume is true, volume_level is rounded down.
   * Note: WebAPI server rejects requests for contracts without actual volume data (
   *       ContractMetadata.has_exchange_volume=False).
   */
  useTickVolume: boolean;
  /**
   * Use 0-plus and 0-minus ticks when building bars.
   * Note: applicable only to tick volume.
   */
  useFlatTicks: boolean;
}

/** Parameters of a Point and Figure request. */
export interface PointAndFigureParameters {
  /** Specifies the number of tick units represented by each P&F. Can take a value from 1 to 1000. */
  boxSize: number;
  /** Specifies the number of boxes for P&F direction reversal. Can take a value from 1 to 1000. */
  reversal: number;
}

/** Constant Volume Bar. */
export interface ConstantVolumeBar {
  /** Bar start time (UTC), it is set only for the first bar of the following bar start times. */
  barUtcTime: number;
  /**
   * Bar index. Different bars with the same bar_utc_time always have different indexes.
   * Greater index means later bar.
   * It is set only if non-zero.
   */
  index: number;
  /** Trade date (in time format), it is set only for the first bar of the following trade dates. */
  tradeDate: number;
  /** Open price. */
  scaledOpenPrice: number;
  /** High price. */
  scaledHighPrice: number;
  /** Low price. */
  scaledLowPrice: number;
  /** Close or last price (if the bar is not closed yet). */
  scaledClosePrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Exchange volume. */
  volume: Decimal | undefined;
  /** Tick volume. */
  tickVolume: number;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/** Point and Figure bar. */
export interface PointAndFigureBar {
  /** Bar start time (UTC), it is set only for the first bar of the following bar start times. */
  barUtcTime: number;
  /**
   * Bar index. Different bars with the same bar_utc_time always have different indexes.
   * Greater index means later bar.
   * It is set only if non-zero.
   */
  index: number;
  /** Trade date (in time format), it is set only for the first bar of the following trade dates. */
  tradeDate: number;
  /** Open price. */
  pfScaledOpenPrice: number;
  /** High price (rounded to PF box). */
  pfScaledHighPrice: number;
  /** Low price (rounded to PF box). */
  pfScaledLowPrice: number;
  /** Close price. */
  pfScaledClosePrice: number;
  /** High price (not rounded to PF box). */
  scaledHighPrice: number;
  /** Low price (not rounded to PF box). */
  scaledLowPrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Exchange volume. */
  volume: Decimal | undefined;
  /** Tick volume. */
  tickVolume: number;
  /** True if the P&F chart is plotting an X, False if the P&F chart is plotting an O. */
  up: boolean;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/** Renko bar. */
export interface RenkoBar {
  /** Bar start time (UTC), it is set only for the first bar of the following bar start times. */
  barUtcTime: number;
  /**
   * Bar index. Different bars with the same bar_utc_time always have different indexes.
   * Greater index means later bar.
   * It is set only if non-zero.
   */
  index: number;
  /** Trade date (in time format), it is set only for the first bar of the following trade dates. */
  tradeDate: number;
  /** Open price. */
  scaledOpenPrice: number;
  /** High price. */
  scaledHighPrice: number;
  /** Low price. */
  scaledLowPrice: number;
  /** Close or last price (if the bar is not closed yet). */
  scaledClosePrice: number;
  /** Maximum of open and close prices. */
  scaledRenkoHighPrice: number;
  /** Minimum of open and close prices. */
  scaledRenkoLowPrice: number;
  /** Defines how renko bar is going to be closed (up or down). */
  up: boolean;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Exchange volume. */
  volume: Decimal | undefined;
  /** Tick volume. */
  tickVolume: number;
  /** If this bar is the first bar after start point. */
  firstBarAfterStartPoint: boolean;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/** Range bar. */
export interface RangeBar {
  /** Bar start time (UTC), it is set only for the first bar of the following bar start times. */
  barUtcTime: number;
  /**
   * Bar index. Different bars with the same bar_utc_time always have different indexes.
   * Greater index means later bar.
   * It is set only if non-zero.
   */
  index: number;
  /** Trade date (in time format), it is set only for the first bar of the following trade dates. */
  tradeDate: number;
  /** Open price. */
  scaledOpenPrice: number;
  /** High price. */
  scaledHighPrice: number;
  /** Low price. */
  scaledLowPrice: number;
  /** Close or last price (if the bar is not closed yet). */
  scaledClosePrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Exchange volume. */
  volume: Decimal | undefined;
  /** Tick volume. */
  tickVolume: number;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/** Tick bar. */
export interface TickBar {
  /** Bar start time (UTC), it is set only for the first bar of the following bar start times. */
  barUtcTime: number;
  /**
   * Bar index. Different bars with the same bar_utc_time always have different indexes.
   * Greater index means later bar.
   * It is set only if non-zero.
   */
  index: number;
  /** Trade date (in time format), it is set only for the first bar of the following trade dates. */
  tradeDate: number;
  /** Close price. */
  scaledClosePrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Exchange volume. */
  volume: Decimal | undefined;
  /**
   * Continuation segment the bar belongs to, it is set only for the first bar
   * and for each first bar from the next continuation segment.
   */
  continuationSegment: ContinuationSegment | undefined;
}

/**
 * VolumeProfile request, 3 month history limit.
 * WebAPI rejects VolumeProfile requests with RESULT_CODE_FAILURE status code
 * if they are sent less than 60 seconds after the previous
 * request with the same parameters was completed.
 */
export interface VolumeProfileRequest {
  /** request ID. */
  requestId: number;
  /** Request parameters. */
  volumeProfileParameters: VolumeProfileParameters | undefined;
  /**
   * This field is associated with RequestType enum type.
   * REQUEST_TYPE_GET operation is processed by default.
   */
  requestType: number;
}

/** Type of request action. */
export enum VolumeProfileRequest_RequestType {
  /** REQUEST_TYPE_GET - Request data. */
  REQUEST_TYPE_GET = 1,
  /** REQUEST_TYPE_DROP - Drop request before server completes it. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function volumeProfileRequest_RequestTypeFromJSON(object: any): VolumeProfileRequest_RequestType {
  switch (object) {
    case 1:
    case 'REQUEST_TYPE_GET':
      return VolumeProfileRequest_RequestType.REQUEST_TYPE_GET;
    case 3:
    case 'REQUEST_TYPE_DROP':
      return VolumeProfileRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return VolumeProfileRequest_RequestType.UNRECOGNIZED;
  }
}

export function volumeProfileRequest_RequestTypeToJSON(object: VolumeProfileRequest_RequestType): string {
  switch (object) {
    case VolumeProfileRequest_RequestType.REQUEST_TYPE_GET:
      return 'REQUEST_TYPE_GET';
    case VolumeProfileRequest_RequestType.REQUEST_TYPE_DROP:
      return 'REQUEST_TYPE_DROP';
    case VolumeProfileRequest_RequestType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Parameters of a Volume Profile request. */
export interface VolumeProfileParameters {
  /** Contract id for the volume profile request. */
  contractId: number;
  /**
   * Both of the next two fields have to be set, otherwise the request will return a failure
   * Start of the time range for volume profile to be calculated for.
   */
  startUtcTime: number;
  /** Exclusive end of the time range for volume profile to be calculated for. */
  endUtcTime: number;
}

/** VolumeProfile snapshot response. */
export interface VolumeProfileReport {
  /** ID of the volume profile request. */
  requestId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Volume profile snapshot items. */
  volumeProfileItems: VolumeProfileItem[];
  /**
   * Volume profile cumulative statistics for the last quotes.
   * This field is filled only for the first chunk of the report.
   */
  lastQuotesCumulativeStatistics: VolumeProfileLastQuotesCumulativeStatistics | undefined;
  /**
   * Time up to which the quotes were included into this report,
   * if there were several with the same time-stamp equal to this time
   * then all are included into the report (UTC).
   * This time is set only for requests with unspecified 'end_utc_time' parameter
   * of message VolumeProfileParameters.
   */
  upToUtcTime: number;
  /**
   * Optional failure details.
   *
   * @deprecated
   */
  textMessage: string;
  /** Optional failure details. */
  details: Text | undefined;
  /** Request contains dates partially outside of allowed historical data depth. */
  truncated: boolean;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
}

export enum VolumeProfileReport_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes  (0 - 99)
   * Request is processed successfully.
   */
  RESULT_CODE_SUCCESS = 0,
  /** RESULT_CODE_DROPPED - The request is dropped by the client. */
  RESULT_CODE_DROPPED = 2,
  /**
   * RESULT_CODE_DISCONNECTED - Request processing status that indicates that currently
   * processing is impossible because of communication issues.
   * NOTE: Clients should not resend requests in this case,
   * the server will restore processing with sending RESULT_CODE_SUCCESS status
   * once communication issues are resolved. If client is not interested
   * in this request any longer it should send REQUEST_TYPE_DROP request.
   */
  RESULT_CODE_DISCONNECTED = 4,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /** RESULT_CODE_ACCESS_DENIED - The user is not allowed to access this instrument data. */
  RESULT_CODE_ACCESS_DENIED = 103,
  /** RESULT_CODE_NOT_FOUND - Requested information is not found. */
  RESULT_CODE_NOT_FOUND = 104,
  /** RESULT_CODE_OUTSIDE_ALLOWED_RANGE - Requested data is outside of allowed range. */
  RESULT_CODE_OUTSIDE_ALLOWED_RANGE = 105,
  /** RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION - The limit of the active historical requests has been violated. */
  RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION = 106,
  /** RESULT_CODE_NOT_SUPPORTED - VolumeProfile request is not supported for specified contract. */
  RESULT_CODE_NOT_SUPPORTED = 110,
  /** RESULT_CODE_INVALID_PARAMS - Parameters of a VolumeProfile request are invalid. */
  RESULT_CODE_INVALID_PARAMS = 111,
  UNRECOGNIZED = -1,
}

export function volumeProfileReport_ResultCodeFromJSON(object: any): VolumeProfileReport_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return VolumeProfileReport_ResultCode.RESULT_CODE_SUCCESS;
    case 2:
    case 'RESULT_CODE_DROPPED':
      return VolumeProfileReport_ResultCode.RESULT_CODE_DROPPED;
    case 4:
    case 'RESULT_CODE_DISCONNECTED':
      return VolumeProfileReport_ResultCode.RESULT_CODE_DISCONNECTED;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return VolumeProfileReport_ResultCode.RESULT_CODE_FAILURE;
    case 103:
    case 'RESULT_CODE_ACCESS_DENIED':
      return VolumeProfileReport_ResultCode.RESULT_CODE_ACCESS_DENIED;
    case 104:
    case 'RESULT_CODE_NOT_FOUND':
      return VolumeProfileReport_ResultCode.RESULT_CODE_NOT_FOUND;
    case 105:
    case 'RESULT_CODE_OUTSIDE_ALLOWED_RANGE':
      return VolumeProfileReport_ResultCode.RESULT_CODE_OUTSIDE_ALLOWED_RANGE;
    case 106:
    case 'RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION':
      return VolumeProfileReport_ResultCode.RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION;
    case 110:
    case 'RESULT_CODE_NOT_SUPPORTED':
      return VolumeProfileReport_ResultCode.RESULT_CODE_NOT_SUPPORTED;
    case 111:
    case 'RESULT_CODE_INVALID_PARAMS':
      return VolumeProfileReport_ResultCode.RESULT_CODE_INVALID_PARAMS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return VolumeProfileReport_ResultCode.UNRECOGNIZED;
  }
}

export function volumeProfileReport_ResultCodeToJSON(object: VolumeProfileReport_ResultCode): string {
  switch (object) {
    case VolumeProfileReport_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case VolumeProfileReport_ResultCode.RESULT_CODE_DROPPED:
      return 'RESULT_CODE_DROPPED';
    case VolumeProfileReport_ResultCode.RESULT_CODE_DISCONNECTED:
      return 'RESULT_CODE_DISCONNECTED';
    case VolumeProfileReport_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case VolumeProfileReport_ResultCode.RESULT_CODE_ACCESS_DENIED:
      return 'RESULT_CODE_ACCESS_DENIED';
    case VolumeProfileReport_ResultCode.RESULT_CODE_NOT_FOUND:
      return 'RESULT_CODE_NOT_FOUND';
    case VolumeProfileReport_ResultCode.RESULT_CODE_OUTSIDE_ALLOWED_RANGE:
      return 'RESULT_CODE_OUTSIDE_ALLOWED_RANGE';
    case VolumeProfileReport_ResultCode.RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION:
      return 'RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION';
    case VolumeProfileReport_ResultCode.RESULT_CODE_NOT_SUPPORTED:
      return 'RESULT_CODE_NOT_SUPPORTED';
    case VolumeProfileReport_ResultCode.RESULT_CODE_INVALID_PARAMS:
      return 'RESULT_CODE_INVALID_PARAMS';
    case VolumeProfileReport_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Volume profile cumulative statistics for the last quotes. */
export interface VolumeProfileLastQuotesCumulativeStatistics {
  /** Price of the last best ask quote within Volume Profile range. */
  scaledLastAskPrice: number;
  /** Price of the last best bid quote within Volume Profile range. */
  scaledLastBidPrice: number;
  /**
   * Note: use ask_trade_volume instead.
   *
   * @deprecated
   */
  scaledAskTradeVolume: number;
  /**
   * Cumulative sum of trade volumes on ask side since the last best bid or best ask price update
   * within Volume Profile range.
   */
  askTradeVolume: number;
  /**
   * Note: use bid_trade_volume instead.
   *
   * @deprecated
   */
  scaledBidTradeVolume: number;
  /**
   * Cumulative sum of trade volumes on bid side since the last best bid or best ask price update
   * within Volume Profile range.
   */
  bidTradeVolume: number;
}

/** Volume profile snapshot item. */
export interface VolumeProfileItem {
  /** Price (unique for each volume profile snapshot reported). */
  scaledPrice: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /**
   * Total volume of all trades with this price for the time range asked.
   * volume, ask_volume, bid_volume are omitted if volume is empty.
   * Trade volume is either associated with bid side or with ask side in the following manner:
   *  1. If best bid and best ask are equal or crossed and the trade has happened,
   *     half of its volume is associated with the bid and half - with the ask.
   *  2. If the trade is less than or equal to best bid, all of its volume is associated with bid.
   *  3. If the trade is greater than or equal to best ask, all of its volume is associated with ask.
   *  4. If the trade is less than best ask and greater than best bid, its volume shall be split
   *     among bid and ask inversely proportional to the distances to best bid/best ask
   *     (closer trade to best bid, more qty associated with bid).
   */
  volume: Decimal | undefined;
  /** Ask part of total volume. */
  askVolume: Decimal | undefined;
  /** Bid part of total volume. */
  bidVolume: Decimal | undefined;
  /**
   * Total tick volume of all trades with this price for the time range asked.
   * tick_volume, ask_tick_volume, bid_tick_volume are omitted if tick_volume is 0.
   */
  tickVolume: number;
  /** Ask part of tick volume. */
  askTickVolume: Decimal | undefined;
  /** Bid part of tick volume. */
  bidTickVolume: Decimal | undefined;
}

function createBaseTimeAndSalesParameters(): TimeAndSalesParameters {
  return {
    contractId: 0,
    level: 0,
    fromUtcTime: 0,
    toUtcTime: 0,
    includeOffMarketTrades: false,
    includeTradeAttributes: false,
  };
}

export const TimeAndSalesParameters = {
  encode(message: TimeAndSalesParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.level !== 0) {
      writer.uint32(16).uint32(message.level);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(24).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(32).sint64(message.toUtcTime);
    }
    if (message.includeOffMarketTrades === true) {
      writer.uint32(40).bool(message.includeOffMarketTrades);
    }
    if (message.includeTradeAttributes === true) {
      writer.uint32(48).bool(message.includeTradeAttributes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeAndSalesParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeAndSalesParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.level = reader.uint32();
          break;
        case 3:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.includeOffMarketTrades = reader.bool();
          break;
        case 6:
          message.includeTradeAttributes = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeAndSalesParameters {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      level: isSet(object.level) ? Number(object.level) : 0,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
      includeOffMarketTrades: isSet(object.includeOffMarketTrades) ? Boolean(object.includeOffMarketTrades) : false,
      includeTradeAttributes: isSet(object.includeTradeAttributes) ? Boolean(object.includeTradeAttributes) : false,
    };
  },

  toJSON(message: TimeAndSalesParameters): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.level !== undefined && (obj.level = Math.round(message.level));
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    message.includeOffMarketTrades !== undefined && (obj.includeOffMarketTrades = message.includeOffMarketTrades);
    message.includeTradeAttributes !== undefined && (obj.includeTradeAttributes = message.includeTradeAttributes);
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeAndSalesParameters>, I>>(base?: I): TimeAndSalesParameters {
    return TimeAndSalesParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeAndSalesParameters>, I>>(object: I): TimeAndSalesParameters {
    const message = createBaseTimeAndSalesParameters();
    message.contractId = object.contractId ?? 0;
    message.level = object.level ?? 0;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    message.includeOffMarketTrades = object.includeOffMarketTrades ?? false;
    message.includeTradeAttributes = object.includeTradeAttributes ?? false;
    return message;
  },
};

function createBaseTimeAndSalesRequest(): TimeAndSalesRequest {
  return { requestId: 0, timeAndSalesParameters: undefined, requestType: 0 };
}

export const TimeAndSalesRequest = {
  encode(message: TimeAndSalesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.timeAndSalesParameters !== undefined) {
      TimeAndSalesParameters.encode(message.timeAndSalesParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeAndSalesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeAndSalesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.timeAndSalesParameters = TimeAndSalesParameters.decode(reader, reader.uint32());
          break;
        case 3:
          message.requestType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeAndSalesRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      timeAndSalesParameters: isSet(object.timeAndSalesParameters)
        ? TimeAndSalesParameters.fromJSON(object.timeAndSalesParameters)
        : undefined,
      requestType: isSet(object.requestType) ? Number(object.requestType) : 0,
    };
  },

  toJSON(message: TimeAndSalesRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.timeAndSalesParameters !== undefined &&
      (obj.timeAndSalesParameters = message.timeAndSalesParameters
        ? TimeAndSalesParameters.toJSON(message.timeAndSalesParameters)
        : undefined);
    message.requestType !== undefined && (obj.requestType = Math.round(message.requestType));
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeAndSalesRequest>, I>>(base?: I): TimeAndSalesRequest {
    return TimeAndSalesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeAndSalesRequest>, I>>(object: I): TimeAndSalesRequest {
    const message = createBaseTimeAndSalesRequest();
    message.requestId = object.requestId ?? 0;
    message.timeAndSalesParameters =
      object.timeAndSalesParameters !== undefined && object.timeAndSalesParameters !== null
        ? TimeAndSalesParameters.fromPartial(object.timeAndSalesParameters)
        : undefined;
    message.requestType = object.requestType ?? 0;
    return message;
  },
};

function createBaseTimeAndSalesReport(): TimeAndSalesReport {
  return {
    requestId: 0,
    resultCode: 0,
    quotes: [],
    corrections: [],
    upToUtcTime: 0,
    isReportComplete: false,
    textMessage: '',
    details: undefined,
    truncated: false,
    offMarketTradesIncluded: false,
    tradeAttributesIncluded: false,
  };
}

export const TimeAndSalesReport = {
  encode(message: TimeAndSalesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    for (const v of message.quotes) {
      Quote.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.corrections) {
      Quote.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.upToUtcTime !== 0) {
      writer.uint32(32).int64(message.upToUtcTime);
    }
    if (message.isReportComplete === true) {
      writer.uint32(40).bool(message.isReportComplete);
    }
    if (message.textMessage !== '') {
      writer.uint32(50).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(90).fork()).ldelim();
    }
    if (message.truncated === true) {
      writer.uint32(56).bool(message.truncated);
    }
    if (message.offMarketTradesIncluded === true) {
      writer.uint32(64).bool(message.offMarketTradesIncluded);
    }
    if (message.tradeAttributesIncluded === true) {
      writer.uint32(72).bool(message.tradeAttributesIncluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeAndSalesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeAndSalesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.quotes.push(Quote.decode(reader, reader.uint32()));
          break;
        case 10:
          message.corrections.push(Quote.decode(reader, reader.uint32()));
          break;
        case 4:
          message.upToUtcTime = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.isReportComplete = reader.bool();
          break;
        case 6:
          message.textMessage = reader.string();
          break;
        case 11:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 7:
          message.truncated = reader.bool();
          break;
        case 8:
          message.offMarketTradesIncluded = reader.bool();
          break;
        case 9:
          message.tradeAttributesIncluded = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeAndSalesReport {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      quotes: Array.isArray(object?.quotes) ? object.quotes.map((e: any) => Quote.fromJSON(e)) : [],
      corrections: Array.isArray(object?.corrections) ? object.corrections.map((e: any) => Quote.fromJSON(e)) : [],
      upToUtcTime: isSet(object.upToUtcTime) ? Number(object.upToUtcTime) : 0,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      truncated: isSet(object.truncated) ? Boolean(object.truncated) : false,
      offMarketTradesIncluded: isSet(object.offMarketTradesIncluded) ? Boolean(object.offMarketTradesIncluded) : false,
      tradeAttributesIncluded: isSet(object.tradeAttributesIncluded) ? Boolean(object.tradeAttributesIncluded) : false,
    };
  },

  toJSON(message: TimeAndSalesReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    if (message.quotes) {
      obj.quotes = message.quotes.map((e) => (e ? Quote.toJSON(e) : undefined));
    } else {
      obj.quotes = [];
    }
    if (message.corrections) {
      obj.corrections = message.corrections.map((e) => (e ? Quote.toJSON(e) : undefined));
    } else {
      obj.corrections = [];
    }
    message.upToUtcTime !== undefined && (obj.upToUtcTime = Math.round(message.upToUtcTime));
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.truncated !== undefined && (obj.truncated = message.truncated);
    message.offMarketTradesIncluded !== undefined && (obj.offMarketTradesIncluded = message.offMarketTradesIncluded);
    message.tradeAttributesIncluded !== undefined && (obj.tradeAttributesIncluded = message.tradeAttributesIncluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeAndSalesReport>, I>>(base?: I): TimeAndSalesReport {
    return TimeAndSalesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeAndSalesReport>, I>>(object: I): TimeAndSalesReport {
    const message = createBaseTimeAndSalesReport();
    message.requestId = object.requestId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.quotes = object.quotes?.map((e) => Quote.fromPartial(e)) || [];
    message.corrections = object.corrections?.map((e) => Quote.fromPartial(e)) || [];
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.isReportComplete = object.isReportComplete ?? false;
    message.textMessage = object.textMessage ?? '';
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.truncated = object.truncated ?? false;
    message.offMarketTradesIncluded = object.offMarketTradesIncluded ?? false;
    message.tradeAttributesIncluded = object.tradeAttributesIncluded ?? false;
    return message;
  },
};

function createBaseContinuationParameters(): ContinuationParameters {
  return { continuationType: 0, equalize: false };
}

export const ContinuationParameters = {
  encode(message: ContinuationParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.continuationType !== 0) {
      writer.uint32(8).uint32(message.continuationType);
    }
    if (message.equalize === true) {
      writer.uint32(16).bool(message.equalize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContinuationParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContinuationParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.continuationType = reader.uint32();
          break;
        case 2:
          message.equalize = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContinuationParameters {
    return {
      continuationType: isSet(object.continuationType) ? Number(object.continuationType) : 0,
      equalize: isSet(object.equalize) ? Boolean(object.equalize) : false,
    };
  },

  toJSON(message: ContinuationParameters): unknown {
    const obj: any = {};
    message.continuationType !== undefined && (obj.continuationType = Math.round(message.continuationType));
    message.equalize !== undefined && (obj.equalize = message.equalize);
    return obj;
  },

  create<I extends Exact<DeepPartial<ContinuationParameters>, I>>(base?: I): ContinuationParameters {
    return ContinuationParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContinuationParameters>, I>>(object: I): ContinuationParameters {
    const message = createBaseContinuationParameters();
    message.continuationType = object.continuationType ?? 0;
    message.equalize = object.equalize ?? false;
    return message;
  },
};

function createBaseTimeBarParameters(): TimeBarParameters {
  return {
    contractId: 0,
    barUnit: 0,
    unitNumber: 0,
    fromUtcTime: 0,
    toUtcTime: 0,
    deprecatedClosePricePreference: 0,
    closeSources: [],
    continuationParameters: undefined,
    tickTypes: [],
    updateInterval: 0,
  };
}

export const TimeBarParameters = {
  encode(message: TimeBarParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.barUnit !== 0) {
      writer.uint32(16).uint32(message.barUnit);
    }
    if (message.unitNumber !== 0) {
      writer.uint32(24).uint32(message.unitNumber);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(32).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(40).sint64(message.toUtcTime);
    }
    if (message.deprecatedClosePricePreference !== 0) {
      writer.uint32(80).uint32(message.deprecatedClosePricePreference);
    }
    writer.uint32(90).fork();
    for (const v of message.closeSources) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.continuationParameters !== undefined) {
      ContinuationParameters.encode(message.continuationParameters, writer.uint32(58).fork()).ldelim();
    }
    writer.uint32(66).fork();
    for (const v of message.tickTypes) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.updateInterval !== 0) {
      writer.uint32(72).uint32(message.updateInterval);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeBarParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeBarParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.barUnit = reader.uint32();
          break;
        case 3:
          message.unitNumber = reader.uint32();
          break;
        case 4:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 10:
          message.deprecatedClosePricePreference = reader.uint32();
          break;
        case 11:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.closeSources.push(reader.uint32());
            }
          } else {
            message.closeSources.push(reader.uint32());
          }
          break;
        case 7:
          message.continuationParameters = ContinuationParameters.decode(reader, reader.uint32());
          break;
        case 8:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tickTypes.push(reader.uint32());
            }
          } else {
            message.tickTypes.push(reader.uint32());
          }
          break;
        case 9:
          message.updateInterval = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeBarParameters {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      barUnit: isSet(object.barUnit) ? Number(object.barUnit) : 0,
      unitNumber: isSet(object.unitNumber) ? Number(object.unitNumber) : 0,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
      deprecatedClosePricePreference: isSet(object.deprecatedClosePricePreference)
        ? Number(object.deprecatedClosePricePreference)
        : 0,
      closeSources: Array.isArray(object?.closeSources) ? object.closeSources.map((e: any) => Number(e)) : [],
      continuationParameters: isSet(object.continuationParameters)
        ? ContinuationParameters.fromJSON(object.continuationParameters)
        : undefined,
      tickTypes: Array.isArray(object?.tickTypes) ? object.tickTypes.map((e: any) => Number(e)) : [],
      updateInterval: isSet(object.updateInterval) ? Number(object.updateInterval) : 0,
    };
  },

  toJSON(message: TimeBarParameters): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.barUnit !== undefined && (obj.barUnit = Math.round(message.barUnit));
    message.unitNumber !== undefined && (obj.unitNumber = Math.round(message.unitNumber));
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    message.deprecatedClosePricePreference !== undefined &&
      (obj.deprecatedClosePricePreference = Math.round(message.deprecatedClosePricePreference));
    if (message.closeSources) {
      obj.closeSources = message.closeSources.map((e) => Math.round(e));
    } else {
      obj.closeSources = [];
    }
    message.continuationParameters !== undefined &&
      (obj.continuationParameters = message.continuationParameters
        ? ContinuationParameters.toJSON(message.continuationParameters)
        : undefined);
    if (message.tickTypes) {
      obj.tickTypes = message.tickTypes.map((e) => Math.round(e));
    } else {
      obj.tickTypes = [];
    }
    message.updateInterval !== undefined && (obj.updateInterval = Math.round(message.updateInterval));
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeBarParameters>, I>>(base?: I): TimeBarParameters {
    return TimeBarParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeBarParameters>, I>>(object: I): TimeBarParameters {
    const message = createBaseTimeBarParameters();
    message.contractId = object.contractId ?? 0;
    message.barUnit = object.barUnit ?? 0;
    message.unitNumber = object.unitNumber ?? 0;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    message.deprecatedClosePricePreference = object.deprecatedClosePricePreference ?? 0;
    message.closeSources = object.closeSources?.map((e) => e) || [];
    message.continuationParameters =
      object.continuationParameters !== undefined && object.continuationParameters !== null
        ? ContinuationParameters.fromPartial(object.continuationParameters)
        : undefined;
    message.tickTypes = object.tickTypes?.map((e) => e) || [];
    message.updateInterval = object.updateInterval ?? 0;
    return message;
  },
};

function createBaseTimeBarRequest(): TimeBarRequest {
  return { requestId: 0, timeBarParameters: undefined, requestType: 0 };
}

export const TimeBarRequest = {
  encode(message: TimeBarRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.timeBarParameters !== undefined) {
      TimeBarParameters.encode(message.timeBarParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeBarRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeBarRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.timeBarParameters = TimeBarParameters.decode(reader, reader.uint32());
          break;
        case 3:
          message.requestType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeBarRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      timeBarParameters: isSet(object.timeBarParameters) ? TimeBarParameters.fromJSON(object.timeBarParameters) : undefined,
      requestType: isSet(object.requestType) ? Number(object.requestType) : 0,
    };
  },

  toJSON(message: TimeBarRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.timeBarParameters !== undefined &&
      (obj.timeBarParameters = message.timeBarParameters ? TimeBarParameters.toJSON(message.timeBarParameters) : undefined);
    message.requestType !== undefined && (obj.requestType = Math.round(message.requestType));
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeBarRequest>, I>>(base?: I): TimeBarRequest {
    return TimeBarRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeBarRequest>, I>>(object: I): TimeBarRequest {
    const message = createBaseTimeBarRequest();
    message.requestId = object.requestId ?? 0;
    message.timeBarParameters =
      object.timeBarParameters !== undefined && object.timeBarParameters !== null
        ? TimeBarParameters.fromPartial(object.timeBarParameters)
        : undefined;
    message.requestType = object.requestType ?? 0;
    return message;
  },
};

function createBaseContinuationSegment(): ContinuationSegment {
  return { cqgShortContractSymbol: '', contractId: 0, contractSymbol: '' };
}

export const ContinuationSegment = {
  encode(message: ContinuationSegment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cqgShortContractSymbol !== '') {
      writer.uint32(10).string(message.cqgShortContractSymbol);
    }
    if (message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    if (message.contractSymbol !== '') {
      writer.uint32(26).string(message.contractSymbol);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContinuationSegment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContinuationSegment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.cqgShortContractSymbol = reader.string();
          break;
        case 2:
          message.contractId = reader.uint32();
          break;
        case 3:
          message.contractSymbol = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContinuationSegment {
    return {
      cqgShortContractSymbol: isSet(object.cqgShortContractSymbol) ? String(object.cqgShortContractSymbol) : '',
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      contractSymbol: isSet(object.contractSymbol) ? String(object.contractSymbol) : '',
    };
  },

  toJSON(message: ContinuationSegment): unknown {
    const obj: any = {};
    message.cqgShortContractSymbol !== undefined && (obj.cqgShortContractSymbol = message.cqgShortContractSymbol);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.contractSymbol !== undefined && (obj.contractSymbol = message.contractSymbol);
    return obj;
  },

  create<I extends Exact<DeepPartial<ContinuationSegment>, I>>(base?: I): ContinuationSegment {
    return ContinuationSegment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContinuationSegment>, I>>(object: I): ContinuationSegment {
    const message = createBaseContinuationSegment();
    message.cqgShortContractSymbol = object.cqgShortContractSymbol ?? '';
    message.contractId = object.contractId ?? 0;
    message.contractSymbol = object.contractSymbol ?? '';
    return message;
  },
};

function createBaseTimeBar(): TimeBar {
  return {
    barUtcTime: 0,
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledClosePrice: 0,
    scaledVolume: 0,
    volume: undefined,
    tradeDate: 0,
    scaledCommodityVolume: 0,
    commodityVolume: undefined,
    scaledOpenInterest: 0,
    openInterest: undefined,
    scaledCommodityOpenInterest: 0,
    commodityOpenInterest: undefined,
    scaledSettlementPrice: 0,
    tickVolume: 0,
    commodityTickVolume: 0,
    continuationSegment: undefined,
  };
}

export const TimeBar = {
  encode(message: TimeBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.scaledOpenPrice !== 0) {
      writer.uint32(16).sint32(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(24).sint32(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(32).sint32(message.scaledLowPrice);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(40).sint32(message.scaledClosePrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(48).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(122).fork()).ldelim();
    }
    if (message.tradeDate !== 0) {
      writer.uint32(56).sint64(message.tradeDate);
    }
    if (message.scaledCommodityVolume !== 0) {
      writer.uint32(64).uint64(message.scaledCommodityVolume);
    }
    if (message.commodityVolume !== undefined) {
      Decimal.encode(message.commodityVolume, writer.uint32(130).fork()).ldelim();
    }
    if (message.scaledOpenInterest !== 0) {
      writer.uint32(72).uint64(message.scaledOpenInterest);
    }
    if (message.openInterest !== undefined) {
      Decimal.encode(message.openInterest, writer.uint32(138).fork()).ldelim();
    }
    if (message.scaledCommodityOpenInterest !== 0) {
      writer.uint32(80).uint64(message.scaledCommodityOpenInterest);
    }
    if (message.commodityOpenInterest !== undefined) {
      Decimal.encode(message.commodityOpenInterest, writer.uint32(146).fork()).ldelim();
    }
    if (message.scaledSettlementPrice !== 0) {
      writer.uint32(88).sint32(message.scaledSettlementPrice);
    }
    if (message.tickVolume !== 0) {
      writer.uint32(96).uint64(message.tickVolume);
    }
    if (message.commodityTickVolume !== 0) {
      writer.uint32(104).uint64(message.commodityTickVolume);
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.scaledOpenPrice = reader.sint32();
          break;
        case 3:
          message.scaledHighPrice = reader.sint32();
          break;
        case 4:
          message.scaledLowPrice = reader.sint32();
          break;
        case 5:
          message.scaledClosePrice = reader.sint32();
          break;
        case 6:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 15:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.scaledCommodityVolume = longToNumber(reader.uint64() as Long);
          break;
        case 16:
          message.commodityVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.scaledOpenInterest = longToNumber(reader.uint64() as Long);
          break;
        case 17:
          message.openInterest = Decimal.decode(reader, reader.uint32());
          break;
        case 10:
          message.scaledCommodityOpenInterest = longToNumber(reader.uint64() as Long);
          break;
        case 18:
          message.commodityOpenInterest = Decimal.decode(reader, reader.uint32());
          break;
        case 11:
          message.scaledSettlementPrice = reader.sint32();
          break;
        case 12:
          message.tickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 13:
          message.commodityTickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 14:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledCommodityVolume: isSet(object.scaledCommodityVolume) ? Number(object.scaledCommodityVolume) : 0,
      commodityVolume: isSet(object.commodityVolume) ? Decimal.fromJSON(object.commodityVolume) : undefined,
      scaledOpenInterest: isSet(object.scaledOpenInterest) ? Number(object.scaledOpenInterest) : 0,
      openInterest: isSet(object.openInterest) ? Decimal.fromJSON(object.openInterest) : undefined,
      scaledCommodityOpenInterest: isSet(object.scaledCommodityOpenInterest) ? Number(object.scaledCommodityOpenInterest) : 0,
      commodityOpenInterest: isSet(object.commodityOpenInterest) ? Decimal.fromJSON(object.commodityOpenInterest) : undefined,
      scaledSettlementPrice: isSet(object.scaledSettlementPrice) ? Number(object.scaledSettlementPrice) : 0,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      commodityTickVolume: isSet(object.commodityTickVolume) ? Number(object.commodityTickVolume) : 0,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: TimeBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.scaledOpenPrice !== undefined && (obj.scaledOpenPrice = Math.round(message.scaledOpenPrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledCommodityVolume !== undefined && (obj.scaledCommodityVolume = Math.round(message.scaledCommodityVolume));
    message.commodityVolume !== undefined &&
      (obj.commodityVolume = message.commodityVolume ? Decimal.toJSON(message.commodityVolume) : undefined);
    message.scaledOpenInterest !== undefined && (obj.scaledOpenInterest = Math.round(message.scaledOpenInterest));
    message.openInterest !== undefined &&
      (obj.openInterest = message.openInterest ? Decimal.toJSON(message.openInterest) : undefined);
    message.scaledCommodityOpenInterest !== undefined &&
      (obj.scaledCommodityOpenInterest = Math.round(message.scaledCommodityOpenInterest));
    message.commodityOpenInterest !== undefined &&
      (obj.commodityOpenInterest = message.commodityOpenInterest ? Decimal.toJSON(message.commodityOpenInterest) : undefined);
    message.scaledSettlementPrice !== undefined && (obj.scaledSettlementPrice = Math.round(message.scaledSettlementPrice));
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.commodityTickVolume !== undefined && (obj.commodityTickVolume = Math.round(message.commodityTickVolume));
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeBar>, I>>(base?: I): TimeBar {
    return TimeBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeBar>, I>>(object: I): TimeBar {
    const message = createBaseTimeBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledCommodityVolume = object.scaledCommodityVolume ?? 0;
    message.commodityVolume =
      object.commodityVolume !== undefined && object.commodityVolume !== null
        ? Decimal.fromPartial(object.commodityVolume)
        : undefined;
    message.scaledOpenInterest = object.scaledOpenInterest ?? 0;
    message.openInterest =
      object.openInterest !== undefined && object.openInterest !== null ? Decimal.fromPartial(object.openInterest) : undefined;
    message.scaledCommodityOpenInterest = object.scaledCommodityOpenInterest ?? 0;
    message.commodityOpenInterest =
      object.commodityOpenInterest !== undefined && object.commodityOpenInterest !== null
        ? Decimal.fromPartial(object.commodityOpenInterest)
        : undefined;
    message.scaledSettlementPrice = object.scaledSettlementPrice ?? 0;
    message.tickVolume = object.tickVolume ?? 0;
    message.commodityTickVolume = object.commodityTickVolume ?? 0;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBaseTimeBarReport(): TimeBarReport {
  return {
    requestId: 0,
    statusCode: 0,
    timeBars: [],
    upToUtcTime: 0,
    isReportComplete: false,
    textMessage: '',
    truncated: false,
    details: undefined,
    reachedStartOfData: false,
  };
}

export const TimeBarReport = {
  encode(message: TimeBarReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    for (const v of message.timeBars) {
      TimeBar.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.upToUtcTime !== 0) {
      writer.uint32(32).int64(message.upToUtcTime);
    }
    if (message.isReportComplete === true) {
      writer.uint32(40).bool(message.isReportComplete);
    }
    if (message.textMessage !== '') {
      writer.uint32(50).string(message.textMessage);
    }
    if (message.truncated === true) {
      writer.uint32(56).bool(message.truncated);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(66).fork()).ldelim();
    }
    if (message.reachedStartOfData === true) {
      writer.uint32(72).bool(message.reachedStartOfData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeBarReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeBarReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.statusCode = reader.uint32();
          break;
        case 3:
          message.timeBars.push(TimeBar.decode(reader, reader.uint32()));
          break;
        case 4:
          message.upToUtcTime = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.isReportComplete = reader.bool();
          break;
        case 6:
          message.textMessage = reader.string();
          break;
        case 7:
          message.truncated = reader.bool();
          break;
        case 8:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 9:
          message.reachedStartOfData = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TimeBarReport {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      timeBars: Array.isArray(object?.timeBars) ? object.timeBars.map((e: any) => TimeBar.fromJSON(e)) : [],
      upToUtcTime: isSet(object.upToUtcTime) ? Number(object.upToUtcTime) : 0,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      truncated: isSet(object.truncated) ? Boolean(object.truncated) : false,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      reachedStartOfData: isSet(object.reachedStartOfData) ? Boolean(object.reachedStartOfData) : false,
    };
  },

  toJSON(message: TimeBarReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    if (message.timeBars) {
      obj.timeBars = message.timeBars.map((e) => (e ? TimeBar.toJSON(e) : undefined));
    } else {
      obj.timeBars = [];
    }
    message.upToUtcTime !== undefined && (obj.upToUtcTime = Math.round(message.upToUtcTime));
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.truncated !== undefined && (obj.truncated = message.truncated);
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.reachedStartOfData !== undefined && (obj.reachedStartOfData = message.reachedStartOfData);
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeBarReport>, I>>(base?: I): TimeBarReport {
    return TimeBarReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeBarReport>, I>>(object: I): TimeBarReport {
    const message = createBaseTimeBarReport();
    message.requestId = object.requestId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.timeBars = object.timeBars?.map((e) => TimeBar.fromPartial(e)) || [];
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.isReportComplete = object.isReportComplete ?? false;
    message.textMessage = object.textMessage ?? '';
    message.truncated = object.truncated ?? false;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.reachedStartOfData = object.reachedStartOfData ?? false;
    return message;
  },
};

function createBaseBarRange(): BarRange {
  return { utcTime: 0, count: 0, index: 0, timeDirection: 0 };
}

export const BarRange = {
  encode(message: BarRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.utcTime !== 0) {
      writer.uint32(8).sint64(message.utcTime);
    }
    if (message.count !== 0) {
      writer.uint32(16).uint32(message.count);
    }
    if (message.index !== 0) {
      writer.uint32(24).sint32(message.index);
    }
    if (message.timeDirection !== 0) {
      writer.uint32(32).uint32(message.timeDirection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BarRange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBarRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.utcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.count = reader.uint32();
          break;
        case 3:
          message.index = reader.sint32();
          break;
        case 4:
          message.timeDirection = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BarRange {
    return {
      utcTime: isSet(object.utcTime) ? Number(object.utcTime) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      timeDirection: isSet(object.timeDirection) ? Number(object.timeDirection) : 0,
    };
  },

  toJSON(message: BarRange): unknown {
    const obj: any = {};
    message.utcTime !== undefined && (obj.utcTime = Math.round(message.utcTime));
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.timeDirection !== undefined && (obj.timeDirection = Math.round(message.timeDirection));
    return obj;
  },

  create<I extends Exact<DeepPartial<BarRange>, I>>(base?: I): BarRange {
    return BarRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BarRange>, I>>(object: I): BarRange {
    const message = createBaseBarRange();
    message.utcTime = object.utcTime ?? 0;
    message.count = object.count ?? 0;
    message.index = object.index ?? 0;
    message.timeDirection = object.timeDirection ?? 0;
    return message;
  },
};

function createBaseNonTimedBarRequest(): NonTimedBarRequest {
  return {
    requestId: 0,
    requestType: 0,
    continuationParameters: undefined,
    contractId: 0,
    barRange: undefined,
    tickTypes: [],
    constantVolumeBarParameters: undefined,
    pointAndFigureParameters: undefined,
    renkoBarParameters: undefined,
    rangeBarParameters: undefined,
    tickBarParameters: undefined,
  };
}

export const NonTimedBarRequest = {
  encode(message: NonTimedBarRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.requestType !== 0) {
      writer.uint32(16).uint32(message.requestType);
    }
    if (message.continuationParameters !== undefined) {
      ContinuationParameters.encode(message.continuationParameters, writer.uint32(26).fork()).ldelim();
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    if (message.barRange !== undefined) {
      BarRange.encode(message.barRange, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(82).fork();
    for (const v of message.tickTypes) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.constantVolumeBarParameters !== undefined) {
      ConstantVolumeBarParameters.encode(message.constantVolumeBarParameters, writer.uint32(50).fork()).ldelim();
    }
    if (message.pointAndFigureParameters !== undefined) {
      PointAndFigureParameters.encode(message.pointAndFigureParameters, writer.uint32(58).fork()).ldelim();
    }
    if (message.renkoBarParameters !== undefined) {
      RenkoBarParameters.encode(message.renkoBarParameters, writer.uint32(66).fork()).ldelim();
    }
    if (message.rangeBarParameters !== undefined) {
      RangeBarParameters.encode(message.rangeBarParameters, writer.uint32(74).fork()).ldelim();
    }
    if (message.tickBarParameters !== undefined) {
      TickBarParameters.encode(message.tickBarParameters, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NonTimedBarRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNonTimedBarRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.requestType = reader.uint32();
          break;
        case 3:
          message.continuationParameters = ContinuationParameters.decode(reader, reader.uint32());
          break;
        case 4:
          message.contractId = reader.uint32();
          break;
        case 5:
          message.barRange = BarRange.decode(reader, reader.uint32());
          break;
        case 10:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.tickTypes.push(reader.uint32());
            }
          } else {
            message.tickTypes.push(reader.uint32());
          }
          break;
        case 6:
          message.constantVolumeBarParameters = ConstantVolumeBarParameters.decode(reader, reader.uint32());
          break;
        case 7:
          message.pointAndFigureParameters = PointAndFigureParameters.decode(reader, reader.uint32());
          break;
        case 8:
          message.renkoBarParameters = RenkoBarParameters.decode(reader, reader.uint32());
          break;
        case 9:
          message.rangeBarParameters = RangeBarParameters.decode(reader, reader.uint32());
          break;
        case 11:
          message.tickBarParameters = TickBarParameters.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NonTimedBarRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      requestType: isSet(object.requestType) ? Number(object.requestType) : 0,
      continuationParameters: isSet(object.continuationParameters)
        ? ContinuationParameters.fromJSON(object.continuationParameters)
        : undefined,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      barRange: isSet(object.barRange) ? BarRange.fromJSON(object.barRange) : undefined,
      tickTypes: Array.isArray(object?.tickTypes) ? object.tickTypes.map((e: any) => Number(e)) : [],
      constantVolumeBarParameters: isSet(object.constantVolumeBarParameters)
        ? ConstantVolumeBarParameters.fromJSON(object.constantVolumeBarParameters)
        : undefined,
      pointAndFigureParameters: isSet(object.pointAndFigureParameters)
        ? PointAndFigureParameters.fromJSON(object.pointAndFigureParameters)
        : undefined,
      renkoBarParameters: isSet(object.renkoBarParameters) ? RenkoBarParameters.fromJSON(object.renkoBarParameters) : undefined,
      rangeBarParameters: isSet(object.rangeBarParameters) ? RangeBarParameters.fromJSON(object.rangeBarParameters) : undefined,
      tickBarParameters: isSet(object.tickBarParameters) ? TickBarParameters.fromJSON(object.tickBarParameters) : undefined,
    };
  },

  toJSON(message: NonTimedBarRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.requestType !== undefined && (obj.requestType = Math.round(message.requestType));
    message.continuationParameters !== undefined &&
      (obj.continuationParameters = message.continuationParameters
        ? ContinuationParameters.toJSON(message.continuationParameters)
        : undefined);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.barRange !== undefined && (obj.barRange = message.barRange ? BarRange.toJSON(message.barRange) : undefined);
    if (message.tickTypes) {
      obj.tickTypes = message.tickTypes.map((e) => Math.round(e));
    } else {
      obj.tickTypes = [];
    }
    message.constantVolumeBarParameters !== undefined &&
      (obj.constantVolumeBarParameters = message.constantVolumeBarParameters
        ? ConstantVolumeBarParameters.toJSON(message.constantVolumeBarParameters)
        : undefined);
    message.pointAndFigureParameters !== undefined &&
      (obj.pointAndFigureParameters = message.pointAndFigureParameters
        ? PointAndFigureParameters.toJSON(message.pointAndFigureParameters)
        : undefined);
    message.renkoBarParameters !== undefined &&
      (obj.renkoBarParameters = message.renkoBarParameters ? RenkoBarParameters.toJSON(message.renkoBarParameters) : undefined);
    message.rangeBarParameters !== undefined &&
      (obj.rangeBarParameters = message.rangeBarParameters ? RangeBarParameters.toJSON(message.rangeBarParameters) : undefined);
    message.tickBarParameters !== undefined &&
      (obj.tickBarParameters = message.tickBarParameters ? TickBarParameters.toJSON(message.tickBarParameters) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<NonTimedBarRequest>, I>>(base?: I): NonTimedBarRequest {
    return NonTimedBarRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NonTimedBarRequest>, I>>(object: I): NonTimedBarRequest {
    const message = createBaseNonTimedBarRequest();
    message.requestId = object.requestId ?? 0;
    message.requestType = object.requestType ?? 0;
    message.continuationParameters =
      object.continuationParameters !== undefined && object.continuationParameters !== null
        ? ContinuationParameters.fromPartial(object.continuationParameters)
        : undefined;
    message.contractId = object.contractId ?? 0;
    message.barRange =
      object.barRange !== undefined && object.barRange !== null ? BarRange.fromPartial(object.barRange) : undefined;
    message.tickTypes = object.tickTypes?.map((e) => e) || [];
    message.constantVolumeBarParameters =
      object.constantVolumeBarParameters !== undefined && object.constantVolumeBarParameters !== null
        ? ConstantVolumeBarParameters.fromPartial(object.constantVolumeBarParameters)
        : undefined;
    message.pointAndFigureParameters =
      object.pointAndFigureParameters !== undefined && object.pointAndFigureParameters !== null
        ? PointAndFigureParameters.fromPartial(object.pointAndFigureParameters)
        : undefined;
    message.renkoBarParameters =
      object.renkoBarParameters !== undefined && object.renkoBarParameters !== null
        ? RenkoBarParameters.fromPartial(object.renkoBarParameters)
        : undefined;
    message.rangeBarParameters =
      object.rangeBarParameters !== undefined && object.rangeBarParameters !== null
        ? RangeBarParameters.fromPartial(object.rangeBarParameters)
        : undefined;
    message.tickBarParameters =
      object.tickBarParameters !== undefined && object.tickBarParameters !== null
        ? TickBarParameters.fromPartial(object.tickBarParameters)
        : undefined;
    return message;
  },
};

function createBaseRenkoBarParameters(): RenkoBarParameters {
  return { brickSize: 0, maxNullBricks: 0, startPoint: 0 };
}

export const RenkoBarParameters = {
  encode(message: RenkoBarParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brickSize !== 0) {
      writer.uint32(8).uint32(message.brickSize);
    }
    if (message.maxNullBricks !== 0) {
      writer.uint32(16).uint32(message.maxNullBricks);
    }
    if (message.startPoint !== 0) {
      writer.uint32(24).uint32(message.startPoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RenkoBarParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenkoBarParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brickSize = reader.uint32();
          break;
        case 2:
          message.maxNullBricks = reader.uint32();
          break;
        case 3:
          message.startPoint = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RenkoBarParameters {
    return {
      brickSize: isSet(object.brickSize) ? Number(object.brickSize) : 0,
      maxNullBricks: isSet(object.maxNullBricks) ? Number(object.maxNullBricks) : 0,
      startPoint: isSet(object.startPoint) ? Number(object.startPoint) : 0,
    };
  },

  toJSON(message: RenkoBarParameters): unknown {
    const obj: any = {};
    message.brickSize !== undefined && (obj.brickSize = Math.round(message.brickSize));
    message.maxNullBricks !== undefined && (obj.maxNullBricks = Math.round(message.maxNullBricks));
    message.startPoint !== undefined && (obj.startPoint = Math.round(message.startPoint));
    return obj;
  },

  create<I extends Exact<DeepPartial<RenkoBarParameters>, I>>(base?: I): RenkoBarParameters {
    return RenkoBarParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RenkoBarParameters>, I>>(object: I): RenkoBarParameters {
    const message = createBaseRenkoBarParameters();
    message.brickSize = object.brickSize ?? 0;
    message.maxNullBricks = object.maxNullBricks ?? 0;
    message.startPoint = object.startPoint ?? 0;
    return message;
  },
};

function createBaseRangeBarParameters(): RangeBarParameters {
  return { rangeSize: 0, startPoint: 0 };
}

export const RangeBarParameters = {
  encode(message: RangeBarParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rangeSize !== 0) {
      writer.uint32(8).uint32(message.rangeSize);
    }
    if (message.startPoint !== 0) {
      writer.uint32(24).uint32(message.startPoint);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RangeBarParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRangeBarParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rangeSize = reader.uint32();
          break;
        case 3:
          message.startPoint = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RangeBarParameters {
    return {
      rangeSize: isSet(object.rangeSize) ? Number(object.rangeSize) : 0,
      startPoint: isSet(object.startPoint) ? Number(object.startPoint) : 0,
    };
  },

  toJSON(message: RangeBarParameters): unknown {
    const obj: any = {};
    message.rangeSize !== undefined && (obj.rangeSize = Math.round(message.rangeSize));
    message.startPoint !== undefined && (obj.startPoint = Math.round(message.startPoint));
    return obj;
  },

  create<I extends Exact<DeepPartial<RangeBarParameters>, I>>(base?: I): RangeBarParameters {
    return RangeBarParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RangeBarParameters>, I>>(object: I): RangeBarParameters {
    const message = createBaseRangeBarParameters();
    message.rangeSize = object.rangeSize ?? 0;
    message.startPoint = object.startPoint ?? 0;
    return message;
  },
};

function createBaseTickBarParameters(): TickBarParameters {
  return { useFlatTicks: false };
}

export const TickBarParameters = {
  encode(message: TickBarParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.useFlatTicks === true) {
      writer.uint32(8).bool(message.useFlatTicks);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TickBarParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTickBarParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.useFlatTicks = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TickBarParameters {
    return { useFlatTicks: isSet(object.useFlatTicks) ? Boolean(object.useFlatTicks) : false };
  },

  toJSON(message: TickBarParameters): unknown {
    const obj: any = {};
    message.useFlatTicks !== undefined && (obj.useFlatTicks = message.useFlatTicks);
    return obj;
  },

  create<I extends Exact<DeepPartial<TickBarParameters>, I>>(base?: I): TickBarParameters {
    return TickBarParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TickBarParameters>, I>>(object: I): TickBarParameters {
    const message = createBaseTickBarParameters();
    message.useFlatTicks = object.useFlatTicks ?? false;
    return message;
  },
};

function createBaseNonTimedBarReport(): NonTimedBarReport {
  return {
    requestId: 0,
    statusCode: 0,
    details: undefined,
    invalidatedFromUtcTime: 0,
    invalidatedToUtcTime: 0,
    upToUtcTime: 0,
    truncatedByBarCount: false,
    isReportComplete: false,
    reachedStartOfData: false,
    constantVolumeBars: [],
    pointAndFigureBars: [],
    renkoBars: [],
    rangeBars: [],
    tickBars: [],
  };
}

export const NonTimedBarReport = {
  encode(message: NonTimedBarReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    if (message.invalidatedFromUtcTime !== 0) {
      writer.uint32(32).sint64(message.invalidatedFromUtcTime);
    }
    if (message.invalidatedToUtcTime !== 0) {
      writer.uint32(40).sint64(message.invalidatedToUtcTime);
    }
    if (message.upToUtcTime !== 0) {
      writer.uint32(48).int64(message.upToUtcTime);
    }
    if (message.truncatedByBarCount === true) {
      writer.uint32(56).bool(message.truncatedByBarCount);
    }
    if (message.isReportComplete === true) {
      writer.uint32(64).bool(message.isReportComplete);
    }
    if (message.reachedStartOfData === true) {
      writer.uint32(112).bool(message.reachedStartOfData);
    }
    for (const v of message.constantVolumeBars) {
      ConstantVolumeBar.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.pointAndFigureBars) {
      PointAndFigureBar.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.renkoBars) {
      RenkoBar.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.rangeBars) {
      RangeBar.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.tickBars) {
      TickBar.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NonTimedBarReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNonTimedBarReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.statusCode = reader.uint32();
          break;
        case 3:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.invalidatedFromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.invalidatedToUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.upToUtcTime = longToNumber(reader.int64() as Long);
          break;
        case 7:
          message.truncatedByBarCount = reader.bool();
          break;
        case 8:
          message.isReportComplete = reader.bool();
          break;
        case 14:
          message.reachedStartOfData = reader.bool();
          break;
        case 9:
          message.constantVolumeBars.push(ConstantVolumeBar.decode(reader, reader.uint32()));
          break;
        case 10:
          message.pointAndFigureBars.push(PointAndFigureBar.decode(reader, reader.uint32()));
          break;
        case 11:
          message.renkoBars.push(RenkoBar.decode(reader, reader.uint32()));
          break;
        case 12:
          message.rangeBars.push(RangeBar.decode(reader, reader.uint32()));
          break;
        case 13:
          message.tickBars.push(TickBar.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NonTimedBarReport {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      invalidatedFromUtcTime: isSet(object.invalidatedFromUtcTime) ? Number(object.invalidatedFromUtcTime) : 0,
      invalidatedToUtcTime: isSet(object.invalidatedToUtcTime) ? Number(object.invalidatedToUtcTime) : 0,
      upToUtcTime: isSet(object.upToUtcTime) ? Number(object.upToUtcTime) : 0,
      truncatedByBarCount: isSet(object.truncatedByBarCount) ? Boolean(object.truncatedByBarCount) : false,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
      reachedStartOfData: isSet(object.reachedStartOfData) ? Boolean(object.reachedStartOfData) : false,
      constantVolumeBars: Array.isArray(object?.constantVolumeBars)
        ? object.constantVolumeBars.map((e: any) => ConstantVolumeBar.fromJSON(e))
        : [],
      pointAndFigureBars: Array.isArray(object?.pointAndFigureBars)
        ? object.pointAndFigureBars.map((e: any) => PointAndFigureBar.fromJSON(e))
        : [],
      renkoBars: Array.isArray(object?.renkoBars) ? object.renkoBars.map((e: any) => RenkoBar.fromJSON(e)) : [],
      rangeBars: Array.isArray(object?.rangeBars) ? object.rangeBars.map((e: any) => RangeBar.fromJSON(e)) : [],
      tickBars: Array.isArray(object?.tickBars) ? object.tickBars.map((e: any) => TickBar.fromJSON(e)) : [],
    };
  },

  toJSON(message: NonTimedBarReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.invalidatedFromUtcTime !== undefined && (obj.invalidatedFromUtcTime = Math.round(message.invalidatedFromUtcTime));
    message.invalidatedToUtcTime !== undefined && (obj.invalidatedToUtcTime = Math.round(message.invalidatedToUtcTime));
    message.upToUtcTime !== undefined && (obj.upToUtcTime = Math.round(message.upToUtcTime));
    message.truncatedByBarCount !== undefined && (obj.truncatedByBarCount = message.truncatedByBarCount);
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    message.reachedStartOfData !== undefined && (obj.reachedStartOfData = message.reachedStartOfData);
    if (message.constantVolumeBars) {
      obj.constantVolumeBars = message.constantVolumeBars.map((e) => (e ? ConstantVolumeBar.toJSON(e) : undefined));
    } else {
      obj.constantVolumeBars = [];
    }
    if (message.pointAndFigureBars) {
      obj.pointAndFigureBars = message.pointAndFigureBars.map((e) => (e ? PointAndFigureBar.toJSON(e) : undefined));
    } else {
      obj.pointAndFigureBars = [];
    }
    if (message.renkoBars) {
      obj.renkoBars = message.renkoBars.map((e) => (e ? RenkoBar.toJSON(e) : undefined));
    } else {
      obj.renkoBars = [];
    }
    if (message.rangeBars) {
      obj.rangeBars = message.rangeBars.map((e) => (e ? RangeBar.toJSON(e) : undefined));
    } else {
      obj.rangeBars = [];
    }
    if (message.tickBars) {
      obj.tickBars = message.tickBars.map((e) => (e ? TickBar.toJSON(e) : undefined));
    } else {
      obj.tickBars = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NonTimedBarReport>, I>>(base?: I): NonTimedBarReport {
    return NonTimedBarReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NonTimedBarReport>, I>>(object: I): NonTimedBarReport {
    const message = createBaseNonTimedBarReport();
    message.requestId = object.requestId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.invalidatedFromUtcTime = object.invalidatedFromUtcTime ?? 0;
    message.invalidatedToUtcTime = object.invalidatedToUtcTime ?? 0;
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.truncatedByBarCount = object.truncatedByBarCount ?? false;
    message.isReportComplete = object.isReportComplete ?? false;
    message.reachedStartOfData = object.reachedStartOfData ?? false;
    message.constantVolumeBars = object.constantVolumeBars?.map((e) => ConstantVolumeBar.fromPartial(e)) || [];
    message.pointAndFigureBars = object.pointAndFigureBars?.map((e) => PointAndFigureBar.fromPartial(e)) || [];
    message.renkoBars = object.renkoBars?.map((e) => RenkoBar.fromPartial(e)) || [];
    message.rangeBars = object.rangeBars?.map((e) => RangeBar.fromPartial(e)) || [];
    message.tickBars = object.tickBars?.map((e) => TickBar.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConstantVolumeBarParameters(): ConstantVolumeBarParameters {
  return { obsoleteVolumeLevel: 0, volumeLevel: undefined, useTickVolume: false, useFlatTicks: false };
}

export const ConstantVolumeBarParameters = {
  encode(message: ConstantVolumeBarParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteVolumeLevel !== 0) {
      writer.uint32(8).uint32(message.obsoleteVolumeLevel);
    }
    if (message.volumeLevel !== undefined) {
      Decimal.encode(message.volumeLevel, writer.uint32(34).fork()).ldelim();
    }
    if (message.useTickVolume === true) {
      writer.uint32(16).bool(message.useTickVolume);
    }
    if (message.useFlatTicks === true) {
      writer.uint32(24).bool(message.useFlatTicks);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConstantVolumeBarParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstantVolumeBarParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.obsoleteVolumeLevel = reader.uint32();
          break;
        case 4:
          message.volumeLevel = Decimal.decode(reader, reader.uint32());
          break;
        case 2:
          message.useTickVolume = reader.bool();
          break;
        case 3:
          message.useFlatTicks = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConstantVolumeBarParameters {
    return {
      obsoleteVolumeLevel: isSet(object.obsoleteVolumeLevel) ? Number(object.obsoleteVolumeLevel) : 0,
      volumeLevel: isSet(object.volumeLevel) ? Decimal.fromJSON(object.volumeLevel) : undefined,
      useTickVolume: isSet(object.useTickVolume) ? Boolean(object.useTickVolume) : false,
      useFlatTicks: isSet(object.useFlatTicks) ? Boolean(object.useFlatTicks) : false,
    };
  },

  toJSON(message: ConstantVolumeBarParameters): unknown {
    const obj: any = {};
    message.obsoleteVolumeLevel !== undefined && (obj.obsoleteVolumeLevel = Math.round(message.obsoleteVolumeLevel));
    message.volumeLevel !== undefined &&
      (obj.volumeLevel = message.volumeLevel ? Decimal.toJSON(message.volumeLevel) : undefined);
    message.useTickVolume !== undefined && (obj.useTickVolume = message.useTickVolume);
    message.useFlatTicks !== undefined && (obj.useFlatTicks = message.useFlatTicks);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConstantVolumeBarParameters>, I>>(base?: I): ConstantVolumeBarParameters {
    return ConstantVolumeBarParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConstantVolumeBarParameters>, I>>(object: I): ConstantVolumeBarParameters {
    const message = createBaseConstantVolumeBarParameters();
    message.obsoleteVolumeLevel = object.obsoleteVolumeLevel ?? 0;
    message.volumeLevel =
      object.volumeLevel !== undefined && object.volumeLevel !== null ? Decimal.fromPartial(object.volumeLevel) : undefined;
    message.useTickVolume = object.useTickVolume ?? false;
    message.useFlatTicks = object.useFlatTicks ?? false;
    return message;
  },
};

function createBasePointAndFigureParameters(): PointAndFigureParameters {
  return { boxSize: 0, reversal: 0 };
}

export const PointAndFigureParameters = {
  encode(message: PointAndFigureParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.boxSize !== 0) {
      writer.uint32(8).uint32(message.boxSize);
    }
    if (message.reversal !== 0) {
      writer.uint32(16).uint32(message.reversal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PointAndFigureParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePointAndFigureParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.boxSize = reader.uint32();
          break;
        case 2:
          message.reversal = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PointAndFigureParameters {
    return {
      boxSize: isSet(object.boxSize) ? Number(object.boxSize) : 0,
      reversal: isSet(object.reversal) ? Number(object.reversal) : 0,
    };
  },

  toJSON(message: PointAndFigureParameters): unknown {
    const obj: any = {};
    message.boxSize !== undefined && (obj.boxSize = Math.round(message.boxSize));
    message.reversal !== undefined && (obj.reversal = Math.round(message.reversal));
    return obj;
  },

  create<I extends Exact<DeepPartial<PointAndFigureParameters>, I>>(base?: I): PointAndFigureParameters {
    return PointAndFigureParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PointAndFigureParameters>, I>>(object: I): PointAndFigureParameters {
    const message = createBasePointAndFigureParameters();
    message.boxSize = object.boxSize ?? 0;
    message.reversal = object.reversal ?? 0;
    return message;
  },
};

function createBaseConstantVolumeBar(): ConstantVolumeBar {
  return {
    barUtcTime: 0,
    index: 0,
    tradeDate: 0,
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledClosePrice: 0,
    scaledVolume: 0,
    volume: undefined,
    tickVolume: 0,
    continuationSegment: undefined,
  };
}

export const ConstantVolumeBar = {
  encode(message: ConstantVolumeBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.index !== 0) {
      writer.uint32(16).sint32(message.index);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(24).sint64(message.tradeDate);
    }
    if (message.scaledOpenPrice !== 0) {
      writer.uint32(32).sint64(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(40).sint64(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(48).sint64(message.scaledLowPrice);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(56).sint64(message.scaledClosePrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(64).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(90).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(72).uint64(message.tickVolume);
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConstantVolumeBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstantVolumeBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.index = reader.sint32();
          break;
        case 3:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.scaledOpenPrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.scaledHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.scaledLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.scaledClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.tickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 10:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConstantVolumeBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: ConstantVolumeBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledOpenPrice !== undefined && (obj.scaledOpenPrice = Math.round(message.scaledOpenPrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConstantVolumeBar>, I>>(base?: I): ConstantVolumeBar {
    return ConstantVolumeBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConstantVolumeBar>, I>>(object: I): ConstantVolumeBar {
    const message = createBaseConstantVolumeBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.index = object.index ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBasePointAndFigureBar(): PointAndFigureBar {
  return {
    barUtcTime: 0,
    index: 0,
    tradeDate: 0,
    pfScaledOpenPrice: 0,
    pfScaledHighPrice: 0,
    pfScaledLowPrice: 0,
    pfScaledClosePrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledVolume: 0,
    volume: undefined,
    tickVolume: 0,
    up: false,
    continuationSegment: undefined,
  };
}

export const PointAndFigureBar = {
  encode(message: PointAndFigureBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.index !== 0) {
      writer.uint32(16).sint32(message.index);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(24).sint64(message.tradeDate);
    }
    if (message.pfScaledOpenPrice !== 0) {
      writer.uint32(32).sint64(message.pfScaledOpenPrice);
    }
    if (message.pfScaledHighPrice !== 0) {
      writer.uint32(40).sint64(message.pfScaledHighPrice);
    }
    if (message.pfScaledLowPrice !== 0) {
      writer.uint32(48).sint64(message.pfScaledLowPrice);
    }
    if (message.pfScaledClosePrice !== 0) {
      writer.uint32(56).sint64(message.pfScaledClosePrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(64).sint64(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(72).sint64(message.scaledLowPrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(80).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(114).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(88).uint64(message.tickVolume);
    }
    if (message.up === true) {
      writer.uint32(96).bool(message.up);
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PointAndFigureBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePointAndFigureBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.index = reader.sint32();
          break;
        case 3:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.pfScaledOpenPrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.pfScaledHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.pfScaledLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.pfScaledClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.scaledHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.scaledLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 10:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 14:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 11:
          message.tickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 12:
          message.up = reader.bool();
          break;
        case 13:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PointAndFigureBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      pfScaledOpenPrice: isSet(object.pfScaledOpenPrice) ? Number(object.pfScaledOpenPrice) : 0,
      pfScaledHighPrice: isSet(object.pfScaledHighPrice) ? Number(object.pfScaledHighPrice) : 0,
      pfScaledLowPrice: isSet(object.pfScaledLowPrice) ? Number(object.pfScaledLowPrice) : 0,
      pfScaledClosePrice: isSet(object.pfScaledClosePrice) ? Number(object.pfScaledClosePrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      up: isSet(object.up) ? Boolean(object.up) : false,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: PointAndFigureBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.pfScaledOpenPrice !== undefined && (obj.pfScaledOpenPrice = Math.round(message.pfScaledOpenPrice));
    message.pfScaledHighPrice !== undefined && (obj.pfScaledHighPrice = Math.round(message.pfScaledHighPrice));
    message.pfScaledLowPrice !== undefined && (obj.pfScaledLowPrice = Math.round(message.pfScaledLowPrice));
    message.pfScaledClosePrice !== undefined && (obj.pfScaledClosePrice = Math.round(message.pfScaledClosePrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.up !== undefined && (obj.up = message.up);
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PointAndFigureBar>, I>>(base?: I): PointAndFigureBar {
    return PointAndFigureBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PointAndFigureBar>, I>>(object: I): PointAndFigureBar {
    const message = createBasePointAndFigureBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.index = object.index ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.pfScaledOpenPrice = object.pfScaledOpenPrice ?? 0;
    message.pfScaledHighPrice = object.pfScaledHighPrice ?? 0;
    message.pfScaledLowPrice = object.pfScaledLowPrice ?? 0;
    message.pfScaledClosePrice = object.pfScaledClosePrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.up = object.up ?? false;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBaseRenkoBar(): RenkoBar {
  return {
    barUtcTime: 0,
    index: 0,
    tradeDate: 0,
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledClosePrice: 0,
    scaledRenkoHighPrice: 0,
    scaledRenkoLowPrice: 0,
    up: false,
    scaledVolume: 0,
    volume: undefined,
    tickVolume: 0,
    firstBarAfterStartPoint: false,
    continuationSegment: undefined,
  };
}

export const RenkoBar = {
  encode(message: RenkoBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.index !== 0) {
      writer.uint32(16).sint32(message.index);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(24).sint64(message.tradeDate);
    }
    if (message.scaledOpenPrice !== 0) {
      writer.uint32(32).sint64(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(40).sint64(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(48).sint64(message.scaledLowPrice);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(56).sint64(message.scaledClosePrice);
    }
    if (message.scaledRenkoHighPrice !== 0) {
      writer.uint32(64).sint64(message.scaledRenkoHighPrice);
    }
    if (message.scaledRenkoLowPrice !== 0) {
      writer.uint32(72).sint64(message.scaledRenkoLowPrice);
    }
    if (message.up === true) {
      writer.uint32(80).bool(message.up);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(88).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(122).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(96).uint64(message.tickVolume);
    }
    if (message.firstBarAfterStartPoint === true) {
      writer.uint32(104).bool(message.firstBarAfterStartPoint);
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RenkoBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenkoBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.index = reader.sint32();
          break;
        case 3:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.scaledOpenPrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.scaledHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.scaledLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.scaledClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.scaledRenkoHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.scaledRenkoLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 10:
          message.up = reader.bool();
          break;
        case 11:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 15:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 12:
          message.tickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 13:
          message.firstBarAfterStartPoint = reader.bool();
          break;
        case 14:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RenkoBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledRenkoHighPrice: isSet(object.scaledRenkoHighPrice) ? Number(object.scaledRenkoHighPrice) : 0,
      scaledRenkoLowPrice: isSet(object.scaledRenkoLowPrice) ? Number(object.scaledRenkoLowPrice) : 0,
      up: isSet(object.up) ? Boolean(object.up) : false,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      firstBarAfterStartPoint: isSet(object.firstBarAfterStartPoint) ? Boolean(object.firstBarAfterStartPoint) : false,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: RenkoBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledOpenPrice !== undefined && (obj.scaledOpenPrice = Math.round(message.scaledOpenPrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledRenkoHighPrice !== undefined && (obj.scaledRenkoHighPrice = Math.round(message.scaledRenkoHighPrice));
    message.scaledRenkoLowPrice !== undefined && (obj.scaledRenkoLowPrice = Math.round(message.scaledRenkoLowPrice));
    message.up !== undefined && (obj.up = message.up);
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.firstBarAfterStartPoint !== undefined && (obj.firstBarAfterStartPoint = message.firstBarAfterStartPoint);
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RenkoBar>, I>>(base?: I): RenkoBar {
    return RenkoBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RenkoBar>, I>>(object: I): RenkoBar {
    const message = createBaseRenkoBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.index = object.index ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledRenkoHighPrice = object.scaledRenkoHighPrice ?? 0;
    message.scaledRenkoLowPrice = object.scaledRenkoLowPrice ?? 0;
    message.up = object.up ?? false;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.firstBarAfterStartPoint = object.firstBarAfterStartPoint ?? false;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBaseRangeBar(): RangeBar {
  return {
    barUtcTime: 0,
    index: 0,
    tradeDate: 0,
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledClosePrice: 0,
    scaledVolume: 0,
    volume: undefined,
    tickVolume: 0,
    continuationSegment: undefined,
  };
}

export const RangeBar = {
  encode(message: RangeBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.index !== 0) {
      writer.uint32(16).sint32(message.index);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(24).sint64(message.tradeDate);
    }
    if (message.scaledOpenPrice !== 0) {
      writer.uint32(32).sint64(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(40).sint64(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(48).sint64(message.scaledLowPrice);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(56).sint64(message.scaledClosePrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(64).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(90).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(72).uint64(message.tickVolume);
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RangeBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRangeBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.index = reader.sint32();
          break;
        case 3:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.scaledOpenPrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.scaledHighPrice = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.scaledLowPrice = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.scaledClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.tickVolume = longToNumber(reader.uint64() as Long);
          break;
        case 10:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RangeBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: RangeBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledOpenPrice !== undefined && (obj.scaledOpenPrice = Math.round(message.scaledOpenPrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RangeBar>, I>>(base?: I): RangeBar {
    return RangeBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RangeBar>, I>>(object: I): RangeBar {
    const message = createBaseRangeBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.index = object.index ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBaseTickBar(): TickBar {
  return {
    barUtcTime: 0,
    index: 0,
    tradeDate: 0,
    scaledClosePrice: 0,
    scaledVolume: 0,
    volume: undefined,
    continuationSegment: undefined,
  };
}

export const TickBar = {
  encode(message: TickBar, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.barUtcTime !== 0) {
      writer.uint32(8).sint64(message.barUtcTime);
    }
    if (message.index !== 0) {
      writer.uint32(16).sint32(message.index);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(24).sint64(message.tradeDate);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(32).sint64(message.scaledClosePrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(40).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(58).fork()).ldelim();
    }
    if (message.continuationSegment !== undefined) {
      ContinuationSegment.encode(message.continuationSegment, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TickBar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTickBar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.barUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.index = reader.sint32();
          break;
        case 3:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.scaledClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.continuationSegment = ContinuationSegment.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TickBar {
    return {
      barUtcTime: isSet(object.barUtcTime) ? Number(object.barUtcTime) : 0,
      index: isSet(object.index) ? Number(object.index) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      continuationSegment: isSet(object.continuationSegment)
        ? ContinuationSegment.fromJSON(object.continuationSegment)
        : undefined,
    };
  },

  toJSON(message: TickBar): unknown {
    const obj: any = {};
    message.barUtcTime !== undefined && (obj.barUtcTime = Math.round(message.barUtcTime));
    message.index !== undefined && (obj.index = Math.round(message.index));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.continuationSegment !== undefined &&
      (obj.continuationSegment = message.continuationSegment
        ? ContinuationSegment.toJSON(message.continuationSegment)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<TickBar>, I>>(base?: I): TickBar {
    return TickBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TickBar>, I>>(object: I): TickBar {
    const message = createBaseTickBar();
    message.barUtcTime = object.barUtcTime ?? 0;
    message.index = object.index ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.continuationSegment =
      object.continuationSegment !== undefined && object.continuationSegment !== null
        ? ContinuationSegment.fromPartial(object.continuationSegment)
        : undefined;
    return message;
  },
};

function createBaseVolumeProfileRequest(): VolumeProfileRequest {
  return { requestId: 0, volumeProfileParameters: undefined, requestType: 0 };
}

export const VolumeProfileRequest = {
  encode(message: VolumeProfileRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.volumeProfileParameters !== undefined) {
      VolumeProfileParameters.encode(message.volumeProfileParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VolumeProfileRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolumeProfileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.volumeProfileParameters = VolumeProfileParameters.decode(reader, reader.uint32());
          break;
        case 3:
          message.requestType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VolumeProfileRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      volumeProfileParameters: isSet(object.volumeProfileParameters)
        ? VolumeProfileParameters.fromJSON(object.volumeProfileParameters)
        : undefined,
      requestType: isSet(object.requestType) ? Number(object.requestType) : 0,
    };
  },

  toJSON(message: VolumeProfileRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.volumeProfileParameters !== undefined &&
      (obj.volumeProfileParameters = message.volumeProfileParameters
        ? VolumeProfileParameters.toJSON(message.volumeProfileParameters)
        : undefined);
    message.requestType !== undefined && (obj.requestType = Math.round(message.requestType));
    return obj;
  },

  create<I extends Exact<DeepPartial<VolumeProfileRequest>, I>>(base?: I): VolumeProfileRequest {
    return VolumeProfileRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VolumeProfileRequest>, I>>(object: I): VolumeProfileRequest {
    const message = createBaseVolumeProfileRequest();
    message.requestId = object.requestId ?? 0;
    message.volumeProfileParameters =
      object.volumeProfileParameters !== undefined && object.volumeProfileParameters !== null
        ? VolumeProfileParameters.fromPartial(object.volumeProfileParameters)
        : undefined;
    message.requestType = object.requestType ?? 0;
    return message;
  },
};

function createBaseVolumeProfileParameters(): VolumeProfileParameters {
  return { contractId: 0, startUtcTime: 0, endUtcTime: 0 };
}

export const VolumeProfileParameters = {
  encode(message: VolumeProfileParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.startUtcTime !== 0) {
      writer.uint32(16).sint64(message.startUtcTime);
    }
    if (message.endUtcTime !== 0) {
      writer.uint32(24).sint64(message.endUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VolumeProfileParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolumeProfileParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.startUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.endUtcTime = longToNumber(reader.sint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VolumeProfileParameters {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      startUtcTime: isSet(object.startUtcTime) ? Number(object.startUtcTime) : 0,
      endUtcTime: isSet(object.endUtcTime) ? Number(object.endUtcTime) : 0,
    };
  },

  toJSON(message: VolumeProfileParameters): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.startUtcTime !== undefined && (obj.startUtcTime = Math.round(message.startUtcTime));
    message.endUtcTime !== undefined && (obj.endUtcTime = Math.round(message.endUtcTime));
    return obj;
  },

  create<I extends Exact<DeepPartial<VolumeProfileParameters>, I>>(base?: I): VolumeProfileParameters {
    return VolumeProfileParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VolumeProfileParameters>, I>>(object: I): VolumeProfileParameters {
    const message = createBaseVolumeProfileParameters();
    message.contractId = object.contractId ?? 0;
    message.startUtcTime = object.startUtcTime ?? 0;
    message.endUtcTime = object.endUtcTime ?? 0;
    return message;
  },
};

function createBaseVolumeProfileReport(): VolumeProfileReport {
  return {
    requestId: 0,
    resultCode: 0,
    volumeProfileItems: [],
    lastQuotesCumulativeStatistics: undefined,
    upToUtcTime: 0,
    textMessage: '',
    details: undefined,
    truncated: false,
    isReportComplete: false,
  };
}

export const VolumeProfileReport = {
  encode(message: VolumeProfileReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    for (const v of message.volumeProfileItems) {
      VolumeProfileItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.lastQuotesCumulativeStatistics !== undefined) {
      VolumeProfileLastQuotesCumulativeStatistics.encode(
        message.lastQuotesCumulativeStatistics,
        writer.uint32(66).fork()
      ).ldelim();
    }
    if (message.upToUtcTime !== 0) {
      writer.uint32(32).int64(message.upToUtcTime);
    }
    if (message.textMessage !== '') {
      writer.uint32(42).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(74).fork()).ldelim();
    }
    if (message.truncated === true) {
      writer.uint32(48).bool(message.truncated);
    }
    if (message.isReportComplete === true) {
      writer.uint32(56).bool(message.isReportComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VolumeProfileReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolumeProfileReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.volumeProfileItems.push(VolumeProfileItem.decode(reader, reader.uint32()));
          break;
        case 8:
          message.lastQuotesCumulativeStatistics = VolumeProfileLastQuotesCumulativeStatistics.decode(reader, reader.uint32());
          break;
        case 4:
          message.upToUtcTime = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.textMessage = reader.string();
          break;
        case 9:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 6:
          message.truncated = reader.bool();
          break;
        case 7:
          message.isReportComplete = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VolumeProfileReport {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      volumeProfileItems: Array.isArray(object?.volumeProfileItems)
        ? object.volumeProfileItems.map((e: any) => VolumeProfileItem.fromJSON(e))
        : [],
      lastQuotesCumulativeStatistics: isSet(object.lastQuotesCumulativeStatistics)
        ? VolumeProfileLastQuotesCumulativeStatistics.fromJSON(object.lastQuotesCumulativeStatistics)
        : undefined,
      upToUtcTime: isSet(object.upToUtcTime) ? Number(object.upToUtcTime) : 0,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      truncated: isSet(object.truncated) ? Boolean(object.truncated) : false,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
    };
  },

  toJSON(message: VolumeProfileReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    if (message.volumeProfileItems) {
      obj.volumeProfileItems = message.volumeProfileItems.map((e) => (e ? VolumeProfileItem.toJSON(e) : undefined));
    } else {
      obj.volumeProfileItems = [];
    }
    message.lastQuotesCumulativeStatistics !== undefined &&
      (obj.lastQuotesCumulativeStatistics = message.lastQuotesCumulativeStatistics
        ? VolumeProfileLastQuotesCumulativeStatistics.toJSON(message.lastQuotesCumulativeStatistics)
        : undefined);
    message.upToUtcTime !== undefined && (obj.upToUtcTime = Math.round(message.upToUtcTime));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.truncated !== undefined && (obj.truncated = message.truncated);
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    return obj;
  },

  create<I extends Exact<DeepPartial<VolumeProfileReport>, I>>(base?: I): VolumeProfileReport {
    return VolumeProfileReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VolumeProfileReport>, I>>(object: I): VolumeProfileReport {
    const message = createBaseVolumeProfileReport();
    message.requestId = object.requestId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.volumeProfileItems = object.volumeProfileItems?.map((e) => VolumeProfileItem.fromPartial(e)) || [];
    message.lastQuotesCumulativeStatistics =
      object.lastQuotesCumulativeStatistics !== undefined && object.lastQuotesCumulativeStatistics !== null
        ? VolumeProfileLastQuotesCumulativeStatistics.fromPartial(object.lastQuotesCumulativeStatistics)
        : undefined;
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.textMessage = object.textMessage ?? '';
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.truncated = object.truncated ?? false;
    message.isReportComplete = object.isReportComplete ?? false;
    return message;
  },
};

function createBaseVolumeProfileLastQuotesCumulativeStatistics(): VolumeProfileLastQuotesCumulativeStatistics {
  return {
    scaledLastAskPrice: 0,
    scaledLastBidPrice: 0,
    scaledAskTradeVolume: 0,
    askTradeVolume: 0,
    scaledBidTradeVolume: 0,
    bidTradeVolume: 0,
  };
}

export const VolumeProfileLastQuotesCumulativeStatistics = {
  encode(message: VolumeProfileLastQuotesCumulativeStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledLastAskPrice !== 0) {
      writer.uint32(8).sint32(message.scaledLastAskPrice);
    }
    if (message.scaledLastBidPrice !== 0) {
      writer.uint32(16).sint32(message.scaledLastBidPrice);
    }
    if (message.scaledAskTradeVolume !== 0) {
      writer.uint32(25).double(message.scaledAskTradeVolume);
    }
    if (message.askTradeVolume !== 0) {
      writer.uint32(41).double(message.askTradeVolume);
    }
    if (message.scaledBidTradeVolume !== 0) {
      writer.uint32(33).double(message.scaledBidTradeVolume);
    }
    if (message.bidTradeVolume !== 0) {
      writer.uint32(49).double(message.bidTradeVolume);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VolumeProfileLastQuotesCumulativeStatistics {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolumeProfileLastQuotesCumulativeStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scaledLastAskPrice = reader.sint32();
          break;
        case 2:
          message.scaledLastBidPrice = reader.sint32();
          break;
        case 3:
          message.scaledAskTradeVolume = reader.double();
          break;
        case 5:
          message.askTradeVolume = reader.double();
          break;
        case 4:
          message.scaledBidTradeVolume = reader.double();
          break;
        case 6:
          message.bidTradeVolume = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VolumeProfileLastQuotesCumulativeStatistics {
    return {
      scaledLastAskPrice: isSet(object.scaledLastAskPrice) ? Number(object.scaledLastAskPrice) : 0,
      scaledLastBidPrice: isSet(object.scaledLastBidPrice) ? Number(object.scaledLastBidPrice) : 0,
      scaledAskTradeVolume: isSet(object.scaledAskTradeVolume) ? Number(object.scaledAskTradeVolume) : 0,
      askTradeVolume: isSet(object.askTradeVolume) ? Number(object.askTradeVolume) : 0,
      scaledBidTradeVolume: isSet(object.scaledBidTradeVolume) ? Number(object.scaledBidTradeVolume) : 0,
      bidTradeVolume: isSet(object.bidTradeVolume) ? Number(object.bidTradeVolume) : 0,
    };
  },

  toJSON(message: VolumeProfileLastQuotesCumulativeStatistics): unknown {
    const obj: any = {};
    message.scaledLastAskPrice !== undefined && (obj.scaledLastAskPrice = Math.round(message.scaledLastAskPrice));
    message.scaledLastBidPrice !== undefined && (obj.scaledLastBidPrice = Math.round(message.scaledLastBidPrice));
    message.scaledAskTradeVolume !== undefined && (obj.scaledAskTradeVolume = message.scaledAskTradeVolume);
    message.askTradeVolume !== undefined && (obj.askTradeVolume = message.askTradeVolume);
    message.scaledBidTradeVolume !== undefined && (obj.scaledBidTradeVolume = message.scaledBidTradeVolume);
    message.bidTradeVolume !== undefined && (obj.bidTradeVolume = message.bidTradeVolume);
    return obj;
  },

  create<I extends Exact<DeepPartial<VolumeProfileLastQuotesCumulativeStatistics>, I>>(
    base?: I
  ): VolumeProfileLastQuotesCumulativeStatistics {
    return VolumeProfileLastQuotesCumulativeStatistics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VolumeProfileLastQuotesCumulativeStatistics>, I>>(
    object: I
  ): VolumeProfileLastQuotesCumulativeStatistics {
    const message = createBaseVolumeProfileLastQuotesCumulativeStatistics();
    message.scaledLastAskPrice = object.scaledLastAskPrice ?? 0;
    message.scaledLastBidPrice = object.scaledLastBidPrice ?? 0;
    message.scaledAskTradeVolume = object.scaledAskTradeVolume ?? 0;
    message.askTradeVolume = object.askTradeVolume ?? 0;
    message.scaledBidTradeVolume = object.scaledBidTradeVolume ?? 0;
    message.bidTradeVolume = object.bidTradeVolume ?? 0;
    return message;
  },
};

function createBaseVolumeProfileItem(): VolumeProfileItem {
  return {
    scaledPrice: 0,
    scaledVolume: 0,
    volume: undefined,
    askVolume: undefined,
    bidVolume: undefined,
    tickVolume: 0,
    askTickVolume: undefined,
    bidTickVolume: undefined,
  };
}

export const VolumeProfileItem = {
  encode(message: VolumeProfileItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledPrice !== 0) {
      writer.uint32(8).sint32(message.scaledPrice);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(16).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(26).fork()).ldelim();
    }
    if (message.askVolume !== undefined) {
      Decimal.encode(message.askVolume, writer.uint32(34).fork()).ldelim();
    }
    if (message.bidVolume !== undefined) {
      Decimal.encode(message.bidVolume, writer.uint32(42).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(48).uint32(message.tickVolume);
    }
    if (message.askTickVolume !== undefined) {
      Decimal.encode(message.askTickVolume, writer.uint32(58).fork()).ldelim();
    }
    if (message.bidTickVolume !== undefined) {
      Decimal.encode(message.bidTickVolume, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VolumeProfileItem {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolumeProfileItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scaledPrice = reader.sint32();
          break;
        case 2:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 4:
          message.askVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.bidVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.tickVolume = reader.uint32();
          break;
        case 7:
          message.askTickVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 8:
          message.bidTickVolume = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VolumeProfileItem {
    return {
      scaledPrice: isSet(object.scaledPrice) ? Number(object.scaledPrice) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      askVolume: isSet(object.askVolume) ? Decimal.fromJSON(object.askVolume) : undefined,
      bidVolume: isSet(object.bidVolume) ? Decimal.fromJSON(object.bidVolume) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      askTickVolume: isSet(object.askTickVolume) ? Decimal.fromJSON(object.askTickVolume) : undefined,
      bidTickVolume: isSet(object.bidTickVolume) ? Decimal.fromJSON(object.bidTickVolume) : undefined,
    };
  },

  toJSON(message: VolumeProfileItem): unknown {
    const obj: any = {};
    message.scaledPrice !== undefined && (obj.scaledPrice = Math.round(message.scaledPrice));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.askVolume !== undefined && (obj.askVolume = message.askVolume ? Decimal.toJSON(message.askVolume) : undefined);
    message.bidVolume !== undefined && (obj.bidVolume = message.bidVolume ? Decimal.toJSON(message.bidVolume) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.askTickVolume !== undefined &&
      (obj.askTickVolume = message.askTickVolume ? Decimal.toJSON(message.askTickVolume) : undefined);
    message.bidTickVolume !== undefined &&
      (obj.bidTickVolume = message.bidTickVolume ? Decimal.toJSON(message.bidTickVolume) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<VolumeProfileItem>, I>>(base?: I): VolumeProfileItem {
    return VolumeProfileItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VolumeProfileItem>, I>>(object: I): VolumeProfileItem {
    const message = createBaseVolumeProfileItem();
    message.scaledPrice = object.scaledPrice ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.askVolume =
      object.askVolume !== undefined && object.askVolume !== null ? Decimal.fromPartial(object.askVolume) : undefined;
    message.bidVolume =
      object.bidVolume !== undefined && object.bidVolume !== null ? Decimal.fromPartial(object.bidVolume) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.askTickVolume =
      object.askTickVolume !== undefined && object.askTickVolume !== null ? Decimal.fromPartial(object.askTickVolume) : undefined;
    message.bidTickVolume =
      object.bidTickVolume !== undefined && object.bidTickVolume !== null ? Decimal.fromPartial(object.bidTickVolume) : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
