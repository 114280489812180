import axios from 'axios';
import config from '../../config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { INotification } from '../../types/INotification';

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async (operationId: number) => {
  const response = await axios.get(`${config.backend.basePath}/notifications/operation/${operationId}`);
  return response.data;
});

export const patchNotification = createAsyncThunk('notifications/patchNotification', async (notification: INotification) => {
  const response = await axios.patch(`${config.backend.basePath}/notifications/operation/${notification.operationId}`, {
    ...notification,
  });
  return response.data;
});

export const readAllNotifications = createAsyncThunk('notifications/readAllNotifications', async (operationId: number) => {
  const response = await axios.patch(`${config.backend.basePath}/notifications/operation/${operationId}/read-all`);
  return response.data;
});
