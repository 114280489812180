export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';

export interface IProductionCyclesPayload {
  selectedYear: string;
}

export function setSelectedYear(selectedYear: string): SetSelectedYear {
  return {
    type: SET_SELECTED_YEAR,
    payload: { selectedYear },
  };
}

export interface SetSelectedYear {
  type: typeof SET_SELECTED_YEAR;
  payload: IProductionCyclesPayload;
}
