import { isNil } from 'lodash';
import { useEffect } from 'react';
import NewVersionToast from '../components/Toast/NewVersionToast';
import config from '../config';
import apiClient from '../shared/apiClient';

let interval = 0;

export function useCheckForUpdates() {
  const currentVersion = config.version;
  const isDisabled = isNil(config.featureFlags.checkForUpdatesInterval) || config.featureFlags.checkForUpdatesInterval === false;
  const intervalInMilliseconds = ((config.featureFlags.checkForUpdatesInterval as number) || 1) * 60 * 1000;

  const fetchVersion = async () => {
    const response = await apiClient.get('/VERSION.json');
    const fetchedVersion = response.data?.version;
    if (!isNil(fetchedVersion) && fetchedVersion !== currentVersion) {
      window.clearInterval(interval);
      NewVersionToast();
    }
  };

  function fetchVersionWrapped() {
    fetchVersion().catch((e) => {
      console.error('Error fetching version', e);
    });
  }

  useEffect(() => {
    if (isDisabled) return;
    interval = window.setInterval(fetchVersionWrapped, intervalInMilliseconds);
    return () => {
      window.clearInterval(interval);
    };
  }, []);
}
