import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { StepProps } from './TradeModal';
import DialogActionsBar from '../common-next/Dialog/DialogActionsBar';
import Button from '../common-next/Button/Button';
import Label from '../common-next/Label/Label';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import Hint from '../common-next/Hint/Hint';
import { formatNumber } from '../../utils/formatNumber';
import DialogContent from '../common-next/Dialog/DialogContent';
import NumericTextBox, { NumericTextBoxChangeEvent } from '../common-next/NumericTextBox/NumericTextBox';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import { useEffect } from 'react';
import Loader from '../common/Loader/Loader';
import { InstrumentType } from '@harvestiq/constants';
import Decimal from 'decimal.js';
import { useIdleTimerContext } from 'react-idle-timer';
import TimeoutDisplay from '../common-next/TimeoutDisplay/TimeoutDisplay';
import { TradeExecutionIntent } from '../CQG/CQGProvider';
import { cqgEvents } from '@farmersrisk/cqg/cqgClient/cqgEvents';
import { IConvertedSessionTimerange } from '@farmersrisk/cqg/cqgClient/CQGClient';
import { CQGHelpers } from '../CQG/helpers';
import { UXLogger } from '../../loggers/UXLogger';
import { OrderDurationType, OrderType } from '../../models/orderTypes';
import { isNil } from 'lodash';
import Loading from '../common-next/Loading/Loading';
import { useCQGContext } from '../CQG/useCQGContext';
import useCQGService from '../CQG/useCQGService';
import { FontAwesomeIconProps, faCheck, faChevronLeft, faCircleNotch } from '../faIcons';
import { useCurrentOperation } from '../../customHooks/useCurrentOperation';
import { useRealtimeQuote } from '../../queries/quotes';
import { TradeOrderState, useTradeOrderContext } from './TradeOrderProvider';
import dayjs from '../../utils/dayjs';
import { getDisplayQuantity } from './helpers';
import config from '../../config';
import TradeModalOrderSummaryText from './TradeModalOrderSummaryText';
interface StepTwoProps extends StepProps {
  orderVerified: boolean;
}

const backIcon: FontAwesomeIconProps = { icon: faChevronLeft };
const loadingIcon: FontAwesomeIconProps = { icon: faCircleNotch, spin: true };
const checkIcon: FontAwesomeIconProps = { icon: faCheck };

const isDEV = config.env === 'development' || process.env.IS_STORYBOOK;

// TODO: add countdown timer for how valid the price/order is?
export default function TradeModalStepTwo(props: StepTwoProps) {
  const { isActive } = props;
  const cqgContext = useCQGContext();
  const cqgService = useCQGService();
  const currency = useCurrentCurrency();
  const currentOperation = useCurrentOperation();
  const orderContext = useTradeOrderContext();
  const [orderPlaced, setOrderPlaced] = React.useState(false);
  const [isMarketOpen, setIsMarketOpen] = React.useState(true);
  const [isSymbolReady, setIsSymbolReady] = React.useState(false);
  const [isSymbolError, setIsSymbolError] = React.useState(false);
  const [marketHours, setMarketHours] = React.useState('');
  const [isOrderVerified, setIsOrderVerified] = React.useState(props.orderVerified);
  // Reset the idle timer when this step is reached.
  const timer = useIdleTimerContext();
  timer.start();

  // DERIVED STATE
  const currentOperationName = currentOperation?.name;
  const symbolContract = cqgContext?.client()?.getCurrentSymbol();
  const hasStrike = orderContext.instrumentType.value !== InstrumentType.Futures;
  const isLimitOrder = [OrderType.Limit, OrderType.StopLimit].includes(orderContext.orderType.value);
  const isStopOrder = [OrderType.StopLimit, OrderType.StopWithProtection].includes(orderContext.orderType.value);
  const isMarketOrder = [OrderType.Market].includes(orderContext.orderType.value);
  const isDayOrder = [OrderDurationType.Day].includes(orderContext.orderDurationType.value);
  const barchartSymbol = orderContext.barchartSymbol;

  // DEPENDENT QUERIES
  const realtimePriceQry = useRealtimeQuote({ barchartSymbol, convertFields: true, enabled: isActive && isSymbolReady });

  const quote = realtimePriceQry.data;
  const quoteIsFetching = realtimePriceQry.isFetching;
  const formattedTradeTimestamp = quote ? dayjs(quote?.serverTimestamp).format('LTS') : '';

  useEffect(() => {
    cqgContext?.client()?.once(cqgEvents.receivedSessionTimerangeReport, onSessionTimerangeReport);
  }, []);

  useEffect(() => {
    if (props.isActive) {
      CQGHelpers.resolveSymbol({
        client: cqgContext?.client(),
        resetClient: cqgService?.resetClient,
        symbol: orderContext.cqgSymbol ?? '',
      })
        .then(() => {
          setIsSymbolReady(true);
        })
        .catch((e: Error) => {
          setIsSymbolReady(true);
          setIsSymbolError(true);
        });
    }
  }, [props.isActive]);

  useEffect(() => {
    if (isActive) {
      orderContext.changeOrderValue('intent', TradeExecutionIntent.RESOLVESYMBOL);
    }
  }, [isActive]);

  useEffect(() => {
    if (props.orderVerified || isOrderVerified) {
      setIsOrderVerified(true);
      props.next && props.next();
    }
  }, [props.orderVerified, isOrderVerified]);

  useEffect(() => {
    if (!isActive) return;

    if (isLimitOrder && isNil(orderContext.orderLimitPrice) && quote?.lastPrice) {
      orderContext.changeOrderValue('orderLimitPrice', quote?.lastPrice);
    }

    if (isStopOrder && isNil(orderContext.orderStopPrice) && quote?.lastPrice) {
      orderContext.changeOrderValue('orderStopPrice', quote?.lastPrice);
    }
  }, [isActive, isLimitOrder, isStopOrder, quote]);

  // conditions: clicked, order in progress, order verified, invalid input
  // allow re-placing an order if isErrorState
  const isTradeComplete = (orderPlaced || isOrderVerified) && !props.isErrorState;
  let orderIcon = undefined;
  let orderText = 'Place This Order';
  if (orderPlaced && !props.isErrorState && !isOrderVerified) {
    orderIcon = loadingIcon;
    orderText = '';
  }
  if (orderPlaced && isOrderVerified) {
    orderText = 'Order Placed';
    orderIcon = checkIcon;
  }
  if (props.isErrorState) {
    orderText = 'Place This Order';
    orderIcon = undefined;
  }

  // loader if symbolContract isn't ready
  if (isSymbolError || realtimePriceQry.error) {
    return (
      <div className="loader-wrapper">
        <DialogActionsBar position="top">
          <Button
            square
            themeColor="secondary"
            onClick={props.prev}
            iconPosition="before"
            iconProps={backIcon}
            disabled={isTradeComplete}
          >
            BACK
          </Button>
        </DialogActionsBar>
        <DialogContent className="loader-row">
          <DialogContentRow>
            <p>An error occurred. Please go back and try again.</p>
          </DialogContentRow>
        </DialogContent>
      </div>
    );
  }

  if (!isSymbolReady) {
    return (
      <div className="loader-wrapper">
        <DialogActionsBar position="top">
          <Button
            square
            themeColor="secondary"
            onClick={props.prev}
            iconPosition="before"
            iconProps={backIcon}
            disabled={isTradeComplete}
          >
            BACK
          </Button>
        </DialogActionsBar>
        <DialogContent className="loader-row">
          <DialogContentRow>
            <Loader />
          </DialogContentRow>
        </DialogContent>
      </div>
    );
  }

  // fallbacks
  let stepSize = hasStrike ? 0.00125 : 0.0025;
  let format = hasStrike ? 'c5' : 'c4';
  let tickSize = symbolContract?.tickSize || 0.25;
  const displayTickSize = new Decimal(tickSize).dividedBy(100).toFixed();
  // cqg defined
  if (isSymbolReady) {
    stepSize = (symbolContract?.tickSize || tickSize) / 100;
    format = (symbolContract?.tickSize || 0.25) === 0.25 ? 'c4' : 'c5';
  }
  const commodity = orderContext.selectedCommodity?.value;
  const validLimitPrice = !isNil(orderContext.orderLimitPrice)
    ? new Decimal(orderContext.orderLimitPrice).times(100).mod(tickSize).toNumber() === 0
    : !isLimitOrder;
  const validStopPrice = !isNil(orderContext.orderStopPrice)
    ? new Decimal(orderContext.orderStopPrice).times(100).mod(tickSize).toNumber() === 0
    : !isStopOrder;

  const tradeValue = !isNil(orderContext.orderLimitPrice)
    ? new Decimal(orderContext.orderLimitPrice).times(commodity?.standardSize ?? 1).toFixed(2)
    : null;

  let priceLabel;
  if (isLimitOrder) {
    priceLabel = hasStrike ? 'Set Premium Price' : 'Set Limit Price';
  } else if (isMarketOrder) {
    priceLabel = 'Order Price';
  }

  let canPlaceOrder = !isTradeComplete;
  if (canPlaceOrder) {
    // no Day orders when market is closed
    if (isDayOrder && !isMarketOpen) {
      canPlaceOrder = false;
    }

    if (isLimitOrder && !validLimitPrice) {
      canPlaceOrder = false;
    }

    if (isStopOrder && !validStopPrice) {
      canPlaceOrder = false;
    }

    if (!orderContext.buySell?.value) {
      canPlaceOrder = false;
    }
  }

  return (
    <DialogContent>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          if (validLimitPrice && validStopPrice) {
            onPlaceOrder();
          }
          return false;
        }}
      >
        <DialogActionsBar position="top">
          <Button
            square
            themeColor="secondary"
            onClick={props.prev}
            iconPosition="before"
            iconProps={backIcon}
            disabled={isTradeComplete}
            type="button"
          >
            BACK
          </Button>
        </DialogActionsBar>

        <DialogContentRow className="confirmation">
          <Label fontWeight="bold">Order Summary</Label>
          <p className="text-center mb-1">
            <TradeModalOrderSummaryText orderPlaced={false} orderContext={orderContext} />
          </p>
          <p className="text-center fs-4 fw-bold text-uppercase mb-1">
            {orderContext.contractsQuantity} {orderContext.contractsQuantity === 1 ? 'Contract' : 'Contracts'}
          </p>
          <p className="text-center mb-1">{getDisplayQuantity(commodity, orderContext.contractsQuantity)}</p>
        </DialogContentRow>
        <DialogContentRow center top feature>
          {isLimitOrder && (
            <div className="col-12 col-md-6">
              <Label fontWeight="bold">
                {priceLabel}
                {!hasStrike && <span className="footnote">*</span>}{' '}
                {symbolContract?.currency && <span className="currency">({symbolContract.currency})</span>}
              </Label>
              <div>
                <NumericTextBox
                  name="orderLimitPrice"
                  format={format}
                  step={stepSize}
                  value={orderContext.orderLimitPrice}
                  className="my-1 mx-auto form-control-lg"
                  onChange={(e) => {
                    onChangeOrderPrice(e, 'orderLimitPrice');
                  }}
                  disabled={isTradeComplete}
                  validationMessage={`Price must be in increments of ${displayTickSize}`}
                  valid={validLimitPrice}
                  required={true}
                />
                {!hasStrike && (
                  <>
                    <Hint important className="updated-at" style={{ marginLeft: '16px' }}>
                      {quote && (
                        <Label fontWeight="normal">
                          {formatNumber(quote?.lastPrice, 4, currency)} at {formattedTradeTimestamp}
                        </Label>
                      )}
                      <Loading className={`ms-1 ${quoteIsFetching ? 'visible' : 'invisible'}`} />
                    </Hint>
                    {!isNil(quote?.bid) && !isNil(quote?.ask) && true && (
                      <Hint className="updated-at d-flex justify-content-center">
                        <Label fontWeight="light" className="px-2">
                          {quote?.bid ? `Bid: ${formatNumber(quote.bid, 4, currency)}` : 'No Current Bid'}
                        </Label>
                        <Label fontWeight="light" className="px-2">
                          {quote?.ask ? `Ask: ${formatNumber(quote?.ask, 4, currency)}` : 'No Current Ask'}
                        </Label>
                      </Hint>
                    )}
                  </>
                )}
                {hasStrike && (
                  <Hint important className="updated-at" style={{ marginLeft: '16px' }}>
                    {quote && (
                      <Label fontWeight="normal">
                        {formatNumber(quote?.lastPrice, 5, currency)} at {formattedTradeTimestamp}
                      </Label>
                    )}
                    <Loading className={`ms-1 ${quoteIsFetching ? 'visible' : 'invisible'}`} />
                  </Hint>
                )}
                {hasStrike && !isNil(quote?.bid) && !isNil(quote?.ask) && true && (
                  <Hint className="updated-at d-flex justify-content-center">
                    <Label fontWeight="light" className="px-2">
                      {quote?.bid ? `Bid: ${formatNumber(quote.bid, 5, currency)}` : 'No Current Bid'}
                    </Label>
                    <Label fontWeight="light" className="px-2">
                      {quote?.ask ? `Ask: ${formatNumber(quote?.ask, 5, currency)}` : 'No Current Ask'}
                    </Label>
                  </Hint>
                )}
              </div>
            </div>
          )}

          {isStopOrder && (
            <div className="col-12 col-md-6">
              <Label fontWeight="bold">
                Set Stop Price
                {!hasStrike && <span className="footnote">*</span>}{' '}
                {symbolContract?.currency && <span className="currency">({symbolContract.currency})</span>}
              </Label>
              <div>
                <NumericTextBox
                  name="orderStopPrice"
                  format={format}
                  step={stepSize}
                  value={orderContext.orderStopPrice}
                  className="my-1 mx-auto form-control-lg"
                  onChange={(e) => {
                    onChangeOrderPrice(e, 'orderStopPrice');
                  }}
                  disabled={isTradeComplete}
                  validationMessage={`Price must be in increments of ${displayTickSize}`}
                  valid={validLimitPrice}
                  required={true}
                />
                <Hint>&nbsp;</Hint>
              </div>
            </div>
          )}

          {isMarketOrder && (
            <div className="col-12 col-md-6">
              <Label fontWeight="bold">
                {priceLabel}
                {!hasStrike && <span className="footnote">*</span>}{' '}
                {symbolContract?.currency && <span className="currency">({symbolContract.currency})</span>}
              </Label>
              <div>
                <Label fontWeight="bold" size="large" className="py-2">
                  {formatNumber(quote?.lastPrice, 5, currency)}
                </Label>
                <Hint subtle className="mt-0">
                  Estimated price. You will view your final price after placing order.
                </Hint>
                <Hint important small className="mt-0">
                  {quote && !hasStrike ? `Price updated ${formattedTradeTimestamp}` : ''}
                </Hint>
              </div>
            </div>
          )}
        </DialogContentRow>
        {hasStrike && !isNil(tradeValue) && (
          <DialogContentRow center>
            <Label fontWeight="light">{currentOperationName}</Label>
            <Label fontWeight="bold">
              Total Trade Value<span className="footnote">*</span>
            </Label>
            <Label fontWeight="normal">{formatNumber(tradeValue, 2, currency)}</Label>
          </DialogContentRow>
        )}
        <DialogContentRow center>
          <Button
            themeColor="tertiary"
            size="large"
            iconProps={orderIcon}
            fontWeight="bold"
            disabled={!canPlaceOrder}
            className="col-6"
            type="submit"
          >
            {orderText}
          </Button>
          <Hint subtle important={!isMarketOpen}>
            {marketHours}
          </Hint>
        </DialogContentRow>
        {props.isErrorState && props.message && (
          <DialogContentRow>
            <Alert variant="danger">{props.message}</Alert>
          </DialogContentRow>
        )}
        <DialogContentRow subtle>
          {!hasStrike && (
            <Hint>
              <span className="footnote">*</span>
              <i>The price does not include commissions and fees associated with performing this trade.</i>
            </Hint>
          )}
          {hasStrike && (
            <Hint>
              <span className="footnote">*</span>
              <i>The Total Trade Value does not include commissions and fees associated with performing this trade.</i>
            </Hint>
          )}
        </DialogContentRow>
      </form>
      <TimeoutDisplay onClose={props.onClose} message={'This modal will close in'} />
    </DialogContent>
  );

  // CALLBACKS

  function onPlaceOrder() {
    orderContext.changeOrderValue('intent', TradeExecutionIntent.PLACEORDER);
    setOrderPlaced(true);
    UXLogger.debug('Sending new order', { client: cqgContext?.client()?.toJSON() });

    if (isDEV) {
      UXLogger.debug('DEV MODE: Order verified (promise)', { curentOrder: {} });
      setIsOrderVerified(true);
      return;
    }

    CQGHelpers.sendNewOrder({
      client: cqgContext?.client(),
      resetClient: cqgService?.resetClient,
      order: {
        symbol: orderContext.cqgSymbol ?? '',
        orderType: orderContext.orderType.value as number,
        duration: orderContext.orderDurationType.value as number,
        side: orderContext.buySell?.value as number,
        quantity: orderContext.contractsQuantity as number,
        requestId: orderContext.orderPlacementTimestamp as number,
        limitPrice: orderContext.orderLimitPrice,
        stopPrice: orderContext.orderStopPrice,
      },
    })
      .then((e: any) => {
        //const serverMessage = e as Partial<OrderVerifiedMessage>;
        UXLogger.debug(`Order verified (promise) :: clOrderId: ${e?.clOrderId}, requestId: ${e?.requestId}`, { curentOrder: e });
        setIsOrderVerified(true);
      })
      .catch((e) => {
        setOrderPlaced(false);
      });
  }

  function onChangeOrderPrice(e: NumericTextBoxChangeEvent, prop: keyof TradeOrderState): void {
    orderContext.changeOrderValue(prop, e.value);
  }

  function onSessionTimerangeReport(e: { id: number; statusCode: number; convertedTimeranges: IConvertedSessionTimerange[] }) {
    const now = new Date();
    let sessionName = null;
    let openingTime = null;
    let closingTime = null;
    for (let i = 0; i < e.convertedTimeranges.length; i++) {
      const sessionRange = e.convertedTimeranges[i];
      // skip spurious Sunday session starting at 2340 UTC
      if (
        sessionRange.sessionName === 'Globex' &&
        sessionRange.openTime.getUTCHours() === 23 &&
        sessionRange.openTime.getUTCMinutes() === 40
      ) {
        continue;
      }
      if (now >= sessionRange.openTime && now < sessionRange.closeTime) {
        sessionName = sessionRange.sessionName;
        openingTime = null;
        // Check for contiguous sessions
        if (i != e.convertedTimeranges.length - 1) {
          if (e.convertedTimeranges[i + 1].openTime == sessionRange.closeTime) {
            closingTime = e.convertedTimeranges[i + 1].closeTime;
            break;
          }
        }
        closingTime = sessionRange.closeTime;
        break;
      } else if (now < sessionRange.openTime) {
        sessionName = null;
        openingTime = sessionRange.openTime;
        closingTime = null;
        break;
      }
    }

    if (isDEV) {
      setIsMarketOpen(true);
      return;
    }

    if (sessionName && closingTime) {
      setIsMarketOpen(true);
      setMarketHours(`This session ${sessionName} ends at ${closingTime.toLocaleTimeString()}`);
    } else if (openingTime) {
      setIsMarketOpen(false);
      setMarketHours(`Market opens at ${openingTime.toLocaleTimeString()}`);
    }
  }
}
