import { isNil } from 'lodash';
import React from 'react';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import { useRealtimeQuote } from '../../queries/quotes';
import dayjs from '../../utils/dayjs';
import { formatNumber } from '../../utils/formatNumber';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import Hint from '../common-next/Hint/Hint';
import Label from '../common-next/Label/Label';
import Loading from '../common-next/Loading/Loading';
import Loader from '../common/Loader/Loader';
import { useTradeOrderContext } from './TradeOrderProvider';

interface TradeModalStepOneFuturesProps {
  isActive: boolean; // from StepProps
}

export default function TradeModalStepOneFutures(props: TradeModalStepOneFuturesProps) {
  const { isActive } = props;
  const currency = useCurrentCurrency();
  const orderContext = useTradeOrderContext();
  const barchartSymbol = orderContext.barchartSymbol;
  const realtimePriceQry = useRealtimeQuote({ barchartSymbol, convertFields: true, enabled: !!barchartSymbol && isActive });

  const quote = realtimePriceQry.data;
  const quoteIsFetching = realtimePriceQry.isFetching;
  const formattedTradeTimestamp = quote ? dayjs(quote?.serverTimestamp).format('LTS') : '';

  const futuresPriceContent = (
    <>
      <Label fontWeight="bold" size="large" className="pt-2">
        {quote?.lastPrice ? `${formatNumber(quote.lastPrice, 4, currency)}` : ''}
      </Label>
      {!isNil(quote?.bid) && !isNil(quote?.ask) && true && (
        <Hint className="d-flex justify-content-center">
          <Label fontWeight="light" className="px-2">
            {quote?.bid ? `Bid: ${formatNumber(quote.bid, 4, currency)}` : 'No Current Bid'}
          </Label>
          <Label fontWeight="light" className="px-2">
            {quote?.ask ? `Ask: ${formatNumber(quote?.ask, 4, currency)}` : 'No Current Ask'}
          </Label>
        </Hint>
      )}
      <Hint important small className="mt-0" style={{ marginLeft: '16px' }}>
        {formattedTradeTimestamp ? `Updated at ${formattedTradeTimestamp}` : ''}
        <Loading className={`ms-1 ${quoteIsFetching ? 'visible' : 'invisible'}`} />
      </Hint>
    </>
  );

  const loader = (
    <div className="py-2">
      <Loader />
    </div>
  );

  return (
    <DialogContentRow feature center>
      <div className="col">
        <Label fontWeight="bold">Futures Price</Label>
        <Hint subtle className="mt-0">
          Current price. You can set a limit or stop price in the next step.
        </Hint>
        {!quote ? loader : futuresPriceContent}
      </div>
    </DialogContentRow>
  );
}
