import React from 'react';
import './ProtectedHeader.scss';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import { useAuth0 } from '@auth0/auth0-react';
import UserButton from './UserButton/UserButton';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import Notifications from './Notifications/Notifications';
import { NavLink } from 'react-router-dom';
import { TradeButton } from './TradeButton/TradeButton';
import { GlobalProductionCycleDropdown } from './GlobalProductionCycleDropdown';
import { OperationDropdown } from './OperationDropdown';
import dayjs from 'dayjs';
import Tooltip from '../common-next/Tooltip/Tooltip';
import SettingsButton from './SettingsButton/SettingsButton';
import useAppContext from '../../customHooks/useAppContext';
import { AppContext } from '../../shared/AppContext';
import config from '../../config';

export default function ProtectedHeader() {
  const { isAuthenticated } = useAuth0();
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const isLapTop = useMobileView(BREAKPOINTS.LAPTOP_VIEW);
  const appContext = useAppContext();

  const isSquareLogo = isMobileView || isLapTop;
  const logoSrc = getLogoSrc(isSquareLogo, appContext);
  // for find/replace with whitelabeling changes in the future
  // public/branding/hiq/logo-color-wide.svg
  // public/branding/hiq/logo-color-square.svg
  const hasWhitelabeling = !logoSrc.includes('/branding/fr/') && !logoSrc.includes('/branding/hiq/');

  const logoStyles = isSquareLogo ? {} : { marginTop: '-8px', marginBottom: '-8px' };

  return (
    <Tooltip>
      <div className={`app-protected-header${isMobileView ? ' mobile' : ''}`}>
        <div className="protected-header-navbar">
          <NavLink to="/dashboard" activeClassName="active" style={{ ...logoStyles, textDecoration: 'none' }}>
            <img
              src={logoSrc}
              className={`wrapper-farmers-risk-logo ${isLapTop || isMobileView ? 'wrapper-farmers-risk-logo-mobile' : ''}`}
              alt={config.organization.name}
              title="Go to the Dashboard"
              data-testid="organization-logo"
            />
          </NavLink>
          <div className="header-menu printable">
            <span>{dayjs().format('MMMM D, YYYY')}</span>
          </div>
          <HeaderMenu />
        </div>
        {isAuthenticated && (
          <div className="protected-header-user">
            <OperationDropdown />
            <GlobalProductionCycleDropdown />
            {!isMobileView && (
              <div className="d-print-none" style={{ height: '40px' }}>
                <Notifications />
              </div>
            )}
            {!isMobileView && <SettingsButton />}
            <UserButton hasWhitelabeling={hasWhitelabeling} />
            {!isMobileView && <TradeButton />}
          </div>
        )}
      </div>
    </Tooltip>
  );
}

function getLogoSrc(isSquareLogo: boolean, appContext: AppContext) {
  let logoDirectory = config.organization.logoDirectory.toLowerCase().trim();

  const { whitelabelEnabled, organization } = appContext;
  if (whitelabelEnabled && organization?.whitelabelAssetsDirectory) {
    logoDirectory = organization.whitelabelAssetsDirectory.toLowerCase().trim();
  }

  return `${logoDirectory}/${isSquareLogo ? 'logo-color-square.svg' : 'logo-color-wide.svg'}`;
}
