import { RootState } from '../../types/Store';
import ErrorToast from '../../components/Toast/ErrorToast';
import { LoadingStatuses } from '../LoadingStatuses';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchElevatorInfo } from './thunks';
import { IGrainElevator, mapToCashBids } from '../CashBids/CashBidsSlice';
import { ICashBid } from '../../types/ICashBid';

type ElevatorInfoInitialStateType = {
  elevatorInfo: IElevatorInfo | null;
  status: string;
  error: string | null;
};

const initialState: ElevatorInfoInitialStateType = {
  elevatorInfo: null,
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'elevatorInfo',
  initialState,
  reducers: {
    setElevatorInfo(state, action: PayloadAction<IElevatorInfo | undefined>) {
      state.elevatorInfo = action.payload ?? null;
    },
  },
  extraReducers: {
    [fetchElevatorInfo.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchElevatorInfo.fulfilled.toString()]: (state, action: PayloadAction<IGrainElevator | undefined>) => {
      state.status = LoadingStatuses.Success;
      state.elevatorInfo = processRawElevatorInfo(action.payload);
      state.error = null;
    },
    [fetchElevatorInfo.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load elevator information!');
    },
  },
});

function processRawElevatorInfo(grainElevator: IGrainElevator | undefined) {
  if (!grainElevator) return null;

  const result: IElevatorInfo = { ...grainElevator, cashBids: [] };
  result.cashBids = grainElevator.bids.map(mapToCashBids);
  return result;
}

export default slice.reducer;
export const { setElevatorInfo } = slice.actions;

export const selectElevatorInfo = (state: RootState) => state.elevatorDetailsPage;

export interface IElevatorInfo extends IGrainElevator {
  cashBids: ICashBid[];
}
