import { useState, useEffect, useCallback } from 'react';

function androidOrIOS() {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return 'ios';
  }
}

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    return {
      width: hasWindow ? window.innerWidth : null,
      height: hasWindow ? window.innerHeight : null,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const os = androidOrIOS();

    // resizing on android causes page refreshes when opening numeric keyboard
    if (hasWindow && os !== 'android') {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow, getWindowDimensions]);

  return windowDimensions;
}
