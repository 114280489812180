import axios from 'axios';
import config from '../../config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITradingAccountConfig } from '../../types/ITradingAccountConfig';

type CreateTradingAccountConfigParams = {
  accountConfig: ITradingAccountConfig;
  documentBytes?: BlobPart;
  callback?: () => void;
};
export const createTradingAccountConfig = createAsyncThunk(
  'tradingAccountConfigs/createTradingAccountConfig',
  async (params: CreateTradingAccountConfigParams) => {
    const { accountConfig, documentBytes, callback } = params;
    const formData = new FormData();

    if (documentBytes) {
      const document = new Blob([documentBytes], { type: 'application/pdf' });
      formData.append('document', document, 'filled-admis-form.pdf');
    }

    Object.keys(accountConfig)
      .filter((key) => !['accountNumbers', 'additionalFields'].includes(key))
      .forEach((key) => formData.append(key, accountConfig[key]));
    accountConfig.accountNumbers.forEach((an) => formData.append('accountNumbers', an));
    formData.append('additionalFields', JSON.stringify(accountConfig.additionalFields));

    const resp = await axios.post(`${config.backend.basePath}/trading_account_configs`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (callback) {
      callback();
    }
    return resp.data;
  }
);

type UpdateTradingAccountConfigParams = Omit<CreateTradingAccountConfigParams, 'documentBytes'>;
export const updateTradingAccountConfig = createAsyncThunk(
  'tradingAccountConfigs/updateTradingAccountConfig',
  async (params: UpdateTradingAccountConfigParams) => {
    const { accountConfig, callback } = params;
    const resp = await axios.put(`${config.backend.basePath}/trading_account_configs`, accountConfig);
    if (callback) {
      callback();
    }
    return resp.data;
  }
);
