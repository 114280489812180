import { parse, stringify } from 'qs';
import React, { PropsWithChildren } from 'react';
import { EncodedQuery, QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

export function FRQueryParamProvider(props: PropsWithChildren<unknown>) {
  return (
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{
        // Avoid pushing to history when the query params are updated
        updateType: 'replace',
        searchStringToObject: (searchString) => parse(searchString.replace(/^\?/g, '')) as EncodedQuery,
        objectToSearchString: stringify,
      }}
    >
      {props.children}
    </QueryParamProvider>
  );
}
