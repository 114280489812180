import { CQGClient, ClientContext } from '@farmersrisk/cqg/cqgClient/CQGClient';
import { getOrInitCQGClient, disposeClient } from './client';
import { cqgEvents } from '@farmersrisk/cqg/cqgClient/cqgEvents';
import { OrderAction, OrderActionType } from '../../store/CQG/orderStatusReducer';
import { CQGClientError, ErrorMap, ErrorRecord } from '@farmersrisk/cqg/cqgClient/Errors';
import ErrorToast from '../Toast/ErrorToast';
import { UXLogger } from '../../loggers/UXLogger';
import { TradeOrderLogger } from '../../loggers/TradeOrderLogger';
import { OrderRequest, OrderRequestReject, OrderStatus } from '@farmersrisk/cqg/cqgMessages/WebAPI/order_2';
import { LoggedOff, LoggedOff_LogoffReason, LogonResult } from '@farmersrisk/cqg/cqgMessages/WebAPI/user_session_2';
import { Dispatch, SetStateAction } from 'react';
import { ErrorAction } from '../../store/CQG/errorReducer';
import { User } from '../../types/User';
import { Operation } from '../../types/Operation';
import { createLogger } from '../../loggers';
import { useQuery } from '@tanstack/react-query';
import { OrderStatus_Status } from '@farmersrisk/cqg/cqgMessages/common/shared_1';

const logger = createLogger('CQGService');

// !!SINGLETON!!
let devTimer = 0;

// !!SINGLETON!!
let __CQG_SERVICE_INSTANCE__: CQGService | undefined = undefined;

type CQGServiceInitOptions = {
  user: User;
  operation: Operation;
  username: string;
  password: string;
  tradingAccountNumbers: string[];
  websocketAddress?: string;
  dispatch: Dispatch<OrderAction>;
  errorDispatch: Dispatch<ErrorAction>;
  setForcedLogoff: Dispatch<SetStateAction<{ forced: boolean; message: string }>>;
};

export class CQGService {
  public _client: CQGClient;
  public user: User;
  public operation: Operation;
  private _initOptions: CQGServiceInitOptions;

  constructor(opts: CQGServiceInitOptions) {
    const { user, operation } = opts;
    this.user = user;
    this.operation = operation;
    this._initOptions = opts;

    this._client = initializeCQGClientWithEvents(opts);
  }

  get client() {
    return this._client;
  }

  get username() {
    return this._client.username;
  }

  resetClient = () => {
    disposeClient().catch((e) => {
      logger.error('Error disposing client during reset', e);
    });

    this._client = initializeCQGClientWithEvents(this._initOptions);

    return this._client;
  };

  public async dispose() {
    try {
      await disposeClient();
    } catch (e) {
      console.error('Error disposing client', e);
    }
  }
}

export function getOrInitCQGService(opts: CQGServiceInitOptions) {
  const query = useQuery({
    queryKey: ['cqg-service', opts.operation.id, opts.user.id, opts.username],
    queryFn: () => {
      return getOrInitCQGServiceInternal(opts);
    },
    enabled: !!(opts.username && opts.password && opts.tradingAccountNumbers && opts.tradingAccountNumbers.length),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    retry: 3,
    onError: (err) => {
      console.error('Error getting CQG service', err);
    },
  });

  return query;
}

export async function getOrInitCQGServiceInternal(opts: CQGServiceInitOptions) {
  if (__CQG_SERVICE_INSTANCE__) {
    let currentService = __CQG_SERVICE_INSTANCE__;

    // detect if operation or user has changed!
    if (
      currentService.username !== opts.username ||
      currentService.user.id !== opts.user.id ||
      currentService.operation.id !== opts.operation.id
    ) {
      logger.info('User or operation has changed, resetting CQG client');
      await currentService.dispose();
      currentService = new CQGService(opts);
      __CQG_SERVICE_INSTANCE__ = currentService;
    }

    return __CQG_SERVICE_INSTANCE__;
  }

  const svc = new CQGService(opts);
  __CQG_SERVICE_INSTANCE__ = svc;

  return __CQG_SERVICE_INSTANCE__;
}

function initializeCQGClientWithEvents(opts: CQGServiceInitOptions) {
  const { user, operation, dispatch, errorDispatch, setForcedLogoff } = opts;

  const userId = user.id ?? 0;
  const operationId = operation.id ?? 0;

  const client = getOrInitCQGClient({
    userId: user.id ?? 0,
    operationId: operation.id ?? 0,
    username: opts.username,
    password: opts.password,
    brokerageAccountIds: opts.tradingAccountNumbers,
    websocketAddress: opts.websocketAddress,
  });

  client.on(cqgEvents.receivedOrderStatus, onReceivedOrderStatus);
  client.on(cqgEvents.receivedOrderRequestRejects, onReceivedRejectStatus);
  client.on(cqgEvents.receivedLogonFailure, onLogonError);
  client.on(cqgEvents.receivedError, onError);
  client.on(cqgEvents.websocketDisconnect, onDisconnect);
  client.on(cqgEvents.receivedError, logTradeError);
  client.on(cqgEvents.sendNewOrder, onSendOrder);
  client.on(cqgEvents.receivedLogoff, onLogoff);
  client.on(cqgEvents.receivedLogonSuccess, onLogon);

  return client;

  // #region Handlers
  function onReceivedOrderStatus(data: OrderStatus) {
    TradeOrderLogger.debug(`${client.getDebugInfo()} - Status - ${data.order?.clOrderId} : ${OrderStatus_Status[data.status]}`, {
      cqgData: data,
    });

    UXLogger.debug('CQGProvider.onReceivedOrderStatus', { client: client.toJSON() });
    dispatch({ type: OrderActionType.Update, status: data });
  }

  function onReceivedRejectStatus(data: OrderRequestReject) {
    TradeOrderLogger.warn(`${client.getDebugInfo()} - Reject - (${data.requestId})`, {
      userId,
      operationId: operationId,
      cqgData: data,
      cqgToken: client.getSessionToken(),
    });
    dispatch({ type: OrderActionType.Reject, reject: data });
  }

  function onError(data: ErrorRecord) {
    errorDispatch({ payload: data });
  }

  function onLogonError(data: ErrorRecord) {
    errorDispatch({ payload: data });
    ErrorToast(data.msg || ErrorMap[data.code], 'Trading Credentials', 'trading-credentials');
  }

  function onDisconnect() {
    if (client.getContext() !== ClientContext.NONE) {
      UXLogger.warn('CQG client disconnected when not done', { context: client.getContext() });
      // pass in current clientRef because the redux store selector for userId/operation will be stale for some reason
      // const client = createNewClient(clientRef.current);
      // clientRef.current = client;
    }
  }

  function logTradeError(data: CQGClientError) {
    TradeOrderLogger.error(`${client.getDebugInfo()} - Error - ${data.textMessage}`, {
      userId,
      operationId: operationId,
      cqgData: data,
      cqgToken: client.getSessionToken(),
    });
  }

  function onSendOrder(data: OrderRequest) {
    TradeOrderLogger.info(`${client.getDebugInfo()} - Sent - ${data.newOrder?.order?.clOrderId} (${data.requestId})`, {
      userId,
      operationId: operationId,
      cqgData: data,
      cqgToken: client.getSessionToken(),
    });
  }

  function onLogoff(data: LoggedOff) {
    if (data.logoffReason !== LoggedOff_LogoffReason.LOGOFF_REASON_BY_REQUEST) {
      // close because the session must be restarted via a new websocket or else we'll receive "Message cannot be processed
      // within this user session after logoff or disconnect."
      client
        .close({ removeListeners: false })
        .then(() => {
          setForcedLogoff({ forced: true, message: data.textMessage || '' });
          clearTimeout(devTimer);
          devTimer = window.setTimeout(() => {
            setForcedLogoff({ forced: false, message: '' });
          }, 30000);
        })
        .catch((err) => {
          console.error('Error closing client', err);
        });
    }
  }

  function onLogon(data: LogonResult) {
    clearTimeout(devTimer);
    setForcedLogoff({ forced: false, message: '' });
  }
  // #endregion Handlers
}
