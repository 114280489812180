import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import useCurrentProductionCycle from '../../../customHooks/useCurrentProductionCycle';
import usePermission from '../../../customHooks/usePermission';
import { useAllOperationCrops } from '../../../queries/operationCrops';
import { selectBudgets } from '../../../store/Budgets/BudgetsSlice';
import { createBudget, deleteBudget, updateBudget } from '../../../store/Budgets/thunks';
import { selectBudgetType, updateCostsPageConfig } from '../../../store/PagesConfigs/Costs/costsSlice';
import { BudgetType, IBudget, IBudgetsItem } from '../../../types/IBudget';
import { Operation } from '../../../types/Operation';
import { IOperationCropV2 } from '../../../types/IOperationCrops';
import { RootState } from '../../../types/Store';
import { isLoading } from '../../../utils/helpers';
import Loader from '../../common/Loader/Loader';
import CustomPrompt from '../../common/Prompt/CustomPrompt';
import CropsWarningRow from '../../common/Warnings/CropsWarningRow';
import './Costs.css';
import CostsBudgetModeSwitcher from './CostsBudgetModeSwitcher';
import CustomMode from './CustomeMode/CustomMode';
import SimpleMode from './SimpleMode';
import Summary from './Summary';
import UniversityMode from './UniversityMode';

const makeSelectBudget = () =>
  createSelector(
    selectBudgets,
    (_: RootState, productionCycle: string) => productionCycle,
    (_: RootState, __: string, budgetType: BudgetType | null) => budgetType,
    (budgets, productionCycle, budgetType) =>
      budgets.filter(
        (budget) => budget.budgetType === budgetType && budget.year === productionCycle && budget.operationId !== null
      )[0]
  );

const makeSelectActiveBudget = () =>
  createSelector(
    selectBudgets,
    (_: RootState, productionCycle: string) => productionCycle,
    (budgets, productionCycle) =>
      budgets.filter((budget) => budget.year === productionCycle && budget.operationId !== null && budget.isActive)[0]
  );

function CostsV2() {
  const productionCycle = useCurrentProductionCycle();
  const budgetType = useSelector(selectBudgetType);
  const budgetStatus = useSelector((state: RootState) => state.budgets.status);
  const selectBudget = useMemo(makeSelectBudget, [productionCycle, budgetType]);
  const budget = useSelector((state: RootState) => selectBudget(state, productionCycle, budgetType));
  const selectActiveBudget = useMemo(makeSelectActiveBudget, [productionCycle]);
  const activeBudget = useSelector((state: RootState) => selectActiveBudget(state, productionCycle));
  const currentOperation = useCurrentOperation();
  const operationCrops = useAllOperationCrops().data || [];
  const selectedYear = useSelector((state: RootState) => state.productionCycles.selectedYear);
  const isAtLeastEditor = usePermission();
  const dispatch = useDispatch();

  const currentOperationCrops = operationCrops.filter(
    (operationCrop) => operationCrop.productionCycle === productionCycle && !operationCrop.hidden
  );

  const [editableBudget, setEditableBudget] = useState<IBudget>(
    getInitialBudget(currentOperationCrops, currentOperation, productionCycle, budgetType)
  );

  useEffect(() => {
    setEditableBudget(budget);
  }, [budget]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!!activeBudget && editableBudget?.budgetType !== activeBudget?.budgetType) {
      dispatch(deleteBudget(activeBudget));
    }

    if (!editableBudget.id) {
      if (editableBudget && isEmpty(editableBudget.cropBudgets)) {
        editableBudget.cropBudgets = Object.fromEntries([currentOperationCrops.map((c) => c.id), 0].map((k) => [k, []]));
      }
      dispatch(createBudget({ ...editableBudget, isActive: true }));
    } else {
      dispatch(updateBudget({ ...editableBudget, isActive: true }));
    }
  };

  const onCancel = () => {
    if (editableBudget?.budgetType !== activeBudget?.budgetType || editableBudget?.year !== activeBudget?.year) {
      dispatch(
        updateCostsPageConfig({
          budgetType: activeBudget?.budgetType,
        })
      );
    } else {
      setEditableBudget(budget);
    }
  };

  if (isLoading([budgetStatus])) {
    return <Loader />;
  }

  const areButtonsDisabled =
    (editableBudget?.budgetType === activeBudget?.budgetType && isEqual(budget, editableBudget)) || !isAtLeastEditor;

  const noCrops = isEmpty(currentOperationCrops.filter((x) => x.productionCycle === selectedYear));

  if (noCrops) {
    return (
      <div className="costs-settings">
        <div className="warning-wrapper">
          <CropsWarningRow selectedYear={selectedYear} />
        </div>
      </div>
    );
  }

  return (
    <div className="costs-settings">
      <div>
        <Summary editableBudget={editableBudget} />
        <form onSubmit={onSubmit}>
          <div className="card-wrapper">
            <div className="card">
              <div className="settings-cost-tabSwitcher">
                <CostsBudgetModeSwitcher />
              </div>
              {budgetType === BudgetType.Simple && (
                <SimpleMode editableBudget={editableBudget} setEditableBudget={setEditableBudget} />
              )}
              {budgetType === BudgetType.University && (
                <UniversityMode editableBudget={editableBudget} setEditableBudget={setEditableBudget} />
              )}
              {budgetType === BudgetType.Custom &&
                (editableBudget && editableBudget.isNew ? (
                  <UniversityMode editableBudget={editableBudget} setEditableBudget={setEditableBudget} />
                ) : (
                  <CustomMode setEditableBudget={setEditableBudget} editableBudget={editableBudget} />
                ))}
            </div>
          </div>
          <div className="fixed-footer">
            {budgetType === BudgetType.Custom && editableBudget && editableBudget.isNew ? (
              <>
                <button type="button" hidden={areButtonsDisabled} className="fr-button backGround-white" onClick={onCancel}>
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setEditableBudget({ ...editableBudget, isNew: false });
                  }}
                  disabled={areButtonsDisabled}
                  className="fr-button backGround-red"
                >
                  Next - Customize My Costs
                </button>
              </>
            ) : (
              <>
                <button type="submit" disabled={areButtonsDisabled} className="fr-button backGround-red">
                  Save Changes
                </button>
                <button type="button" hidden={areButtonsDisabled} className="fr-button backGround-white" onClick={onCancel}>
                  Cancel Changes
                </button>
              </>
            )}
          </div>
        </form>
        <CustomPrompt isPromptActive={!areButtonsDisabled} okButtonCallback={onCancel} />
      </div>
    </div>
  );
}

export default CostsV2;

export function getInitialBudget(
  operationCrops: IOperationCropV2[],
  currentOperation: Operation,
  productionCycle: string,
  budgetType: BudgetType | null
): IBudget {
  const cropBudgets = {} as { [key: number]: IBudgetsItem[] };
  const result = {
    id: 0,
    operationId: currentOperation.id,
    name: currentOperation.name + ' - ' + productionCycle,
    year: productionCycle,
    isActive: true,
    createdAt: '',
  };

  if (budgetType === BudgetType.Simple) {
    operationCrops.forEach((crop) => {
      cropBudgets[crop.id] = [{ value: 0, valueType: 2, categoryName: 'Production Cost' }];
    });
    return {
      ...result,
      budgetType: 1,
      cropBudgets,
    };
  }

  if (budgetType === BudgetType.University) {
    return {
      ...result,
      budgetType: 2,
      cropBudgets,
    };
  }

  // Custom
  return {
    ...result,
    budgetType: BudgetType.Custom,
    isNew: true,
    cropBudgets: {},
  };
}
