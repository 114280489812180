import './Toast.css';
import { toast } from 'react-toastify';
import React from 'react';

export default function NewVersionToast(): void {
  toast.info(
    <div className="custom-toast">
      <span>Update available!</span>
      <p>
        A newer version is available. <a href="/">Reload</a>
      </p>
    </div>,
    {
      toastId: 'new-version-toast',
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: true,
      bodyClassName: 'new-version-toast-body',
      className: 'new-version-toast',
      onClick: () => {
        window.location.reload();
      },
    }
  );
}
