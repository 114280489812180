import { IconProp, faCorn, faFarm, faPeapod, faWheat, FontAwesomeIcon, FontAwesomeIconProps } from '../../faIcons';
import React from 'react';
import { CommodityId } from '@harvestiq/constants';

interface CropIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  cropType?: CommodityId;
}

const cropTypeIconMap = {
  [CommodityId.UNKNOWN]: faCorn,
  [CommodityId.CORN]: faCorn,
  [CommodityId.SOYBEANS]: faPeapod,
  [CommodityId.KC_WINTER_WHEAT_HRW]: faWheat,
  [CommodityId.MN_SPRING_WHEAT_HRSW]: faWheat,
  [CommodityId.CHI_SOFT_RED_WINTER_SRW]: faWheat,
  [CommodityId.OATS]: faWheat,
  [CommodityId.SORGHUM]: faCorn,
  [CommodityId.ZUC]: faFarm,
  [CommodityId.LEAN_HOGS]: faFarm,
  [CommodityId.FEEDER_CATTLE]: faFarm,
  [CommodityId.LIVE_CATTLE]: faFarm,
  [CommodityId.SOYBEAN_MEAL]: faFarm,
};

export function CropIcon(props: CropIconProps) {
  const { cropType, ...rest } = props;
  let icon = cropTypeIconMap[cropType || 0] || faFarm;
  return <FontAwesomeIcon icon={icon as IconProp} {...rest} />;
}
