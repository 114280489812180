import '../HistoryChart.css';

import React, { useEffect, useState } from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';
import useWindowDimensions from '../../../customHooks/useWindowDimensions';
import { CommodityInformation } from '@harvestiq/constants';
import { findNearestMonthSelectorAfterCurrent } from '../../../models/CropSymbols/cropFuturesSymbolLookup';
import { isEmpty } from 'lodash';
import { QuotesStoreItem } from '../../../store/Quotes/quotesSlice';
import { HistoryQuotesStoreItem } from '../../../store/HistoryQuotes/historyQuotesSlice';
import { IHistoryQuote } from '../../../types/IHistoryQuote';
import { getTrendLine } from '../../../models/trendLine';
import { SelectorTypes } from '../../../components/common/SelectWithLabel/SelectWithLabel';
import Tab from '../../../components/common/TabSwitcher/Tab';
import TabSwitcher from '../../../components/common/TabSwitcher/TabSwitcher';
import Selector from '../../../components/common/Selector/Selector';
import Loader from '../../../components/common/Loader/Loader';
import { IHistoryQuoteParams, useHistoryQuotes } from '../../../queries/useHistoryQuotes';
import { UseQueryResult } from '@tanstack/react-query';
import ErrorToast from '../../../components/Toast/ErrorToast';
import { DropDownListChangeEvent } from '../../../components/common-next/DropDownList/DropDownList';
import HedgingCropTypeDropDownNavigator from '../../../components/common-next/DropDownList/HedgingCropTypeDropDownNavigator';

interface IHistoryChartProps {
  crops: CommodityInformation[];
  cropFuturesExpirations: { [key: string]: any };
  hedgingCrops: CommodityInformation[];
  quotesQuery: UseQueryResult<QuotesStoreItem | undefined>;
}

export enum HistoryChartTimePeriod {
  YEAR = 1,
  QUARTER = 2,
  MONTH = 3,
  WEEK = 4,
  FIVE_YEARS = 5,
}

const timePeriods = [
  HistoryChartTimePeriod.WEEK,
  HistoryChartTimePeriod.MONTH,
  HistoryChartTimePeriod.QUARTER,
  HistoryChartTimePeriod.YEAR,
  HistoryChartTimePeriod.FIVE_YEARS,
];

const pad = (number: number, size: number) => {
  let num = number.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

const formatDateForQuery = (date: Date) => {
  return `${date.getFullYear()}${pad(date.getMonth() + 1, 2)}${pad(date.getDate(), 2)}`;
};

const timePeriodToStartEnd = (timePeriod: HistoryChartTimePeriod) => {
  const date = new Date();
  const endDate = formatDateForQuery(date);
  switch (timePeriod) {
    case HistoryChartTimePeriod.FIVE_YEARS:
      date.setFullYear(date.getFullYear() - 5);
      break;
    case HistoryChartTimePeriod.YEAR:
      date.setFullYear(date.getFullYear() - 1);
      break;
    case HistoryChartTimePeriod.QUARTER:
      date.setMonth(date.getMonth() - 3);
      break;
    case HistoryChartTimePeriod.MONTH:
      date.setMonth(date.getMonth() - 1);
      break;
    case HistoryChartTimePeriod.WEEK:
      date.setDate(date.getDate() - 7);
      break;
  }
  const startDate = formatDateForQuery(date);
  return { startDate, endDate };
};

const timePeriodToType = (timePeriod: HistoryChartTimePeriod) => {
  switch (timePeriod) {
    case HistoryChartTimePeriod.FIVE_YEARS:
      return 'quarterly';
    case HistoryChartTimePeriod.YEAR:
      return 'monthly';
    case HistoryChartTimePeriod.QUARTER:
      return 'weekly';
    case HistoryChartTimePeriod.MONTH:
      return 'daily';
    default:
      return 'daily';
  }
};

const timePeriodToString = (timePeriod: HistoryChartTimePeriod) => {
  switch (timePeriod) {
    case HistoryChartTimePeriod.FIVE_YEARS:
      return '5 years';
    case HistoryChartTimePeriod.YEAR:
      return '1 year';
    case HistoryChartTimePeriod.QUARTER:
      return '3 months';
    case HistoryChartTimePeriod.MONTH:
      return '1 month';
    case HistoryChartTimePeriod.WEEK:
      return '7 days';
  }
};

const formatTradingDay = (tradingDay: string) => {
  const date = new Date(Date.parse(tradingDay));
  const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString(undefined, options);
};

const getMarketData = (
  quotes: QuotesStoreItem,
  historyQuotes: HistoryQuotesStoreItem,
  tradingCode: string,
  selectedTimePeriod: number
) => {
  const params = {
    symbol: tradingCode,
    type: timePeriodToType(selectedTimePeriod),
    ...timePeriodToStartEnd(selectedTimePeriod),
  };
  const result: IHistoryQuote[] = [];

  if (historyQuotes[JSON.stringify(params)]) {
    result.push(...historyQuotes[JSON.stringify(params)]);
  }

  const currentDayHistory = {
    close: quotes[tradingCode]?.close || quotes[tradingCode]?.lastPrice,
    tradingDay: new Date().toLocaleDateString('en-US'),
    openInterest: 0,
    timestamp: '',
  };

  if (result.length && result[result.length - 1].tradingDay !== currentDayHistory.tradingDay && quotes[tradingCode]) {
    const { high, low, mode, open, symbol, volume } = quotes[tradingCode];
    result.push({ ...currentDayHistory, high, low, mode, open, symbol, volume });
  }

  return result;
};

function renderChart(chartData: Serie[]) {
  const values = chartData[0].data.map((item) => item.y) as number[];
  const min = Math.floor(Math.min(...values));
  const max = Math.ceil(Math.max(...values));

  return (
    <ResponsiveLine
      data={[
        ...chartData,
        {
          id: 'trendline',
          data: getTrendLine([...chartData[0].data]),
          color: '#1262AB',
        },
      ]}
      margin={{ left: 40, top: 30, right: 70, bottom: 50 }}
      crosshairType={'bottom-right'}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', min: min, max: max }}
      axisLeft={null}
      axisTop={null}
      axisRight={{
        tickSize: 0,
        format: '>-$5.2~f',
      }}
      axisBottom={{
        tickSize: 15,
      }}
      pointSize={2}
      pointColor={{ from: 'color' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'color' }}
      pointLabelYOffset={-12}
      lineWidth={2}
      enableGridX={false}
      useMesh={true}
      colors={{ datum: 'color' }}
    />
  );
}

function HistoryChart(props: IHistoryChartProps) {
  const { crops, cropFuturesExpirations, hedgingCrops, quotesQuery } = props;

  const quotes = quotesQuery.data || {};
  const quotesIsLoading = quotesQuery.isLoading;

  const [historyQuotesParams, setHistoryQuotesParams] = useState<IHistoryQuoteParams | null>(null);
  const historyQuotesQuery = useHistoryQuotes(historyQuotesParams);
  const historyQuotes = historyQuotesQuery.data || {};
  const historyQuotesIsLoading = historyQuotesQuery.isLoading;
  const historyQuotesIsError = historyQuotesQuery.isError;
  const historyQuotesIsFetching = historyQuotesQuery.isFetching;

  const defaultCrop = hedgingCrops[0] || crops[0];
  const [selectedCrop, setSelectedCrop] = useState<CommodityInformation>(defaultCrop);
  const [selectedTradingCode, setSelectedTradingCode] = useState<SelectorTypes>();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<HistoryChartTimePeriod>(HistoryChartTimePeriod.YEAR);
  const { height } = useWindowDimensions();

  const selectExpirationMonth = (crop: CommodityInformation) => {
    const options = cropFuturesExpirations[crop.tradingCode];
    const nearestMonth = findNearestMonthSelectorAfterCurrent(options, crop);
    setSelectedTradingCode(nearestMonth);
  };

  useEffect(() => {
    if (isEmpty(cropFuturesExpirations) || !!selectedTradingCode) {
      return;
    }

    if (selectedCrop) {
      selectExpirationMonth(selectedCrop);
    } else if (defaultCrop) {
      setSelectedCrop(defaultCrop);
      selectExpirationMonth(defaultCrop);
    }
    // eslint-disable-next-line
  }, [defaultCrop, selectedCrop, cropFuturesExpirations, selectedTradingCode]);

  useEffect(() => {
    if (selectedTradingCode && selectedTimePeriod) {
      const params: IHistoryQuoteParams = {
        symbol: selectedTradingCode.value,
        type: timePeriodToType(selectedTimePeriod),
        ...timePeriodToStartEnd(selectedTimePeriod),
      };
      if (!historyQuotesIsFetching && !historyQuotes[JSON.stringify(params)]) {
        setHistoryQuotesParams(params);
      }
    }
  }, [historyQuotes, selectedTradingCode, selectedTimePeriod]);

  useEffect(() => {
    if (!hedgingCrops.includes(selectedCrop)) {
      setSelectedCrop(defaultCrop);
      selectExpirationMonth(defaultCrop);
    }
    // eslint-disable-next-line
  }, [hedgingCrops, defaultCrop, selectedCrop]);

  const switchCrop = (e: DropDownListChangeEvent) => {
    const value = e.value.value as CommodityInformation;
    const crop = hedgingCrops.find((x) => x.id === value.id);
    if (crop) {
      setSelectedCrop(crop);
      selectExpirationMonth(crop);
    }
  };

  const switchTimePeriod = (period: HistoryChartTimePeriod) => {
    setSelectedTimePeriod(period);
  };

  const mapDataToChartParams = (crop: CommodityInformation, chartData: IHistoryQuote[]): Serie[] => {
    return [
      {
        id: crop.name,
        color: 'hsl(0,0%,0%)',
        data: chartData.map((item: IHistoryQuote) => {
          return { x: formatTradingDay(item.tradingDay), y: (item.close / 100).toFixed(2) };
        }),
      },
    ];
  };

  const timePeriodTabs = timePeriods.map((timePeriod: HistoryChartTimePeriod) => (
    <Tab
      key={timePeriod}
      tabClassName="tab-button"
      selectedClassName="tab-button-selected"
      onClick={() => switchTimePeriod(timePeriod)}
      name={timePeriodToString(timePeriod)}
      active={selectedTimePeriod === timePeriod}
    />
  ));

  const calculateChartHeight = (screenHeight: number | null) => {
    let height = 300;
    if (screenHeight) {
      const calculatedHeight = screenHeight - 260;
      if (calculatedHeight > height) {
        height = calculatedHeight;
      }
    }

    return height;
  };

  if (historyQuotesIsError) {
    ErrorToast('Failed to load prices history!');
  }

  const getChart = () => {
    const marketData = getMarketData(quotes, historyQuotes, selectedTradingCode?.value || '', selectedTimePeriod);
    if (marketData.length) {
      return renderChart(mapDataToChartParams(selectedCrop, marketData));
    } else {
      return 'No data';
    }
  };

  return (
    <div className="d-print-none card history-chart">
      <div className="flex-column">
        <div className="flex-row">
          {!!crops.length && selectedCrop && (
            <HedgingCropTypeDropDownNavigator
              onChange={switchCrop}
              selectedHedgingCropType={selectedCrop.id}
              byCurrentProductionCycle={true}
            />
          )}
        </div>
        <div className="flex-row history-chart-settings-row2">
          {!!timePeriods.length && <TabSwitcher children={timePeriodTabs} />}
          <div className="flex-row history-chart-month-select-wrapper">
            {selectedCrop && (
              <label>
                {selectedCrop.name + ' (' + selectedCrop.tradingCode + '):'}
                <Selector
                  options={cropFuturesExpirations[selectedCrop.tradingCode]}
                  selected={selectedTradingCode}
                  onChange={setSelectedTradingCode}
                />
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="chart" style={{ height: `${calculateChartHeight(height)}px` }}>
        {quotesIsLoading || historyQuotesIsLoading || historyQuotesIsFetching || !selectedCrop || !selectedTradingCode ? (
          <Loader />
        ) : (
          getChart()
        )}
      </div>
    </div>
  );
}

export default HistoryChart;
