import WarningRow from './WarningRow';

type LivestockWarningRowProps = {
  selectedYear: string;
};

export function LivestockWarningRow({ selectedYear }: LivestockWarningRowProps) {
  const displayMessage = `Livestock inventory is empty for ${selectedYear}`;

  return <WarningRow message={displayMessage} linkTo="/livestock/lots" linkCaption="Add a Livestock Lot" />;
}

export default LivestockWarningRow;
