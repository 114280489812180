export const enum RoleType {
  Admin = 0,
  Editor = 1,
  Viewer = 2,
  Advisor = 3,
}

export function roleTypeToString(roleType: RoleType) {
  switch (roleType) {
    case RoleType.Admin:
      return 'Admin';
    case RoleType.Editor:
      return 'Editor';
    case RoleType.Viewer:
      return 'Viewer';
    case RoleType.Advisor:
      return 'Advisor';
    default:
      return 'Unknown';
  }
}
