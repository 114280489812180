import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../types/Store';
import ErrorToast from '../../components/Toast/ErrorToast';
import { LoadingStatuses } from '../LoadingStatuses';
import { IFuturesOptions } from '../../types/IFuturesOptions';
import { fetchFuturesOptions } from './thunks';

export type FuturesOptionsStoreItem = {
  [symbol: string]: {
    data: IFuturesOptions[];
    lastUpdate: string;
  };
};

type FuturesOptionsSliceInitialStateType = {
  futuresOptions: FuturesOptionsStoreItem;
  status: string;
  error: string | null;
};

const initialState: FuturesOptionsSliceInitialStateType = {
  futuresOptions: {},
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'futuresOptions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFuturesOptions.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchFuturesOptions.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.futuresOptions = { ...state.futuresOptions, ...prepareFuturesOptions(action.payload, action.meta.arg) };
    },
    [fetchFuturesOptions.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load options prices!');
    },
  },
});

function prepareFuturesOptions(futuresOptions: IFuturesOptions[], symbolsRequested: string[]): FuturesOptionsStoreItem {
  const result: FuturesOptionsStoreItem = {};
  symbolsRequested.forEach(
    (symbol, index) =>
      (result[symbol] = { data: futuresOptions.filter((x) => x.symbol === symbol), lastUpdate: new Date().toString() })
  );
  return result;
}

export default slice.reducer;

export const selectAllFuturesOptions = (state: RootState) => state.futuresOptions.futuresOptions;
