import { uniq } from 'lodash';
import { SelectorTypes } from '../../components/common/SelectWithLabel/SelectWithLabel';
import { SymbolLookUpsStoreItem } from '../../store/SymbolLookUps/symbolLookUpsSlice';
import { IOperationCropV2 } from '../../types/IOperationCrops';
import { findCropMonthSelector } from './cropFuturesSymbolLookup';
import { getCropFutures } from '../getMarketData';
import dayjs from '../../utils/dayjs';
import { FUTURES_MONTH_CODES, NEW_CROP_FUTURES_MONTH_BY_CROP_ID } from '@harvestiq/symbology';
import { getFuturesParamsForDate } from '@harvestiq/symbology';
import { CommodityInformation } from '@harvestiq/constants';
import { getBarchartSymbol } from '@harvestiq/symbology';

const EXCHANGES: { [key: string]: string } = {
  CBOT: 'CBOT',
  KCBT: 'KCBT',
  MGEX: 'MGEX',
} as const;

// According to Barchart
const EXCHANGES_BY_SYMBOL_PREFIX: { [key: string]: string } = {
  ZC: EXCHANGES.CBOT,
  ZS: EXCHANGES.CBOT,
  ZW: EXCHANGES.CBOT,
  ZO: EXCHANGES.CBOT,
  BC: EXCHANGES.CBOT,
  BY: EXCHANGES.CBOT,
  BH: EXCHANGES.CBOT,

  KE: EXCHANGES.KCBT,
  MW: EXCHANGES.MGEX,
} as const;

export function getContractTradingCode(crop: CommodityInformation | undefined, year = '', month = 0) {
  if (!crop) {
    return '';
  }

  const targetDate = dayjs().year(Number(year)).month(month).date(1);
  const params = getFuturesParamsForDate(crop, targetDate);

  return getBarchartSymbol(params) ?? '';
}

export function getNewCropFuturesSymbol(crop: CommodityInformation, year: string = new Date().getFullYear().toString()) {
  return crop.tradingCode + FUTURES_MONTH_CODES[NEW_CROP_FUTURES_MONTH_BY_CROP_ID[crop.id]] + year.substring(2, 4);
}

export function getFuturesTradingCode(
  targetYear: string,
  crop: CommodityInformation,
  availableFutures: { [cropTradingCode: string]: SelectorTypes[] }
): string {
  const year = new Date().getFullYear().toString();
  if (Number(targetYear) > Number(year)) {
    const futureYearTradingCode = getNewCropFuturesSymbol(crop, targetYear);
    const availableCodes = (availableFutures[crop.tradingCode] || []).map((o) => o.value);
    return availableCodes.includes(futureYearTradingCode) ? futureYearTradingCode : '';
  } else {
    return findCropMonthSelector(targetYear, crop, availableFutures[crop.tradingCode])?.value || '';
  }
}

export function getFuturesSymbols(
  operationCrops: IOperationCropV2[],
  crops: CommodityInformation[],
  symbolLookUps: SymbolLookUpsStoreItem
): string[] {
  const cropFutures = getCropFutures(crops, symbolLookUps);

  return uniq(
    operationCrops
      .map((operationCrop) => {
        const crop = crops.find((crop) => crop.id === operationCrop.hedgingCropType);
        if (crop) {
          return getFuturesTradingCode(operationCrop.productionCycle, crop, cropFutures);
        } else {
          return '';
        }
      })
      .filter((tradingCode) => tradingCode)
  );
}

export function getExchangeBySymbol(symbol: string): string {
  return EXCHANGES_BY_SYMBOL_PREFIX[symbol.slice(0, 2).toUpperCase()];
}

export function getExchangesBySymbols(symbols: string[]): Map<string, string[]> {
  const symbolsWithExchange = symbols.map((x) => {
    return {
      symbol: x,
      exchange: getExchangeBySymbol(x) || '',
    };
  });

  const result: Map<string, string[]> = new Map();
  symbolsWithExchange.forEach((x) => {
    result.set(x.exchange, (result.get(x.exchange) || []).concat([x.symbol]));
  });

  return result;
}

export function isValidSymbol(symbol: string) {
  // best we can do at the moment ... TODO: see if we can improve this symbol validation logic
  return !!symbol && symbol.trim() !== '' && symbol.length > 4;
}
