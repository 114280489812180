import { SelectorTypes } from '../components/common/SelectWithLabel/SelectWithLabel';

export const prepareCoveragePercentageForSelect = (isPercent = false): SelectorTypes<number>[] => {
  const coverage = [];
  let i = 55;
  while (i <= 95) {
    if (isPercent) {
      coverage.push({ value: i, label: i + '%' });
    } else {
      coverage.push({ value: i, label: i.toString() });
    }
    i = i + 5;
  }
  return coverage;
};
