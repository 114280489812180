import styles from './Dialog.module.scss';

import * as React from 'react';
import {
  Dialog as KDialog,
  DialogCloseEvent as KDialogCloseEvent,
  DialogProps as KDialogProps,
  DialogHandle as KDialogHandle,
} from '@progress/kendo-react-dialogs';

export interface DialogProps extends KDialogProps {
  width?: 'small' | 'medium' | 'large' | 'auto' | number;
  actionsBarPosition?: 'top' | 'bottom' | 'both' | 'none';
  header?: JSX.Element;
  footer?: JSX.Element;
}
export interface DialogHandle extends KDialogHandle {}
export interface DialogCloseEvent extends KDialogCloseEvent {}

function Dialog(props: DialogProps, ref: React.Ref<DialogHandle>) {
  const {
    className,
    actionsBarPosition = 'none',
    width = 'auto',
    autoFocus = true,
    closeIcon = true,
    onClose,
    ...otherProps
  } = props;
  const widthClass = typeof width === 'number' ? undefined : `size-${width}`;
  const explicitWidth = typeof width === 'number' ? width : undefined;
  // prevent background page scrolling when dialog is open
  document.body.style.overflow = 'hidden';

  const _onClose = (e: KDialogCloseEvent) => {
    document.body.style.overflow = '';
    if (onClose) {
      onClose(e);
    }
  };

  return (
    <KDialog
      ref={ref}
      className={`${className} ${styles['dialog-wrap']} ${widthClass} actions-${actionsBarPosition} ${
        closeIcon ? 'has-close-icon' : ''
      }`}
      autoFocus={autoFocus}
      closeIcon={closeIcon}
      width={explicitWidth}
      onClose={_onClose}
      {...otherProps}
    >
      {props.header && <div className="dialog-header">{props.header}</div>}
      <div className="dialog-contents" style={{ height: props.footer ? undefined : '100%' }}>
        {props.children}
      </div>
      {props.footer && <div className="dialog-footer">{props.footer}</div>}
    </KDialog>
  );
}

export default React.forwardRef(Dialog);
