import { OrderStatus_Status } from '../cqgMessages/common/shared_1';
import { ServerMsg } from '../cqgMessages/WebAPI/webapi_2';
import { ErrorCode, ErrorRecord } from './Errors';

export interface ServerResponse {
  data?: ServerMsg;
  cached: boolean;
  err?: ErrorRecord;
}

export function returnServerResponse(data: ServerMsg): ServerResponse {
  return {
    data,
    cached: false,
  };
}

export function returnCachedResponse(): ServerResponse {
  return {
    cached: true,
  };
}

export function returnErrorResponse(errorCode?: ErrorCode): ServerResponse {
  const err: ErrorRecord = {
    code: errorCode || ErrorCode.UNKNOWN,
    timestamp: Date.now(),
  };
  return {
    cached: false,
    err,
  };
}

export function getCancelableStatuses() {
  return [
    OrderStatus_Status.IN_TRANSIT,
    OrderStatus_Status.WORKING,
    OrderStatus_Status.APPROVE_REQUIRED,
    OrderStatus_Status.IN_CANCEL,
    OrderStatus_Status.IN_MODIFY,
    OrderStatus_Status.ACTIVEAT,
    OrderStatus_Status.APPROVED_BY_EXCHANGE,
    OrderStatus_Status.MATCHED,
    OrderStatus_Status.PARTIALLY_MATCHED,
  ];
}

export function asyncDelay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
