import * as React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps, faSpinnerThird } from '../../faIcons';

interface LoadingProps {
  className?: string;
  style?: React.CSSProperties;
}

// Note: doesn't currently support refs
export default function Loading(props: LoadingProps) {
  const duoStyle = {
    display: 'inline-block',
    '--fa-primary-color': '#f0f',
    '--fa-secondary-color': '#f0f',
  } as React.CSSProperties;
  const iconProps: FontAwesomeIconProps = {
    icon: faSpinnerThird,
    spin: true,
    style: duoStyle,
  };
  return <FontAwesomeIcon {...iconProps} {...props} />;
}
