import React from 'react';
import Button, { ButtonProps } from '../Button/Button';
import { FontAwesomeIconProps, faBan, faCircleNotch } from '../../faIcons';

interface CancelTradeButtonProps extends ButtonProps {
  isCanceling?: boolean;
  className?: string;
}

export const CancelTradeButton = (props: CancelTradeButtonProps) => {
  let { isCanceling, onClick, className, size } = props;
  const cancelIcon: FontAwesomeIconProps = { icon: faBan, className: 'pr-2' };
  const loadingIcon: FontAwesomeIconProps = { icon: faCircleNotch, spin: true, className: 'ps-5 pe-5' };
  className = `${className || ''} mt-2`;
  size = size || 'small';

  if (isCanceling) {
    return <Button themeColor="error" disabled iconProps={loadingIcon} className={className} size={size} />;
  }

  return (
    <Button themeColor="error" onClick={onClick} className={className} size={size} iconProps={cancelIcon} iconPosition="before">
      <b>Cancel Order</b>
    </Button>
  );
};
