import { InstrumentType } from './InstrumentType';

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

export enum InstrumentSource {
  MANUAL = 'MANUAL',
  BARCHART = 'BARCHART',
  RJO = 'RJO',
  ADM = 'ADM',
  STONEX = 'STONEX',
  MAREX = 'MAREX',
  FARMERSRISK = 'FARMERSRISK',
}

export function mapInstrumentTypeToSymbol(i?: InstrumentType) {
  if (!i) {
    return '';
  }

  switch (i) {
    case InstrumentType.Futures:
      return 'F';
    case InstrumentType.Put:
    case InstrumentType.ShortDatedPut:
    case InstrumentType.Week1Put:
    case InstrumentType.Week2Put:
    case InstrumentType.Week3Put:
    case InstrumentType.Week4Put:
    case InstrumentType.Week5Put:
    case InstrumentType.SerialPut:
    case InstrumentType.NewCropWeek1Put:
    case InstrumentType.NewCropWeek2Put:
    case InstrumentType.NewCropWeek3Put:
    case InstrumentType.NewCropWeek4Put:
    case InstrumentType.NewCropWeek5Put:
      return 'P';
    case InstrumentType.Call:
    case InstrumentType.ShortDatedCall:
    case InstrumentType.Week1Call:
    case InstrumentType.Week2Call:
    case InstrumentType.Week3Call:
    case InstrumentType.Week4Call:
    case InstrumentType.Week5Call:
    case InstrumentType.SerialCall:
    case InstrumentType.NewCropWeek1Call:
    case InstrumentType.NewCropWeek2Call:
    case InstrumentType.NewCropWeek3Call:
    case InstrumentType.NewCropWeek4Call:
    case InstrumentType.NewCropWeek5Call:
      return 'C';
    default:
      throw new UnreachableCaseError(i);
  }
}

export function getInstrumentTypeName(instrumentType: InstrumentType) {
  switch (instrumentType) {
    case InstrumentType.Futures:
      return 'Futures';
    case InstrumentType.Call:
      return 'Call';
    case InstrumentType.Put:
      return 'Put';
    case InstrumentType.ShortDatedCall:
      return 'Short Dated Call';
    case InstrumentType.ShortDatedPut:
      return 'Short Dated Put';
    case InstrumentType.Week1Call:
      return 'Week 1 Call';
    case InstrumentType.Week1Put:
      return 'Week 1 Put';
    case InstrumentType.Week2Call:
      return 'Week 2 Call';
    case InstrumentType.Week2Put:
      return 'Week 2 Put';
    case InstrumentType.Week3Call:
      return 'Week 3 Call';
    case InstrumentType.Week3Put:
      return 'Week 3 Put';
    case InstrumentType.Week4Call:
      return 'Week 4 Call';
    case InstrumentType.Week4Put:
      return 'Week 4 Put';
    case InstrumentType.Week5Call:
      return 'Week 5 Call';
    case InstrumentType.Week5Put:
      return 'Week 5 Put';
    case InstrumentType.SerialCall:
      return 'Serial Call';
    case InstrumentType.SerialPut:
      return 'Serial Put';
    case InstrumentType.NewCropWeek1Call:
      return 'New Crop Week 1 Call';
    case InstrumentType.NewCropWeek1Put:
      return 'New Crop Week 1 Put';
    case InstrumentType.NewCropWeek2Call:
      return 'New Crop Week 2 Call';
    case InstrumentType.NewCropWeek2Put:
      return 'New Crop Week 2 Put';
    case InstrumentType.NewCropWeek3Call:
      return 'New Crop Week 3 Call';
    case InstrumentType.NewCropWeek3Put:
      return 'New Crop Week 3 Put';
    case InstrumentType.NewCropWeek4Call:
      return 'New Crop Week 4 Call';
    case InstrumentType.NewCropWeek4Put:
      return 'New Crop Week 4 Put';
    case InstrumentType.NewCropWeek5Call:
      return 'New Crop Week 5 Call';
    case InstrumentType.NewCropWeek5Put:
      return 'New Crop Week 5 Put';
    default:
      throw new UnreachableCaseError(instrumentType);
  }
}

export const MONTH_ABBREVIATION: Record<number, string> = {
  0: 'JAN',
  1: 'FEB',
  2: 'MAR',
  3: 'APR',
  4: 'MAY',
  5: 'JUN',
  6: 'JUL',
  7: 'AUG',
  8: 'SEP',
  9: 'OCT',
  10: 'NOV',
  11: 'DEC',
};
