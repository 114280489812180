import React from 'react';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { match, matchPath, useHistory } from 'react-router-dom';
import { Debug, Login, Logout, Signup, ThrowError } from './routes/UtilityRoutes';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ProtectedApp from './ProtectedApp';
import config from './config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { trpc, getTrpcClient } from './shared/trpc';
import { IAdvisorSignup } from './types/IAdvisorSignup';

const ProtectedAppWithAuth = withAuthenticationRequired(ProtectedApp);

export default function App() {
  const history = useHistory();
  const signupMatch: match<Partial<IAdvisorSignup>> | null = matchPath(
    history.location.pathname,
    '/signup/:advisorId?/:verificationCode?'
  );
  const loginMatch = matchPath(history.location.pathname, '/login');
  const logoutMatch = matchPath(history.location.pathname, '/logout');
  const debugMatch = matchPath(history.location.pathname, '/debug');
  const errorMatch = matchPath(history.location.pathname, '/error');

  const { getAccessTokenSilently } = useAuth0();

  if (signupMatch) {
    return <Signup advisorId={signupMatch.params.advisorId} verificationCode={signupMatch.params.verificationCode} />;
  }
  if (loginMatch) {
    return <Login />;
  }
  if (logoutMatch) {
    return <Logout />;
  }
  if (debugMatch) {
    return <Debug />;
  }
  if (errorMatch) {
    return <ThrowError />;
  }

  const isDev = config.env === 'development';
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: isDev ? false : true,
        staleTime: isDev ? undefined : 1000 * 60 * 5,
        cacheTime: isDev ? undefined : 1000 * 60 * 30,
        useErrorBoundary: (error) => {
          if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            return !!(status && status >= 500);
          }
          return true;
        },
      },
    },
  });

  const trpcClient = getTrpcClient({ getAccessTokenSilently });

  return (
    <ReduxProvider store={store}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <ProtectedAppWithAuth />

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </trpc.Provider>
    </ReduxProvider>
  );
}
