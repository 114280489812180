/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'trading_session_2';

/** Days of week enumeration. */
export enum DayOfWeek {
  DAY_OF_WEEK_SUNDAY = 0,
  DAY_OF_WEEK_MONDAY = 1,
  DAY_OF_WEEK_TUESDAY = 2,
  DAY_OF_WEEK_WEDNESDAY = 3,
  DAY_OF_WEEK_THURSDAY = 4,
  DAY_OF_WEEK_FRIDAY = 5,
  DAY_OF_WEEK_SATURDAY = 6,
  UNRECOGNIZED = -1,
}

export function dayOfWeekFromJSON(object: any): DayOfWeek {
  switch (object) {
    case 0:
    case 'DAY_OF_WEEK_SUNDAY':
      return DayOfWeek.DAY_OF_WEEK_SUNDAY;
    case 1:
    case 'DAY_OF_WEEK_MONDAY':
      return DayOfWeek.DAY_OF_WEEK_MONDAY;
    case 2:
    case 'DAY_OF_WEEK_TUESDAY':
      return DayOfWeek.DAY_OF_WEEK_TUESDAY;
    case 3:
    case 'DAY_OF_WEEK_WEDNESDAY':
      return DayOfWeek.DAY_OF_WEEK_WEDNESDAY;
    case 4:
    case 'DAY_OF_WEEK_THURSDAY':
      return DayOfWeek.DAY_OF_WEEK_THURSDAY;
    case 5:
    case 'DAY_OF_WEEK_FRIDAY':
      return DayOfWeek.DAY_OF_WEEK_FRIDAY;
    case 6:
    case 'DAY_OF_WEEK_SATURDAY':
      return DayOfWeek.DAY_OF_WEEK_SATURDAY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DayOfWeek.UNRECOGNIZED;
  }
}

export function dayOfWeekToJSON(object: DayOfWeek): string {
  switch (object) {
    case DayOfWeek.DAY_OF_WEEK_SUNDAY:
      return 'DAY_OF_WEEK_SUNDAY';
    case DayOfWeek.DAY_OF_WEEK_MONDAY:
      return 'DAY_OF_WEEK_MONDAY';
    case DayOfWeek.DAY_OF_WEEK_TUESDAY:
      return 'DAY_OF_WEEK_TUESDAY';
    case DayOfWeek.DAY_OF_WEEK_WEDNESDAY:
      return 'DAY_OF_WEEK_WEDNESDAY';
    case DayOfWeek.DAY_OF_WEEK_THURSDAY:
      return 'DAY_OF_WEEK_THURSDAY';
    case DayOfWeek.DAY_OF_WEEK_FRIDAY:
      return 'DAY_OF_WEEK_FRIDAY';
    case DayOfWeek.DAY_OF_WEEK_SATURDAY:
      return 'DAY_OF_WEEK_SATURDAY';
    case DayOfWeek.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for session information per instrument group. */
export interface SessionInformationRequest {
  /** ID of a session information from contract meta-data. */
  sessionInfoId: number;
  /**
   * UTC time from which session information is requested (e.g. if historical session times are necessary).
   * Current time is used by default.
   */
  fromUtcTime: number;
  /**
   * UTC time up to which session information is requested (e.g. if future session times are necessary).
   * Current time is used by default. This field must not be used with subscriptions.
   */
  toUtcTime: number;
}

/** Report with session information. */
export interface SessionInformationReport {
  /** Session information ID. */
  sessionInfoId: number;
  /** List of session segment records. */
  sessionSegments: SessionSegment[];
}

/** Session segment record. */
export interface SessionSegment {
  /** Session segment ID useful for updates. */
  sessionSegmentId: number;
  /** Session group is deleted (used for updates). */
  deleted: boolean;
  /**
   * UTC time from which this session information is effective (inclusive).
   * It is omitted if this session information was the same since Instrument group was introduced.
   */
  fromUtcTime: number;
  /**
   * UTC time up to which this session information is effective (exclusive).
   * It is omitted if this session information is not planned yet to be changed.
   */
  toUtcTime: number;
  /** Schedule for all sessions. */
  sessionSchedules: SessionSchedule[];
  /** Trading day schedule. */
  tradingDays: TradingDay[];
  /** Daily holidays. May have some sessions interday, but daily bar is not created. */
  dailyHolidays: SessionHoliday[];
}

/** Session schedule. */
export interface SessionSchedule {
  /** Session name. */
  name: string;
  /** List of session times per day of week. */
  sessionDays: SessionDay[];
  /** List of exchange specific dates when this session is closed. */
  sessionHolidays: SessionHoliday[];
  /** True if this is a primary session. */
  isPrimary: boolean;
}

/** Trading day schedule. */
export interface TradingDay {
  /** Days of week list with the same day schedule. */
  daysOfWeek: DayOfWeek[];
  /** Trading day start offset in milliseconds from 00:00 UTC. */
  startOffset: number;
}

/**
 * Session times per day of week.
 * All time offsets are in milliseconds from 00:00 UTC time of a specific date that corresponds to specific day of week.
 * Offset values are optional and can be positive and negative
 * (e.g. session can be completely 'pre-open' so only pre_open_offset and post_close_offset values are set).
 * NOTE: Session times may not match exact exchange schedule especially if exchange have dynamic times
 * (e.g. session starts after publishing a settlement, after underlying contract trade, etc.)
 * or if exchange sends market data outside of session boundaries (e.g. late trades)
 */
export interface SessionDay {
  /** Days of week list with the same day schedule. */
  daysOfWeek: DayOfWeek[];
  /** Session pre-open time offset. */
  preOpenOffset: number;
  /** Session open time offset. */
  openOffset: number;
  /** Session close time offset. */
  closeOffset: number;
  /** Session post-close time offset. */
  postCloseOffset: number;
  /**
   * Original day if the session was reallocated to the next trading day.
   * Holidays have to be applied to the original day.
   * SessionDay with original day of week has a single day in the days_of_week list.
   */
  originalDayOfWeek: DayOfWeek;
}

/** Session holiday record. */
export interface SessionHoliday {
  /** Date of a holiday. */
  holidayDate: number;
  /** Name of a holiday. */
  holidayName: string;
}

/** Request for session open/close times. */
export interface SessionTimeRangeRequest {
  /** ID of a session information from contract meta-data. */
  sessionInfoId: number;
  /**
   * Exactly two of the next three fields have to be set:
   * UTC time of start of the time range to get information for.
   * Sessions with post-close time > from_utc_time are returned.
   */
  fromUtcTime: number;
  /**
   * UTC time of end of the time range to get information for.
   * Sessions with pre-open time < to_utc_time are returned.
   */
  toUtcTime: number;
  /**
   * Number of session timeranges to return.
   * If used with from_utc_time then it is the number of timeranges to return starting from that time.
   * If used with to_utc_time then it is the number of timeranges to return preceding that time.
   */
  count: number;
}

/** This may come in multiple chunks if many items are reported. */
export interface SessionTimeRangeReport {
  /** Session open/close times. */
  sessionTimeRanges: SessionTimeRange[];
  /** Request was done for the range that was too long, so it was truncated. */
  truncated: boolean;
}

/** Session open/close times and trading day date. */
export interface SessionTimeRange {
  /** UTC time of session pre-open. */
  preOpenUtcTime: number;
  /** UTC time of session open. */
  openUtcTime: number;
  /** UTC time of session close. */
  closeUtcTime: number;
  /** UTC time of session post-close. */
  postCloseUtcTime: number;
  /** Trading date the session belongs to, local to exchange, time part is not used (set to 00:00). */
  tradeDate: number;
  /** Session name. */
  sessionName: string;
}

/** Request for trading day open/close times. */
export interface TradingDayTimeRangeRequest {
  /** ID of a session information from contract meta-data. */
  sessionInfoId: number;
  /** True if holidays should be included in the response. */
  includeHolidays: boolean;
  /**
   * Exactly two of the next three fields have to be set:
   * UTC time of start of the time range to get information for.
   * Trading days with end time > from_utc_time are returned.
   */
  fromUtcTime: number;
  /**
   * UTC time of end of the time range to get information for.
   * Trading days with start time < to_utc_time are returned.
   */
  toUtcTime: number;
  /**
   * Number of trading day timeranges to return.
   * If used with from_utc_time then it is the number of timeranges to return starting from that time.
   * If used with to_utc_time then it is the number of timeranges to return preceding that time.
   */
  count: number;
}

/** This may come in multiple chunks if many items are reported. */
export interface TradingDayTimeRangeReport {
  /** Trading day start/end times. */
  tradingDayTimeRanges: TradingDayTimeRange[];
  /** Request was done for the range that was too long, so it was truncated. */
  truncated: boolean;
}

/** Trading day pre-open/post-close, open/close times and date. */
export interface TradingDayTimeRange {
  /** Trading date, local to exchange, time part is not used (set to 00:00). */
  tradeDate: number;
  /**
   * The next fields are not present for holidays,
   * because there’s no trading day for these dates, hence no pre-open/post-close and open/close times.
   * UTC time of trading day pre-open (first session pre-open time).
   */
  tradingDayPreOpenUtcTime: number;
  /** UTC time of trading day open (first session open time). */
  tradingDayOpenUtcTime: number;
  /** UTC time of trading day close (last session close time). */
  tradingDayCloseUtcTime: number;
  /** UTC time of trading day post-close (last session post-Close time). */
  tradingDayPostCloseUtcTime: number;
  /** Primary session open UTC time of trading day. */
  openPrimaryUtcTime: number;
  /** Primary session close UTC time of trading day. */
  closePrimaryUtcTime: number;
}

function createBaseSessionInformationRequest(): SessionInformationRequest {
  return { sessionInfoId: 0, fromUtcTime: 0, toUtcTime: 0 };
}

export const SessionInformationRequest = {
  encode(message: SessionInformationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionInfoId !== 0) {
      writer.uint32(8).sint32(message.sessionInfoId);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(16).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(24).sint64(message.toUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionInformationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionInformationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionInfoId = reader.sint32();
          break;
        case 2:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionInformationRequest {
    return {
      sessionInfoId: isSet(object.sessionInfoId) ? Number(object.sessionInfoId) : 0,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
    };
  },

  toJSON(message: SessionInformationRequest): unknown {
    const obj: any = {};
    message.sessionInfoId !== undefined && (obj.sessionInfoId = Math.round(message.sessionInfoId));
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionInformationRequest>, I>>(base?: I): SessionInformationRequest {
    return SessionInformationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionInformationRequest>, I>>(object: I): SessionInformationRequest {
    const message = createBaseSessionInformationRequest();
    message.sessionInfoId = object.sessionInfoId ?? 0;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    return message;
  },
};

function createBaseSessionInformationReport(): SessionInformationReport {
  return { sessionInfoId: 0, sessionSegments: [] };
}

export const SessionInformationReport = {
  encode(message: SessionInformationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionInfoId !== 0) {
      writer.uint32(8).sint32(message.sessionInfoId);
    }
    for (const v of message.sessionSegments) {
      SessionSegment.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionInformationReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionInformationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionInfoId = reader.sint32();
          break;
        case 2:
          message.sessionSegments.push(SessionSegment.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionInformationReport {
    return {
      sessionInfoId: isSet(object.sessionInfoId) ? Number(object.sessionInfoId) : 0,
      sessionSegments: Array.isArray(object?.sessionSegments)
        ? object.sessionSegments.map((e: any) => SessionSegment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SessionInformationReport): unknown {
    const obj: any = {};
    message.sessionInfoId !== undefined && (obj.sessionInfoId = Math.round(message.sessionInfoId));
    if (message.sessionSegments) {
      obj.sessionSegments = message.sessionSegments.map((e) => (e ? SessionSegment.toJSON(e) : undefined));
    } else {
      obj.sessionSegments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionInformationReport>, I>>(base?: I): SessionInformationReport {
    return SessionInformationReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionInformationReport>, I>>(object: I): SessionInformationReport {
    const message = createBaseSessionInformationReport();
    message.sessionInfoId = object.sessionInfoId ?? 0;
    message.sessionSegments = object.sessionSegments?.map((e) => SessionSegment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSessionSegment(): SessionSegment {
  return {
    sessionSegmentId: 0,
    deleted: false,
    fromUtcTime: 0,
    toUtcTime: 0,
    sessionSchedules: [],
    tradingDays: [],
    dailyHolidays: [],
  };
}

export const SessionSegment = {
  encode(message: SessionSegment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionSegmentId !== 0) {
      writer.uint32(8).sint64(message.sessionSegmentId);
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(24).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(32).sint64(message.toUtcTime);
    }
    for (const v of message.sessionSchedules) {
      SessionSchedule.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.tradingDays) {
      TradingDay.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.dailyHolidays) {
      SessionHoliday.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionSegment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionSegment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionSegmentId = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        case 3:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.sessionSchedules.push(SessionSchedule.decode(reader, reader.uint32()));
          break;
        case 6:
          message.tradingDays.push(TradingDay.decode(reader, reader.uint32()));
          break;
        case 7:
          message.dailyHolidays.push(SessionHoliday.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionSegment {
    return {
      sessionSegmentId: isSet(object.sessionSegmentId) ? Number(object.sessionSegmentId) : 0,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
      sessionSchedules: Array.isArray(object?.sessionSchedules)
        ? object.sessionSchedules.map((e: any) => SessionSchedule.fromJSON(e))
        : [],
      tradingDays: Array.isArray(object?.tradingDays) ? object.tradingDays.map((e: any) => TradingDay.fromJSON(e)) : [],
      dailyHolidays: Array.isArray(object?.dailyHolidays) ? object.dailyHolidays.map((e: any) => SessionHoliday.fromJSON(e)) : [],
    };
  },

  toJSON(message: SessionSegment): unknown {
    const obj: any = {};
    message.sessionSegmentId !== undefined && (obj.sessionSegmentId = Math.round(message.sessionSegmentId));
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    if (message.sessionSchedules) {
      obj.sessionSchedules = message.sessionSchedules.map((e) => (e ? SessionSchedule.toJSON(e) : undefined));
    } else {
      obj.sessionSchedules = [];
    }
    if (message.tradingDays) {
      obj.tradingDays = message.tradingDays.map((e) => (e ? TradingDay.toJSON(e) : undefined));
    } else {
      obj.tradingDays = [];
    }
    if (message.dailyHolidays) {
      obj.dailyHolidays = message.dailyHolidays.map((e) => (e ? SessionHoliday.toJSON(e) : undefined));
    } else {
      obj.dailyHolidays = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionSegment>, I>>(base?: I): SessionSegment {
    return SessionSegment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionSegment>, I>>(object: I): SessionSegment {
    const message = createBaseSessionSegment();
    message.sessionSegmentId = object.sessionSegmentId ?? 0;
    message.deleted = object.deleted ?? false;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    message.sessionSchedules = object.sessionSchedules?.map((e) => SessionSchedule.fromPartial(e)) || [];
    message.tradingDays = object.tradingDays?.map((e) => TradingDay.fromPartial(e)) || [];
    message.dailyHolidays = object.dailyHolidays?.map((e) => SessionHoliday.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSessionSchedule(): SessionSchedule {
  return { name: '', sessionDays: [], sessionHolidays: [], isPrimary: false };
}

export const SessionSchedule = {
  encode(message: SessionSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    for (const v of message.sessionDays) {
      SessionDay.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.sessionHolidays) {
      SessionHoliday.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.isPrimary === true) {
      writer.uint32(32).bool(message.isPrimary);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionSchedule {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.sessionDays.push(SessionDay.decode(reader, reader.uint32()));
          break;
        case 3:
          message.sessionHolidays.push(SessionHoliday.decode(reader, reader.uint32()));
          break;
        case 4:
          message.isPrimary = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionSchedule {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      sessionDays: Array.isArray(object?.sessionDays) ? object.sessionDays.map((e: any) => SessionDay.fromJSON(e)) : [],
      sessionHolidays: Array.isArray(object?.sessionHolidays)
        ? object.sessionHolidays.map((e: any) => SessionHoliday.fromJSON(e))
        : [],
      isPrimary: isSet(object.isPrimary) ? Boolean(object.isPrimary) : false,
    };
  },

  toJSON(message: SessionSchedule): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    if (message.sessionDays) {
      obj.sessionDays = message.sessionDays.map((e) => (e ? SessionDay.toJSON(e) : undefined));
    } else {
      obj.sessionDays = [];
    }
    if (message.sessionHolidays) {
      obj.sessionHolidays = message.sessionHolidays.map((e) => (e ? SessionHoliday.toJSON(e) : undefined));
    } else {
      obj.sessionHolidays = [];
    }
    message.isPrimary !== undefined && (obj.isPrimary = message.isPrimary);
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionSchedule>, I>>(base?: I): SessionSchedule {
    return SessionSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionSchedule>, I>>(object: I): SessionSchedule {
    const message = createBaseSessionSchedule();
    message.name = object.name ?? '';
    message.sessionDays = object.sessionDays?.map((e) => SessionDay.fromPartial(e)) || [];
    message.sessionHolidays = object.sessionHolidays?.map((e) => SessionHoliday.fromPartial(e)) || [];
    message.isPrimary = object.isPrimary ?? false;
    return message;
  },
};

function createBaseTradingDay(): TradingDay {
  return { daysOfWeek: [], startOffset: 0 };
}

export const TradingDay = {
  encode(message: TradingDay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.daysOfWeek) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.startOffset !== 0) {
      writer.uint32(16).sint64(message.startOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingDay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingDay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.daysOfWeek.push(reader.int32() as any);
            }
          } else {
            message.daysOfWeek.push(reader.int32() as any);
          }
          break;
        case 2:
          message.startOffset = longToNumber(reader.sint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradingDay {
    return {
      daysOfWeek: Array.isArray(object?.daysOfWeek) ? object.daysOfWeek.map((e: any) => dayOfWeekFromJSON(e)) : [],
      startOffset: isSet(object.startOffset) ? Number(object.startOffset) : 0,
    };
  },

  toJSON(message: TradingDay): unknown {
    const obj: any = {};
    if (message.daysOfWeek) {
      obj.daysOfWeek = message.daysOfWeek.map((e) => dayOfWeekToJSON(e));
    } else {
      obj.daysOfWeek = [];
    }
    message.startOffset !== undefined && (obj.startOffset = Math.round(message.startOffset));
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingDay>, I>>(base?: I): TradingDay {
    return TradingDay.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradingDay>, I>>(object: I): TradingDay {
    const message = createBaseTradingDay();
    message.daysOfWeek = object.daysOfWeek?.map((e) => e) || [];
    message.startOffset = object.startOffset ?? 0;
    return message;
  },
};

function createBaseSessionDay(): SessionDay {
  return { daysOfWeek: [], preOpenOffset: 0, openOffset: 0, closeOffset: 0, postCloseOffset: 0, originalDayOfWeek: 0 };
}

export const SessionDay = {
  encode(message: SessionDay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.daysOfWeek) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.preOpenOffset !== 0) {
      writer.uint32(16).sint64(message.preOpenOffset);
    }
    if (message.openOffset !== 0) {
      writer.uint32(24).sint64(message.openOffset);
    }
    if (message.closeOffset !== 0) {
      writer.uint32(32).sint64(message.closeOffset);
    }
    if (message.postCloseOffset !== 0) {
      writer.uint32(40).sint64(message.postCloseOffset);
    }
    if (message.originalDayOfWeek !== 0) {
      writer.uint32(48).int32(message.originalDayOfWeek);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionDay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionDay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.daysOfWeek.push(reader.int32() as any);
            }
          } else {
            message.daysOfWeek.push(reader.int32() as any);
          }
          break;
        case 2:
          message.preOpenOffset = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.openOffset = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.closeOffset = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.postCloseOffset = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.originalDayOfWeek = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionDay {
    return {
      daysOfWeek: Array.isArray(object?.daysOfWeek) ? object.daysOfWeek.map((e: any) => dayOfWeekFromJSON(e)) : [],
      preOpenOffset: isSet(object.preOpenOffset) ? Number(object.preOpenOffset) : 0,
      openOffset: isSet(object.openOffset) ? Number(object.openOffset) : 0,
      closeOffset: isSet(object.closeOffset) ? Number(object.closeOffset) : 0,
      postCloseOffset: isSet(object.postCloseOffset) ? Number(object.postCloseOffset) : 0,
      originalDayOfWeek: isSet(object.originalDayOfWeek) ? dayOfWeekFromJSON(object.originalDayOfWeek) : 0,
    };
  },

  toJSON(message: SessionDay): unknown {
    const obj: any = {};
    if (message.daysOfWeek) {
      obj.daysOfWeek = message.daysOfWeek.map((e) => dayOfWeekToJSON(e));
    } else {
      obj.daysOfWeek = [];
    }
    message.preOpenOffset !== undefined && (obj.preOpenOffset = Math.round(message.preOpenOffset));
    message.openOffset !== undefined && (obj.openOffset = Math.round(message.openOffset));
    message.closeOffset !== undefined && (obj.closeOffset = Math.round(message.closeOffset));
    message.postCloseOffset !== undefined && (obj.postCloseOffset = Math.round(message.postCloseOffset));
    message.originalDayOfWeek !== undefined && (obj.originalDayOfWeek = dayOfWeekToJSON(message.originalDayOfWeek));
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionDay>, I>>(base?: I): SessionDay {
    return SessionDay.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionDay>, I>>(object: I): SessionDay {
    const message = createBaseSessionDay();
    message.daysOfWeek = object.daysOfWeek?.map((e) => e) || [];
    message.preOpenOffset = object.preOpenOffset ?? 0;
    message.openOffset = object.openOffset ?? 0;
    message.closeOffset = object.closeOffset ?? 0;
    message.postCloseOffset = object.postCloseOffset ?? 0;
    message.originalDayOfWeek = object.originalDayOfWeek ?? 0;
    return message;
  },
};

function createBaseSessionHoliday(): SessionHoliday {
  return { holidayDate: 0, holidayName: '' };
}

export const SessionHoliday = {
  encode(message: SessionHoliday, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.holidayDate !== 0) {
      writer.uint32(8).sint64(message.holidayDate);
    }
    if (message.holidayName !== '') {
      writer.uint32(18).string(message.holidayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionHoliday {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionHoliday();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.holidayDate = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.holidayName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionHoliday {
    return {
      holidayDate: isSet(object.holidayDate) ? Number(object.holidayDate) : 0,
      holidayName: isSet(object.holidayName) ? String(object.holidayName) : '',
    };
  },

  toJSON(message: SessionHoliday): unknown {
    const obj: any = {};
    message.holidayDate !== undefined && (obj.holidayDate = Math.round(message.holidayDate));
    message.holidayName !== undefined && (obj.holidayName = message.holidayName);
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionHoliday>, I>>(base?: I): SessionHoliday {
    return SessionHoliday.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionHoliday>, I>>(object: I): SessionHoliday {
    const message = createBaseSessionHoliday();
    message.holidayDate = object.holidayDate ?? 0;
    message.holidayName = object.holidayName ?? '';
    return message;
  },
};

function createBaseSessionTimeRangeRequest(): SessionTimeRangeRequest {
  return { sessionInfoId: 0, fromUtcTime: 0, toUtcTime: 0, count: 0 };
}

export const SessionTimeRangeRequest = {
  encode(message: SessionTimeRangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionInfoId !== 0) {
      writer.uint32(8).sint32(message.sessionInfoId);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(16).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(24).sint64(message.toUtcTime);
    }
    if (message.count !== 0) {
      writer.uint32(32).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionTimeRangeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionTimeRangeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionInfoId = reader.sint32();
          break;
        case 2:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.count = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionTimeRangeRequest {
    return {
      sessionInfoId: isSet(object.sessionInfoId) ? Number(object.sessionInfoId) : 0,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: SessionTimeRangeRequest): unknown {
    const obj: any = {};
    message.sessionInfoId !== undefined && (obj.sessionInfoId = Math.round(message.sessionInfoId));
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionTimeRangeRequest>, I>>(base?: I): SessionTimeRangeRequest {
    return SessionTimeRangeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionTimeRangeRequest>, I>>(object: I): SessionTimeRangeRequest {
    const message = createBaseSessionTimeRangeRequest();
    message.sessionInfoId = object.sessionInfoId ?? 0;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseSessionTimeRangeReport(): SessionTimeRangeReport {
  return { sessionTimeRanges: [], truncated: false };
}

export const SessionTimeRangeReport = {
  encode(message: SessionTimeRangeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sessionTimeRanges) {
      SessionTimeRange.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.truncated === true) {
      writer.uint32(16).bool(message.truncated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionTimeRangeReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionTimeRangeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionTimeRanges.push(SessionTimeRange.decode(reader, reader.uint32()));
          break;
        case 2:
          message.truncated = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionTimeRangeReport {
    return {
      sessionTimeRanges: Array.isArray(object?.sessionTimeRanges)
        ? object.sessionTimeRanges.map((e: any) => SessionTimeRange.fromJSON(e))
        : [],
      truncated: isSet(object.truncated) ? Boolean(object.truncated) : false,
    };
  },

  toJSON(message: SessionTimeRangeReport): unknown {
    const obj: any = {};
    if (message.sessionTimeRanges) {
      obj.sessionTimeRanges = message.sessionTimeRanges.map((e) => (e ? SessionTimeRange.toJSON(e) : undefined));
    } else {
      obj.sessionTimeRanges = [];
    }
    message.truncated !== undefined && (obj.truncated = message.truncated);
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionTimeRangeReport>, I>>(base?: I): SessionTimeRangeReport {
    return SessionTimeRangeReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionTimeRangeReport>, I>>(object: I): SessionTimeRangeReport {
    const message = createBaseSessionTimeRangeReport();
    message.sessionTimeRanges = object.sessionTimeRanges?.map((e) => SessionTimeRange.fromPartial(e)) || [];
    message.truncated = object.truncated ?? false;
    return message;
  },
};

function createBaseSessionTimeRange(): SessionTimeRange {
  return { preOpenUtcTime: 0, openUtcTime: 0, closeUtcTime: 0, postCloseUtcTime: 0, tradeDate: 0, sessionName: '' };
}

export const SessionTimeRange = {
  encode(message: SessionTimeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preOpenUtcTime !== 0) {
      writer.uint32(8).sint64(message.preOpenUtcTime);
    }
    if (message.openUtcTime !== 0) {
      writer.uint32(16).sint64(message.openUtcTime);
    }
    if (message.closeUtcTime !== 0) {
      writer.uint32(24).sint64(message.closeUtcTime);
    }
    if (message.postCloseUtcTime !== 0) {
      writer.uint32(32).sint64(message.postCloseUtcTime);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(40).sint64(message.tradeDate);
    }
    if (message.sessionName !== '') {
      writer.uint32(50).string(message.sessionName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionTimeRange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.preOpenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.openUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.closeUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.postCloseUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.sessionName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SessionTimeRange {
    return {
      preOpenUtcTime: isSet(object.preOpenUtcTime) ? Number(object.preOpenUtcTime) : 0,
      openUtcTime: isSet(object.openUtcTime) ? Number(object.openUtcTime) : 0,
      closeUtcTime: isSet(object.closeUtcTime) ? Number(object.closeUtcTime) : 0,
      postCloseUtcTime: isSet(object.postCloseUtcTime) ? Number(object.postCloseUtcTime) : 0,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      sessionName: isSet(object.sessionName) ? String(object.sessionName) : '',
    };
  },

  toJSON(message: SessionTimeRange): unknown {
    const obj: any = {};
    message.preOpenUtcTime !== undefined && (obj.preOpenUtcTime = Math.round(message.preOpenUtcTime));
    message.openUtcTime !== undefined && (obj.openUtcTime = Math.round(message.openUtcTime));
    message.closeUtcTime !== undefined && (obj.closeUtcTime = Math.round(message.closeUtcTime));
    message.postCloseUtcTime !== undefined && (obj.postCloseUtcTime = Math.round(message.postCloseUtcTime));
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.sessionName !== undefined && (obj.sessionName = message.sessionName);
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionTimeRange>, I>>(base?: I): SessionTimeRange {
    return SessionTimeRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SessionTimeRange>, I>>(object: I): SessionTimeRange {
    const message = createBaseSessionTimeRange();
    message.preOpenUtcTime = object.preOpenUtcTime ?? 0;
    message.openUtcTime = object.openUtcTime ?? 0;
    message.closeUtcTime = object.closeUtcTime ?? 0;
    message.postCloseUtcTime = object.postCloseUtcTime ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.sessionName = object.sessionName ?? '';
    return message;
  },
};

function createBaseTradingDayTimeRangeRequest(): TradingDayTimeRangeRequest {
  return { sessionInfoId: 0, includeHolidays: false, fromUtcTime: 0, toUtcTime: 0, count: 0 };
}

export const TradingDayTimeRangeRequest = {
  encode(message: TradingDayTimeRangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionInfoId !== 0) {
      writer.uint32(8).sint32(message.sessionInfoId);
    }
    if (message.includeHolidays === true) {
      writer.uint32(16).bool(message.includeHolidays);
    }
    if (message.fromUtcTime !== 0) {
      writer.uint32(24).sint64(message.fromUtcTime);
    }
    if (message.toUtcTime !== 0) {
      writer.uint32(32).sint64(message.toUtcTime);
    }
    if (message.count !== 0) {
      writer.uint32(40).uint32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingDayTimeRangeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingDayTimeRangeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sessionInfoId = reader.sint32();
          break;
        case 2:
          message.includeHolidays = reader.bool();
          break;
        case 3:
          message.fromUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.toUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.count = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradingDayTimeRangeRequest {
    return {
      sessionInfoId: isSet(object.sessionInfoId) ? Number(object.sessionInfoId) : 0,
      includeHolidays: isSet(object.includeHolidays) ? Boolean(object.includeHolidays) : false,
      fromUtcTime: isSet(object.fromUtcTime) ? Number(object.fromUtcTime) : 0,
      toUtcTime: isSet(object.toUtcTime) ? Number(object.toUtcTime) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: TradingDayTimeRangeRequest): unknown {
    const obj: any = {};
    message.sessionInfoId !== undefined && (obj.sessionInfoId = Math.round(message.sessionInfoId));
    message.includeHolidays !== undefined && (obj.includeHolidays = message.includeHolidays);
    message.fromUtcTime !== undefined && (obj.fromUtcTime = Math.round(message.fromUtcTime));
    message.toUtcTime !== undefined && (obj.toUtcTime = Math.round(message.toUtcTime));
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingDayTimeRangeRequest>, I>>(base?: I): TradingDayTimeRangeRequest {
    return TradingDayTimeRangeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradingDayTimeRangeRequest>, I>>(object: I): TradingDayTimeRangeRequest {
    const message = createBaseTradingDayTimeRangeRequest();
    message.sessionInfoId = object.sessionInfoId ?? 0;
    message.includeHolidays = object.includeHolidays ?? false;
    message.fromUtcTime = object.fromUtcTime ?? 0;
    message.toUtcTime = object.toUtcTime ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseTradingDayTimeRangeReport(): TradingDayTimeRangeReport {
  return { tradingDayTimeRanges: [], truncated: false };
}

export const TradingDayTimeRangeReport = {
  encode(message: TradingDayTimeRangeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tradingDayTimeRanges) {
      TradingDayTimeRange.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.truncated === true) {
      writer.uint32(16).bool(message.truncated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingDayTimeRangeReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingDayTimeRangeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tradingDayTimeRanges.push(TradingDayTimeRange.decode(reader, reader.uint32()));
          break;
        case 2:
          message.truncated = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradingDayTimeRangeReport {
    return {
      tradingDayTimeRanges: Array.isArray(object?.tradingDayTimeRanges)
        ? object.tradingDayTimeRanges.map((e: any) => TradingDayTimeRange.fromJSON(e))
        : [],
      truncated: isSet(object.truncated) ? Boolean(object.truncated) : false,
    };
  },

  toJSON(message: TradingDayTimeRangeReport): unknown {
    const obj: any = {};
    if (message.tradingDayTimeRanges) {
      obj.tradingDayTimeRanges = message.tradingDayTimeRanges.map((e) => (e ? TradingDayTimeRange.toJSON(e) : undefined));
    } else {
      obj.tradingDayTimeRanges = [];
    }
    message.truncated !== undefined && (obj.truncated = message.truncated);
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingDayTimeRangeReport>, I>>(base?: I): TradingDayTimeRangeReport {
    return TradingDayTimeRangeReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradingDayTimeRangeReport>, I>>(object: I): TradingDayTimeRangeReport {
    const message = createBaseTradingDayTimeRangeReport();
    message.tradingDayTimeRanges = object.tradingDayTimeRanges?.map((e) => TradingDayTimeRange.fromPartial(e)) || [];
    message.truncated = object.truncated ?? false;
    return message;
  },
};

function createBaseTradingDayTimeRange(): TradingDayTimeRange {
  return {
    tradeDate: 0,
    tradingDayPreOpenUtcTime: 0,
    tradingDayOpenUtcTime: 0,
    tradingDayCloseUtcTime: 0,
    tradingDayPostCloseUtcTime: 0,
    openPrimaryUtcTime: 0,
    closePrimaryUtcTime: 0,
  };
}

export const TradingDayTimeRange = {
  encode(message: TradingDayTimeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tradeDate !== 0) {
      writer.uint32(8).sint64(message.tradeDate);
    }
    if (message.tradingDayPreOpenUtcTime !== 0) {
      writer.uint32(16).sint64(message.tradingDayPreOpenUtcTime);
    }
    if (message.tradingDayOpenUtcTime !== 0) {
      writer.uint32(48).sint64(message.tradingDayOpenUtcTime);
    }
    if (message.tradingDayCloseUtcTime !== 0) {
      writer.uint32(56).sint64(message.tradingDayCloseUtcTime);
    }
    if (message.tradingDayPostCloseUtcTime !== 0) {
      writer.uint32(24).sint64(message.tradingDayPostCloseUtcTime);
    }
    if (message.openPrimaryUtcTime !== 0) {
      writer.uint32(32).sint64(message.openPrimaryUtcTime);
    }
    if (message.closePrimaryUtcTime !== 0) {
      writer.uint32(40).sint64(message.closePrimaryUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingDayTimeRange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingDayTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.tradingDayPreOpenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.tradingDayOpenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.tradingDayCloseUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.tradingDayPostCloseUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.openPrimaryUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.closePrimaryUtcTime = longToNumber(reader.sint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradingDayTimeRange {
    return {
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      tradingDayPreOpenUtcTime: isSet(object.tradingDayPreOpenUtcTime) ? Number(object.tradingDayPreOpenUtcTime) : 0,
      tradingDayOpenUtcTime: isSet(object.tradingDayOpenUtcTime) ? Number(object.tradingDayOpenUtcTime) : 0,
      tradingDayCloseUtcTime: isSet(object.tradingDayCloseUtcTime) ? Number(object.tradingDayCloseUtcTime) : 0,
      tradingDayPostCloseUtcTime: isSet(object.tradingDayPostCloseUtcTime) ? Number(object.tradingDayPostCloseUtcTime) : 0,
      openPrimaryUtcTime: isSet(object.openPrimaryUtcTime) ? Number(object.openPrimaryUtcTime) : 0,
      closePrimaryUtcTime: isSet(object.closePrimaryUtcTime) ? Number(object.closePrimaryUtcTime) : 0,
    };
  },

  toJSON(message: TradingDayTimeRange): unknown {
    const obj: any = {};
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.tradingDayPreOpenUtcTime !== undefined &&
      (obj.tradingDayPreOpenUtcTime = Math.round(message.tradingDayPreOpenUtcTime));
    message.tradingDayOpenUtcTime !== undefined && (obj.tradingDayOpenUtcTime = Math.round(message.tradingDayOpenUtcTime));
    message.tradingDayCloseUtcTime !== undefined && (obj.tradingDayCloseUtcTime = Math.round(message.tradingDayCloseUtcTime));
    message.tradingDayPostCloseUtcTime !== undefined &&
      (obj.tradingDayPostCloseUtcTime = Math.round(message.tradingDayPostCloseUtcTime));
    message.openPrimaryUtcTime !== undefined && (obj.openPrimaryUtcTime = Math.round(message.openPrimaryUtcTime));
    message.closePrimaryUtcTime !== undefined && (obj.closePrimaryUtcTime = Math.round(message.closePrimaryUtcTime));
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingDayTimeRange>, I>>(base?: I): TradingDayTimeRange {
    return TradingDayTimeRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradingDayTimeRange>, I>>(object: I): TradingDayTimeRange {
    const message = createBaseTradingDayTimeRange();
    message.tradeDate = object.tradeDate ?? 0;
    message.tradingDayPreOpenUtcTime = object.tradingDayPreOpenUtcTime ?? 0;
    message.tradingDayOpenUtcTime = object.tradingDayOpenUtcTime ?? 0;
    message.tradingDayCloseUtcTime = object.tradingDayCloseUtcTime ?? 0;
    message.tradingDayPostCloseUtcTime = object.tradingDayPostCloseUtcTime ?? 0;
    message.openPrimaryUtcTime = object.openPrimaryUtcTime ?? 0;
    message.closePrimaryUtcTime = object.closePrimaryUtcTime ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
