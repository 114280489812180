import { combineReducers } from 'redux';
import user from './User/reducers';
import operationMembers from './OperationMember/reducers';
import errors from './Error/reducers';
import cropContracts from './CropContracts/cropContractsSlice';
import strategies from './Strategies/strategiesSlice';
import quotes from './Quotes/quotesSlice';
import notifications from './Notifications/notificationsSlice';
import symbolLookUps from './SymbolLookUps/symbolLookUpsSlice';
import specialOptions from './SpecialOptions/specialOptionsSlice';
import futuresOptions from './FuturesOptions/futuresOptionsSlice';
import historyQuotes from './HistoryQuotes/historyQuotesSlice';
import tradingAccountConfigs from './TradingAccountConfigs/tradingAccountConfigsSlice';
import budgets from './Budgets/BudgetsSlice';
import myPosition from './PagesConfigs/MyPositions/myPositionSlice';
import cashSales from './PagesConfigs/CashSales/cashSalesSlice';
import costsPage from './PagesConfigs/Costs/costsSlice';
import riskScenarios from './RiskScenarios/riskScenariosSlice';
import elevatorDetailsPage from './Elevators/ElevatorsSlice';
import productionCycles from './ProductionCycles/reducers';
import { configureStore } from '@reduxjs/toolkit';
import config from '../config';

export const rootReducer = combineReducers({
  budgets,
  cashSales,
  costsPage,
  cropContracts,
  elevatorDetailsPage,
  errors,
  futuresOptions,
  historyQuotes,
  myPosition,
  notifications,
  operationMembers,
  productionCycles,
  quotes,
  riskScenarios,
  specialOptions,
  strategies,
  symbolLookUps,
  tradingAccountConfigs,
  user,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: config.env === 'development',
});
