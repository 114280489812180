import { createSelector } from '@reduxjs/toolkit';
import { selectBudgets } from '../store/Budgets/BudgetsSlice';
import { RootState } from '../types/Store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCropBudgets, ICropBudgets } from '../models/cropBudget';
import { IBudget } from '../types/IBudget';
import { useCurrentOperationCrops } from '../queries/operationCrops';

const makeSelectBudget = () =>
  createSelector(
    selectBudgets,
    (_: RootState, productionCycle: string) => productionCycle,
    (budgets, productionCycle) =>
      budgets.filter((budget) => budget.year === productionCycle && budget.operationId !== null && budget.isActive)[0]
  );

export default function useCropBudgets(productionCycle: string, editedBudget?: IBudget): ICropBudgets {
  const selectBudget = useMemo(makeSelectBudget, [productionCycle]);
  const budget = useSelector((state: RootState) => selectBudget(state, productionCycle));
  const operationCrops = useCurrentOperationCrops().data || [];

  return getCropBudgets({ ...budget, ...editedBudget }, operationCrops);
}
