import type {
  IGetFuturesOptionsResult,
  IGetFuturesOptionsParams,
  IGetSpecialOptionsParams,
  IGetSpecialOptionsResult,
} from '@harvestiq/external-clients/barchart';
import { useFuturesExpirations, useFuturesOptions, useSpecialOptions } from '../../queries/marketData';
import { UseQueryOptions } from '@tanstack/react-query';
import { CommodityInformation, InstrumentType, isPut } from '@harvestiq/constants';
import { isSpecialOption } from '../../utils/helpers';
import { buildFuturesContractOptions, buildOptionsContractOptions } from './helpers';

export function useRegularOptionsQuery(params: IGetFuturesOptionsParams, opts?: UseQueryOptions<IGetFuturesOptionsResult[]>) {
  const query = useFuturesOptions(
    {
      fields: 'premium,bid,ask',
      realtime: true,
      ...params,
    },
    opts
  );
  return query;
}

export function useSpecialOptionsQuery(params: IGetSpecialOptionsParams, opts?: UseQueryOptions<IGetSpecialOptionsResult[]>) {
  const specialOptionsQry = useSpecialOptions(
    {
      fields: 'premium,bid,ask,last',
      realtime: true,
      ...params,
    },
    opts
  );

  return specialOptionsQry;
}

interface UseContractsParams {
  commodity: CommodityInformation | undefined;
  instrumentType: InstrumentType | undefined;
}

export function useContracts(params: UseContractsParams) {
  const { commodity, instrumentType } = params;

  const root = commodity?.tradingCode; // ZC, ZS, etc.

  const optionPutOrCall =
    instrumentType === InstrumentType.Futures || !instrumentType ? undefined : isPut(instrumentType) ? 'Put' : 'Call';
  const isSpecial = isSpecialOption(instrumentType);

  const futuresExpirationsQry = useFuturesExpirations(
    {
      roots: root,
      fields: 'expirationDate',
    },
    {
      enabled: !!root && instrumentType === InstrumentType.Futures && !!commodity,
    }
  );

  const regularOptionsQry = useRegularOptionsQuery(
    {
      root: root,
      exchange: commodity?.barchartExchangeCode ?? 'CME',
      type: optionPutOrCall,
    },
    {
      enabled: !!root && instrumentType !== InstrumentType.Futures && !!commodity && !isSpecial,
    }
  );
  const regularOptions = regularOptionsQry.data || [];

  const specialOptionsQry = useSpecialOptionsQuery(
    {
      root: root ?? 'ZC',
      exchange: commodity?.barchartExchangeCode ?? 'CME',
      type: optionPutOrCall,
    },
    {
      enabled: !!root && instrumentType !== InstrumentType.Futures && !!commodity && isSpecial,
    }
  );
  const allSpecialOptions = specialOptionsQry.data || [];

  if (!commodity || !instrumentType) return [];

  if (instrumentType === InstrumentType.Futures) return buildFuturesContractOptions(futuresExpirationsQry.data || []);

  return buildOptionsContractOptions(instrumentType, regularOptions, allSpecialOptions);
}
