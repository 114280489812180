import { UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { CommodityId, CommodityInformation } from '@harvestiq/constants';
import { GetCropPricesOutput, GetLivestockPricesOutput } from '../../../queries/marketData';
import FuturesPrices, { transformCommodityInformationIntoFuturesPrices } from './FuturesPrices';

const newPriceLabel = dayjs().add(1, 'year').get('year').toString();

interface LivestockFuturesPricesProps {
  cropPricesQuery: UseQueryResult<GetCropPricesOutput>;
  commodityInformation: CommodityInformation[];
  livestockPricesQuery: UseQueryResult<GetLivestockPricesOutput>;
}

function LivestockFuturesPrices({ cropPricesQuery, commodityInformation, livestockPricesQuery }: LivestockFuturesPricesProps) {
  if (!livestockPricesQuery.data) {
    return <></>;
  }
  const cropPrices = cropPricesQuery.data;
  const livestockPrices = livestockPricesQuery.data;

  const quotesLastUpdate = Object.values(livestockPrices)[0]?.newMarketPrices?.serverTimestampStr || '';

  const filteredCommodityInformation = commodityInformation.filter(
    ({ id }) => id === CommodityId.CORN // livestock uses only corn from the crops
  );

  const futuresPrices = transformCommodityInformationIntoFuturesPrices(filteredCommodityInformation, cropPrices, livestockPrices);

  return (
    <FuturesPrices
      prices={futuresPrices}
      oldPriceLabel="Nearby"
      newPriceLabel={newPriceLabel}
      quotesLastUpdate={quotesLastUpdate}
    />
  );
}

export default LivestockFuturesPrices;
