import React from 'react';
import { FontAwesomeIcon, faCog, IconDefinition } from '../../faIcons';
import { NavLink } from 'react-router-dom';

export default function SettingsButton() {
  return (
    <NavLink to={'/settings'} style={{ height: '40px' }}>
      <button className="icon-button-root h-100" style={{ marginLeft: '8px' }} title="Settings">
        <FontAwesomeIcon icon={faCog as IconDefinition} />
      </button>
    </NavLink>
  );
}
