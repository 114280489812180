import { FontAwesomeIcon, IconDefinition, faArrowLeft } from '../../../faIcons';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentOperation } from '../../../../customHooks/useCurrentOperation';
import usePermission from '../../../../customHooks/usePermission';
import { useAllOperationCrops } from '../../../../queries/operationCrops';
import { selectBudgetType } from '../../../../store/PagesConfigs/Costs/costsSlice';
import { IBudget } from '../../../../types/IBudget';
import { RootState } from '../../../../types/Store';
import DeleteButton from '../../../common/DeleteButton/DeleteButton';
import InputWithLabel from '../../../common/Inputs/InputWithLabel/InputWithLabel';
import { getInitialBudget } from '../Costs';
import CustomCropBudgetCard from './CustomCropBudgetCard';

interface ICustomModeParams {
  editableBudget: IBudget;
  setEditableBudget: Dispatch<SetStateAction<IBudget>>;
}

function CustomMode(params: ICustomModeParams) {
  const { editableBudget, setEditableBudget } = params;
  const isAtLeastEditor = usePermission();
  const operationCrops = useAllOperationCrops().data || [];
  const productionCycle = useSelector((state: RootState) => state.productionCycles.selectedYear);
  const budgetType = useSelector(selectBudgetType);
  const currentOperation = useCurrentOperation();

  const currentOperationCrops = operationCrops.filter(
    (operationCrop) => operationCrop.productionCycle === productionCycle && !operationCrop.hidden
  );

  useEffect(() => {
    if (!editableBudget) {
      setEditableBudget(getInitialBudget(currentOperationCrops, currentOperation, productionCycle, budgetType));
    }
  }, [editableBudget, setEditableBudget, currentOperationCrops, currentOperation, productionCycle, budgetType]);

  const deleteAllBudgetItems = () => {
    setEditableBudget({ ...editableBudget, cropBudgets: {}, isNew: true });
  };

  return (
    <div className="custom-mode-wrapper" style={{ marginBottom: '64' }}>
      {isAtLeastEditor && (
        <div className="flex-row">
          <button
            className="fr-button backGround-none custom-mode-back-button"
            onClick={() => setEditableBudget({ ...editableBudget, isNew: true })}
          >
            <FontAwesomeIcon icon={faArrowLeft as IconDefinition} color={'#AB141D'} />
            <label className="create-contract-label">Back</label>
          </button>
        </div>
      )}
      <div className="custom-mode-header">
        <div className="costs-input-wrapper">
          <InputWithLabel
            label="Budget name"
            value={editableBudget?.name || ''}
            required={true}
            disabled={!isAtLeastEditor}
            onChange={(e) => setEditableBudget({ ...editableBudget, name: e.target.value })}
          />
        </div>
        {isAtLeastEditor && (
          <div className="delete-all-budget-items">
            <DeleteButton disabled={!isAtLeastEditor} onClick={() => deleteAllBudgetItems()} text="Delete All" />
          </div>
        )}
      </div>
      {editableBudget && (
        //TODO: Fix this hack
        <CustomCropBudgetCard
          key={'overhead'}
          operationCrop={{ ...currentOperationCrops[0], id: 0, name: 'Overhead' }}
          editableBudget={editableBudget}
          setEditableBudget={setEditableBudget}
        />
      )}
      {currentOperationCrops.map((operationCrop) => (
        <CustomCropBudgetCard
          key={operationCrop.id}
          operationCrop={operationCrop}
          editableBudget={editableBudget}
          setEditableBudget={setEditableBudget}
        />
      ))}
    </div>
  );
}

export default CustomMode;
