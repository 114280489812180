import { isNil } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import { CropIcon } from '../../../../components/common-next/CropIcon/CropIcon';
import { RedGreen } from '../../../../components/RedGreen/RedGreen';
import useCurrentCurrency from '../../../../customHooks/useCurrentCurrency';
import useMobileView, { BREAKPOINTS } from '../../../../customHooks/useMobileView';
import { formatNumber } from '../../../../utils/formatNumber';
import { CommodityId } from '@harvestiq/constants';

interface TitleProps {
  operationCropId: number;
  cropType?: CommodityId;
  totalAcres?: number;
  relevantYield?: string;
  name?: string;
  uom?: string;
  breakevenPerUOM?: number;
  profitPerAcre?: number;
  profitPerUOM?: number;
  returnOnInvestmentPercentage?: number;
}

export function Title(props: TitleProps) {
  const currency = useCurrentCurrency();
  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const { uom = UnitOfMeasure.Bushel } = props;
  return (
    <div className={`card-title d-flex ${isMobile ? 'flex-column' : ''}`}>
      <div className="flex-grow-1">
        <Link to={`settings/crops/edit/${props.operationCropId}`} className="summary-card-title name">
          <CropIcon cropType={props.cropType} />
          &nbsp;&nbsp;{props.name}
        </Link>
        {!isNil(props.totalAcres) && !isNil(props.relevantYield) && (
          <p className="card-subtitle">{`${props.totalAcres} ac @ ${formatNumber(props.relevantYield, 0)} ${uom}`}</p>
        )}
      </div>
      <div className={`d-flex profit-glance ${isMobile ? 'mt-3 row g-2 mx-auto justify-content-center' : ''}`}>
        <div className={`border text-center p-2 me-2 ${isMobile ? 'col-5' : ''}`}>
          {!isNil(props.breakevenPerUOM) && (
            <>
              <div>
                <span>{`${formatNumber(props.breakevenPerUOM, 2, currency)}/${uom}`}</span>
              </div>
              <div className="profit-label">Breakeven</div>
            </>
          )}
        </div>
        <div className={`border text-center p-2 ${isMobile ? 'col-5' : 'me-2'}`}>
          {!isNil(props.returnOnInvestmentPercentage) && (
            <>
              <div>
                <RedGreen
                  content={`${formatNumber(props.returnOnInvestmentPercentage, 2)}%`}
                  number={props.returnOnInvestmentPercentage}
                />
              </div>
              <div className="profit-label">ROI %</div>
            </>
          )}
        </div>
        <div className={`border text-center p-2 me-2 ${isMobile ? 'col-5' : ''}`}>
          {!isNil(props.profitPerAcre) && (
            <>
              <div>
                <RedGreen content={`${formatNumber(props.profitPerAcre, 0, currency)}/ac`} number={props.profitPerAcre} />
              </div>
              <div className="profit-label">Profit/ac</div>
            </>
          )}
        </div>
        <div className={`border text-center p-2 ${isMobile ? 'col-5' : 'me-2'}`}>
          {!isNil(props.profitPerUOM) && (
            <>
              <div>
                <RedGreen content={`${formatNumber(props.profitPerUOM, 2, currency)}/${uom}`} number={props.profitPerUOM} />
              </div>
              <div className="profit-label">Profit/{uom}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
