import { getStoredCurrentOperationId, setStoredCurrentOperationId } from '../shared/storageService';
import { Operation } from '../types/Operation';
import useAppContext from './useAppContext';

export function useCurrentOperationId(): number {
  // subscribe to redux changes
  const currentOperation = useCurrentOperation();
  const currentOperationId = currentOperation.id;

  const storedOperationId = getStoredCurrentOperationId();

  if (!storedOperationId) {
    setStoredCurrentOperationId(currentOperationId);
    return currentOperationId;
  }

  // consider sessionStorage the source of truth
  return storedOperationId;
}

export function useCurrentOperation(): Operation {
  const { currentOperation } = useAppContext();

  if (!currentOperation) {
    throw new Error('Current operation is not set');
  }

  return currentOperation;
}
