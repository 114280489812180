import { Redirect, Route } from 'react-router-dom';

import DashboardLivestockPage from '../pages/Dashboard/DashboardLivestockPage';
import DashboardCropsPage from '../pages/Dashboard/DashboardCropsPage';
import TabNav from '../components/TabNav/TabNav';
import useIsFeatureAccessible from '../customHooks/useIsFeatureAccessible';

import { Pages } from '@farmersrisk/shared/constants/Pages';

const TabNavMenuItems = [
  { name: Pages.dashboardCrops.title, path: Pages.dashboardCrops.path },
  { name: Pages.dashboardLivestock.title, path: Pages.dashboardLivestock.path },
];

export const DashboardRouter = () => {
  const isFeatureAccessible = useIsFeatureAccessible('isLivestockProfitabilityEnabled');

  return (
    <div className="flex-column">
      {isFeatureAccessible && (
        <>
          <div className="p-3 pb-0">
            <TabNav menuItems={TabNavMenuItems} />
          </div>
          <Route path={Pages.dashboardLivestock.path}>
            <DashboardLivestockPage />
          </Route>
        </>
      )}

      <Route path={Pages.dashboardCrops.path}>
        <DashboardCropsPage />
      </Route>

      <Route exact path={Pages.dashboard.path}>
        <Redirect to={Pages.dashboardCrops.path} />
      </Route>
    </div>
  );
};

export default DashboardRouter;
