import { LoadingStatuses } from '../store/LoadingStatuses';
import { InstrumentType } from '@harvestiq/constants';

export interface IItemWithId {
  id: number;
}

export function findById<Type extends IItemWithId>(items: Type[], id: number, defaultItem: Type): Type {
  return items.find((i) => i.id === id) || defaultItem;
}

export function nthWeekdayOfMonth(weekday: 1 | 2 | 3 | 4 | 5 | 6 | 7, n: number, date: Date) {
  const idate = new Date(date.getFullYear(), date.getMonth(), 1, 12),
    add = ((weekday - date.getDay() + 7) % 7) + (n - 1) * 7;
  idate.setDate(1 + add);
  return idate;
}

export function isLoading(statuses: string[]): boolean {
  return statuses.some((status) => status === LoadingStatuses.Loading);
}

export function toNumberOrUndefined(value: string | number | null): number | undefined {
  return value === '' || value === null ? undefined : Number(value);
}

export function toStringOrUndefined(value: string | null): string | undefined {
  return value === null ? undefined : value;
}

export function toStringOrNull(value: string | undefined): string | null {
  return value ? value : null;
}

export function toNumberOrNull(value: string | undefined): number | null {
  return value ? Number(value) : null;
}

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export const isSpecialOption = (instrumentType: InstrumentType | undefined) => {
  if (
    !instrumentType ||
    instrumentType === InstrumentType.Call ||
    instrumentType === InstrumentType.Put ||
    instrumentType === InstrumentType.Futures
  ) {
    return false;
  }

  return true;
};
