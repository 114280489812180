import React from 'react';

import { StepProps } from './TradeModal';
import Label from '../common-next/Label/Label';
import DialogContent from '../common-next/Dialog/DialogContent';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import { FontAwesomeIcon, IconProp, faBan, faCircleArrowRight, faFileInvoice, faCircleCheck } from '../faIcons';
import { InstrumentType } from '@harvestiq/constants';
import { formatNumber } from '../../utils/formatNumber';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import Decimal from 'decimal.js';
import { useIdleTimerContext } from 'react-idle-timer';
import TimeoutDisplay from '../common-next/TimeoutDisplay/TimeoutDisplay';
import Button from '../common-next/Button/Button';
import { useEffect, useState } from 'react';
import { getCancelableStatuses } from '@farmersrisk/cqg/cqgClient/helpers';
import { CQGHelpers } from '../CQG/helpers';
import { OrderStatus_Status } from '@farmersrisk/cqg/cqgMessages/common/shared_1';
import { CancelTradeButton } from '../common-next/CancelTradeButton/CancelTradeButton';
import { isNil } from 'lodash';
import Loading from '../common-next/Loading/Loading';
import { useCQGContext } from '../CQG/useCQGContext';
import useCQGService from '../CQG/useCQGService';
import { useTradeOrderContext } from './TradeOrderProvider';
import { useCurrentOperation } from '../../customHooks/useCurrentOperation';
import { getDisplayQuantity } from './helpers';
import TradeModalOrderSummaryText from './TradeModalOrderSummaryText';

interface TradeModalConfirmationProps extends StepProps {}

export const OrderStatusMap = {
  [OrderStatus_Status.IN_TRANSIT.toString()]: 'In Transit',
  [OrderStatus_Status.REJECTED.toString()]: 'Rejected',
  [OrderStatus_Status.WORKING.toString()]: 'Working',
  [OrderStatus_Status.EXPIRED.toString()]: 'Expired',
  [OrderStatus_Status.IN_CANCEL.toString()]: 'Cancelation Pending',
  [OrderStatus_Status.IN_MODIFY.toString()]: 'Modification Pending',
  [OrderStatus_Status.CANCELLED.toString()]: 'Canceled',
  [OrderStatus_Status.FILLED.toString()]: 'Filled',
  [OrderStatus_Status.SUSPENDED.toString()]: 'Suspended',
  [OrderStatus_Status.DISCONNECTED.toString()]: 'Disconnected',
  [OrderStatus_Status.ACTIVEAT.toString()]: 'Active',
  [OrderStatus_Status.APPROVE_REQUIRED.toString()]: 'Approval Pending',
  [OrderStatus_Status.APPROVED_BY_EXCHANGE.toString()]: 'Approved',
  [OrderStatus_Status.APPROVE_REJECTED.toString()]: 'Rejected',
  [OrderStatus_Status.MATCHED.toString()]: 'Matched',
  [OrderStatus_Status.PARTIALLY_MATCHED.toString()]: 'Partially Matched',
  [OrderStatus_Status.TRADE_BROKEN.toString()]: 'Trade Broken',
  [OrderStatus_Status.WORKING.toString()]: 'Working',
};

// TODO: add countdown timer for how valid the price/order is?
export default function TradeModalConfirmation(props: TradeModalConfirmationProps) {
  const currentOperation = useCurrentOperation();
  const cqgContext = useCQGContext();
  const cqgService = useCQGService();
  const orderContext = useTradeOrderContext();
  const [orderStatus, setOrderStatus] = useState<OrderStatus_Status | null>(null);
  const [isCanceling, setIsCanceling] = useState(false);
  const [orderInfo, setOrderInfo] = useState<string | null>(null);
  const currency = useCurrentCurrency();
  // Reset the idle timer when this step is reached.
  const timer = useIdleTimerContext();
  const currentOperationName = currentOperation?.name ?? '';

  useEffect(() => {
    const currentOrder = cqgContext?.client()?.getCurrentOrderRequest();
    if (currentOrder) {
      if (cqgContext?.state.orders[currentOrder.clOrderId]) {
        setOrderStatus(cqgContext?.state.orders[currentOrder.clOrderId].status);
        setOrderInfo(cqgContext?.state.orders[currentOrder.clOrderId].rejectMessage || '');
      }
      if (cqgContext?.state.rejects[currentOrder.requestId]) {
        setOrderStatus(OrderStatus_Status.REJECTED);
        setOrderInfo(cqgContext?.state.rejects[currentOrder.requestId].textMessage || '');
      }
    }
  }, [cqgContext?.state.orders, cqgContext?.state.rejects]);

  // DERIVED STATE
  const hasStrike = orderContext.instrumentType.value !== InstrumentType.Futures;
  const commodity = orderContext.selectedCommodity?.value;
  const displayStatus = orderStatus ? OrderStatusMap[orderStatus.toString()] : 'Unconfirmed';
  const showLoader =
    isNil(orderStatus) ||
    [
      OrderStatus_Status.IN_TRANSIT,
      OrderStatus_Status.IN_CANCEL,
      OrderStatus_Status.IN_MODIFY,
      OrderStatus_Status.WORKING,
    ].includes(orderStatus);

  timer.start();
  const orderStatusHint = showLoader ? 'Status receives live updates' : undefined;
  const orderStatusClassName = showLoader ? 'order-status-updating' : '';
  const cancelable = orderStatus ? getCancelableStatuses().includes(orderStatus) : false;
  const canceled = orderStatus === OrderStatus_Status.CANCELLED;
  const cqgClient = cqgContext?.client();
  if (!cqgClient || !commodity) {
    return null;
  }

  const currentOrder = cqgClient.getCurrentOrderRequest();

  // TODO: Improve this logic and make re-useable
  const tradeValue = !isNil(orderContext.orderLimitPrice)
    ? new Decimal(orderContext.orderLimitPrice).times(commodity?.standardSize ?? 1).toFixed(2)
    : null;

  return (
    <DialogContent className="complete">
      <DialogContentRow className="py-1">
        <div className="col-12 text-center">
          <FontAwesomeIcon icon={faFileInvoice as IconProp} className="hero" />
        </div>
      </DialogContentRow>
      <DialogContentRow className="text-center py-1">
        <p className="py-0 fs-4">Your order has been placed.</p>
      </DialogContentRow>
      <DialogContentRow className="details py-1">
        <Label fontWeight="bold">Order Details</Label>
        <span title={orderStatusHint} className={orderStatusClassName}>
          Status: {displayStatus} {orderInfo ? `(${orderInfo})` : null} {showLoader && <Loading />}
        </span>
      </DialogContentRow>
      <DialogContentRow className="confirmation py-1">
        <Label fontWeight="bold">Order Summary for {currentOperationName}</Label>
        <p className="text-center mb-1">
          <TradeModalOrderSummaryText orderPlaced={true} orderContext={orderContext} />
        </p>
        <p className="text-center fw-bold text-uppercase">
          {orderContext.contractsQuantity} {orderContext.contractsQuantity === 1 ? 'Contract' : 'Contracts'}
        </p>
        <p className="text-center mb-0">{getDisplayQuantity(commodity, orderContext.contractsQuantity)}</p>
        <p className="text-center">
          {hasStrike && !isNil(tradeValue) && <>The total trade value is {formatNumber(tradeValue, 2, currency)}.</>}
        </p>
      </DialogContentRow>
      {cancelable && (
        <DialogContentRow>
          <CancelTradeButton
            isCanceling={isCanceling}
            className="col-12 col-md-5 ms-2 mb-3"
            size="medium"
            onClick={() => {
              setIsCanceling(true);
              CQGHelpers.cancelOrder({
                client: cqgService?.client,
                resetClient: cqgService?.resetClient,
                orderStatus: cqgContext?.state.orders[currentOrder!.clOrderId],
              })
                .catch((e) => {
                  console.error(e);
                })
                .finally(() => {
                  setIsCanceling(false);
                });
            }}
          >
            <FontAwesomeIcon className="pr-2" icon={faBan} /> <b>Cancel Order</b>
          </CancelTradeButton>
        </DialogContentRow>
      )}
      {canceled && (
        <DialogContentRow>
          <Button className="col-12 col-md-5 mb-3" themeColor="light" size="medium" disabled={true}>
            <FontAwesomeIcon className="pr-2" icon={faCircleCheck} style={{ color: '#198754' }} /> <b>Order Canceled</b>
          </Button>
        </DialogContentRow>
      )}
      <DialogContentRow center className="pt-4 mx-auto">
        <Button
          className="col-4"
          themeColor="secondary"
          size="x-small"
          onClick={(e) => {
            e.preventDefault();
            if (props.showTradingHistoryModal) {
              cqgContext?.client()?.clearCurrentOrderRequest();
              props.showTradingHistoryModal();
            }
          }}
        >
          View Recent Trades
        </Button>
        <div className="col-3" />
        <Button
          themeColor="secondary"
          className=" btn-sm fw-bold p-3 k-reset col-5 fst-italic"
          onClick={(e) => {
            e.preventDefault();
            cqgContext?.client()?.clearCurrentOrderRequest();
            props.restart && props.restart();
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          <b> Place New Order</b> <FontAwesomeIcon className="pr-2" icon={faCircleArrowRight} style={{ color: '#1162ab' }} />
        </Button>
      </DialogContentRow>
      <TimeoutDisplay onClose={props.onClose} message={'This modal will close in'} />
    </DialogContent>
  );
}
