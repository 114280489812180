import { useEffect, useState } from 'react';
import { useIdleTimerContext } from 'react-idle-timer';
import * as React from 'react';

interface TimeoutDisplayProps {
  onClose: () => void;
  message: string;
}
export default function TimeoutDisplay(props: TimeoutDisplayProps) {
  const [secondsLeft, setSecondsLeft] = useState(-1);

  const { getRemainingTime } = useIdleTimerContext();

  // Update every 200 milliseconds to keep the timer accurate
  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft(Math.floor(getRemainingTime() / 1000));
    }, 200);
    return () => clearInterval(interval);
  }, [getRemainingTime]);

  const minutes: number = Math.floor(secondsLeft / 60);
  const seconds: number = secondsLeft % 60;
  return (
    <div>
      {secondsLeft <= 120 && secondsLeft > 0 && (
        <div>
          <br />
          <p className="col-12 p-0 m-0 text-center">
            {`${props.message} `}
            <span className="text-danger">
              {minutes > 1 ? `${minutes} minutes` : minutes === 1 ? '1 minute' : ''}
              {minutes > 0 && seconds > 0 ? ' ' : ''}
              {seconds > 1 ? `${seconds} seconds` : seconds === 1 ? '1 second' : ''}
            </span>{' '}
          </p>
        </div>
      )}
    </div>
  );
}
