import { CommodityInformation, CommodityId } from '@harvestiq/constants';
import Decimal from 'decimal.js';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';

export const fakeCrop: CommodityInformation = {
  id: CommodityId.UNKNOWN,
  name: 'Fake Crop',
  tradingCode: '00',
  barchartExchangeCode: '00',
  uom: UnitOfMeasure.Bushel,
  standardSize: new Decimal(1),
  futuresTradingMonths: [],
  newCropMonth: 0,
  shortDatedCutoff: 0,
  barchartStrikeMultiplier: 100,
  barchartPriceFactor: 100,
  formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
  formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
  isHedgingCrop: false,
  isLivestock: false,
  isMarketingCrop: true,
  isTradeable: false,
};
