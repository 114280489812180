import styles from './SteppedDialog.module.scss';

import * as React from 'react';
import { useRef } from 'react';
import Dialog, { DialogHandle, DialogProps } from './Dialog';
import { ScrollView, ScrollViewHandle } from '@progress/kendo-react-scrollview';
import { Keys } from '@progress/kendo-react-common';

export interface SteppedDialogProps extends DialogProps {
  initialStep?: number;
  onStepChange?: (currentStep: number, stepToChangeTo: number) => void;
  next?: () => void;
  prev?: () => void;
  restart?: () => void;
}

export interface ChildWithStepControl {
  prev?: () => void;
  next?: () => void;
  restart?: () => void;
  isActive?: boolean;
}

function SteppedDialog(props: React.PropsWithChildren<SteppedDialogProps>, ref: React.Ref<DialogHandle>) {
  const { initialStep = 1, className, ...otherProps } = props;
  const [currentStep, setStep] = React.useState<number>(initialStep);
  const scrollViewRef = useRef<ScrollViewHandle>(null);
  // This sucks because it blocks left/right from children components as well. Proceeding anyways as there is not a current need
  // for those keys and kendo does not have support for disabling keyboard navigation at this time
  const preventKendoKeyboardHack = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case Keys.left:
      case Keys.right:
        e.preventDefault();
        e.stopPropagation();
        return false;
      default:
        break;
    }
  };

  React.useEffect(() => {
    scrollViewRef.current?.element?.addEventListener('keydown', preventKendoKeyboardHack);
    return () => {
      scrollViewRef.current?.element?.removeEventListener('keydown', preventKendoKeyboardHack);
    };
  }, [scrollViewRef.current]);

  const prev = () => {
    if (scrollViewRef?.current?.element) {
      const prevArrow = scrollViewRef?.current?.element.querySelector('.k-scrollview-prev') as HTMLElement;
      if (prevArrow) {
        prevArrow.click();
      }
    }
    setStep(currentStep - 1);
    if (props.onStepChange) {
      props.onStepChange(currentStep, currentStep - 1);
    }
  };
  const next = () => {
    if (scrollViewRef?.current?.element) {
      const nextArrow = scrollViewRef?.current?.element.querySelector('.k-scrollview-next') as HTMLElement;
      if (nextArrow) {
        nextArrow.click();
      }
    }
    setStep(currentStep + 1);
    if (props.onStepChange) {
      props.onStepChange(currentStep, currentStep + 1);
    }
  };

  const restart = () => {
    if (scrollViewRef?.current?.element) {
      const nextArrow = scrollViewRef?.current?.element.querySelector('.k-scrollview-next') as HTMLElement;
      if (nextArrow) {
        nextArrow.click();
      }
    }
    setStep(1);
    if (props.onStepChange) {
      props.onStepChange(currentStep, 1);
    }
  };

  const childrenWithProps = React.Children.map(props.children, (child, idx: number) => {
    const props = { prev, next, restart, isActive: currentStep - 1 === idx };

    // DO NOT initialize and render views that aren't active.
    if (React.isValidElement<ChildWithStepControl>(child) && props.isActive) {
      return React.cloneElement(child, props);
    } else if (React.isValidElement<ChildWithStepControl>(child)) {
      return <div key={idx + 1} className="steppedDialogPlaceholder"></div>;
    }
    return child;
  });

  return (
    <Dialog ref={ref} className={`${styles['stepped-dialog-wrap']} ${className}`} {...otherProps}>
      <ScrollView
        ref={scrollViewRef}
        automaticViewChange={false}
        activeView={initialStep}
        endless={true}
        className="fr-scrollview"
      >
        {childrenWithProps}
      </ScrollView>
    </Dialog>
  );
}

export default React.forwardRef(SteppedDialog);
