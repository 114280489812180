import { faTriangleExclamation, FontAwesomeIcon } from '../../../../components/faIcons';
import { isNil } from 'lodash';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Button from '../../../../components/common-next/Button/Button';
import { BarConfig, ColoredBar } from '../../../../components/common-next/ColoredBar/ColoredBar';
import useMobileView, { BREAKPOINTS } from '../../../../customHooks/useMobileView';
import { formatNumber } from '../../../../utils/formatNumber';
import { HedgeCalcType } from '@farmersrisk/shared/enums';
import { OperationCropSummary } from '../../../../queries/operationCropSummaries';
import { CropContractSummary } from '../../../../queries/cropContractSummary';
import config from '../../../../config';

const dashboardSalesBar = config.featureFlags.dashboardSalesBar;

interface ColoredBarProps {
  operationCropSummary?: OperationCropSummary;
  cropContractSummary?: CropContractSummary;
  hedgeCalcType?: HedgeCalcType;
}

export function Visualization(props: ColoredBarProps) {
  const { operationCropSummary, cropContractSummary, hedgeCalcType = HedgeCalcType.Delta } = props;

  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  if (!operationCropSummary) {
    return <div>Some crop data is missing</div>;
  }
  if (operationCropSummary && !operationCropSummary.totalRelevantYield) {
    const errorSymbol = <FontAwesomeIcon icon={faTriangleExclamation} className="me-3" />;
    return (
      <div className="mt-3">
        <Alert variant="warning" className="d-flex align-items-center justify-content-between">
          <div className="flex-row align-items-center">
            <div className="">{errorSymbol}</div>
            <div>Please enter a non-zero yield value for this crop.</div>
          </div>
          <NavLink to={`/settings/crops/edit/${operationCropSummary.operationCropId}`} style={{ textDecoration: 'none' }}>
            <Button size={isMobile ? 'x-small' : 'medium'} themeColor="primary">
              Edit Crop
            </Button>
          </NavLink>
        </Alert>
      </div>
    );
  }
  const configSold = generateConfigSold(operationCropSummary, hedgeCalcType);
  const configCommitted = cropContractSummary && dashboardSalesBar ? generateConfigCommitted(cropContractSummary) : null;
  return (
    <>
      <ColoredBar className="mb-2" config={configSold} />
      {configCommitted && dashboardSalesBar && cropContractSummary?.committedQuantityUOM.greaterThan(0) && (
        <ColoredBar className="mb-2" config={configCommitted} />
      )}
    </>
  );
}

function generateConfigSold(summary: OperationCropSummary, hedgeCalcType?: HedgeCalcType) {
  const unprotectedQuantityUOM =
    hedgeCalcType === HedgeCalcType.Simple
      ? summary.simpleUnprotectedQuantityUOM.toNumber()
      : summary.unprotectedQuantityUOM.toNumber();
  const hedgeQuantityUOM =
    hedgeCalcType === HedgeCalcType.Simple
      ? dashboardSalesBar
        ? summary.simpleHedgeQuantityUOM.toNumber()
        : summary.simpleHedgeQuantityUOM.plus(summary.simpleHedgeOverageQuantityUOM ?? 0).toNumber()
      : dashboardSalesBar
      ? summary.currentDeltaHedgeQuantityUOM.toNumber()
      : summary.currentDeltaHedgeQuantityUOM.plus(summary.currentDeltaHedgeOverageQuantityUOM ?? 0).toNumber();

  const hedgePercentage =
    hedgeCalcType === HedgeCalcType.Simple ? summary.simpleHedgePercentage : summary.currentDeltaHedgePercentage;

  const unprotectedPercentage =
    hedgeCalcType === HedgeCalcType.Simple ? summary.simpleUnprotectedPercentage : summary.unprotectedPercentage;

  const config: BarConfig[] = [];

  config.push({
    labelTop: 'Sold',
    labelBottom: `${formatNumber(summary.soldQuantityUOM)} ${summary.uom} (${summary.soldPercentage.toFixed(1)}%)`,
    color: '#21BC30',
    percentValue: summary.soldPercentage.toNumber(),
    rawValue: summary.soldQuantityUOM.toNumber(),
    insurancePercent:
      isNil(summary.insuranceCoveragePercentage) || summary.insuranceCoveragePercentage.isZero()
        ? undefined
        : summary.insuranceCoveragePercentage.toNumber(),
  });

  if (hedgePercentage.greaterThan(0)) {
    config.push({
      labelTop: 'Unsold - Hedged',
      labelBottom: `${formatNumber(hedgeQuantityUOM)} ${summary.uom} (${hedgePercentage.toFixed(1)}%)`,
      color: '#FFDB6A',
      percentValue: hedgePercentage.toNumber(),
      rawValue: hedgeQuantityUOM,
    });
  }
  if (unprotectedQuantityUOM > 0) {
    config.push({
      labelTop: 'Unsold - Unprotected',
      labelBottom: `${formatNumber(unprotectedQuantityUOM)} ${summary.uom} (${unprotectedPercentage.toFixed(1)}%)`,
      color: '#FA414D',
      percentValue: unprotectedPercentage.toNumber(),
      rawValue: unprotectedQuantityUOM,
    });
  }
  return config;
}

function generateConfigCommitted(summary: CropContractSummary) {
  const config: BarConfig[] = [];

  if (summary.cashQuantityUOM.greaterThan(0)) {
    config.push({
      labelTop: 'Cash',
      labelBottom: `${formatNumber(summary.cashQuantityUOM)} ${summary.uom} (${summary.cashPercentage.toFixed(1)}%)`,
      color: '#21BC30',
      percentValue: summary.cashPercentage.toNumber(),
      rawValue: summary.cashQuantityUOM.toNumber(),
    });
  }
  if (summary.futuresQuantityUOM.greaterThan(0)) {
    config.push({
      labelTop: 'Futures',
      labelBottom: `${formatNumber(summary.futuresQuantityUOM)} ${summary.uom} (${summary.futuresPercentage.toFixed(1)}%)`,
      color: '#8BB98F',
      percentValue: summary.futuresPercentage.toNumber(),
      rawValue: summary.futuresQuantityUOM.toNumber(),
    });
  }
  if (summary.basisQuantityUOM.greaterThan(0)) {
    config.push({
      labelTop: 'Basis',
      labelBottom: `${formatNumber(summary.basisQuantityUOM)} ${summary.uom} (${summary.basisPercentage.toFixed(1)}%)`,
      color: '#FFDB6A',
      percentValue: summary.basisPercentage.toNumber(),
      rawValue: summary.basisQuantityUOM.toNumber(),
    });
  }
  if (summary.unpricedQuantityUOM.greaterThan(0)) {
    config.push({
      labelTop: 'Unpriced',
      labelBottom: `${formatNumber(summary.unpricedQuantityUOM)} ${summary.uom} (${summary.unpricedPercentage.toFixed(1)}%)`,
      color: '#FFF1C5',
      percentValue: summary.unpricedPercentage.toNumber(),
      rawValue: summary.unpricedQuantityUOM.toNumber(),
    });
  }
  if (summary.uncommittedQuantityUOM.greaterThan(0)) {
    config.push({
      labelTop: 'Uncommitted',
      labelBottom: `${formatNumber(summary.uncommittedQuantityUOM)} ${summary.uom} (${summary.uncommittedPercentage.toFixed(
        1
      )}%)`,
      color: '#FA414D',
      percentValue: summary.uncommittedPercentage.toNumber(),
      rawValue: summary.uncommittedQuantityUOM.toNumber(),
    });
  }

  return config;
}
