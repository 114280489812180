import { User } from '../../types/User';
import axios from 'axios';
import { addUser, editUserAction } from './actions';
import { AppThunk } from '../../types/Store';
import config from '../../config';
import { DbUserUpdate } from '@farmersrisk/shared/db/models/DbUser';

export const editUserThunk = (data: DbUserUpdate): AppThunk => {
  return (dispatch) => {
    axios
      .patch(`${config.backend.basePath}/users/current`, { ...data })
      .then((response) => dispatch(editUserAction(response.data)))
      .catch((error) => console.error('Error', error));
  };
};

export const getCurrentUserThunk = (callback: (user: User | null, error?: any) => void = () => {}): AppThunk => {
  return (dispatch) => {
    axios
      .get(`${config.backend.basePath}/users/current`)
      .then((res) => {
        dispatch(addUser({ ...res.data }));
        callback(res.data);
      })
      .catch((error) => {
        callback(null, error);
        console.error('Error', error);
      });
  };
};
