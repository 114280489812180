import { FontAwesomeIcon, IconDefinition, faExclamationTriangle } from '../../faIcons';
import { NavLink } from 'react-router-dom';
import React from 'react';
import './WarningRow.css';
import Button from '../../common-next/Button/Button';

export default function WarningRow(props: WarningRowProps) {
  const { message, linkTo, linkCaption, className = 'warning-row' } = props;

  return (
    <div className={className}>
      <div className="warning-row-item">
        <FontAwesomeIcon icon={faExclamationTriangle as IconDefinition} color="#FFC100" size={'2x'} />
        <label>{message}</label>
      </div>
      <div className="warning-row-item">
        {!!linkTo && (
          <NavLink to={linkTo} style={{ textDecoration: 'none' }}>
            <Button themeColor="primary">{linkCaption}</Button>
          </NavLink>
        )}
      </div>
    </div>
  );
}

type WarningRowProps = {
  message: string;
  linkTo?: string;
  linkCaption?: string;
  className?: string;
};
