import React from 'react';
import './Wizard.scss';
import Button from '../common/Button/Button';
import InputWithLabel, { InputEventType } from '../common/Inputs/InputWithLabel/InputWithLabel';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { IOperationCropWizard } from './Wizard';

const WEIGHTED_AVERAGE_LABEL = 'Weighted average selling price';
const BUSHELS_LABEL = 'Bushels';

interface WizardSoldProps {
  handleBack: () => void;
  handleSave: () => void;
  onSkip: () => void;
  operationCrops: IOperationCropWizard[];
  onChangeHandler: (data: { [key: string]: any }, cropId: number, isPrices: boolean) => void;
}

export default function WizardSold(props: WizardSoldProps) {
  const { handleSave, handleBack, operationCrops, onChangeHandler, onSkip } = props;
  const isMobileView = useMobileView(BREAKPOINTS.WIZARD_MOBILE);
  const currency = useCurrentCurrency();

  return (
    <div id="wizard-step5" className="wizard-card wizard-step5">
      <div className="wizard-card-header">
        <h3>How much crop have you sold already?</h3>
        <p />
      </div>
      {operationCrops.length && (
        <div>
          <div id="wizard-step5-body" className="wizard-card-body">
            <div className={`wizard-table${isMobileView ? '-mobile' : ''}`}>
              {!isMobileView && (
                <div className="wizard-table-head">
                  <div className="wizard-table-row">
                    <span className="wizard-table-cell">Crop</span>
                    <span className="wizard-table-cell">{WEIGHTED_AVERAGE_LABEL}</span>
                    <span className="wizard-table-cell">{BUSHELS_LABEL}</span>
                  </div>
                </div>
              )}
              <div className="wizard-table-body">
                {operationCrops
                  .filter((crop: IOperationCropWizard) => crop.selected)
                  .map((crop: IOperationCropWizard) => (
                    <div className="wizard-table-row" key={crop.name}>
                      <span className="wizard-table-cell">{crop.name}</span>
                      <span className="wizard-table-cell">
                        <div className="wizard-input-wrapper">
                          <InputWithLabel
                            label={isMobileView ? WEIGHTED_AVERAGE_LABEL : ''}
                            type={'number'}
                            value={crop.price?.sellingPrice || ''}
                            onChange={(e: InputEventType) => {
                              onChangeHandler(
                                { price: { ...crop.price, sellingPrice: e.target.value } },
                                crop.marketingCropType,
                                true
                              );
                            }}
                            additional={{
                              rightSideComponent: <span className="wizard-right-side-component">{currency.symbol}</span>,
                            }}
                          />
                        </div>
                      </span>
                      <span className="wizard-table-cell">
                        <div className="wizard-input-wrapper">
                          <InputWithLabel
                            label={isMobileView ? BUSHELS_LABEL : ''}
                            type={'number'}
                            value={crop.price?.bushelsContracted || ''}
                            onChange={(e: InputEventType) => {
                              onChangeHandler(
                                { price: { ...crop.price, bushelsContracted: e.target.value } },
                                crop.marketingCropType,
                                true
                              );
                            }}
                          />
                        </div>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="wizard-card-footer">
            <Button value="Skip Wizard" className="fr-button wizard-skip-button" onClick={onSkip} />
            <div>
              <Button value="Back" className="fr-button backGround-none wizard-back-button" onClick={handleBack} />
              <Button value="Finish" className="fr-button backGround-red" onClick={handleSave} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
