import React from 'react';
import Selector from '../common/Selector/Selector';
import { useDispatch } from 'react-redux';
import { setSelectedYear } from '../../store/ProductionCycles/actions';
import { prepareYearsListForSelect, buildYears } from '../../utils/optionsForSelect';
import { SelectorTypes } from '../common/SelectWithLabel/SelectWithLabel';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import useCurrentProductionCycle from '../../customHooks/useCurrentProductionCycle';
import { useAllOperationCrops } from '../../queries/operationCrops';
import { getInitialCropYear } from '../../shared/productionCycles';

export function GlobalProductionCycleDropdown() {
  const dispatch = useDispatch();
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const allOperationCropsQuery = useAllOperationCrops();
  const allOperationCrops = allOperationCropsQuery.data?.filter((oc) => !oc.hidden) || [];
  const selectedYear = useCurrentProductionCycle();
  const initialYear = selectedYear || getInitialCropYear(allOperationCrops);
  return (
    <div className={`protected-header-production-cycle-select${isMobileView ? '-mobile' : ''}`}>
      <Selector
        id={'global-production-cycle-select'}
        selected={{ value: initialYear, label: initialYear }}
        options={prepareYearsListForSelect(buildYears(allOperationCrops.map((operationCrop) => operationCrop.productionCycle)))}
        onChange={(selectedYearOption: SelectorTypes) => {
          dispatch(setSelectedYear(selectedYearOption.value));
        }}
      />
    </div>
  );
}
