import { ImpersonatingConfig } from '../types/ImpersonatingConfig';

const CURRENT_OPERATION_KEY = 'CURRENT_OPERATION_ID';
const LAST_VISITED_OPERATION_KEY = 'operationId';
const IMPERSONATING_CONFIG_KEY = 'CURRENT_IMPERSONATING_CONFIG';

export function getStoredCurrentOperationId(): number | null {
  const val = sessionStorage.getItem(CURRENT_OPERATION_KEY);

  if (val) {
    return Number(val);
  }
  return null;
}

export function getStoredLastVisitedOperationId(): number | null {
  let val = sessionStorage.getItem(CURRENT_OPERATION_KEY);
  if (!val) {
    val = localStorage.getItem(LAST_VISITED_OPERATION_KEY);
  }

  if (val) {
    return Number(val);
  }
  return null;
}

export function setStoredCurrentOperationId(operationId: number | string | null | undefined, sessionOnly = false) {
  if (operationId) {
    const val = Number(operationId);
    sessionStorage.setItem(CURRENT_OPERATION_KEY, val.toString());

    if (!sessionOnly) {
      localStorage.setItem(LAST_VISITED_OPERATION_KEY, val.toString());
    }
  } else {
    sessionStorage.removeItem(CURRENT_OPERATION_KEY);
  }
}

export function getImpersonatingConfig(): ImpersonatingConfig | undefined {
  const config = sessionStorage.getItem(IMPERSONATING_CONFIG_KEY);
  if (config) {
    return JSON.parse(config);
  }
}

export function setImpersonatingConfig(config: ImpersonatingConfig | undefined) {
  if (config) {
    sessionStorage.setItem(IMPERSONATING_CONFIG_KEY, JSON.stringify(config));
  } else {
    sessionStorage.removeItem(IMPERSONATING_CONFIG_KEY);
  }
}
