import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import ErrorToast from '../components/Toast/ErrorToast';
import SuccessToast from '../components/Toast/SuccessToast';
import config from '../config';
import { useCurrentOperationId } from '../customHooks/useCurrentOperation';
import useCurrentProductionCycle from '../customHooks/useCurrentProductionCycle';
import apiClient from '../shared/apiClient';
import {
  getHedgingCropsFromOperationCrops,
  getMarketingCropsFromOperationCrops,
  ICreateNewOperationCrop,
  IOperationCropV2,
} from '../types/IOperationCrops';
import { convertFieldsToDecimal } from '../utils/decimal';
import { sortOperationCrops } from '../utils/sort';
import { QueryKey } from './QueryKey';
import { getTradeableCommodities } from '@harvestiq/constants';

const BASE_PATH = `${config.backend.basePath}/operation-crops`;

const fetch = async (): Promise<IOperationCropV2[]> => {
  const includeHidden = true;
  const response = await apiClient.get(BASE_PATH, {
    params: {
      includeHidden,
    },
  });
  return response.data;
};

const createNewCrop = async (newOperationCrop: ICreateNewOperationCrop | IOperationCropV2) => {
  const response = await apiClient.post(BASE_PATH, newOperationCrop);
  return response.data;
};

const editCrop = async (editedOperationCrop: Omit<IOperationCropV2, 'relevantYieldUOMPerAcre'>) => {
  const response = await apiClient.patch(BASE_PATH, editedOperationCrop);
  return response.data;
};

const deleteCrop = async (operationCropId: number) => {
  const response = await apiClient.delete(`${BASE_PATH}/${operationCropId}`);
  return response.data;
};

const confirmDeleteCrop = async (operationCropId: number) => {
  const response = await apiClient.delete(`${BASE_PATH}/${operationCropId}?confirm=true`);
  return response.data;
};

export function useCurrentOperationCrops(includeHidden = false, selectedYear?: string, enabled = true) {
  const currentOperationId = useCurrentOperationId();
  const currentProductionCycle = useCurrentProductionCycle();
  const productionCycle = selectedYear || currentProductionCycle;

  const select = React.useCallback(
    (data: IOperationCropV2[]) => {
      let operationCrops = data.map(convertFieldsToDecimal);
      if (!includeHidden) {
        operationCrops = operationCrops.filter((operationCrop) => !operationCrop.hidden);
      }
      return sortOperationCrops(operationCrops, true, productionCycle);
    },
    [includeHidden, productionCycle, currentOperationId]
  );

  const query = useAllOperationCropsInner(select);
  return query;
}

export function useAllOperationCrops(opts?: { enabled: boolean }) {
  const currentOperationId = useCurrentOperationId();
  const select = React.useCallback(
    (data: IOperationCropV2[]) => {
      const operationCrops = data.map(convertFieldsToDecimal);
      return sortOperationCrops(operationCrops, false);
    },
    [currentOperationId]
  );

  const query = useAllOperationCropsInner(select, opts?.enabled ?? true);
  return query;
}

export function useAllOperationCropsInner<T>(select?: (data: IOperationCropV2[]) => T[], enabled = true) {
  const currentOperationId = useCurrentOperationId();

  const query = useQuery({
    queryKey: [QueryKey.OperationCrops, currentOperationId],
    queryFn: () => fetch(),
    enabled: enabled,
    select: select,
    refetchOnMount: false,
    onError: (error) => {
      ErrorToast('There was a problem while fetching your crops');
    },
  });
  return query;
}

export function useAllTradeableCommodities() {
  return {
    data: getTradeableCommodities(),
    isSuccess: true,
    isLoading: false,
  };
}

export function useAllHedgingCrops() {
  const currentOperationId = useCurrentOperationId();

  const select = React.useCallback(
    (operationCrops: IOperationCropV2[]) => {
      return getHedgingCropsFromOperationCrops(operationCrops);
    },
    [currentOperationId]
  );

  const query = useAllOperationCropsInner(select);
  return query;
}

export function useCurrentHedgingCrops() {
  const currentOperationId = useCurrentOperationId();
  const productionCycle = useCurrentProductionCycle();

  const select = React.useCallback(
    (operationCrops: IOperationCropV2[]) => {
      const currentCrops = operationCrops.filter((crop) => crop.productionCycle === productionCycle);
      return getHedgingCropsFromOperationCrops(currentCrops);
    },
    [currentOperationId, productionCycle]
  );

  const query = useAllOperationCropsInner(select);
  return query;
}

export function useCurrentMarketingCrops() {
  const currentOperationId = useCurrentOperationId();
  const productionCycle = useCurrentProductionCycle();

  const select = React.useCallback(
    (operationCrops: IOperationCropV2[]) => {
      const currentCrops = operationCrops.filter((crop) => crop.productionCycle === productionCycle);
      return getMarketingCropsFromOperationCrops(currentCrops);
    },
    [currentOperationId, productionCycle]
  );

  const query = useAllOperationCropsInner(select);
  return query;
}

export const useCreateOperationCrop = () => {
  const queryClient = useQueryClient();
  const currentOperationId = useCurrentOperationId();

  const mutation = useMutation(createNewCrop, {
    onSuccess: async (data: IOperationCropV2) => {
      // adds new data to useCurrentOperationCrops
      await queryClient.invalidateQueries([QueryKey.OperationCrops, currentOperationId]);

      SuccessToast(`${data.name} crop created successfully`);
    },
    onError: (error) => {
      console.error('Failed to create new crop', error);
      ErrorToast('There was a problem while attempting to create the crop');
    },
  });
  return mutation;
};

export const useEditOperationCrop = () => {
  const queryClient = useQueryClient();
  const currentOperationId = useCurrentOperationId();

  const mutation = useMutation(editCrop, {
    onSuccess: (data: IOperationCropV2) => {
      return queryClient.invalidateQueries([QueryKey.OperationCrops, currentOperationId]);
    },
    onError: (error) => {
      console.error('Failed to edit crop', error);
      ErrorToast('There was a problem while attempting to update the crop');
    },
  });
  return mutation;
};

export const useDeleteOperationCrop = () => {
  const queryClient = useQueryClient();
  const currentOperationId = useCurrentOperationId();

  const mutation = useMutation(deleteCrop, {
    onSuccess: async (data: IOperationCropV2) => {
      await queryClient.invalidateQueries([QueryKey.OperationCrops, currentOperationId]);
      SuccessToast(`${data.name} crop deleted successfully`);
    },
    onError: (error: AxiosError) => {
      if (error.response?.status !== 409) {
        console.error('Failed to delete crop', error);
        ErrorToast('There was a problem while attempting to delete the crop');
      }
    },
  });
  return mutation;
};

export const useConfirmDeleteOperationCrop = () => {
  const queryClient = useQueryClient();
  const currentOperationId = useCurrentOperationId();

  const mutation = useMutation(confirmDeleteCrop, {
    onSuccess: async (data: IOperationCropV2) => {
      await queryClient.invalidateQueries([QueryKey.OperationCrops, currentOperationId]);
      SuccessToast(`${data.name} crop deleted successfully`);
    },
    onError: (e) => {
      console.error('Failed to delete crop', e);
      ErrorToast('There was a problem while attempting to delete the crop');
    },
  });
  return mutation;
};
