import React, { ChangeEvent, useState } from 'react';
import './InputWithLabel.css';
import InfoToolTip from '../../ToolTips/InfoToolTip';
import useMobileView, { BREAKPOINTS } from '../../../../customHooks/useMobileView';
import { orderBy, uniq } from 'lodash';

export type InputEventType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type InputWithLabelProps = {
  autocomplete?: boolean;
  options?: string[];
  label: string;
  required?: boolean;
  value?: string | number | null;
  type?: string;
  className?: string;
  onChange?: (e: InputEventType) => void;
  onSelect?: (e: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean | undefined;
  validationErrorMessage?: string | null;
  placeholder?: string;
  defaultValue?: any;
  additional?:
    | {
        leftSideComponent?: any;
        rightSideComponent?: any;
        bottomSideComponent?: any;
      }
    | any;
  redBorder?: boolean;
  min?: number | string;
  max?: number | string;
  step?: number;
  infoTooltip?: string;
};

function getDefaultValue(value: number | string | undefined | null) {
  return value === undefined || value === null ? '' : value;
}

function InputWithLabel(props: InputWithLabelProps) {
  const [focus, setFocus] = useState(false);
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const {
    autocomplete = false,
    options = null,
    onSelect,
    label,
    required = false,
    value,
    infoTooltip,
    min,
    max,
    step,
    onChange,
    onKeyDown,
    onBlur,
    disabled,
    type,
    className = '',
    placeholder = '',
    defaultValue = '',
    additional = {},
    redBorder = false,
    validationErrorMessage,
  } = props;

  return (
    <div className={'input-with-label ' + className} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
      {label && (
        <label>
          {label}
          {required && <span className="required-label">*</span>}
          {infoTooltip && <InfoToolTip title={infoTooltip} />}
        </label>
      )}
      <div className={`input-wrapper ${validationErrorMessage || redBorder ? 'input-error' : ''}`.trim()}>
        {additional.leftSideComponent && <div className="mr-12 left-label">{additional.leftSideComponent}</div>}
        {type === 'textarea' ? (
          <textarea
            {...(onChange ? { value: getDefaultValue(value) } : { defaultValue: defaultValue })}
            className="input"
            cols={isMobileView ? 60 : 90}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <input
            {...(onChange ? { value: getDefaultValue(value) } : { defaultValue: defaultValue })}
            className="input"
            type={type || 'text'}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            disabled={disabled}
            required={required}
            min={min}
            max={max}
            step={step}
          />
        )}
        {additional.rightSideComponent && <div className="ml-8 right-label">{additional.rightSideComponent}</div>}
        {focus &&
          autocomplete &&
          options &&
          ((!value && prepareOptions(options).length > 0) || (!!value && filterOptions(options, value).length > 0)) && (
            <div className="autoselect-block" onFocus={() => setFocus(true)}>
              {((!value && prepareOptions(options)) || (!!value && filterOptions(options, value ?? '')) || []).map(
                (option: string) => (
                  <div
                    className={`autoselect-item${value === option ? ' selected' : ''}`}
                    key={option}
                    onMouseDown={() => {
                      onSelect && onSelect(option);
                      setFocus(false);
                    }}
                  >
                    {option}
                  </div>
                )
              )}
            </div>
          )}
      </div>
      {validationErrorMessage && <span className="input-with-label-error-message">{validationErrorMessage}</span>}
      {additional.bottomSideComponent && (
        <div className="input-with-label-bottom-side-component">{additional.bottomSideComponent}</div>
      )}
    </div>
  );
}

function prepareOptions(options: string[]): string[] {
  return orderBy(uniq(options)).filter((i: string) => !!i);
}

function filterOptions(options: string[], value: string | number): string[] {
  return prepareOptions(options).filter((v) => !!value && v.toLowerCase().indexOf(value.toString().toLowerCase()) >= 0);
}

export default InputWithLabel;
