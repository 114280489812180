import React from 'react';
import useAppContext from '../../customHooks/useAppContext';
import { useCurrentOperation } from '../../customHooks/useCurrentOperation';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import useOperations from '../../customHooks/useOperations';
import Selector from '../common/Selector/Selector';

export function OperationDropdown() {
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const operations = useOperations();
  const currentOperation = useCurrentOperation();
  const { changeCurrentOperation } = useAppContext();

  function onOperationChangeHandler(selectedOption: { label: string; value: number }) {
    const newOperationId = selectedOption.value;
    changeCurrentOperation(newOperationId);
  }

  if (!operations.length) return null;
  return (
    <div className={`protected-header-user-operation-select${isMobileView ? '-mobile' : ''}`}>
      <Selector
        selected={{ value: currentOperation.id, label: currentOperation.name }}
        options={operations.map((operation) => {
          return { value: operation.id, label: operation.name };
        })}
        onChange={(selectedOption: any) => onOperationChangeHandler(selectedOption)}
      />
    </div>
  );
}
