import { v4 as uuid } from 'uuid';
import Decimal from 'decimal.js';
import { SimpleOrderStatus } from '../../store/CQG/orderStatusReducer';
import { operation, OperationOptions } from 'retry';
import { TradeLogger } from '../../loggers/TradeLogger';
import config from '../../config';
import ErrorToast from '../Toast/ErrorToast';
import { TradeOrderLogger } from '../../loggers/TradeOrderLogger';
import { Order_Duration, Order_OrderType, Order_Side } from '@farmersrisk/cqg/cqgMessages/WebAPI/order_2';
import { isNil } from 'lodash';
import { ClientContext, CQGClient } from '@farmersrisk/cqg/cqgClient/CQGClient';

let timer = 0;

interface NewOrder {
  symbol: string;
  orderType: Order_OrderType;
  duration: Order_Duration;
  side: Order_Side;
  quantity: number;
  requestId?: number;
  limitPrice?: number;
  stopPrice?: number;
  suspend?: boolean;
}

interface RetryOptions {
  client: CQGClient | undefined;
  todoType: string;
  todo: (attempt?: number) => Promise<unknown>;
  onFail: (err: Error) => CQGClient | undefined;
  retries?: number;
}

interface HelperOptions {
  client: CQGClient | undefined;
  resetClient: (() => CQGClient) | undefined;
}

interface SendNewOrderOptions extends HelperOptions {
  order: NewOrder;
}
interface ResolveSymbolOptions extends HelperOptions {
  symbol: string;
}
interface CancelOrderOptions extends HelperOptions {
  orderStatus: SimpleOrderStatus | undefined;
}

interface LogoffAndCloseOptions {
  removeListeners?: boolean;
  /** Whether to delay the logoff procedure in the chance that another area is navigated to (e.g. trade -> activity) */
  immediate?: boolean;
}

export class CQGHelpers {
  // TODO: use async-retry instead of retry!!!
  static withRetry = (options: RetryOptions, operationName?: string) => {
    let { client, todoType, todo, onFail, retries = config.trading.retryAmount } = options;
    if (!client) {
      TradeLogger.warn('Helper called with invalid client');
      const err = new Error('Helper called with a null/invalid client');
      return new Promise<string>((r, reject) => reject(err));
    }
    clearTimeout(timer);
    let operationOptions: OperationOptions = {
      retries,
      minTimeout: config.trading.retryMinTimeout || 2000,
      maxTimeout: config.trading.retryMaxTimeout,
    };
    if (process.env.RUN_CQG_compliance || process.env.NODE_ENV === 'test') {
      operationOptions.minTimeout = 10;
      operationOptions.maxTimeout = 100;
    }
    let o = operation(operationOptions);

    async function execAttempt(currentAttempt: number) {
      try {
        await client?.ensureSession();
        const result = await todo(currentAttempt);
        return result;
      } catch (e) {
        TradeLogger.warn(
          `${client?.getDebugInfo()} - ${todoType} failed ${
            currentAttempt <= retries
              ? ` - Retrying CQG operation (${operationName}) failed, next attempt: ${currentAttempt + 1}`
              : ` - Retrying CQG operation (${operationName}) failed, no more attempts`
          }`,
          { error: e }
        );
        client = onFail(e as Error);
        throw e;
      }
    }

    // TODO: use async-retry instead of retry!!!
    return new Promise((resolve, reject) => {
      o.attempt((currentAttempt) => {
        execAttempt(currentAttempt)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            // o.retry returns false when no error is given
            // o.retry returns true when error is given or timeout reached
            if (o.retry(err as Error)) {
              return;
            }

            reject(o.mainError());
          });
      });
    });
  };

  static logoffAndClose = (client: CQGClient | undefined, context: ClientContext, options?: LogoffAndCloseOptions) => {
    if (client) {
      clearTimeout(timer);
      const logoff = async () => {
        // If the client is now active in another area, do not logoff
        if (client.getContext() !== context) {
          return;
        }
        try {
          client.setContext(ClientContext.NONE);
          await client.logoff();
          const removeListeners = typeof options?.removeListeners === 'boolean' ? options.removeListeners : true;
          await client.close({ removeListeners });
        } catch (e) {
          console.error('CGQ: Failed to logoff and close client', e);
        }
      };
      if (!options?.immediate) {
        return new Promise((resolve, reject) => {
          timer = window.setTimeout(() => {
            logoff().then(resolve).catch(reject);
          }, 5000);
        });
      }
      return logoff();
    }
    return Promise.resolve();
  };

  static subscribeToTrade = async (options: HelperOptions) => {
    let { client, resetClient } = options;
    const retryOptions: RetryOptions = {
      client,
      todoType: 'Subscribe to trade',
      todo: async (attemptNumber) => {
        await client?.logon();
        await client?.getAccounts();
        return await client?.tradeSubscription();
      },
      onFail: (e) => {
        if (typeof resetClient === 'function') {
          client = resetClient();
          return client;
        } else {
          return undefined;
        }
      },
    };
    return this.withRetry(retryOptions, 'subscribe').catch((e) => {
      console.error('CQG - Failed to subscribe to trade:', e);
      TradeLogger.error(`${client?.getDebugInfo()} - Retries exhausted - Subscribe to trade failed: ${e}`, { error: e });
      ErrorToast('There was a problem while preparing to trade. Please try again later.', 'Trading Error', 'subscribe-to-trade');
    });
  };

  static fetchOrderHistory = async (options: HelperOptions) => {
    let { client, resetClient } = options;

    if (!client && typeof resetClient === 'function') {
      client = resetClient();
    }

    if (!client) {
      throw new Error('CQGHelpers.fetchOrderHistory: client is undefined');
    }

    const retryOptions: RetryOptions = {
      client,
      todoType: 'Fetch recent orders',
      todo: async (attempt) => {
        await client?.logon();
        await client?.getAccounts();
        const orderHistoryReport = await client?.orderHistory();
        await client?.tradeSubscription();

        return orderHistoryReport;
      },
      onFail: (e) => {
        if (typeof resetClient === 'function') {
          client = resetClient();
          return client;
        } else {
          return undefined;
        }
      },
    };

    return this.withRetry(retryOptions, 'fetch').catch((e) => {
      TradeLogger.error(`${client?.getDebugInfo()} - Retries exhausted - Fetching recent orders failed ${e}`, { error: e });
      ErrorToast(
        'There was a problem while fetching recent orders. Please try again later.',
        'Trading Error',
        'fetch-recent-orders'
      );
      console.error('CQG - Failed to fetch recent orders:', e);
    });
  };

  static sendNewOrder = async (options: SendNewOrderOptions) => {
    let { client, resetClient, order } = options;
    const retryOptions: RetryOptions = {
      client,
      todoType: 'Send new order',
      // NOTE: Don't retry sending an order so that accidental order duplication doesn't occur!
      retries: 0,
      todo: async () => {
        await client?.logon();
        await client?.getAccounts();
        await client?.tradeSubscription();
        await client?.resolveSymbol(order.symbol);
        const symbolContract = client?.getCurrentSymbol();
        let limitPrice, stopPrice;
        if (symbolContract) {
          limitPrice = !isNil(order.limitPrice)
            ? new Decimal(order.limitPrice).times(100).dividedBy(symbolContract.correctPriceScale).toNumber()
            : undefined;
          stopPrice = !isNil(order.stopPrice)
            ? new Decimal(order.stopPrice).times(100).dividedBy(symbolContract.correctPriceScale).toNumber()
            : undefined;
        } else {
          throw new Error();
        }
        const account = client?.getValidAccounts()[0];
        const accountId = account ? account.accountId : 0;
        const clOrderId = uuid();
        return await client?.sendNewOrder(
          accountId,
          symbolContract?.contractId as number,
          order.orderType,
          order.duration,
          order.side,
          order.quantity,
          order.requestId,
          limitPrice,
          stopPrice,
          order.suspend || false,
          clOrderId
        );
      },
      onFail: (e) => {
        if (typeof resetClient === 'function') {
          client = resetClient();
          return client;
        } else {
          return undefined;
        }
      },
    };
    return this.withRetry(retryOptions, 'send new').catch((e) => {
      TradeOrderLogger.error(`${client?.getDebugInfo()} - Retries exhausted - Send new order failed: ${e}`, { error: e });
      ErrorToast('There was a problem while placing a trade. Please try again later.', 'Trading Error', 'send-new-order');
      throw e;
    });
  };

  static resolveSymbol = async (options: ResolveSymbolOptions) => {
    let { client, resetClient, symbol } = options;
    const retryOptions: RetryOptions = {
      client,
      todoType: 'Resolve symbol',
      todo: async () => {
        await client?.logon();
        await client?.getAccounts();
        await client?.tradeSubscription();
        return await client?.resolveSymbol(symbol);
      },
      onFail: (e) => {
        if (typeof resetClient === 'function') {
          client = resetClient();
          return client;
        } else {
          return undefined;
        }
      },
    };
    return this.withRetry(retryOptions, 'resolve').catch((e) => {
      console.error('CGQ: resolveSymbol error', e);
      TradeLogger.error(`${client?.getDebugInfo()} - Retries exhausted - Resolving symbol failed: ${e}`, { error: e });
      ErrorToast('There was a problem preparing the trade contract. Please try again later.', 'Trading Error', 'resolve-symbol');
      throw e;
    });
  };

  static cancelOrder = async (options: CancelOrderOptions) => {
    let { client, resetClient, orderStatus } = options;
    const retryOptions: RetryOptions = {
      client,
      todoType: 'Cancel order',
      todo: async () => {
        await client?.logon();
        await client?.getAccounts();
        await client?.tradeSubscription();
        if (orderStatus?.order) {
          return await client?.cancelOrder(orderStatus.orderId, orderStatus.order?.accountId, orderStatus.order?.clOrderId);
        }
      },
      onFail: (e) => {
        if (typeof resetClient === 'function') {
          client = resetClient();
          return client;
        } else {
          return undefined;
        }
      },
    };
    return this.withRetry(retryOptions, 'cancel').catch((e) => {
      TradeLogger.error(`${client?.getDebugInfo()} - Retries exhausted - Cancel order failed: ${e}`, { error: e });
      ErrorToast('There was a problem canceling the order. Please try again later.', 'Trading Error', 'cancel-order');
      throw e;
    });
  };
}
