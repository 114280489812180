import { ErrorRecord } from '@farmersrisk/cqg/cqgClient/Errors';

export interface ErrorState {
  errors: ErrorRecord[];
}

export interface ErrorAction {
  payload: ErrorRecord;
}

export function errorReducer(state: ErrorState, action: ErrorAction): ErrorState {
  return { errors: [action.payload].concat(state.errors) };
}
