import styles from './DialogContentRow.module.scss';

import * as React from 'react';

interface DialogContentRowProps extends React.HTMLProps<HTMLDivElement> {
  center?: boolean;
  feature?: boolean;
  mute?: boolean;
  subtle?: boolean;
  slimTop?: boolean;
  slimBottom?: boolean;
  title?: string;
  justify?: boolean;
  top?: boolean;
}

function DialogContentRow(props: DialogContentRowProps, ref: React.Ref<HTMLDivElement>) {
  const { className, center, feature, mute, subtle, slimTop, slimBottom, justify, top, title, ...otherProps } = props;
  const classNames = [
    `${styles['fr-dialog-content-row']}`,
    `${center ? 'fr-dialog-content-row-centered' : ''}`,
    `${top ? 'fr-dialog-content-row-top' : ''}`,
    `${feature ? 'fr-dialog-content-row-featured' : ''}`,
    `${slimTop ? 'fr-dialog-content-row-slim-top' : ''}`,
    `${slimBottom ? 'fr-dialog-content-row-slim-top' : ''}`,
    `${mute ? 'fr-dialog-content-row-muted' : ''}`,
    `${subtle ? 'fr-dialog-content-row-subtle' : ''}`,
    `${justify ? 'fr-dialog-content-row-justify' : ''}`,
    'row',
    className,
  ].filter((x) => !!x) as string[];
  return (
    <>
      {title && <div className={styles['fr-dialog-content-title']}>{title}</div>}
      <div ref={ref} className={classNames.join(' ')} {...otherProps} />
    </>
  );
}

export default React.forwardRef(DialogContentRow);
