import { ITradingLog, TradingLogOriginType } from '../types/ITradingLogs';
import { BuySellType } from '@farmersrisk/shared/enums';
import { CommodityInformation, InstrumentType } from '@harvestiq/constants';
import { cqgEvents } from '@farmersrisk/cqg/cqgClient/cqgEvents';
import { LogLevel, createLogger } from '.';
import { TradeExecutionIntent } from '../components/CQG/CQGProvider';
import { Order_OrderType } from '@farmersrisk/cqg/cqgMessages/WebAPI/order_2';
import { OrderType } from '../models/orderTypes';
import { StrikeAndPremiumType } from '../components/TradeModal/TradeOrderProvider';

export const TradeLogger = createLogger('tradingLogger', { context: 'trade' });

export interface TradeExecutionOptions {
  orderType?: Order_OrderType | number;
  quantity?: number;
  instrumentType?: InstrumentType;
  buySell?: BuySellType;
  orderPlacementTimestamp?: number;
  orderPrice?: number;
  crop?: CommodityInformation;
  contract?: string;
  strikeAndPremium: StrikeAndPremiumType;
}

export const createCQGEventTradingLog = (
  cqgEvent: cqgEvents,
  opts: TradeExecutionOptions | undefined,
  userId: number,
  operationId: number,
  intent: TradeExecutionIntent | undefined,
  level: LogLevel = LogLevel.info,
  data?: any
) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin: TradingLogOriginType.CQG,
    cqgEvent: cqgEvent,
    cqgData: data,
    orderInformation: opts,
  };

  const orderType = opts?.orderType ? OrderType[opts.orderType] : '[NO ORDER TYPE]';
  const instrumentType = opts?.instrumentType ? InstrumentType[opts.instrumentType] : '[NO INSTRUMENT TYPE]';
  const tradingLogMessage = `CQG: ${cqgEvent} - intent: ${
    intent ?? '[NO INTENT]'
  } - user: ${userId} - operation: ${operationId} - ${instrumentType} ${orderType} order`;

  TradeLogger[level](tradingLogMessage, tradingLog);
};

export const createUserEventTradingLog = (
  message: string,
  opts: TradeExecutionOptions | undefined,
  userId: number,
  operationId: number,
  level: LogLevel = LogLevel.info,
  data?: any
) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin: TradingLogOriginType.User,
    cqgData: data,
    orderInformation: opts,
  };

  const orderType = opts?.orderType ? OrderType[opts.orderType] : '[NO ORDER TYPE]';
  const instrumentType = opts?.instrumentType ? InstrumentType[opts.instrumentType] : '[NO INSTRUMENT TYPE]';
  const tradingLogMessage = `User: ${message} user: ${userId} - operation: ${operationId} - ${instrumentType} ${orderType} order`;

  TradeLogger[level](tradingLogMessage, tradingLog);
};

// Every userMsg sent and serverMsg received
export const logRawMessageTradingLog = (
  userId: number,
  operationId: number,
  origin: TradingLogOriginType,
  data: any,
  messagePrefix: string
) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin,
    cqgData: data,
  };
  const msg =
    origin === TradingLogOriginType.User
      ? `Raw User Message - user: ${userId} operation: ${operationId}`
      : `Raw CQG Server Message - user: ${userId} operation: ${operationId}`;
  TradeLogger.debug(`${messagePrefix} - ${msg}`, tradingLog);
};

// Function in CGQClient to send a msg
export const logUserActionsTradingLog = (
  userId: number,
  operationId: number,
  message: string,
  data: any,
  messagePrefix: string
) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin: TradingLogOriginType.User,
    cqgData: data,
  };

  TradeLogger.debug(`${messagePrefix} - ${message} - user: ${userId} operation: ${operationId}`, tradingLog);
};

// Unable to perform an action to send a msg
export const logUserActionsErrorTradingLog = (userId: number, operationId: number, message: string, messagePrefix: string) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin: TradingLogOriginType.User,
  };

  TradeLogger.error(`${messagePrefix} - ${message} - user: ${userId} operation: ${operationId}`, tradingLog);
};

// Parsed server msg received
export const logServerMsgTradingLog = (
  userId: number,
  operationId: number,
  message: string,
  data: any,
  messagePrefix: string
) => {
  const tradingLog: ITradingLog = {
    userId: userId,
    operationId: operationId,
    origin: TradingLogOriginType.CQG,
    cqgData: data,
  };

  TradeLogger.debug(`${messagePrefix} - ${message} - user: ${userId} operation: ${operationId}`, tradingLog);
};
