import { useCQGContext } from './useCQGContext';

export default function useCQGService() {
  const cqgContext = useCQGContext();

  // could be initializing...
  if (!cqgContext) {
    return;
  }

  return cqgContext.service;
}
