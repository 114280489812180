export enum RecordCode {
  A = 'A', // Adjustment
  B = 'B', // Busts/Cancel
  C = 'C', // Cash Entry
  E = 'E', // Exercises & Assignments
  P = 'P', // Position !OR! P&S Record (Purchases & Sales Report)
  Q = 'Q', // Re-instated Trade/P&S Reversal
  R = 'R', // Receive/Deliver
  S = 'S', // FX Transaction
  T = 'T', // Trade/Transaction Record
  X = 'X', // Expiration
  Z = 'Z', // Partial Fill, Custom FR code
}

export enum SourceType {
  manual = 0, // created by user
  integration = 1, // created by sync with FCM
  implied = 2, // transaction created on first sync with FCM to balance existing open positions
  // or openPosition created from when manual transactions have a net position
  cqgOrder = 3, // created by sync with CQG
}

// deprecated
// This should only be used with strategies
export enum ContractStatus {
  Open = 1,
  Closed = 2,
  Expired = 3,

  // indicating that a position has dropped from the POS report, but we're not sure yet if it
  // has expired, closed out, or been exercised
  Pending = 4,
  Excercised = 5,
}

export enum BuySellType {
  Buy = 1,
  Sell = 2,
}

export enum SynchronizationTypes {
  ADMTransaction = 1,
  ADMPosition = 2,
  MarexTransaction = 3,
  MarexPosition = 4,
  RJOBrienTransaction = 5,
  RJOBrienPosition = 6,
  StoneXTransaction = 7,
  StoneXPosition = 8,
}

export enum PositionType {
  Option = 1,
  Futures = 2,
  Spreads = 3,
}

export enum HedgeCalcType {
  Delta = 1,
  Simple = 2,
}
