import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICropContract } from '../../types/ICropContract';
import { LoadingStatuses } from '../LoadingStatuses';
import { RootState } from '../../types/Store';
import { cloneDeep } from 'lodash';

export type CropContractInitialStateType = {
  cropContracts: ICropContract[];
  scenarioAnalysisCropContracts: ICropContract[];
  status: string;
  error: string | null;
};

const initialState: CropContractInitialStateType = {
  cropContracts: [],
  scenarioAnalysisCropContracts: [],
  status: LoadingStatuses.Idle,
  error: null,
};

export interface ICropContractUpdate {
  id: number;
  isEnabled: boolean;
}

const cropContractSlice = createSlice({
  name: 'cropContracts',
  initialState,
  reducers: {
    changeLoadingStatus: (state, action: PayloadAction<string>) => ({ ...state, status: action.payload }),
    setCropContracts: (state, action: PayloadAction<ICropContract[]>) => ({
      ...state,
      cropContracts: action.payload.filter(({ isScenario }) => !isScenario),
      scenarioAnalysisCropContracts: action.payload.filter(({ isScenario }) => isScenario),
    }),
    addNewCropContract: (state, action: PayloadAction<ICropContract>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts']: [
        ...state[action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts'],
        {
          ...action.payload,
          isEnabled: action.payload.isScenario,
        },
      ],
    }),
    editCropContract: (state, action: PayloadAction<ICropContract>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts']: updateCropContract(
        state[action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts'],
        action.payload
      ),
    }),
    enableScenarioAnalysisCropContracts: (state, action: PayloadAction<ICropContractUpdate[]>) => ({
      ...state,
      scenarioAnalysisCropContracts: enableManyScenarioAnalysisCropContracts(state.scenarioAnalysisCropContracts, action.payload),
    }),
    deleteCropContract: (state, action: PayloadAction<ICropContract>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts']: filterDeletedCropContract(
        state[action.payload.isScenario ? 'scenarioAnalysisCropContracts' : 'cropContracts'],
        action.payload
      ),
    }),
  },
});

function updateCropContract(state: ICropContract[], cropContract: ICropContract) {
  const newState = [...state];
  const previousCropContract: ICropContract | undefined = newState.find((crop) => crop.id === cropContract.id);

  if (previousCropContract) {
    newState[newState.indexOf(previousCropContract)] = { ...previousCropContract, ...cropContract };
  }
  return newState;
}

function filterDeletedCropContract(state: ICropContract[], cropContract: ICropContract) {
  return state.filter((contract) => contract.id !== cropContract.id);
}

function enableManyScenarioAnalysisCropContracts(state: ICropContract[], updates: ICropContractUpdate[]) {
  const newState = cloneDeep(state);

  updates.forEach((update) => {
    const previousContract: ICropContract = newState.find((contract) => contract.id === update.id) as ICropContract;

    if (previousContract) {
      newState[newState.indexOf(previousContract)] = { ...previousContract, ...update };
    }
  });

  return newState;
}

export const {
  changeLoadingStatus,
  setCropContracts,
  addNewCropContract,
  editCropContract,
  enableScenarioAnalysisCropContracts,
  deleteCropContract,
} = cropContractSlice.actions;

export default cropContractSlice.reducer;

export const selectAllCropContracts = (state: RootState) => state.cropContracts.cropContracts;

export const selectAllScenarioAnalysisCropContracts = (state: RootState) => state.cropContracts.scenarioAnalysisCropContracts;

export const selectAllEnabledScenarioAnalysisCropContracts = (state: RootState) =>
  state.cropContracts.scenarioAnalysisCropContracts.filter((c) => c.isEnabled);
