import { ErrorType, ErrorTypes } from './types';

export default function errors(state: ErrorType = {}, action: ErrorTypes) {
  switch (action.type) {
    case 'SET_INVITE_ACCOUNT_MEMBER_ERROR':
      return { ...state, ...action.payload };
    case 'DELETE_ERROR':
      return {};
    default:
      return state;
  }
}
