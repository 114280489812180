import React from 'react';
import styles from './OperationCropSummaryCard.module.scss';
import { IOperationCropV2 } from '../../../../types/IOperationCrops';
import { Title } from './Title';
import { Visualization } from './Visualization';
import { Details, DetailsProps } from './Details';
import { formatNumber } from '../../../../utils/formatNumber';
import useMobileView, { BREAKPOINTS } from '../../../../customHooks/useMobileView';
import { useCurrentOperation } from '../../../../customHooks/useCurrentOperation';
import { HedgeCalcType } from '@farmersrisk/shared/enums';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import { OperationCropSummary } from '../../../../queries/operationCropSummaries';
import { CropContractSummary } from '../../../../queries/cropContractSummary';
import config from '../../../../config';

const dashboardSalesBar = config.featureFlags.dashboardSalesBar;

interface OperationCropSummaryCard {
  operationCrop: IOperationCropV2;
  operationCropSummary?: OperationCropSummary;
  cropContractSummary?: CropContractSummary;
}

export function OperationCropSummaryCard(props: OperationCropSummaryCard) {
  const { operationCropSummary, operationCrop, cropContractSummary } = props;
  const currentOperation = useCurrentOperation();
  const uom = operationCrop.uom ?? UnitOfMeasure.Bushel;

  // if (summary) {
  //   summary.hedgeCalcType = currentOperation?.hedgeCalcType ?? HedgeCalcType.Delta;
  // }

  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const soldDetails: DetailsProps = {
    label: 'Sold',
    tooltip: 'Sold refers to contracts with a specified futures price',
    amountLabel: `${formatNumber(operationCropSummary?.soldQuantityUOM)} ${uom}`,
    perPriceValue: operationCropSummary?.soldPerUOMPrice.toNumber(),
    profit: operationCropSummary?.soldProfitValue.toNumber(),
    profitPerAcre: operationCropSummary?.soldProfitPerAcrePrice.toNumber(),
    perUOMValue: operationCropSummary?.soldProfitPerUOMPrice.toNumber(),
    uom,
  };
  const unsoldDetails: DetailsProps = {
    label: 'Unsold',
    tooltip: 'Unsold refers to remaining yield that is not sold',
    amountLabel: `${formatNumber(operationCropSummary?.unsoldQuantityUOM.toNumber())} ${uom}`,
    perPriceValue: operationCropSummary?.currentMarketPrice.toNumber(),
    profit: operationCropSummary?.unsoldProfitValue.toNumber(),
    profitPerAcre: operationCropSummary?.unsoldProfitPerAcrePrice.toNumber(),
    perUOMValue: operationCropSummary?.unsoldProfitPerUOMPrice.toNumber(),
    uom,
  };
  const gainLossDetails: DetailsProps = {
    label: 'Hedge Gain/Loss',
    tooltip: 'Gain/Loss from hedging activity allocated to this operation crop',
    profit: operationCropSummary?.totalGainLossValue.toNumber(),
    revenueLabel: 'Total Gain/Loss',
    perUOMValue: operationCropSummary?.totalGainLossPerUOMPrice.toNumber(),
    perUOMLabel: 'Gain/Loss',
    uom,
  };
  const oversoldDetails: DetailsProps = {
    label: 'Oversold',
    tooltip: 'Oversold refers to contracts with a specified futures price in excess of yield',
    amountLabel: `${formatNumber(operationCropSummary?.soldOverageUOM)} ${uom}`,
    perPriceValue: operationCropSummary?.soldOverageValue.dividedBy(operationCropSummary.soldOverageUOM).toNumber(),
    profit: operationCropSummary?.soldOverageValue.toNumber(),
    uom,
  };
  const overHedgedQuantity =
    (currentOperation?.hedgeCalcType ?? HedgeCalcType.Delta) === HedgeCalcType.Simple
      ? operationCropSummary?.simpleHedgeOverageQuantityUOM.toNumber()
      : operationCropSummary?.currentDeltaHedgeOverageQuantityUOM.toNumber();
  const overHedgedDetails: DetailsProps = {
    label: 'Overhedged',
    tooltip: 'Overhedged refers to a hedge quantity in excess of unsold quantity',
    amountLabel: `${formatNumber(overHedgedQuantity)} ${uom}`,
    uom,
  };
  const specQuantity =
    (currentOperation?.hedgeCalcType ?? HedgeCalcType.Delta) === HedgeCalcType.Simple
      ? operationCropSummary?.simpleHedgeQuantityUOM.lessThan(0)
        ? operationCropSummary.simpleHedgeQuantityUOM.negated().toNumber()
        : 0
      : operationCropSummary?.currentDeltaHedgeQuantityUOM.lessThan(0)
      ? operationCropSummary.currentDeltaHedgeQuantityUOM.negated().toNumber()
      : 0;
  const specDetails: DetailsProps = {
    label: 'Speculation',
    tooltip: 'Speculated quantity represents long positions in the market',
    amountLabel: `${formatNumber(specQuantity)} ${uom}`,
    profit: operationCropSummary?.unrealizedGainLossValue.toNumber(),
    revenueLabel: 'Unrealized Gain/Loss',
    uom,
  };
  return (
    <div className={`${styles['fr-operation-crop-summary-card']} card mb-3`}>
      <Title
        operationCropId={operationCrop.id}
        name={operationCrop.name}
        cropType={operationCrop.marketingCropType}
        uom={uom}
        totalAcres={operationCropSummary?.totalAcres.toNumber()}
        relevantYield={operationCrop?.relevantYieldUOMPerAcre.toString()}
        breakevenPerUOM={operationCropSummary?.breakEvenPerUOMPrice.toNumber()}
        profitPerAcre={operationCropSummary?.totalProfitPerAcrePrice.toNumber()}
        profitPerUOM={operationCropSummary?.totalProfitPerUOMPrice.toNumber()}
        returnOnInvestmentPercentage={operationCropSummary?.returnOnInvestmentPercentage.toNumber()}
      />
      <Visualization
        operationCropSummary={operationCropSummary}
        cropContractSummary={cropContractSummary}
        hedgeCalcType={currentOperation?.hedgeCalcType ?? HedgeCalcType.Delta}
      />
      {isMobile && <hr />}
      <Details {...soldDetails} />
      {isMobile && <hr />}
      <Details {...unsoldDetails} />
      {dashboardSalesBar && (operationCropSummary?.soldOverageUOM.toNumber() ?? 0) > 0 && isMobile && <hr />}
      {dashboardSalesBar && (operationCropSummary?.soldOverageUOM.toNumber() ?? 0) > 0 && <Details {...oversoldDetails} />}
      {isMobile && <hr />}
      <Details {...gainLossDetails} />
      {dashboardSalesBar && (overHedgedQuantity ?? 0) > 0 && isMobile && <hr />}
      {dashboardSalesBar && (overHedgedQuantity ?? 0) > 0 && <Details {...overHedgedDetails} />}
      {dashboardSalesBar && (specQuantity ?? 0) > 0 && isMobile && <hr />}
      {dashboardSalesBar && (specQuantity ?? 0) > 0 && <Details {...specDetails} />}
    </div>
  );
}
