/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../google/protobuf/timestamp';
import { ContractMetadata } from './metadata_2';

export const protobufPackage = 'instrument_definition_2';

/**
 * Message describing a user-defined (non-strategy) instrument, e.g. a flex option.
 * Which attributes may be specified, and in what combination, is exchange-specific.
 * Description of usage conventions can be found in document InstrumentDefinitionConventions_WebAPI.adoc.
 */
export interface InstrumentDefinition {
  /**
   * Symbol of the underlying contract, in exchange-specific format.
   * This is a required field.
   */
  underlyingContractSymbol: string;
  /**
   * CQG base instrument type.
   * One of metadata_2.CQGInstrumentType enums.
   * This is a required field.
   */
  cqgInstrumentType: number;
  /**
   * Maturity timestamp.
   * (local exchange date in timestamp format, use date part only).
   * This is a required field.
   */
  maturityTimestamp: Date | undefined;
  /**
   * Exchange-specific instrument type, if required by exchange.
   * Allowed type strings depend on exchange convention.
   */
  exchangeInstrumentType: string;
  /**
   * Settlement method, if required by exchange.
   * One of metadata_2.SettlementMethod enums.
   */
  settlementMethod: number;
  /**
   * Option strike value, for options only.
   * Value will be passed as-is to exchange.
   */
  strike: number;
  /**
   * Exercise style, for options only, if required by exchange.
   * One of metadata_2.ExerciseStyle enums.
   */
  exerciseStyle: number;
  /**
   * Pricing convention, if required by exchange.
   * One of metadata_2.PricingConvention enums.
   */
  pricingConvention: number;
}

/** Request to define a non-strategy as a contract. */
export interface InstrumentDefinitionRequest {
  /**
   * Instrument to define.
   * This is a required field.
   */
  instrumentDefinition: InstrumentDefinition | undefined;
  /**
   * Account ID in CQG trade routing system.
   * Some exchanges require an account ID in the process of defining an instrument.
   * For those exchanges this is a required field.
   */
  accountId: number;
}

/** Report of the contract metadata assigned to the instrument. */
export interface InstrumentDefinitionReport {
  /**
   * Assigned instrument metadata.
   * This is a required field.
   */
  contractMetadata: ContractMetadata | undefined;
}

function createBaseInstrumentDefinition(): InstrumentDefinition {
  return {
    underlyingContractSymbol: '',
    cqgInstrumentType: 0,
    maturityTimestamp: undefined,
    exchangeInstrumentType: '',
    settlementMethod: 0,
    strike: 0,
    exerciseStyle: 0,
    pricingConvention: 0,
  };
}

export const InstrumentDefinition = {
  encode(message: InstrumentDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.underlyingContractSymbol !== '') {
      writer.uint32(10).string(message.underlyingContractSymbol);
    }
    if (message.cqgInstrumentType !== 0) {
      writer.uint32(16).uint32(message.cqgInstrumentType);
    }
    if (message.maturityTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.maturityTimestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.exchangeInstrumentType !== '') {
      writer.uint32(34).string(message.exchangeInstrumentType);
    }
    if (message.settlementMethod !== 0) {
      writer.uint32(40).uint32(message.settlementMethod);
    }
    if (message.strike !== 0) {
      writer.uint32(49).double(message.strike);
    }
    if (message.exerciseStyle !== 0) {
      writer.uint32(56).uint32(message.exerciseStyle);
    }
    if (message.pricingConvention !== 0) {
      writer.uint32(64).uint32(message.pricingConvention);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.underlyingContractSymbol = reader.string();
          break;
        case 2:
          message.cqgInstrumentType = reader.uint32();
          break;
        case 3:
          message.maturityTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.exchangeInstrumentType = reader.string();
          break;
        case 5:
          message.settlementMethod = reader.uint32();
          break;
        case 6:
          message.strike = reader.double();
          break;
        case 7:
          message.exerciseStyle = reader.uint32();
          break;
        case 8:
          message.pricingConvention = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinition {
    return {
      underlyingContractSymbol: isSet(object.underlyingContractSymbol) ? String(object.underlyingContractSymbol) : '',
      cqgInstrumentType: isSet(object.cqgInstrumentType) ? Number(object.cqgInstrumentType) : 0,
      maturityTimestamp: isSet(object.maturityTimestamp) ? fromJsonTimestamp(object.maturityTimestamp) : undefined,
      exchangeInstrumentType: isSet(object.exchangeInstrumentType) ? String(object.exchangeInstrumentType) : '',
      settlementMethod: isSet(object.settlementMethod) ? Number(object.settlementMethod) : 0,
      strike: isSet(object.strike) ? Number(object.strike) : 0,
      exerciseStyle: isSet(object.exerciseStyle) ? Number(object.exerciseStyle) : 0,
      pricingConvention: isSet(object.pricingConvention) ? Number(object.pricingConvention) : 0,
    };
  },

  toJSON(message: InstrumentDefinition): unknown {
    const obj: any = {};
    message.underlyingContractSymbol !== undefined && (obj.underlyingContractSymbol = message.underlyingContractSymbol);
    message.cqgInstrumentType !== undefined && (obj.cqgInstrumentType = Math.round(message.cqgInstrumentType));
    message.maturityTimestamp !== undefined && (obj.maturityTimestamp = message.maturityTimestamp.toISOString());
    message.exchangeInstrumentType !== undefined && (obj.exchangeInstrumentType = message.exchangeInstrumentType);
    message.settlementMethod !== undefined && (obj.settlementMethod = Math.round(message.settlementMethod));
    message.strike !== undefined && (obj.strike = message.strike);
    message.exerciseStyle !== undefined && (obj.exerciseStyle = Math.round(message.exerciseStyle));
    message.pricingConvention !== undefined && (obj.pricingConvention = Math.round(message.pricingConvention));
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinition>, I>>(base?: I): InstrumentDefinition {
    return InstrumentDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentDefinition>, I>>(object: I): InstrumentDefinition {
    const message = createBaseInstrumentDefinition();
    message.underlyingContractSymbol = object.underlyingContractSymbol ?? '';
    message.cqgInstrumentType = object.cqgInstrumentType ?? 0;
    message.maturityTimestamp = object.maturityTimestamp ?? undefined;
    message.exchangeInstrumentType = object.exchangeInstrumentType ?? '';
    message.settlementMethod = object.settlementMethod ?? 0;
    message.strike = object.strike ?? 0;
    message.exerciseStyle = object.exerciseStyle ?? 0;
    message.pricingConvention = object.pricingConvention ?? 0;
    return message;
  },
};

function createBaseInstrumentDefinitionRequest(): InstrumentDefinitionRequest {
  return { instrumentDefinition: undefined, accountId: 0 };
}

export const InstrumentDefinitionRequest = {
  encode(message: InstrumentDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentDefinition !== undefined) {
      InstrumentDefinition.encode(message.instrumentDefinition, writer.uint32(10).fork()).ldelim();
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instrumentDefinition = InstrumentDefinition.decode(reader, reader.uint32());
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinitionRequest {
    return {
      instrumentDefinition: isSet(object.instrumentDefinition)
        ? InstrumentDefinition.fromJSON(object.instrumentDefinition)
        : undefined,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
    };
  },

  toJSON(message: InstrumentDefinitionRequest): unknown {
    const obj: any = {};
    message.instrumentDefinition !== undefined &&
      (obj.instrumentDefinition = message.instrumentDefinition
        ? InstrumentDefinition.toJSON(message.instrumentDefinition)
        : undefined);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinitionRequest>, I>>(base?: I): InstrumentDefinitionRequest {
    return InstrumentDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentDefinitionRequest>, I>>(object: I): InstrumentDefinitionRequest {
    const message = createBaseInstrumentDefinitionRequest();
    message.instrumentDefinition =
      object.instrumentDefinition !== undefined && object.instrumentDefinition !== null
        ? InstrumentDefinition.fromPartial(object.instrumentDefinition)
        : undefined;
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseInstrumentDefinitionReport(): InstrumentDefinitionReport {
  return { contractMetadata: undefined };
}

export const InstrumentDefinitionReport = {
  encode(message: InstrumentDefinitionReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentDefinitionReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentDefinitionReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentDefinitionReport {
    return {
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: InstrumentDefinitionReport): unknown {
    const obj: any = {};
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentDefinitionReport>, I>>(base?: I): InstrumentDefinitionReport {
    return InstrumentDefinitionReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentDefinitionReport>, I>>(object: I): InstrumentDefinitionReport {
    const message = createBaseInstrumentDefinitionReport();
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
