import { ADD_USER, EDIT_USER, UPDATE_USER, UPDATE_USERS_ROLE } from './constants';
import { User } from '../../types/User';
import { UserActionTypes } from './types';
import { IRole } from '../../types/IRole';

export default function user(state: UserWithRoles | null = null, action: UserActionTypes): UserWithRoles | null {
  switch (action.type) {
    case ADD_USER:
      return { ...state, ...action.payload };
    case UPDATE_USER: {
      const mergedUser = { ...state, ...action.payload };

      // backfill any missing fields from the original user
      if (state) {
        mergedUser.id = state.id;
        mergedUser.phoneNumber = state.phoneNumber;
        mergedUser.email = state.email;
        mergedUser.roles = state.roles;
      }

      return mergedUser as UserWithRoles;
    }
    case EDIT_USER:
      return { ...state, ...action.payload };
    case UPDATE_USERS_ROLE:
      return updateUsersRole(state, action.payload);
    default:
      return state;
  }
}

// hack to pass typechecks. React-query will handle role updates
type UserWithRoles = User & { roles?: IRole[] };

function updateUsersRole(state: UserWithRoles | null, updatedRole: IRole): UserWithRoles | null {
  const roles = state?.roles;
  const roleToUpdate = (roles || []).find((role) => updatedRole.id === role.id);
  if (roleToUpdate) {
    const newRoles = (roles || []).map((role) => (role.id === roleToUpdate.id ? updatedRole : { ...role }));
    return { ...state, roles: newRoles } as UserWithRoles;
  } else {
    return { ...state, roles: [...(roles || []), updatedRole] } as UserWithRoles;
  }
}
