export const getWebsocketAddress = () => process.env.CQG_ADDRESS || 'ws://localhost:3001';

export const getWebsocketPort = () => {
  const address = getWebsocketAddress();
  const splits = address.split(':');
  if (splits.length === 3) {
    return Number(splits[2]);
  }
  return 3001;
};

export const getClientAppId = () => 'FarmersRiskWAPI';

export const getPrivateLabel = () => 'FarmersRisk';

// EB-TODO: reconcile with 'RUN_CQG_compliance' in .env
export const isCQGComplianceTestRun = () => process.env.CQG_COMPLIANCE_TEST === 'true';
