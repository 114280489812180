/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../google/protobuf/timestamp';

export const protobufPackage = 'trading_account_2';

/** Request for a list of accounts this user is authorized to use and/ or monitor, empty message. */
export interface AccountsRequest {}

/**
 * Response with a list of authorized accounts grouped by brokerage firms and internal sales series (account groups),
 * the same account can not belong to more than one account group.
 */
export interface AccountsReport {
  /** List or brokerages with accounts the user works with. */
  brokerages: Brokerage[];
}

/** List of brokerage accounts. */
export interface Brokerage {
  /** Brokerage firm ID in CQG trade routing system. */
  id: number;
  /** Brokerage firm name. */
  name: string;
  /** This field is associated with BrokerageType enum type. */
  type: number;
  /** List of sales series. */
  salesSeries: SalesSeries[];
}

/** Brokerage type. */
export enum Brokerage_BrokerageType {
  /** BROKERAGE_TYPE_REGULAR - Regular. */
  BROKERAGE_TYPE_REGULAR = 1,
  /** BROKERAGE_TYPE_SIM - Simulation. */
  BROKERAGE_TYPE_SIM = 2,
  /** BROKERAGE_TYPE_DEMO - Demo. */
  BROKERAGE_TYPE_DEMO = 3,
  UNRECOGNIZED = -1,
}

export function brokerage_BrokerageTypeFromJSON(object: any): Brokerage_BrokerageType {
  switch (object) {
    case 1:
    case 'BROKERAGE_TYPE_REGULAR':
      return Brokerage_BrokerageType.BROKERAGE_TYPE_REGULAR;
    case 2:
    case 'BROKERAGE_TYPE_SIM':
      return Brokerage_BrokerageType.BROKERAGE_TYPE_SIM;
    case 3:
    case 'BROKERAGE_TYPE_DEMO':
      return Brokerage_BrokerageType.BROKERAGE_TYPE_DEMO;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Brokerage_BrokerageType.UNRECOGNIZED;
  }
}

export function brokerage_BrokerageTypeToJSON(object: Brokerage_BrokerageType): string {
  switch (object) {
    case Brokerage_BrokerageType.BROKERAGE_TYPE_REGULAR:
      return 'BROKERAGE_TYPE_REGULAR';
    case Brokerage_BrokerageType.BROKERAGE_TYPE_SIM:
      return 'BROKERAGE_TYPE_SIM';
    case Brokerage_BrokerageType.BROKERAGE_TYPE_DEMO:
      return 'BROKERAGE_TYPE_DEMO';
    case Brokerage_BrokerageType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** List of accounts in a sales series group. */
export interface SalesSeries {
  /** Sales series number in CQG trade routing system. */
  number: string;
  /** Sales series name. */
  name: string;
  /** List of accounts. */
  accounts: Account[];
}

/** Trade routing account data. */
export interface Account {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** Account ID in Brokerage Firm. */
  brokerageAccountId: string;
  /** Account name in CQG trade routing system. */
  name: string;
  /**
   * Last statement date for this account
   * (brokerage local date in time format, use date part only).
   */
  lastStatementDate: number;
  /** True if user can only monitor this account without trading. */
  isViewOnly: boolean;
  /** True if user was unauthorized and is not able to use/ see this account anymore. */
  isUnauthorized: boolean;
  /**
   * Current account connection status.
   * It is set for only accounts that require authorization.
   */
  accountConnectionStatus: number;
  /**
   * Account status last update time.
   * It is set for only accounts that require authorization.
   */
  accountConnectionStatusUtcTimestamp: Date | undefined;
  /** Optional list of authorized OTC contributors. */
  contributorIds: string[];
  /**
   * This flag shows if pre trade mid market mark should be calculated by client
   * and provided via order extra attribute "PreTradeMidMarketMark" during order placement or modification.
   */
  preTradeMidMarketMarkRequired: boolean;
  /**
   * This flag shows if current account can be used to place orders into external accounts.
   * See order_2.Order.external_account_number.
   */
  allowExternalAccounts: boolean;
  /** True if it is omnibus account. */
  isOmnibus: boolean;
  /** True if it is a group account member. This field and fields dependent on it are not updated until re-logon. */
  isGroupMember: boolean;
  /** If true, all orders for the account will be placed as care orders. */
  forceCareOrders: boolean;
  /** True if this account supports back office statements, whether received from brokerage or generated by CQG. */
  supportsStatements: boolean;
  /**
   * True if this account supports subscriptions on exchange balances.
   * (see trade_routing_2.TradeSubscription.SUBSCRIPTION_SCOPE_EXCHANGE_BALANCES)
   */
  supportsExchangeBalancesSubscription: boolean;
  /**
   * True if this account supports subscriptions on exchange positions.
   * (see trade_routing_2.TradeSubscription.SUBSCRIPTION_SCOPE_EXCHANGE_POSITIONS)
   */
  supportsExchangePositionsSubscription: boolean;
}

/**
 * Account connection status list.
 * It is used for accounts that require additional authorization.
 */
export enum Account_AccountConnectionStatus {
  /** ACCOUNT_CONNECTION_STATUS_OFFLINE - Route is offline, connection is impossible. */
  ACCOUNT_CONNECTION_STATUS_OFFLINE = 1,
  /** ACCOUNT_CONNECTION_STATUS_DISCONNECTED - Connection is not established but can be initiated by a logon message. */
  ACCOUNT_CONNECTION_STATUS_DISCONNECTED = 2,
  /** ACCOUNT_CONNECTION_STATUS_CONNECTING - Connection is initiated but is not fully established. */
  ACCOUNT_CONNECTION_STATUS_CONNECTING = 3,
  /** ACCOUNT_CONNECTION_STATUS_CONNECTED - Connection is established, trading is possible. */
  ACCOUNT_CONNECTION_STATUS_CONNECTED = 4,
  UNRECOGNIZED = -1,
}

export function account_AccountConnectionStatusFromJSON(object: any): Account_AccountConnectionStatus {
  switch (object) {
    case 1:
    case 'ACCOUNT_CONNECTION_STATUS_OFFLINE':
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_OFFLINE;
    case 2:
    case 'ACCOUNT_CONNECTION_STATUS_DISCONNECTED':
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_DISCONNECTED;
    case 3:
    case 'ACCOUNT_CONNECTION_STATUS_CONNECTING':
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTING;
    case 4:
    case 'ACCOUNT_CONNECTION_STATUS_CONNECTED':
      return Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Account_AccountConnectionStatus.UNRECOGNIZED;
  }
}

export function account_AccountConnectionStatusToJSON(object: Account_AccountConnectionStatus): string {
  switch (object) {
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_OFFLINE:
      return 'ACCOUNT_CONNECTION_STATUS_OFFLINE';
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_DISCONNECTED:
      return 'ACCOUNT_CONNECTION_STATUS_DISCONNECTED';
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTING:
      return 'ACCOUNT_CONNECTION_STATUS_CONNECTING';
    case Account_AccountConnectionStatus.ACCOUNT_CONNECTION_STATUS_CONNECTED:
      return 'ACCOUNT_CONNECTION_STATUS_CONNECTED';
    case Account_AccountConnectionStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for balances from the last statement for all authorized accounts. */
export interface LastStatementBalancesRequest {}

/**
 * Report with balances from the last statement for all authorized accounts
 * with Account.supports_statements=True flag set.
 * Will be sent as a response to a corresponding request
 * and after each statement processing (if subscribed).
 * Each initial report contains a full snapshot of all balances on all accounts.
 * Each report for an active subscription contains updates only.
 */
export interface LastStatementBalancesReport {
  balances: Balance[];
}

/**
 * Balance record for one of the accounts and one of the currencies (id is used as a key for updates).
 * Values like OTE, UPL and MVO are from statements and won't be updated in real time but after each next statement.
 * Money related values are in the specified currency.
 * NOTE: Not all values for a balance record are provided by all statements,
 *       it is up to Brokerage to include such values and only
 *       currency and ending cash balance are required to be valid.
 */
export interface Balance {
  /** Surrogate id as a key for updates. */
  id: number;
  /** Account of this balance record. */
  accountId: number;
  /** Statement date this positions is related. */
  statementDate: number;
  /** Currency code. */
  currency: string;
  /** Ending Cash Balance. */
  balance: number;
  /** Total Account Value. */
  totalValue: number;
  /** Open Trade Equity for Futures and futures-style Options. */
  ote: number;
  /** Unrealized Profit and Loss for premium-style Options and Fixed Income. */
  upl: number;
  /** Market Value of premium-style Options and Fixed Income. */
  mvo: number;
  /** Cash Excess. */
  cashExcess: number;
  /** Collateral on Deposit. */
  collateral: number;
  /** Initial Margin Requirements. */
  initialMargin: number;
  /** If true the balance should be removed from the balances snapshot. */
  deleted: boolean;
}

/** Request for currency rates for all brokerages user's accounts belong to. */
export interface CurrencyRatesRequest {}

/**
 * Report with currency rates per brokerage.
 * It will be sent as a response to a corresponding request
 * and after each statement processing (if subscribed).
 */
export interface CurrencyRatesReport {
  /** List of rates per brokerage the user is authorized to. */
  brokerageCurrencyRates: BrokerageCurrencyRates[];
}

/** Currency rates of one brokerage. */
export interface BrokerageCurrencyRates {
  /** Brokerage ID. */
  brokerageId: number;
  /** Master currency code to which rates are provided. */
  masterCurrency: string;
  /** Statement date. */
  statementDate: number;
  /** List of brokerage rates. */
  currencyRates: CurrencyRate[];
}

/** Rate of one currency to a master currency for the brokerage. */
export interface CurrencyRate {
  /** Currency code. */
  currency: string;
  /** Exchange rate. */
  rate: number;
  /** Number of decimals in 'rate' field. */
  decimalPlaces: number;
}

/** Request for currency rate source contracts for all brokerages user's accounts belong to. */
export interface CurrencyRateSourcesRequest {}

/**
 * Report with currency rate sources per brokerage.
 * Note: in case of an update, report may not include sources for all brokerages.
 */
export interface CurrencyRateSourcesReport {
  /** List of rate sources per brokerage the user is authorized to. */
  brokerageRateSources: BrokerageCurrencyRateSources[];
}

/** Currency rate sources of one brokerage. */
export interface BrokerageCurrencyRateSources {
  /**
   * Brokerage ID.
   * See AccountsRequest and Brokerage messages.
   * It is a required field.
   */
  brokerageId: number;
  /**
   * List of brokerage rate sources.
   * In case of an update contains full list of sources.
   */
  currencyRateSources: CurrencyRateSource[];
}

export interface CurrencyRateSource {
  /**
   * Base currency code (ISO-4217-based).
   * Note that cryptocurrencies use codes that are ISO-4217-style, though usually not actual
   * ISO codes (e.g. can be longer than 3 characters).
   * It is a required field.
   */
  baseCurrency: string;
  /**
   * Quote currency code (ISO-4217-based).
   * Note that cryptocurrencies use codes that are ISO-4217-style, though usually not actual
   * ISO codes (e.g. can be longer than 3 characters).
   * It is a required field.
   */
  quoteCurrency: string;
  /**
   * Realtime rate source contract for these base and quote currencies.
   * E.g. for "X.US.USDJPY", "USD" is the base currency and "JPY" is the quote currency.
   * Might be omitted if const_currency_rate has a value.
   */
  rateSourceContractId: number;
  /**
   * Constant currency rate. If the field is not empty use it instead of subscribing
   * to rate_source_contract_id.
   */
  constCurrencyRate: number;
}

function createBaseAccountsRequest(): AccountsRequest {
  return {};
}

export const AccountsRequest = {
  encode(_: AccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AccountsRequest {
    return {};
  },

  toJSON(_: AccountsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountsRequest>, I>>(base?: I): AccountsRequest {
    return AccountsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountsRequest>, I>>(_: I): AccountsRequest {
    const message = createBaseAccountsRequest();
    return message;
  },
};

function createBaseAccountsReport(): AccountsReport {
  return { brokerages: [] };
}

export const AccountsReport = {
  encode(message: AccountsReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerages) {
      Brokerage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountsReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountsReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brokerages.push(Brokerage.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountsReport {
    return {
      brokerages: Array.isArray(object?.brokerages) ? object.brokerages.map((e: any) => Brokerage.fromJSON(e)) : [],
    };
  },

  toJSON(message: AccountsReport): unknown {
    const obj: any = {};
    if (message.brokerages) {
      obj.brokerages = message.brokerages.map((e) => (e ? Brokerage.toJSON(e) : undefined));
    } else {
      obj.brokerages = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountsReport>, I>>(base?: I): AccountsReport {
    return AccountsReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountsReport>, I>>(object: I): AccountsReport {
    const message = createBaseAccountsReport();
    message.brokerages = object.brokerages?.map((e) => Brokerage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBrokerage(): Brokerage {
  return { id: 0, name: '', type: 0, salesSeries: [] };
}

export const Brokerage = {
  encode(message: Brokerage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.type !== 0) {
      writer.uint32(32).uint32(message.type);
    }
    for (const v of message.salesSeries) {
      SalesSeries.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Brokerage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 4:
          message.type = reader.uint32();
          break;
        case 3:
          message.salesSeries.push(SalesSeries.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Brokerage {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? Number(object.type) : 0,
      salesSeries: Array.isArray(object?.salesSeries) ? object.salesSeries.map((e: any) => SalesSeries.fromJSON(e)) : [],
    };
  },

  toJSON(message: Brokerage): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = Math.round(message.type));
    if (message.salesSeries) {
      obj.salesSeries = message.salesSeries.map((e) => (e ? SalesSeries.toJSON(e) : undefined));
    } else {
      obj.salesSeries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Brokerage>, I>>(base?: I): Brokerage {
    return Brokerage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Brokerage>, I>>(object: I): Brokerage {
    const message = createBaseBrokerage();
    message.id = object.id ?? 0;
    message.name = object.name ?? '';
    message.type = object.type ?? 0;
    message.salesSeries = object.salesSeries?.map((e) => SalesSeries.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSalesSeries(): SalesSeries {
  return { number: '', name: '', accounts: [] };
}

export const SalesSeries = {
  encode(message: SalesSeries, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== '') {
      writer.uint32(10).string(message.number);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.accounts) {
      Account.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeries {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeries();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.number = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.accounts.push(Account.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SalesSeries {
    return {
      number: isSet(object.number) ? String(object.number) : '',
      name: isSet(object.name) ? String(object.name) : '',
      accounts: Array.isArray(object?.accounts) ? object.accounts.map((e: any) => Account.fromJSON(e)) : [],
    };
  },

  toJSON(message: SalesSeries): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = message.number);
    message.name !== undefined && (obj.name = message.name);
    if (message.accounts) {
      obj.accounts = message.accounts.map((e) => (e ? Account.toJSON(e) : undefined));
    } else {
      obj.accounts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeries>, I>>(base?: I): SalesSeries {
    return SalesSeries.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SalesSeries>, I>>(object: I): SalesSeries {
    const message = createBaseSalesSeries();
    message.number = object.number ?? '';
    message.name = object.name ?? '';
    message.accounts = object.accounts?.map((e) => Account.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccount(): Account {
  return {
    accountId: 0,
    brokerageAccountId: '',
    name: '',
    lastStatementDate: 0,
    isViewOnly: false,
    isUnauthorized: false,
    accountConnectionStatus: 0,
    accountConnectionStatusUtcTimestamp: undefined,
    contributorIds: [],
    preTradeMidMarketMarkRequired: false,
    allowExternalAccounts: false,
    isOmnibus: false,
    isGroupMember: false,
    forceCareOrders: false,
    supportsStatements: false,
    supportsExchangeBalancesSubscription: false,
    supportsExchangePositionsSubscription: false,
  };
}

export const Account = {
  encode(message: Account, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.brokerageAccountId !== '') {
      writer.uint32(18).string(message.brokerageAccountId);
    }
    if (message.name !== '') {
      writer.uint32(26).string(message.name);
    }
    if (message.lastStatementDate !== 0) {
      writer.uint32(32).sint64(message.lastStatementDate);
    }
    if (message.isViewOnly === true) {
      writer.uint32(40).bool(message.isViewOnly);
    }
    if (message.isUnauthorized === true) {
      writer.uint32(48).bool(message.isUnauthorized);
    }
    if (message.accountConnectionStatus !== 0) {
      writer.uint32(64).uint32(message.accountConnectionStatus);
    }
    if (message.accountConnectionStatusUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.accountConnectionStatusUtcTimestamp), writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.contributorIds) {
      writer.uint32(82).string(v!);
    }
    if (message.preTradeMidMarketMarkRequired === true) {
      writer.uint32(88).bool(message.preTradeMidMarketMarkRequired);
    }
    if (message.allowExternalAccounts === true) {
      writer.uint32(96).bool(message.allowExternalAccounts);
    }
    if (message.isOmnibus === true) {
      writer.uint32(104).bool(message.isOmnibus);
    }
    if (message.isGroupMember === true) {
      writer.uint32(112).bool(message.isGroupMember);
    }
    if (message.forceCareOrders === true) {
      writer.uint32(120).bool(message.forceCareOrders);
    }
    if (message.supportsStatements === true) {
      writer.uint32(128).bool(message.supportsStatements);
    }
    if (message.supportsExchangeBalancesSubscription === true) {
      writer.uint32(136).bool(message.supportsExchangeBalancesSubscription);
    }
    if (message.supportsExchangePositionsSubscription === true) {
      writer.uint32(144).bool(message.supportsExchangePositionsSubscription);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.brokerageAccountId = reader.string();
          break;
        case 3:
          message.name = reader.string();
          break;
        case 4:
          message.lastStatementDate = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.isViewOnly = reader.bool();
          break;
        case 6:
          message.isUnauthorized = reader.bool();
          break;
        case 8:
          message.accountConnectionStatus = reader.uint32();
          break;
        case 9:
          message.accountConnectionStatusUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 10:
          message.contributorIds.push(reader.string());
          break;
        case 11:
          message.preTradeMidMarketMarkRequired = reader.bool();
          break;
        case 12:
          message.allowExternalAccounts = reader.bool();
          break;
        case 13:
          message.isOmnibus = reader.bool();
          break;
        case 14:
          message.isGroupMember = reader.bool();
          break;
        case 15:
          message.forceCareOrders = reader.bool();
          break;
        case 16:
          message.supportsStatements = reader.bool();
          break;
        case 17:
          message.supportsExchangeBalancesSubscription = reader.bool();
          break;
        case 18:
          message.supportsExchangePositionsSubscription = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      brokerageAccountId: isSet(object.brokerageAccountId) ? String(object.brokerageAccountId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      lastStatementDate: isSet(object.lastStatementDate) ? Number(object.lastStatementDate) : 0,
      isViewOnly: isSet(object.isViewOnly) ? Boolean(object.isViewOnly) : false,
      isUnauthorized: isSet(object.isUnauthorized) ? Boolean(object.isUnauthorized) : false,
      accountConnectionStatus: isSet(object.accountConnectionStatus) ? Number(object.accountConnectionStatus) : 0,
      accountConnectionStatusUtcTimestamp: isSet(object.accountConnectionStatusUtcTimestamp)
        ? fromJsonTimestamp(object.accountConnectionStatusUtcTimestamp)
        : undefined,
      contributorIds: Array.isArray(object?.contributorIds) ? object.contributorIds.map((e: any) => String(e)) : [],
      preTradeMidMarketMarkRequired: isSet(object.preTradeMidMarketMarkRequired)
        ? Boolean(object.preTradeMidMarketMarkRequired)
        : false,
      allowExternalAccounts: isSet(object.allowExternalAccounts) ? Boolean(object.allowExternalAccounts) : false,
      isOmnibus: isSet(object.isOmnibus) ? Boolean(object.isOmnibus) : false,
      isGroupMember: isSet(object.isGroupMember) ? Boolean(object.isGroupMember) : false,
      forceCareOrders: isSet(object.forceCareOrders) ? Boolean(object.forceCareOrders) : false,
      supportsStatements: isSet(object.supportsStatements) ? Boolean(object.supportsStatements) : false,
      supportsExchangeBalancesSubscription: isSet(object.supportsExchangeBalancesSubscription)
        ? Boolean(object.supportsExchangeBalancesSubscription)
        : false,
      supportsExchangePositionsSubscription: isSet(object.supportsExchangePositionsSubscription)
        ? Boolean(object.supportsExchangePositionsSubscription)
        : false,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.brokerageAccountId !== undefined && (obj.brokerageAccountId = message.brokerageAccountId);
    message.name !== undefined && (obj.name = message.name);
    message.lastStatementDate !== undefined && (obj.lastStatementDate = Math.round(message.lastStatementDate));
    message.isViewOnly !== undefined && (obj.isViewOnly = message.isViewOnly);
    message.isUnauthorized !== undefined && (obj.isUnauthorized = message.isUnauthorized);
    message.accountConnectionStatus !== undefined && (obj.accountConnectionStatus = Math.round(message.accountConnectionStatus));
    message.accountConnectionStatusUtcTimestamp !== undefined &&
      (obj.accountConnectionStatusUtcTimestamp = message.accountConnectionStatusUtcTimestamp.toISOString());
    if (message.contributorIds) {
      obj.contributorIds = message.contributorIds.map((e) => e);
    } else {
      obj.contributorIds = [];
    }
    message.preTradeMidMarketMarkRequired !== undefined &&
      (obj.preTradeMidMarketMarkRequired = message.preTradeMidMarketMarkRequired);
    message.allowExternalAccounts !== undefined && (obj.allowExternalAccounts = message.allowExternalAccounts);
    message.isOmnibus !== undefined && (obj.isOmnibus = message.isOmnibus);
    message.isGroupMember !== undefined && (obj.isGroupMember = message.isGroupMember);
    message.forceCareOrders !== undefined && (obj.forceCareOrders = message.forceCareOrders);
    message.supportsStatements !== undefined && (obj.supportsStatements = message.supportsStatements);
    message.supportsExchangeBalancesSubscription !== undefined &&
      (obj.supportsExchangeBalancesSubscription = message.supportsExchangeBalancesSubscription);
    message.supportsExchangePositionsSubscription !== undefined &&
      (obj.supportsExchangePositionsSubscription = message.supportsExchangePositionsSubscription);
    return obj;
  },

  create<I extends Exact<DeepPartial<Account>, I>>(base?: I): Account {
    return Account.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.accountId = object.accountId ?? 0;
    message.brokerageAccountId = object.brokerageAccountId ?? '';
    message.name = object.name ?? '';
    message.lastStatementDate = object.lastStatementDate ?? 0;
    message.isViewOnly = object.isViewOnly ?? false;
    message.isUnauthorized = object.isUnauthorized ?? false;
    message.accountConnectionStatus = object.accountConnectionStatus ?? 0;
    message.accountConnectionStatusUtcTimestamp = object.accountConnectionStatusUtcTimestamp ?? undefined;
    message.contributorIds = object.contributorIds?.map((e) => e) || [];
    message.preTradeMidMarketMarkRequired = object.preTradeMidMarketMarkRequired ?? false;
    message.allowExternalAccounts = object.allowExternalAccounts ?? false;
    message.isOmnibus = object.isOmnibus ?? false;
    message.isGroupMember = object.isGroupMember ?? false;
    message.forceCareOrders = object.forceCareOrders ?? false;
    message.supportsStatements = object.supportsStatements ?? false;
    message.supportsExchangeBalancesSubscription = object.supportsExchangeBalancesSubscription ?? false;
    message.supportsExchangePositionsSubscription = object.supportsExchangePositionsSubscription ?? false;
    return message;
  },
};

function createBaseLastStatementBalancesRequest(): LastStatementBalancesRequest {
  return {};
}

export const LastStatementBalancesRequest = {
  encode(_: LastStatementBalancesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LastStatementBalancesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastStatementBalancesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): LastStatementBalancesRequest {
    return {};
  },

  toJSON(_: LastStatementBalancesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<LastStatementBalancesRequest>, I>>(base?: I): LastStatementBalancesRequest {
    return LastStatementBalancesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LastStatementBalancesRequest>, I>>(_: I): LastStatementBalancesRequest {
    const message = createBaseLastStatementBalancesRequest();
    return message;
  },
};

function createBaseLastStatementBalancesReport(): LastStatementBalancesReport {
  return { balances: [] };
}

export const LastStatementBalancesReport = {
  encode(message: LastStatementBalancesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.balances) {
      Balance.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LastStatementBalancesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastStatementBalancesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.balances.push(Balance.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LastStatementBalancesReport {
    return { balances: Array.isArray(object?.balances) ? object.balances.map((e: any) => Balance.fromJSON(e)) : [] };
  },

  toJSON(message: LastStatementBalancesReport): unknown {
    const obj: any = {};
    if (message.balances) {
      obj.balances = message.balances.map((e) => (e ? Balance.toJSON(e) : undefined));
    } else {
      obj.balances = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LastStatementBalancesReport>, I>>(base?: I): LastStatementBalancesReport {
    return LastStatementBalancesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LastStatementBalancesReport>, I>>(object: I): LastStatementBalancesReport {
    const message = createBaseLastStatementBalancesReport();
    message.balances = object.balances?.map((e) => Balance.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBalance(): Balance {
  return {
    id: 0,
    accountId: 0,
    statementDate: 0,
    currency: '',
    balance: 0,
    totalValue: 0,
    ote: 0,
    upl: 0,
    mvo: 0,
    cashExcess: 0,
    collateral: 0,
    initialMargin: 0,
    deleted: false,
  };
}

export const Balance = {
  encode(message: Balance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    if (message.currency !== '') {
      writer.uint32(34).string(message.currency);
    }
    if (message.balance !== 0) {
      writer.uint32(41).double(message.balance);
    }
    if (message.totalValue !== 0) {
      writer.uint32(49).double(message.totalValue);
    }
    if (message.ote !== 0) {
      writer.uint32(57).double(message.ote);
    }
    if (message.upl !== 0) {
      writer.uint32(65).double(message.upl);
    }
    if (message.mvo !== 0) {
      writer.uint32(73).double(message.mvo);
    }
    if (message.cashExcess !== 0) {
      writer.uint32(81).double(message.cashExcess);
    }
    if (message.collateral !== 0) {
      writer.uint32(89).double(message.collateral);
    }
    if (message.initialMargin !== 0) {
      writer.uint32(97).double(message.initialMargin);
    }
    if (message.deleted === true) {
      writer.uint32(104).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Balance {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.sint32();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.statementDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.currency = reader.string();
          break;
        case 5:
          message.balance = reader.double();
          break;
        case 6:
          message.totalValue = reader.double();
          break;
        case 7:
          message.ote = reader.double();
          break;
        case 8:
          message.upl = reader.double();
          break;
        case 9:
          message.mvo = reader.double();
          break;
        case 10:
          message.cashExcess = reader.double();
          break;
        case 11:
          message.collateral = reader.double();
          break;
        case 12:
          message.initialMargin = reader.double();
          break;
        case 13:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Balance {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      statementDate: isSet(object.statementDate) ? Number(object.statementDate) : 0,
      currency: isSet(object.currency) ? String(object.currency) : '',
      balance: isSet(object.balance) ? Number(object.balance) : 0,
      totalValue: isSet(object.totalValue) ? Number(object.totalValue) : 0,
      ote: isSet(object.ote) ? Number(object.ote) : 0,
      upl: isSet(object.upl) ? Number(object.upl) : 0,
      mvo: isSet(object.mvo) ? Number(object.mvo) : 0,
      cashExcess: isSet(object.cashExcess) ? Number(object.cashExcess) : 0,
      collateral: isSet(object.collateral) ? Number(object.collateral) : 0,
      initialMargin: isSet(object.initialMargin) ? Number(object.initialMargin) : 0,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: Balance): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.statementDate !== undefined && (obj.statementDate = Math.round(message.statementDate));
    message.currency !== undefined && (obj.currency = message.currency);
    message.balance !== undefined && (obj.balance = message.balance);
    message.totalValue !== undefined && (obj.totalValue = message.totalValue);
    message.ote !== undefined && (obj.ote = message.ote);
    message.upl !== undefined && (obj.upl = message.upl);
    message.mvo !== undefined && (obj.mvo = message.mvo);
    message.cashExcess !== undefined && (obj.cashExcess = message.cashExcess);
    message.collateral !== undefined && (obj.collateral = message.collateral);
    message.initialMargin !== undefined && (obj.initialMargin = message.initialMargin);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<Balance>, I>>(base?: I): Balance {
    return Balance.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Balance>, I>>(object: I): Balance {
    const message = createBaseBalance();
    message.id = object.id ?? 0;
    message.accountId = object.accountId ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.currency = object.currency ?? '';
    message.balance = object.balance ?? 0;
    message.totalValue = object.totalValue ?? 0;
    message.ote = object.ote ?? 0;
    message.upl = object.upl ?? 0;
    message.mvo = object.mvo ?? 0;
    message.cashExcess = object.cashExcess ?? 0;
    message.collateral = object.collateral ?? 0;
    message.initialMargin = object.initialMargin ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseCurrencyRatesRequest(): CurrencyRatesRequest {
  return {};
}

export const CurrencyRatesRequest = {
  encode(_: CurrencyRatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CurrencyRatesRequest {
    return {};
  },

  toJSON(_: CurrencyRatesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRatesRequest>, I>>(base?: I): CurrencyRatesRequest {
    return CurrencyRatesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRatesRequest>, I>>(_: I): CurrencyRatesRequest {
    const message = createBaseCurrencyRatesRequest();
    return message;
  },
};

function createBaseCurrencyRatesReport(): CurrencyRatesReport {
  return { brokerageCurrencyRates: [] };
}

export const CurrencyRatesReport = {
  encode(message: CurrencyRatesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerageCurrencyRates) {
      BrokerageCurrencyRates.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRatesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRatesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brokerageCurrencyRates.push(BrokerageCurrencyRates.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CurrencyRatesReport {
    return {
      brokerageCurrencyRates: Array.isArray(object?.brokerageCurrencyRates)
        ? object.brokerageCurrencyRates.map((e: any) => BrokerageCurrencyRates.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CurrencyRatesReport): unknown {
    const obj: any = {};
    if (message.brokerageCurrencyRates) {
      obj.brokerageCurrencyRates = message.brokerageCurrencyRates.map((e) => (e ? BrokerageCurrencyRates.toJSON(e) : undefined));
    } else {
      obj.brokerageCurrencyRates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRatesReport>, I>>(base?: I): CurrencyRatesReport {
    return CurrencyRatesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRatesReport>, I>>(object: I): CurrencyRatesReport {
    const message = createBaseCurrencyRatesReport();
    message.brokerageCurrencyRates = object.brokerageCurrencyRates?.map((e) => BrokerageCurrencyRates.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBrokerageCurrencyRates(): BrokerageCurrencyRates {
  return { brokerageId: 0, masterCurrency: '', statementDate: 0, currencyRates: [] };
}

export const BrokerageCurrencyRates = {
  encode(message: BrokerageCurrencyRates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerageId !== 0) {
      writer.uint32(8).uint32(message.brokerageId);
    }
    if (message.masterCurrency !== '') {
      writer.uint32(18).string(message.masterCurrency);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    for (const v of message.currencyRates) {
      CurrencyRate.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerageCurrencyRates {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerageCurrencyRates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brokerageId = reader.uint32();
          break;
        case 2:
          message.masterCurrency = reader.string();
          break;
        case 3:
          message.statementDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.currencyRates.push(CurrencyRate.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BrokerageCurrencyRates {
    return {
      brokerageId: isSet(object.brokerageId) ? Number(object.brokerageId) : 0,
      masterCurrency: isSet(object.masterCurrency) ? String(object.masterCurrency) : '',
      statementDate: isSet(object.statementDate) ? Number(object.statementDate) : 0,
      currencyRates: Array.isArray(object?.currencyRates) ? object.currencyRates.map((e: any) => CurrencyRate.fromJSON(e)) : [],
    };
  },

  toJSON(message: BrokerageCurrencyRates): unknown {
    const obj: any = {};
    message.brokerageId !== undefined && (obj.brokerageId = Math.round(message.brokerageId));
    message.masterCurrency !== undefined && (obj.masterCurrency = message.masterCurrency);
    message.statementDate !== undefined && (obj.statementDate = Math.round(message.statementDate));
    if (message.currencyRates) {
      obj.currencyRates = message.currencyRates.map((e) => (e ? CurrencyRate.toJSON(e) : undefined));
    } else {
      obj.currencyRates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageCurrencyRates>, I>>(base?: I): BrokerageCurrencyRates {
    return BrokerageCurrencyRates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BrokerageCurrencyRates>, I>>(object: I): BrokerageCurrencyRates {
    const message = createBaseBrokerageCurrencyRates();
    message.brokerageId = object.brokerageId ?? 0;
    message.masterCurrency = object.masterCurrency ?? '';
    message.statementDate = object.statementDate ?? 0;
    message.currencyRates = object.currencyRates?.map((e) => CurrencyRate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCurrencyRate(): CurrencyRate {
  return { currency: '', rate: 0, decimalPlaces: 0 };
}

export const CurrencyRate = {
  encode(message: CurrencyRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== '') {
      writer.uint32(18).string(message.currency);
    }
    if (message.rate !== 0) {
      writer.uint32(25).double(message.rate);
    }
    if (message.decimalPlaces !== 0) {
      writer.uint32(32).uint32(message.decimalPlaces);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.currency = reader.string();
          break;
        case 3:
          message.rate = reader.double();
          break;
        case 4:
          message.decimalPlaces = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CurrencyRate {
    return {
      currency: isSet(object.currency) ? String(object.currency) : '',
      rate: isSet(object.rate) ? Number(object.rate) : 0,
      decimalPlaces: isSet(object.decimalPlaces) ? Number(object.decimalPlaces) : 0,
    };
  },

  toJSON(message: CurrencyRate): unknown {
    const obj: any = {};
    message.currency !== undefined && (obj.currency = message.currency);
    message.rate !== undefined && (obj.rate = message.rate);
    message.decimalPlaces !== undefined && (obj.decimalPlaces = Math.round(message.decimalPlaces));
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRate>, I>>(base?: I): CurrencyRate {
    return CurrencyRate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRate>, I>>(object: I): CurrencyRate {
    const message = createBaseCurrencyRate();
    message.currency = object.currency ?? '';
    message.rate = object.rate ?? 0;
    message.decimalPlaces = object.decimalPlaces ?? 0;
    return message;
  },
};

function createBaseCurrencyRateSourcesRequest(): CurrencyRateSourcesRequest {
  return {};
}

export const CurrencyRateSourcesRequest = {
  encode(_: CurrencyRateSourcesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRateSourcesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRateSourcesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CurrencyRateSourcesRequest {
    return {};
  },

  toJSON(_: CurrencyRateSourcesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRateSourcesRequest>, I>>(base?: I): CurrencyRateSourcesRequest {
    return CurrencyRateSourcesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRateSourcesRequest>, I>>(_: I): CurrencyRateSourcesRequest {
    const message = createBaseCurrencyRateSourcesRequest();
    return message;
  },
};

function createBaseCurrencyRateSourcesReport(): CurrencyRateSourcesReport {
  return { brokerageRateSources: [] };
}

export const CurrencyRateSourcesReport = {
  encode(message: CurrencyRateSourcesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerageRateSources) {
      BrokerageCurrencyRateSources.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRateSourcesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRateSourcesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brokerageRateSources.push(BrokerageCurrencyRateSources.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CurrencyRateSourcesReport {
    return {
      brokerageRateSources: Array.isArray(object?.brokerageRateSources)
        ? object.brokerageRateSources.map((e: any) => BrokerageCurrencyRateSources.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CurrencyRateSourcesReport): unknown {
    const obj: any = {};
    if (message.brokerageRateSources) {
      obj.brokerageRateSources = message.brokerageRateSources.map((e) =>
        e ? BrokerageCurrencyRateSources.toJSON(e) : undefined
      );
    } else {
      obj.brokerageRateSources = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRateSourcesReport>, I>>(base?: I): CurrencyRateSourcesReport {
    return CurrencyRateSourcesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRateSourcesReport>, I>>(object: I): CurrencyRateSourcesReport {
    const message = createBaseCurrencyRateSourcesReport();
    message.brokerageRateSources = object.brokerageRateSources?.map((e) => BrokerageCurrencyRateSources.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBrokerageCurrencyRateSources(): BrokerageCurrencyRateSources {
  return { brokerageId: 0, currencyRateSources: [] };
}

export const BrokerageCurrencyRateSources = {
  encode(message: BrokerageCurrencyRateSources, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerageId !== 0) {
      writer.uint32(8).uint32(message.brokerageId);
    }
    for (const v of message.currencyRateSources) {
      CurrencyRateSource.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BrokerageCurrencyRateSources {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrokerageCurrencyRateSources();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.brokerageId = reader.uint32();
          break;
        case 2:
          message.currencyRateSources.push(CurrencyRateSource.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BrokerageCurrencyRateSources {
    return {
      brokerageId: isSet(object.brokerageId) ? Number(object.brokerageId) : 0,
      currencyRateSources: Array.isArray(object?.currencyRateSources)
        ? object.currencyRateSources.map((e: any) => CurrencyRateSource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BrokerageCurrencyRateSources): unknown {
    const obj: any = {};
    message.brokerageId !== undefined && (obj.brokerageId = Math.round(message.brokerageId));
    if (message.currencyRateSources) {
      obj.currencyRateSources = message.currencyRateSources.map((e) => (e ? CurrencyRateSource.toJSON(e) : undefined));
    } else {
      obj.currencyRateSources = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BrokerageCurrencyRateSources>, I>>(base?: I): BrokerageCurrencyRateSources {
    return BrokerageCurrencyRateSources.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BrokerageCurrencyRateSources>, I>>(object: I): BrokerageCurrencyRateSources {
    const message = createBaseBrokerageCurrencyRateSources();
    message.brokerageId = object.brokerageId ?? 0;
    message.currencyRateSources = object.currencyRateSources?.map((e) => CurrencyRateSource.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCurrencyRateSource(): CurrencyRateSource {
  return { baseCurrency: '', quoteCurrency: '', rateSourceContractId: 0, constCurrencyRate: 0 };
}

export const CurrencyRateSource = {
  encode(message: CurrencyRateSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseCurrency !== '') {
      writer.uint32(10).string(message.baseCurrency);
    }
    if (message.quoteCurrency !== '') {
      writer.uint32(18).string(message.quoteCurrency);
    }
    if (message.rateSourceContractId !== 0) {
      writer.uint32(24).uint32(message.rateSourceContractId);
    }
    if (message.constCurrencyRate !== 0) {
      writer.uint32(33).double(message.constCurrencyRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyRateSource {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyRateSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.baseCurrency = reader.string();
          break;
        case 2:
          message.quoteCurrency = reader.string();
          break;
        case 3:
          message.rateSourceContractId = reader.uint32();
          break;
        case 4:
          message.constCurrencyRate = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CurrencyRateSource {
    return {
      baseCurrency: isSet(object.baseCurrency) ? String(object.baseCurrency) : '',
      quoteCurrency: isSet(object.quoteCurrency) ? String(object.quoteCurrency) : '',
      rateSourceContractId: isSet(object.rateSourceContractId) ? Number(object.rateSourceContractId) : 0,
      constCurrencyRate: isSet(object.constCurrencyRate) ? Number(object.constCurrencyRate) : 0,
    };
  },

  toJSON(message: CurrencyRateSource): unknown {
    const obj: any = {};
    message.baseCurrency !== undefined && (obj.baseCurrency = message.baseCurrency);
    message.quoteCurrency !== undefined && (obj.quoteCurrency = message.quoteCurrency);
    message.rateSourceContractId !== undefined && (obj.rateSourceContractId = Math.round(message.rateSourceContractId));
    message.constCurrencyRate !== undefined && (obj.constCurrencyRate = message.constCurrencyRate);
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyRateSource>, I>>(base?: I): CurrencyRateSource {
    return CurrencyRateSource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CurrencyRateSource>, I>>(object: I): CurrencyRateSource {
    const message = createBaseCurrencyRateSource();
    message.baseCurrency = object.baseCurrency ?? '';
    message.quoteCurrency = object.quoteCurrency ?? '';
    message.rateSourceContractId = object.rateSourceContractId ?? 0;
    message.constCurrencyRate = object.constCurrencyRate ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
