// TODO: this needs to be improved to better handle variety of crops ... or just move away from global production cycle

import { orderBy } from 'lodash';
import { IOperationCropV2 } from '../types/IOperationCrops';
import dayjs from '../utils/dayjs';

// Use March 1st as the "new year" for production cycles
export function getInitialCropYear(allOperationCrops: IOperationCropV2[]): string {
  const now = dayjs();
  const CURRENT_PC_CUTOFF = dayjs().month(2).date(1).year(now.year()); // March 1st
  let currentYear = now.year().toString();

  if (now.isBefore(CURRENT_PC_CUTOFF)) {
    currentYear = now.subtract(1, 'year').year().toString();
  }

  const currentYearOperationCrops = allOperationCrops.filter((oc) => oc.productionCycle === currentYear);
  if (currentYearOperationCrops.length > 0) {
    return currentYear;
  }
  const sortedOperationCrops = orderBy(allOperationCrops, (x) => parseInt(x.productionCycle, 10), 'desc');
  if (sortedOperationCrops.length === 0) {
    return currentYear;
  }
  return sortedOperationCrops[0].productionCycle;
}
