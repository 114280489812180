/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'cqg';

/**
 * Number is represented as a decimal floating point significand (limited by 64 bits including sign)
 * and 32-bit exponent.
 * The value represented is significand * (10 ^ exponent)
 */
export interface Decimal {
  /**
   * Significand (aka mantissa) of the decimal.  Signed.
   * It can have trailing zeros (not normalized).
   */
  significand: number;
  /** Exponent of the decimal. */
  exponent: number;
}

function createBaseDecimal(): Decimal {
  return { significand: 0, exponent: 0 };
}

export const Decimal = {
  encode(message: Decimal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.significand !== 0) {
      writer.uint32(8).sint64(message.significand);
    }
    if (message.exponent !== 0) {
      writer.uint32(16).sint32(message.exponent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Decimal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDecimal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.significand = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.exponent = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Decimal {
    return {
      significand: isSet(object.significand) ? Number(object.significand) : 0,
      exponent: isSet(object.exponent) ? Number(object.exponent) : 0,
    };
  },

  toJSON(message: Decimal): unknown {
    const obj: any = {};
    message.significand !== undefined && (obj.significand = Math.round(message.significand));
    message.exponent !== undefined && (obj.exponent = Math.round(message.exponent));
    return obj;
  },

  create<I extends Exact<DeepPartial<Decimal>, I>>(base?: I): Decimal {
    return Decimal.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Decimal>, I>>(object: I): Decimal {
    const message = createBaseDecimal();
    message.significand = object.significand ?? 0;
    message.exponent = object.exponent ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
