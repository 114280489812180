import React from 'react';
import dayjs from 'dayjs';

import { COMMODITIES, CommodityId, CommodityInformation } from '@harvestiq/constants';
import { formatNumberMinMax } from '../../../utils/formatNumber';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import useMobileView, { BREAKPOINTS } from '../../../customHooks/useMobileView';
import { GetCropPricesOutput, GetLivestockPricesOutput } from '../../../queries/marketData';
import styles from '../Dashboard.module.scss';

export type FuturePricesSchema = {
  id: number;
  name: string;
  oldSymbol: string;
  oldPrice: number;
  oldPriceChange: number;
  newSymbol: string;
  newPrice: number;
  newPriceChange: number;
  formatPriceMaxDigitsAfterDecimal: number;
};

interface FuturesPricesProps {
  quotesLastUpdate: string;
  prices: FuturePricesSchema[];
  oldPriceLabel: string;
  newPriceLabel: string;
}

function FuturesPrices({ oldPriceLabel, newPriceLabel, prices, quotesLastUpdate }: FuturesPricesProps) {
  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);

  const currency = useCurrentCurrency();

  const getPriceChangeElement = (netChange: number, maxDigits: number) => {
    const isPositiveChange = netChange > 0;
    const formattedChange = formatNumberMinMax(netChange, 2, maxDigits, currency);
    const displayChange = isPositiveChange ? `+${formattedChange}` : formattedChange;
    const className = isPositiveChange ? 'green-price' : 'red-price';

    return (
      <span>
        <span className={className}>{displayChange}</span>
      </span>
    );
  };

  function formatPrice(price: number, maxDigits: number) {
    return price ? formatNumberMinMax(price, 2, maxDigits, currency) : '- -';
  }

  function formatPriceChange(change: number, maxDigits: number) {
    return change !== undefined ? getPriceChangeElement(change, maxDigits) : <span>&#8205;</span>;
  }

  return (
    <div className={`${styles['section']} card dashboard-futures-prices`}>
      <div className="card-title mb-0">Futures Prices</div>
      <table className="table table-borderless align-bottom mb-0 lh-1">
        <thead className="text-end">
          <tr>
            <th scope="col"></th>
            <th scope="col">{oldPriceLabel}</th>
            <th scope="col">{newPriceLabel}</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((priceInfo) => (
            <tr key={priceInfo.id}>
              <th scope="row" className={`text-start ${isMobile ? '' : 'py-1'}`}>
                {priceInfo.name}
              </th>

              {/* OLD PRICE */}
              <td className={`text-end ${isMobile ? '' : 'py-1'}`}>
                <div className="futures-prices-change fw-bold pb-1">
                  {formatPriceChange(priceInfo.oldPriceChange, priceInfo.formatPriceMaxDigitsAfterDecimal)}
                </div>
                <div className="fw-bold">
                  {!isMobile && <span className="symbols">{priceInfo.oldSymbol}</span>}
                  <span className="futures-prices">
                    {formatPrice(priceInfo.oldPrice, priceInfo.formatPriceMaxDigitsAfterDecimal)}
                  </span>
                  {isMobile && <div className="symbols pe-0">{priceInfo.oldSymbol}</div>}
                </div>
              </td>

              {/* NEW PRICE */}
              <td className={`text-end ${isMobile ? '' : 'py-1'}`}>
                <div className="futures-prices-change fw-bold pb-1">
                  {formatPriceChange(priceInfo.newPriceChange, priceInfo.formatPriceMaxDigitsAfterDecimal)}
                </div>
                <div className="fw-bold">
                  {!isMobile && <span className="symbols">{priceInfo.newSymbol}</span>}
                  <span className="futures-prices">
                    {formatPrice(priceInfo.newPrice, priceInfo.formatPriceMaxDigitsAfterDecimal)}
                  </span>
                  {isMobile && <div className="symbols pe-0">{priceInfo.newSymbol}</div>}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="futures-prices-last-updated-row pe-2">
        <span></span>
        <span>Delayed Price From {dayjs(quotesLastUpdate).format('hh:mm A')}</span>
      </div>
    </div>
  );
}

export const transformCommodityInformationIntoFuturesPrices = (
  hedgingCrops?: CommodityInformation[],
  cropPrices?: GetCropPricesOutput,
  livestockPrices?: GetLivestockPricesOutput
): FuturePricesSchema[] => {
  const futuresPrices: FuturePricesSchema[] = [];

  if (cropPrices && Object.keys(cropPrices).length && hedgingCrops?.length) {
    hedgingCrops.forEach((crop) => {
      const prices = cropPrices[crop.id];

      futuresPrices.push({
        id: crop.id,
        name: crop.name,
        formatPriceMaxDigitsAfterDecimal: crop.formatPriceMaxDigitsAfterDecimal,
        oldSymbol: prices.oldMarketPrices.barchartSymbol,
        oldPrice: prices.oldMarketPrices.currentFuturesPrice.toNumber(),
        oldPriceChange: prices.oldMarketPrices.futuresPriceChange.toNumber(),
        newSymbol: prices.newMarketPrices.barchartSymbol,
        newPrice: prices.newMarketPrices.currentFuturesPrice.toNumber(),
        newPriceChange: prices.newMarketPrices.futuresPriceChange.toNumber(),
      });
    });
  }

  if (livestockPrices && Object.keys(livestockPrices).length) {
    Object.entries(livestockPrices).forEach(([commodityId, prices]) => {
      const commodity = COMMODITIES[+commodityId as CommodityId];

      futuresPrices.push({
        id: commodity.id,
        name: commodity.name,
        formatPriceMaxDigitsAfterDecimal: commodity.formatPriceMaxDigitsAfterDecimal,
        oldSymbol: prices.oldMarketPrices.barchartSymbol,
        oldPrice: prices.oldMarketPrices.currentFuturesPrice.toNumber(),
        oldPriceChange: prices.oldMarketPrices.futuresPriceChange.toNumber(),
        newSymbol: prices.newMarketPrices.barchartSymbol,
        newPrice: prices.newMarketPrices.currentFuturesPrice.toNumber(),
        newPriceChange: prices.newMarketPrices.futuresPriceChange.toNumber(),
      });
    });
  }

  return futuresPrices;
};

export default FuturesPrices;
