import { FuturesContractType, PositionType, IPosition, OptionContractType } from '../types/IPosition';
import {
  IPositionProtectionInfo,
  isLongCallOption,
  isLongPutOption,
  isShortCallOption,
  isShortFutures,
  isShortPutOption,
} from './strategiesTypes';
import { sum, uniq } from 'lodash';

export enum ProtectedPositionsTypes {
  SINGLE_LEG_LONG_PUT = 1,
  SINGLE_LEG_SHORT_FUTURES = 2,
  SYNTHETIC_LONG_PUT = 3,
  SYNTHETIC_SHORT_FUTURES = 4,
  BEAR_PUT_SPREAD = 5,
  SYNTHETIC_SHORT_FUTURES_REVERSAL = 6,
  THREE_WAY = 7,
  SYNTHETIC_PUT_SPREAD = 8,
  BASIC_PROTECTION = 9,
}

export const getProtectedPositionsType = (legs: IPosition[]): ProtectedPositionsTypes | undefined => {
  if (isSingleLegLongPut(legs)) {
    return ProtectedPositionsTypes.SINGLE_LEG_LONG_PUT;
  } else if (isSingleLegShortFutures(legs)) {
    return ProtectedPositionsTypes.SINGLE_LEG_SHORT_FUTURES;
  } else if (isSyntheticLongPut(legs)) {
    return ProtectedPositionsTypes.SYNTHETIC_LONG_PUT;
  } else if (isSyntheticShortFutures(legs)) {
    return ProtectedPositionsTypes.SYNTHETIC_SHORT_FUTURES;
  } else if (isBearPutSpread(legs)) {
    return ProtectedPositionsTypes.BEAR_PUT_SPREAD;
  } else if (isSyntheticShortFuturesReversal(legs)) {
    return ProtectedPositionsTypes.SYNTHETIC_SHORT_FUTURES_REVERSAL;
  } else if (isThreeWay(legs)) {
    return ProtectedPositionsTypes.THREE_WAY;
  } else if (isSyntheticPutSpread(legs)) {
    return ProtectedPositionsTypes.SYNTHETIC_PUT_SPREAD;
  } else if (isBasicProtection(legs)) {
    return ProtectedPositionsTypes.BASIC_PROTECTION;
  }

  return undefined;
};

export function isProtected(legs: IPosition[]): boolean {
  return haveSomeQuantitySold(legs) && !!getProtectedPositionsType(legs);
}

export function haveSomeQuantitySold(legs: IPosition[]): boolean {
  return (
    legs.every((l) => l.quantitySold && Math.abs(l.quantitySold) > 0) ||
    uniq(legs.map((l) => Number(l.quantitySold))).length === 1
  );
}

export function isSingleLegLongPut(legs: IPosition[]): boolean {
  if (!isOneLeg(legs)) {
    return false;
  }
  const [leg] = legs;
  return isLongPutOption(leg);
}

export function isSingleLegShortFutures(legs: IPosition[]): boolean {
  if (!isOneLeg(legs)) {
    return false;
  }
  const [leg] = legs;
  return isShortFutures(leg);
}

export function isSyntheticLongPut(legs: IPosition[]): boolean {
  if (!isTwoLeg(legs)) {
    return false;
  }
  return legs.some(isShortFutures) && legs.some(isLongCallOption);
}

export function isSyntheticShortFutures(legs: IPosition[]): boolean {
  if (!isTwoLeg(legs)) {
    return false;
  }
  const [legOne, legTwo] = legs;
  return isShortCallOption(legOne) && isLongPutOption(legTwo);
}

export function isBearPutSpread(legs: IPosition[]): boolean {
  if (!isTwoLeg(legs)) {
    return false;
  }
  return legs.some(isLongPutOption) && legs.some(isShortPutOption);
}

export function isSyntheticShortFuturesReversal(legs: IPosition[]): boolean {
  if (!isTwoLeg(legs)) {
    return false;
  }
  const [legOne, legTwo] = legs;
  return isLongPutOption(legOne) && isShortCallOption(legTwo);
}

export function isThreeWay(legs: IPosition[]): boolean {
  if (!isThreeLeg(legs)) {
    return false;
  }
  return legs.some(isLongPutOption) && legs.some(isShortPutOption) && legs.some(isShortCallOption);
}

export function isSyntheticPutSpread(legs: IPosition[]): boolean {
  if (!isThreeLeg(legs)) {
    return false;
  }
  return legs.some(isShortFutures) && legs.some(isLongCallOption) && legs.some(isShortCallOption);
}

export function isBasicProtection(legs: IPosition[]): boolean {
  // Only process legs with quantities sold
  const filteredLegs = legs.filter((leg) => leg.quantitySold);

  return filteredLegs.some(isShortFutures) || filteredLegs.some(isLongPutOption);
}

export function getProtectedLegs(legs: IPositionProtectionInfo[]) {
  // Only process legs with quantities sold
  const filteredLegs = legs.filter((leg) => leg.quantitySold);

  const retVal = filteredLegs.filter((x) => {
    if (isShortFutures(x)) {
      return true;
    }
    if (isLongPutOption(x)) {
      return true;
    }
    return false;
  });

  return retVal;
}

export function getTotalProtectedBushels(legs: IPositionProtectionInfo[]): number {
  const protectedLegs = getProtectedLegs(legs);
  // const unprotectedLegs = legs.filter(x => !protectedLegs.includes(x));

  if (!protectedLegs.length) {
    return 0;
  }

  if (protectedLegs.length === 1) {
    return protectedLegs[0].quantitySold || 0;
  }

  // handle "legging-in" situations where multiple of the same type were bought over time
  const futures = protectedLegs.filter((x) => x.contractType === FuturesContractType.Sell);
  const options = protectedLegs.filter((x) => x.type === PositionType.Option);

  // simplest scenario - just sum the futures contracts
  if (!options.length) {
    return sum(futures.map((x) => x.quantitySold || 0));
  }

  // The "protected bushels" is the MIN() of the 3 types of protection
  // so calculate the total bushels for:
  // - short futures
  // - long puts
  // - short calls (Verify this)
  const shortFuturesBushels = sum(futures.map((x) => x.quantitySold || 0));
  const longPutBushels = sum(options.filter((x) => x.contractType === OptionContractType.BuyPut).map((x) => x.quantitySold));

  // only use ones that are > 0
  const possibleProtectedBushels = [shortFuturesBushels, longPutBushels].filter((x) => x > 0);
  return Math.min(...possibleProtectedBushels) || 0;
}

export function isOneLeg(legs: IPosition[]): boolean {
  return legs.length === 1;
}

export function isTwoLeg(legs: IPosition[]): boolean {
  return legs.length === 2;
}

export function isThreeLeg(legs: IPosition[]): boolean {
  return legs.length === 3;
}
