/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { NamedValue, Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';
import { ContractMetadata } from './metadata_2';
import {
  GoMarket,
  StrategyLegFill,
  StrategyTradingParameters,
  SyntheticHang,
  SyntheticLiquidate,
  SyntheticScratch,
  SyntheticStrategyProperties,
} from './strategy_2';
import { ModifyUserAttributes, UserAttribute } from './user_attribute_2';

export const protobufPackage = 'order_2';

/** Specifies what kind of strategy an order, a trade or a position belongs to. */
export enum SpeculationType {
  /** SPECULATION_TYPE_SPECULATION - Speculation strategy. */
  SPECULATION_TYPE_SPECULATION = 1,
  /** SPECULATION_TYPE_ARBITRAGE - Arbitrage strategy. */
  SPECULATION_TYPE_ARBITRAGE = 2,
  /** SPECULATION_TYPE_HEDGE - Hedging strategy. */
  SPECULATION_TYPE_HEDGE = 3,
  UNRECOGNIZED = -1,
}

export function speculationTypeFromJSON(object: any): SpeculationType {
  switch (object) {
    case 1:
    case 'SPECULATION_TYPE_SPECULATION':
      return SpeculationType.SPECULATION_TYPE_SPECULATION;
    case 2:
    case 'SPECULATION_TYPE_ARBITRAGE':
      return SpeculationType.SPECULATION_TYPE_ARBITRAGE;
    case 3:
    case 'SPECULATION_TYPE_HEDGE':
      return SpeculationType.SPECULATION_TYPE_HEDGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SpeculationType.UNRECOGNIZED;
  }
}

export function speculationTypeToJSON(object: SpeculationType): string {
  switch (object) {
    case SpeculationType.SPECULATION_TYPE_SPECULATION:
      return 'SPECULATION_TYPE_SPECULATION';
    case SpeculationType.SPECULATION_TYPE_ARBITRAGE:
      return 'SPECULATION_TYPE_ARBITRAGE';
    case SpeculationType.SPECULATION_TYPE_HEDGE:
      return 'SPECULATION_TYPE_HEDGE';
    case SpeculationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** MiFID algorithm ID type. */
export enum MiFIDAlgorithmIdType {
  /**
   * MIFID_ALGORITHM_ID_TYPE_EXTERNAL - External MiFID Algo ID, an explicit value to be sent as-is to the execution system.
   * Non-CQG WebAPI clients should always use this type.
   */
  MIFID_ALGORITHM_ID_TYPE_EXTERNAL = 1,
  /** MIFID_ALGORITHM_ID_TYPE_CQG - CQG internal MiFID Algo ID. */
  MIFID_ALGORITHM_ID_TYPE_CQG = 2,
  UNRECOGNIZED = -1,
}

export function miFIDAlgorithmIdTypeFromJSON(object: any): MiFIDAlgorithmIdType {
  switch (object) {
    case 1:
    case 'MIFID_ALGORITHM_ID_TYPE_EXTERNAL':
      return MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_EXTERNAL;
    case 2:
    case 'MIFID_ALGORITHM_ID_TYPE_CQG':
      return MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_CQG;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MiFIDAlgorithmIdType.UNRECOGNIZED;
  }
}

export function miFIDAlgorithmIdTypeToJSON(object: MiFIDAlgorithmIdType): string {
  switch (object) {
    case MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_EXTERNAL:
      return 'MIFID_ALGORITHM_ID_TYPE_EXTERNAL';
    case MiFIDAlgorithmIdType.MIFID_ALGORITHM_ID_TYPE_CQG:
      return 'MIFID_ALGORITHM_ID_TYPE_CQG';
    case MiFIDAlgorithmIdType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** MiFID investment decision ID type. */
export enum MiFIDInvestmentDecisionIdType {
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID - Trader MiFID short code. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID = 1,
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID - External MiFID Algo ID, an explicit value to be sent as-is to the execution system. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID = 2,
  /** MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID - CQG internal MiFID Algo ID. */
  MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID = 3,
  UNRECOGNIZED = -1,
}

export function miFIDInvestmentDecisionIdTypeFromJSON(object: any): MiFIDInvestmentDecisionIdType {
  switch (object) {
    case 1:
    case 'MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID':
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID;
    case 2:
    case 'MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID':
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID;
    case 3:
    case 'MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID':
      return MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MiFIDInvestmentDecisionIdType.UNRECOGNIZED;
  }
}

export function miFIDInvestmentDecisionIdTypeToJSON(object: MiFIDInvestmentDecisionIdType): string {
  switch (object) {
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID:
      return 'MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_TRADER_ID';
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID:
      return 'MIFID_INVESTMENT_DECISION_ID_TYPE_EXTERNAL_ALGO_ID';
    case MiFIDInvestmentDecisionIdType.MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID:
      return 'MIFID_INVESTMENT_DECISION_ID_TYPE_CQG_ALGO_ID';
    case MiFIDInvestmentDecisionIdType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** MiFID execution decision ID type. */
export enum MiFIDExecutionDecisionIdType {
  /** MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID - Trader MiFID short code. */
  MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID = 1,
  /** MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID - External MiFID Algo ID, an explicit value to be sent as-is to the execution system. */
  MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID = 2,
  UNRECOGNIZED = -1,
}

export function miFIDExecutionDecisionIdTypeFromJSON(object: any): MiFIDExecutionDecisionIdType {
  switch (object) {
    case 1:
    case 'MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID':
      return MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID;
    case 2:
    case 'MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID':
      return MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MiFIDExecutionDecisionIdType.UNRECOGNIZED;
  }
}

export function miFIDExecutionDecisionIdTypeToJSON(object: MiFIDExecutionDecisionIdType): string {
  switch (object) {
    case MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID:
      return 'MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_TRADER_ID';
    case MiFIDExecutionDecisionIdType.MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID:
      return 'MIFID_EXECUTION_DECISION_ID_TYPE_EXTERNAL_ALGO_ID';
    case MiFIDExecutionDecisionIdType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Low-level order request reject. It is sent only if direct order status updates are impossible. */
export interface OrderRequestReject {
  /** ID of the order request this reject corresponds to. */
  requestId: number;
  /**
   * If order request is rejected, this is rejection code.
   * See https://help.cqg.com/apihelp/#!Documents/rejectcodesfixconnectorderrouting.htm for the list of values.
   * Client implementation must be ready to get new values in this field.
   */
  rejectCode: number;
  /**
   * Note: use 'details' instead.
   *
   * @deprecated
   */
  textMessage: string;
  /** Optional reject details message. */
  details: Text | undefined;
}

/** It is sent only for OrderRequest actions: LiquidateAll, CancelAll, GoFlat. */
export interface OrderRequestAck {
  /** ID of the order request this acknowledgement corresponds to. */
  requestId: number;
  /** Time when OrderRequest action has been processed. */
  when: Date | undefined;
}

/** Status of order. */
export interface OrderStatus {
  /** List of trade subscription IDs this status is related to. */
  subscriptionIds: number[];
  /**
   * True if this is a snapshot related message.
   * Since snapshot might be sent in several messages (including none),
   * client should use TradeSnapshotCompletion message as
   * an indicator of complete snapshot delivery for a particular subscription.
   */
  isSnapshot: boolean;
  /** This field is associated with shared_1.OrderStatus.Status enum type. */
  status: number;
  /**
   * Order ID assigned by server.
   * It is changed by server after each modify request acknowledgment.
   * Some fields and nested messages of OrderStatus message may be different
   * for different accounts for the same order (i.e. same order_id).
   */
  orderId: string;
  /** Order ID assigned by server to originally placed order. It stays the same regardless of modification requests. */
  chainOrderId: string;
  /** Order ID assigned by execution system (e.g. exchange). */
  execOrderId: string;
  /**
   * Id used to determine a user's order in Detailed DOM.
   * Is unique for an exchange. contract_id + detailed_dom_order_id pair is unique among all orders of a user.
   */
  detailedDomOrderId: string;
  /**
   * Note: use status_utc_timestamp field instead.
   *
   * @deprecated
   */
  statusUtcTime: number;
  /**
   * Last order change time from server perspective (UTC).
   * It is not guaranteed that order status (e.g. from different order chain) which comes later has bigger timestamp.
   */
  statusUtcTimestamp: Date | undefined;
  /**
   * Note: use submission_utc_timestamp field instead.
   *
   * @deprecated
   */
  submissionUtcTime: number;
  /** Time when original order was submitted to the execution system by server (UTC). */
  submissionUtcTimestamp: Date | undefined;
  /**
   * Note: use fill_qty field instead.
   *
   * @deprecated
   */
  uint32FillQty: number;
  /** Filled quantity. */
  fillQty: Decimal | undefined;
  /** Number of fill events. */
  fillCnt: number;
  /**
   * Average fill price.
   * NOTE: this price is aligned by ContractMetadata.correct_price_scale so don't use it for OTE/UPL calculation but
   * use prices from individual TRADE elements. See also avg_fill_price_correct field.
   */
  scaledAvgFillPrice: number;
  /**
   * Average fill price in correct price format.
   * Could have higher precision than avg_fill_price multiplied by correct_price_scale.
   */
  avgFillPriceCorrect: number;
  /**
   * Note: use active_at_utc_timestamp field instead.
   *
   * @deprecated
   */
  activeAtUtcTime: number;
  /** Time when order should be submitted to execution system as assigned by server (UTC). */
  activeAtUtcTimestamp: Date | undefined;
  /**
   * Note: use remaining_qty field instead.
   *
   * @deprecated
   */
  uint32RemainingQty: number;
  /**
   * Remaining (unfilled) order quantity. It is not always equal
   * to order size minus filled size (for example, busted orders violate that).
   */
  remainingQty: Decimal | undefined;
  /** Order with updated attributes. Server may not send it if order attributes remained the same since last update. */
  order: Order | undefined;
  /**
   * List of [last] transactions.
   * In case of initial snapshot (if last_orders_update_utc_time was not set in TradeSubscription)
   * all available transactions are sent. This list can be empty if this status is sent to update order attributes only
   * (e.g. to update prices of trailing orders).
   * It is guaranteed that this list is sorted by transaction identifier (TransactionStatus.trans_id).
   */
  transactionStatuses: TransactionStatus[];
  /**
   * Time of the latest REJECTED, REJECT_CANCEL or REJECT_MODIFY transaction, if any (UTC).
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   */
  rejectUtcTimestamp: Date | undefined;
  /**
   * Message of the latest REJECTED, REJECT_CANCEL or REJECT_MODIFY transaction, if any.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   */
  rejectMessage: string;
  /** Time of the latest ACK_CANCEL transaction, if any (UTC). */
  cancelUtcTimestamp: Date | undefined;
  /** Time of the latest FILL transaction, if any (UTC). */
  fillUtcTimestamp: Date | undefined;
  /**
   * Whether there is FILL_CORRECT with updated quantity. Canceled and busted fills are ignored.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   *
   * @deprecated
   */
  hasFillWithModifiedQty: boolean;
  /**
   * Whether there is FILL_CORRECT with updated price. Canceled and busted fills are ignored.
   * Note: only transactions present in this message in OrderStatus.transaction_status contribute to this field.
   *
   * @deprecated
   */
  hasFillWithModifiedPrice: boolean;
  /** User who placed the order, or user on whose behalf the order was placed. */
  enteredByUser: string;
  /** Statement date when this order was submitted. */
  firstStatementDate: number;
  /**
   * Last statement date this order belongs to. It is set when order is cleaned by a statement report or other event
   * so that order is not 'current' any longer. For example, canceled synthetic strategy leg orders without fills
   * may be cleared before the end of 'current' day.
   */
  lastStatementDate: number;
  /**
   * Multiple OrderStatus and PositionStatus messages in a single ServerMsg can reference the same contract.
   * If the contract is unknown to the client before this ServerMsg,
   * contract meta-data will be added in at least one of them.
   * If in your code you process orders first and positions second then during processing orders you may
   * encounter order with yet unknown contract_id.
   * In this case you should look for matching contract metadata in positions.
   * The opposite is also true: contract metadata for positions can be in order status messages.
   */
  contractMetadata: ContractMetadata[];
  /**
   * Id of an account for this order status.
   * Some fields and nested messages of OrderStatus message may be different
   * for different accounts for the same order (i.e. same order_id).
   */
  accountId: number;
  /** If the order is part of a compound order then this field describe the structure of the compound. */
  compoundOrderStructure: CompoundOrderStructure | undefined;
  /** The number of active (not resolved) hangs on this synthetic strategy order. */
  hangCount: number;
  /** Synthetic order's outright properties */
  strategyProperties: SyntheticStrategyProperties | undefined;
  /** Cross transaction ID assigned by exchange. */
  crossTransactionId: string;
  /** Exchange specific extra attributes. */
  exchangeExtraAttributes: NamedValue[];
  /**
   * Cumulative commission charged for the order fills.
   * CQG trade routing system might not provide this information for some contracts.
   */
  fillCommission: Commission | undefined;
  /** Order identifier assigned by server when sending the order to execution system. */
  routeClOrderId: string;
  /**
   * Actual value of MiFID Execution Within Firm associated with
   * initial or acknowledged modify order request of this order chain for execution in this account
   */
  mifidExecutionDecision: string;
  /** Indicates whether MiFID Execution decision identifies a person (false) or algorithm (true) */
  mifidExecutionDecisionIsAlgo: boolean;
  /**
   * Actual value of MiFID Investment Decision Within Firm associated with
   * initial or acknowledged modify order request of this order chain.
   */
  mifidInvestmentDecision: string;
  /** Indicates whether MiFID investment decision identifies a person (false) or algorithm (true) */
  mifidInvestmentDecisionIsAlgo: boolean;
  /** Message originator location (i.e. geographic location) */
  senderLocationId: string;
  /** Message originator identifier (desk, trader etc) */
  senderOperatorId: string;
  /** Applied effective value of message originator identifier */
  operatorId: string;
  /** Identifier that is used to match two filled care orders. */
  fillCareOrderRequestId: string;
}

/**
 * Status of a specific transaction.
 * All attributes are at the transaction moment.
 */
export interface TransactionStatus {
  /** This field is associated with shared_1.TransactionStatus.Status enum type. */
  status: number;
  /**
   * Order transaction identifier.
   * For all transactions within order chain it is guaranteed that:
   * 1) Transaction identifier is unique.
   * 2) The later transaction has greater transaction identifier than the earlier one.
   */
  transId: number;
  /**
   * Transaction time assigned by server (UTC).
   * Note: use trans_utc_timestamp field instead.
   *
   * @deprecated
   */
  transUtcTime: number;
  /** Transaction time assigned by server (UTC). */
  transUtcTimestamp: Date | undefined;
  /** Reference transaction ID to the previous fill transaction for a bust, cancel or correction. */
  refTransId: number;
  /** If order is modified, this client order ID of the order before modification. */
  origClOrderId: string;
  /** Client order ID of the order at the transaction moment. */
  clOrderId: string;
  /**
   * Note: use fill_qty field instead.
   *
   * @deprecated
   */
  uint32FillQty: number;
  /** Filled quantity for fill transactions or updated quantity for fill correction. */
  fillQty: Decimal | undefined;
  /** Fill price for fill transactions or updated price for fill correction. */
  scaledFillPrice: number;
  /** List of specific per leg trades (more than one for strategies). */
  trades: Trade[];
  /**
   * List of leg order fills that contribute to a synthetic strategy order fill.
   * Present if the transaction is of type FILL or FILL_CORRECT and this order is a synthetic strategy order.
   * Mutually exclusive with 'trade' attribute.
   */
  strategyLegFills: StrategyLegFill[];
  /** Details of the hang */
  syntheticHang: SyntheticHang | undefined;
  /**
   * Note: use prev_order_qty field instead.
   *
   * @deprecated
   */
  uint32PrevOrderQty: number;
  /** If order is modified, this is the previous order size. */
  prevOrderQty: Decimal | undefined;
  /**
   * Note: use order_qty field instead.
   *
   * @deprecated
   */
  uint32OrderQty: number;
  /** If order is modified, this is the updated order size. */
  orderQty: Decimal | undefined;
  /** If order is modified, this is the previous order limit price. */
  scaledPrevLimitPrice: number;
  /** If order is modified, this is the updated order limit price. */
  scaledLimitPrice: number;
  /** If order is modified, this is the previous order stop price. */
  scaledPrevStopPrice: number;
  /** If order is modified, this is the updated order stop price. */
  scaledStopPrice: number;
  /**
   * Note: use prev_visible_qty field instead.
   *
   * @deprecated
   */
  uint32PrevVisibleQty: number;
  /** If order is modified, this is the previous visible order size. */
  prevVisibleQty: Decimal | undefined;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty: number;
  /** If order is modified, this is the updated visible order size. */
  visibleQty: Decimal | undefined;
  /**
   * Note: use prev_min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32PrevMinVisibleQty: number;
  /** If order is modified, this is the previous min visible order size. */
  prevMinVisibleQty: Decimal | undefined;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty: number;
  /** If order is modified, this is the updated min visible order size. */
  minVisibleQty: Decimal | undefined;
  /**
   * Note: use prev_fill_qty field instead.
   *
   * @deprecated
   */
  uint32PrevFillQty: number;
  /** If fill is modified, the previous size is reported here. */
  prevFillQty: Decimal | undefined;
  /** If fill is modified, the previous price is reported here. */
  scaledPrevFillPrice: number;
  /**
   * If order is modified, this is the previous order type.
   * This field is associated with Order.OrderType enum type.
   */
  prevOrderType: number;
  /**
   * If order is modified, this is the updated order type.
   * This field is associated with Order.OrderType enum type.
   */
  orderType: number;
  /**
   * If order is modified, these are the previous exec instructions.
   * This field is associated with Order.ExecInstruction enum type.
   */
  prevExecInstructions: number[];
  /**
   * If order is modified, these are the updated exec instructions.
   * This field is associated with Order.ExecInstruction enum type.
   */
  execInstructions: number[];
  /**
   * If order is modified, this is the previous duration.
   * This field is associated with Order.Duration enum type.
   */
  prevDuration: number;
  /**
   * If order is modified, this is the new duration.
   * This field is associated with Order.Duration enum type.
   */
  duration: number;
  /** If order is modified, this is the previous date then the order should expire  (see Order.good_thru_date). */
  prevGoodThruDate: number;
  /**
   * If order is modified, this is the updated date then the order should expire  (see Order.good_thru_date).
   * The value of duration field must be set to GTD when this date value is specified.
   */
  goodThruDate: number;
  /**
   * If order request is rejected, this is rejection code.
   * See https://help.cqg.com/apihelp/#!Documents/rejectcodesfixconnectorderrouting.htm for the list of values.
   */
  rejectCode: number;
  /** If present, identifies the counterpart of the order fill, as reported by the execution system. */
  fillCounterparty: string;
  /** Order identifier assigned by server when sending the order to execution system. */
  routeClOrderId: string;
  /** Additional transaction details. */
  textMessage: string;
  /**
   * If order is modified, this is the previous time then the order should expire  (see Order.good_thru_utc_time).
   * Note: use prev_good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  prevGoodThruUtcTime: number;
  /** If order is modified, this is the previous time then the order should expire  (see Order.good_thru_utc_timestamp). */
  prevGoodThruUtcTimestamp: Date | undefined;
  /**
   * If order is modified, this is the updated time then the order should expire  (see Order.good_thru_utc).
   * The value of duration field must be set to GTT when this time value is specified.
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime: number;
  /**
   * If order is modified, this is the updated time then the order should expire  (see Order.good_thru_utc).
   * The value of duration field must be set to GTT when this time value is specified.
   */
  goodThruUtcTimestamp: Date | undefined;
  /** True if fill was made as aggressive. False if fill was made as passive. Abscence of value means undefined. */
  isAggressive: boolean;
  /** OrderRequest.client_regulatory_algorithm_id value echoed back from the latest order request. */
  clientRegulatoryAlgorithmId: number;
  /**
   * Regulatory Algorithm ID actually sent to the exchange for this order, if any.
   * Will differ from client_regulatory_algorithm_id if any CQG algorithm was applied to the order.
   */
  effectiveRegulatoryAlgorithmId: number;
  /** MiFID algorithm ID provided with this order request, contents depend on mifid_algorithm_id_type. */
  mifidAlgorithmId: string;
  /** Defines the type of mifid_algorithm_id reported. One of MiFIDAlgorithmIdType enum. */
  mifidAlgorithmIdType: number;
  /**
   * The MiFID algorithm ID associated with the order request for execution in this account.
   * It may have no relation to mifid_algorithm_id, e.g. in case client supplied a mifid_algorithm_id
   * and then a server-side algorithm was used, its identifier would be applied to the order instead.
   */
  mifidAppliedAlgorithmId: string;
  /** The type of mifid_applied_algorithm_id reported. One of MiFIDAlgorithmIdType enum. */
  mifidAppliedAlgorithmIdType: number;
  /**
   * Actual value of MiFID Execution Within Firm associated with this order for execution in this account,
   * reflects FCM + route short code mapping if configured.
   */
  mifidExecutionDecision: string;
  /** Indicates whether MiFID Execution decision identifies a person (false) or algorithm (true) */
  mifidExecutionDecisionIsAlgo: boolean;
  /**
   * Open/close effect of the order fill transaction as a result of the corresponding open_close_instruction.
   * One of OpenCloseInstruction enum.
   */
  openCloseEffect: number;
  /** Speculation type of the transaction. One of SpeculationType enum. */
  speculationType: number;
  /**
   * Commission charged for the fill.
   * CQG trade routing system might not provide this information for some contracts.
   */
  fillCommission: Commission | undefined;
  /** Decision Within Firm associated with this order. */
  mifidInvestmentDecision: string;
  /** Indicates whether MiFID investment decision identifies a person (false) or algorithm (true) */
  mifidInvestmentDecisionIsAlgo: boolean;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code
   */
  executionSourceCode: string;
  /**
   * True if the order with cl_order_id the same as cl_order_id from the
   * current transaction is automatic, manual (the order was sent after the
   * user direct action) otherwise.
   * Different transaction statuses of the same order (or orders in the same
   * chain, see OrderStatus.chain_order_id) may have different values
   * of the flag, depending on the nature of the corresponding order action.
   */
  isAutomated: boolean;
  /** Identifier assigned to a trade by a matching system. */
  tradeMatchId: string;
  /** Identifier that is used to match two filled care orders. */
  fillCareOrderRequestId: string;
  /**
   * User who modified or canceled the order, or user on whose behalf the order was modified or canceled.
   * It is present in the following cases:
   * 1. For real time events:
   *    - If transaction status is IN_TRANSIT, SUSPEND or ACTIVEAT;
   *    - If a trader who initiated this transaction is different from the trader who initiated the previous
   *      transaction, and if the current transaction has one of the following statuses:
   *      IN_MODIFY, IN_CANCEL, REJECT_MODIFY, REJECT_CANCEL, or UPDATE (when available).
   * 2. For past events:
   *    - If transaction has one of these statuses: IN_TRANSIT, SUSPEND, ACTIVEAT, IN_MODIFY, IN_CANCEL, REJECT_MODIFY,
   *      REJECT_CANCEL or UPDATE (when available).
   */
  username: string;
}

/** Trade per specific outright contract. */
export interface Trade {
  /** Trade ID assigned by server, unique within account. */
  tradeId: string;
  /** Server contract identifier. */
  contractId: number;
  /** Brokerage statement date this trade corresponds to (in time format, convert to date only). */
  statementDate: number;
  /**
   * Time of the trade (UTC).
   * Note: use trade_utc_timestamp field instead.
   *
   * @deprecated
   */
  tradeUtcTime: number;
  /** Time of the trade (UTC). */
  tradeUtcTimestamp: Date | undefined;
  /** Exchange trade day this trade corresponds to (in time format, date only value). */
  tradeDate: number;
  /**
   * Trade price in native integer format.
   * price = round(price_correct / correct_price_scale).
   */
  scaledPrice: number;
  /** Trade price in correct price format. Could have higher precision than price multiplied by correct_price_scale. */
  priceCorrect: number;
  /**
   * Trade side.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty: number;
  /** Trade size. */
  qty: Decimal | undefined;
  /**
   * If present, identifies the counter-party of the trade, as reported by the execution system
   * (may or may not match counter-party reported for the order fill).
   */
  tradeCounterparty: string;
  /** True if trade was made as aggressive. False if trade was made as passive. Abscence of value means undefined. */
  isAggressive: boolean;
  /** Execution id of the leg fill */
  legExecutionId: string;
  /**
   * Open/close effect of the trade as a result of the corresponding open_close_instruction.
   * One of OpenCloseInstruction enum.
   */
  openCloseEffect: number;
  /** Speculation type of the trade. One of SpeculationType enum. */
  speculationType: number;
  /** Identifier assigned to a trade by a matching system. */
  tradeMatchId: string;
  /**
   * When this field is present, the contract_id field contains the server contract identifier of a relative
   * symbol (e.g., in case of relative daily futures), and the specific_contract_id contains the server
   * contract identifier of the specific symbol (e.g. the specific instrument mapped to the relative daily
   * future at the moment of this trade).
   */
  specificContractId: number;
  /** List of fill extra attributes. */
  fillExtraAttributes: NamedValue[];
}

export interface Commission {
  /** Commission currency code. */
  commissionCurrency: string;
  /** Commission. */
  commission: number;
}

/** Compound order structure. */
export interface CompoundOrderStructure {
  /**
   * This field is associated with CompoundOrder.Type enum type.
   * Type of the compound order.
   */
  type: number;
  /** ID of the compound order assigned by client. */
  clCompoundId: string;
  /** List of entires. */
  compoundOrderEntries: CompoundOrderStructureEntry[];
  /**
   * Offset in ticks from fill price.
   * New order price will be calculated as
   * (fill price + offset) for buy master orders and (fill price - offset) for sell master orders.
   */
  profitOffset: number;
  /**
   * Offset in ticks from fill price.
   * New order price will be calculated as
   * (fill price - offset) for buy master orders and (fill price + offset) for sell master orders.
   */
  lossOffset: number;
  /**
   * Offset in ticks from loss_offset for stop limit orders.
   * The offset determines the limit price for the order the stop limit becomes when the stop price is hit.
   */
  stopLimitOffset: number;
}

/** Entry of a compound order structure. One of optional fields is specified. */
export interface CompoundOrderStructureEntry {
  /** Regular order id as assigned by server. */
  chainOrderId: string;
  /** Sub-compound order structure entry. */
  compoundOrderStructure: CompoundOrderStructure | undefined;
}

/** Trade routing order. It is used as new order request and as a part of order status updates. */
export interface Order {
  /** Id of an account that is/ was used to place an order. */
  accountId: number;
  /**
   * Client side time when an order was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when an order was submitted (UTC).
   * If specified by the user it's used instead of when_utc_time field
   * (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /** Server side contract Id of the order (see ContractMetadata message). */
  contractId: number;
  /**
   * Client order identifier.
   * It must be unique within a single trading day for day orders and across days for multi-day orders.
   * Maximum allowed length is 64 bytes.
   * In OrderStatus messages it contains cl_order_id of the last accepted order request in the order chain,
   * which may be a cancel request.
   */
  clOrderId: string;
  /**
   * Order type.
   * This field is associated with OrderType enum type.
   */
  orderType: number;
  /**
   * List of applicable execution instructions.
   * This field is associated with ExecInstruction enum type.
   */
  execInstructions: number[];
  /**
   * Order duration.
   * This field is associated with Duration enum type.
   */
  duration: number;
  /** Date for GTD (local to exchange, date only value in time format) when the order should expire. */
  goodThruDate: number;
  /**
   * Order side.
   * This field is associated with Side enum type.
   * This field is required for all order types except for ORDER_TYPE_CROSS.
   * For orders with ORDER_TYPE_CROSS order type this field is not relevant and ignored.
   * See CrossOrderParameters.side_allocation field instead.
   */
  side: number;
  /** Limit price of the order (required for Limit and Stop-limit orders). */
  scaledLimitPrice: number;
  /** Stop price of the order (required for Stop and Stop-limit orders). */
  scaledStopPrice: number;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty: number;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty: number;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty: number;
  /** Total positive size of the order. */
  qty: Decimal | undefined;
  /** Visible size of the order for Icebergs. */
  visibleQty: Decimal | undefined;
  /**
   * Minimum visible positive size of the order
   * used for iceberg orders with 'random visible size',
   * must be < visible_qty.
   */
  minVisibleQty: Decimal | undefined;
  /**
   * True if this is a manual order (order placed by the user direct action), automated otherwise.
   * Deprecated, use OrderRequest.is_automated to set a request source,
   * or TransactionStatus.is_automated to read the value for an order.
   *
   * @deprecated
   */
  isManual: boolean;
  /**
   * True if order is used for closing/ reducing a position, opening/ increasing otherwise
   * (omit this field if there is no need to be explicit). Deprecated, refer to open_close_instruction.
   *
   * @deprecated
   */
  isClose: boolean;
  /** True if the order is aggressive (Fixed Income specific order modifier). */
  isAggressive: boolean;
  /** Maximum offset between market and limit prices or market and stop prices for trail orders. */
  scaledTrailOffset: number;
  /**
   * Trailing peg to follow specific side of the market (for limit orders only).
   * This field is associated with TrailingPeg enum type.
   * Note: order request is rejected if this field is set for stop or stop-limit order.
   */
  trailingPeg: number;
  /**
   * Note: use trigger_qty instead.
   *
   * @deprecated
   */
  uint32TriggerQty: number;
  /** Trigger quantity (threshold) as additional stop order activation condition. */
  triggerQty: Decimal | undefined;
  /**
   * Time when order should be submitted to execution system as assigned by client (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime: number;
  /**
   * Time when order should be submitted to execution system as assigned by client (UTC).
   * If specified by the user it's used instead of activation_utc_time field.
   */
  activationUtcTimestamp: Date | undefined;
  /**
   * Time when order should be suspended (parked) (UTC).
   * Note: use suspension_utc_timestamp field instead.
   *
   * @deprecated
   */
  suspensionUtcTime: number;
  /**
   * Time when order should be suspended (parked) (UTC).
   * If specified by the user it's used instead of suspension_utc_time field.
   */
  suspensionUtcTimestamp: Date | undefined;
  /**
   * User specific attributes.
   * Only printable ASCII characters are allowed in "name" and "value" fields including 0-9A-Za-z, punctuation.
   * (ASCII character set used by the "C" locale with codes greater than 0x1f (US), except 0x7f (DEL).)
   * Violation of the fields formatting leads to rejection of requests that contain UserAttribute.
   * Note that despite punctuation is allowed, it can cause issues with 3rd party tools and formats like CSV.
   * Order comment is provided for an order via UserAttribute with "name" set to "comment" and "value" set to
   * the order comment. Depending on order route, a limit on order comment length can be imposed.
   * If that limit is exceeded, order comment is skipped, but order is not rejected.
   */
  userAttributes: UserAttribute[];
  /**
   * List of order extra attributes.
   * Such attributes are used for Pre-Trade Mid-Market Mark, Algo Strategies and other purposes.
   */
  extraAttributes: NamedValue[];
  /** Case-sensitive identifier (abbreviation) of algorithm used to execute the order (e.g. VWAP, TWAP, etc.). */
  algoStrategy: string;
  /**
   * Date and time for GTT order (UTC) when the order should expire.
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime: number;
  /**
   * Date and time for GTT order (UTC) when the order should expire.
   * If specified by the user it's used instead of good_thru_utc_time field.
   */
  goodThruUtcTimestamp: Date | undefined;
  /** Flag used by the client for marking the user attributes checked/unchecked. */
  isUserAttributeChecked: boolean;
  /**
   * Synthetic strategy trading parameters, applicable only if contract_id designates a synthetic strategy.
   * If the parameter is not applicable for the order the default value is used.
   */
  strategyTradingParameters: StrategyTradingParameters | undefined;
  /**
   * Specifies what the order is intended to do: open or close (reduce) a position. One of OpenCloseInstruction enum.
   * Applicable only if ContractMetadata.position_tracking is either
   * LONG_SHORT_WITH_IMPLIED_CLOSE or LONG_SHORT_WITH_EXPLICIT_CLOSE.
   */
  openCloseInstruction: number;
  /** Speculation type of the order. One of SpeculationType enum. */
  speculationType: number;
  /** Optional OTC contributor involved into the order processing. */
  contributorId: string;
  /**
   * FIA Execution Source Code value for the placement.
   * Overrides the value CQG would otherwise automatically assign
   * to the operation based on trader-account configuration.
   * Refer to https://www.fia.org/articles/fia-and-fia-tech-release-guidelines-simplified-execution-source-code
   * Possible values:
   * W - Desk
   * Y - Electronic (Default)
   * C - Vendor-provided Platform billed by Executing Broker
   * G - Sponsored Access via Exchange API or FIX provided by Executing Broker
   * H - Premium Algorithmic Trading Provider billed by Executing Broker
   * D - Other, including Other-provided Screen
   */
  executionSourceCode: string;
  /**
   * Cross order parameters.
   * It must be specified if order_type is ORDER_TYPE_CROSS.
   */
  crossOrderParameters: CrossOrderParameters | undefined;
  /**
   * Unique identifier of account within the exchange's Member to place order on it.
   * This account can be external for CQG. Placing order on such account may require
   * providing additional exchange-specific information via Order.extra_attributes field.
   * See order_2.ExternalAccount.external_account_number and trading_account_2.Account.allow_external_accounts.
   */
  externalAccountNumber: string;
  /** Specifies that the order is Care order. */
  isCareOrder: boolean;
}

export enum Order_Side {
  /** SIDE_BUY - Describes purchasing side of a trade. */
  SIDE_BUY = 1,
  /** SIDE_SELL - Describes selling side of a trade. */
  SIDE_SELL = 2,
  UNRECOGNIZED = -1,
}

export function order_SideFromJSON(object: any): Order_Side {
  switch (object) {
    case 1:
    case 'SIDE_BUY':
      return Order_Side.SIDE_BUY;
    case 2:
    case 'SIDE_SELL':
      return Order_Side.SIDE_SELL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_Side.UNRECOGNIZED;
  }
}

export function order_SideToJSON(object: Order_Side): string {
  switch (object) {
    case Order_Side.SIDE_BUY:
      return 'SIDE_BUY';
    case Order_Side.SIDE_SELL:
      return 'SIDE_SELL';
    case Order_Side.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Order_OrderType {
  /** ORDER_TYPE_MKT - Market order, buy or sell by the best available opposite price. */
  ORDER_TYPE_MKT = 1,
  /** ORDER_TYPE_LMT - Limit order, buy or sell by price that is the same or better then specified limit price. */
  ORDER_TYPE_LMT = 2,
  /**
   * ORDER_TYPE_STP - Stop order, Order becomes a Market when market reaches order's stop price
   * (which is on opposite side of market).
   */
  ORDER_TYPE_STP = 3,
  /** ORDER_TYPE_STL - Stop-limit order, Order becomes a Limit when market reaches order's stop price. */
  ORDER_TYPE_STL = 4,
  /** ORDER_TYPE_CROSS - Cross order type. See also CrossOrderParameters message. */
  ORDER_TYPE_CROSS = 5,
  UNRECOGNIZED = -1,
}

export function order_OrderTypeFromJSON(object: any): Order_OrderType {
  switch (object) {
    case 1:
    case 'ORDER_TYPE_MKT':
      return Order_OrderType.ORDER_TYPE_MKT;
    case 2:
    case 'ORDER_TYPE_LMT':
      return Order_OrderType.ORDER_TYPE_LMT;
    case 3:
    case 'ORDER_TYPE_STP':
      return Order_OrderType.ORDER_TYPE_STP;
    case 4:
    case 'ORDER_TYPE_STL':
      return Order_OrderType.ORDER_TYPE_STL;
    case 5:
    case 'ORDER_TYPE_CROSS':
      return Order_OrderType.ORDER_TYPE_CROSS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_OrderType.UNRECOGNIZED;
  }
}

export function order_OrderTypeToJSON(object: Order_OrderType): string {
  switch (object) {
    case Order_OrderType.ORDER_TYPE_MKT:
      return 'ORDER_TYPE_MKT';
    case Order_OrderType.ORDER_TYPE_LMT:
      return 'ORDER_TYPE_LMT';
    case Order_OrderType.ORDER_TYPE_STP:
      return 'ORDER_TYPE_STP';
    case Order_OrderType.ORDER_TYPE_STL:
      return 'ORDER_TYPE_STL';
    case Order_OrderType.ORDER_TYPE_CROSS:
      return 'ORDER_TYPE_CROSS';
    case Order_OrderType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Order_ExecInstruction {
  /**
   * EXEC_INSTRUCTION_NONE - None (this means "plain order without any exec instructions").
   * This value shall not be explicitly provided in OrderRequest.
   */
  EXEC_INSTRUCTION_NONE = 12,
  /** EXEC_INSTRUCTION_AON - All or None (fill order only completely). */
  EXEC_INSTRUCTION_AON = 1,
  /** EXEC_INSTRUCTION_ICEBERG - Iceberg (show only part of order size). */
  EXEC_INSTRUCTION_ICEBERG = 2,
  /** EXEC_INSTRUCTION_QT - Quantity triggered (aka DOM Triggered, honor additional quantity threshold for triggering). */
  EXEC_INSTRUCTION_QT = 3,
  /** EXEC_INSTRUCTION_TRAIL - Trailing order (price of the order is following market one direction by specific offset). */
  EXEC_INSTRUCTION_TRAIL = 4,
  /** EXEC_INSTRUCTION_FUNARI - Funari (Limit order becomes a Market on Close). */
  EXEC_INSTRUCTION_FUNARI = 5,
  /** EXEC_INSTRUCTION_MIT - Market if Touched (Limit order becomes a Market when market reaches order's limit price). */
  EXEC_INSTRUCTION_MIT = 6,
  /**
   * EXEC_INSTRUCTION_MLM - Market Limit Market is a limit order that is used to place a buy order above the best offer
   * to fill by the best offer or a sell order below the best bid to fill by the best bid.
   */
  EXEC_INSTRUCTION_MLM = 7,
  /**
   * EXEC_INSTRUCTION_POSTONLY - Post-only order. Ensures the limit order will be added to the order book and not match with
   * a pre-existing order.
   */
  EXEC_INSTRUCTION_POSTONLY = 8,
  /** EXEC_INSTRUCTION_MTL - Market with leftover as Limit (market order then unexecuted quantity becomes limit order at last price). */
  EXEC_INSTRUCTION_MTL = 10,
  /**
   * EXEC_INSTRUCTION_AUCTION - An auction order is an order to buy or sell in the market at the Calculated Opening Price (COP).
   * Unmatched auction orders are converted to limit orders on the market open.
   */
  EXEC_INSTRUCTION_AUCTION = 11,
  /** EXEC_INSTRUCTION_ATANYPRICE - At Any Price Orders are US-Style Market Orders. */
  EXEC_INSTRUCTION_ATANYPRICE = 13,
  /** EXEC_INSTRUCTION_LMT_PRARGD - Limit order with prearranged transaction flag (IntentToCross) set. */
  EXEC_INSTRUCTION_LMT_PRARGD = 14,
  /**
   * EXEC_INSTRUCTION_ICO - Internal Cross Only.
   * This order type is used by OTC to pull up to order size quantity from the exchange on the opposite side.
   */
  EXEC_INSTRUCTION_ICO = 15,
  UNRECOGNIZED = -1,
}

export function order_ExecInstructionFromJSON(object: any): Order_ExecInstruction {
  switch (object) {
    case 12:
    case 'EXEC_INSTRUCTION_NONE':
      return Order_ExecInstruction.EXEC_INSTRUCTION_NONE;
    case 1:
    case 'EXEC_INSTRUCTION_AON':
      return Order_ExecInstruction.EXEC_INSTRUCTION_AON;
    case 2:
    case 'EXEC_INSTRUCTION_ICEBERG':
      return Order_ExecInstruction.EXEC_INSTRUCTION_ICEBERG;
    case 3:
    case 'EXEC_INSTRUCTION_QT':
      return Order_ExecInstruction.EXEC_INSTRUCTION_QT;
    case 4:
    case 'EXEC_INSTRUCTION_TRAIL':
      return Order_ExecInstruction.EXEC_INSTRUCTION_TRAIL;
    case 5:
    case 'EXEC_INSTRUCTION_FUNARI':
      return Order_ExecInstruction.EXEC_INSTRUCTION_FUNARI;
    case 6:
    case 'EXEC_INSTRUCTION_MIT':
      return Order_ExecInstruction.EXEC_INSTRUCTION_MIT;
    case 7:
    case 'EXEC_INSTRUCTION_MLM':
      return Order_ExecInstruction.EXEC_INSTRUCTION_MLM;
    case 8:
    case 'EXEC_INSTRUCTION_POSTONLY':
      return Order_ExecInstruction.EXEC_INSTRUCTION_POSTONLY;
    case 10:
    case 'EXEC_INSTRUCTION_MTL':
      return Order_ExecInstruction.EXEC_INSTRUCTION_MTL;
    case 11:
    case 'EXEC_INSTRUCTION_AUCTION':
      return Order_ExecInstruction.EXEC_INSTRUCTION_AUCTION;
    case 13:
    case 'EXEC_INSTRUCTION_ATANYPRICE':
      return Order_ExecInstruction.EXEC_INSTRUCTION_ATANYPRICE;
    case 14:
    case 'EXEC_INSTRUCTION_LMT_PRARGD':
      return Order_ExecInstruction.EXEC_INSTRUCTION_LMT_PRARGD;
    case 15:
    case 'EXEC_INSTRUCTION_ICO':
      return Order_ExecInstruction.EXEC_INSTRUCTION_ICO;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_ExecInstruction.UNRECOGNIZED;
  }
}

export function order_ExecInstructionToJSON(object: Order_ExecInstruction): string {
  switch (object) {
    case Order_ExecInstruction.EXEC_INSTRUCTION_NONE:
      return 'EXEC_INSTRUCTION_NONE';
    case Order_ExecInstruction.EXEC_INSTRUCTION_AON:
      return 'EXEC_INSTRUCTION_AON';
    case Order_ExecInstruction.EXEC_INSTRUCTION_ICEBERG:
      return 'EXEC_INSTRUCTION_ICEBERG';
    case Order_ExecInstruction.EXEC_INSTRUCTION_QT:
      return 'EXEC_INSTRUCTION_QT';
    case Order_ExecInstruction.EXEC_INSTRUCTION_TRAIL:
      return 'EXEC_INSTRUCTION_TRAIL';
    case Order_ExecInstruction.EXEC_INSTRUCTION_FUNARI:
      return 'EXEC_INSTRUCTION_FUNARI';
    case Order_ExecInstruction.EXEC_INSTRUCTION_MIT:
      return 'EXEC_INSTRUCTION_MIT';
    case Order_ExecInstruction.EXEC_INSTRUCTION_MLM:
      return 'EXEC_INSTRUCTION_MLM';
    case Order_ExecInstruction.EXEC_INSTRUCTION_POSTONLY:
      return 'EXEC_INSTRUCTION_POSTONLY';
    case Order_ExecInstruction.EXEC_INSTRUCTION_MTL:
      return 'EXEC_INSTRUCTION_MTL';
    case Order_ExecInstruction.EXEC_INSTRUCTION_AUCTION:
      return 'EXEC_INSTRUCTION_AUCTION';
    case Order_ExecInstruction.EXEC_INSTRUCTION_ATANYPRICE:
      return 'EXEC_INSTRUCTION_ATANYPRICE';
    case Order_ExecInstruction.EXEC_INSTRUCTION_LMT_PRARGD:
      return 'EXEC_INSTRUCTION_LMT_PRARGD';
    case Order_ExecInstruction.EXEC_INSTRUCTION_ICO:
      return 'EXEC_INSTRUCTION_ICO';
    case Order_ExecInstruction.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Order_Duration {
  /** DURATION_DAY - Day order. Order is working through the current trading day only. */
  DURATION_DAY = 1,
  /** DURATION_GTC - Good Til Canceled. Order is working until canceled or until the contract is no longer available for trading. */
  DURATION_GTC = 2,
  /**
   * DURATION_GTD - Good Til Date. Order is working through the specified trade date (good_thru_date) for the contract.
   * Note: Exchange must have a trading session for the contract for the specified trade date.
   */
  DURATION_GTD = 3,
  /** DURATION_GTT - Good Til Time. Order is working until the specified time. */
  DURATION_GTT = 4,
  /** DURATION_FAK - Fill and Kill. Immediately fill as many as possible and cancel the rest. */
  DURATION_FAK = 5,
  /** DURATION_FOK - Fill Or Kill. Immediately fill this order completely or cancel. */
  DURATION_FOK = 6,
  /** DURATION_ATO - At The Open. Buy or sell at the very beginning of the trading day. */
  DURATION_ATO = 7,
  /** DURATION_ATC - At The Close. Buy or sell at the close of the market, or as near to the closing price as possible. */
  DURATION_ATC = 8,
  /** DURATION_GFA - Good for auction. Order only valid for the next auction. */
  DURATION_GFA = 9,
  UNRECOGNIZED = -1,
}

export function order_DurationFromJSON(object: any): Order_Duration {
  switch (object) {
    case 1:
    case 'DURATION_DAY':
      return Order_Duration.DURATION_DAY;
    case 2:
    case 'DURATION_GTC':
      return Order_Duration.DURATION_GTC;
    case 3:
    case 'DURATION_GTD':
      return Order_Duration.DURATION_GTD;
    case 4:
    case 'DURATION_GTT':
      return Order_Duration.DURATION_GTT;
    case 5:
    case 'DURATION_FAK':
      return Order_Duration.DURATION_FAK;
    case 6:
    case 'DURATION_FOK':
      return Order_Duration.DURATION_FOK;
    case 7:
    case 'DURATION_ATO':
      return Order_Duration.DURATION_ATO;
    case 8:
    case 'DURATION_ATC':
      return Order_Duration.DURATION_ATC;
    case 9:
    case 'DURATION_GFA':
      return Order_Duration.DURATION_GFA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_Duration.UNRECOGNIZED;
  }
}

export function order_DurationToJSON(object: Order_Duration): string {
  switch (object) {
    case Order_Duration.DURATION_DAY:
      return 'DURATION_DAY';
    case Order_Duration.DURATION_GTC:
      return 'DURATION_GTC';
    case Order_Duration.DURATION_GTD:
      return 'DURATION_GTD';
    case Order_Duration.DURATION_GTT:
      return 'DURATION_GTT';
    case Order_Duration.DURATION_FAK:
      return 'DURATION_FAK';
    case Order_Duration.DURATION_FOK:
      return 'DURATION_FOK';
    case Order_Duration.DURATION_ATO:
      return 'DURATION_ATO';
    case Order_Duration.DURATION_ATC:
      return 'DURATION_ATC';
    case Order_Duration.DURATION_GFA:
      return 'DURATION_GFA';
    case Order_Duration.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Order_TrailingPeg {
  /** TRAILING_PEG_BESTBID - Trail the best bid. */
  TRAILING_PEG_BESTBID = 1,
  /** TRAILING_PEG_BESTASK - Trail the best ask. */
  TRAILING_PEG_BESTASK = 2,
  /** TRAILING_PEG_LASTTRADE - Trail the last trade. */
  TRAILING_PEG_LASTTRADE = 3,
  UNRECOGNIZED = -1,
}

export function order_TrailingPegFromJSON(object: any): Order_TrailingPeg {
  switch (object) {
    case 1:
    case 'TRAILING_PEG_BESTBID':
      return Order_TrailingPeg.TRAILING_PEG_BESTBID;
    case 2:
    case 'TRAILING_PEG_BESTASK':
      return Order_TrailingPeg.TRAILING_PEG_BESTASK;
    case 3:
    case 'TRAILING_PEG_LASTTRADE':
      return Order_TrailingPeg.TRAILING_PEG_LASTTRADE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_TrailingPeg.UNRECOGNIZED;
  }
}

export function order_TrailingPegToJSON(object: Order_TrailingPeg): string {
  switch (object) {
    case Order_TrailingPeg.TRAILING_PEG_BESTBID:
      return 'TRAILING_PEG_BESTBID';
    case Order_TrailingPeg.TRAILING_PEG_BESTASK:
      return 'TRAILING_PEG_BESTASK';
    case Order_TrailingPeg.TRAILING_PEG_LASTTRADE:
      return 'TRAILING_PEG_LASTTRADE';
    case Order_TrailingPeg.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Specifies what the order is intended to do:
 * open (or extend) a position on the same side as the order or close (or reduce) an opposite position.
 * Applicable only for contracts with position_tracking
 * either LONG_SHORT_WITH_IMPLIED_CLOSE or LONG_SHORT_WITH_EXPLICIT_CLOSE.
 */
export enum Order_OpenCloseInstruction {
  /** OPEN_CLOSE_INSTRUCTION_OPEN - Opening a new today position. */
  OPEN_CLOSE_INSTRUCTION_OPEN = 1,
  /**
   * OPEN_CLOSE_INSTRUCTION_CLOSE - Closing or reducing (today only if ContractMetadata.position_tracking is LONG_SHORT_WITH_EXPLICIT_CLOSE,
   * today or yesterday if LONG_SHORT_WITH_IMPLIED_CLOSE).
   */
  OPEN_CLOSE_INSTRUCTION_CLOSE = 2,
  /**
   * OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY - Closing or reducing a yesterday position
   * (if ContractMetadata.position_tracking is LONG_SHORT_WITH_EXPLICIT_CLOSE).
   */
  OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY = 3,
  UNRECOGNIZED = -1,
}

export function order_OpenCloseInstructionFromJSON(object: any): Order_OpenCloseInstruction {
  switch (object) {
    case 1:
    case 'OPEN_CLOSE_INSTRUCTION_OPEN':
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_OPEN;
    case 2:
    case 'OPEN_CLOSE_INSTRUCTION_CLOSE':
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE;
    case 3:
    case 'OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY':
      return Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Order_OpenCloseInstruction.UNRECOGNIZED;
  }
}

export function order_OpenCloseInstructionToJSON(object: Order_OpenCloseInstruction): string {
  switch (object) {
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_OPEN:
      return 'OPEN_CLOSE_INSTRUCTION_OPEN';
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE:
      return 'OPEN_CLOSE_INSTRUCTION_CLOSE';
    case Order_OpenCloseInstruction.OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY:
      return 'OPEN_CLOSE_INSTRUCTION_CLOSE_YESTERDAY';
    case Order_OpenCloseInstruction.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * One of the order related requests. There must be only one optional order submessage specified per request.
 * E.g NewOrder, but not NewOrder and CancelOrder.
 * It is required to perform a TradeSubscription before any order requests, the subscription is required
 * in order to receive order events.
 */
export interface OrderRequest {
  /** ID of a request that should be unique enough to match with possible OrderRequestReject. */
  requestId: number;
  /** New order request (place order). */
  newOrder: NewOrder | undefined;
  /** Modify order request. */
  modifyOrder: ModifyOrder | undefined;
  /** Cancel order request. */
  cancelOrder: CancelOrder | undefined;
  /** Cancel all orders request. */
  cancelAllOrders: CancelAllOrders | undefined;
  /** Liquidate all open positions. */
  liquidateAll: LiquidateAll | undefined;
  /** Cancel all orders and liquidate all open positions. */
  goFlat: GoFlat | undefined;
  /** Suspend (park) order. */
  suspendOrder: SuspendOrder | undefined;
  /** Activate suspended (parked) order. */
  activateOrder: ActivateOrder | undefined;
  /** Modify order's user attributes. */
  modifyUserAttributes: ModifyUserAttributes | undefined;
  /** New compound order request (place compound order). */
  newCompoundOrder: NewCompoundOrder | undefined;
  /** Synthetic hangup liquidation with a MKT order. */
  syntheticLiquidate: SyntheticLiquidate | undefined;
  /** Synthetic hangup liquidation with a LMT order. */
  syntheticScratch: SyntheticScratch | undefined;
  /** Modify a synthetic order to get an immediate fill. */
  goMarket: GoMarket | undefined;
  /** Approve order with ORDER_TYPE_CROSS type. */
  approveOrder: ApproveOrder | undefined;
  /** Fill specific care order. */
  fillCareOrder: FillCareOrder | undefined;
  /**
   * Username of the user on whose behalf the order request is being sent.
   * Must be a known username in CQG trade routing system,
   * otherwise the order will be rejected.
   */
  onBehalfOfUser: string;
  /**
   * Regulatory Algorithm ID for client algorithm associated with this order, if any.
   * This field is applicable only for exchanges that use it, e.g. Eurex
   * (not currently used, remains in protocol for backward compatibility only).
   * TransactionStatus message echoes back the value from the latest order request
   */
  clientRegulatoryAlgorithmId: number;
  /**
   * MiFID algorithm ID for this order, contents depend on mifid_algorithm_id_type.
   * Provide only when the order must have an algorithm as Execution Within Firm under MiFID II definitions.
   */
  mifidAlgorithmId: string;
  /** The type of mifid_algorithm_id supplied. One of MiFIDAlgorithmIdType enum. */
  mifidAlgorithmIdType: number;
  /**
   * True instructs server to send this order as Execution Within Firm=CLIENT/NORE
   * rather than the user's server-side configured trader short code.
   */
  overrideExecutionWithinFirmWithNore: boolean;
  /** MiFID investment decision ID for this order, contents depend on mifid_investment_decision_id_type. */
  mifidInvestmentDecisionId: string;
  /** The type of mifid_investment_decision_id supplied. One of MiFIDInvestmentDecisionIdType enum. */
  mifidInvestmentDecisionIdType: number;
  /**
   * MiFID execution decision ID for this order, contents depend on mifid_execution_decision_id_type.
   * Note: this field is used only by approved clients for absolute pass-through; orders from any other
   * clients that use the field will be rejected. Use general-purpose field mifid_algorithm_id with
   * mifid_algorithm_id_type=1 (MIFID_ALGORITHM_ID_TYPE_EXTERNAL) when execution decision is made by
   * an algorithm with registered short code.
   */
  mifidExecutionDecisionId: string;
  /** The type of mifid_execution_decision_id supplied. One of MiFIDExecutionDecisionIdType enum. */
  mifidExecutionDecisionIdType: number;
  /** True if this is an automated order request, manual (request placed by the user direct action) otherwise. */
  isAutomated: boolean;
  /**
   * Message originator location (i.e. geographic location).
   * Format: "CC[,SS]", CountryCode (ISO 3166-1 alpha 2) and StateCode
   * (postal abbreviation) when sending country code is "US" or "CA".
   * Examples: "US,CA", "GB".
   */
  senderLocationId: string;
  /**
   * Message originator identifier (desk, trader etc).
   * It is passed directly to the exchange in the appropriate exchange API field/tag,
   * often 'Operator ID', 'tag 50', or similar.
   */
  senderOperatorId: string;
}

/** New order request. */
export interface NewOrder {
  /** Order to place. */
  order: Order | undefined;
  /** True if order should be suspended (parked) and wait a separate activate request or activation time. */
  suspend: boolean;
}

/** Modify order request, include only fields that are supposed to be modified. */
export interface ModifyOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to modify. */
  origClOrderId: string;
  /**
   * Client order ID of this modify request (will become new client order Id if modify is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /**
   * Note: use qty field instead.
   *
   * @deprecated
   */
  uint32Qty: number;
  /**
   * Note: use visible_qty field instead.
   *
   * @deprecated
   */
  uint32VisibleQty: number;
  /**
   * Note: use min_visible_qty field instead.
   *
   * @deprecated
   */
  uint32MinVisibleQty: number;
  /** Order size what the client wants the total size to become. */
  qty: Decimal | undefined;
  /** What the client wants the visible size to become. */
  visibleQty: Decimal | undefined;
  /** What the client wants the minimum visible size to become. */
  minVisibleQty: Decimal | undefined;
  /** What the client wants the limit price to become. */
  scaledLimitPrice: number;
  /** What the client wants the stop price to become. */
  scaledStopPrice: number;
  /**
   * What the client wants the activation time to become (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime: number;
  /**
   * What the client wants the activation time to become (UTC).
   * If specified it's used instead of activation_utc_time field.
   */
  activationUtcTimestamp: Date | undefined;
  /** True if activation time should be removed. */
  removeActivationTime: boolean;
  /**
   * What the client wants the suspension time to become (UTC).
   * Note: use suspension_utc_timestamp field instead.
   *
   * @deprecated
   */
  suspensionUtcTime: number;
  /**
   * What the client wants the suspension time to become (UTC).
   * If specified it's used instead of suspension_utc_time field.
   */
  suspensionUtcTimestamp: Date | undefined;
  /** True if suspension time should be removed. */
  removeSuspensionUtcTime: boolean;
  /**
   * What the client wants the order duration to become.
   * This field is associated with Order.Duration enum type.
   */
  duration: number;
  /** What the client wants the order expiration date to become (see Order.good_thru_date). */
  goodThruDate: number;
  /**
   * What the client wants the order expiration time to become (see Order.good_thru_utc_time).
   * Note: use good_thru_utc_timestamp field instead.
   *
   * @deprecated
   */
  goodThruUtcTime: number;
  /**
   * What the client wants the order expiration time to become (see Order.good_thru_utc_timestamp).
   * If specified by the user it's used instead of good_thru_utc_time field.
   */
  goodThruUtcTimestamp: Date | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code
   */
  executionSourceCode: string;
}

/** Cancel order request. */
export interface CancelOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to cancel. */
  origClOrderId: string;
  /**
   * Client order ID of this cancel request.
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
}

/** Specifies an account and what orders to use in the account. */
export interface AccountOrderFilter {
  /** ID of the order's account. */
  accountId: number;
  /**
   * Specifies either all orders in the account or just orders in the
   * account associated with the trader sending the request.
   */
  mine: boolean;
  /**
   * Specifies whether only suspended orders or all not suspended orders in the
   * account associated with the trader sending the request.
   */
  suspended: boolean;
  /**
   * Specifies whether only BUY orders or only SELL orders.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /** Use only orders of specified contract. */
  contractId: number;
  /**
   * Specifies whether only order(s) expiring during the
   * current/nearest session should be considered.
   */
  currentDayOnly: boolean;
}

/** Cancel all orders request. */
export interface CancelAllOrders {
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /** Client order ID of this cancel request. */
  clOrderId: string;
  /**
   * Specifies an account and what orders to cancel in the account.
   * All orders that meet the criteria (account, mine, suspended etc.) will be canceled.
   * At least one AccountOrderFilter should be present.
   */
  accountOrderFilters: AccountOrderFilter[];
}

/** Activate suspended (parked) order request. */
export interface ActivateOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to activate. */
  origClOrderId: string;
  /**
   * Client order ID of this activation request (will become new client order Id if activation is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode: string;
  /** Set to true when the action is initiated by care order auto activation algorithm. */
  isCareAutoActivation: boolean;
}

/** Placement of a compound order. */
export interface NewCompoundOrder {
  /** Compound order to place. */
  compoundOrder: CompoundOrder | undefined;
  /**
   * Indicates whether compound order execution shall happen on a partial fill (true or omitted)
   * or complete fill (false).
   */
  partialFillsHandling: boolean;
}

/** Compound order. */
export interface CompoundOrder {
  /** This field is associated with Type enum type. */
  type: number;
  /**
   * ID of the compound order assigned by client.
   * Maximum allowed length is 64 bytes.
   */
  clCompoundId: string;
  /** List of entires. */
  compoundOrderEntries: CompoundOrderEntry[];
  /**
   * Offset in ticks from fill price (variable ticks size is supported). New order price will be
   * calculated as (fill price + offset) for buy master orders and (price - offset) for sell master orders.
   */
  profitTickOffset: number;
  /**
   * Offset in ticks from fill price (variable ticks size is supported). New order price will be
   * calculated as (fill price - offset) for buy master orders and (price + offset) for sell master orders.
   */
  lossTickOffset: number;
  /**
   * Offset in ticks from loss_tick_offset for stop limit orders (variable ticks size is supported).
   * The offset determines the limit price for stop limit order after the stop price is hit.
   */
  stopLimitTickOffset: number;
  /** Treat compound order as server-side bracket. */
  isBracket: boolean;
}

/** Type of the compound order. */
export enum CompoundOrder_Type {
  /**
   * TYPE_OPO - Order Places Order.
   * [Partial] fill of the first entry triggers [partial] placement of all other orders.
   */
  TYPE_OPO = 1,
  /**
   * TYPE_OCO - Order Cancels Order. This type allows several compound order entires.
   * [Partial] fill of any order triggers [partial] canceling of all other orders.
   */
  TYPE_OCO = 2,
  /** TYPE_INDEPENDENT - A set of otherwise unrelated orders being placed in one action. */
  TYPE_INDEPENDENT = 3,
  UNRECOGNIZED = -1,
}

export function compoundOrder_TypeFromJSON(object: any): CompoundOrder_Type {
  switch (object) {
    case 1:
    case 'TYPE_OPO':
      return CompoundOrder_Type.TYPE_OPO;
    case 2:
    case 'TYPE_OCO':
      return CompoundOrder_Type.TYPE_OCO;
    case 3:
    case 'TYPE_INDEPENDENT':
      return CompoundOrder_Type.TYPE_INDEPENDENT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CompoundOrder_Type.UNRECOGNIZED;
  }
}

export function compoundOrder_TypeToJSON(object: CompoundOrder_Type): string {
  switch (object) {
    case CompoundOrder_Type.TYPE_OPO:
      return 'TYPE_OPO';
    case CompoundOrder_Type.TYPE_OCO:
      return 'TYPE_OCO';
    case CompoundOrder_Type.TYPE_INDEPENDENT:
      return 'TYPE_INDEPENDENT';
    case CompoundOrder_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Use existing order. */
export interface UseOrder {
  /** Order id of existing order assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
}

/** Entry of a compound order. Only one of optional fields has to be specified. */
export interface CompoundOrderEntry {
  /** Regular order entry. */
  order: Order | undefined;
  /** Sub-compound order entry. */
  compoundOrder: CompoundOrder | undefined;
  /** Existing order entry. */
  useOrder: UseOrder | undefined;
}

/** Suspend (park) order request. */
export interface SuspendOrder {
  /** Order id assigned by server after last modification. */
  orderId: string;
  /** ID of the order's account. */
  accountId: number;
  /** This client order ID of the order to suspend (park). */
  origClOrderId: string;
  /**
   * Client order ID of this suspension request (will become new client order Id if suspension is accepted).
   * Maximum allowed length is 64 bytes.
   */
  clOrderId: string;
  /**
   * Client side time when a modify request was submitted (UTC).
   * Note: use when_utc_timestamp field instead.
   *
   * @deprecated
   */
  whenUtcTime: number;
  /**
   * Client side time when a modify request was submitted (UTC).
   * If specified it's used instead of when_utc_time field (at least one of these fields has to be specified).
   */
  whenUtcTimestamp: Date | undefined;
  /**
   * Time when the suspended (parked) order should automatically activate (UTC).
   * Note: use activation_utc_timestamp field instead.
   *
   * @deprecated
   */
  activationUtcTime: number;
  /**
   * Time when the suspended (parked) order should automatically activate (UTC).
   * If specified it's used instead of activation_utc_time field.
   * If neither this nor activation_utc_time field is specified, order must be activated by ActivateOrder request
   */
  activationUtcTimestamp: Date | undefined;
  /** List of order extra attributes, can be used to remove or modify existing or add new attributes. */
  extraAttributes: NamedValue[];
}

/** Specify account and its positions. */
export interface AccountPositionFilter {
  /** Id of an account. */
  accountId: number;
  /** Server side contract id. */
  contractId: number;
  /**
   * Specifies whether only short (true) or only long (false) positions should be selected.
   * If not specified, both short and long positions will be selected.
   */
  isShort: boolean;
  /**
   * Specifies whether only positions of current day (true)
   * or only positions of previous days (false) should be selected.
   * If not specified, positions of current and previous day(s) will be selected.
   * Should be used only if instrument supports explicit close for positions.
   * If specified, and any instrument not supporting explicit close matches this filter,
   * ServerMsg.OrderRequestReject is sent.
   */
  currentDayOnly: boolean;
}

/** Liquidate all open positions. */
export interface LiquidateAll {
  /**
   * Accounts and positions to liquidate.
   * At least one AccountPositionFilter should be specified, otherwise ServerMsg.OrderRequestReject is sent.
   * Position matching any filter is to be liquidated.
   */
  accountPositionFilters: AccountPositionFilter[];
  /** Client side time when this request was submitted (UTC). */
  whenUtcTimestamp: Date | undefined;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode: string;
  /** Specifies a speculation type. One of SpeculationType enum. */
  speculationType: number;
}

/** Cancel all orders (including parked) and liquidate all open positions. */
export interface GoFlat {
  /** Id of an account. At least one should be specified, otherwise ServerMsg.OrderRequestReject is sent. */
  accountIds: number[];
  /** Client side time when this request was submitted (UTC). */
  whenUtcTimestamp: Date | undefined;
  /**
   * FIA Execution Source Code value of the operation.
   * See Order.execution_source_code.
   */
  executionSourceCode: string;
  /** Specifies a speculation type. One of SpeculationType enum. */
  speculationType: number;
}

/** Request of order entitlements. */
export interface OrderEntitlementRequest {
  /** Server side contract id. */
  contractId: number;
  /** Account ID in CQG trade routing system. */
  accountId: number;
}

/** Report with order entitlements. */
export interface OrderEntitlementReport {
  /** Set of order entitlements. */
  orderEntitlements: OrderEntitlement[];
}

/**
 * Entitlements for order.
 * For future compatibility unknown order types/durations/execution instructions should be ignored.
 */
export interface OrderEntitlement {
  /**
   * Order type.
   * This field is associated with Order.OrderType enum type.
   */
  orderType: number;
  /**
   * Value indicating whether order_type may be executed synthetically by CQG.
   * E.g. if is_synthetic is true and order_type is MKT, it means that there will be synthetic processing
   * of Market order by CQG before the order is sent to the exchange, where Market order is not natively supported.
   * This is an informational field. The Client cannot specify this field in an order.
   */
  isSynthetic: boolean;
  /**
   * Order duration.
   * This field is associated with Order.Duration enum type.
   */
  duration: number;
  /**
   * Order execution instruction.
   * This field is associated with Order.ExecInstruction enum type.
   */
  execInstruction: number;
  /** Whether the algo strategy is required to be set for the order. */
  algoStrategyRequired: boolean;
  /** List of algo strategies enabled for the order (abbreviations). */
  algoStrategies: string[];
}

/**
 * Cross order parameters.
 * This message allows to report or register privately negotiated pre-agreed
 * transactions with one or multiple counter parties. Matching at exchange can
 * either immediately occur when the request is submitted as a dual-sided
 * or will occur when the different counterparts have all replied
 * to the cross order initiator (see ApproveOrder message).
 * Examples of these transactions: cross orders, Approved Publication
 * Arrangement (APA) and wholesale orders.
 * Transaction type is chosen by specifying corresponding execution instruction
 * (see Order.ExecInstruction). Note that some transaction types have various
 * exchange specific custom parameters (e.g. see Order.extra_attribute,
 * SideAllocation.extra_attributes and ApproveOrder.extra_attributes).
 * All these transaction types are referred to as "cross orders" within the
 * protocol for simplicity.
 *
 * Note that exchanges may support LegAllocation as independent children of a
 * cross order. This is used when each leg is an independent trade, so submitting the
 * cross order on a recognized strategy symbol is not possible. In this case, the
 * cross order symbol is a exchange-specific placeholder agreed between client and server
 * rather than a tradable symbol.
 *
 * Possible scenarios:
 * 1) Dual-sided submission (no match process)
 *    Initiator submits a cross order and specifies all participants involved
 *    on buy and sell sides of the order; approve from the participants may be
 *    required for some exchanges.
 *    Two or more side allocations are provided for this type of cross order.
 * 2) Single-sided submission
 *    Each participant submits its own side (buy or sell) of a cross order.
 *    Matching could be done with an associated identifier (see transaction_id
 *    field) or with an approve request.
 *    One side allocation is provided for this type of cross order.
 * 3) Approved Publication Arrangement (APA)
 *    Allows users to publish trade reports in all instruments subject to the
 *    OTC publication requirement.
 *    No side allocations are provided for this type of cross order.
 */
export interface CrossOrderParameters {
  /** Side allocations of the cross order. */
  sideAllocations: SideAllocation[];
  /** Price of the cross order (in correct format). */
  price: number;
  /**
   * Optional ID that can be used to associate the single-sided
   * cross orders with the same transaction (used by Euronext exchange).
   */
  transactionId: string;
  /** Leg allocations of the cross order. */
  legAllocations: LegAllocation[];
}

/** Side allocation of the cross order. */
export interface SideAllocation {
  /**
   * Identifier of the side allocation record, this field is generated by
   * server.
   * Client should set this field to modify/approve existing side allocation.
   */
  sideId: number;
  /** Either this or external_account field must be specified. */
  accountId: number;
  /** Either this or account_id field must be specified. */
  externalAccount: ExternalAccount | undefined;
  /**
   * Allocation size, is only used for some particular scenarios
   * (like strategies on options for Euronext), in regular scenarios
   * the attribute is omitted.
   */
  qty: Decimal | undefined;
  /**
   * Allocation side.
   * This field is associated with Order.Side enum type.
   * It is a required field.
   */
  side: number;
  /** List of side allocation extra attributes. */
  extraAttributes: NamedValue[];
}

/** Leg allocation of the cross order */
export interface LegAllocation {
  /**
   * Server side contract Id of the leg (see ContractMetadata message).
   * It is a required field.
   */
  contractId: number;
  /** Size of the leg. */
  qty: Decimal | undefined;
  /**
   * Price of the leg (in correct format).
   * It is a required field.
   */
  price: number;
  /**
   * Side of the leg, for exchanges that support LegAllocation as independent
   * child of CrossOrderParameters rather than child of SideAllocation.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /**
   * Identifier of the leg allocation record, this field is generated by server.
   * Client may require to set this field to modify/approve existing leg allocation.
   */
  legAllocId: number;
  /**
   * Identifier assigned to a trade by a matching system.
   * This field is only sent from server to client.
   * Check if the field is supported for specific execution system.
   */
  tradeMatchId: string;
}

/**
 * Account related information that is specific for the exchange and/or the
 * brokerage.
 */
export interface ExternalAccount {
  /**
   * Unique identifier of account within the exchange's Member to place order
   * on it.
   * This account can be external for CQG.
   */
  externalAccountNumber: string;
  /**
   * Account type (aka Customer Type Indicator), values are specific per
   * execution systems.
   */
  accountType: string;
  /** Account origin, values are specific per execution systems. */
  accountOrigin: string;
  /**
   * Member ID that 'takes up' this account on the target exchange.
   * Should be considered only if account is 'give up'. Can be empty for
   * 'give up' accounts too, means that Member is not specified for current
   * account.
   */
  giveUpMemberId: string;
}

/**
 * This message is sent by contra to approve the alleged order of CROSS type.
 * See CrossOrderParameters message for details.
 */
export interface ApproveOrder {
  /**
   * Order id assigned by server after last modification.
   * It is a required field.
   */
  orderId: string;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId: number;
  /**
   * This field is associated with Action enum type.
   * APPROVE is used if this field is empty.
   */
  action: number;
  /**
   * Client side time when an approve order request was submitted (UTC).
   * It is a required field.
   */
  whenUtcTimestamp: Date | undefined;
  /**
   * The contra is expected to provide its own side of the cross order in this
   * field.
   * It is a required field.
   */
  sideAllocation: SideAllocation | undefined;
  /** List of extra attributes. */
  extraAttributes: NamedValue[];
}

export enum ApproveOrder_Action {
  /** APPROVE_ORDER_ACTION_APPROVE - Action to approve the cross order. */
  APPROVE_ORDER_ACTION_APPROVE = 0,
  /** APPROVE_ORDER_ACTION_REJECT - Action to reject the cross order. */
  APPROVE_ORDER_ACTION_REJECT = 1,
  UNRECOGNIZED = -1,
}

export function approveOrder_ActionFromJSON(object: any): ApproveOrder_Action {
  switch (object) {
    case 0:
    case 'APPROVE_ORDER_ACTION_APPROVE':
      return ApproveOrder_Action.APPROVE_ORDER_ACTION_APPROVE;
    case 1:
    case 'APPROVE_ORDER_ACTION_REJECT':
      return ApproveOrder_Action.APPROVE_ORDER_ACTION_REJECT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ApproveOrder_Action.UNRECOGNIZED;
  }
}

export function approveOrder_ActionToJSON(object: ApproveOrder_Action): string {
  switch (object) {
    case ApproveOrder_Action.APPROVE_ORDER_ACTION_APPROVE:
      return 'APPROVE_ORDER_ACTION_APPROVE';
    case ApproveOrder_Action.APPROVE_ORDER_ACTION_REJECT:
      return 'APPROVE_ORDER_ACTION_REJECT';
    case ApproveOrder_Action.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Fill specific care order.
 * This message is sent in order to fill care order without sending it to
 * exchange.
 */
export interface FillCareOrder {
  /**
   * Execution ID of the fill.
   * It is a required field.
   */
  execId: string;
  /** Unique id used to identify filled order. */
  fillCareOrderRequestId: string;
  /**
   * Price for the fill in correct price format.
   * It is a required field.
   */
  fillPrice: number;
  /**
   * Quantity to fill for the order.
   * It is a required field.
   */
  fillQty: Decimal | undefined;
  /**
   * Order id being filled. See OrderStatus.order_id.
   * It is a required field.
   */
  orderId: string;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId: number;
  /** Holds information about fill parameters of spread legs. */
  orderLegs: FillCareOrderLeg[];
  /** Holds information about parameters of opposite-side fill. */
  oppositeSide: FillCareOppositeSide | undefined;
}

/** Provides fill parameters of native strategy order's leg during FillCareOrder. */
export interface FillCareOrderLeg {
  /**
   * Execution ID of the leg fill.
   * It is a required field.
   */
  legExecId: string;
  /**
   * Node index of corresponding strategy leg (see LegDefinition.node_index parameter).
   * It is a required field.
   */
  nodeIndex: number;
  /**
   * Price for the leg fill in correct price format.
   * It is a required field.
   */
  fillPrice: number;
  /**
   * Quantity for the leg fill.
   * It is a required field.
   */
  fillQty: Decimal | undefined;
}

/** Provides parameters of opposite-side fill during FillCareOrder. */
export interface FillCareOppositeSide {
  /**
   * Execution ID of opposite-side fill.
   * It is a required field.
   */
  execId: string;
  /**
   * ID of opposite-side care order to fill,
   * Can be ommitted if create_new_order=true.
   */
  orderId: string;
  /** Instruction to create a new order for opposite-side fill. */
  createNewOrder: boolean;
  /**
   * ID of the opposite-side fill's account.
   * It is a required field.
   */
  accountId: number;
  /** Holds information about fill parameters of spread legs. */
  orderLegs: FillCareOrderLeg[];
}

/**
 * Allows to get an order status with all transactions.
 * Works for current day and historical orders, with
 * the exception of unfilled leg orders of
 * non-failed synthetic strategy orders.
 */
export interface OrderStatusRequest {
  /**
   * Chain order id from an order status.
   * See OrderStatus.chain_order_id.
   * It is a required field.
   */
  chainOrderId: string;
  /**
   * ID of the order's account.
   * It is a required field.
   */
  accountId: number;
}

/** Report with requested information in OrderStatusRequest */
export interface OrderStatusReport {
  /** Order status with all transactions, if found. */
  orderStatus: OrderStatus | undefined;
}

function createBaseOrderRequestReject(): OrderRequestReject {
  return { requestId: 0, rejectCode: 0, textMessage: '', details: undefined };
}

export const OrderRequestReject = {
  encode(message: OrderRequestReject, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.rejectCode !== 0) {
      writer.uint32(16).uint32(message.rejectCode);
    }
    if (message.textMessage !== '') {
      writer.uint32(26).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequestReject {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequestReject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.rejectCode = reader.uint32();
          break;
        case 3:
          message.textMessage = reader.string();
          break;
        case 4:
          message.details = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequestReject {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      rejectCode: isSet(object.rejectCode) ? Number(object.rejectCode) : 0,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: OrderRequestReject): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.rejectCode !== undefined && (obj.rejectCode = Math.round(message.rejectCode));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequestReject>, I>>(base?: I): OrderRequestReject {
    return OrderRequestReject.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderRequestReject>, I>>(object: I): OrderRequestReject {
    const message = createBaseOrderRequestReject();
    message.requestId = object.requestId ?? 0;
    message.rejectCode = object.rejectCode ?? 0;
    message.textMessage = object.textMessage ?? '';
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    return message;
  },
};

function createBaseOrderRequestAck(): OrderRequestAck {
  return { requestId: 0, when: undefined };
}

export const OrderRequestAck = {
  encode(message: OrderRequestAck, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.when !== undefined) {
      Timestamp.encode(toTimestamp(message.when), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequestAck {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequestAck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.when = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequestAck {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      when: isSet(object.when) ? fromJsonTimestamp(object.when) : undefined,
    };
  },

  toJSON(message: OrderRequestAck): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.when !== undefined && (obj.when = message.when.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequestAck>, I>>(base?: I): OrderRequestAck {
    return OrderRequestAck.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderRequestAck>, I>>(object: I): OrderRequestAck {
    const message = createBaseOrderRequestAck();
    message.requestId = object.requestId ?? 0;
    message.when = object.when ?? undefined;
    return message;
  },
};

function createBaseOrderStatus(): OrderStatus {
  return {
    subscriptionIds: [],
    isSnapshot: false,
    status: 0,
    orderId: '',
    chainOrderId: '',
    execOrderId: '',
    detailedDomOrderId: '',
    statusUtcTime: 0,
    statusUtcTimestamp: undefined,
    submissionUtcTime: 0,
    submissionUtcTimestamp: undefined,
    uint32FillQty: 0,
    fillQty: undefined,
    fillCnt: 0,
    scaledAvgFillPrice: 0,
    avgFillPriceCorrect: 0,
    activeAtUtcTime: 0,
    activeAtUtcTimestamp: undefined,
    uint32RemainingQty: 0,
    remainingQty: undefined,
    order: undefined,
    transactionStatuses: [],
    rejectUtcTimestamp: undefined,
    rejectMessage: '',
    cancelUtcTimestamp: undefined,
    fillUtcTimestamp: undefined,
    hasFillWithModifiedQty: false,
    hasFillWithModifiedPrice: false,
    enteredByUser: '',
    firstStatementDate: 0,
    lastStatementDate: 0,
    contractMetadata: [],
    accountId: 0,
    compoundOrderStructure: undefined,
    hangCount: 0,
    strategyProperties: undefined,
    crossTransactionId: '',
    exchangeExtraAttributes: [],
    fillCommission: undefined,
    routeClOrderId: '',
    mifidExecutionDecision: '',
    mifidExecutionDecisionIsAlgo: false,
    mifidInvestmentDecision: '',
    mifidInvestmentDecisionIsAlgo: false,
    senderLocationId: '',
    senderOperatorId: '',
    operatorId: '',
    fillCareOrderRequestId: '',
  };
}

export const OrderStatus = {
  encode(message: OrderStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.subscriptionIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.isSnapshot === true) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    if (message.status !== 0) {
      writer.uint32(24).uint32(message.status);
    }
    if (message.orderId !== '') {
      writer.uint32(34).string(message.orderId);
    }
    if (message.chainOrderId !== '') {
      writer.uint32(42).string(message.chainOrderId);
    }
    if (message.execOrderId !== '') {
      writer.uint32(50).string(message.execOrderId);
    }
    if (message.detailedDomOrderId !== '') {
      writer.uint32(290).string(message.detailedDomOrderId);
    }
    if (message.statusUtcTime !== 0) {
      writer.uint32(56).sint64(message.statusUtcTime);
    }
    if (message.statusUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.statusUtcTimestamp), writer.uint32(194).fork()).ldelim();
    }
    if (message.submissionUtcTime !== 0) {
      writer.uint32(64).sint64(message.submissionUtcTime);
    }
    if (message.submissionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.submissionUtcTimestamp), writer.uint32(202).fork()).ldelim();
    }
    if (message.uint32FillQty !== 0) {
      writer.uint32(72).uint32(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(226).fork()).ldelim();
    }
    if (message.fillCnt !== 0) {
      writer.uint32(80).uint32(message.fillCnt);
    }
    if (message.scaledAvgFillPrice !== 0) {
      writer.uint32(88).sint64(message.scaledAvgFillPrice);
    }
    if (message.avgFillPriceCorrect !== 0) {
      writer.uint32(217).double(message.avgFillPriceCorrect);
    }
    if (message.activeAtUtcTime !== 0) {
      writer.uint32(96).sint64(message.activeAtUtcTime);
    }
    if (message.activeAtUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activeAtUtcTimestamp), writer.uint32(210).fork()).ldelim();
    }
    if (message.uint32RemainingQty !== 0) {
      writer.uint32(104).uint32(message.uint32RemainingQty);
    }
    if (message.remainingQty !== undefined) {
      Decimal.encode(message.remainingQty, writer.uint32(234).fork()).ldelim();
    }
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.transactionStatuses) {
      TransactionStatus.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.rejectUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.rejectUtcTimestamp), writer.uint32(242).fork()).ldelim();
    }
    if (message.rejectMessage !== '') {
      writer.uint32(250).string(message.rejectMessage);
    }
    if (message.cancelUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.cancelUtcTimestamp), writer.uint32(258).fork()).ldelim();
    }
    if (message.fillUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.fillUtcTimestamp), writer.uint32(266).fork()).ldelim();
    }
    if (message.hasFillWithModifiedQty === true) {
      writer.uint32(272).bool(message.hasFillWithModifiedQty);
    }
    if (message.hasFillWithModifiedPrice === true) {
      writer.uint32(280).bool(message.hasFillWithModifiedPrice);
    }
    if (message.enteredByUser !== '') {
      writer.uint32(130).string(message.enteredByUser);
    }
    if (message.firstStatementDate !== 0) {
      writer.uint32(136).sint64(message.firstStatementDate);
    }
    if (message.lastStatementDate !== 0) {
      writer.uint32(144).sint64(message.lastStatementDate);
    }
    for (const v of message.contractMetadata) {
      ContractMetadata.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.accountId !== 0) {
      writer.uint32(160).sint32(message.accountId);
    }
    if (message.compoundOrderStructure !== undefined) {
      CompoundOrderStructure.encode(message.compoundOrderStructure, writer.uint32(170).fork()).ldelim();
    }
    if (message.hangCount !== 0) {
      writer.uint32(176).uint32(message.hangCount);
    }
    if (message.strategyProperties !== undefined) {
      SyntheticStrategyProperties.encode(message.strategyProperties, writer.uint32(186).fork()).ldelim();
    }
    if (message.crossTransactionId !== '') {
      writer.uint32(298).string(message.crossTransactionId);
    }
    for (const v of message.exchangeExtraAttributes) {
      NamedValue.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.fillCommission !== undefined) {
      Commission.encode(message.fillCommission, writer.uint32(314).fork()).ldelim();
    }
    if (message.routeClOrderId !== '') {
      writer.uint32(322).string(message.routeClOrderId);
    }
    if (message.mifidExecutionDecision !== '') {
      writer.uint32(330).string(message.mifidExecutionDecision);
    }
    if (message.mifidExecutionDecisionIsAlgo === true) {
      writer.uint32(336).bool(message.mifidExecutionDecisionIsAlgo);
    }
    if (message.mifidInvestmentDecision !== '') {
      writer.uint32(346).string(message.mifidInvestmentDecision);
    }
    if (message.mifidInvestmentDecisionIsAlgo === true) {
      writer.uint32(352).bool(message.mifidInvestmentDecisionIsAlgo);
    }
    if (message.senderLocationId !== '') {
      writer.uint32(362).string(message.senderLocationId);
    }
    if (message.senderOperatorId !== '') {
      writer.uint32(370).string(message.senderOperatorId);
    }
    if (message.operatorId !== '') {
      writer.uint32(378).string(message.operatorId);
    }
    if (message.fillCareOrderRequestId !== '') {
      writer.uint32(386).string(message.fillCareOrderRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.subscriptionIds.push(reader.uint32());
            }
          } else {
            message.subscriptionIds.push(reader.uint32());
          }
          break;
        case 2:
          message.isSnapshot = reader.bool();
          break;
        case 3:
          message.status = reader.uint32();
          break;
        case 4:
          message.orderId = reader.string();
          break;
        case 5:
          message.chainOrderId = reader.string();
          break;
        case 6:
          message.execOrderId = reader.string();
          break;
        case 36:
          message.detailedDomOrderId = reader.string();
          break;
        case 7:
          message.statusUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 24:
          message.statusUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.submissionUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 25:
          message.submissionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.uint32FillQty = reader.uint32();
          break;
        case 28:
          message.fillQty = Decimal.decode(reader, reader.uint32());
          break;
        case 10:
          message.fillCnt = reader.uint32();
          break;
        case 11:
          message.scaledAvgFillPrice = longToNumber(reader.sint64() as Long);
          break;
        case 27:
          message.avgFillPriceCorrect = reader.double();
          break;
        case 12:
          message.activeAtUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 26:
          message.activeAtUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 13:
          message.uint32RemainingQty = reader.uint32();
          break;
        case 29:
          message.remainingQty = Decimal.decode(reader, reader.uint32());
          break;
        case 14:
          message.order = Order.decode(reader, reader.uint32());
          break;
        case 15:
          message.transactionStatuses.push(TransactionStatus.decode(reader, reader.uint32()));
          break;
        case 30:
          message.rejectUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 31:
          message.rejectMessage = reader.string();
          break;
        case 32:
          message.cancelUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 33:
          message.fillUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 34:
          message.hasFillWithModifiedQty = reader.bool();
          break;
        case 35:
          message.hasFillWithModifiedPrice = reader.bool();
          break;
        case 16:
          message.enteredByUser = reader.string();
          break;
        case 17:
          message.firstStatementDate = longToNumber(reader.sint64() as Long);
          break;
        case 18:
          message.lastStatementDate = longToNumber(reader.sint64() as Long);
          break;
        case 19:
          message.contractMetadata.push(ContractMetadata.decode(reader, reader.uint32()));
          break;
        case 20:
          message.accountId = reader.sint32();
          break;
        case 21:
          message.compoundOrderStructure = CompoundOrderStructure.decode(reader, reader.uint32());
          break;
        case 22:
          message.hangCount = reader.uint32();
          break;
        case 23:
          message.strategyProperties = SyntheticStrategyProperties.decode(reader, reader.uint32());
          break;
        case 37:
          message.crossTransactionId = reader.string();
          break;
        case 38:
          message.exchangeExtraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        case 39:
          message.fillCommission = Commission.decode(reader, reader.uint32());
          break;
        case 40:
          message.routeClOrderId = reader.string();
          break;
        case 41:
          message.mifidExecutionDecision = reader.string();
          break;
        case 42:
          message.mifidExecutionDecisionIsAlgo = reader.bool();
          break;
        case 43:
          message.mifidInvestmentDecision = reader.string();
          break;
        case 44:
          message.mifidInvestmentDecisionIsAlgo = reader.bool();
          break;
        case 45:
          message.senderLocationId = reader.string();
          break;
        case 46:
          message.senderOperatorId = reader.string();
          break;
        case 47:
          message.operatorId = reader.string();
          break;
        case 48:
          message.fillCareOrderRequestId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderStatus {
    return {
      subscriptionIds: Array.isArray(object?.subscriptionIds) ? object.subscriptionIds.map((e: any) => Number(e)) : [],
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      status: isSet(object.status) ? Number(object.status) : 0,
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      execOrderId: isSet(object.execOrderId) ? String(object.execOrderId) : '',
      detailedDomOrderId: isSet(object.detailedDomOrderId) ? String(object.detailedDomOrderId) : '',
      statusUtcTime: isSet(object.statusUtcTime) ? Number(object.statusUtcTime) : 0,
      statusUtcTimestamp: isSet(object.statusUtcTimestamp) ? fromJsonTimestamp(object.statusUtcTimestamp) : undefined,
      submissionUtcTime: isSet(object.submissionUtcTime) ? Number(object.submissionUtcTime) : 0,
      submissionUtcTimestamp: isSet(object.submissionUtcTimestamp) ? fromJsonTimestamp(object.submissionUtcTimestamp) : undefined,
      uint32FillQty: isSet(object.uint32FillQty) ? Number(object.uint32FillQty) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      fillCnt: isSet(object.fillCnt) ? Number(object.fillCnt) : 0,
      scaledAvgFillPrice: isSet(object.scaledAvgFillPrice) ? Number(object.scaledAvgFillPrice) : 0,
      avgFillPriceCorrect: isSet(object.avgFillPriceCorrect) ? Number(object.avgFillPriceCorrect) : 0,
      activeAtUtcTime: isSet(object.activeAtUtcTime) ? Number(object.activeAtUtcTime) : 0,
      activeAtUtcTimestamp: isSet(object.activeAtUtcTimestamp) ? fromJsonTimestamp(object.activeAtUtcTimestamp) : undefined,
      uint32RemainingQty: isSet(object.uint32RemainingQty) ? Number(object.uint32RemainingQty) : 0,
      remainingQty: isSet(object.remainingQty) ? Decimal.fromJSON(object.remainingQty) : undefined,
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      transactionStatuses: Array.isArray(object?.transactionStatuses)
        ? object.transactionStatuses.map((e: any) => TransactionStatus.fromJSON(e))
        : [],
      rejectUtcTimestamp: isSet(object.rejectUtcTimestamp) ? fromJsonTimestamp(object.rejectUtcTimestamp) : undefined,
      rejectMessage: isSet(object.rejectMessage) ? String(object.rejectMessage) : '',
      cancelUtcTimestamp: isSet(object.cancelUtcTimestamp) ? fromJsonTimestamp(object.cancelUtcTimestamp) : undefined,
      fillUtcTimestamp: isSet(object.fillUtcTimestamp) ? fromJsonTimestamp(object.fillUtcTimestamp) : undefined,
      hasFillWithModifiedQty: isSet(object.hasFillWithModifiedQty) ? Boolean(object.hasFillWithModifiedQty) : false,
      hasFillWithModifiedPrice: isSet(object.hasFillWithModifiedPrice) ? Boolean(object.hasFillWithModifiedPrice) : false,
      enteredByUser: isSet(object.enteredByUser) ? String(object.enteredByUser) : '',
      firstStatementDate: isSet(object.firstStatementDate) ? Number(object.firstStatementDate) : 0,
      lastStatementDate: isSet(object.lastStatementDate) ? Number(object.lastStatementDate) : 0,
      contractMetadata: Array.isArray(object?.contractMetadata)
        ? object.contractMetadata.map((e: any) => ContractMetadata.fromJSON(e))
        : [],
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      compoundOrderStructure: isSet(object.compoundOrderStructure)
        ? CompoundOrderStructure.fromJSON(object.compoundOrderStructure)
        : undefined,
      hangCount: isSet(object.hangCount) ? Number(object.hangCount) : 0,
      strategyProperties: isSet(object.strategyProperties)
        ? SyntheticStrategyProperties.fromJSON(object.strategyProperties)
        : undefined,
      crossTransactionId: isSet(object.crossTransactionId) ? String(object.crossTransactionId) : '',
      exchangeExtraAttributes: Array.isArray(object?.exchangeExtraAttributes)
        ? object.exchangeExtraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      fillCommission: isSet(object.fillCommission) ? Commission.fromJSON(object.fillCommission) : undefined,
      routeClOrderId: isSet(object.routeClOrderId) ? String(object.routeClOrderId) : '',
      mifidExecutionDecision: isSet(object.mifidExecutionDecision) ? String(object.mifidExecutionDecision) : '',
      mifidExecutionDecisionIsAlgo: isSet(object.mifidExecutionDecisionIsAlgo)
        ? Boolean(object.mifidExecutionDecisionIsAlgo)
        : false,
      mifidInvestmentDecision: isSet(object.mifidInvestmentDecision) ? String(object.mifidInvestmentDecision) : '',
      mifidInvestmentDecisionIsAlgo: isSet(object.mifidInvestmentDecisionIsAlgo)
        ? Boolean(object.mifidInvestmentDecisionIsAlgo)
        : false,
      senderLocationId: isSet(object.senderLocationId) ? String(object.senderLocationId) : '',
      senderOperatorId: isSet(object.senderOperatorId) ? String(object.senderOperatorId) : '',
      operatorId: isSet(object.operatorId) ? String(object.operatorId) : '',
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId) ? String(object.fillCareOrderRequestId) : '',
    };
  },

  toJSON(message: OrderStatus): unknown {
    const obj: any = {};
    if (message.subscriptionIds) {
      obj.subscriptionIds = message.subscriptionIds.map((e) => Math.round(e));
    } else {
      obj.subscriptionIds = [];
    }
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    message.status !== undefined && (obj.status = Math.round(message.status));
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.execOrderId !== undefined && (obj.execOrderId = message.execOrderId);
    message.detailedDomOrderId !== undefined && (obj.detailedDomOrderId = message.detailedDomOrderId);
    message.statusUtcTime !== undefined && (obj.statusUtcTime = Math.round(message.statusUtcTime));
    message.statusUtcTimestamp !== undefined && (obj.statusUtcTimestamp = message.statusUtcTimestamp.toISOString());
    message.submissionUtcTime !== undefined && (obj.submissionUtcTime = Math.round(message.submissionUtcTime));
    message.submissionUtcTimestamp !== undefined && (obj.submissionUtcTimestamp = message.submissionUtcTimestamp.toISOString());
    message.uint32FillQty !== undefined && (obj.uint32FillQty = Math.round(message.uint32FillQty));
    message.fillQty !== undefined && (obj.fillQty = message.fillQty ? Decimal.toJSON(message.fillQty) : undefined);
    message.fillCnt !== undefined && (obj.fillCnt = Math.round(message.fillCnt));
    message.scaledAvgFillPrice !== undefined && (obj.scaledAvgFillPrice = Math.round(message.scaledAvgFillPrice));
    message.avgFillPriceCorrect !== undefined && (obj.avgFillPriceCorrect = message.avgFillPriceCorrect);
    message.activeAtUtcTime !== undefined && (obj.activeAtUtcTime = Math.round(message.activeAtUtcTime));
    message.activeAtUtcTimestamp !== undefined && (obj.activeAtUtcTimestamp = message.activeAtUtcTimestamp.toISOString());
    message.uint32RemainingQty !== undefined && (obj.uint32RemainingQty = Math.round(message.uint32RemainingQty));
    message.remainingQty !== undefined &&
      (obj.remainingQty = message.remainingQty ? Decimal.toJSON(message.remainingQty) : undefined);
    message.order !== undefined && (obj.order = message.order ? Order.toJSON(message.order) : undefined);
    if (message.transactionStatuses) {
      obj.transactionStatuses = message.transactionStatuses.map((e) => (e ? TransactionStatus.toJSON(e) : undefined));
    } else {
      obj.transactionStatuses = [];
    }
    message.rejectUtcTimestamp !== undefined && (obj.rejectUtcTimestamp = message.rejectUtcTimestamp.toISOString());
    message.rejectMessage !== undefined && (obj.rejectMessage = message.rejectMessage);
    message.cancelUtcTimestamp !== undefined && (obj.cancelUtcTimestamp = message.cancelUtcTimestamp.toISOString());
    message.fillUtcTimestamp !== undefined && (obj.fillUtcTimestamp = message.fillUtcTimestamp.toISOString());
    message.hasFillWithModifiedQty !== undefined && (obj.hasFillWithModifiedQty = message.hasFillWithModifiedQty);
    message.hasFillWithModifiedPrice !== undefined && (obj.hasFillWithModifiedPrice = message.hasFillWithModifiedPrice);
    message.enteredByUser !== undefined && (obj.enteredByUser = message.enteredByUser);
    message.firstStatementDate !== undefined && (obj.firstStatementDate = Math.round(message.firstStatementDate));
    message.lastStatementDate !== undefined && (obj.lastStatementDate = Math.round(message.lastStatementDate));
    if (message.contractMetadata) {
      obj.contractMetadata = message.contractMetadata.map((e) => (e ? ContractMetadata.toJSON(e) : undefined));
    } else {
      obj.contractMetadata = [];
    }
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.compoundOrderStructure !== undefined &&
      (obj.compoundOrderStructure = message.compoundOrderStructure
        ? CompoundOrderStructure.toJSON(message.compoundOrderStructure)
        : undefined);
    message.hangCount !== undefined && (obj.hangCount = Math.round(message.hangCount));
    message.strategyProperties !== undefined &&
      (obj.strategyProperties = message.strategyProperties
        ? SyntheticStrategyProperties.toJSON(message.strategyProperties)
        : undefined);
    message.crossTransactionId !== undefined && (obj.crossTransactionId = message.crossTransactionId);
    if (message.exchangeExtraAttributes) {
      obj.exchangeExtraAttributes = message.exchangeExtraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.exchangeExtraAttributes = [];
    }
    message.fillCommission !== undefined &&
      (obj.fillCommission = message.fillCommission ? Commission.toJSON(message.fillCommission) : undefined);
    message.routeClOrderId !== undefined && (obj.routeClOrderId = message.routeClOrderId);
    message.mifidExecutionDecision !== undefined && (obj.mifidExecutionDecision = message.mifidExecutionDecision);
    message.mifidExecutionDecisionIsAlgo !== undefined &&
      (obj.mifidExecutionDecisionIsAlgo = message.mifidExecutionDecisionIsAlgo);
    message.mifidInvestmentDecision !== undefined && (obj.mifidInvestmentDecision = message.mifidInvestmentDecision);
    message.mifidInvestmentDecisionIsAlgo !== undefined &&
      (obj.mifidInvestmentDecisionIsAlgo = message.mifidInvestmentDecisionIsAlgo);
    message.senderLocationId !== undefined && (obj.senderLocationId = message.senderLocationId);
    message.senderOperatorId !== undefined && (obj.senderOperatorId = message.senderOperatorId);
    message.operatorId !== undefined && (obj.operatorId = message.operatorId);
    message.fillCareOrderRequestId !== undefined && (obj.fillCareOrderRequestId = message.fillCareOrderRequestId);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatus>, I>>(base?: I): OrderStatus {
    return OrderStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderStatus>, I>>(object: I): OrderStatus {
    const message = createBaseOrderStatus();
    message.subscriptionIds = object.subscriptionIds?.map((e) => e) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.status = object.status ?? 0;
    message.orderId = object.orderId ?? '';
    message.chainOrderId = object.chainOrderId ?? '';
    message.execOrderId = object.execOrderId ?? '';
    message.detailedDomOrderId = object.detailedDomOrderId ?? '';
    message.statusUtcTime = object.statusUtcTime ?? 0;
    message.statusUtcTimestamp = object.statusUtcTimestamp ?? undefined;
    message.submissionUtcTime = object.submissionUtcTime ?? 0;
    message.submissionUtcTimestamp = object.submissionUtcTimestamp ?? undefined;
    message.uint32FillQty = object.uint32FillQty ?? 0;
    message.fillQty = object.fillQty !== undefined && object.fillQty !== null ? Decimal.fromPartial(object.fillQty) : undefined;
    message.fillCnt = object.fillCnt ?? 0;
    message.scaledAvgFillPrice = object.scaledAvgFillPrice ?? 0;
    message.avgFillPriceCorrect = object.avgFillPriceCorrect ?? 0;
    message.activeAtUtcTime = object.activeAtUtcTime ?? 0;
    message.activeAtUtcTimestamp = object.activeAtUtcTimestamp ?? undefined;
    message.uint32RemainingQty = object.uint32RemainingQty ?? 0;
    message.remainingQty =
      object.remainingQty !== undefined && object.remainingQty !== null ? Decimal.fromPartial(object.remainingQty) : undefined;
    message.order = object.order !== undefined && object.order !== null ? Order.fromPartial(object.order) : undefined;
    message.transactionStatuses = object.transactionStatuses?.map((e) => TransactionStatus.fromPartial(e)) || [];
    message.rejectUtcTimestamp = object.rejectUtcTimestamp ?? undefined;
    message.rejectMessage = object.rejectMessage ?? '';
    message.cancelUtcTimestamp = object.cancelUtcTimestamp ?? undefined;
    message.fillUtcTimestamp = object.fillUtcTimestamp ?? undefined;
    message.hasFillWithModifiedQty = object.hasFillWithModifiedQty ?? false;
    message.hasFillWithModifiedPrice = object.hasFillWithModifiedPrice ?? false;
    message.enteredByUser = object.enteredByUser ?? '';
    message.firstStatementDate = object.firstStatementDate ?? 0;
    message.lastStatementDate = object.lastStatementDate ?? 0;
    message.contractMetadata = object.contractMetadata?.map((e) => ContractMetadata.fromPartial(e)) || [];
    message.accountId = object.accountId ?? 0;
    message.compoundOrderStructure =
      object.compoundOrderStructure !== undefined && object.compoundOrderStructure !== null
        ? CompoundOrderStructure.fromPartial(object.compoundOrderStructure)
        : undefined;
    message.hangCount = object.hangCount ?? 0;
    message.strategyProperties =
      object.strategyProperties !== undefined && object.strategyProperties !== null
        ? SyntheticStrategyProperties.fromPartial(object.strategyProperties)
        : undefined;
    message.crossTransactionId = object.crossTransactionId ?? '';
    message.exchangeExtraAttributes = object.exchangeExtraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.fillCommission =
      object.fillCommission !== undefined && object.fillCommission !== null
        ? Commission.fromPartial(object.fillCommission)
        : undefined;
    message.routeClOrderId = object.routeClOrderId ?? '';
    message.mifidExecutionDecision = object.mifidExecutionDecision ?? '';
    message.mifidExecutionDecisionIsAlgo = object.mifidExecutionDecisionIsAlgo ?? false;
    message.mifidInvestmentDecision = object.mifidInvestmentDecision ?? '';
    message.mifidInvestmentDecisionIsAlgo = object.mifidInvestmentDecisionIsAlgo ?? false;
    message.senderLocationId = object.senderLocationId ?? '';
    message.senderOperatorId = object.senderOperatorId ?? '';
    message.operatorId = object.operatorId ?? '';
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? '';
    return message;
  },
};

function createBaseTransactionStatus(): TransactionStatus {
  return {
    status: 0,
    transId: 0,
    transUtcTime: 0,
    transUtcTimestamp: undefined,
    refTransId: 0,
    origClOrderId: '',
    clOrderId: '',
    uint32FillQty: 0,
    fillQty: undefined,
    scaledFillPrice: 0,
    trades: [],
    strategyLegFills: [],
    syntheticHang: undefined,
    uint32PrevOrderQty: 0,
    prevOrderQty: undefined,
    uint32OrderQty: 0,
    orderQty: undefined,
    scaledPrevLimitPrice: 0,
    scaledLimitPrice: 0,
    scaledPrevStopPrice: 0,
    scaledStopPrice: 0,
    uint32PrevVisibleQty: 0,
    prevVisibleQty: undefined,
    uint32VisibleQty: 0,
    visibleQty: undefined,
    uint32PrevMinVisibleQty: 0,
    prevMinVisibleQty: undefined,
    uint32MinVisibleQty: 0,
    minVisibleQty: undefined,
    uint32PrevFillQty: 0,
    prevFillQty: undefined,
    scaledPrevFillPrice: 0,
    prevOrderType: 0,
    orderType: 0,
    prevExecInstructions: [],
    execInstructions: [],
    prevDuration: 0,
    duration: 0,
    prevGoodThruDate: 0,
    goodThruDate: 0,
    rejectCode: 0,
    fillCounterparty: '',
    routeClOrderId: '',
    textMessage: '',
    prevGoodThruUtcTime: 0,
    prevGoodThruUtcTimestamp: undefined,
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    isAggressive: false,
    clientRegulatoryAlgorithmId: 0,
    effectiveRegulatoryAlgorithmId: 0,
    mifidAlgorithmId: '',
    mifidAlgorithmIdType: 0,
    mifidAppliedAlgorithmId: '',
    mifidAppliedAlgorithmIdType: 0,
    mifidExecutionDecision: '',
    mifidExecutionDecisionIsAlgo: false,
    openCloseEffect: 0,
    speculationType: 0,
    fillCommission: undefined,
    mifidInvestmentDecision: '',
    mifidInvestmentDecisionIsAlgo: false,
    executionSourceCode: '',
    isAutomated: false,
    tradeMatchId: '',
    fillCareOrderRequestId: '',
    username: '',
  };
}

export const TransactionStatus = {
  encode(message: TransactionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).uint32(message.status);
    }
    if (message.transId !== 0) {
      writer.uint32(16).uint64(message.transId);
    }
    if (message.transUtcTime !== 0) {
      writer.uint32(24).sint64(message.transUtcTime);
    }
    if (message.transUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.transUtcTimestamp), writer.uint32(322).fork()).ldelim();
    }
    if (message.refTransId !== 0) {
      writer.uint32(32).uint64(message.refTransId);
    }
    if (message.origClOrderId !== '') {
      writer.uint32(42).string(message.origClOrderId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(50).string(message.clOrderId);
    }
    if (message.uint32FillQty !== 0) {
      writer.uint32(56).uint32(message.uint32FillQty);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(418).fork()).ldelim();
    }
    if (message.scaledFillPrice !== 0) {
      writer.uint32(64).sint64(message.scaledFillPrice);
    }
    for (const v of message.trades) {
      Trade.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.strategyLegFills) {
      StrategyLegFill.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.syntheticHang !== undefined) {
      SyntheticHang.encode(message.syntheticHang, writer.uint32(314).fork()).ldelim();
    }
    if (message.uint32PrevOrderQty !== 0) {
      writer.uint32(80).uint32(message.uint32PrevOrderQty);
    }
    if (message.prevOrderQty !== undefined) {
      Decimal.encode(message.prevOrderQty, writer.uint32(426).fork()).ldelim();
    }
    if (message.uint32OrderQty !== 0) {
      writer.uint32(88).uint32(message.uint32OrderQty);
    }
    if (message.orderQty !== undefined) {
      Decimal.encode(message.orderQty, writer.uint32(434).fork()).ldelim();
    }
    if (message.scaledPrevLimitPrice !== 0) {
      writer.uint32(96).sint64(message.scaledPrevLimitPrice);
    }
    if (message.scaledLimitPrice !== 0) {
      writer.uint32(104).sint64(message.scaledLimitPrice);
    }
    if (message.scaledPrevStopPrice !== 0) {
      writer.uint32(112).sint64(message.scaledPrevStopPrice);
    }
    if (message.scaledStopPrice !== 0) {
      writer.uint32(120).sint64(message.scaledStopPrice);
    }
    if (message.uint32PrevVisibleQty !== 0) {
      writer.uint32(128).uint32(message.uint32PrevVisibleQty);
    }
    if (message.prevVisibleQty !== undefined) {
      Decimal.encode(message.prevVisibleQty, writer.uint32(442).fork()).ldelim();
    }
    if (message.uint32VisibleQty !== 0) {
      writer.uint32(136).uint32(message.uint32VisibleQty);
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(450).fork()).ldelim();
    }
    if (message.uint32PrevMinVisibleQty !== 0) {
      writer.uint32(144).uint32(message.uint32PrevMinVisibleQty);
    }
    if (message.prevMinVisibleQty !== undefined) {
      Decimal.encode(message.prevMinVisibleQty, writer.uint32(458).fork()).ldelim();
    }
    if (message.uint32MinVisibleQty !== 0) {
      writer.uint32(152).uint32(message.uint32MinVisibleQty);
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(466).fork()).ldelim();
    }
    if (message.uint32PrevFillQty !== 0) {
      writer.uint32(160).uint32(message.uint32PrevFillQty);
    }
    if (message.prevFillQty !== undefined) {
      Decimal.encode(message.prevFillQty, writer.uint32(474).fork()).ldelim();
    }
    if (message.scaledPrevFillPrice !== 0) {
      writer.uint32(168).sint64(message.scaledPrevFillPrice);
    }
    if (message.prevOrderType !== 0) {
      writer.uint32(176).uint32(message.prevOrderType);
    }
    if (message.orderType !== 0) {
      writer.uint32(184).uint32(message.orderType);
    }
    writer.uint32(194).fork();
    for (const v of message.prevExecInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(202).fork();
    for (const v of message.execInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.prevDuration !== 0) {
      writer.uint32(208).uint32(message.prevDuration);
    }
    if (message.duration !== 0) {
      writer.uint32(216).uint32(message.duration);
    }
    if (message.prevGoodThruDate !== 0) {
      writer.uint32(224).sint64(message.prevGoodThruDate);
    }
    if (message.goodThruDate !== 0) {
      writer.uint32(232).sint64(message.goodThruDate);
    }
    if (message.rejectCode !== 0) {
      writer.uint32(240).uint32(message.rejectCode);
    }
    if (message.fillCounterparty !== '') {
      writer.uint32(250).string(message.fillCounterparty);
    }
    if (message.routeClOrderId !== '') {
      writer.uint32(258).string(message.routeClOrderId);
    }
    if (message.textMessage !== '') {
      writer.uint32(266).string(message.textMessage);
    }
    if (message.prevGoodThruUtcTime !== 0) {
      writer.uint32(272).sint64(message.prevGoodThruUtcTime);
    }
    if (message.prevGoodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.prevGoodThruUtcTimestamp), writer.uint32(330).fork()).ldelim();
    }
    if (message.goodThruUtcTime !== 0) {
      writer.uint32(280).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(338).fork()).ldelim();
    }
    if (message.isAggressive === true) {
      writer.uint32(512).bool(message.isAggressive);
    }
    if (message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(288).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.effectiveRegulatoryAlgorithmId !== 0) {
      writer.uint32(296).uint32(message.effectiveRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== '') {
      writer.uint32(346).string(message.mifidAlgorithmId);
    }
    if (message.mifidAlgorithmIdType !== 0) {
      writer.uint32(352).uint32(message.mifidAlgorithmIdType);
    }
    if (message.mifidAppliedAlgorithmId !== '') {
      writer.uint32(362).string(message.mifidAppliedAlgorithmId);
    }
    if (message.mifidAppliedAlgorithmIdType !== 0) {
      writer.uint32(368).uint32(message.mifidAppliedAlgorithmIdType);
    }
    if (message.mifidExecutionDecision !== '') {
      writer.uint32(378).string(message.mifidExecutionDecision);
    }
    if (message.mifidExecutionDecisionIsAlgo === true) {
      writer.uint32(536).bool(message.mifidExecutionDecisionIsAlgo);
    }
    if (message.openCloseEffect !== 0) {
      writer.uint32(384).uint32(message.openCloseEffect);
    }
    if (message.speculationType !== 0) {
      writer.uint32(392).uint32(message.speculationType);
    }
    if (message.fillCommission !== undefined) {
      Commission.encode(message.fillCommission, writer.uint32(482).fork()).ldelim();
    }
    if (message.mifidInvestmentDecision !== '') {
      writer.uint32(490).string(message.mifidInvestmentDecision);
    }
    if (message.mifidInvestmentDecisionIsAlgo === true) {
      writer.uint32(496).bool(message.mifidInvestmentDecisionIsAlgo);
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(506).string(message.executionSourceCode);
    }
    if (message.isAutomated === true) {
      writer.uint32(520).bool(message.isAutomated);
    }
    if (message.tradeMatchId !== '') {
      writer.uint32(530).string(message.tradeMatchId);
    }
    if (message.fillCareOrderRequestId !== '') {
      writer.uint32(546).string(message.fillCareOrderRequestId);
    }
    if (message.username !== '') {
      writer.uint32(554).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.uint32();
          break;
        case 2:
          message.transId = longToNumber(reader.uint64() as Long);
          break;
        case 3:
          message.transUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 40:
          message.transUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.refTransId = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.origClOrderId = reader.string();
          break;
        case 6:
          message.clOrderId = reader.string();
          break;
        case 7:
          message.uint32FillQty = reader.uint32();
          break;
        case 52:
          message.fillQty = Decimal.decode(reader, reader.uint32());
          break;
        case 8:
          message.scaledFillPrice = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.trades.push(Trade.decode(reader, reader.uint32()));
          break;
        case 38:
          message.strategyLegFills.push(StrategyLegFill.decode(reader, reader.uint32()));
          break;
        case 39:
          message.syntheticHang = SyntheticHang.decode(reader, reader.uint32());
          break;
        case 10:
          message.uint32PrevOrderQty = reader.uint32();
          break;
        case 53:
          message.prevOrderQty = Decimal.decode(reader, reader.uint32());
          break;
        case 11:
          message.uint32OrderQty = reader.uint32();
          break;
        case 54:
          message.orderQty = Decimal.decode(reader, reader.uint32());
          break;
        case 12:
          message.scaledPrevLimitPrice = longToNumber(reader.sint64() as Long);
          break;
        case 13:
          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          break;
        case 14:
          message.scaledPrevStopPrice = longToNumber(reader.sint64() as Long);
          break;
        case 15:
          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          break;
        case 16:
          message.uint32PrevVisibleQty = reader.uint32();
          break;
        case 55:
          message.prevVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 17:
          message.uint32VisibleQty = reader.uint32();
          break;
        case 56:
          message.visibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 18:
          message.uint32PrevMinVisibleQty = reader.uint32();
          break;
        case 57:
          message.prevMinVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 19:
          message.uint32MinVisibleQty = reader.uint32();
          break;
        case 58:
          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 20:
          message.uint32PrevFillQty = reader.uint32();
          break;
        case 59:
          message.prevFillQty = Decimal.decode(reader, reader.uint32());
          break;
        case 21:
          message.scaledPrevFillPrice = longToNumber(reader.sint64() as Long);
          break;
        case 22:
          message.prevOrderType = reader.uint32();
          break;
        case 23:
          message.orderType = reader.uint32();
          break;
        case 24:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.prevExecInstructions.push(reader.uint32());
            }
          } else {
            message.prevExecInstructions.push(reader.uint32());
          }
          break;
        case 25:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstructions.push(reader.uint32());
            }
          } else {
            message.execInstructions.push(reader.uint32());
          }
          break;
        case 26:
          message.prevDuration = reader.uint32();
          break;
        case 27:
          message.duration = reader.uint32();
          break;
        case 28:
          message.prevGoodThruDate = longToNumber(reader.sint64() as Long);
          break;
        case 29:
          message.goodThruDate = longToNumber(reader.sint64() as Long);
          break;
        case 30:
          message.rejectCode = reader.uint32();
          break;
        case 31:
          message.fillCounterparty = reader.string();
          break;
        case 32:
          message.routeClOrderId = reader.string();
          break;
        case 33:
          message.textMessage = reader.string();
          break;
        case 34:
          message.prevGoodThruUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 41:
          message.prevGoodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 35:
          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 42:
          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 64:
          message.isAggressive = reader.bool();
          break;
        case 36:
          message.clientRegulatoryAlgorithmId = reader.uint32();
          break;
        case 37:
          message.effectiveRegulatoryAlgorithmId = reader.uint32();
          break;
        case 43:
          message.mifidAlgorithmId = reader.string();
          break;
        case 44:
          message.mifidAlgorithmIdType = reader.uint32();
          break;
        case 45:
          message.mifidAppliedAlgorithmId = reader.string();
          break;
        case 46:
          message.mifidAppliedAlgorithmIdType = reader.uint32();
          break;
        case 47:
          message.mifidExecutionDecision = reader.string();
          break;
        case 67:
          message.mifidExecutionDecisionIsAlgo = reader.bool();
          break;
        case 48:
          message.openCloseEffect = reader.uint32();
          break;
        case 49:
          message.speculationType = reader.uint32();
          break;
        case 60:
          message.fillCommission = Commission.decode(reader, reader.uint32());
          break;
        case 61:
          message.mifidInvestmentDecision = reader.string();
          break;
        case 62:
          message.mifidInvestmentDecisionIsAlgo = reader.bool();
          break;
        case 63:
          message.executionSourceCode = reader.string();
          break;
        case 65:
          message.isAutomated = reader.bool();
          break;
        case 66:
          message.tradeMatchId = reader.string();
          break;
        case 68:
          message.fillCareOrderRequestId = reader.string();
          break;
        case 69:
          message.username = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TransactionStatus {
    return {
      status: isSet(object.status) ? Number(object.status) : 0,
      transId: isSet(object.transId) ? Number(object.transId) : 0,
      transUtcTime: isSet(object.transUtcTime) ? Number(object.transUtcTime) : 0,
      transUtcTimestamp: isSet(object.transUtcTimestamp) ? fromJsonTimestamp(object.transUtcTimestamp) : undefined,
      refTransId: isSet(object.refTransId) ? Number(object.refTransId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? String(object.origClOrderId) : '',
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      uint32FillQty: isSet(object.uint32FillQty) ? Number(object.uint32FillQty) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      scaledFillPrice: isSet(object.scaledFillPrice) ? Number(object.scaledFillPrice) : 0,
      trades: Array.isArray(object?.trades) ? object.trades.map((e: any) => Trade.fromJSON(e)) : [],
      strategyLegFills: Array.isArray(object?.strategyLegFills)
        ? object.strategyLegFills.map((e: any) => StrategyLegFill.fromJSON(e))
        : [],
      syntheticHang: isSet(object.syntheticHang) ? SyntheticHang.fromJSON(object.syntheticHang) : undefined,
      uint32PrevOrderQty: isSet(object.uint32PrevOrderQty) ? Number(object.uint32PrevOrderQty) : 0,
      prevOrderQty: isSet(object.prevOrderQty) ? Decimal.fromJSON(object.prevOrderQty) : undefined,
      uint32OrderQty: isSet(object.uint32OrderQty) ? Number(object.uint32OrderQty) : 0,
      orderQty: isSet(object.orderQty) ? Decimal.fromJSON(object.orderQty) : undefined,
      scaledPrevLimitPrice: isSet(object.scaledPrevLimitPrice) ? Number(object.scaledPrevLimitPrice) : 0,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? Number(object.scaledLimitPrice) : 0,
      scaledPrevStopPrice: isSet(object.scaledPrevStopPrice) ? Number(object.scaledPrevStopPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? Number(object.scaledStopPrice) : 0,
      uint32PrevVisibleQty: isSet(object.uint32PrevVisibleQty) ? Number(object.uint32PrevVisibleQty) : 0,
      prevVisibleQty: isSet(object.prevVisibleQty) ? Decimal.fromJSON(object.prevVisibleQty) : undefined,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? Number(object.uint32VisibleQty) : 0,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      uint32PrevMinVisibleQty: isSet(object.uint32PrevMinVisibleQty) ? Number(object.uint32PrevMinVisibleQty) : 0,
      prevMinVisibleQty: isSet(object.prevMinVisibleQty) ? Decimal.fromJSON(object.prevMinVisibleQty) : undefined,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? Number(object.uint32MinVisibleQty) : 0,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      uint32PrevFillQty: isSet(object.uint32PrevFillQty) ? Number(object.uint32PrevFillQty) : 0,
      prevFillQty: isSet(object.prevFillQty) ? Decimal.fromJSON(object.prevFillQty) : undefined,
      scaledPrevFillPrice: isSet(object.scaledPrevFillPrice) ? Number(object.scaledPrevFillPrice) : 0,
      prevOrderType: isSet(object.prevOrderType) ? Number(object.prevOrderType) : 0,
      orderType: isSet(object.orderType) ? Number(object.orderType) : 0,
      prevExecInstructions: Array.isArray(object?.prevExecInstructions)
        ? object.prevExecInstructions.map((e: any) => Number(e))
        : [],
      execInstructions: Array.isArray(object?.execInstructions) ? object.execInstructions.map((e: any) => Number(e)) : [],
      prevDuration: isSet(object.prevDuration) ? Number(object.prevDuration) : 0,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      prevGoodThruDate: isSet(object.prevGoodThruDate) ? Number(object.prevGoodThruDate) : 0,
      goodThruDate: isSet(object.goodThruDate) ? Number(object.goodThruDate) : 0,
      rejectCode: isSet(object.rejectCode) ? Number(object.rejectCode) : 0,
      fillCounterparty: isSet(object.fillCounterparty) ? String(object.fillCounterparty) : '',
      routeClOrderId: isSet(object.routeClOrderId) ? String(object.routeClOrderId) : '',
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      prevGoodThruUtcTime: isSet(object.prevGoodThruUtcTime) ? Number(object.prevGoodThruUtcTime) : 0,
      prevGoodThruUtcTimestamp: isSet(object.prevGoodThruUtcTimestamp)
        ? fromJsonTimestamp(object.prevGoodThruUtcTimestamp)
        : undefined,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp) ? fromJsonTimestamp(object.goodThruUtcTimestamp) : undefined,
      isAggressive: isSet(object.isAggressive) ? Boolean(object.isAggressive) : false,
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId) ? Number(object.clientRegulatoryAlgorithmId) : 0,
      effectiveRegulatoryAlgorithmId: isSet(object.effectiveRegulatoryAlgorithmId)
        ? Number(object.effectiveRegulatoryAlgorithmId)
        : 0,
      mifidAlgorithmId: isSet(object.mifidAlgorithmId) ? String(object.mifidAlgorithmId) : '',
      mifidAlgorithmIdType: isSet(object.mifidAlgorithmIdType) ? Number(object.mifidAlgorithmIdType) : 0,
      mifidAppliedAlgorithmId: isSet(object.mifidAppliedAlgorithmId) ? String(object.mifidAppliedAlgorithmId) : '',
      mifidAppliedAlgorithmIdType: isSet(object.mifidAppliedAlgorithmIdType) ? Number(object.mifidAppliedAlgorithmIdType) : 0,
      mifidExecutionDecision: isSet(object.mifidExecutionDecision) ? String(object.mifidExecutionDecision) : '',
      mifidExecutionDecisionIsAlgo: isSet(object.mifidExecutionDecisionIsAlgo)
        ? Boolean(object.mifidExecutionDecisionIsAlgo)
        : false,
      openCloseEffect: isSet(object.openCloseEffect) ? Number(object.openCloseEffect) : 0,
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
      fillCommission: isSet(object.fillCommission) ? Commission.fromJSON(object.fillCommission) : undefined,
      mifidInvestmentDecision: isSet(object.mifidInvestmentDecision) ? String(object.mifidInvestmentDecision) : '',
      mifidInvestmentDecisionIsAlgo: isSet(object.mifidInvestmentDecisionIsAlgo)
        ? Boolean(object.mifidInvestmentDecisionIsAlgo)
        : false,
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
      isAutomated: isSet(object.isAutomated) ? Boolean(object.isAutomated) : false,
      tradeMatchId: isSet(object.tradeMatchId) ? String(object.tradeMatchId) : '',
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId) ? String(object.fillCareOrderRequestId) : '',
      username: isSet(object.username) ? String(object.username) : '',
    };
  },

  toJSON(message: TransactionStatus): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = Math.round(message.status));
    message.transId !== undefined && (obj.transId = Math.round(message.transId));
    message.transUtcTime !== undefined && (obj.transUtcTime = Math.round(message.transUtcTime));
    message.transUtcTimestamp !== undefined && (obj.transUtcTimestamp = message.transUtcTimestamp.toISOString());
    message.refTransId !== undefined && (obj.refTransId = Math.round(message.refTransId));
    message.origClOrderId !== undefined && (obj.origClOrderId = message.origClOrderId);
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.uint32FillQty !== undefined && (obj.uint32FillQty = Math.round(message.uint32FillQty));
    message.fillQty !== undefined && (obj.fillQty = message.fillQty ? Decimal.toJSON(message.fillQty) : undefined);
    message.scaledFillPrice !== undefined && (obj.scaledFillPrice = Math.round(message.scaledFillPrice));
    if (message.trades) {
      obj.trades = message.trades.map((e) => (e ? Trade.toJSON(e) : undefined));
    } else {
      obj.trades = [];
    }
    if (message.strategyLegFills) {
      obj.strategyLegFills = message.strategyLegFills.map((e) => (e ? StrategyLegFill.toJSON(e) : undefined));
    } else {
      obj.strategyLegFills = [];
    }
    message.syntheticHang !== undefined &&
      (obj.syntheticHang = message.syntheticHang ? SyntheticHang.toJSON(message.syntheticHang) : undefined);
    message.uint32PrevOrderQty !== undefined && (obj.uint32PrevOrderQty = Math.round(message.uint32PrevOrderQty));
    message.prevOrderQty !== undefined &&
      (obj.prevOrderQty = message.prevOrderQty ? Decimal.toJSON(message.prevOrderQty) : undefined);
    message.uint32OrderQty !== undefined && (obj.uint32OrderQty = Math.round(message.uint32OrderQty));
    message.orderQty !== undefined && (obj.orderQty = message.orderQty ? Decimal.toJSON(message.orderQty) : undefined);
    message.scaledPrevLimitPrice !== undefined && (obj.scaledPrevLimitPrice = Math.round(message.scaledPrevLimitPrice));
    message.scaledLimitPrice !== undefined && (obj.scaledLimitPrice = Math.round(message.scaledLimitPrice));
    message.scaledPrevStopPrice !== undefined && (obj.scaledPrevStopPrice = Math.round(message.scaledPrevStopPrice));
    message.scaledStopPrice !== undefined && (obj.scaledStopPrice = Math.round(message.scaledStopPrice));
    message.uint32PrevVisibleQty !== undefined && (obj.uint32PrevVisibleQty = Math.round(message.uint32PrevVisibleQty));
    message.prevVisibleQty !== undefined &&
      (obj.prevVisibleQty = message.prevVisibleQty ? Decimal.toJSON(message.prevVisibleQty) : undefined);
    message.uint32VisibleQty !== undefined && (obj.uint32VisibleQty = Math.round(message.uint32VisibleQty));
    message.visibleQty !== undefined && (obj.visibleQty = message.visibleQty ? Decimal.toJSON(message.visibleQty) : undefined);
    message.uint32PrevMinVisibleQty !== undefined && (obj.uint32PrevMinVisibleQty = Math.round(message.uint32PrevMinVisibleQty));
    message.prevMinVisibleQty !== undefined &&
      (obj.prevMinVisibleQty = message.prevMinVisibleQty ? Decimal.toJSON(message.prevMinVisibleQty) : undefined);
    message.uint32MinVisibleQty !== undefined && (obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty));
    message.minVisibleQty !== undefined &&
      (obj.minVisibleQty = message.minVisibleQty ? Decimal.toJSON(message.minVisibleQty) : undefined);
    message.uint32PrevFillQty !== undefined && (obj.uint32PrevFillQty = Math.round(message.uint32PrevFillQty));
    message.prevFillQty !== undefined &&
      (obj.prevFillQty = message.prevFillQty ? Decimal.toJSON(message.prevFillQty) : undefined);
    message.scaledPrevFillPrice !== undefined && (obj.scaledPrevFillPrice = Math.round(message.scaledPrevFillPrice));
    message.prevOrderType !== undefined && (obj.prevOrderType = Math.round(message.prevOrderType));
    message.orderType !== undefined && (obj.orderType = Math.round(message.orderType));
    if (message.prevExecInstructions) {
      obj.prevExecInstructions = message.prevExecInstructions.map((e) => Math.round(e));
    } else {
      obj.prevExecInstructions = [];
    }
    if (message.execInstructions) {
      obj.execInstructions = message.execInstructions.map((e) => Math.round(e));
    } else {
      obj.execInstructions = [];
    }
    message.prevDuration !== undefined && (obj.prevDuration = Math.round(message.prevDuration));
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.prevGoodThruDate !== undefined && (obj.prevGoodThruDate = Math.round(message.prevGoodThruDate));
    message.goodThruDate !== undefined && (obj.goodThruDate = Math.round(message.goodThruDate));
    message.rejectCode !== undefined && (obj.rejectCode = Math.round(message.rejectCode));
    message.fillCounterparty !== undefined && (obj.fillCounterparty = message.fillCounterparty);
    message.routeClOrderId !== undefined && (obj.routeClOrderId = message.routeClOrderId);
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.prevGoodThruUtcTime !== undefined && (obj.prevGoodThruUtcTime = Math.round(message.prevGoodThruUtcTime));
    message.prevGoodThruUtcTimestamp !== undefined &&
      (obj.prevGoodThruUtcTimestamp = message.prevGoodThruUtcTimestamp.toISOString());
    message.goodThruUtcTime !== undefined && (obj.goodThruUtcTime = Math.round(message.goodThruUtcTime));
    message.goodThruUtcTimestamp !== undefined && (obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString());
    message.isAggressive !== undefined && (obj.isAggressive = message.isAggressive);
    message.clientRegulatoryAlgorithmId !== undefined &&
      (obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId));
    message.effectiveRegulatoryAlgorithmId !== undefined &&
      (obj.effectiveRegulatoryAlgorithmId = Math.round(message.effectiveRegulatoryAlgorithmId));
    message.mifidAlgorithmId !== undefined && (obj.mifidAlgorithmId = message.mifidAlgorithmId);
    message.mifidAlgorithmIdType !== undefined && (obj.mifidAlgorithmIdType = Math.round(message.mifidAlgorithmIdType));
    message.mifidAppliedAlgorithmId !== undefined && (obj.mifidAppliedAlgorithmId = message.mifidAppliedAlgorithmId);
    message.mifidAppliedAlgorithmIdType !== undefined &&
      (obj.mifidAppliedAlgorithmIdType = Math.round(message.mifidAppliedAlgorithmIdType));
    message.mifidExecutionDecision !== undefined && (obj.mifidExecutionDecision = message.mifidExecutionDecision);
    message.mifidExecutionDecisionIsAlgo !== undefined &&
      (obj.mifidExecutionDecisionIsAlgo = message.mifidExecutionDecisionIsAlgo);
    message.openCloseEffect !== undefined && (obj.openCloseEffect = Math.round(message.openCloseEffect));
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    message.fillCommission !== undefined &&
      (obj.fillCommission = message.fillCommission ? Commission.toJSON(message.fillCommission) : undefined);
    message.mifidInvestmentDecision !== undefined && (obj.mifidInvestmentDecision = message.mifidInvestmentDecision);
    message.mifidInvestmentDecisionIsAlgo !== undefined &&
      (obj.mifidInvestmentDecisionIsAlgo = message.mifidInvestmentDecisionIsAlgo);
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    message.isAutomated !== undefined && (obj.isAutomated = message.isAutomated);
    message.tradeMatchId !== undefined && (obj.tradeMatchId = message.tradeMatchId);
    message.fillCareOrderRequestId !== undefined && (obj.fillCareOrderRequestId = message.fillCareOrderRequestId);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<TransactionStatus>, I>>(base?: I): TransactionStatus {
    return TransactionStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TransactionStatus>, I>>(object: I): TransactionStatus {
    const message = createBaseTransactionStatus();
    message.status = object.status ?? 0;
    message.transId = object.transId ?? 0;
    message.transUtcTime = object.transUtcTime ?? 0;
    message.transUtcTimestamp = object.transUtcTimestamp ?? undefined;
    message.refTransId = object.refTransId ?? 0;
    message.origClOrderId = object.origClOrderId ?? '';
    message.clOrderId = object.clOrderId ?? '';
    message.uint32FillQty = object.uint32FillQty ?? 0;
    message.fillQty = object.fillQty !== undefined && object.fillQty !== null ? Decimal.fromPartial(object.fillQty) : undefined;
    message.scaledFillPrice = object.scaledFillPrice ?? 0;
    message.trades = object.trades?.map((e) => Trade.fromPartial(e)) || [];
    message.strategyLegFills = object.strategyLegFills?.map((e) => StrategyLegFill.fromPartial(e)) || [];
    message.syntheticHang =
      object.syntheticHang !== undefined && object.syntheticHang !== null
        ? SyntheticHang.fromPartial(object.syntheticHang)
        : undefined;
    message.uint32PrevOrderQty = object.uint32PrevOrderQty ?? 0;
    message.prevOrderQty =
      object.prevOrderQty !== undefined && object.prevOrderQty !== null ? Decimal.fromPartial(object.prevOrderQty) : undefined;
    message.uint32OrderQty = object.uint32OrderQty ?? 0;
    message.orderQty =
      object.orderQty !== undefined && object.orderQty !== null ? Decimal.fromPartial(object.orderQty) : undefined;
    message.scaledPrevLimitPrice = object.scaledPrevLimitPrice ?? 0;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledPrevStopPrice = object.scaledPrevStopPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.uint32PrevVisibleQty = object.uint32PrevVisibleQty ?? 0;
    message.prevVisibleQty =
      object.prevVisibleQty !== undefined && object.prevVisibleQty !== null
        ? Decimal.fromPartial(object.prevVisibleQty)
        : undefined;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.visibleQty =
      object.visibleQty !== undefined && object.visibleQty !== null ? Decimal.fromPartial(object.visibleQty) : undefined;
    message.uint32PrevMinVisibleQty = object.uint32PrevMinVisibleQty ?? 0;
    message.prevMinVisibleQty =
      object.prevMinVisibleQty !== undefined && object.prevMinVisibleQty !== null
        ? Decimal.fromPartial(object.prevMinVisibleQty)
        : undefined;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.minVisibleQty =
      object.minVisibleQty !== undefined && object.minVisibleQty !== null ? Decimal.fromPartial(object.minVisibleQty) : undefined;
    message.uint32PrevFillQty = object.uint32PrevFillQty ?? 0;
    message.prevFillQty =
      object.prevFillQty !== undefined && object.prevFillQty !== null ? Decimal.fromPartial(object.prevFillQty) : undefined;
    message.scaledPrevFillPrice = object.scaledPrevFillPrice ?? 0;
    message.prevOrderType = object.prevOrderType ?? 0;
    message.orderType = object.orderType ?? 0;
    message.prevExecInstructions = object.prevExecInstructions?.map((e) => e) || [];
    message.execInstructions = object.execInstructions?.map((e) => e) || [];
    message.prevDuration = object.prevDuration ?? 0;
    message.duration = object.duration ?? 0;
    message.prevGoodThruDate = object.prevGoodThruDate ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.rejectCode = object.rejectCode ?? 0;
    message.fillCounterparty = object.fillCounterparty ?? '';
    message.routeClOrderId = object.routeClOrderId ?? '';
    message.textMessage = object.textMessage ?? '';
    message.prevGoodThruUtcTime = object.prevGoodThruUtcTime ?? 0;
    message.prevGoodThruUtcTimestamp = object.prevGoodThruUtcTimestamp ?? undefined;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.isAggressive = object.isAggressive ?? false;
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.effectiveRegulatoryAlgorithmId = object.effectiveRegulatoryAlgorithmId ?? 0;
    message.mifidAlgorithmId = object.mifidAlgorithmId ?? '';
    message.mifidAlgorithmIdType = object.mifidAlgorithmIdType ?? 0;
    message.mifidAppliedAlgorithmId = object.mifidAppliedAlgorithmId ?? '';
    message.mifidAppliedAlgorithmIdType = object.mifidAppliedAlgorithmIdType ?? 0;
    message.mifidExecutionDecision = object.mifidExecutionDecision ?? '';
    message.mifidExecutionDecisionIsAlgo = object.mifidExecutionDecisionIsAlgo ?? false;
    message.openCloseEffect = object.openCloseEffect ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.fillCommission =
      object.fillCommission !== undefined && object.fillCommission !== null
        ? Commission.fromPartial(object.fillCommission)
        : undefined;
    message.mifidInvestmentDecision = object.mifidInvestmentDecision ?? '';
    message.mifidInvestmentDecisionIsAlgo = object.mifidInvestmentDecisionIsAlgo ?? false;
    message.executionSourceCode = object.executionSourceCode ?? '';
    message.isAutomated = object.isAutomated ?? false;
    message.tradeMatchId = object.tradeMatchId ?? '';
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? '';
    message.username = object.username ?? '';
    return message;
  },
};

function createBaseTrade(): Trade {
  return {
    tradeId: '',
    contractId: 0,
    statementDate: 0,
    tradeUtcTime: 0,
    tradeUtcTimestamp: undefined,
    tradeDate: 0,
    scaledPrice: 0,
    priceCorrect: 0,
    side: 0,
    uint32Qty: 0,
    qty: undefined,
    tradeCounterparty: '',
    isAggressive: false,
    legExecutionId: '',
    openCloseEffect: 0,
    speculationType: 0,
    tradeMatchId: '',
    specificContractId: 0,
    fillExtraAttributes: [],
  };
}

export const Trade = {
  encode(message: Trade, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tradeId !== '') {
      writer.uint32(10).string(message.tradeId);
    }
    if (message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    if (message.statementDate !== 0) {
      writer.uint32(24).sint64(message.statementDate);
    }
    if (message.tradeUtcTime !== 0) {
      writer.uint32(32).sint64(message.tradeUtcTime);
    }
    if (message.tradeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.tradeUtcTimestamp), writer.uint32(98).fork()).ldelim();
    }
    if (message.tradeDate !== 0) {
      writer.uint32(40).sint64(message.tradeDate);
    }
    if (message.scaledPrice !== 0) {
      writer.uint32(48).sint64(message.scaledPrice);
    }
    if (message.priceCorrect !== 0) {
      writer.uint32(105).double(message.priceCorrect);
    }
    if (message.side !== 0) {
      writer.uint32(56).uint32(message.side);
    }
    if (message.uint32Qty !== 0) {
      writer.uint32(64).uint32(message.uint32Qty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(130).fork()).ldelim();
    }
    if (message.tradeCounterparty !== '') {
      writer.uint32(74).string(message.tradeCounterparty);
    }
    if (message.isAggressive === true) {
      writer.uint32(80).bool(message.isAggressive);
    }
    if (message.legExecutionId !== '') {
      writer.uint32(90).string(message.legExecutionId);
    }
    if (message.openCloseEffect !== 0) {
      writer.uint32(112).uint32(message.openCloseEffect);
    }
    if (message.speculationType !== 0) {
      writer.uint32(120).uint32(message.speculationType);
    }
    if (message.tradeMatchId !== '') {
      writer.uint32(138).string(message.tradeMatchId);
    }
    if (message.specificContractId !== 0) {
      writer.uint32(144).uint32(message.specificContractId);
    }
    for (const v of message.fillExtraAttributes) {
      NamedValue.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Trade {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tradeId = reader.string();
          break;
        case 2:
          message.contractId = reader.uint32();
          break;
        case 3:
          message.statementDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.tradeUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 12:
          message.tradeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 5:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.scaledPrice = longToNumber(reader.sint64() as Long);
          break;
        case 13:
          message.priceCorrect = reader.double();
          break;
        case 7:
          message.side = reader.uint32();
          break;
        case 8:
          message.uint32Qty = reader.uint32();
          break;
        case 16:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.tradeCounterparty = reader.string();
          break;
        case 10:
          message.isAggressive = reader.bool();
          break;
        case 11:
          message.legExecutionId = reader.string();
          break;
        case 14:
          message.openCloseEffect = reader.uint32();
          break;
        case 15:
          message.speculationType = reader.uint32();
          break;
        case 17:
          message.tradeMatchId = reader.string();
          break;
        case 18:
          message.specificContractId = reader.uint32();
          break;
        case 19:
          message.fillExtraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Trade {
    return {
      tradeId: isSet(object.tradeId) ? String(object.tradeId) : '',
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      statementDate: isSet(object.statementDate) ? Number(object.statementDate) : 0,
      tradeUtcTime: isSet(object.tradeUtcTime) ? Number(object.tradeUtcTime) : 0,
      tradeUtcTimestamp: isSet(object.tradeUtcTimestamp) ? fromJsonTimestamp(object.tradeUtcTimestamp) : undefined,
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      scaledPrice: isSet(object.scaledPrice) ? Number(object.scaledPrice) : 0,
      priceCorrect: isSet(object.priceCorrect) ? Number(object.priceCorrect) : 0,
      side: isSet(object.side) ? Number(object.side) : 0,
      uint32Qty: isSet(object.uint32Qty) ? Number(object.uint32Qty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      tradeCounterparty: isSet(object.tradeCounterparty) ? String(object.tradeCounterparty) : '',
      isAggressive: isSet(object.isAggressive) ? Boolean(object.isAggressive) : false,
      legExecutionId: isSet(object.legExecutionId) ? String(object.legExecutionId) : '',
      openCloseEffect: isSet(object.openCloseEffect) ? Number(object.openCloseEffect) : 0,
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
      tradeMatchId: isSet(object.tradeMatchId) ? String(object.tradeMatchId) : '',
      specificContractId: isSet(object.specificContractId) ? Number(object.specificContractId) : 0,
      fillExtraAttributes: Array.isArray(object?.fillExtraAttributes)
        ? object.fillExtraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Trade): unknown {
    const obj: any = {};
    message.tradeId !== undefined && (obj.tradeId = message.tradeId);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.statementDate !== undefined && (obj.statementDate = Math.round(message.statementDate));
    message.tradeUtcTime !== undefined && (obj.tradeUtcTime = Math.round(message.tradeUtcTime));
    message.tradeUtcTimestamp !== undefined && (obj.tradeUtcTimestamp = message.tradeUtcTimestamp.toISOString());
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.scaledPrice !== undefined && (obj.scaledPrice = Math.round(message.scaledPrice));
    message.priceCorrect !== undefined && (obj.priceCorrect = message.priceCorrect);
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.uint32Qty !== undefined && (obj.uint32Qty = Math.round(message.uint32Qty));
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.tradeCounterparty !== undefined && (obj.tradeCounterparty = message.tradeCounterparty);
    message.isAggressive !== undefined && (obj.isAggressive = message.isAggressive);
    message.legExecutionId !== undefined && (obj.legExecutionId = message.legExecutionId);
    message.openCloseEffect !== undefined && (obj.openCloseEffect = Math.round(message.openCloseEffect));
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    message.tradeMatchId !== undefined && (obj.tradeMatchId = message.tradeMatchId);
    message.specificContractId !== undefined && (obj.specificContractId = Math.round(message.specificContractId));
    if (message.fillExtraAttributes) {
      obj.fillExtraAttributes = message.fillExtraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.fillExtraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trade>, I>>(base?: I): Trade {
    return Trade.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Trade>, I>>(object: I): Trade {
    const message = createBaseTrade();
    message.tradeId = object.tradeId ?? '';
    message.contractId = object.contractId ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.tradeUtcTime = object.tradeUtcTime ?? 0;
    message.tradeUtcTimestamp = object.tradeUtcTimestamp ?? undefined;
    message.tradeDate = object.tradeDate ?? 0;
    message.scaledPrice = object.scaledPrice ?? 0;
    message.priceCorrect = object.priceCorrect ?? 0;
    message.side = object.side ?? 0;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.tradeCounterparty = object.tradeCounterparty ?? '';
    message.isAggressive = object.isAggressive ?? false;
    message.legExecutionId = object.legExecutionId ?? '';
    message.openCloseEffect = object.openCloseEffect ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.tradeMatchId = object.tradeMatchId ?? '';
    message.specificContractId = object.specificContractId ?? 0;
    message.fillExtraAttributes = object.fillExtraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCommission(): Commission {
  return { commissionCurrency: '', commission: 0 };
}

export const Commission = {
  encode(message: Commission, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.commissionCurrency !== '') {
      writer.uint32(10).string(message.commissionCurrency);
    }
    if (message.commission !== 0) {
      writer.uint32(17).double(message.commission);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commission {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommission();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.commissionCurrency = reader.string();
          break;
        case 2:
          message.commission = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Commission {
    return {
      commissionCurrency: isSet(object.commissionCurrency) ? String(object.commissionCurrency) : '',
      commission: isSet(object.commission) ? Number(object.commission) : 0,
    };
  },

  toJSON(message: Commission): unknown {
    const obj: any = {};
    message.commissionCurrency !== undefined && (obj.commissionCurrency = message.commissionCurrency);
    message.commission !== undefined && (obj.commission = message.commission);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commission>, I>>(base?: I): Commission {
    return Commission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commission>, I>>(object: I): Commission {
    const message = createBaseCommission();
    message.commissionCurrency = object.commissionCurrency ?? '';
    message.commission = object.commission ?? 0;
    return message;
  },
};

function createBaseCompoundOrderStructure(): CompoundOrderStructure {
  return { type: 0, clCompoundId: '', compoundOrderEntries: [], profitOffset: 0, lossOffset: 0, stopLimitOffset: 0 };
}

export const CompoundOrderStructure = {
  encode(message: CompoundOrderStructure, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.clCompoundId !== '') {
      writer.uint32(18).string(message.clCompoundId);
    }
    for (const v of message.compoundOrderEntries) {
      CompoundOrderStructureEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.profitOffset !== 0) {
      writer.uint32(32).sint32(message.profitOffset);
    }
    if (message.lossOffset !== 0) {
      writer.uint32(40).sint32(message.lossOffset);
    }
    if (message.stopLimitOffset !== 0) {
      writer.uint32(48).sint32(message.stopLimitOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderStructure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderStructure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.uint32();
          break;
        case 2:
          message.clCompoundId = reader.string();
          break;
        case 3:
          message.compoundOrderEntries.push(CompoundOrderStructureEntry.decode(reader, reader.uint32()));
          break;
        case 4:
          message.profitOffset = reader.sint32();
          break;
        case 5:
          message.lossOffset = reader.sint32();
          break;
        case 6:
          message.stopLimitOffset = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderStructure {
    return {
      type: isSet(object.type) ? Number(object.type) : 0,
      clCompoundId: isSet(object.clCompoundId) ? String(object.clCompoundId) : '',
      compoundOrderEntries: Array.isArray(object?.compoundOrderEntries)
        ? object.compoundOrderEntries.map((e: any) => CompoundOrderStructureEntry.fromJSON(e))
        : [],
      profitOffset: isSet(object.profitOffset) ? Number(object.profitOffset) : 0,
      lossOffset: isSet(object.lossOffset) ? Number(object.lossOffset) : 0,
      stopLimitOffset: isSet(object.stopLimitOffset) ? Number(object.stopLimitOffset) : 0,
    };
  },

  toJSON(message: CompoundOrderStructure): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = Math.round(message.type));
    message.clCompoundId !== undefined && (obj.clCompoundId = message.clCompoundId);
    if (message.compoundOrderEntries) {
      obj.compoundOrderEntries = message.compoundOrderEntries.map((e) => (e ? CompoundOrderStructureEntry.toJSON(e) : undefined));
    } else {
      obj.compoundOrderEntries = [];
    }
    message.profitOffset !== undefined && (obj.profitOffset = Math.round(message.profitOffset));
    message.lossOffset !== undefined && (obj.lossOffset = Math.round(message.lossOffset));
    message.stopLimitOffset !== undefined && (obj.stopLimitOffset = Math.round(message.stopLimitOffset));
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderStructure>, I>>(base?: I): CompoundOrderStructure {
    return CompoundOrderStructure.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CompoundOrderStructure>, I>>(object: I): CompoundOrderStructure {
    const message = createBaseCompoundOrderStructure();
    message.type = object.type ?? 0;
    message.clCompoundId = object.clCompoundId ?? '';
    message.compoundOrderEntries = object.compoundOrderEntries?.map((e) => CompoundOrderStructureEntry.fromPartial(e)) || [];
    message.profitOffset = object.profitOffset ?? 0;
    message.lossOffset = object.lossOffset ?? 0;
    message.stopLimitOffset = object.stopLimitOffset ?? 0;
    return message;
  },
};

function createBaseCompoundOrderStructureEntry(): CompoundOrderStructureEntry {
  return { chainOrderId: '', compoundOrderStructure: undefined };
}

export const CompoundOrderStructureEntry = {
  encode(message: CompoundOrderStructureEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== '') {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.compoundOrderStructure !== undefined) {
      CompoundOrderStructure.encode(message.compoundOrderStructure, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderStructureEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderStructureEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainOrderId = reader.string();
          break;
        case 2:
          message.compoundOrderStructure = CompoundOrderStructure.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderStructureEntry {
    return {
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      compoundOrderStructure: isSet(object.compoundOrderStructure)
        ? CompoundOrderStructure.fromJSON(object.compoundOrderStructure)
        : undefined,
    };
  },

  toJSON(message: CompoundOrderStructureEntry): unknown {
    const obj: any = {};
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.compoundOrderStructure !== undefined &&
      (obj.compoundOrderStructure = message.compoundOrderStructure
        ? CompoundOrderStructure.toJSON(message.compoundOrderStructure)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderStructureEntry>, I>>(base?: I): CompoundOrderStructureEntry {
    return CompoundOrderStructureEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CompoundOrderStructureEntry>, I>>(object: I): CompoundOrderStructureEntry {
    const message = createBaseCompoundOrderStructureEntry();
    message.chainOrderId = object.chainOrderId ?? '';
    message.compoundOrderStructure =
      object.compoundOrderStructure !== undefined && object.compoundOrderStructure !== null
        ? CompoundOrderStructure.fromPartial(object.compoundOrderStructure)
        : undefined;
    return message;
  },
};

function createBaseOrder(): Order {
  return {
    accountId: 0,
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    contractId: 0,
    clOrderId: '',
    orderType: 0,
    execInstructions: [],
    duration: 0,
    goodThruDate: 0,
    side: 0,
    scaledLimitPrice: 0,
    scaledStopPrice: 0,
    uint32Qty: 0,
    uint32VisibleQty: 0,
    uint32MinVisibleQty: 0,
    qty: undefined,
    visibleQty: undefined,
    minVisibleQty: undefined,
    isManual: false,
    isClose: false,
    isAggressive: false,
    scaledTrailOffset: 0,
    trailingPeg: 0,
    uint32TriggerQty: 0,
    triggerQty: undefined,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    suspensionUtcTime: 0,
    suspensionUtcTimestamp: undefined,
    userAttributes: [],
    extraAttributes: [],
    algoStrategy: '',
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    isUserAttributeChecked: false,
    strategyTradingParameters: undefined,
    openCloseInstruction: 0,
    speculationType: 0,
    contributorId: '',
    executionSourceCode: '',
    crossOrderParameters: undefined,
    externalAccountNumber: '',
    isCareOrder: false,
  };
}

export const Order = {
  encode(message: Order, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(16).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(218).fork()).ldelim();
    }
    if (message.contractId !== 0) {
      writer.uint32(24).uint32(message.contractId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.orderType !== 0) {
      writer.uint32(40).uint32(message.orderType);
    }
    writer.uint32(50).fork();
    for (const v of message.execInstructions) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.duration !== 0) {
      writer.uint32(56).uint32(message.duration);
    }
    if (message.goodThruDate !== 0) {
      writer.uint32(64).sint64(message.goodThruDate);
    }
    if (message.side !== 0) {
      writer.uint32(72).uint32(message.side);
    }
    if (message.scaledLimitPrice !== 0) {
      writer.uint32(80).sint64(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== 0) {
      writer.uint32(88).sint64(message.scaledStopPrice);
    }
    if (message.uint32Qty !== 0) {
      writer.uint32(96).uint32(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== 0) {
      writer.uint32(104).uint32(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== 0) {
      writer.uint32(112).uint32(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(274).fork()).ldelim();
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(282).fork()).ldelim();
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(290).fork()).ldelim();
    }
    if (message.isManual === true) {
      writer.uint32(120).bool(message.isManual);
    }
    if (message.isClose === true) {
      writer.uint32(128).bool(message.isClose);
    }
    if (message.isAggressive === true) {
      writer.uint32(136).bool(message.isAggressive);
    }
    if (message.scaledTrailOffset !== 0) {
      writer.uint32(144).sint32(message.scaledTrailOffset);
    }
    if (message.trailingPeg !== 0) {
      writer.uint32(152).uint32(message.trailingPeg);
    }
    if (message.uint32TriggerQty !== 0) {
      writer.uint32(160).uint32(message.uint32TriggerQty);
    }
    if (message.triggerQty !== undefined) {
      Decimal.encode(message.triggerQty, writer.uint32(298).fork()).ldelim();
    }
    if (message.activationUtcTime !== 0) {
      writer.uint32(168).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(226).fork()).ldelim();
    }
    if (message.suspensionUtcTime !== 0) {
      writer.uint32(176).sint64(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.suspensionUtcTimestamp), writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.userAttributes) {
      UserAttribute.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(306).fork()).ldelim();
    }
    if (message.algoStrategy !== '') {
      writer.uint32(314).string(message.algoStrategy);
    }
    if (message.goodThruUtcTime !== 0) {
      writer.uint32(192).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(242).fork()).ldelim();
    }
    if (message.isUserAttributeChecked === true) {
      writer.uint32(200).bool(message.isUserAttributeChecked);
    }
    if (message.strategyTradingParameters !== undefined) {
      StrategyTradingParameters.encode(message.strategyTradingParameters, writer.uint32(210).fork()).ldelim();
    }
    if (message.openCloseInstruction !== 0) {
      writer.uint32(248).uint32(message.openCloseInstruction);
    }
    if (message.speculationType !== 0) {
      writer.uint32(256).uint32(message.speculationType);
    }
    if (message.contributorId !== '') {
      writer.uint32(322).string(message.contributorId);
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(330).string(message.executionSourceCode);
    }
    if (message.crossOrderParameters !== undefined) {
      CrossOrderParameters.encode(message.crossOrderParameters, writer.uint32(338).fork()).ldelim();
    }
    if (message.externalAccountNumber !== '') {
      writer.uint32(346).string(message.externalAccountNumber);
    }
    if (message.isCareOrder === true) {
      writer.uint32(352).bool(message.isCareOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Order {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 27:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.contractId = reader.uint32();
          break;
        case 4:
          message.clOrderId = reader.string();
          break;
        case 5:
          message.orderType = reader.uint32();
          break;
        case 6:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstructions.push(reader.uint32());
            }
          } else {
            message.execInstructions.push(reader.uint32());
          }
          break;
        case 7:
          message.duration = reader.uint32();
          break;
        case 8:
          message.goodThruDate = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.side = reader.uint32();
          break;
        case 10:
          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          break;
        case 11:
          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          break;
        case 12:
          message.uint32Qty = reader.uint32();
          break;
        case 13:
          message.uint32VisibleQty = reader.uint32();
          break;
        case 14:
          message.uint32MinVisibleQty = reader.uint32();
          break;
        case 34:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 35:
          message.visibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 36:
          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 15:
          message.isManual = reader.bool();
          break;
        case 16:
          message.isClose = reader.bool();
          break;
        case 17:
          message.isAggressive = reader.bool();
          break;
        case 18:
          message.scaledTrailOffset = reader.sint32();
          break;
        case 19:
          message.trailingPeg = reader.uint32();
          break;
        case 20:
          message.uint32TriggerQty = reader.uint32();
          break;
        case 37:
          message.triggerQty = Decimal.decode(reader, reader.uint32());
          break;
        case 21:
          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 28:
          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 22:
          message.suspensionUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 29:
          message.suspensionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 23:
          message.userAttributes.push(UserAttribute.decode(reader, reader.uint32()));
          break;
        case 38:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        case 39:
          message.algoStrategy = reader.string();
          break;
        case 24:
          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 30:
          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 25:
          message.isUserAttributeChecked = reader.bool();
          break;
        case 26:
          message.strategyTradingParameters = StrategyTradingParameters.decode(reader, reader.uint32());
          break;
        case 31:
          message.openCloseInstruction = reader.uint32();
          break;
        case 32:
          message.speculationType = reader.uint32();
          break;
        case 40:
          message.contributorId = reader.string();
          break;
        case 41:
          message.executionSourceCode = reader.string();
          break;
        case 42:
          message.crossOrderParameters = CrossOrderParameters.decode(reader, reader.uint32());
          break;
        case 43:
          message.externalAccountNumber = reader.string();
          break;
        case 44:
          message.isCareOrder = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Order {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      orderType: isSet(object.orderType) ? Number(object.orderType) : 0,
      execInstructions: Array.isArray(object?.execInstructions) ? object.execInstructions.map((e: any) => Number(e)) : [],
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? Number(object.goodThruDate) : 0,
      side: isSet(object.side) ? Number(object.side) : 0,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? Number(object.scaledLimitPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? Number(object.scaledStopPrice) : 0,
      uint32Qty: isSet(object.uint32Qty) ? Number(object.uint32Qty) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? Number(object.uint32VisibleQty) : 0,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? Number(object.uint32MinVisibleQty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      isManual: isSet(object.isManual) ? Boolean(object.isManual) : false,
      isClose: isSet(object.isClose) ? Boolean(object.isClose) : false,
      isAggressive: isSet(object.isAggressive) ? Boolean(object.isAggressive) : false,
      scaledTrailOffset: isSet(object.scaledTrailOffset) ? Number(object.scaledTrailOffset) : 0,
      trailingPeg: isSet(object.trailingPeg) ? Number(object.trailingPeg) : 0,
      uint32TriggerQty: isSet(object.uint32TriggerQty) ? Number(object.uint32TriggerQty) : 0,
      triggerQty: isSet(object.triggerQty) ? Decimal.fromJSON(object.triggerQty) : undefined,
      activationUtcTime: isSet(object.activationUtcTime) ? Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp) ? fromJsonTimestamp(object.activationUtcTimestamp) : undefined,
      suspensionUtcTime: isSet(object.suspensionUtcTime) ? Number(object.suspensionUtcTime) : 0,
      suspensionUtcTimestamp: isSet(object.suspensionUtcTimestamp) ? fromJsonTimestamp(object.suspensionUtcTimestamp) : undefined,
      userAttributes: Array.isArray(object?.userAttributes)
        ? object.userAttributes.map((e: any) => UserAttribute.fromJSON(e))
        : [],
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      algoStrategy: isSet(object.algoStrategy) ? String(object.algoStrategy) : '',
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp) ? fromJsonTimestamp(object.goodThruUtcTimestamp) : undefined,
      isUserAttributeChecked: isSet(object.isUserAttributeChecked) ? Boolean(object.isUserAttributeChecked) : false,
      strategyTradingParameters: isSet(object.strategyTradingParameters)
        ? StrategyTradingParameters.fromJSON(object.strategyTradingParameters)
        : undefined,
      openCloseInstruction: isSet(object.openCloseInstruction) ? Number(object.openCloseInstruction) : 0,
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
      crossOrderParameters: isSet(object.crossOrderParameters)
        ? CrossOrderParameters.fromJSON(object.crossOrderParameters)
        : undefined,
      externalAccountNumber: isSet(object.externalAccountNumber) ? String(object.externalAccountNumber) : '',
      isCareOrder: isSet(object.isCareOrder) ? Boolean(object.isCareOrder) : false,
    };
  },

  toJSON(message: Order): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.orderType !== undefined && (obj.orderType = Math.round(message.orderType));
    if (message.execInstructions) {
      obj.execInstructions = message.execInstructions.map((e) => Math.round(e));
    } else {
      obj.execInstructions = [];
    }
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.goodThruDate !== undefined && (obj.goodThruDate = Math.round(message.goodThruDate));
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.scaledLimitPrice !== undefined && (obj.scaledLimitPrice = Math.round(message.scaledLimitPrice));
    message.scaledStopPrice !== undefined && (obj.scaledStopPrice = Math.round(message.scaledStopPrice));
    message.uint32Qty !== undefined && (obj.uint32Qty = Math.round(message.uint32Qty));
    message.uint32VisibleQty !== undefined && (obj.uint32VisibleQty = Math.round(message.uint32VisibleQty));
    message.uint32MinVisibleQty !== undefined && (obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty));
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.visibleQty !== undefined && (obj.visibleQty = message.visibleQty ? Decimal.toJSON(message.visibleQty) : undefined);
    message.minVisibleQty !== undefined &&
      (obj.minVisibleQty = message.minVisibleQty ? Decimal.toJSON(message.minVisibleQty) : undefined);
    message.isManual !== undefined && (obj.isManual = message.isManual);
    message.isClose !== undefined && (obj.isClose = message.isClose);
    message.isAggressive !== undefined && (obj.isAggressive = message.isAggressive);
    message.scaledTrailOffset !== undefined && (obj.scaledTrailOffset = Math.round(message.scaledTrailOffset));
    message.trailingPeg !== undefined && (obj.trailingPeg = Math.round(message.trailingPeg));
    message.uint32TriggerQty !== undefined && (obj.uint32TriggerQty = Math.round(message.uint32TriggerQty));
    message.triggerQty !== undefined && (obj.triggerQty = message.triggerQty ? Decimal.toJSON(message.triggerQty) : undefined);
    message.activationUtcTime !== undefined && (obj.activationUtcTime = Math.round(message.activationUtcTime));
    message.activationUtcTimestamp !== undefined && (obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString());
    message.suspensionUtcTime !== undefined && (obj.suspensionUtcTime = Math.round(message.suspensionUtcTime));
    message.suspensionUtcTimestamp !== undefined && (obj.suspensionUtcTimestamp = message.suspensionUtcTimestamp.toISOString());
    if (message.userAttributes) {
      obj.userAttributes = message.userAttributes.map((e) => (e ? UserAttribute.toJSON(e) : undefined));
    } else {
      obj.userAttributes = [];
    }
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    message.algoStrategy !== undefined && (obj.algoStrategy = message.algoStrategy);
    message.goodThruUtcTime !== undefined && (obj.goodThruUtcTime = Math.round(message.goodThruUtcTime));
    message.goodThruUtcTimestamp !== undefined && (obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString());
    message.isUserAttributeChecked !== undefined && (obj.isUserAttributeChecked = message.isUserAttributeChecked);
    message.strategyTradingParameters !== undefined &&
      (obj.strategyTradingParameters = message.strategyTradingParameters
        ? StrategyTradingParameters.toJSON(message.strategyTradingParameters)
        : undefined);
    message.openCloseInstruction !== undefined && (obj.openCloseInstruction = Math.round(message.openCloseInstruction));
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    message.crossOrderParameters !== undefined &&
      (obj.crossOrderParameters = message.crossOrderParameters
        ? CrossOrderParameters.toJSON(message.crossOrderParameters)
        : undefined);
    message.externalAccountNumber !== undefined && (obj.externalAccountNumber = message.externalAccountNumber);
    message.isCareOrder !== undefined && (obj.isCareOrder = message.isCareOrder);
    return obj;
  },

  create<I extends Exact<DeepPartial<Order>, I>>(base?: I): Order {
    return Order.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Order>, I>>(object: I): Order {
    const message = createBaseOrder();
    message.accountId = object.accountId ?? 0;
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.contractId = object.contractId ?? 0;
    message.clOrderId = object.clOrderId ?? '';
    message.orderType = object.orderType ?? 0;
    message.execInstructions = object.execInstructions?.map((e) => e) || [];
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.side = object.side ?? 0;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.visibleQty =
      object.visibleQty !== undefined && object.visibleQty !== null ? Decimal.fromPartial(object.visibleQty) : undefined;
    message.minVisibleQty =
      object.minVisibleQty !== undefined && object.minVisibleQty !== null ? Decimal.fromPartial(object.minVisibleQty) : undefined;
    message.isManual = object.isManual ?? false;
    message.isClose = object.isClose ?? false;
    message.isAggressive = object.isAggressive ?? false;
    message.scaledTrailOffset = object.scaledTrailOffset ?? 0;
    message.trailingPeg = object.trailingPeg ?? 0;
    message.uint32TriggerQty = object.uint32TriggerQty ?? 0;
    message.triggerQty =
      object.triggerQty !== undefined && object.triggerQty !== null ? Decimal.fromPartial(object.triggerQty) : undefined;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.suspensionUtcTime = object.suspensionUtcTime ?? 0;
    message.suspensionUtcTimestamp = object.suspensionUtcTimestamp ?? undefined;
    message.userAttributes = object.userAttributes?.map((e) => UserAttribute.fromPartial(e)) || [];
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.algoStrategy = object.algoStrategy ?? '';
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.isUserAttributeChecked = object.isUserAttributeChecked ?? false;
    message.strategyTradingParameters =
      object.strategyTradingParameters !== undefined && object.strategyTradingParameters !== null
        ? StrategyTradingParameters.fromPartial(object.strategyTradingParameters)
        : undefined;
    message.openCloseInstruction = object.openCloseInstruction ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.contributorId = object.contributorId ?? '';
    message.executionSourceCode = object.executionSourceCode ?? '';
    message.crossOrderParameters =
      object.crossOrderParameters !== undefined && object.crossOrderParameters !== null
        ? CrossOrderParameters.fromPartial(object.crossOrderParameters)
        : undefined;
    message.externalAccountNumber = object.externalAccountNumber ?? '';
    message.isCareOrder = object.isCareOrder ?? false;
    return message;
  },
};

function createBaseOrderRequest(): OrderRequest {
  return {
    requestId: 0,
    newOrder: undefined,
    modifyOrder: undefined,
    cancelOrder: undefined,
    cancelAllOrders: undefined,
    liquidateAll: undefined,
    goFlat: undefined,
    suspendOrder: undefined,
    activateOrder: undefined,
    modifyUserAttributes: undefined,
    newCompoundOrder: undefined,
    syntheticLiquidate: undefined,
    syntheticScratch: undefined,
    goMarket: undefined,
    approveOrder: undefined,
    fillCareOrder: undefined,
    onBehalfOfUser: '',
    clientRegulatoryAlgorithmId: 0,
    mifidAlgorithmId: '',
    mifidAlgorithmIdType: 0,
    overrideExecutionWithinFirmWithNore: false,
    mifidInvestmentDecisionId: '',
    mifidInvestmentDecisionIdType: 0,
    mifidExecutionDecisionId: '',
    mifidExecutionDecisionIdType: 0,
    isAutomated: false,
    senderLocationId: '',
    senderOperatorId: '',
  };
}

export const OrderRequest = {
  encode(message: OrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.newOrder !== undefined) {
      NewOrder.encode(message.newOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.modifyOrder !== undefined) {
      ModifyOrder.encode(message.modifyOrder, writer.uint32(26).fork()).ldelim();
    }
    if (message.cancelOrder !== undefined) {
      CancelOrder.encode(message.cancelOrder, writer.uint32(34).fork()).ldelim();
    }
    if (message.cancelAllOrders !== undefined) {
      CancelAllOrders.encode(message.cancelAllOrders, writer.uint32(114).fork()).ldelim();
    }
    if (message.liquidateAll !== undefined) {
      LiquidateAll.encode(message.liquidateAll, writer.uint32(162).fork()).ldelim();
    }
    if (message.goFlat !== undefined) {
      GoFlat.encode(message.goFlat, writer.uint32(170).fork()).ldelim();
    }
    if (message.suspendOrder !== undefined) {
      SuspendOrder.encode(message.suspendOrder, writer.uint32(82).fork()).ldelim();
    }
    if (message.activateOrder !== undefined) {
      ActivateOrder.encode(message.activateOrder, writer.uint32(42).fork()).ldelim();
    }
    if (message.modifyUserAttributes !== undefined) {
      ModifyUserAttributes.encode(message.modifyUserAttributes, writer.uint32(50).fork()).ldelim();
    }
    if (message.newCompoundOrder !== undefined) {
      NewCompoundOrder.encode(message.newCompoundOrder, writer.uint32(66).fork()).ldelim();
    }
    if (message.syntheticLiquidate !== undefined) {
      SyntheticLiquidate.encode(message.syntheticLiquidate, writer.uint32(90).fork()).ldelim();
    }
    if (message.syntheticScratch !== undefined) {
      SyntheticScratch.encode(message.syntheticScratch, writer.uint32(98).fork()).ldelim();
    }
    if (message.goMarket !== undefined) {
      GoMarket.encode(message.goMarket, writer.uint32(106).fork()).ldelim();
    }
    if (message.approveOrder !== undefined) {
      ApproveOrder.encode(message.approveOrder, writer.uint32(178).fork()).ldelim();
    }
    if (message.fillCareOrder !== undefined) {
      FillCareOrder.encode(message.fillCareOrder, writer.uint32(226).fork()).ldelim();
    }
    if (message.onBehalfOfUser !== '') {
      writer.uint32(58).string(message.onBehalfOfUser);
    }
    if (message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(72).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.mifidAlgorithmId !== '') {
      writer.uint32(122).string(message.mifidAlgorithmId);
    }
    if (message.mifidAlgorithmIdType !== 0) {
      writer.uint32(128).uint32(message.mifidAlgorithmIdType);
    }
    if (message.overrideExecutionWithinFirmWithNore === true) {
      writer.uint32(136).bool(message.overrideExecutionWithinFirmWithNore);
    }
    if (message.mifidInvestmentDecisionId !== '') {
      writer.uint32(146).string(message.mifidInvestmentDecisionId);
    }
    if (message.mifidInvestmentDecisionIdType !== 0) {
      writer.uint32(152).uint32(message.mifidInvestmentDecisionIdType);
    }
    if (message.mifidExecutionDecisionId !== '') {
      writer.uint32(194).string(message.mifidExecutionDecisionId);
    }
    if (message.mifidExecutionDecisionIdType !== 0) {
      writer.uint32(200).uint32(message.mifidExecutionDecisionIdType);
    }
    if (message.isAutomated === true) {
      writer.uint32(184).bool(message.isAutomated);
    }
    if (message.senderLocationId !== '') {
      writer.uint32(210).string(message.senderLocationId);
    }
    if (message.senderOperatorId !== '') {
      writer.uint32(218).string(message.senderOperatorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.newOrder = NewOrder.decode(reader, reader.uint32());
          break;
        case 3:
          message.modifyOrder = ModifyOrder.decode(reader, reader.uint32());
          break;
        case 4:
          message.cancelOrder = CancelOrder.decode(reader, reader.uint32());
          break;
        case 14:
          message.cancelAllOrders = CancelAllOrders.decode(reader, reader.uint32());
          break;
        case 20:
          message.liquidateAll = LiquidateAll.decode(reader, reader.uint32());
          break;
        case 21:
          message.goFlat = GoFlat.decode(reader, reader.uint32());
          break;
        case 10:
          message.suspendOrder = SuspendOrder.decode(reader, reader.uint32());
          break;
        case 5:
          message.activateOrder = ActivateOrder.decode(reader, reader.uint32());
          break;
        case 6:
          message.modifyUserAttributes = ModifyUserAttributes.decode(reader, reader.uint32());
          break;
        case 8:
          message.newCompoundOrder = NewCompoundOrder.decode(reader, reader.uint32());
          break;
        case 11:
          message.syntheticLiquidate = SyntheticLiquidate.decode(reader, reader.uint32());
          break;
        case 12:
          message.syntheticScratch = SyntheticScratch.decode(reader, reader.uint32());
          break;
        case 13:
          message.goMarket = GoMarket.decode(reader, reader.uint32());
          break;
        case 22:
          message.approveOrder = ApproveOrder.decode(reader, reader.uint32());
          break;
        case 28:
          message.fillCareOrder = FillCareOrder.decode(reader, reader.uint32());
          break;
        case 7:
          message.onBehalfOfUser = reader.string();
          break;
        case 9:
          message.clientRegulatoryAlgorithmId = reader.uint32();
          break;
        case 15:
          message.mifidAlgorithmId = reader.string();
          break;
        case 16:
          message.mifidAlgorithmIdType = reader.uint32();
          break;
        case 17:
          message.overrideExecutionWithinFirmWithNore = reader.bool();
          break;
        case 18:
          message.mifidInvestmentDecisionId = reader.string();
          break;
        case 19:
          message.mifidInvestmentDecisionIdType = reader.uint32();
          break;
        case 24:
          message.mifidExecutionDecisionId = reader.string();
          break;
        case 25:
          message.mifidExecutionDecisionIdType = reader.uint32();
          break;
        case 23:
          message.isAutomated = reader.bool();
          break;
        case 26:
          message.senderLocationId = reader.string();
          break;
        case 27:
          message.senderOperatorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      newOrder: isSet(object.newOrder) ? NewOrder.fromJSON(object.newOrder) : undefined,
      modifyOrder: isSet(object.modifyOrder) ? ModifyOrder.fromJSON(object.modifyOrder) : undefined,
      cancelOrder: isSet(object.cancelOrder) ? CancelOrder.fromJSON(object.cancelOrder) : undefined,
      cancelAllOrders: isSet(object.cancelAllOrders) ? CancelAllOrders.fromJSON(object.cancelAllOrders) : undefined,
      liquidateAll: isSet(object.liquidateAll) ? LiquidateAll.fromJSON(object.liquidateAll) : undefined,
      goFlat: isSet(object.goFlat) ? GoFlat.fromJSON(object.goFlat) : undefined,
      suspendOrder: isSet(object.suspendOrder) ? SuspendOrder.fromJSON(object.suspendOrder) : undefined,
      activateOrder: isSet(object.activateOrder) ? ActivateOrder.fromJSON(object.activateOrder) : undefined,
      modifyUserAttributes: isSet(object.modifyUserAttributes)
        ? ModifyUserAttributes.fromJSON(object.modifyUserAttributes)
        : undefined,
      newCompoundOrder: isSet(object.newCompoundOrder) ? NewCompoundOrder.fromJSON(object.newCompoundOrder) : undefined,
      syntheticLiquidate: isSet(object.syntheticLiquidate) ? SyntheticLiquidate.fromJSON(object.syntheticLiquidate) : undefined,
      syntheticScratch: isSet(object.syntheticScratch) ? SyntheticScratch.fromJSON(object.syntheticScratch) : undefined,
      goMarket: isSet(object.goMarket) ? GoMarket.fromJSON(object.goMarket) : undefined,
      approveOrder: isSet(object.approveOrder) ? ApproveOrder.fromJSON(object.approveOrder) : undefined,
      fillCareOrder: isSet(object.fillCareOrder) ? FillCareOrder.fromJSON(object.fillCareOrder) : undefined,
      onBehalfOfUser: isSet(object.onBehalfOfUser) ? String(object.onBehalfOfUser) : '',
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId) ? Number(object.clientRegulatoryAlgorithmId) : 0,
      mifidAlgorithmId: isSet(object.mifidAlgorithmId) ? String(object.mifidAlgorithmId) : '',
      mifidAlgorithmIdType: isSet(object.mifidAlgorithmIdType) ? Number(object.mifidAlgorithmIdType) : 0,
      overrideExecutionWithinFirmWithNore: isSet(object.overrideExecutionWithinFirmWithNore)
        ? Boolean(object.overrideExecutionWithinFirmWithNore)
        : false,
      mifidInvestmentDecisionId: isSet(object.mifidInvestmentDecisionId) ? String(object.mifidInvestmentDecisionId) : '',
      mifidInvestmentDecisionIdType: isSet(object.mifidInvestmentDecisionIdType)
        ? Number(object.mifidInvestmentDecisionIdType)
        : 0,
      mifidExecutionDecisionId: isSet(object.mifidExecutionDecisionId) ? String(object.mifidExecutionDecisionId) : '',
      mifidExecutionDecisionIdType: isSet(object.mifidExecutionDecisionIdType) ? Number(object.mifidExecutionDecisionIdType) : 0,
      isAutomated: isSet(object.isAutomated) ? Boolean(object.isAutomated) : false,
      senderLocationId: isSet(object.senderLocationId) ? String(object.senderLocationId) : '',
      senderOperatorId: isSet(object.senderOperatorId) ? String(object.senderOperatorId) : '',
    };
  },

  toJSON(message: OrderRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.newOrder !== undefined && (obj.newOrder = message.newOrder ? NewOrder.toJSON(message.newOrder) : undefined);
    message.modifyOrder !== undefined &&
      (obj.modifyOrder = message.modifyOrder ? ModifyOrder.toJSON(message.modifyOrder) : undefined);
    message.cancelOrder !== undefined &&
      (obj.cancelOrder = message.cancelOrder ? CancelOrder.toJSON(message.cancelOrder) : undefined);
    message.cancelAllOrders !== undefined &&
      (obj.cancelAllOrders = message.cancelAllOrders ? CancelAllOrders.toJSON(message.cancelAllOrders) : undefined);
    message.liquidateAll !== undefined &&
      (obj.liquidateAll = message.liquidateAll ? LiquidateAll.toJSON(message.liquidateAll) : undefined);
    message.goFlat !== undefined && (obj.goFlat = message.goFlat ? GoFlat.toJSON(message.goFlat) : undefined);
    message.suspendOrder !== undefined &&
      (obj.suspendOrder = message.suspendOrder ? SuspendOrder.toJSON(message.suspendOrder) : undefined);
    message.activateOrder !== undefined &&
      (obj.activateOrder = message.activateOrder ? ActivateOrder.toJSON(message.activateOrder) : undefined);
    message.modifyUserAttributes !== undefined &&
      (obj.modifyUserAttributes = message.modifyUserAttributes
        ? ModifyUserAttributes.toJSON(message.modifyUserAttributes)
        : undefined);
    message.newCompoundOrder !== undefined &&
      (obj.newCompoundOrder = message.newCompoundOrder ? NewCompoundOrder.toJSON(message.newCompoundOrder) : undefined);
    message.syntheticLiquidate !== undefined &&
      (obj.syntheticLiquidate = message.syntheticLiquidate ? SyntheticLiquidate.toJSON(message.syntheticLiquidate) : undefined);
    message.syntheticScratch !== undefined &&
      (obj.syntheticScratch = message.syntheticScratch ? SyntheticScratch.toJSON(message.syntheticScratch) : undefined);
    message.goMarket !== undefined && (obj.goMarket = message.goMarket ? GoMarket.toJSON(message.goMarket) : undefined);
    message.approveOrder !== undefined &&
      (obj.approveOrder = message.approveOrder ? ApproveOrder.toJSON(message.approveOrder) : undefined);
    message.fillCareOrder !== undefined &&
      (obj.fillCareOrder = message.fillCareOrder ? FillCareOrder.toJSON(message.fillCareOrder) : undefined);
    message.onBehalfOfUser !== undefined && (obj.onBehalfOfUser = message.onBehalfOfUser);
    message.clientRegulatoryAlgorithmId !== undefined &&
      (obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId));
    message.mifidAlgorithmId !== undefined && (obj.mifidAlgorithmId = message.mifidAlgorithmId);
    message.mifidAlgorithmIdType !== undefined && (obj.mifidAlgorithmIdType = Math.round(message.mifidAlgorithmIdType));
    message.overrideExecutionWithinFirmWithNore !== undefined &&
      (obj.overrideExecutionWithinFirmWithNore = message.overrideExecutionWithinFirmWithNore);
    message.mifidInvestmentDecisionId !== undefined && (obj.mifidInvestmentDecisionId = message.mifidInvestmentDecisionId);
    message.mifidInvestmentDecisionIdType !== undefined &&
      (obj.mifidInvestmentDecisionIdType = Math.round(message.mifidInvestmentDecisionIdType));
    message.mifidExecutionDecisionId !== undefined && (obj.mifidExecutionDecisionId = message.mifidExecutionDecisionId);
    message.mifidExecutionDecisionIdType !== undefined &&
      (obj.mifidExecutionDecisionIdType = Math.round(message.mifidExecutionDecisionIdType));
    message.isAutomated !== undefined && (obj.isAutomated = message.isAutomated);
    message.senderLocationId !== undefined && (obj.senderLocationId = message.senderLocationId);
    message.senderOperatorId !== undefined && (obj.senderOperatorId = message.senderOperatorId);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequest>, I>>(base?: I): OrderRequest {
    return OrderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderRequest>, I>>(object: I): OrderRequest {
    const message = createBaseOrderRequest();
    message.requestId = object.requestId ?? 0;
    message.newOrder =
      object.newOrder !== undefined && object.newOrder !== null ? NewOrder.fromPartial(object.newOrder) : undefined;
    message.modifyOrder =
      object.modifyOrder !== undefined && object.modifyOrder !== null ? ModifyOrder.fromPartial(object.modifyOrder) : undefined;
    message.cancelOrder =
      object.cancelOrder !== undefined && object.cancelOrder !== null ? CancelOrder.fromPartial(object.cancelOrder) : undefined;
    message.cancelAllOrders =
      object.cancelAllOrders !== undefined && object.cancelAllOrders !== null
        ? CancelAllOrders.fromPartial(object.cancelAllOrders)
        : undefined;
    message.liquidateAll =
      object.liquidateAll !== undefined && object.liquidateAll !== null
        ? LiquidateAll.fromPartial(object.liquidateAll)
        : undefined;
    message.goFlat = object.goFlat !== undefined && object.goFlat !== null ? GoFlat.fromPartial(object.goFlat) : undefined;
    message.suspendOrder =
      object.suspendOrder !== undefined && object.suspendOrder !== null
        ? SuspendOrder.fromPartial(object.suspendOrder)
        : undefined;
    message.activateOrder =
      object.activateOrder !== undefined && object.activateOrder !== null
        ? ActivateOrder.fromPartial(object.activateOrder)
        : undefined;
    message.modifyUserAttributes =
      object.modifyUserAttributes !== undefined && object.modifyUserAttributes !== null
        ? ModifyUserAttributes.fromPartial(object.modifyUserAttributes)
        : undefined;
    message.newCompoundOrder =
      object.newCompoundOrder !== undefined && object.newCompoundOrder !== null
        ? NewCompoundOrder.fromPartial(object.newCompoundOrder)
        : undefined;
    message.syntheticLiquidate =
      object.syntheticLiquidate !== undefined && object.syntheticLiquidate !== null
        ? SyntheticLiquidate.fromPartial(object.syntheticLiquidate)
        : undefined;
    message.syntheticScratch =
      object.syntheticScratch !== undefined && object.syntheticScratch !== null
        ? SyntheticScratch.fromPartial(object.syntheticScratch)
        : undefined;
    message.goMarket =
      object.goMarket !== undefined && object.goMarket !== null ? GoMarket.fromPartial(object.goMarket) : undefined;
    message.approveOrder =
      object.approveOrder !== undefined && object.approveOrder !== null
        ? ApproveOrder.fromPartial(object.approveOrder)
        : undefined;
    message.fillCareOrder =
      object.fillCareOrder !== undefined && object.fillCareOrder !== null
        ? FillCareOrder.fromPartial(object.fillCareOrder)
        : undefined;
    message.onBehalfOfUser = object.onBehalfOfUser ?? '';
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.mifidAlgorithmId = object.mifidAlgorithmId ?? '';
    message.mifidAlgorithmIdType = object.mifidAlgorithmIdType ?? 0;
    message.overrideExecutionWithinFirmWithNore = object.overrideExecutionWithinFirmWithNore ?? false;
    message.mifidInvestmentDecisionId = object.mifidInvestmentDecisionId ?? '';
    message.mifidInvestmentDecisionIdType = object.mifidInvestmentDecisionIdType ?? 0;
    message.mifidExecutionDecisionId = object.mifidExecutionDecisionId ?? '';
    message.mifidExecutionDecisionIdType = object.mifidExecutionDecisionIdType ?? 0;
    message.isAutomated = object.isAutomated ?? false;
    message.senderLocationId = object.senderLocationId ?? '';
    message.senderOperatorId = object.senderOperatorId ?? '';
    return message;
  },
};

function createBaseNewOrder(): NewOrder {
  return { order: undefined, suspend: false };
}

export const NewOrder = {
  encode(message: NewOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(10).fork()).ldelim();
    }
    if (message.suspend === true) {
      writer.uint32(16).bool(message.suspend);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.order = Order.decode(reader, reader.uint32());
          break;
        case 2:
          message.suspend = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NewOrder {
    return {
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      suspend: isSet(object.suspend) ? Boolean(object.suspend) : false,
    };
  },

  toJSON(message: NewOrder): unknown {
    const obj: any = {};
    message.order !== undefined && (obj.order = message.order ? Order.toJSON(message.order) : undefined);
    message.suspend !== undefined && (obj.suspend = message.suspend);
    return obj;
  },

  create<I extends Exact<DeepPartial<NewOrder>, I>>(base?: I): NewOrder {
    return NewOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NewOrder>, I>>(object: I): NewOrder {
    const message = createBaseNewOrder();
    message.order = object.order !== undefined && object.order !== null ? Order.fromPartial(object.order) : undefined;
    message.suspend = object.suspend ?? false;
    return message;
  },
};

function createBaseModifyOrder(): ModifyOrder {
  return {
    orderId: '',
    accountId: 0,
    origClOrderId: '',
    clOrderId: '',
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    uint32Qty: 0,
    uint32VisibleQty: 0,
    uint32MinVisibleQty: 0,
    qty: undefined,
    visibleQty: undefined,
    minVisibleQty: undefined,
    scaledLimitPrice: 0,
    scaledStopPrice: 0,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    removeActivationTime: false,
    suspensionUtcTime: 0,
    suspensionUtcTimestamp: undefined,
    removeSuspensionUtcTime: false,
    duration: 0,
    goodThruDate: 0,
    goodThruUtcTime: 0,
    goodThruUtcTimestamp: undefined,
    extraAttributes: [],
    executionSourceCode: '',
  };
}

export const ModifyOrder = {
  encode(message: ModifyOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== '') {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(146).fork()).ldelim();
    }
    if (message.uint32Qty !== 0) {
      writer.uint32(48).uint32(message.uint32Qty);
    }
    if (message.uint32VisibleQty !== 0) {
      writer.uint32(56).uint32(message.uint32VisibleQty);
    }
    if (message.uint32MinVisibleQty !== 0) {
      writer.uint32(64).uint32(message.uint32MinVisibleQty);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(186).fork()).ldelim();
    }
    if (message.visibleQty !== undefined) {
      Decimal.encode(message.visibleQty, writer.uint32(194).fork()).ldelim();
    }
    if (message.minVisibleQty !== undefined) {
      Decimal.encode(message.minVisibleQty, writer.uint32(202).fork()).ldelim();
    }
    if (message.scaledLimitPrice !== 0) {
      writer.uint32(72).sint64(message.scaledLimitPrice);
    }
    if (message.scaledStopPrice !== 0) {
      writer.uint32(80).sint64(message.scaledStopPrice);
    }
    if (message.activationUtcTime !== 0) {
      writer.uint32(88).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(154).fork()).ldelim();
    }
    if (message.removeActivationTime === true) {
      writer.uint32(96).bool(message.removeActivationTime);
    }
    if (message.suspensionUtcTime !== 0) {
      writer.uint32(104).sint64(message.suspensionUtcTime);
    }
    if (message.suspensionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.suspensionUtcTimestamp), writer.uint32(162).fork()).ldelim();
    }
    if (message.removeSuspensionUtcTime === true) {
      writer.uint32(112).bool(message.removeSuspensionUtcTime);
    }
    if (message.duration !== 0) {
      writer.uint32(120).uint32(message.duration);
    }
    if (message.goodThruDate !== 0) {
      writer.uint32(128).sint64(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== 0) {
      writer.uint32(136).sint64(message.goodThruUtcTime);
    }
    if (message.goodThruUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.goodThruUtcTimestamp), writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(218).string(message.executionSourceCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.origClOrderId = reader.string();
          break;
        case 4:
          message.clOrderId = reader.string();
          break;
        case 5:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 18:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.uint32Qty = reader.uint32();
          break;
        case 7:
          message.uint32VisibleQty = reader.uint32();
          break;
        case 8:
          message.uint32MinVisibleQty = reader.uint32();
          break;
        case 23:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 24:
          message.visibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 25:
          message.minVisibleQty = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.scaledLimitPrice = longToNumber(reader.sint64() as Long);
          break;
        case 10:
          message.scaledStopPrice = longToNumber(reader.sint64() as Long);
          break;
        case 11:
          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 19:
          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 12:
          message.removeActivationTime = reader.bool();
          break;
        case 13:
          message.suspensionUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 20:
          message.suspensionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 14:
          message.removeSuspensionUtcTime = reader.bool();
          break;
        case 15:
          message.duration = reader.uint32();
          break;
        case 16:
          message.goodThruDate = longToNumber(reader.sint64() as Long);
          break;
        case 17:
          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 21:
          message.goodThruUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 26:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        case 27:
          message.executionSourceCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ModifyOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? String(object.origClOrderId) : '',
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      uint32Qty: isSet(object.uint32Qty) ? Number(object.uint32Qty) : 0,
      uint32VisibleQty: isSet(object.uint32VisibleQty) ? Number(object.uint32VisibleQty) : 0,
      uint32MinVisibleQty: isSet(object.uint32MinVisibleQty) ? Number(object.uint32MinVisibleQty) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      visibleQty: isSet(object.visibleQty) ? Decimal.fromJSON(object.visibleQty) : undefined,
      minVisibleQty: isSet(object.minVisibleQty) ? Decimal.fromJSON(object.minVisibleQty) : undefined,
      scaledLimitPrice: isSet(object.scaledLimitPrice) ? Number(object.scaledLimitPrice) : 0,
      scaledStopPrice: isSet(object.scaledStopPrice) ? Number(object.scaledStopPrice) : 0,
      activationUtcTime: isSet(object.activationUtcTime) ? Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp) ? fromJsonTimestamp(object.activationUtcTimestamp) : undefined,
      removeActivationTime: isSet(object.removeActivationTime) ? Boolean(object.removeActivationTime) : false,
      suspensionUtcTime: isSet(object.suspensionUtcTime) ? Number(object.suspensionUtcTime) : 0,
      suspensionUtcTimestamp: isSet(object.suspensionUtcTimestamp) ? fromJsonTimestamp(object.suspensionUtcTimestamp) : undefined,
      removeSuspensionUtcTime: isSet(object.removeSuspensionUtcTime) ? Boolean(object.removeSuspensionUtcTime) : false,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? Number(object.goodThruDate) : 0,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? Number(object.goodThruUtcTime) : 0,
      goodThruUtcTimestamp: isSet(object.goodThruUtcTimestamp) ? fromJsonTimestamp(object.goodThruUtcTimestamp) : undefined,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
    };
  },

  toJSON(message: ModifyOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.origClOrderId !== undefined && (obj.origClOrderId = message.origClOrderId);
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.uint32Qty !== undefined && (obj.uint32Qty = Math.round(message.uint32Qty));
    message.uint32VisibleQty !== undefined && (obj.uint32VisibleQty = Math.round(message.uint32VisibleQty));
    message.uint32MinVisibleQty !== undefined && (obj.uint32MinVisibleQty = Math.round(message.uint32MinVisibleQty));
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.visibleQty !== undefined && (obj.visibleQty = message.visibleQty ? Decimal.toJSON(message.visibleQty) : undefined);
    message.minVisibleQty !== undefined &&
      (obj.minVisibleQty = message.minVisibleQty ? Decimal.toJSON(message.minVisibleQty) : undefined);
    message.scaledLimitPrice !== undefined && (obj.scaledLimitPrice = Math.round(message.scaledLimitPrice));
    message.scaledStopPrice !== undefined && (obj.scaledStopPrice = Math.round(message.scaledStopPrice));
    message.activationUtcTime !== undefined && (obj.activationUtcTime = Math.round(message.activationUtcTime));
    message.activationUtcTimestamp !== undefined && (obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString());
    message.removeActivationTime !== undefined && (obj.removeActivationTime = message.removeActivationTime);
    message.suspensionUtcTime !== undefined && (obj.suspensionUtcTime = Math.round(message.suspensionUtcTime));
    message.suspensionUtcTimestamp !== undefined && (obj.suspensionUtcTimestamp = message.suspensionUtcTimestamp.toISOString());
    message.removeSuspensionUtcTime !== undefined && (obj.removeSuspensionUtcTime = message.removeSuspensionUtcTime);
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.goodThruDate !== undefined && (obj.goodThruDate = Math.round(message.goodThruDate));
    message.goodThruUtcTime !== undefined && (obj.goodThruUtcTime = Math.round(message.goodThruUtcTime));
    message.goodThruUtcTimestamp !== undefined && (obj.goodThruUtcTimestamp = message.goodThruUtcTimestamp.toISOString());
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyOrder>, I>>(base?: I): ModifyOrder {
    return ModifyOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ModifyOrder>, I>>(object: I): ModifyOrder {
    const message = createBaseModifyOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? '';
    message.clOrderId = object.clOrderId ?? '';
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.uint32Qty = object.uint32Qty ?? 0;
    message.uint32VisibleQty = object.uint32VisibleQty ?? 0;
    message.uint32MinVisibleQty = object.uint32MinVisibleQty ?? 0;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.visibleQty =
      object.visibleQty !== undefined && object.visibleQty !== null ? Decimal.fromPartial(object.visibleQty) : undefined;
    message.minVisibleQty =
      object.minVisibleQty !== undefined && object.minVisibleQty !== null ? Decimal.fromPartial(object.minVisibleQty) : undefined;
    message.scaledLimitPrice = object.scaledLimitPrice ?? 0;
    message.scaledStopPrice = object.scaledStopPrice ?? 0;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.removeActivationTime = object.removeActivationTime ?? false;
    message.suspensionUtcTime = object.suspensionUtcTime ?? 0;
    message.suspensionUtcTimestamp = object.suspensionUtcTimestamp ?? undefined;
    message.removeSuspensionUtcTime = object.removeSuspensionUtcTime ?? false;
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.goodThruUtcTimestamp = object.goodThruUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.executionSourceCode = object.executionSourceCode ?? '';
    return message;
  },
};

function createBaseCancelOrder(): CancelOrder {
  return {
    orderId: '',
    accountId: 0,
    origClOrderId: '',
    clOrderId: '',
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    extraAttributes: [],
  };
}

export const CancelOrder = {
  encode(message: CancelOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== '') {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.origClOrderId = reader.string();
          break;
        case 4:
          message.clOrderId = reader.string();
          break;
        case 5:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? String(object.origClOrderId) : '',
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CancelOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.origClOrderId !== undefined && (obj.origClOrderId = message.origClOrderId);
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelOrder>, I>>(base?: I): CancelOrder {
    return CancelOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CancelOrder>, I>>(object: I): CancelOrder {
    const message = createBaseCancelOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? '';
    message.clOrderId = object.clOrderId ?? '';
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountOrderFilter(): AccountOrderFilter {
  return { accountId: 0, mine: false, suspended: false, side: 0, contractId: 0, currentDayOnly: false };
}

export const AccountOrderFilter = {
  encode(message: AccountOrderFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.mine === true) {
      writer.uint32(16).bool(message.mine);
    }
    if (message.suspended === true) {
      writer.uint32(24).bool(message.suspended);
    }
    if (message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.contractId !== 0) {
      writer.uint32(40).uint32(message.contractId);
    }
    if (message.currentDayOnly === true) {
      writer.uint32(48).bool(message.currentDayOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountOrderFilter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountOrderFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.mine = reader.bool();
          break;
        case 3:
          message.suspended = reader.bool();
          break;
        case 4:
          message.side = reader.uint32();
          break;
        case 5:
          message.contractId = reader.uint32();
          break;
        case 6:
          message.currentDayOnly = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountOrderFilter {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      mine: isSet(object.mine) ? Boolean(object.mine) : false,
      suspended: isSet(object.suspended) ? Boolean(object.suspended) : false,
      side: isSet(object.side) ? Number(object.side) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      currentDayOnly: isSet(object.currentDayOnly) ? Boolean(object.currentDayOnly) : false,
    };
  },

  toJSON(message: AccountOrderFilter): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.mine !== undefined && (obj.mine = message.mine);
    message.suspended !== undefined && (obj.suspended = message.suspended);
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.currentDayOnly !== undefined && (obj.currentDayOnly = message.currentDayOnly);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountOrderFilter>, I>>(base?: I): AccountOrderFilter {
    return AccountOrderFilter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountOrderFilter>, I>>(object: I): AccountOrderFilter {
    const message = createBaseAccountOrderFilter();
    message.accountId = object.accountId ?? 0;
    message.mine = object.mine ?? false;
    message.suspended = object.suspended ?? false;
    message.side = object.side ?? 0;
    message.contractId = object.contractId ?? 0;
    message.currentDayOnly = object.currentDayOnly ?? false;
    return message;
  },
};

function createBaseCancelAllOrders(): CancelAllOrders {
  return { whenUtcTime: 0, whenUtcTimestamp: undefined, clOrderId: '', accountOrderFilters: [] };
}

export const CancelAllOrders = {
  encode(message: CancelAllOrders, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.whenUtcTime !== 0) {
      writer.uint32(8).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    if (message.clOrderId !== '') {
      writer.uint32(18).string(message.clOrderId);
    }
    for (const v of message.accountOrderFilters) {
      AccountOrderFilter.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelAllOrders {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelAllOrders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.clOrderId = reader.string();
          break;
        case 3:
          message.accountOrderFilters.push(AccountOrderFilter.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelAllOrders {
    return {
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      accountOrderFilters: Array.isArray(object?.accountOrderFilters)
        ? object.accountOrderFilters.map((e: any) => AccountOrderFilter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CancelAllOrders): unknown {
    const obj: any = {};
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    if (message.accountOrderFilters) {
      obj.accountOrderFilters = message.accountOrderFilters.map((e) => (e ? AccountOrderFilter.toJSON(e) : undefined));
    } else {
      obj.accountOrderFilters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelAllOrders>, I>>(base?: I): CancelAllOrders {
    return CancelAllOrders.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CancelAllOrders>, I>>(object: I): CancelAllOrders {
    const message = createBaseCancelAllOrders();
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.clOrderId = object.clOrderId ?? '';
    message.accountOrderFilters = object.accountOrderFilters?.map((e) => AccountOrderFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActivateOrder(): ActivateOrder {
  return {
    orderId: '',
    accountId: 0,
    origClOrderId: '',
    clOrderId: '',
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    extraAttributes: [],
    executionSourceCode: '',
    isCareAutoActivation: false,
  };
}

export const ActivateOrder = {
  encode(message: ActivateOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== '') {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(66).string(message.executionSourceCode);
    }
    if (message.isCareAutoActivation === true) {
      writer.uint32(72).bool(message.isCareAutoActivation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.origClOrderId = reader.string();
          break;
        case 4:
          message.clOrderId = reader.string();
          break;
        case 5:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        case 8:
          message.executionSourceCode = reader.string();
          break;
        case 9:
          message.isCareAutoActivation = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActivateOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? String(object.origClOrderId) : '',
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
      isCareAutoActivation: isSet(object.isCareAutoActivation) ? Boolean(object.isCareAutoActivation) : false,
    };
  },

  toJSON(message: ActivateOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.origClOrderId !== undefined && (obj.origClOrderId = message.origClOrderId);
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    message.isCareAutoActivation !== undefined && (obj.isCareAutoActivation = message.isCareAutoActivation);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateOrder>, I>>(base?: I): ActivateOrder {
    return ActivateOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActivateOrder>, I>>(object: I): ActivateOrder {
    const message = createBaseActivateOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? '';
    message.clOrderId = object.clOrderId ?? '';
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.executionSourceCode = object.executionSourceCode ?? '';
    message.isCareAutoActivation = object.isCareAutoActivation ?? false;
    return message;
  },
};

function createBaseNewCompoundOrder(): NewCompoundOrder {
  return { compoundOrder: undefined, partialFillsHandling: false };
}

export const NewCompoundOrder = {
  encode(message: NewCompoundOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compoundOrder !== undefined) {
      CompoundOrder.encode(message.compoundOrder, writer.uint32(10).fork()).ldelim();
    }
    if (message.partialFillsHandling === true) {
      writer.uint32(16).bool(message.partialFillsHandling);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewCompoundOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewCompoundOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.compoundOrder = CompoundOrder.decode(reader, reader.uint32());
          break;
        case 2:
          message.partialFillsHandling = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NewCompoundOrder {
    return {
      compoundOrder: isSet(object.compoundOrder) ? CompoundOrder.fromJSON(object.compoundOrder) : undefined,
      partialFillsHandling: isSet(object.partialFillsHandling) ? Boolean(object.partialFillsHandling) : false,
    };
  },

  toJSON(message: NewCompoundOrder): unknown {
    const obj: any = {};
    message.compoundOrder !== undefined &&
      (obj.compoundOrder = message.compoundOrder ? CompoundOrder.toJSON(message.compoundOrder) : undefined);
    message.partialFillsHandling !== undefined && (obj.partialFillsHandling = message.partialFillsHandling);
    return obj;
  },

  create<I extends Exact<DeepPartial<NewCompoundOrder>, I>>(base?: I): NewCompoundOrder {
    return NewCompoundOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NewCompoundOrder>, I>>(object: I): NewCompoundOrder {
    const message = createBaseNewCompoundOrder();
    message.compoundOrder =
      object.compoundOrder !== undefined && object.compoundOrder !== null
        ? CompoundOrder.fromPartial(object.compoundOrder)
        : undefined;
    message.partialFillsHandling = object.partialFillsHandling ?? false;
    return message;
  },
};

function createBaseCompoundOrder(): CompoundOrder {
  return {
    type: 0,
    clCompoundId: '',
    compoundOrderEntries: [],
    profitTickOffset: 0,
    lossTickOffset: 0,
    stopLimitTickOffset: 0,
    isBracket: false,
  };
}

export const CompoundOrder = {
  encode(message: CompoundOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.clCompoundId !== '') {
      writer.uint32(18).string(message.clCompoundId);
    }
    for (const v of message.compoundOrderEntries) {
      CompoundOrderEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.profitTickOffset !== 0) {
      writer.uint32(32).sint32(message.profitTickOffset);
    }
    if (message.lossTickOffset !== 0) {
      writer.uint32(40).sint32(message.lossTickOffset);
    }
    if (message.stopLimitTickOffset !== 0) {
      writer.uint32(48).sint32(message.stopLimitTickOffset);
    }
    if (message.isBracket === true) {
      writer.uint32(56).bool(message.isBracket);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.uint32();
          break;
        case 2:
          message.clCompoundId = reader.string();
          break;
        case 3:
          message.compoundOrderEntries.push(CompoundOrderEntry.decode(reader, reader.uint32()));
          break;
        case 4:
          message.profitTickOffset = reader.sint32();
          break;
        case 5:
          message.lossTickOffset = reader.sint32();
          break;
        case 6:
          message.stopLimitTickOffset = reader.sint32();
          break;
        case 7:
          message.isBracket = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompoundOrder {
    return {
      type: isSet(object.type) ? Number(object.type) : 0,
      clCompoundId: isSet(object.clCompoundId) ? String(object.clCompoundId) : '',
      compoundOrderEntries: Array.isArray(object?.compoundOrderEntries)
        ? object.compoundOrderEntries.map((e: any) => CompoundOrderEntry.fromJSON(e))
        : [],
      profitTickOffset: isSet(object.profitTickOffset) ? Number(object.profitTickOffset) : 0,
      lossTickOffset: isSet(object.lossTickOffset) ? Number(object.lossTickOffset) : 0,
      stopLimitTickOffset: isSet(object.stopLimitTickOffset) ? Number(object.stopLimitTickOffset) : 0,
      isBracket: isSet(object.isBracket) ? Boolean(object.isBracket) : false,
    };
  },

  toJSON(message: CompoundOrder): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = Math.round(message.type));
    message.clCompoundId !== undefined && (obj.clCompoundId = message.clCompoundId);
    if (message.compoundOrderEntries) {
      obj.compoundOrderEntries = message.compoundOrderEntries.map((e) => (e ? CompoundOrderEntry.toJSON(e) : undefined));
    } else {
      obj.compoundOrderEntries = [];
    }
    message.profitTickOffset !== undefined && (obj.profitTickOffset = Math.round(message.profitTickOffset));
    message.lossTickOffset !== undefined && (obj.lossTickOffset = Math.round(message.lossTickOffset));
    message.stopLimitTickOffset !== undefined && (obj.stopLimitTickOffset = Math.round(message.stopLimitTickOffset));
    message.isBracket !== undefined && (obj.isBracket = message.isBracket);
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrder>, I>>(base?: I): CompoundOrder {
    return CompoundOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CompoundOrder>, I>>(object: I): CompoundOrder {
    const message = createBaseCompoundOrder();
    message.type = object.type ?? 0;
    message.clCompoundId = object.clCompoundId ?? '';
    message.compoundOrderEntries = object.compoundOrderEntries?.map((e) => CompoundOrderEntry.fromPartial(e)) || [];
    message.profitTickOffset = object.profitTickOffset ?? 0;
    message.lossTickOffset = object.lossTickOffset ?? 0;
    message.stopLimitTickOffset = object.stopLimitTickOffset ?? 0;
    message.isBracket = object.isBracket ?? false;
    return message;
  },
};

function createBaseUseOrder(): UseOrder {
  return { orderId: '', accountId: 0 };
}

export const UseOrder = {
  encode(message: UseOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UseOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UseOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
    };
  },

  toJSON(message: UseOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    return obj;
  },

  create<I extends Exact<DeepPartial<UseOrder>, I>>(base?: I): UseOrder {
    return UseOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UseOrder>, I>>(object: I): UseOrder {
    const message = createBaseUseOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseCompoundOrderEntry(): CompoundOrderEntry {
  return { order: undefined, compoundOrder: undefined, useOrder: undefined };
}

export const CompoundOrderEntry = {
  encode(message: CompoundOrderEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.order !== undefined) {
      Order.encode(message.order, writer.uint32(10).fork()).ldelim();
    }
    if (message.compoundOrder !== undefined) {
      CompoundOrder.encode(message.compoundOrder, writer.uint32(18).fork()).ldelim();
    }
    if (message.useOrder !== undefined) {
      UseOrder.encode(message.useOrder, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.order = Order.decode(reader, reader.uint32());
          break;
        case 2:
          message.compoundOrder = CompoundOrder.decode(reader, reader.uint32());
          break;
        case 3:
          message.useOrder = UseOrder.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderEntry {
    return {
      order: isSet(object.order) ? Order.fromJSON(object.order) : undefined,
      compoundOrder: isSet(object.compoundOrder) ? CompoundOrder.fromJSON(object.compoundOrder) : undefined,
      useOrder: isSet(object.useOrder) ? UseOrder.fromJSON(object.useOrder) : undefined,
    };
  },

  toJSON(message: CompoundOrderEntry): unknown {
    const obj: any = {};
    message.order !== undefined && (obj.order = message.order ? Order.toJSON(message.order) : undefined);
    message.compoundOrder !== undefined &&
      (obj.compoundOrder = message.compoundOrder ? CompoundOrder.toJSON(message.compoundOrder) : undefined);
    message.useOrder !== undefined && (obj.useOrder = message.useOrder ? UseOrder.toJSON(message.useOrder) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderEntry>, I>>(base?: I): CompoundOrderEntry {
    return CompoundOrderEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CompoundOrderEntry>, I>>(object: I): CompoundOrderEntry {
    const message = createBaseCompoundOrderEntry();
    message.order = object.order !== undefined && object.order !== null ? Order.fromPartial(object.order) : undefined;
    message.compoundOrder =
      object.compoundOrder !== undefined && object.compoundOrder !== null
        ? CompoundOrder.fromPartial(object.compoundOrder)
        : undefined;
    message.useOrder =
      object.useOrder !== undefined && object.useOrder !== null ? UseOrder.fromPartial(object.useOrder) : undefined;
    return message;
  },
};

function createBaseSuspendOrder(): SuspendOrder {
  return {
    orderId: '',
    accountId: 0,
    origClOrderId: '',
    clOrderId: '',
    whenUtcTime: 0,
    whenUtcTimestamp: undefined,
    activationUtcTime: 0,
    activationUtcTimestamp: undefined,
    extraAttributes: [],
  };
}

export const SuspendOrder = {
  encode(message: SuspendOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.origClOrderId !== '') {
      writer.uint32(26).string(message.origClOrderId);
    }
    if (message.clOrderId !== '') {
      writer.uint32(34).string(message.clOrderId);
    }
    if (message.whenUtcTime !== 0) {
      writer.uint32(40).sint64(message.whenUtcTime);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.activationUtcTime !== 0) {
      writer.uint32(48).sint64(message.activationUtcTime);
    }
    if (message.activationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.activationUtcTimestamp), writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SuspendOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSuspendOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.origClOrderId = reader.string();
          break;
        case 4:
          message.clOrderId = reader.string();
          break;
        case 5:
          message.whenUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.activationUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 8:
          message.activationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SuspendOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      origClOrderId: isSet(object.origClOrderId) ? String(object.origClOrderId) : '',
      clOrderId: isSet(object.clOrderId) ? String(object.clOrderId) : '',
      whenUtcTime: isSet(object.whenUtcTime) ? Number(object.whenUtcTime) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      activationUtcTime: isSet(object.activationUtcTime) ? Number(object.activationUtcTime) : 0,
      activationUtcTimestamp: isSet(object.activationUtcTimestamp) ? fromJsonTimestamp(object.activationUtcTimestamp) : undefined,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SuspendOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.origClOrderId !== undefined && (obj.origClOrderId = message.origClOrderId);
    message.clOrderId !== undefined && (obj.clOrderId = message.clOrderId);
    message.whenUtcTime !== undefined && (obj.whenUtcTime = Math.round(message.whenUtcTime));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.activationUtcTime !== undefined && (obj.activationUtcTime = Math.round(message.activationUtcTime));
    message.activationUtcTimestamp !== undefined && (obj.activationUtcTimestamp = message.activationUtcTimestamp.toISOString());
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SuspendOrder>, I>>(base?: I): SuspendOrder {
    return SuspendOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SuspendOrder>, I>>(object: I): SuspendOrder {
    const message = createBaseSuspendOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.origClOrderId = object.origClOrderId ?? '';
    message.clOrderId = object.clOrderId ?? '';
    message.whenUtcTime = object.whenUtcTime ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.activationUtcTime = object.activationUtcTime ?? 0;
    message.activationUtcTimestamp = object.activationUtcTimestamp ?? undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountPositionFilter(): AccountPositionFilter {
  return { accountId: 0, contractId: 0, isShort: false, currentDayOnly: false };
}

export const AccountPositionFilter = {
  encode(message: AccountPositionFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    if (message.isShort === true) {
      writer.uint32(24).bool(message.isShort);
    }
    if (message.currentDayOnly === true) {
      writer.uint32(32).bool(message.currentDayOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPositionFilter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPositionFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.contractId = reader.uint32();
          break;
        case 3:
          message.isShort = reader.bool();
          break;
        case 4:
          message.currentDayOnly = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountPositionFilter {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      isShort: isSet(object.isShort) ? Boolean(object.isShort) : false,
      currentDayOnly: isSet(object.currentDayOnly) ? Boolean(object.currentDayOnly) : false,
    };
  },

  toJSON(message: AccountPositionFilter): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.isShort !== undefined && (obj.isShort = message.isShort);
    message.currentDayOnly !== undefined && (obj.currentDayOnly = message.currentDayOnly);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPositionFilter>, I>>(base?: I): AccountPositionFilter {
    return AccountPositionFilter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountPositionFilter>, I>>(object: I): AccountPositionFilter {
    const message = createBaseAccountPositionFilter();
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.isShort = object.isShort ?? false;
    message.currentDayOnly = object.currentDayOnly ?? false;
    return message;
  },
};

function createBaseLiquidateAll(): LiquidateAll {
  return { accountPositionFilters: [], whenUtcTimestamp: undefined, executionSourceCode: '', speculationType: 0 };
}

export const LiquidateAll = {
  encode(message: LiquidateAll, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountPositionFilters) {
      AccountPositionFilter.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(26).string(message.executionSourceCode);
    }
    if (message.speculationType !== 0) {
      writer.uint32(32).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LiquidateAll {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLiquidateAll();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountPositionFilters.push(AccountPositionFilter.decode(reader, reader.uint32()));
          break;
        case 2:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.executionSourceCode = reader.string();
          break;
        case 4:
          message.speculationType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LiquidateAll {
    return {
      accountPositionFilters: Array.isArray(object?.accountPositionFilters)
        ? object.accountPositionFilters.map((e: any) => AccountPositionFilter.fromJSON(e))
        : [],
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
    };
  },

  toJSON(message: LiquidateAll): unknown {
    const obj: any = {};
    if (message.accountPositionFilters) {
      obj.accountPositionFilters = message.accountPositionFilters.map((e) => (e ? AccountPositionFilter.toJSON(e) : undefined));
    } else {
      obj.accountPositionFilters = [];
    }
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    return obj;
  },

  create<I extends Exact<DeepPartial<LiquidateAll>, I>>(base?: I): LiquidateAll {
    return LiquidateAll.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LiquidateAll>, I>>(object: I): LiquidateAll {
    const message = createBaseLiquidateAll();
    message.accountPositionFilters = object.accountPositionFilters?.map((e) => AccountPositionFilter.fromPartial(e)) || [];
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.executionSourceCode = object.executionSourceCode ?? '';
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseGoFlat(): GoFlat {
  return { accountIds: [], whenUtcTimestamp: undefined, executionSourceCode: '', speculationType: 0 };
}

export const GoFlat = {
  encode(message: GoFlat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.accountIds) {
      writer.sint32(v);
    }
    writer.ldelim();
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.executionSourceCode !== '') {
      writer.uint32(26).string(message.executionSourceCode);
    }
    if (message.speculationType !== 0) {
      writer.uint32(32).uint32(message.speculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoFlat {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoFlat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.sint32());
            }
          } else {
            message.accountIds.push(reader.sint32());
          }
          break;
        case 2:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.executionSourceCode = reader.string();
          break;
        case 4:
          message.speculationType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GoFlat {
    return {
      accountIds: Array.isArray(object?.accountIds) ? object.accountIds.map((e: any) => Number(e)) : [],
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      executionSourceCode: isSet(object.executionSourceCode) ? String(object.executionSourceCode) : '',
      speculationType: isSet(object.speculationType) ? Number(object.speculationType) : 0,
    };
  },

  toJSON(message: GoFlat): unknown {
    const obj: any = {};
    if (message.accountIds) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    } else {
      obj.accountIds = [];
    }
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.executionSourceCode !== undefined && (obj.executionSourceCode = message.executionSourceCode);
    message.speculationType !== undefined && (obj.speculationType = Math.round(message.speculationType));
    return obj;
  },

  create<I extends Exact<DeepPartial<GoFlat>, I>>(base?: I): GoFlat {
    return GoFlat.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GoFlat>, I>>(object: I): GoFlat {
    const message = createBaseGoFlat();
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.executionSourceCode = object.executionSourceCode ?? '';
    message.speculationType = object.speculationType ?? 0;
    return message;
  },
};

function createBaseOrderEntitlementRequest(): OrderEntitlementRequest {
  return { contractId: 0, accountId: 0 };
}

export const OrderEntitlementRequest = {
  encode(message: OrderEntitlementRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlementRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlementRequest {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
    };
  },

  toJSON(message: OrderEntitlementRequest): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlementRequest>, I>>(base?: I): OrderEntitlementRequest {
    return OrderEntitlementRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderEntitlementRequest>, I>>(object: I): OrderEntitlementRequest {
    const message = createBaseOrderEntitlementRequest();
    message.contractId = object.contractId ?? 0;
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseOrderEntitlementReport(): OrderEntitlementReport {
  return { orderEntitlements: [] };
}

export const OrderEntitlementReport = {
  encode(message: OrderEntitlementReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orderEntitlements) {
      OrderEntitlement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlementReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlementReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderEntitlements.push(OrderEntitlement.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlementReport {
    return {
      orderEntitlements: Array.isArray(object?.orderEntitlements)
        ? object.orderEntitlements.map((e: any) => OrderEntitlement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OrderEntitlementReport): unknown {
    const obj: any = {};
    if (message.orderEntitlements) {
      obj.orderEntitlements = message.orderEntitlements.map((e) => (e ? OrderEntitlement.toJSON(e) : undefined));
    } else {
      obj.orderEntitlements = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlementReport>, I>>(base?: I): OrderEntitlementReport {
    return OrderEntitlementReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderEntitlementReport>, I>>(object: I): OrderEntitlementReport {
    const message = createBaseOrderEntitlementReport();
    message.orderEntitlements = object.orderEntitlements?.map((e) => OrderEntitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrderEntitlement(): OrderEntitlement {
  return {
    orderType: 0,
    isSynthetic: false,
    duration: 0,
    execInstruction: 0,
    algoStrategyRequired: false,
    algoStrategies: [],
  };
}

export const OrderEntitlement = {
  encode(message: OrderEntitlement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderType !== 0) {
      writer.uint32(8).uint32(message.orderType);
    }
    if (message.isSynthetic === true) {
      writer.uint32(16).bool(message.isSynthetic);
    }
    if (message.duration !== 0) {
      writer.uint32(24).uint32(message.duration);
    }
    if (message.execInstruction !== 0) {
      writer.uint32(32).uint32(message.execInstruction);
    }
    if (message.algoStrategyRequired === true) {
      writer.uint32(40).bool(message.algoStrategyRequired);
    }
    for (const v of message.algoStrategies) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEntitlement {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEntitlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderType = reader.uint32();
          break;
        case 2:
          message.isSynthetic = reader.bool();
          break;
        case 3:
          message.duration = reader.uint32();
          break;
        case 4:
          message.execInstruction = reader.uint32();
          break;
        case 5:
          message.algoStrategyRequired = reader.bool();
          break;
        case 6:
          message.algoStrategies.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderEntitlement {
    return {
      orderType: isSet(object.orderType) ? Number(object.orderType) : 0,
      isSynthetic: isSet(object.isSynthetic) ? Boolean(object.isSynthetic) : false,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      execInstruction: isSet(object.execInstruction) ? Number(object.execInstruction) : 0,
      algoStrategyRequired: isSet(object.algoStrategyRequired) ? Boolean(object.algoStrategyRequired) : false,
      algoStrategies: Array.isArray(object?.algoStrategies) ? object.algoStrategies.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: OrderEntitlement): unknown {
    const obj: any = {};
    message.orderType !== undefined && (obj.orderType = Math.round(message.orderType));
    message.isSynthetic !== undefined && (obj.isSynthetic = message.isSynthetic);
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.execInstruction !== undefined && (obj.execInstruction = Math.round(message.execInstruction));
    message.algoStrategyRequired !== undefined && (obj.algoStrategyRequired = message.algoStrategyRequired);
    if (message.algoStrategies) {
      obj.algoStrategies = message.algoStrategies.map((e) => e);
    } else {
      obj.algoStrategies = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEntitlement>, I>>(base?: I): OrderEntitlement {
    return OrderEntitlement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderEntitlement>, I>>(object: I): OrderEntitlement {
    const message = createBaseOrderEntitlement();
    message.orderType = object.orderType ?? 0;
    message.isSynthetic = object.isSynthetic ?? false;
    message.duration = object.duration ?? 0;
    message.execInstruction = object.execInstruction ?? 0;
    message.algoStrategyRequired = object.algoStrategyRequired ?? false;
    message.algoStrategies = object.algoStrategies?.map((e) => e) || [];
    return message;
  },
};

function createBaseCrossOrderParameters(): CrossOrderParameters {
  return { sideAllocations: [], price: 0, transactionId: '', legAllocations: [] };
}

export const CrossOrderParameters = {
  encode(message: CrossOrderParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sideAllocations) {
      SideAllocation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.price !== 0) {
      writer.uint32(17).double(message.price);
    }
    if (message.transactionId !== '') {
      writer.uint32(26).string(message.transactionId);
    }
    for (const v of message.legAllocations) {
      LegAllocation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CrossOrderParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrossOrderParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sideAllocations.push(SideAllocation.decode(reader, reader.uint32()));
          break;
        case 2:
          message.price = reader.double();
          break;
        case 3:
          message.transactionId = reader.string();
          break;
        case 4:
          message.legAllocations.push(LegAllocation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CrossOrderParameters {
    return {
      sideAllocations: Array.isArray(object?.sideAllocations)
        ? object.sideAllocations.map((e: any) => SideAllocation.fromJSON(e))
        : [],
      price: isSet(object.price) ? Number(object.price) : 0,
      transactionId: isSet(object.transactionId) ? String(object.transactionId) : '',
      legAllocations: Array.isArray(object?.legAllocations)
        ? object.legAllocations.map((e: any) => LegAllocation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CrossOrderParameters): unknown {
    const obj: any = {};
    if (message.sideAllocations) {
      obj.sideAllocations = message.sideAllocations.map((e) => (e ? SideAllocation.toJSON(e) : undefined));
    } else {
      obj.sideAllocations = [];
    }
    message.price !== undefined && (obj.price = message.price);
    message.transactionId !== undefined && (obj.transactionId = message.transactionId);
    if (message.legAllocations) {
      obj.legAllocations = message.legAllocations.map((e) => (e ? LegAllocation.toJSON(e) : undefined));
    } else {
      obj.legAllocations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CrossOrderParameters>, I>>(base?: I): CrossOrderParameters {
    return CrossOrderParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CrossOrderParameters>, I>>(object: I): CrossOrderParameters {
    const message = createBaseCrossOrderParameters();
    message.sideAllocations = object.sideAllocations?.map((e) => SideAllocation.fromPartial(e)) || [];
    message.price = object.price ?? 0;
    message.transactionId = object.transactionId ?? '';
    message.legAllocations = object.legAllocations?.map((e) => LegAllocation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSideAllocation(): SideAllocation {
  return { sideId: 0, accountId: 0, externalAccount: undefined, qty: undefined, side: 0, extraAttributes: [] };
}

export const SideAllocation = {
  encode(message: SideAllocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sideId !== 0) {
      writer.uint32(8).sint64(message.sideId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.externalAccount !== undefined) {
      ExternalAccount.encode(message.externalAccount, writer.uint32(26).fork()).ldelim();
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(34).fork()).ldelim();
    }
    if (message.side !== 0) {
      writer.uint32(40).uint32(message.side);
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SideAllocation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSideAllocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.sideId = longToNumber(reader.sint64() as Long);
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.externalAccount = ExternalAccount.decode(reader, reader.uint32());
          break;
        case 4:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.side = reader.uint32();
          break;
        case 6:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SideAllocation {
    return {
      sideId: isSet(object.sideId) ? Number(object.sideId) : 0,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      externalAccount: isSet(object.externalAccount) ? ExternalAccount.fromJSON(object.externalAccount) : undefined,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      side: isSet(object.side) ? Number(object.side) : 0,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SideAllocation): unknown {
    const obj: any = {};
    message.sideId !== undefined && (obj.sideId = Math.round(message.sideId));
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.externalAccount !== undefined &&
      (obj.externalAccount = message.externalAccount ? ExternalAccount.toJSON(message.externalAccount) : undefined);
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.side !== undefined && (obj.side = Math.round(message.side));
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SideAllocation>, I>>(base?: I): SideAllocation {
    return SideAllocation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SideAllocation>, I>>(object: I): SideAllocation {
    const message = createBaseSideAllocation();
    message.sideId = object.sideId ?? 0;
    message.accountId = object.accountId ?? 0;
    message.externalAccount =
      object.externalAccount !== undefined && object.externalAccount !== null
        ? ExternalAccount.fromPartial(object.externalAccount)
        : undefined;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.side = object.side ?? 0;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLegAllocation(): LegAllocation {
  return { contractId: 0, qty: undefined, price: 0, side: 0, legAllocId: 0, tradeMatchId: '' };
}

export const LegAllocation = {
  encode(message: LegAllocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(18).fork()).ldelim();
    }
    if (message.price !== 0) {
      writer.uint32(25).double(message.price);
    }
    if (message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.legAllocId !== 0) {
      writer.uint32(40).sint64(message.legAllocId);
    }
    if (message.tradeMatchId !== '') {
      writer.uint32(50).string(message.tradeMatchId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LegAllocation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLegAllocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 3:
          message.price = reader.double();
          break;
        case 4:
          message.side = reader.uint32();
          break;
        case 5:
          message.legAllocId = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.tradeMatchId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LegAllocation {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      price: isSet(object.price) ? Number(object.price) : 0,
      side: isSet(object.side) ? Number(object.side) : 0,
      legAllocId: isSet(object.legAllocId) ? Number(object.legAllocId) : 0,
      tradeMatchId: isSet(object.tradeMatchId) ? String(object.tradeMatchId) : '',
    };
  },

  toJSON(message: LegAllocation): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.price !== undefined && (obj.price = message.price);
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.legAllocId !== undefined && (obj.legAllocId = Math.round(message.legAllocId));
    message.tradeMatchId !== undefined && (obj.tradeMatchId = message.tradeMatchId);
    return obj;
  },

  create<I extends Exact<DeepPartial<LegAllocation>, I>>(base?: I): LegAllocation {
    return LegAllocation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LegAllocation>, I>>(object: I): LegAllocation {
    const message = createBaseLegAllocation();
    message.contractId = object.contractId ?? 0;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.price = object.price ?? 0;
    message.side = object.side ?? 0;
    message.legAllocId = object.legAllocId ?? 0;
    message.tradeMatchId = object.tradeMatchId ?? '';
    return message;
  },
};

function createBaseExternalAccount(): ExternalAccount {
  return { externalAccountNumber: '', accountType: '', accountOrigin: '', giveUpMemberId: '' };
}

export const ExternalAccount = {
  encode(message: ExternalAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.externalAccountNumber !== '') {
      writer.uint32(10).string(message.externalAccountNumber);
    }
    if (message.accountType !== '') {
      writer.uint32(18).string(message.accountType);
    }
    if (message.accountOrigin !== '') {
      writer.uint32(26).string(message.accountOrigin);
    }
    if (message.giveUpMemberId !== '') {
      writer.uint32(34).string(message.giveUpMemberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalAccount {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.externalAccountNumber = reader.string();
          break;
        case 2:
          message.accountType = reader.string();
          break;
        case 3:
          message.accountOrigin = reader.string();
          break;
        case 4:
          message.giveUpMemberId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExternalAccount {
    return {
      externalAccountNumber: isSet(object.externalAccountNumber) ? String(object.externalAccountNumber) : '',
      accountType: isSet(object.accountType) ? String(object.accountType) : '',
      accountOrigin: isSet(object.accountOrigin) ? String(object.accountOrigin) : '',
      giveUpMemberId: isSet(object.giveUpMemberId) ? String(object.giveUpMemberId) : '',
    };
  },

  toJSON(message: ExternalAccount): unknown {
    const obj: any = {};
    message.externalAccountNumber !== undefined && (obj.externalAccountNumber = message.externalAccountNumber);
    message.accountType !== undefined && (obj.accountType = message.accountType);
    message.accountOrigin !== undefined && (obj.accountOrigin = message.accountOrigin);
    message.giveUpMemberId !== undefined && (obj.giveUpMemberId = message.giveUpMemberId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExternalAccount>, I>>(base?: I): ExternalAccount {
    return ExternalAccount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExternalAccount>, I>>(object: I): ExternalAccount {
    const message = createBaseExternalAccount();
    message.externalAccountNumber = object.externalAccountNumber ?? '';
    message.accountType = object.accountType ?? '';
    message.accountOrigin = object.accountOrigin ?? '';
    message.giveUpMemberId = object.giveUpMemberId ?? '';
    return message;
  },
};

function createBaseApproveOrder(): ApproveOrder {
  return {
    orderId: '',
    accountId: 0,
    action: 0,
    whenUtcTimestamp: undefined,
    sideAllocation: undefined,
    extraAttributes: [],
  };
}

export const ApproveOrder = {
  encode(message: ApproveOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== '') {
      writer.uint32(10).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.action !== 0) {
      writer.uint32(24).uint32(message.action);
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    if (message.sideAllocation !== undefined) {
      SideAllocation.encode(message.sideAllocation, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.extraAttributes) {
      NamedValue.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApproveOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.action = reader.uint32();
          break;
        case 4:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 5:
          message.sideAllocation = SideAllocation.decode(reader, reader.uint32());
          break;
        case 6:
          message.extraAttributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveOrder {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      action: isSet(object.action) ? Number(object.action) : 0,
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      sideAllocation: isSet(object.sideAllocation) ? SideAllocation.fromJSON(object.sideAllocation) : undefined,
      extraAttributes: Array.isArray(object?.extraAttributes)
        ? object.extraAttributes.map((e: any) => NamedValue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveOrder): unknown {
    const obj: any = {};
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.action !== undefined && (obj.action = Math.round(message.action));
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.sideAllocation !== undefined &&
      (obj.sideAllocation = message.sideAllocation ? SideAllocation.toJSON(message.sideAllocation) : undefined);
    if (message.extraAttributes) {
      obj.extraAttributes = message.extraAttributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.extraAttributes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApproveOrder>, I>>(base?: I): ApproveOrder {
    return ApproveOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ApproveOrder>, I>>(object: I): ApproveOrder {
    const message = createBaseApproveOrder();
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.action = object.action ?? 0;
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.sideAllocation =
      object.sideAllocation !== undefined && object.sideAllocation !== null
        ? SideAllocation.fromPartial(object.sideAllocation)
        : undefined;
    message.extraAttributes = object.extraAttributes?.map((e) => NamedValue.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFillCareOrder(): FillCareOrder {
  return {
    execId: '',
    fillCareOrderRequestId: '',
    fillPrice: 0,
    fillQty: undefined,
    orderId: '',
    accountId: 0,
    orderLegs: [],
    oppositeSide: undefined,
  };
}

export const FillCareOrder = {
  encode(message: FillCareOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.execId !== '') {
      writer.uint32(10).string(message.execId);
    }
    if (message.fillCareOrderRequestId !== '') {
      writer.uint32(18).string(message.fillCareOrderRequestId);
    }
    if (message.fillPrice !== 0) {
      writer.uint32(25).double(message.fillPrice);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(34).fork()).ldelim();
    }
    if (message.orderId !== '') {
      writer.uint32(42).string(message.orderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(48).sint32(message.accountId);
    }
    for (const v of message.orderLegs) {
      FillCareOrderLeg.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.oppositeSide !== undefined) {
      FillCareOppositeSide.encode(message.oppositeSide, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.execId = reader.string();
          break;
        case 2:
          message.fillCareOrderRequestId = reader.string();
          break;
        case 3:
          message.fillPrice = reader.double();
          break;
        case 4:
          message.fillQty = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.orderId = reader.string();
          break;
        case 6:
          message.accountId = reader.sint32();
          break;
        case 7:
          message.orderLegs.push(FillCareOrderLeg.decode(reader, reader.uint32()));
          break;
        case 8:
          message.oppositeSide = FillCareOppositeSide.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FillCareOrder {
    return {
      execId: isSet(object.execId) ? String(object.execId) : '',
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId) ? String(object.fillCareOrderRequestId) : '',
      fillPrice: isSet(object.fillPrice) ? Number(object.fillPrice) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      orderLegs: Array.isArray(object?.orderLegs) ? object.orderLegs.map((e: any) => FillCareOrderLeg.fromJSON(e)) : [],
      oppositeSide: isSet(object.oppositeSide) ? FillCareOppositeSide.fromJSON(object.oppositeSide) : undefined,
    };
  },

  toJSON(message: FillCareOrder): unknown {
    const obj: any = {};
    message.execId !== undefined && (obj.execId = message.execId);
    message.fillCareOrderRequestId !== undefined && (obj.fillCareOrderRequestId = message.fillCareOrderRequestId);
    message.fillPrice !== undefined && (obj.fillPrice = message.fillPrice);
    message.fillQty !== undefined && (obj.fillQty = message.fillQty ? Decimal.toJSON(message.fillQty) : undefined);
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    if (message.orderLegs) {
      obj.orderLegs = message.orderLegs.map((e) => (e ? FillCareOrderLeg.toJSON(e) : undefined));
    } else {
      obj.orderLegs = [];
    }
    message.oppositeSide !== undefined &&
      (obj.oppositeSide = message.oppositeSide ? FillCareOppositeSide.toJSON(message.oppositeSide) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOrder>, I>>(base?: I): FillCareOrder {
    return FillCareOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FillCareOrder>, I>>(object: I): FillCareOrder {
    const message = createBaseFillCareOrder();
    message.execId = object.execId ?? '';
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? '';
    message.fillPrice = object.fillPrice ?? 0;
    message.fillQty = object.fillQty !== undefined && object.fillQty !== null ? Decimal.fromPartial(object.fillQty) : undefined;
    message.orderId = object.orderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.orderLegs = object.orderLegs?.map((e) => FillCareOrderLeg.fromPartial(e)) || [];
    message.oppositeSide =
      object.oppositeSide !== undefined && object.oppositeSide !== null
        ? FillCareOppositeSide.fromPartial(object.oppositeSide)
        : undefined;
    return message;
  },
};

function createBaseFillCareOrderLeg(): FillCareOrderLeg {
  return { legExecId: '', nodeIndex: 0, fillPrice: 0, fillQty: undefined };
}

export const FillCareOrderLeg = {
  encode(message: FillCareOrderLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.legExecId !== '') {
      writer.uint32(10).string(message.legExecId);
    }
    if (message.nodeIndex !== 0) {
      writer.uint32(16).uint32(message.nodeIndex);
    }
    if (message.fillPrice !== 0) {
      writer.uint32(25).double(message.fillPrice);
    }
    if (message.fillQty !== undefined) {
      Decimal.encode(message.fillQty, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOrderLeg {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOrderLeg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.legExecId = reader.string();
          break;
        case 2:
          message.nodeIndex = reader.uint32();
          break;
        case 3:
          message.fillPrice = reader.double();
          break;
        case 4:
          message.fillQty = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FillCareOrderLeg {
    return {
      legExecId: isSet(object.legExecId) ? String(object.legExecId) : '',
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      fillPrice: isSet(object.fillPrice) ? Number(object.fillPrice) : 0,
      fillQty: isSet(object.fillQty) ? Decimal.fromJSON(object.fillQty) : undefined,
    };
  },

  toJSON(message: FillCareOrderLeg): unknown {
    const obj: any = {};
    message.legExecId !== undefined && (obj.legExecId = message.legExecId);
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.fillPrice !== undefined && (obj.fillPrice = message.fillPrice);
    message.fillQty !== undefined && (obj.fillQty = message.fillQty ? Decimal.toJSON(message.fillQty) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOrderLeg>, I>>(base?: I): FillCareOrderLeg {
    return FillCareOrderLeg.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FillCareOrderLeg>, I>>(object: I): FillCareOrderLeg {
    const message = createBaseFillCareOrderLeg();
    message.legExecId = object.legExecId ?? '';
    message.nodeIndex = object.nodeIndex ?? 0;
    message.fillPrice = object.fillPrice ?? 0;
    message.fillQty = object.fillQty !== undefined && object.fillQty !== null ? Decimal.fromPartial(object.fillQty) : undefined;
    return message;
  },
};

function createBaseFillCareOppositeSide(): FillCareOppositeSide {
  return { execId: '', orderId: '', createNewOrder: false, accountId: 0, orderLegs: [] };
}

export const FillCareOppositeSide = {
  encode(message: FillCareOppositeSide, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.execId !== '') {
      writer.uint32(10).string(message.execId);
    }
    if (message.orderId !== '') {
      writer.uint32(18).string(message.orderId);
    }
    if (message.createNewOrder === true) {
      writer.uint32(24).bool(message.createNewOrder);
    }
    if (message.accountId !== 0) {
      writer.uint32(32).sint32(message.accountId);
    }
    for (const v of message.orderLegs) {
      FillCareOrderLeg.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCareOppositeSide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCareOppositeSide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.execId = reader.string();
          break;
        case 2:
          message.orderId = reader.string();
          break;
        case 3:
          message.createNewOrder = reader.bool();
          break;
        case 4:
          message.accountId = reader.sint32();
          break;
        case 5:
          message.orderLegs.push(FillCareOrderLeg.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FillCareOppositeSide {
    return {
      execId: isSet(object.execId) ? String(object.execId) : '',
      orderId: isSet(object.orderId) ? String(object.orderId) : '',
      createNewOrder: isSet(object.createNewOrder) ? Boolean(object.createNewOrder) : false,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      orderLegs: Array.isArray(object?.orderLegs) ? object.orderLegs.map((e: any) => FillCareOrderLeg.fromJSON(e)) : [],
    };
  },

  toJSON(message: FillCareOppositeSide): unknown {
    const obj: any = {};
    message.execId !== undefined && (obj.execId = message.execId);
    message.orderId !== undefined && (obj.orderId = message.orderId);
    message.createNewOrder !== undefined && (obj.createNewOrder = message.createNewOrder);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    if (message.orderLegs) {
      obj.orderLegs = message.orderLegs.map((e) => (e ? FillCareOrderLeg.toJSON(e) : undefined));
    } else {
      obj.orderLegs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCareOppositeSide>, I>>(base?: I): FillCareOppositeSide {
    return FillCareOppositeSide.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FillCareOppositeSide>, I>>(object: I): FillCareOppositeSide {
    const message = createBaseFillCareOppositeSide();
    message.execId = object.execId ?? '';
    message.orderId = object.orderId ?? '';
    message.createNewOrder = object.createNewOrder ?? false;
    message.accountId = object.accountId ?? 0;
    message.orderLegs = object.orderLegs?.map((e) => FillCareOrderLeg.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrderStatusRequest(): OrderStatusRequest {
  return { chainOrderId: '', accountId: 0 };
}

export const OrderStatusRequest = {
  encode(message: OrderStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== '') {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainOrderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderStatusRequest {
    return {
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
    };
  },

  toJSON(message: OrderStatusRequest): unknown {
    const obj: any = {};
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatusRequest>, I>>(base?: I): OrderStatusRequest {
    return OrderStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderStatusRequest>, I>>(object: I): OrderStatusRequest {
    const message = createBaseOrderStatusRequest();
    message.chainOrderId = object.chainOrderId ?? '';
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseOrderStatusReport(): OrderStatusReport {
  return { orderStatus: undefined };
}

export const OrderStatusReport = {
  encode(message: OrderStatusReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderStatus !== undefined) {
      OrderStatus.encode(message.orderStatus, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderStatusReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderStatusReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderStatus = OrderStatus.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderStatusReport {
    return { orderStatus: isSet(object.orderStatus) ? OrderStatus.fromJSON(object.orderStatus) : undefined };
  },

  toJSON(message: OrderStatusReport): unknown {
    const obj: any = {};
    message.orderStatus !== undefined &&
      (obj.orderStatus = message.orderStatus ? OrderStatus.toJSON(message.orderStatus) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderStatusReport>, I>>(base?: I): OrderStatusReport {
    return OrderStatusReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderStatusReport>, I>>(object: I): OrderStatusReport {
    const message = createBaseOrderStatusReport();
    message.orderStatus =
      object.orderStatus !== undefined && object.orderStatus !== null ? OrderStatus.fromPartial(object.orderStatus) : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
