import { type ReactQueryOptions, type RouterInputs, type RouterOutputs, trpc } from '../shared/trpc';

type GetOperationSummariesOptions = ReactQueryOptions['operationSummary']['getSummary'];
type GetOperationSummariesInput = RouterInputs['operationSummary']['getSummary'];
export type GetOperationSummariesResult = RouterOutputs['operationSummary']['getSummary'];
export type OperationSummary = GetOperationSummariesResult;

export function useOperationSummary(input: GetOperationSummariesInput, opts?: GetOperationSummariesOptions) {
  const qry = trpc.operationSummary.getSummary.useQuery(input, opts);
  return qry;
}
