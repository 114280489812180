import React from 'react';
import { FontAwesomeIcon, IconDefinition, faInfoCircle } from '../../faIcons';
import { Tooltip } from '@material-ui/core';
import { useStyles } from './toolTipStyles';

export default function InfoToolTip({ title, icon }: InfoToolTipProps) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      title={title}
      placement={'top-start'}
      classes={{
        tooltipArrow: classes.tooltipArrow,
        arrow: classes.arrow,
      }}
    >
      <span className={`${classes.infoIcon} info-tooltip`}>
        <FontAwesomeIcon icon={icon ? icon : (faInfoCircle as IconDefinition)} />
      </span>
    </Tooltip>
  );
}

type InfoToolTipProps = {
  title: string | NonNullable<React.ReactNode>;
  icon?: IconDefinition;
};
