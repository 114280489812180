import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { IBudget } from '../../types/IBudget';

export const fetchBudgets = createAsyncThunk('budgets/fetchBudgets', async (operationId: number) => {
  const response = await axios.get(`${config.backend.basePath}/costs/${operationId}`);
  return response.data;
});

export const fetchDefaultUniversityBudgets = createAsyncThunk('budgets/fetchDefaultUniversityBudgets', async () => {
  const response = await axios.get(`${config.backend.basePath}/costs/university`);
  return response.data;
});

export const createBudget = createAsyncThunk('budgets/createBudget', async (budget: IBudget) => {
  const resp = await axios.post(`${config.backend.basePath}/costs`, budget);
  return resp.data;
});

export const createOldBudget = createAsyncThunk('budgets/createBudget', async (budget: IBudget) => {
  const resp = await axios.post(`${config.backend.basePath}/budgets`, budget);
  return resp.data;
});

export const updateBudget = createAsyncThunk('budgets/updateBudget', async (budget: IBudget) => {
  await axios.patch(`${config.backend.basePath}/costs`, budget);
  const fetchResponse = await axios.get(`${config.backend.basePath}/costs/${budget.operationId}`);
  return fetchResponse.data.find((b: IBudget) => b.id === budget.id);
});

export const deleteBudget = createAsyncThunk('budgets/deleteBudget', async (data: IBudget) => {
  await axios.delete(`${config.backend.basePath}/costs`, { data });
  return data;
});
