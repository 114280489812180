import { CommodityInformation, InstrumentType } from '@harvestiq/constants';
import { BuySellType } from '@farmersrisk/shared/enums';
import { SelectorTypes } from '../components/common/SelectWithLabel/SelectWithLabel';

export const defaultTradeableInstrumentTypeSelections: SelectorTypes<InstrumentType>[] = [
  { value: InstrumentType.Futures, label: 'Future' },
  { value: InstrumentType.Call, label: 'Call' },
  { value: InstrumentType.Put, label: 'Put' },
];

const withNewCropTradeableInstrumentTypeSelections: SelectorTypes<InstrumentType>[] =
  defaultTradeableInstrumentTypeSelections.concat([
    { value: InstrumentType.ShortDatedCall, label: 'Short Dated Call' },
    { value: InstrumentType.ShortDatedPut, label: 'Short Dated Put' },
  ]);

export const instrumentContractTypeSelections: SelectorTypes<InstrumentType>[] =
  withNewCropTradeableInstrumentTypeSelections.concat([
    { value: InstrumentType.Week1Call, label: 'Week 1 Call' },
    { value: InstrumentType.Week1Put, label: 'Week 1 Put' },
    { value: InstrumentType.Week2Call, label: 'Week 2 Call' },
    { value: InstrumentType.Week2Put, label: 'Week 2 Put' },
    { value: InstrumentType.Week3Call, label: 'Week 3 Call' },
    { value: InstrumentType.Week3Put, label: 'Week 3 Put' },
    { value: InstrumentType.Week4Call, label: 'Week 4 Call' },
    { value: InstrumentType.Week4Put, label: 'Week 4 Put' },
    { value: InstrumentType.Week5Call, label: 'Week 5 Call' },
    { value: InstrumentType.Week5Put, label: 'Week 5 Put' },
    { value: InstrumentType.SerialCall, label: 'Serial Call' },
    { value: InstrumentType.SerialPut, label: 'Serial Put' },
    { value: InstrumentType.NewCropWeek1Call, label: 'New Crop Week 1 Call' },
    { value: InstrumentType.NewCropWeek1Put, label: 'New Crop Week 1 Put' },
    { value: InstrumentType.NewCropWeek2Call, label: 'New Crop Week 2 Call' },
    { value: InstrumentType.NewCropWeek2Put, label: 'New Crop Week 2 Put' },
    { value: InstrumentType.NewCropWeek3Call, label: 'New Crop Week 3 Call' },
    { value: InstrumentType.NewCropWeek3Put, label: 'New Crop Week 3 Put' },
    { value: InstrumentType.NewCropWeek4Call, label: 'New Crop Week 4 Call' },
    { value: InstrumentType.NewCropWeek4Put, label: 'New Crop Week 4 Put' },
    { value: InstrumentType.NewCropWeek5Call, label: 'New Crop Week 5 Call' },
    { value: InstrumentType.NewCropWeek5Put, label: 'New Crop Week 5 Put' },
  ]);

export function getInstrumentTypeSelectors(commodity: CommodityInformation) {
  if (commodity.hasSpecialOptions) {
    return withNewCropTradeableInstrumentTypeSelections;
  }

  return defaultTradeableInstrumentTypeSelections;
}

export const buySellTypes = [
  { value: BuySellType.Buy, label: 'Buy' },
  { value: BuySellType.Sell, label: 'Sell' },
];
