import * as React from 'react';
import DialogContent from '../common-next/Dialog/DialogContent';
import TradeActivityRow from './TradeActivityRow';
import { orderBy, uniqBy } from 'lodash';
import Button from '../common-next/Button/Button';
import { FontAwesomeIcon, faCircleArrowRight } from '../faIcons';
import { IdleTimerProvider } from 'react-idle-timer';
import TimeoutDisplay from '../common-next/TimeoutDisplay/TimeoutDisplay';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { OrderStatus_Status } from '@farmersrisk/cqg/cqgMessages/common/shared_1';
import { useOrderHistoryQuery } from './queries';
import Loader from '../common/Loader/Loader';
import { CQGContext } from '../CQG/CQGProvider';
import { CQGService } from '../CQG/service';

const MINUTES_TO_TIMEOUT = 10;
dayjs.extend(utc);

interface TradeActivityModalContentProps {
  toggleModals: () => void;
  onClose: () => void;
  cqgContext: CQGContext;
  cqgService: CQGService;
}

export default function TradeActivityModalContent(props: TradeActivityModalContentProps) {
  const { toggleModals, cqgContext, cqgService } = props;
  const isPhone = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const orderHistoryQry = useOrderHistoryQuery(cqgService, cqgContext, { enabled: cqgContext?.isReady });

  const liveOrders = cqgContext?.state.orders;

  if (!cqgContext || !cqgContext?.isReady) {
    return (
      <div className="container" style={{ minHeight: 300 }}>
        <div className="d-flex flex-row align-content-center" style={{ height: 300 }}>
          <Loader />
        </div>
      </div>
    );
  }

  if (!orderHistoryQry.data) {
    return (
      <div className="container" style={{ minHeight: 300 }}>
        <div className="d-flex flex-row align-content-center" style={{ height: 300 }}>
          <Loader />
        </div>
      </div>
    );
  }

  const historicalOrders = orderHistoryQry.data.orders;
  const isError = orderHistoryQry.isError;
  const contractMetadata = cqgContext.state.contracts;

  // merge live orders with historical orders
  const orderStatus = { ...historicalOrders, ...liveOrders };

  function showTradingModal() {
    toggleModals();
  }

  const sortedOrderStatus = orderBy(
    orderStatus,
    [
      (os) => {
        switch (os.status) {
          // open-ish
          case OrderStatus_Status.WORKING:
          case OrderStatus_Status.IN_TRANSIT:
          case OrderStatus_Status.IN_CANCEL:
          case OrderStatus_Status.IN_MODIFY:
          case OrderStatus_Status.ACTIVEAT:
          case OrderStatus_Status.APPROVE_REQUIRED:
            return 1;
          case OrderStatus_Status.FILLED:
          case OrderStatus_Status.APPROVED_BY_EXCHANGE:
          case OrderStatus_Status.MATCHED:
          case OrderStatus_Status.PARTIALLY_MATCHED:
          case OrderStatus_Status.REJECTED:
          case OrderStatus_Status.EXPIRED:
          case OrderStatus_Status.CANCELLED:
          case OrderStatus_Status.SUSPENDED:
          case OrderStatus_Status.DISCONNECTED:
          case OrderStatus_Status.APPROVE_REJECTED:
          case OrderStatus_Status.TRADE_BROKEN:
          case OrderStatus_Status.UNRECOGNIZED:
          default:
            return 2;
        }
      },
      (os) => {
        return dayjs.utc(os.submissionUtcTimestamp).unix();
      },
    ],
    ['asc', 'desc']
  );
  const uniqueSortedOrderStatus = uniqBy(sortedOrderStatus, 'orderId');
  const hasOrders = uniqueSortedOrderStatus.length > 0;

  return (
    <IdleTimerProvider timeout={1000 * 60 * MINUTES_TO_TIMEOUT} events={[]} onIdle={props.onClose}>
      <DialogContent className={`dialog-content${isPhone ? '-mobile' : ''}`}>
        <div className="overflow-auto row" style={{ height: 'auto', minHeight: 200 }}>
          {isError && <p className="text-center mt-5">There was an error fetching orders.</p>}
          {!isError && !hasOrders && <p className="text-center mt-5">No recent orders found</p>}
          {!isError &&
            hasOrders &&
            uniqueSortedOrderStatus.map((status) => (
              <TradeActivityRow key={status.orderId} orderStatus={status} contractMetadataMap={contractMetadata} />
            ))}
        </div>

        <hr />

        {isPhone && (
          <div className="d-flex justify-content-center mt-2 mb-2">
            <Button
              themeColor="secondary"
              className="fw-bold p-3"
              size="x-small"
              onClick={() => {
                showTradingModal();
              }}
            >
              <b>Place New Order</b> <FontAwesomeIcon className="pr-2" icon={faCircleArrowRight} style={{ color: '#1162ab' }} />
            </Button>
          </div>
        )}
        {!isPhone && (
          <div className="row text-end">
            <div className="col-12 text-end fst-italic align-middle">
              <Button
                themeColor="secondary"
                className="fw-bold p-3"
                size="medium"
                onClick={() => {
                  showTradingModal();
                }}
              >
                <b>Place New Order</b> <FontAwesomeIcon className="pr-2" icon={faCircleArrowRight} style={{ color: '#1162ab' }} />
              </Button>
            </div>
          </div>
        )}
        <TimeoutDisplay onClose={props.onClose} message={'This modal will close in'} />
      </DialogContent>
    </IdleTimerProvider>
  );
}
