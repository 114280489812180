import styles from './DialogActionsBar.module.scss';

import * as React from 'react';
import { DialogActionsBar as KDialogActionsBar } from '@progress/kendo-react-dialogs';
import { DialogActionsBarProps as KDialogActionsBarProps } from '@progress/kendo-react-dialogs/dist/npm/DialogActionsBar';

interface DialogActionsBarProps extends KDialogActionsBarProps {
  className?: string;
  position?: 'top' | 'bottom';
}

function DialogActionsBar(props: React.PropsWithChildren<DialogActionsBarProps>, ref: React.Ref<HTMLDivElement>) {
  const { position = 'bottom' } = props;
  return (
    <div ref={ref} className={`${styles['fr-dialog-actions-bar']} fr-dialog-actions-${position} ${props.className}`}>
      <KDialogActionsBar {...props}>{props.children}</KDialogActionsBar>
    </div>
  );
}

export default React.forwardRef(DialogActionsBar);
