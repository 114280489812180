import React from 'react';
import ArcGaugeWithTitle, { ArcGaugeColors } from '../../../components/common-next/ArcGaugeWithTitle/ArcGaugeWithTitle';
import { LotSummary } from '@farmersrisk/shared/features/lots/lotSummaries';
import { formatNumber } from '../../../utils/formatNumber';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';

import { KpiBar, KpiBarType } from '../../../components/common-next/KpiBar/KpiBar';
import LivestockLotPnL from './Livestock/LivestockLotPnL';

import styles from '../Dashboard.module.scss';

export const getLotPnLSnippet = (headcount: number, perCwt: number): string => {
  const currency = useCurrentCurrency();
  return `${headcount} hd @ avg ${formatNumber(perCwt, 2, currency)}/cwt `;
};

const LivestockLotSummaryCard = ({ ...lot }: LotSummary) => {
  const currency = useCurrentCurrency();
  const soldGaugeData = {
    title: 'Sold',
    centerTitle: formatNumber(lot.soldRevenue, 2, currency),
    bottomTitle: lot.soldHeadcount ? getLotPnLSnippet(lot.soldHeadcount, lot.soldRevenuePerCwt.toNumber()) : '',
    ...(lot.soldHeadcount && {
      kpi: {
        label: 'P/L',
        value: `${formatNumber(lot.soldProfitPerCwt, 2, currency)} cwt`,
        color: ArcGaugeColors.GREEN,
      },
    }),
    value: lot.soldPercentage.toNumber(),
    color: ArcGaugeColors.GREEN,
  };

  const unsoldGaugeData = {
    title: 'Unsold',
    centerTitle: formatNumber(lot.unsoldProjectedRevenue, 2, currency),
    bottomTitle: getLotPnLSnippet(lot.unsoldHeadcount, lot.unsoldProjectedRevenuePerCwt.toNumber()),
    value: lot.unsoldPercentage.toNumber(),
    color: ArcGaugeColors.RED,
  };

  const lotKpiFields: KpiBarType[] = [
    { title: 'Breakeven Price', value: `${formatNumber(lot.breakevenPricePerCwt, 2, currency)}/cwt` },
    { title: 'Cost of Gain', value: `${formatNumber(lot.costOfGainPerLb, 2, currency)}/lb` },
    { title: 'Days on Feed', value: `${lot.daysOnFeed}` },
    { title: 'Profit', value: `${formatNumber(lot.profitPerCwt.dividedBy(100), 2, currency)}/lb` },
    { title: 'ROI', value: `${formatNumber(lot.projectedRoi.times(100), 2)}%` },
  ];

  return (
    <div className={`${styles['dashboard-group']} d-flex gap-3 card justify-content-between mt-3`}>
      <LivestockLotPnL {...lot} />

      <div className="container-fluid text-start p-0">
        <div className="row mb-3">
          <div className="col-sm">
            <div className="d-flex gap-2 justify-content-between">
              <KpiBar data={lotKpiFields} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <ArcGaugeWithTitle {...soldGaugeData} />
          </div>
          <div className="col-sm">
            <ArcGaugeWithTitle {...unsoldGaugeData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivestockLotSummaryCard;
