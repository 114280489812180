export interface Currency {
  id: number;
  symbol: string;
  name: string;
}

export const USD: Currency = { id: 1, symbol: '$', name: 'USD' };
export const CAD: Currency = { id: 2, symbol: '$', name: 'CAD' };

export const CURRENCIES: Currency[] = [CAD, USD];

export function getCurrencies() {
  return CURRENCIES;
}
