type RGB = [number, number, number];

/**
 *
 * @param {string} hex - The color in hex format including the leading hash. e.g., #000000
 * @returns {number} - The luminance of the color
 */
export function luminance(hex: string) {
  const rgb = getRgbColorFromHex(hex);
  const [r, g, b] = rgb.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

function getRgbColorFromHex(hex: string) {
  hex = hex.slice(1).toLowerCase();
  const value = parseInt(hex, 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return [r, g, b] as RGB;
}
