import { IStrategy } from '../../types/IStrategy';
import { forEach, groupBy, sum } from 'lodash';
import { FuturesContractType, PositionType, StrategyStatus, IPosition, OptionContractType } from '../../types/IPosition';
import { CommodityInformation, InstrumentType } from '@harvestiq/constants';
import { shortMonthNames } from '../../utils/dateHelpers';
import { formatNumber } from '../../utils/formatNumber';
import { PriceByTradingCode } from './myPositions';
import { isOneLeg } from '../protectedPosition';
import React from 'react';
import { isFutures, isOption } from '../strategiesTypes';
import SummaryLegsExpander from '../../components/common/StrategiesCard/SummaryLegsExpander';
import { getTotalBushels } from './summary';
import { getContractTradingCode } from '../CropSymbols/cropSymbols';
import dayjs from 'dayjs';
import { IFormattedPosition } from '../formattedPosition';
import { BuySellType } from '@farmersrisk/shared/enums';
import { IPositionWithQuoteData } from '../../types/IPositionWithQuoteData';
import { IStrategyWithQuoteData } from '../../types/IStrategyWithQuoteData';
import Decimal from 'decimal.js';

const fieldsToCalculateTotal = ['quantitySold', 'gainLoss', 'netRevenue'];
const fieldsToCalculateWeightedAverage = ['closingPrice', 'strikePrice', 'futuresPrice'];

export function formatLegType(leg: IPosition): string {
  const nameByType: { [key: number]: { [key: number]: string } } = {
    [PositionType.Option]: {
      [OptionContractType.BuyPut]: 'Buy Put',
      [OptionContractType.BuyCall]: 'Buy Call',
      [OptionContractType.SellPut]: 'Sell Put',
      [OptionContractType.SellCall]: 'Sell Call',
    },
    [PositionType.Futures]: {
      [FuturesContractType.Buy]: 'Buy Futures',
      [FuturesContractType.Sell]: 'Sell Futures',
    },
  };

  const contractType = leg.contractType as OptionContractType | FuturesContractType;
  return nameByType[leg.type][contractType];
}

export function getReferenceContractFromLeg(leg: IPosition) {
  return leg.futuresMonth !== undefined ? `${shortMonthNames[leg.futuresMonth]} ${leg.futuresYear}` : '';
}

// TODO: use local currency
const currency = { id: 1, symbol: '$', name: 'USD' };

const getOriginalPrice = (position: IPosition) => {
  if (!position.futuresPrice) {
    const totalCost = !!position.premiumCost && !!position.quantitySold ? position.premiumCost * position.quantitySold : '- -';
    return typeof totalCost === 'number'
      ? `${formatNumber(position.premiumCost, 2, currency)} (${formatNumber(totalCost, 0, currency)})`
      : '- -';
  } else if (position.futuresPrice) {
    return formatNumber(position.futuresPrice, 4, currency);
  } else {
    return '- -';
  }
};

export function getGainLoss(position: IPosition, marketPrice?: number) {
  let gainLoss;
  if (position.status === StrategyStatus.Closed || marketPrice === undefined) {
    if (!position.closingPrice && position.closingPrice !== 0) {
      gainLoss = 0;
    } else {
      gainLoss =
        position.buySellCode === BuySellType.Buy
          ? (position.closingPrice - Number(position.originalCost)) * position.quantitySold!
          : (Number(position.originalCost) - position.closingPrice) * position.quantitySold!;
    }
  } else {
    gainLoss =
      position.buySellCode === BuySellType.Buy
        ? (marketPrice - Number(position.originalCost)) * position.quantitySold!
        : (Number(position.originalCost) - marketPrice) * position.quantitySold!;
  }
  return gainLoss;
}

const getFormattedGainLoss = (position: IPosition, gainLoss: number) => {
  if (!position.quantitySold || position.status !== StrategyStatus.Open) {
    return '- -';
  }
  const operator = gainLoss < 0 ? '' : '+';
  const gainLossPerBushel = gainLoss / position.quantitySold;
  return `${operator}${formatNumber(gainLossPerBushel, 2, currency)} (${formatNumber(gainLoss, 0, currency)})`;
};

const getFormattedCurrentPrice = (position: IPosition, marketPrice: number | undefined) => {
  if (!position.quantitySold || marketPrice === undefined || position.status !== StrategyStatus.Open) {
    return '- -';
  }
  if (position.futuresPrice) {
    return formatNumber(marketPrice, 4, currency);
  }
  const totalPremium = marketPrice * position.quantitySold;
  return `${formatNumber(marketPrice, 2, currency)} (${formatNumber(totalPremium, 0, currency)})`;
};

const getFormattedType = (position: IPosition) => {
  if (!position.instrumentType) {
    return '- -';
  }
  const rawType = InstrumentType[position.instrumentType];
  const splitType = rawType.match(/[A-Z][a-z]+|[0-9]+/g);
  const formattedType = splitType !== null ? splitType.join(' ').toLocaleUpperCase() : '- -';
  return formattedType;
};

const getFormattedHedgedBushels = (position: IPosition, hedgedBushels: Decimal | null) => {
  if (hedgedBushels === null) {
    return '- -';
  }
  const hedgedBushelsWithSignReversed = hedgedBushels.times(-1);
  const percentHedged = hedgedBushelsWithSignReversed.dividedBy(position.quantitySold!).times(100).toDecimalPlaces(1);
  return `${hedgedBushelsWithSignReversed.toDecimalPlaces(0).toString()} (${percentHedged.toString()}%)`;
};

const getFormattedCurrentDelta = (position: IPosition, originalDelta?: number, currentDelta?: number) => {
  if (currentDelta === undefined || currentDelta === null) {
    return '- -';
  }

  const currentDeltaDecimal = new Decimal(currentDelta);

  if (position.type === PositionType.Futures || originalDelta === undefined || originalDelta === null) {
    return `${currentDeltaDecimal}`;
  }
  const originalDeltaDecimal = new Decimal(originalDelta);

  const deltaDelta = currentDeltaDecimal.minus(originalDeltaDecimal);

  return `${currentDeltaDecimal} ${deltaDelta}`;
};

const getFormattedDeltaHedgeBushels = (position: IPosition, originalDelta?: number, currentDelta?: number) => {
  if (originalDelta === undefined || originalDelta === null) {
    return '- -';
  }
  if (currentDelta === undefined || currentDelta === null) {
    return '- -';
  }
  if (position.quantitySold === undefined) {
    return '- -';
  }

  const currentDeltaDecimal = new Decimal(currentDelta);
  const originalDeltaDecimal = new Decimal(originalDelta);

  if (currentDeltaDecimal.equals(originalDeltaDecimal)) {
    return `${currentDeltaDecimal}`;
  }

  const quantitySoldDecimal = new Decimal(position.quantitySold);
  const deltaDelta = currentDeltaDecimal.minus(originalDeltaDecimal);

  const deltaHedgeBushels = quantitySoldDecimal.times(deltaDelta).times(-1);
  const deltaHedgeBushelsPercent = deltaHedgeBushels.dividedBy(quantitySoldDecimal).times(100).toDecimalPlaces(1);

  return `${deltaHedgeBushels} ${deltaHedgeBushelsPercent}`;
};

export function prepareLeg(
  contract: IStrategyWithQuoteData,
  leg: IPositionWithQuoteData,
  marketPrices: PriceByTradingCode
): IFormattedPosition {
  const marketPrice = leg.currentPrice;

  return {
    ...leg,
    title: contract.legs.length > 1 && !contract.isMerged ? 'Leg ' + (leg.id + 1) : `#${leg.contractId}`,
    orderNumber: leg.orderNumber,
    contractTypeFormatted: formatLegType(leg),
    statusFormatted: (leg.status && StrategyStatus[Number(leg.status)]) || '',
    contract: getReferenceContractFromLeg(leg),
    closingPrice: leg.closingPrice || marketPrice,
    premiumCost: leg.type === PositionType.Option ? leg.premiumCost : undefined,
    breakEvenStrike: leg.type === PositionType.Option ? Number(leg.strikePrice || 0) - Number(leg.premiumCost || 0) : undefined,
    traceId: leg.traceId,

    short: leg.buySellCode === BuySellType.Sell ? leg.quantitySold : 0,
    long: leg.buySellCode === BuySellType.Buy ? leg.quantitySold : 0,
    formattedTradeDate: leg.booked ? dayjs(leg.booked).format('MMM D YYYY') : '- -',
    formattedExpirationDate: leg.expirationDate ? dayjs(leg.expirationDate).format('MMM D YYYY') : '- -',
    formattedStrikePrice: leg.strikePrice ? formatNumber(leg.strikePrice, 2) : '- -',
    formattedOriginalPrice: getOriginalPrice(leg),
    formattedGainLoss: getFormattedGainLoss(leg, leg.gainLoss || 0),
    formattedType: getFormattedType(leg),
    formattedCurrentPrice: getFormattedCurrentPrice(leg, marketPrice),
    formattedHedgedBushels: getFormattedHedgedBushels(leg, leg.hedgedBushels || null),
    formattedCurrentDelta: getFormattedCurrentDelta(leg, leg.originalDelta, leg.currentDelta),
    formattedDeltaHedgeBushels: getFormattedDeltaHedgeBushels(leg, leg.originalDelta, leg.currentDelta),
  };
}

function getTotalByField<T extends object, Prop extends keyof T>(items: T[], fieldName: Prop) {
  return sum(items.map((item) => Number(item[fieldName] || 0)));
}

export function getWeightedAverageByField<T extends object, Prop extends keyof T>(
  items: T[],
  fieldName: Prop,
  weightedAverageField: Prop = 'quantitySold' as Prop
): number {
  const total = sum(items.map((item) => (Number(item[fieldName]) ? Number(item[weightedAverageField] || 0) : 0)));
  return sum(
    items.map((item) => {
      if (Number(item[fieldName])) {
        return (Number(item[fieldName] || 0) * Number(item[weightedAverageField] || 0)) / total;
      }
      return 0;
    })
  );
}

export function getTotalFromLegs(legs: IPosition[], fieldsToCalculateTotal: string[], includeAll = false) {
  const totalFromLegs: { [key: string]: number } = {};
  fieldsToCalculateTotal.forEach(
    (field: string) =>
      (totalFromLegs[field] = field === 'quantitySold' && !includeAll ? getTotalBushels(legs) : getTotalByField(legs, field))
  );
  return totalFromLegs;
}

export function getWeightedAverageFromLegs(legs: IPositionWithQuoteData[], fields: string[]) {
  const weightedAverageFromLegs: { [key: string]: number } = {};
  fields.forEach((field: string) => (weightedAverageFromLegs[field] = getWeightedAverageByField(legs, field)));
  return weightedAverageFromLegs;
}

function groupContractsByFutures(strategies: IStrategyWithQuoteData[], selectedCrop: CommodityInformation) {
  const singleLegContracts = strategies.filter((strategy) => isOneLeg(strategy.legs));
  const multiLegContracts = strategies.filter((strategy) => !singleLegContracts.includes(strategy));
  const strategiesByTypeAndFutures = groupBy(singleLegContracts, (strategy) => {
    const leg = strategy.legs[0];
    return leg.type + '-' + getContractTradingCode(selectedCrop, leg.futuresYear, leg.futuresMonth);
  });

  const mergedContracts: IStrategyWithQuoteData[] = [];
  forEach(strategiesByTypeAndFutures, (strategies) => {
    const strategy: IStrategyWithQuoteData = {
      ...strategies[0],
      legs: strategies.flatMap((strategy) =>
        strategy.legs.map((leg) => ({
          ...leg,
          contractId: strategy.id,
          isScenario: strategy.isScenario,
          isEnabled: strategy.isEnabled,
          isSynchronized: strategy.isSynchronized,
          synchronizationType: strategy.synchronizationType,
          accountNumber: strategy.accountNumber,
          notes: strategy.notes,
        }))
      ),
      isMerged: strategies.length > 1,
      isSynchronized: false,
    };
    mergedContracts.push(strategy);
  });
  return [...mergedContracts, ...multiLegContracts];
}

export interface IStrategyDetailsTotalRow extends IStrategy {
  id: number;
  title: React.ReactNode;
  titleComponent: (props: { isExpanded: boolean }) => React.ReactNode;
  legs: IPositionWithQuoteData[];
  isMerged: boolean;
  contract: string;

  [key: string]: any;
}

function prepareContractLegs(strategy: IStrategyWithQuoteData, spotPrices: PriceByTradingCode): IFormattedPosition[] {
  return strategy.legs.map((leg) => {
    const preparedLeg = prepareLeg(strategy, leg, spotPrices);
    const legsCount = strategy.legs.length;
    const isSingleUnmergedLeg = legsCount > 1 || !strategy.isMerged;
    return {
      ...preparedLeg,
      title: isSingleUnmergedLeg ? preparedLeg.title : undefined,
    };
  });
}

function cleanFields(result: IStrategyDetailsTotalRow, legs: IPosition[]) {
  if (!result.isMerged) {
    delete result['closingPrice'];
    delete result['strikePrice'];
    delete result['futuresPrice'];
  } else {
    if (isFutures(legs[0])) {
      delete result['strikePrice'];
    }
    if (isOption(legs[0])) {
      delete result['futuresPrice'];
    }
  }

  return result;
}

function prepareContractTotal(
  strategy: IStrategyWithQuoteData,
  spotPrices: PriceByTradingCode,
  groupByFutures: boolean,
  mergedId: number
) {
  const legs = prepareContractLegs(strategy, spotPrices);
  const totalFromLegs = getTotalFromLegs(legs, fieldsToCalculateTotal, strategy.isMerged);
  const weightedAverageFromLegs = getWeightedAverageFromLegs(legs, fieldsToCalculateWeightedAverage);

  let result: IStrategyDetailsTotalRow = {
    ...strategy,
    contractId: groupByFutures ? ++mergedId : strategy.id,
    titleComponent: SummaryLegsExpander,
    title: SummaryLegsExpander({ isExpanded: true }),
    ...totalFromLegs,
    ...weightedAverageFromLegs,
    legs,
    isMerged: strategy.isMerged,
    contract: strategy.isMerged ? getReferenceContractFromLeg(legs[0]) : '',
    source: undefined,
  };

  result = cleanFields(result, legs);

  return result;
}

export function prepareStrategiesForTable(
  strategies: IStrategyWithQuoteData[],
  selectedCrop: CommodityInformation,
  spotPrices: PriceByTradingCode,
  groupByFutures = false
): IStrategyDetailsTotalRow[] {
  const contracts = groupByFutures ? groupContractsByFutures(strategies, selectedCrop) : strategies;

  const mergedId = Date.now();
  return contracts.map((strategy) => prepareContractTotal(strategy, spotPrices, groupByFutures, mergedId));
}

export function filterContractsByStatus(
  strategies: IStrategyWithQuoteData[],
  contractsType: number | null,
  isFilteringEnabled: boolean,
  statuses: StrategyStatus[]
): IStrategyWithQuoteData[] {
  const result: IStrategyWithQuoteData[] = [];

  if (!isFilteringEnabled) {
    return strategies;
  }

  strategies.forEach((contract: IStrategyWithQuoteData) => {
    // combined strategy with at least one open leg and the tab status is closed/expired -> don't show in closed/expired tab
    if (
      contractsType === 3 &&
      statuses.some((status) => status === 2 || status === 3) &&
      contract.legs.some((leg) => leg.status === 1)
    ) {
      return result;
    } else if (!contract.isMerged && contract.legs.some((leg) => leg.status && statuses.includes(leg.status))) {
      result.push(contract);
    } else if (contract.isMerged) {
      const legs = contract.legs.filter(
        (l: IPositionWithQuoteData) => !isFilteringEnabled || (l.status && statuses.includes(l.status))
      );
      const mergedContractWithFilteredLegs = {
        ...contract,
        legs,
        isEnabled: legs.every((leg) => leg.isEnabled),
      };

      if (mergedContractWithFilteredLegs.legs.length) {
        result.push(mergedContractWithFilteredLegs);
      }
    }
  });

  return result;
}

export const formatFuturesMonth = (date: string | Date | undefined) =>
  date ? dayjs(date).add(1, 'month').format('MMM YYYY') : null;
