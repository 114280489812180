import { IOperationCropV2 } from '../../types/IOperationCrops';
import { ICropContract } from '../../types/ICropContract';
import { calculateStrategySummaryData, IStrategySummaryData } from '../MyPositions/summary';
import { IStrategy } from '../../types/IStrategy';
import { PriceByTradingCode } from '../MyPositions/myPositions';
import { QuotesStoreItem } from '../../store/Quotes/quotesSlice';
import { IQuote } from '../../types/IQuote';
import Decimal from 'decimal.js';
import { sum } from 'lodash';
import { CommodityInformation, CommodityId, InsuranceType } from '@harvestiq/constants';
import { DeliveryStatus } from '@farmersrisk/shared/constants/CropContracts';
import { OperationCropSummary } from '../../queries/operationCropSummaries';

const zero = new Decimal(0);

type RiskAssessmentData = {
  marketed: null | {
    bushels: number;
    revenue: number;
    productionCost: number;
    profit: {
      total: number;
      perAcre: number;
    };
    price: {
      perBushel: number;
    };
  };
  protected: null | {
    bushels: number;
    revenue: number;
    productionCost: number;
    profit: {
      total: number;
      perAcre: number;
    };
    price: {
      perBushel: number;
    };
  };
  unprotected: null | {
    bushels: number;
    revenue: number;
    productionCost: number;
    profit: {
      total: number;
      perAcre: number;
    };
    price: {
      perBushel: number;
    };
  };
};

export function calculateAcresFromYield(relevantYield: number, targetBushels: number) {
  if (relevantYield === 0) {
    return targetBushels;
  }
  return new Decimal(targetBushels).dividedBy(relevantYield).toNumber();
}

export function calculatePotentialRevenue(bushelsContracted: number, sellingPrice: number): number {
  return bushelsContracted * sellingPrice;
}

export function calculateTotalProductionCost(productionCostsPerAcre: number, acres: number) {
  return productionCostsPerAcre * acres;
}

export function getTotalProfit(revenue: number, productionCost: number, contractBuyBack?: number) {
  let profit = revenue - productionCost;
  if (contractBuyBack) {
    profit = profit - contractBuyBack;
  }

  return profit;
}

export function getTotalProfitPerAcre(totalProfit: number, acres: number) {
  if (!acres) {
    return 0;
  }
  return totalProfit / acres;
}

export function calculateRiskAssessmentData(
  operationCrop: IOperationCropV2,
  cropBudget: number,
  spotPrice: number,
  cropContracts: ICropContract[] = [],
  strategiesSummaryData: IStrategySummaryData
): RiskAssessmentData {
  const relevantYield = Math.max(0, operationCrop.relevantYieldUOMPerAcre.toNumber());
  const totalAcres = operationCrop.acres?.toNumber() || 0;
  const totalExpectedYield = relevantYield * totalAcres;
  const price = operationCrop.price;
  let bushelsContracted = 0;
  let sellingPrice = 0;

  const estimatedBasis = Number(price?.estimatedBasis || 0);

  let marketed = null;
  const matchingCropContracts = cropContracts.filter((cc) => cc.operationCropId === operationCrop.id);
  const validCropContracts = filterMarketedCropContracts(matchingCropContracts);
  const marketedStrategiesSummary = strategiesSummaryData.marketedForRiskAssessment;

  if (validCropContracts.length === 0) {
    bushelsContracted = Number(price?.bushelsContracted || 0);
    sellingPrice = Number(price?.sellingPrice || 0);
    if (bushelsContracted > 0) {
      const acresToGrowMarketed = Math.min(totalAcres, calculateAcresFromYield(relevantYield, bushelsContracted));
      const revenue = calculatePotentialRevenue(bushelsContracted, sellingPrice) + marketedStrategiesSummary.estimatedValue;
      const productionCostPerAcre = calculateTotalProductionCost(cropBudget, acresToGrowMarketed);
      const marketedTotalProfit = getTotalProfit(revenue, productionCostPerAcre);
      const marketedProfitPerAcre = getTotalProfitPerAcre(marketedTotalProfit, acresToGrowMarketed);

      marketed = {
        bushels: bushelsContracted,
        revenue,
        productionCost: productionCostPerAcre,
        profit: {
          total: marketedTotalProfit,
          perAcre: marketedProfitPerAcre,
        },
        price: {
          perBushel: sellingPrice,
        },
      };
    }
  } else {
    const cropContractsSummary = calculateCropSummaryData(operationCrop, validCropContracts, spotPrice);
    const { netPrice, totalBushels } = cropContractsSummary?.contracted || {};
    bushelsContracted = totalBushels || 0;
    sellingPrice = netPrice || 0;

    const acresToGrowMarketed = Math.min(totalAcres, calculateAcresFromYield(relevantYield, bushelsContracted));
    let contractBuyBack = 0;
    if (totalExpectedYield < bushelsContracted) {
      contractBuyBack = (bushelsContracted - totalExpectedYield) * sellingPrice;
    }
    const revenue = calculatePotentialRevenue(bushelsContracted, sellingPrice) + marketedStrategiesSummary.estimatedValue;
    const productionCostPerAcre = calculateTotalProductionCost(cropBudget, acresToGrowMarketed);
    const marketedTotalProfit = getTotalProfit(revenue, productionCostPerAcre, contractBuyBack);
    const marketedProfitPerAcre = getTotalProfitPerAcre(marketedTotalProfit, acresToGrowMarketed);

    marketed = {
      bushels: bushelsContracted,
      revenue,
      productionCost: productionCostPerAcre,
      profit: {
        total: marketedTotalProfit,
        perAcre: marketedProfitPerAcre,
      },
      price: {
        perBushel: sellingPrice,
      },
    };
  }

  let protectedData = null;
  const protectedSummary = strategiesSummaryData.protectedForRiskAssessment;
  if (protectedSummary.totalBushels > 0) {
    const acresToGrowProtected = Math.min(totalAcres, calculateAcresFromYield(relevantYield, protectedSummary.totalBushels));
    const revenue = calculatePotentialRevenue(protectedSummary.totalBushels, protectedSummary.netGainLoss);
    const productionCostPerAcre = calculateTotalProductionCost(cropBudget, acresToGrowProtected);
    const protectedTotalProfit = getTotalProfit(revenue, productionCostPerAcre);
    const protectedProfitPerAcre = getTotalProfitPerAcre(protectedTotalProfit, acresToGrowProtected);

    protectedData = {
      bushels: protectedSummary.totalBushels,
      revenue,
      productionCost: productionCostPerAcre,
      profit: {
        total: protectedTotalProfit,
        perAcre: protectedProfitPerAcre,
      },
      price: {
        perBushel: protectedSummary.netGainLoss,
      },
    };
  }

  let unprotected = null;

  const unprotectedYield = Math.max(0, totalExpectedYield - bushelsContracted - protectedSummary.totalBushels);
  if (unprotectedYield > 0 || strategiesSummaryData.speculation?.estimatedValue) {
    const cropSpotPrice = spotPrice + estimatedBasis;
    const acresToGrowUnprotected = unprotectedYield ? calculateAcresFromYield(relevantYield, unprotectedYield) : 0;
    const revenue = calculatePotentialRevenue(unprotectedYield, cropSpotPrice) + strategiesSummaryData.speculation.estimatedValue;
    const totalProductionCost = calculateTotalProductionCost(cropBudget, acresToGrowUnprotected);
    const unprotectedTotalProfit = getTotalProfit(revenue, totalProductionCost);
    const unprotectedTotalProfitPerAcre = getTotalProfitPerAcre(unprotectedTotalProfit, acresToGrowUnprotected);
    unprotected = {
      // We don't add the speculative bushels here
      bushels: unprotectedYield,
      revenue,
      productionCost: totalProductionCost,
      profit: {
        total: unprotectedTotalProfit,
        perAcre: unprotectedTotalProfitPerAcre,
      },
      price: {
        perBushel: unprotectedYield ? revenue / unprotectedYield : cropSpotPrice,
      },
    };
  }

  return { marketed, protected: protectedData, unprotected };
}

export type MatrixRanges = {
  priceRange: {
    firstValue: number;
    length: number;
  };
  yieldRange: {
    firstValue: number;
    length: number;
  };
};

export const defaultMatrixLength = 15;
export const defaultYieldStep = 5;

export function getPriceStepByCrop(cropId: number) {
  const defaultValue = 0.2;
  return (
    [
      {
        cropId: CommodityId.CORN,
        step: 0.2,
      },
      {
        cropId: CommodityId.SOYBEANS,
        step: 0.4,
      },
      {
        cropId: CommodityId.KC_WINTER_WHEAT_HRW,
        step: 0.2,
      },
      {
        cropId: CommodityId.MN_SPRING_WHEAT_HRSW,
        step: 0.2,
      },
    ].find((item) => item.cropId === cropId)?.step || defaultValue
  );
}

export function getRange(firstValue: number, step: number, length: number) {
  const result: number[] = [];

  let value: number = firstValue;
  for (let i = 0; i < length; i++) {
    result.push(parseFloat(value.toFixed(5)));
    value = value + step;
  }

  return result;
}

export function getMatrixPriceRange(firstValue: number, cropId: number, columnAmount = 15) {
  const priceStep: number = getPriceStepByCrop(cropId);
  return getRange(firstValue, priceStep, columnAmount);
}

export function getMatrixYieldRange(firstValue: number, yieldStep = 5, rowAmount = 15) {
  return getRange(firstValue, yieldStep, rowAmount).reverse();
}

export function calculateInsurance(operationCrop: IOperationCropV2, actualYield: number, actualPrice?: number | null): number {
  const coverageAmount = (operationCrop.aphUOMPerAcre ?? zero).times((operationCrop.insurancePercent ?? zero).dividedBy(100));
  const springInsurancePrice = new Decimal(operationCrop.price?.springInsurancePrice || 0);
  const autumnInsurancePrice = new Decimal(operationCrop.price?.autumnInsurancePrice || 0);

  // https://www.extension.iastate.edu/agdm/crops/html/a1-54.html
  // for REVENUE and REVENUE-HPE we have to calculate the fall/harvest revenue
  // if we already have the fall price, then just use that, otherwise, use a passed in value
  // (the passed in value is likely to support scenario matrix)
  // finally, if we don't have any of those prices, then just use the spring price as a fallback
  let insuranceIndemnityPayment = zero;
  const validatedActualYield = Math.max(0, actualYield);
  const harvestPrice = new Decimal(autumnInsurancePrice.toNumber() || actualPrice || springInsurancePrice.toNumber());

  switch (operationCrop.insuranceType) {
    case InsuranceType.YIELD:
      insuranceIndemnityPayment = coverageAmount.minus(validatedActualYield).times(springInsurancePrice);
      break;
    case InsuranceType.REVENUE: {
      const higherInsurancePrice = Math.max(
        springInsurancePrice.toNumber(),
        autumnInsurancePrice.toNumber() || actualPrice || 0 // if we don't have a fall insurance price yet, use the actual
      );
      const totalCoverageAmount = coverageAmount.times(higherInsurancePrice);
      const actualRevenue = harvestPrice.times(validatedActualYield);
      if (harvestPrice.greaterThan(0)) {
        insuranceIndemnityPayment = totalCoverageAmount.minus(actualRevenue);
      }
      break;
    }
    case InsuranceType.REVENUE_HPE: {
      const coverageAmountPrice = coverageAmount.times(springInsurancePrice);
      const actualRevenue = springInsurancePrice.times(validatedActualYield);
      insuranceIndemnityPayment = coverageAmountPrice.minus(actualRevenue);
      break;
    }

    default:
      insuranceIndemnityPayment = zero;
  }

  const retVal = insuranceIndemnityPayment.greaterThanOrEqualTo(0) ? insuranceIndemnityPayment : zero;
  return retVal.toNumber();
}

export enum ProfitType {
  PER_ACRE,
  TOTAL,
}

export function calculateMatrixProfit(
  operationCrop: IOperationCropV2,
  possibleYield: number,
  riskAssessmentData: RiskAssessmentData,
  profitType: ProfitType,
  isInsuranceEnabled: boolean,
  actualPrice?: number | null
) {
  const totalAcres = operationCrop.acres?.toNumber() ?? 0;
  const insuranceIndemnityPayment = isInsuranceEnabled ? calculateInsurance(operationCrop, possibleYield, actualPrice) : 0;
  const totalProfit =
    (riskAssessmentData.marketed?.profit.total || 0) +
    (riskAssessmentData.protected?.profit.total || 0) +
    (riskAssessmentData.unprotected?.profit.total || 0) +
    insuranceIndemnityPayment * totalAcres;

  switch (profitType) {
    case ProfitType.PER_ACRE:
      return totalAcres === 0 ? 0 : totalProfit / totalAcres;
    case ProfitType.TOTAL:
      return totalProfit;
    default:
      return 0;
  }
}

// For Tooltips and debugging in the Matrix UX
export interface MatrixItem {
  profitPricePerAcre: Decimal;
  profitValue: Decimal;
  insuranceIndemnityValue: Decimal;
  soldRevenueValue: Decimal;
  unsoldRevenueValue: Decimal;
  totalCostsValue: Decimal;
  totalGainLossValue: Decimal;
  totalRevenueValue: Decimal;
}
export function calculateMatrixItem(
  operationCrop: IOperationCropV2,
  possibleYield: number,
  cropSummary: OperationCropSummary,
  profitType: ProfitType,
  isInsuranceEnabled: boolean,
  actualPrice?: number | null
): MatrixItem {
  const totalAcres = operationCrop.acres ?? zero;
  const possibleTotalYieldUOM = totalAcres.times(possibleYield);
  const insuranceIndemnityPayment = isInsuranceEnabled ? calculateInsurance(operationCrop, possibleYield, actualPrice) : 0;
  const insuranceIndemnityValue = new Decimal(insuranceIndemnityPayment).times(totalAcres);
  // const possibleUnsoldQuantityUOM = new Decimal(Math.max(possibleTotalYieldUOM - cropSummary.soldQuantityUOM, 0));
  const possibleUnsoldQuantityUOM = possibleTotalYieldUOM.minus(cropSummary.soldQuantityUOM).greaterThan(0)
    ? possibleTotalYieldUOM.minus(cropSummary.soldQuantityUOM)
    : zero;
  const possibleUnsoldRevenueValue = possibleUnsoldQuantityUOM.times(actualPrice ?? 0);
  const totalRevenue = zero
    .plus(cropSummary.soldRevenueValue)
    .plus(cropSummary.totalGainLossValue)
    .plus(possibleUnsoldRevenueValue)
    .plus(totalAcres.times(insuranceIndemnityPayment));

  const totalProfit = totalRevenue.minus(cropSummary.totalCostsValue);

  const matrixItem: MatrixItem = {
    profitPricePerAcre: totalProfit.dividedBy(totalAcres),
    profitValue: totalProfit,
    insuranceIndemnityValue,
    soldRevenueValue: cropSummary.soldRevenueValue,
    unsoldRevenueValue: possibleUnsoldRevenueValue,
    totalCostsValue: cropSummary.totalCostsValue,
    totalGainLossValue: cropSummary.totalGainLossValue,
    totalRevenueValue: totalRevenue,
  };

  return matrixItem;
}

export function calculateMatrixData(
  operationCrop: IOperationCropV2,
  cropBudget: number,
  ranges: MatrixRanges,
  marketSpotPrice: number,
  profitType: ProfitType,
  isInsuranceEnabled: boolean,
  cropContracts: ICropContract[],
  strategies: IStrategy[],
  crop: CommodityInformation,
  spotPrices: PriceByTradingCode
) {
  const priceRange = getMatrixPriceRange(ranges.priceRange.firstValue, operationCrop.marketingCropType, ranges.priceRange.length);
  const yieldRange = getMatrixYieldRange(ranges.yieldRange.firstValue, defaultYieldStep, ranges.yieldRange.length);

  const result = yieldRange.map((possibleYield: number) =>
    priceRange.map((price) => {
      const operationCropWithPossibleYield: IOperationCropV2 = {
        ...operationCrop,
        relevantYieldUOMPerAcre: new Decimal(possibleYield),
      };
      const strategiesSummaryData = calculateStrategySummaryData(strategies, spotPrices, crop, operationCrop);
      const riskAssessmentData = calculateRiskAssessmentData(
        operationCropWithPossibleYield,
        cropBudget,
        price,
        cropContracts,
        strategiesSummaryData
      );
      return Math.round(
        calculateMatrixProfit(operationCrop, possibleYield, riskAssessmentData, profitType, isInsuranceEnabled, price)
      );
    })
  );

  return result;
}

export function calculateMatrixItems(
  operationCrop: IOperationCropV2,
  cropSummary: OperationCropSummary,
  ranges: MatrixRanges,
  profitType: ProfitType,
  isInsuranceEnabled: boolean
): MatrixItem[][] {
  const priceRange = getMatrixPriceRange(ranges.priceRange.firstValue, operationCrop.marketingCropType, ranges.priceRange.length);
  const yieldRange = getMatrixYieldRange(ranges.yieldRange.firstValue, defaultYieldStep, ranges.yieldRange.length);

  const result = yieldRange.map((possibleYield: number) =>
    priceRange.map((price) => {
      const matrixItem = calculateMatrixItem(operationCrop, possibleYield, cropSummary, profitType, isInsuranceEnabled, price);
      return matrixItem;
    })
  );

  return result;
}

export function calculateBreakEvenPerBushel(relevantYield: number, productionCosts: number) {
  return productionCosts && relevantYield ? productionCosts / relevantYield : 0;
}

export function calculateBushelsContracted(cropContracts: ICropContract[]) {
  return sum(cropContracts.map((c) => Number(c.quantitySold ?? 0)));
}

export function calculateBushelsDelivered(cropContracts: ICropContract[]): Decimal {
  return cropContracts.map((c) => new Decimal(c.deliveredQuantityUOM ?? 0)).reduce((acc, curr) => acc.plus(curr), zero);
}

export function calculateAverageContractPrice(cropContracts: ICropContract[]) {
  return (
    cropContracts.map((c) => Number(c.quantitySold) * Number(c.netPrice)).reduce((a, b) => a + b, 0) /
    calculateBushelsContracted(cropContracts)
  );
}

export function filterMarketedCropContracts(cropContracts: ICropContract[]) {
  return cropContracts.filter((cc) => Number(cc.netPrice) && Number(cc.futuresPrice));
}

export type CropSummaryData = {
  contracted?: {
    count: number;
    totalBushels: number;
    netPrice: number;
    estimatedValue: number;
  };
  uncontracted?: {
    totalBushels: number;
    netPrice: number;
    estimatedValue: number;
  };
  delivered?: {
    totalBushels?: number;
  };
  remaining?: {
    totalBushels?: number;
  };
  total?: {
    totalBushels: number;
    netPrice: number;
    estimatedValue: number;
  };
};

export function calculateCropSummaryData(
  operationCrop: IOperationCropV2,
  cropContracts: ICropContract[],
  marketSpotPrice: number
): CropSummaryData {
  const relevantYield = operationCrop.relevantYieldUOMPerAcre;
  const totalAcres = operationCrop.acres ?? zero;
  const estimatedBasis = new Decimal(operationCrop.price?.estimatedBasis || 0);
  const isOtherCropType = operationCrop.marketingCropType === CommodityId.UNKNOWN;

  const expectedYield = relevantYield.times(totalAcres);

  const result: any = {};

  const matchingCropContracts = cropContracts.filter((cc) => cc.operationCropId === operationCrop.id);
  let validContracts = matchingCropContracts;
  if (!isOtherCropType) {
    validContracts = filterMarketedCropContracts(matchingCropContracts);
  }

  result.remaining = {
    totalBushels: expectedYield,
  };

  const totalDeliveredBushels = calculateBushelsDelivered(matchingCropContracts);

  if (validContracts.length) {
    const totalContractedBushels = new Decimal(calculateBushelsContracted(validContracts));
    const contractedNetPrice = new Decimal(calculateAverageContractPrice(validContracts));
    result.contracted = {
      count: validContracts.length,
      totalBushels: totalContractedBushels,
      netPrice: contractedNetPrice,
      estimatedValue: contractedNetPrice.times(totalContractedBushels),
    };
    result.delivered = {
      totalBushels: totalDeliveredBushels,
    };
  }

  let totalBushels = expectedYield;
  if (result?.contracted?.totalBushels.greaterThan(totalBushels)) {
    totalBushels = result?.contracted?.totalBushels;
    result.remaining.totalBushels = totalBushels;
  }

  const uncontractedBushels = expectedYield.minus(result?.contracted?.totalBushels || 0);
  if (uncontractedBushels.greaterThan(0)) {
    const sellingPrice = estimatedBasis.plus(marketSpotPrice || 0);
    result.uncontracted = {
      totalBushels: uncontractedBushels,
      netPrice: sellingPrice,
      estimatedValue: uncontractedBushels.times(sellingPrice),
    };
  }

  const contractedRevenue = new Decimal(result?.contracted?.totalBushels || 0).times(result?.contracted?.netPrice || 0);
  const uncontractedRevenue = new Decimal(result?.uncontracted?.totalBushels || 0).times(result?.uncontracted?.netPrice || 0);
  const numerator = uncontractedRevenue.plus(contractedRevenue);
  const averageWeightedNetPrice = numerator.dividedBy(totalBushels);

  if (averageWeightedNetPrice && totalBushels) {
    result.total = {
      totalBushels: totalBushels,
      netPrice: averageWeightedNetPrice,
      estimatedValue: totalBushels.times(averageWeightedNetPrice),
    };
  }

  // remaining
  if (matchingCropContracts.length) {
    const undeliveredContracts = matchingCropContracts.filter((cc) => cc.deliveryStatus !== DeliveryStatus.COMPLETE);

    if (undeliveredContracts.length) {
      const undeliveredUOM = sum(
        undeliveredContracts.map((c) => Math.max(Number(c.quantitySold ?? 0) - Number(c.deliveredQuantityUOM ?? 0), 0))
      );
      result.remaining.totalBushels = new Decimal(undeliveredUOM);
    } else {
      result.remaining.totalBushels = new Decimal(0);
    }
  }

  // TODO: remove once decimal.js is fully implemented
  Object.keys(result).forEach((key) => {
    result[key].totalBushels = result[key].totalBushels.toNumber();
    if (result[key].netPrice) {
      result[key].netPrice = result[key].netPrice.toNumber();
    }
    if (result[key].estimatedValue) {
      result[key].estimatedValue = result[key].estimatedValue.toNumber();
    }
  });

  return result;
}

export interface IPriceBySymbol {
  [key: string]: number;
}

export function prepareSpotPrices(quotes: QuotesStoreItem): IPriceBySymbol {
  const result: { [key: string]: number } = {};
  Object.values(quotes).forEach((quote: IQuote) => {
    result[quote.symbol] = Number(quote.lastPrice || 0) / 100;
  });
  return result;
}

export function prepareSpotPrice(quote: IQuote): number {
  if (!quote) {
    return 0;
  }
  return Number(quote.lastPrice || 0) / 100;
}

export function prepareSpotPricesNetChanges(quotes: QuotesStoreItem): IPriceBySymbol {
  const result: { [key: string]: number } = {};
  Object.values(quotes).forEach((quote: IQuote) => {
    result[quote.symbol] = Number(quote.netChange || 0) / 100;
  });
  return result;
}
