import { User } from '../types/User';
import useAppContext from './useAppContext';

export default function useCurrentUser(): User {
  const context = useAppContext();

  if (!context?.currentUser) {
    throw new Error('Current user is not set');
  }

  return context.currentUser;
}
