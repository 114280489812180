import { isNil } from 'lodash';
import {
  CommodityId,
  getCommodityByCommodityId,
  hasTradingCode,
  InstrumentType,
} from '@harvestiq/constants';
import { dayjs, Dayjs, nowCdt } from '@harvestiq/utils';
import { FUTURES_MONTH_CODES } from './constants';
import { SymbolParams } from './symbology';
import Decimal from 'decimal.js';

export const BARCHART_SPECIAL_OPTION_PREFIX_BY_COMMODITY_TYPE: Partial<
  Record<CommodityId, string>
> = {
  [CommodityId.CORN]: 'BC',
  [CommodityId.SOYBEANS]: 'BY',
  [CommodityId.KC_WINTER_WHEAT_HRW]: 'BK',
  [CommodityId.CHI_SOFT_RED_WINTER_SRW]: 'BH',
};

export const BARCHART_NEW_CROP_WEEKLY_PREFIX_BY_COMMODITY_TYPE: Partial<
  Record<CommodityId, string>
> = {
  [CommodityId.CORN]: 'BC',
  [CommodityId.SOYBEANS]: 'BM',
};

/**
 * Determines the correct Symbol to be used for fetching quote and futures/options data
 * @returns the Barchart-compatible Symbol
 */
export function getBarchartSymbol(params: SymbolParams): string | null {
  const tz = 'America/Chicago';
  const commodity = getCommodityByCommodityId(params.commodityId);

  if (!hasTradingCode(commodity)) {
    return null;
  }
  // FYI - January is 0 month code
  if (isNil(params.contractMonth)) {
    return null;
  }
  if (!params.contractYear) {
    return null;
  }
  if (params.contractYear && params.contractYear < 2000) {
    throw new Error(`2 digit year ${params.contractYear}`);
  }
  const monthCode = FUTURES_MONTH_CODES[params.contractMonth];
  if (!monthCode) {
    return null;
  }
  const expirationDate = params.expirationDate
    ? dayjs.tz(params.expirationDate, tz)
    : getFallbackExpirationDate(params);

  if (params.instrumentType === InstrumentType.Futures) {
    if (params.isMini) {
      if (commodity.miniTradingCode) {
        return `${commodity.miniTradingCode}${monthCode}${
          params.contractYear - 2000
        }`;
      }
      return null;
    }
    // ZCZ22
    return `${commodity.tradingCode}${monthCode}${params.contractYear - 2000}`;
  }

  if (!params.strikePrice) {
    return null;
  }

  // OPTIONS!
  const strikePrice = new Decimal(params.strikePrice)
    .times(commodity.barchartStrikeMultiplier)
    .toString()
    .padStart(3, '0');
  const currentTime = params.currentTime || nowCdt();

  // expired options
  if (expirationDate.isBefore(currentTime, 'day')) {
    return null;
  }

  // Regular & Serial Options
  if (
    [
      InstrumentType.Call,
      InstrumentType.Put,
      InstrumentType.SerialCall,
      InstrumentType.SerialPut,
    ].includes(params.instrumentType)
  ) {
    const optionCode = getOptionCode(
      expirationDate,
      currentTime,
      params.instrumentType
    );
    // ZCZ190C
    return `${commodity.tradingCode}${monthCode}${strikePrice}${optionCode}`;
  }

  // Special Options & New Crop Weeklys
  const specialOptionCode =
    BARCHART_SPECIAL_OPTION_PREFIX_BY_COMMODITY_TYPE[
      commodity.id as CommodityId
    ];
  const newCropWeeklyCode =
    BARCHART_NEW_CROP_WEEKLY_PREFIX_BY_COMMODITY_TYPE[
      commodity.id as CommodityId
    ];

  // Short-Dated Options
  if (
    [InstrumentType.ShortDatedCall, InstrumentType.ShortDatedPut].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.ShortDatedCall ? 'C' : 'P';
    // BCDN2|670C
    return `${specialOptionCode}D${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // Week 1 Options
  if (
    [InstrumentType.Week1Call, InstrumentType.Week1Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.Week1Call ? 'C' : 'P';
    // BC1N2|670C
    return `${specialOptionCode}1${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // Week 2 Options
  if (
    [InstrumentType.Week2Call, InstrumentType.Week2Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.Week2Call ? 'C' : 'P';
    // BC2N2|670C
    return `${specialOptionCode}2${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // Week 3 Options
  if (
    [InstrumentType.Week3Call, InstrumentType.Week3Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.Week3Call ? 'C' : 'P';
    // BC3N2|670C
    return `${specialOptionCode}3${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // Week 4 Options
  if (
    [InstrumentType.Week4Call, InstrumentType.Week4Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.Week4Call ? 'C' : 'P';
    // BC4N2|670C
    return `${specialOptionCode}4${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // Week 5 Options
  if (
    [InstrumentType.Week5Call, InstrumentType.Week5Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.Week5Call ? 'C' : 'P';
    // BC5N2|670C
    return `${specialOptionCode}5${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // New Crop Week 1 Options
  if (
    [InstrumentType.NewCropWeek1Call, InstrumentType.NewCropWeek1Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.NewCropWeek1Call ? 'C' : 'P';
    // BM6N2|670C
    return `${newCropWeeklyCode}6${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // New Crop Week 2 Options
  if (
    [InstrumentType.NewCropWeek2Call, InstrumentType.NewCropWeek2Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.NewCropWeek2Call ? 'C' : 'P';
    // BM7N2|670C
    return `${newCropWeeklyCode}7${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // New Crop Week 3 Options
  if (
    [InstrumentType.NewCropWeek3Call, InstrumentType.NewCropWeek3Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.NewCropWeek3Call ? 'C' : 'P';
    // BM8N2|670C
    return `${newCropWeeklyCode}8${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // New Crop Week 4 Options
  if (
    [InstrumentType.NewCropWeek4Call, InstrumentType.NewCropWeek4Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.NewCropWeek4Call ? 'C' : 'P';
    // BM9N2|670C
    return `${newCropWeeklyCode}9${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  // New Crop Week 5 Options
  if (
    [InstrumentType.NewCropWeek5Call, InstrumentType.NewCropWeek5Put].includes(
      params.instrumentType
    )
  ) {
    const optionCode =
      params.instrumentType === InstrumentType.NewCropWeek5Call ? 'C' : 'P';
    // BM0N2|670C
    return `${newCropWeeklyCode}0${monthCode}${
      params.contractYear - 2020
    }|${strikePrice}${optionCode}`;
  }

  return null;
}

export function getFallbackExpirationDate(params: SymbolParams): Dayjs {
  const tz = 'America/Chicago';

  if (params.instrumentType === InstrumentType.Futures) {
    return dayjs.tz(
      `${params.contractYear}-${params.contractMonth + 1}-15`,
      tz
    );
  }

  // handle special options
  if (
    params.instrumentType === InstrumentType.ShortDatedCall ||
    params.instrumentType === InstrumentType.ShortDatedPut
  ) {
    return dayjs.tz(
      `${params.contractYear}-${params.contractMonth + 1}-15`,
      tz
    );
  }

  // handle week 1 options
  if (
    params.instrumentType === InstrumentType.Week1Call ||
    params.instrumentType === InstrumentType.Week1Put
  ) {
    return dayjs.tz(`${params.contractYear}-${params.contractMonth}-28`, tz);
  }

  // handle week 2 options
  if (
    params.instrumentType === InstrumentType.Week2Call ||
    params.instrumentType === InstrumentType.Week2Put
  ) {
    return dayjs.tz(`${params.contractYear}-${params.contractMonth + 1}-2`, tz);
  }

  // handle week 3 options
  if (
    params.instrumentType === InstrumentType.Week3Call ||
    params.instrumentType === InstrumentType.Week3Put
  ) {
    return dayjs.tz(`${params.contractYear}-${params.contractMonth + 1}-9`, tz);
  }

  // handle week 4 options
  if (
    params.instrumentType === InstrumentType.Week4Call ||
    params.instrumentType === InstrumentType.Week4Put
  ) {
    return dayjs.tz(
      `${params.contractYear}-${params.contractMonth + 1}-16`,
      tz
    );
  }

  // handle week 5 options
  if (
    params.instrumentType === InstrumentType.Week5Call ||
    params.instrumentType === InstrumentType.Week5Put
  ) {
    return dayjs.tz(
      `${params.contractYear}-${params.contractMonth + 1}-23`,
      tz
    );
  }

  // handle regular & serial options
  return dayjs.tz(`${params.contractYear}-${params.contractMonth}-25`, tz);
}

// TODO: verify this functionality further ...
function getOptionCode(
  expirationDate: Dayjs,
  currentTime: Date,
  instrumentType: InstrumentType
): string {
  if (
    ![
      InstrumentType.Call,
      InstrumentType.Put,
      InstrumentType.SerialCall,
      InstrumentType.SerialPut,
    ].includes(instrumentType)
  ) {
    throw new Error(
      `Cannot determine an option code for InstrumentType: ${instrumentType}`
    );
  }

  // FIXME - this is likely naive, BUT we can't find exactly what logic is to determine when
  // an option's PUT/CALL code in its symbol moves from
  // E -> D -> C (for calls)
  // R -> Q -> P (for puts)
  // some light research makes it seem that it's likely the movement from one year to the next
  // we did confirm that its not simply just "is the expiration < 1 year from now? " ... that didn't work

  // if its the same as the current year, then we're good
  // except for regular options that expire in December (which means they're actually JANUARY options)
  const expirationMonth = expirationDate.month(); // 0-11 ZERO INDEXED!!
  const expirationYear = expirationDate.year();
  const currentYear = currentTime.getFullYear();
  if (expirationYear === currentYear && expirationMonth !== 11) {
    return instrumentType === InstrumentType.Call ||
      instrumentType === InstrumentType.SerialCall
      ? 'C'
      : 'P';
  }

  // next year, or
  if (
    (expirationYear === currentYear + 1 && expirationMonth !== 11) ||
    (expirationMonth === 11 && expirationYear === currentYear)
  ) {
    return instrumentType === InstrumentType.Call ? 'D' : 'Q';
  }

  if (
    expirationYear > currentYear + 1 ||
    (expirationMonth === 11 && expirationYear === currentYear + 1)
  ) {
    return instrumentType === InstrumentType.Call ? 'E' : 'R';
  }

  // :shrug:
  return instrumentType === InstrumentType.Call ? 'C' : 'P';
}

export function isBarchartSpecialOption(instrumentType: InstrumentType) {
  if (
    [
      InstrumentType.Futures,
      InstrumentType.Put,
      InstrumentType.Call,
      InstrumentType.SerialPut,
      InstrumentType.SerialCall,
    ].includes(instrumentType)
  ) {
    return false;
  }
  return true;
}

export function getBarchartSpecialOptionsContract(params: SymbolParams) {
  const commodity = getCommodityByCommodityId(params.commodityId);
  if (!hasTradingCode(commodity)) {
    throw new Error(
      `Cannot determine a Barchart special option contract for commodityId: ${params.commodityId}`
    );
  }
  const monthCode = FUTURES_MONTH_CODES[params.contractMonth];
  const twoDigitYear = params.contractYear - 2000;

  switch (params.instrumentType) {
    case InstrumentType.ShortDatedCall:
    case InstrumentType.ShortDatedPut:
      return `${commodity.specialOptionTradingCode}D${monthCode}${twoDigitYear}`;

    case InstrumentType.Week1Call:
    case InstrumentType.Week1Put:
      return `${commodity.specialOptionTradingCode}1${monthCode}${twoDigitYear}`;

    case InstrumentType.Week2Call:
    case InstrumentType.Week2Put:
      return `${commodity.specialOptionTradingCode}2${monthCode}${twoDigitYear}`;

    case InstrumentType.Week3Call:
    case InstrumentType.Week3Put:
      return `${commodity.specialOptionTradingCode}3${monthCode}${twoDigitYear}`;

    case InstrumentType.Week4Call:
    case InstrumentType.Week4Put:
      return `${commodity.specialOptionTradingCode}4${monthCode}${twoDigitYear}`;

    case InstrumentType.Week5Call:
    case InstrumentType.Week5Put:
      return `${commodity.specialOptionTradingCode}5${monthCode}${twoDigitYear}`;

    default:
      throw new Error(
        'getBarchartSpecialOptionsContract is only to be called for special options'
      );
  }
}
