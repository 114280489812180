import React from 'react';
import './Wizard.scss';
import InputWithLabel, { InputEventType } from '../common/Inputs/InputWithLabel/InputWithLabel';
import Button from '../common/Button/Button';
import InputTypeCheckbox from '../common/Inputs/InputTypeCheckbox/InputTypeCheckbox';
import { IOperationCropWizard } from './Wizard';

interface WizardFillCropsProps {
  handleBack: () => void;
  openNextStep: () => void;
  onSkip: () => void;
  operationCrops: IOperationCropWizard[];
  onChangeHandler: (data: { [key: string]: any }, cropId: number) => void;
}

export default function WizardFillCrops(props: WizardFillCropsProps) {
  const { operationCrops, onChangeHandler, handleBack, openNextStep, onSkip } = props;
  const handleSubmit = () => {
    openNextStep();
  };

  return (
    <div id="wizard-step2" className="wizard-card wizard-step2">
      <div className="wizard-card-header">
        <h3>What crops do you grow?</h3>
        <p />
      </div>
      {operationCrops.length && (
        <div>
          <div id="wizard-step2-body" className="wizard-card-body">
            {operationCrops.map((crop: IOperationCropWizard) => (
              <div className={'crop-item'} key={crop.name}>
                <label className="wizard-checkbox">
                  <InputTypeCheckbox
                    checked={crop.selected}
                    onChange={(e) => onChangeHandler({ selected: !crop.selected }, crop.marketingCropType)}
                  />
                  {crop.name}
                </label>
                {crop.selected && (
                  <div>
                    <div className="flex-row">
                      <InputWithLabel
                        label={'Acres'}
                        type="number"
                        value={crop.acres ? crop.acres.toNumber() : ''}
                        onChange={(e: InputEventType) => onChangeHandler({ acres: e.target.value }, crop.marketingCropType)}
                      />
                      <InputWithLabel
                        label={'Actual Production History (APH)'}
                        type="number"
                        value={crop.aphUOMPerAcre ? crop.aphUOMPerAcre.toNumber() : ''}
                        onChange={(e: InputEventType) =>
                          onChangeHandler({ aphUOMPerAcre: e.target.value }, crop.marketingCropType)
                        }
                      />
                    </div>
                    <div className="flex-row">
                      <InputWithLabel
                        label={'Actual Yield (bu/acre)'}
                        type="number"
                        value={crop.actualYieldUOMPerAcre ? crop.actualYieldUOMPerAcre.toNumber() : ''}
                        onChange={(e: InputEventType) =>
                          onChangeHandler({ actualYieldUOMPerAcre: e.target.value }, crop.marketingCropType)
                        }
                      />
                      <InputWithLabel
                        label={'Projected Yield (bu/acre)'}
                        type="number"
                        value={crop.projectedYieldUOMPerAcre ? crop.projectedYieldUOMPerAcre.toNumber() : ''}
                        onChange={(e: InputEventType) =>
                          onChangeHandler({ projectedYieldUOMPerAcre: e.target.value }, crop.marketingCropType)
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="wizard-card-footer">
            <Button value="Skip Wizard" className="fr-button wizard-skip-button" onClick={onSkip} />
            <div>
              <Button value="Back" className="fr-button backGround-none wizard-back-button" onClick={handleBack} />
              <Button
                value="Next"
                disabled={!operationCrops.filter((crop: IOperationCropWizard) => crop.selected).length}
                className="fr-button backGround-red"
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
