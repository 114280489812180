import styles from './DropDownNavigator.module.scss';

import React from 'react';
import DropDownList, { DropDownListChangeEvent, DropDownListProps } from './DropDownList';
import Label from '../Label/Label';
import { SelectorTypes } from '../../common/SelectWithLabel/SelectWithLabel';

export interface DropDownNavigatorProps extends DropDownListProps {
  data: SelectorTypes<any>[];
  valueId: number;
  onChange: (e: DropDownListChangeEvent) => void | null;
  label?: string;
  width?: string;
}

export default function DropDownNavigator(props: DropDownNavigatorProps) {
  const { className, data, valueId, onChange, label, width, ...otherProps } = props;

  const value = data.find((item) => item.value.id === valueId);

  return (
    <div className={`${styles['fr-crop-dropdown-navigator']} ${className}`}>
      {!!label && <Label className="me-3">{label}</Label>}
      <DropDownList
        data={data}
        dataItemKey="value"
        textField="label"
        defaultValue={value || data[0]}
        value={value}
        onChange={(e: DropDownListChangeEvent) => onChange(e)}
        style={{ width: width || '20em' }}
        stretch
        {...otherProps}
      />
    </div>
  );
}
