import React, { ReactChild, ReactChildren } from 'react';
import './TabSwitcher.css';

export interface TabSwitcherProps {
  children: ReactChild | ReactChildren | ReactChildren[] | ReactChild[];
  wrapperClassName?: string;
}

function TabSwitcher(props: TabSwitcherProps) {
  return (
    <div className={`tab-switcher ${props.wrapperClassName || ''}`}>
      <ul>{props.children}</ul>
    </div>
  );
}

export default TabSwitcher;
