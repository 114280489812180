import { Route, useHistory } from 'react-router-dom';
import React from 'react';
import TradeActivityModal from '../components/TradeActivityModal/TradeActivityModal';
import TradeModal from '../components/TradeModal/TradeModal';
import { History } from 'history';
import TradeModalTeaser from '../components/TradeModal/TradeModalTeaser';

function closeTradeModal(history: History) {
  const { pathname } = history.location;
  const passedState: any = history.location.state || {};
  const routes = pathname.split('/');
  const tradeIndex = routes.indexOf('trade');
  const newRoutes = routes.slice(0, tradeIndex);
  history.push(newRoutes.join('/'), { ...passedState });
}

function toggleModals(history: History) {
  const { pathname } = history.location;
  const passedState: any = history.location.state || {};
  const routes = pathname.split('/');
  const isTradeHistory = routes.indexOf('trade-activity') !== -1;
  const tradeIndex = routes.indexOf('trade');
  const newRoutes = routes.slice(0, tradeIndex);
  newRoutes.push(isTradeHistory ? 'trade' : 'trade-activity');
  history.push(newRoutes.join('/'), { ...passedState });
}

function TradeRouter() {
  const history = useHistory();
  return (
    <>
      <Route exact path="/*/trade-activity">
        <TradeActivityModal
          key={Date.now()}
          width={750}
          timeoutMinutes={10}
          toggleModals={() => {
            toggleModals(history);
          }}
          onClose={() => {
            closeTradeModal(history);
          }}
        />
      </Route>
      <Route exact path="/*/trade/:frSymbol?/:buySell?/:contractsQuantity?">
        <TradeModal
          key={Date.now()}
          width={550}
          timeoutMinutes={10}
          toggleModals={() => {
            toggleModals(history);
          }}
          onClose={() => {
            closeTradeModal(history);
          }}
        />
      </Route>
      <Route exact path="/*/trade-preview">
        <TradeModalTeaser
          width={550}
          onClose={() => {
            closeTradeModal(history);
          }}
        />
      </Route>
    </>
  );
}

export default TradeRouter;
