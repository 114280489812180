import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ClientContext } from '@farmersrisk/cqg/types';
import { CQGService } from '../CQG/service';
import { CQGContext } from '../CQG/CQGProvider';
import { OrderActionType, OrderState, orderStatusReducer } from '../../store/CQG/orderStatusReducer';

export function useOrderHistoryQuery(
  cqgService: CQGService | undefined,
  cqgContext: CQGContext | undefined,
  opts?: UseQueryOptions<OrderState>
) {
  if (!cqgContext) throw new Error('CQG Context not found');
  if (!cqgService) throw new Error('CQG Service not found');

  // Just kick off the subscription. The order statuses come back individually and not as one single payload!!
  async function fetchRecentOrders() {
    if (!cqgContext) throw new Error('CQG Context not found');
    if (!cqgService) throw new Error('CQG Service not found');

    const client = cqgService.client;
    const orderState = cqgContext.state;

    client.setContext(ClientContext.HISTORY);
    await client.logon();
    await client.getAccounts();
    await client.tradeSubscription();
    const orderHistoryReport = await client.orderHistory();

    // TODO: this is just a hack until a bigger refactor to get rid of this OrderState nonsense
    // transform to OrderStatusState
    if (!orderHistoryReport?.orderStatuses.length) return orderState;

    const orderStatuses = orderHistoryReport.orderStatuses;
    for (const status of orderStatuses) {
      const action = { type: OrderActionType.Update, status };
      const newState = orderStatusReducer(orderState, action);
      orderState.orders = newState.orders;
    }

    return orderState;
  }

  const query = useQuery({
    queryKey: ['cqg-order-history', cqgService.operation.id, cqgService.user.id ?? 0, cqgService.username],
    queryFn: fetchRecentOrders,
    ...opts,
    cacheTime: 3 * 1000, // 10 secs, just little bit of buffering
    retry: 3,
  });

  return query;
}
