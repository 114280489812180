import React, { useEffect, useRef, useState } from 'react';
import Modal from '../Modal/Modal';

import { Prompt, useHistory } from 'react-router-dom';

export default function CustomPrompt(props: CustomPromptProps) {
  const { isPromptActive = false, okButtonCallback, cancelButtonCallback } = props;
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [newLocation, setLocation] = useState<{ pathname: string } | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const refDeleteOperation = useRef();

  const handleBlockedNavigation = (nextLocation: any) => {
    if (nextLocation && !confirmedNavigation) {
      setLocation(nextLocation);
      setShowPrompt(true);
      return false;
    }

    setConfirmedNavigation(false);
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && newLocation && newLocation.pathname) {
      history.push(newLocation.pathname);
    }
  }, [confirmedNavigation, history, newLocation]);

  return (
    <>
      <Modal
        show={showPrompt}
        setShow={setShowPrompt}
        modalRef={refDeleteOperation}
        title="You have unsaved changes"
        content={<p>Would you like to skip saving?</p>}
        okButtonName="Yes"
        cancelButtonName="No"
        handleOkClick={() => {
          okButtonCallback && okButtonCallback();
          setConfirmedNavigation(true);
        }}
        handleCancelClick={() => {
          cancelButtonCallback && cancelButtonCallback();
          setShowPrompt(false);
        }}
      />
      <Prompt message={handleBlockedNavigation} when={isPromptActive} />
    </>
  );
}

type CustomPromptProps = {
  isPromptActive: boolean;
  okButtonCallback?: () => void;
  cancelButtonCallback?: () => void;
};
