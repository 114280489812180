import Decimal from 'decimal.js';
import { CommodityInformation } from '@harvestiq/constants';
import { ICropContract } from '../types/ICropContract';
import { Currency } from '@farmersrisk/shared/constants/currencies';
import { IOperationCropV2 } from '../types/IOperationCrops';
import { IRiskScenario } from '../types/IRiskScenario';
import { IStrategy } from '../types/IStrategy';
import { formatNumber, roundToCents } from '../utils/formatNumber';
import { toStringOrUndefined } from '../utils/helpers';
import { PriceByTradingCode } from './MyPositions/myPositions';
import {
  calculateMatrixData,
  defaultMatrixLength,
  defaultYieldStep,
  getMatrixPriceRange,
  getMatrixYieldRange,
  MatrixRanges,
  ProfitType,
} from './RiskAssessment/riskAssessment';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';

export type MatrixCell = {
  value: string;
  class: string;
};

export function prepareMatrixDataForDisplaying(
  profit: number[][],
  priceRange: number[],
  yieldRange: number[],
  currency: Currency,
  spotPrice = 0,
  relevantYield = 0
) {
  const firstCell = {
    value: '',
    class: '',
  };

  const result: MatrixCell[][] = [[firstCell]];
  let currentPriceIndex = -1;

  priceRange.forEach((item, index) => {
    if (roundOneDecimal(item) === roundOneDecimal(spotPrice)) {
      currentPriceIndex = index;
    }

    result[0].push({
      value: formatNumber(item, 2, currency),
      class: item === spotPrice ? 'highlighted-title' : '',
    });
  });

  profit.forEach((row, rowIndex) => {
    const rowTitle = {
      value: yieldRange[rowIndex].toString(),
      class: yieldRange[rowIndex] === relevantYield ? 'highlighted-title' : '',
    };
    const newRow = row.map((price, index) => {
      return {
        value: formatNumber(price, 0, currency),
        class: getPriceClass(price, yieldRange[rowIndex] === relevantYield || currentPriceIndex === index),
      };
    });
    result.push([rowTitle, ...newRow]);
  });

  return result;
}

export function getPriceClass(price: number, isHighlighted: boolean): string {
  const className = isHighlighted ? '-highlighted-price' : '-price';

  if (price > 20) {
    return 'green' + className;
  } else if (price >= -20 && price <= 20) {
    return 'yellow' + className;
  } else {
    return 'red' + className;
  }
}

export function getMatrixData(
  allFieldsPresent: boolean,
  spotPrice: number,
  currency: Currency,
  ranges: MatrixRanges | undefined,
  operationCrop: IOperationCropV2,
  cropBudget: number,
  selectedProfitType: ProfitTypeOption,
  isInsuranceEnabled: boolean,
  currentYearAndCropContracts: ICropContract[],
  strategies: IStrategy[],
  selectedCrop: CommodityInformation,
  cropsSpotPrices: PriceByTradingCode
): MatrixCell[][] {
  if (allFieldsPresent && spotPrice && ranges) {
    const relevantYield = operationCrop.relevantYieldUOMPerAcre.toNumber();
    const profit = calculateMatrixData(
      operationCrop,
      cropBudget,
      ranges,
      spotPrice,
      selectedProfitType.value,
      isInsuranceEnabled,
      currentYearAndCropContracts,
      strategies,
      selectedCrop,
      cropsSpotPrices
    );
    const priceRange = getMatrixPriceRange(
      ranges.priceRange.firstValue,
      operationCrop.marketingCropType,
      ranges.priceRange.length
    );
    const yieldRange = getMatrixYieldRange(ranges.yieldRange.firstValue, defaultYieldStep, ranges.yieldRange.length);

    return prepareMatrixDataForDisplaying(profit, priceRange, yieldRange, currency, spotPrice, relevantYield);
  } else {
    return [];
  }
}

export type ProfitTypeOption = {
  label: string;
  value: ProfitType;
};

export function getProfitTypes(currency: Currency) {
  return [
    {
      label: currency.symbol + '/acre',
      value: ProfitType.PER_ACRE,
    },
    {
      label: 'total ' + currency.symbol,
      value: ProfitType.TOTAL,
    },
  ];
}

export function scenarioToOperationCrop(scenario: IRiskScenario): IOperationCropV2 {
  // IRiskScenario number fields are strings and may be empty, don't use isNil for trinary value check
  const x: IOperationCropV2 = {
    id: 0,
    hidden: false,
    name: '',
    marketingCropType: scenario.cropId,
    hedgingCropType: scenario.cropId,
    uom: UnitOfMeasure.Bushel,
    aphUOMPerAcre: scenario.aph ? new Decimal(scenario.aph) : null,
    operationId: scenario.operationId || 0,
    acres: scenario.acres ? new Decimal(scenario.acres) : null,
    productionCycle: scenario.year || '',
    insurancePercent: scenario.protectionPercent ? new Decimal(scenario.protectionPercent) : null,
    insuranceType: scenario.protectionType,
    storageUOM: new Decimal(0),
    actualYieldUOMPerAcre: scenario.actualYield ? new Decimal(scenario.actualYield) : null,
    projectedYieldUOMPerAcre: scenario.projectedYield ? new Decimal(scenario.projectedYield) : null,
    relevantYieldUOMPerAcre: new Decimal(0),
    price: {
      id: 0,
      operationCropId: 0,
      autumnInsurancePrice: toStringOrUndefined(scenario.autumnInsurancePrice),
      springInsurancePrice: toStringOrUndefined(scenario.springInsurancePrice),
      estimatedBasis: toStringOrUndefined(scenario.estimatedBasis),
      sellingPrice: toStringOrUndefined(scenario.sellingPrice),
      bushelsContracted: toStringOrUndefined(scenario.bushelsContracted),
    },
  };

  x.relevantYieldUOMPerAcre = new Decimal(x.actualYieldUOMPerAcre?.toNumber() || x.projectedYieldUOMPerAcre?.toNumber() || 0);

  return x;
}

export function getMinPriceRangeValueByCrop(cropId: number, spotPrice: number, priceStep: number) {
  const defaultValue: number = spotPrice - defaultMatrixLength * priceStep;
  return roundToCents(
    [
      {
        cropId: 1,
        value: getActualPrice(3, spotPrice, priceStep),
      },
    ].find((item) => item.cropId === cropId)?.value || defaultValue
  );
}

export function getActualPrice(displayedPrice: number, spotPrice: number, priceStep: number) {
  // EB: No idea WTF is going on here...
  // return spotPrice - ((Math.round((spotPrice - displayedPrice) / priceStep)) * priceStep);
  return displayedPrice;
}

export function roundOneDecimal(val: number): number {
  return Math.round(val * 10) / 10;
}
