import Decimal from 'decimal.js';
import { Currency, USD } from '@farmersrisk/shared/constants/currencies';

export function formatAsNumberOrHyphen(value: number | string | null | undefined, currency: Currency | null) {
  return value ? formatNumber(value, 2, currency) : '-';
}

export function formatCurrency(value: Decimal | number | string | null | undefined, currency?: Currency) {
  return value ? formatNumber(value, 2, currency ?? USD) : '--';
}

export function formatNumber(
  number: string | number | Decimal | null | undefined,
  fixed = 0,
  currency: Currency | null = null,
  withOperator = false
): string {
  if (number === null || number === undefined) {
    return '--';
  }

  let valueToFormat: number;
  if (typeof number === 'string') {
    valueToFormat = Number(number);
  } else if (number instanceof Decimal) {
    valueToFormat = number.toNumber();
  } else {
    valueToFormat = number;
  }

  if (isNaN(valueToFormat)) {
    return '--';
  }

  if (currency) {
    const formatted = new Intl.NumberFormat(undefined, {
      currency: currency?.name || 'USD',
      style: 'currency',
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
    }).format(valueToFormat);
    return withOperator ? prependOperator(valueToFormat, formatted) : formatted;
  }

  const formatted = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: fixed,
    minimumFractionDigits: fixed,
  }).format(valueToFormat);
  return withOperator ? prependOperator(valueToFormat, formatted) : formatted;
}

export function formatNumberMinMax(
  number: string | number | Decimal | null | undefined,
  min = 0,
  max = 0,
  currency: Currency | null = null,
  withOperator = false
): string {
  if (number === null || number === undefined) {
    return '--';
  }

  let valueToFormat: number;
  if (typeof number === 'string') {
    valueToFormat = Number(number);
  } else if (number instanceof Decimal) {
    valueToFormat = number.toNumber();
  } else {
    valueToFormat = number;
  }

  if (isNaN(valueToFormat)) {
    return '--';
  }

  if (currency) {
    const formatted = new Intl.NumberFormat(undefined, {
      currency: currency?.name || 'USD',
      style: 'currency',
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(valueToFormat);
    return withOperator ? prependOperator(valueToFormat, formatted) : formatted;
  }

  const formatted = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: max,
    minimumFractionDigits: min,
  }).format(valueToFormat);
  return withOperator ? prependOperator(valueToFormat, formatted) : formatted;
}

interface FormatNumberOptions {
  number: string | number | null | undefined;
  fixed?: number;
  currency?: Currency | null;
  withOperator?: boolean;
}

export function formatNumberWithOptions(options: FormatNumberOptions) {
  const { number, fixed, currency, withOperator } = options;
  return formatNumber(number, fixed, currency, withOperator);
}

export function prependOperator(n: number, s?: string) {
  return n > 0 ? `+${s || n.toString()}` : `${s || n.toString()}`;
}

export function roundToCents(x: number) {
  return Math.round(x * 100) / 100;
}

export function isPositiveNumber(value: string) {
  return Number(value) >= 0;
}
