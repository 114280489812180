import React from 'react';
import { DropDownListChangeEvent } from './DropDownList';
import DropDownNavigator, { DropDownNavigatorProps } from './DropDownNavigator';
import { useAllHedgingCrops, useCurrentHedgingCrops } from '../../../queries/operationCrops';
import { fakeCrop } from '../../../types/ICrop';
import { CommodityInformation } from '@harvestiq/constants';

interface HedgingCropTypeDropDownNavigatorProps extends Omit<DropDownNavigatorProps, 'data' | 'valueId'> {
  selectedHedgingCropType: number;
  onChange: (e: DropDownListChangeEvent) => void | null;
  includeSummary?: boolean;
  byCurrentProductionCycle: boolean;
}

export default function HedgingCropTypeDropDownNavigator(props: HedgingCropTypeDropDownNavigatorProps) {
  const { selectedHedgingCropType, onChange, includeSummary, byCurrentProductionCycle, ...otherProps } = props;
  let hedgingCrops: CommodityInformation[];
  const currentHedgingCropsQuery = useCurrentHedgingCrops();
  const allHedgingCropsQuery = useAllHedgingCrops();
  if (byCurrentProductionCycle) {
    hedgingCrops = currentHedgingCropsQuery.data ?? [];
  } else {
    hedgingCrops = allHedgingCropsQuery.data ?? [];
  }
  const hedgingCropsSelectors = hedgingCrops ? hedgingCrops.map((crop) => ({ value: crop, label: crop.name })) : [];

  if (includeSummary) {
    const summary = { ...fakeCrop, name: 'Summary' };
    hedgingCropsSelectors.unshift({ value: summary, label: 'Summary' });
  }

  return (
    <DropDownNavigator
      {...otherProps}
      name="hedgingCropType"
      label={props.label || 'Crop'}
      data={hedgingCropsSelectors}
      valueId={selectedHedgingCropType}
      onChange={onChange}
    />
  );
}
