import { ContractMetadata, InstrumentGroupItem, OptionMaturityMetadata } from '../cqgMessages/WebAPI/metadata_2';
import { Symbol as CQGSymbol, SymbolCategory } from '../cqgMessages/WebAPI/symbol_browsing_2';
import { Brokerage } from '../cqgMessages/WebAPI/trading_account_2';
import { SessionInformationReport } from '../cqgMessages/WebAPI/trading_session_2';
import { InformationReport_StatusCode } from '../cqgMessages/WebAPI/webapi_2';

export enum cqgEvents {
  // logon
  logonCouldNotRestore = 'cqg:logon:couldNotRestore',
  // websocket
  websocketConnect = 'cqg:websocket:connect',
  websocketDisconnect = 'cqg:websocket:disconnect',
  websocketClosed = 'cqg:websocket:closed',
  // send
  //   logon
  sendPing = 'cqg:send:ping',
  sendLogon = 'cqg:send:logon',
  sendLogoff = 'cqg:send:logoff',
  sendRestoreOrJoinSession = 'cqg:send:restoreOrJoinSession',
  sendChangePassword = 'cqg:send:changePassword',
  //   info
  sendSymbolResolution = 'cqg:send:symbolResolution',
  sendAccountsReport = 'cqg:send:accountsReport',
  sendSessionInformation = 'cqg:send:sessionInformation',
  sendOptionMaturityList = 'cqg:send:optionMaturityList',
  sendInstrumentGroup = 'cqg:send:instrumentGroup',
  sendAtTheMoneyStrike = 'cqg:send:atTheMoneyStrike',
  sendSymbolCategoryRequest = 'cqg:send:symbolCategoryRequest',
  sendSymbolListRequest = 'cqg:send:symbolListRequest',
  sendSymbolCategoryListRequest = 'cqg:send:symbolCategoryListRequest',
  sendProductSearchRequest = 'cqg:send:productSearchRequest',
  sendSessionTimerangeRequest = 'cqg:send:sessionTimerangeRequest',
  sendHistoricalOrderRequest = 'cqg:send:historicalOrders',
  //   orders
  sendTradeSubscription = 'cqg:send:tradeSubscription',
  sendNewOrder = 'cqg:send:newOrder',
  sendModifyOrder = 'cqg:send:modifyOrder',
  sendCancelOrder = 'cqg:send:cancelOrder',
  sendSuspendOrder = 'cqg:send:suspendOrder',
  sendActivateOrder = 'cqg:send:activateOrder',
  sendOrderStatus = 'cqg:send:orderStatus',
  // time and sales
  sendTimeAndSales = 'cqg:send:timeAndSales',
  // market data
  sendMarketDataSubscription = 'cqg:send:marketDataSubscription',
  // time bar
  sendTimeBarData = 'cqg:send:timeBarData',
  // received
  // generic
  receivedError = 'cqg:received:error',
  receivedMessage = 'cqg:received:message',
  //   logon
  receivedPing = 'cqg:received:ping',
  receivedPong = 'cqg:received:pong',
  receivedLogon = 'cqg:received:logon',
  receivedLogonSuccess = 'cqg:received:logon:success',
  receivedLogonFailure = 'cqg:received:logon:failure',
  receivedLogoff = 'cqg:received:logoff',
  receivedRestoreOrJoinSession = 'cqg:received:restoreOrJoinSession',
  receivedPasswordChange = 'cqg:received:receivedPasswordChange',
  //   info
  receivedUserMessages = 'cqg:received:userMessages',
  receivedInformationReport = 'cqg:received:informationReport',
  receivedInformationReportNotFound = 'cqg:received:informationReport:notFound',
  receivedSymbolResolution = 'cqg:received:symbolResolution',
  receivedSymbolResolutionSuccess = 'cqg:received:symbolResolution:success',
  receivedSymbolResolutionFailure = 'cqg:received:symbolResolution:failure',
  receivedAccountsReport = 'cqg:received:accountsReport',
  receivedSessionInformation = 'cqg:received:sessionInformation',
  receivedOptionMaturityList = 'cqg:received:optionMaturityList',
  receivedInstrumentGroup = 'cqg:received:instrumentGroup',
  receivedAtTheMoneyStrike = 'cqg:received:atTheMoneyStrike',
  receivedSymbolCategoryReport = 'cqg:received:symbolCategoryReport',
  receivedSymbolListReport = 'cqg:received:symbolListReport',
  receivedSymbolCategoryListReport = 'cqg:received:symbolCategoryListReport',
  receivedProductSearchReport = 'cqg:received:productSearchReport',
  receivedSessionTimerangeReport = 'cqg:received:sessionTimerangeReport',
  //   orders
  receivedTradeSubscriptionStatus = 'cqg:received:tradeSubscriptionStatus',
  receivedTradeSubscriptionStatusSuccess = 'cqg:received:tradeSubscriptionStatus:success',
  receivedTradeSubscriptionStatusFailure = 'cqg:received:tradeSubscriptionStatus:failure',
  receivedOrderStatus = 'cqg:received:orderStatus',
  receivedOrderRequestRejects = 'cqg:received:orderRequestRejects',
  receivedHistoricalOrdersReport = 'cqg:received:historicalOrdersReport',
  // time and sales
  receivedTimeAndSales = 'cqg:received:timeAndSales',
  // market data
  receivedMarketDataSubscription = 'cqg:received:marketDataSubscription',
  receivedRealTimeMarketDataSubscription = 'cqg:received:realTimeMarketDataSubscription',
  // time bar
  receivedTimeBarData = 'cqg:received:timeBarData',
}

export interface IInformationReportEventData extends BasicInformationReport {
  contractMetadata?: ContractMetadata;
  brokerages?: Brokerage[];
  sessionInformationReport?: SessionInformationReport;
  optionMaturities?: OptionMaturityMetadata[];
  instruments?: InstrumentGroupItem[];
  strike?: number;
  symbols?: CQGSymbol[];
  symbolCategory?: SymbolCategory;
  symbolCategories?: SymbolCategory[];
}

export interface BasicInformationReport {
  id: number;
  statusCode: InformationReport_StatusCode;
  textMessage?: string;
}
