import React from 'react';
import { NavLink } from 'react-router-dom';

export type SubNavItem = {
  title: string;
  path: string;
};

type SubNavProps = {
  items: SubNavItem[];
  extraContent?: React.ReactNode;
};

function SubNav({ items, extraContent }: SubNavProps) {
  return (
    <div className="fr-navbar">
      <ul>
        {items.map((item) => (
          <li key={`menu-item-${item.title.replace(' ', '-').toLowerCase()}`}>
            <NavLink to={item.path} className="fr-navbar-item" activeClassName="fr-navbar-selected-item">
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
      {extraContent}
    </div>
  );
}

export default SubNav;
