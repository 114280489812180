import axios, { AxiosResponse } from 'axios';
import config from '../config';
import { Operation } from '../types/Operation';
import { IPrice } from '../types/IPrices';

export function postNewOperation(data: { userId: number | undefined; name: string }): Promise<AxiosResponse<Operation>> {
  return axios.post(`${config.backend.basePath}/operations`, data);
}

export function patchPrice(price: IPrice): Promise<AxiosResponse<any>> {
  return axios.patch(`${config.backend.basePath}/prices`, price);
}

export function patchIsWizardCompleted(isWizardCompleted: boolean): Promise<AxiosResponse<any>> {
  return axios.patch(`${config.backend.basePath}/users/current`, { isWizardCompleted });
}
