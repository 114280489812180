import React, { createContext, useContext } from 'react';
import { BuySellType } from '@farmersrisk/shared/enums';
import { SelectorTypes } from '../common/SelectWithLabel/SelectWithLabel';
import { CommodityInformation, CommodityId, getTradeableCommodities, InstrumentType } from '@harvestiq/constants';
import { OrderDurationType, OrderType, getOrderDurationTypeLabel, getOrderTypeLabel } from '../../models/orderTypes';
import { TradeExecutionIntent } from '../CQG/CQGProvider';
import config from '../../config';
import { instrumentContractTypeSelections } from '../../shared/positionsSelectors';
import {
  getFuturesParamsForDate,
  getBarchartSymbol,
  getFRSymbolFromParams,
  CQGSymbolUtils,
  SymbolParams,
} from '@harvestiq/symbology';
import dayjs from '../../utils/dayjs';
import { isEqual, omit } from 'lodash';
import { getContractSelector } from './helpers';

export enum QuantityType {
  Bushels = 'BUSHELS',
  Contracts = 'CONTRACTS',
}

export type OptionContext = {
  strike: number;
  underlyingFutureBarchartSymbol?: string;
  barchartSymbol?: string;
  expirationDate?: string;
};

export type OptionDisplay = OptionContext & {
  last: number;
  bid: number | null;
  ask: number | null;
  volume: number | null;
  value: number; // binding to the form element value
};

export type ContractParams = {
  contractMonth: number;
  contractYear: number;
};

export type ContractSelector = SelectorTypes<ContractParams> | null;

// backward compatibility
export type StrikeAndPremiumType = OptionDisplay;

export type TradeOrderState = {
  orderType: SelectorTypes<OrderType>;
  orderDurationType: SelectorTypes<OrderDurationType>;
  contractsQuantity: number;
  selectedCommodity: SelectorTypes<CommodityInformation> | null;
  contractSelector: ContractSelector;
  instrumentType: SelectorTypes<InstrumentType>;
  buySell: SelectorTypes<BuySellType> | null;
  intent: TradeExecutionIntent;
  /** This is the current user-controlled *limit* price for whichever type of trade they are performing
   *  (future price, premium price, etc)
   **/
  orderLimitPrice?: number;
  /** This is the current user-controlled *stop* price for whichever type of trade they are performing */
  orderStopPrice?: number;
  orderPlacementTimestamp?: number;
  selectedOption?: OptionContext;
  isMarketOpen: boolean;
  marketHours: string;
  // make it easy on whoever is using this context to get the symbol they need
  frSymbol: string | undefined;
  cqgSymbol: string | undefined;
  barchartSymbol: string | undefined;
};

export type InitialTraderOrderParams = {
  selectedCommodity: SelectorTypes<CommodityInformation>;
  contractsQuantity: number;
  contractSelector: ContractSelector;
  instrumentType: InstrumentType;
  buySell?: BuySellType;
  strikePrice?: number;
  frSymbol?: string;
};

export type TradeOrderContext = TradeOrderState & {
  changeOrderValue: (name: string, value: any) => void;
  changeOrder: (updatedOrder: Partial<TradeOrderState>) => void;
};

const orderType =
  config.trading.defaultOrderType &&
  config.trading.defaultOrderType in OrderType &&
  config.trading.allowedOrderTypes.includes(config.trading.defaultOrderType)
    ? {
        value: OrderType[OrderType[config.trading.defaultOrderType] as keyof typeof OrderType],
        label: getOrderTypeLabel(config.trading.defaultOrderType),
      }
    : { value: OrderType.Limit, label: getOrderTypeLabel(OrderType.Limit) };

const orderDurationType =
  config.trading.defaultOrderDuration &&
  config.trading.defaultOrderDuration in OrderDurationType &&
  config.trading.allowedOrderDurations.includes(config.trading.defaultOrderDuration)
    ? {
        value: OrderDurationType[OrderDurationType[config.trading.defaultOrderDuration] as keyof typeof OrderDurationType],
        label: getOrderDurationTypeLabel(config.trading.defaultOrderDuration),
      }
    : { value: OrderDurationType.Day, label: getOrderDurationTypeLabel(OrderDurationType.Day) };

const TRADEABLE_COMMODITIES = getTradeableCommodities();
const corn = TRADEABLE_COMMODITIES[CommodityId.CORN];
const cornOption = { value: corn, label: corn.name };

const EMPTY_ORDER_STATE: TradeOrderState = {
  orderType,
  orderDurationType,
  contractsQuantity: 1,
  selectedCommodity: cornOption,
  contractSelector: null,
  instrumentType: instrumentContractTypeSelections[0],
  buySell: null,
  intent: TradeExecutionIntent.INITIALIZE,
  isMarketOpen: true,
  marketHours: '',
  cqgSymbol: undefined,
  frSymbol: undefined,
  barchartSymbol: undefined,
  orderPlacementTimestamp: generateTimestamp(),
};

export const TradeOrderContext = createContext<TradeOrderContext>({
  ...EMPTY_ORDER_STATE,
  changeOrderValue: () => {},
  changeOrder: () => {},
});

export type TradeOrderProviderProps = Partial<TradeOrderState> & {
  children: React.ReactNode;
};

export function TradeOrderProvider(props: TradeOrderProviderProps) {
  const initialOrderState = buildInitialOrderState({
    ...EMPTY_ORDER_STATE,
    ...omit(props, 'children'),
  });

  const [tradeOrderState, setTradeOrderState] = React.useState<TradeOrderState>(initialOrderState);

  const contextValue: TradeOrderContext = {
    ...tradeOrderState,
    changeOrder: (updatedOrder: Partial<TradeOrderState>) => {
      setTradeOrderState((prevState) => ({ ...prevState, ...updatedOrder }));
    },
    changeOrderValue: (name: string, value: any) => {
      setTradeOrderState((prevValues) => {
        //let newOrderPrice = null;
        let newSymbol = null;
        let newTimestamp = null;

        const updatedOrder = {
          ...prevValues,
          [name]: value,
        };

        if (name === 'selectedCommodity') {
          const newVal = value as SelectorTypes<CommodityInformation>;
          if (prevValues.selectedCommodity?.value.id !== newVal?.value.id) {
            const symbolParams = getNearestFutureParams(newVal.value);

            const frSymbol = getFRSymbolFromParams(symbolParams);
            const barchartSymbol = getBarchartSymbol(symbolParams);

            if (!frSymbol || !barchartSymbol) {
              throw new Error('Could not generate symbol!');
            }

            updatedOrder.cqgSymbol = frSymbol;
            updatedOrder.instrumentType =
              instrumentContractTypeSelections.find((x) => x.value === symbolParams.instrumentType) ??
              instrumentContractTypeSelections[0];

            updatedOrder.contractSelector = getContractSelector(symbolParams.contractMonth, symbolParams.contractYear);
          }
        }

        if (name === 'instrumentType') {
          // current selectedOption is invalid, hooks in the strike ladder will fill in default
          updatedOrder.selectedOption = undefined;
        }

        if (name === 'contractSelector') {
          const newVal = value as ContractSelector;
          const prevVal = prevValues.contractSelector as ContractSelector | undefined;
          if (!isEqual(newVal?.value, prevVal?.value)) {
            // clear the strikeAndPremium value
            updatedOrder.selectedOption = undefined;
          }
        }

        if (name === 'intent') {
          newSymbol = value === TradeExecutionIntent.INITIALIZE ? '' : prevValues.cqgSymbol;
          newTimestamp = value === TradeExecutionIntent.INITIALIZE ? generateTimestamp() : prevValues.orderPlacementTimestamp;

          updatedOrder.cqgSymbol = newSymbol;
          updatedOrder.orderPlacementTimestamp = newTimestamp;
        }

        // keep the symbols updated
        if (updatedOrder.selectedCommodity && updatedOrder.instrumentType && updatedOrder.contractSelector) {
          let cqgSymbol;
          let frSymbol;
          let barchartSymbol;

          const instrumentType = updatedOrder.instrumentType.value;
          const commodity = updatedOrder.selectedCommodity.value;
          const contractSymbolParams: SymbolParams = {
            commodityId: commodity.id,
            instrumentType,
            contractMonth: updatedOrder.contractSelector.value.contractMonth,
            contractYear: updatedOrder.contractSelector.value.contractYear,
          };

          if (instrumentType === InstrumentType.Futures) {
            // contract is a barchart symbol
            barchartSymbol = getBarchartSymbol(contractSymbolParams);
            cqgSymbol = CQGSymbolUtils.getCQGSymbol(contractSymbolParams);
            updatedOrder.selectedOption = undefined;
          } else if (updatedOrder.selectedOption) {
            // contract is an option
            const strikePrice = updatedOrder.selectedOption?.strike;

            const symbolParams: SymbolParams = {
              commodityId: commodity.id,
              contractMonth: updatedOrder.contractSelector?.value.contractMonth ?? contractSymbolParams.contractMonth,
              contractYear: updatedOrder.contractSelector?.value.contractYear ?? contractSymbolParams.contractYear,
              strikePrice,
              instrumentType,
            };

            barchartSymbol = getBarchartSymbol(symbolParams);
            cqgSymbol = CQGSymbolUtils.getCQGSymbol(symbolParams);
          }

          updatedOrder.cqgSymbol = cqgSymbol ?? undefined;
          updatedOrder.frSymbol = frSymbol ?? undefined;
          updatedOrder.barchartSymbol = barchartSymbol ?? undefined;
        }

        return updatedOrder;
      });
    },
  };

  function buildInitialOrderState(initialOrderState: TradeOrderState): TradeOrderState {
    const orderState = {
      ...initialOrderState,
    };

    if (!orderState.contractSelector) {
      const futuresParams = getNearestFutureParams(orderState.selectedCommodity?.value ?? TRADEABLE_COMMODITIES[0]);
      const symbolParams: SymbolParams = {
        ...futuresParams,
        instrumentType: orderState.instrumentType?.value ?? InstrumentType.Futures,
      };

      const frSymbol = getFRSymbolFromParams(symbolParams);
      const barchartSymbol = getBarchartSymbol(symbolParams);
      const cqgSymbol = CQGSymbolUtils.getCQGSymbol(symbolParams);

      orderState.contractSelector = getContractSelector(symbolParams.contractMonth, symbolParams.contractYear);
      orderState.cqgSymbol = cqgSymbol ?? undefined;
      orderState.frSymbol = frSymbol ?? undefined;
      orderState.barchartSymbol = barchartSymbol ?? undefined;
      orderState.instrumentType =
        instrumentContractTypeSelections.find((x) => x.value === symbolParams.instrumentType) ??
        instrumentContractTypeSelections[0];
    }

    return orderState;
  }

  return <TradeOrderContext.Provider value={contextValue}>{props.children}</TradeOrderContext.Provider>;
}

export default TradeOrderProvider;

export function useTradeOrderContext() {
  return useContext(TradeOrderContext);
}

function generateTimestamp() {
  return Number(new Date().getTime().toString().slice(9));
}

function getNearestFutureParams(commodity: CommodityInformation) {
  const djs = dayjs().add(1, 'month').startOf('month');
  const nearestFuture = getFuturesParamsForDate(commodity, djs);
  return nearestFuture;
}
