import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import usePermission from '../../../customHooks/usePermission';
import { useAllOperationCrops } from '../../../queries/operationCrops';
import { selectBudgetType } from '../../../store/PagesConfigs/Costs/costsSlice';
import { IBudget } from '../../../types/IBudget';
import { IOperationCropV2 } from '../../../types/IOperationCrops';
import { RootState } from '../../../types/Store';
import { toNumberOrNull } from '../../../utils/helpers';
import InputWithLabel, { InputEventType } from '../../common/Inputs/InputWithLabel/InputWithLabel';
import BudgetTotalRow from './BudgetTotalRow';
import { getInitialBudget } from './Costs';

type SimpleModeProps = {
  editableBudget?: IBudget;
  setEditableBudget: Dispatch<SetStateAction<IBudget>>;
};

function SimpleMode(props: SimpleModeProps) {
  const { editableBudget, setEditableBudget } = props;
  const productionCycle = useSelector((state: RootState) => state.productionCycles.selectedYear);
  const budgetType = useSelector(selectBudgetType);
  const currentOperation = useCurrentOperation();
  const currency = useCurrentCurrency();
  const isAtLeastEditor = usePermission();
  const operationCrops = useAllOperationCrops().data || [];

  const currentOperationCrops = operationCrops.filter(
    (operationCrop) => operationCrop.productionCycle === productionCycle && !operationCrop.hidden
  );

  useEffect(() => {
    if (!editableBudget) {
      setEditableBudget(getInitialBudget(currentOperationCrops, currentOperation, productionCycle, budgetType));
    }
  }, [editableBudget, setEditableBudget, currentOperationCrops, currentOperation, productionCycle, budgetType]);

  const labels = {
    1: `(${currency.symbol})`,
    2: `(${currency.symbol}/acre)`,
    3: `(${currency.symbol}/bu)`,
  };

  const onChangeBudgetNameHandler = (e: InputEventType) => {
    setEditableBudget((previousBudget) => ({
      ...previousBudget,
      name: e.target.value,
    }));
  };

  function onChangeHandler(e: InputEventType, operationCrop: IOperationCropV2) {
    const newBudgetItem = {
      valueType: 2,
      value: toNumberOrNull(e.target.value),
      categoryName: 'Production Cost',
    };

    setEditableBudget((previousBudget) => ({
      ...previousBudget,
      cropBudgets: {
        ...previousBudget.cropBudgets,
        [operationCrop.id]: [newBudgetItem],
      },
    }));
  }

  return (
    <div>
      <div className="costs-input-wrapper">
        <InputWithLabel
          label="Budget name"
          value={editableBudget?.name || ''}
          required={true}
          disabled={!isAtLeastEditor}
          onChange={onChangeBudgetNameHandler}
        />
      </div>
      {currentOperationCrops.map((operationCrop) => {
        const budgetItem = editableBudget?.cropBudgets?.[operationCrop.id]?.[0];
        return (
          <div key={operationCrop.id}>
            <div className="divider-line" />
            <BudgetTotalRow operationCrop={operationCrop} budget={editableBudget} />
            <div key={editableBudget?.id} className="costs-input-wrapper">
              <InputWithLabel
                label={
                  (budgetItem && budgetItem.categoryName && `${budgetItem.categoryName} ${labels[budgetItem.valueType]}`) ||
                  'Production Cost'
                }
                type="number"
                min={0}
                value={budgetItem && budgetItem.value}
                disabled={!isAtLeastEditor}
                onChange={(e: InputEventType) => onChangeHandler(e, operationCrop)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SimpleMode;
