import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';
import axios from 'axios';
import { axiosInit } from '../shared/apiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Operation } from '../types/Operation';
import { AppContextQueryResult } from '@farmersrisk/shared/features/app/appContextQuery';
import {
  getImpersonatingConfig,
  getStoredLastVisitedOperationId,
  setImpersonatingConfig,
  setStoredCurrentOperationId,
} from './storageService';
import { IRole } from '../types/IRole';
import Loader from '../components/common/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchBudgets } from '../store/Budgets/thunks';
import { getCropContractsThunk } from '../store/CropContracts/thunks';
import { getStrategiesThunk } from '../store/Strategies/thunks';
import { fetchRiskScenarios } from '../store/RiskScenarios/thunks';
import { ImpersonatingConfig } from '../types/ImpersonatingConfig';
import { usePrefetchTradingAccountConfigs } from '../queries/tradingAccountConfigs';
import { QueryKey } from '../queries/QueryKey';

export type AppContext = AppContextQueryResult & {
  isLoading: boolean;
  isAuthenticated: boolean;
  currentOperation: Operation;
  currentRole?: IRole;

  changeCurrentOperation: (operationId: number, isImpersonating?: boolean) => Promise<void>;
  impersonateUser: (opts: { sub?: string; operationId: number; userId: number }) => Promise<void>;
  stopImpersonatingUser: () => Promise<void>;
};

export const AppContext = React.createContext<AppContext | undefined>(undefined);

export function AppContextProvider({ children }: { children: React.ReactNode }) {
  const storedOperationId = getStoredLastVisitedOperationId();
  const [currentOperationId, setCurrentOperationId] = React.useState(storedOperationId);
  const prevImpersonatingConfig = getImpersonatingConfig();

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { data: accessToken } = useAuth0AccessToken();
  const { data: appContextResponse, isLoading: isQueryLoading } = useAppContextQuery({
    accessToken: accessToken!,
    operationId: currentOperationId,
    enabled: isAuthenticated && !!accessToken,
  });
  const prefetchTradingAccountConfigs = usePrefetchTradingAccountConfigs();
  const wipeQueryClientCache = useWipeQueryClientCache();

  // TODO: remove this when redux is gone
  const dispatch = useDispatch();

  // move this to a hook when redux is gone
  if (isAuthenticated) {
    axiosInit(getAccessTokenSilently);
  }

  if (isLoading || !isAuthenticated || isQueryLoading || !appContextResponse) {
    return <Loader></Loader>;
  }

  const { currentUser } = appContextResponse;

  const context: AppContext = {
    currentOperation: {
      id: 0,
      userId: appContextResponse.currentUser.id || 0,
      name: `${appContextResponse.currentUser.givenName}'s Operation`,
      currencyId: 1,
      isDeleted: false,
      isPremium: false,
      tradingEnabled: false,
    },
    ...appContextResponse,
    isLoading,
    isAuthenticated,
    changeCurrentOperation,
    impersonateUser,
    stopImpersonatingUser,
  };

  if (context.currentOperation?.id && context.currentUser) {
    context.currentRole = context.roles.find(
      (x) => x.operationId === context.currentOperation?.id && x.userId === context.currentUser?.id
    );
  }

  const { currentOperation, currentRole } = context;
  if (currentOperation?.id && currentRole?.id) {
    // // fire and forget these...
    dispatch(fetchBudgets(context.currentOperation.id));
    //dispatch(fetchNotifications(currentOperation.id)),
    dispatch(getCropContractsThunk(context.currentOperation.id));
    dispatch(getStrategiesThunk(context.currentOperation.id));
    dispatch(fetchRiskScenarios(context.currentOperation.id));
    prefetchTradingAccountConfigs(currentOperation.id);
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;

  // Helper functions
  async function changeCurrentOperation(operationId: number, isImpersonating = false) {
    setStoredCurrentOperationId(operationId, isImpersonating ?? false);
    await wipeQueryClientCache()
      .then(() => {
        setCurrentOperationId(operationId);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function impersonateUser(opts: { sub?: string; operationId: number; userId: number }) {
    const { sub, operationId, userId } = opts;

    const config: ImpersonatingConfig = {
      isImpersonating: true,
      chosenOperationId: operationId,
      previousOperationId: currentOperation.id,
      adminUser: prevImpersonatingConfig?.adminUser || currentUser,
      auth0Id: sub,
      userId,
    };

    setImpersonatingConfig(config);
    return changeCurrentOperation(operationId, true);
  }

  function stopImpersonatingUser() {
    if (!prevImpersonatingConfig || !prevImpersonatingConfig.isImpersonating) {
      return Promise.resolve();
    }

    // clear the impersonating config
    setImpersonatingConfig(undefined);
    return changeCurrentOperation(prevImpersonatingConfig.previousOperationId);
  }
}

const BASE_PATH = `${config.backend.basePath}/app/context`;

const fetch = async (args: { accessToken: string; operationId: number | null }) => {
  // use axios directly to avoid the auth0 hook

  let url = `${BASE_PATH}`;
  if (args.operationId) {
    url += `/${args.operationId}`;
  }

  // TODO: fix this type...
  const response = await axios.get<AppContextQueryResult>(url, {
    headers: {
      Authorization: `Bearer ${args.accessToken}`,
      'x-current-operation-id': args.operationId ?? undefined,
    },
  });
  return response.data;
};

const useAppContextQuery = (args: { accessToken: string; operationId: number | null; enabled: boolean }) => {
  const query = useQuery({
    queryKey: [QueryKey.AppContext, args.operationId],
    queryFn: () => fetch({ ...args }),
    enabled: !!args.enabled,
  });
  return query;
};

const useAuth0AccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // react query with zero caching
  const query = useQuery({
    queryKey: ['auth0-access-token'],
    queryFn: () => getAccessTokenSilently(),
    enabled: !!isAuthenticated,
  });

  return query;
};

const useWipeQueryClientCache = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.clear();
    return Promise.resolve(true);
  }, [queryClient]);
};
