import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tooltipArrow: {
    background: '#E0E4E8',
    borderRadius: 2,
    border: 0,
    color: 'black',
    padding: '12px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  tooltipArrowNotes: {
    background: '#E0E4E8',
    borderRadius: 2,
    padding: 8,
    border: 0,
    color: 'black',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
    maxWidth: '400px',
    whiteSpace: 'pre-wrap',
    '& *': {
      margin: 0,
    },
  },
  arrow: {
    color: '#E0E4E8',
  },
  infoIcon: {
    marginLeft: '4px',
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
  },
});
