import { IConfig } from '@farmersrisk/shared/config';

declare global {
  interface Window {
    __CONFIG: IConfig;
  }
}

const defaultConfig: IConfig = {
  auth0: {
    domain: 'farmersrisk-test.us.auth0.com',
    clientId: 'OOLIFqOY84H3zwtfx8t6LEcVYEDMsrUJ',
    audience: 'https://farmersrisk-test.us.auth0.com/api/v2/',
  },
  landing: {
    basePath: 'http://localhost:9999',
  },
  backend: {
    basePath: '',
  },
  adminsFormData: {
    thirdPartyName: 'HarvestIQ',
    contractName: 'Eric Barnard',
    emailAddress: 'eric+developmentadmis@harvestiq.com',
  },
  organization: {
    name: 'HarvestIQ',
    logoDirectory: '/branding/hiq',
    logoSquare: '/branding/hiq/logo-color-square.svg',
    logoWide: '/branding/hiq/logo-color-256x66.png',
    logoAnimated: '/branding/hiq/logo-animated.svg',
    favicon: '/branding/hiq/favicon.ico',
  },
  trading: {
    useMockWebsocket: false,
    websocketAddress: '',
    username: '',
    password: '',
    retryAmount: 2,
    retryMinTimeout: 2000,
    allowedOrderTypes: [2],
    allowedOrderDurations: [1, 2],
    realtimePricingEnabled: true,
    realtimePricingUpdateIntervalMs: 30000,
  },
  env: 'test',
  featureFlags: {
    myPositionsSummaryEnabled: false,
    /** Interval in minutes. `false` to disable */
    checkForUpdatesInterval: false,
    stoneXAllowCredentials: false,
    insightsEnabled: false,
    livestockTradingEnabled: false,
    cropContractSummaryImprovements: false,
    cashSalesV2Enabled: false,
    isCropsMenuV2Enabled: false,
    isLivestockProfitabilityEnabled: false,
  },
  pendoEnabled: false,
  dataDogEnabled: false,
  version: process.env.VITE_APP_VERSION || '0.0.defaultInConfig2',
};

const fileConfig = window.__CONFIG || defaultConfig;

const config = {
  ...fileConfig,
  version: process.env.VITE_APP_VERSION || '0.0.defaultInConfig',
};

export default config;
