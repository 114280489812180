import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { FUTURES_MONTH_CODES } from '@harvestiq/symbology';
import { CommodityInformation } from '@harvestiq/constants';
import { Instrument } from '../../queries/useInstruments';
import { getNearestMonthTradingSymbol } from '@harvestiq/symbology';
import { nowCdtDayjs } from '../../utils/dayjs';

export type SymbolLookUp = {
  exchange: string;
  name: string;
  symbol: string;
};

export const prepareExpirationMonthOptions = (data: SymbolLookUp[]) => {
  const filteredData = data.filter((result) => !result.symbol.includes('Y00'));

  const sortedData = filteredData.sort(
    (a: any, b: any) => new Date(a.name.replace(/\w+\s/, '')).getTime() - new Date(b.name.replace(/\w+\s/, '')).getTime()
  );

  return sortedData.map((item) => {
    const monthAndYear: string | undefined = item.name.match('[^ ]+\\s+[^ ]+$')?.toString();
    const label = monthAndYear?.replace(/\s\d{2}/, ', ') + ' (' + item.symbol + ')';
    return { label: label, value: item.symbol };
  });
};

// for use with selector types, e.g., { label: string; value: string }
export const findNearestMonthSelectorAfterCurrent = (
  data: { label: string; value: string }[],
  crop: CommodityInformation
): { label: string; value: string } | undefined => {
  if (!data || !data.length) {
    return;
  }

  const month = new Date().getMonth();
  let nextMonth = month;
  let nearestMonth: { label: string; value: string } | undefined;

  const findNearestMonth = () => {
    if (nextMonth === 11) {
      nextMonth = 0;
    } else {
      nextMonth++;
    }
    nearestMonth = data.find((item) => item.value.charAt(2) === getNearestMonthTradingSymbol(nextMonth, crop));
  };

  do {
    findNearestMonth();
  } while (!nearestMonth);

  return nearestMonth;
};

// for use with selector types, e.g., { label: string; value: string }
export const findCropMonthSelector = (
  targetYear: string,
  crop: CommodityInformation,
  data: { label: string; value: string }[]
): { label: string; value: string } | undefined => {
  if (!data || isEmpty(data) || !Array.isArray(data)) {
    return;
  }

  const currentYear = nowCdtDayjs().year();
  const currentYearData = data.filter((item) => item.value.slice(-2) === currentYear.toString().slice(-2));
  if (
    currentYear > Number(targetYear) ||
    // for scenarios where new crop futures of this year have already passed
    // e.g., late November and December, ZSX already expired
    (currentYear === Number(targetYear) && isEmpty(currentYearData))
  ) {
    return findNearestMonthSelectorAfterCurrent(data, crop);
  }

  if (crop.id === 1 || crop.id === 2 || crop.id === 7) {
    return data.filter(
      (datum) =>
        datum.value === `${crop.tradingCode}Z${targetYear.substring(2)}` ||
        datum.value === `${crop.tradingCode}X${targetYear.substring(2)}`
    )[0];
  } else if (crop.id === 3 || crop.id === 4 || crop.id === 5) {
    return data.filter(
      (datum) =>
        'NUZ'.includes(datum.value[2]) && datum.value === `${crop.tradingCode}${datum.value[2]}${targetYear.substring(2)}`
    )[0];
  } else if (crop.id === 6) {
    return data.filter(
      (datum) => 'UZ'.includes(datum.value[2]) && datum.value === `${crop.tradingCode}${datum.value[2]}${targetYear.substring(2)}`
    )[0];
  }
};

// for use with IInstrument[], returns Date
const findNearestMonthDateAfterCurrent = (instruments: Instrument[], crop: CommodityInformation): Date | undefined => {
  if (!instruments || !instruments.length) {
    return;
  }

  const month = new Date().getMonth();
  let nextMonth = month;
  let nearestInstrument: Instrument | undefined;

  const findNearestMonth = () => {
    if (nextMonth === 11) {
      nextMonth = 0;
    } else {
      nextMonth++;
    }
    nearestInstrument = instruments.find(
      (instrument) => FUTURES_MONTH_CODES[instrument.contractMonth] === getNearestMonthTradingSymbol(nextMonth, crop)
    );
  };

  do {
    findNearestMonth();
  } while (!nearestInstrument);

  return new Date(nearestInstrument.contractYear, nearestInstrument.contractMonth, 1);
};

// for use with IInstrument[], returns Date
export const findCropMonthDate = (
  targetYear: string,
  crop: CommodityInformation,
  instruments: Instrument[]
): Date | undefined => {
  const currentYear = dayjs().year();
  if (currentYear > Number(targetYear)) {
    return findNearestMonthDateAfterCurrent(instruments, crop);
  }

  // No data was passed; so filter will not work.
  // Also make sure that the data is an array.
  if (!instruments || !Array.isArray(instruments)) {
    return;
  }

  let relevantInstruments = instruments.filter(
    (instrument) => instrument.contractYear === Number(targetYear) && instrument.cropType === crop.id
  );

  if (crop.id === 1 || crop.id === 2 || crop.id === 7) {
    relevantInstruments = relevantInstruments.filter(
      (instrument) => instrument.contractMonth === 10 || instrument.contractMonth === 11
    );
  } else if (crop.id === 3 || crop.id === 4 || crop.id === 5) {
    relevantInstruments = relevantInstruments.filter(
      (instrument) => instrument.contractMonth === 6 || instrument.contractMonth === 8 || instrument.contractMonth === 11
    );
  } else if (crop.id === 6) {
    relevantInstruments = relevantInstruments.filter(
      (instrument) => instrument.contractMonth === 8 || instrument.contractMonth === 11
    );
  }

  return relevantInstruments[0].contractYear && relevantInstruments[0].contractMonth
    ? new Date(relevantInstruments[0].contractYear, relevantInstruments[0].contractMonth, 1)
    : undefined;
};
