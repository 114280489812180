import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatuses } from '../LoadingStatuses';
import { createTradingAccountConfig, updateTradingAccountConfig } from './thunks';
import ErrorToast from '../../components/Toast/ErrorToast';
import { RootState } from '../../types/Store';
import { ITradingAccountConfig } from '../../types/ITradingAccountConfig';
import { SynchronizationStatus } from '@farmersrisk/shared/constants/SynchronizationStatus';
import { User } from '../../types/User';
import { Operation } from '../../types/Operation';

type TradingAccountConfigSliceInitialState = {
  configs: ITradingAccountConfig[];
  status: LoadingStatuses;
  error: string | null;
};

function getOperationAddress(operation: Operation) {
  return (
    ((operation.address1 || '') + ' ' + (operation.address2 || '')).trim() +
    '\n' +
    (operation.city || '') +
    ', ' +
    (operation.postalCode || '')
  );
}

export const initialTradingAccountConfig = (currentUser: User, operation: Operation): ITradingAccountConfig => ({
  id: 0,
  operationId: operation.id,
  isActive: true,
  accountNumbers: [''],
  synchronizedAt: null,
  documentName: '',
  updatedAt: '',
  createdAt: '',
  lastSyncStatus: SynchronizationStatus.IDLE,
  additionalFields: {
    title: '',
    signature: '',
    firstName: currentUser.givenName || '',
    lastName: currentUser.familyName || '',
    email: currentUser.email,
    phoneNumber: currentUser.phoneNumberVerified || currentUser.phoneNumber || '',
    operationName: operation.name,
    operationAddress: getOperationAddress(operation),
  },
  dataProvider: null,
});

const initialState: TradingAccountConfigSliceInitialState = {
  configs: [],
  status: LoadingStatuses.Idle,
  error: null,
};

const tradingAccountConfigsSlice = createSlice({
  name: 'tradingAccountConfigs',
  initialState,
  reducers: {},
  extraReducers: {
    [createTradingAccountConfig.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [createTradingAccountConfig.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Success;
      state.configs = [...state.configs, action.payload];
    },
    [createTradingAccountConfig.rejected.toString()]: (state, action) => {
      state.error = action.error.message;
      state.status = LoadingStatuses.Failed;
      if (action.error.message.includes('status code 400')) {
        ErrorToast('Your integration credentials are invalid!');
      } else {
        ErrorToast('Failed to set up integration!');
      }
    },
    [updateTradingAccountConfig.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Success;
      state.error = null;
      state.configs = updateScenario(state.configs, action.payload);
    },
    [updateTradingAccountConfig.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Updating;
    },
    [updateTradingAccountConfig.rejected.toString()]: (state, action) => {
      state.error = action.error.message;
      state.status = LoadingStatuses.Failed;
      ErrorToast('Failed to update integration!');
    },
  },
});

function updateScenario(state: ITradingAccountConfig[], config: ITradingAccountConfig) {
  const newState = [...state];
  const previousConfig: ITradingAccountConfig | undefined = newState.find((c) => c.id === config.id);

  if (previousConfig) {
    newState[newState.indexOf(previousConfig)] = { ...previousConfig, ...config };
  }
  return newState;
}

export default tradingAccountConfigsSlice.reducer;

export const selectTradingAccountConfigsStatus = (state: RootState) => state.tradingAccountConfigs.status;
export const selectTradingAccountConfigsError = (state: RootState) => state.tradingAccountConfigs.error;
