import { CommodityInformation } from '@harvestiq/constants';
import { Dayjs, dayjs } from '@harvestiq/utils';
import { InstrumentType } from '@harvestiq/constants';
import {
  FUTURES_MONTH_CODES,
  MONTH_CODE,
  MONTH_CODE_TO_MONTH_NUMBER,
} from './constants';
import { SymbolParams } from './symbology';

export function getNearestMonthTradingSymbol(
  month: number,
  commodity: CommodityInformation
): string {
  const FUTURE_MONTH_SYMBOLS = getCommodityFutureMonthSymbols(commodity);

  // Loop over the key, values in the future month dictionary, and return the first key that matches the month
  for (const [monthCode, monthValue] of Object.entries(FUTURE_MONTH_SYMBOLS)) {
    if (Number(monthCode) >= month) {
      return monthValue;
    }
  }

  // If we get here, we didn't find a match, so return the earliest month.
  return FUTURE_MONTH_SYMBOLS[0] || FUTURE_MONTH_SYMBOLS[2];
}

export function getFuturesParamsForDate(
  commodity: CommodityInformation,
  targetDate: Dayjs | string // ISO 8601 date string YYYY-MM-DD
): SymbolParams {
  const targetDjs = dayjs(targetDate);
  const year = targetDjs.year();
  const month = targetDjs.month();

  let contractYear = year;

  const symbolMonth = getNearestMonthTradingSymbol(month, commodity);
  const contractMonth = MONTH_CODE_TO_MONTH_NUMBER[symbolMonth];
  // if the symbol is for the next year, reflect that ...
  if (contractMonth < month) {
    contractYear += 1;
  }

  return {
    instrumentType: InstrumentType.Futures,
    contractYear: contractYear,
    contractMonth: contractMonth,
    commodityId: commodity.id,
  };
}

export function getCommodityFutureMonthSymbols(
  commodity: CommodityInformation
): Record<number, MONTH_CODE> {
  const futuresMonths = commodity.futuresTradingMonths;

  const monthCodes = futuresMonths.reduce((acc, month) => {
    const monthCode = FUTURES_MONTH_CODES[month];
    acc[month] = monthCode;
    return acc;
  }, {} as Record<number, MONTH_CODE>);

  return monthCodes;
}
