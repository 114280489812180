import './Toast.css';
import { toast } from 'react-toastify';
import React from 'react';

export default function SuccessToast(message: string, title = 'Success!', toastId?: string): void {
  toast.success(
    <div className="custom-toast">
      <span data-testid="toast-title">{title}</span>
      <p data-testid="toast-message">{message}</p>
    </div>,
    { toastId }
  );
}
