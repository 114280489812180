export enum ErrorCode {
  LOGON_FAILURE = 1,
  TIMEOUT_TRADE_SUBSCRIPTION = 2,
  CONNECTION_CLOSED = 401,
  UNKNOWN = 901,
}

export const ErrorMap = {
  [ErrorCode.LOGON_FAILURE]: 'There was an error logging in',
  [ErrorCode.TIMEOUT_TRADE_SUBSCRIPTION]: 'There was an unexpected error',
  [ErrorCode.CONNECTION_CLOSED]: 'The connection was unexpectedly closed',
  [ErrorCode.UNKNOWN]: 'An unexpected error occurred',
};

export interface ErrorRecord {
  msg?: string;
  code: ErrorCode;
  timestamp: number;
  data?: any;
}

export interface CQGClientError {
  token?: string;
  id: number;
  statusCode: number;
  textMessage?: string | null;
}

export class TimeoutError extends Error {
  constructor(msg: string) {
    super(msg);
  }
}
