import { AppThunk } from '../../types/Store';
import axios from 'axios';
import config from '../../config';
import ErrorToast from '../../components/Toast/ErrorToast';
import {
  addNewCropContract,
  changeLoadingStatus,
  deleteCropContract,
  editCropContract,
  setCropContracts,
} from './cropContractsSlice';
import { ICropContract } from '../../types/ICropContract';
import { LoadingStatuses } from '../LoadingStatuses';

export const getCropContractsThunk = (operationId: number): AppThunk => {
  return (dispatch) => {
    dispatch(changeLoadingStatus(LoadingStatuses.Loading));
    axios
      .get(`${config.backend.basePath}/crop_contracts/operations/${operationId}`)
      .then((response) => {
        dispatch(setCropContracts(response.data));
        dispatch(changeLoadingStatus(LoadingStatuses.Idle));
      })
      .catch((error) => {
        console.error('Failed to load crop contracts.', error);
        dispatch(changeLoadingStatus(LoadingStatuses.Failed));
        ErrorToast('Failed to load crop contracts.');
      });
  };
};

export const createCropContractsThunk = (cropContract: ICropContract, callback: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .post(`${config.backend.basePath}/crop_contracts`, cropContract)
      .then((response) => {
        dispatch(addNewCropContract(response.data));
        callback();
      })
      .catch((error) => {
        console.error('Failed to create crop contract.', error);
        ErrorToast('Failed to create crop contract.');
      });
  };
};

export const deleteCropContractsThunk = (data: ICropContract, callback?: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .delete(`${config.backend.basePath}/crop_contracts`, { data })
      .then((response) => {
        dispatch(deleteCropContract(data));
        callback && callback();
      })
      .catch((error) => {
        ErrorToast('Failed to delete crop contract.');
      });
  };
};

export const updateCropContractsThunk = (cropContract: ICropContract, callback: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .patch(`${config.backend.basePath}/crop_contracts`, cropContract)
      .then((response) => {
        dispatch(editCropContract(response.data));
        callback();
      })
      .catch((error) => {
        ErrorToast('Failed to edit crop contract.');
      });
  };
};
