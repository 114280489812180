import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import advanced from 'dayjs/plugin/advancedFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advanced);

export function nowUtc(): Date {
  return dayjs.utc().toDate();
}

export function nowUtcFormatted(): string {
  return dayjs.utc().format();
}

export { Dayjs };
export default dayjs;
