import React from 'react';
import { LotSummary } from '@farmersrisk/shared/features/lots/lotSummaries';
import { formatNumber } from '../../../../utils/formatNumber';
import useCurrentCurrency from '../../../../customHooks/useCurrentCurrency';
import { getLotPnLSnippet } from '../LivestockLotSummaryCard';
import './Livestock.css';

const LivestockLotPnL = ({ ...lot }: LotSummary) => {
  const currency = useCurrentCurrency();

  const soldSummarySnippet = getLotPnLSnippet(lot.soldHeadcount, lot.soldRevenuePerCwt.toNumber());
  const soldSummary = [
    {
      value: 'Sold',
      label: `${soldSummarySnippet} (${formatNumber(lot.soldPercentage, 2)}%)`,
    },
    { value: `${formatNumber(lot.soldProfit, 2, currency)}`, label: 'Profit' },
    { value: `${formatNumber(lot.soldProfitPerHead, 2, currency)}`, label: 'Profit/hd' },
    { value: `${formatNumber(lot.soldProfitPerCwt, 2, currency)}`, label: 'Profit/cwt' },
  ];

  const unsoldSummarySnippet = getLotPnLSnippet(lot.unsoldHeadcount, lot.unsoldProjectedRevenuePerCwt.toNumber());
  const unsoldSummary = [
    {
      value: 'Unsold',
      label: `${unsoldSummarySnippet} (${formatNumber(lot.unsoldPercentage, 2)}%)`,
    },
    { value: `${formatNumber(lot.unsoldProfit, 2, currency)}`, label: 'Profit' },
    { value: `${formatNumber(lot.unsoldProfitPerHead, 2, currency)}`, label: 'Profit/hd' },
    { value: `${formatNumber(lot.unsoldProfitPerCwt, 2, currency)}`, label: 'Profit/cwt' },
  ];

  const lotSummaries = [soldSummary, unsoldSummary];

  return (
    <div className="lot-summary container-fluid text-start p-0 pe-3">
      <div className="row mb-5">
        <h6>
          <strong>{lot.name}</strong>
        </h6>
        <small>
          {lot.initialHeadcount} total head / {formatNumber(lot.targetFinishWeightPerHead.times(lot.initialHeadcount), 0)} lb
        </small>
      </div>
      {lotSummaries.map((lotSummary, index) => (
        <div key={`lot-summary-${index}`} className="lot-pnl row mb-3">
          {lotSummary.map((item, index) => (
            <div key={`lot-summary-item-${index}`} className="col">
              <strong>{item.value}</strong>
              <small>{item.label}</small>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default LivestockLotPnL;
