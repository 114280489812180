import Decimal from 'decimal.js';

export function convertFieldsToDecimal<T extends object>(obj: T): T {
  const copy = { ...obj } as unknown as { [key: string]: null | string | number | Decimal };
  Object.keys(obj).forEach((key) => {
    if (
      key.endsWith('Price') ||
      key.endsWith('Value') ||
      key.endsWith('Percentage') ||
      key.endsWith('Delta') ||
      (key.endsWith('QuantityUOM') && key !== 'soldQuantityUOM' && key !== 'insuranceCoverageQuantityUOM') ||
      key.endsWith('PriceChange') ||
      key.endsWith('ValueChange') ||
      key.endsWith('PercentageChange') ||
      key.endsWith('DeltaChange') ||
      key.endsWith('UOMChange') ||
      key.endsWith('acres') ||
      key.endsWith('Percent') ||
      key.endsWith('UOMPerAcre') ||
      key.endsWith('storageUOM')
    ) {
      const val = copy[key] as string | number | null | Decimal;
      try {
        copy[key] = val === null || val === undefined ? val : new Decimal(val);
      } catch (e) {
        copy[key] = val;
      }
    }
  });

  return copy as unknown as T;
}

export function isDecimal(n: any) {
  return typeof n === 'object' && n instanceof Decimal;
}
/*
let x = {
  accountNumber: '27556048',
  changeInDelta: '0.4555238573788946',
  changeInDeltaHedgeQuantity: '-4.555238573788946',
  changeInDeltaHedgeQuantityUOM: '-22776.19286894473',
  changeInNetValue: '2125',
  contractSizeUOM: 5000,
  cropId: 1,
  currentDelta: '0.4670238573788946',
  currentPrice: '0.25625',
  dataProvider: 3,
  deltaHedgeQuantity: '-4.670238573788946',
  deltaHedgeQuantityUOM: '-23351.19286894473',
  expirationDate: null,
  expirationMonth: 11,
  expirationYear: 2022,
  frSymbol: 'ZC.Z22.C.700',
  futuresMonth: 11,
  futuresYear: 2022,
  instrumentDescription: 'CALL DEC 22 CORN      700',
  instrumentType: 4,
  lastTradeDate: null,
  longQuantity: 5,
  longQuantityUOM: 25000,
  netMarketValue: '-12812.5',
  netPreviousCloseValue: '-14937.5',
  netQuantity: -10,
  netQuantityUOM: -50000,
  netTradePrice: '0.18',
  netTradeValue: '-9000',
  originalDelta: '0.0115',
  originalDeltaHedgeQuantity: '-0.115',
  originalDeltaHedgeQuantityUOM: '-575',
  previousClosePrice: '0.29875',
  productionCycle: '2022',
  realizedGainLossValue: '-1156.25',
  shortQuantity: 15,
  shortQuantityUOM: 75000,
  strikePrice: '7',
  totalGainLossValue: '-4968.75',
  totalTradeAvgPrice: '1028.125',
  totalTradeQuantity: 20,
  totalTradeQuantityUOM: 100000,
  totalTradeValue: '20562.5',
  tradeLongValue: '5781.25',
  tradeShortValue: '14781.25',
  tradingAccountConfigId: 165,
  unrealizedGainLossValue: '-3812.5',
  uom: 'bu',
};
*/
