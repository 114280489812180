import { useCurrentOperationId } from '../customHooks/useCurrentOperation';
import { type ReactQueryOptions, type RouterInputs, type RouterOutputs, trpc } from '../shared/trpc';

type GetCropContractSummariesOptions = ReactQueryOptions['cropContractSummary']['getSummaries'];
type GetCropContractSummariesInput = RouterInputs['cropContractSummary']['getSummaries'];
export type GetCropContractSummariesResult = RouterOutputs['cropContractSummary']['getSummaries'];
export type CropContractSummary = GetCropContractSummariesResult[0];

export function useCropContractSummaries(input: GetCropContractSummariesInput, opts?: GetCropContractSummariesOptions) {
  const currentOperationId = useCurrentOperationId();

  const qry = trpc.cropContractSummary.getSummaries.useQuery(
    {
      ...input,
      operationId: input.operationId || currentOperationId,
    },
    opts
  );
  return qry;
}
