import styles from './TradeModal.module.scss';

import React from 'react';
import Dialog from '../common-next/Dialog/Dialog';
import SteppedDialog, { ChildWithStepControl, SteppedDialogProps } from '../common-next/Dialog/SteppedDialog';
import { BuySellType } from '@farmersrisk/shared/enums';
import { useState, useEffect } from 'react';
import TradeModalStepTwo from './TradeModalStepTwo';
import { CommodityInformation, CommodityId, getTradeableCommodities, COMMODITIES } from '@harvestiq/constants';
import { SelectorTypes } from '../common/SelectWithLabel/SelectWithLabel';
import TradeModalStepOne from './TradeModalStepOne';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TradeModalConfirmation from './TradeModalConfirmation';
import TradeModalFooter from './TradeModalFooter';
import { IdleTimerProvider } from 'react-idle-timer';
import { UXLogger } from '../../loggers/UXLogger';
import { CQGHelpers } from '../CQG/helpers';
import { DialogHandle } from '../common-next/Dialog/Dialog';
import Tooltip from '../common-next/Tooltip/Tooltip';
import { ClientContext } from '@farmersrisk/cqg/cqgClient/CQGClient';
import { useAllHedgingCrops, useAllOperationCrops } from '../../queries/operationCrops';
import { instrumentContractTypeSelections } from '../../shared/positionsSelectors';
import { FRSymbolUtils } from '@harvestiq/symbology';
import useCQGService from '../CQG/useCQGService';
import { useCQGContext } from '../CQG/useCQGContext';
import Loader from '../common/Loader/Loader';
import TradeOrderProvider, { InitialTraderOrderParams, TradeOrderState } from './TradeOrderProvider';
import { getContractSelector } from './helpers';

export interface TradeOrderRouteParams {
  frSymbol: string | undefined;
  buySell: string | undefined;
  contractsQuantity: string | undefined;
}

interface TradeModalProps extends Omit<SteppedDialogProps, 'children' | 'title' | 'closeIcon'> {
  initialStep?: number;
  onClose: () => void;
  timeoutMinutes: number;
  toggleModals: () => void;

  // map to trade params
  frSymbol?: string;
  buySell?: BuySellType;
  contractsQuantity?: number;
}
export interface StepProps extends ChildWithStepControl {
  onClose: () => void;
  isErrorState?: boolean;
  message?: string | null;
  showTradingHistoryModal?: () => void;
}

export default function TradeModal(tradeModalProps: TradeModalProps) {
  const props = {
    // default props
    width: 550,
    ...tradeModalProps,
  };

  const dialogRef = React.useRef<DialogHandle>(null);
  const cqgContext = useCQGContext();

  if (!cqgContext || !cqgContext?.isReady) {
    return (
      <Dialog width={props.width} height={600} title="Place an Order" ref={dialogRef} onClose={props.onClose}>
        <div className="container" style={{ height: '100%' }}>
          <div className="d-flex flex-row align-content-center" style={{ height: '100%' }}>
            <Loader />
          </div>
        </div>
      </Dialog>
    );
  }

  return <TradeModalInner {...props} ref={dialogRef}></TradeModalInner>;
}

const corn = COMMODITIES[CommodityId.CORN];

export function TradeModalInnerWithRef(props: TradeModalProps, ref: React.Ref<DialogHandle>) {
  const { onClose, ...otherProps } = props;
  const cqg = useCQGContext();
  const cqgService = useCQGService();
  const hedgingCropsQuery = useAllHedgingCrops();
  const operationCropsQuery = useAllOperationCrops();
  const operationCrops = operationCropsQuery.data || [];
  const rrHistory = useHistory();
  const location = useLocation();
  const routeParams = useParams<TradeOrderRouteParams | undefined>();
  const [orderVerified, setOrderVerified] = useState(false);
  const dialogRef = ref as React.RefObject<DialogHandle>; // backwards compatibility

  // DERIVED STATE
  const hedgingCrops = hedgingCropsQuery.data || [];
  const defaultCommodity: SelectorTypes<CommodityInformation> = hedgingCrops.length
    ? {
        value: hedgingCrops[0],
        label: hedgingCrops[0]?.name,
      }
    : {
        value: corn,
        label: corn.name,
      };
  const commodityFromRoute = getCommodityFromRoute();
  const defaultSelectedCommmodity = commodityFromRoute || defaultCommodity;
  const initialOrderParams: InitialTraderOrderParams | null = getInitialTradeOrder(routeParams, props);

  const initialOrderState: Partial<TradeOrderState> = {
    ...initialOrderParams,
    selectedCommodity: initialOrderParams?.selectedCommodity || defaultSelectedCommmodity,
    contractsQuantity: initialOrderParams?.contractsQuantity || 1,
    contractSelector: initialOrderParams?.contractSelector,
    instrumentType:
      instrumentContractTypeSelections.find((instrumentType) => instrumentType.value === initialOrderParams?.instrumentType) ||
      instrumentContractTypeSelections[0],
    buySell: initialOrderParams?.buySell
      ? { value: initialOrderParams?.buySell, label: BuySellType[initialOrderParams?.buySell] }
      : null,
    selectedOption: initialOrderParams?.strikePrice
      ? {
          strike: initialOrderParams?.strikePrice || 0,
        }
      : undefined,
  };

  const commonStepProps: StepProps = {
    onClose: clearOrderAndClose,
  };

  // EFFECT HOOKS
  useEffect(() => {
    const currentOrder = cqg?.client()?.getCurrentOrderRequest();
    if (currentOrder) {
      if (cqg?.state.orders[currentOrder.clOrderId]) {
        UXLogger.debug('Verified order'!);
        setOrderVerified(true);
      }
      if (cqg?.state.rejects[currentOrder.requestId]) {
        UXLogger.debug('Verified order'!);
        setOrderVerified(true);
      }
    }
  }, [cqg?.state.orders, cqg?.state.rejects]);

  // should this kill any existing 'logOffAndClose' calls if they are queued?
  useEffect(() => {
    cqg?.client()?.setContext(ClientContext.TRADE);
    void CQGHelpers.subscribeToTrade({ client: cqg?.client(), resetClient: cqgService?.resetClient });
    return () => {
      void CQGHelpers.logoffAndClose(cqg?.client(), ClientContext.TRADE, { removeListeners: false });
    };
  }, []);

  return (
    <IdleTimerProvider timeout={1000 * 60 * props.timeoutMinutes} events={[]} onIdle={clearOrderAndClose}>
      <Tooltip>
        <TradeOrderProvider {...initialOrderState}>
          <SteppedDialog
            title={orderVerified ? 'Trade Order Placed' : 'Place an Order'}
            className={`${styles['futures-trade-modal-wrapper']}`}
            actionsBarPosition="both"
            onStepChange={onStepChange}
            footer={<TradeModalFooter />}
            onClose={clearOrderAndClose}
            initialStep={props.initialStep || 1}
            {...otherProps}
          >
            <TradeModalStepOne key={1} {...commonStepProps} showTradingHistoryModal={props.toggleModals} />
            <TradeModalStepTwo key={2} {...commonStepProps} orderVerified={orderVerified} />
            <TradeModalConfirmation key={3} {...commonStepProps} showTradingHistoryModal={props.toggleModals} />
          </SteppedDialog>
        </TradeOrderProvider>
      </Tooltip>
    </IdleTimerProvider>
  );

  // ====================================================================================
  // HELPERS

  function onStepChange(currentStep: number, stepToChangeTo: number) {
    // scroll to top between state changes
    try {
      dialogRef?.current?.element?.querySelector('.k-dialog-content')?.scrollTo(0, 0);
    } catch (e) {
      console.error('Error scrolling to top of trade modal', e);
    }

    if (currentStep === 3 && stepToChangeTo === 1) {
      const pathname = location.pathname;
      const rootPath = pathname.replace('/trade', '');
      const newPath = `/${rootPath}/trade`.replace('//', '/'); // rootPath should have a leading /
      rrHistory.replace(newPath);
      setOrderVerified(false);
    } else if (stepToChangeTo % 3 === 1) {
      setOrderVerified(false);
    }
  }

  function getCommodityFromRoute(): SelectorTypes<CommodityInformation> | null {
    if (!operationCrops.length) {
      return null;
    }
    const pathname = location.pathname;
    const matchingOperationCrop = operationCrops.find(
      (oc) => pathname.includes(`/${oc.id}`) || pathname.includes(`/${oc.name?.toLowerCase()}`)
    );
    const matchingCrop = hedgingCrops.find((c) => c.id === matchingOperationCrop?.hedgingCropType);
    return matchingCrop ? { value: matchingCrop, label: matchingCrop.name } : null;
  }

  function getInitialTradeOrder(routeParams?: TradeOrderRouteParams, opts?: Partial<InitialTraderOrderParams>) {
    // TODO: make it so we can pass aribtrary SymbolParams here and it will work
    // Right now frSymbol means you basically need to know exactly what you're trading
    if (!routeParams?.frSymbol && !opts?.frSymbol) {
      return null;
    }

    const frSymbol = routeParams?.frSymbol || opts?.frSymbol;
    const symbolParams = FRSymbolUtils.getSymbolParameters(frSymbol ?? '');
    let buySellType;
    if (routeParams?.buySell || opts?.buySell) {
      buySellType = Number(routeParams?.buySell || opts?.buySell) as BuySellType;
    }

    let strikePrice;
    if (symbolParams.strikePrice || opts?.strikePrice) {
      strikePrice = Number(symbolParams.strikePrice || opts?.strikePrice);
    }

    const commodity = getTradeableCommodities().find((c) => c.id === symbolParams.commodityId);
    if (!commodity) {
      throw new Error(`Could not find commodityId ${symbolParams.commodityId} in hedging crop list`);
    }

    const initialOrder: InitialTraderOrderParams = {
      ...opts,
      selectedCommodity: { value: commodity, label: commodity?.name },
      contractsQuantity: Number(routeParams?.contractsQuantity || opts?.contractsQuantity || 1),
      frSymbol,
      instrumentType: symbolParams.instrumentType,
      buySell: buySellType ?? undefined,
      contractSelector: getContractSelector(symbolParams.contractMonth, symbolParams.contractYear),
      strikePrice,
    };

    return initialOrder;
  }

  function clearOrderAndClose() {
    onClose && onClose();
    cqg?.client()?.clearCurrentOrderRequest();
  }
}

const TradeModalInner = React.forwardRef(TradeModalInnerWithRef);
