import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { isValidSymbol } from '../../models/CropSymbols/cropSymbols';
import { IQuote } from '../../types/IQuote';

export const fetchQuotes = createAsyncThunk('quotes/fetchQuotes', async (symbols: string[]) => {
  if (!symbols || !symbols.length) {
    return [];
  }

  // TODO: see if we can go all the way and validate the actual symbol...
  const validSymbols = symbols.filter((x) => isValidSymbol(x));

  if (!validSymbols.length) {
    return [];
  }

  const response = await axios.get<IQuote[]>(`${config.backend.basePath}/market_data/quotes`, {
    params: {
      symbols: validSymbols.join(','),
      fields: 'previousClose',
    },
  });

  // ensure that all symbols requested have a result, or else we can get a runaway request loop
  const result = validSymbols.map((s) => response.data.find((x) => x.symbol === s) || ({ symbol: s } as IQuote));

  return result;
});
