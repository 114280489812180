import { ReactQueryOptions, RouterInputs, trpc } from '../shared/trpc';

type GetLotsInput = RouterInputs['lots']['get'];
type GetLotsOpts = ReactQueryOptions['lots']['get'];
export const useGetLots = (input?: GetLotsInput, options?: GetLotsOpts) => trpc.lots.get.useQuery(input ?? {}, options);

type GetLotsOverviewInput = RouterInputs['lots']['getOverview'];
type GetLotsOverviewOpts = ReactQueryOptions['lots']['getOverview'];
export const useGetLotsOverview = (input?: GetLotsOverviewInput, options?: GetLotsOverviewOpts) =>
  trpc.lots.getOverview.useQuery(input ?? {}, options);

type GetLotSummariesInput = RouterInputs['lots']['getSummaries'];
type GetLotSummariesOpts = ReactQueryOptions['lots']['getSummaries'];
export const useGetLotSummaries = (input?: GetLotSummariesInput, options?: GetLotSummariesOpts) =>
  // EB-TODO: fix unknown and add options arg
  trpc.lots.getSummaries.useQuery(input ?? {}, options);

export const useCreateLot = () => {
  const getLotsQuery = trpc.useContext().lots.get;
  const getLotsOverviewQuery = trpc.useContext().lots.getOverview;

  return trpc.lots.create.useMutation({
    onSuccess: async () => {
      await getLotsQuery.invalidate();
      await getLotsOverviewQuery.invalidate();
    },
  });
};

export const useUpdateLot = () => {
  const getLotsQuery = trpc.useContext().lots.get;
  const getLotsOverviewQuery = trpc.useContext().lots.getOverview;
  const getLotSummariesQuery = trpc.useContext().lots.getSummaries;

  return trpc.lots.update.useMutation({
    onSuccess: async () => {
      await getLotsQuery.invalidate();
      await getLotsOverviewQuery.invalidate();
      await getLotSummariesQuery.invalidate(); // query is used in lot details, invalidate on success update
    },
  });
};

export const useDeleteLot = () => {
  const getLotsQuery = trpc.useContext().lots.get;
  const getLotsOverviewQuery = trpc.useContext().lots.getOverview;

  return trpc.lots.remove.useMutation({
    onSuccess: async () => {
      await getLotsQuery.invalidate();
      await getLotsOverviewQuery.invalidate();
    },
  });
};

type GetLotTradesInput = RouterInputs['lots']['getTrades'];
type GetLotTradesOpts = ReactQueryOptions['lots']['getTrades'];
export const useGetLotTrades = (input: GetLotTradesInput, options?: GetLotTradesOpts) =>
  trpc.lots.getTrades.useQuery(input, options);
