import React from 'react';
import './Modal.css';
import { useRootClose } from 'react-overlays';

export default function Modal(props: ModalProps) {
  const {
    show,
    setShow,
    modalRef,
    title,
    content,
    okButtonName = 'Save',
    cancelButtonName = 'Cancel',
    handleOkClick,
    handleCancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setShow(false);
    },
    okButtonDisabled = false,
  } = props;
  const handleRootClose = () => setShow(false);

  useRootClose(modalRef, handleRootClose, {
    disabled: !show,
    clickTrigger: 'mousedown',
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleOkClick(event);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-wrapper">
      <div ref={modalRef} className="fr-modal">
        <div className="modal-header">
          <label>{title}</label>
          <button onClick={() => setShow(false)}>
            <img src="/svgIcons/close.svg" alt="Close" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-content">{content}</div>
          <div className="fr-modal-footer">
            <button type="submit" disabled={okButtonDisabled} className="fr-button backGround-red">
              {okButtonName}
            </button>
            <button type="button" className="fr-button backGround-white" onClick={handleCancelClick}>
              {cancelButtonName}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

type ModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  modalRef: any;
  title: string;
  content: string | React.ReactNode;
  okButtonName?: string;
  cancelButtonName?: string;
  handleOkClick: (e: React.FormEvent) => void;
  handleCancelClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  okButtonDisabled?: boolean;
};
