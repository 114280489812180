import React from 'react';
import { formatNumber } from '../../utils/formatNumber';
import { TradeOrderContext } from './TradeOrderProvider';
import { InstrumentType } from '@harvestiq/constants';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import { OrderDurationType, orderTypeLabelMap } from '../../models/orderTypes';

export interface TradeModalOrderSummaryTextProps {
  orderPlaced: boolean;
  orderContext: Pick<
    TradeOrderContext,
    'orderType' | 'instrumentType' | 'buySell' | 'contractSelector' | 'selectedCommodity' | 'selectedOption' | 'orderDurationType'
  >;
}

export default function TradeModalOrderSummaryText(props: TradeModalOrderSummaryTextProps): JSX.Element {
  const { orderPlaced, orderContext } = props;
  const currency = useCurrentCurrency();

  const prefix = orderPlaced ? 'You placed a' : 'You are placing a';
  const orderTypeLabel = orderTypeLabelMap[orderContext.orderType?.value] ?? '';
  const instrumentTypeLabel = orderContext.instrumentType?.label ?? '';
  const buySellLabel = orderContext.buySell?.label ?? '';
  const contractMonthAndYearLabel = orderContext.contractSelector?.label ?? '';
  const commodity = orderContext.selectedCommodity?.value;
  const tradingCode = commodity?.tradingCode ?? '';
  const hasStrike = orderContext.instrumentType?.value !== InstrumentType.Futures;
  const displayStrike = hasStrike ? formatNumber(orderContext.selectedOption?.strike, 2, currency) : '';
  const strikePriceInfo = hasStrike ? ` at a Strike Price of ${displayStrike}` : '';

  let durationLabel = null;
  if (orderContext.orderDurationType?.value === OrderDurationType.Day) {
    durationLabel = (
      <>
        {' '}
        for the <b>{orderContext.orderDurationType.label.toUpperCase()}</b>{' '}
      </>
    );
  }
  if (orderContext.orderDurationType?.value === OrderDurationType.GoodTillCancel) {
    durationLabel = (
      <>
        {' '}
        that is <b>{orderContext.orderDurationType.label.toUpperCase()}</b>{' '}
      </>
    );
  }

  return (
    <>
      {prefix} <b>{orderTypeLabel.toUpperCase()}</b>
      {durationLabel} to{' '}
      <b>
        {buySellLabel.toUpperCase()} {instrumentTypeLabel.toUpperCase()}S
      </b>{' '}
      for:
      <br />
      {contractMonthAndYearLabel} {commodity?.name.toUpperCase()} ({tradingCode}){strikePriceInfo}
    </>
  );
}
