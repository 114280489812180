import { AppThunk } from '../../types/Store';
import axios from 'axios';
import config from '../../config';
import { addAccountMemberAction, getAccountMembersAction, removeAccountMemberAction, updateAccountMemberAction } from './actions';
import { IRole } from '../../types/IRole';
import { OperationMember } from '../../types/OperationMember';
import { setInviteAccountMemberError } from '../Error/actions';
import ErrorToast from '../../components/Toast/ErrorToast';
import { updateUsersRole } from '../User/actions';

export const getOperationMemberThunk = (operationId: number, finishLoading: any): AppThunk => {
  return (dispatch) => {
    axios
      .get(`${config.backend.basePath}/operations/${operationId}/users`)
      .then((response) => {
        dispatch(getAccountMembersAction(response.data));
        finishLoading();
      })
      .catch((error) => {
        ErrorToast('Failed to load operation members.');
        finishLoading();
      });
  };
};

export const updateOperationMemberRoleThunk = (roleId: number, role: IRole): AppThunk => {
  return (dispatch) => {
    axios
      .patch(`${config.backend.basePath}/roles/${role.id}`, { ...role })
      .then((response) => dispatch(updateAccountMemberAction({ id: roleId, role: response.data })))
      .catch((error) => ErrorToast("Failed to update member's role."));
  };
};

export const updateOperationMemberRoleConfigThunk = (role: IRole, callback?: () => void): AppThunk => {
  return (dispatch) => {
    axios
      .patch(`${config.backend.basePath}/roles/${role.id}/config`, { ...role })
      .then((response) => {
        dispatch(updateAccountMemberAction({ id: role.id, role: response.data }));
        dispatch(updateUsersRole(response.data));
        callback && callback();
      })
      .catch((error) => ErrorToast("Failed to update member's role."));
  };
};

export const removeOperationMemberRoleThunk = (roleId: number): AppThunk => {
  return (dispatch) => {
    axios
      .delete(`${config.backend.basePath}/roles/${roleId}`)
      .then((response) => dispatch(removeAccountMemberAction(roleId)))
      .catch((error) => 'Failed to remove a member from this operation.');
  };
};

export const addOperationMemberThunk = (operationId: number, newAccountMember: OperationMember, closeModal: any): AppThunk => {
  return (dispatch) => {
    axios
      .post(`${config.backend.basePath}/operations/${operationId}/users`, { ...newAccountMember })
      .then((response) => {
        dispatch(addAccountMemberAction(response.data));
        closeModal();
      })
      .catch((error) => dispatch(setInviteAccountMemberError(error.response.data.errors)));
  };
};
