import styles from './NumericTextBox.module.scss';
import React, { ForwardedRef, RefObject, useEffect } from 'react';
import {
  NumericTextBox as KNumericTextBox,
  NumericTextBoxChangeEvent as KNumericTextBoxChangeEvent,
  NumericTextBoxProps as KNumericTextBoxProps,
  NumericTextBoxHandle as KNumericTextBoxHandle,
} from '@progress/kendo-react-inputs';

export interface NumericTextboxHandle extends KNumericTextBoxHandle {}

export interface NumericTextBoxChangeEvent extends KNumericTextBoxChangeEvent {}
interface NumericTextBoxProps extends KNumericTextBoxProps {
  stretch?: boolean;
  enableScrollToChange?: boolean;
}

function NumericTextBox(props: NumericTextBoxProps, ref: ForwardedRef<NumericTextboxHandle>) {
  const { className, stretch = false, enableScrollToChange = false, ...otherProps } = props;
  ref = ref || React.createRef<NumericTextboxHandle>();

  const disableWheel = (e: WheelEvent) => {
    e?.stopPropagation();
  };
  useEffect(() => {
    const current = (ref as RefObject<NumericTextboxHandle>)?.current
      ? (ref as RefObject<NumericTextboxHandle> | null)?.current
      : null;
    if (current && !enableScrollToChange) {
      current.element?.addEventListener('wheel', disableWheel);
      return () => {
        current?.element?.removeEventListener('wheel', disableWheel);
      };
    }
  }, []);

  const classes = [styles['fr-numerictextbox'], stretch ? 'fr-dropdown-stretch' : '', className].filter(Boolean).join(' ');
  return (
    <div className={classes}>
      <KNumericTextBox ref={ref} {...otherProps} />
    </div>
  );
}
export default React.forwardRef(NumericTextBox);
