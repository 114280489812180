import axios from 'axios';
import config from '../config';
import { ITradingAccountConfig } from '../types/ITradingAccountConfig';
import { DataProvider } from '@farmersrisk/shared/constants/DataProvider';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from './QueryKey';

interface CQGCredentials {
  username: string;
  password: string;
  accountNumbers: string[];
  operationId: number;
}

const fetch = async (operationId: number): Promise<CQGCredentials | null> => {
  const resp = await axios.get<ITradingAccountConfig[]>(
    `${config.backend.basePath}/trading_account_configs/operation/${operationId}/trading_credentials`
  );
  const stoneXConfigs = resp.data.filter((account) => account.dataProvider === DataProvider.StoneX);
  const cqgCredentials = stoneXConfigs.find((x) => x.accountNumbers && x.accountNumbers.some((x) => x.startsWith('PRG')));
  if (!cqgCredentials) {
    return null;
  }
  return {
    username: (cqgCredentials.additionalFields.tradingUsername || '') as string,
    password: (cqgCredentials.additionalFields.tradingPassword || '') as string,
    accountNumbers: cqgCredentials.accountNumbers || [],
    operationId: cqgCredentials.operationId,
  };
};

async function fetchCredentials(operationId: number): Promise<CQGCredentials | null> {
  if (config.env === 'development' && config.trading.username && config.trading.password) {
    return Promise.resolve({
      username: config.trading.username,
      password: config.trading.password,
      accountNumbers: ['PRG00123'],
      operationId: operationId,
    });
  }
  return fetch(operationId);
}

export function useTradingCredentials({ operationId, enabled = false }: { operationId: number; enabled?: boolean }) {
  const queryKey = [QueryKey.TradingCredentials, operationId];
  const query = useQuery(queryKey, () => fetchCredentials(operationId), {
    enabled: !!operationId && enabled,
  });
  return query;
}
