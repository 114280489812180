export enum InstrumentType {
  Futures = 1,
  Put = 2,
  Call = 4,
  ShortDatedPut = 5,
  ShortDatedCall = 6,
  Week1Put = 7,
  Week1Call = 8,
  Week2Put = 9,
  Week2Call = 10,
  Week3Put = 11,
  Week3Call = 12,
  Week4Put = 13,
  Week4Call = 14,
  Week5Put = 15,
  Week5Call = 16,
  SerialPut = 17,
  SerialCall = 18,
  NewCropWeek1Put = 19,
  NewCropWeek1Call = 20,
  NewCropWeek2Put = 21,
  NewCropWeek2Call = 22,
  NewCropWeek3Put = 23,
  NewCropWeek3Call = 24,
  NewCropWeek4Put = 25,
  NewCropWeek4Call = 26,
  NewCropWeek5Put = 27,
  NewCropWeek5Call = 28,
}

export function isPut(instrumentType: InstrumentType) {
  return getInstrumentTypeDisplayName(instrumentType)
    .toLowerCase()
    .includes('put');
}

export function isCall(instrumentType: InstrumentType) {
  return getInstrumentTypeDisplayName(instrumentType)
    .toLowerCase()
    .includes('call');
}

export function isNewCropSpecialOption(instrumentType: InstrumentType) {
  return [
    InstrumentType.ShortDatedCall,
    InstrumentType.ShortDatedPut,
    InstrumentType.NewCropWeek1Call,
    InstrumentType.NewCropWeek1Put,
    InstrumentType.NewCropWeek2Call,
    InstrumentType.NewCropWeek2Put,
    InstrumentType.NewCropWeek3Call,
    InstrumentType.NewCropWeek3Put,
    InstrumentType.NewCropWeek4Call,
    InstrumentType.NewCropWeek4Put,
    InstrumentType.NewCropWeek5Call,
    InstrumentType.NewCropWeek5Put,
  ].includes(instrumentType);
}

export function getInstrumentTypeDisplayName(instrumentType: InstrumentType) {
  switch (instrumentType) {
    case InstrumentType.Futures:
      return 'Futures';
    case InstrumentType.Put:
      return 'Put';
    case InstrumentType.Call:
      return 'Call';
    case InstrumentType.ShortDatedPut:
      return 'Short Dated Put';
    case InstrumentType.ShortDatedCall:
      return 'Short Dated Call';
    case InstrumentType.Week1Put:
      return 'Week 1 Put';
    case InstrumentType.Week1Call:
      return 'Week 1 Call';
    case InstrumentType.Week2Put:
      return 'Week 2 Put';
    case InstrumentType.Week2Call:
      return 'Week 2 Call';
    case InstrumentType.Week3Put:
      return 'Week 3 Put';
    case InstrumentType.Week3Call:
      return 'Week 3 Call';
    case InstrumentType.Week4Put:
      return 'Week 4 Put';
    case InstrumentType.Week4Call:
      return 'Week 4 Call';
    case InstrumentType.Week5Put:
      return 'Week 5 Put';
    case InstrumentType.Week5Call:
      return 'Week 5 Call';
    case InstrumentType.SerialPut:
      return 'Serial Put';
    case InstrumentType.SerialCall:
      return 'Serial Call';
    case InstrumentType.NewCropWeek1Put:
      return 'New Crop Week 1 Put';
    case InstrumentType.NewCropWeek1Call:
      return 'New Crop Week 1 Call';
    case InstrumentType.NewCropWeek2Put:
      return 'New Crop Week 2 Put';
    case InstrumentType.NewCropWeek2Call:
      return 'New Crop Week 2 Call';
    case InstrumentType.NewCropWeek3Put:
      return 'New Crop Week 3 Put';
    case InstrumentType.NewCropWeek3Call:
      return 'New Crop Week 3 Call';
    case InstrumentType.NewCropWeek4Put:
      return 'New Crop Week 4 Put';
    case InstrumentType.NewCropWeek4Call:
      return 'New Crop Week 4 Call';
    case InstrumentType.NewCropWeek5Put:
      return 'New Crop Week 5 Put';
    case InstrumentType.NewCropWeek5Call:
      return 'New Crop Week 5 Call';
    default:
      return '';
  }
}
