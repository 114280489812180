/**
 * This file is intended to slowly replace all the direct
 * axios calls so that we have better interception and control
 * over the interaction with our backend API and authentication
 * mechanisms.
 */

import axios from 'axios';
import config from '../config';
import { history } from './history';
import { getImpersonatingConfig, getStoredLastVisitedOperationId } from './storageService';

// just an alias for now, but down the road we can add member functions
const apiClient = axios;

// hack to keep a reference to Auth0's `getAccessTokenSilently`
// function, which is currently only available inside hooks
let _getAccessTokenSilently: () => Promise<string>;
export function axiosInit(getAccessTokenSilently: () => Promise<string>) {
  _getAccessTokenSilently = getAccessTokenSilently;
}

axios.interceptors.request.use(
  async (requestConfig) => {
    const headers = requestConfig.headers ?? {};

    const backendBasePath = config.backend.basePath;
    if (requestConfig.url?.startsWith(backendBasePath)) {
      const currentOperationId = getStoredLastVisitedOperationId();
      if (currentOperationId) {
        headers['x-current-operation-id'] = currentOperationId;
      }

      const impersonatingConfig = getImpersonatingConfig();
      if (impersonatingConfig && impersonatingConfig.isImpersonating && impersonatingConfig.auth0Id) {
        // DEPRECATED: use the userId header instead
        headers['ImpersonatedUser'] = impersonatingConfig.auth0Id;
      }
      if (impersonatingConfig && impersonatingConfig.isImpersonating && impersonatingConfig.userId) {
        headers['x-impersonated-user-id'] = impersonatingConfig.userId;
      }
    }

    if (_getAccessTokenSilently) {
      const freshToken = await _getAccessTokenSilently();
      if (freshToken) {
        headers['Authorization'] = `Bearer ${freshToken}`;
      }
    }
    requestConfig.headers = headers;
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((res) => {
  if (res.status === 401) {
    history.push('/logout');
  }
  return res;
});

export default apiClient;
