import * as React from 'react';
import { useCQGContext } from '../CQG/useCQGContext';
import TradeModalFooter from '../TradeModal/TradeModalFooter';
import Dialog, { DialogHandle } from '../common-next/Dialog/Dialog';
import Tooltip from '../common-next/Tooltip/Tooltip';
import styles from './TradeActivityModal.module.scss';
import TradeActivityModalContent from './TradeActivityModalContent';

import Loader from '../common/Loader/Loader';
import useCQGService from '../CQG/useCQGService';

interface TradeActivityModalProps {
  width?: number;
  onClose: () => void;
  timeoutMinutes: number;
  toggleModals: () => void;
}

export default function TradeActivityModal(props: TradeActivityModalProps) {
  const cqgContext = useCQGContext();
  const cqgService = useCQGService();
  const dialogRef = React.useRef<DialogHandle>(null);

  if (!cqgContext || !cqgContext?.isReady || !cqgService) {
    return (
      <Dialog width={props.width} height={300} title="Place an Order" ref={dialogRef} onClose={props.onClose}>
        <div className="container" style={{ height: '100%' }}>
          <div className="d-flex flex-row align-content-center" style={{ height: '100%' }}>
            <Loader />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      title={'Trade Activity'}
      className={`${styles['trade-activity-modal-wrapper']}`}
      actionsBarPosition="both"
      ref={dialogRef}
      footer={<TradeModalFooter />}
      {...props}
    >
      <Tooltip>
        <TradeActivityModalContent
          toggleModals={props.toggleModals}
          onClose={props.onClose}
          cqgContext={cqgContext}
          cqgService={cqgService}
        />
      </Tooltip>
    </Dialog>
  );
}
