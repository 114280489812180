import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../types/Store';
import { BudgetType } from '../../../types/IBudget';

export type CostsPageType = {
  budgetType: BudgetType | null;
};

const initialState: CostsPageType = {
  budgetType: null,
};

const slice = createSlice({
  name: 'costsPage',
  initialState,
  reducers: {
    updateCostsPageConfig: (state, action: PayloadAction<CostsPageType>) => ({ ...state, ...action.payload }),
    updateCostsPageBudgetType: (state, action: PayloadAction<BudgetType | null>) => ({ ...state, budgetType: action.payload }),
  },
});

export const { updateCostsPageConfig, updateCostsPageBudgetType } = slice.actions;

export default slice.reducer;

export const selectBudgetType = (state: RootState) => state.costsPage.budgetType;
