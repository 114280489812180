import styles from './DropDownList.module.scss';

import React from 'react';
import {
  DropDownList as KDropDownList,
  DropDownListChangeEvent as KDropDownListChangeEvent,
  DropDownListProps as KDropDownListProps,
} from '@progress/kendo-react-dropdowns';
import { ComponentWithElement } from '..';
import Hint from '../Hint/Hint';

export interface DropDownListChangeEvent extends KDropDownListChangeEvent {}

export interface DropDownListProps extends KDropDownListProps {
  stretch?: boolean;
  hint?: JSX.Element;
}

function DropDownListWithRef(props: DropDownListProps, ref: React.Ref<ComponentWithElement>) {
  // FR-396, TODO: try to complete z-fix workaround logic for dialogs
  // const defaultPopupSettings: DropDownsPopupSettings = {
  //   popupClass: styles['fr-dropdown-z-fix']
  // };
  const { className, stretch = false, popupSettings = {}, hint = null, ...otherProps } = props;
  return (
    <div className={`${styles['fr-dropdown']} ${stretch ? 'fr-dropdown-stretch' : ''} ${className}`}>
      <KDropDownList ref={ref} popupSettings={popupSettings} {...otherProps} />
      {hint && (
        <Hint subtle className="ps-2">
          {hint}
        </Hint>
      )}
    </div>
  );
}

const DropDownList = React.forwardRef(DropDownListWithRef);
export default DropDownList;
