import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { IGrainElevator } from '../CashBids/CashBidsSlice';

export const sendFetchElevatorInfoRequest = (params: string) => {
  return axios.get<IGrainElevator[]>(`${config.backend.basePath}/market_data/get_grain_bids?location=${params}`);
};

export const fetchElevatorInfo = createAsyncThunk(
  'elevators/fetchElevatorInfo',
  async (params: string): Promise<IGrainElevator | undefined> => {
    const response = await sendFetchElevatorInfoRequest(params);
    return response.data[0];
  }
);
