import { FuturesContractType, PositionType, StrategyStatus, IPosition, OptionContractType } from '../types/IPosition';
import { CommodityInformation } from '@harvestiq/constants';
import { PriceByTradingCode } from './MyPositions/myPositions';
import { subtract } from 'lodash';
import { getContractTradingCode } from './CropSymbols/cropSymbols';

interface IParamsForGainLoss {
  strikePrice: number;
  spotPrice: number;
  premiumCost: number;
}

export function getGainLossPerBushelByTypeAndProductionCycle(
  leg: IPosition,
  crop: CommodityInformation,
  spotPrices: PriceByTradingCode
) {
  const defaultValue = 0;
  const legTradingCode = getContractTradingCode(crop, leg.futuresYear, leg.futuresMonth);
  const spotPrice = spotPrices[legTradingCode] || 0;

  if (leg.status === StrategyStatus.Closed || leg.status === StrategyStatus.Expired) {
    return Number(leg.gainLoss) / Number(leg.quantitySold) || 0;
  }

  switch (leg.type) {
    case PositionType.Option:
      return getOptionGainLossPerBushelByContractType(leg, spotPrice);
    case PositionType.Futures:
      return getFuturesGainLossPerBushelByContractType(leg, spotPrice);
    default:
      return defaultValue;
  }
}

export function getOptionGainLossPerBushelByContractType(leg: IPosition, spotPrice: number): number {
  const strikePrice = Number(leg.strikePrice || 0);
  const premiumCost = Number(leg.premiumCost || 0);
  const paramsForGainLossCalculation: IParamsForGainLoss = {
    strikePrice,
    spotPrice,
    premiumCost,
  };

  switch (leg.contractType) {
    case OptionContractType.BuyCall:
      return getGainLossPerBushelForLongCall(paramsForGainLossCalculation);
    case OptionContractType.SellCall:
      return getGainLossPerBushelForShortCall(paramsForGainLossCalculation);
    case OptionContractType.BuyPut:
      return getGainLossPerBushelForLongPut(paramsForGainLossCalculation);
    case OptionContractType.SellPut:
      return getGainLossPerBushelForShortPut(paramsForGainLossCalculation);
    default:
      return 0;
  }
}

export function getFuturesGainLossPerBushelByContractType(leg: IPosition, spotPrice: number): number {
  let result = 0;
  const futuresPrice = Number(leg.futuresPrice || 0);

  switch (leg.contractType) {
    case FuturesContractType.Sell:
      result = subtract(futuresPrice, spotPrice);
      break;
    case FuturesContractType.Buy:
      result = subtract(spotPrice, futuresPrice);
      break;
  }

  return result;
}

export function getGainLossPerBushelForLongCall(params: IParamsForGainLoss): number {
  const { strikePrice, spotPrice, premiumCost } = params;
  let result = 0;

  if (spotPrice > strikePrice) {
    result = spotPrice - strikePrice - premiumCost;
  } else {
    result = -premiumCost;
  }

  return result;
}

export function getGainLossPerBushelForShortCall(params: IParamsForGainLoss): number {
  const { strikePrice, spotPrice, premiumCost } = params;
  let result = 0;

  if (spotPrice > strikePrice) {
    result = -(spotPrice - strikePrice - premiumCost);
  } else {
    result = premiumCost;
  }

  return result;
}

export function getGainLossPerBushelForLongPut(params: IParamsForGainLoss): number {
  const { strikePrice, spotPrice, premiumCost } = params;
  let result = 0;

  if (spotPrice > strikePrice) {
    result = -premiumCost;
  } else {
    result = strikePrice - spotPrice - premiumCost;
  }

  return result;
}

export function getGainLossPerBushelForShortPut(params: IParamsForGainLoss): number {
  const { strikePrice, spotPrice, premiumCost } = params;
  let result = 0;

  if (spotPrice > strikePrice) {
    result = premiumCost;
  } else {
    result = -(strikePrice - spotPrice - premiumCost);
  }

  return result;
}
