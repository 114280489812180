import useCurrentRole from './useCurrentRole';
import { RoleType } from '@farmersrisk/shared/constants/Roles';
import PermissionsValidator, { PermissionRole } from '@farmersrisk/shared/services/PermissionsValidator';
import { isNil } from 'lodash';

export default function usePermission(expectedRoleType: RoleType = RoleType.Editor): boolean {
  const currentRole = useCurrentRole();
  if (!currentRole || isNil(currentRole.roleType)) {
    return false;
  }
  return PermissionsValidator.isAtLeast(currentRole as PermissionRole, expectedRoleType);
}
