import { useQuery } from '@tanstack/react-query';
import { IQuote } from '../types/IQuote';
import apiClient from '../shared/apiClient';
import config from '../config';
import { QueryKey } from './QueryKey';
import { isNil } from 'lodash';
import { COMMODITIES, CommodityId, getCommodityByCommodityId } from '@harvestiq/constants';
import React from 'react';
import { Decimal } from 'decimal.js';
import dayjs from 'dayjs';
import { barchartSymbolUtils } from '@harvestiq/symbology';
import { FRSymbolUtils } from '@harvestiq/symbology';

interface IQuoteOptions {
  barchartSymbol?: string | null;
  enabled?: boolean;
  convertFields?: boolean;
  refetchInterval?: number | false;
}

const fetchBarchartRealtimePrice = async (
  barchartSymbol: string | undefined,
  realtimeDesired = false
): Promise<IQuote | null> => {
  if (isNil(barchartSymbol)) {
    return Promise.resolve(null);
  }

  const realtime =
    barchartSymbol?.substring(0, 2) === COMMODITIES[CommodityId.MN_SPRING_WHEAT_HRSW].tradingCode ? false : realtimeDesired;
  const response = await apiClient.get(`${config.backend.basePath}/market_data/quotes`, {
    params: {
      symbols: barchartSymbol,
      realtime,
      fields: 'previousClose,premium,bid,ask,openInterest',
    },
  });
  return response.data[0] ?? null;
};

const getValidatedSymbol = (options: IQuoteOptions): { isValid: boolean; barchartSymbol?: string } => {
  const barchartSymbol = isNil(options.barchartSymbol) ? undefined : options.barchartSymbol;
  return { isValid: true, barchartSymbol };
};

type targetDecimalProps = Pick<IQuote, 'lastPrice' | 'bid' | 'ask' | 'open' | 'high' | 'low' | 'close' | 'previousClose'>;
type targetDecimalKeysType = keyof targetDecimalProps;
const targetDecimalKeys: Array<targetDecimalKeysType> = [
  'lastPrice',
  'bid',
  'ask',
  'open',
  'high',
  'low',
  'close',
  'previousClose',
];

// TODO: move this to backend TRPC logic
function convert(obj: IQuote | null): IQuote | null {
  if (isNil(obj)) {
    return null;
  }

  const frSymbol = barchartSymbolUtils.getFRSymbol(obj.symbol);
  if (!frSymbol) {
    return null;
  }

  const symbolParams = FRSymbolUtils.getSymbolParameters(frSymbol);

  const commodity = getCommodityByCommodityId(symbolParams.commodityId);

  const copy = { ...obj } as IQuote;

  for (const key of targetDecimalKeys) {
    // decimal
    const val = copy[key];
    try {
      if (!isNil(val)) {
        const converted = new Decimal(val).dividedBy(commodity.barchartPriceFactor).toNumber();
        copy[key] = converted;
      }
    } catch (e) {
      if (val !== undefined) {
        copy[key] = val;
      }
    }
  }
  try {
    if (!isNil(copy.serverTimestamp)) {
      copy.serverTimestamp = dayjs(copy.serverTimestamp).toDate();
    }
  } catch (e) {
    copy.serverTimestamp = obj.serverTimestamp;
  }

  return copy;
}

export function useRealtimeQuote(options: IQuoteOptions) {
  let enabled = options.enabled ?? true;
  if (isNil(options.barchartSymbol)) {
    enabled = false;
  }
  let refetchInterval = config.trading.realtimePricingEnabled === true ? config.trading.realtimePricingUpdateIntervalMs : false;
  if (!enabled) {
    refetchInterval = false;
  }
  const query = useQuery(
    [QueryKey.RealtimePrice, options.barchartSymbol],
    () => {
      const { isValid, barchartSymbol } = getValidatedSymbol(options);
      if (!isValid) {
        return Promise.resolve(null);
      }
      return fetchBarchartRealtimePrice(barchartSymbol, config.trading.realtimePricingEnabled);
    },
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: options.refetchInterval ?? refetchInterval,
      select: React.useCallback((data) => {
        return options.convertFields === true ? convert(data) : data;
      }, []),
    }
  );
  return query;
}
