import React from 'react';
import './Selector.css';
import Select, { components } from 'react-select';
import { FontAwesomeIcon, IconDefinition, faCaretDown } from '../../faIcons';

export type SelectProps = {
  options: any;
  selected: any;
  onChange?: any;
  additionalContainerStyles?: any;
  className?: string;
  id?: string;
};

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon={faCaretDown as IconDefinition} />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

export default function Selector({ options, selected, onChange, additionalContainerStyles, ...rest }: SelectProps) {
  const customStyles: any = {
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    container: (styles: any) => ({
      ...styles,
      ...additionalContainerStyles,
      width: 'inherit',
      padding: 0,
      height: 40,
      border: '1px solid #E0E4E8',
      borderRadius: '2px',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      cursor: 'pointer',
    }),
    control: () => ({
      width: 'inherit',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
    }),
    singleValue: (styles: any) => ({ ...styles, color: '#000000' }),
    dropdownIndicator: (styles: any) => ({ ...styles, color: '#000000' }),
    option: (styles: any, state: any) => ({
      ...styles,
      ':hover': {
        color: '#AB141D',
      },
      textAlign: 'left',
      color: '#000000',
      backgroundColor: state.isSelected ? 'rgba(171, 20, 29, 0.1)' : '#FFFFFF',
      transition: '0.15s',
      cursor: 'pointer',
    }),
    menu: (styles: any) => ({ ...styles, zIndex: 10002 }),
  };

  return (
    <div className="selector">
      <Select
        options={options}
        onChange={onChange}
        components={{ DropdownIndicator }}
        value={selected}
        styles={customStyles}
        {...rest}
      />
    </div>
  );
}
