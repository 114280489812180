import styles from './TradeModalFooter.module.scss';
import * as React from 'react';
import { SocketState } from '@farmersrisk/cqg/cqgClient/CQGClient';
import { TradeExecutionIntent } from '../CQG/CQGProvider';
import { isNil } from 'lodash';
import {
  FontAwesomeIcon,
  faBolt,
  faBoltSlash,
  faCloud,
  faCloudArrowDown,
  faCloudArrowUp,
  faCloudSlash,
  faHandshake,
  faHandshakeSlash,
  faUser,
  faUserGroup,
  faUserSlash,
} from '../faIcons';
import { useCQGContext } from '../CQG/useCQGContext';
import { useTradeOrderContext } from './TradeOrderProvider';

interface TradeModalFooterProps {
  intent?: TradeExecutionIntent;
}

// Note: doesn't currently support refs
export default function TradeModalFooter(props: TradeModalFooterProps) {
  const cqg = useCQGContext();
  const cqgClient = cqg?.client();
  const orderContext = useTradeOrderContext();

  if (!cqgClient) {
    return null;
  }

  const loggedOn = cqgClient.isLoggedOn();
  const socketState = cqgClient.getSocketState();
  const subscribed = cqgClient.getTradeSubscriptionStatus();
  const order = cqgClient.getCurrentOrderRequest();
  const orders = cqg?.state.orders;
  const orderMatch = orders ? orders[order?.clOrderId || ''] : null;
  const contractSymbol = cqgClient.getCurrentSymbol()?.contractSymbol || '';
  const validAccounts = cqgClient.getBrokerageAccountIds();

  const intent = props.intent ?? orderContext.intent;
  let centerInfo = orderContext.cqgSymbol || '';
  if (intent === TradeExecutionIntent.RESOLVESYMBOL) centerInfo = contractSymbol || orderContext.cqgSymbol || '';
  if (intent === TradeExecutionIntent.PLACEORDER) centerInfo = isNil(orderMatch) ? contractSymbol : orderMatch.orderId;

  let socketIcon = faCloudSlash;
  switch (socketState) {
    case SocketState.open:
      socketIcon = faCloud;
      break;
    case SocketState.opening:
      socketIcon = faCloudArrowUp;
      break;
    case SocketState.closing:
      socketIcon = faCloudArrowDown;
      break;
    case SocketState.closed:
    default:
      socketIcon = faCloudSlash;
      break;
  }
  let userIcon = faUserSlash;
  if (validAccounts?.length) {
    userIcon = faUser;
    if (validAccounts?.length && validAccounts?.length > 1) {
      userIcon = faUserGroup;
    }
  }
  const accountId = validAccounts?.length ? validAccounts[0] : '';
  return (
    <div className={`${styles['trade-modal-footer']} row`}>
      <div className="col">{accountId || '...'}</div>
      <div className="col text-center">{centerInfo}</div>
      <div className="col text-end">
        <FontAwesomeIcon fixedWidth icon={socketIcon} title={`Connection: ${SocketState[socketState || SocketState.closed]}`} />
        <FontAwesomeIcon fixedWidth icon={loggedOn ? faBolt : faBoltSlash} title={loggedOn ? 'Logged on' : 'Not logged on'} />
        <FontAwesomeIcon fixedWidth icon={userIcon} title={`Found ${validAccounts?.length} account(s)`} />
        <FontAwesomeIcon
          fixedWidth
          icon={subscribed ? faHandshake : faHandshakeSlash}
          title={subscribed ? 'Trade subscription active' : 'Trade subscription inactive'}
        />
      </div>
    </div>
  );
}
