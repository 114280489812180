import React, { ChangeEvent } from 'react';
import './InputTypeCheckbox.css';

export default function InputTypeCheckbox({ checked, disabled, onChange }: InputTypeCheckboxProps) {
  return (
    <div className="input-type-checkbox">
      <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
      <span className="input-type-checkbox-checkmark"></span>
    </div>
  );
}

type InputTypeCheckboxProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};
