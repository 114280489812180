import styles from './Hint.module.scss';

import * as React from 'react';
import { Hint as KHint, HintProps as KHintProps } from '@progress/kendo-react-labels';

export interface HintProps extends KHintProps {
  subtle?: boolean;
  important?: boolean;
  small?: boolean;
}

// Note: doesn't currently support refs
export default function Hint(props: HintProps) {
  const { className, subtle, important, small, ...otherProps } = props;

  const classNames = [
    `${styles['fr-hint']}`,
    `${subtle ? 'fr-hint-subtle' : ''}`,
    `${important ? 'fr-hint-important' : ''}`,
    `${small ? 'fr-hint-small' : ''}`,
    `${className}`,
  ];

  return <KHint className={classNames.join(' ')} {...otherProps} />;
}
