import styles from './Button.module.scss';

import React, { LegacyRef } from 'react';
import { Button as KButton, ButtonProps as KButtonProps } from '@progress/kendo-react-buttons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '../../faIcons';

export interface ButtonProps extends Omit<KButtonProps, 'icon' | 'iconClass' | 'size'> {
  iconProps?: FontAwesomeIconProps;
  iconPosition?: 'before' | 'after';
  square?: boolean;
  fontWeight?: 'normal' | 'bold';
  /**
   * Configures the `size` of the Button.
   *
   * The available options are:
   * - small
   * - medium
   * - large
   * - null&mdash;Does not set a size `className`.
   *
   * @default `medium`
   */
  size?: null | 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
}

function Button(props: ButtonProps, ref: LegacyRef<KButton> | undefined) {
  let {
    iconProps,
    iconPosition = 'after',
    rounded = 'medium',
    square = false,
    fontWeight = 'normal',
    size,
    ...otherProps
  } = props;
  if (square) {
    rounded = null;
  }
  let kendoSize: null | undefined | 'small' | 'medium' | 'large';
  switch (size) {
    case 'xx-small':
    case 'x-small':
      kendoSize = 'small';
      break;
    default:
      kendoSize = size;
      break;
  }
  return (
    <KButton
      ref={ref}
      size={kendoSize}
      type="button"
      {...otherProps}
      className={`${styles['fr-button']} ${props.className} fr-button-fw-${fontWeight} fr-button-${size}`}
      rounded={rounded}
    >
      {props.children && iconProps && iconPosition === 'before' && (
        <FontAwesomeIcon {...iconProps} className={`fr-button-icon-before ${iconProps.className}`} />
      )}
      {props.children}
      {props.children && iconProps && iconPosition === 'after' && (
        <FontAwesomeIcon {...iconProps} className={`fr-button-icon-after ${iconProps.className}`} />
      )}

      {!props.children && iconProps && iconPosition === 'after' && <FontAwesomeIcon {...iconProps} />}
    </KButton>
  );
}

export default React.forwardRef(Button);
