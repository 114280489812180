import { isNil } from 'lodash';
import { createNanoEvents, type DefaultEvents, type EventsMap } from './nanoevents';

// EventEmitter shim using nanoevents package
export class NanoEventEmitter {
  private _emitter: ReturnType<typeof createNanoEvents>;
  constructor() {
    this._emitter = createNanoEvents();
  }

  on<K extends keyof EventsMap>(event: K, listener: DefaultEvents[K]) {
    this._emitter.on(event, listener);
  }

  emit(event: string, ...args: unknown[]) {
    this._emitter.emit(event, ...args);
  }

  removeAllListeners(event?: string) {
    if (!isNil(event)) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this._emitter.events[event] = []; // empty the callback array

      return;
    }

    this._emitter.events = {};
  }

  removeListener<K extends keyof EventsMap>(event: K, listener: DefaultEvents[K]) {
    const listeners = this._emitter.events[event] || [];

    if (!listeners.length) {
      return;
    }

    const newListeners = listeners.filter((x) => x !== listener);
    this._emitter.events[event] = newListeners;
  }

  once<K extends keyof EventsMap>(event: K, listener: DefaultEvents[K]) {
    const unbind = this._emitter.on(event, (...args) => {
      unbind();
      listener(...args);
    });
    return unbind;
  }
}
