import Decimal from 'decimal.js';
import { isNil, sortBy, uniqBy } from 'lodash';
import { CommodityInformation, getCommodityByCommodityId, InsuranceType } from '@harvestiq/constants';
import { ICreateNewPrice, IPrice } from './IPrices';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import type { OperationCrop } from '@farmersrisk/shared/repository/OperationCrop';

export type InsuranceTypeWithDisplayName = InsuranceType & { getDisplayName: (insuranceType: InsuranceType) => string };

export const InsuranceTypeWithDisplayName = {
  ...InsuranceType,
  getDisplayName: (insuranceType: InsuranceType): string => {
    switch (insuranceType) {
      case InsuranceType.YIELD:
        return 'Yield';
      case InsuranceType.REVENUE:
        return 'Revenue';
      case InsuranceType.REVENUE_HPE:
        return 'Revenue - HPE';
      default:
        return '- -';
    }
  },
};

export interface IOperationCrop {
  id: number;
  cropId: number;
  name?: string;
  operationId: number;
  year: string;
  acres?: string;
  aph?: string;
  actualYield?: string;
  currentProjectedYield?: string;
  relevantYield?: string;
  protectionType: InsuranceType | null;
  protectionPercent?: number;
  storage?: number;
  price?: IPrice;
  cropType?: {
    id: number;
    name: string;
    tradingCode: string;
  };
  isDeleted?: boolean;
  isUpdated?: boolean;
}

export type IOperationCropV2 = Omit<OperationCrop, 'createdAt' | 'updatedAt' | 'price'> & {
  createdAt?: Date | string;
  updatedAt?: Date | string;
  //price?: OperationCrop['price'];
  price?: IPrice; // TODO: fix this to match to the BE price object
};

// used only for creating new operation crop
export interface ICreateNewOperationCrop
  extends Omit<IOperationCropV2, 'id' | 'price' | 'relevantYieldUOMPerAcre' | 'operationId'> {
  id?: number;
  price: ICreateNewPrice;
}

export const getUOMName = (uom: UnitOfMeasure, capitalFirstLetter = false): string => {
  let name = '';

  switch (uom) {
    case UnitOfMeasure.Bushel:
      name = 'bushel';
      break;
    case UnitOfMeasure.Pounds:
      name = 'pound';
      break;
    default:
      name = '';
      break;
  }

  if (capitalFirstLetter) {
    name = name.charAt(0).toUpperCase() + name.slice(1);
  }

  return name;
};

export const getHedgingCropsFromOperationCrops = (operationCrops: IOperationCropV2[]): CommodityInformation[] => {
  const operationCropsWithHedgingType = operationCrops.filter((operationCrop) => operationCrop.hedgingCropType > 0);
  const hedgingCrops = operationCropsWithHedgingType
    .map((operationCrop) => getCommodityByCommodityId(operationCrop.hedgingCropType!))
    .filter((x) => !!x) as CommodityInformation[];
  const validHedgingCrops = uniqBy(hedgingCrops, 'id');
  const sortedHedgingCrops = sortBy(validHedgingCrops, 'id');
  return sortedHedgingCrops as CommodityInformation[];
};

export const getMarketingCropsFromOperationCrops = (operationCrops: IOperationCropV2[]): CommodityInformation[] => {
  const operationCropsWithMarketingType = operationCrops.filter((operationCrop) => !isNil(operationCrop.marketingCropType));
  const marketingCrops = operationCropsWithMarketingType
    .map((operationCrop) => getCommodityByCommodityId(operationCrop.marketingCropType!))
    .filter((x) => !!x) as CommodityInformation[];
  const validMarketingCrops = uniqBy(marketingCrops, 'id');
  const sortedMarketingCrops = sortBy(validMarketingCrops, 'id');
  return sortedMarketingCrops as CommodityInformation[];
};

export const fakeOperationCrop: IOperationCropV2 = {
  id: 0,
  marketingCropType: 1,
  operationId: 1,
  hedgingCropType: 1,
  productionCycle: '2023',
  name: 'Fake Crop',
  acres: new Decimal(0),
  actualYieldUOMPerAcre: new Decimal(0),
  projectedYieldUOMPerAcre: null,
  relevantYieldUOMPerAcre: new Decimal(0),
  storageUOM: null,
  aphUOMPerAcre: null,
  insuranceType: null,
  insurancePercent: null,
  uom: UnitOfMeasure.Bushel,
  hidden: false,
};

export const convertToOldOperationCrop = (operationCropV2: IOperationCropV2): IOperationCrop => {
  const {
    id,
    marketingCropType,
    hedgingCropType,
    productionCycle,
    name,
    acres,
    actualYieldUOMPerAcre,
    projectedYieldUOMPerAcre,
    relevantYieldUOMPerAcre,
    storageUOM,
    aphUOMPerAcre,
    insuranceType,
    insurancePercent,
    price,
  } = operationCropV2;

  const cropType = marketingCropType || hedgingCropType || 0;
  const crop = getCommodityByCommodityId(cropType);

  return {
    id,
    cropId: cropType || 0,
    name,
    operationId: 0,
    year: productionCycle,
    acres: acres?.toString() || '',
    aph: aphUOMPerAcre?.toString() || '',
    actualYield: actualYieldUOMPerAcre?.toString() || '',
    currentProjectedYield: projectedYieldUOMPerAcre?.toString() || '',
    relevantYield: relevantYieldUOMPerAcre?.toString() || '',
    protectionType: insuranceType || null,
    protectionPercent: insurancePercent?.toNumber(),
    storage: storageUOM?.toNumber(),
    price,
    cropType: crop ? { id: crop?.id, name: crop?.name, tradingCode: crop?.tradingCode } : undefined,
    isDeleted: false,
    isUpdated: false,
  };
};
