import React, { Dispatch, SetStateAction } from 'react';
import InputWithLabel from '../../../common/Inputs/InputWithLabel/InputWithLabel';
import { BudgetValueType, IBudget, IBudgetsItem } from '../../../../types/IBudget';
import usePermission from '../../../../customHooks/usePermission';
import BudgetTotalRow from '../BudgetTotalRow';
import { FontAwesomeIcon, faPlus, IconDefinition } from '../../../faIcons';
import SelectWithLabel from '../../../common/SelectWithLabel/SelectWithLabel';
import { prepareOptionsForSelect } from '../../../../utils/optionsForSelect';
import DeleteButton from '../../../common/DeleteButton/DeleteButton';
import { isUndefined } from 'lodash';
import { toNumberOrNull } from '../../../../utils/helpers';
import { IOperationCropV2 } from '../../../../types/IOperationCrops';

interface ICustomCropBudgetParams {
  editableBudget: IBudget;
  operationCrop: IOperationCropV2;
  setEditableBudget: Dispatch<SetStateAction<IBudget>>;
}

function ICustomCropBudget(params: ICustomCropBudgetParams) {
  const { editableBudget, operationCrop, setEditableBudget } = params;
  const isAtLeastEditor = usePermission();

  const valueTypes = {
    1: 'Flat',
    2: 'Per Acre',
    3: 'Per Bushel',
  };

  const isOverhead = operationCrop && operationCrop.id === 0;

  const addNewCategory = (operationCropId: number) => {
    let updatedCropBudgets = editableBudget.cropBudgets;
    if (!updatedCropBudgets[operationCropId]) {
      updatedCropBudgets = { ...updatedCropBudgets, [operationCropId]: [] };
    }
    updatedCropBudgets = {
      ...updatedCropBudgets,
      [operationCropId]: [
        ...updatedCropBudgets[operationCropId],
        {
          orderId: updatedCropBudgets[operationCropId].length,
          categoryName: '',
          valueType: isOverhead ? BudgetValueType.Flat : BudgetValueType.PerAcre,
          value: 0,
        },
      ],
    };
    setEditableBudget({ ...editableBudget, cropBudgets: updatedCropBudgets });
  };

  const updateValue = (updatedItem: IBudgetsItem) => {
    const updatedBudget: IBudget = { ...editableBudget };
    updatedBudget.cropBudgets = {
      ...updatedBudget.cropBudgets,
      [operationCrop.id]: updatedBudget.cropBudgets[operationCrop.id].map((item) => {
        return item.orderId === updatedItem.orderId ? updatedItem : item;
      }),
    };
    setEditableBudget(updatedBudget);
  };

  const deleteBudgetItem = (budgetItem: IBudgetsItem) => {
    const updatedBudget: IBudget = { ...editableBudget };
    const updatedBudgetItems = updatedBudget.cropBudgets[operationCrop.id]
      .filter((item) => {
        return item.orderId !== budgetItem.orderId;
      })
      .map((item) => {
        if (isUndefined(budgetItem.orderId) || isUndefined(item.orderId)) {
          return item;
        }
        return budgetItem.orderId < item.orderId ? { ...item, orderId: item.orderId - 1 } : item;
      });
    updatedBudget.cropBudgets = {
      ...updatedBudget.cropBudgets,
      [operationCrop.id]: updatedBudgetItems,
    };
    setEditableBudget(updatedBudget);
  };

  return (
    <div>
      <div className="divider-line" />
      <BudgetTotalRow operationCrop={operationCrop} budget={editableBudget} isOverhead={isOverhead} />
      {editableBudget?.cropBudgets &&
        editableBudget.cropBudgets[operationCrop.id] &&
        editableBudget.cropBudgets[operationCrop.id].map((item, index) => (
          <div className="budget-item-row" key={operationCrop.id + index}>
            <div className="costs-input-wrapper">
              <InputWithLabel
                label="Category name"
                value={item.categoryName || ''}
                required={true}
                disabled={!isAtLeastEditor}
                onChange={({ target }) => updateValue({ ...item, categoryName: target.value })}
              />
            </div>
            <div className="costs-input-wrapper">
              <InputWithLabel
                label="Cost/Budget"
                type={'number'}
                value={item.value}
                required={true}
                disabled={!isAtLeastEditor}
                step={0.01}
                onChange={({ target }) => updateValue({ ...item, value: toNumberOrNull(target.value) })}
              />
            </div>
            <div className="costs-input-wrapper cost-input-variable-name">
              <SelectWithLabel
                label="Variable name"
                options={prepareOptionsForSelect(valueTypes)}
                selected={prepareOptionsForSelect(valueTypes).find((i) => i.value === item.valueType) || ''}
                required={true}
                disabled={!isAtLeastEditor || isOverhead}
                onChange={({ value }) => updateValue({ ...item, valueType: value })}
              />
            </div>
            <div className="delete-budget-item">
              <DeleteButton disabled={!isAtLeastEditor} onClick={() => deleteBudgetItem(item)} />
            </div>
          </div>
        ))}

      {isAtLeastEditor && (
        <div className="create-contract-wrapper" onClick={() => addNewCategory(operationCrop.id)}>
          <FontAwesomeIcon icon={faPlus as IconDefinition} color={'#AB141D'} />
          <label className="create-contract-label">Add New Category</label>
        </div>
      )}
    </div>
  );
}

export default ICustomCropBudget;
