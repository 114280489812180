import dayjs from './dayjs';
import { SymbolLookUpResponseItem } from '../models/getMarketData';

export const toDate = (strDate: string | undefined) => {
  if (!strDate) return '';

  return new Date(strDate).toLocaleDateString('es-PR');
};

export function formatDateForInput(date: Date): string {
  const dayjsDate = dayjs.utc(date).format('YYYY-MM-DD');
  if (dayjsDate.length === 10) {
    return dayjsDate;
  }

  // dayjs format returns years <1000 without leading 0s, but input date doesn't accept it
  // some dates will temporarily have years <1000 as they are entered manually via input
  // e.g., dayjs format returns 2-01-2023 instead of 0002-01-2023
  const split = dayjsDate.split('-');
  let year = split[0];
  const month = split[1];
  const day = split[2];
  while (year.length < 4) {
    year = '0' + year;
  }
  return `${year}-${month}-${day}`;
}

export function formatDateForPDF(date: Date): string {
  return dayjs(date).format('MM/DD/YYYY');
}

export function inputDateToISO(date: string | Date | null): string | null {
  if (!date) {
    return null;
  }

  console.log('inputDateToISO', date);
  if (!dayjs(date).isValid()) {
    return new Date().toISOString();
  }

  let djsVal = dayjs(date);
  // if (djsVal.isBefore(dayjs('1990-01-01')) || djsVal.isAfter(dayjs('2050-01-01'))) {
  //   djsVal = djsVal.year(new Date().getFullYear());
  // }

  return djsVal.toISOString();
}

export const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getFuturesMonthList = (futuresMonthList: SymbolLookUpResponseItem[], futuresYear: string) => {
  return futuresMonthList
    .map((item: SymbolLookUpResponseItem) => {
      const result = item.name.split(' ');
      const lastElPosition = result.length - 1;
      return { year: result[lastElPosition], month: result[lastElPosition - 1] };
    })
    .filter((item: { year: string; month: string }) => Number(item.year) === Number(futuresYear))
    .map((item: { year: string; month: string }) => monthNames.indexOf(item.month))
    .sort((a, b) => a - b)
    .map((index) => monthNames[index])
    .filter((month) => month)
    .filter(function (month1, month2, array) {
      return array.indexOf(month1) === month2;
    });
};
