import { BudgetValueType, IBudget, IBudgetsItem } from '../types/IBudget';
import { sum } from 'lodash';
import { IOperationCropV2 } from '../types/IOperationCrops';
import Decimal from 'decimal.js';

export interface ICropBudgets {
  [key: number]: number;
}

export function getCropBudgets(budget: IBudget | undefined, operationCrops: IOperationCropV2[]) {
  const result: ICropBudgets = {};

  const totalOverhead =
    (budget && budget?.cropBudgets && budget?.cropBudgets[0] && sum(budget?.cropBudgets[0].map((x) => x.value))) || 0;
  const totalAcres = sum(operationCrops.map((operationCrop) => (operationCrop.acres ? operationCrop.acres.toNumber() : 0)));

  operationCrops.forEach((operationCrop) => {
    const overheadPerAcre = (budget && budget?.cropBudgets && getOverheadPerAcre(totalOverhead, operationCrop, totalAcres)) || 0;

    // handle backwards compatibility
    let budgetItems: IBudgetsItem[] | undefined;
    let budgetKey: number | undefined;

    // handle crop budgets mapped by marketingCropType
    if (budget?.cropBudgets && budget?.cropBudgets[operationCrop.marketingCropType]) {
      budgetKey = operationCrop.marketingCropType;
      budgetItems = budget?.cropBudgets[operationCrop.marketingCropType];
    }

    // handle crop budgets mapped by operationCropId
    if (budget?.cropBudgets && budget?.cropBudgets[operationCrop.id]) {
      budgetKey = operationCrop.id;
      budgetItems = budget?.cropBudgets[operationCrop.id];
    }

    if (budgetItems && budgetKey) {
      result[budgetKey] = budgetItems
        ? sum(budgetItems.map((budgetItem) => getCostPerAcre(budgetItem, operationCrop))) + overheadPerAcre
        : overheadPerAcre;
    } else {
      result[budgetKey ?? 99] = 0;
    }
  });

  return result;
}

function getCostPerAcre(budgetItem: IBudgetsItem, operationCrop?: IOperationCropV2) {
  if (!operationCrop && (budgetItem.valueType === BudgetValueType.Flat || budgetItem.valueType === BudgetValueType.PerBushel)) {
    return 0;
  }

  const acres = operationCrop?.acres?.toNumber() || 0;
  const relevantYield = operationCrop?.relevantYieldUOMPerAcre.toNumber() || 0;

  return {
    [BudgetValueType.Flat]: acres > 0 ? new Decimal(budgetItem.value || 0).dividedBy(acres).toNumber() : 0,
    [BudgetValueType.PerAcre]: budgetItem.value,
    [BudgetValueType.PerBushel]: relevantYield > 0 ? new Decimal(budgetItem.value || 0).dividedBy(relevantYield).toNumber() : 0,
  }[budgetItem.valueType];
}

export function getTotalCropCosts(operationCrops: IOperationCropV2[], cropBudgets: ICropBudgets): number {
  return sum(
    Object.keys(cropBudgets)
      .map((cropTypeOrId) => Number(cropTypeOrId))
      .map((cropTypeOrId) => {
        const operationCrop = operationCrops.find(
          (operationCrop) => operationCrop.marketingCropType === cropTypeOrId || operationCrop.id === cropTypeOrId
        );
        const acres = operationCrop?.acres || new Decimal(0);
        return acres.times(cropBudgets[cropTypeOrId]).toNumber();
      })
  );
}

function getOverheadPerAcre(overhead: number, operationCrop: IOperationCropV2 | undefined, totalAcres: number) {
  if (!overhead || !operationCrop || !operationCrop.acres || !totalAcres) {
    return 0;
  }

  const percentOfTotalAcres = operationCrop.acres.dividedBy(totalAcres);
  const overheadPerCrop = new Decimal(overhead).times(percentOfTotalAcres);
  const overheadPerAcre = overheadPerCrop.dividedBy(operationCrop.acres);

  return overheadPerAcre.toNumber();
}
