import { Decimal } from 'decimal.js';
import {
  getCommodityByCommodityId,
  hasTradingCode,
  CommodityId,
  InstrumentType,
  getCommodityByTradingCode,
  UnreachableCaseError,
} from '@harvestiq/constants';
//import { createLogger } from '../../logger';
import { isNullOrEmpty } from '@harvestiq/utils';
import { getBarchartSymbol } from './barchartSymbols';
import {
  FUTURES_MONTH_CODES,
  InstrumentSymbol,
  MONTH_CODE_TO_MONTH_NUMBER,
  SpecialOptionCode,
} from './constants';
import { SymbolParams } from './symbology';
import { isNil } from 'lodash';

export function getFRSymbolFromParams(params: SymbolParams): string | null {
  const {
    commodityId,
    instrumentType,
    contractMonth,
    contractYear,
    strikePrice,
  } = params;
  return getFRSymbol({
    commodityId,
    instrumentType,
    contractMonth,
    contractYear,
    strikePrice: isNil(strikePrice) ? undefined : new Decimal(strikePrice),
  });
}

/**
 * Determines the canonical Symbol to be used for transaction grouping
 * @returns the FR Canonical Symbol
 * Futures {cropTradingSymbol}.{monthCode}{2digitYear}.F[.M]
 * Calls/Puts {cropTradingSymbol}.{expirationMonthCode}{2digitExpirationYear}.{C,P}.{strike}[.{specialOptionCode}]
 */
export function getFRSymbol({
  commodityId,
  instrumentType,
  contractMonth,
  contractYear, // 4-digit year
  strikePrice, // $/bu avoid floating-point errors
  suppressErrors = true,
  isMini = false,
}: {
  commodityId: CommodityId;
  instrumentType: InstrumentType;
  contractMonth: number;
  contractYear: number; // 4-digit year
  strikePrice?: Decimal; // $/bu avoid floating-point errors
  suppressErrors?: boolean;
  isMini?: boolean;
}): string | null {
  const commodity = getCommodityByCommodityId(commodityId);
  if (!hasTradingCode(commodity)) {
    if (suppressErrors) {
      return null;
    } else {
      throw new Error(`Invalid crop type 'other': ${commodityId}`);
    }
  }

  // futures
  if (instrumentType === InstrumentType.Futures) {
    // if (!crop.futuresTradingMonths.includes(contractMonth)) {
    // logger.warn(
    //   `Requested FRSymbol for ${crop.name} future month: ${contractMonth} which is not a futures month for that crop`
    // );
    // }
    const futuresMonthCode = FUTURES_MONTH_CODES[contractMonth];

    if (isMini) {
      // ZC.Z22.F.M
      return `${commodity.tradingCode}.${futuresMonthCode}${
        contractYear - 2000
      }.F.M`;
    }

    // ZC.Z22.F
    return `${commodity.tradingCode}.${futuresMonthCode}${
      contractYear - 2000
    }.F`;
  }

  // options
  if (!strikePrice) {
    if (suppressErrors) {
      return null;
    } else {
      throw new Error('Non-futures instruments must define a strike price');
    }
  }

  const expirationMonthCode = FUTURES_MONTH_CODES[contractMonth];
  let optionSymbol: InstrumentSymbol;

  let specialOptionCode: SpecialOptionCode | null;
  try {
    ({ optionSymbol, specialOptionCode } = getFROptionCode(instrumentType));
  } catch (e) {
    if (suppressErrors) {
      return null;
    } else {
      throw e;
    }
  }

  const strikePriceNormalized = strikePrice
    .times(commodity.barchartStrikeMultiplier)
    .toString()
    .padStart(3, '0');

  // ZC.K22.C.700
  // ZC.M22.P.710.SD
  return `${commodity.tradingCode}.${expirationMonthCode}${
    contractYear - 2000
  }.${optionSymbol}.${strikePriceNormalized}${
    specialOptionCode ? `.${specialOptionCode}` : ''
  }`;
}

function getFROptionCode(instrumentType: InstrumentType): {
  optionSymbol: InstrumentSymbol;
  specialOptionCode: SpecialOptionCode | null;
} {
  switch (instrumentType) {
    case InstrumentType.Put:
      return { optionSymbol: InstrumentSymbol.PUT, specialOptionCode: null };
    case InstrumentType.Call:
      return { optionSymbol: InstrumentSymbol.CALL, specialOptionCode: null };
    case InstrumentType.ShortDatedPut:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.SHORTDATED,
      };
    case InstrumentType.ShortDatedCall:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.SHORTDATED,
      };
    case InstrumentType.Week1Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.WEEK1,
      };
    case InstrumentType.Week1Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.WEEK1,
      };
    case InstrumentType.Week2Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.WEEK2,
      };
    case InstrumentType.Week2Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.WEEK2,
      };
    case InstrumentType.Week3Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.WEEK3,
      };
    case InstrumentType.Week3Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.WEEK3,
      };
    case InstrumentType.Week4Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.WEEK4,
      };
    case InstrumentType.Week4Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.WEEK4,
      };
    case InstrumentType.Week5Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.WEEK5,
      };
    case InstrumentType.Week5Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.WEEK5,
      };
    case InstrumentType.SerialPut:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.SERIAL,
      };
    case InstrumentType.SerialCall:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.SERIAL,
      };
    case InstrumentType.NewCropWeek1Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK1,
      };
    case InstrumentType.NewCropWeek1Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK1,
      };
    case InstrumentType.NewCropWeek2Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK2,
      };
    case InstrumentType.NewCropWeek2Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK2,
      };
    case InstrumentType.NewCropWeek3Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK3,
      };
    case InstrumentType.NewCropWeek3Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK3,
      };
    case InstrumentType.NewCropWeek4Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK4,
      };
    case InstrumentType.NewCropWeek4Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK4,
      };
    case InstrumentType.NewCropWeek5Call:
      return {
        optionSymbol: InstrumentSymbol.CALL,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK5,
      };
    case InstrumentType.NewCropWeek5Put:
      return {
        optionSymbol: InstrumentSymbol.PUT,
        specialOptionCode: SpecialOptionCode.NEW_CROP_WEEK5,
      };
    default:
      throw new Error('Invalid InstrumentType for options');
  }
}

export class FRSymbolUtils {
  static isMini(frSymbol: string) {
    return this.extractFRSymbolComponents(frSymbol).isMini;
  }

  // Futures {cropTradingSymbol}.{monthCode}{2digitYear}.F[.M]
  // Calls/Puts {cropTradingSymbol}.{expirationMonthCode}{2digitExpirationYear}.{C,P}.{strike}[.{specialOptionCode}]
  static extractFRSymbolComponents(frSymbol: string) {
    const frSymbolBreakup = frSymbol.split('.');
    const tradingCode = frSymbolBreakup[0];

    // Get Commodity by Trading Code
    const commodity = getCommodityByTradingCode(tradingCode);
    if (!commodity) {
      throw new Error(`Invalid commodity trading code: ${tradingCode}`);
    }

    // Get Month/Year
    const contractMonthYear = frSymbolBreakup[1];
    const contractMonthLetter = contractMonthYear.substring(0, 1);
    const contractYear = contractMonthYear.substring(1, 3);
    // Get Month code from FUTURES_MONTH_CODES by value
    const contractMonthNumber = MONTH_CODE_TO_MONTH_NUMBER[contractMonthLetter];
    const contractYearNumber = parseInt(contractYear, 10) + 2000;

    const instrumentSymbol = frSymbolBreakup[2];
    let simplifiedInstrumentType: InstrumentType = InstrumentType.Futures; // F, C, P at this point
    if (instrumentSymbol === 'C') {
      simplifiedInstrumentType = InstrumentType.Call;
    } else if (instrumentSymbol === 'P') {
      simplifiedInstrumentType = InstrumentType.Put;
    }

    const isMini =
      simplifiedInstrumentType === InstrumentType.Futures &&
      frSymbolBreakup[3] === 'M';

    let strikePrice: Decimal | undefined = undefined;
    if (simplifiedInstrumentType !== InstrumentType.Futures) {
      strikePrice = new Decimal(frSymbolBreakup[3]).dividedBy(
        commodity.barchartStrikeMultiplier
      ); // $ / UOM
    }

    const isSpecialOption = frSymbolBreakup.length > 4;
    let isShortDated = false;
    if (isSpecialOption) {
      const specialOptionCode = frSymbolBreakup
        .slice(4)
        .join('.') as SpecialOptionCode;
      if (simplifiedInstrumentType == InstrumentType.Call) {
        if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK1) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek1Call;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK2) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek2Call;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK3) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek3Call;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK4) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek4Call;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK5) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek5Call;
        } else if (specialOptionCode == SpecialOptionCode.WEEK1) {
          simplifiedInstrumentType = InstrumentType.Week1Call;
        } else if (specialOptionCode == SpecialOptionCode.WEEK2) {
          simplifiedInstrumentType = InstrumentType.Week2Call;
        } else if (specialOptionCode == SpecialOptionCode.WEEK3) {
          simplifiedInstrumentType = InstrumentType.Week3Call;
        } else if (specialOptionCode == SpecialOptionCode.WEEK4) {
          simplifiedInstrumentType = InstrumentType.Week4Call;
        } else if (specialOptionCode == SpecialOptionCode.WEEK5) {
          simplifiedInstrumentType = InstrumentType.Week5Call;
        } else if (specialOptionCode == SpecialOptionCode.SERIAL) {
          simplifiedInstrumentType = InstrumentType.SerialCall;
        } else if (specialOptionCode == SpecialOptionCode.SHORTDATED) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.ShortDatedCall;
        }
      } else if (simplifiedInstrumentType == InstrumentType.Put) {
        if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK1) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek1Put;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK2) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek2Put;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK3) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek3Put;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK4) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek4Put;
        } else if (specialOptionCode == SpecialOptionCode.NEW_CROP_WEEK5) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.NewCropWeek5Put;
        } else if (specialOptionCode == SpecialOptionCode.WEEK1) {
          simplifiedInstrumentType = InstrumentType.Week1Put;
        } else if (specialOptionCode == SpecialOptionCode.WEEK2) {
          simplifiedInstrumentType = InstrumentType.Week2Put;
        } else if (specialOptionCode == SpecialOptionCode.WEEK3) {
          simplifiedInstrumentType = InstrumentType.Week3Put;
        } else if (specialOptionCode == SpecialOptionCode.WEEK4) {
          simplifiedInstrumentType = InstrumentType.Week4Put;
        } else if (specialOptionCode == SpecialOptionCode.WEEK5) {
          simplifiedInstrumentType = InstrumentType.Week5Put;
        } else if (specialOptionCode == SpecialOptionCode.SERIAL) {
          simplifiedInstrumentType = InstrumentType.SerialPut;
        } else if (specialOptionCode == SpecialOptionCode.SHORTDATED) {
          isShortDated = true;
          simplifiedInstrumentType = InstrumentType.ShortDatedPut;
        }
      } else {
        throw new Error(`Invalid FR Symbol ${frSymbol}`);
      }
    }
    return {
      commodity,
      contractMonthNumber,
      contractYearNumber,
      instrumentType: simplifiedInstrumentType,
      strikePrice,
      isMini,
      isSpecialOption,
      isShortDated,
    };
  }

  static getSymbolParameters(
    frSymbol: string,
    currentTime?: Date
  ): SymbolParams & { strikePrice?: Decimal } {
    if (isNullOrEmpty(frSymbol)) {
      throw new Error('FRSymbol cannot be null or empty');
    }

    // Do the opposite of getFrSymbol
    const frSymbolBreakup = this.extractFRSymbolComponents(frSymbol);
    return {
      instrumentType: frSymbolBreakup.instrumentType,
      commodityId: frSymbolBreakup.commodity.id,
      strikePrice: frSymbolBreakup.strikePrice,
      contractMonth: frSymbolBreakup.contractMonthNumber,
      contractYear: frSymbolBreakup.contractYearNumber,
      currentTime,
      isMini: frSymbolBreakup.isMini,
    };
  }

  static getBarchartSymbol(frSymbol: string, currentTime?: Date) {
    if (!frSymbol) {
      return null;
    }
    const instrumentInfo = this.getSymbolParameters(frSymbol);
    const params: SymbolParams = {
      ...instrumentInfo,
      currentTime: currentTime ?? instrumentInfo.currentTime,
    };
    return getBarchartSymbol(params);
  }

  static formatForSorting(frSymbol: string) {
    const twoDigitYear = frSymbol.split('.')[1].slice(1);
    const symbolForSorting = twoDigitYear + frSymbol.replace(twoDigitYear, '');
    return symbolForSorting;
  }
}
