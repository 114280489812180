import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../types/Store';
import ErrorToast from '../../components/Toast/ErrorToast';
import { LoadingStatuses } from '../LoadingStatuses';
import { ISpecialOptions } from '../../types/ISpecialOptions';
import { fetchSpecialOptions } from './thunks';
import { uniqBy } from 'lodash';

export type SpecialOptionsStoreItem = {
  [symbol: string]: {
    data: ISpecialOptions[];
    lastUpdate: string;
  };
};

type SpecialOptionsSliceInitialStateType = {
  specialOptions: SpecialOptionsStoreItem;
  status: string;
  error: string | null;
};

const initialState: SpecialOptionsSliceInitialStateType = {
  specialOptions: {},
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'futuresOptions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSpecialOptions.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchSpecialOptions.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.specialOptions = { ...state.specialOptions, ...prepareSpecialOptions(action.payload, action.meta.arg) };
    },
    [fetchSpecialOptions.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load options prices!');
    },
  },
});

function prepareSpecialOptions(specialOptions: ISpecialOptions[], symbolsRequested: string[]): SpecialOptionsStoreItem {
  const result: SpecialOptionsStoreItem = {};
  let uniqueSpecialOptionsByContract = uniqBy(specialOptions, 'contract');
  symbolsRequested.forEach((symbol, index) => {
    let uniqueSpecialOptionsByCrop = uniqueSpecialOptionsByContract.filter((option) => option.root === symbol);
    uniqueSpecialOptionsByCrop = uniqBy(uniqueSpecialOptionsByContract, (option) => {
      return option.expirationDate + option.contractName + option.underlying_future;
    });
    uniqueSpecialOptionsByCrop = uniqueSpecialOptionsByContract.map((x) => {
      const expirationDate = x.expirationDate;
      return { ...x, expirationDate: expirationDate };
    });
    result[symbol] = {
      data: uniqueSpecialOptionsByCrop,
      lastUpdate: new Date().toString(),
    };
  });
  return result;
}

export default slice.reducer;

export const selectAllSpecialOptions = (state: RootState) => state.specialOptions.specialOptions;
