import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TabSwitcher from '../common/TabSwitcher/TabSwitcher';
import Tab from '../common/TabSwitcher/Tab';

export type TabNavProps = {
  menuItems: { name: string; path: string }[];
  selectedTab?: number;
};

function TabNav({ menuItems }: TabNavProps) {
  const currentRoute = menuItems.find((item) => item.path === window.location.pathname);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(currentRoute?.path || menuItems[0].path);

  return (
    <TabSwitcher>
      {menuItems.map((tab) => (
        <Tab
          key={tab.name}
          tabClassName="tab-button"
          selectedClassName="tab-button-selected"
          onClick={() => {
            setActiveTab(tab.path);
            history.push(tab.path);
          }}
          name={tab.name}
          active={activeTab === tab.path}
        />
      ))}
    </TabSwitcher>
  );
}

export default TabNav;
