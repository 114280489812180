import { TradeOrderLogger } from '../../loggers/TradeOrderLogger';
import { find } from 'lodash';
import { Order, OrderRequestReject, OrderStatus } from '@farmersrisk/cqg/cqgMessages/WebAPI/order_2';
import { ContractMetadata } from '@farmersrisk/cqg/cqgMessages/WebAPI/metadata_2';

export enum OrderActionType {
  Update,
  Reject,
  Cancel,
}

export interface OrderAction {
  type: OrderActionType;
  status?: OrderStatus;
  reject?: OrderRequestReject;
}

export interface SimpleOrderStatus {
  // extends order_2.IOrderStatus {
  // TODO: finish
  status: number;
  orderId: string;
  order?: Order | null;
  rejectMessage: string;
  submissionUtcTimestamp: Date;
  statusUtcTimestamp?: Date;
  tradePrice?: number;
  enteredBy?: string;
}

export interface SimpleOrderReject extends OrderRequestReject {}

export interface SimpleContractMetadata extends ContractMetadata {
  // TODO: finish
}

export interface OrderState {
  orders: OrderStatusState;
  rejects: OrderRejectsState;
  contracts: ContractState;
}

export interface OrderStatusState {
  [key: string]: SimpleOrderStatus;
}
export interface OrderRejectsState {
  [key: string]: SimpleOrderReject;
}

export interface ContractState {
  [key: string]: SimpleContractMetadata;
}

export function orderStatusReducer(state: OrderState, action: OrderAction): OrderState {
  let simpleId = action.status && action.status.order ? action.status?.order?.clOrderId : 'unknown';
  if (action.reject) {
    simpleId = action.reject.requestId.toString();
  }
  const simpleStatus = action.status as OrderStatus | undefined;
  const simple: SimpleOrderStatus = {
    status: simpleStatus?.status || 0,
    orderId: simpleStatus?.orderId || 'Unknown',
    rejectMessage: simpleStatus?.rejectMessage || '',
    submissionUtcTimestamp: simpleStatus?.submissionUtcTimestamp || new Date(0),
    statusUtcTimestamp: simpleStatus?.statusUtcTimestamp,
    order: simpleStatus?.order,
    tradePrice: simpleStatus?.avgFillPriceCorrect,
    enteredBy: simpleStatus?.enteredByUser,
  };
  const simpleReject = action.reject as OrderRequestReject;
  let orders = { ...state.orders };
  let rejects = { ...state.rejects };
  let contracts = { ...state.contracts };

  const existingOrder = orders[simpleId];

  if (!existingOrder) {
    const existingOrderById = find(orders, (o: SimpleOrderStatus) => o.orderId === simpleStatus?.orderId);
    if (existingOrderById) {
      // update simple id to the mapped orderId since cancel sometimes changes the clOrderId
      simpleId = existingOrderById?.order?.clOrderId || 'unknown';
    }
  }
  let skipOrderUpdate =
    existingOrder &&
    existingOrder.statusUtcTimestamp &&
    simpleStatus?.statusUtcTimestamp &&
    existingOrder.statusUtcTimestamp.getTime() > simpleStatus.statusUtcTimestamp.getTime();

  if (skipOrderUpdate) {
    TradeOrderLogger.warn('Order status received out of order', { existingOrder, newStatus: simpleStatus });
  }

  switch (action.type) {
    case OrderActionType.Update:
      if (!skipOrderUpdate) {
        orders = { ...orders, ...{ [simpleId]: simple } };
      }
      break;
    case OrderActionType.Reject:
      rejects = { ...rejects, ...{ [simpleId]: simpleReject } };
      break;
    default:
      break;
  }
  if (action.type === OrderActionType.Update && action.status?.contractMetadata) {
    const contractMetadata = action.status?.contractMetadata as ContractMetadata[];
    contractMetadata.forEach((contract) => {
      contracts[contract.contractId] = contract;
    });
  }
  return { orders, rejects, contracts };
}
