import useWindowDimensions from './useWindowDimensions';

export enum BREAKPOINTS {
  WIZARD_MOBILE = 500,
  LANDING_HEADER = 648,
  LANDING = 980,
  DEFAULT = 1160,
  MOBILE_VIEW = 768,
  TABLET_VIEW = 1024,
  LAPTOP_VIEW = 1424,
  SMALL_LAPTOP_VIEW = 1365,
}

export default function useMobileView(breakpoint: number = BREAKPOINTS.DEFAULT): boolean {
  const { width } = useWindowDimensions();

  return Boolean(width && width <= breakpoint);
}
