export enum QueryKey {
  AppContext = 'app-context',
  CashBids = 'cash-bids',
  PositionSummaries = 'position-summaries',
  HedgingCropSummaries = 'hedging-crop-summaries',
  OperationCropSummaries = 'operation-crop-summaries',
  Transactions = 'transactions',
  RealtimePrice = 'realtime-price',
  OpenPositions = 'open-positions',
  Quotes = 'quotes',
  Reports = 'reports',
  HistoryQuotes = 'history-quotes',
  SymbolLookUps = 'symbol-look-ups',
  OperationSummary = 'operation-summary',
  OperationCrops = 'operation-crops',
  AdvisorVerification = 'advisor-verification',
  CurrentHedgingCrops = 'current-hedging-crops',
  CurrentMarketingCrops = 'current-marketing-crops',
  AllHedgingCrops = 'all-hedging-crops',
  InsightsSymbol = 'insights-symbol',
  TradingCredentials = 'trading-credentials',
  TradingAccountConfigs = 'trading-account-configs',
}
