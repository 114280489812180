import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';

export interface ISummaryLegsExpanderProps {
  isExpanded: boolean;
}

export default function SummaryLegsExpander(props: ISummaryLegsExpanderProps) {
  const { isExpanded } = props;

  return (
    <div className={'parent-item'}>
      Total
      {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </div>
  );
}
