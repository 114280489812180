import { FuturesContractType, PositionType, StrategyStatus, OptionContractType } from '../types/IPosition';

// so we don't have to pass around the ENTIRE leg data all the time
export interface IPositionProtectionInfo {
  type: PositionType;
  contractType: FuturesContractType | OptionContractType;
  status?: StrategyStatus;
  quantitySold?: number;
  strikePrice?: number;
  premiumCost?: number;
  futuresPrice?: number | null;
  futuresMonth?: number;
  futuresYear?: string;
}

export function isOption(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Option;
}

export function isLongPutOption(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Option && leg.contractType === OptionContractType.BuyPut;
}

export function isLongCallOption(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Option && leg.contractType === OptionContractType.BuyCall;
}

export function isShortPutOption(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Option && leg.contractType === OptionContractType.SellPut;
}

export function isShortCallOption(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Option && leg.contractType === OptionContractType.SellCall;
}

export function isFutures(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Futures;
}

export function isShortFutures(leg: IPositionProtectionInfo): boolean {
  return leg.type === PositionType.Futures && leg.contractType === FuturesContractType.Sell;
}
