import styles from './Radio.module.scss';

import * as React from 'react';
import {
  RadioButton as KRadioButton,
  RadioButtonHandle as KRadioButtonHandle,
  RadioButtonProps as KRadioButtonProps,
} from '@progress/kendo-react-inputs';
import { forwardRef } from 'react';

export interface RadioButtonHandle extends KRadioButtonHandle {}
export interface RadioButtonProps extends KRadioButtonProps {
  // this hurts doc generation
  // [key: string]: any;
}

const RadioButton = forwardRef<RadioButtonHandle, RadioButtonProps>(
  (props: RadioButtonProps, ref?: React.Ref<RadioButtonHandle>) => {
    const { className, ...otherProps } = props;

    return <KRadioButton ref={ref} className={`${styles['fr-radio-button']} ${className}`} {...otherProps} />;
  }
);

export default RadioButton;
