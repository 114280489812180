/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';

export const protobufPackage = 'market_data_2';

/**
 * Subscription to market data.
 * If it is necessary to change subscription level client should send a new subscription request
 * with the same contract ID but a new subscription level.
 */
export interface MarketDataSubscription {
  /**
   * Contract ID to subscribe (see symbol resolution report).
   * This is client's responsibility to re-subscribe in case of symbol resolution update.
   */
  contractId: number;
  /**
   * Subscription request id. Should be unique among currently processed requests.
   * It is a required field.
   */
  requestId: number;
  /**
   * Subscription level.
   * This field is associated with Level enum type.
   * Result level will be available in MarketDataSubscriptionStatus.
   */
  level: number;
  /**
   * Defines market values array in real-time market data snapshot.
   * False or omitted means that snapshots will contain market values for most recent trading day only (if available).
   * True means that snapshots may contain market values for several (up to 3) past trading days.
   */
  includePastMarketValues: boolean;
  /**
   * Defines quotes array in real-time market data snapshot.
   * See details in commentary for field RealTimeMarketData.quotes.
   * Note: subscription with include_past_quotes=True may take longer.
   */
  includePastQuotes: boolean;
  /**
   * Defines session market values array in real-time market data snapshot.
   * False or omitted means that snapshots will not contain session market values.
   * True means that snapshots may contain session market values for several sessions.
   */
  includeSessionMarketValues: boolean;
  /**
   * Specifies that quotes and market values need to include yields.
   * Result value will be available in MarketDataSubscriptionStatus.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeYields: boolean;
  /**
   * Defines source contract quote price in real-time market data.
   * scaled_source_price is provided only for best ask and best bid.
   * Other quote types either have the same values as scaled_price for the contract or not supported.
   * Note: Flag is ignored (considered as False) if the contract does not have a source contract.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeSourcePrices: boolean;
  /**
   * Whether need to include RFQ in real-time market data. By default server don't provide them.
   * Result value will be available in MarketDataSubscriptionStatus.
   */
  includeRequestsForQuotation: boolean;
  /** Specifies that quotes need to include trade attributes. */
  includeTradeAttributes: boolean;
  /** Specifies to include market state in real-time market data. */
  includeMarketState: boolean;
  /** Specifies to include Off Market trade quotes in real-time market data. */
  includeOffMarketTrades: boolean;
  /** Optional OTC contributor ID for contributor specific data subscriptions. */
  contributorId: string;
  /**
   * Specifies that quotes and market values need to include currency prices used for price conversion.
   * Note: Flag is ignored (considered as False) if the contract does not have a source contract.
   * Re-subscription with the changed value of this parameter may lead to temporary disconnection
   * of real-time market data (STATUS_CODE_DISCONNECTED).
   */
  includeCurrencyRate: boolean;
  /**
   * DOM data subscription type for level LEVEL_TRADES_BBA_DOM or LEVEL_TRADES_BBA_DETAILED_DOM.
   * This field is associated with DomType enum type.
   * Default value is DOM_TYPE_COMBINED
   */
  domSubscriptionType: number;
  /** Specifies whether to include scaled premium price added to quote price. */
  includePremium: boolean;
}

/** Level or subscription. */
export enum MarketDataSubscription_Level {
  /** LEVEL_NONE - Unsubscribe. */
  LEVEL_NONE = 0,
  /**
   * LEVEL_SETTLEMENTS - Get only settlement quotes.
   * NOTE: MarketValues will contain only settlements.
   */
  LEVEL_SETTLEMENTS = 5,
  /**
   * LEVEL_END_OF_DAY - Get only market values.
   * Supported only for contracts with filled field ContractMetadata::end_of_day_delay.
   * NOTE: Array of quotes, requests for quotation, corrections, detailed DOM,
   * market state in RealTimeMarketData message will be always empty.
   * NOTE: If current trading day is not yet available (see commentary to field 'end_of_day_delay' in message
   * ContractMetadata), then MarketValues for today are provided the same way as if trading day was just started:
   * only required fields and yesterday prices are filled.
   */
  LEVEL_END_OF_DAY = 6,
  /** LEVEL_TRADES - Get trade and settlement with volumes (if volumes are known). */
  LEVEL_TRADES = 1,
  /** LEVEL_TRADES_BBA - Get trades and settlements with volumes (if volumes are known), best asks and best bids without volumes. */
  LEVEL_TRADES_BBA = 2,
  /** LEVEL_TRADES_BBA_VOLUMES - Get trades, settlements, best asks and best bids with volumes (if volumes are known). */
  LEVEL_TRADES_BBA_VOLUMES = 3,
  /**
   * LEVEL_TRADES_BBA_DOM - All price data including DOM (Implied and/or Combined, depending on dom_subscription_type
   * and MarketDataSubscriptionStatus.actual_dom_subscription_type).
   */
  LEVEL_TRADES_BBA_DOM = 4,
  /**
   * LEVEL_TRADES_BBA_DETAILED_DOM - LEVEL_TRADES_BBA_DOM + Order Details.
   * Note: Includes information for all orders (both implied and outright).
   */
  LEVEL_TRADES_BBA_DETAILED_DOM = 7,
  UNRECOGNIZED = -1,
}

export function marketDataSubscription_LevelFromJSON(object: any): MarketDataSubscription_Level {
  switch (object) {
    case 0:
    case 'LEVEL_NONE':
      return MarketDataSubscription_Level.LEVEL_NONE;
    case 5:
    case 'LEVEL_SETTLEMENTS':
      return MarketDataSubscription_Level.LEVEL_SETTLEMENTS;
    case 6:
    case 'LEVEL_END_OF_DAY':
      return MarketDataSubscription_Level.LEVEL_END_OF_DAY;
    case 1:
    case 'LEVEL_TRADES':
      return MarketDataSubscription_Level.LEVEL_TRADES;
    case 2:
    case 'LEVEL_TRADES_BBA':
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA;
    case 3:
    case 'LEVEL_TRADES_BBA_VOLUMES':
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES;
    case 4:
    case 'LEVEL_TRADES_BBA_DOM':
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM;
    case 7:
    case 'LEVEL_TRADES_BBA_DETAILED_DOM':
      return MarketDataSubscription_Level.LEVEL_TRADES_BBA_DETAILED_DOM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MarketDataSubscription_Level.UNRECOGNIZED;
  }
}

export function marketDataSubscription_LevelToJSON(object: MarketDataSubscription_Level): string {
  switch (object) {
    case MarketDataSubscription_Level.LEVEL_NONE:
      return 'LEVEL_NONE';
    case MarketDataSubscription_Level.LEVEL_SETTLEMENTS:
      return 'LEVEL_SETTLEMENTS';
    case MarketDataSubscription_Level.LEVEL_END_OF_DAY:
      return 'LEVEL_END_OF_DAY';
    case MarketDataSubscription_Level.LEVEL_TRADES:
      return 'LEVEL_TRADES';
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA:
      return 'LEVEL_TRADES_BBA';
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES:
      return 'LEVEL_TRADES_BBA_VOLUMES';
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM:
      return 'LEVEL_TRADES_BBA_DOM';
    case MarketDataSubscription_Level.LEVEL_TRADES_BBA_DETAILED_DOM:
      return 'LEVEL_TRADES_BBA_DETAILED_DOM';
    case MarketDataSubscription_Level.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum MarketDataSubscription_DomType {
  /**
   * DOM_TYPE_COMBINED - Real-time data shall contain Combined DOM only: a sum of Outright DOM and Implied DOM.
   * Implied DOM is based on spread orders, Outright DOM is based on outright orders.
   */
  DOM_TYPE_COMBINED = 0,
  /** DOM_TYPE_IMPLIED - Real-time data shall contain only Implied DOM. */
  DOM_TYPE_IMPLIED = 1,
  /** DOM_TYPE_IMPLIED_AND_COMBINED - Real-time data shall contain both Combined and Implied DOM. */
  DOM_TYPE_IMPLIED_AND_COMBINED = 2,
  UNRECOGNIZED = -1,
}

export function marketDataSubscription_DomTypeFromJSON(object: any): MarketDataSubscription_DomType {
  switch (object) {
    case 0:
    case 'DOM_TYPE_COMBINED':
      return MarketDataSubscription_DomType.DOM_TYPE_COMBINED;
    case 1:
    case 'DOM_TYPE_IMPLIED':
      return MarketDataSubscription_DomType.DOM_TYPE_IMPLIED;
    case 2:
    case 'DOM_TYPE_IMPLIED_AND_COMBINED':
      return MarketDataSubscription_DomType.DOM_TYPE_IMPLIED_AND_COMBINED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MarketDataSubscription_DomType.UNRECOGNIZED;
  }
}

export function marketDataSubscription_DomTypeToJSON(object: MarketDataSubscription_DomType): string {
  switch (object) {
    case MarketDataSubscription_DomType.DOM_TYPE_COMBINED:
      return 'DOM_TYPE_COMBINED';
    case MarketDataSubscription_DomType.DOM_TYPE_IMPLIED:
      return 'DOM_TYPE_IMPLIED';
    case MarketDataSubscription_DomType.DOM_TYPE_IMPLIED_AND_COMBINED:
      return 'DOM_TYPE_IMPLIED_AND_COMBINED';
    case MarketDataSubscription_DomType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Subscription status */
export interface MarketDataSubscriptionStatus {
  /** Subscription contract ID. */
  contractId: number;
  /**
   * Subscription result.
   * This field is associated with StatusCode enum type.
   */
  statusCode: number;
  /**
   * Effective subscription level.
   * This field is associated with MarketDataSubscription.Level enum type.
   * Note: when successfully subscribed to delayed DOM data, the reported level is LEVEL_TRADES_BBA_DOM,
   * but actual DOM data may not yet be available and will be available later.
   * Level can be decreased if:
   *   1. the client is prohibited to get requested data;
   *   2. Implied DOM is unavailable for an instrument and level LEVEL_TRADES_BBA_DOM or LEVEL_TRADES_BBA_DETAILED_DOM
   *      was selected with DOM_TYPE_IMPLIED - it will be lowered to LEVEL_TRADES_BBA_VOLUMES;
   */
  level: number;
  /**
   * Possible details of subscription failure.
   *
   * @deprecated
   */
  textMessage: string;
  /** Possible details of subscription failure. */
  details: Text | undefined;
  /**
   * Specifies whether past quotes are included into real-time market data snapshot or not.
   * Always False if user didn't request past quotes.
   */
  pastQuotesIncluded: boolean;
  /** Specifies whether intraday session market values are included into real-time market data or not. */
  sessionMarketValuesIncluded: boolean;
  /** Specifies whether yields are included into real-time market data or not. */
  yieldsIncluded: boolean;
  /**
   * Specifies whether source contract prices are include into real-time market data or not.
   * Always False if user didn't request source contract quotes.
   */
  sourcePriceIncluded: boolean;
  /**
   * Specifies whether RFQs are included into real-time market data or not.
   * Always False if user didn't request RFQs.
   */
  requestsForQuotationIncluded: boolean;
  /**
   * Specifies whether trade attributes included into real-time market data.
   * Always False if user didn't request trade attributes.
   */
  tradeAttributesIncluded: boolean;
  /**
   * Specifies whether market state included into real-time market data.
   * Always False if user didn't request market state.
   */
  marketStateIncluded: boolean;
  /**
   * Specifies whether Off Market trade quotes included into real-time market data.
   * Always False if user didn't request Off Market trades.
   */
  offMarketTradesIncluded: boolean;
  /**
   * Specifies whether currency prices are included into real-time market data.
   * Always False if user did not request currency prices or contract does not use currency conversion.
   */
  currencyRateIncluded: boolean;
  /** Request ID of a corresponding request. */
  requestId: number;
  /** OTC contributor ID for contributor specific data subscriptions. */
  contributorId: string;
  /**
   * Effective DOM data subscription type.
   * This field is associated with MarketDataSubscription.DomType enum type.
   */
  actualDomSubscriptionType: number;
  /** Specifies whether the scaled premium price is included in quotes. */
  premiumIncluded: boolean;
}

export enum MarketDataSubscriptionStatus_StatusCode {
  /** STATUS_CODE_SUCCESS - / success codes (0 - 99) */
  STATUS_CODE_SUCCESS = 0,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected.
   * Possible reasons: communication issues, metadata update, subscription parameters change.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending STATUS_CODE_SUCCESS status. If subscription can't be restored for some reason
   * one of the failure codes will be sent.
   */
  STATUS_CODE_DISCONNECTED = 1,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+)
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Subscription parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_ACCESS_DENIED - The user is not allowed to be subscribed to this Instrument. */
  STATUS_CODE_ACCESS_DENIED = 103,
  /** STATUS_CODE_DELETED - Market data source was deleted because of expiration or by another reason. */
  STATUS_CODE_DELETED = 104,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The subscription count limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_CONTRIBUTOR_REQUIRED - OTC Contributor ID is required for this subscription but was not provided or not authorized. */
  STATUS_CODE_CONTRIBUTOR_REQUIRED = 106,
  /** STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION - The subscription rate limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION = 107,
  /** STATUS_CODE_NOT_SUPPORTED - Market data subscription is not supported for specified contract. */
  STATUS_CODE_NOT_SUPPORTED = 110,
  UNRECOGNIZED = -1,
}

export function marketDataSubscriptionStatus_StatusCodeFromJSON(object: any): MarketDataSubscriptionStatus_StatusCode {
  switch (object) {
    case 0:
    case 'STATUS_CODE_SUCCESS':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case 'STATUS_CODE_DISCONNECTED':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case 'STATUS_CODE_FAILURE':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case 'STATUS_CODE_INVALID_PARAMS':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case 'STATUS_CODE_ACCESS_DENIED':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_ACCESS_DENIED;
    case 104:
    case 'STATUS_CODE_DELETED':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DELETED;
    case 105:
    case 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 106:
    case 'STATUS_CODE_CONTRIBUTOR_REQUIRED':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED;
    case 107:
    case 'STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION;
    case 110:
    case 'STATUS_CODE_NOT_SUPPORTED':
      return MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_NOT_SUPPORTED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MarketDataSubscriptionStatus_StatusCode.UNRECOGNIZED;
  }
}

export function marketDataSubscriptionStatus_StatusCodeToJSON(object: MarketDataSubscriptionStatus_StatusCode): string {
  switch (object) {
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS:
      return 'STATUS_CODE_SUCCESS';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED:
      return 'STATUS_CODE_DISCONNECTED';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE:
      return 'STATUS_CODE_FAILURE';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return 'STATUS_CODE_INVALID_PARAMS';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_ACCESS_DENIED:
      return 'STATUS_CODE_ACCESS_DENIED';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DELETED:
      return 'STATUS_CODE_DELETED';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED:
      return 'STATUS_CODE_CONTRIBUTOR_REQUIRED';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION';
    case MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_NOT_SUPPORTED:
      return 'STATUS_CODE_NOT_SUPPORTED';
    case MarketDataSubscriptionStatus_StatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Real time data delivery.
 * Snapshot is sent as the first message after subscription and may appear periodically when subscribed.
 */
export interface RealTimeMarketData {
  /** Contract ID assigned by server. */
  contractId: number;
  /**
   * Contract quotes. DOM is updated by new volumes per price. Zero volume is used to clean this price record from DOM.
   * Snapshot quotes have no indicators and no trade attributes.
   * If is_snapshot=False, contains new quotes for the contract.
   * If is_snapshot=True, contains one last quote of each type (best ask, best bid, trade and settlement).
   * If MarketDataSubscription.include_past_quote=False, includes last quotes for most recent trading day
   * or new day in pre-open.
   * If MarketDataSubscription.include_past_quote=True, includes last quotes for last N trading days
   * or new day in pre-open. N by default equals to 7 and can't be changed by client. Contact customer support
   * if you want to increase this value.
   * If you need to get last settlement, prefer to use message MarketValues because it contains trade_date.
   */
  quotes: Quote[];
  /** Trading day the quotes belong to. */
  quotesTradeDate: number;
  /**
   * Contract correction quotes.
   * If correction affects fields of MarketValues, and MarketValues for affected trading day was sent in the snapshot
   * (see MarketDataSubscription.include_past_market_values), then a separate update for MarketValues will be sent.
   */
  corrections: Quote[];
  /**
   * True if this is a snapshot (all previously known quotes, market values, detailed DOM, market state for
   * this contract should be cleaned).
   */
  isSnapshot: boolean;
  /**
   * Collapsing level that was applied by server.
   * NONE if the field is omitted.
   * This field is associated with RealTimeCollapsingLevel enum type.
   */
  collapsingLevel: number;
  /**
   * Market values of a contract (e.g. Open/High/Low/Last) for several past trading days.
   * Present in snapshots and in updates if some values are changed (only changed values are included in updates).
   */
  marketValues: MarketValues[];
  /** Detailed Combined DOM for LEVEL_TRADES_BBA_DETAILED_DOM subscription level. */
  detailedDom: DetailedDOM | undefined;
  /**
   * List of RFQs.
   * Never presents in snapshots, can be provided in updates only.
   */
  requestsForQuotation: RFQ[];
  /**
   * Specifies the current market state. Not all contracts provide such data.
   * Received on subscription and in updates if the state is changed.
   */
  marketState: MarketState | undefined;
  /**
   * Scale to convert scaled_* prices of RealTimeMarketData and its submessages to correct prices,
   * most of the time same as ContractMetadata.correct_price_scale
   * with possible exception of short periods between metadata change notifications and
   * real-time data snapshots. Set in snapshots only, if missing, use the previous one.
   */
  correctPriceScale: number;
}

/** Specific quote information */
export interface Quote {
  /** This field is associated with Type enum type. */
  type: number;
  /**
   * Time of the quote (UTC).
   * If time of first quote is not specified quote_utc_times are unknown.
   * Settlement quotes may not have quote_utc_time(even if past_quotes_included=True).
   * If time of snapshot quotes is required, subscribe with include_past_quotes(subscription may be slower).
   * If MarketDataSubscriptionStatus returned with flag past_quotes_included, quotes shall contain valid quote_utc_time.
   * quote_utc_time is set if the time of this quote is different from the previous one in the list of quotes.
   * Several RealTimeMarketData messages can be sent with the same quote_utc_time.
   */
  quoteUtcTime: number;
  /** Quote price. */
  scaledPrice: number;
  /**
   * Source contract quote price. See MarketDataSubscription.include_source_prices.
   * correct_price_scale from source contract metadata should be used
   * to convert scaled_source_price to correct source price.
   *
   * For Flat and Basis contracts there is no source contract.
   * In this case, use correct_price_scale from this contract metadata
   * to convert scaled_source_price to correct source price.
   * However, scaled_source_price may be imprecise with error up to half a tick due to rounding.
   * Use Price or NetBasis contributor parameters in contract metadata to get precise correct source price.
   */
  scaledSourcePrice: number;
  /**
   * Yield
   * Included if subscribed and the contract support yields.
   */
  priceYield: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /**
   * Quote volume.
   * Not set if volume is unknown or if volumes were not requested (see LEVEL_TRADES_BBA for example).
   * Zero volume indicates that the quote has been cleared. It is included even if volumes were not requested.
   */
  volume: Decimal | undefined;
  /** This field is associated with Indicator enum type. */
  indicators: number[];
  /** This field is associated with SalesCondition enum type. */
  salesCondition: number;
  /** Trade attributes. */
  tradeAttributes: TradeAttributes | undefined;
  /**
   * Currency price used to calculate scaled prices in this message.
   * correct_price_scale from currency rate contract should be used to get correct price.
   */
  scaledCurrencyRatePrice: number;
  /**
   * Premium that was added to the quote price. It is rounded to the tick size.
   * correct_price_scale should be used to get premium in correct price format.
   * Premium is calculated as a percent of quote price for bid and ask quotes.
   * Note that scaled_price already includes premium!
   */
  scaledPremium: number;
}

/** Type of the quote. */
export enum Quote_Type {
  /** TYPE_TRADE - Trade quote. */
  TYPE_TRADE = 0,
  /**
   * TYPE_BESTBID - Combined DOM Best bid quote.
   * Note that best bid/ask quotes are not guaranteed to be consistent with bid/ask quotes in the short
   * term. Thus, best bid/ask quotes must not be used to update DOM.
   */
  TYPE_BESTBID = 1,
  /**
   * TYPE_BESTASK - Combined DOM Best ask quote.
   * See the note for TYPE_BESTBID.
   */
  TYPE_BESTASK = 2,
  /** TYPE_BID - Combined DOM Bid quote (DOM level). */
  TYPE_BID = 3,
  /** TYPE_ASK - Combined DOM Ask quote (DOM level). */
  TYPE_ASK = 4,
  /** TYPE_SETTLEMENT - Settlement quote. */
  TYPE_SETTLEMENT = 5,
  /** TYPE_IMPLIED_BID - Implied Bid quote (For DOM and DetailedDOM level and DOM subscription type that includes Implied DOM). */
  TYPE_IMPLIED_BID = 10,
  /** TYPE_IMPLIED_ASK - Implied Ask quote (For DOM and DetailedDOM level and DOM subscription type that includes Implied DOM). */
  TYPE_IMPLIED_ASK = 11,
  UNRECOGNIZED = -1,
}

export function quote_TypeFromJSON(object: any): Quote_Type {
  switch (object) {
    case 0:
    case 'TYPE_TRADE':
      return Quote_Type.TYPE_TRADE;
    case 1:
    case 'TYPE_BESTBID':
      return Quote_Type.TYPE_BESTBID;
    case 2:
    case 'TYPE_BESTASK':
      return Quote_Type.TYPE_BESTASK;
    case 3:
    case 'TYPE_BID':
      return Quote_Type.TYPE_BID;
    case 4:
    case 'TYPE_ASK':
      return Quote_Type.TYPE_ASK;
    case 5:
    case 'TYPE_SETTLEMENT':
      return Quote_Type.TYPE_SETTLEMENT;
    case 10:
    case 'TYPE_IMPLIED_BID':
      return Quote_Type.TYPE_IMPLIED_BID;
    case 11:
    case 'TYPE_IMPLIED_ASK':
      return Quote_Type.TYPE_IMPLIED_ASK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Quote_Type.UNRECOGNIZED;
  }
}

export function quote_TypeToJSON(object: Quote_Type): string {
  switch (object) {
    case Quote_Type.TYPE_TRADE:
      return 'TYPE_TRADE';
    case Quote_Type.TYPE_BESTBID:
      return 'TYPE_BESTBID';
    case Quote_Type.TYPE_BESTASK:
      return 'TYPE_BESTASK';
    case Quote_Type.TYPE_BID:
      return 'TYPE_BID';
    case Quote_Type.TYPE_ASK:
      return 'TYPE_ASK';
    case Quote_Type.TYPE_SETTLEMENT:
      return 'TYPE_SETTLEMENT';
    case Quote_Type.TYPE_IMPLIED_BID:
      return 'TYPE_IMPLIED_BID';
    case Quote_Type.TYPE_IMPLIED_ASK:
      return 'TYPE_IMPLIED_ASK';
    case Quote_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Optional indicator(s) (e.g. this quote also updates some of Open/High/Low/Last session prices). */
export enum Quote_Indicator {
  /** INDICATOR_OPEN - Price of this quote is a new open price of the contract session. */
  INDICATOR_OPEN = 1,
  /** INDICATOR_HIGH - Price of this quote is a new high price of the contract session. */
  INDICATOR_HIGH = 2,
  /** INDICATOR_LOW - Price of this quote is a new low price of the contract session. */
  INDICATOR_LOW = 3,
  /**
   * INDICATOR_LAST - Price of this quote is a new last price of the contract session
   * (corresponds to MarketValues.scaled_last_price_no_settlement).
   * Contains any last received price, excluding settlement price.
   */
  INDICATOR_LAST = 4,
  /**
   * INDICATOR_CLOSE - Price of this quote is a new close price of the contract session.
   * Contains any last received price, including settlement price.
   */
  INDICATOR_CLOSE = 11,
  /** INDICATOR_PAST - This quote doesn't belong to current trading day. */
  INDICATOR_PAST = 5,
  /**
   * INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT - Quote is generated from another one.
   * E.g. for contracts with PricingFallBack OTC contributor parameter set to 1 if there is no best ask,
   * but there is a trade, trade price is sent as a best ask with this indicator along with the trade.
   */
  INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT = 6,
  /**
   * INDICATOR_INSERTION - Quote is non-settlement insertion correction.
   * Used only when Quote is a part of RealTimeMarketData.correction.
   */
  INDICATOR_INSERTION = 7,
  /**
   * INDICATOR_DELETION - Quote is deletion correction.
   * Used only when Quote is a part of RealTimeMarketData.correction.
   */
  INDICATOR_DELETION = 8,
  /**
   * INDICATOR_OFF_MARKET - This is an Off Market quote, it does not affect Open/High/Low/Last
   * session prices and is not used in bar updating.
   */
  INDICATOR_OFF_MARKET = 9,
  /**
   * INDICATOR_CURRENCY_RATE_CHANGED - Quote is caused by change in currency conversion price.
   * Applicable only for best bid and best ask quotes.
   */
  INDICATOR_CURRENCY_RATE_CHANGED = 10,
  UNRECOGNIZED = -1,
}

export function quote_IndicatorFromJSON(object: any): Quote_Indicator {
  switch (object) {
    case 1:
    case 'INDICATOR_OPEN':
      return Quote_Indicator.INDICATOR_OPEN;
    case 2:
    case 'INDICATOR_HIGH':
      return Quote_Indicator.INDICATOR_HIGH;
    case 3:
    case 'INDICATOR_LOW':
      return Quote_Indicator.INDICATOR_LOW;
    case 4:
    case 'INDICATOR_LAST':
      return Quote_Indicator.INDICATOR_LAST;
    case 11:
    case 'INDICATOR_CLOSE':
      return Quote_Indicator.INDICATOR_CLOSE;
    case 5:
    case 'INDICATOR_PAST':
      return Quote_Indicator.INDICATOR_PAST;
    case 6:
    case 'INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT':
      return Quote_Indicator.INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT;
    case 7:
    case 'INDICATOR_INSERTION':
      return Quote_Indicator.INDICATOR_INSERTION;
    case 8:
    case 'INDICATOR_DELETION':
      return Quote_Indicator.INDICATOR_DELETION;
    case 9:
    case 'INDICATOR_OFF_MARKET':
      return Quote_Indicator.INDICATOR_OFF_MARKET;
    case 10:
    case 'INDICATOR_CURRENCY_RATE_CHANGED':
      return Quote_Indicator.INDICATOR_CURRENCY_RATE_CHANGED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Quote_Indicator.UNRECOGNIZED;
  }
}

export function quote_IndicatorToJSON(object: Quote_Indicator): string {
  switch (object) {
    case Quote_Indicator.INDICATOR_OPEN:
      return 'INDICATOR_OPEN';
    case Quote_Indicator.INDICATOR_HIGH:
      return 'INDICATOR_HIGH';
    case Quote_Indicator.INDICATOR_LOW:
      return 'INDICATOR_LOW';
    case Quote_Indicator.INDICATOR_LAST:
      return 'INDICATOR_LAST';
    case Quote_Indicator.INDICATOR_CLOSE:
      return 'INDICATOR_CLOSE';
    case Quote_Indicator.INDICATOR_PAST:
      return 'INDICATOR_PAST';
    case Quote_Indicator.INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT:
      return 'INDICATOR_FALL_BACK_TO_TRADE_OR_SETTLEMENT';
    case Quote_Indicator.INDICATOR_INSERTION:
      return 'INDICATOR_INSERTION';
    case Quote_Indicator.INDICATOR_DELETION:
      return 'INDICATOR_DELETION';
    case Quote_Indicator.INDICATOR_OFF_MARKET:
      return 'INDICATOR_OFF_MARKET';
    case Quote_Indicator.INDICATOR_CURRENCY_RATE_CHANGED:
      return 'INDICATOR_CURRENCY_RATE_CHANGED';
    case Quote_Indicator.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Optional sales condition of the quote. */
export enum Quote_SalesCondition {
  /** SALES_CONDITION_HIT - Hit trade. */
  SALES_CONDITION_HIT = 1,
  /** SALES_CONDITION_TAKE - Take trade. */
  SALES_CONDITION_TAKE = 2,
  /** SALES_CONDITION_SPREAD_LEG - One leg of a spread. */
  SALES_CONDITION_SPREAD_LEG = 3,
  /**
   * SALES_CONDITION_BUY_SIDE_AGGRESSOR - The market participant was the originator of the transaction.
   * He completes trade on buy with price matched by latest Best Ask price.
   */
  SALES_CONDITION_BUY_SIDE_AGGRESSOR = 4,
  /**
   * SALES_CONDITION_SELL_SIDE_AGGRESSOR - The market participant was the originator of the transaction.
   * He completes trade on sell with price matched by latest Best Bid price.
   */
  SALES_CONDITION_SELL_SIDE_AGGRESSOR = 5,
  UNRECOGNIZED = -1,
}

export function quote_SalesConditionFromJSON(object: any): Quote_SalesCondition {
  switch (object) {
    case 1:
    case 'SALES_CONDITION_HIT':
      return Quote_SalesCondition.SALES_CONDITION_HIT;
    case 2:
    case 'SALES_CONDITION_TAKE':
      return Quote_SalesCondition.SALES_CONDITION_TAKE;
    case 3:
    case 'SALES_CONDITION_SPREAD_LEG':
      return Quote_SalesCondition.SALES_CONDITION_SPREAD_LEG;
    case 4:
    case 'SALES_CONDITION_BUY_SIDE_AGGRESSOR':
      return Quote_SalesCondition.SALES_CONDITION_BUY_SIDE_AGGRESSOR;
    case 5:
    case 'SALES_CONDITION_SELL_SIDE_AGGRESSOR':
      return Quote_SalesCondition.SALES_CONDITION_SELL_SIDE_AGGRESSOR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Quote_SalesCondition.UNRECOGNIZED;
  }
}

export function quote_SalesConditionToJSON(object: Quote_SalesCondition): string {
  switch (object) {
    case Quote_SalesCondition.SALES_CONDITION_HIT:
      return 'SALES_CONDITION_HIT';
    case Quote_SalesCondition.SALES_CONDITION_TAKE:
      return 'SALES_CONDITION_TAKE';
    case Quote_SalesCondition.SALES_CONDITION_SPREAD_LEG:
      return 'SALES_CONDITION_SPREAD_LEG';
    case Quote_SalesCondition.SALES_CONDITION_BUY_SIDE_AGGRESSOR:
      return 'SALES_CONDITION_BUY_SIDE_AGGRESSOR';
    case Quote_SalesCondition.SALES_CONDITION_SELL_SIDE_AGGRESSOR:
      return 'SALES_CONDITION_SELL_SIDE_AGGRESSOR';
    case Quote_SalesCondition.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Quote trade attributes. */
export interface TradeAttributes {
  /** Buyer contributor id from ContributorMetadata. */
  buyer: number;
  /** Seller contributor id from ContributorMetadata. */
  seller: number;
  /** Quote trade type. */
  tradeType: string;
  /** Match ID. */
  matchId: string;
  /** UTC agreement time. */
  agreementTimeUtc: Date | undefined;
}

/**
 * Market data values of a contract.
 * The market values are sent after subscription and when there are changes not reflected in quotes.
 * Note that market values do not duplicate the updates sent as quotes. E.g. customers are expected
 * to maintain total_volume, tick_volume and OHLC based on quotes and quote indicators.
 * If both quotes and market_values are sent in a single RealTimeMarketData update (is_snapshot=False),
 * then the order of applying quotes and market_values to a user snapshot doesn't matter.
 */
export interface MarketValues {
  /** First price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledOpenPrice: number;
  /** Highest price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledHighPrice: number;
  /** Lowest price among all tick types from ContractMetadata.bar_building_tick_types. */
  scaledLowPrice: number;
  /**
   * Left for backward compatibility. Sometimes we provide in this field 'scaled_close_price',
   * sometimes - 'scaled_last_price_no_settlement'.
   * Note: use 'scaled_last_price_no_settlement'/'scaled_close_price' instead.
   *
   * @deprecated
   */
  scaledLastPrice: number;
  /**
   * Close price.
   * Details can be found in enum CloseSource and field ContractMetadata.close_sources from metadata_2.proto file.
   * Contains any last received price, including settlement price.
   */
  scaledClosePrice: number;
  /**
   * Latest price among all tick types from metadata_2.ContractMetadata.bar_building_tick_types,
   * except for BAR_BUILDING_TICK_TYPE_SETTLEMENT. Settlements don't affect last_price.
   * Contains any last received price, excluding settlement price.
   * Can be unavailable for delayed data.
   */
  scaledLastPriceNoSettlement: number;
  /**
   * Exchange close price.
   * Can be unavailable for delayed data.
   */
  scaledExchangeClosePrice: number;
  /**
   * Yesterday settlement price.
   * NOTE: Available for current trading day only.
   */
  scaledYesterdaySettlement: number;
  /**
   * Note: use 'total_volume' instead.
   *
   * @deprecated
   */
  scaledTotalVolume: number;
  /** Contract total volume. */
  totalVolume: Decimal | undefined;
  /**
   * Yesterday last price.
   * Available for current trading day only.
   * Note: Left for backward compatibility.
   * Sometimes this field contains scaled yesterday close price, sometimes - scaled yesterday last.
   * Use MarketValues with day_index=-1 and 'scaled_last_price_no_settlement'/'scaled_close_price' instead.
   *
   * @deprecated
   */
  scaledYesterdayLast: number;
  /** Indicative open price. */
  scaledIndicativeOpen: number;
  /**
   * Note: use 'indicative_open_volume' instead.
   *
   * @deprecated
   */
  scaledIndicativeOpenVolume: number;
  /** Indicative open volume. */
  indicativeOpenVolume: Decimal | undefined;
  /**
   * Day index the market values are related to.
   * 0 - most recent trading day, -1 - trading day before most recent trading day, etc.
   * When new trading day starts, WebAPI sends real-time market data snapshot.
   */
  dayIndex: number;
  /**
   * Note: use 'open_interest' instead.
   *
   * @deprecated
   */
  scaledOpenInterest: number;
  /** Open interest. */
  openInterest: Decimal | undefined;
  /** Contract tick volume. */
  tickVolume: number;
  /** Settlement price. */
  scaledSettlement: number;
  /**
   * Marker price. Also known as PTMMM, Pre-Trade Mid-Market Mark.
   * For cryptocurrency contracts it contains mark price.
   */
  scaledMarkerPrice: number;
  /** Last trade price. */
  scaledLastTradePrice: number;
  /** Last trade volume. */
  lastTradeVolume: Decimal | undefined;
  /**
   * Identifiers of fields being cleared.
   * It also specifies to clear corresponding yield values in market_yields.
   */
  clearedFields: number[];
  /** Trading date the market values belong to. */
  tradeDate: number;
  /**
   * Session index the market values are related to.
   * 0 - first session of the day, 1 - second session of the day, etc.
   */
  sessionIndex: number;
  /**
   * Market yields.
   * Included if subscribed and the contract support yields.
   */
  marketYields: MarketYields | undefined;
  /**
   * Currency price used to calculate scaled prices in this message.
   * correct_price_scale from currency rate contract should be used to get correct price.
   */
  scaledCurrencyRatePrice: number;
}

/** Market yields of a contract. */
export interface MarketYields {
  /** Yield corresponding to open price. */
  yieldOfOpenPrice: number;
  /** Yield corresponding to high price. */
  yieldOfHighPrice: number;
  /** Yield corresponding to low price. */
  yieldOfLowPrice: number;
  /**
   * Yield corresponding to last price.
   *
   * @deprecated
   */
  yieldOfLastPrice: number;
  /** Yield corresponding to close price. */
  yieldOfClosePrice: number;
  /** Yield corresponding to last price without settlement. */
  yieldOfLastPriceNoSettlement: number;
  /** Yield corresponding to exchange close price. */
  yieldOfExchangeClosePrice: number;
  /**
   * Yesterday settlement yield.
   * NOTE: Available for current trading day only.
   */
  yieldOfYesterdaySettlement: number;
  /**
   * Yesterday last yield.
   * NOTE: Available for current trading day only.
   *
   * @deprecated
   */
  yieldOfYesterdayLast: number;
  /** Indicative open yield. */
  yieldOfIndicativeOpen: number;
  /** Settlement yield. */
  yieldOfSettlement: number;
}

/** Request at-the-money strike for the option maturity. */
export interface AtTheMoneyStrikeRequest {
  /**
   * Identifier of the option maturity representing option strike list of the same underlying and maturity period
   * (see OptionMaturityMetadata.id).
   */
  optionMaturityId: string;
}

/** Report with at-the-money strikes. */
export interface AtTheMoneyStrikeReport {
  /**
   * Option at-the-money strike.
   * It is omitted if at-the-money strike cannot be calculated at the moment (e.g. due to lack of real time data).
   */
  strike: number;
}

/**
 * Describes separate orders at price levels.
 * Detailed DOM updates (snapshot or not) can come in several DetailedDOM messages. The first will have
 * RealTimeMarketData.is_snapshot set, the rest will come as updates. The last will have
 * DetailedDOM.is_detailed_dom_complete set.
 * Update for a price level cannot be split into several messages.
 * Updates must be applied in the order they listed in.
 */
export interface DetailedDOM {
  /** True if this message is the last in the series of DetailedDOM updates (snapshot or not) for a contract. */
  isDetailedDomComplete: boolean;
  /**
   * True if the DOM is stale, i.e. has become obsolete and is not being updated at the moment.
   * A new DetailedDOM message with is_stale = false will be sent when data is fixed.
   */
  isStale: boolean;
  /** If true, the data for this message should be entirely replaced with 'price_levels' contents. */
  isSnapshot: boolean;
  /** Price level updates. */
  priceLevels: DetailedDOMAtPrice[];
}

/** Either snapshot of orders at a price level or incremental update. */
export interface DetailedDOMAtPrice {
  /**
   * Price level described by this message.
   * Price is absent for No-price Orders.
   */
  scaledPrice: number;
  /**
   * Side of this price level. SIDE_BUY or SIDE_SELL.
   * This field is associated with Order.Side enum type.
   */
  side: number;
  /** If true, the data for this price level should be entirely replaced with 'orders' contents. */
  isSnapshot: boolean;
  /** All orders at the price level, if is_snapshot is true, or changes to the price level, otherwise. */
  orders: DetailedDOMOrder[];
}

/** A single order or change to an existing order (depending on DetailedDOMAtPrice.is_snapshot). */
export interface DetailedDOMOrder {
  /**
   * Same as OrderStatus.detailed_dom_order_id.
   * The field is also non-empty for other users' orders. So, to determine user's place in orders
   * queue user must check if this is his order using the contract_id and detailed_dom_order_id pair.
   */
  detailedDomOrderId: string;
  /** Associated with Operation enum. */
  operation: number;
  /**
   * Order Index at the price level.
   * It is the index after all updates up to this one have been applied.
   * The index is specified only if it cannot be deduced:
   *  - for INSERT operation it is omitted if equals order_index in previous DetailedDOMOrder plus 1;
   *  - for MODIFY operation it is specified if order place relative to other orders in a price level changed.
   */
  orderIndex: number;
  /**
   * Note: use 'volume' instead.
   *
   * @deprecated
   */
  scaledVolume: number;
  /** Order volume, if changed or new order. */
  volume: Decimal | undefined;
  /** If True, then order is Implied. */
  implied: boolean;
  /** Contributor id from ContributorMetadata. */
  contributorId: number;
}

export enum DetailedDOMOrder_Operation {
  /** OPERATION_INSERT - A new order is added. */
  OPERATION_INSERT = 0,
  /** OPERATION_REMOVE - An existing order is removed. */
  OPERATION_REMOVE = 1,
  /** OPERATION_MODIFY - An existing order is modified but remains at the same price level. */
  OPERATION_MODIFY = 2,
  /** OPERATION_MOVE_FROM_PRICE - An existing order is removed from its current price level to another one. */
  OPERATION_MOVE_FROM_PRICE = 3,
  /**
   * OPERATION_MOVE_TO_PRICE - An existing order is inserted into its new price level.
   * Note, some fields can also change.
   * Note, relative order of OPERATION_MOVE_FROM_PRICE and OPERATION_MOVE_TO_PRICE is not defined.
   */
  OPERATION_MOVE_TO_PRICE = 4,
  UNRECOGNIZED = -1,
}

export function detailedDOMOrder_OperationFromJSON(object: any): DetailedDOMOrder_Operation {
  switch (object) {
    case 0:
    case 'OPERATION_INSERT':
      return DetailedDOMOrder_Operation.OPERATION_INSERT;
    case 1:
    case 'OPERATION_REMOVE':
      return DetailedDOMOrder_Operation.OPERATION_REMOVE;
    case 2:
    case 'OPERATION_MODIFY':
      return DetailedDOMOrder_Operation.OPERATION_MODIFY;
    case 3:
    case 'OPERATION_MOVE_FROM_PRICE':
      return DetailedDOMOrder_Operation.OPERATION_MOVE_FROM_PRICE;
    case 4:
    case 'OPERATION_MOVE_TO_PRICE':
      return DetailedDOMOrder_Operation.OPERATION_MOVE_TO_PRICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DetailedDOMOrder_Operation.UNRECOGNIZED;
  }
}

export function detailedDOMOrder_OperationToJSON(object: DetailedDOMOrder_Operation): string {
  switch (object) {
    case DetailedDOMOrder_Operation.OPERATION_INSERT:
      return 'OPERATION_INSERT';
    case DetailedDOMOrder_Operation.OPERATION_REMOVE:
      return 'OPERATION_REMOVE';
    case DetailedDOMOrder_Operation.OPERATION_MODIFY:
      return 'OPERATION_MODIFY';
    case DetailedDOMOrder_Operation.OPERATION_MOVE_FROM_PRICE:
      return 'OPERATION_MOVE_FROM_PRICE';
    case DetailedDOMOrder_Operation.OPERATION_MOVE_TO_PRICE:
      return 'OPERATION_MOVE_TO_PRICE';
    case DetailedDOMOrder_Operation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for quotation (RFQ). */
export interface RFQ {
  /** Exchange ID of the request. */
  exchangeRequestId: string;
  /** Timestamp provided by exchange (UTC). */
  utcTimestamp: Date | undefined;
  /**
   * Specifies side of active RFQ.
   * This field is associated with Side enum type.
   */
  side: number;
  /** RFQ volume. */
  volume: Decimal | undefined;
  /** Expiration time provided by exchange (UTC). */
  expirationUtcTimestamp: Date | undefined;
}

/** Side of RFQ. */
export enum RFQ_Side {
  /** SIDE_ANY - Any. */
  SIDE_ANY = 0,
  /** SIDE_BID - Bid. */
  SIDE_BID = 1,
  /** SIDE_ASK - Ask. */
  SIDE_ASK = 2,
  /** SIDE_CROSS - Cross. */
  SIDE_CROSS = 3,
  UNRECOGNIZED = -1,
}

export function rFQ_SideFromJSON(object: any): RFQ_Side {
  switch (object) {
    case 0:
    case 'SIDE_ANY':
      return RFQ_Side.SIDE_ANY;
    case 1:
    case 'SIDE_BID':
      return RFQ_Side.SIDE_BID;
    case 2:
    case 'SIDE_ASK':
      return RFQ_Side.SIDE_ASK;
    case 3:
    case 'SIDE_CROSS':
      return RFQ_Side.SIDE_CROSS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RFQ_Side.UNRECOGNIZED;
  }
}

export function rFQ_SideToJSON(object: RFQ_Side): string {
  switch (object) {
    case RFQ_Side.SIDE_ANY:
      return 'SIDE_ANY';
    case RFQ_Side.SIDE_BID:
      return 'SIDE_BID';
    case RFQ_Side.SIDE_ASK:
      return 'SIDE_ASK';
    case RFQ_Side.SIDE_CROSS:
      return 'SIDE_CROSS';
    case RFQ_Side.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** RFQ request. Used to create RFQ on market. */
export interface RFQRequest {
  /**
   * Client ID of the request.
   * It is a required field.
   */
  clientRequestId: number;
  /**
   * Id of an account that is used to request quote.
   * It is a required field.
   */
  accountId: number;
  /**
   * Contract id.
   * It is a required field.
   */
  contractId: number;
  /**
   * Is the request entered manually.
   * False or omitted means that request is created by automated system.
   */
  isManual: boolean;
  /**
   * Specifies side of requested quote. Default value is RFQ.SIDE_ANY.
   * This field is associated with RFQ.Side enum type.
   */
  side: number;
  /** Specifies size of requested quote. Unspecified value means "doesn't matter". */
  size: Decimal | undefined;
  /** Time when RFQ should be created (UTC). */
  executionUtcTimestamp: Date | undefined;
  /**
   * Regulatory Algorithm ID for client algorithm associated with this request, if any.
   * This field is applicable only for exchanges that use it, e.g. Eurex.
   */
  clientRegulatoryAlgorithmId: number;
  /**
   * Quote request expiration date/time (UTC).
   * Note: there is no way to cancel RFQ. The only way to manage its lifetime is to set expiration_utc_timestamp.
   */
  expirationUtcTimestamp: Date | undefined;
}

/** Result of RFQ request. */
export interface RFQReport {
  /**
   * Client ID of the request.
   * It is a required field.
   */
  clientRequestId: number;
  /**
   * Exchange ID of the request.
   * It's set if status_code = STATUS_CODE_SUCCESS.
   */
  exchangeRequestId: string;
  /**
   * Status code.
   * This field is associated with StatusCode enum type.
   */
  statusCode: number;
  /** Optional failure details. */
  details: Text | undefined;
  /**
   * Regulatory Algorithm ID actually sent to the exchange for this request, if any.
   * Will differ from client_regulatory_algorithm_id if any CQG algorithm was applied to the order.
   */
  effectiveRegulatoryAlgorithmId: number;
}

export enum RFQReport_StatusCode {
  /** STATUS_CODE_SUCCESS - / Success codes (0 - 99) */
  STATUS_CODE_SUCCESS = 0,
  /**
   * STATUS_CODE_FAILURE - / Failure codes (100+)
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Request parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT - RFQ is not supported for specified contract. */
  STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT = 103,
  UNRECOGNIZED = -1,
}

export function rFQReport_StatusCodeFromJSON(object: any): RFQReport_StatusCode {
  switch (object) {
    case 0:
    case 'STATUS_CODE_SUCCESS':
      return RFQReport_StatusCode.STATUS_CODE_SUCCESS;
    case 101:
    case 'STATUS_CODE_FAILURE':
      return RFQReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case 'STATUS_CODE_INVALID_PARAMS':
      return RFQReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case 'STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT':
      return RFQReport_StatusCode.STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RFQReport_StatusCode.UNRECOGNIZED;
  }
}

export function rFQReport_StatusCodeToJSON(object: RFQReport_StatusCode): string {
  switch (object) {
    case RFQReport_StatusCode.STATUS_CODE_SUCCESS:
      return 'STATUS_CODE_SUCCESS';
    case RFQReport_StatusCode.STATUS_CODE_FAILURE:
      return 'STATUS_CODE_FAILURE';
    case RFQReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return 'STATUS_CODE_INVALID_PARAMS';
    case RFQReport_StatusCode.STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT:
      return 'STATUS_CODE_NOT_SUPPORTED_FOR_CONTRACT';
    case RFQReport_StatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Specifies market state for placing orders.
 * If empty then state is temporary unknown.
 */
export interface MarketState {
  /**
   * Current trading session state and allowed order actions.
   * If omitted then there are exchange specific market state changes that do not affect the trading state.
   */
  tradingState: TradingState | undefined;
  /** Array of exchange specific market state elements. */
  exchangeSpecifics: ExchangeSpecificMarketState[];
  /** True if this is a snapshot (previously known market state for this contract should be cleaned). */
  isSnapshot: boolean;
}

/** Specifies trading session state and allowed order actions. */
export interface TradingState {
  /**
   * Indicates the current exchange state.
   * This field is associated with ExchangeState enum type.
   * If omitted then exchange's provided state information is out of generic ExchangeState enum list.
   * Clients should use ExchangeSpecificMarketState to get detailed exchange state information.
   */
  exchangeState: number;
  /**
   * Indicates that order placing is allowed.
   * Note that exchanges may have more detailed rules limiting the type of order, duration, etc. that is allowed.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowPlaceOrder: boolean;
  /**
   * Indicates that order canceling is allowed.
   * Note that exchanges may have more detailed rules limiting which orders may be canceled.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowCancelOrder: boolean;
  /**
   * Indicates that order modification is allowed.
   * Note that exchanges may have more detailed rules limiting which modifies are allowed.
   * Clients should use ExchangeSpecificMarketState if they need to implement/display those detailed rules.
   */
  allowModifyOrder: boolean;
  /** Indicates that continuous trading is occurring at the exchange. */
  matchingEnabled: boolean;
}

/**
 * Indicates CQG's normalized exchange trading session state.
 * This is result of a best-effort mapping from each exchange's provided state information to a small set
 * of generic states.
 */
export enum TradingState_ExchangeState {
  /**
   * EXCHANGE_STATE_PRE_OPEN - The state outside of continuous trading, can include uncrossing/auction periods where only certain order
   * actions are allowed. Note that CQG uses this state for pre-open, opening auction, and closing auction.
   */
  EXCHANGE_STATE_PRE_OPEN = 1,
  /** EXCHANGE_STATE_OPEN - The state indicating continuous trading. */
  EXCHANGE_STATE_OPEN = 2,
  /** EXCHANGE_STATE_CLOSED - The state when continuous trading and all closing auctions have ended. */
  EXCHANGE_STATE_CLOSED = 3,
  /** EXCHANGE_STATE_HALTED - The state when continuous trading is halted, usually unscheduled. */
  EXCHANGE_STATE_HALTED = 4,
  /** EXCHANGE_STATE_SUSPENDED - The state when trading is suspended, similar to halt but for an indeterminate period. */
  EXCHANGE_STATE_SUSPENDED = 5,
  UNRECOGNIZED = -1,
}

export function tradingState_ExchangeStateFromJSON(object: any): TradingState_ExchangeState {
  switch (object) {
    case 1:
    case 'EXCHANGE_STATE_PRE_OPEN':
      return TradingState_ExchangeState.EXCHANGE_STATE_PRE_OPEN;
    case 2:
    case 'EXCHANGE_STATE_OPEN':
      return TradingState_ExchangeState.EXCHANGE_STATE_OPEN;
    case 3:
    case 'EXCHANGE_STATE_CLOSED':
      return TradingState_ExchangeState.EXCHANGE_STATE_CLOSED;
    case 4:
    case 'EXCHANGE_STATE_HALTED':
      return TradingState_ExchangeState.EXCHANGE_STATE_HALTED;
    case 5:
    case 'EXCHANGE_STATE_SUSPENDED':
      return TradingState_ExchangeState.EXCHANGE_STATE_SUSPENDED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TradingState_ExchangeState.UNRECOGNIZED;
  }
}

export function tradingState_ExchangeStateToJSON(object: TradingState_ExchangeState): string {
  switch (object) {
    case TradingState_ExchangeState.EXCHANGE_STATE_PRE_OPEN:
      return 'EXCHANGE_STATE_PRE_OPEN';
    case TradingState_ExchangeState.EXCHANGE_STATE_OPEN:
      return 'EXCHANGE_STATE_OPEN';
    case TradingState_ExchangeState.EXCHANGE_STATE_CLOSED:
      return 'EXCHANGE_STATE_CLOSED';
    case TradingState_ExchangeState.EXCHANGE_STATE_HALTED:
      return 'EXCHANGE_STATE_HALTED';
    case TradingState_ExchangeState.EXCHANGE_STATE_SUSPENDED:
      return 'EXCHANGE_STATE_SUSPENDED';
    case TradingState_ExchangeState.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Exchange specific market state element represented by tag/value pair.
 * Array of the elements describes entire market state.
 */
export interface ExchangeSpecificMarketState {
  /** The market state element tag. Not all exchanges provide it. */
  tag: string;
  /** The market state element value. */
  value: string;
}

/** Parameters of OptionCalculationRequest */
export interface OptionCalculationParameters {
  /**
   * Identifier of the option maturity representing option strike list of the same underlying and maturity period
   * (see OptionMaturityMetadata.id).
   */
  optionMaturityId: string;
  /** Server side contract identifiers. Leave it empty to request all strikes for the given maturity. */
  strikeContractIds: number[];
  /** Optional OTC contributor ID for contributor specific data subscriptions. */
  contributorId: string;
}

/** Request for Greeks and other option calculations */
export interface OptionCalculationRequest {
  /** ID of the request. Cannot be empty. */
  requestId: number;
  /** Request parameters. */
  optionCalculationParameters: OptionCalculationParameters | undefined;
  /**
   * This field is associated with RequestType enum type.
   * REQUEST_TYPE_SUBSCRIBE operation is processed by default.
   */
  requestType: number;
}

/** Type of request action. */
export enum OptionCalculationRequest_RequestType {
  /** REQUEST_TYPE_SUBSCRIBE - Calculation request with subscription for updates. */
  REQUEST_TYPE_SUBSCRIBE = 2,
  /** REQUEST_TYPE_DROP - Drop subscription request and unsubscribe. */
  REQUEST_TYPE_DROP = 3,
  UNRECOGNIZED = -1,
}

export function optionCalculationRequest_RequestTypeFromJSON(object: any): OptionCalculationRequest_RequestType {
  switch (object) {
    case 2:
    case 'REQUEST_TYPE_SUBSCRIBE':
      return OptionCalculationRequest_RequestType.REQUEST_TYPE_SUBSCRIBE;
    case 3:
    case 'REQUEST_TYPE_DROP':
      return OptionCalculationRequest_RequestType.REQUEST_TYPE_DROP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OptionCalculationRequest_RequestType.UNRECOGNIZED;
  }
}

export function optionCalculationRequest_RequestTypeToJSON(object: OptionCalculationRequest_RequestType): string {
  switch (object) {
    case OptionCalculationRequest_RequestType.REQUEST_TYPE_SUBSCRIBE:
      return 'REQUEST_TYPE_SUBSCRIBE';
    case OptionCalculationRequest_RequestType.REQUEST_TYPE_DROP:
      return 'REQUEST_TYPE_DROP';
    case OptionCalculationRequest_RequestType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Calculated values */
export interface OptionCalculationValues {
  /** Server side contract id. */
  strikeContractId: number;
  /** The most recent price of the underlying instrument at the time of the option contract price used in calculations. */
  scaledCoherentUnderlyingPrice: number;
  /** Calculated Implied Volatility in percents. */
  impliedVolatility: number;
  /** Calculated Theoretical Price in correct price format. */
  theov: number;
  /**
   * Calculated Delta Greek value in percents.
   * Represents the ratio of the change in the correct theoretical price
   * over the change in the correct underlying price.
   */
  delta: number;
  /**
   * Calculated Gamma Greek value in percents.
   * Represents Delta Greek change which occurs if correct underlying price increases by 1.
   * Correct underlying price can be calculated by multiplying OptionCalculationReport.scaled_underlying_price
   * by correct_price_scale of the underlying contract.
   */
  gamma: number;
  /**
   * Calculated Vega Greek value.
   * Represents correct theoretical price change which occurs if Implied Volatility increases by 1%.
   */
  vega: number;
  /**
   * Calculated Theta Greek value.
   * Represents correct theoretical price change which occurs if number of days to expiration decreases by one day.
   */
  theta: number;
  /**
   * Calculated Rho Greek value.
   * Represents correct theoretical price change which occurs if Interest Rate increases by 1%.
   */
  rho: number;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only strike_contract_id will be provided in this case.
   */
  deleted: boolean;
}

/** Option calculation response */
export interface OptionCalculationReport {
  /** Request ID. */
  requestId: number;
  /**
   * Subscription result.
   * This field is associated with StatusCode enum type.
   */
  statusCode: number;
  /** Timestamp of the latest event that caused calculations included in this report. */
  upToUtcTime: number;
  /**
   * Interest Rate is the latest price of the most active future contract determined
   * by the currency of the option contract. E.g. interest rate for options traded
   * in USD is the latest price of the most active Eurodollar Futures.
   * Interest Rate within a range (0;1], where 1 means 100%, 0.1 means 10%.
   */
  interestRate: number;
  /** Latest quote price of the underlying instrument at the moment of calculations. */
  scaledUnderlyingPrice: number;
  /** Calculated values. */
  values: OptionCalculationValues[];
  /** Optional failure details. */
  details: Text | undefined;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
}

export enum OptionCalculationReport_StatusCode {
  /**
   * STATUS_CODE_SUBSCRIBED - / success codes (1 - 99)
   * Request is processed and subscription is established.
   */
  STATUS_CODE_SUBSCRIBED = 1,
  /** STATUS_CODE_DROPPED - The subscription is dropped by the client. */
  STATUS_CODE_DROPPED = 2,
  /** STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  STATUS_CODE_UPDATE = 3,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected. Possible reasons: communication issues, metadata update.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending STATUS_CODE_SUBSCRIBED status.
   */
  STATUS_CODE_DISCONNECTED = 4,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+)
   * general failure
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Subscription parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_ACCESS_DENIED - The user is not allowed to be subscribed to this Instrument. */
  STATUS_CODE_ACCESS_DENIED = 103,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The subscription count limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 104,
  /** STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_DELETED - Market data source was deleted because of expiration or for another reason. */
  STATUS_CODE_DELETED = 106,
  /** STATUS_CODE_CONTRIBUTOR_REQUIRED - OTC Contributor ID is required for this subscription but was not provided or not authorized. */
  STATUS_CODE_CONTRIBUTOR_REQUIRED = 107,
  UNRECOGNIZED = -1,
}

export function optionCalculationReport_StatusCodeFromJSON(object: any): OptionCalculationReport_StatusCode {
  switch (object) {
    case 1:
    case 'STATUS_CODE_SUBSCRIBED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIBED;
    case 2:
    case 'STATUS_CODE_DROPPED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_DROPPED;
    case 3:
    case 'STATUS_CODE_UPDATE':
      return OptionCalculationReport_StatusCode.STATUS_CODE_UPDATE;
    case 4:
    case 'STATUS_CODE_DISCONNECTED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case 'STATUS_CODE_FAILURE':
      return OptionCalculationReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case 'STATUS_CODE_INVALID_PARAMS':
      return OptionCalculationReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case 'STATUS_CODE_ACCESS_DENIED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_ACCESS_DENIED;
    case 104:
    case 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION':
      return OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 105:
    case 'STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION':
      return OptionCalculationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 106:
    case 'STATUS_CODE_DELETED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_DELETED;
    case 107:
    case 'STATUS_CODE_CONTRIBUTOR_REQUIRED':
      return OptionCalculationReport_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OptionCalculationReport_StatusCode.UNRECOGNIZED;
  }
}

export function optionCalculationReport_StatusCodeToJSON(object: OptionCalculationReport_StatusCode): string {
  switch (object) {
    case OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIBED:
      return 'STATUS_CODE_SUBSCRIBED';
    case OptionCalculationReport_StatusCode.STATUS_CODE_DROPPED:
      return 'STATUS_CODE_DROPPED';
    case OptionCalculationReport_StatusCode.STATUS_CODE_UPDATE:
      return 'STATUS_CODE_UPDATE';
    case OptionCalculationReport_StatusCode.STATUS_CODE_DISCONNECTED:
      return 'STATUS_CODE_DISCONNECTED';
    case OptionCalculationReport_StatusCode.STATUS_CODE_FAILURE:
      return 'STATUS_CODE_FAILURE';
    case OptionCalculationReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return 'STATUS_CODE_INVALID_PARAMS';
    case OptionCalculationReport_StatusCode.STATUS_CODE_ACCESS_DENIED:
      return 'STATUS_CODE_ACCESS_DENIED';
    case OptionCalculationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION';
    case OptionCalculationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return 'STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION';
    case OptionCalculationReport_StatusCode.STATUS_CODE_DELETED:
      return 'STATUS_CODE_DELETED';
    case OptionCalculationReport_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED:
      return 'STATUS_CODE_CONTRIBUTOR_REQUIRED';
    case OptionCalculationReport_StatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseMarketDataSubscription(): MarketDataSubscription {
  return {
    contractId: 0,
    requestId: 0,
    level: 0,
    includePastMarketValues: false,
    includePastQuotes: false,
    includeSessionMarketValues: false,
    includeYields: false,
    includeSourcePrices: false,
    includeRequestsForQuotation: false,
    includeTradeAttributes: false,
    includeMarketState: false,
    includeOffMarketTrades: false,
    contributorId: '',
    includeCurrencyRate: false,
    domSubscriptionType: 0,
    includePremium: false,
  };
}

export const MarketDataSubscription = {
  encode(message: MarketDataSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.requestId !== 0) {
      writer.uint32(112).uint32(message.requestId);
    }
    if (message.level !== 0) {
      writer.uint32(16).uint32(message.level);
    }
    if (message.includePastMarketValues === true) {
      writer.uint32(24).bool(message.includePastMarketValues);
    }
    if (message.includePastQuotes === true) {
      writer.uint32(40).bool(message.includePastQuotes);
    }
    if (message.includeSessionMarketValues === true) {
      writer.uint32(72).bool(message.includeSessionMarketValues);
    }
    if (message.includeYields === true) {
      writer.uint32(64).bool(message.includeYields);
    }
    if (message.includeSourcePrices === true) {
      writer.uint32(80).bool(message.includeSourcePrices);
    }
    if (message.includeRequestsForQuotation === true) {
      writer.uint32(88).bool(message.includeRequestsForQuotation);
    }
    if (message.includeTradeAttributes === true) {
      writer.uint32(96).bool(message.includeTradeAttributes);
    }
    if (message.includeMarketState === true) {
      writer.uint32(104).bool(message.includeMarketState);
    }
    if (message.includeOffMarketTrades === true) {
      writer.uint32(120).bool(message.includeOffMarketTrades);
    }
    if (message.contributorId !== '') {
      writer.uint32(58).string(message.contributorId);
    }
    if (message.includeCurrencyRate === true) {
      writer.uint32(128).bool(message.includeCurrencyRate);
    }
    if (message.domSubscriptionType !== 0) {
      writer.uint32(136).uint32(message.domSubscriptionType);
    }
    if (message.includePremium === true) {
      writer.uint32(144).bool(message.includePremium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketDataSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 14:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.level = reader.uint32();
          break;
        case 3:
          message.includePastMarketValues = reader.bool();
          break;
        case 5:
          message.includePastQuotes = reader.bool();
          break;
        case 9:
          message.includeSessionMarketValues = reader.bool();
          break;
        case 8:
          message.includeYields = reader.bool();
          break;
        case 10:
          message.includeSourcePrices = reader.bool();
          break;
        case 11:
          message.includeRequestsForQuotation = reader.bool();
          break;
        case 12:
          message.includeTradeAttributes = reader.bool();
          break;
        case 13:
          message.includeMarketState = reader.bool();
          break;
        case 15:
          message.includeOffMarketTrades = reader.bool();
          break;
        case 7:
          message.contributorId = reader.string();
          break;
        case 16:
          message.includeCurrencyRate = reader.bool();
          break;
        case 17:
          message.domSubscriptionType = reader.uint32();
          break;
        case 18:
          message.includePremium = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketDataSubscription {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      level: isSet(object.level) ? Number(object.level) : 0,
      includePastMarketValues: isSet(object.includePastMarketValues) ? Boolean(object.includePastMarketValues) : false,
      includePastQuotes: isSet(object.includePastQuotes) ? Boolean(object.includePastQuotes) : false,
      includeSessionMarketValues: isSet(object.includeSessionMarketValues) ? Boolean(object.includeSessionMarketValues) : false,
      includeYields: isSet(object.includeYields) ? Boolean(object.includeYields) : false,
      includeSourcePrices: isSet(object.includeSourcePrices) ? Boolean(object.includeSourcePrices) : false,
      includeRequestsForQuotation: isSet(object.includeRequestsForQuotation)
        ? Boolean(object.includeRequestsForQuotation)
        : false,
      includeTradeAttributes: isSet(object.includeTradeAttributes) ? Boolean(object.includeTradeAttributes) : false,
      includeMarketState: isSet(object.includeMarketState) ? Boolean(object.includeMarketState) : false,
      includeOffMarketTrades: isSet(object.includeOffMarketTrades) ? Boolean(object.includeOffMarketTrades) : false,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      includeCurrencyRate: isSet(object.includeCurrencyRate) ? Boolean(object.includeCurrencyRate) : false,
      domSubscriptionType: isSet(object.domSubscriptionType) ? Number(object.domSubscriptionType) : 0,
      includePremium: isSet(object.includePremium) ? Boolean(object.includePremium) : false,
    };
  },

  toJSON(message: MarketDataSubscription): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.level !== undefined && (obj.level = Math.round(message.level));
    message.includePastMarketValues !== undefined && (obj.includePastMarketValues = message.includePastMarketValues);
    message.includePastQuotes !== undefined && (obj.includePastQuotes = message.includePastQuotes);
    message.includeSessionMarketValues !== undefined && (obj.includeSessionMarketValues = message.includeSessionMarketValues);
    message.includeYields !== undefined && (obj.includeYields = message.includeYields);
    message.includeSourcePrices !== undefined && (obj.includeSourcePrices = message.includeSourcePrices);
    message.includeRequestsForQuotation !== undefined && (obj.includeRequestsForQuotation = message.includeRequestsForQuotation);
    message.includeTradeAttributes !== undefined && (obj.includeTradeAttributes = message.includeTradeAttributes);
    message.includeMarketState !== undefined && (obj.includeMarketState = message.includeMarketState);
    message.includeOffMarketTrades !== undefined && (obj.includeOffMarketTrades = message.includeOffMarketTrades);
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    message.includeCurrencyRate !== undefined && (obj.includeCurrencyRate = message.includeCurrencyRate);
    message.domSubscriptionType !== undefined && (obj.domSubscriptionType = Math.round(message.domSubscriptionType));
    message.includePremium !== undefined && (obj.includePremium = message.includePremium);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketDataSubscription>, I>>(base?: I): MarketDataSubscription {
    return MarketDataSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketDataSubscription>, I>>(object: I): MarketDataSubscription {
    const message = createBaseMarketDataSubscription();
    message.contractId = object.contractId ?? 0;
    message.requestId = object.requestId ?? 0;
    message.level = object.level ?? 0;
    message.includePastMarketValues = object.includePastMarketValues ?? false;
    message.includePastQuotes = object.includePastQuotes ?? false;
    message.includeSessionMarketValues = object.includeSessionMarketValues ?? false;
    message.includeYields = object.includeYields ?? false;
    message.includeSourcePrices = object.includeSourcePrices ?? false;
    message.includeRequestsForQuotation = object.includeRequestsForQuotation ?? false;
    message.includeTradeAttributes = object.includeTradeAttributes ?? false;
    message.includeMarketState = object.includeMarketState ?? false;
    message.includeOffMarketTrades = object.includeOffMarketTrades ?? false;
    message.contributorId = object.contributorId ?? '';
    message.includeCurrencyRate = object.includeCurrencyRate ?? false;
    message.domSubscriptionType = object.domSubscriptionType ?? 0;
    message.includePremium = object.includePremium ?? false;
    return message;
  },
};

function createBaseMarketDataSubscriptionStatus(): MarketDataSubscriptionStatus {
  return {
    contractId: 0,
    statusCode: 0,
    level: 0,
    textMessage: '',
    details: undefined,
    pastQuotesIncluded: false,
    sessionMarketValuesIncluded: false,
    yieldsIncluded: false,
    sourcePriceIncluded: false,
    requestsForQuotationIncluded: false,
    tradeAttributesIncluded: false,
    marketStateIncluded: false,
    offMarketTradesIncluded: false,
    currencyRateIncluded: false,
    requestId: 0,
    contributorId: '',
    actualDomSubscriptionType: 0,
    premiumIncluded: false,
  };
}

export const MarketDataSubscriptionStatus = {
  encode(message: MarketDataSubscriptionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.level !== 0) {
      writer.uint32(24).uint32(message.level);
    }
    if (message.textMessage !== '') {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(146).fork()).ldelim();
    }
    if (message.pastQuotesIncluded === true) {
      writer.uint32(48).bool(message.pastQuotesIncluded);
    }
    if (message.sessionMarketValuesIncluded === true) {
      writer.uint32(80).bool(message.sessionMarketValuesIncluded);
    }
    if (message.yieldsIncluded === true) {
      writer.uint32(72).bool(message.yieldsIncluded);
    }
    if (message.sourcePriceIncluded === true) {
      writer.uint32(88).bool(message.sourcePriceIncluded);
    }
    if (message.requestsForQuotationIncluded === true) {
      writer.uint32(96).bool(message.requestsForQuotationIncluded);
    }
    if (message.tradeAttributesIncluded === true) {
      writer.uint32(104).bool(message.tradeAttributesIncluded);
    }
    if (message.marketStateIncluded === true) {
      writer.uint32(112).bool(message.marketStateIncluded);
    }
    if (message.offMarketTradesIncluded === true) {
      writer.uint32(128).bool(message.offMarketTradesIncluded);
    }
    if (message.currencyRateIncluded === true) {
      writer.uint32(136).bool(message.currencyRateIncluded);
    }
    if (message.requestId !== 0) {
      writer.uint32(120).uint32(message.requestId);
    }
    if (message.contributorId !== '') {
      writer.uint32(66).string(message.contributorId);
    }
    if (message.actualDomSubscriptionType !== 0) {
      writer.uint32(152).uint32(message.actualDomSubscriptionType);
    }
    if (message.premiumIncluded === true) {
      writer.uint32(160).bool(message.premiumIncluded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketDataSubscriptionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketDataSubscriptionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.statusCode = reader.uint32();
          break;
        case 3:
          message.level = reader.uint32();
          break;
        case 4:
          message.textMessage = reader.string();
          break;
        case 18:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 6:
          message.pastQuotesIncluded = reader.bool();
          break;
        case 10:
          message.sessionMarketValuesIncluded = reader.bool();
          break;
        case 9:
          message.yieldsIncluded = reader.bool();
          break;
        case 11:
          message.sourcePriceIncluded = reader.bool();
          break;
        case 12:
          message.requestsForQuotationIncluded = reader.bool();
          break;
        case 13:
          message.tradeAttributesIncluded = reader.bool();
          break;
        case 14:
          message.marketStateIncluded = reader.bool();
          break;
        case 16:
          message.offMarketTradesIncluded = reader.bool();
          break;
        case 17:
          message.currencyRateIncluded = reader.bool();
          break;
        case 15:
          message.requestId = reader.uint32();
          break;
        case 8:
          message.contributorId = reader.string();
          break;
        case 19:
          message.actualDomSubscriptionType = reader.uint32();
          break;
        case 20:
          message.premiumIncluded = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketDataSubscriptionStatus {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      level: isSet(object.level) ? Number(object.level) : 0,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      pastQuotesIncluded: isSet(object.pastQuotesIncluded) ? Boolean(object.pastQuotesIncluded) : false,
      sessionMarketValuesIncluded: isSet(object.sessionMarketValuesIncluded)
        ? Boolean(object.sessionMarketValuesIncluded)
        : false,
      yieldsIncluded: isSet(object.yieldsIncluded) ? Boolean(object.yieldsIncluded) : false,
      sourcePriceIncluded: isSet(object.sourcePriceIncluded) ? Boolean(object.sourcePriceIncluded) : false,
      requestsForQuotationIncluded: isSet(object.requestsForQuotationIncluded)
        ? Boolean(object.requestsForQuotationIncluded)
        : false,
      tradeAttributesIncluded: isSet(object.tradeAttributesIncluded) ? Boolean(object.tradeAttributesIncluded) : false,
      marketStateIncluded: isSet(object.marketStateIncluded) ? Boolean(object.marketStateIncluded) : false,
      offMarketTradesIncluded: isSet(object.offMarketTradesIncluded) ? Boolean(object.offMarketTradesIncluded) : false,
      currencyRateIncluded: isSet(object.currencyRateIncluded) ? Boolean(object.currencyRateIncluded) : false,
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      actualDomSubscriptionType: isSet(object.actualDomSubscriptionType) ? Number(object.actualDomSubscriptionType) : 0,
      premiumIncluded: isSet(object.premiumIncluded) ? Boolean(object.premiumIncluded) : false,
    };
  },

  toJSON(message: MarketDataSubscriptionStatus): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.level !== undefined && (obj.level = Math.round(message.level));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.pastQuotesIncluded !== undefined && (obj.pastQuotesIncluded = message.pastQuotesIncluded);
    message.sessionMarketValuesIncluded !== undefined && (obj.sessionMarketValuesIncluded = message.sessionMarketValuesIncluded);
    message.yieldsIncluded !== undefined && (obj.yieldsIncluded = message.yieldsIncluded);
    message.sourcePriceIncluded !== undefined && (obj.sourcePriceIncluded = message.sourcePriceIncluded);
    message.requestsForQuotationIncluded !== undefined &&
      (obj.requestsForQuotationIncluded = message.requestsForQuotationIncluded);
    message.tradeAttributesIncluded !== undefined && (obj.tradeAttributesIncluded = message.tradeAttributesIncluded);
    message.marketStateIncluded !== undefined && (obj.marketStateIncluded = message.marketStateIncluded);
    message.offMarketTradesIncluded !== undefined && (obj.offMarketTradesIncluded = message.offMarketTradesIncluded);
    message.currencyRateIncluded !== undefined && (obj.currencyRateIncluded = message.currencyRateIncluded);
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    message.actualDomSubscriptionType !== undefined &&
      (obj.actualDomSubscriptionType = Math.round(message.actualDomSubscriptionType));
    message.premiumIncluded !== undefined && (obj.premiumIncluded = message.premiumIncluded);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketDataSubscriptionStatus>, I>>(base?: I): MarketDataSubscriptionStatus {
    return MarketDataSubscriptionStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketDataSubscriptionStatus>, I>>(object: I): MarketDataSubscriptionStatus {
    const message = createBaseMarketDataSubscriptionStatus();
    message.contractId = object.contractId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.level = object.level ?? 0;
    message.textMessage = object.textMessage ?? '';
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.pastQuotesIncluded = object.pastQuotesIncluded ?? false;
    message.sessionMarketValuesIncluded = object.sessionMarketValuesIncluded ?? false;
    message.yieldsIncluded = object.yieldsIncluded ?? false;
    message.sourcePriceIncluded = object.sourcePriceIncluded ?? false;
    message.requestsForQuotationIncluded = object.requestsForQuotationIncluded ?? false;
    message.tradeAttributesIncluded = object.tradeAttributesIncluded ?? false;
    message.marketStateIncluded = object.marketStateIncluded ?? false;
    message.offMarketTradesIncluded = object.offMarketTradesIncluded ?? false;
    message.currencyRateIncluded = object.currencyRateIncluded ?? false;
    message.requestId = object.requestId ?? 0;
    message.contributorId = object.contributorId ?? '';
    message.actualDomSubscriptionType = object.actualDomSubscriptionType ?? 0;
    message.premiumIncluded = object.premiumIncluded ?? false;
    return message;
  },
};

function createBaseRealTimeMarketData(): RealTimeMarketData {
  return {
    contractId: 0,
    quotes: [],
    quotesTradeDate: 0,
    corrections: [],
    isSnapshot: false,
    collapsingLevel: 0,
    marketValues: [],
    detailedDom: undefined,
    requestsForQuotation: [],
    marketState: undefined,
    correctPriceScale: 0,
  };
}

export const RealTimeMarketData = {
  encode(message: RealTimeMarketData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    for (const v of message.quotes) {
      Quote.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.quotesTradeDate !== 0) {
      writer.uint32(144).sint64(message.quotesTradeDate);
    }
    for (const v of message.corrections) {
      Quote.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.isSnapshot === true) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    if (message.collapsingLevel !== 0) {
      writer.uint32(32).uint32(message.collapsingLevel);
    }
    for (const v of message.marketValues) {
      MarketValues.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.detailedDom !== undefined) {
      DetailedDOM.encode(message.detailedDom, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.requestsForQuotation) {
      RFQ.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.marketState !== undefined) {
      MarketState.encode(message.marketState, writer.uint32(138).fork()).ldelim();
    }
    if (message.correctPriceScale !== 0) {
      writer.uint32(153).double(message.correctPriceScale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RealTimeMarketData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRealTimeMarketData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.quotes.push(Quote.decode(reader, reader.uint32()));
          break;
        case 18:
          message.quotesTradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.corrections.push(Quote.decode(reader, reader.uint32()));
          break;
        case 3:
          message.isSnapshot = reader.bool();
          break;
        case 4:
          message.collapsingLevel = reader.uint32();
          break;
        case 5:
          message.marketValues.push(MarketValues.decode(reader, reader.uint32()));
          break;
        case 16:
          message.detailedDom = DetailedDOM.decode(reader, reader.uint32());
          break;
        case 6:
          message.requestsForQuotation.push(RFQ.decode(reader, reader.uint32()));
          break;
        case 17:
          message.marketState = MarketState.decode(reader, reader.uint32());
          break;
        case 19:
          message.correctPriceScale = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RealTimeMarketData {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      quotes: Array.isArray(object?.quotes) ? object.quotes.map((e: any) => Quote.fromJSON(e)) : [],
      quotesTradeDate: isSet(object.quotesTradeDate) ? Number(object.quotesTradeDate) : 0,
      corrections: Array.isArray(object?.corrections) ? object.corrections.map((e: any) => Quote.fromJSON(e)) : [],
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      collapsingLevel: isSet(object.collapsingLevel) ? Number(object.collapsingLevel) : 0,
      marketValues: Array.isArray(object?.marketValues) ? object.marketValues.map((e: any) => MarketValues.fromJSON(e)) : [],
      detailedDom: isSet(object.detailedDom) ? DetailedDOM.fromJSON(object.detailedDom) : undefined,
      requestsForQuotation: Array.isArray(object?.requestsForQuotation)
        ? object.requestsForQuotation.map((e: any) => RFQ.fromJSON(e))
        : [],
      marketState: isSet(object.marketState) ? MarketState.fromJSON(object.marketState) : undefined,
      correctPriceScale: isSet(object.correctPriceScale) ? Number(object.correctPriceScale) : 0,
    };
  },

  toJSON(message: RealTimeMarketData): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    if (message.quotes) {
      obj.quotes = message.quotes.map((e) => (e ? Quote.toJSON(e) : undefined));
    } else {
      obj.quotes = [];
    }
    message.quotesTradeDate !== undefined && (obj.quotesTradeDate = Math.round(message.quotesTradeDate));
    if (message.corrections) {
      obj.corrections = message.corrections.map((e) => (e ? Quote.toJSON(e) : undefined));
    } else {
      obj.corrections = [];
    }
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    message.collapsingLevel !== undefined && (obj.collapsingLevel = Math.round(message.collapsingLevel));
    if (message.marketValues) {
      obj.marketValues = message.marketValues.map((e) => (e ? MarketValues.toJSON(e) : undefined));
    } else {
      obj.marketValues = [];
    }
    message.detailedDom !== undefined &&
      (obj.detailedDom = message.detailedDom ? DetailedDOM.toJSON(message.detailedDom) : undefined);
    if (message.requestsForQuotation) {
      obj.requestsForQuotation = message.requestsForQuotation.map((e) => (e ? RFQ.toJSON(e) : undefined));
    } else {
      obj.requestsForQuotation = [];
    }
    message.marketState !== undefined &&
      (obj.marketState = message.marketState ? MarketState.toJSON(message.marketState) : undefined);
    message.correctPriceScale !== undefined && (obj.correctPriceScale = message.correctPriceScale);
    return obj;
  },

  create<I extends Exact<DeepPartial<RealTimeMarketData>, I>>(base?: I): RealTimeMarketData {
    return RealTimeMarketData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RealTimeMarketData>, I>>(object: I): RealTimeMarketData {
    const message = createBaseRealTimeMarketData();
    message.contractId = object.contractId ?? 0;
    message.quotes = object.quotes?.map((e) => Quote.fromPartial(e)) || [];
    message.quotesTradeDate = object.quotesTradeDate ?? 0;
    message.corrections = object.corrections?.map((e) => Quote.fromPartial(e)) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    message.collapsingLevel = object.collapsingLevel ?? 0;
    message.marketValues = object.marketValues?.map((e) => MarketValues.fromPartial(e)) || [];
    message.detailedDom =
      object.detailedDom !== undefined && object.detailedDom !== null ? DetailedDOM.fromPartial(object.detailedDom) : undefined;
    message.requestsForQuotation = object.requestsForQuotation?.map((e) => RFQ.fromPartial(e)) || [];
    message.marketState =
      object.marketState !== undefined && object.marketState !== null ? MarketState.fromPartial(object.marketState) : undefined;
    message.correctPriceScale = object.correctPriceScale ?? 0;
    return message;
  },
};

function createBaseQuote(): Quote {
  return {
    type: 0,
    quoteUtcTime: 0,
    scaledPrice: 0,
    scaledSourcePrice: 0,
    priceYield: 0,
    scaledVolume: 0,
    volume: undefined,
    indicators: [],
    salesCondition: 0,
    tradeAttributes: undefined,
    scaledCurrencyRatePrice: 0,
    scaledPremium: 0,
  };
}

export const Quote = {
  encode(message: Quote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.quoteUtcTime !== 0) {
      writer.uint32(16).sint64(message.quoteUtcTime);
    }
    if (message.scaledPrice !== 0) {
      writer.uint32(24).sint32(message.scaledPrice);
    }
    if (message.scaledSourcePrice !== 0) {
      writer.uint32(64).sint32(message.scaledSourcePrice);
    }
    if (message.priceYield !== 0) {
      writer.uint32(49).double(message.priceYield);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(32).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(90).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.indicators) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.salesCondition !== 0) {
      writer.uint32(56).uint32(message.salesCondition);
    }
    if (message.tradeAttributes !== undefined) {
      TradeAttributes.encode(message.tradeAttributes, writer.uint32(74).fork()).ldelim();
    }
    if (message.scaledCurrencyRatePrice !== 0) {
      writer.uint32(80).sint32(message.scaledCurrencyRatePrice);
    }
    if (message.scaledPremium !== 0) {
      writer.uint32(96).sint32(message.scaledPremium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quote {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.uint32();
          break;
        case 2:
          message.quoteUtcTime = longToNumber(reader.sint64() as Long);
          break;
        case 3:
          message.scaledPrice = reader.sint32();
          break;
        case 8:
          message.scaledSourcePrice = reader.sint32();
          break;
        case 6:
          message.priceYield = reader.double();
          break;
        case 4:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 11:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.indicators.push(reader.uint32());
            }
          } else {
            message.indicators.push(reader.uint32());
          }
          break;
        case 7:
          message.salesCondition = reader.uint32();
          break;
        case 9:
          message.tradeAttributes = TradeAttributes.decode(reader, reader.uint32());
          break;
        case 10:
          message.scaledCurrencyRatePrice = reader.sint32();
          break;
        case 12:
          message.scaledPremium = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Quote {
    return {
      type: isSet(object.type) ? Number(object.type) : 0,
      quoteUtcTime: isSet(object.quoteUtcTime) ? Number(object.quoteUtcTime) : 0,
      scaledPrice: isSet(object.scaledPrice) ? Number(object.scaledPrice) : 0,
      scaledSourcePrice: isSet(object.scaledSourcePrice) ? Number(object.scaledSourcePrice) : 0,
      priceYield: isSet(object.priceYield) ? Number(object.priceYield) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      indicators: Array.isArray(object?.indicators) ? object.indicators.map((e: any) => Number(e)) : [],
      salesCondition: isSet(object.salesCondition) ? Number(object.salesCondition) : 0,
      tradeAttributes: isSet(object.tradeAttributes) ? TradeAttributes.fromJSON(object.tradeAttributes) : undefined,
      scaledCurrencyRatePrice: isSet(object.scaledCurrencyRatePrice) ? Number(object.scaledCurrencyRatePrice) : 0,
      scaledPremium: isSet(object.scaledPremium) ? Number(object.scaledPremium) : 0,
    };
  },

  toJSON(message: Quote): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = Math.round(message.type));
    message.quoteUtcTime !== undefined && (obj.quoteUtcTime = Math.round(message.quoteUtcTime));
    message.scaledPrice !== undefined && (obj.scaledPrice = Math.round(message.scaledPrice));
    message.scaledSourcePrice !== undefined && (obj.scaledSourcePrice = Math.round(message.scaledSourcePrice));
    message.priceYield !== undefined && (obj.priceYield = message.priceYield);
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    if (message.indicators) {
      obj.indicators = message.indicators.map((e) => Math.round(e));
    } else {
      obj.indicators = [];
    }
    message.salesCondition !== undefined && (obj.salesCondition = Math.round(message.salesCondition));
    message.tradeAttributes !== undefined &&
      (obj.tradeAttributes = message.tradeAttributes ? TradeAttributes.toJSON(message.tradeAttributes) : undefined);
    message.scaledCurrencyRatePrice !== undefined && (obj.scaledCurrencyRatePrice = Math.round(message.scaledCurrencyRatePrice));
    message.scaledPremium !== undefined && (obj.scaledPremium = Math.round(message.scaledPremium));
    return obj;
  },

  create<I extends Exact<DeepPartial<Quote>, I>>(base?: I): Quote {
    return Quote.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quote>, I>>(object: I): Quote {
    const message = createBaseQuote();
    message.type = object.type ?? 0;
    message.quoteUtcTime = object.quoteUtcTime ?? 0;
    message.scaledPrice = object.scaledPrice ?? 0;
    message.scaledSourcePrice = object.scaledSourcePrice ?? 0;
    message.priceYield = object.priceYield ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.indicators = object.indicators?.map((e) => e) || [];
    message.salesCondition = object.salesCondition ?? 0;
    message.tradeAttributes =
      object.tradeAttributes !== undefined && object.tradeAttributes !== null
        ? TradeAttributes.fromPartial(object.tradeAttributes)
        : undefined;
    message.scaledCurrencyRatePrice = object.scaledCurrencyRatePrice ?? 0;
    message.scaledPremium = object.scaledPremium ?? 0;
    return message;
  },
};

function createBaseTradeAttributes(): TradeAttributes {
  return { buyer: 0, seller: 0, tradeType: '', matchId: '', agreementTimeUtc: undefined };
}

export const TradeAttributes = {
  encode(message: TradeAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.buyer !== 0) {
      writer.uint32(8).sint32(message.buyer);
    }
    if (message.seller !== 0) {
      writer.uint32(16).sint32(message.seller);
    }
    if (message.tradeType !== '') {
      writer.uint32(26).string(message.tradeType);
    }
    if (message.matchId !== '') {
      writer.uint32(34).string(message.matchId);
    }
    if (message.agreementTimeUtc !== undefined) {
      Timestamp.encode(toTimestamp(message.agreementTimeUtc), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeAttributes {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyer = reader.sint32();
          break;
        case 2:
          message.seller = reader.sint32();
          break;
        case 3:
          message.tradeType = reader.string();
          break;
        case 4:
          message.matchId = reader.string();
          break;
        case 5:
          message.agreementTimeUtc = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradeAttributes {
    return {
      buyer: isSet(object.buyer) ? Number(object.buyer) : 0,
      seller: isSet(object.seller) ? Number(object.seller) : 0,
      tradeType: isSet(object.tradeType) ? String(object.tradeType) : '',
      matchId: isSet(object.matchId) ? String(object.matchId) : '',
      agreementTimeUtc: isSet(object.agreementTimeUtc) ? fromJsonTimestamp(object.agreementTimeUtc) : undefined,
    };
  },

  toJSON(message: TradeAttributes): unknown {
    const obj: any = {};
    message.buyer !== undefined && (obj.buyer = Math.round(message.buyer));
    message.seller !== undefined && (obj.seller = Math.round(message.seller));
    message.tradeType !== undefined && (obj.tradeType = message.tradeType);
    message.matchId !== undefined && (obj.matchId = message.matchId);
    message.agreementTimeUtc !== undefined && (obj.agreementTimeUtc = message.agreementTimeUtc.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeAttributes>, I>>(base?: I): TradeAttributes {
    return TradeAttributes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradeAttributes>, I>>(object: I): TradeAttributes {
    const message = createBaseTradeAttributes();
    message.buyer = object.buyer ?? 0;
    message.seller = object.seller ?? 0;
    message.tradeType = object.tradeType ?? '';
    message.matchId = object.matchId ?? '';
    message.agreementTimeUtc = object.agreementTimeUtc ?? undefined;
    return message;
  },
};

function createBaseMarketValues(): MarketValues {
  return {
    scaledOpenPrice: 0,
    scaledHighPrice: 0,
    scaledLowPrice: 0,
    scaledLastPrice: 0,
    scaledClosePrice: 0,
    scaledLastPriceNoSettlement: 0,
    scaledExchangeClosePrice: 0,
    scaledYesterdaySettlement: 0,
    scaledTotalVolume: 0,
    totalVolume: undefined,
    scaledYesterdayLast: 0,
    scaledIndicativeOpen: 0,
    scaledIndicativeOpenVolume: 0,
    indicativeOpenVolume: undefined,
    dayIndex: 0,
    scaledOpenInterest: 0,
    openInterest: undefined,
    tickVolume: 0,
    scaledSettlement: 0,
    scaledMarkerPrice: 0,
    scaledLastTradePrice: 0,
    lastTradeVolume: undefined,
    clearedFields: [],
    tradeDate: 0,
    sessionIndex: 0,
    marketYields: undefined,
    scaledCurrencyRatePrice: 0,
  };
}

export const MarketValues = {
  encode(message: MarketValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledOpenPrice !== 0) {
      writer.uint32(8).sint32(message.scaledOpenPrice);
    }
    if (message.scaledHighPrice !== 0) {
      writer.uint32(16).sint32(message.scaledHighPrice);
    }
    if (message.scaledLowPrice !== 0) {
      writer.uint32(24).sint32(message.scaledLowPrice);
    }
    if (message.scaledLastPrice !== 0) {
      writer.uint32(32).sint32(message.scaledLastPrice);
    }
    if (message.scaledClosePrice !== 0) {
      writer.uint32(200).sint32(message.scaledClosePrice);
    }
    if (message.scaledLastPriceNoSettlement !== 0) {
      writer.uint32(208).sint32(message.scaledLastPriceNoSettlement);
    }
    if (message.scaledExchangeClosePrice !== 0) {
      writer.uint32(216).sint64(message.scaledExchangeClosePrice);
    }
    if (message.scaledYesterdaySettlement !== 0) {
      writer.uint32(40).sint32(message.scaledYesterdaySettlement);
    }
    if (message.scaledTotalVolume !== 0) {
      writer.uint32(48).uint64(message.scaledTotalVolume);
    }
    if (message.totalVolume !== undefined) {
      Decimal.encode(message.totalVolume, writer.uint32(170).fork()).ldelim();
    }
    if (message.scaledYesterdayLast !== 0) {
      writer.uint32(56).sint32(message.scaledYesterdayLast);
    }
    if (message.scaledIndicativeOpen !== 0) {
      writer.uint32(64).sint32(message.scaledIndicativeOpen);
    }
    if (message.scaledIndicativeOpenVolume !== 0) {
      writer.uint32(120).uint64(message.scaledIndicativeOpenVolume);
    }
    if (message.indicativeOpenVolume !== undefined) {
      Decimal.encode(message.indicativeOpenVolume, writer.uint32(178).fork()).ldelim();
    }
    if (message.dayIndex !== 0) {
      writer.uint32(72).sint32(message.dayIndex);
    }
    if (message.scaledOpenInterest !== 0) {
      writer.uint32(80).sint64(message.scaledOpenInterest);
    }
    if (message.openInterest !== undefined) {
      Decimal.encode(message.openInterest, writer.uint32(186).fork()).ldelim();
    }
    if (message.tickVolume !== 0) {
      writer.uint32(88).uint32(message.tickVolume);
    }
    if (message.scaledSettlement !== 0) {
      writer.uint32(96).sint32(message.scaledSettlement);
    }
    if (message.scaledMarkerPrice !== 0) {
      writer.uint32(144).sint32(message.scaledMarkerPrice);
    }
    if (message.scaledLastTradePrice !== 0) {
      writer.uint32(152).sint32(message.scaledLastTradePrice);
    }
    if (message.lastTradeVolume !== undefined) {
      Decimal.encode(message.lastTradeVolume, writer.uint32(194).fork()).ldelim();
    }
    writer.uint32(106).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.tradeDate !== 0) {
      writer.uint32(112).sint64(message.tradeDate);
    }
    if (message.sessionIndex !== 0) {
      writer.uint32(136).uint32(message.sessionIndex);
    }
    if (message.marketYields !== undefined) {
      MarketYields.encode(message.marketYields, writer.uint32(130).fork()).ldelim();
    }
    if (message.scaledCurrencyRatePrice !== 0) {
      writer.uint32(160).sint32(message.scaledCurrencyRatePrice);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketValues {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scaledOpenPrice = reader.sint32();
          break;
        case 2:
          message.scaledHighPrice = reader.sint32();
          break;
        case 3:
          message.scaledLowPrice = reader.sint32();
          break;
        case 4:
          message.scaledLastPrice = reader.sint32();
          break;
        case 25:
          message.scaledClosePrice = reader.sint32();
          break;
        case 26:
          message.scaledLastPriceNoSettlement = reader.sint32();
          break;
        case 27:
          message.scaledExchangeClosePrice = longToNumber(reader.sint64() as Long);
          break;
        case 5:
          message.scaledYesterdaySettlement = reader.sint32();
          break;
        case 6:
          message.scaledTotalVolume = longToNumber(reader.uint64() as Long);
          break;
        case 21:
          message.totalVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.scaledYesterdayLast = reader.sint32();
          break;
        case 8:
          message.scaledIndicativeOpen = reader.sint32();
          break;
        case 15:
          message.scaledIndicativeOpenVolume = longToNumber(reader.uint64() as Long);
          break;
        case 22:
          message.indicativeOpenVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.dayIndex = reader.sint32();
          break;
        case 10:
          message.scaledOpenInterest = longToNumber(reader.sint64() as Long);
          break;
        case 23:
          message.openInterest = Decimal.decode(reader, reader.uint32());
          break;
        case 11:
          message.tickVolume = reader.uint32();
          break;
        case 12:
          message.scaledSettlement = reader.sint32();
          break;
        case 18:
          message.scaledMarkerPrice = reader.sint32();
          break;
        case 19:
          message.scaledLastTradePrice = reader.sint32();
          break;
        case 24:
          message.lastTradeVolume = Decimal.decode(reader, reader.uint32());
          break;
        case 13:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }
          } else {
            message.clearedFields.push(reader.uint32());
          }
          break;
        case 14:
          message.tradeDate = longToNumber(reader.sint64() as Long);
          break;
        case 17:
          message.sessionIndex = reader.uint32();
          break;
        case 16:
          message.marketYields = MarketYields.decode(reader, reader.uint32());
          break;
        case 20:
          message.scaledCurrencyRatePrice = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketValues {
    return {
      scaledOpenPrice: isSet(object.scaledOpenPrice) ? Number(object.scaledOpenPrice) : 0,
      scaledHighPrice: isSet(object.scaledHighPrice) ? Number(object.scaledHighPrice) : 0,
      scaledLowPrice: isSet(object.scaledLowPrice) ? Number(object.scaledLowPrice) : 0,
      scaledLastPrice: isSet(object.scaledLastPrice) ? Number(object.scaledLastPrice) : 0,
      scaledClosePrice: isSet(object.scaledClosePrice) ? Number(object.scaledClosePrice) : 0,
      scaledLastPriceNoSettlement: isSet(object.scaledLastPriceNoSettlement) ? Number(object.scaledLastPriceNoSettlement) : 0,
      scaledExchangeClosePrice: isSet(object.scaledExchangeClosePrice) ? Number(object.scaledExchangeClosePrice) : 0,
      scaledYesterdaySettlement: isSet(object.scaledYesterdaySettlement) ? Number(object.scaledYesterdaySettlement) : 0,
      scaledTotalVolume: isSet(object.scaledTotalVolume) ? Number(object.scaledTotalVolume) : 0,
      totalVolume: isSet(object.totalVolume) ? Decimal.fromJSON(object.totalVolume) : undefined,
      scaledYesterdayLast: isSet(object.scaledYesterdayLast) ? Number(object.scaledYesterdayLast) : 0,
      scaledIndicativeOpen: isSet(object.scaledIndicativeOpen) ? Number(object.scaledIndicativeOpen) : 0,
      scaledIndicativeOpenVolume: isSet(object.scaledIndicativeOpenVolume) ? Number(object.scaledIndicativeOpenVolume) : 0,
      indicativeOpenVolume: isSet(object.indicativeOpenVolume) ? Decimal.fromJSON(object.indicativeOpenVolume) : undefined,
      dayIndex: isSet(object.dayIndex) ? Number(object.dayIndex) : 0,
      scaledOpenInterest: isSet(object.scaledOpenInterest) ? Number(object.scaledOpenInterest) : 0,
      openInterest: isSet(object.openInterest) ? Decimal.fromJSON(object.openInterest) : undefined,
      tickVolume: isSet(object.tickVolume) ? Number(object.tickVolume) : 0,
      scaledSettlement: isSet(object.scaledSettlement) ? Number(object.scaledSettlement) : 0,
      scaledMarkerPrice: isSet(object.scaledMarkerPrice) ? Number(object.scaledMarkerPrice) : 0,
      scaledLastTradePrice: isSet(object.scaledLastTradePrice) ? Number(object.scaledLastTradePrice) : 0,
      lastTradeVolume: isSet(object.lastTradeVolume) ? Decimal.fromJSON(object.lastTradeVolume) : undefined,
      clearedFields: Array.isArray(object?.clearedFields) ? object.clearedFields.map((e: any) => Number(e)) : [],
      tradeDate: isSet(object.tradeDate) ? Number(object.tradeDate) : 0,
      sessionIndex: isSet(object.sessionIndex) ? Number(object.sessionIndex) : 0,
      marketYields: isSet(object.marketYields) ? MarketYields.fromJSON(object.marketYields) : undefined,
      scaledCurrencyRatePrice: isSet(object.scaledCurrencyRatePrice) ? Number(object.scaledCurrencyRatePrice) : 0,
    };
  },

  toJSON(message: MarketValues): unknown {
    const obj: any = {};
    message.scaledOpenPrice !== undefined && (obj.scaledOpenPrice = Math.round(message.scaledOpenPrice));
    message.scaledHighPrice !== undefined && (obj.scaledHighPrice = Math.round(message.scaledHighPrice));
    message.scaledLowPrice !== undefined && (obj.scaledLowPrice = Math.round(message.scaledLowPrice));
    message.scaledLastPrice !== undefined && (obj.scaledLastPrice = Math.round(message.scaledLastPrice));
    message.scaledClosePrice !== undefined && (obj.scaledClosePrice = Math.round(message.scaledClosePrice));
    message.scaledLastPriceNoSettlement !== undefined &&
      (obj.scaledLastPriceNoSettlement = Math.round(message.scaledLastPriceNoSettlement));
    message.scaledExchangeClosePrice !== undefined &&
      (obj.scaledExchangeClosePrice = Math.round(message.scaledExchangeClosePrice));
    message.scaledYesterdaySettlement !== undefined &&
      (obj.scaledYesterdaySettlement = Math.round(message.scaledYesterdaySettlement));
    message.scaledTotalVolume !== undefined && (obj.scaledTotalVolume = Math.round(message.scaledTotalVolume));
    message.totalVolume !== undefined &&
      (obj.totalVolume = message.totalVolume ? Decimal.toJSON(message.totalVolume) : undefined);
    message.scaledYesterdayLast !== undefined && (obj.scaledYesterdayLast = Math.round(message.scaledYesterdayLast));
    message.scaledIndicativeOpen !== undefined && (obj.scaledIndicativeOpen = Math.round(message.scaledIndicativeOpen));
    message.scaledIndicativeOpenVolume !== undefined &&
      (obj.scaledIndicativeOpenVolume = Math.round(message.scaledIndicativeOpenVolume));
    message.indicativeOpenVolume !== undefined &&
      (obj.indicativeOpenVolume = message.indicativeOpenVolume ? Decimal.toJSON(message.indicativeOpenVolume) : undefined);
    message.dayIndex !== undefined && (obj.dayIndex = Math.round(message.dayIndex));
    message.scaledOpenInterest !== undefined && (obj.scaledOpenInterest = Math.round(message.scaledOpenInterest));
    message.openInterest !== undefined &&
      (obj.openInterest = message.openInterest ? Decimal.toJSON(message.openInterest) : undefined);
    message.tickVolume !== undefined && (obj.tickVolume = Math.round(message.tickVolume));
    message.scaledSettlement !== undefined && (obj.scaledSettlement = Math.round(message.scaledSettlement));
    message.scaledMarkerPrice !== undefined && (obj.scaledMarkerPrice = Math.round(message.scaledMarkerPrice));
    message.scaledLastTradePrice !== undefined && (obj.scaledLastTradePrice = Math.round(message.scaledLastTradePrice));
    message.lastTradeVolume !== undefined &&
      (obj.lastTradeVolume = message.lastTradeVolume ? Decimal.toJSON(message.lastTradeVolume) : undefined);
    if (message.clearedFields) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    } else {
      obj.clearedFields = [];
    }
    message.tradeDate !== undefined && (obj.tradeDate = Math.round(message.tradeDate));
    message.sessionIndex !== undefined && (obj.sessionIndex = Math.round(message.sessionIndex));
    message.marketYields !== undefined &&
      (obj.marketYields = message.marketYields ? MarketYields.toJSON(message.marketYields) : undefined);
    message.scaledCurrencyRatePrice !== undefined && (obj.scaledCurrencyRatePrice = Math.round(message.scaledCurrencyRatePrice));
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketValues>, I>>(base?: I): MarketValues {
    return MarketValues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketValues>, I>>(object: I): MarketValues {
    const message = createBaseMarketValues();
    message.scaledOpenPrice = object.scaledOpenPrice ?? 0;
    message.scaledHighPrice = object.scaledHighPrice ?? 0;
    message.scaledLowPrice = object.scaledLowPrice ?? 0;
    message.scaledLastPrice = object.scaledLastPrice ?? 0;
    message.scaledClosePrice = object.scaledClosePrice ?? 0;
    message.scaledLastPriceNoSettlement = object.scaledLastPriceNoSettlement ?? 0;
    message.scaledExchangeClosePrice = object.scaledExchangeClosePrice ?? 0;
    message.scaledYesterdaySettlement = object.scaledYesterdaySettlement ?? 0;
    message.scaledTotalVolume = object.scaledTotalVolume ?? 0;
    message.totalVolume =
      object.totalVolume !== undefined && object.totalVolume !== null ? Decimal.fromPartial(object.totalVolume) : undefined;
    message.scaledYesterdayLast = object.scaledYesterdayLast ?? 0;
    message.scaledIndicativeOpen = object.scaledIndicativeOpen ?? 0;
    message.scaledIndicativeOpenVolume = object.scaledIndicativeOpenVolume ?? 0;
    message.indicativeOpenVolume =
      object.indicativeOpenVolume !== undefined && object.indicativeOpenVolume !== null
        ? Decimal.fromPartial(object.indicativeOpenVolume)
        : undefined;
    message.dayIndex = object.dayIndex ?? 0;
    message.scaledOpenInterest = object.scaledOpenInterest ?? 0;
    message.openInterest =
      object.openInterest !== undefined && object.openInterest !== null ? Decimal.fromPartial(object.openInterest) : undefined;
    message.tickVolume = object.tickVolume ?? 0;
    message.scaledSettlement = object.scaledSettlement ?? 0;
    message.scaledMarkerPrice = object.scaledMarkerPrice ?? 0;
    message.scaledLastTradePrice = object.scaledLastTradePrice ?? 0;
    message.lastTradeVolume =
      object.lastTradeVolume !== undefined && object.lastTradeVolume !== null
        ? Decimal.fromPartial(object.lastTradeVolume)
        : undefined;
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.tradeDate = object.tradeDate ?? 0;
    message.sessionIndex = object.sessionIndex ?? 0;
    message.marketYields =
      object.marketYields !== undefined && object.marketYields !== null
        ? MarketYields.fromPartial(object.marketYields)
        : undefined;
    message.scaledCurrencyRatePrice = object.scaledCurrencyRatePrice ?? 0;
    return message;
  },
};

function createBaseMarketYields(): MarketYields {
  return {
    yieldOfOpenPrice: 0,
    yieldOfHighPrice: 0,
    yieldOfLowPrice: 0,
    yieldOfLastPrice: 0,
    yieldOfClosePrice: 0,
    yieldOfLastPriceNoSettlement: 0,
    yieldOfExchangeClosePrice: 0,
    yieldOfYesterdaySettlement: 0,
    yieldOfYesterdayLast: 0,
    yieldOfIndicativeOpen: 0,
    yieldOfSettlement: 0,
  };
}

export const MarketYields = {
  encode(message: MarketYields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.yieldOfOpenPrice !== 0) {
      writer.uint32(9).double(message.yieldOfOpenPrice);
    }
    if (message.yieldOfHighPrice !== 0) {
      writer.uint32(17).double(message.yieldOfHighPrice);
    }
    if (message.yieldOfLowPrice !== 0) {
      writer.uint32(25).double(message.yieldOfLowPrice);
    }
    if (message.yieldOfLastPrice !== 0) {
      writer.uint32(33).double(message.yieldOfLastPrice);
    }
    if (message.yieldOfClosePrice !== 0) {
      writer.uint32(73).double(message.yieldOfClosePrice);
    }
    if (message.yieldOfLastPriceNoSettlement !== 0) {
      writer.uint32(81).double(message.yieldOfLastPriceNoSettlement);
    }
    if (message.yieldOfExchangeClosePrice !== 0) {
      writer.uint32(89).double(message.yieldOfExchangeClosePrice);
    }
    if (message.yieldOfYesterdaySettlement !== 0) {
      writer.uint32(41).double(message.yieldOfYesterdaySettlement);
    }
    if (message.yieldOfYesterdayLast !== 0) {
      writer.uint32(49).double(message.yieldOfYesterdayLast);
    }
    if (message.yieldOfIndicativeOpen !== 0) {
      writer.uint32(57).double(message.yieldOfIndicativeOpen);
    }
    if (message.yieldOfSettlement !== 0) {
      writer.uint32(65).double(message.yieldOfSettlement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketYields {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketYields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.yieldOfOpenPrice = reader.double();
          break;
        case 2:
          message.yieldOfHighPrice = reader.double();
          break;
        case 3:
          message.yieldOfLowPrice = reader.double();
          break;
        case 4:
          message.yieldOfLastPrice = reader.double();
          break;
        case 9:
          message.yieldOfClosePrice = reader.double();
          break;
        case 10:
          message.yieldOfLastPriceNoSettlement = reader.double();
          break;
        case 11:
          message.yieldOfExchangeClosePrice = reader.double();
          break;
        case 5:
          message.yieldOfYesterdaySettlement = reader.double();
          break;
        case 6:
          message.yieldOfYesterdayLast = reader.double();
          break;
        case 7:
          message.yieldOfIndicativeOpen = reader.double();
          break;
        case 8:
          message.yieldOfSettlement = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketYields {
    return {
      yieldOfOpenPrice: isSet(object.yieldOfOpenPrice) ? Number(object.yieldOfOpenPrice) : 0,
      yieldOfHighPrice: isSet(object.yieldOfHighPrice) ? Number(object.yieldOfHighPrice) : 0,
      yieldOfLowPrice: isSet(object.yieldOfLowPrice) ? Number(object.yieldOfLowPrice) : 0,
      yieldOfLastPrice: isSet(object.yieldOfLastPrice) ? Number(object.yieldOfLastPrice) : 0,
      yieldOfClosePrice: isSet(object.yieldOfClosePrice) ? Number(object.yieldOfClosePrice) : 0,
      yieldOfLastPriceNoSettlement: isSet(object.yieldOfLastPriceNoSettlement) ? Number(object.yieldOfLastPriceNoSettlement) : 0,
      yieldOfExchangeClosePrice: isSet(object.yieldOfExchangeClosePrice) ? Number(object.yieldOfExchangeClosePrice) : 0,
      yieldOfYesterdaySettlement: isSet(object.yieldOfYesterdaySettlement) ? Number(object.yieldOfYesterdaySettlement) : 0,
      yieldOfYesterdayLast: isSet(object.yieldOfYesterdayLast) ? Number(object.yieldOfYesterdayLast) : 0,
      yieldOfIndicativeOpen: isSet(object.yieldOfIndicativeOpen) ? Number(object.yieldOfIndicativeOpen) : 0,
      yieldOfSettlement: isSet(object.yieldOfSettlement) ? Number(object.yieldOfSettlement) : 0,
    };
  },

  toJSON(message: MarketYields): unknown {
    const obj: any = {};
    message.yieldOfOpenPrice !== undefined && (obj.yieldOfOpenPrice = message.yieldOfOpenPrice);
    message.yieldOfHighPrice !== undefined && (obj.yieldOfHighPrice = message.yieldOfHighPrice);
    message.yieldOfLowPrice !== undefined && (obj.yieldOfLowPrice = message.yieldOfLowPrice);
    message.yieldOfLastPrice !== undefined && (obj.yieldOfLastPrice = message.yieldOfLastPrice);
    message.yieldOfClosePrice !== undefined && (obj.yieldOfClosePrice = message.yieldOfClosePrice);
    message.yieldOfLastPriceNoSettlement !== undefined &&
      (obj.yieldOfLastPriceNoSettlement = message.yieldOfLastPriceNoSettlement);
    message.yieldOfExchangeClosePrice !== undefined && (obj.yieldOfExchangeClosePrice = message.yieldOfExchangeClosePrice);
    message.yieldOfYesterdaySettlement !== undefined && (obj.yieldOfYesterdaySettlement = message.yieldOfYesterdaySettlement);
    message.yieldOfYesterdayLast !== undefined && (obj.yieldOfYesterdayLast = message.yieldOfYesterdayLast);
    message.yieldOfIndicativeOpen !== undefined && (obj.yieldOfIndicativeOpen = message.yieldOfIndicativeOpen);
    message.yieldOfSettlement !== undefined && (obj.yieldOfSettlement = message.yieldOfSettlement);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketYields>, I>>(base?: I): MarketYields {
    return MarketYields.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketYields>, I>>(object: I): MarketYields {
    const message = createBaseMarketYields();
    message.yieldOfOpenPrice = object.yieldOfOpenPrice ?? 0;
    message.yieldOfHighPrice = object.yieldOfHighPrice ?? 0;
    message.yieldOfLowPrice = object.yieldOfLowPrice ?? 0;
    message.yieldOfLastPrice = object.yieldOfLastPrice ?? 0;
    message.yieldOfClosePrice = object.yieldOfClosePrice ?? 0;
    message.yieldOfLastPriceNoSettlement = object.yieldOfLastPriceNoSettlement ?? 0;
    message.yieldOfExchangeClosePrice = object.yieldOfExchangeClosePrice ?? 0;
    message.yieldOfYesterdaySettlement = object.yieldOfYesterdaySettlement ?? 0;
    message.yieldOfYesterdayLast = object.yieldOfYesterdayLast ?? 0;
    message.yieldOfIndicativeOpen = object.yieldOfIndicativeOpen ?? 0;
    message.yieldOfSettlement = object.yieldOfSettlement ?? 0;
    return message;
  },
};

function createBaseAtTheMoneyStrikeRequest(): AtTheMoneyStrikeRequest {
  return { optionMaturityId: '' };
}

export const AtTheMoneyStrikeRequest = {
  encode(message: AtTheMoneyStrikeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionMaturityId !== '') {
      writer.uint32(10).string(message.optionMaturityId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AtTheMoneyStrikeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAtTheMoneyStrikeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.optionMaturityId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AtTheMoneyStrikeRequest {
    return { optionMaturityId: isSet(object.optionMaturityId) ? String(object.optionMaturityId) : '' };
  },

  toJSON(message: AtTheMoneyStrikeRequest): unknown {
    const obj: any = {};
    message.optionMaturityId !== undefined && (obj.optionMaturityId = message.optionMaturityId);
    return obj;
  },

  create<I extends Exact<DeepPartial<AtTheMoneyStrikeRequest>, I>>(base?: I): AtTheMoneyStrikeRequest {
    return AtTheMoneyStrikeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AtTheMoneyStrikeRequest>, I>>(object: I): AtTheMoneyStrikeRequest {
    const message = createBaseAtTheMoneyStrikeRequest();
    message.optionMaturityId = object.optionMaturityId ?? '';
    return message;
  },
};

function createBaseAtTheMoneyStrikeReport(): AtTheMoneyStrikeReport {
  return { strike: 0 };
}

export const AtTheMoneyStrikeReport = {
  encode(message: AtTheMoneyStrikeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strike !== 0) {
      writer.uint32(8).sint32(message.strike);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AtTheMoneyStrikeReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAtTheMoneyStrikeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.strike = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AtTheMoneyStrikeReport {
    return { strike: isSet(object.strike) ? Number(object.strike) : 0 };
  },

  toJSON(message: AtTheMoneyStrikeReport): unknown {
    const obj: any = {};
    message.strike !== undefined && (obj.strike = Math.round(message.strike));
    return obj;
  },

  create<I extends Exact<DeepPartial<AtTheMoneyStrikeReport>, I>>(base?: I): AtTheMoneyStrikeReport {
    return AtTheMoneyStrikeReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AtTheMoneyStrikeReport>, I>>(object: I): AtTheMoneyStrikeReport {
    const message = createBaseAtTheMoneyStrikeReport();
    message.strike = object.strike ?? 0;
    return message;
  },
};

function createBaseDetailedDOM(): DetailedDOM {
  return { isDetailedDomComplete: false, isStale: false, isSnapshot: false, priceLevels: [] };
}

export const DetailedDOM = {
  encode(message: DetailedDOM, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isDetailedDomComplete === true) {
      writer.uint32(8).bool(message.isDetailedDomComplete);
    }
    if (message.isStale === true) {
      writer.uint32(16).bool(message.isStale);
    }
    if (message.isSnapshot === true) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    for (const v of message.priceLevels) {
      DetailedDOMAtPrice.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOM {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOM();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isDetailedDomComplete = reader.bool();
          break;
        case 2:
          message.isStale = reader.bool();
          break;
        case 3:
          message.isSnapshot = reader.bool();
          break;
        case 4:
          message.priceLevels.push(DetailedDOMAtPrice.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DetailedDOM {
    return {
      isDetailedDomComplete: isSet(object.isDetailedDomComplete) ? Boolean(object.isDetailedDomComplete) : false,
      isStale: isSet(object.isStale) ? Boolean(object.isStale) : false,
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      priceLevels: Array.isArray(object?.priceLevels) ? object.priceLevels.map((e: any) => DetailedDOMAtPrice.fromJSON(e)) : [],
    };
  },

  toJSON(message: DetailedDOM): unknown {
    const obj: any = {};
    message.isDetailedDomComplete !== undefined && (obj.isDetailedDomComplete = message.isDetailedDomComplete);
    message.isStale !== undefined && (obj.isStale = message.isStale);
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    if (message.priceLevels) {
      obj.priceLevels = message.priceLevels.map((e) => (e ? DetailedDOMAtPrice.toJSON(e) : undefined));
    } else {
      obj.priceLevels = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOM>, I>>(base?: I): DetailedDOM {
    return DetailedDOM.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetailedDOM>, I>>(object: I): DetailedDOM {
    const message = createBaseDetailedDOM();
    message.isDetailedDomComplete = object.isDetailedDomComplete ?? false;
    message.isStale = object.isStale ?? false;
    message.isSnapshot = object.isSnapshot ?? false;
    message.priceLevels = object.priceLevels?.map((e) => DetailedDOMAtPrice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDetailedDOMAtPrice(): DetailedDOMAtPrice {
  return { scaledPrice: 0, side: 0, isSnapshot: false, orders: [] };
}

export const DetailedDOMAtPrice = {
  encode(message: DetailedDOMAtPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.scaledPrice !== 0) {
      writer.uint32(8).sint32(message.scaledPrice);
    }
    if (message.side !== 0) {
      writer.uint32(16).uint32(message.side);
    }
    if (message.isSnapshot === true) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    for (const v of message.orders) {
      DetailedDOMOrder.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOMAtPrice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOMAtPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.scaledPrice = reader.sint32();
          break;
        case 2:
          message.side = reader.uint32();
          break;
        case 3:
          message.isSnapshot = reader.bool();
          break;
        case 4:
          message.orders.push(DetailedDOMOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DetailedDOMAtPrice {
    return {
      scaledPrice: isSet(object.scaledPrice) ? Number(object.scaledPrice) : 0,
      side: isSet(object.side) ? Number(object.side) : 0,
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      orders: Array.isArray(object?.orders) ? object.orders.map((e: any) => DetailedDOMOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: DetailedDOMAtPrice): unknown {
    const obj: any = {};
    message.scaledPrice !== undefined && (obj.scaledPrice = Math.round(message.scaledPrice));
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    if (message.orders) {
      obj.orders = message.orders.map((e) => (e ? DetailedDOMOrder.toJSON(e) : undefined));
    } else {
      obj.orders = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOMAtPrice>, I>>(base?: I): DetailedDOMAtPrice {
    return DetailedDOMAtPrice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetailedDOMAtPrice>, I>>(object: I): DetailedDOMAtPrice {
    const message = createBaseDetailedDOMAtPrice();
    message.scaledPrice = object.scaledPrice ?? 0;
    message.side = object.side ?? 0;
    message.isSnapshot = object.isSnapshot ?? false;
    message.orders = object.orders?.map((e) => DetailedDOMOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDetailedDOMOrder(): DetailedDOMOrder {
  return {
    detailedDomOrderId: '',
    operation: 0,
    orderIndex: 0,
    scaledVolume: 0,
    volume: undefined,
    implied: false,
    contributorId: 0,
  };
}

export const DetailedDOMOrder = {
  encode(message: DetailedDOMOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.detailedDomOrderId !== '') {
      writer.uint32(10).string(message.detailedDomOrderId);
    }
    if (message.operation !== 0) {
      writer.uint32(16).uint32(message.operation);
    }
    if (message.orderIndex !== 0) {
      writer.uint32(24).uint32(message.orderIndex);
    }
    if (message.scaledVolume !== 0) {
      writer.uint32(40).uint64(message.scaledVolume);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(58).fork()).ldelim();
    }
    if (message.implied === true) {
      writer.uint32(64).bool(message.implied);
    }
    if (message.contributorId !== 0) {
      writer.uint32(72).sint32(message.contributorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedDOMOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedDOMOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.detailedDomOrderId = reader.string();
          break;
        case 2:
          message.operation = reader.uint32();
          break;
        case 3:
          message.orderIndex = reader.uint32();
          break;
        case 5:
          message.scaledVolume = longToNumber(reader.uint64() as Long);
          break;
        case 7:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 8:
          message.implied = reader.bool();
          break;
        case 9:
          message.contributorId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DetailedDOMOrder {
    return {
      detailedDomOrderId: isSet(object.detailedDomOrderId) ? String(object.detailedDomOrderId) : '',
      operation: isSet(object.operation) ? Number(object.operation) : 0,
      orderIndex: isSet(object.orderIndex) ? Number(object.orderIndex) : 0,
      scaledVolume: isSet(object.scaledVolume) ? Number(object.scaledVolume) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      implied: isSet(object.implied) ? Boolean(object.implied) : false,
      contributorId: isSet(object.contributorId) ? Number(object.contributorId) : 0,
    };
  },

  toJSON(message: DetailedDOMOrder): unknown {
    const obj: any = {};
    message.detailedDomOrderId !== undefined && (obj.detailedDomOrderId = message.detailedDomOrderId);
    message.operation !== undefined && (obj.operation = Math.round(message.operation));
    message.orderIndex !== undefined && (obj.orderIndex = Math.round(message.orderIndex));
    message.scaledVolume !== undefined && (obj.scaledVolume = Math.round(message.scaledVolume));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.implied !== undefined && (obj.implied = message.implied);
    message.contributorId !== undefined && (obj.contributorId = Math.round(message.contributorId));
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedDOMOrder>, I>>(base?: I): DetailedDOMOrder {
    return DetailedDOMOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DetailedDOMOrder>, I>>(object: I): DetailedDOMOrder {
    const message = createBaseDetailedDOMOrder();
    message.detailedDomOrderId = object.detailedDomOrderId ?? '';
    message.operation = object.operation ?? 0;
    message.orderIndex = object.orderIndex ?? 0;
    message.scaledVolume = object.scaledVolume ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.implied = object.implied ?? false;
    message.contributorId = object.contributorId ?? 0;
    return message;
  },
};

function createBaseRFQ(): RFQ {
  return {
    exchangeRequestId: '',
    utcTimestamp: undefined,
    side: 0,
    volume: undefined,
    expirationUtcTimestamp: undefined,
  };
}

export const RFQ = {
  encode(message: RFQ, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeRequestId !== '') {
      writer.uint32(10).string(message.exchangeRequestId);
    }
    if (message.utcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.utcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.side !== 0) {
      writer.uint32(24).uint32(message.side);
    }
    if (message.volume !== undefined) {
      Decimal.encode(message.volume, writer.uint32(34).fork()).ldelim();
    }
    if (message.expirationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQ {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQ();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeRequestId = reader.string();
          break;
        case 2:
          message.utcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.side = reader.uint32();
          break;
        case 4:
          message.volume = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.expirationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RFQ {
    return {
      exchangeRequestId: isSet(object.exchangeRequestId) ? String(object.exchangeRequestId) : '',
      utcTimestamp: isSet(object.utcTimestamp) ? fromJsonTimestamp(object.utcTimestamp) : undefined,
      side: isSet(object.side) ? Number(object.side) : 0,
      volume: isSet(object.volume) ? Decimal.fromJSON(object.volume) : undefined,
      expirationUtcTimestamp: isSet(object.expirationUtcTimestamp) ? fromJsonTimestamp(object.expirationUtcTimestamp) : undefined,
    };
  },

  toJSON(message: RFQ): unknown {
    const obj: any = {};
    message.exchangeRequestId !== undefined && (obj.exchangeRequestId = message.exchangeRequestId);
    message.utcTimestamp !== undefined && (obj.utcTimestamp = message.utcTimestamp.toISOString());
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.volume !== undefined && (obj.volume = message.volume ? Decimal.toJSON(message.volume) : undefined);
    message.expirationUtcTimestamp !== undefined && (obj.expirationUtcTimestamp = message.expirationUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQ>, I>>(base?: I): RFQ {
    return RFQ.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RFQ>, I>>(object: I): RFQ {
    const message = createBaseRFQ();
    message.exchangeRequestId = object.exchangeRequestId ?? '';
    message.utcTimestamp = object.utcTimestamp ?? undefined;
    message.side = object.side ?? 0;
    message.volume = object.volume !== undefined && object.volume !== null ? Decimal.fromPartial(object.volume) : undefined;
    message.expirationUtcTimestamp = object.expirationUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseRFQRequest(): RFQRequest {
  return {
    clientRequestId: 0,
    accountId: 0,
    contractId: 0,
    isManual: false,
    side: 0,
    size: undefined,
    executionUtcTimestamp: undefined,
    clientRegulatoryAlgorithmId: 0,
    expirationUtcTimestamp: undefined,
  };
}

export const RFQRequest = {
  encode(message: RFQRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientRequestId !== 0) {
      writer.uint32(8).uint32(message.clientRequestId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(24).uint32(message.contractId);
    }
    if (message.isManual === true) {
      writer.uint32(32).bool(message.isManual);
    }
    if (message.side !== 0) {
      writer.uint32(40).uint32(message.side);
    }
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(50).fork()).ldelim();
    }
    if (message.executionUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.executionUtcTimestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.clientRegulatoryAlgorithmId !== 0) {
      writer.uint32(64).uint32(message.clientRegulatoryAlgorithmId);
    }
    if (message.expirationUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationUtcTimestamp), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientRequestId = reader.uint32();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.contractId = reader.uint32();
          break;
        case 4:
          message.isManual = reader.bool();
          break;
        case 5:
          message.side = reader.uint32();
          break;
        case 6:
          message.size = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.executionUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.clientRegulatoryAlgorithmId = reader.uint32();
          break;
        case 9:
          message.expirationUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RFQRequest {
    return {
      clientRequestId: isSet(object.clientRequestId) ? Number(object.clientRequestId) : 0,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      isManual: isSet(object.isManual) ? Boolean(object.isManual) : false,
      side: isSet(object.side) ? Number(object.side) : 0,
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      executionUtcTimestamp: isSet(object.executionUtcTimestamp) ? fromJsonTimestamp(object.executionUtcTimestamp) : undefined,
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId) ? Number(object.clientRegulatoryAlgorithmId) : 0,
      expirationUtcTimestamp: isSet(object.expirationUtcTimestamp) ? fromJsonTimestamp(object.expirationUtcTimestamp) : undefined,
    };
  },

  toJSON(message: RFQRequest): unknown {
    const obj: any = {};
    message.clientRequestId !== undefined && (obj.clientRequestId = Math.round(message.clientRequestId));
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.isManual !== undefined && (obj.isManual = message.isManual);
    message.side !== undefined && (obj.side = Math.round(message.side));
    message.size !== undefined && (obj.size = message.size ? Decimal.toJSON(message.size) : undefined);
    message.executionUtcTimestamp !== undefined && (obj.executionUtcTimestamp = message.executionUtcTimestamp.toISOString());
    message.clientRegulatoryAlgorithmId !== undefined &&
      (obj.clientRegulatoryAlgorithmId = Math.round(message.clientRegulatoryAlgorithmId));
    message.expirationUtcTimestamp !== undefined && (obj.expirationUtcTimestamp = message.expirationUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQRequest>, I>>(base?: I): RFQRequest {
    return RFQRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RFQRequest>, I>>(object: I): RFQRequest {
    const message = createBaseRFQRequest();
    message.clientRequestId = object.clientRequestId ?? 0;
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.isManual = object.isManual ?? false;
    message.side = object.side ?? 0;
    message.size = object.size !== undefined && object.size !== null ? Decimal.fromPartial(object.size) : undefined;
    message.executionUtcTimestamp = object.executionUtcTimestamp ?? undefined;
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? 0;
    message.expirationUtcTimestamp = object.expirationUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseRFQReport(): RFQReport {
  return {
    clientRequestId: 0,
    exchangeRequestId: '',
    statusCode: 0,
    details: undefined,
    effectiveRegulatoryAlgorithmId: 0,
  };
}

export const RFQReport = {
  encode(message: RFQReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientRequestId !== 0) {
      writer.uint32(8).uint32(message.clientRequestId);
    }
    if (message.exchangeRequestId !== '') {
      writer.uint32(18).string(message.exchangeRequestId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(24).uint32(message.statusCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    if (message.effectiveRegulatoryAlgorithmId !== 0) {
      writer.uint32(40).uint32(message.effectiveRegulatoryAlgorithmId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RFQReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRFQReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientRequestId = reader.uint32();
          break;
        case 2:
          message.exchangeRequestId = reader.string();
          break;
        case 3:
          message.statusCode = reader.uint32();
          break;
        case 4:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 5:
          message.effectiveRegulatoryAlgorithmId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RFQReport {
    return {
      clientRequestId: isSet(object.clientRequestId) ? Number(object.clientRequestId) : 0,
      exchangeRequestId: isSet(object.exchangeRequestId) ? String(object.exchangeRequestId) : '',
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      effectiveRegulatoryAlgorithmId: isSet(object.effectiveRegulatoryAlgorithmId)
        ? Number(object.effectiveRegulatoryAlgorithmId)
        : 0,
    };
  },

  toJSON(message: RFQReport): unknown {
    const obj: any = {};
    message.clientRequestId !== undefined && (obj.clientRequestId = Math.round(message.clientRequestId));
    message.exchangeRequestId !== undefined && (obj.exchangeRequestId = message.exchangeRequestId);
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.effectiveRegulatoryAlgorithmId !== undefined &&
      (obj.effectiveRegulatoryAlgorithmId = Math.round(message.effectiveRegulatoryAlgorithmId));
    return obj;
  },

  create<I extends Exact<DeepPartial<RFQReport>, I>>(base?: I): RFQReport {
    return RFQReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RFQReport>, I>>(object: I): RFQReport {
    const message = createBaseRFQReport();
    message.clientRequestId = object.clientRequestId ?? 0;
    message.exchangeRequestId = object.exchangeRequestId ?? '';
    message.statusCode = object.statusCode ?? 0;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.effectiveRegulatoryAlgorithmId = object.effectiveRegulatoryAlgorithmId ?? 0;
    return message;
  },
};

function createBaseMarketState(): MarketState {
  return { tradingState: undefined, exchangeSpecifics: [], isSnapshot: false };
}

export const MarketState = {
  encode(message: MarketState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tradingState !== undefined) {
      TradingState.encode(message.tradingState, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.exchangeSpecifics) {
      ExchangeSpecificMarketState.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.isSnapshot === true) {
      writer.uint32(24).bool(message.isSnapshot);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tradingState = TradingState.decode(reader, reader.uint32());
          break;
        case 2:
          message.exchangeSpecifics.push(ExchangeSpecificMarketState.decode(reader, reader.uint32()));
          break;
        case 3:
          message.isSnapshot = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketState {
    return {
      tradingState: isSet(object.tradingState) ? TradingState.fromJSON(object.tradingState) : undefined,
      exchangeSpecifics: Array.isArray(object?.exchangeSpecifics)
        ? object.exchangeSpecifics.map((e: any) => ExchangeSpecificMarketState.fromJSON(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
    };
  },

  toJSON(message: MarketState): unknown {
    const obj: any = {};
    message.tradingState !== undefined &&
      (obj.tradingState = message.tradingState ? TradingState.toJSON(message.tradingState) : undefined);
    if (message.exchangeSpecifics) {
      obj.exchangeSpecifics = message.exchangeSpecifics.map((e) => (e ? ExchangeSpecificMarketState.toJSON(e) : undefined));
    } else {
      obj.exchangeSpecifics = [];
    }
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketState>, I>>(base?: I): MarketState {
    return MarketState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketState>, I>>(object: I): MarketState {
    const message = createBaseMarketState();
    message.tradingState =
      object.tradingState !== undefined && object.tradingState !== null
        ? TradingState.fromPartial(object.tradingState)
        : undefined;
    message.exchangeSpecifics = object.exchangeSpecifics?.map((e) => ExchangeSpecificMarketState.fromPartial(e)) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    return message;
  },
};

function createBaseTradingState(): TradingState {
  return {
    exchangeState: 0,
    allowPlaceOrder: false,
    allowCancelOrder: false,
    allowModifyOrder: false,
    matchingEnabled: false,
  };
}

export const TradingState = {
  encode(message: TradingState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeState !== 0) {
      writer.uint32(8).uint32(message.exchangeState);
    }
    if (message.allowPlaceOrder === true) {
      writer.uint32(16).bool(message.allowPlaceOrder);
    }
    if (message.allowCancelOrder === true) {
      writer.uint32(24).bool(message.allowCancelOrder);
    }
    if (message.allowModifyOrder === true) {
      writer.uint32(32).bool(message.allowModifyOrder);
    }
    if (message.matchingEnabled === true) {
      writer.uint32(40).bool(message.matchingEnabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeState = reader.uint32();
          break;
        case 2:
          message.allowPlaceOrder = reader.bool();
          break;
        case 3:
          message.allowCancelOrder = reader.bool();
          break;
        case 4:
          message.allowModifyOrder = reader.bool();
          break;
        case 5:
          message.matchingEnabled = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TradingState {
    return {
      exchangeState: isSet(object.exchangeState) ? Number(object.exchangeState) : 0,
      allowPlaceOrder: isSet(object.allowPlaceOrder) ? Boolean(object.allowPlaceOrder) : false,
      allowCancelOrder: isSet(object.allowCancelOrder) ? Boolean(object.allowCancelOrder) : false,
      allowModifyOrder: isSet(object.allowModifyOrder) ? Boolean(object.allowModifyOrder) : false,
      matchingEnabled: isSet(object.matchingEnabled) ? Boolean(object.matchingEnabled) : false,
    };
  },

  toJSON(message: TradingState): unknown {
    const obj: any = {};
    message.exchangeState !== undefined && (obj.exchangeState = Math.round(message.exchangeState));
    message.allowPlaceOrder !== undefined && (obj.allowPlaceOrder = message.allowPlaceOrder);
    message.allowCancelOrder !== undefined && (obj.allowCancelOrder = message.allowCancelOrder);
    message.allowModifyOrder !== undefined && (obj.allowModifyOrder = message.allowModifyOrder);
    message.matchingEnabled !== undefined && (obj.matchingEnabled = message.matchingEnabled);
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingState>, I>>(base?: I): TradingState {
    return TradingState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TradingState>, I>>(object: I): TradingState {
    const message = createBaseTradingState();
    message.exchangeState = object.exchangeState ?? 0;
    message.allowPlaceOrder = object.allowPlaceOrder ?? false;
    message.allowCancelOrder = object.allowCancelOrder ?? false;
    message.allowModifyOrder = object.allowModifyOrder ?? false;
    message.matchingEnabled = object.matchingEnabled ?? false;
    return message;
  },
};

function createBaseExchangeSpecificMarketState(): ExchangeSpecificMarketState {
  return { tag: '', value: '' };
}

export const ExchangeSpecificMarketState = {
  encode(message: ExchangeSpecificMarketState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== '') {
      writer.uint32(10).string(message.tag);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSpecificMarketState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSpecificMarketState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tag = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeSpecificMarketState {
    return { tag: isSet(object.tag) ? String(object.tag) : '', value: isSet(object.value) ? String(object.value) : '' };
  },

  toJSON(message: ExchangeSpecificMarketState): unknown {
    const obj: any = {};
    message.tag !== undefined && (obj.tag = message.tag);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeSpecificMarketState>, I>>(base?: I): ExchangeSpecificMarketState {
    return ExchangeSpecificMarketState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeSpecificMarketState>, I>>(object: I): ExchangeSpecificMarketState {
    const message = createBaseExchangeSpecificMarketState();
    message.tag = object.tag ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseOptionCalculationParameters(): OptionCalculationParameters {
  return { optionMaturityId: '', strikeContractIds: [], contributorId: '' };
}

export const OptionCalculationParameters = {
  encode(message: OptionCalculationParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionMaturityId !== '') {
      writer.uint32(10).string(message.optionMaturityId);
    }
    writer.uint32(18).fork();
    for (const v of message.strikeContractIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.contributorId !== '') {
      writer.uint32(26).string(message.contributorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.optionMaturityId = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.strikeContractIds.push(reader.uint32());
            }
          } else {
            message.strikeContractIds.push(reader.uint32());
          }
          break;
        case 3:
          message.contributorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationParameters {
    return {
      optionMaturityId: isSet(object.optionMaturityId) ? String(object.optionMaturityId) : '',
      strikeContractIds: Array.isArray(object?.strikeContractIds) ? object.strikeContractIds.map((e: any) => Number(e)) : [],
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
    };
  },

  toJSON(message: OptionCalculationParameters): unknown {
    const obj: any = {};
    message.optionMaturityId !== undefined && (obj.optionMaturityId = message.optionMaturityId);
    if (message.strikeContractIds) {
      obj.strikeContractIds = message.strikeContractIds.map((e) => Math.round(e));
    } else {
      obj.strikeContractIds = [];
    }
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationParameters>, I>>(base?: I): OptionCalculationParameters {
    return OptionCalculationParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionCalculationParameters>, I>>(object: I): OptionCalculationParameters {
    const message = createBaseOptionCalculationParameters();
    message.optionMaturityId = object.optionMaturityId ?? '';
    message.strikeContractIds = object.strikeContractIds?.map((e) => e) || [];
    message.contributorId = object.contributorId ?? '';
    return message;
  },
};

function createBaseOptionCalculationRequest(): OptionCalculationRequest {
  return { requestId: 0, optionCalculationParameters: undefined, requestType: 0 };
}

export const OptionCalculationRequest = {
  encode(message: OptionCalculationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.optionCalculationParameters !== undefined) {
      OptionCalculationParameters.encode(message.optionCalculationParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.optionCalculationParameters = OptionCalculationParameters.decode(reader, reader.uint32());
          break;
        case 3:
          message.requestType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      optionCalculationParameters: isSet(object.optionCalculationParameters)
        ? OptionCalculationParameters.fromJSON(object.optionCalculationParameters)
        : undefined,
      requestType: isSet(object.requestType) ? Number(object.requestType) : 0,
    };
  },

  toJSON(message: OptionCalculationRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.optionCalculationParameters !== undefined &&
      (obj.optionCalculationParameters = message.optionCalculationParameters
        ? OptionCalculationParameters.toJSON(message.optionCalculationParameters)
        : undefined);
    message.requestType !== undefined && (obj.requestType = Math.round(message.requestType));
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationRequest>, I>>(base?: I): OptionCalculationRequest {
    return OptionCalculationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionCalculationRequest>, I>>(object: I): OptionCalculationRequest {
    const message = createBaseOptionCalculationRequest();
    message.requestId = object.requestId ?? 0;
    message.optionCalculationParameters =
      object.optionCalculationParameters !== undefined && object.optionCalculationParameters !== null
        ? OptionCalculationParameters.fromPartial(object.optionCalculationParameters)
        : undefined;
    message.requestType = object.requestType ?? 0;
    return message;
  },
};

function createBaseOptionCalculationValues(): OptionCalculationValues {
  return {
    strikeContractId: 0,
    scaledCoherentUnderlyingPrice: 0,
    impliedVolatility: 0,
    theov: 0,
    delta: 0,
    gamma: 0,
    vega: 0,
    theta: 0,
    rho: 0,
    deleted: false,
  };
}

export const OptionCalculationValues = {
  encode(message: OptionCalculationValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.strikeContractId !== 0) {
      writer.uint32(8).uint32(message.strikeContractId);
    }
    if (message.scaledCoherentUnderlyingPrice !== 0) {
      writer.uint32(16).sint32(message.scaledCoherentUnderlyingPrice);
    }
    if (message.impliedVolatility !== 0) {
      writer.uint32(25).double(message.impliedVolatility);
    }
    if (message.theov !== 0) {
      writer.uint32(33).double(message.theov);
    }
    if (message.delta !== 0) {
      writer.uint32(41).double(message.delta);
    }
    if (message.gamma !== 0) {
      writer.uint32(49).double(message.gamma);
    }
    if (message.vega !== 0) {
      writer.uint32(57).double(message.vega);
    }
    if (message.theta !== 0) {
      writer.uint32(65).double(message.theta);
    }
    if (message.rho !== 0) {
      writer.uint32(73).double(message.rho);
    }
    if (message.deleted === true) {
      writer.uint32(80).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationValues {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.strikeContractId = reader.uint32();
          break;
        case 2:
          message.scaledCoherentUnderlyingPrice = reader.sint32();
          break;
        case 3:
          message.impliedVolatility = reader.double();
          break;
        case 4:
          message.theov = reader.double();
          break;
        case 5:
          message.delta = reader.double();
          break;
        case 6:
          message.gamma = reader.double();
          break;
        case 7:
          message.vega = reader.double();
          break;
        case 8:
          message.theta = reader.double();
          break;
        case 9:
          message.rho = reader.double();
          break;
        case 10:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationValues {
    return {
      strikeContractId: isSet(object.strikeContractId) ? Number(object.strikeContractId) : 0,
      scaledCoherentUnderlyingPrice: isSet(object.scaledCoherentUnderlyingPrice)
        ? Number(object.scaledCoherentUnderlyingPrice)
        : 0,
      impliedVolatility: isSet(object.impliedVolatility) ? Number(object.impliedVolatility) : 0,
      theov: isSet(object.theov) ? Number(object.theov) : 0,
      delta: isSet(object.delta) ? Number(object.delta) : 0,
      gamma: isSet(object.gamma) ? Number(object.gamma) : 0,
      vega: isSet(object.vega) ? Number(object.vega) : 0,
      theta: isSet(object.theta) ? Number(object.theta) : 0,
      rho: isSet(object.rho) ? Number(object.rho) : 0,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: OptionCalculationValues): unknown {
    const obj: any = {};
    message.strikeContractId !== undefined && (obj.strikeContractId = Math.round(message.strikeContractId));
    message.scaledCoherentUnderlyingPrice !== undefined &&
      (obj.scaledCoherentUnderlyingPrice = Math.round(message.scaledCoherentUnderlyingPrice));
    message.impliedVolatility !== undefined && (obj.impliedVolatility = message.impliedVolatility);
    message.theov !== undefined && (obj.theov = message.theov);
    message.delta !== undefined && (obj.delta = message.delta);
    message.gamma !== undefined && (obj.gamma = message.gamma);
    message.vega !== undefined && (obj.vega = message.vega);
    message.theta !== undefined && (obj.theta = message.theta);
    message.rho !== undefined && (obj.rho = message.rho);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationValues>, I>>(base?: I): OptionCalculationValues {
    return OptionCalculationValues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionCalculationValues>, I>>(object: I): OptionCalculationValues {
    const message = createBaseOptionCalculationValues();
    message.strikeContractId = object.strikeContractId ?? 0;
    message.scaledCoherentUnderlyingPrice = object.scaledCoherentUnderlyingPrice ?? 0;
    message.impliedVolatility = object.impliedVolatility ?? 0;
    message.theov = object.theov ?? 0;
    message.delta = object.delta ?? 0;
    message.gamma = object.gamma ?? 0;
    message.vega = object.vega ?? 0;
    message.theta = object.theta ?? 0;
    message.rho = object.rho ?? 0;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseOptionCalculationReport(): OptionCalculationReport {
  return {
    requestId: 0,
    statusCode: 0,
    upToUtcTime: 0,
    interestRate: 0,
    scaledUnderlyingPrice: 0,
    values: [],
    details: undefined,
    isReportComplete: false,
  };
}

export const OptionCalculationReport = {
  encode(message: OptionCalculationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.upToUtcTime !== 0) {
      writer.uint32(24).int64(message.upToUtcTime);
    }
    if (message.interestRate !== 0) {
      writer.uint32(33).double(message.interestRate);
    }
    if (message.scaledUnderlyingPrice !== 0) {
      writer.uint32(40).sint32(message.scaledUnderlyingPrice);
    }
    for (const v of message.values) {
      OptionCalculationValues.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(58).fork()).ldelim();
    }
    if (message.isReportComplete === true) {
      writer.uint32(64).bool(message.isReportComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionCalculationReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionCalculationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.uint32();
          break;
        case 2:
          message.statusCode = reader.uint32();
          break;
        case 3:
          message.upToUtcTime = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.interestRate = reader.double();
          break;
        case 5:
          message.scaledUnderlyingPrice = reader.sint32();
          break;
        case 6:
          message.values.push(OptionCalculationValues.decode(reader, reader.uint32()));
          break;
        case 7:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 8:
          message.isReportComplete = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionCalculationReport {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      upToUtcTime: isSet(object.upToUtcTime) ? Number(object.upToUtcTime) : 0,
      interestRate: isSet(object.interestRate) ? Number(object.interestRate) : 0,
      scaledUnderlyingPrice: isSet(object.scaledUnderlyingPrice) ? Number(object.scaledUnderlyingPrice) : 0,
      values: Array.isArray(object?.values) ? object.values.map((e: any) => OptionCalculationValues.fromJSON(e)) : [],
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
    };
  },

  toJSON(message: OptionCalculationReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = Math.round(message.requestId));
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.upToUtcTime !== undefined && (obj.upToUtcTime = Math.round(message.upToUtcTime));
    message.interestRate !== undefined && (obj.interestRate = message.interestRate);
    message.scaledUnderlyingPrice !== undefined && (obj.scaledUnderlyingPrice = Math.round(message.scaledUnderlyingPrice));
    if (message.values) {
      obj.values = message.values.map((e) => (e ? OptionCalculationValues.toJSON(e) : undefined));
    } else {
      obj.values = [];
    }
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionCalculationReport>, I>>(base?: I): OptionCalculationReport {
    return OptionCalculationReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionCalculationReport>, I>>(object: I): OptionCalculationReport {
    const message = createBaseOptionCalculationReport();
    message.requestId = object.requestId ?? 0;
    message.statusCode = object.statusCode ?? 0;
    message.upToUtcTime = object.upToUtcTime ?? 0;
    message.interestRate = object.interestRate ?? 0;
    message.scaledUnderlyingPrice = object.scaledUnderlyingPrice ?? 0;
    message.values = object.values?.map((e) => OptionCalculationValues.fromPartial(e)) || [];
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.isReportComplete = object.isReportComplete ?? false;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
