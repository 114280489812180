import config from '../config';
import apiClient from '../shared/apiClient';
import { trpc, ReactQueryOptions, RouterInputs, RouterOutputs } from '../shared/trpc';
import type {
  IGetFuturesOptionsParams,
  IGetFuturesOptionsResult,
  IGetSpecialOptionsParams,
  IGetSpecialOptionsResult,
} from '@harvestiq/external-clients/barchart';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type GetFuturesExpirationsOptions = ReactQueryOptions['marketData']['getFuturesExpirations'];
type GetFuturesExpirationsInput = RouterInputs['marketData']['getFuturesExpirations'];

export function useFuturesExpirations(input: GetFuturesExpirationsInput, opts?: GetFuturesExpirationsOptions) {
  const qry = trpc.marketData.getFuturesExpirations.useQuery(input, opts);
  return qry;
}

type GetCropPricesOptions = ReactQueryOptions['marketData']['getCropPrices'];
type GetCropPricesInput = RouterInputs['marketData']['getCropPrices'];
export type GetCropPricesOutput = RouterOutputs['marketData']['getCropPrices'];

export function useCropPrices(input: GetCropPricesInput, opts?: GetCropPricesOptions) {
  const qry = trpc.marketData.getCropPrices.useQuery(input, opts);
  return qry;
}

type GetLivestockPricesOptions = ReactQueryOptions['marketData']['getLivestockPrices'];
export type GetLivestockPricesOutput = RouterOutputs['marketData']['getLivestockPrices'];
export function useLivestockPrices(opts?: GetLivestockPricesOptions) {
  const qry = trpc.marketData.getLivestockPrices.useQuery();
  return qry;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// get_futures_options
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchBarchartFuturesOptions(params: IGetFuturesOptionsParams) {
  const result = await apiClient.get<IGetFuturesOptionsResult[]>(`${config.backend.basePath}/market_data/get_futures_options`, {
    params: {
      fields: 'premium,bid,ask',
      ...params,
    },
  });

  return result.data;
}

export function useFuturesOptions(params: IGetFuturesOptionsParams, opts?: UseQueryOptions<IGetFuturesOptionsResult[]>) {
  const query = useQuery({
    queryKey: ['market-data-futures-options', params],
    queryFn: () => fetchBarchartFuturesOptions(params),
    ...opts,
  });

  return query;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// get_special_options
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function fetchBarchartSpecialOptions(params: IGetSpecialOptionsParams) {
  const result = await apiClient.get<IGetSpecialOptionsResult[]>(`${config.backend.basePath}/market_data/get_special_options`, {
    params: {
      fields: 'premium,bid,ask,delta,last',
      ...params,
    },
  });

  const validSpecialOptions = result.data.filter((option: { contractName: string }) => {
    if (!option.contractName.includes('CSO')) {
      return true;
    }
  });

  return validSpecialOptions;
}

export function useSpecialOptions(params: IGetSpecialOptionsParams, opts?: UseQueryOptions<IGetSpecialOptionsResult[]>) {
  const query = useQuery({
    queryKey: ['market-data-special-options', params],
    queryFn: () => fetchBarchartSpecialOptions(params),
    ...opts,
  });

  return query;
}
