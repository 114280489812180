import React from 'react';
import InfoToolTip from '../ToolTips/InfoToolTip';

import './SummaryItem.css';

interface ISummaryItemProps {
  label: string;
  labelTooltip?: string;
  value: string;
  secondaryValue?: string;
  className?: string;
}

export function SummaryItem(props: ISummaryItemProps) {
  const { label, value, labelTooltip, secondaryValue } = props;
  const className = 'card-header-item ' + (props.className || '');
  return (
    <div className={className}>
      <div className="text mx-auto">
        <label>{value}</label>
      </div>
      <div className="text mx-auto">
        <label>{secondaryValue}</label>
      </div>
      <label>
        {label}
        {!!labelTooltip && <InfoToolTip title={labelTooltip} />}
      </label>
    </div>
  );
}
