import { makeStyles, Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/common-next/Button/Button';
import { InputEventType } from '../../../../components/common/Inputs/InputWithLabel/InputWithLabel';
import useCurrentCurrency from '../../../../customHooks/useCurrentCurrency';
import { getActualPrice } from '../../../../models/profitMatrix';
import { defaultMatrixLength, defaultYieldStep, MatrixRanges } from '../../../../models/RiskAssessment/riskAssessment';
import { roundToCents } from '../../../../utils/formatNumber';

const useStyles = makeStyles({
  root: {
    color: '#1262AB',
    rail: {
      backgroundColor: '#E0E4E8',
    },
  },
});

export function MatrixRangesSliders(props: MatrixRangesSlidersProps) {
  const { expectedYield, spotPrice, priceStep, setRanges, minPrice } = props;
  const [priceSliderRange, setPriceSliderRange] = useState<number[]>([]);
  const [yieldSliderRange, setYieldSliderRange] = useState<number[]>([]);
  const [minAndMaxPrice, setMinAndMaxPrice] = useState<{ min: number; max: number }>();
  const [minAndMaxYield, setMinAndMaxYield] = useState<{ min: number; max: number }>();
  const [isDisabled, setIsDisabled] = useState(true);
  const currency = useCurrentCurrency();
  const classes = useStyles();

  const priceRangeChangeHandler = (event: any, value: any) => {
    const newValue = [roundToCents(value[0]), roundToCents(value[1])];
    if (newValue !== priceSliderRange && newValue[0] < newValue[1]) {
      setPriceSliderRange(newValue);
    }
  };

  const yieldRangeChangeHandler = (event: any, newValue: any) => {
    if (newValue !== yieldSliderRange && newValue[0] < newValue[1]) {
      setYieldSliderRange(newValue);
    }
  };

  const applyRangesHandler = (event: React.FormEvent) => {
    event.preventDefault();
    setNewRanges();
  };

  const setNewRanges = () => {
    setRanges({
      priceRange: {
        firstValue: getActualPrice(priceSliderRange[0], spotPrice, priceStep),
        length: 1 + (priceSliderRange[1] - priceSliderRange[0]) / priceStep,
      },
      yieldRange: {
        firstValue: yieldSliderRange[0],
        length: 1 + (yieldSliderRange[1] - yieldSliderRange[0]) / defaultYieldStep,
      },
    });
  };

  useEffect(() => {
    if (expectedYield && priceStep && spotPrice) {
      const yieldStep = defaultYieldStep;

      let priceFirstValue = Math.max(0, spotPrice - Math.floor(defaultMatrixLength / 2) * priceStep);
      // round to nearest 10-cent value
      priceFirstValue = Math.round(priceFirstValue * 10) / 10;
      const yieldFirstValue = Math.max(0, expectedYield - Math.floor(defaultMatrixLength / 2) * yieldStep);
      const priceLastValue = priceFirstValue + priceStep * (defaultMatrixLength - 1);
      const yieldLastValue = yieldFirstValue + yieldStep * (defaultMatrixLength - 1);

      setPriceSliderRange([roundToCents(priceFirstValue), roundToCents(priceLastValue)]);
      setYieldSliderRange([yieldFirstValue, yieldLastValue]);

      setMinAndMaxPrice({
        min: Math.max(0, minPrice),
        max: roundToCents(spotPrice + defaultMatrixLength * priceStep),
      });
      setMinAndMaxYield({
        min: Math.max(0, expectedYield - defaultMatrixLength * yieldStep),
        max: expectedYield + defaultMatrixLength * yieldStep,
      });

      setRanges({
        priceRange: {
          firstValue: priceFirstValue,
          length: defaultMatrixLength,
        },
        yieldRange: {
          firstValue: yieldFirstValue,
          length: defaultMatrixLength,
        },
      });

      setIsDisabled(false);
    } else {
      setPriceSliderRange([0, 0]);
      setYieldSliderRange([0, 0]);
      setIsDisabled(true);
    }
    // eslint-disable-next-line
  }, [spotPrice, expectedYield, minPrice, priceStep]);

  return (
    <form onSubmit={applyRangesHandler} className="live-matrix-sliders">
      <div className="live-matrix-slider first-slider">
        <div className="slider-inputs">
          <div className="slider-inputs-label-wrap">
            <label>Price Range: ({currency?.symbol})</label>
          </div>
          <div className="slider-inputs-label-wrap">
            <input
              type="number"
              value={priceSliderRange[0] || ''}
              onChange={(e: InputEventType) => setPriceSliderRange([+e.target.value, priceSliderRange[1]])}
              step={0.01}
              min={minAndMaxPrice?.min}
              max={minAndMaxPrice?.max}
              disabled={isDisabled}
            />
            <span>-</span>
            <input
              type="number"
              value={priceSliderRange[1] || ''}
              onChange={(e: InputEventType) => setPriceSliderRange([priceSliderRange[0], +e.target.value])}
              step={0.01}
              min={minAndMaxPrice?.min}
              max={minAndMaxPrice?.max}
              disabled={isDisabled}
            />
          </div>
        </div>
        <Slider
          className={classes.root}
          value={priceSliderRange}
          onChange={priceRangeChangeHandler}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={0.05}
          min={minAndMaxPrice?.min}
          max={minAndMaxPrice?.max}
          disabled={isDisabled}
        />
      </div>
      <div className="live-matrix-slider second-slider">
        <div className="slider-inputs">
          <div className="slider-inputs-label-wrap">
            <label>Yield Range: (bu/ac)</label>
          </div>
          <div className="slider-inputs-label-wrap">
            <input
              type="number"
              value={yieldSliderRange[0] || ''}
              onChange={(e: InputEventType) => setYieldSliderRange([+e.target.value, yieldSliderRange[1]])}
              step={1}
              min={minAndMaxYield?.min}
              max={minAndMaxYield?.max}
              disabled={isDisabled}
            />
            <span>-</span>
            <input
              type="number"
              value={yieldSliderRange[1] || ''}
              onChange={(e: InputEventType) => setYieldSliderRange([yieldSliderRange[0], +e.target.value])}
              step={1}
              min={minAndMaxYield?.min}
              max={minAndMaxYield?.max}
              disabled={isDisabled}
            />
          </div>
        </div>
        <Slider
          className={classes.root}
          value={yieldSliderRange}
          onChange={yieldRangeChangeHandler}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={defaultYieldStep}
          min={minAndMaxYield?.min}
          max={minAndMaxYield?.max}
          disabled={isDisabled}
        />
      </div>
      <Button className="apply-btn" themeColor="primary" type="submit" disabled={isDisabled}>
        Apply
      </Button>
    </form>
  );
}

type MatrixRangesSlidersProps = {
  expectedYield: number;
  priceStep: number;
  spotPrice: number;
  setRanges: (data: MatrixRanges) => void;
  minPrice: number;
};
