import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductionCyclesType } from '../../../utils/productionCycle';

export type CashSalesInitialStateType = {
  productionCycles: ProductionCyclesType;
};

const initialState: CashSalesInitialStateType = {
  productionCycles: {},
};

const slice = createSlice({
  name: 'cashSales',
  initialState,
  reducers: {
    initializeCashSalesProductionCycles: (state, action: PayloadAction<ProductionCyclesType>) => ({
      ...state,
      productionCycles: action.payload,
    }),
    setCashSalesProductionCycle: (state, action: PayloadAction<ProductionCyclesType>) => ({
      ...state,
      productionCycles: { ...state.productionCycles, ...action.payload },
    }),
  },
});

export const { initializeCashSalesProductionCycles, setCashSalesProductionCycle } = slice.actions;

export default slice.reducer;
