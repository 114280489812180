import { UseQueryResult } from '@tanstack/react-query';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { IAdvisor } from '../../types/IAdvisor';
import Loading from '../common-next/Loading/Loading';
import './Wizard.scss';
import config from '../../config';

interface AdvisorVerificationProps {
  query?: UseQueryResult<IAdvisor, unknown>;
}

export function AdvisorVerification(props: AdvisorVerificationProps) {
  const { query } = props;
  const [advisorSignupDismissed, setAdvisorSignupDismissed] = useState(false);
  if (isNil(query) || advisorSignupDismissed) return null;

  return (
    <Alert
      variant={query.isError ? 'danger' : 'info'}
      dismissible
      onClose={() => setAdvisorSignupDismissed(true)}
      className="advisor-info"
    >
      {query.isLoading && (
        <div>
          Checking verification code...
          <span className="ms-2">
            <Loading />
          </span>
        </div>
      )}
      {!query.isLoading && !query.isError && (
        <div>
          You have been invited {query.data.name ? `by ${query.data?.name}` : ''} to join {config.organization.name}.{' '}
          <div className="incorrect">
            If this is not correct, continue <a href="/wizard">here</a> instead.
          </div>
        </div>
      )}
      {!query.isLoading && query.isError && (
        <div>The invitation you attempted to sign up with is not valid. Please contact your advisor or continue without one.</div>
      )}
    </Alert>
  );
}
