import React from 'react';
import { DropDownListChangeEvent } from './DropDownList';
import { fakeOperationCrop, IOperationCropV2 } from '../../../types/IOperationCrops';
import DropDownNavigator, { DropDownNavigatorProps } from './DropDownNavigator';
import { useAllOperationCrops } from '../../../queries/operationCrops';
import { orderBy } from 'lodash';

interface OperationCropDropDownNavigatorProps extends Omit<DropDownNavigatorProps, 'data' | 'valueId'> {
  selectedOperationCropId: number;
  onChange: (e: DropDownListChangeEvent) => void | null;
  includeSummary?: boolean;
  onlyCropsWithMarketingTypes?: boolean;
  operationCrops?: IOperationCropV2[];
}

export default function OperationCropDropDownNavigator(props: OperationCropDropDownNavigatorProps) {
  const { selectedOperationCropId, onChange, includeSummary, onlyCropsWithMarketingTypes, ...otherProps } = props;

  // query is enabled if operationCrops is undefined
  const enabled = !props.operationCrops;

  const operationCropsQuery = useAllOperationCrops({ enabled });
  let operationCrops = props.operationCrops ?? operationCropsQuery.data ?? [];
  if (onlyCropsWithMarketingTypes) {
    operationCrops = operationCrops?.filter((operationCrop) => !!operationCrop.marketingCropType);
  }
  let operationCropsSelectors = operationCrops.map((crop) => ({ value: crop, label: `${crop.productionCycle} - ${crop.name}` }));
  operationCropsSelectors = orderBy(operationCropsSelectors, ['value.productionCycle', 'value.name'], ['desc', 'asc']);

  if (includeSummary) {
    const summary: IOperationCropV2 = { ...fakeOperationCrop, name: 'Summary' };
    operationCropsSelectors.unshift({ value: summary, label: 'Summary' });
  }

  return (
    <DropDownNavigator
      {...otherProps}
      name="operationCrop"
      label={props.label || 'Crop'}
      data={operationCropsSelectors}
      valueId={selectedOperationCropId}
      onChange={onChange}
    />
  );
}
