import React from 'react';
import styles from './ColoredBar.module.scss';
import { FontAwesomeIcon, IconDefinition, faCaretDown, faCaretUp } from '../../faIcons';
import useMobileView, { BREAKPOINTS } from '../../../customHooks/useMobileView';
import { isNil } from 'lodash';

export interface BarConfig {
  color: string;
  labelTop: any;
  labelBottom: any;
  percentValue: number;
  rawValue: number;
  insurancePercent?: number;
}

type ColoredBarProps = {
  config: BarConfig[];
  className?: string;
};

export function ColoredBar(props: ColoredBarProps) {
  const { config } = props;
  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  if (!config.length) {
    return null;
  }

  return (
    <div className={`${styles['fr-colored-bar-wrapper']} ${props.className}`}>
      <div className={`labels${isMobile ? '-mobile' : ''}`}>
        {config.map((c, index) => {
          const isLast = index === config.length - 1;
          const isFirst = index === 0;
          let positionClass = 'middle';
          if (isFirst) positionClass = 'first';
          if (isLast) positionClass = 'last';
          return (
            <div
              key={c.color}
              className={`item-labels-wrapper ${positionClass}`}
              style={{ flexGrow: c.percentValue, flexShrink: c.percentValue }}
            >
              <div className={`legend-circle${isMobile ? '-mobile' : ''}`} style={{ backgroundColor: c.color }}></div>
              <div className={`item-labels${isMobile ? '-mobile' : ''}`}>
                <label className={`label-top${isMobile ? '-mobile' : ''}`}>{c.labelTop}</label>
                <label className="label-bottom">{c.labelBottom}</label>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bar shadow">
        {config.map((c) => {
          let insurancePercentWithLimit = '100';
          if (!isNil(c.insurancePercent) && c.insurancePercent < 100) {
            insurancePercentWithLimit = c.insurancePercent.toFixed(1);
          }
          return (
            <div
              key={c.color}
              className="bar-part"
              style={{ backgroundColor: c.color, flexGrow: c.percentValue, flexShrink: c.percentValue }}
            >
              {c.insurancePercent && (
                <div
                  className="insurance-marker-wrapper"
                  style={{ left: `${insurancePercentWithLimit}%` }}
                  title={`${c.insurancePercent.toFixed(1)}% Crop Insurance`}
                >
                  <div className="markers">
                    <FontAwesomeIcon icon={faCaretUp as IconDefinition} className="marker bottom" />
                    <FontAwesomeIcon icon={faCaretDown as IconDefinition} className="marker top" />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
