import { useCurrentOperation } from '../../customHooks/useCurrentOperation';
import useCurrentRole from '../../customHooks/useCurrentRole';
import { useTradingAccountConfigs } from '../../queries/tradingAccountConfigs';

export default function useCanTrade() {
  const operation = useCurrentOperation();
  const currentRole = useCurrentRole();
  const tradingAccountConfigsQry = useTradingAccountConfigs();

  if (!tradingAccountConfigsQry.data) {
    return { canTrade: null, tradingAccountConfigs: null };
  }

  const tradingAccountConfigs = tradingAccountConfigsQry.data || [];
  const isAllowedToTrade = (currentRole?.executeTrades ?? false) && (operation?.tradingEnabled ?? false);
  const tradingTacs = tradingAccountConfigs.filter((tac) => tac.dataProvider === 4 && tac.additionalFields.hasTradingCredentials);
  const hasStoneX = tradingTacs.length > 0;

  const canTrade = isAllowedToTrade && hasStoneX;

  return { canTrade, tradingAccountConfigs: tradingTacs };
}
