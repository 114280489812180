import { IConfig } from '@farmersrisk/shared/config';
import config from '../config';
import { useCurrentOperation } from './useCurrentOperation';

// @note static ids are a temporary solution

// organizations
const atiOrgId = 1;
const pinionOrgId = 11;
const stockguardOrgId = 8;
const tredasOrgId = 13;
const hilltopOrgId = 14;
const advisorDemoOrgId = 15;

// operations
const vanzanteFamilyOperationId = 1668;
const ericAbbottOperationId = 2088;
const daneKuperOperationId = 255;
const d2kfcsOperationId = 2099;
const ilDemoOperationId = 491;
const deanCluckFeedersOperationId = 2125;
const augustineAndSonsOperationId = 99;
const cactusFeedersOperationId = 2136;
const agraRiskOperationId = 2152;
const blueReefOperationId = 2161;
const iowaFarmsOperationId = 13;
const williamsonCattleCoOperationId = 2211;
const kellyGarrettOperationId = 343;
const producersTradingCompany24OperationId = 2248;
const ccAcresJVOperationId = 2244;
const demoOp1 = 2256; // pre-established demo operations for sales
const demoOp2 = 2257;
const demoOp3 = 2258;
const demoOp4 = 2259;
const demoOp5 = 2260;
const hudsonPurvisOperationId = 2267;
const lincolnGouldOperationId = 2271;

type FeatureFlags = IConfig['featureFlags'];
type PrivateBetaRecord = Record<number, Partial<FeatureFlags>>;

const commonLivestockFeatureList: Partial<FeatureFlags> = {
  ...config.featureFlags,
  isCropsMenuV2Enabled: true,
  isLivestockProfitabilityEnabled: true,
};

const privateFeatureOrgList: PrivateBetaRecord = {
  [atiOrgId]: commonLivestockFeatureList,
  [pinionOrgId]: commonLivestockFeatureList,
  [stockguardOrgId]: commonLivestockFeatureList,
  [tredasOrgId]: commonLivestockFeatureList,
  [hilltopOrgId]: commonLivestockFeatureList,
  [advisorDemoOrgId]: commonLivestockFeatureList,
};

const privateFeatureOperationList: PrivateBetaRecord = {
  [vanzanteFamilyOperationId]: commonLivestockFeatureList,
  [ericAbbottOperationId]: commonLivestockFeatureList,
  [daneKuperOperationId]: commonLivestockFeatureList,
  [d2kfcsOperationId]: commonLivestockFeatureList,
  [ilDemoOperationId]: commonLivestockFeatureList,
  [deanCluckFeedersOperationId]: commonLivestockFeatureList,
  [augustineAndSonsOperationId]: commonLivestockFeatureList,
  [cactusFeedersOperationId]: commonLivestockFeatureList,
  [agraRiskOperationId]: commonLivestockFeatureList,
  [blueReefOperationId]: commonLivestockFeatureList,
  [iowaFarmsOperationId]: commonLivestockFeatureList,
  [williamsonCattleCoOperationId]: commonLivestockFeatureList,
  [kellyGarrettOperationId]: commonLivestockFeatureList,
  [producersTradingCompany24OperationId]: commonLivestockFeatureList,
  [ccAcresJVOperationId]: commonLivestockFeatureList,
  [demoOp1]: commonLivestockFeatureList,
  [demoOp2]: commonLivestockFeatureList,
  [demoOp3]: commonLivestockFeatureList,
  [demoOp4]: commonLivestockFeatureList,
  [demoOp5]: commonLivestockFeatureList,
  [hudsonPurvisOperationId]: commonLivestockFeatureList,
  [lincolnGouldOperationId]: commonLivestockFeatureList,
};

export default function useIsFeatureAccessible(featureKey: keyof FeatureFlags): boolean {
  const { organizationId, id: operationId } = useCurrentOperation();

  const featureFlags = config.featureFlags as unknown as FeatureFlags;
  const featureFlagEnabled = Boolean(featureFlags[featureKey]);

  const isOrgAllowed = organizationId ? Boolean(privateFeatureOrgList[organizationId]?.[featureKey]) : false;

  const isOperationAllowed = operationId ? Boolean(privateFeatureOperationList[operationId]?.[featureKey]) : false;

  return isOperationAllowed || isOrgAllowed || featureFlagEnabled;
}
