import { type ReactQueryOptions, type RouterInputs, type RouterOutputs, trpc } from '../shared/trpc';

type GetOperationCropSummariesOptions = ReactQueryOptions['operationCropSummary']['getSummary'];
type GetOperationCropSummariesInput = RouterInputs['operationCropSummary']['getSummary'];
export type GetOperationCropSummariesResult = RouterOutputs['operationCropSummary']['getSummary'];
export type OperationCropSummary = GetOperationCropSummariesResult[0];

export function useOperationCropSummaries(input: GetOperationCropSummariesInput, opts?: GetOperationCropSummariesOptions) {
  const qry = trpc.operationCropSummary.getSummary.useQuery(input, opts);
  return qry;
}
