import { CQGOriginLog, RawMessageLog, TradingLogOriginType } from './types';

export const enum LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

// FIXME type logger better

// Every userMsg sent and serverMsg received
export const logRawMessageTradingLog = (logger: any, rawMsg: RawMessageLog) => {
  const msg =
    rawMsg.origin === TradingLogOriginType.User
      ? `Raw User Message - user: ${rawMsg.userId} operation: ${rawMsg.operationId}`
      : `Raw CQG Server Message - user: ${rawMsg.userId} operation: ${rawMsg.operationId}`;
  logger.debug(`${msg}`, rawMsg);
};

// Function in CGQClient to send a msg
export const logClientActions = (logger: any, cqgLog: CQGOriginLog, message: string, data?: any) => {
  logger.debug(`${message} - user: ${cqgLog.userId} operation: ${cqgLog.operationId}`, { ...cqgLog, cqgData: data });
};

// Unable to perform an action to send a msg
export const logClientActionsError = (logger: any, cqgLog: CQGOriginLog, message: string, data?: any) => {
  logger.error(`${message} - user: ${cqgLog.userId} operation: ${cqgLog.operationId}`, { ...cqgLog, cqgData: data });
};

// Parsed server msg received
export const logServerMsg = (logger: any, cqgLog: CQGOriginLog, message: string, data: any) => {
  logger.debug(`${message} - user: ${cqgLog.userId} operation: ${cqgLog.operationId}`, { ...cqgLog, cqgData: data });
};
