import { isNil } from 'lodash';
import React from 'react';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import useCurrentCurrency from '../../../../customHooks/useCurrentCurrency';
import { formatNumber } from '../../../../utils/formatNumber';

export interface DetailsProps {
  label: string;
  tooltip?: string;
  amountLabel?: string;
  perPriceValue?: number;
  revenue?: number;
  profit?: number;
  revenueLabel?: string;
  profitPerAcre?: number;
  perUOMLabel?: string;
  perUOMValue?: number;
  uom?: string;
}

export function Details(props: DetailsProps) {
  const { uom = UnitOfMeasure.Bushel, perUOMLabel = 'Profit', revenueLabel = 'Profit', ...rest } = props;
  const currency = useCurrentCurrency();
  return (
    <div className="row mt-3">
      <div className="col-12 col-md-6">
        <div>
          <b>{rest.label}</b>{' '}
          <i className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={rest.tooltip}></i>
        </div>
        {!isNil(rest.amountLabel) && (
          <div>
            {rest.amountLabel}
            {rest.perPriceValue && ` at ${formatNumber(rest.perPriceValue, 2, currency)}/${uom}`}
          </div>
        )}
      </div>
      <div className="col-12 col-md-2 text-md-end">
        {!isNil(rest.profit) && (
          <>
            <div>
              <b>{formatNumber(rest.profit, 2, currency)}</b>
            </div>
            <div>{revenueLabel}</div>
          </>
        )}
      </div>
      <div className="col-12 col-md-2 text-md-end">
        {!isNil(rest.profitPerAcre) && (
          <>
            <div>
              <b>{formatNumber(rest.profitPerAcre, 2, currency)}/ac</b>
            </div>
            <div>Profit/ac</div>
          </>
        )}
      </div>
      <div className="col-12 col-md-2 text-md-end">
        {!isNil(rest.perUOMValue) && (
          <>
            <div>
              <b>
                {formatNumber(rest.perUOMValue, 2, currency)}/{uom}
              </b>
            </div>
            <div>
              {perUOMLabel}/{uom}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
