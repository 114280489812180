import React from 'react';
import './Wizard.scss';
import InputWithLabel, { InputEventType } from '../common/Inputs/InputWithLabel/InputWithLabel';
import Button from '../common/Button/Button';
import config from '../../config';

type WizardCreateOperationProps = {
  openNextStep: () => void;
  setOperationName: (name: string) => void;
  operationName: string;
};

export default function WizardCreateOperation(props: WizardCreateOperationProps) {
  const { operationName, setOperationName, openNextStep } = props;

  return (
    <div id="wizard-step1" className="wizard-card">
      <div className="wizard-card-header">
        <h3>Welcome to {config.organization.name}</h3>
        <p>Lets help you get a jump start on setting up your operation</p>
      </div>
      <div id="wizard-step1-body" className="wizard-card-body">
        <InputWithLabel
          label="Operation Name"
          onChange={(e: InputEventType) => setOperationName(e.target.value)}
          value={operationName}
        />
      </div>

      <div className="wizard-card-footer">
        <Button value="Next" disabled={operationName.trim() === ''} className="fr-button backGround-red" onClick={openNextStep} />
      </div>
    </div>
  );
}
