import { UnreachableCaseError } from '@harvestiq/utils';
// abuse for backward compatibility
import { DataProvider as DP } from '@harvestiq/constants';
export { DataProvider } from '@harvestiq/constants';

export const DataProviderNames = {
  [DP.ADM]: 'ADM Investor Services',
  [DP.Marex]: 'Marex Spectron',
  [DP.RJOBrien]: 'R.J. O’Brien',
  [DP.StoneX]: 'StoneX',
  [DP.Cunningham]: 'Cunningham',
  [DP.Wedbush]: 'Wedbush',
};

const dataProvidersWithoutAgreement = [DP.Cunningham, DP.Wedbush] as (DP | null)[];

export function dataProviderHasAgreement(provider: DP | null): boolean {
  return !dataProvidersWithoutAgreement.includes(provider);
}

export function dataProviderToString(provider: DP) {
  switch (provider) {
    case DP.ADM: {
      return 'ADM';
    }
    case DP.Marex: {
      return 'Marex';
    }
    case DP.RJOBrien: {
      return "RJ O'Brien";
    }
    case DP.StoneX: {
      return 'StoneX';
    }
    case DP.Cunningham: {
      return 'Cunningham';
    }
    case DP.Wedbush: {
      return 'Wedbush';
    }
    default:
      throw new UnreachableCaseError(provider);
  }
}
