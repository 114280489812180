export type MONTH_CODE =
  | 'F'
  | 'G'
  | 'H'
  | 'J'
  | 'K'
  | 'M'
  | 'N'
  | 'Q'
  | 'U'
  | 'V'
  | 'X'
  | 'Z';
export type MONTH = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export const FUTURES_MONTH_CODES: Record<number, MONTH_CODE> = {
  0: 'F',
  1: 'G',
  2: 'H',
  3: 'J',
  4: 'K',
  5: 'M',
  6: 'N',
  7: 'Q',
  8: 'U',
  9: 'V',
  10: 'X',
  11: 'Z',
};

// invert FUTURES_MONTH_CODES
export const MONTH_CODE_TO_MONTH_NUMBER: Record<string, MONTH> = {
  F: 0,
  G: 1,
  H: 2,
  J: 3,
  K: 4,
  M: 5,
  N: 6,
  Q: 7,
  U: 8,
  V: 9,
  X: 10,
  Z: 11,
};

export const enum InstrumentSymbol {
  FUTURE = 'F',
  CALL = 'C',
  PUT = 'P',
}

export const enum SpecialOptionCode {
  SHORTDATED = 'SD',
  WEEK1 = 'W1',
  WEEK2 = 'W2',
  WEEK3 = 'W3',
  WEEK4 = 'W4',
  WEEK5 = 'W5',
  SERIAL = 'S',
  NEW_CROP_WEEK1 = 'W1.SD',
  NEW_CROP_WEEK2 = 'W2.SD',
  NEW_CROP_WEEK3 = 'W3.SD',
  NEW_CROP_WEEK4 = 'W4.SD',
  NEW_CROP_WEEK5 = 'W5.SD',
}

export const NEW_CROP_FUTURES_MONTH_BY_CROP_ID: {
  [key: number]: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
} = {
  // Corn - December
  // Soybeans - November
  // Spring Wheat - September
  // Winter Wheat - July
  // Chicago Wheat - July
  // Oats - September
  // Sorghum - same as corn
  1: 11,
  2: 10,
  3: 6,
  4: 8,
  5: 6,
  6: 8,
  7: 11,
};
