import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as EB } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import styles from './ErrorBoundary.module.scss';
import config from '../../config';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

function ErrorRenderer(props: FallbackProps) {
  if (isFailedToFetchModuleError(props.error)) {
    if (checkShouldRetryReloading()) {
      console.warn('Failed to fetch module, reloading...');
      window.location.reload();
      return <div>Reloading...</div>;
    }
  }

  return (
    <div className={styles['error-boundary']}>
      <div className="logo">
        <img src={config.organization.logoWide} className={'wrapper-farmers-risk-logo'} alt={config.organization.name} />
      </div>
      <div className="details" role="alert">
        <h1>Whoops!</h1>
        <p>Something went wrong. We've been notified and are looking into it.</p>
        {/* <pre>{props.error.message}</pre> */}
        <button
          className="btn btn-primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Try again
        </button>
        <button
          className="btn btn-light"
          onClick={() => {
            window.location.replace('/');
          }}
        >
          Go home
        </button>
      </div>
    </div>
  );
}

type DDError = Error & {
  source?: string;
  type?: string;
};

function onError(error: Error | any, info: { componentStack: string }) {
  if (isFailedToFetchModuleError(error)) {
    return;
  }
  // React will call console.error with any unhandled errors.
  // However, Datadog will not trigger ErrorTracking for console.error calls.
  // The error.source property is used to determine if the error should be tracked.
  // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm

  const renderingError = new Error(error.message) as DDError;
  renderingError.name = error.name || 'ReactRenderingError';
  renderingError.type = error.type || error.name;
  renderingError.source = 'source';
  renderingError.stack = info.componentStack;
  renderingError.cause = error;

  // NOTICE: this will make two errors appear in Datadog:
  // 1. the console.error call that react makes
  // 2. the error that we are adding here
  datadogRum.addError(renderingError);
}

export function ErrorBoundary(props: PropsWithChildren<unknown>) {
  return (
    <EB FallbackComponent={ErrorRenderer} onError={onError}>
      {props.children}
    </EB>
  );
}

/*
 * This is a workaround for the following issue:
 * https://mitchgavan.com/code-splitting-react-safely/
 * TL;DR; when we deploy a new version of the app and the current user is on the app,
 * they will get a 404 error when clicking Lazy-imported modules.
 */
const isFailedToFetchModuleRegex = /Failed to fetch dynamically imported module:(.*)/gi;
function isFailedToFetchModuleError(error: Error | any) {
  return error && error.message && isFailedToFetchModuleRegex.test(error.message);
}

const IS_RELOADING_KEY = 'reviq-error-boundary-reloading';
const MAX_RETRIES = 2;
function checkShouldRetryReloading() {
  // use localStorage to keep track of retries
  const retries = parseInt(localStorage.getItem(IS_RELOADING_KEY) || '0', 10);
  if (retries >= MAX_RETRIES) {
    localStorage.removeItem(IS_RELOADING_KEY);
    return false;
  }
  localStorage.setItem(IS_RELOADING_KEY, (retries + 1).toString());
  return true;
}
