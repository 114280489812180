export enum BudgetType {
  Simple = 1,
  University = 2,
  Custom = 3,
}

export const budgetTypeNames: { [key: number]: string } = {
  1: 'Simple Cost',
  2: 'Use University Budget',
  3: 'Customize My Costs',
};

export enum BudgetValueType {
  Flat = 1,
  PerAcre = 2,
  PerBushel = 3,
}

export interface IBudgetsItem {
  orderId?: number;
  value: number | null;
  valueType: BudgetValueType;
  categoryName: string;
}

export interface IBudget {
  id: number;
  operationId: number | null;
  name: string;
  year: string;
  budgetType: BudgetType | null;
  cropBudgets: { [key: string]: IBudgetsItem[] };
  isActive: boolean;
  createdAt: string;
  isNew?: boolean;
}
