import { uniq } from 'lodash';
import Decimal from 'decimal.js';

import Loading from '../../components/common-next/Loading/Loading';
import { useOperationSummary } from '../../queries/operationSummary';
import useCurrentProductionCycle from '../../customHooks/useCurrentProductionCycle';
import { useCurrentHedgingCrops } from '../../queries/operationCrops';
import { useCropPrices, useLivestockPrices } from '../../queries/marketData';
import { useGetLotSummaries, useGetLots } from '../../queries/lots';
import OperationSummaryCard from './components/OperationSummaryCard';
import LivestockLotSummaryCard from './components/LivestockLotSummaryCard';
import LivestockFuturesPrices from './components/LivestockFuturesPrices';
import styles from './Dashboard.module.scss';
import { LivestockWarningRow } from '../../components/common/Warnings/LivestockWarningRow';

import { CommodityGroup } from '@harvestiq/constants';
import { LotSummary } from '@farmersrisk/shared/features/lots/lotSummaries';

const zero = new Decimal(0);

const DashboardLivestockPage = () => {
  // operation summary
  const productionCycle = useCurrentProductionCycle();
  const operationSummaryQuery = useOperationSummary({ productionCycle, commodityGroup: CommodityGroup.LIVESTOCK });

  // future prices
  const hedgingCropsQuery = useCurrentHedgingCrops();
  const hedgingCrops = hedgingCropsQuery.data || [];
  const cropPrices = useCropPrices({ cropTypes: hedgingCrops.map((c) => c.id) });

  // lots
  const { data: lots = [], isLoading: isLoadingLots } = useGetLots({ productionCycle });
  const livestockPrices = useLivestockPrices();

  // lot summaries
  const { data: { lotSummaries = [] } = {}, isLoading: isLoadingLotSummaries } = useGetLotSummaries();

  if (isLoadingLotSummaries || isLoadingLots) return <Loading />;

  if (!lots?.length) {
    return (
      <div className="warning-wrapper">
        <LivestockWarningRow selectedYear={productionCycle} />
      </div>
    );
  }

  return (
    <div className={`${styles['dashboard']} pt-0 gap-3`}>
      <div className={`${styles['dashboard-group']} pt-3 gap-3`}>
        <OperationSummaryCard operationSummaryQuery={operationSummaryQuery} type={CommodityGroup.LIVESTOCK} />
        <LivestockFuturesPrices
          cropPricesQuery={cropPrices}
          commodityInformation={hedgingCrops}
          livestockPricesQuery={livestockPrices}
        />
      </div>
      {lotSummaries.map((lotSummary: LotSummary) => {
        return <LivestockLotSummaryCard key={lotSummary.id} {...lotSummary} />;
      })}
    </div>
  );
};

export default DashboardLivestockPage;
