import { BuySellType } from '@farmersrisk/shared/enums';
import { InstrumentType } from '@harvestiq/constants';

export { InstrumentType, BuySellType };

export interface StrikeAndPremiumType {
  strike: number;
  premium: number;
}

export enum OrderType {
  // these correlate to cqgMessages.d.ts::Order.OrderType
  Market = 1,
  Limit = 2,
  StopWithProtection = 3,
  StopLimit = 4,
  Cross = 5,
}
export enum SocketState {
  opening = 0,
  open = 1,
  closing = 2,
  closed = 3,
}

export interface IConvertedSessionTimerange {
  preOpenTime: Date;
  openTime: Date;
  closeTime: Date;
  postClosedTime: Date;
  tradeDate: Date;
  sessionName: string;
}

export interface WaitUnpackedMessageOptions {
  timeout?: number;
  timeoutError?: Error;
}

export interface OrderMetadata {
  requestId: number;
  clOrderId: string;
}

export enum ClientContext {
  TRADE,
  HISTORY,
  OTHER,
  NONE,
}

export interface CQGClientOptions {
  userId: number;
  operationId: number;
  websocketAddress: string;
  username: string;
  password: string;
  /** The duration in minutes that keepalive will last for. `true` = forever. `false` = auto. */
  keepAliveDuration?: boolean | number;
  /** The ping interval frequency in milliseconds */
  keepAliveInterval?: number;
  env?: string;
  version?: string;
  dataDogEnabled?: boolean;
  logPingPong?: boolean;
  customCreateWebsocket?: () => any;
  logger?: any; // FIXME
}
