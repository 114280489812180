/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';

export const protobufPackage = 'strategy_definition_2';

/**
 * Message describing a synthetic, a user defined strategy or a native spread.
 * It is a tree where terminal nodes (legs) are outright contracts, non-terminal ones are nested strategies.
 * Nodes are defined from the BUY perspective.
 * Maximum strategy nesting level:
 * - Synthetic strategies: 2 (i.e. strategy of strategies of legs);
 * - Exchange strategies: depends only on exchange.
 * Maximum number of legs in the whole strategy:
 * - Synthetic strategies: 40;
 * - Exchange strategies: depends only on exchange.
 */
export interface StrategyDefinition {
  /** The strategy is of the aggregation type. */
  aggregation: boolean;
  /** If specified - defines exchange strategy, otherwise - synthetic strategy. */
  exchangeStrategy: ExchangeStrategy | undefined;
  /**
   * The strategy is a native spread.
   * Note: this field can only be set by server and cannot be used in StrategyDefinitionRequest.
   */
  nativeSpread: boolean;
  /** Strategy tick size (correct price format). The tick size of the 1st leg is default. */
  tickSize: number;
  /**
   * Additive offset for computation of strategy price.
   * 0 is default.
   */
  priceOffset: number;
  /**
   * Controls how to handle fractional quantities when calculating leg quantities for an order on this strategy,
   * One of RoundLegMode enums, ROUND_LEG_MODE_DOWN is default. Ignored for aggregations and exchange strategies.
   */
  roundLegMode: number;
  /** Unique node index within the root strategy to apply strategy trading parameters and to associate an order. */
  nodeIndex: number;
  /** Legs or nested strategies. */
  nodeDefinitions: StrategyNodeDefinition[];
  /** User text associated with the strategy. Allowed to be specified only for non-exchange strategies. */
  userDescription: string;
}

/**
 * Using a fractional trade ratio may result in fractional lots. If so, the number of lots has to be rounded.
 * Rounding applies only to complex strategies with fractional quantity ratios.
 */
export enum StrategyDefinition_RoundLegMode {
  /** ROUND_LEG_MODE_DOWN - always round down, e.g. 1.1 -> 1 and -2.1 -> -2 */
  ROUND_LEG_MODE_DOWN = 1,
  /** ROUND_LEG_MODE_UP - always round up, e.g. 1.1 -> 2 and -2.1 -> -3 */
  ROUND_LEG_MODE_UP = 2,
  /**
   * ROUND_LEG_MODE_MATH - round up when fractional part is 5 or greater;
   * round down when fractional part is less than 5, e.g. 1.1 -> 1 and 1.6 -> 2
   */
  ROUND_LEG_MODE_MATH = 3,
  UNRECOGNIZED = -1,
}

export function strategyDefinition_RoundLegModeFromJSON(object: any): StrategyDefinition_RoundLegMode {
  switch (object) {
    case 1:
    case 'ROUND_LEG_MODE_DOWN':
      return StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_DOWN;
    case 2:
    case 'ROUND_LEG_MODE_UP':
      return StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_UP;
    case 3:
    case 'ROUND_LEG_MODE_MATH':
      return StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_MATH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyDefinition_RoundLegMode.UNRECOGNIZED;
  }
}

export function strategyDefinition_RoundLegModeToJSON(object: StrategyDefinition_RoundLegMode): string {
  switch (object) {
    case StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_DOWN:
      return 'ROUND_LEG_MODE_DOWN';
    case StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_UP:
      return 'ROUND_LEG_MODE_UP';
    case StrategyDefinition_RoundLegMode.ROUND_LEG_MODE_MATH:
      return 'ROUND_LEG_MODE_MATH';
    case StrategyDefinition_RoundLegMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Defines a strategy created by an individual and registered by an exchange for general open market trading. */
export interface ExchangeStrategy {
  /**
   * Value of this field might be chosen from the predefined list to specify requested CQG exchange strategy type.
   * List of supported types can be found in document ExchangeStrategyRequestedCQGTypes.adoc.
   * "custom" if omitted.
   * This field is never provided in response.
   * Strategy can be created on exchange even if requested type is different than actual type recognized by exchange.
   */
  requestedCqgType: string;
}

/** Defines a leg (resolved contract) of a strategy. */
export interface LegDefinition {
  /**
   * Id of the leg contract.
   * Note: in case of receiving unknown id the contract metadata can be obtained via ContractMetadataRequest.
   */
  contractId: number;
  /** Unique node index within the root strategy to apply strategy trading parameters and to associate an order. */
  nodeIndex: number;
  /**
   * Note: use qty_ratio field instead.
   * This field has precision 1e-12 and can have maximum value of 9223372.
   *
   * @deprecated
   */
  doubleQtyRatio: number;
  /**
   * Leg quantity ratio, signed decimal, number of leg contracts to buy(positive) or sell(negative).
   * Must be integer for an aggregation.
   * Default is 1.
   */
  qtyRatio: Decimal | undefined;
  /**
   * Leg price calculation ratio, signed decimal, coefficient in strategy price formula before the leg contract.
   * Same as qty_ratio if omitted.
   * This field has precision 1e-12 and can have maximum value of 9223372.
   * Applicable for legs of an aggregation or a multiplicative strategy.
   */
  priceRatio: number;
  /**
   * Price offset for this leg (correct price format), only specified for legs of an aggregation or
   * a multiplicative strategy.
   * 0 is default.
   */
  priceOffset: number;
  /** Price of covering future contract. For exchange strategy only. */
  coveringPrice: number;
  /**
   * Used to calculate the quantity of futures covering the option or options strategy. For exchange strategy only.
   * Note: only qty_ratio sign means side of the leg (buy if positive or sell if negative).
   */
  optionDelta: Decimal | undefined;
}

/** Defines a strategy nested inside a parent strategy. */
export interface NestedStrategy {
  /** Nested strategy definition. */
  definition: StrategyDefinition | undefined;
  /**
   * Id of the nested strategy, if it already exists on exchange.
   * Note: can be provided by server in case of receiving contract metadata for multi-level strategy.
   * Note: in case of receiving unknown id the contract metadata can be obtained via ContractMetadataRequest.
   */
  contractId: number;
  /**
   * Note: use qty_ratio field instead.
   *
   * @deprecated
   */
  doubleQtyRatio: number;
  /**
   * Quantity ratio, signed decimal, number of leg contracts to buy(positive) or sell(negative).
   * Default is 1.
   */
  qtyRatio: Decimal | undefined;
  /**
   * Leg price calculation ratio, signed decimal, coefficient in strategy price formula.
   * Same as qty_ratio if omitted.
   * Applicable for legs of an aggregation or a multiplicative strategy.
   */
  priceRatio: number;
}

/** Child node definition within the parent strategy tree. Can be either a resolved contract (leg) or a nested strategy. */
export interface StrategyNodeDefinition {
  /** Specified node operation, one of NodeOperation enums, the default is SUM. */
  nodeOperation: number;
  /** Leg definition, if the node is a resolved contract. */
  leg: LegDefinition | undefined;
  /** Nested strategy, mutually exclusive with 'leg' attribute. */
  nestedStrategy: NestedStrategy | undefined;
}

/**
 * Operation on a node of the synthetic strategy.
 * Associating an operation with a node allows using different operations, e.g. multiplication and subtraction,
 * without introducing intermediate StrategyDefinition entities.
 * MUL and DIV cannot be mixed with SUM within the same StrategyDefinition.node_definition array.
 * i.e. node1 / node2 * node3 is allowed, but node1 / node2 + node3 is not.
 */
export enum StrategyNodeDefinition_NodeOperation {
  /** NODE_OPERATION_SUM - Add the quantity and the price. */
  NODE_OPERATION_SUM = 1,
  /** NODE_OPERATION_MUL - Add the quantity but multiply the price. */
  NODE_OPERATION_MUL = 2,
  /** NODE_OPERATION_DIV - Subtract the quantity but divide the price. */
  NODE_OPERATION_DIV = 3,
  UNRECOGNIZED = -1,
}

export function strategyNodeDefinition_NodeOperationFromJSON(object: any): StrategyNodeDefinition_NodeOperation {
  switch (object) {
    case 1:
    case 'NODE_OPERATION_SUM':
      return StrategyNodeDefinition_NodeOperation.NODE_OPERATION_SUM;
    case 2:
    case 'NODE_OPERATION_MUL':
      return StrategyNodeDefinition_NodeOperation.NODE_OPERATION_MUL;
    case 3:
    case 'NODE_OPERATION_DIV':
      return StrategyNodeDefinition_NodeOperation.NODE_OPERATION_DIV;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StrategyNodeDefinition_NodeOperation.UNRECOGNIZED;
  }
}

export function strategyNodeDefinition_NodeOperationToJSON(object: StrategyNodeDefinition_NodeOperation): string {
  switch (object) {
    case StrategyNodeDefinition_NodeOperation.NODE_OPERATION_SUM:
      return 'NODE_OPERATION_SUM';
    case StrategyNodeDefinition_NodeOperation.NODE_OPERATION_MUL:
      return 'NODE_OPERATION_MUL';
    case StrategyNodeDefinition_NodeOperation.NODE_OPERATION_DIV:
      return 'NODE_OPERATION_DIV';
    case StrategyNodeDefinition_NodeOperation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Algo strategy definition. */
export interface AlgoStrategyDefinition {
  /** Algo strategy abbreviation. */
  abbreviation: string;
  /**
   * Algo strategy definition.
   * Contains FIXatdl document that describes the list of parameters, their types and some UI specifics.
   * Non-standard extensions to FIXatdl may be used.
   * Ignore algo strategy if definition cannot be completely parsed by client.
   */
  definition: string;
}

function createBaseStrategyDefinition(): StrategyDefinition {
  return {
    aggregation: false,
    exchangeStrategy: undefined,
    nativeSpread: false,
    tickSize: 0,
    priceOffset: 0,
    roundLegMode: 0,
    nodeIndex: 0,
    nodeDefinitions: [],
    userDescription: '',
  };
}

export const StrategyDefinition = {
  encode(message: StrategyDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aggregation === true) {
      writer.uint32(8).bool(message.aggregation);
    }
    if (message.exchangeStrategy !== undefined) {
      ExchangeStrategy.encode(message.exchangeStrategy, writer.uint32(18).fork()).ldelim();
    }
    if (message.nativeSpread === true) {
      writer.uint32(72).bool(message.nativeSpread);
    }
    if (message.tickSize !== 0) {
      writer.uint32(25).double(message.tickSize);
    }
    if (message.priceOffset !== 0) {
      writer.uint32(33).double(message.priceOffset);
    }
    if (message.roundLegMode !== 0) {
      writer.uint32(40).uint32(message.roundLegMode);
    }
    if (message.nodeIndex !== 0) {
      writer.uint32(48).uint32(message.nodeIndex);
    }
    for (const v of message.nodeDefinitions) {
      StrategyNodeDefinition.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.userDescription !== '') {
      writer.uint32(66).string(message.userDescription);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.aggregation = reader.bool();
          break;
        case 2:
          message.exchangeStrategy = ExchangeStrategy.decode(reader, reader.uint32());
          break;
        case 9:
          message.nativeSpread = reader.bool();
          break;
        case 3:
          message.tickSize = reader.double();
          break;
        case 4:
          message.priceOffset = reader.double();
          break;
        case 5:
          message.roundLegMode = reader.uint32();
          break;
        case 6:
          message.nodeIndex = reader.uint32();
          break;
        case 7:
          message.nodeDefinitions.push(StrategyNodeDefinition.decode(reader, reader.uint32()));
          break;
        case 8:
          message.userDescription = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyDefinition {
    return {
      aggregation: isSet(object.aggregation) ? Boolean(object.aggregation) : false,
      exchangeStrategy: isSet(object.exchangeStrategy) ? ExchangeStrategy.fromJSON(object.exchangeStrategy) : undefined,
      nativeSpread: isSet(object.nativeSpread) ? Boolean(object.nativeSpread) : false,
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      priceOffset: isSet(object.priceOffset) ? Number(object.priceOffset) : 0,
      roundLegMode: isSet(object.roundLegMode) ? Number(object.roundLegMode) : 0,
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      nodeDefinitions: Array.isArray(object?.nodeDefinitions)
        ? object.nodeDefinitions.map((e: any) => StrategyNodeDefinition.fromJSON(e))
        : [],
      userDescription: isSet(object.userDescription) ? String(object.userDescription) : '',
    };
  },

  toJSON(message: StrategyDefinition): unknown {
    const obj: any = {};
    message.aggregation !== undefined && (obj.aggregation = message.aggregation);
    message.exchangeStrategy !== undefined &&
      (obj.exchangeStrategy = message.exchangeStrategy ? ExchangeStrategy.toJSON(message.exchangeStrategy) : undefined);
    message.nativeSpread !== undefined && (obj.nativeSpread = message.nativeSpread);
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.priceOffset !== undefined && (obj.priceOffset = message.priceOffset);
    message.roundLegMode !== undefined && (obj.roundLegMode = Math.round(message.roundLegMode));
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    if (message.nodeDefinitions) {
      obj.nodeDefinitions = message.nodeDefinitions.map((e) => (e ? StrategyNodeDefinition.toJSON(e) : undefined));
    } else {
      obj.nodeDefinitions = [];
    }
    message.userDescription !== undefined && (obj.userDescription = message.userDescription);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyDefinition>, I>>(base?: I): StrategyDefinition {
    return StrategyDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyDefinition>, I>>(object: I): StrategyDefinition {
    const message = createBaseStrategyDefinition();
    message.aggregation = object.aggregation ?? false;
    message.exchangeStrategy =
      object.exchangeStrategy !== undefined && object.exchangeStrategy !== null
        ? ExchangeStrategy.fromPartial(object.exchangeStrategy)
        : undefined;
    message.nativeSpread = object.nativeSpread ?? false;
    message.tickSize = object.tickSize ?? 0;
    message.priceOffset = object.priceOffset ?? 0;
    message.roundLegMode = object.roundLegMode ?? 0;
    message.nodeIndex = object.nodeIndex ?? 0;
    message.nodeDefinitions = object.nodeDefinitions?.map((e) => StrategyNodeDefinition.fromPartial(e)) || [];
    message.userDescription = object.userDescription ?? '';
    return message;
  },
};

function createBaseExchangeStrategy(): ExchangeStrategy {
  return { requestedCqgType: '' };
}

export const ExchangeStrategy = {
  encode(message: ExchangeStrategy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestedCqgType !== '') {
      writer.uint32(10).string(message.requestedCqgType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeStrategy {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeStrategy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestedCqgType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeStrategy {
    return { requestedCqgType: isSet(object.requestedCqgType) ? String(object.requestedCqgType) : '' };
  },

  toJSON(message: ExchangeStrategy): unknown {
    const obj: any = {};
    message.requestedCqgType !== undefined && (obj.requestedCqgType = message.requestedCqgType);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeStrategy>, I>>(base?: I): ExchangeStrategy {
    return ExchangeStrategy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeStrategy>, I>>(object: I): ExchangeStrategy {
    const message = createBaseExchangeStrategy();
    message.requestedCqgType = object.requestedCqgType ?? '';
    return message;
  },
};

function createBaseLegDefinition(): LegDefinition {
  return {
    contractId: 0,
    nodeIndex: 0,
    doubleQtyRatio: 0,
    qtyRatio: undefined,
    priceRatio: 0,
    priceOffset: 0,
    coveringPrice: 0,
    optionDelta: undefined,
  };
}

export const LegDefinition = {
  encode(message: LegDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.nodeIndex !== 0) {
      writer.uint32(16).uint32(message.nodeIndex);
    }
    if (message.doubleQtyRatio !== 0) {
      writer.uint32(25).double(message.doubleQtyRatio);
    }
    if (message.qtyRatio !== undefined) {
      Decimal.encode(message.qtyRatio, writer.uint32(66).fork()).ldelim();
    }
    if (message.priceRatio !== 0) {
      writer.uint32(33).double(message.priceRatio);
    }
    if (message.priceOffset !== 0) {
      writer.uint32(41).double(message.priceOffset);
    }
    if (message.coveringPrice !== 0) {
      writer.uint32(49).double(message.coveringPrice);
    }
    if (message.optionDelta !== undefined) {
      Decimal.encode(message.optionDelta, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LegDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLegDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.nodeIndex = reader.uint32();
          break;
        case 3:
          message.doubleQtyRatio = reader.double();
          break;
        case 8:
          message.qtyRatio = Decimal.decode(reader, reader.uint32());
          break;
        case 4:
          message.priceRatio = reader.double();
          break;
        case 5:
          message.priceOffset = reader.double();
          break;
        case 6:
          message.coveringPrice = reader.double();
          break;
        case 7:
          message.optionDelta = Decimal.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LegDefinition {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      nodeIndex: isSet(object.nodeIndex) ? Number(object.nodeIndex) : 0,
      doubleQtyRatio: isSet(object.doubleQtyRatio) ? Number(object.doubleQtyRatio) : 0,
      qtyRatio: isSet(object.qtyRatio) ? Decimal.fromJSON(object.qtyRatio) : undefined,
      priceRatio: isSet(object.priceRatio) ? Number(object.priceRatio) : 0,
      priceOffset: isSet(object.priceOffset) ? Number(object.priceOffset) : 0,
      coveringPrice: isSet(object.coveringPrice) ? Number(object.coveringPrice) : 0,
      optionDelta: isSet(object.optionDelta) ? Decimal.fromJSON(object.optionDelta) : undefined,
    };
  },

  toJSON(message: LegDefinition): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.nodeIndex !== undefined && (obj.nodeIndex = Math.round(message.nodeIndex));
    message.doubleQtyRatio !== undefined && (obj.doubleQtyRatio = message.doubleQtyRatio);
    message.qtyRatio !== undefined && (obj.qtyRatio = message.qtyRatio ? Decimal.toJSON(message.qtyRatio) : undefined);
    message.priceRatio !== undefined && (obj.priceRatio = message.priceRatio);
    message.priceOffset !== undefined && (obj.priceOffset = message.priceOffset);
    message.coveringPrice !== undefined && (obj.coveringPrice = message.coveringPrice);
    message.optionDelta !== undefined &&
      (obj.optionDelta = message.optionDelta ? Decimal.toJSON(message.optionDelta) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LegDefinition>, I>>(base?: I): LegDefinition {
    return LegDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LegDefinition>, I>>(object: I): LegDefinition {
    const message = createBaseLegDefinition();
    message.contractId = object.contractId ?? 0;
    message.nodeIndex = object.nodeIndex ?? 0;
    message.doubleQtyRatio = object.doubleQtyRatio ?? 0;
    message.qtyRatio =
      object.qtyRatio !== undefined && object.qtyRatio !== null ? Decimal.fromPartial(object.qtyRatio) : undefined;
    message.priceRatio = object.priceRatio ?? 0;
    message.priceOffset = object.priceOffset ?? 0;
    message.coveringPrice = object.coveringPrice ?? 0;
    message.optionDelta =
      object.optionDelta !== undefined && object.optionDelta !== null ? Decimal.fromPartial(object.optionDelta) : undefined;
    return message;
  },
};

function createBaseNestedStrategy(): NestedStrategy {
  return { definition: undefined, contractId: 0, doubleQtyRatio: 0, qtyRatio: undefined, priceRatio: 0 };
}

export const NestedStrategy = {
  encode(message: NestedStrategy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.definition !== undefined) {
      StrategyDefinition.encode(message.definition, writer.uint32(10).fork()).ldelim();
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    if (message.doubleQtyRatio !== 0) {
      writer.uint32(17).double(message.doubleQtyRatio);
    }
    if (message.qtyRatio !== undefined) {
      Decimal.encode(message.qtyRatio, writer.uint32(42).fork()).ldelim();
    }
    if (message.priceRatio !== 0) {
      writer.uint32(25).double(message.priceRatio);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NestedStrategy {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNestedStrategy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.definition = StrategyDefinition.decode(reader, reader.uint32());
          break;
        case 4:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.doubleQtyRatio = reader.double();
          break;
        case 5:
          message.qtyRatio = Decimal.decode(reader, reader.uint32());
          break;
        case 3:
          message.priceRatio = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NestedStrategy {
    return {
      definition: isSet(object.definition) ? StrategyDefinition.fromJSON(object.definition) : undefined,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      doubleQtyRatio: isSet(object.doubleQtyRatio) ? Number(object.doubleQtyRatio) : 0,
      qtyRatio: isSet(object.qtyRatio) ? Decimal.fromJSON(object.qtyRatio) : undefined,
      priceRatio: isSet(object.priceRatio) ? Number(object.priceRatio) : 0,
    };
  },

  toJSON(message: NestedStrategy): unknown {
    const obj: any = {};
    message.definition !== undefined &&
      (obj.definition = message.definition ? StrategyDefinition.toJSON(message.definition) : undefined);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.doubleQtyRatio !== undefined && (obj.doubleQtyRatio = message.doubleQtyRatio);
    message.qtyRatio !== undefined && (obj.qtyRatio = message.qtyRatio ? Decimal.toJSON(message.qtyRatio) : undefined);
    message.priceRatio !== undefined && (obj.priceRatio = message.priceRatio);
    return obj;
  },

  create<I extends Exact<DeepPartial<NestedStrategy>, I>>(base?: I): NestedStrategy {
    return NestedStrategy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NestedStrategy>, I>>(object: I): NestedStrategy {
    const message = createBaseNestedStrategy();
    message.definition =
      object.definition !== undefined && object.definition !== null
        ? StrategyDefinition.fromPartial(object.definition)
        : undefined;
    message.contractId = object.contractId ?? 0;
    message.doubleQtyRatio = object.doubleQtyRatio ?? 0;
    message.qtyRatio =
      object.qtyRatio !== undefined && object.qtyRatio !== null ? Decimal.fromPartial(object.qtyRatio) : undefined;
    message.priceRatio = object.priceRatio ?? 0;
    return message;
  },
};

function createBaseStrategyNodeDefinition(): StrategyNodeDefinition {
  return { nodeOperation: 0, leg: undefined, nestedStrategy: undefined };
}

export const StrategyNodeDefinition = {
  encode(message: StrategyNodeDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeOperation !== 0) {
      writer.uint32(16).uint32(message.nodeOperation);
    }
    if (message.leg !== undefined) {
      LegDefinition.encode(message.leg, writer.uint32(26).fork()).ldelim();
    }
    if (message.nestedStrategy !== undefined) {
      NestedStrategy.encode(message.nestedStrategy, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyNodeDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyNodeDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.nodeOperation = reader.uint32();
          break;
        case 3:
          message.leg = LegDefinition.decode(reader, reader.uint32());
          break;
        case 4:
          message.nestedStrategy = NestedStrategy.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StrategyNodeDefinition {
    return {
      nodeOperation: isSet(object.nodeOperation) ? Number(object.nodeOperation) : 0,
      leg: isSet(object.leg) ? LegDefinition.fromJSON(object.leg) : undefined,
      nestedStrategy: isSet(object.nestedStrategy) ? NestedStrategy.fromJSON(object.nestedStrategy) : undefined,
    };
  },

  toJSON(message: StrategyNodeDefinition): unknown {
    const obj: any = {};
    message.nodeOperation !== undefined && (obj.nodeOperation = Math.round(message.nodeOperation));
    message.leg !== undefined && (obj.leg = message.leg ? LegDefinition.toJSON(message.leg) : undefined);
    message.nestedStrategy !== undefined &&
      (obj.nestedStrategy = message.nestedStrategy ? NestedStrategy.toJSON(message.nestedStrategy) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyNodeDefinition>, I>>(base?: I): StrategyNodeDefinition {
    return StrategyNodeDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StrategyNodeDefinition>, I>>(object: I): StrategyNodeDefinition {
    const message = createBaseStrategyNodeDefinition();
    message.nodeOperation = object.nodeOperation ?? 0;
    message.leg = object.leg !== undefined && object.leg !== null ? LegDefinition.fromPartial(object.leg) : undefined;
    message.nestedStrategy =
      object.nestedStrategy !== undefined && object.nestedStrategy !== null
        ? NestedStrategy.fromPartial(object.nestedStrategy)
        : undefined;
    return message;
  },
};

function createBaseAlgoStrategyDefinition(): AlgoStrategyDefinition {
  return { abbreviation: '', definition: '' };
}

export const AlgoStrategyDefinition = {
  encode(message: AlgoStrategyDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.abbreviation !== '') {
      writer.uint32(10).string(message.abbreviation);
    }
    if (message.definition !== '') {
      writer.uint32(18).string(message.definition);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AlgoStrategyDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAlgoStrategyDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.abbreviation = reader.string();
          break;
        case 2:
          message.definition = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AlgoStrategyDefinition {
    return {
      abbreviation: isSet(object.abbreviation) ? String(object.abbreviation) : '',
      definition: isSet(object.definition) ? String(object.definition) : '',
    };
  },

  toJSON(message: AlgoStrategyDefinition): unknown {
    const obj: any = {};
    message.abbreviation !== undefined && (obj.abbreviation = message.abbreviation);
    message.definition !== undefined && (obj.definition = message.definition);
    return obj;
  },

  create<I extends Exact<DeepPartial<AlgoStrategyDefinition>, I>>(base?: I): AlgoStrategyDefinition {
    return AlgoStrategyDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AlgoStrategyDefinition>, I>>(object: I): AlgoStrategyDefinition {
    const message = createBaseAlgoStrategyDefinition();
    message.abbreviation = object.abbreviation ?? '';
    message.definition = object.definition ?? '';
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
