import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRiskScenario } from '../../types/IRiskScenario';
import { LoadingStatuses } from '../LoadingStatuses';
import { createRiskScenario, fetchRiskScenarios, updateRiskScenario } from './thunks';
import ErrorToast from '../../components/Toast/ErrorToast';
import { RootState } from '../../types/Store';
import { IOperationCrop } from '../../types/IOperationCrops';
import { ICropBudgets } from '../../models/cropBudget';

type RiskScenarioSliceInitialState = {
  scenarios: IRiskScenario[];
  status: string;
  error: string | null;
};

export const initialRiskScenario = (
  cropId: number,
  year: string,
  operationId: number,
  operationCrop?: IOperationCrop,
  cropBudgets?: ICropBudgets
) => {
  if (operationCrop) {
    let productionCost = null;
    if (cropBudgets && cropBudgets[operationCrop.id]) {
      productionCost = cropBudgets[operationCrop.id];
    } else if (cropBudgets && cropBudgets[cropId]) {
      productionCost = cropBudgets[cropId];
    }

    return {
      aph: operationCrop.aph,
      acres: operationCrop.acres,
      bushelsContracted: operationCrop.price?.bushelsContracted,
      sellingPrice: operationCrop.price?.sellingPrice,
      protectionType: operationCrop.protectionType,
      protectionPercent: operationCrop.protectionPercent,
      springInsurancePrice: operationCrop.price?.springInsurancePrice,
      autumnInsurancePrice: operationCrop.price?.autumnInsurancePrice,
      estimatedBasis: operationCrop.price?.estimatedBasis,
      productionCost: productionCost,
      actualYield: operationCrop.actualYield,
      operationId: operationId,
      cropId: operationCrop.cropId,
      year: operationCrop.year,
      id: null,
      operation_crop_id: operationCrop.id,
    };
  }
  return {
    id: 0,
    cropId: cropId,
    operationId: operationId,
    year: year,
    aph: null,
    acres: null,
    bushelsContracted: null,
    sellingPrice: null,
    protectionPercent: null,
    springInsurancePrice: null,
    autumnInsurancePrice: null,
    estimatedBasis: null,
    productionCost: null,
    protectionType: null,
    actualYield: null,
  };
};
const initialState: RiskScenarioSliceInitialState = {
  scenarios: [],
  status: LoadingStatuses.Idle,
  error: null,
};

const scenarioAnalysisSlice = createSlice({
  name: 'riskScenarios',
  initialState,
  reducers: {
    setRiskScenarios: (state, action: PayloadAction<IRiskScenario[]>) => {
      state.scenarios = [...action.payload];
    },
  },
  extraReducers: {
    [fetchRiskScenarios.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchRiskScenarios.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.scenarios = [...action.payload];
    },
    [fetchRiskScenarios.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load scenarios!');
    },
    [createRiskScenario.fulfilled.toString()]: (state, action) => {
      state.scenarios = [...state.scenarios, action.payload];
    },
    [createRiskScenario.rejected.toString()]: (state, action) => {
      state.error = action.error.message;
      ErrorToast('Failed to load scenarios!');
    },
    [updateRiskScenario.fulfilled.toString()]: (state, action) => {
      state.scenarios = updateScenario(state.scenarios, action.payload);
    },
    [updateRiskScenario.rejected.toString()]: (state, action) => {
      state.error = action.error.message;
      ErrorToast('Failed to update scenarios!');
    },
  },
});

function updateScenario(state: IRiskScenario[], scenario: IRiskScenario) {
  const newState = [...state];
  const previousScenario: IRiskScenario | undefined = newState.find((crop) => crop.id === scenario.id);

  if (previousScenario) {
    newState[newState.indexOf(previousScenario)] = {
      ...previousScenario,
      ...scenario,
    };
  }
  return newState;
}

export default scenarioAnalysisSlice.reducer;
export const { setRiskScenarios } = scenarioAnalysisSlice.actions;

export const selectAllScenarios = (state: RootState) => state.riskScenarios.scenarios;
