/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';
import { AbortRoutine, LogonResult, PasswordChangeResult, PasswordRequest, PasswordResponse } from './user_session_2';

export const protobufPackage = 'account_authorization_2';

/** Messages for accounts that require separate authorization process */

/**
 * Account level Logon Request. One-step logon procedure.
 * Note: it is deprecated. Use multi-step account logon procedure instead
 * (see AccountLogonRoutineClient / AccountLogonRoutineServer).
 */
export interface AccountLogon {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** User login name for this account. */
  username: string;
  /** User's password for this account. */
  password: string;
}

/**
 * Account level Logon result.
 * Note: it is deprecated. Use multi-step account logon procedure instead
 * (see AccountLogonRoutineClient / AccountLogonRoutineServer).
 */
export interface AccountLogonResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /**
   * One of the logon result codes.
   * This field is associated with ResultCode enum type.
   */
  resultCode: number;
  /** Optional logon result message for failure details. */
  textMessage: Text | undefined;
  /** Time when the message was sent. */
  serverUtcTimestamp: Date | undefined;
}

export enum AccountLogonResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - // success codes
   * User is logged in to the system. Account connection status becomes CONNECTED.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_PASSWORD_CHANGE_REQUIRED - Account password change required after the first logon of new account.
   * Account connection status remains CONNECTING.
   */
  RESULT_CODE_PASSWORD_CHANGE_REQUIRED = 1,
  /** RESULT_CODE_PASSWORD_EXPIRED - Account password expired. Account connection status remains CONNECTING. */
  RESULT_CODE_PASSWORD_EXPIRED = 2,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+), account connection status is reset to DISCONNECTED.
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountLogonResult_ResultCodeFromJSON(object: any): AccountLogonResult_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return AccountLogonResult_ResultCode.RESULT_CODE_SUCCESS;
    case 1:
    case 'RESULT_CODE_PASSWORD_CHANGE_REQUIRED':
      return AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_CHANGE_REQUIRED;
    case 2:
    case 'RESULT_CODE_PASSWORD_EXPIRED':
      return AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return AccountLogonResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountLogonResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountLogonResult_ResultCodeToJSON(object: AccountLogonResult_ResultCode): string {
  switch (object) {
    case AccountLogonResult_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_CHANGE_REQUIRED:
      return 'RESULT_CODE_PASSWORD_CHANGE_REQUIRED';
    case AccountLogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED:
      return 'RESULT_CODE_PASSWORD_EXPIRED';
    case AccountLogonResult_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case AccountLogonResult_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Account level password change request. */
export interface AccountPasswordChange {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** Currently used password. */
  oldPassword: string;
  /** New password that user wants to use. */
  newPassword: string;
}

/** Account level password change result. */
export interface AccountPasswordChangeResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Optional failure details. */
  textMessage: Text | undefined;
}

/** Password change result. */
export enum AccountPasswordChangeResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes
   * User password is changed successfully.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * Most likely incorrect user name or password.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountPasswordChangeResult_ResultCodeFromJSON(object: any): AccountPasswordChangeResult_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return AccountPasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return AccountPasswordChangeResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountPasswordChangeResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountPasswordChangeResult_ResultCodeToJSON(object: AccountPasswordChangeResult_ResultCode): string {
  switch (object) {
    case AccountPasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case AccountPasswordChangeResult_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case AccountPasswordChangeResult_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Account level Logoff request. */
export interface AccountLogoff {
  /** Account ID in CQG trade routing system. */
  accountId: number;
}

/** Account level Logoff result. */
export interface AccountLogoffResult {
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** This field is associated with ResultCode enum type. */
  resultCode: number;
  /** Optional failure description. */
  textMessage: Text | undefined;
  /** Time when the message was sent. */
  serverUtcTimestamp: Date | undefined;
}

/** Logoff result. */
export enum AccountLogoffResult_ResultCode {
  /**
   * RESULT_CODE_SUCCESS - / success codes
   * Logoff was successful.
   */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - / failure codes (100+)
   * Logoff failed.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function accountLogoffResult_ResultCodeFromJSON(object: any): AccountLogoffResult_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return AccountLogoffResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return AccountLogoffResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountLogoffResult_ResultCode.UNRECOGNIZED;
  }
}

export function accountLogoffResult_ResultCodeToJSON(object: AccountLogoffResult_ResultCode): string {
  switch (object) {
    case AccountLogoffResult_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case AccountLogoffResult_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case AccountLogoffResult_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Message for multi-step account level logon.
 * Multi-step account level logon sequence has the following steps:
 *      1) Client initiates logon negotiation by sending
 *         AccountLogonRoutineClient.logon_init message to the server.
 *      2) Server sends one or more AccountLogonRoutineServer.password_requests messages
 *         (this step may repeat after step #3).
 *      3) Client sends list of AccountLogonRoutineClient.password_responses back to the
 *         server.
 *      4) Server sends AccountLogonRoutineServer.logon_result as the final message of
 *         the negotiation.
 * Client may stop the negotiation by sending AccountLogonRoutineClient.abort_logon
 * message in response to any server AccountLogonRoutineServer.password_requests
 * message.
 * NOTE: only one of logon_init, password_responses and abort_logon fields has to be
 * specified.
 */
export interface AccountLogonRoutineClient {
  /**
   * Account ID in CQG trade routing system.
   * This is a required field.
   */
  accountId: number;
  /** Initiating of multi-step account level logon process by client. */
  logonInit: AccountLogonInit | undefined;
  /**
   * List of passwords in response to request from server.
   * The number of passwords in response should be equal to the number of passwords in request from server.
   */
  passwordResponses: PasswordResponse[];
  /** Aborting of multi-step account level logon process by client. */
  abortLogon: AbortRoutine | undefined;
}

/**
 * Initiating of account level multi-step logon process by client.
 * Note: unlike user_session_2.LogonInit used for trader logon, we do not assume that
 * user_name is required for account level logon. Where required, server will request it
 * via AccountLogonRoutineServer.password_requests with password_type=PASSWORD_TYPE_TEXT
 * and user_prompt_type=user_name.
 */
export interface AccountLogonInit {}

/**
 * Response to the AccountLogonRoutineClient message.
 * Only one of logon_result and password_requests fields is specified.
 * Note that most messages are shared with multi-step logon, though not all options may
 * be available when used with multi-step account level logon.
 */
export interface AccountLogonRoutineServer {
  /**
   * Account ID in CQG trade routing system.
   * This is a required field.
   */
  accountId: number;
  /** Final message of the negotiation with the results. */
  logonResult: LogonResult | undefined;
  /** List of requested passwords. */
  passwordRequests: PasswordRequest[];
}

/**
 * Message for multi-step account level password change.
 * Multi-step account level password change sequence has the following steps:
 *      1) Client initiates account level password change procedure by sending
 *         AccountPasswordChangeRoutineClient.init_password_change to the server.
 *      2) Server sends one or more AccountPasswordChangeRoutineServer.password_requests
 *         messages (this step may repeat after step #3).
 *      3) Client sends list of AccountPasswordChangeRoutineClient.password_responses
 *         back to the server.
 *      4) Server sends AccountPasswordChangeRoutineServer.password_change_result
 *         as the final message of the negotiation with the results.
 *         Client may stop the negotiation by sending
 *         AccountPasswordChangeRoutineClient.abort_password_change message in response
 *         to any server AccountPasswordChangeRoutineServer.password_request message.
 * NOTE: only one of init_password_change, password_responses and abort_password_change
 * fields has to be specified.
 */
export interface AccountPasswordChangeRoutineClient {
  /**
   * Account ID in CQG trade routing system.
   * This is a required field.
   */
  accountId: number;
  /** True means user has decided to initiate password changing procedure. */
  initPasswordChange: boolean;
  /**
   * List of passwords in response to request from server.
   * The number of passwords in response should be equal to the number of passwords in request from server.
   */
  passwordResponses: PasswordResponse[];
  /** Aborting of multi-step password change process by client. */
  abortPasswordChange: AbortRoutine | undefined;
}

/**
 * Response to the AccountPasswordChangeRoutineClient message.
 * Only one of password_requests and password_change_result fields is specified.
 * Note that most messages are shared with multi-step logon, though not all options may
 * be available when used with multi-step account level logon.
 */
export interface AccountPasswordChangeRoutineServer {
  /**
   * Account ID in CQG trade routing system.
   * This is a required field.
   */
  accountId: number;
  /** List of requested passwords. */
  passwordRequests: PasswordRequest[];
  /** Final message of the negotiation with the results. */
  passwordChangeResult: PasswordChangeResult | undefined;
}

function createBaseAccountLogon(): AccountLogon {
  return { accountId: 0, username: '', password: '' };
}

export const AccountLogon = {
  encode(message: AccountLogon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.username !== '') {
      writer.uint32(18).string(message.username);
    }
    if (message.password !== '') {
      writer.uint32(26).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogon {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.username = reader.string();
          break;
        case 3:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogon {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      username: isSet(object.username) ? String(object.username) : '',
      password: isSet(object.password) ? String(object.password) : '',
    };
  },

  toJSON(message: AccountLogon): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogon>, I>>(base?: I): AccountLogon {
    return AccountLogon.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogon>, I>>(object: I): AccountLogon {
    const message = createBaseAccountLogon();
    message.accountId = object.accountId ?? 0;
    message.username = object.username ?? '';
    message.password = object.password ?? '';
    return message;
  },
};

function createBaseAccountLogonResult(): AccountLogonResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined, serverUtcTimestamp: undefined };
}

export const AccountLogonResult = {
  encode(message: AccountLogonResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    if (message.serverUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.serverUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogonResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogonResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.textMessage = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.serverUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogonResult {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
      serverUtcTimestamp: isSet(object.serverUtcTimestamp) ? fromJsonTimestamp(object.serverUtcTimestamp) : undefined,
    };
  },

  toJSON(message: AccountLogonResult): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage ? Text.toJSON(message.textMessage) : undefined);
    message.serverUtcTimestamp !== undefined && (obj.serverUtcTimestamp = message.serverUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogonResult>, I>>(base?: I): AccountLogonResult {
    return AccountLogonResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogonResult>, I>>(object: I): AccountLogonResult {
    const message = createBaseAccountLogonResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage =
      object.textMessage !== undefined && object.textMessage !== null ? Text.fromPartial(object.textMessage) : undefined;
    message.serverUtcTimestamp = object.serverUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseAccountPasswordChange(): AccountPasswordChange {
  return { accountId: 0, oldPassword: '', newPassword: '' };
}

export const AccountPasswordChange = {
  encode(message: AccountPasswordChange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.oldPassword !== '') {
      writer.uint32(18).string(message.oldPassword);
    }
    if (message.newPassword !== '') {
      writer.uint32(26).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChange {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.oldPassword = reader.string();
          break;
        case 3:
          message.newPassword = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChange {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      oldPassword: isSet(object.oldPassword) ? String(object.oldPassword) : '',
      newPassword: isSet(object.newPassword) ? String(object.newPassword) : '',
    };
  },

  toJSON(message: AccountPasswordChange): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.oldPassword !== undefined && (obj.oldPassword = message.oldPassword);
    message.newPassword !== undefined && (obj.newPassword = message.newPassword);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChange>, I>>(base?: I): AccountPasswordChange {
    return AccountPasswordChange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountPasswordChange>, I>>(object: I): AccountPasswordChange {
    const message = createBaseAccountPasswordChange();
    message.accountId = object.accountId ?? 0;
    message.oldPassword = object.oldPassword ?? '';
    message.newPassword = object.newPassword ?? '';
    return message;
  },
};

function createBaseAccountPasswordChangeResult(): AccountPasswordChangeResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined };
}

export const AccountPasswordChangeResult = {
  encode(message: AccountPasswordChangeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChangeResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChangeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.textMessage = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChangeResult {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
    };
  },

  toJSON(message: AccountPasswordChangeResult): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage ? Text.toJSON(message.textMessage) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChangeResult>, I>>(base?: I): AccountPasswordChangeResult {
    return AccountPasswordChangeResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountPasswordChangeResult>, I>>(object: I): AccountPasswordChangeResult {
    const message = createBaseAccountPasswordChangeResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage =
      object.textMessage !== undefined && object.textMessage !== null ? Text.fromPartial(object.textMessage) : undefined;
    return message;
  },
};

function createBaseAccountLogoff(): AccountLogoff {
  return { accountId: 0 };
}

export const AccountLogoff = {
  encode(message: AccountLogoff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogoff {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogoff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogoff {
    return { accountId: isSet(object.accountId) ? Number(object.accountId) : 0 };
  },

  toJSON(message: AccountLogoff): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogoff>, I>>(base?: I): AccountLogoff {
    return AccountLogoff.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogoff>, I>>(object: I): AccountLogoff {
    const message = createBaseAccountLogoff();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountLogoffResult(): AccountLogoffResult {
  return { accountId: 0, resultCode: 0, textMessage: undefined, serverUtcTimestamp: undefined };
}

export const AccountLogoffResult = {
  encode(message: AccountLogoffResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.textMessage !== undefined) {
      Text.encode(message.textMessage, writer.uint32(26).fork()).ldelim();
    }
    if (message.serverUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.serverUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogoffResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogoffResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.textMessage = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.serverUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogoffResult {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      textMessage: isSet(object.textMessage) ? Text.fromJSON(object.textMessage) : undefined,
      serverUtcTimestamp: isSet(object.serverUtcTimestamp) ? fromJsonTimestamp(object.serverUtcTimestamp) : undefined,
    };
  },

  toJSON(message: AccountLogoffResult): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage ? Text.toJSON(message.textMessage) : undefined);
    message.serverUtcTimestamp !== undefined && (obj.serverUtcTimestamp = message.serverUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogoffResult>, I>>(base?: I): AccountLogoffResult {
    return AccountLogoffResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogoffResult>, I>>(object: I): AccountLogoffResult {
    const message = createBaseAccountLogoffResult();
    message.accountId = object.accountId ?? 0;
    message.resultCode = object.resultCode ?? 0;
    message.textMessage =
      object.textMessage !== undefined && object.textMessage !== null ? Text.fromPartial(object.textMessage) : undefined;
    message.serverUtcTimestamp = object.serverUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseAccountLogonRoutineClient(): AccountLogonRoutineClient {
  return { accountId: 0, logonInit: undefined, passwordResponses: [], abortLogon: undefined };
}

export const AccountLogonRoutineClient = {
  encode(message: AccountLogonRoutineClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.logonInit !== undefined) {
      AccountLogonInit.encode(message.logonInit, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.passwordResponses) {
      PasswordResponse.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.abortLogon !== undefined) {
      AbortRoutine.encode(message.abortLogon, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogonRoutineClient {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogonRoutineClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.logonInit = AccountLogonInit.decode(reader, reader.uint32());
          break;
        case 3:
          message.passwordResponses.push(PasswordResponse.decode(reader, reader.uint32()));
          break;
        case 4:
          message.abortLogon = AbortRoutine.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogonRoutineClient {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      logonInit: isSet(object.logonInit) ? AccountLogonInit.fromJSON(object.logonInit) : undefined,
      passwordResponses: Array.isArray(object?.passwordResponses)
        ? object.passwordResponses.map((e: any) => PasswordResponse.fromJSON(e))
        : [],
      abortLogon: isSet(object.abortLogon) ? AbortRoutine.fromJSON(object.abortLogon) : undefined,
    };
  },

  toJSON(message: AccountLogonRoutineClient): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.logonInit !== undefined &&
      (obj.logonInit = message.logonInit ? AccountLogonInit.toJSON(message.logonInit) : undefined);
    if (message.passwordResponses) {
      obj.passwordResponses = message.passwordResponses.map((e) => (e ? PasswordResponse.toJSON(e) : undefined));
    } else {
      obj.passwordResponses = [];
    }
    message.abortLogon !== undefined &&
      (obj.abortLogon = message.abortLogon ? AbortRoutine.toJSON(message.abortLogon) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogonRoutineClient>, I>>(base?: I): AccountLogonRoutineClient {
    return AccountLogonRoutineClient.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogonRoutineClient>, I>>(object: I): AccountLogonRoutineClient {
    const message = createBaseAccountLogonRoutineClient();
    message.accountId = object.accountId ?? 0;
    message.logonInit =
      object.logonInit !== undefined && object.logonInit !== null ? AccountLogonInit.fromPartial(object.logonInit) : undefined;
    message.passwordResponses = object.passwordResponses?.map((e) => PasswordResponse.fromPartial(e)) || [];
    message.abortLogon =
      object.abortLogon !== undefined && object.abortLogon !== null ? AbortRoutine.fromPartial(object.abortLogon) : undefined;
    return message;
  },
};

function createBaseAccountLogonInit(): AccountLogonInit {
  return {};
}

export const AccountLogonInit = {
  encode(_: AccountLogonInit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogonInit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogonInit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AccountLogonInit {
    return {};
  },

  toJSON(_: AccountLogonInit): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogonInit>, I>>(base?: I): AccountLogonInit {
    return AccountLogonInit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogonInit>, I>>(_: I): AccountLogonInit {
    const message = createBaseAccountLogonInit();
    return message;
  },
};

function createBaseAccountLogonRoutineServer(): AccountLogonRoutineServer {
  return { accountId: 0, logonResult: undefined, passwordRequests: [] };
}

export const AccountLogonRoutineServer = {
  encode(message: AccountLogonRoutineServer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.logonResult !== undefined) {
      LogonResult.encode(message.logonResult, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.passwordRequests) {
      PasswordRequest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountLogonRoutineServer {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountLogonRoutineServer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.logonResult = LogonResult.decode(reader, reader.uint32());
          break;
        case 3:
          message.passwordRequests.push(PasswordRequest.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountLogonRoutineServer {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      logonResult: isSet(object.logonResult) ? LogonResult.fromJSON(object.logonResult) : undefined,
      passwordRequests: Array.isArray(object?.passwordRequests)
        ? object.passwordRequests.map((e: any) => PasswordRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountLogonRoutineServer): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.logonResult !== undefined &&
      (obj.logonResult = message.logonResult ? LogonResult.toJSON(message.logonResult) : undefined);
    if (message.passwordRequests) {
      obj.passwordRequests = message.passwordRequests.map((e) => (e ? PasswordRequest.toJSON(e) : undefined));
    } else {
      obj.passwordRequests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountLogonRoutineServer>, I>>(base?: I): AccountLogonRoutineServer {
    return AccountLogonRoutineServer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountLogonRoutineServer>, I>>(object: I): AccountLogonRoutineServer {
    const message = createBaseAccountLogonRoutineServer();
    message.accountId = object.accountId ?? 0;
    message.logonResult =
      object.logonResult !== undefined && object.logonResult !== null ? LogonResult.fromPartial(object.logonResult) : undefined;
    message.passwordRequests = object.passwordRequests?.map((e) => PasswordRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountPasswordChangeRoutineClient(): AccountPasswordChangeRoutineClient {
  return { accountId: 0, initPasswordChange: false, passwordResponses: [], abortPasswordChange: undefined };
}

export const AccountPasswordChangeRoutineClient = {
  encode(message: AccountPasswordChangeRoutineClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.initPasswordChange === true) {
      writer.uint32(16).bool(message.initPasswordChange);
    }
    for (const v of message.passwordResponses) {
      PasswordResponse.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.abortPasswordChange !== undefined) {
      AbortRoutine.encode(message.abortPasswordChange, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChangeRoutineClient {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChangeRoutineClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.initPasswordChange = reader.bool();
          break;
        case 3:
          message.passwordResponses.push(PasswordResponse.decode(reader, reader.uint32()));
          break;
        case 4:
          message.abortPasswordChange = AbortRoutine.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChangeRoutineClient {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      initPasswordChange: isSet(object.initPasswordChange) ? Boolean(object.initPasswordChange) : false,
      passwordResponses: Array.isArray(object?.passwordResponses)
        ? object.passwordResponses.map((e: any) => PasswordResponse.fromJSON(e))
        : [],
      abortPasswordChange: isSet(object.abortPasswordChange) ? AbortRoutine.fromJSON(object.abortPasswordChange) : undefined,
    };
  },

  toJSON(message: AccountPasswordChangeRoutineClient): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.initPasswordChange !== undefined && (obj.initPasswordChange = message.initPasswordChange);
    if (message.passwordResponses) {
      obj.passwordResponses = message.passwordResponses.map((e) => (e ? PasswordResponse.toJSON(e) : undefined));
    } else {
      obj.passwordResponses = [];
    }
    message.abortPasswordChange !== undefined &&
      (obj.abortPasswordChange = message.abortPasswordChange ? AbortRoutine.toJSON(message.abortPasswordChange) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChangeRoutineClient>, I>>(base?: I): AccountPasswordChangeRoutineClient {
    return AccountPasswordChangeRoutineClient.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountPasswordChangeRoutineClient>, I>>(
    object: I
  ): AccountPasswordChangeRoutineClient {
    const message = createBaseAccountPasswordChangeRoutineClient();
    message.accountId = object.accountId ?? 0;
    message.initPasswordChange = object.initPasswordChange ?? false;
    message.passwordResponses = object.passwordResponses?.map((e) => PasswordResponse.fromPartial(e)) || [];
    message.abortPasswordChange =
      object.abortPasswordChange !== undefined && object.abortPasswordChange !== null
        ? AbortRoutine.fromPartial(object.abortPasswordChange)
        : undefined;
    return message;
  },
};

function createBaseAccountPasswordChangeRoutineServer(): AccountPasswordChangeRoutineServer {
  return { accountId: 0, passwordRequests: [], passwordChangeResult: undefined };
}

export const AccountPasswordChangeRoutineServer = {
  encode(message: AccountPasswordChangeRoutineServer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    for (const v of message.passwordRequests) {
      PasswordRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.passwordChangeResult !== undefined) {
      PasswordChangeResult.encode(message.passwordChangeResult, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPasswordChangeRoutineServer {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPasswordChangeRoutineServer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.passwordRequests.push(PasswordRequest.decode(reader, reader.uint32()));
          break;
        case 3:
          message.passwordChangeResult = PasswordChangeResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountPasswordChangeRoutineServer {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      passwordRequests: Array.isArray(object?.passwordRequests)
        ? object.passwordRequests.map((e: any) => PasswordRequest.fromJSON(e))
        : [],
      passwordChangeResult: isSet(object.passwordChangeResult)
        ? PasswordChangeResult.fromJSON(object.passwordChangeResult)
        : undefined,
    };
  },

  toJSON(message: AccountPasswordChangeRoutineServer): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    if (message.passwordRequests) {
      obj.passwordRequests = message.passwordRequests.map((e) => (e ? PasswordRequest.toJSON(e) : undefined));
    } else {
      obj.passwordRequests = [];
    }
    message.passwordChangeResult !== undefined &&
      (obj.passwordChangeResult = message.passwordChangeResult
        ? PasswordChangeResult.toJSON(message.passwordChangeResult)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPasswordChangeRoutineServer>, I>>(base?: I): AccountPasswordChangeRoutineServer {
    return AccountPasswordChangeRoutineServer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountPasswordChangeRoutineServer>, I>>(
    object: I
  ): AccountPasswordChangeRoutineServer {
    const message = createBaseAccountPasswordChangeRoutineServer();
    message.accountId = object.accountId ?? 0;
    message.passwordRequests = object.passwordRequests?.map((e) => PasswordRequest.fromPartial(e)) || [];
    message.passwordChangeResult =
      object.passwordChangeResult !== undefined && object.passwordChangeResult !== null
        ? PasswordChangeResult.fromPartial(object.passwordChangeResult)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
