import { useQuery } from '@tanstack/react-query';
import config from '../config';
import apiClient from '../shared/apiClient';
import { HistoryQuotesStoreItem, prepareHistoryQuotes } from '../store/HistoryQuotes/historyQuotesSlice';
import { IHistoryQuote } from '../types/IHistoryQuote';
import { QueryKey } from './QueryKey';

export interface IHistoryQuoteParams {
  // barchartSymbol, kept 'symbol' as key to match back end
  symbol: string;
  type: string;
  startDate: string;
  endDate: string;
}

const fetchHistoryQuotes = async (params: IHistoryQuoteParams | null): Promise<HistoryQuotesStoreItem> => {
  if (!params) {
    return {};
  }
  const response = await apiClient.get<IHistoryQuote[]>(`${config.backend.basePath}/market_data/history`, {
    params: { ...params },
  });

  return prepareHistoryQuotes(params, response.data);
};

export const useHistoryQuotes = (params: IHistoryQuoteParams | null) => {
  const query = useQuery([QueryKey.HistoryQuotes, params], () => fetchHistoryQuotes(params), {
    enabled: !!params,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 120,
  });
  return query;
};
