import { isNil, random } from 'lodash';
import Long from 'long';
import { v4 as uuid4 } from 'uuid';
import WebSocketAsPromised from 'websocket-as-promised';
import WSAPOptions from 'websocket-as-promised/types/options';
import { TradeLogger } from '../loggers/TradeLogger';
import { CQGLog, CQGOriginLog, TradingLogOriginType } from '../loggers/types';
import { nowUtcFormatted } from '../dayjs';
import { cqgEvents } from './cqgEvents';
import { ErrorCode, ErrorRecord, TimeoutError } from './Errors';
import {
  informationReportsStatusToString,
  logoffReasonToString,
  logonResultToString,
  marketDataSubscriptionStatusToString,
  orderStatusToString,
  passwordChangeResultToString,
  restoreOrJoinResultToString,
  timeAndSalesResultToString,
  timeBarDataResultToString,
  tradeSubscriptionScopeToString,
  tradeSubscriptionStatusToString,
} from './statusToString';
import { ContractMetadata } from '../cqgMessages/WebAPI/metadata_2';
import { asyncDelay } from './helpers';
import { Account, Brokerage } from '../cqgMessages/WebAPI/trading_account_2';
import {
  ActivateOrder,
  CancelOrder,
  ModifyOrder,
  Order,
  OrderRequest,
  OrderRequestReject,
  OrderStatus,
  Order_Duration,
  Order_OrderType,
  Order_Side,
  SuspendOrder,
} from '../cqgMessages/WebAPI/order_2';
import {
  ClientMsg,
  InformationReport_StatusCode,
  InformationRequest,
  ProtocolVersionMajor,
  ProtocolVersionMinor,
  ServerMsg,
} from '../cqgMessages/WebAPI/webapi_2';
import {
  Logoff,
  Logon,
  LogonResult_ResultCode,
  Logon_SessionSetting,
  PasswordChange,
  Ping,
  Pong,
  RestoreOrJoinSession,
} from '../cqgMessages/WebAPI/user_session_2';
import {
  HistoricalOrdersRequest,
  TradeSubscription,
  TradeSubscriptionStatus_StatusCode,
  TradeSubscription_SubscriptionScope,
} from '../cqgMessages/WebAPI/trade_routing_2';
import {
  ProductSearchRequest,
  SymbolCategoryListRequest,
  SymbolCategoryRequest,
  SymbolListRequest,
} from '../cqgMessages/WebAPI/symbol_browsing_2';
import {
  TimeAndSalesParameters_Level,
  TimeAndSalesRequest,
  TimeAndSalesRequest_RequestType,
  TimeBarParameters_BarUnit,
  TimeBarRequest,
  TimeBarRequest_RequestType,
} from '../cqgMessages/WebAPI/historical_2';
import { MarketDataSubscription, MarketDataSubscription_Level } from '../cqgMessages/WebAPI/market_data_2';
import {
  OrderMetadata,
  ClientContext,
  CQGClientOptions,
  SocketState,
  WaitUnpackedMessageOptions,
  IConvertedSessionTimerange,
} from '../types';
import { OrderVerifiedMessage } from './models';
import { getClientAppId, getPrivateLabel } from '../config';
import { logClientActions, logClientActionsError, logRawMessageTradingLog, logServerMsg } from '../loggers';
import { NanoEventEmitter } from '../NanoEventEmitter';

export * from '../types';
export * from './models';

export class CQGClient extends NanoEventEmitter {
  private userId: number; // for logging purposes
  private operationId: number; // for logging purposes
  private cqgWebsocket: WebSocketAsPromised;
  private websocketAddress: string;
  public username: string;
  public password: string;
  private numberOfRecievedServerMessages = 0;
  private sessionToken: string | null | undefined = null;
  private baseTime: number | null = null;
  private messageIdCounter = 0;
  private requestIdCounter = 0;
  private brokerageAccountIds: string[] = [];
  public currentContractSymbol: string | null = null;
  private symbolInfo = new Map<string, ContractMetadata>();
  private brokerages: Brokerage[] = [];
  private orderRequests = new Map<number, OrderRequest>();
  private currentOrderRequest: OrderMetadata | null = null;
  private orderRequestRejects = new Map<number, OrderRequestReject>();
  private orderStatus = new Map<string, OrderStatus>();
  private logoffPending = false;
  private tradeSubscribed = false;
  private context = ClientContext.OTHER;
  public constructedAt = Date.now();
  private requestTimeout = 20000;
  private logPingPong = true;
  private logger: TradeLogger;
  private _KEEP_ALIVE_INTERVAL_HANDLE: NodeJS.Timeout | null = null;
  private _KEEP_ALIVE_DURATION_HANDLE: NodeJS.Timeout | null = null;
  private _KEEP_ALIVE_INTERVAL: number | undefined;
  private _KEEP_ALIVE_DURATION: number | boolean | undefined;

  constructor(options: CQGClientOptions) {
    super();
    this.userId = options.userId;
    this.operationId = options.operationId;
    this.websocketAddress = options.websocketAddress;
    this.username = options.username;
    this.password = options.password;
    this.cqgWebsocket = this.ensureWebsocketConnection(options.customCreateWebsocket);

    if (!options.logger) {
      this.logger = new TradeLogger(options?.env ?? '', options?.dataDogEnabled ?? false);
    } else {
      this.logger = options.logger;
    }

    this.logPingPong = options.logPingPong ?? true;
    this._KEEP_ALIVE_DURATION = options.keepAliveDuration;
    this._KEEP_ALIVE_INTERVAL = options.keepAliveInterval;
    if (!isNil(options.keepAliveDuration) && options.keepAliveDuration !== false) {
      this.startKeepAlive(
        typeof this._KEEP_ALIVE_DURATION === 'number' ? this._KEEP_ALIVE_DURATION : undefined,
        this._KEEP_ALIVE_INTERVAL
      );
    }
    logClientActions(this.logger, this.getClientLogData(), `CQG Client initialization - ${this.websocketAddress}`);
    if (process.env.RUN_CQG_compliance || process.env.NODE_ENV === 'test') {
      this.requestTimeout = 500;
    }
  }

  /** Allow overriding the timeout value for particular transactions, e.g. order placement */
  private onTimeout(caller?: string, overrideTimeout?: number) {
    let timeout = overrideTimeout ?? this.requestTimeout;
    if (process.env.RUN_CQG_compliance || process.env.NODE_ENV === 'test') {
      timeout = this.requestTimeout;
    }
    return {
      timeout: timeout,
      timeoutError: new TimeoutError(`A request took too long. Please try again. (${caller})`),
    };
  }

  private ensureWebsocketConnection(customCreateWebsocket?: () => WebSocket) {
    if (this.cqgWebsocket && (this.cqgWebsocket.isOpened || this.cqgWebsocket.isOpening)) {
      logClientActions(this.logger, this.getClientLogData(), 'Already connected to websocket server');
      return this.cqgWebsocket;
    }

    const opts: WSAPOptions = {
      createWebSocket: () => {
        if (customCreateWebsocket) {
          return customCreateWebsocket();
        }
        const ws = new WebSocket(this.websocketAddress);
        ws.binaryType = 'arraybuffer';
        return ws;
      },
      packMessage: (data) => {
        if ((isNil(data.pong) && isNil(data.ping)) || this.logPingPong) {
          logRawMessageTradingLog(this.logger, {
            ...this.getClientLogData(),
            cqgData: data,
          });
        }
        const msg = ClientMsg.encode(data).finish();
        return msg;
      },
      unpackMessage: (data: string | ArrayBuffer | Blob) => {
        const bufferedMsg = new Uint8Array(data as ArrayBuffer);
        const msg = ServerMsg.decode(bufferedMsg);
        if ((isNil(msg.pong) && isNil(msg.ping)) || this.logPingPong) {
          logRawMessageTradingLog(this.logger, {
            ...this.getServerLogData(),
            cqgData: msg,
          });
        }
        return msg;
      },
      attachRequestId: (data) => {
        return data;
      },
      extractRequestId: (data) => {
        return data;
      },
    };

    const cqgWebsocket = new WebSocketAsPromised(this.websocketAddress, opts);
    cqgWebsocket.onOpen.addListener((e) => {
      logClientActions(this.logger, this.getClientLogData(), 'Connected to websocket server');
      this.emit(cqgEvents.websocketConnect, e);
    });

    cqgWebsocket.onClose.addListener((e) => {
      logClientActions(this.logger, this.getClientLogData(), 'Disconnected from websocket');
      this.baseTime = null; // flag that it's no longer logged in
      this.emit(cqgEvents.websocketDisconnect, e);
    });

    cqgWebsocket.onUnpackedMessage.addListener((msg) => {
      this.emit(cqgEvents.receivedMessage, msg);
      this.processServerMessage(msg);
    });

    return cqgWebsocket;
  }

  getSocketState() {
    if (this.cqgWebsocket.isClosed) return SocketState.closed;
    if (this.cqgWebsocket.isClosing) return SocketState.closing;
    if (this.cqgWebsocket.isOpening) return SocketState.opening;
    if (this.cqgWebsocket.isOpened) return SocketState.open;
    return SocketState.closed;
  }

  // EB-TODO: document what event listeners we are expecting to keep?
  async close(options = { removeListeners: true }) {
    await this.cqgWebsocket.close();
    this.context = ClientContext.NONE;

    this.stopKeepAlive();
    // Remove only the CQG Events listeners
    if (options.removeListeners) {
      Object.values(cqgEvents).forEach((event) => {
        this.removeAllListeners(event);
      });
    }
    this.emit(cqgEvents.websocketClosed);
  }

  getNumberOfRecievedServerMessages() {
    return this.numberOfRecievedServerMessages;
  }

  getUtcTime(date?: Date) {
    const nowUnixTimestamp = date ? date.valueOf() : Date.now();
    return this.baseTime ? nowUnixTimestamp - this.baseTime : null;
  }

  getValidAccounts() {
    if (this.websocketAddress.includes('demo')) {
      // Demo Environment
      const accountId = this.brokerages?.[0]?.salesSeries?.[0]?.accounts?.[0].accountId || null;
      return accountId
        ? [
            {
              accountId: accountId,
              name: '',
              brokerageAccountId: '',
              lastStatementDate: 1,
            } as Account,
          ]
        : [];
    } else {
      const brokerage = this.brokerages?.find((x) => x.name.includes('Stone'));
      const accounts = brokerage?.salesSeries?.flatMap((x) => x.accounts) || [];
      const validAccounts = accounts
        .filter((x) => !!x)
        .filter((x) => this.brokerageAccountIds.includes(x?.brokerageAccountId ?? 'notAnAccount'));
      return validAccounts.length ? validAccounts : [];
    }
  }

  getBrokerageAccountIds() {
    const validAccounts = this.getValidAccounts();
    return validAccounts.map((x) => x?.brokerageAccountId);
  }

  convertUtcTime(serverTime: number | Long) {
    if (Long.isLong(serverTime)) {
      return this.baseTime ? new Date(serverTime.add(this.baseTime).toNumber()) : null;
    } else {
      return this.baseTime ? new Date(serverTime + this.baseTime) : null;
    }
  }

  setMetadata(userId: number, operationId: number) {
    this.userId = userId;
    this.operationId = operationId;
  }

  getMetadata() {
    return { userId: this.userId, operationId: this.operationId };
  }

  setContext(context: ClientContext) {
    this.context = context;
  }

  getContext() {
    return this.context;
  }

  getTradeSubscriptionStatus() {
    return this.tradeSubscribed;
  }

  getCurrentSymbol() {
    return this.currentContractSymbol ? this.symbolInfo.get(this.currentContractSymbol) : null;
  }

  getCurrentOrderRequest() {
    return this.currentOrderRequest;
  }

  clearCurrentOrderRequest() {
    this.currentOrderRequest = null;
  }

  setCredentials(username: string, password: string) {
    this.username = username;
    this.password = password;
  }

  static stringHash(str: string) {
    let hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  getCqgLogData(): CQGLog {
    return {
      userId: this.userId,
      operationId: this.operationId,
      datetime: nowUtcFormatted(),
      sessionToken: this.sessionToken ?? undefined,
      sessionTokenHash: this.sessionToken ? CQGClient.stringHash(this.sessionToken) : undefined,
    };
  }

  getClientLogData(): CQGOriginLog {
    return {
      ...this.getCqgLogData(),
      origin: TradingLogOriginType.User,
    };
  }

  getServerLogData(): CQGOriginLog {
    return {
      ...this.getCqgLogData(),
      origin: TradingLogOriginType.CQG,
    };
  }

  getDebugInfo() {
    return `${nowUtcFormatted()} - ${CQGClient.stringHash(this.sessionToken || '')} - [${this.username}:${this.sessionToken}]`;
  }

  getSessionToken() {
    return this.sessionToken;
  }

  clearCredentials() {
    this.username = '';
    this.password = '';
  }

  /**
   * The FCM Account Number strings
   * @example: `FMI86069`
   */
  setBrokerageAccountIds(brokerageAccountNumbers: string[]) {
    this.brokerageAccountIds = brokerageAccountNumbers || [];
  }

  getBrokerages() {
    return this.brokerages;
  }

  getOrderStatus(clOrderId: string) {
    if (!this.orderStatus.has(clOrderId)) {
      logClientActionsError(this.logger, this.getClientLogData(), `Unknown clOrderId: ${clOrderId}`);
    }
    return this.orderStatus.get(clOrderId);
  }

  wasRequestRejected(requestId: number) {
    return this.orderRequestRejects.has(requestId);
  }

  toJSON() {
    return {
      constructedAt: this.constructedAt,
      sessionToken: this.sessionToken,
      username: this.username,
      opId: this.operationId,
      userId: this.userId,
      socket: SocketState[this.getSocketState()],
      context: ClientContext[this.context],
      tradeSubscribed: this.tradeSubscribed,
      address: this.websocketAddress,
    };
  }

  async dispose() {
    await this.close({ removeListeners: true });
    this.removeAllListeners();
  }

  private async sendLogonClientMessage(msg: ClientMsg) {
    // Opens socket if not open
    // for use in logon and restore messages
    if (this.cqgWebsocket.isClosing) {
      await this.cqgWebsocket.close();
    }
    if (!this.cqgWebsocket.isOpened) {
      await this.cqgWebsocket.open();
    }
    this.cqgWebsocket.sendPacked(msg);
  }

  private sendClientMessage(msg: ClientMsg) {
    if (!this.cqgWebsocket.isOpened) {
      throw new Error('Attempting to send a message on closed websocket');
    }
    if (!this.isLoggedOn()) {
      throw new Error('Attempting to send a message without being logged on');
    }
    this.cqgWebsocket.sendPacked(msg);
  }

  // Logon
  /**
   * Ping (websocket message)
   */
  async ping() {
    const utcTime = this.getUtcTime();
    if (utcTime) {
      const pingMsg = Ping.fromJSON({
        token: `client_ping_${random(1000)}`,
        pingUtcTime: utcTime,
      });

      const clientMsg = ClientMsg.fromJSON({});
      clientMsg.ping = pingMsg;
      logClientActions(this.logger, this.getClientLogData(), 'Sending Ping', pingMsg);
      this.sendClientMessage(clientMsg);
      this.emit(cqgEvents.sendPing);
      return await this.waitForMessage((data) => {
        return data.pong?.pingUtcTime === pingMsg.pingUtcTime;
      });
    } else {
      logClientActionsError(this.logger, this.getClientLogData(), 'Can not determine client time for ping. Are you logged in?');
    }
  }

  pingFireAndForget() {
    const utcTime = this.getUtcTime();
    if (utcTime && this.cqgWebsocket.isOpened) {
      const pingMsg = Ping.fromJSON({
        token: `client_ping_${random(1000)}`,
        pingUtcTime: utcTime,
      });

      const clientMsg = ClientMsg.fromJSON({});
      clientMsg.ping = pingMsg;
      if (this.logPingPong) {
        logClientActions(this.logger, this.getClientLogData(), 'Sending Ping', pingMsg);
      }
      this.sendClientMessage(clientMsg);
      this.emit(cqgEvents.sendPing);
    } else {
      logClientActionsError(
        this.logger,
        this.getClientLogData(),
        'Websocket not open or can not determine client time for ping. Closing connection'
      );
      this.close().catch((error) =>
        logClientActionsError(this.logger, this.getClientLogData(), 'Failure in closing websocket after failed ping', error)
      );
    }
  }

  /** There are two configuration options for this function. See `CQGClientOptions` for more information
   * @param durationInMinutes corresponds to the `keepAliveDuration` option
   * @param intervalInMilliseconds corresponds to the `keepAliveInterval` option
   */
  startKeepAlive(durationInMinutes = 0, intervalInMilliseconds = 2000) {
    if (!this._KEEP_ALIVE_INTERVAL_HANDLE) {
      this._KEEP_ALIVE_INTERVAL_HANDLE = setInterval(() => {
        if (this.isLoggedOn()) {
          this.pingFireAndForget();
        }
      }, intervalInMilliseconds);
    }

    if (durationInMinutes && !this._KEEP_ALIVE_DURATION_HANDLE) {
      this._KEEP_ALIVE_DURATION_HANDLE = setTimeout(() => {
        if (this._KEEP_ALIVE_INTERVAL_HANDLE) {
          clearInterval(this._KEEP_ALIVE_INTERVAL_HANDLE);
        }
      }, durationInMinutes * 1000 * 60);
    }
  }

  stopKeepAlive() {
    if (this._KEEP_ALIVE_INTERVAL_HANDLE) {
      clearInterval(this._KEEP_ALIVE_INTERVAL_HANDLE);
    }
    if (this._KEEP_ALIVE_DURATION_HANDLE) {
      clearTimeout(this._KEEP_ALIVE_DURATION_HANDLE);
    }
  }

  /**
   * Logon (websocket message)
   */
  async logon(
    username?: string,
    password?: string,
    dropConcurrentSession: boolean | null = null,
    allowRestore?: boolean,
    allowJoin?: boolean
  ) {
    this.startKeepAlive(
      typeof this._KEEP_ALIVE_DURATION === 'number' ? this._KEEP_ALIVE_DURATION : undefined,
      this._KEEP_ALIVE_INTERVAL
    );
    if (this.isLoggedOn()) {
      return Promise.resolve(true);
    }
    const sessionSettings: number[] = [];
    if (allowJoin) {
      sessionSettings.push(Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_JOIN);
    }
    if (allowRestore) {
      sessionSettings.push(Logon_SessionSetting.SESSION_SETTING_ALLOW_SESSION_RESTORE);
    }
    const logonMsg = Logon.fromPartial({
      privateLabel: getPrivateLabel(),
      userName: username || this.username,
      password: password || this.password,
      clientAppId: getClientAppId(),
      clientVersion: '0.1',
      protocolVersionMajor: ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR,
      protocolVersionMinor: ProtocolVersionMinor.PROTOCOL_VERSION_MINOR,
      dropConcurrentSession: dropConcurrentSession ?? undefined,
      sessionSettings,
    });

    const clientMsg = ClientMsg.fromPartial({
      logon: logonMsg,
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Logon', logonMsg);
    this.emit(cqgEvents.sendLogon, logonMsg);
    await this.sendLogonClientMessage(clientMsg);

    const serverMsg = await this.waitForMessage((data) => {
      // This waits for a logonResult message and returns that message
      // It does not wait for a successful logon
      if (!isNil(data.logonResult)) {
        return true;
      }

      return false;
    }, this.onTimeout('Logon'));

    if (!serverMsg.logonResult) {
      throw new Error('LogonResult not found in logon response');
    }

    const logonResult = serverMsg.logonResult;
    if (logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_SUCCESS) {
      this.sessionToken = logonResult.sessionToken;
      this.baseTime = new Date(logonResult.baseTime + 'Z').getTime();
      return this.isLoggedOn();
    } else if (
      logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_FAILURE &&
      (logonResult.textMessage?.includes('already logged on') || logonResult.textMessage?.includes('initiated logon procedure'))
    ) {
      return this.isLoggedOn();
    } else if (logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_CONCURRENT_SESSION) {
      throw new Error(logonResult.textMessage || 'Logon failed due to another session already being active');
    } else {
      throw new Error(`LogonResult unhandled in logon: ${JSON.stringify(logonResult)}`);
    }
  }

  isLoggedOn() {
    return !!this.baseTime;
  }

  /**
   * Logoff (websocket message)
   */
  async logoff() {
    if (!this.isLoggedOn() || this.logoffPending) {
      return Promise.resolve(true);
    }

    const logoffMsg = Logoff.fromPartial({});
    const clientMsg = ClientMsg.fromPartial({
      logoff: logoffMsg,
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Logoff', logoffMsg);
    this.logoffPending = true;
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendLogoff);

    const resp = await this.waitForMessage((data) => {
      return !isNil(data.loggedOff);
    }, this.onTimeout('Logoff'));

    if (!resp.loggedOff) {
      throw new Error('LogoffResult not found in logoff response');
    }

    this.clearState();
    return !this.isLoggedOn();
  }

  ensureSession() {
    this.cqgWebsocket = this.ensureWebsocketConnection();
    return this.cqgWebsocket.open();
  }

  async restoreSession() {
    if (!this.sessionToken) {
      logClientActionsError(this.logger, this.getClientLogData(), 'No log in information to restore.');
      this.emit(cqgEvents.logonCouldNotRestore);
      return;
    }
    const sessionToken = this.sessionToken;
    this.cqgWebsocket = this.ensureWebsocketConnection();
    this.clearState();
    await this.cqgWebsocket.open();
    const waitForSocket = async (numTries: number) => {
      if (this.getSocketState() === SocketState.opening) {
        while (numTries < 30) {
          await asyncDelay(200);
          if (this.getSocketState() == SocketState.open) {
            break;
          }
          numTries += 1;
        }
      }
      if (this.getSocketState() !== SocketState.open) {
        this.emit(cqgEvents.logonCouldNotRestore);
        return;
      }
      await this.restoreOrJoinSession(sessionToken);
    };
    await waitForSocket(0);
  }

  async restoreOrJoinSession(sessionToken: string) {
    if (!sessionToken) {
      this.emit(cqgEvents.logonCouldNotRestore);
      return;
    }
    const restoreMsg = RestoreOrJoinSession.fromJSON({
      sessionToken,
      clientAppId: getClientAppId(),
      protocolVersionMajor: ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR,
      protocolVersionMinor: ProtocolVersionMinor.PROTOCOL_VERSION_MINOR,
    });
    const clientMsg = ClientMsg.fromJSON({
      restoreOrJoinSession: restoreMsg,
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Restore or Join Message', restoreMsg);
    await this.sendLogonClientMessage(clientMsg);
    this.emit(cqgEvents.sendRestoreOrJoinSession, restoreMsg);
  }

  /**
   * Change password (websocket message)
   */
  async changePassword(newPassword: string, currentPassword?: string) {
    const changePasswordMsg = PasswordChange.fromJSON({
      newPassword,
      oldPassword: currentPassword || this.password,
    });
    const clientMsg = ClientMsg.fromJSON({
      passwordChange: changePasswordMsg,
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Change Password Message', changePasswordMsg);
    await this.sendLogonClientMessage(clientMsg);
    this.emit(cqgEvents.sendChangePassword);
    return await this.waitForMessage((data) => {
      return typeof data.passwordChangeResult?.resultCode === 'number';
    });
  }
  // END logon

  // Instrument Request

  /**
   * Resolve symbol (websocket message)
   */
  async resolveSymbol(symbol: string, subscribe: boolean | null = null, msgId?: number) {
    // subscribe = null - don't subscribe
    // subscribe = true - subscribe
    // subscribe = false - unsubscribe, only works if already subscribed

    // Do not cache at this time as it complicates market hours resolution/caching
    // const cachedSymbol = this.symbolInfo.get(symbol);
    // if (cachedSymbol) {
    //   this.emit(cqgEvents.sendSymbolResolution, symbol);
    //   return new Promise((r) => r(cachedSymbol));
    // }

    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      symbolResolutionRequest: {
        symbol,
      },
      subscribe: subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), `Resolving symbol ${symbol}`, infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendSymbolResolution, symbol);
    const response = await this.waitForMessage((data) => {
      const matching = data.informationReports.find((r) => r.id === infoRequest.id);
      if (!isNil(matching) && matching?.statusCode !== 0) {
        logClientActionsError(this.logger, this.getClientLogData(), `Symbol lookup failed for ${symbol}.`, { matching });
        throw new Error(`Requested symbol ${symbol} was not found ${matching?.textMessage}`);
      }
      return matching !== undefined;
    }, this.onTimeout('Symbol'));

    // Send session timerange request
    const matching = response.informationReports.find((r) => r.id === infoRequest.id);
    if (matching && matching.symbolResolutionReport?.contractMetadata?.sessionInfoId) {
      this.sessionTimeRangeRequest(matching.symbolResolutionReport.contractMetadata.sessionInfoId);
    }
    return response;
  }

  async waitForMessage(predicate: (data: ServerMsg) => boolean, options?: WaitUnpackedMessageOptions): Promise<ServerMsg> {
    try {
      const serverMsg = (await this.cqgWebsocket.waitUnpackedMessage(predicate, options)) as Promise<ServerMsg>;
      return serverMsg;
    } catch (e) {
      logClientActionsError(this.logger, this.getClientLogData(), 'Error waiting for message', { e });
      throw e;
    }
  }

  getCurrentlyAvailableSymbols() {
    return this.symbolInfo.keys();
  }

  getContractId(symbol: string) {
    if (!this.symbolInfo.has(symbol)) {
      logClientActionsError(this.logger, this.getClientLogData(), `Unknown symbol ${symbol}, have you sent resoveSymbol yet?`);
    }
    return this.symbolInfo.get(symbol)?.contractId;
  }

  getSessionInfoId(symbol: string) {
    if (!this.symbolInfo.has(symbol)) {
      logClientActionsError(this.logger, this.getClientLogData(), `Unknown symbol ${symbol}, have you sent resoveSymbol yet?`);
    }
    return this.symbolInfo.get(symbol)?.sessionInfoId;
  }

  /**
   * Session information request (websocket message)
   */
  sessionInformationRequest(
    sessionInfoId: number,
    fromUtcTime?: number,
    toUtcTime?: number,
    msgId?: number,
    subscribe: boolean | null = null
  ) {
    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      sessionInformationRequest: {
        sessionInfoId,
        fromUtcTime,
        toUtcTime,
      },
      subscribe: subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), `Requesting session information, id: ${sessionInfoId}`, infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendSessionInformation, sessionInfoId);
  }

  sessionTimeRangeRequest(sessionInfoId: number, msgId?: number) {
    const utcTime = this.getUtcTime();
    if (utcTime) {
      const infoRequest = InformationRequest.fromJSON({
        id: msgId || ++this.messageIdCounter,
        sessionTimerangeRequest: {
          sessionInfoId,
          fromUtcTime: utcTime,
          toUtcTime: (utcTime ?? 0) + 60 * 60 * 24 * 7 * 1000, // One week later so we get the next session around holidays
        },
      });

      const clientMsg = ClientMsg.fromJSON({
        informationRequests: [infoRequest],
      });

      logClientActions(this.logger, this.getClientLogData(), `Requesting session timerange, id: ${sessionInfoId}`, infoRequest);
      this.sendClientMessage(clientMsg);
      this.emit(cqgEvents.sendSessionTimerangeRequest, sessionInfoId);
    } else {
      logClientActionsError(
        this.logger,
        this.getClientLogData(),
        'Can not determine client time for session timerange request. Are you logged in?'
      );
    }
  }

  /**
   * Get a report containing brokerage information. Sets `this.brokerages = []`
   * @returns informationReports `infoReport.accountsReport.brokerages`
   */
  async getAccounts(subscribe = false, msgId?: number) {
    if (this.brokerages.length > 0) {
      return new Promise<string>((r) => r('Already fetched accounts'));
    }
    const id = msgId || ++this.messageIdCounter;
    const infoRequest = InformationRequest.fromJSON({
      id,
      accountsRequest: {},
      subscribe: subscribe ? subscribe : null,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Getting Accounts', infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendAccountsReport);
    return await this.waitForMessage((data) => {
      const matching = data.informationReports.find((o) => o.id === infoRequest.id);
      if (!isNil(matching) && !((!subscribe && matching?.statusCode === 0) || (subscribe && matching?.statusCode === 1))) {
        logClientActionsError(this.logger, this.getClientLogData(), 'Account lookup failed', {
          matching,
        });
        throw new Error(`Get accounts failed: ${matching?.textMessage}`);
      }
      return matching !== undefined;
    }, this.onTimeout('Account'));
  }

  optionMaturityList(contractId: number, subscribe: boolean | null = null, msgId?: number) {
    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      subscribe,
      optionMaturityListRequest: {
        underlyingContractId: contractId,
      },
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), `Requesting option maturity list, id: ${contractId}`, infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendOptionMaturityList, contractId);
  }

  instrumentGroup(instrumentGroupId: string, subscribe: boolean | null = null, msgId?: number) {
    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      subscribe,
      instrumentGroupRequest: {
        instrumentGroupId,
      },
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), `Requesting instrument group, id: ${instrumentGroupId}`, infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendInstrumentGroup, instrumentGroupId);
  }

  atTheMoneyStrike(optionMaturityId: string, subscribe: boolean | null = null, msgId?: number) {
    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      subscribe,
      atTheMoneyStrikeRequest: {
        optionMaturityId,
      },
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Requesting at the money strike, id: ${optionMaturityId}`,
      infoRequest
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendAtTheMoneyStrike, optionMaturityId);
  }

  // END Instrumet Request

  // Orders

  /**
   * Subscribe to trades for live updates. Sets `this.tradeSubscribed`.
   * @returns `tradeSubscriptionStatuses`
   */
  async tradeSubscription(
    subscriptionScope = TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS,
    subscribe = true,
    msgId?: number
  ) {
    if (this.tradeSubscribed) {
      return new Promise<string>((r) => r('Already subscribed'));
    }
    const id = msgId || ++this.messageIdCounter;
    this.tradeSubscribed = false;
    const tradeSubMsg = TradeSubscription.fromJSON({
      id,
      subscribe,
      subscriptionScopes: [subscriptionScope],
    });

    const clientMsg = ClientMsg.fromJSON({
      tradeSubscriptions: [tradeSubMsg],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Getting trade subscription ${id} at ${tradeSubscriptionScopeToString[subscriptionScope]}`,
      tradeSubMsg
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendTradeSubscription, id);
    return await this.waitForMessage((data) => {
      const matching = data.tradeSubscriptionStatuses.find((t) => t.id === id);
      if (!isNil(matching) && matching?.statusCode !== 0) {
        logClientActionsError(this.logger, this.getClientLogData(), 'Trade subscription failed', { matching });
        throw new Error(`Trade subscription failed: ${matching?.textMessage}`);
      }
      return matching !== undefined;
    }, this.onTimeout('Subscription'));
  }

  /**
   * Order history (websocket message)
   */
  async orderHistory() {
    // EB-TODO: find all callers and ensure they handle the change in return type
    const id = ++this.messageIdCounter;
    const offset = new Date();
    offset.setDate(offset.getDate() - 30); // 30 days is the max Historical Orders goes back
    const fromDate = this.getUtcTime(offset);

    const accountIds = this.getValidAccounts().map((x) => x.accountId);

    const historicalOrdersRequest = HistoricalOrdersRequest.fromJSON({
      fromDate,
      accountIds: accountIds.length > 0 ? accountIds : undefined,
    });

    const infoRequest = InformationRequest.fromJSON({
      id,
      historicalOrdersRequest,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    this.emit(cqgEvents.sendHistoricalOrderRequest, id);
    this.sendClientMessage(clientMsg);

    logClientActions(this.logger, this.getClientLogData(), 'Getting order history', infoRequest);

    const serverMsg = await this.waitForMessage((data) => {
      const matching = data.informationReports.find((t) => {
        return t.id === id && t.isReportComplete;
      });

      if (!isNil(matching) && matching?.statusCode !== 0) {
        logClientActionsError(this.logger, this.getClientLogData(), 'Order history failed', {
          matching,
        });
        throw new Error(`Order history failed: ${matching?.textMessage}`);
      }
      return matching !== undefined;
    }, this.onTimeout('Activity'));

    const historicalOrdersReport = serverMsg.informationReports.find((t) => t.id === id)?.historicalOrdersReport;
    if (isNil(historicalOrdersReport)) {
      this.logger.error(`No historicalOrdersReport found in response for msgId: ${id}`, { clientMsg, serverMsg });
    }

    return historicalOrdersReport;
  }

  // FIXME type this better, validation (stopPrice > limitPrice if STL order)
  /**
   * Send new order (websocket message)
   */
  async sendNewOrder(
    accountId: number,
    contractId: number,
    orderType: Order_OrderType,
    duration: Order_Duration,
    side: Order_Side,
    quantity: number,
    requestId?: number,
    limitPrice?: number,
    stopPrice?: number,
    suspend = false,
    clOrderId?: string
  ) {
    const utcTime = this.getUtcTime();
    clOrderId = clOrderId || uuid4();
    const order = Order.fromJSON({
      clOrderId,
      accountId,
      contractId,
      orderType,
      duration,
      side,
      qty: {
        significand: quantity,
        exponent: 0,
      },
      whenUtcTime: utcTime,
      scaledLimitPrice: limitPrice,
      scaledStopPrice: stopPrice,
    });

    requestId = requestId || ++this.requestIdCounter;
    const orderRequest = OrderRequest.fromJSON({
      requestId,
      newOrder: { order, suspend },
      isAutomated: false,
    });
    this.orderRequests.set(requestId, orderRequest);
    this.currentOrderRequest = { clOrderId, requestId };

    const clientMsg = ClientMsg.fromJSON({
      orderRequests: [orderRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Order Request', orderRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendNewOrder, orderRequest);
    return await this.waitForMessage((data) => {
      const confirmed = data.orderStatuses.findIndex((o) => o.order?.clOrderId === clOrderId) !== -1;
      const rejected = data.orderRequestRejects.findIndex((o) => o.requestId === requestId) !== -1;
      return confirmed || rejected;
    }, this.onTimeout('Order', 60000)).then((data: ServerMsg) => {
      const msg: OrderVerifiedMessage = {
        clOrderId,
        requestId,
        raw: data,
      };
      return msg;
    });
  }

  suspendOrder(
    orderId: string,
    accountId: number,
    origClOrderId: string,
    activationTime?: Date, // UTC
    requestId?: number
  ) {
    const utcTime = this.getUtcTime();
    const clOrderId = uuid4();
    const activationUtcTime = this.getUtcTime(activationTime);
    const suspendOrderMsg = SuspendOrder.fromJSON({
      orderId,
      accountId,
      origClOrderId,
      clOrderId,
      whenUtcTime: utcTime,
      activationUtcTime,
    });

    requestId = requestId || ++this.requestIdCounter;
    const orderRequest = OrderRequest.fromJSON({
      requestId,
      suspendOrder: suspendOrderMsg,
      isAutomated: false,
    });
    this.orderRequests.set(requestId, orderRequest);

    const clientMsg = ClientMsg.fromJSON({
      orderRequests: [orderRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Suspend Order Request', orderRequest);
    this.sendClientMessage(clientMsg);
    const currentOrderStatus = this.orderStatus.get(origClOrderId);
    if (currentOrderStatus) {
      this.orderStatus.set(clOrderId, currentOrderStatus);
      this.orderStatus.delete(origClOrderId);
    }
    this.emit(cqgEvents.sendSuspendOrder, orderRequest);
    return { requestId, clOrderId };
  }

  modifyOrder(
    orderId: string,
    accountId: number,
    origClOrderId: string,
    quantity?: number,
    limitPrice?: number,
    stopPrice?: number,
    duration?: Order_Duration,
    requestId?: number
  ) {
    const utcTime = this.getUtcTime();
    const clOrderId = uuid4();
    const modifyOrder = ModifyOrder.fromJSON({
      clOrderId,
      origClOrderId,
      orderId,
      accountId,
      duration,
      qty: {
        significand: quantity,
        exponent: 0,
      },
      whenUtcTime: utcTime,
      scaledLimitPrice: limitPrice,
      scaledStopPrice: stopPrice,
    });

    requestId = requestId || ++this.requestIdCounter;
    const orderRequest = OrderRequest.fromJSON({
      requestId,
      modifyOrder,
      isAutomated: false,
    });
    this.orderRequests.set(requestId, orderRequest);

    const clientMsg = ClientMsg.fromJSON({
      orderRequests: [orderRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Modify Order Request', orderRequest);
    this.sendClientMessage(clientMsg);
    const currentOrderStatus = this.orderStatus.get(origClOrderId);
    if (currentOrderStatus) {
      this.orderStatus.set(clOrderId, currentOrderStatus);
      this.orderStatus.delete(origClOrderId);
    }
    this.emit(cqgEvents.sendModifyOrder, orderRequest);
    return { requestId, clOrderId };
  }

  async cancelOrder(orderId: string, accountId: number, origClOrderId: string, requestId?: number) {
    const utcTime = this.getUtcTime();
    const clOrderId = uuid4();
    const cancelOrder = CancelOrder.fromJSON({
      clOrderId,
      origClOrderId,
      orderId,
      accountId,
      whenUtcTime: utcTime,
    });

    requestId = requestId || ++this.requestIdCounter;
    const orderRequest = OrderRequest.fromJSON({
      requestId,
      cancelOrder,
      isAutomated: false,
    });
    this.orderRequests.set(requestId, orderRequest);

    const clientMsg = ClientMsg.fromJSON({
      orderRequests: [orderRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Cancel Order Request', orderRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendCancelOrder, orderRequest);
    const currentOrderStatus = this.orderStatus.get(origClOrderId);

    // EB: this could be causing problems. The processMessage method executes before the waitForMessage method returns.
    if (currentOrderStatus) {
      this.orderStatus.set(clOrderId, currentOrderStatus);
      this.orderStatus.delete(origClOrderId);
    }
    return await this.waitForMessage((data) => {
      return data.orderStatuses.findIndex((o) => o.orderId === orderId) !== -1;
    }, this.onTimeout('Cancel'));
  }

  activateOrder(orderId: string, accountId: number, origClOrderId: string, requestId?: number) {
    const utcTime = this.getUtcTime();
    const clOrderId = uuid4();
    const activateOrder = ActivateOrder.fromJSON({
      clOrderId,
      origClOrderId,
      orderId,
      accountId,
      whenUtcTime: utcTime,
    });

    requestId = requestId || ++this.requestIdCounter;
    const orderRequest = OrderRequest.fromJSON({
      requestId,
      activateOrder,
    });
    this.orderRequests.set(requestId, orderRequest);

    const clientMsg = ClientMsg.fromJSON({
      orderRequests: [orderRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Activate Order Request', orderRequest);
    this.sendClientMessage(clientMsg);
    const currentOrderStatus = this.orderStatus.get(origClOrderId);
    if (currentOrderStatus) {
      this.orderStatus.set(clOrderId, currentOrderStatus);
      this.orderStatus.delete(origClOrderId);
    }
    this.emit(cqgEvents.sendActivateOrder, orderRequest);
    return { requestId, clOrderId };
  }

  orderStatusRequest(clOrderId: string, accountId: number, subscribe: boolean | null = null, msgId?: number) {
    const currentStatus = this.getOrderStatus(clOrderId);
    const chainOrderId = currentStatus?.chainOrderId;
    if (!chainOrderId) {
      logClientActionsError(this.logger, this.getClientLogData(), `Could not determine chainOrderId for ${clOrderId}`);
      return;
    }

    const infoRequest = InformationRequest.fromJSON({
      id: msgId || ++this.messageIdCounter,
      subscribe,
      orderStatusRequest: { chainOrderId, accountId },
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [infoRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), `Requesting Order Status, clOrderId: ${clOrderId}`, infoRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendOrderStatus, clOrderId);
  }

  symbolCategoryRequest(categoryId?: string, msgId?: number, subscribe: boolean | null = null) {
    const symbolCategoryRequest = SymbolCategoryRequest.fromJSON({
      categoryId,
    });

    const informationRequest = InformationRequest.fromJSON({
      symbolCategoryRequest: symbolCategoryRequest,
      id: msgId || ++this.messageIdCounter,
      subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [informationRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Symbol Category Request', informationRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendSymbolCategoryRequest, categoryId);
  }

  symbolListRequest(
    categoryIds?: string[],
    depth?: number,
    parentSymbolId?: string,
    msgId?: number,
    subscribe: boolean | null = null
  ) {
    const symbolListRequest = SymbolListRequest.fromJSON({
      categoryIds,
      depth,
      parentSymbolId,
    });

    const informationRequest = InformationRequest.fromJSON({
      symbolListRequest: symbolListRequest,
      id: msgId || ++this.messageIdCounter,
      subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [informationRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Symbol List Request', informationRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendSymbolListRequest, [categoryIds, depth, parentSymbolId]);
  }

  symbolCategoryListRequest(categoryId?: string, depth?: number, msgId?: number, subscribe: boolean | null = null) {
    const symbolCategoryListRequest = SymbolCategoryListRequest.fromJSON({
      categoryId,
      depth,
    });

    const informationRequest = InformationRequest.fromJSON({
      symbolCategoryListRequest: symbolCategoryListRequest,
      id: msgId || ++this.messageIdCounter,
      subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [informationRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Symbol List Request', informationRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendSymbolCategoryListRequest, [categoryId, depth]);
  }

  productSearchRequest(
    searchTerm?: string,
    categoryIds?: string[],
    reserved1?: boolean,
    msgId?: number,
    subscribe: boolean | null = null
  ) {
    const productSearchRequest = ProductSearchRequest.fromJSON({
      searchTerm,
      categoryIds,
      reserved1,
    });

    const informationRequest = InformationRequest.fromJSON({
      productSearchRequest: productSearchRequest,
      id: msgId || ++this.messageIdCounter,
      subscribe,
    });

    const clientMsg = ClientMsg.fromJSON({
      informationRequests: [informationRequest],
    });

    logClientActions(this.logger, this.getClientLogData(), 'Sending Product Search Request', informationRequest);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendProductSearchRequest, [searchTerm, categoryIds, reserved1]);
  }

  // END orders

  // Time and Sales Data

  getTimeAndSales(
    contractId: number,
    fromTime: Date,
    toTime?: Date,
    level = TimeAndSalesParameters_Level.LEVEL_TRADES,
    includeOffMarketTrades = false,
    includeTradeAttributes = false,
    requestId?: number
  ) {
    requestId = requestId || ++this.requestIdCounter;
    if (!this.baseTime) {
      logClientActionsError(this.logger, this.getClientLogData(), 'Cannot determine baseTime. Are you logged in?');
      return;
    }
    const fromUtcTime = this.getUtcTime(fromTime);
    const toUtcTime = toTime ? this.getUtcTime(toTime) : undefined;

    const timeAndSalesRequestMsg = TimeAndSalesRequest.fromJSON({
      requestId,
      requestType: TimeAndSalesRequest_RequestType.REQUEST_TYPE_GET,
      timeAndSalesParameters: {
        contractId,
        level,
        fromUtcTime,
        toUtcTime,
        includeOffMarketTrades,
        includeTradeAttributes,
      },
    });

    const clientMsg = ClientMsg.fromJSON({
      timeAndSalesRequests: [timeAndSalesRequestMsg],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Getting Time and Sales Data for contractId ${contractId}`,
      timeAndSalesRequestMsg
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendTimeAndSales, timeAndSalesRequestMsg);
    return requestId;
  }

  dropTimeAndSales(requestId: number) {
    const timeAndSalesRequestMsg = TimeAndSalesRequest.fromJSON({
      requestId,
      requestType: TimeAndSalesRequest_RequestType.REQUEST_TYPE_DROP,
    });

    const clientMsg = ClientMsg.fromJSON({
      timeAndSalesRequests: [timeAndSalesRequestMsg],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Dropping Time and Sales Data for requestId ${requestId}`,
      timeAndSalesRequestMsg
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendTimeAndSales, timeAndSalesRequestMsg);
  }

  // END Time and Sales Data

  // Market Data
  sendMarketDataSubscription(
    contractId: any,
    level: MarketDataSubscription_Level = MarketDataSubscription_Level.LEVEL_NONE,
    requestId?: number
  ) {
    requestId = requestId || ++this.requestIdCounter;
    const realTimeSubMsg = MarketDataSubscription.fromJSON({
      contractId,
      requestId,
      level: level,
    });
    const clientMsg = ClientMsg.fromJSON({
      marketDataSubscriptions: [realTimeSubMsg],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Sending Market Data Subscription for contract ${contractId} at level ${level}`,
      realTimeSubMsg
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendMarketDataSubscription, realTimeSubMsg);
  }
  // END Market Data

  // Time Bar Data

  getTimeBar(contractId: number, barUnit: TimeBarParameters_BarUnit, fromTime: Date, toTime?: Date, requestId?: number) {
    requestId = requestId || ++this.requestIdCounter;
    if (!this.baseTime) {
      logClientActionsError(this.logger, this.getClientLogData(), 'Cannot determine baseTime. Are you logged in?');
      return;
    }
    const fromUtcTime = this.getUtcTime(fromTime);
    const toUtcTime = toTime ? this.getUtcTime(toTime) : undefined;

    const msg = TimeBarRequest.fromJSON({
      requestId,
      requestType: TimeBarRequest_RequestType.REQUEST_TYPE_GET,
      timeBarParameters: {
        contractId,
        barUnit: barUnit,
        fromUtcTime,
        toUtcTime,
      },
    });

    const clientMsg = ClientMsg.fromJSON({
      timeBarRequests: [msg],
    });

    logClientActions(
      this.logger,
      this.getClientLogData(),
      `Getting Time Bar Data [${barUnit}] for contractId ${contractId}`,
      TimeBarRequest
    );
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendTimeBarData, msg);
    return requestId;
  }

  dropTimeBar(requestId: number) {
    const msg = TimeBarRequest.fromJSON({
      requestId,
      requestType: TimeBarRequest_RequestType.REQUEST_TYPE_DROP,
    });

    const clientMsg = ClientMsg.fromJSON({
      timeBarRequests: [msg],
    });

    logClientActions(this.logger, this.getClientLogData(), `Dropping Time Bar Data for requestId ${requestId}`, msg);
    this.sendClientMessage(clientMsg);
    this.emit(cqgEvents.sendTimeBarData, msg);
  }

  // END Time Bar Data

  /** Clear internally tracked state (session / time / etc) */
  private clearState() {
    this.sessionToken = null;
    this.baseTime = null;
    this.tradeSubscribed = false;
    this.logoffPending = false;
    this.brokerages = [];
    this.stopKeepAlive();
  }

  private processServerMessage(msg: ServerMsg) {
    this.numberOfRecievedServerMessages += 1;

    // PING
    if (msg.ping) {
      const ping = msg.ping;
      if (this.logPingPong) {
        logServerMsg(this.logger, this.getServerLogData(), 'Received Ping', ping);
      }
      const utcTime = this.getUtcTime();
      if (utcTime) {
        const pongMsg = Pong.fromJSON({
          token: ping.token,
          pingUtcTime: ping.pingUtcTime,
          pongUtcTime: utcTime,
        });
        const clientMsg = ClientMsg.fromJSON({
          pong: pongMsg,
        });
        if (this.logPingPong) {
          logClientActions(this.logger, this.getClientLogData(), 'Sending Pong', pongMsg);
        }
        try {
          this.sendClientMessage(clientMsg);
        } catch {
          logClientActionsError(this.logger, this.getClientLogData(), 'Could not send Pong');
        }
      } else {
        logClientActionsError(this.logger, this.getClientLogData(), 'Can not determine base time from Pong');
      }
      this.emit(cqgEvents.receivedPing, ping);
    }

    // PONG
    if (msg.pong) {
      const pong = msg.pong;
      const pingTime = pong.pingUtcTime;
      const pongTime = pong.pongUtcTime;
      let timeElapsed: Long;
      if (Long.isLong(pongTime)) {
        timeElapsed = pongTime.subtract(pingTime);
      } else {
        timeElapsed = new Long(pongTime).subtract(pingTime);
      }
      if (this.logPingPong) {
        logServerMsg(this.logger, this.getServerLogData(), `Received Pong: Ping ${pong.token} took ${timeElapsed} ms`, pong);
      }
      this.emit(cqgEvents.receivedPong, pong);
    }

    // LOGON_RESULT
    if (msg.logonResult) {
      const logonResult = msg.logonResult;
      logServerMsg(
        this.logger,
        this.getServerLogData(),
        `Logon Result: ${logonResultToString[logonResult.resultCode]}`,
        logonResult
      );
      if (logonResult.protocolVersionMajor !== ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR) {
        this.logger.error(
          `Protocol MAJOR version mismatch. Expected ${ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR}, got ${logonResult.protocolVersionMajor}`
        );
      }
      if (logonResult.protocolVersionMinor !== ProtocolVersionMinor.PROTOCOL_VERSION_MINOR) {
        this.logger.warn(
          `Protocol MINOR version mismatch. Expected ${ProtocolVersionMinor.PROTOCOL_VERSION_MINOR}, got ${logonResult.protocolVersionMinor}`
        );
      }
      if (
        logonResult.protocolVersionMajor < ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR ||
        (logonResult.protocolVersionMinor < ProtocolVersionMinor.PROTOCOL_VERSION_MINOR &&
          logonResult.protocolVersionMajor === ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR)
      ) {
        this.logger.error('Protocol version in client is newer than server. Something horrible has happened.');
      }
      if (logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_SUCCESS) {
        this.sessionToken = logonResult.sessionToken;
        this.baseTime = new Date(logonResult.baseTime + 'Z').getTime();
        this.emit(cqgEvents.receivedLogonSuccess, logonResult);
      } else {
        const error: ErrorRecord = {
          code: ErrorCode.LOGON_FAILURE,
          msg: logonResult.textMessage || undefined,
          timestamp: Date.now(),
          data: logonResult,
        };
        this.emit(cqgEvents.receivedLogonFailure, error);
      }
      this.emit(cqgEvents.receivedLogon, logonResult);
    }

    // LOGOFF
    if (msg.loggedOff) {
      logServerMsg(
        this.logger,
        this.getServerLogData(),
        `Logged off ${msg.loggedOff.textMessage}, Result Code: ${logoffReasonToString[msg.loggedOff.logoffReason]}`,
        msg.loggedOff
      );
      this.clearState();
      this.emit(cqgEvents.receivedLogoff, msg.loggedOff);
    }

    // RESTORE_OR_JOIN_SESSION_RESULT
    if (msg.restoreOrJoinSessionResult) {
      const result = msg.restoreOrJoinSessionResult;
      if (result.resultCode === 0) {
        this.baseTime = new Date(result.baseTime + 'Z').getTime();
        logServerMsg(this.logger, this.getServerLogData(), 'Sucessful session restore', result);
      } else {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `SessionRestore Result ${restoreOrJoinResultToString[result.resultCode]}`,
          result
        );
        this.clearState();
      }
      this.emit(cqgEvents.receivedRestoreOrJoinSession, result);
    }

    // PASSWORD_CHANGE_RESULT
    if (msg.passwordChangeResult) {
      const result = msg.passwordChangeResult;
      logServerMsg(
        this.logger,
        this.getServerLogData(),
        `PasswordChange Result ${passwordChangeResultToString[result.resultCode]}`,
        result
      );
      this.emit(cqgEvents.receivedPasswordChange, result);
    }

    // userMessages
    if (msg.userMessages) {
      for (const userMsg of msg.userMessages) {
        logServerMsg(this.logger, this.getServerLogData(), userMsg.text, userMsg);
      }
      this.emit(cqgEvents.receivedUserMessages, msg.userMessages);
    }
    // END userMessages

    // informationReports
    if (msg.informationReports) {
      for (const infoReport of msg.informationReports) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Information Report Status ${informationReportsStatusToString[infoReport.statusCode]}, msgId: ${infoReport.id}`,
          {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            textMessage: infoReport.textMessage,
          }
        );
        this.emit(cqgEvents.receivedInformationReport, {
          id: infoReport.id,
          statusCode: infoReport.statusCode,
          textMessage: infoReport.textMessage,
        });

        // Failure
        if (infoReport.statusCode >= InformationReport_StatusCode.STATUS_CODE_FAILURE) {
          if (infoReport.statusCode === InformationReport_StatusCode.STATUS_CODE_NOT_FOUND) {
            // not found
            this.emit(cqgEvents.receivedInformationReportNotFound, {
              id: infoReport.id,
              statusCode: infoReport.statusCode,
              textMessage: infoReport.textMessage,
            });
          } else {
            // all other errors
            this.emit(cqgEvents.receivedError, {
              id: infoReport.id,
              statusCode: infoReport.statusCode,
              textMessage: infoReport.textMessage,
              token: this.sessionToken,
            });
          }
          if (infoReport.textMessage) {
            switch (true) {
              case /Requested symbol/.test(infoReport.textMessage):
                this.emit(cqgEvents.receivedSymbolResolutionFailure, {
                  id: infoReport.id,
                  statusCode: infoReport.statusCode,
                  textMessage: infoReport.textMessage,
                });
                break;
              default:
                break;
            }
          }
        }

        // Symbol Resolution
        if (infoReport.symbolResolutionReport?.contractMetadata) {
          logServerMsg(
            this.logger,
            this.getServerLogData(),
            `${infoReport.id}: ${infoReport.symbolResolutionReport.contractMetadata.contractSymbol}`,
            infoReport.symbolResolutionReport.contractMetadata
          );
          this.symbolInfo.set(
            infoReport.symbolResolutionReport.contractMetadata.contractSymbol,
            infoReport.symbolResolutionReport.contractMetadata
          );
          this.currentContractSymbol = infoReport.symbolResolutionReport.contractMetadata.contractSymbol;

          if (infoReport.statusCode === InformationReport_StatusCode.STATUS_CODE_SUCCESS) {
            this.emit(cqgEvents.receivedSymbolResolutionSuccess, {
              id: infoReport.id,
              statusCode: infoReport.statusCode,
              contractMetadata: infoReport.symbolResolutionReport.contractMetadata,
            });
          } else {
            // Note: this never seems to fire because in reality the symbolResolutionReport won't be set
            this.emit(cqgEvents.receivedSymbolResolutionFailure, {
              id: infoReport.id,
              statusCode: infoReport.statusCode,
              contractMetadata: infoReport.symbolResolutionReport.contractMetadata,
            });
          }
          this.emit(cqgEvents.receivedSymbolResolution, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            contractMetadata: infoReport.symbolResolutionReport.contractMetadata,
          });
        }

        // Account Reports
        if (infoReport.accountsReport?.brokerages) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Brokerages', infoReport.accountsReport.brokerages);
          this.brokerages = infoReport.accountsReport.brokerages;
          this.emit(cqgEvents.receivedAccountsReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            brokerages: infoReport.accountsReport.brokerages,
          });
        }

        // Session Info
        if (infoReport.sessionInformationReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Session Info', infoReport.sessionInformationReport);
          this.emit(cqgEvents.receivedSessionInformation, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            sessionInformationReport: infoReport.sessionInformationReport,
          });
        }

        // Option Maturity List
        if (infoReport.optionMaturityListReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Option Maturity List', infoReport.optionMaturityListReport);
          this.emit(cqgEvents.receivedOptionMaturityList, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            optionMaturities: infoReport.optionMaturityListReport.optionMaturities,
          });
        }

        // Instrument Group
        if (infoReport.instrumentGroupReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Instrument Group', infoReport.instrumentGroupReport);
          this.emit(cqgEvents.receivedInstrumentGroup, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            instruments: infoReport.instrumentGroupReport.instruments,
          });
        }

        // At The Money Strike
        if (infoReport.atTheMoneyStrikeReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found at the money strike', infoReport.atTheMoneyStrikeReport);
          this.emit(cqgEvents.receivedAtTheMoneyStrike, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            strike: infoReport.atTheMoneyStrikeReport.strike,
          });
        }

        // Symbol Category
        if (infoReport.symbolCategoryReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Symbol Category', infoReport.symbolCategoryReport);
          this.emit(cqgEvents.receivedSymbolCategoryReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            symbolCategory: infoReport.symbolCategoryReport.symbolCategory,
          });
        }

        // Symbol List
        if (infoReport.symbolListReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Symbol List', infoReport.symbolCategoryListReport);
          this.emit(cqgEvents.receivedSymbolListReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            symbols: infoReport.symbolListReport.symbols,
          });
        }

        // Symbol Category List
        if (infoReport.symbolCategoryListReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Symbol Category List', infoReport.symbolCategoryListReport);
          this.emit(cqgEvents.receivedSymbolCategoryListReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            symbolCategories: infoReport.symbolCategoryListReport.symbolCategories,
          });
        }

        // Product Search
        if (infoReport.productSearchReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Found Product Search Results', infoReport.productSearchReport);
          this.emit(cqgEvents.receivedProductSearchReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            symbolCategories: infoReport.productSearchReport.symbols,
          });
        }

        // Session Timeranges
        if (infoReport.sessionTimerangeReport) {
          const timeranges = infoReport.sessionTimerangeReport.sessionTimeRanges;
          const converted = timeranges?.map((range) => {
            return {
              preOpenTime: this.convertUtcTime(range.preOpenUtcTime),
              openTime: this.convertUtcTime(range.openUtcTime),
              closeTime: this.convertUtcTime(range.closeUtcTime),
              postClosedTime: this.convertUtcTime(range.postCloseUtcTime),
              tradeDate: this.convertUtcTime(range.tradeDate),
              sessionName: range.sessionName,
            } as IConvertedSessionTimerange;
          });
          logServerMsg(this.logger, this.getServerLogData(), 'Session Timeranges', converted);
          this.emit(cqgEvents.receivedSessionTimerangeReport, {
            id: infoReport.id,
            statusCode: infoReport.statusCode,
            convertedTimeranges: converted,
          });
        }

        // Historical Orders Report
        if (infoReport.historicalOrdersReport) {
          logServerMsg(this.logger, this.getServerLogData(), 'Historical Orders Report Received', infoReport.id);
          const report = infoReport.historicalOrdersReport;

          if (report.orderStatusLimitReached) {
            logServerMsg(
              this.logger,
              this.getServerLogData(),
              'Historical Orders Report Limit Reached',
              report.orderStatuses.length
            );
          }

          if (report.transactionStatusLimitReached) {
            logServerMsg(
              this.logger,
              this.getServerLogData(),
              'Historical Orders Report Limit Reached',
              report.orderStatuses.length
            );
          }

          const historicalStatuses = infoReport.historicalOrdersReport.orderStatuses || [];
          for (const status of historicalStatuses) {
            // logServerMsg(
            //   this.logger,
            //   this.getServerLogData(),
            //   `clOrderId: ${status.order?.clOrderId}, Order id: ${status.orderId}, status: ${
            //     orderStatusToString[status.status]
            //   }, fillcnt: ${status.fillCnt}`,
            //   { status, self: this.toJSON() }
            // );
            if (status.order?.clOrderId) {
              this.orderStatus.set(status.order?.clOrderId, status);
            }
            // this triggers state changes in a tight loop, which is not good
            //this.emit(cqgEvents.receivedOrderStatus, status);
          }

          this.emit(cqgEvents.receivedHistoricalOrdersReport, report);
        }
      }
    }
    // END informationReports

    // Trade Subscription Statuses
    if (msg.tradeSubscriptionStatuses) {
      for (const status of msg.tradeSubscriptionStatuses) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Trade Subscription ${status.id} is ${tradeSubscriptionStatusToString[status.statusCode]}, details: ${status.details}`,
          status
        );
        if (status.statusCode === TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS) {
          this.tradeSubscribed = true;
          this.emit(cqgEvents.receivedTradeSubscriptionStatusSuccess, status);
        } else {
          this.tradeSubscribed = false;
          this.emit(cqgEvents.receivedTradeSubscriptionStatusFailure, status);
        }
        this.emit(cqgEvents.receivedTradeSubscriptionStatus, status);
      }
    }

    // Order Statuses
    if (msg.orderStatuses) {
      // TODO: don't handle these in a loop, handle them as a set!!!
      for (const status of msg.orderStatuses) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `clOrderId: ${status.order?.clOrderId}, Order id: ${status.orderId}, status: ${
            orderStatusToString[status.status]
          }, fillcnt: ${status.fillCnt}`,
          { status, self: this.toJSON() }
        );
        if (status.order?.clOrderId) {
          this.orderStatus.set(status.order?.clOrderId, status);
        }
        this.emit(cqgEvents.receivedOrderStatus, status);
      }
    }

    // Order Request Rejects
    if (msg.orderRequestRejects) {
      // TODO: don't handle these in a loop, handle them as a set!!!
      for (const reject of msg.orderRequestRejects) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Order Request Id: ${reject.requestId} REJECTED for - ${reject.details}`,
          reject
        );
        this.orderRequestRejects.set(reject.requestId, reject);
        this.emit(cqgEvents.receivedOrderRequestRejects, reject);
      }
    }
    // END orders

    // marketData
    if (msg.marketDataSubscriptionStatuses) {
      // TODO: don't handle these in a loop, handle them as a set!!!
      for (const marketDataSubscriptionStatusMsg of msg.marketDataSubscriptionStatuses) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Received Market Data Subscription for level:  ${marketDataSubscriptionStatusMsg.level}
          with status: ${marketDataSubscriptionStatusToString[marketDataSubscriptionStatusMsg.statusCode]}`,
          marketDataSubscriptionStatusMsg
        );
        this.emit(cqgEvents.receivedMarketDataSubscription, marketDataSubscriptionStatusMsg);
      }
    }

    if (msg.realTimeMarketData) {
      // TODO: don't handle these in a loop, handle them as a set!!!
      for (const realTimeMarketDataMsg of msg.realTimeMarketData) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Received ${realTimeMarketDataMsg.quotes?.length} Real Time Market Data for contract:  ${realTimeMarketDataMsg.contractId}`,
          realTimeMarketDataMsg
        );
        this.emit(cqgEvents.receivedRealTimeMarketDataSubscription, realTimeMarketDataMsg);
      }
    }
    // END marketData

    // time and sales data
    if (msg.timeAndSalesReports) {
      for (const report of msg.timeAndSalesReports) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Time and Sales requestId: ${report.requestId} ${timeAndSalesResultToString[report.resultCode]}`,
          report
        );
        this.emit(cqgEvents.receivedTimeAndSales, report);
      }
    }
    // END time and sales data

    // bar data
    if (msg.timeBarReports) {
      for (const report of msg.timeBarReports) {
        logServerMsg(
          this.logger,
          this.getServerLogData(),
          `Bar Data requestId: ${report.requestId} ${timeBarDataResultToString[report.statusCode]}`,
          report
        );
        this.emit(cqgEvents.receivedTimeBarData, report);
      }
    }
    // END bar data
  }
}
