import { sum, uniqBy } from 'lodash';
import { Datum } from '@nivo/line';

export function getTrendLine(data: Datum[]): Datum[] {
  const remappedForCalculation = data.map((item, index) => ({ x: index, y: Number(item.y) }));
  const J1 = sum(remappedForCalculation.map((item) => Math.pow(item.x, 2)));
  const J2 = sum(remappedForCalculation.map((item) => item.x));
  const K1 = J2;
  const K2 = remappedForCalculation.length;

  const M1 = sum(remappedForCalculation.map((item) => item.x * item.y));
  const M2 = sum(remappedForCalculation.map((item) => item.y));

  const I5 = J1 * K2 - J2 * K1;

  const J8 = K2;
  const J9 = -K1;
  const K8 = -J2;
  const K9 = J1;

  const J11 = J8;
  const J12 = K8;
  const K11 = J9;
  const K12 = K9;

  const J14 = J11 * (1 / I5);
  const J15 = J12 * (1 / I5);
  const K14 = K11 * (1 / I5);
  const K15 = K12 * (1 / I5);

  const matrix1 = [
    [J14, K14],
    [J15, K15],
  ];
  const matrix2 = [[M1], [M2]];

  const matrix3 = multiply(matrix1, matrix2);
  const M14 = matrix3[0][0];
  const M15 = matrix3[1][0];

  const newData = remappedForCalculation.map((item) => ({ x: item.x, y: M14 * item.x + M15 }));

  return uniqBy(
    data.map((item, index) => ({ x: item.x, y: newData[index].y })),
    'x'
  );
}

function multiply(a: number[][], b: number[][]) {
  const aNumRows = a.length,
    aNumCols = a[0].length,
    bNumCols = b[0].length,
    m = new Array(aNumRows);

  for (let r = 0; r < aNumRows; ++r) {
    m[r] = new Array(bNumCols);
    for (let c = 0; c < bNumCols; ++c) {
      m[r][c] = 0;
      for (let i = 0; i < aNumCols; ++i) {
        m[r][c] += a[r][i] * b[i][c];
      }
    }
  }
  return m;
}
