import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';
import type { IConfig } from '@farmersrisk/shared/config';
import { loggingInit, type Logger } from '@farmersrisk/shared/logger';
import config from '../config';

export const enum LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}
let _config: IConfig = config;
const defaultContext = {
  service: 'webapp',
  env: _config.env,
  version: _config.version,
};

let _defaultLogger: Logger = createLogger('default');

export default function init(config: IConfig) {
  _config = config;

  datadogLogs.init({
    clientToken: 'pubd2157a0d8783ee2aea15cb381ddd6936',
    site: 'datadoghq.com',
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env: config.env,
    version: _config.version,
    service: 'webapp',
    beforeSend: (log) => {
      const sensitiveInfoList = ['accountId', 'sessionToken', 'password'];
      if (log.cqgData) {
        sanitizeAllSensitiveInfo(log.cqgData, sensitiveInfoList);
      }
      return true;
    },
  });

  loggingInit({
    logger: _defaultLogger,
    createLogger,
  });
}

function sanitizeAllSensitiveInfo(cqgData: { [key: string]: any }, sensitiveInfoList: string[]) {
  const sanitizeSensitiveItem = (cqgData: { [key: string]: any }, sensitiveInfoKey: string) => {
    Object.keys(cqgData).forEach((key) => {
      if (key === sensitiveInfoKey) {
        cqgData[key] = '[REDACTED]';
      } else if (typeof cqgData[key] === 'object') {
        sanitizeSensitiveItem(cqgData[key], sensitiveInfoKey);
      }
    });
  };
  sensitiveInfoList.forEach((sensitiveInfoKey) => sanitizeSensitiveItem(cqgData, sensitiveInfoKey));
}

function getHandlers(): HandlerType[] {
  switch (_config.env) {
    case 'ci':
      return [];
    case 'development':
      return ['console'];
    case 'test':
      return ['console', 'http'];
    case 'staging':
      // also use console in staging, for now
      return ['console', 'http'];
    case 'production':
    default:
      return ['http'];
  }
}

export function createLogger(moduleOrFileName: string, additionalContext?: object): Logger {
  const logLevel: StatusType = _config.env === 'production' ? 'info' : 'debug';

  const ddLogger = datadogLogs.createLogger(moduleOrFileName, {
    level: logLevel,
    handler: getHandlers(),
    context: {
      ...datadogLogs.getGlobalContext(),
      ...defaultContext,
      ...(additionalContext ?? {}),
      module: moduleOrFileName,
    },
  });

  const logger: Logger = {
    silly: ddLogger.debug.bind(ddLogger),
    debug: ddLogger.debug.bind(ddLogger),
    info: ddLogger.info.bind(ddLogger),
    warn: ddLogger.warn.bind(ddLogger),
    error: ddLogger.error.bind(ddLogger),
  };

  return logger;
}
