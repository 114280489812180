import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';
import { IAdvisorSignup } from '../types/IAdvisorSignup';

/**
 * Supports deep-linking from our marketing content for "signups"
 * @returns
 */
export function Signup(props: Partial<IAdvisorSignup>) {
  // this component is rendered outside of react-router so you can't use useParams hook
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect({ screen_hint: 'signup', appState: { returnTo: 'wizard', ...props } }).catch((error) => {
    console.error('Failed to loginWithRedirect', error);
  });
  return <></>;
}

/**
 * Supports deep-linking directly to the login experience
 * @returns
 */
export function Login() {
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect().catch((error) => {
    console.error('Failed to loginWithRedirect', error);
  });
  return <></>;
}

/**
 * Ability to force logout if you can't login to the app due to bugs downstream
 * @returns
 */
export function Logout() {
  const { logout, isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    logout({ returnTo: config.landing.basePath });
  }
  return <></>;
}

/**
 * Ability to get to a view that is non-authenticated so you can inspect
 * cookies/localstorage/etc...
 */
export function Debug() {
  return <p>Hello</p>;
}

/**
 * Ability to throw a client error to test logging and RUM
 */
export function ThrowError() {
  throw new Error('Hardcoded Error for Testing Purposes');
  return <></>;
}
