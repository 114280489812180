import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { setStoredCurrentOperationId } from '../shared/storageService';

export function useCurrentOperationIdFromUser() {
  const { operationId } = useParams<{ operationId?: string }>();
  const history = useHistory();
  const pathNameForRedirect = '/' + history.location.pathname.split('/').splice(3).join('/');

  if (operationId) {
    setStoredCurrentOperationId(operationId);
  }

  return <Redirect to={pathNameForRedirect} />;
}
