import dayjs from '../../utils/dayjs';
import type {
  IGetSpecialOptionsResult,
  IGetFuturesExpirationsResult,
  IGetFuturesOptionsResult,
} from '@harvestiq/external-clients/barchart';
import { SelectorTypes } from '../common/SelectWithLabel/SelectWithLabel';
import { isEmpty, orderBy, uniqBy } from 'lodash';
import { CommodityInformation, InstrumentType } from '@harvestiq/constants';
import { ContractParams } from './TradeOrderProvider';
import { shortMonthNames } from '../../utils/dateHelpers';
import { MONTH_CODE_TO_MONTH_NUMBER } from '@harvestiq/symbology';
import { formatNumber } from '../../utils/formatNumber';
import { getUomDisplayAbbreviation } from '@farmersrisk/shared/constants/UOM';

export function getDisplayQuantity(commodity?: CommodityInformation, contractQuantity?: number) {
  if (!commodity || !contractQuantity) {
    return '';
  }
  const total = formatNumber(commodity.standardSize.times(contractQuantity).toNumber());
  const displayUom = getUomDisplayAbbreviation(commodity.uom);
  return `${total} ${displayUom}`;
}

export function getContractSelector(contractMonth: number, contractYear: number): SelectorTypes<ContractParams> {
  const month = shortMonthNames[contractMonth];
  const year = contractYear.toString().slice(2);

  // DEC - 23
  return {
    label: `${month} - ${year}`.toUpperCase(),
    value: {
      contractMonth,
      contractYear,
    },
  };
}

export function buildFuturesContractOptions(futuresExpirations: IGetFuturesExpirationsResult[]): SelectorTypes<ContractParams>[] {
  const options = futuresExpirations.map((expiration) => {
    // Dec 23
    const djs = dayjs(expiration.contract, 'MMM YY');
    const month = djs.month();
    const year = djs.year();

    return getContractSelector(month, year);
  });

  return orderBy(options, (x) => x.value.contractYear * 100 + x.value.contractMonth, 'asc');
}

export function buildOptionsContractOptions(
  instrumentType: InstrumentType,
  regularOptions: IGetFuturesOptionsResult[],
  allSpecialOptions: IGetSpecialOptionsResult[]
): SelectorTypes<ContractParams>[] {
  if (isEmpty([...allSpecialOptions, ...regularOptions])) {
    return [];
  }

  let optionsToUse: Array<IGetFuturesOptionsResult | IGetSpecialOptionsResult> = [];
  switch (instrumentType) {
    case InstrumentType.Put:
    case InstrumentType.Call:
      optionsToUse = regularOptions;
      break;
    case InstrumentType.ShortDatedPut:
    case InstrumentType.ShortDatedCall:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Short Dated'));
      break;
    case InstrumentType.Week1Put:
    case InstrumentType.Week1Call:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Week 1'));
      break;
    case InstrumentType.Week2Put:
    case InstrumentType.Week2Call:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Week 2'));
      break;
    case InstrumentType.Week3Put:
    case InstrumentType.Week3Call:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Week 3'));
      break;
    case InstrumentType.Week4Put:
    case InstrumentType.Week4Call:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Week 4'));
      break;
    case InstrumentType.Week5Put:
    case InstrumentType.Week5Call:
      optionsToUse = allSpecialOptions.filter((option) => option.contractName.includes('Week 5'));
      break;
    default:
      break;
  }

  let contractMonths = optionsToUse.map((x) => {
    const yearShortCode = x.contract.slice(-2);
    const contractMonthNumber = MONTH_CODE_TO_MONTH_NUMBER[x.contractMonth];

    return getContractSelector(contractMonthNumber, 2000 + parseInt(yearShortCode));
  });
  contractMonths = uniqBy(contractMonths, (x) => x.label);
  return orderBy(contractMonths, (x) => x.value.contractYear * 100 + x.value.contractMonth, 'asc');
}
