import styles from './Radio.module.scss';

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import {
  RadioGroup as KRadioGroup,
  RadioGroupHandle as KRadioGroupHandle,
  RadioGroupProps as KRadioGroupProps,
} from '@progress/kendo-react-inputs';
import { RadioButtonProps } from './RadioButton';
import { forwardRef } from 'react';
import { useContainerScroll } from '../../../customHooks/useContainerScroll';

interface RadioGroupProps extends Omit<KRadioGroupProps, 'data'> {
  header?: JSX.Element;
  footer?: JSX.Element;
  height?: string | number;
  data: Array<RadioButtonProps>;
  id: string;
}
export interface RadioGroupHandle extends KRadioGroupHandle {}

const RadioGroup = forwardRef<RadioGroupHandle, RadioGroupProps>((props: RadioGroupProps, ref?: React.Ref<RadioGroupHandle>) => {
  const { className, header, footer, height = 'auto', id = uuid(), ...otherProps } = props;
  const heading = header ? <div className="fr-radio-group-header">{header}</div> : null;
  const footing = footer ? <div className="fr-radio-group-footer">{footer}</div> : null;
  const groupHeight = typeof height === 'number' ? `${height}px` : height;
  const { scrollRef } = useContainerScroll<HTMLDivElement>(id, [props.value]);

  return (
    <div className={`${styles['fr-radio-group']} ${className}`} id={id}>
      {heading}
      <div className="scroll-container" style={{ height: groupHeight }} ref={scrollRef}>
        <KRadioGroup className="fr-k-radio-group" ref={ref} {...otherProps} />
      </div>
      {footing}
    </div>
  );
});

RadioGroup.displayName = 'RadioGroup';
export default RadioGroup;
