import styles from './Label.module.scss';

import * as React from 'react';
import { Label as KLabel, LabelProps as KLabelProps } from '@progress/kendo-react-labels';

export interface LabelProps extends KLabelProps {
  mode?: 'block' | 'inline';
  fontWeight?: 'normal' | 'bold' | 'light';
  size?: 'medium' | 'large';
}

// Note: doesn't currently support refs
export default function Label(props: LabelProps) {
  const { className, mode = 'block', fontWeight = 'normal', size = 'medium', ...otherProps } = props;

  const fontWeightClass = fontWeight === 'bold' || fontWeight === 'light' ? `fr-label-fw-${fontWeight}` : '';
  const sizeClass = size === 'large' ? `fr-label-size-${size}` : '';

  return (
    <KLabel className={`${styles['fr-label']} ${className} fr-label-${mode} ${fontWeightClass} ${sizeClass}`} {...otherProps} />
  );
}
