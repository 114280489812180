import { createSelector } from '@reduxjs/toolkit';
import { isEqual, sum } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import usePermission from '../../../customHooks/usePermission';
import { useAllOperationCrops } from '../../../queries/operationCrops';
import { selectBudgets } from '../../../store/Budgets/BudgetsSlice';
import { selectBudgetType } from '../../../store/PagesConfigs/Costs/costsSlice';
import { BudgetType, IBudget, IBudgetsItem } from '../../../types/IBudget';
import { RootState } from '../../../types/Store';
import { formatNumber } from '../../../utils/formatNumber';
import SelectWithLabel, { SelectorTypes } from '../../common/SelectWithLabel/SelectWithLabel';
import BudgetTotalRow from './BudgetTotalRow';
import { getInitialBudget } from './Costs';

const makeSelectDefaultUniversityBudgets = () =>
  createSelector(
    selectBudgets,
    (_: RootState, productionCycle: string) => productionCycle,
    (budgets, productionCycle) =>
      budgets.filter(
        (budget) =>
          budget.year === productionCycle &&
          budget.budgetType === BudgetType.University &&
          budget.operationId === null &&
          budget.isActive
      )
  );

type UniversityModeProps = {
  editableBudget?: IBudget;
  setEditableBudget: Dispatch<SetStateAction<IBudget>>;
};

function UniversityMode(props: UniversityModeProps) {
  const { editableBudget, setEditableBudget } = props;
  const productionCycle = useSelector((state: RootState) => state.productionCycles.selectedYear);
  const budgetType = useSelector(selectBudgetType);
  const currentOperation = useCurrentOperation();
  const selectDefaultUniversityBudgets = useMemo(makeSelectDefaultUniversityBudgets, [productionCycle]);
  const defaultUniversityBudgets = useSelector((state: RootState) => selectDefaultUniversityBudgets(state, productionCycle));
  const currency = useCurrentCurrency();
  const operationCrops = useAllOperationCrops().data || [];
  const isAtLeastEditor = usePermission();

  // add a budget item for "Other"
  if (!defaultUniversityBudgets.find((budget) => budget.id === 0)) {
    defaultUniversityBudgets.push({
      id: 0,
      name: 'Other',
      cropBudgets: {
        0: [{ value: 0, valueType: 2, categoryName: 'Production Cost' }],
      },
      isActive: true,
      year: productionCycle,
      operationId: null,
      budgetType: BudgetType.University,
      createdAt: new Date().toISOString(),
    });
  }

  const currentOperationCrops = operationCrops.filter(
    (operationCrop) => operationCrop.productionCycle === productionCycle && !operationCrop.hidden
  );

  useEffect(() => {
    if (!editableBudget) {
      setEditableBudget(getInitialBudget(currentOperationCrops, currentOperation, productionCycle, budgetType));
    }
  }, [editableBudget, setEditableBudget, currentOperationCrops, currentOperation, productionCycle, budgetType]);

  const onSelectBudgetHandler = (universityBudgetId: number, cropId: number, operationCropId: number) => {
    const defaultUniversityBudgetItems = defaultUniversityBudgets.find((budget) => budget.id === universityBudgetId)?.cropBudgets[
      cropId
    ];

    if (!defaultUniversityBudgetItems?.length) {
      return;
    }

    const orderedItems = defaultUniversityBudgetItems.map((item, index) => ({ orderId: index, ...item }));

    setEditableBudget((previousBudget) => ({
      ...previousBudget,
      cropBudgets: {
        ...previousBudget.cropBudgets,
        [cropId]: [...orderedItems],
        [operationCropId]: [...orderedItems],
      },
    }));
  };

  const getTotalPreview = (cropBudgets: IBudgetsItem[]) => {
    return cropBudgets && cropBudgets.length ? sum(cropBudgets.map((cropBudget) => cropBudget.value || 0)) : 0;
  };

  return (
    <div>
      {currentOperationCrops.map((crop, index) => (
        <div key={crop.id}>
          {!!index && <div className="divider-line" />}
          <BudgetTotalRow operationCrop={crop} budget={editableBudget} />
          {editableBudget?.cropBudgets[crop.id] ? (
            <>
              <SelectWithLabel<number>
                label="University Budget"
                disabled={!isAtLeastEditor}
                options={getUniversityBudgetsOptions(defaultUniversityBudgets, crop.marketingCropType)}
                selected={getSelectedUniversityBudgets(
                  defaultUniversityBudgets,
                  crop.marketingCropType,
                  editableBudget.cropBudgets[crop.marketingCropType]
                )}
                onChange={(e) => onSelectBudgetHandler(e.value, crop.marketingCropType, crop.id)}
              />
              <div className="setting-costs-budget-preview flex-column">
                <span className="font-bold">Preview</span>
                {editableBudget.cropBudgets[crop.id].map((cropBudget, index) => (
                  <div key={index}>
                    <span>{cropBudget.categoryName}</span>
                    <span>{formatNumber(cropBudget.value, 2, currency)}</span>
                  </div>
                ))}
                <div>
                  <span className="font-bold">Total</span>
                  <span className="font-bold">
                    {formatNumber(getTotalPreview(editableBudget.cropBudgets[crop.id]), 2, currency)}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <SelectWithLabel<number>
              label="University Budget"
              options={getUniversityBudgetsOptions(defaultUniversityBudgets, crop.marketingCropType)}
              selected={null}
              disabled={!isAtLeastEditor}
              onChange={(e) => onSelectBudgetHandler(e.value, crop.marketingCropType, crop.id)}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default UniversityMode;

export function getSelectedUniversityBudgets(
  budgets: IBudget[],
  cropId: number,
  selectedBudgetItems: IBudgetsItem[]
): SelectorTypes<number> | null {
  const selectedBudget = budgets.find(
    (budget) => selectedBudgetItems && isEqual(budget.cropBudgets[cropId], selectedBudgetItems)
  );
  return selectedBudget ? { value: selectedBudget.id, label: selectedBudget.name } : null;
}

export function getUniversityBudgetsOptions(budgets: IBudget[], cropType: number): SelectorTypes<number>[] {
  return budgets
    .filter((budget) => budget.cropBudgets[cropType])
    .map((budget) => ({ value: budget.id, label: budget.name || '' }));
}
