import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from '../../types/INotification';
import { LoadingStatuses } from '../LoadingStatuses';
import ErrorToast from '../../components/Toast/ErrorToast';
import { RootState } from '../../types/Store';
import { fetchNotifications, patchNotification, readAllNotifications } from './thunks';

type NotificationsSliceInitialStateType = {
  notifications: INotification[];
  status: string;
  error: string | null;
};

const initialState: NotificationsSliceInitialStateType = {
  notifications: [],
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<INotification[]>) {
      state.notifications = [...action.payload];
    },
  },
  extraReducers: {
    [fetchNotifications.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchNotifications.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.notifications = [...action.payload];
    },
    [fetchNotifications.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load notifications!');
    },
    [patchNotification.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [patchNotification.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.notifications = state.notifications.filter((n) => n.id !== action.payload.id);
    },
    [patchNotification.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to close notification!');
    },
    [readAllNotifications.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [readAllNotifications.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.notifications = [];
    },
    [readAllNotifications.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to close all notifications!');
    },
  },
});

export default slice.reducer;
export const { setNotifications } = slice.actions;

export const selectAllNotifications = (state: RootState) => state.notifications.notifications;
