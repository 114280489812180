import { ITradingAccountConfig } from '../types/ITradingAccountConfig';
import apiClient from '../shared/apiClient';
import config from '../config';
import { QueryKey } from './QueryKey';
import { useCurrentOperationId } from '../customHooks/useCurrentOperation';
import { useQuery, useQueryClient } from '@tanstack/react-query';

async function fetch(operationId: number): Promise<ITradingAccountConfig[]> {
  const resp = await apiClient.get(`${config.backend.basePath}/trading_account_configs/operation/${operationId}`);
  return resp.data;
}

export function useTradingAccountConfigs() {
  const operationId = useCurrentOperationId();

  const queryKey = [QueryKey.TradingAccountConfigs, operationId];

  return useQuery({
    queryKey,
    queryFn: () => fetch(operationId),
    enabled: !!operationId,
    select: (data) => {
      return data.map((item) => {
        return {
          ...item,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        };
      });
    },
  });
}

export function usePrefetchTradingAccountConfigs() {
  const queryClient = useQueryClient();

  return (operationId: number) => {
    const queryKey = [QueryKey.TradingAccountConfigs, operationId];

    queryClient
      .prefetchQuery(queryKey, () => fetch(operationId))
      .catch((err) => {
        console.error('Error prefetching trading account configs', err);
      });
  };
}
