import * as types from './constants';
import { ErrorTypes, ValidationError } from './types';

export function setInviteAccountMemberError(error: ValidationError): ErrorTypes {
  return {
    type: types.SET_INVITE_ACCOUNT_MEMBER_ERROR,
    payload: { operationMember: error },
  };
}

export function deleteError(): ErrorTypes {
  return {
    type: types.DELETE_ERROR,
  };
}
