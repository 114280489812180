import { Auth0Provider, Auth0ProviderOptions, CacheLocation } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import 'bootstrap-print-css/css/bootstrap-print.min.css';
import { isNil } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import config from './config';
import './index.css';
import loggingInit from './loggers';
import * as serviceWorker from './serviceWorker';
import { FRQueryParamProvider } from './shared/FRQueryParamProvider';
import { history } from './shared/history';

// This needs to be the first thing so that we catch
// errors and get them reported
console.log(config.version);
loggingInit(config);

if (config.dataDogEnabled) {
  datadogRum.init({
    clientToken: 'pubd2157a0d8783ee2aea15cb381ddd6936',
    applicationId: 'ce7672c2-ac77-4900-9d99-e01b16f4cc25',
    site: 'datadoghq.com',
    service: 'webapp',
    env: config.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: config.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [
      'https://api.test.farmersrisk.ag',
      'https://api.staging.farmersrisk.ag',
      'https://api.farmersrisk.ag',
      'https://api.test.harvestiq.com',
      'https://api.staging.harvestiq.com',
      'https://api.harvestiq.com',
    ],
  });

  datadogRum.startSessionReplayRecording();
}

const onRedirectCallback = (appState: any) => {
  const redirectTo = appState?.returnTo || window.location.pathname;
  const redirectUri =
    !isNil(appState.advisorId) && !isNil(appState.verificationCode)
      ? `${redirectTo}/${appState.advisorId}/${appState.verificationCode}`
      : redirectTo;
  history.replace(redirectUri);
};

const auth0DefaultConfig: Partial<Auth0ProviderOptions> = {
  cacheLocation: 'localstorage' as CacheLocation,
  redirectUri: `${window.location.origin}/dashboard`,
  useRefreshTokens: true,
  onRedirectCallback,
};
const auth0EnvConfig: Auth0ProviderOptions = config.auth0;

const auth0ProviderConfig: Auth0ProviderOptions = {
  ...auth0DefaultConfig,
  ...auth0EnvConfig,
};

ReactDOM.render(
  <ErrorBoundary>
    <Router history={history}>
      <FRQueryParamProvider>
        <Auth0Provider {...auth0ProviderConfig}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Auth0Provider>
      </FRQueryParamProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
