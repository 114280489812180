import React from 'react';
import './Steps.css';

export interface iSteps {
  current: number;
  items: number;
}

function Steps({ current, items }: iSteps) {
  const activeItem = current - 1;

  return (
    <div className="steps steps-label-vertical steps-dot">
      {[...Array(items)].map((x, i) => (
        <div
          key={i}
          className={
            'steps-item ' +
            (activeItem >= i
              ? activeItem === i
                ? 'steps-item-process steps-item-active'
                : 'steps-item-finish'
              : 'steps-item-wait')
          }
        >
          <div className="steps-item-container">
            <div className="steps-item-tail" />
            <div className="steps-item-icon">
              <span className="steps-icon">
                <span className="steps-icon-dot" />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Steps;
