import { useAuth0 } from '@auth0/auth0-react';
import { useRef, useState } from 'react';
import { useRootClose } from 'react-overlays';
import { Link } from 'react-router-dom';
import config from '../../../config';
import useCurrentUser from '../../../customHooks/useCurrentUser';
import './UserButton.css';

export default function UserButton(props: { hasWhitelabeling?: boolean }) {
  const authenticatedUser = useCurrentUser();
  const ref: any = useRef();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const handleRootClose = () => {
    setShowDropdownMenu(false);
  };
  const { logout } = useAuth0();

  const logOut = () => {
    logout({ returnTo: config.landing.basePath });
  };

  useRootClose(ref, handleRootClose, { disabled: !showDropdownMenu });

  return (
    <div className="d-print-none user-button">
      <button className="user-button-icon" type="button" onClick={() => setShowDropdownMenu(true)} title="Account">
        {authenticatedUser.picture ? <img src={authenticatedUser.picture} alt={authenticatedUser.givenName ?? undefined} /> : ''}
        {props.hasWhitelabeling && (
          <img src={config.organization.logoSquare} alt={config.organization.name} style={{ marginLeft: 5 }} />
        )}
      </button>

      {showDropdownMenu && (
        <div ref={ref} className="user-dropdown-menu">
          <div className="user-name-and-email">
            <label>{authenticatedUser.givenName + ' ' + authenticatedUser.familyName}</label>
            <span>{authenticatedUser.email}</span>
          </div>
          <div className="user-dropdown-menu-link">
            <Link to="/account">Account</Link>
          </div>
          <div className="user-dropdown-menu-link">
            <a href="mailto:support@harvestiq.com">Help</a>
          </div>
          <div className="user-dropdown-menu-link">
            <Link to="/faq">FAQ</Link>
          </div>
          <div className="user-dropdown-menu-link">
            <button onClick={logOut}>Log out</button>
          </div>
        </div>
      )}
    </div>
  );
}
