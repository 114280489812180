import config from '../config';
import { Order_Duration, Order_OrderType } from '@farmersrisk/cqg/cqgMessages/WebAPI/order_2';
import { isNil } from 'lodash';

interface DropDownItem {
  value: number;
  label: string;
}

export enum OrderType {
  // these correlate to cqg proto Order_OrderType
  Market = Order_OrderType.ORDER_TYPE_MKT,
  Limit = Order_OrderType.ORDER_TYPE_LMT,
  StopWithProtection = Order_OrderType.ORDER_TYPE_STP,
  StopLimit = Order_OrderType.ORDER_TYPE_STL,
  // Cross = Order_OrderType.ORDER_TYPE_CROSS,
}

export const orderTypeLabelMap: Record<OrderType, string> = {
  [OrderType.Limit]: 'Limit Order',
  [OrderType.Market]: 'Market Order',
  [OrderType.StopWithProtection]: 'Stop Order',
  [OrderType.StopLimit]: 'Stop Limit Order',
  // [OrderType.Cross]: 'Cross Order',
};

const validTypes: DropDownItem[] = [];
config.trading.allowedOrderTypes.forEach((orderType: OrderType) => {
  if (orderType in OrderType) {
    validTypes.push({ value: orderType, label: orderTypeLabelMap[orderType] || '' });
  }
});
export const allowedOrderTypeData = validTypes;

export const getOrderTypeLabel = (orderType?: OrderType) => {
  if (isNil(orderType)) return '';
  return orderTypeLabelMap[orderType] || '';
};

export enum OrderDurationType {
  // these correlate to cqg proto Order_Duration
  Day = Order_Duration.DURATION_DAY,
  GoodTillCancel = Order_Duration.DURATION_GTC,
}

export const orderDurationTypeLabelMap: Record<OrderDurationType, string> = {
  [OrderDurationType.Day]: 'Day',
  [OrderDurationType.GoodTillCancel]: 'Good Til Canceled',
};

const validDurations: DropDownItem[] = [];
config.trading.allowedOrderDurations.forEach((orderDuration: OrderDurationType) => {
  if (orderDuration in OrderDurationType) {
    validDurations.push({ value: orderDuration, label: orderDurationTypeLabelMap[orderDuration] || '' });
  }
});

export const allowedOrderDurationTypeData = validDurations;

export const getOrderDurationTypeLabel = (orderDurationType?: OrderDurationType) => {
  if (isNil(orderDurationType)) return '';
  return orderDurationTypeLabelMap[orderDurationType] || '';
};
