import { Badge, makeStyles, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Notifications.css';
import { faBell, faTimes, FontAwesomeIcon, IconDefinition } from '../../faIcons';
import { INotification } from '../../../types/INotification';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { selectAllNotifications } from '../../../store/Notifications/notificationsSlice';
import { patchNotification, readAllNotifications } from '../../../store/Notifications/thunks';
import Button from '../../common/Button/Button';
import { createSelector } from '@reduxjs/toolkit';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';

dayjs.extend(relativeTime);

const useStyles = makeStyles({
  customBadge: {
    backgroundColor: '#AB141D',
    color: 'white',
    cursor: 'default',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  customPopover: {
    '& .MuiPaper-root': {
      padding: 16,
      margin: '4px 0',
      border: '1px solid rgb(224, 228, 232)',
      borderRadius: '2px',
      boxShadow: 'none',
    },
  },
});

interface INotificationItemProps {
  notification: INotification;
  updateNotificationHandler: () => void;
}

function NotificationItem(props: INotificationItemProps) {
  const { notification, updateNotificationHandler } = props;

  return (
    <div className="notifications-item">
      <div className="notifications-item-icon">
        <FontAwesomeIcon icon={faBell as IconDefinition} color={'#AB141D'} />
      </div>
      <div className="flex-column">
        {notification.message}
        <div className="notifications-item-age">{dayjs(notification.createdAt).fromNow()}</div>
      </div>
      <div className="close-notifications-item-icon">
        <FontAwesomeIcon
          icon={faTimes as IconDefinition}
          style={{ cursor: 'pointer' }}
          color={'#2E373F'}
          onClick={updateNotificationHandler}
        />
      </div>
    </div>
  );
}

const useNotificationsCount = createSelector(
  selectAllNotifications,
  (notifications) => notifications.filter((n) => !n.isRead).length
);

export default function Notifications() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSelector(selectAllNotifications);
  const notificationsCount = useSelector(useNotificationsCount);
  const currentOperation = useCurrentOperation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNotificationHandler = (notification: INotification) => {
    dispatch(patchNotification({ ...notification, isRead: true }));
  };

  const closeAllNotificationHandler = () => {
    handleClose();
    if (notifications.length) {
      dispatch(readAllNotifications(currentOperation.id));
    }
  };

  const getNotificationsContent = () =>
    notifications.length ? (
      notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          updateNotificationHandler={() => updateNotificationHandler(notification)}
        />
      ))
    ) : (
      <div className="notifications-is-empty">No notifications for now.</div>
    );

  return (
    <Badge
      className="notifications-icon"
      overlap="rectangular"
      classes={{ badge: classes.customBadge }}
      badgeContent={notificationsCount}
      color="error"
    >
      <button className="icon-button-root" onClick={handleOpen} title="Notifications">
        <FontAwesomeIcon icon={faBell as IconDefinition} />
      </button>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.customPopover}
      >
        <div className="notifications-container-root">
          <div className="notifications-header">
            Notifications
            <div className="notifications-container-close">
              <FontAwesomeIcon
                icon={faTimes as IconDefinition}
                color={'#2E373F'}
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="notifications-content">{getNotificationsContent()}</div>
          <div className="notifications-footer">
            <Button className="fr-button backGround-none" value="Close all" onClick={closeAllNotificationHandler} />
          </div>
        </div>
      </Popover>
    </Badge>
  );
}
