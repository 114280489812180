import { UnreachableCaseError } from '@harvestiq/utils';
import { UnitOfMeasure as UOM } from '@harvestiq/constants';

export const UnitOfMeasure = UOM;
export type UnitOfMeasure = UOM;

export enum LivestockWeightUom {
  HundredWeight = UOM.HundredWeight,
  Pounds = UOM.Pounds,
}

export const LbsPerCwt = 100;

export function getUomDisplayAbbreviation(uom: UnitOfMeasure) {
  const exhaustiveCheck = uom as never;
  switch (uom) {
    case UnitOfMeasure.Bushel:
      return 'bu';
    case UnitOfMeasure.Pounds:
      return 'lbs';
    // case UnitOfMeasure.Head:
    //   return 'hd';
    case UnitOfMeasure.ShortTon:
      return 'tons';
    case UnitOfMeasure.LongTon:
      return 'LT';
    case UnitOfMeasure.HundredWeight:
      return 'cwt';
    case UnitOfMeasure.MetricTon:
      return 't';
    // case UnitOfMeasure.Bales:
    //   return 'bales';
    default:
      throw new UnreachableCaseError(exhaustiveCheck);
  }
}

export function getUomDisplayPluralText(uom: UnitOfMeasure) {
  const exhaustiveCheck = uom as never;
  switch (uom) {
    case UnitOfMeasure.Bushel:
      return 'Bushels';
    case UnitOfMeasure.Pounds:
      return 'Pounds';
    case UnitOfMeasure.Head:
      return 'hd';
    case UnitOfMeasure.ShortTon:
      return 'Short Tons';
    case UnitOfMeasure.LongTon:
      return 'Long Tons';
    case UnitOfMeasure.HundredWeight:
      return 'Hundred Weight';
    case UnitOfMeasure.MetricTon:
      return 'Metric Tonnes';
    // case UnitOfMeasure.Bales:
    //   return 'bales';
    default:
      throw new UnreachableCaseError(exhaustiveCheck);
  }
}
