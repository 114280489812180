import { Decimal } from 'decimal.js';
import { dayjs } from '@harvestiq/utils';
import {
  CommodityInformation,
  CommodityId,
  getCommodityByCommodityId,
  getCommodityByTradingCode,
  getTradeableCommodities,
  mapInstrumentTypeToSymbol,
  InstrumentType,
} from '@harvestiq/constants';
import { FUTURES_MONTH_CODES, MONTH_CODE_TO_MONTH_NUMBER } from './constants';
import { FRSymbolUtils, getFRSymbol } from './frSymbols';
import { UnreachableCaseError } from '@harvestiq/utils';

export interface SymbolParams {
  instrumentType: InstrumentType;
  contractYear: number;
  contractMonth: number;
  expirationDate?: string | Date | null;
  strikePrice?: Decimal | number | null;
  underlyingFuturesMonth?: number | null;
  underlyingFuturesYear?: number | null;
  commodityId: CommodityId;
  currentTime?: Date | null;
  isMini?: boolean;
}

export const BARCHART_SPECIAL_OPTION_PREFIXES: Record<
  string,
  CommodityId | undefined
> = {
  BC: CommodityId.CORN,
  BY: CommodityId.SOYBEANS,
  BK: CommodityId.KC_WINTER_WHEAT_HRW,
  BH: CommodityId.CHI_SOFT_RED_WINTER_SRW,
};

export const BARCHART_NEW_CROP_WEEKLY_PREFIXES: Record<
  string,
  CommodityId | undefined
> = {
  BC: CommodityId.CORN,
  BM: CommodityId.SOYBEANS,
};

/**
 * Determines the correct Contract to be used for fetching quote and futures/options data
 * @returns the Barchart-compatible Contract
 */
export function getContract(params: {
  futuresYear: string;
  futuresMonth: number;
  commodityId: CommodityId;
}): string | null {
  const crop = getCommodityByCommodityId(params.commodityId);
  const monthCode = FUTURES_MONTH_CODES[params.futuresMonth];
  if (crop) {
    return `${crop.tradingCode}${monthCode}${params.futuresYear.substring(
      2,
      4
    )}`;
  }
  return null;
}

export class barchartSymbolUtils {
  static getMonthYearFromContract(barchartContract: string) {
    const monthYear = barchartContract.slice(-3);
    const contractMonth = MONTH_CODE_TO_MONTH_NUMBER[monthYear.slice(0, 1)];
    const contractYear = 2000 + Number(monthYear.slice(1));
    return { contractMonth, contractYear };
  }

  static getFRSymbol(
    barchartSymbol: string | null,
    currentTime?: Date
  ): string | null {
    if (barchartSymbol === null) {
      return null;
    }

    let hedgingCropType: CommodityId | undefined;
    let cropCode: string | undefined;
    let commodity: CommodityInformation | undefined;
    let isSpecialOption = false;
    let isNewCropWeeklyOption = false;
    if (barchartSymbol.includes('|')) {
      // special options
      const specialOptionTradingCode = barchartSymbol.substring(0, 2);
      hedgingCropType =
        BARCHART_SPECIAL_OPTION_PREFIXES[specialOptionTradingCode];
      if (hedgingCropType) {
        isSpecialOption = true;
      } else {
        // new crop weekly options
        hedgingCropType =
          BARCHART_NEW_CROP_WEEKLY_PREFIXES[specialOptionTradingCode];
        if (hedgingCropType) {
          isNewCropWeeklyOption = true;
        }
      }

      if (!hedgingCropType) {
        return null;
      }

      commodity = getCommodityByCommodityId(hedgingCropType);
      cropCode = commodity.tradingCode;
    } else {
      // futures, calls, puts
      cropCode = barchartSymbol.substring(0, 2);
      commodity = getCommodityByTradingCode(cropCode);
    }

    if (!commodity) {
      return null;
    }

    // FUTURES
    // ZCZ22 -> ZC.Z22.F
    // XNZ22 -> ZC.Z22.F.M
    if (barchartSymbol.length === 5) {
      return `${commodity.tradingCode}.${barchartSymbol.substring(2)}.F${
        cropCode != commodity.tradingCode ? '.M' : ''
      }`;
    }

    // CALLS AND PUTS
    // ZCZ650C -> ZC.Z22.C.650
    // ZCZ650D -> ZC.Z23.C.650
    // ZCZ650E -> ZC.Z24.C.650
    // ZCZ170P -> ZC.Z22.P.170
    // ZCZ170Q -> ZC.Z23.P.170
    // ZCZ170R -> ZC.Z24.P.170
    // ZSX1700C -> ZS.X22.C.1700
    // GFU1540P -> GF.U22.P.1540
    // ZMU410P -> ZM.U23.P.4100
    if (!isSpecialOption && !isNewCropWeeklyOption) {
      const currentTimeDjs = currentTime ? dayjs(currentTime) : dayjs();
      const currentYear = currentTimeDjs.format('YY');
      const nextYear = currentTimeDjs.add(1, 'year').format('YY');
      const nextNextYear = currentTimeDjs.add(2, 'year').format('YY');
      const barchartOptionType = barchartSymbol.slice(-1);
      const strikeStr = barchartSymbol.slice(3, -1);
      const monthCode = barchartSymbol.charAt(2);
      let year;
      let callOrPut;
      switch (barchartOptionType) {
        case 'C':
          year = currentYear;
          callOrPut = 'C';
          break;
        case 'D':
          year = nextYear;
          callOrPut = 'C';
          break;
        case 'E':
          year = nextNextYear;
          callOrPut = 'C';
          break;
        case 'P':
          year = currentYear;
          callOrPut = 'P';
          break;
        case 'Q':
          year = nextYear;
          callOrPut = 'P';
          break;
        case 'R':
          year = nextNextYear;
          callOrPut = 'P';
      }

      const monthNumber = MONTH_CODE_TO_MONTH_NUMBER[monthCode];
      const isSerial = !commodity.futuresTradingMonths.includes(monthNumber);

      const strike = parseInt(strikeStr, 10);

      return `${cropCode}.${monthCode}${year}.${callOrPut}.${strike}${
        isSerial ? '.S' : ''
      }`;
    }

    // SPECIAL OPTIONS
    // BCDN2|670C -> ZC.N22.C.670.SD
    // BC1N2|595C -> ZC.N22.C.595.W1
    // BY1H5|1600C -> ZS.H22.C.1600.W1
    // BM8H3|1020C -> ZS.H22.C.1020.W8.SD
    if (barchartSymbol.length === 10 || barchartSymbol.length === 11) {
      const monthCode = barchartSymbol.charAt(3);
      const lastDigitOfYear = barchartSymbol.charAt(4);
      const callOrPut = barchartSymbol.slice(-1);
      const strike = barchartSymbol.slice(6, -1);
      let specialOptionTypeCode;
      if (barchartSymbol.charAt(2) === 'D') {
        specialOptionTypeCode = 'SD';
      } else {
        const weekNumber = Number(barchartSymbol.charAt(2));

        // Regular Weekly Option
        if (weekNumber > 0 && weekNumber < 6) {
          specialOptionTypeCode = `W${weekNumber}`;
        } else {
          // New Crop Weekly Option
          specialOptionTypeCode = `W${
            weekNumber === 0 ? 5 : weekNumber - 5
          }.SD`;
        }
      }
      // FIXME: the '2' will be incorrect in the 2030s and beyond
      return `${cropCode}.${monthCode}2${lastDigitOfYear}.${callOrPut}.${strike}.${specialOptionTypeCode}`;
    }

    return null;
  }
}
export class CQGSymbolUtils {
  // F.US.ZCEZ22 future
  // C.US.ZCEZ226200 call - 6.20 strike
  // C.US.ZCEDU226150 short-dated call - 6.15 strike
  // C.US.ZCE1U226150 week 1 call - 6.15 strike
  static getInstrumentInformation(cqgSymbol: string) {
    const cqgSymbolSplit = cqgSymbol.split('.');
    // F, C, or P
    const instrumentLetter = cqgSymbolSplit[0];
    let remainingSuffix = cqgSymbolSplit[2];

    let instrumentType: InstrumentType;
    let cqgTradingCode: string | undefined;
    let weeklyModifier = '';

    const TRADEABLE_COMMODITIES = getTradeableCommodities();
    const cqgTradingCodes = TRADEABLE_COMMODITIES.map(
      (c) => c.cqgTradingCode
    ).filter((x) => !!x) as string[];
    const cqgShortDatedTradingCodes = TRADEABLE_COMMODITIES.map(
      (c) => c.cqgShortDatedTradingCode
    ).filter((x) => !!x) as string[];

    cqgTradingCode = cqgTradingCodes.find((code) =>
      remainingSuffix.toUpperCase().startsWith(code)
    );
    const cqgShortDatedTradingCode = cqgShortDatedTradingCodes.find((code) =>
      remainingSuffix.toUpperCase().startsWith(code)
    );

    if (!cqgTradingCode && !cqgShortDatedTradingCode) {
      throw new Error(
        `Could not find commodity using given symbol: ${cqgSymbol}`
      );
    }
    const isShortDated = !!cqgShortDatedTradingCode;
    const commodity = isShortDated
      ? TRADEABLE_COMMODITIES.find(
          (c) => c.cqgShortDatedTradingCode === cqgShortDatedTradingCode
        )
      : TRADEABLE_COMMODITIES.find((c) => c.cqgTradingCode === cqgTradingCode);
    const commodityId = commodity?.id;

    if (!commodityId) {
      throw new Error(
        `Could not find commodity using given symbol: ${cqgSymbol}`
      );
    }

    remainingSuffix = remainingSuffix.slice(
      isShortDated ? cqgShortDatedTradingCode.length : cqgTradingCode?.length
    );

    // if 1st character after trading code is a digit, it's a weekly option modifier ('1', '2', '3', '4', or '5')
    if (remainingSuffix[0].match(/\d/)) {
      // WEEKLY OPTIONS
      weeklyModifier = remainingSuffix[0];
    }

    if (instrumentLetter === 'F') {
      // FUTURES
      instrumentType = InstrumentType.Futures;
      // FIXME: Add Mini Futures HERE!!!
    } else {
      // OPTIONS
      if (!weeklyModifier && isShortDated) {
        // SHORT-DATED OPTIONS
        cqgTradingCode = cqgShortDatedTradingCode;
        instrumentType =
          instrumentLetter === 'C'
            ? InstrumentType.ShortDatedCall
            : InstrumentType.ShortDatedPut;
      } else if (weeklyModifier && !isShortDated) {
        // regular weekly options
        if (weeklyModifier === '1' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.Week1Call;
        } else if (weeklyModifier === '1' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.Week1Put;
        } else if (weeklyModifier === '2' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.Week2Call;
        } else if (weeklyModifier === '2' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.Week2Put;
        } else if (weeklyModifier === '3' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.Week3Call;
        } else if (weeklyModifier === '3' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.Week3Put;
        } else if (weeklyModifier === '4' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.Week4Call;
        } else if (weeklyModifier === '4' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.Week4Put;
        } else if (weeklyModifier === '5' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.Week5Call;
        } else {
          instrumentType = InstrumentType.Week5Put;
        }

        // chop off the weekly modifier
        remainingSuffix = remainingSuffix.slice(1);
      } else if (weeklyModifier && isShortDated) {
        cqgTradingCode = cqgShortDatedTradingCode;
        // new crop weekly options
        if (weeklyModifier === '1' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.NewCropWeek1Call;
        } else if (weeklyModifier === '1' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.NewCropWeek1Put;
        } else if (weeklyModifier === '2' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.NewCropWeek2Call;
        } else if (weeklyModifier === '2' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.NewCropWeek2Put;
        } else if (weeklyModifier === '3' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.NewCropWeek3Call;
        } else if (weeklyModifier === '3' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.NewCropWeek3Put;
        } else if (weeklyModifier === '4' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.NewCropWeek4Call;
        } else if (weeklyModifier === '4' && instrumentLetter === 'P') {
          instrumentType = InstrumentType.NewCropWeek4Put;
        } else if (weeklyModifier === '5' && instrumentLetter === 'C') {
          instrumentType = InstrumentType.NewCropWeek5Call;
        } else {
          instrumentType = InstrumentType.NewCropWeek5Put;
        }

        // chop off the weekly modifier
        remainingSuffix = remainingSuffix.slice(1);
      } else {
        // REGULAR OPTIONS
        instrumentType =
          instrumentLetter === 'C' ? InstrumentType.Call : InstrumentType.Put;
      }
    }

    const monthCode = remainingSuffix.slice(0, 1); // F, G, H, J, K, M, N, Q, U, V, X, Z
    const month = MONTH_CODE_TO_MONTH_NUMBER[monthCode];
    const yearCode = remainingSuffix.slice(1, 3);
    const year = Number(yearCode) + 2000;

    let strikePriceDecimal: Decimal | undefined = undefined;
    let underlyingFuturesMonth: number | undefined = undefined;
    let underlyingFuturesYear: number | undefined = undefined;
    let contractMonth: number | undefined = undefined;
    let contractYear: number | undefined = undefined;
    if (instrumentType === InstrumentType.Futures) {
      underlyingFuturesMonth = month;
      underlyingFuturesYear = year;
      contractMonth = month;
      contractYear = year;
    } else {
      strikePriceDecimal = new Decimal(remainingSuffix.slice(3)).dividedBy(
        commodity.cqgStrikeMultiplier ?? 1000
      );
      contractMonth = month;
      contractYear = year;
    }

    // check for serial options
    if (
      (instrumentType === InstrumentType.Call ||
        instrumentType === InstrumentType.Put) &&
      !commodity.futuresTradingMonths.includes(month)
    ) {
      instrumentType =
        instrumentType === InstrumentType.Call
          ? InstrumentType.SerialCall
          : InstrumentType.SerialPut;
      underlyingFuturesMonth = undefined;
      underlyingFuturesYear = undefined;
      contractMonth = month;
      contractYear = year;
    }

    const params: SymbolParams = {
      commodityId,
      instrumentType,
      underlyingFuturesMonth,
      underlyingFuturesYear,
      strikePrice: strikePriceDecimal,
      contractMonth,
      contractYear,
    };

    return params;
  }

  static getCQGSymbol(symbolParams: SymbolParams): string | null {
    const {
      commodityId,
      instrumentType,
      contractMonth,
      contractYear,
      strikePrice,
    } = symbolParams;

    const commodity = getCommodityByCommodityId(commodityId);

    const monthCode = FUTURES_MONTH_CODES[contractMonth];
    const yearCode = contractYear.toString().slice(-2);
    const prefix = `${mapInstrumentTypeToSymbol(instrumentType)}.US.`;
    let tradingCode = commodity.cqgTradingCode;
    let tradingModifier = '';
    let strike: number | null = strikePrice
      ? new Decimal(strikePrice)
          .times(commodity.cqgStrikeMultiplier ?? 1000)
          .toNumber()
      : null;

    switch (instrumentType) {
      case InstrumentType.Put:
      case InstrumentType.Call:
      case InstrumentType.SerialPut:
      case InstrumentType.SerialCall:
        // no modification needed
        break;
      case InstrumentType.ShortDatedPut:
      case InstrumentType.ShortDatedCall:
        tradingCode = commodity.cqgShortDatedTradingCode;
        break;
      case InstrumentType.Week1Put:
      case InstrumentType.Week1Call:
        tradingModifier = '1';
        break;
      case InstrumentType.Week2Put:
      case InstrumentType.Week2Call:
        tradingModifier = '2';
        break;
      case InstrumentType.Week3Put:
      case InstrumentType.Week3Call:
        tradingModifier = '3';
        break;
      case InstrumentType.Week4Put:
      case InstrumentType.Week4Call:
        tradingModifier = '4';
        break;
      case InstrumentType.Week5Put:
      case InstrumentType.Week5Call:
        tradingModifier = '5';
        break;
      // new crop weekly options
      case InstrumentType.NewCropWeek1Put:
      case InstrumentType.NewCropWeek1Call:
        tradingCode = commodity.cqgShortDatedTradingCode;
        tradingModifier = '1';
        break;
      case InstrumentType.NewCropWeek2Put:
      case InstrumentType.NewCropWeek2Call:
        tradingCode = commodity.cqgShortDatedTradingCode;
        tradingModifier = '2';
        break;
      case InstrumentType.NewCropWeek3Put:
      case InstrumentType.NewCropWeek3Call:
        tradingCode = commodity.cqgShortDatedTradingCode;
        tradingModifier = '3';
        break;
      case InstrumentType.NewCropWeek4Put:
      case InstrumentType.NewCropWeek4Call:
        tradingCode = commodity.cqgShortDatedTradingCode;
        tradingModifier = '4';
        break;
      case InstrumentType.NewCropWeek5Put:
      case InstrumentType.NewCropWeek5Call:
        tradingCode = commodity.cqgShortDatedTradingCode;
        tradingModifier = '5';
        break;
      case InstrumentType.Futures:
        strike = null;
        // no modification needed
        break;
      default:
        throw new UnreachableCaseError(instrumentType);
    }
    const cqgSymbol = `${prefix}${tradingCode}${tradingModifier}${monthCode}${yearCode}${
      strike !== null ? strike : ''
    }`;
    return cqgSymbol;
  }

  static getFRSymbol(cqgSymbol: string): string | null {
    const params = this.getInstrumentInformation(cqgSymbol);
    return getFRSymbol({
      commodityId: params.commodityId,
      instrumentType: params.instrumentType,
      contractMonth: params.contractMonth ?? 99,
      contractYear: params.contractYear ?? 9999,
      strikePrice: params.strikePrice
        ? new Decimal(params.strikePrice)
        : undefined,
      isMini: params.isMini,
    });
  }

  static getBarchartSymbol(
    cqgSymbol: string,
    currentTime?: Date
  ): string | null {
    const frSymbol = this.getFRSymbol(cqgSymbol);
    return frSymbol
      ? FRSymbolUtils.getBarchartSymbol(frSymbol, currentTime)
      : null;
  }
}
