import { ClientMsg, ServerMsg } from '../cqgMessages/WebAPI/webapi_2';

export enum TradingLogOriginType {
  CQG = 1,
  User = 2,
}

export type CQGLog = {
  userId: number;
  operationId: number;
  datetime: string;
  sessionToken?: string;
  sessionTokenHash?: number;
};

export type CQGOriginLog = CQGLog & { origin: TradingLogOriginType };

export type RawMessageLog = CQGOriginLog & { cqgData: ClientMsg | ServerMsg };
