/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';

export const protobufPackage = 'otc_1';

export enum InstanceState {
  /** INSTANCE_STATE_ONLINE - Instance is online and processes orders. */
  INSTANCE_STATE_ONLINE = 0,
  /**
   * INSTANCE_STATE_OFFLINE - Instance is offline. Used only in status response. OTC may not be put
   * offline by client request.
   */
  INSTANCE_STATE_OFFLINE = 1,
  /** INSTANCE_STATE_SUSPENDED - Instance is suspended by ChangeOtcStateRequest. */
  INSTANCE_STATE_SUSPENDED = 2,
  UNRECOGNIZED = -1,
}

export function instanceStateFromJSON(object: any): InstanceState {
  switch (object) {
    case 0:
    case 'INSTANCE_STATE_ONLINE':
      return InstanceState.INSTANCE_STATE_ONLINE;
    case 1:
    case 'INSTANCE_STATE_OFFLINE':
      return InstanceState.INSTANCE_STATE_OFFLINE;
    case 2:
    case 'INSTANCE_STATE_SUSPENDED':
      return InstanceState.INSTANCE_STATE_SUSPENDED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return InstanceState.UNRECOGNIZED;
  }
}

export function instanceStateToJSON(object: InstanceState): string {
  switch (object) {
    case InstanceState.INSTANCE_STATE_ONLINE:
      return 'INSTANCE_STATE_ONLINE';
    case InstanceState.INSTANCE_STATE_OFFLINE:
      return 'INSTANCE_STATE_OFFLINE';
    case InstanceState.INSTANCE_STATE_SUSPENDED:
      return 'INSTANCE_STATE_SUSPENDED';
    case InstanceState.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Client messages. */
export interface OtcRequest {
  /** Request identifier. */
  requestId: string;
  /**
   * OTC instances subscription.
   * Number of simultaneous requests with subscription for updates is limited,
   * 1 by default.
   */
  otcInstancesSubscription: OtcInstancesSubscription | undefined;
  /**
   * Hedge books subscription.
   * Number of simultaneous requests with subscription for updates is limited,
   * 1 per OTC instance by default.
   */
  hedgeBooksSubscription: HedgeBooksSubscription | undefined;
  /**
   * Hedge book details subscription.
   * Request rate is limited, 1000 requests per 100 seconds by default.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Number of simultaneous requests with subscription for updates is limited,
   * 1000 by default.
   */
  hedgeBookDetailsSubscription: HedgeBookDetailsSubscription | undefined;
  /**
   * Offset hedge balance request.
   * Request rate is limited, 100 requests per 100 seconds by default.
   */
  offsetHedgeBalanceRequest: OffsetHedgeBalanceRequest | undefined;
  /**
   * Change OTC state request.
   * Request rate is limited, 10 requests per 100 seconds by default.
   */
  changeOtcStateRequest: ChangeOtcStateRequest | undefined;
  /**
   * Fill cash order request.
   * Request rate is limited, 1000 requests per 100 seconds by default.
   */
  fillCashOrderRequest: FillCashOrderRequest | undefined;
  /** Archive hedge balance details request. */
  archiveHedgeBalanceDetailsRequest: ArchiveHedgeBalanceDetailsRequest | undefined;
  /**
   * Request balance item links for specific item.
   * Request rate is limited, 100 requests per 100 seconds by default.
   */
  balanceItemsLinksRequest: BalanceItemsLinksRequest | undefined;
  /**
   * TailManagement configuration subscription.
   * Number of simultaneous requests with subscription for updates is limited,
   * 1 by default.
   */
  tailManagementConfigurationSubscription: TailManagementConfigurationSubscription | undefined;
  /**
   * Request to update tail management configuration.
   * Request rate is limited, 10 requests per 100 seconds by default.
   */
  updateTailManagementConfiguration: UpdateTailManagementConfiguration | undefined;
  /**
   * Request to get tail management configuration as string in ATDL format.
   * Request rate is limited, 10 requests per 100 seconds by default.
   */
  requestTailManagementConfigurationMetadata: RequestTailManagementConfigurationMetadata | undefined;
  /**
   * Request to reset order's custom basis.
   * Request rate is limited, 1000 requests per 100 seconds by default.
   *
   * @deprecated
   */
  resetCustomBasisRequest: ResetCustomBasisRequest | undefined;
}

/** OTC/WebAPI responses. */
export interface OtcReport {
  /** ID of a corresponding request or subscription. */
  requestId: string;
  /**
   * Operation status.
   * This field is associated with StatusCode enum.
   */
  statusCode: number;
  /** Operation details. */
  details: Text | undefined;
  /** OTC instances report. */
  otcInstancesReport: OtcInstancesReport | undefined;
  /** Hedge books report. */
  hedgeBooksReport: HedgeBooksReport | undefined;
  /** Hedge book details report. */
  hedgeBookDetailsReport: HedgeBookDetailsReport | undefined;
  /** Offset hedge balance result. */
  offsetHedgeBalanceResult: OffsetHedgeBalanceResult | undefined;
  /** Change OTC state result. */
  changeOtcStateResult: ChangeOtcStateResult | undefined;
  /** Fill cash order result. */
  fillCashOrderResult: FillCashOrderResult | undefined;
  /** Archive hedge balance details result. */
  archiveHedgeBalanceDetailsResult: ArchiveHedgeBalanceDetailsResult | undefined;
  /** Balance item links for requested item. */
  balanceItemsLinksResult: BalanceItemsLinksResult | undefined;
  /** Tail management configuration report. */
  tailManagementConfigurationReport: TailManagementConfigurationReport | undefined;
  /** Tail management metadata in ATDL format as string. */
  tailManagementConfigurationMetadata: TailManagementConfigurationMetadata | undefined;
  /** Update tail management configuration result. */
  updateTailManagementConfigurationResult: UpdateTailManagementConfigurationResult | undefined;
  /** Reset order's custom basis result. */
  resetCustomBasisResult: ResetCustomBasisResult | undefined;
}

export enum OtcReport_StatusCode {
  /** STATUS_CODE_SUCCESS - / success codes (0 - 100) */
  STATUS_CODE_SUCCESS = 0,
  /** STATUS_CODE_SUBSCRIBED - Request is processed and subscription is established. */
  STATUS_CODE_SUBSCRIBED = 1,
  /** STATUS_CODE_DROPPED - The subscription is dropped by the client. */
  STATUS_CODE_DROPPED = 2,
  /** STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  STATUS_CODE_UPDATE = 3,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected because of communication issues.
   * NOTE: Clients should not resubscribe in this case, the server will
   * restore subscription with sending STATUS_CODE_SUBSCRIBED status once
   * communication issues are resolved.
   */
  STATUS_CODE_DISCONNECTED = 4,
  /**
   * STATUS_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Subscription parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_ACCESS_DENIED - The user is not allowed to perform the request. */
  STATUS_CODE_ACCESS_DENIED = 103,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The subscription count limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 104,
  /** STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION - The subscription rate limit has been violated. */
  STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_NOT_FOUND - Object not found. */
  STATUS_CODE_NOT_FOUND = 106,
  /** STATUS_CODE_TIMEOUT - Request is timed out. */
  STATUS_CODE_TIMEOUT = 107,
  UNRECOGNIZED = -1,
}

export function otcReport_StatusCodeFromJSON(object: any): OtcReport_StatusCode {
  switch (object) {
    case 0:
    case 'STATUS_CODE_SUCCESS':
      return OtcReport_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case 'STATUS_CODE_SUBSCRIBED':
      return OtcReport_StatusCode.STATUS_CODE_SUBSCRIBED;
    case 2:
    case 'STATUS_CODE_DROPPED':
      return OtcReport_StatusCode.STATUS_CODE_DROPPED;
    case 3:
    case 'STATUS_CODE_UPDATE':
      return OtcReport_StatusCode.STATUS_CODE_UPDATE;
    case 4:
    case 'STATUS_CODE_DISCONNECTED':
      return OtcReport_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case 'STATUS_CODE_FAILURE':
      return OtcReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case 'STATUS_CODE_INVALID_PARAMS':
      return OtcReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 103:
    case 'STATUS_CODE_ACCESS_DENIED':
      return OtcReport_StatusCode.STATUS_CODE_ACCESS_DENIED;
    case 104:
    case 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION':
      return OtcReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 105:
    case 'STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION':
      return OtcReport_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION;
    case 106:
    case 'STATUS_CODE_NOT_FOUND':
      return OtcReport_StatusCode.STATUS_CODE_NOT_FOUND;
    case 107:
    case 'STATUS_CODE_TIMEOUT':
      return OtcReport_StatusCode.STATUS_CODE_TIMEOUT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OtcReport_StatusCode.UNRECOGNIZED;
  }
}

export function otcReport_StatusCodeToJSON(object: OtcReport_StatusCode): string {
  switch (object) {
    case OtcReport_StatusCode.STATUS_CODE_SUCCESS:
      return 'STATUS_CODE_SUCCESS';
    case OtcReport_StatusCode.STATUS_CODE_SUBSCRIBED:
      return 'STATUS_CODE_SUBSCRIBED';
    case OtcReport_StatusCode.STATUS_CODE_DROPPED:
      return 'STATUS_CODE_DROPPED';
    case OtcReport_StatusCode.STATUS_CODE_UPDATE:
      return 'STATUS_CODE_UPDATE';
    case OtcReport_StatusCode.STATUS_CODE_DISCONNECTED:
      return 'STATUS_CODE_DISCONNECTED';
    case OtcReport_StatusCode.STATUS_CODE_FAILURE:
      return 'STATUS_CODE_FAILURE';
    case OtcReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return 'STATUS_CODE_INVALID_PARAMS';
    case OtcReport_StatusCode.STATUS_CODE_ACCESS_DENIED:
      return 'STATUS_CODE_ACCESS_DENIED';
    case OtcReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION';
    case OtcReport_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION';
    case OtcReport_StatusCode.STATUS_CODE_NOT_FOUND:
      return 'STATUS_CODE_NOT_FOUND';
    case OtcReport_StatusCode.STATUS_CODE_TIMEOUT:
      return 'STATUS_CODE_TIMEOUT';
    case OtcReport_StatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Hedge balance key. */
export interface HedgeBalanceKey {
  /** Hedge Account ID in CQG trade routing system. */
  accountId: number;
  /** Server side contract id. */
  contractId: number;
}

/** OTC instances subscription. */
export interface OtcInstancesSubscription {
  /** If set to true - subscribe to changes, if false - drop subscription. */
  subscribe: boolean;
}

/** OTC instances report. */
export interface OtcInstancesReport {
  otcInstanceStates: OtcInstanceState[];
}

/** OTC instance state. */
export interface OtcInstanceState {
  /** OTC instance ID. */
  instanceId: number;
  /** OTC instance name. */
  name: string;
  /**
   * OTC instance state.
   * This field is associated with InstanceState enum type.
   */
  state: number;
}

/** Hedge books subscription. */
export interface HedgeBooksSubscription {
  /** If set to true - subscribe to changes, if false - drop subscription. */
  subscribe: boolean;
  /** OTC instance id. */
  otcInstanceId: number;
}

/** Hedge books report. */
export interface HedgeBooksReport {
  /**
   * True if this is a snapshot (all previously known data about this hedge
   * book should be cleaned).
   * All updates will be postponed until full snapshot is sent.
   * False used for incremental updates.
   * Snapshot will be sent only once after subscription. May be sent in multiple
   * parts. Each part will have this field set to true. False by default.
   */
  isSnapshot: boolean;
  /**
   * If true - then this message mean last part of multi-parted snapshot.
   * NOTE: It can be also true in case of internal communication issues.
   * After reconnection WebAPI will send difference between the old snapshot and the actual snapshot.
   */
  isLastPart: boolean;
  /** Hedge balance details. */
  hedgeBalanceDetails: HedgeBalanceDetails[];
  /** List of archived hedge balance details. */
  archivedHedgeBalanceDetails: ArchivedHedgeBalanceDetails[];
}

export interface HedgeBalanceDetails {
  /** Hedge balance key. */
  hedgeBalanceKey: HedgeBalanceKey | undefined;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only hedge_balance_key will be provided in this case.
   */
  deleted: boolean;
  /**
   * Current hedge balance in units. If missing - hedge balance is not changed
   * since last update.
   */
  hedgeBalance: Decimal | undefined;
  /**
   * Pending balance in units. Means non-filled hedge orders size in units.
   * If missing - pending balance is not changed since last update.
   */
  pendingBalance: Decimal | undefined;
  /** Full contract symbol. */
  contractSymbol: string;
  /** Name of units for balance values in this message */
  unitsName: Text | undefined;
}

/** Hedge book details subscription. */
export interface HedgeBookDetailsSubscription {
  /** If set to true - subscribe to changes, if false - drop subscription. */
  subscribe: boolean;
  /** OTC instance id. */
  otcInstanceId: number;
  /** Hedge balance key. */
  hedgeBalanceKey: HedgeBalanceKey | undefined;
  /**
   * Start point for the history request, inclusive.
   * Maximum allowed history is returned if omitted.
   */
  fromUtcTimestamp: Date | undefined;
  /**
   * Unique identifier of archived hedge balance and its details; generated by OTC.
   * Should be used for grouping archived hedge balance details and archived hedge book details.
   */
  archiveId: string;
}

/** Hedge book details report. */
export interface HedgeBookDetailsReport {
  /**
   * True if this is a snapshot (all previously known data about this hedge book
   * should be cleaned).
   * False used for incremental updates.
   * Snapshot will be sent only once after subscription. May be sent in multiple
   * parts. Each part will have this field set to true. False by default.
   */
  isSnapshot: boolean;
  /**
   * If true - then this message mean last part of multi-parted snapshot.
   * NOTE: It can be also true in case of internal communication issues.
   * After reconnection WebAPI will send difference between the old snapshot and the actual snapshot.
   */
  isLastPart: boolean;
  /**
   * List of cash orders.
   * Deprecated. Use balance_items instead.
   *
   * @deprecated
   */
  cashOrderDetails: CashOrderDetails[];
  /**
   * List of hedge orders.
   * Deprecated. Use balance_items instead.
   *
   * @deprecated
   */
  hedgeOrderDetails: HedgeOrderDetails[];
  /**
   * List of links between cash and hedge orders.
   * Only one link between particular cash and hedge orders may exist.
   * Deprecated. Use items_links instead.
   *
   * @deprecated
   */
  cashToHedgeOrdersLinks: CashToHedgeOrdersLink[];
  /** List of balance items: Cash, Hedge and BalanceOffset */
  balanceItems: BalanceItem[];
  /** List of links between any type of BalanceItem. */
  itemsLinks: BalanceItemsLink[];
}

/**
 * Cash order details.
 * [deprecated] use BalanceItem instead.
 */
export interface CashOrderDetails {
  /** Order ID assigned by server to originally placed order. */
  chainOrderId: string;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only chain_order_id will be provided in this case.
   */
  deleted: boolean;
  /** Account ID in CQG trade routing system. */
  accountId: number;
  /** Server side contract id. */
  contractId: number;
  /** Time when order added to hedge balance. */
  hedgeUtcTimestamp: Date | undefined;
  /** Total order size in units. */
  units: Decimal | undefined;
  /** Name of units for order size value in this message. */
  unitsName: Text | undefined;
}

/**
 * Hedge order details.
 * [deprecated] use BalanceItem instead.
 */
export interface HedgeOrderDetails {
  /** Order ID assigned by server to originally placed order. */
  chainOrderId: string;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only chain_order_id will be provided in this case.
   */
  deleted: boolean;
  /** Time when hedge order placed. */
  hedgeUtcTimestamp: Date | undefined;
  /** Total order size in units. */
  units: Decimal | undefined;
  /** Quantity open for further execution in units. */
  openUnits: Decimal | undefined;
  /** Name of units for order size values in this message */
  unitsName: Text | undefined;
}

/**
 * Cash to hedge orders link.
 * [deprecated] use BalanceItemsLink instead.
 */
export interface CashToHedgeOrdersLink {
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only link_id will be provided in this case.
   */
  deleted: boolean;
  /** Time when link created. */
  linkUtcTimestamp: Date | undefined;
  /** Cash order ID. */
  cashOrderId: string;
  /** Hedge Order ID. */
  hedgeOrderId: string;
  /** Hedge size covered by this link in units. */
  linkUnits: Decimal | undefined;
  /** Name of units for hedge size values in this message */
  unitsName: Text | undefined;
}

/** Key that uniquely identifies each BalanceItem. */
export interface BalanceItemKey {
  /**
   * For CASH_ORDER and HEDGE_ORDER - Order ID assigned by server to originally placed order.
   * For BALANCE_OFFSET - Offset ID assigned by OTC.
   */
  itemId: string;
  /** This field is associated with ItemType enum. */
  itemType: number;
}

export enum BalanceItemKey_ItemType {
  CASH_ORDER = 1,
  HEDGE_ORDER = 2,
  BALANCE_OFFSET = 3,
  UNRECOGNIZED = -1,
}

export function balanceItemKey_ItemTypeFromJSON(object: any): BalanceItemKey_ItemType {
  switch (object) {
    case 1:
    case 'CASH_ORDER':
      return BalanceItemKey_ItemType.CASH_ORDER;
    case 2:
    case 'HEDGE_ORDER':
      return BalanceItemKey_ItemType.HEDGE_ORDER;
    case 3:
    case 'BALANCE_OFFSET':
      return BalanceItemKey_ItemType.BALANCE_OFFSET;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BalanceItemKey_ItemType.UNRECOGNIZED;
  }
}

export function balanceItemKey_ItemTypeToJSON(object: BalanceItemKey_ItemType): string {
  switch (object) {
    case BalanceItemKey_ItemType.CASH_ORDER:
      return 'CASH_ORDER';
    case BalanceItemKey_ItemType.HEDGE_ORDER:
      return 'HEDGE_ORDER';
    case BalanceItemKey_ItemType.BALANCE_OFFSET:
      return 'BALANCE_OFFSET';
    case BalanceItemKey_ItemType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Balance item details. */
export interface BalanceItem {
  /** Unique identifier for each BalanceItem */
  itemKey: BalanceItemKey | undefined;
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only chain_order_id will be provided in this case.
   */
  deleted: boolean;
  /** Time when order placed or balance offset initiated. */
  utcTimestamp: Date | undefined;
  /** Total order size in units. */
  units: Decimal | undefined;
  /** Quantity open for further execution in units. */
  openUnits: Decimal | undefined;
  /** Name of units for order size values in this message */
  unitsName: Text | undefined;
  /**
   * Trader name initiated balance offset.
   * Used for BALANCE_OFFSET and if specified in OffsetHedgeBalanceRequest.
   */
  username: string;
  /**
   * User comment with information related to balance offset.
   * Used for BALANCE_OFFSET and if specified in OffsetHedgeBalanceRequest.
   */
  comment: string;
  /** Server side contract id. */
  contractId: number;
}

/** Balance items link details. */
export interface BalanceItemsLink {
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only first_item_key and second_item_key will be provided in this case.
   */
  deleted: boolean;
  /** Time when link created. */
  linkUtcTimestamp: Date | undefined;
  /** Unique identifier for first BalanceItem. */
  firstItemKey: BalanceItemKey | undefined;
  /** Unique identifier for second BalanceItem. */
  secondItemKey: BalanceItemKey | undefined;
  /** Link size in units of first item */
  sizeInFirstItemUnits: Decimal | undefined;
  /** Units for size_in_first_item_units value (eg: 'Bushels', 'Tonnes', 'CAD', ...) */
  firstItemUnits: Text | undefined;
  /** Link size from in units of the second item */
  sizeInSecondItemUnits: Decimal | undefined;
  /** Units for size_in_second_item_units value (eg: 'Bushels', 'Tonnes', 'CAD', ...) */
  secondItemUnits: Text | undefined;
}

/** Offset hedge balance request. */
export interface OffsetHedgeBalanceRequest {
  /** OTC instance id. */
  otcInstanceId: number;
  /** Hedge balance key. */
  hedgeBalanceKey: HedgeBalanceKey | undefined;
  /** Hedge balance offset. */
  hedgeBalanceOffset: Decimal | undefined;
  /** Trader name initiated balance offset. */
  username: string;
  /** User comment. */
  comment: string;
  /** offset balance to 0. */
  zeroBalance: boolean;
}

/** Offset hedge balance result. */
export interface OffsetHedgeBalanceResult {}

/**
 * Change OTC state request.
 * Shall provide OTC instance ID.
 */
export interface ChangeOtcStateRequest {
  /** OTC instance id. */
  otcInstanceId: number;
  /**
   * New OTC instance state.
   * This field is associated with Action enum type.
   */
  action: number;
}

export enum ChangeOtcStateRequest_Action {
  /** ACTION_ACTIVATE - Activate previously suspended OTC instance. */
  ACTION_ACTIVATE = 0,
  /**
   * ACTION_SUSPEND - Suspend active OTC instance.
   * OTC will reject all client orders and will not send hedge orders to
   * exchange.
   */
  ACTION_SUSPEND = 1,
  UNRECOGNIZED = -1,
}

export function changeOtcStateRequest_ActionFromJSON(object: any): ChangeOtcStateRequest_Action {
  switch (object) {
    case 0:
    case 'ACTION_ACTIVATE':
      return ChangeOtcStateRequest_Action.ACTION_ACTIVATE;
    case 1:
    case 'ACTION_SUSPEND':
      return ChangeOtcStateRequest_Action.ACTION_SUSPEND;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ChangeOtcStateRequest_Action.UNRECOGNIZED;
  }
}

export function changeOtcStateRequest_ActionToJSON(object: ChangeOtcStateRequest_Action): string {
  switch (object) {
    case ChangeOtcStateRequest_Action.ACTION_ACTIVATE:
      return 'ACTION_ACTIVATE';
    case ChangeOtcStateRequest_Action.ACTION_SUSPEND:
      return 'ACTION_SUSPEND';
    case ChangeOtcStateRequest_Action.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Change OTC state result. */
export interface ChangeOtcStateResult {}

/** Fill cash order request. */
export interface FillCashOrderRequest {
  /** OTC instance id. */
  otcInstanceId: number;
  /**
   * Order ID assigned by server to originally placed order.
   * See order_2.OrderStatus.chain_order_id.
   */
  chainOrderId: string;
  /** Account ID in CQG trade routing system associated with the order. */
  accountId: number;
  /** Server side contract id of the contract for the order. */
  contractId: number;
  /**
   * Optional fill quantity in lots. Set this field only for partial fills.
   * Order will be fully filled if this field is not set.
   */
  qty: Decimal | undefined;
  /** Fill price. May be omitted to fill limit order at order's price. */
  scaledFillPrice: number;
  /** OTC contributor id involved into the order fill processing. */
  contributorId: string;
}

/** Fill cash order result. */
export interface FillCashOrderResult {}

/** Archive hedge balance details request. */
export interface ArchiveHedgeBalanceDetailsRequest {
  /** Hedge balance key. */
  hedgeBalanceKey: HedgeBalanceKey | undefined;
  /** OTC instance id. */
  otcInstanceId: number;
}

/** Archive hedge balance details response. */
export interface ArchiveHedgeBalanceDetailsResult {}

/** Hedge balance details, marked as archived. */
export interface ArchivedHedgeBalanceDetails {
  /**
   * Deleted flag is used in updates when entity is removed.
   * Only archive_id will be provided in this case.
   */
  deleted: boolean;
  /**
   * Unique identifier of archived hedge balance and its details; generated by OTC.
   * Should be used for grouping archived hedge balance details and archived hedge book details.
   */
  archiveId: string;
  /** Archived hedge balance details. */
  hedgeBalanceDetails: HedgeBalanceDetails | undefined;
  /** The time when hedge balance and its details were archived. */
  archiveUtcTimestamp: Date | undefined;
}

/** Request balance item links for specific item. */
export interface BalanceItemsLinksRequest {
  /** OTC instance id. */
  otcInstanceId: number;
  /**
   * For CASH_ORDER and HEDGE_ORDER - Order ID assigned by server to originally placed order.
   * For BALANCE_OFFSET - Offset ID assigned by OTC.
   */
  itemId: string;
  /** Order Account ID in CQG trade routing system. */
  accountId: number;
  /** Server side item contract id. */
  contractId: number;
}

/** Balance item links for requested item. */
export interface BalanceItemsLinksResult {
  /** All balance items for provided links. */
  balanceItems: BalanceItem[];
  /** Links for requested item. */
  itemsLinks: BalanceItemsLink[];
}

/** Update tail management configuration result. */
export interface UpdateTailManagementConfigurationResult {}

/** Subscribe on Tail Management Configuration updates. */
export interface TailManagementConfigurationSubscription {
  /** If set to true - subscribe to changes, if false - drop subscription. */
  subscribe: boolean;
  /** OTC instance id. */
  otcInstanceId: number;
}

/** Request to update Tail Management Configuration. */
export interface UpdateTailManagementConfiguration {
  /** OTC instance id. */
  otcInstanceId: number;
  /** New tail management configuration. */
  tailManagementConfigurations: TailManagementConfiguration[];
}

/** Message sent to subscribers about Tail Management Configuration change. */
export interface TailManagementConfigurationReport {
  /**
   * True if this is a snapshot (all previously known configuration data
   * about this contract and account should be cleaned).
   * False used for incremental updates.
   * Snapshot will be sent only once after subscription. May be sent in multiple
   * parts. Each part will have this field set to true. False by default.
   */
  isSnapshot: boolean;
  /**
   * If true - then this message means last part of multi-parted snapshot.
   * NOTE: It can be also true in case of internal communication issues.
   * After reconnection WebAPI will send difference between the old snapshot and the actual snapshot.
   */
  isLastPart: boolean;
  tailManagementConfigurations: TailManagementConfiguration[];
}

export interface RequestTailManagementConfigurationMetadata {
  /** OTC instance id. */
  otcInstanceId: number;
}

/** Metadata to describe Tail Management Configuration. */
export interface TailManagementConfigurationMetadata {
  /** ATDL XML schema used to describe parameters metadata and layout. */
  metadata: string;
}

/** Describe Tail Management Configuration per Hedge Account Balance. */
export interface TailManagementConfiguration {
  /**
   * Hedge balance key.
   * Required, but account level default can be specified by omitting hedge_balance_keys.balance_contract_symbol
   */
  hedgeBalanceKeys: HedgeBalanceKey[];
  /** If set to true – OTC shall remove this configuration from cache. */
  removed: boolean;
  configurationEntries: ConfigurationEntry[];
}

/** Pair of configuration name with its value. */
export interface ConfigurationEntry {
  /** If set to true – OTC shall remove this parameter from cache and take default. */
  removed: boolean;
  name: string;
  value: string;
}

/** Reset order's custom basis request. */
export interface ResetCustomBasisRequest {
  /** OTC instance id. */
  otcInstanceId: number;
  /**
   * Order ID assigned by server to originally placed order.
   * See order_2.OrderStatus.chain_order_id.
   */
  chainOrderId: string;
  /** Account ID in CQG trade routing system associated with the order. */
  accountId: number;
  /** OTC contributor id involved in the order fill processing. */
  contributorId: string;
  /** Server side contract id of the contract for the order. */
  contractId: number;
  /** Optional custom basis for order in ticks. Reset to basis from contributor parameters if empty. */
  offset: number;
}

/** Reset order's custom basis result. */
export interface ResetCustomBasisResult {}

function createBaseOtcRequest(): OtcRequest {
  return {
    requestId: '',
    otcInstancesSubscription: undefined,
    hedgeBooksSubscription: undefined,
    hedgeBookDetailsSubscription: undefined,
    offsetHedgeBalanceRequest: undefined,
    changeOtcStateRequest: undefined,
    fillCashOrderRequest: undefined,
    archiveHedgeBalanceDetailsRequest: undefined,
    balanceItemsLinksRequest: undefined,
    tailManagementConfigurationSubscription: undefined,
    updateTailManagementConfiguration: undefined,
    requestTailManagementConfigurationMetadata: undefined,
    resetCustomBasisRequest: undefined,
  };
}

export const OtcRequest = {
  encode(message: OtcRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.otcInstancesSubscription !== undefined) {
      OtcInstancesSubscription.encode(message.otcInstancesSubscription, writer.uint32(18).fork()).ldelim();
    }
    if (message.hedgeBooksSubscription !== undefined) {
      HedgeBooksSubscription.encode(message.hedgeBooksSubscription, writer.uint32(26).fork()).ldelim();
    }
    if (message.hedgeBookDetailsSubscription !== undefined) {
      HedgeBookDetailsSubscription.encode(message.hedgeBookDetailsSubscription, writer.uint32(34).fork()).ldelim();
    }
    if (message.offsetHedgeBalanceRequest !== undefined) {
      OffsetHedgeBalanceRequest.encode(message.offsetHedgeBalanceRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.changeOtcStateRequest !== undefined) {
      ChangeOtcStateRequest.encode(message.changeOtcStateRequest, writer.uint32(50).fork()).ldelim();
    }
    if (message.fillCashOrderRequest !== undefined) {
      FillCashOrderRequest.encode(message.fillCashOrderRequest, writer.uint32(58).fork()).ldelim();
    }
    if (message.archiveHedgeBalanceDetailsRequest !== undefined) {
      ArchiveHedgeBalanceDetailsRequest.encode(message.archiveHedgeBalanceDetailsRequest, writer.uint32(66).fork()).ldelim();
    }
    if (message.balanceItemsLinksRequest !== undefined) {
      BalanceItemsLinksRequest.encode(message.balanceItemsLinksRequest, writer.uint32(74).fork()).ldelim();
    }
    if (message.tailManagementConfigurationSubscription !== undefined) {
      TailManagementConfigurationSubscription.encode(
        message.tailManagementConfigurationSubscription,
        writer.uint32(82).fork()
      ).ldelim();
    }
    if (message.updateTailManagementConfiguration !== undefined) {
      UpdateTailManagementConfiguration.encode(message.updateTailManagementConfiguration, writer.uint32(90).fork()).ldelim();
    }
    if (message.requestTailManagementConfigurationMetadata !== undefined) {
      RequestTailManagementConfigurationMetadata.encode(
        message.requestTailManagementConfigurationMetadata,
        writer.uint32(98).fork()
      ).ldelim();
    }
    if (message.resetCustomBasisRequest !== undefined) {
      ResetCustomBasisRequest.encode(message.resetCustomBasisRequest, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OtcRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOtcRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 2:
          message.otcInstancesSubscription = OtcInstancesSubscription.decode(reader, reader.uint32());
          break;
        case 3:
          message.hedgeBooksSubscription = HedgeBooksSubscription.decode(reader, reader.uint32());
          break;
        case 4:
          message.hedgeBookDetailsSubscription = HedgeBookDetailsSubscription.decode(reader, reader.uint32());
          break;
        case 5:
          message.offsetHedgeBalanceRequest = OffsetHedgeBalanceRequest.decode(reader, reader.uint32());
          break;
        case 6:
          message.changeOtcStateRequest = ChangeOtcStateRequest.decode(reader, reader.uint32());
          break;
        case 7:
          message.fillCashOrderRequest = FillCashOrderRequest.decode(reader, reader.uint32());
          break;
        case 8:
          message.archiveHedgeBalanceDetailsRequest = ArchiveHedgeBalanceDetailsRequest.decode(reader, reader.uint32());
          break;
        case 9:
          message.balanceItemsLinksRequest = BalanceItemsLinksRequest.decode(reader, reader.uint32());
          break;
        case 10:
          message.tailManagementConfigurationSubscription = TailManagementConfigurationSubscription.decode(
            reader,
            reader.uint32()
          );
          break;
        case 11:
          message.updateTailManagementConfiguration = UpdateTailManagementConfiguration.decode(reader, reader.uint32());
          break;
        case 12:
          message.requestTailManagementConfigurationMetadata = RequestTailManagementConfigurationMetadata.decode(
            reader,
            reader.uint32()
          );
          break;
        case 13:
          message.resetCustomBasisRequest = ResetCustomBasisRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OtcRequest {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      otcInstancesSubscription: isSet(object.otcInstancesSubscription)
        ? OtcInstancesSubscription.fromJSON(object.otcInstancesSubscription)
        : undefined,
      hedgeBooksSubscription: isSet(object.hedgeBooksSubscription)
        ? HedgeBooksSubscription.fromJSON(object.hedgeBooksSubscription)
        : undefined,
      hedgeBookDetailsSubscription: isSet(object.hedgeBookDetailsSubscription)
        ? HedgeBookDetailsSubscription.fromJSON(object.hedgeBookDetailsSubscription)
        : undefined,
      offsetHedgeBalanceRequest: isSet(object.offsetHedgeBalanceRequest)
        ? OffsetHedgeBalanceRequest.fromJSON(object.offsetHedgeBalanceRequest)
        : undefined,
      changeOtcStateRequest: isSet(object.changeOtcStateRequest)
        ? ChangeOtcStateRequest.fromJSON(object.changeOtcStateRequest)
        : undefined,
      fillCashOrderRequest: isSet(object.fillCashOrderRequest)
        ? FillCashOrderRequest.fromJSON(object.fillCashOrderRequest)
        : undefined,
      archiveHedgeBalanceDetailsRequest: isSet(object.archiveHedgeBalanceDetailsRequest)
        ? ArchiveHedgeBalanceDetailsRequest.fromJSON(object.archiveHedgeBalanceDetailsRequest)
        : undefined,
      balanceItemsLinksRequest: isSet(object.balanceItemsLinksRequest)
        ? BalanceItemsLinksRequest.fromJSON(object.balanceItemsLinksRequest)
        : undefined,
      tailManagementConfigurationSubscription: isSet(object.tailManagementConfigurationSubscription)
        ? TailManagementConfigurationSubscription.fromJSON(object.tailManagementConfigurationSubscription)
        : undefined,
      updateTailManagementConfiguration: isSet(object.updateTailManagementConfiguration)
        ? UpdateTailManagementConfiguration.fromJSON(object.updateTailManagementConfiguration)
        : undefined,
      requestTailManagementConfigurationMetadata: isSet(object.requestTailManagementConfigurationMetadata)
        ? RequestTailManagementConfigurationMetadata.fromJSON(object.requestTailManagementConfigurationMetadata)
        : undefined,
      resetCustomBasisRequest: isSet(object.resetCustomBasisRequest)
        ? ResetCustomBasisRequest.fromJSON(object.resetCustomBasisRequest)
        : undefined,
    };
  },

  toJSON(message: OtcRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.otcInstancesSubscription !== undefined &&
      (obj.otcInstancesSubscription = message.otcInstancesSubscription
        ? OtcInstancesSubscription.toJSON(message.otcInstancesSubscription)
        : undefined);
    message.hedgeBooksSubscription !== undefined &&
      (obj.hedgeBooksSubscription = message.hedgeBooksSubscription
        ? HedgeBooksSubscription.toJSON(message.hedgeBooksSubscription)
        : undefined);
    message.hedgeBookDetailsSubscription !== undefined &&
      (obj.hedgeBookDetailsSubscription = message.hedgeBookDetailsSubscription
        ? HedgeBookDetailsSubscription.toJSON(message.hedgeBookDetailsSubscription)
        : undefined);
    message.offsetHedgeBalanceRequest !== undefined &&
      (obj.offsetHedgeBalanceRequest = message.offsetHedgeBalanceRequest
        ? OffsetHedgeBalanceRequest.toJSON(message.offsetHedgeBalanceRequest)
        : undefined);
    message.changeOtcStateRequest !== undefined &&
      (obj.changeOtcStateRequest = message.changeOtcStateRequest
        ? ChangeOtcStateRequest.toJSON(message.changeOtcStateRequest)
        : undefined);
    message.fillCashOrderRequest !== undefined &&
      (obj.fillCashOrderRequest = message.fillCashOrderRequest
        ? FillCashOrderRequest.toJSON(message.fillCashOrderRequest)
        : undefined);
    message.archiveHedgeBalanceDetailsRequest !== undefined &&
      (obj.archiveHedgeBalanceDetailsRequest = message.archiveHedgeBalanceDetailsRequest
        ? ArchiveHedgeBalanceDetailsRequest.toJSON(message.archiveHedgeBalanceDetailsRequest)
        : undefined);
    message.balanceItemsLinksRequest !== undefined &&
      (obj.balanceItemsLinksRequest = message.balanceItemsLinksRequest
        ? BalanceItemsLinksRequest.toJSON(message.balanceItemsLinksRequest)
        : undefined);
    message.tailManagementConfigurationSubscription !== undefined &&
      (obj.tailManagementConfigurationSubscription = message.tailManagementConfigurationSubscription
        ? TailManagementConfigurationSubscription.toJSON(message.tailManagementConfigurationSubscription)
        : undefined);
    message.updateTailManagementConfiguration !== undefined &&
      (obj.updateTailManagementConfiguration = message.updateTailManagementConfiguration
        ? UpdateTailManagementConfiguration.toJSON(message.updateTailManagementConfiguration)
        : undefined);
    message.requestTailManagementConfigurationMetadata !== undefined &&
      (obj.requestTailManagementConfigurationMetadata = message.requestTailManagementConfigurationMetadata
        ? RequestTailManagementConfigurationMetadata.toJSON(message.requestTailManagementConfigurationMetadata)
        : undefined);
    message.resetCustomBasisRequest !== undefined &&
      (obj.resetCustomBasisRequest = message.resetCustomBasisRequest
        ? ResetCustomBasisRequest.toJSON(message.resetCustomBasisRequest)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OtcRequest>, I>>(base?: I): OtcRequest {
    return OtcRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OtcRequest>, I>>(object: I): OtcRequest {
    const message = createBaseOtcRequest();
    message.requestId = object.requestId ?? '';
    message.otcInstancesSubscription =
      object.otcInstancesSubscription !== undefined && object.otcInstancesSubscription !== null
        ? OtcInstancesSubscription.fromPartial(object.otcInstancesSubscription)
        : undefined;
    message.hedgeBooksSubscription =
      object.hedgeBooksSubscription !== undefined && object.hedgeBooksSubscription !== null
        ? HedgeBooksSubscription.fromPartial(object.hedgeBooksSubscription)
        : undefined;
    message.hedgeBookDetailsSubscription =
      object.hedgeBookDetailsSubscription !== undefined && object.hedgeBookDetailsSubscription !== null
        ? HedgeBookDetailsSubscription.fromPartial(object.hedgeBookDetailsSubscription)
        : undefined;
    message.offsetHedgeBalanceRequest =
      object.offsetHedgeBalanceRequest !== undefined && object.offsetHedgeBalanceRequest !== null
        ? OffsetHedgeBalanceRequest.fromPartial(object.offsetHedgeBalanceRequest)
        : undefined;
    message.changeOtcStateRequest =
      object.changeOtcStateRequest !== undefined && object.changeOtcStateRequest !== null
        ? ChangeOtcStateRequest.fromPartial(object.changeOtcStateRequest)
        : undefined;
    message.fillCashOrderRequest =
      object.fillCashOrderRequest !== undefined && object.fillCashOrderRequest !== null
        ? FillCashOrderRequest.fromPartial(object.fillCashOrderRequest)
        : undefined;
    message.archiveHedgeBalanceDetailsRequest =
      object.archiveHedgeBalanceDetailsRequest !== undefined && object.archiveHedgeBalanceDetailsRequest !== null
        ? ArchiveHedgeBalanceDetailsRequest.fromPartial(object.archiveHedgeBalanceDetailsRequest)
        : undefined;
    message.balanceItemsLinksRequest =
      object.balanceItemsLinksRequest !== undefined && object.balanceItemsLinksRequest !== null
        ? BalanceItemsLinksRequest.fromPartial(object.balanceItemsLinksRequest)
        : undefined;
    message.tailManagementConfigurationSubscription =
      object.tailManagementConfigurationSubscription !== undefined && object.tailManagementConfigurationSubscription !== null
        ? TailManagementConfigurationSubscription.fromPartial(object.tailManagementConfigurationSubscription)
        : undefined;
    message.updateTailManagementConfiguration =
      object.updateTailManagementConfiguration !== undefined && object.updateTailManagementConfiguration !== null
        ? UpdateTailManagementConfiguration.fromPartial(object.updateTailManagementConfiguration)
        : undefined;
    message.requestTailManagementConfigurationMetadata =
      object.requestTailManagementConfigurationMetadata !== undefined &&
      object.requestTailManagementConfigurationMetadata !== null
        ? RequestTailManagementConfigurationMetadata.fromPartial(object.requestTailManagementConfigurationMetadata)
        : undefined;
    message.resetCustomBasisRequest =
      object.resetCustomBasisRequest !== undefined && object.resetCustomBasisRequest !== null
        ? ResetCustomBasisRequest.fromPartial(object.resetCustomBasisRequest)
        : undefined;
    return message;
  },
};

function createBaseOtcReport(): OtcReport {
  return {
    requestId: '',
    statusCode: 0,
    details: undefined,
    otcInstancesReport: undefined,
    hedgeBooksReport: undefined,
    hedgeBookDetailsReport: undefined,
    offsetHedgeBalanceResult: undefined,
    changeOtcStateResult: undefined,
    fillCashOrderResult: undefined,
    archiveHedgeBalanceDetailsResult: undefined,
    balanceItemsLinksResult: undefined,
    tailManagementConfigurationReport: undefined,
    tailManagementConfigurationMetadata: undefined,
    updateTailManagementConfigurationResult: undefined,
    resetCustomBasisResult: undefined,
  };
}

export const OtcReport = {
  encode(message: OtcReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.statusCode !== 0) {
      writer.uint32(16).uint32(message.statusCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    if (message.otcInstancesReport !== undefined) {
      OtcInstancesReport.encode(message.otcInstancesReport, writer.uint32(34).fork()).ldelim();
    }
    if (message.hedgeBooksReport !== undefined) {
      HedgeBooksReport.encode(message.hedgeBooksReport, writer.uint32(42).fork()).ldelim();
    }
    if (message.hedgeBookDetailsReport !== undefined) {
      HedgeBookDetailsReport.encode(message.hedgeBookDetailsReport, writer.uint32(50).fork()).ldelim();
    }
    if (message.offsetHedgeBalanceResult !== undefined) {
      OffsetHedgeBalanceResult.encode(message.offsetHedgeBalanceResult, writer.uint32(58).fork()).ldelim();
    }
    if (message.changeOtcStateResult !== undefined) {
      ChangeOtcStateResult.encode(message.changeOtcStateResult, writer.uint32(66).fork()).ldelim();
    }
    if (message.fillCashOrderResult !== undefined) {
      FillCashOrderResult.encode(message.fillCashOrderResult, writer.uint32(74).fork()).ldelim();
    }
    if (message.archiveHedgeBalanceDetailsResult !== undefined) {
      ArchiveHedgeBalanceDetailsResult.encode(message.archiveHedgeBalanceDetailsResult, writer.uint32(82).fork()).ldelim();
    }
    if (message.balanceItemsLinksResult !== undefined) {
      BalanceItemsLinksResult.encode(message.balanceItemsLinksResult, writer.uint32(90).fork()).ldelim();
    }
    if (message.tailManagementConfigurationReport !== undefined) {
      TailManagementConfigurationReport.encode(message.tailManagementConfigurationReport, writer.uint32(98).fork()).ldelim();
    }
    if (message.tailManagementConfigurationMetadata !== undefined) {
      TailManagementConfigurationMetadata.encode(message.tailManagementConfigurationMetadata, writer.uint32(106).fork()).ldelim();
    }
    if (message.updateTailManagementConfigurationResult !== undefined) {
      UpdateTailManagementConfigurationResult.encode(
        message.updateTailManagementConfigurationResult,
        writer.uint32(114).fork()
      ).ldelim();
    }
    if (message.resetCustomBasisResult !== undefined) {
      ResetCustomBasisResult.encode(message.resetCustomBasisResult, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OtcReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOtcReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 2:
          message.statusCode = reader.uint32();
          break;
        case 3:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.otcInstancesReport = OtcInstancesReport.decode(reader, reader.uint32());
          break;
        case 5:
          message.hedgeBooksReport = HedgeBooksReport.decode(reader, reader.uint32());
          break;
        case 6:
          message.hedgeBookDetailsReport = HedgeBookDetailsReport.decode(reader, reader.uint32());
          break;
        case 7:
          message.offsetHedgeBalanceResult = OffsetHedgeBalanceResult.decode(reader, reader.uint32());
          break;
        case 8:
          message.changeOtcStateResult = ChangeOtcStateResult.decode(reader, reader.uint32());
          break;
        case 9:
          message.fillCashOrderResult = FillCashOrderResult.decode(reader, reader.uint32());
          break;
        case 10:
          message.archiveHedgeBalanceDetailsResult = ArchiveHedgeBalanceDetailsResult.decode(reader, reader.uint32());
          break;
        case 11:
          message.balanceItemsLinksResult = BalanceItemsLinksResult.decode(reader, reader.uint32());
          break;
        case 12:
          message.tailManagementConfigurationReport = TailManagementConfigurationReport.decode(reader, reader.uint32());
          break;
        case 13:
          message.tailManagementConfigurationMetadata = TailManagementConfigurationMetadata.decode(reader, reader.uint32());
          break;
        case 14:
          message.updateTailManagementConfigurationResult = UpdateTailManagementConfigurationResult.decode(
            reader,
            reader.uint32()
          );
          break;
        case 15:
          message.resetCustomBasisResult = ResetCustomBasisResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OtcReport {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      otcInstancesReport: isSet(object.otcInstancesReport) ? OtcInstancesReport.fromJSON(object.otcInstancesReport) : undefined,
      hedgeBooksReport: isSet(object.hedgeBooksReport) ? HedgeBooksReport.fromJSON(object.hedgeBooksReport) : undefined,
      hedgeBookDetailsReport: isSet(object.hedgeBookDetailsReport)
        ? HedgeBookDetailsReport.fromJSON(object.hedgeBookDetailsReport)
        : undefined,
      offsetHedgeBalanceResult: isSet(object.offsetHedgeBalanceResult)
        ? OffsetHedgeBalanceResult.fromJSON(object.offsetHedgeBalanceResult)
        : undefined,
      changeOtcStateResult: isSet(object.changeOtcStateResult)
        ? ChangeOtcStateResult.fromJSON(object.changeOtcStateResult)
        : undefined,
      fillCashOrderResult: isSet(object.fillCashOrderResult)
        ? FillCashOrderResult.fromJSON(object.fillCashOrderResult)
        : undefined,
      archiveHedgeBalanceDetailsResult: isSet(object.archiveHedgeBalanceDetailsResult)
        ? ArchiveHedgeBalanceDetailsResult.fromJSON(object.archiveHedgeBalanceDetailsResult)
        : undefined,
      balanceItemsLinksResult: isSet(object.balanceItemsLinksResult)
        ? BalanceItemsLinksResult.fromJSON(object.balanceItemsLinksResult)
        : undefined,
      tailManagementConfigurationReport: isSet(object.tailManagementConfigurationReport)
        ? TailManagementConfigurationReport.fromJSON(object.tailManagementConfigurationReport)
        : undefined,
      tailManagementConfigurationMetadata: isSet(object.tailManagementConfigurationMetadata)
        ? TailManagementConfigurationMetadata.fromJSON(object.tailManagementConfigurationMetadata)
        : undefined,
      updateTailManagementConfigurationResult: isSet(object.updateTailManagementConfigurationResult)
        ? UpdateTailManagementConfigurationResult.fromJSON(object.updateTailManagementConfigurationResult)
        : undefined,
      resetCustomBasisResult: isSet(object.resetCustomBasisResult)
        ? ResetCustomBasisResult.fromJSON(object.resetCustomBasisResult)
        : undefined,
    };
  },

  toJSON(message: OtcReport): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.otcInstancesReport !== undefined &&
      (obj.otcInstancesReport = message.otcInstancesReport ? OtcInstancesReport.toJSON(message.otcInstancesReport) : undefined);
    message.hedgeBooksReport !== undefined &&
      (obj.hedgeBooksReport = message.hedgeBooksReport ? HedgeBooksReport.toJSON(message.hedgeBooksReport) : undefined);
    message.hedgeBookDetailsReport !== undefined &&
      (obj.hedgeBookDetailsReport = message.hedgeBookDetailsReport
        ? HedgeBookDetailsReport.toJSON(message.hedgeBookDetailsReport)
        : undefined);
    message.offsetHedgeBalanceResult !== undefined &&
      (obj.offsetHedgeBalanceResult = message.offsetHedgeBalanceResult
        ? OffsetHedgeBalanceResult.toJSON(message.offsetHedgeBalanceResult)
        : undefined);
    message.changeOtcStateResult !== undefined &&
      (obj.changeOtcStateResult = message.changeOtcStateResult
        ? ChangeOtcStateResult.toJSON(message.changeOtcStateResult)
        : undefined);
    message.fillCashOrderResult !== undefined &&
      (obj.fillCashOrderResult = message.fillCashOrderResult
        ? FillCashOrderResult.toJSON(message.fillCashOrderResult)
        : undefined);
    message.archiveHedgeBalanceDetailsResult !== undefined &&
      (obj.archiveHedgeBalanceDetailsResult = message.archiveHedgeBalanceDetailsResult
        ? ArchiveHedgeBalanceDetailsResult.toJSON(message.archiveHedgeBalanceDetailsResult)
        : undefined);
    message.balanceItemsLinksResult !== undefined &&
      (obj.balanceItemsLinksResult = message.balanceItemsLinksResult
        ? BalanceItemsLinksResult.toJSON(message.balanceItemsLinksResult)
        : undefined);
    message.tailManagementConfigurationReport !== undefined &&
      (obj.tailManagementConfigurationReport = message.tailManagementConfigurationReport
        ? TailManagementConfigurationReport.toJSON(message.tailManagementConfigurationReport)
        : undefined);
    message.tailManagementConfigurationMetadata !== undefined &&
      (obj.tailManagementConfigurationMetadata = message.tailManagementConfigurationMetadata
        ? TailManagementConfigurationMetadata.toJSON(message.tailManagementConfigurationMetadata)
        : undefined);
    message.updateTailManagementConfigurationResult !== undefined &&
      (obj.updateTailManagementConfigurationResult = message.updateTailManagementConfigurationResult
        ? UpdateTailManagementConfigurationResult.toJSON(message.updateTailManagementConfigurationResult)
        : undefined);
    message.resetCustomBasisResult !== undefined &&
      (obj.resetCustomBasisResult = message.resetCustomBasisResult
        ? ResetCustomBasisResult.toJSON(message.resetCustomBasisResult)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OtcReport>, I>>(base?: I): OtcReport {
    return OtcReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OtcReport>, I>>(object: I): OtcReport {
    const message = createBaseOtcReport();
    message.requestId = object.requestId ?? '';
    message.statusCode = object.statusCode ?? 0;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.otcInstancesReport =
      object.otcInstancesReport !== undefined && object.otcInstancesReport !== null
        ? OtcInstancesReport.fromPartial(object.otcInstancesReport)
        : undefined;
    message.hedgeBooksReport =
      object.hedgeBooksReport !== undefined && object.hedgeBooksReport !== null
        ? HedgeBooksReport.fromPartial(object.hedgeBooksReport)
        : undefined;
    message.hedgeBookDetailsReport =
      object.hedgeBookDetailsReport !== undefined && object.hedgeBookDetailsReport !== null
        ? HedgeBookDetailsReport.fromPartial(object.hedgeBookDetailsReport)
        : undefined;
    message.offsetHedgeBalanceResult =
      object.offsetHedgeBalanceResult !== undefined && object.offsetHedgeBalanceResult !== null
        ? OffsetHedgeBalanceResult.fromPartial(object.offsetHedgeBalanceResult)
        : undefined;
    message.changeOtcStateResult =
      object.changeOtcStateResult !== undefined && object.changeOtcStateResult !== null
        ? ChangeOtcStateResult.fromPartial(object.changeOtcStateResult)
        : undefined;
    message.fillCashOrderResult =
      object.fillCashOrderResult !== undefined && object.fillCashOrderResult !== null
        ? FillCashOrderResult.fromPartial(object.fillCashOrderResult)
        : undefined;
    message.archiveHedgeBalanceDetailsResult =
      object.archiveHedgeBalanceDetailsResult !== undefined && object.archiveHedgeBalanceDetailsResult !== null
        ? ArchiveHedgeBalanceDetailsResult.fromPartial(object.archiveHedgeBalanceDetailsResult)
        : undefined;
    message.balanceItemsLinksResult =
      object.balanceItemsLinksResult !== undefined && object.balanceItemsLinksResult !== null
        ? BalanceItemsLinksResult.fromPartial(object.balanceItemsLinksResult)
        : undefined;
    message.tailManagementConfigurationReport =
      object.tailManagementConfigurationReport !== undefined && object.tailManagementConfigurationReport !== null
        ? TailManagementConfigurationReport.fromPartial(object.tailManagementConfigurationReport)
        : undefined;
    message.tailManagementConfigurationMetadata =
      object.tailManagementConfigurationMetadata !== undefined && object.tailManagementConfigurationMetadata !== null
        ? TailManagementConfigurationMetadata.fromPartial(object.tailManagementConfigurationMetadata)
        : undefined;
    message.updateTailManagementConfigurationResult =
      object.updateTailManagementConfigurationResult !== undefined && object.updateTailManagementConfigurationResult !== null
        ? UpdateTailManagementConfigurationResult.fromPartial(object.updateTailManagementConfigurationResult)
        : undefined;
    message.resetCustomBasisResult =
      object.resetCustomBasisResult !== undefined && object.resetCustomBasisResult !== null
        ? ResetCustomBasisResult.fromPartial(object.resetCustomBasisResult)
        : undefined;
    return message;
  },
};

function createBaseHedgeBalanceKey(): HedgeBalanceKey {
  return { accountId: 0, contractId: 0 };
}

export const HedgeBalanceKey = {
  encode(message: HedgeBalanceKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(16).uint32(message.contractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBalanceKey {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBalanceKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.accountId = reader.sint32();
          break;
        case 2:
          message.contractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBalanceKey {
    return {
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
    };
  },

  toJSON(message: HedgeBalanceKey): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBalanceKey>, I>>(base?: I): HedgeBalanceKey {
    return HedgeBalanceKey.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBalanceKey>, I>>(object: I): HedgeBalanceKey {
    const message = createBaseHedgeBalanceKey();
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    return message;
  },
};

function createBaseOtcInstancesSubscription(): OtcInstancesSubscription {
  return { subscribe: false };
}

export const OtcInstancesSubscription = {
  encode(message: OtcInstancesSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscribe === true) {
      writer.uint32(8).bool(message.subscribe);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OtcInstancesSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOtcInstancesSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.subscribe = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OtcInstancesSubscription {
    return { subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false };
  },

  toJSON(message: OtcInstancesSubscription): unknown {
    const obj: any = {};
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    return obj;
  },

  create<I extends Exact<DeepPartial<OtcInstancesSubscription>, I>>(base?: I): OtcInstancesSubscription {
    return OtcInstancesSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OtcInstancesSubscription>, I>>(object: I): OtcInstancesSubscription {
    const message = createBaseOtcInstancesSubscription();
    message.subscribe = object.subscribe ?? false;
    return message;
  },
};

function createBaseOtcInstancesReport(): OtcInstancesReport {
  return { otcInstanceStates: [] };
}

export const OtcInstancesReport = {
  encode(message: OtcInstancesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.otcInstanceStates) {
      OtcInstanceState.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OtcInstancesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOtcInstancesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceStates.push(OtcInstanceState.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OtcInstancesReport {
    return {
      otcInstanceStates: Array.isArray(object?.otcInstanceStates)
        ? object.otcInstanceStates.map((e: any) => OtcInstanceState.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OtcInstancesReport): unknown {
    const obj: any = {};
    if (message.otcInstanceStates) {
      obj.otcInstanceStates = message.otcInstanceStates.map((e) => (e ? OtcInstanceState.toJSON(e) : undefined));
    } else {
      obj.otcInstanceStates = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OtcInstancesReport>, I>>(base?: I): OtcInstancesReport {
    return OtcInstancesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OtcInstancesReport>, I>>(object: I): OtcInstancesReport {
    const message = createBaseOtcInstancesReport();
    message.otcInstanceStates = object.otcInstanceStates?.map((e) => OtcInstanceState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOtcInstanceState(): OtcInstanceState {
  return { instanceId: 0, name: '', state: 0 };
}

export const OtcInstanceState = {
  encode(message: OtcInstanceState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instanceId !== 0) {
      writer.uint32(8).uint32(message.instanceId);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.state !== 0) {
      writer.uint32(24).uint32(message.state);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OtcInstanceState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOtcInstanceState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instanceId = reader.uint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.state = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OtcInstanceState {
    return {
      instanceId: isSet(object.instanceId) ? Number(object.instanceId) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      state: isSet(object.state) ? Number(object.state) : 0,
    };
  },

  toJSON(message: OtcInstanceState): unknown {
    const obj: any = {};
    message.instanceId !== undefined && (obj.instanceId = Math.round(message.instanceId));
    message.name !== undefined && (obj.name = message.name);
    message.state !== undefined && (obj.state = Math.round(message.state));
    return obj;
  },

  create<I extends Exact<DeepPartial<OtcInstanceState>, I>>(base?: I): OtcInstanceState {
    return OtcInstanceState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OtcInstanceState>, I>>(object: I): OtcInstanceState {
    const message = createBaseOtcInstanceState();
    message.instanceId = object.instanceId ?? 0;
    message.name = object.name ?? '';
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseHedgeBooksSubscription(): HedgeBooksSubscription {
  return { subscribe: false, otcInstanceId: 0 };
}

export const HedgeBooksSubscription = {
  encode(message: HedgeBooksSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscribe === true) {
      writer.uint32(8).bool(message.subscribe);
    }
    if (message.otcInstanceId !== 0) {
      writer.uint32(16).uint32(message.otcInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBooksSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBooksSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.subscribe = reader.bool();
          break;
        case 2:
          message.otcInstanceId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBooksSubscription {
    return {
      subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false,
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
    };
  },

  toJSON(message: HedgeBooksSubscription): unknown {
    const obj: any = {};
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBooksSubscription>, I>>(base?: I): HedgeBooksSubscription {
    return HedgeBooksSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBooksSubscription>, I>>(object: I): HedgeBooksSubscription {
    const message = createBaseHedgeBooksSubscription();
    message.subscribe = object.subscribe ?? false;
    message.otcInstanceId = object.otcInstanceId ?? 0;
    return message;
  },
};

function createBaseHedgeBooksReport(): HedgeBooksReport {
  return { isSnapshot: false, isLastPart: false, hedgeBalanceDetails: [], archivedHedgeBalanceDetails: [] };
}

export const HedgeBooksReport = {
  encode(message: HedgeBooksReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSnapshot === true) {
      writer.uint32(8).bool(message.isSnapshot);
    }
    if (message.isLastPart === true) {
      writer.uint32(16).bool(message.isLastPart);
    }
    for (const v of message.hedgeBalanceDetails) {
      HedgeBalanceDetails.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.archivedHedgeBalanceDetails) {
      ArchivedHedgeBalanceDetails.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBooksReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBooksReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isSnapshot = reader.bool();
          break;
        case 2:
          message.isLastPart = reader.bool();
          break;
        case 3:
          message.hedgeBalanceDetails.push(HedgeBalanceDetails.decode(reader, reader.uint32()));
          break;
        case 4:
          message.archivedHedgeBalanceDetails.push(ArchivedHedgeBalanceDetails.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBooksReport {
    return {
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      isLastPart: isSet(object.isLastPart) ? Boolean(object.isLastPart) : false,
      hedgeBalanceDetails: Array.isArray(object?.hedgeBalanceDetails)
        ? object.hedgeBalanceDetails.map((e: any) => HedgeBalanceDetails.fromJSON(e))
        : [],
      archivedHedgeBalanceDetails: Array.isArray(object?.archivedHedgeBalanceDetails)
        ? object.archivedHedgeBalanceDetails.map((e: any) => ArchivedHedgeBalanceDetails.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HedgeBooksReport): unknown {
    const obj: any = {};
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    message.isLastPart !== undefined && (obj.isLastPart = message.isLastPart);
    if (message.hedgeBalanceDetails) {
      obj.hedgeBalanceDetails = message.hedgeBalanceDetails.map((e) => (e ? HedgeBalanceDetails.toJSON(e) : undefined));
    } else {
      obj.hedgeBalanceDetails = [];
    }
    if (message.archivedHedgeBalanceDetails) {
      obj.archivedHedgeBalanceDetails = message.archivedHedgeBalanceDetails.map((e) =>
        e ? ArchivedHedgeBalanceDetails.toJSON(e) : undefined
      );
    } else {
      obj.archivedHedgeBalanceDetails = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBooksReport>, I>>(base?: I): HedgeBooksReport {
    return HedgeBooksReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBooksReport>, I>>(object: I): HedgeBooksReport {
    const message = createBaseHedgeBooksReport();
    message.isSnapshot = object.isSnapshot ?? false;
    message.isLastPart = object.isLastPart ?? false;
    message.hedgeBalanceDetails = object.hedgeBalanceDetails?.map((e) => HedgeBalanceDetails.fromPartial(e)) || [];
    message.archivedHedgeBalanceDetails =
      object.archivedHedgeBalanceDetails?.map((e) => ArchivedHedgeBalanceDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHedgeBalanceDetails(): HedgeBalanceDetails {
  return {
    hedgeBalanceKey: undefined,
    deleted: false,
    hedgeBalance: undefined,
    pendingBalance: undefined,
    contractSymbol: '',
    unitsName: undefined,
  };
}

export const HedgeBalanceDetails = {
  encode(message: HedgeBalanceDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hedgeBalanceKey !== undefined) {
      HedgeBalanceKey.encode(message.hedgeBalanceKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    if (message.hedgeBalance !== undefined) {
      Decimal.encode(message.hedgeBalance, writer.uint32(26).fork()).ldelim();
    }
    if (message.pendingBalance !== undefined) {
      Decimal.encode(message.pendingBalance, writer.uint32(34).fork()).ldelim();
    }
    if (message.contractSymbol !== '') {
      writer.uint32(42).string(message.contractSymbol);
    }
    if (message.unitsName !== undefined) {
      Text.encode(message.unitsName, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBalanceDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBalanceDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hedgeBalanceKey = HedgeBalanceKey.decode(reader, reader.uint32());
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        case 3:
          message.hedgeBalance = Decimal.decode(reader, reader.uint32());
          break;
        case 4:
          message.pendingBalance = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.contractSymbol = reader.string();
          break;
        case 6:
          message.unitsName = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBalanceDetails {
    return {
      hedgeBalanceKey: isSet(object.hedgeBalanceKey) ? HedgeBalanceKey.fromJSON(object.hedgeBalanceKey) : undefined,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      hedgeBalance: isSet(object.hedgeBalance) ? Decimal.fromJSON(object.hedgeBalance) : undefined,
      pendingBalance: isSet(object.pendingBalance) ? Decimal.fromJSON(object.pendingBalance) : undefined,
      contractSymbol: isSet(object.contractSymbol) ? String(object.contractSymbol) : '',
      unitsName: isSet(object.unitsName) ? Text.fromJSON(object.unitsName) : undefined,
    };
  },

  toJSON(message: HedgeBalanceDetails): unknown {
    const obj: any = {};
    message.hedgeBalanceKey !== undefined &&
      (obj.hedgeBalanceKey = message.hedgeBalanceKey ? HedgeBalanceKey.toJSON(message.hedgeBalanceKey) : undefined);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.hedgeBalance !== undefined &&
      (obj.hedgeBalance = message.hedgeBalance ? Decimal.toJSON(message.hedgeBalance) : undefined);
    message.pendingBalance !== undefined &&
      (obj.pendingBalance = message.pendingBalance ? Decimal.toJSON(message.pendingBalance) : undefined);
    message.contractSymbol !== undefined && (obj.contractSymbol = message.contractSymbol);
    message.unitsName !== undefined && (obj.unitsName = message.unitsName ? Text.toJSON(message.unitsName) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBalanceDetails>, I>>(base?: I): HedgeBalanceDetails {
    return HedgeBalanceDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBalanceDetails>, I>>(object: I): HedgeBalanceDetails {
    const message = createBaseHedgeBalanceDetails();
    message.hedgeBalanceKey =
      object.hedgeBalanceKey !== undefined && object.hedgeBalanceKey !== null
        ? HedgeBalanceKey.fromPartial(object.hedgeBalanceKey)
        : undefined;
    message.deleted = object.deleted ?? false;
    message.hedgeBalance =
      object.hedgeBalance !== undefined && object.hedgeBalance !== null ? Decimal.fromPartial(object.hedgeBalance) : undefined;
    message.pendingBalance =
      object.pendingBalance !== undefined && object.pendingBalance !== null
        ? Decimal.fromPartial(object.pendingBalance)
        : undefined;
    message.contractSymbol = object.contractSymbol ?? '';
    message.unitsName =
      object.unitsName !== undefined && object.unitsName !== null ? Text.fromPartial(object.unitsName) : undefined;
    return message;
  },
};

function createBaseHedgeBookDetailsSubscription(): HedgeBookDetailsSubscription {
  return { subscribe: false, otcInstanceId: 0, hedgeBalanceKey: undefined, fromUtcTimestamp: undefined, archiveId: '' };
}

export const HedgeBookDetailsSubscription = {
  encode(message: HedgeBookDetailsSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscribe === true) {
      writer.uint32(8).bool(message.subscribe);
    }
    if (message.otcInstanceId !== 0) {
      writer.uint32(16).uint32(message.otcInstanceId);
    }
    if (message.hedgeBalanceKey !== undefined) {
      HedgeBalanceKey.encode(message.hedgeBalanceKey, writer.uint32(26).fork()).ldelim();
    }
    if (message.fromUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.fromUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    if (message.archiveId !== '') {
      writer.uint32(42).string(message.archiveId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBookDetailsSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBookDetailsSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.subscribe = reader.bool();
          break;
        case 2:
          message.otcInstanceId = reader.uint32();
          break;
        case 3:
          message.hedgeBalanceKey = HedgeBalanceKey.decode(reader, reader.uint32());
          break;
        case 4:
          message.fromUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 5:
          message.archiveId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBookDetailsSubscription {
    return {
      subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false,
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      hedgeBalanceKey: isSet(object.hedgeBalanceKey) ? HedgeBalanceKey.fromJSON(object.hedgeBalanceKey) : undefined,
      fromUtcTimestamp: isSet(object.fromUtcTimestamp) ? fromJsonTimestamp(object.fromUtcTimestamp) : undefined,
      archiveId: isSet(object.archiveId) ? String(object.archiveId) : '',
    };
  },

  toJSON(message: HedgeBookDetailsSubscription): unknown {
    const obj: any = {};
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.hedgeBalanceKey !== undefined &&
      (obj.hedgeBalanceKey = message.hedgeBalanceKey ? HedgeBalanceKey.toJSON(message.hedgeBalanceKey) : undefined);
    message.fromUtcTimestamp !== undefined && (obj.fromUtcTimestamp = message.fromUtcTimestamp.toISOString());
    message.archiveId !== undefined && (obj.archiveId = message.archiveId);
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBookDetailsSubscription>, I>>(base?: I): HedgeBookDetailsSubscription {
    return HedgeBookDetailsSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBookDetailsSubscription>, I>>(object: I): HedgeBookDetailsSubscription {
    const message = createBaseHedgeBookDetailsSubscription();
    message.subscribe = object.subscribe ?? false;
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.hedgeBalanceKey =
      object.hedgeBalanceKey !== undefined && object.hedgeBalanceKey !== null
        ? HedgeBalanceKey.fromPartial(object.hedgeBalanceKey)
        : undefined;
    message.fromUtcTimestamp = object.fromUtcTimestamp ?? undefined;
    message.archiveId = object.archiveId ?? '';
    return message;
  },
};

function createBaseHedgeBookDetailsReport(): HedgeBookDetailsReport {
  return {
    isSnapshot: false,
    isLastPart: false,
    cashOrderDetails: [],
    hedgeOrderDetails: [],
    cashToHedgeOrdersLinks: [],
    balanceItems: [],
    itemsLinks: [],
  };
}

export const HedgeBookDetailsReport = {
  encode(message: HedgeBookDetailsReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSnapshot === true) {
      writer.uint32(8).bool(message.isSnapshot);
    }
    if (message.isLastPart === true) {
      writer.uint32(16).bool(message.isLastPart);
    }
    for (const v of message.cashOrderDetails) {
      CashOrderDetails.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.hedgeOrderDetails) {
      HedgeOrderDetails.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.cashToHedgeOrdersLinks) {
      CashToHedgeOrdersLink.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.balanceItems) {
      BalanceItem.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.itemsLinks) {
      BalanceItemsLink.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeBookDetailsReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeBookDetailsReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isSnapshot = reader.bool();
          break;
        case 2:
          message.isLastPart = reader.bool();
          break;
        case 3:
          message.cashOrderDetails.push(CashOrderDetails.decode(reader, reader.uint32()));
          break;
        case 4:
          message.hedgeOrderDetails.push(HedgeOrderDetails.decode(reader, reader.uint32()));
          break;
        case 5:
          message.cashToHedgeOrdersLinks.push(CashToHedgeOrdersLink.decode(reader, reader.uint32()));
          break;
        case 6:
          message.balanceItems.push(BalanceItem.decode(reader, reader.uint32()));
          break;
        case 7:
          message.itemsLinks.push(BalanceItemsLink.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeBookDetailsReport {
    return {
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      isLastPart: isSet(object.isLastPart) ? Boolean(object.isLastPart) : false,
      cashOrderDetails: Array.isArray(object?.cashOrderDetails)
        ? object.cashOrderDetails.map((e: any) => CashOrderDetails.fromJSON(e))
        : [],
      hedgeOrderDetails: Array.isArray(object?.hedgeOrderDetails)
        ? object.hedgeOrderDetails.map((e: any) => HedgeOrderDetails.fromJSON(e))
        : [],
      cashToHedgeOrdersLinks: Array.isArray(object?.cashToHedgeOrdersLinks)
        ? object.cashToHedgeOrdersLinks.map((e: any) => CashToHedgeOrdersLink.fromJSON(e))
        : [],
      balanceItems: Array.isArray(object?.balanceItems) ? object.balanceItems.map((e: any) => BalanceItem.fromJSON(e)) : [],
      itemsLinks: Array.isArray(object?.itemsLinks) ? object.itemsLinks.map((e: any) => BalanceItemsLink.fromJSON(e)) : [],
    };
  },

  toJSON(message: HedgeBookDetailsReport): unknown {
    const obj: any = {};
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    message.isLastPart !== undefined && (obj.isLastPart = message.isLastPart);
    if (message.cashOrderDetails) {
      obj.cashOrderDetails = message.cashOrderDetails.map((e) => (e ? CashOrderDetails.toJSON(e) : undefined));
    } else {
      obj.cashOrderDetails = [];
    }
    if (message.hedgeOrderDetails) {
      obj.hedgeOrderDetails = message.hedgeOrderDetails.map((e) => (e ? HedgeOrderDetails.toJSON(e) : undefined));
    } else {
      obj.hedgeOrderDetails = [];
    }
    if (message.cashToHedgeOrdersLinks) {
      obj.cashToHedgeOrdersLinks = message.cashToHedgeOrdersLinks.map((e) => (e ? CashToHedgeOrdersLink.toJSON(e) : undefined));
    } else {
      obj.cashToHedgeOrdersLinks = [];
    }
    if (message.balanceItems) {
      obj.balanceItems = message.balanceItems.map((e) => (e ? BalanceItem.toJSON(e) : undefined));
    } else {
      obj.balanceItems = [];
    }
    if (message.itemsLinks) {
      obj.itemsLinks = message.itemsLinks.map((e) => (e ? BalanceItemsLink.toJSON(e) : undefined));
    } else {
      obj.itemsLinks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeBookDetailsReport>, I>>(base?: I): HedgeBookDetailsReport {
    return HedgeBookDetailsReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeBookDetailsReport>, I>>(object: I): HedgeBookDetailsReport {
    const message = createBaseHedgeBookDetailsReport();
    message.isSnapshot = object.isSnapshot ?? false;
    message.isLastPart = object.isLastPart ?? false;
    message.cashOrderDetails = object.cashOrderDetails?.map((e) => CashOrderDetails.fromPartial(e)) || [];
    message.hedgeOrderDetails = object.hedgeOrderDetails?.map((e) => HedgeOrderDetails.fromPartial(e)) || [];
    message.cashToHedgeOrdersLinks = object.cashToHedgeOrdersLinks?.map((e) => CashToHedgeOrdersLink.fromPartial(e)) || [];
    message.balanceItems = object.balanceItems?.map((e) => BalanceItem.fromPartial(e)) || [];
    message.itemsLinks = object.itemsLinks?.map((e) => BalanceItemsLink.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCashOrderDetails(): CashOrderDetails {
  return {
    chainOrderId: '',
    deleted: false,
    accountId: 0,
    contractId: 0,
    hedgeUtcTimestamp: undefined,
    units: undefined,
    unitsName: undefined,
  };
}

export const CashOrderDetails = {
  encode(message: CashOrderDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== '') {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    if (message.hedgeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.hedgeUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    if (message.units !== undefined) {
      Decimal.encode(message.units, writer.uint32(50).fork()).ldelim();
    }
    if (message.unitsName !== undefined) {
      Text.encode(message.unitsName, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CashOrderDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCashOrderDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainOrderId = reader.string();
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        case 3:
          message.accountId = reader.sint32();
          break;
        case 4:
          message.contractId = reader.uint32();
          break;
        case 5:
          message.hedgeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.units = Decimal.decode(reader, reader.uint32());
          break;
        case 7:
          message.unitsName = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CashOrderDetails {
    return {
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      hedgeUtcTimestamp: isSet(object.hedgeUtcTimestamp) ? fromJsonTimestamp(object.hedgeUtcTimestamp) : undefined,
      units: isSet(object.units) ? Decimal.fromJSON(object.units) : undefined,
      unitsName: isSet(object.unitsName) ? Text.fromJSON(object.unitsName) : undefined,
    };
  },

  toJSON(message: CashOrderDetails): unknown {
    const obj: any = {};
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.hedgeUtcTimestamp !== undefined && (obj.hedgeUtcTimestamp = message.hedgeUtcTimestamp.toISOString());
    message.units !== undefined && (obj.units = message.units ? Decimal.toJSON(message.units) : undefined);
    message.unitsName !== undefined && (obj.unitsName = message.unitsName ? Text.toJSON(message.unitsName) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CashOrderDetails>, I>>(base?: I): CashOrderDetails {
    return CashOrderDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CashOrderDetails>, I>>(object: I): CashOrderDetails {
    const message = createBaseCashOrderDetails();
    message.chainOrderId = object.chainOrderId ?? '';
    message.deleted = object.deleted ?? false;
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.hedgeUtcTimestamp = object.hedgeUtcTimestamp ?? undefined;
    message.units = object.units !== undefined && object.units !== null ? Decimal.fromPartial(object.units) : undefined;
    message.unitsName =
      object.unitsName !== undefined && object.unitsName !== null ? Text.fromPartial(object.unitsName) : undefined;
    return message;
  },
};

function createBaseHedgeOrderDetails(): HedgeOrderDetails {
  return {
    chainOrderId: '',
    deleted: false,
    hedgeUtcTimestamp: undefined,
    units: undefined,
    openUnits: undefined,
    unitsName: undefined,
  };
}

export const HedgeOrderDetails = {
  encode(message: HedgeOrderDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== '') {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    if (message.hedgeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.hedgeUtcTimestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.units !== undefined) {
      Decimal.encode(message.units, writer.uint32(34).fork()).ldelim();
    }
    if (message.openUnits !== undefined) {
      Decimal.encode(message.openUnits, writer.uint32(42).fork()).ldelim();
    }
    if (message.unitsName !== undefined) {
      Text.encode(message.unitsName, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HedgeOrderDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHedgeOrderDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainOrderId = reader.string();
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        case 3:
          message.hedgeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.units = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.openUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.unitsName = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HedgeOrderDetails {
    return {
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      hedgeUtcTimestamp: isSet(object.hedgeUtcTimestamp) ? fromJsonTimestamp(object.hedgeUtcTimestamp) : undefined,
      units: isSet(object.units) ? Decimal.fromJSON(object.units) : undefined,
      openUnits: isSet(object.openUnits) ? Decimal.fromJSON(object.openUnits) : undefined,
      unitsName: isSet(object.unitsName) ? Text.fromJSON(object.unitsName) : undefined,
    };
  },

  toJSON(message: HedgeOrderDetails): unknown {
    const obj: any = {};
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.hedgeUtcTimestamp !== undefined && (obj.hedgeUtcTimestamp = message.hedgeUtcTimestamp.toISOString());
    message.units !== undefined && (obj.units = message.units ? Decimal.toJSON(message.units) : undefined);
    message.openUnits !== undefined && (obj.openUnits = message.openUnits ? Decimal.toJSON(message.openUnits) : undefined);
    message.unitsName !== undefined && (obj.unitsName = message.unitsName ? Text.toJSON(message.unitsName) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<HedgeOrderDetails>, I>>(base?: I): HedgeOrderDetails {
    return HedgeOrderDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HedgeOrderDetails>, I>>(object: I): HedgeOrderDetails {
    const message = createBaseHedgeOrderDetails();
    message.chainOrderId = object.chainOrderId ?? '';
    message.deleted = object.deleted ?? false;
    message.hedgeUtcTimestamp = object.hedgeUtcTimestamp ?? undefined;
    message.units = object.units !== undefined && object.units !== null ? Decimal.fromPartial(object.units) : undefined;
    message.openUnits =
      object.openUnits !== undefined && object.openUnits !== null ? Decimal.fromPartial(object.openUnits) : undefined;
    message.unitsName =
      object.unitsName !== undefined && object.unitsName !== null ? Text.fromPartial(object.unitsName) : undefined;
    return message;
  },
};

function createBaseCashToHedgeOrdersLink(): CashToHedgeOrdersLink {
  return {
    deleted: false,
    linkUtcTimestamp: undefined,
    cashOrderId: '',
    hedgeOrderId: '',
    linkUnits: undefined,
    unitsName: undefined,
  };
}

export const CashToHedgeOrdersLink = {
  encode(message: CashToHedgeOrdersLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deleted === true) {
      writer.uint32(8).bool(message.deleted);
    }
    if (message.linkUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.linkUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.cashOrderId !== '') {
      writer.uint32(26).string(message.cashOrderId);
    }
    if (message.hedgeOrderId !== '') {
      writer.uint32(34).string(message.hedgeOrderId);
    }
    if (message.linkUnits !== undefined) {
      Decimal.encode(message.linkUnits, writer.uint32(42).fork()).ldelim();
    }
    if (message.unitsName !== undefined) {
      Text.encode(message.unitsName, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CashToHedgeOrdersLink {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCashToHedgeOrdersLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deleted = reader.bool();
          break;
        case 2:
          message.linkUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.cashOrderId = reader.string();
          break;
        case 4:
          message.hedgeOrderId = reader.string();
          break;
        case 5:
          message.linkUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.unitsName = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CashToHedgeOrdersLink {
    return {
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      linkUtcTimestamp: isSet(object.linkUtcTimestamp) ? fromJsonTimestamp(object.linkUtcTimestamp) : undefined,
      cashOrderId: isSet(object.cashOrderId) ? String(object.cashOrderId) : '',
      hedgeOrderId: isSet(object.hedgeOrderId) ? String(object.hedgeOrderId) : '',
      linkUnits: isSet(object.linkUnits) ? Decimal.fromJSON(object.linkUnits) : undefined,
      unitsName: isSet(object.unitsName) ? Text.fromJSON(object.unitsName) : undefined,
    };
  },

  toJSON(message: CashToHedgeOrdersLink): unknown {
    const obj: any = {};
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.linkUtcTimestamp !== undefined && (obj.linkUtcTimestamp = message.linkUtcTimestamp.toISOString());
    message.cashOrderId !== undefined && (obj.cashOrderId = message.cashOrderId);
    message.hedgeOrderId !== undefined && (obj.hedgeOrderId = message.hedgeOrderId);
    message.linkUnits !== undefined && (obj.linkUnits = message.linkUnits ? Decimal.toJSON(message.linkUnits) : undefined);
    message.unitsName !== undefined && (obj.unitsName = message.unitsName ? Text.toJSON(message.unitsName) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CashToHedgeOrdersLink>, I>>(base?: I): CashToHedgeOrdersLink {
    return CashToHedgeOrdersLink.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CashToHedgeOrdersLink>, I>>(object: I): CashToHedgeOrdersLink {
    const message = createBaseCashToHedgeOrdersLink();
    message.deleted = object.deleted ?? false;
    message.linkUtcTimestamp = object.linkUtcTimestamp ?? undefined;
    message.cashOrderId = object.cashOrderId ?? '';
    message.hedgeOrderId = object.hedgeOrderId ?? '';
    message.linkUnits =
      object.linkUnits !== undefined && object.linkUnits !== null ? Decimal.fromPartial(object.linkUnits) : undefined;
    message.unitsName =
      object.unitsName !== undefined && object.unitsName !== null ? Text.fromPartial(object.unitsName) : undefined;
    return message;
  },
};

function createBaseBalanceItemKey(): BalanceItemKey {
  return { itemId: '', itemType: 0 };
}

export const BalanceItemKey = {
  encode(message: BalanceItemKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemId !== '') {
      writer.uint32(10).string(message.itemId);
    }
    if (message.itemType !== 0) {
      writer.uint32(16).uint32(message.itemType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceItemKey {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceItemKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.itemId = reader.string();
          break;
        case 2:
          message.itemType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceItemKey {
    return {
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      itemType: isSet(object.itemType) ? Number(object.itemType) : 0,
    };
  },

  toJSON(message: BalanceItemKey): unknown {
    const obj: any = {};
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.itemType !== undefined && (obj.itemType = Math.round(message.itemType));
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceItemKey>, I>>(base?: I): BalanceItemKey {
    return BalanceItemKey.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceItemKey>, I>>(object: I): BalanceItemKey {
    const message = createBaseBalanceItemKey();
    message.itemId = object.itemId ?? '';
    message.itemType = object.itemType ?? 0;
    return message;
  },
};

function createBaseBalanceItem(): BalanceItem {
  return {
    itemKey: undefined,
    deleted: false,
    utcTimestamp: undefined,
    units: undefined,
    openUnits: undefined,
    unitsName: undefined,
    username: '',
    comment: '',
    contractId: 0,
  };
}

export const BalanceItem = {
  encode(message: BalanceItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.itemKey !== undefined) {
      BalanceItemKey.encode(message.itemKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    if (message.utcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.utcTimestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.units !== undefined) {
      Decimal.encode(message.units, writer.uint32(34).fork()).ldelim();
    }
    if (message.openUnits !== undefined) {
      Decimal.encode(message.openUnits, writer.uint32(42).fork()).ldelim();
    }
    if (message.unitsName !== undefined) {
      Text.encode(message.unitsName, writer.uint32(50).fork()).ldelim();
    }
    if (message.username !== '') {
      writer.uint32(58).string(message.username);
    }
    if (message.comment !== '') {
      writer.uint32(66).string(message.comment);
    }
    if (message.contractId !== 0) {
      writer.uint32(72).uint32(message.contractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceItem {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.itemKey = BalanceItemKey.decode(reader, reader.uint32());
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        case 3:
          message.utcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.units = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.openUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.unitsName = Text.decode(reader, reader.uint32());
          break;
        case 7:
          message.username = reader.string();
          break;
        case 8:
          message.comment = reader.string();
          break;
        case 9:
          message.contractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceItem {
    return {
      itemKey: isSet(object.itemKey) ? BalanceItemKey.fromJSON(object.itemKey) : undefined,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      utcTimestamp: isSet(object.utcTimestamp) ? fromJsonTimestamp(object.utcTimestamp) : undefined,
      units: isSet(object.units) ? Decimal.fromJSON(object.units) : undefined,
      openUnits: isSet(object.openUnits) ? Decimal.fromJSON(object.openUnits) : undefined,
      unitsName: isSet(object.unitsName) ? Text.fromJSON(object.unitsName) : undefined,
      username: isSet(object.username) ? String(object.username) : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
    };
  },

  toJSON(message: BalanceItem): unknown {
    const obj: any = {};
    message.itemKey !== undefined && (obj.itemKey = message.itemKey ? BalanceItemKey.toJSON(message.itemKey) : undefined);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.utcTimestamp !== undefined && (obj.utcTimestamp = message.utcTimestamp.toISOString());
    message.units !== undefined && (obj.units = message.units ? Decimal.toJSON(message.units) : undefined);
    message.openUnits !== undefined && (obj.openUnits = message.openUnits ? Decimal.toJSON(message.openUnits) : undefined);
    message.unitsName !== undefined && (obj.unitsName = message.unitsName ? Text.toJSON(message.unitsName) : undefined);
    message.username !== undefined && (obj.username = message.username);
    message.comment !== undefined && (obj.comment = message.comment);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceItem>, I>>(base?: I): BalanceItem {
    return BalanceItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceItem>, I>>(object: I): BalanceItem {
    const message = createBaseBalanceItem();
    message.itemKey =
      object.itemKey !== undefined && object.itemKey !== null ? BalanceItemKey.fromPartial(object.itemKey) : undefined;
    message.deleted = object.deleted ?? false;
    message.utcTimestamp = object.utcTimestamp ?? undefined;
    message.units = object.units !== undefined && object.units !== null ? Decimal.fromPartial(object.units) : undefined;
    message.openUnits =
      object.openUnits !== undefined && object.openUnits !== null ? Decimal.fromPartial(object.openUnits) : undefined;
    message.unitsName =
      object.unitsName !== undefined && object.unitsName !== null ? Text.fromPartial(object.unitsName) : undefined;
    message.username = object.username ?? '';
    message.comment = object.comment ?? '';
    message.contractId = object.contractId ?? 0;
    return message;
  },
};

function createBaseBalanceItemsLink(): BalanceItemsLink {
  return {
    deleted: false,
    linkUtcTimestamp: undefined,
    firstItemKey: undefined,
    secondItemKey: undefined,
    sizeInFirstItemUnits: undefined,
    firstItemUnits: undefined,
    sizeInSecondItemUnits: undefined,
    secondItemUnits: undefined,
  };
}

export const BalanceItemsLink = {
  encode(message: BalanceItemsLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deleted === true) {
      writer.uint32(8).bool(message.deleted);
    }
    if (message.linkUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.linkUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    if (message.firstItemKey !== undefined) {
      BalanceItemKey.encode(message.firstItemKey, writer.uint32(26).fork()).ldelim();
    }
    if (message.secondItemKey !== undefined) {
      BalanceItemKey.encode(message.secondItemKey, writer.uint32(34).fork()).ldelim();
    }
    if (message.sizeInFirstItemUnits !== undefined) {
      Decimal.encode(message.sizeInFirstItemUnits, writer.uint32(42).fork()).ldelim();
    }
    if (message.firstItemUnits !== undefined) {
      Text.encode(message.firstItemUnits, writer.uint32(50).fork()).ldelim();
    }
    if (message.sizeInSecondItemUnits !== undefined) {
      Decimal.encode(message.sizeInSecondItemUnits, writer.uint32(58).fork()).ldelim();
    }
    if (message.secondItemUnits !== undefined) {
      Text.encode(message.secondItemUnits, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceItemsLink {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceItemsLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deleted = reader.bool();
          break;
        case 2:
          message.linkUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.firstItemKey = BalanceItemKey.decode(reader, reader.uint32());
          break;
        case 4:
          message.secondItemKey = BalanceItemKey.decode(reader, reader.uint32());
          break;
        case 5:
          message.sizeInFirstItemUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.firstItemUnits = Text.decode(reader, reader.uint32());
          break;
        case 7:
          message.sizeInSecondItemUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 8:
          message.secondItemUnits = Text.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceItemsLink {
    return {
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      linkUtcTimestamp: isSet(object.linkUtcTimestamp) ? fromJsonTimestamp(object.linkUtcTimestamp) : undefined,
      firstItemKey: isSet(object.firstItemKey) ? BalanceItemKey.fromJSON(object.firstItemKey) : undefined,
      secondItemKey: isSet(object.secondItemKey) ? BalanceItemKey.fromJSON(object.secondItemKey) : undefined,
      sizeInFirstItemUnits: isSet(object.sizeInFirstItemUnits) ? Decimal.fromJSON(object.sizeInFirstItemUnits) : undefined,
      firstItemUnits: isSet(object.firstItemUnits) ? Text.fromJSON(object.firstItemUnits) : undefined,
      sizeInSecondItemUnits: isSet(object.sizeInSecondItemUnits) ? Decimal.fromJSON(object.sizeInSecondItemUnits) : undefined,
      secondItemUnits: isSet(object.secondItemUnits) ? Text.fromJSON(object.secondItemUnits) : undefined,
    };
  },

  toJSON(message: BalanceItemsLink): unknown {
    const obj: any = {};
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.linkUtcTimestamp !== undefined && (obj.linkUtcTimestamp = message.linkUtcTimestamp.toISOString());
    message.firstItemKey !== undefined &&
      (obj.firstItemKey = message.firstItemKey ? BalanceItemKey.toJSON(message.firstItemKey) : undefined);
    message.secondItemKey !== undefined &&
      (obj.secondItemKey = message.secondItemKey ? BalanceItemKey.toJSON(message.secondItemKey) : undefined);
    message.sizeInFirstItemUnits !== undefined &&
      (obj.sizeInFirstItemUnits = message.sizeInFirstItemUnits ? Decimal.toJSON(message.sizeInFirstItemUnits) : undefined);
    message.firstItemUnits !== undefined &&
      (obj.firstItemUnits = message.firstItemUnits ? Text.toJSON(message.firstItemUnits) : undefined);
    message.sizeInSecondItemUnits !== undefined &&
      (obj.sizeInSecondItemUnits = message.sizeInSecondItemUnits ? Decimal.toJSON(message.sizeInSecondItemUnits) : undefined);
    message.secondItemUnits !== undefined &&
      (obj.secondItemUnits = message.secondItemUnits ? Text.toJSON(message.secondItemUnits) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceItemsLink>, I>>(base?: I): BalanceItemsLink {
    return BalanceItemsLink.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceItemsLink>, I>>(object: I): BalanceItemsLink {
    const message = createBaseBalanceItemsLink();
    message.deleted = object.deleted ?? false;
    message.linkUtcTimestamp = object.linkUtcTimestamp ?? undefined;
    message.firstItemKey =
      object.firstItemKey !== undefined && object.firstItemKey !== null
        ? BalanceItemKey.fromPartial(object.firstItemKey)
        : undefined;
    message.secondItemKey =
      object.secondItemKey !== undefined && object.secondItemKey !== null
        ? BalanceItemKey.fromPartial(object.secondItemKey)
        : undefined;
    message.sizeInFirstItemUnits =
      object.sizeInFirstItemUnits !== undefined && object.sizeInFirstItemUnits !== null
        ? Decimal.fromPartial(object.sizeInFirstItemUnits)
        : undefined;
    message.firstItemUnits =
      object.firstItemUnits !== undefined && object.firstItemUnits !== null ? Text.fromPartial(object.firstItemUnits) : undefined;
    message.sizeInSecondItemUnits =
      object.sizeInSecondItemUnits !== undefined && object.sizeInSecondItemUnits !== null
        ? Decimal.fromPartial(object.sizeInSecondItemUnits)
        : undefined;
    message.secondItemUnits =
      object.secondItemUnits !== undefined && object.secondItemUnits !== null
        ? Text.fromPartial(object.secondItemUnits)
        : undefined;
    return message;
  },
};

function createBaseOffsetHedgeBalanceRequest(): OffsetHedgeBalanceRequest {
  return {
    otcInstanceId: 0,
    hedgeBalanceKey: undefined,
    hedgeBalanceOffset: undefined,
    username: '',
    comment: '',
    zeroBalance: false,
  };
}

export const OffsetHedgeBalanceRequest = {
  encode(message: OffsetHedgeBalanceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    if (message.hedgeBalanceKey !== undefined) {
      HedgeBalanceKey.encode(message.hedgeBalanceKey, writer.uint32(18).fork()).ldelim();
    }
    if (message.hedgeBalanceOffset !== undefined) {
      Decimal.encode(message.hedgeBalanceOffset, writer.uint32(26).fork()).ldelim();
    }
    if (message.username !== '') {
      writer.uint32(34).string(message.username);
    }
    if (message.comment !== '') {
      writer.uint32(42).string(message.comment);
    }
    if (message.zeroBalance === true) {
      writer.uint32(48).bool(message.zeroBalance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OffsetHedgeBalanceRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOffsetHedgeBalanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.hedgeBalanceKey = HedgeBalanceKey.decode(reader, reader.uint32());
          break;
        case 3:
          message.hedgeBalanceOffset = Decimal.decode(reader, reader.uint32());
          break;
        case 4:
          message.username = reader.string();
          break;
        case 5:
          message.comment = reader.string();
          break;
        case 6:
          message.zeroBalance = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OffsetHedgeBalanceRequest {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      hedgeBalanceKey: isSet(object.hedgeBalanceKey) ? HedgeBalanceKey.fromJSON(object.hedgeBalanceKey) : undefined,
      hedgeBalanceOffset: isSet(object.hedgeBalanceOffset) ? Decimal.fromJSON(object.hedgeBalanceOffset) : undefined,
      username: isSet(object.username) ? String(object.username) : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
      zeroBalance: isSet(object.zeroBalance) ? Boolean(object.zeroBalance) : false,
    };
  },

  toJSON(message: OffsetHedgeBalanceRequest): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.hedgeBalanceKey !== undefined &&
      (obj.hedgeBalanceKey = message.hedgeBalanceKey ? HedgeBalanceKey.toJSON(message.hedgeBalanceKey) : undefined);
    message.hedgeBalanceOffset !== undefined &&
      (obj.hedgeBalanceOffset = message.hedgeBalanceOffset ? Decimal.toJSON(message.hedgeBalanceOffset) : undefined);
    message.username !== undefined && (obj.username = message.username);
    message.comment !== undefined && (obj.comment = message.comment);
    message.zeroBalance !== undefined && (obj.zeroBalance = message.zeroBalance);
    return obj;
  },

  create<I extends Exact<DeepPartial<OffsetHedgeBalanceRequest>, I>>(base?: I): OffsetHedgeBalanceRequest {
    return OffsetHedgeBalanceRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OffsetHedgeBalanceRequest>, I>>(object: I): OffsetHedgeBalanceRequest {
    const message = createBaseOffsetHedgeBalanceRequest();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.hedgeBalanceKey =
      object.hedgeBalanceKey !== undefined && object.hedgeBalanceKey !== null
        ? HedgeBalanceKey.fromPartial(object.hedgeBalanceKey)
        : undefined;
    message.hedgeBalanceOffset =
      object.hedgeBalanceOffset !== undefined && object.hedgeBalanceOffset !== null
        ? Decimal.fromPartial(object.hedgeBalanceOffset)
        : undefined;
    message.username = object.username ?? '';
    message.comment = object.comment ?? '';
    message.zeroBalance = object.zeroBalance ?? false;
    return message;
  },
};

function createBaseOffsetHedgeBalanceResult(): OffsetHedgeBalanceResult {
  return {};
}

export const OffsetHedgeBalanceResult = {
  encode(_: OffsetHedgeBalanceResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OffsetHedgeBalanceResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOffsetHedgeBalanceResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): OffsetHedgeBalanceResult {
    return {};
  },

  toJSON(_: OffsetHedgeBalanceResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OffsetHedgeBalanceResult>, I>>(base?: I): OffsetHedgeBalanceResult {
    return OffsetHedgeBalanceResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OffsetHedgeBalanceResult>, I>>(_: I): OffsetHedgeBalanceResult {
    const message = createBaseOffsetHedgeBalanceResult();
    return message;
  },
};

function createBaseChangeOtcStateRequest(): ChangeOtcStateRequest {
  return { otcInstanceId: 0, action: 0 };
}

export const ChangeOtcStateRequest = {
  encode(message: ChangeOtcStateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    if (message.action !== 0) {
      writer.uint32(16).uint32(message.action);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeOtcStateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeOtcStateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.action = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChangeOtcStateRequest {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      action: isSet(object.action) ? Number(object.action) : 0,
    };
  },

  toJSON(message: ChangeOtcStateRequest): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.action !== undefined && (obj.action = Math.round(message.action));
    return obj;
  },

  create<I extends Exact<DeepPartial<ChangeOtcStateRequest>, I>>(base?: I): ChangeOtcStateRequest {
    return ChangeOtcStateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChangeOtcStateRequest>, I>>(object: I): ChangeOtcStateRequest {
    const message = createBaseChangeOtcStateRequest();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.action = object.action ?? 0;
    return message;
  },
};

function createBaseChangeOtcStateResult(): ChangeOtcStateResult {
  return {};
}

export const ChangeOtcStateResult = {
  encode(_: ChangeOtcStateResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeOtcStateResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeOtcStateResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ChangeOtcStateResult {
    return {};
  },

  toJSON(_: ChangeOtcStateResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ChangeOtcStateResult>, I>>(base?: I): ChangeOtcStateResult {
    return ChangeOtcStateResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChangeOtcStateResult>, I>>(_: I): ChangeOtcStateResult {
    const message = createBaseChangeOtcStateResult();
    return message;
  },
};

function createBaseFillCashOrderRequest(): FillCashOrderRequest {
  return {
    otcInstanceId: 0,
    chainOrderId: '',
    accountId: 0,
    contractId: 0,
    qty: undefined,
    scaledFillPrice: 0,
    contributorId: '',
  };
}

export const FillCashOrderRequest = {
  encode(message: FillCashOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    if (message.chainOrderId !== '') {
      writer.uint32(18).string(message.chainOrderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(42).fork()).ldelim();
    }
    if (message.scaledFillPrice !== 0) {
      writer.uint32(48).sint64(message.scaledFillPrice);
    }
    if (message.contributorId !== '') {
      writer.uint32(58).string(message.contributorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCashOrderRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCashOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.chainOrderId = reader.string();
          break;
        case 3:
          message.accountId = reader.sint32();
          break;
        case 4:
          message.contractId = reader.uint32();
          break;
        case 5:
          message.qty = Decimal.decode(reader, reader.uint32());
          break;
        case 6:
          message.scaledFillPrice = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.contributorId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FillCashOrderRequest {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      scaledFillPrice: isSet(object.scaledFillPrice) ? Number(object.scaledFillPrice) : 0,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
    };
  },

  toJSON(message: FillCashOrderRequest): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.qty !== undefined && (obj.qty = message.qty ? Decimal.toJSON(message.qty) : undefined);
    message.scaledFillPrice !== undefined && (obj.scaledFillPrice = Math.round(message.scaledFillPrice));
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCashOrderRequest>, I>>(base?: I): FillCashOrderRequest {
    return FillCashOrderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FillCashOrderRequest>, I>>(object: I): FillCashOrderRequest {
    const message = createBaseFillCashOrderRequest();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.chainOrderId = object.chainOrderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    message.qty = object.qty !== undefined && object.qty !== null ? Decimal.fromPartial(object.qty) : undefined;
    message.scaledFillPrice = object.scaledFillPrice ?? 0;
    message.contributorId = object.contributorId ?? '';
    return message;
  },
};

function createBaseFillCashOrderResult(): FillCashOrderResult {
  return {};
}

export const FillCashOrderResult = {
  encode(_: FillCashOrderResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FillCashOrderResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFillCashOrderResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): FillCashOrderResult {
    return {};
  },

  toJSON(_: FillCashOrderResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FillCashOrderResult>, I>>(base?: I): FillCashOrderResult {
    return FillCashOrderResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FillCashOrderResult>, I>>(_: I): FillCashOrderResult {
    const message = createBaseFillCashOrderResult();
    return message;
  },
};

function createBaseArchiveHedgeBalanceDetailsRequest(): ArchiveHedgeBalanceDetailsRequest {
  return { hedgeBalanceKey: undefined, otcInstanceId: 0 };
}

export const ArchiveHedgeBalanceDetailsRequest = {
  encode(message: ArchiveHedgeBalanceDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hedgeBalanceKey !== undefined) {
      HedgeBalanceKey.encode(message.hedgeBalanceKey, writer.uint32(10).fork()).ldelim();
    }
    if (message.otcInstanceId !== 0) {
      writer.uint32(16).uint32(message.otcInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchiveHedgeBalanceDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchiveHedgeBalanceDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hedgeBalanceKey = HedgeBalanceKey.decode(reader, reader.uint32());
          break;
        case 2:
          message.otcInstanceId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchiveHedgeBalanceDetailsRequest {
    return {
      hedgeBalanceKey: isSet(object.hedgeBalanceKey) ? HedgeBalanceKey.fromJSON(object.hedgeBalanceKey) : undefined,
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
    };
  },

  toJSON(message: ArchiveHedgeBalanceDetailsRequest): unknown {
    const obj: any = {};
    message.hedgeBalanceKey !== undefined &&
      (obj.hedgeBalanceKey = message.hedgeBalanceKey ? HedgeBalanceKey.toJSON(message.hedgeBalanceKey) : undefined);
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    return obj;
  },

  create<I extends Exact<DeepPartial<ArchiveHedgeBalanceDetailsRequest>, I>>(base?: I): ArchiveHedgeBalanceDetailsRequest {
    return ArchiveHedgeBalanceDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ArchiveHedgeBalanceDetailsRequest>, I>>(object: I): ArchiveHedgeBalanceDetailsRequest {
    const message = createBaseArchiveHedgeBalanceDetailsRequest();
    message.hedgeBalanceKey =
      object.hedgeBalanceKey !== undefined && object.hedgeBalanceKey !== null
        ? HedgeBalanceKey.fromPartial(object.hedgeBalanceKey)
        : undefined;
    message.otcInstanceId = object.otcInstanceId ?? 0;
    return message;
  },
};

function createBaseArchiveHedgeBalanceDetailsResult(): ArchiveHedgeBalanceDetailsResult {
  return {};
}

export const ArchiveHedgeBalanceDetailsResult = {
  encode(_: ArchiveHedgeBalanceDetailsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchiveHedgeBalanceDetailsResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchiveHedgeBalanceDetailsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ArchiveHedgeBalanceDetailsResult {
    return {};
  },

  toJSON(_: ArchiveHedgeBalanceDetailsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ArchiveHedgeBalanceDetailsResult>, I>>(base?: I): ArchiveHedgeBalanceDetailsResult {
    return ArchiveHedgeBalanceDetailsResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ArchiveHedgeBalanceDetailsResult>, I>>(_: I): ArchiveHedgeBalanceDetailsResult {
    const message = createBaseArchiveHedgeBalanceDetailsResult();
    return message;
  },
};

function createBaseArchivedHedgeBalanceDetails(): ArchivedHedgeBalanceDetails {
  return { deleted: false, archiveId: '', hedgeBalanceDetails: undefined, archiveUtcTimestamp: undefined };
}

export const ArchivedHedgeBalanceDetails = {
  encode(message: ArchivedHedgeBalanceDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deleted === true) {
      writer.uint32(8).bool(message.deleted);
    }
    if (message.archiveId !== '') {
      writer.uint32(18).string(message.archiveId);
    }
    if (message.hedgeBalanceDetails !== undefined) {
      HedgeBalanceDetails.encode(message.hedgeBalanceDetails, writer.uint32(26).fork()).ldelim();
    }
    if (message.archiveUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.archiveUtcTimestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchivedHedgeBalanceDetails {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchivedHedgeBalanceDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.deleted = reader.bool();
          break;
        case 2:
          message.archiveId = reader.string();
          break;
        case 3:
          message.hedgeBalanceDetails = HedgeBalanceDetails.decode(reader, reader.uint32());
          break;
        case 4:
          message.archiveUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ArchivedHedgeBalanceDetails {
    return {
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      archiveId: isSet(object.archiveId) ? String(object.archiveId) : '',
      hedgeBalanceDetails: isSet(object.hedgeBalanceDetails)
        ? HedgeBalanceDetails.fromJSON(object.hedgeBalanceDetails)
        : undefined,
      archiveUtcTimestamp: isSet(object.archiveUtcTimestamp) ? fromJsonTimestamp(object.archiveUtcTimestamp) : undefined,
    };
  },

  toJSON(message: ArchivedHedgeBalanceDetails): unknown {
    const obj: any = {};
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.archiveId !== undefined && (obj.archiveId = message.archiveId);
    message.hedgeBalanceDetails !== undefined &&
      (obj.hedgeBalanceDetails = message.hedgeBalanceDetails
        ? HedgeBalanceDetails.toJSON(message.hedgeBalanceDetails)
        : undefined);
    message.archiveUtcTimestamp !== undefined && (obj.archiveUtcTimestamp = message.archiveUtcTimestamp.toISOString());
    return obj;
  },

  create<I extends Exact<DeepPartial<ArchivedHedgeBalanceDetails>, I>>(base?: I): ArchivedHedgeBalanceDetails {
    return ArchivedHedgeBalanceDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ArchivedHedgeBalanceDetails>, I>>(object: I): ArchivedHedgeBalanceDetails {
    const message = createBaseArchivedHedgeBalanceDetails();
    message.deleted = object.deleted ?? false;
    message.archiveId = object.archiveId ?? '';
    message.hedgeBalanceDetails =
      object.hedgeBalanceDetails !== undefined && object.hedgeBalanceDetails !== null
        ? HedgeBalanceDetails.fromPartial(object.hedgeBalanceDetails)
        : undefined;
    message.archiveUtcTimestamp = object.archiveUtcTimestamp ?? undefined;
    return message;
  },
};

function createBaseBalanceItemsLinksRequest(): BalanceItemsLinksRequest {
  return { otcInstanceId: 0, itemId: '', accountId: 0, contractId: 0 };
}

export const BalanceItemsLinksRequest = {
  encode(message: BalanceItemsLinksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    if (message.itemId !== '') {
      writer.uint32(18).string(message.itemId);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.contractId !== 0) {
      writer.uint32(32).uint32(message.contractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceItemsLinksRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceItemsLinksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.itemId = reader.string();
          break;
        case 3:
          message.accountId = reader.sint32();
          break;
        case 4:
          message.contractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceItemsLinksRequest {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
    };
  },

  toJSON(message: BalanceItemsLinksRequest): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceItemsLinksRequest>, I>>(base?: I): BalanceItemsLinksRequest {
    return BalanceItemsLinksRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceItemsLinksRequest>, I>>(object: I): BalanceItemsLinksRequest {
    const message = createBaseBalanceItemsLinksRequest();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.itemId = object.itemId ?? '';
    message.accountId = object.accountId ?? 0;
    message.contractId = object.contractId ?? 0;
    return message;
  },
};

function createBaseBalanceItemsLinksResult(): BalanceItemsLinksResult {
  return { balanceItems: [], itemsLinks: [] };
}

export const BalanceItemsLinksResult = {
  encode(message: BalanceItemsLinksResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.balanceItems) {
      BalanceItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.itemsLinks) {
      BalanceItemsLink.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceItemsLinksResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceItemsLinksResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.balanceItems.push(BalanceItem.decode(reader, reader.uint32()));
          break;
        case 2:
          message.itemsLinks.push(BalanceItemsLink.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceItemsLinksResult {
    return {
      balanceItems: Array.isArray(object?.balanceItems) ? object.balanceItems.map((e: any) => BalanceItem.fromJSON(e)) : [],
      itemsLinks: Array.isArray(object?.itemsLinks) ? object.itemsLinks.map((e: any) => BalanceItemsLink.fromJSON(e)) : [],
    };
  },

  toJSON(message: BalanceItemsLinksResult): unknown {
    const obj: any = {};
    if (message.balanceItems) {
      obj.balanceItems = message.balanceItems.map((e) => (e ? BalanceItem.toJSON(e) : undefined));
    } else {
      obj.balanceItems = [];
    }
    if (message.itemsLinks) {
      obj.itemsLinks = message.itemsLinks.map((e) => (e ? BalanceItemsLink.toJSON(e) : undefined));
    } else {
      obj.itemsLinks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceItemsLinksResult>, I>>(base?: I): BalanceItemsLinksResult {
    return BalanceItemsLinksResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BalanceItemsLinksResult>, I>>(object: I): BalanceItemsLinksResult {
    const message = createBaseBalanceItemsLinksResult();
    message.balanceItems = object.balanceItems?.map((e) => BalanceItem.fromPartial(e)) || [];
    message.itemsLinks = object.itemsLinks?.map((e) => BalanceItemsLink.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateTailManagementConfigurationResult(): UpdateTailManagementConfigurationResult {
  return {};
}

export const UpdateTailManagementConfigurationResult = {
  encode(_: UpdateTailManagementConfigurationResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTailManagementConfigurationResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTailManagementConfigurationResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UpdateTailManagementConfigurationResult {
    return {};
  },

  toJSON(_: UpdateTailManagementConfigurationResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTailManagementConfigurationResult>, I>>(
    base?: I
  ): UpdateTailManagementConfigurationResult {
    return UpdateTailManagementConfigurationResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTailManagementConfigurationResult>, I>>(
    _: I
  ): UpdateTailManagementConfigurationResult {
    const message = createBaseUpdateTailManagementConfigurationResult();
    return message;
  },
};

function createBaseTailManagementConfigurationSubscription(): TailManagementConfigurationSubscription {
  return { subscribe: false, otcInstanceId: 0 };
}

export const TailManagementConfigurationSubscription = {
  encode(message: TailManagementConfigurationSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subscribe === true) {
      writer.uint32(8).bool(message.subscribe);
    }
    if (message.otcInstanceId !== 0) {
      writer.uint32(16).uint32(message.otcInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TailManagementConfigurationSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTailManagementConfigurationSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.subscribe = reader.bool();
          break;
        case 2:
          message.otcInstanceId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TailManagementConfigurationSubscription {
    return {
      subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false,
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
    };
  },

  toJSON(message: TailManagementConfigurationSubscription): unknown {
    const obj: any = {};
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    return obj;
  },

  create<I extends Exact<DeepPartial<TailManagementConfigurationSubscription>, I>>(
    base?: I
  ): TailManagementConfigurationSubscription {
    return TailManagementConfigurationSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TailManagementConfigurationSubscription>, I>>(
    object: I
  ): TailManagementConfigurationSubscription {
    const message = createBaseTailManagementConfigurationSubscription();
    message.subscribe = object.subscribe ?? false;
    message.otcInstanceId = object.otcInstanceId ?? 0;
    return message;
  },
};

function createBaseUpdateTailManagementConfiguration(): UpdateTailManagementConfiguration {
  return { otcInstanceId: 0, tailManagementConfigurations: [] };
}

export const UpdateTailManagementConfiguration = {
  encode(message: UpdateTailManagementConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    for (const v of message.tailManagementConfigurations) {
      TailManagementConfiguration.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTailManagementConfiguration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTailManagementConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.tailManagementConfigurations.push(TailManagementConfiguration.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateTailManagementConfiguration {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      tailManagementConfigurations: Array.isArray(object?.tailManagementConfigurations)
        ? object.tailManagementConfigurations.map((e: any) => TailManagementConfiguration.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateTailManagementConfiguration): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    if (message.tailManagementConfigurations) {
      obj.tailManagementConfigurations = message.tailManagementConfigurations.map((e) =>
        e ? TailManagementConfiguration.toJSON(e) : undefined
      );
    } else {
      obj.tailManagementConfigurations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTailManagementConfiguration>, I>>(base?: I): UpdateTailManagementConfiguration {
    return UpdateTailManagementConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateTailManagementConfiguration>, I>>(object: I): UpdateTailManagementConfiguration {
    const message = createBaseUpdateTailManagementConfiguration();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.tailManagementConfigurations =
      object.tailManagementConfigurations?.map((e) => TailManagementConfiguration.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTailManagementConfigurationReport(): TailManagementConfigurationReport {
  return { isSnapshot: false, isLastPart: false, tailManagementConfigurations: [] };
}

export const TailManagementConfigurationReport = {
  encode(message: TailManagementConfigurationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSnapshot === true) {
      writer.uint32(8).bool(message.isSnapshot);
    }
    if (message.isLastPart === true) {
      writer.uint32(16).bool(message.isLastPart);
    }
    for (const v of message.tailManagementConfigurations) {
      TailManagementConfiguration.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TailManagementConfigurationReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTailManagementConfigurationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.isSnapshot = reader.bool();
          break;
        case 2:
          message.isLastPart = reader.bool();
          break;
        case 3:
          message.tailManagementConfigurations.push(TailManagementConfiguration.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TailManagementConfigurationReport {
    return {
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
      isLastPart: isSet(object.isLastPart) ? Boolean(object.isLastPart) : false,
      tailManagementConfigurations: Array.isArray(object?.tailManagementConfigurations)
        ? object.tailManagementConfigurations.map((e: any) => TailManagementConfiguration.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TailManagementConfigurationReport): unknown {
    const obj: any = {};
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    message.isLastPart !== undefined && (obj.isLastPart = message.isLastPart);
    if (message.tailManagementConfigurations) {
      obj.tailManagementConfigurations = message.tailManagementConfigurations.map((e) =>
        e ? TailManagementConfiguration.toJSON(e) : undefined
      );
    } else {
      obj.tailManagementConfigurations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TailManagementConfigurationReport>, I>>(base?: I): TailManagementConfigurationReport {
    return TailManagementConfigurationReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TailManagementConfigurationReport>, I>>(object: I): TailManagementConfigurationReport {
    const message = createBaseTailManagementConfigurationReport();
    message.isSnapshot = object.isSnapshot ?? false;
    message.isLastPart = object.isLastPart ?? false;
    message.tailManagementConfigurations =
      object.tailManagementConfigurations?.map((e) => TailManagementConfiguration.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRequestTailManagementConfigurationMetadata(): RequestTailManagementConfigurationMetadata {
  return { otcInstanceId: 0 };
}

export const RequestTailManagementConfigurationMetadata = {
  encode(message: RequestTailManagementConfigurationMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestTailManagementConfigurationMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestTailManagementConfigurationMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestTailManagementConfigurationMetadata {
    return { otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0 };
  },

  toJSON(message: RequestTailManagementConfigurationMetadata): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    return obj;
  },

  create<I extends Exact<DeepPartial<RequestTailManagementConfigurationMetadata>, I>>(
    base?: I
  ): RequestTailManagementConfigurationMetadata {
    return RequestTailManagementConfigurationMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RequestTailManagementConfigurationMetadata>, I>>(
    object: I
  ): RequestTailManagementConfigurationMetadata {
    const message = createBaseRequestTailManagementConfigurationMetadata();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    return message;
  },
};

function createBaseTailManagementConfigurationMetadata(): TailManagementConfigurationMetadata {
  return { metadata: '' };
}

export const TailManagementConfigurationMetadata = {
  encode(message: TailManagementConfigurationMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metadata !== '') {
      writer.uint32(10).string(message.metadata);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TailManagementConfigurationMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTailManagementConfigurationMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.metadata = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TailManagementConfigurationMetadata {
    return { metadata: isSet(object.metadata) ? String(object.metadata) : '' };
  },

  toJSON(message: TailManagementConfigurationMetadata): unknown {
    const obj: any = {};
    message.metadata !== undefined && (obj.metadata = message.metadata);
    return obj;
  },

  create<I extends Exact<DeepPartial<TailManagementConfigurationMetadata>, I>>(base?: I): TailManagementConfigurationMetadata {
    return TailManagementConfigurationMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TailManagementConfigurationMetadata>, I>>(
    object: I
  ): TailManagementConfigurationMetadata {
    const message = createBaseTailManagementConfigurationMetadata();
    message.metadata = object.metadata ?? '';
    return message;
  },
};

function createBaseTailManagementConfiguration(): TailManagementConfiguration {
  return { hedgeBalanceKeys: [], removed: false, configurationEntries: [] };
}

export const TailManagementConfiguration = {
  encode(message: TailManagementConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.hedgeBalanceKeys) {
      HedgeBalanceKey.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.removed === true) {
      writer.uint32(16).bool(message.removed);
    }
    for (const v of message.configurationEntries) {
      ConfigurationEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TailManagementConfiguration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTailManagementConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hedgeBalanceKeys.push(HedgeBalanceKey.decode(reader, reader.uint32()));
          break;
        case 2:
          message.removed = reader.bool();
          break;
        case 3:
          message.configurationEntries.push(ConfigurationEntry.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TailManagementConfiguration {
    return {
      hedgeBalanceKeys: Array.isArray(object?.hedgeBalanceKeys)
        ? object.hedgeBalanceKeys.map((e: any) => HedgeBalanceKey.fromJSON(e))
        : [],
      removed: isSet(object.removed) ? Boolean(object.removed) : false,
      configurationEntries: Array.isArray(object?.configurationEntries)
        ? object.configurationEntries.map((e: any) => ConfigurationEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TailManagementConfiguration): unknown {
    const obj: any = {};
    if (message.hedgeBalanceKeys) {
      obj.hedgeBalanceKeys = message.hedgeBalanceKeys.map((e) => (e ? HedgeBalanceKey.toJSON(e) : undefined));
    } else {
      obj.hedgeBalanceKeys = [];
    }
    message.removed !== undefined && (obj.removed = message.removed);
    if (message.configurationEntries) {
      obj.configurationEntries = message.configurationEntries.map((e) => (e ? ConfigurationEntry.toJSON(e) : undefined));
    } else {
      obj.configurationEntries = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TailManagementConfiguration>, I>>(base?: I): TailManagementConfiguration {
    return TailManagementConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TailManagementConfiguration>, I>>(object: I): TailManagementConfiguration {
    const message = createBaseTailManagementConfiguration();
    message.hedgeBalanceKeys = object.hedgeBalanceKeys?.map((e) => HedgeBalanceKey.fromPartial(e)) || [];
    message.removed = object.removed ?? false;
    message.configurationEntries = object.configurationEntries?.map((e) => ConfigurationEntry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConfigurationEntry(): ConfigurationEntry {
  return { removed: false, name: '', value: '' };
}

export const ConfigurationEntry = {
  encode(message: ConfigurationEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.removed === true) {
      writer.uint32(8).bool(message.removed);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.value !== '') {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfigurationEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigurationEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.removed = reader.bool();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConfigurationEntry {
    return {
      removed: isSet(object.removed) ? Boolean(object.removed) : false,
      name: isSet(object.name) ? String(object.name) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: ConfigurationEntry): unknown {
    const obj: any = {};
    message.removed !== undefined && (obj.removed = message.removed);
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfigurationEntry>, I>>(base?: I): ConfigurationEntry {
    return ConfigurationEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConfigurationEntry>, I>>(object: I): ConfigurationEntry {
    const message = createBaseConfigurationEntry();
    message.removed = object.removed ?? false;
    message.name = object.name ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseResetCustomBasisRequest(): ResetCustomBasisRequest {
  return { otcInstanceId: 0, chainOrderId: '', accountId: 0, contributorId: '', contractId: 0, offset: 0 };
}

export const ResetCustomBasisRequest = {
  encode(message: ResetCustomBasisRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.otcInstanceId !== 0) {
      writer.uint32(8).uint32(message.otcInstanceId);
    }
    if (message.chainOrderId !== '') {
      writer.uint32(18).string(message.chainOrderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(24).sint32(message.accountId);
    }
    if (message.contributorId !== '') {
      writer.uint32(34).string(message.contributorId);
    }
    if (message.contractId !== 0) {
      writer.uint32(40).uint32(message.contractId);
    }
    if (message.offset !== 0) {
      writer.uint32(48).sint32(message.offset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetCustomBasisRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetCustomBasisRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.otcInstanceId = reader.uint32();
          break;
        case 2:
          message.chainOrderId = reader.string();
          break;
        case 3:
          message.accountId = reader.sint32();
          break;
        case 4:
          message.contributorId = reader.string();
          break;
        case 5:
          message.contractId = reader.uint32();
          break;
        case 6:
          message.offset = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetCustomBasisRequest {
    return {
      otcInstanceId: isSet(object.otcInstanceId) ? Number(object.otcInstanceId) : 0,
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      offset: isSet(object.offset) ? Number(object.offset) : 0,
    };
  },

  toJSON(message: ResetCustomBasisRequest): unknown {
    const obj: any = {};
    message.otcInstanceId !== undefined && (obj.otcInstanceId = Math.round(message.otcInstanceId));
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetCustomBasisRequest>, I>>(base?: I): ResetCustomBasisRequest {
    return ResetCustomBasisRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ResetCustomBasisRequest>, I>>(object: I): ResetCustomBasisRequest {
    const message = createBaseResetCustomBasisRequest();
    message.otcInstanceId = object.otcInstanceId ?? 0;
    message.chainOrderId = object.chainOrderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.contributorId = object.contributorId ?? '';
    message.contractId = object.contractId ?? 0;
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseResetCustomBasisResult(): ResetCustomBasisResult {
  return {};
}

export const ResetCustomBasisResult = {
  encode(_: ResetCustomBasisResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetCustomBasisResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetCustomBasisResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ResetCustomBasisResult {
    return {};
  },

  toJSON(_: ResetCustomBasisResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetCustomBasisResult>, I>>(base?: I): ResetCustomBasisResult {
    return ResetCustomBasisResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ResetCustomBasisResult>, I>>(_: I): ResetCustomBasisResult {
    const message = createBaseResetCustomBasisResult();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
