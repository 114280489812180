import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { getExchangesBySymbols, isValidSymbol } from '../../models/CropSymbols/cropSymbols';

export const fetchFuturesOptions = createAsyncThunk('futuresOptions/fetchFuturesOptions', async (optionsSymbols: string[]) => {
  if (!optionsSymbols || !optionsSymbols.length) {
    return [];
  }

  // TODO: see if we can go all the way and validate the actual symbol...
  const validSymbols = optionsSymbols.filter((x) => isValidSymbol(x));

  if (!validSymbols.length) {
    return [];
  }

  // Performance optimization - barchart doesn't do well on large, mixed exchange futuresOptions requests...
  const symbolsByExchange = getExchangesBySymbols(validSymbols);
  const exchanges = Array.from(symbolsByExchange.keys());

  const promises = exchanges.map(async (exchange): Promise<object[]> => {
    const symbols = symbolsByExchange.get(exchange);
    if (!symbols || !symbols.length) {
      return Promise.resolve([]);
    }

    const response = await axios.get(`${config.backend.basePath}/market_data/get_futures_options`, {
      params: {
        symbols: symbols.join(','),
        exchange: exchange || undefined,
      },
    });

    return response.data;
  });

  const dataResults = await Promise.all(promises);
  const futuresOptionsResults = dataResults.flatMap((x) => x);

  return futuresOptionsResults;
});
