import { useQuery } from '@tanstack/react-query';
import config from '../config';
import apiClient from '../shared/apiClient';
import { getTradeableCommodities } from '@harvestiq/constants';
import { prepareSymbolLookUps, SymbolLookUpsStoreItem } from '../store/SymbolLookUps/symbolLookUpsSlice';
import { ISymbolLookUp } from '../types/ISymbolLookUp';
import { QueryKey } from './QueryKey';

const fetchSymbolLookUps = async (tradingCodes: string[]): Promise<SymbolLookUpsStoreItem> => {
  const response = await Promise.all(
    tradingCodes.map((tradingCode) =>
      apiClient.get<ISymbolLookUp[]>(`${config.backend.basePath}/market_data/get_symbol_look_up`, {
        params: {
          keyword: tradingCode,
          exchanges: 'CBOT,MGEX,KCBT,CME',
        },
      })
    )
  );
  const symbolLookUps = response.map((response) => response.data);
  return prepareSymbolLookUps(symbolLookUps, tradingCodes);
};

export function useSymbolLookUps() {
  const crops = getTradeableCommodities();
  const tradingCodes = crops.map((crop) => crop.tradingCode).filter((code) => !!code);
  const query = useQuery([QueryKey.SymbolLookUps, tradingCodes], () => fetchSymbolLookUps(tradingCodes), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 120,
  });
  return query;
}
