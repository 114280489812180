/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Text } from '../common/shared_1';

export const protobufPackage = 'metadata_admin_2';

export enum ContributorParameterSource {
  CONTRIBUTOR_PARAMETER_SOURCE_UNSPECIFIED = 0,
  /** CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT - Origin of a parameter is a contract. */
  CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT = 1,
  /** CONTRIBUTOR_PARAMETER_SOURCE_SECURITY - Origin of a parameter is a security. */
  CONTRIBUTOR_PARAMETER_SOURCE_SECURITY = 2,
  UNRECOGNIZED = -1,
}

export function contributorParameterSourceFromJSON(object: any): ContributorParameterSource {
  switch (object) {
    case 0:
    case 'CONTRIBUTOR_PARAMETER_SOURCE_UNSPECIFIED':
      return ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_UNSPECIFIED;
    case 1:
    case 'CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT':
      return ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT;
    case 2:
    case 'CONTRIBUTOR_PARAMETER_SOURCE_SECURITY':
      return ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_SECURITY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ContributorParameterSource.UNRECOGNIZED;
  }
}

export function contributorParameterSourceToJSON(object: ContributorParameterSource): string {
  switch (object) {
    case ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_UNSPECIFIED:
      return 'CONTRIBUTOR_PARAMETER_SOURCE_UNSPECIFIED';
    case ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT:
      return 'CONTRIBUTOR_PARAMETER_SOURCE_CONTRACT';
    case ContributorParameterSource.CONTRIBUTOR_PARAMETER_SOURCE_SECURITY:
      return 'CONTRIBUTOR_PARAMETER_SOURCE_SECURITY';
    case ContributorParameterSource.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Client meta-data messages.
 * Only one metadata operation is allowed.
 */
export interface MetadataAdminRequest {
  /** Request identifier. */
  requestId: string;
  /** Request security parameters. */
  getSecurityParameters: GetSecurityParameters | undefined;
  /** Set security level parameters. */
  setSecurityParameters: SetSecurityParameters | undefined;
  /** Publish a new contract or publish changes of an existing contract. */
  publishContract: PublishContract | undefined;
  /** Remove a previous published contract from public access. */
  unpublishContract: UnpublishContract | undefined;
  /** Get contract level parameters. */
  getContractParameters: GetContractParameters | undefined;
}

/** Server meta-data messages. */
export interface MetadataAdminResult {
  /** ID of a corresponding request or subscription. */
  requestId: string;
  /**
   * Operation status.
   * This field is associated with ResultCode enum.
   */
  resultCode: number;
  /** Operation details. */
  details: Text | undefined;
  /** Result of security parameters request. */
  getSecurityParametersResult: GetSecurityParametersResult | undefined;
  /** Result of security parameters update. */
  setSecurityParametersResult: SetSecurityParametersResult | undefined;
  /** Result of contract publishing. */
  publishContractResult: PublishContractResult | undefined;
  /** Result of contract unpublishing. */
  unpublishContractResult: UnpublishContractResult | undefined;
  /** Result of contract parameters request. */
  getContractParametersResult: GetContractParametersResult | undefined;
}

export enum MetadataAdminResult_ResultCode {
  /** RESULT_CODE_SUCCESS - / success codes (0 - 100) */
  RESULT_CODE_SUCCESS = 0,
  /**
   * RESULT_CODE_FAILURE - // failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  /** RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION = 102,
  /**
   * RESULT_CODE_TIMEOUT - The request is timed out. No more statuses are expected for the request.
   * The modification request can be still in processing, and its result can
   * be observed later over subscriptions. E.g. PublishContract may lead to
   * SymbolListRequest update even after obtaining this status.
   */
  RESULT_CODE_TIMEOUT = 103,
  UNRECOGNIZED = -1,
}

export function metadataAdminResult_ResultCodeFromJSON(object: any): MetadataAdminResult_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return MetadataAdminResult_ResultCode.RESULT_CODE_SUCCESS;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return MetadataAdminResult_ResultCode.RESULT_CODE_FAILURE;
    case 102:
    case 'RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION':
      return MetadataAdminResult_ResultCode.RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 103:
    case 'RESULT_CODE_TIMEOUT':
      return MetadataAdminResult_ResultCode.RESULT_CODE_TIMEOUT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MetadataAdminResult_ResultCode.UNRECOGNIZED;
  }
}

export function metadataAdminResult_ResultCodeToJSON(object: MetadataAdminResult_ResultCode): string {
  switch (object) {
    case MetadataAdminResult_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case MetadataAdminResult_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case MetadataAdminResult_ResultCode.RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return 'RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION';
    case MetadataAdminResult_ResultCode.RESULT_CODE_TIMEOUT:
      return 'RESULT_CODE_TIMEOUT';
    case MetadataAdminResult_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Contributor with attached parameters. */
export interface ContributorParameters {
  /** OTC Contributor identifiers. */
  contributorId: string;
  /** List of custom parameters. */
  parameters: ContributorParameter[];
}

export interface ContributorParameter {
  /** Contributor parameter name. */
  name: string;
  /** Contributor parameter value. */
  value: string;
  /** An optional "deleted" flag for update operations. */
  deleted: boolean;
  /**
   * Contributor parameters are available on contract and security levels.
   * This field specifies where the value (see ContributorParameter.value
   * field) came from.
   * Contract source has a priority over security. Different requests
   * (which require different entitlements) control value on different
   * levels.
   * The field has meaning only in server to client messages.
   * This field is associated with ContributorParameterSource enum.
   */
  source: number;
}

/** Get security level parameters. */
export interface GetSecurityParameters {
  /** Security identifier. */
  securityId: string;
  /** OTC Contributor identifiers. */
  contributorIds: string[];
}

/** Result of security parameters update. */
export interface GetSecurityParametersResult {
  /** Security identifier. */
  securityId: string;
  /** Security custom parameters. */
  contributorParameters: ContributorParameters[];
}

/** Set security level parameters. */
export interface SetSecurityParameters {
  /** Security identifier. */
  securityId: string;
  /**
   * Security custom parameters.
   * Client includes only parameters that are changed (added, updated or deleted).
   */
  contributorParameters: ContributorParameters[];
}

/** Result of security parameters update. */
export interface SetSecurityParametersResult {
  /** Security identifier. */
  securityId: string;
  /**
   * Security custom parameters after the change.
   * The full list of parameters as a result of this update.
   */
  contributorParameters: ContributorParameters[];
}

/**
 * Publish a new contract or publish changes of an existing contract.
 * Modification is incremental - only specified fields are modified, if the
 * field is not specified then it is not touched.
 * Some fields cannot be modified.
 */
export interface PublishContract {
  /** Security identifier for which contract is created. Unmodifiable. */
  securityId: string;
  /**
   * Contract trading date for derivatives
   * (local exchange date in time format, use date part only).
   */
  lastTradingDate: number;
  /** Source contract identifier for double-quoting. */
  sourceContractId: number;
  /**
   * Contract first notice date
   * (local exchange date in time format, use date part only).
   */
  firstNoticeDate: number;
  /**
   * Contract last delivery date
   * (local exchange date in time format, use date part only).
   */
  lastDeliveryDate: number;
  /**
   * Contract custom parameters.
   * Client includes all initial values.
   */
  contributorParameters: ContributorParameters[];
  /** Contract description. */
  description: string;
  /**
   * Publish a contract in preview mode. The contract will be visible only to
   * the current user, until it is published with the flag set to false or
   * omitted. Preview contracts do not persist between user sessions.
   */
  preview: boolean;
  /**
   * ID of the previously published contract.
   * Used for overriding contract parameters or final publishing.
   * Take the value from contract_id of previous PublishContractResult.
   * Note: Specifying this parameter leads to modification of the contract with
   * previous_contract_id. If the contract changes significantly (e.g. preview
   * flag changes), previous_contract_id may become invalid: notification about
   * the contract deletion will be sent if there is a subscription on the
   * contract, and a new contract id is returned in the response. In this case,
   * sending multiple requests with the same previous_contract_id before
   * getting a response will lead to the rejection of subsequent modifications.
   * It is recommended to send modifications one by one using the contract id
   * from the response of the last modification.
   */
  previousContractId: number;
  /**
   * Identifier of the currency contract used for price conversion.
   * Contract should belong to security specified in SecurityMetadata.currency_rate_instrument_group_name.
   * Leave this field empty if SecurityMetadata.currency_rate_instrument_group_name is not set.
   */
  currencyRateContractId: number;
  /**
   * Identifier of the currency contract used for hedging conversion.
   * Contract should belong to security specified in SecurityMetadata.currency_hedge_instrument_group_name.
   * Leave this field empty if SecurityMetadata.currency_hedge_instrument_group_name is not set.
   */
  currencyHedgeContractId: number;
  /**
   * Identifier of the contract used for hedging.
   * Can be different from source contract only if 'HedgeFirst' contributor parameter is explicitly set to false.
   * If not set, it is assumed to be the same as source contract.
   */
  hedgeWithContractId: number;
  /**
   * Identifiers of fields being cleared.
   * E.g. To clear first_notice_date field, put 5 in this collection.
   */
  clearedFields: number[];
}

/** Result of contract publishing. */
export interface PublishContractResult {
  /**
   * Assigned contract ID for the published contract if successful.
   * Complete meta-data can be requested by ContractMetadataRequest message.
   */
  contractId: number;
  /**
   * Symbol id for the published contract if successful.
   * Complete meta-data can be requested by SymbolRequest message.
   */
  symbolId: string;
}

/** Remove previous published contract from public access. */
export interface UnpublishContract {
  /** Contract identifier. */
  contractId: number;
  /**
   * OTC Contributor identifiers which are affected by the action.
   * Note: Unused in current version.
   */
  contributorIds: string[];
}

/** Result of contract unpublishing. */
export interface UnpublishContractResult {}

/** Get contract level parameters. */
export interface GetContractParameters {
  /** Contract identifier. */
  contractId: number;
  /** OTC Contributor identifiers. */
  contributorIds: string[];
}

/** Result of contract parameters request. */
export interface GetContractParametersResult {
  /** Contract identifier. */
  contractId: number;
  /** Contract custom parameters. */
  contributorParameters: ContributorParameters[];
}

function createBaseMetadataAdminRequest(): MetadataAdminRequest {
  return {
    requestId: '',
    getSecurityParameters: undefined,
    setSecurityParameters: undefined,
    publishContract: undefined,
    unpublishContract: undefined,
    getContractParameters: undefined,
  };
}

export const MetadataAdminRequest = {
  encode(message: MetadataAdminRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.getSecurityParameters !== undefined) {
      GetSecurityParameters.encode(message.getSecurityParameters, writer.uint32(18).fork()).ldelim();
    }
    if (message.setSecurityParameters !== undefined) {
      SetSecurityParameters.encode(message.setSecurityParameters, writer.uint32(26).fork()).ldelim();
    }
    if (message.publishContract !== undefined) {
      PublishContract.encode(message.publishContract, writer.uint32(42).fork()).ldelim();
    }
    if (message.unpublishContract !== undefined) {
      UnpublishContract.encode(message.unpublishContract, writer.uint32(50).fork()).ldelim();
    }
    if (message.getContractParameters !== undefined) {
      GetContractParameters.encode(message.getContractParameters, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MetadataAdminRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetadataAdminRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 2:
          message.getSecurityParameters = GetSecurityParameters.decode(reader, reader.uint32());
          break;
        case 3:
          message.setSecurityParameters = SetSecurityParameters.decode(reader, reader.uint32());
          break;
        case 5:
          message.publishContract = PublishContract.decode(reader, reader.uint32());
          break;
        case 6:
          message.unpublishContract = UnpublishContract.decode(reader, reader.uint32());
          break;
        case 7:
          message.getContractParameters = GetContractParameters.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MetadataAdminRequest {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      getSecurityParameters: isSet(object.getSecurityParameters)
        ? GetSecurityParameters.fromJSON(object.getSecurityParameters)
        : undefined,
      setSecurityParameters: isSet(object.setSecurityParameters)
        ? SetSecurityParameters.fromJSON(object.setSecurityParameters)
        : undefined,
      publishContract: isSet(object.publishContract) ? PublishContract.fromJSON(object.publishContract) : undefined,
      unpublishContract: isSet(object.unpublishContract) ? UnpublishContract.fromJSON(object.unpublishContract) : undefined,
      getContractParameters: isSet(object.getContractParameters)
        ? GetContractParameters.fromJSON(object.getContractParameters)
        : undefined,
    };
  },

  toJSON(message: MetadataAdminRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.getSecurityParameters !== undefined &&
      (obj.getSecurityParameters = message.getSecurityParameters
        ? GetSecurityParameters.toJSON(message.getSecurityParameters)
        : undefined);
    message.setSecurityParameters !== undefined &&
      (obj.setSecurityParameters = message.setSecurityParameters
        ? SetSecurityParameters.toJSON(message.setSecurityParameters)
        : undefined);
    message.publishContract !== undefined &&
      (obj.publishContract = message.publishContract ? PublishContract.toJSON(message.publishContract) : undefined);
    message.unpublishContract !== undefined &&
      (obj.unpublishContract = message.unpublishContract ? UnpublishContract.toJSON(message.unpublishContract) : undefined);
    message.getContractParameters !== undefined &&
      (obj.getContractParameters = message.getContractParameters
        ? GetContractParameters.toJSON(message.getContractParameters)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MetadataAdminRequest>, I>>(base?: I): MetadataAdminRequest {
    return MetadataAdminRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MetadataAdminRequest>, I>>(object: I): MetadataAdminRequest {
    const message = createBaseMetadataAdminRequest();
    message.requestId = object.requestId ?? '';
    message.getSecurityParameters =
      object.getSecurityParameters !== undefined && object.getSecurityParameters !== null
        ? GetSecurityParameters.fromPartial(object.getSecurityParameters)
        : undefined;
    message.setSecurityParameters =
      object.setSecurityParameters !== undefined && object.setSecurityParameters !== null
        ? SetSecurityParameters.fromPartial(object.setSecurityParameters)
        : undefined;
    message.publishContract =
      object.publishContract !== undefined && object.publishContract !== null
        ? PublishContract.fromPartial(object.publishContract)
        : undefined;
    message.unpublishContract =
      object.unpublishContract !== undefined && object.unpublishContract !== null
        ? UnpublishContract.fromPartial(object.unpublishContract)
        : undefined;
    message.getContractParameters =
      object.getContractParameters !== undefined && object.getContractParameters !== null
        ? GetContractParameters.fromPartial(object.getContractParameters)
        : undefined;
    return message;
  },
};

function createBaseMetadataAdminResult(): MetadataAdminResult {
  return {
    requestId: '',
    resultCode: 0,
    details: undefined,
    getSecurityParametersResult: undefined,
    setSecurityParametersResult: undefined,
    publishContractResult: undefined,
    unpublishContractResult: undefined,
    getContractParametersResult: undefined,
  };
}

export const MetadataAdminResult = {
  encode(message: MetadataAdminResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    if (message.getSecurityParametersResult !== undefined) {
      GetSecurityParametersResult.encode(message.getSecurityParametersResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.setSecurityParametersResult !== undefined) {
      SetSecurityParametersResult.encode(message.setSecurityParametersResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.publishContractResult !== undefined) {
      PublishContractResult.encode(message.publishContractResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.unpublishContractResult !== undefined) {
      UnpublishContractResult.encode(message.unpublishContractResult, writer.uint32(58).fork()).ldelim();
    }
    if (message.getContractParametersResult !== undefined) {
      GetContractParametersResult.encode(message.getContractParametersResult, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MetadataAdminResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMetadataAdminResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.getSecurityParametersResult = GetSecurityParametersResult.decode(reader, reader.uint32());
          break;
        case 5:
          message.setSecurityParametersResult = SetSecurityParametersResult.decode(reader, reader.uint32());
          break;
        case 6:
          message.publishContractResult = PublishContractResult.decode(reader, reader.uint32());
          break;
        case 7:
          message.unpublishContractResult = UnpublishContractResult.decode(reader, reader.uint32());
          break;
        case 8:
          message.getContractParametersResult = GetContractParametersResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MetadataAdminResult {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      getSecurityParametersResult: isSet(object.getSecurityParametersResult)
        ? GetSecurityParametersResult.fromJSON(object.getSecurityParametersResult)
        : undefined,
      setSecurityParametersResult: isSet(object.setSecurityParametersResult)
        ? SetSecurityParametersResult.fromJSON(object.setSecurityParametersResult)
        : undefined,
      publishContractResult: isSet(object.publishContractResult)
        ? PublishContractResult.fromJSON(object.publishContractResult)
        : undefined,
      unpublishContractResult: isSet(object.unpublishContractResult)
        ? UnpublishContractResult.fromJSON(object.unpublishContractResult)
        : undefined,
      getContractParametersResult: isSet(object.getContractParametersResult)
        ? GetContractParametersResult.fromJSON(object.getContractParametersResult)
        : undefined,
    };
  },

  toJSON(message: MetadataAdminResult): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.getSecurityParametersResult !== undefined &&
      (obj.getSecurityParametersResult = message.getSecurityParametersResult
        ? GetSecurityParametersResult.toJSON(message.getSecurityParametersResult)
        : undefined);
    message.setSecurityParametersResult !== undefined &&
      (obj.setSecurityParametersResult = message.setSecurityParametersResult
        ? SetSecurityParametersResult.toJSON(message.setSecurityParametersResult)
        : undefined);
    message.publishContractResult !== undefined &&
      (obj.publishContractResult = message.publishContractResult
        ? PublishContractResult.toJSON(message.publishContractResult)
        : undefined);
    message.unpublishContractResult !== undefined &&
      (obj.unpublishContractResult = message.unpublishContractResult
        ? UnpublishContractResult.toJSON(message.unpublishContractResult)
        : undefined);
    message.getContractParametersResult !== undefined &&
      (obj.getContractParametersResult = message.getContractParametersResult
        ? GetContractParametersResult.toJSON(message.getContractParametersResult)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MetadataAdminResult>, I>>(base?: I): MetadataAdminResult {
    return MetadataAdminResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MetadataAdminResult>, I>>(object: I): MetadataAdminResult {
    const message = createBaseMetadataAdminResult();
    message.requestId = object.requestId ?? '';
    message.resultCode = object.resultCode ?? 0;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.getSecurityParametersResult =
      object.getSecurityParametersResult !== undefined && object.getSecurityParametersResult !== null
        ? GetSecurityParametersResult.fromPartial(object.getSecurityParametersResult)
        : undefined;
    message.setSecurityParametersResult =
      object.setSecurityParametersResult !== undefined && object.setSecurityParametersResult !== null
        ? SetSecurityParametersResult.fromPartial(object.setSecurityParametersResult)
        : undefined;
    message.publishContractResult =
      object.publishContractResult !== undefined && object.publishContractResult !== null
        ? PublishContractResult.fromPartial(object.publishContractResult)
        : undefined;
    message.unpublishContractResult =
      object.unpublishContractResult !== undefined && object.unpublishContractResult !== null
        ? UnpublishContractResult.fromPartial(object.unpublishContractResult)
        : undefined;
    message.getContractParametersResult =
      object.getContractParametersResult !== undefined && object.getContractParametersResult !== null
        ? GetContractParametersResult.fromPartial(object.getContractParametersResult)
        : undefined;
    return message;
  },
};

function createBaseContributorParameters(): ContributorParameters {
  return { contributorId: '', parameters: [] };
}

export const ContributorParameters = {
  encode(message: ContributorParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contributorId !== '') {
      writer.uint32(10).string(message.contributorId);
    }
    for (const v of message.parameters) {
      ContributorParameter.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContributorParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributorParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contributorId = reader.string();
          break;
        case 2:
          message.parameters.push(ContributorParameter.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContributorParameters {
    return {
      contributorId: isSet(object.contributorId) ? String(object.contributorId) : '',
      parameters: Array.isArray(object?.parameters) ? object.parameters.map((e: any) => ContributorParameter.fromJSON(e)) : [],
    };
  },

  toJSON(message: ContributorParameters): unknown {
    const obj: any = {};
    message.contributorId !== undefined && (obj.contributorId = message.contributorId);
    if (message.parameters) {
      obj.parameters = message.parameters.map((e) => (e ? ContributorParameter.toJSON(e) : undefined));
    } else {
      obj.parameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContributorParameters>, I>>(base?: I): ContributorParameters {
    return ContributorParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContributorParameters>, I>>(object: I): ContributorParameters {
    const message = createBaseContributorParameters();
    message.contributorId = object.contributorId ?? '';
    message.parameters = object.parameters?.map((e) => ContributorParameter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseContributorParameter(): ContributorParameter {
  return { name: '', value: '', deleted: false, source: 0 };
}

export const ContributorParameter = {
  encode(message: ContributorParameter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    if (message.deleted === true) {
      writer.uint32(24).bool(message.deleted);
    }
    if (message.source !== 0) {
      writer.uint32(32).uint32(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContributorParameter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributorParameter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        case 3:
          message.deleted = reader.bool();
          break;
        case 4:
          message.source = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContributorParameter {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      value: isSet(object.value) ? String(object.value) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      source: isSet(object.source) ? Number(object.source) : 0,
    };
  },

  toJSON(message: ContributorParameter): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.source !== undefined && (obj.source = Math.round(message.source));
    return obj;
  },

  create<I extends Exact<DeepPartial<ContributorParameter>, I>>(base?: I): ContributorParameter {
    return ContributorParameter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContributorParameter>, I>>(object: I): ContributorParameter {
    const message = createBaseContributorParameter();
    message.name = object.name ?? '';
    message.value = object.value ?? '';
    message.deleted = object.deleted ?? false;
    message.source = object.source ?? 0;
    return message;
  },
};

function createBaseGetSecurityParameters(): GetSecurityParameters {
  return { securityId: '', contributorIds: [] };
}

export const GetSecurityParameters = {
  encode(message: GetSecurityParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    for (const v of message.contributorIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecurityParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecurityParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 2:
          message.contributorIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetSecurityParameters {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      contributorIds: Array.isArray(object?.contributorIds) ? object.contributorIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: GetSecurityParameters): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    if (message.contributorIds) {
      obj.contributorIds = message.contributorIds.map((e) => e);
    } else {
      obj.contributorIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecurityParameters>, I>>(base?: I): GetSecurityParameters {
    return GetSecurityParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetSecurityParameters>, I>>(object: I): GetSecurityParameters {
    const message = createBaseGetSecurityParameters();
    message.securityId = object.securityId ?? '';
    message.contributorIds = object.contributorIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetSecurityParametersResult(): GetSecurityParametersResult {
  return { securityId: '', contributorParameters: [] };
}

export const GetSecurityParametersResult = {
  encode(message: GetSecurityParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSecurityParametersResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSecurityParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 2:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetSecurityParametersResult {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetSecurityParametersResult): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSecurityParametersResult>, I>>(base?: I): GetSecurityParametersResult {
    return GetSecurityParametersResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetSecurityParametersResult>, I>>(object: I): GetSecurityParametersResult {
    const message = createBaseGetSecurityParametersResult();
    message.securityId = object.securityId ?? '';
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetSecurityParameters(): SetSecurityParameters {
  return { securityId: '', contributorParameters: [] };
}

export const SetSecurityParameters = {
  encode(message: SetSecurityParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSecurityParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSecurityParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 2:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSecurityParameters {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetSecurityParameters): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSecurityParameters>, I>>(base?: I): SetSecurityParameters {
    return SetSecurityParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetSecurityParameters>, I>>(object: I): SetSecurityParameters {
    const message = createBaseSetSecurityParameters();
    message.securityId = object.securityId ?? '';
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetSecurityParametersResult(): SetSecurityParametersResult {
  return { securityId: '', contributorParameters: [] };
}

export const SetSecurityParametersResult = {
  encode(message: SetSecurityParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSecurityParametersResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSecurityParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 2:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSecurityParametersResult {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetSecurityParametersResult): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSecurityParametersResult>, I>>(base?: I): SetSecurityParametersResult {
    return SetSecurityParametersResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetSecurityParametersResult>, I>>(object: I): SetSecurityParametersResult {
    const message = createBaseSetSecurityParametersResult();
    message.securityId = object.securityId ?? '';
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    return message;
  },
};

function createBasePublishContract(): PublishContract {
  return {
    securityId: '',
    lastTradingDate: 0,
    sourceContractId: 0,
    firstNoticeDate: 0,
    lastDeliveryDate: 0,
    contributorParameters: [],
    description: '',
    preview: false,
    previousContractId: 0,
    currencyRateContractId: 0,
    currencyHedgeContractId: 0,
    hedgeWithContractId: 0,
    clearedFields: [],
  };
}

export const PublishContract = {
  encode(message: PublishContract, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    if (message.lastTradingDate !== 0) {
      writer.uint32(24).sint64(message.lastTradingDate);
    }
    if (message.sourceContractId !== 0) {
      writer.uint32(32).uint32(message.sourceContractId);
    }
    if (message.firstNoticeDate !== 0) {
      writer.uint32(40).sint64(message.firstNoticeDate);
    }
    if (message.lastDeliveryDate !== 0) {
      writer.uint32(48).sint64(message.lastDeliveryDate);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.description !== '') {
      writer.uint32(66).string(message.description);
    }
    if (message.preview === true) {
      writer.uint32(72).bool(message.preview);
    }
    if (message.previousContractId !== 0) {
      writer.uint32(80).uint32(message.previousContractId);
    }
    if (message.currencyRateContractId !== 0) {
      writer.uint32(96).uint32(message.currencyRateContractId);
    }
    if (message.currencyHedgeContractId !== 0) {
      writer.uint32(104).uint32(message.currencyHedgeContractId);
    }
    if (message.hedgeWithContractId !== 0) {
      writer.uint32(112).uint32(message.hedgeWithContractId);
    }
    writer.uint32(90).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublishContract {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublishContract();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 3:
          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          break;
        case 4:
          message.sourceContractId = reader.uint32();
          break;
        case 5:
          message.firstNoticeDate = longToNumber(reader.sint64() as Long);
          break;
        case 6:
          message.lastDeliveryDate = longToNumber(reader.sint64() as Long);
          break;
        case 7:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        case 8:
          message.description = reader.string();
          break;
        case 9:
          message.preview = reader.bool();
          break;
        case 10:
          message.previousContractId = reader.uint32();
          break;
        case 12:
          message.currencyRateContractId = reader.uint32();
          break;
        case 13:
          message.currencyHedgeContractId = reader.uint32();
          break;
        case 14:
          message.hedgeWithContractId = reader.uint32();
          break;
        case 11:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }
          } else {
            message.clearedFields.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PublishContract {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      lastTradingDate: isSet(object.lastTradingDate) ? Number(object.lastTradingDate) : 0,
      sourceContractId: isSet(object.sourceContractId) ? Number(object.sourceContractId) : 0,
      firstNoticeDate: isSet(object.firstNoticeDate) ? Number(object.firstNoticeDate) : 0,
      lastDeliveryDate: isSet(object.lastDeliveryDate) ? Number(object.lastDeliveryDate) : 0,
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
      description: isSet(object.description) ? String(object.description) : '',
      preview: isSet(object.preview) ? Boolean(object.preview) : false,
      previousContractId: isSet(object.previousContractId) ? Number(object.previousContractId) : 0,
      currencyRateContractId: isSet(object.currencyRateContractId) ? Number(object.currencyRateContractId) : 0,
      currencyHedgeContractId: isSet(object.currencyHedgeContractId) ? Number(object.currencyHedgeContractId) : 0,
      hedgeWithContractId: isSet(object.hedgeWithContractId) ? Number(object.hedgeWithContractId) : 0,
      clearedFields: Array.isArray(object?.clearedFields) ? object.clearedFields.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: PublishContract): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    message.lastTradingDate !== undefined && (obj.lastTradingDate = Math.round(message.lastTradingDate));
    message.sourceContractId !== undefined && (obj.sourceContractId = Math.round(message.sourceContractId));
    message.firstNoticeDate !== undefined && (obj.firstNoticeDate = Math.round(message.firstNoticeDate));
    message.lastDeliveryDate !== undefined && (obj.lastDeliveryDate = Math.round(message.lastDeliveryDate));
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    message.description !== undefined && (obj.description = message.description);
    message.preview !== undefined && (obj.preview = message.preview);
    message.previousContractId !== undefined && (obj.previousContractId = Math.round(message.previousContractId));
    message.currencyRateContractId !== undefined && (obj.currencyRateContractId = Math.round(message.currencyRateContractId));
    message.currencyHedgeContractId !== undefined && (obj.currencyHedgeContractId = Math.round(message.currencyHedgeContractId));
    message.hedgeWithContractId !== undefined && (obj.hedgeWithContractId = Math.round(message.hedgeWithContractId));
    if (message.clearedFields) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    } else {
      obj.clearedFields = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PublishContract>, I>>(base?: I): PublishContract {
    return PublishContract.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PublishContract>, I>>(object: I): PublishContract {
    const message = createBasePublishContract();
    message.securityId = object.securityId ?? '';
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.sourceContractId = object.sourceContractId ?? 0;
    message.firstNoticeDate = object.firstNoticeDate ?? 0;
    message.lastDeliveryDate = object.lastDeliveryDate ?? 0;
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    message.description = object.description ?? '';
    message.preview = object.preview ?? false;
    message.previousContractId = object.previousContractId ?? 0;
    message.currencyRateContractId = object.currencyRateContractId ?? 0;
    message.currencyHedgeContractId = object.currencyHedgeContractId ?? 0;
    message.hedgeWithContractId = object.hedgeWithContractId ?? 0;
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    return message;
  },
};

function createBasePublishContractResult(): PublishContractResult {
  return { contractId: 0, symbolId: '' };
}

export const PublishContractResult = {
  encode(message: PublishContractResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.symbolId !== '') {
      writer.uint32(18).string(message.symbolId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublishContractResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublishContractResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.symbolId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PublishContractResult {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      symbolId: isSet(object.symbolId) ? String(object.symbolId) : '',
    };
  },

  toJSON(message: PublishContractResult): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.symbolId !== undefined && (obj.symbolId = message.symbolId);
    return obj;
  },

  create<I extends Exact<DeepPartial<PublishContractResult>, I>>(base?: I): PublishContractResult {
    return PublishContractResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PublishContractResult>, I>>(object: I): PublishContractResult {
    const message = createBasePublishContractResult();
    message.contractId = object.contractId ?? 0;
    message.symbolId = object.symbolId ?? '';
    return message;
  },
};

function createBaseUnpublishContract(): UnpublishContract {
  return { contractId: 0, contributorIds: [] };
}

export const UnpublishContract = {
  encode(message: UnpublishContract, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    for (const v of message.contributorIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpublishContract {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpublishContract();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.contributorIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UnpublishContract {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      contributorIds: Array.isArray(object?.contributorIds) ? object.contributorIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: UnpublishContract): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    if (message.contributorIds) {
      obj.contributorIds = message.contributorIds.map((e) => e);
    } else {
      obj.contributorIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpublishContract>, I>>(base?: I): UnpublishContract {
    return UnpublishContract.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UnpublishContract>, I>>(object: I): UnpublishContract {
    const message = createBaseUnpublishContract();
    message.contractId = object.contractId ?? 0;
    message.contributorIds = object.contributorIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseUnpublishContractResult(): UnpublishContractResult {
  return {};
}

export const UnpublishContractResult = {
  encode(_: UnpublishContractResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnpublishContractResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnpublishContractResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UnpublishContractResult {
    return {};
  },

  toJSON(_: UnpublishContractResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UnpublishContractResult>, I>>(base?: I): UnpublishContractResult {
    return UnpublishContractResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UnpublishContractResult>, I>>(_: I): UnpublishContractResult {
    const message = createBaseUnpublishContractResult();
    return message;
  },
};

function createBaseGetContractParameters(): GetContractParameters {
  return { contractId: 0, contributorIds: [] };
}

export const GetContractParameters = {
  encode(message: GetContractParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    for (const v of message.contributorIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetContractParameters {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetContractParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.contributorIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetContractParameters {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      contributorIds: Array.isArray(object?.contributorIds) ? object.contributorIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: GetContractParameters): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    if (message.contributorIds) {
      obj.contributorIds = message.contributorIds.map((e) => e);
    } else {
      obj.contributorIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetContractParameters>, I>>(base?: I): GetContractParameters {
    return GetContractParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetContractParameters>, I>>(object: I): GetContractParameters {
    const message = createBaseGetContractParameters();
    message.contractId = object.contractId ?? 0;
    message.contributorIds = object.contributorIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetContractParametersResult(): GetContractParametersResult {
  return { contractId: 0, contributorParameters: [] };
}

export const GetContractParametersResult = {
  encode(message: GetContractParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetContractParametersResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetContractParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetContractParametersResult {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetContractParametersResult): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetContractParametersResult>, I>>(base?: I): GetContractParametersResult {
    return GetContractParametersResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetContractParametersResult>, I>>(object: I): GetContractParametersResult {
    const message = createBaseGetContractParametersResult();
    message.contractId = object.contractId ?? 0;
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
