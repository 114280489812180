import { createSlice } from '@reduxjs/toolkit';
import { IQuote } from '../../types/IQuote';
import { RootState } from '../../types/Store';
import { fetchQuotes } from './thunks';
import ErrorToast from '../../components/Toast/ErrorToast';
import { LoadingStatuses } from '../LoadingStatuses';
import dayjs from 'dayjs';

export type QuotesStoreItem = {
  [symbol: string]: IQuote;
};

type QuotesSliceInitialStateType = {
  quotes: QuotesStoreItem;
  status: string;
  previousArguments: string;
  error: string | null;
  lastUpdated: string;
};

const initialState: QuotesSliceInitialStateType = {
  quotes: {},
  status: LoadingStatuses.Idle,
  previousArguments: '',
  error: null,
  lastUpdated: dayjs().toString(),
};

const slice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchQuotes.pending.toString()]: (state, action) => {
      state.previousArguments = action.meta.arg;
      state.status = LoadingStatuses.Loading;
    },
    [fetchQuotes.fulfilled.toString()]: (state, action) => {
      if (!action.payload.length && (action.meta.arg as string[]).some((x) => !!x)) {
        console.error(`Failed to fetch quotes for symbols: ${action.meta.arg}`);
      } else {
        state.quotes = { ...state.quotes, ...prepareQuotes(action.payload) };
      }
      state.status = LoadingStatuses.Idle;
      state.lastUpdated = dayjs().toString();
    },
    [fetchQuotes.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast("We couldn't get quotes for all of your crops. Is there a network issue?");
    },
  },
});

export function prepareQuotes(quotes: IQuote[]): QuotesStoreItem {
  const result: QuotesStoreItem = {};
  quotes.forEach((quote) => (result[quote.symbol] = { ...quote, lastUpdate: new Date().toString() }));
  return result;
}

export default slice.reducer;

export const selectAllQuotes = (state: RootState) => state.quotes.quotes;
