export enum InsurancePrice {
  BASE = 1,
  HARVEST = 2,
}

type InsurancePriceItem = {
  year: number;
  cropType: number;
  basePrice?: number;
  harvestPrice?: number;
};

const INSURANCE_PRICES: InsurancePriceItem[] = [
  // 2019
  { year: 2019, cropType: 1, basePrice: 4, harvestPrice: 3.9 },
  { year: 2019, cropType: 2, basePrice: 9.54, harvestPrice: 9.25 },
  { year: 2019, cropType: 3, basePrice: 5.74, harvestPrice: 4.63 },
  { year: 2019, cropType: 4, basePrice: 5.77, harvestPrice: 5.05 },
  { year: 2019, cropType: 5, basePrice: 5.72, harvestPrice: 5.04 },
  // 2020
  { year: 2020, cropType: 1, basePrice: 3.88, harvestPrice: 3.99 },
  { year: 2020, cropType: 2, basePrice: 9.17, harvestPrice: 10.54 },
  { year: 2020, cropType: 3, basePrice: 4.35, harvestPrice: 4.44 },
  { year: 2020, cropType: 4, basePrice: 5.56, harvestPrice: 5.06 },
  { year: 2020, cropType: 5, basePrice: 4.94, harvestPrice: 5.24 },
  // 2021
  { year: 2021, cropType: 1, basePrice: 4.59, harvestPrice: 5.37 },
  { year: 2021, cropType: 2, basePrice: 11.87, harvestPrice: 12.3 },
  { year: 2021, cropType: 3, basePrice: 4.9, harvestPrice: 9.86 },
  { year: 2021, cropType: 4, basePrice: 6.53, harvestPrice: 9.21 },
  { year: 2021, cropType: 5, basePrice: 5.6, harvestPrice: 6.68 },
  // 2022
  { year: 2022, cropType: 1, basePrice: 5.9, harvestPrice: 6.86 },
  { year: 2022, cropType: 2, basePrice: 14.33, harvestPrice: 13.81 },
  { year: 2022, cropType: 3, basePrice: 7.08, harvestPrice: 10.88 },
  { year: 2022, cropType: 4, basePrice: 9.19, harvestPrice: 8.95 },
  { year: 2022, cropType: 5, basePrice: 7.16, harvestPrice: 8.12 },
  // 2023
  { year: 2023, cropType: 1, basePrice: 5.91, harvestPrice: 4.88 },
  { year: 2023, cropType: 2, basePrice: 13.76, harvestPrice: 12.84 },
  { year: 2023, cropType: 3, basePrice: 8.79, harvestPrice: undefined },
  { year: 2023, cropType: 4, basePrice: 8.87, harvestPrice: undefined },
  { year: 2023, cropType: 5, basePrice: 8.45, harvestPrice: undefined },
];

export function getAllInsurancePrices() {
  return INSURANCE_PRICES;
}

export function getInsurancePrice(year: number, priceType: InsurancePrice, cropId: number): number | undefined {
  const price = INSURANCE_PRICES.find((p) => p.year === year && p.cropType === cropId);
  return priceType === InsurancePrice.BASE ? price?.basePrice : price?.harvestPrice;
}

export function hasInsurancePrice(year: number, priceType: InsurancePrice, cropId: number): boolean {
  return !!getInsurancePrice(year, priceType, cropId);
}
