import React from 'react';
import './Wizard.scss';
import Button from '../common/Button/Button';
import SelectWithLabel, { SelectorTypes } from '../common/SelectWithLabel/SelectWithLabel';
import { prepareCoveragePercentageForSelect } from '../../utils/coveragePercentage';
import { prepareOptionsForSelect } from '../../utils/optionsForSelect';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { IOperationCropWizard } from './Wizard';

export const PROTECTION_TYPES: { [key: number]: string } = {
  0: 'Yield',
  1: 'Revenue',
  2: 'Revenue - HPE',
};

const INSURANCE_LABEL = 'Crop Insurance Type';
const COVERAGE_LABEL = 'Coverage Level';

interface WizardInsuranceProps {
  handleBack: () => void;
  openNextStep: () => void;
  onSkip: () => void;
  operationCrops: IOperationCropWizard[];
  onChangeHandler: (data: { [key: string]: any }, cropId: number) => void;
}

export default function WizardInsurance(props: WizardInsuranceProps) {
  const { operationCrops, onChangeHandler, handleBack, openNextStep, onSkip } = props;
  const isMobileView = useMobileView(BREAKPOINTS.WIZARD_MOBILE);

  const insuranceTypeOptions = prepareOptionsForSelect(PROTECTION_TYPES, { label: 'None', value: null });
  const coverageLevelOptions = prepareCoveragePercentageForSelect();

  return (
    <div id="wizard-step3" className="wizard-card wizard-step3">
      <div className="wizard-card-header">
        <h3>What level of insurance do you typically carry?</h3>
        <p />
      </div>
      {operationCrops.length && (
        <div>
          <div id="wizard-step3-body" className="wizard-card-body">
            <div className={`wizard-table${isMobileView ? '-mobile' : ''}`}>
              {!isMobileView && (
                <div className="wizard-table-head">
                  <div className="wizard-table-row">
                    <span className="wizard-table-cell">Crop</span>
                    <span className="wizard-table-cell">{INSURANCE_LABEL}</span>
                    <span className="wizard-table-cell">{COVERAGE_LABEL}</span>
                  </div>
                </div>
              )}
              <div className="wizard-table-body">
                {operationCrops
                  .filter((crop: IOperationCropWizard) => crop.selected)
                  .map((crop: IOperationCropWizard) => (
                    <div className="wizard-table-row" key={crop.name}>
                      <span className="wizard-table-cell">{crop.name}</span>
                      <span className="wizard-table-cell">
                        <div
                          className="wizard-input-wrapper"
                          title={!crop.aphUOMPerAcre ? 'APH required to add insurance' : undefined}
                        >
                          <SelectWithLabel
                            label={isMobileView ? INSURANCE_LABEL : ''}
                            options={insuranceTypeOptions}
                            selected={insuranceTypeOptions.find((o) => o.value === crop.insuranceType)}
                            onChange={(e: SelectorTypes) => onChangeHandler({ insuranceType: e.value }, crop.marketingCropType)}
                            disabled={!crop.aphUOMPerAcre}
                          />
                        </div>
                      </span>
                      <span className="wizard-table-cell">
                        <div
                          className="wizard-input-wrapper"
                          title={!crop.aphUOMPerAcre ? 'APH required to add insurance' : undefined}
                        >
                          <SelectWithLabel<number>
                            label={isMobileView ? COVERAGE_LABEL : ''}
                            options={coverageLevelOptions}
                            selected={coverageLevelOptions.find((o) => o.value === crop.insurancePercent?.toNumber())}
                            onChange={(e: SelectorTypes<number>) => {
                              onChangeHandler({ insurancePercent: e.value }, crop.marketingCropType);
                            }}
                            disabled={!crop.aphUOMPerAcre}
                          />
                        </div>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="wizard-card-footer">
            <Button value="Skip Wizard" className="fr-button wizard-skip-button" onClick={onSkip} />
            <div>
              <Button value="Back" className="fr-button backGround-none wizard-back-button" onClick={handleBack} />
              <Button value="Next" className="fr-button backGround-red" onClick={openNextStep} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
