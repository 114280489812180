/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Decimal } from '../common/decimal';
import { Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';
import { ContributorParameters } from './metadata_admin_2';
import { StrategyDefinition } from './strategy_definition_2';

export const protobufPackage = 'metadata_2';

/** Way how to track the trader's open positions on a given contract. */
export enum PositionTrackingType {
  /** POSITION_TRACKING_TYPE_NET_POSITION - Difference between long and short open positions is tracked. */
  POSITION_TRACKING_TYPE_NET_POSITION = 1,
  /** POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE - Long and short open positions are tracked separately. */
  POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE = 2,
  /**
   * POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE - Long and short as well as today and yesterday open positions are tracked separately.
   * The explicit order instruction to close either today or yesterday is taken into account.
   */
  POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE = 3,
  UNRECOGNIZED = -1,
}

export function positionTrackingTypeFromJSON(object: any): PositionTrackingType {
  switch (object) {
    case 1:
    case 'POSITION_TRACKING_TYPE_NET_POSITION':
      return PositionTrackingType.POSITION_TRACKING_TYPE_NET_POSITION;
    case 2:
    case 'POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE':
      return PositionTrackingType.POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE;
    case 3:
    case 'POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE':
      return PositionTrackingType.POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PositionTrackingType.UNRECOGNIZED;
  }
}

export function positionTrackingTypeToJSON(object: PositionTrackingType): string {
  switch (object) {
    case PositionTrackingType.POSITION_TRACKING_TYPE_NET_POSITION:
      return 'POSITION_TRACKING_TYPE_NET_POSITION';
    case PositionTrackingType.POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE:
      return 'POSITION_TRACKING_TYPE_LONG_SHORT_WITH_IMPLIED_CLOSE';
    case PositionTrackingType.POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE:
      return 'POSITION_TRACKING_TYPE_LONG_SHORT_WITH_EXPLICIT_CLOSE';
    case PositionTrackingType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Recommended display mode for prices.
 * The rules are applied according to the display scale of a contract.
 * For combined scales like Eighths of Thirty Seconds the mode is applied only to the minor fractions (Eighths) while
 * the major fractions (Thirty Seconds) always shown in PRICE_DISPLAY_MODE_NUMERATOR mode.
 * Sample: TRUNCATED mode for 108 22/32 with extra 7/8 of 1/32 (108 + 22/32 + 7/256)
 * is shown as 108'228 where 8 is calculated as 7/8 -> truncate (0.875) -> 8 (see TRUNCATED sample below).
 */
export enum PriceDisplayMode {
  /**
   * PRICE_DISPLAY_MODE_NUMERATOR - The numerator of the fractional part is shown directly.
   * Samples: for 3/8 the fractional part is shown as '3', for 7/16 - '07', for 15/100 - '15' etc.
   */
  PRICE_DISPLAY_MODE_NUMERATOR = 0,
  /**
   * PRICE_DISPLAY_MODE_ROUNDED - Rounded decimal. In this mode the fractional part is converted to the decimal fraction
   * and then rounded to the minimum digits which are necessary to recognize the price.
   * Samples: 3/8 -> round(0.375) -> '4', 7/8 ¨C round(0.875) -> '9', 11/16 -> round(0.6875) -> '69'.
   * It means the same as PRICE_DISPLAY_MODE_NUMERATOR for decimal scales.
   */
  PRICE_DISPLAY_MODE_ROUNDED = 1,
  /**
   * PRICE_DISPLAY_MODE_TRUNCATED - Truncated decimal. In this mode the fractional part is converted to the decimal fraction
   * and then truncated to the minimum digits which are necessary to recognize the price.
   * Samples: 3/8 -> truncate(0.375) -> '3', 7/8 -> truncate (0.875) -> '8', 11/16 -> truncate (0.6875) -> '68'.
   * It means the same as PRICE_DISPLAY_MODE_NUMERATOR for decimal scales.
   */
  PRICE_DISPLAY_MODE_TRUNCATED = 2,
  UNRECOGNIZED = -1,
}

export function priceDisplayModeFromJSON(object: any): PriceDisplayMode {
  switch (object) {
    case 0:
    case 'PRICE_DISPLAY_MODE_NUMERATOR':
      return PriceDisplayMode.PRICE_DISPLAY_MODE_NUMERATOR;
    case 1:
    case 'PRICE_DISPLAY_MODE_ROUNDED':
      return PriceDisplayMode.PRICE_DISPLAY_MODE_ROUNDED;
    case 2:
    case 'PRICE_DISPLAY_MODE_TRUNCATED':
      return PriceDisplayMode.PRICE_DISPLAY_MODE_TRUNCATED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PriceDisplayMode.UNRECOGNIZED;
  }
}

export function priceDisplayModeToJSON(object: PriceDisplayMode): string {
  switch (object) {
    case PriceDisplayMode.PRICE_DISPLAY_MODE_NUMERATOR:
      return 'PRICE_DISPLAY_MODE_NUMERATOR';
    case PriceDisplayMode.PRICE_DISPLAY_MODE_ROUNDED:
      return 'PRICE_DISPLAY_MODE_ROUNDED';
    case PriceDisplayMode.PRICE_DISPLAY_MODE_TRUNCATED:
      return 'PRICE_DISPLAY_MODE_TRUNCATED';
    case PriceDisplayMode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Describes period value type.
 * See example in ContractMetadata.listing_period_type and ContractMetadata.listing_period_value.
 */
export enum PeriodType {
  PERIOD_TYPE_MONTH = 0,
  PERIOD_TYPE_QUARTER = 1,
  PERIOD_TYPE_SEMI_ANNUAL = 2,
  PERIOD_TYPE_YEAR = 3,
  PERIOD_TYPE_DAY_OF_WEEK = 4,
  PERIOD_TYPE_DAY_OF_MONTH = 5,
  PERIOD_TYPE_DAY_OF_YEAR = 6,
  PERIOD_TYPE_WEEK_OF_MONTH = 7,
  PERIOD_TYPE_WEEK_OF_YEAR = 8,
  PERIOD_TYPE_SECOND = 9,
  PERIOD_TYPE_MINUTE = 10,
  PERIOD_TYPE_HOUR = 11,
  PERIOD_TYPE_DATE = 12,
  UNRECOGNIZED = -1,
}

export function periodTypeFromJSON(object: any): PeriodType {
  switch (object) {
    case 0:
    case 'PERIOD_TYPE_MONTH':
      return PeriodType.PERIOD_TYPE_MONTH;
    case 1:
    case 'PERIOD_TYPE_QUARTER':
      return PeriodType.PERIOD_TYPE_QUARTER;
    case 2:
    case 'PERIOD_TYPE_SEMI_ANNUAL':
      return PeriodType.PERIOD_TYPE_SEMI_ANNUAL;
    case 3:
    case 'PERIOD_TYPE_YEAR':
      return PeriodType.PERIOD_TYPE_YEAR;
    case 4:
    case 'PERIOD_TYPE_DAY_OF_WEEK':
      return PeriodType.PERIOD_TYPE_DAY_OF_WEEK;
    case 5:
    case 'PERIOD_TYPE_DAY_OF_MONTH':
      return PeriodType.PERIOD_TYPE_DAY_OF_MONTH;
    case 6:
    case 'PERIOD_TYPE_DAY_OF_YEAR':
      return PeriodType.PERIOD_TYPE_DAY_OF_YEAR;
    case 7:
    case 'PERIOD_TYPE_WEEK_OF_MONTH':
      return PeriodType.PERIOD_TYPE_WEEK_OF_MONTH;
    case 8:
    case 'PERIOD_TYPE_WEEK_OF_YEAR':
      return PeriodType.PERIOD_TYPE_WEEK_OF_YEAR;
    case 9:
    case 'PERIOD_TYPE_SECOND':
      return PeriodType.PERIOD_TYPE_SECOND;
    case 10:
    case 'PERIOD_TYPE_MINUTE':
      return PeriodType.PERIOD_TYPE_MINUTE;
    case 11:
    case 'PERIOD_TYPE_HOUR':
      return PeriodType.PERIOD_TYPE_HOUR;
    case 12:
    case 'PERIOD_TYPE_DATE':
      return PeriodType.PERIOD_TYPE_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PeriodType.UNRECOGNIZED;
  }
}

export function periodTypeToJSON(object: PeriodType): string {
  switch (object) {
    case PeriodType.PERIOD_TYPE_MONTH:
      return 'PERIOD_TYPE_MONTH';
    case PeriodType.PERIOD_TYPE_QUARTER:
      return 'PERIOD_TYPE_QUARTER';
    case PeriodType.PERIOD_TYPE_SEMI_ANNUAL:
      return 'PERIOD_TYPE_SEMI_ANNUAL';
    case PeriodType.PERIOD_TYPE_YEAR:
      return 'PERIOD_TYPE_YEAR';
    case PeriodType.PERIOD_TYPE_DAY_OF_WEEK:
      return 'PERIOD_TYPE_DAY_OF_WEEK';
    case PeriodType.PERIOD_TYPE_DAY_OF_MONTH:
      return 'PERIOD_TYPE_DAY_OF_MONTH';
    case PeriodType.PERIOD_TYPE_DAY_OF_YEAR:
      return 'PERIOD_TYPE_DAY_OF_YEAR';
    case PeriodType.PERIOD_TYPE_WEEK_OF_MONTH:
      return 'PERIOD_TYPE_WEEK_OF_MONTH';
    case PeriodType.PERIOD_TYPE_WEEK_OF_YEAR:
      return 'PERIOD_TYPE_WEEK_OF_YEAR';
    case PeriodType.PERIOD_TYPE_SECOND:
      return 'PERIOD_TYPE_SECOND';
    case PeriodType.PERIOD_TYPE_MINUTE:
      return 'PERIOD_TYPE_MINUTE';
    case PeriodType.PERIOD_TYPE_HOUR:
      return 'PERIOD_TYPE_HOUR';
    case PeriodType.PERIOD_TYPE_DATE:
      return 'PERIOD_TYPE_DATE';
    case PeriodType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** CQG base instrument types eligible for user defined instrument requests. */
export enum CQGInstrumentType {
  /**
   * CQG_INSTRUMENT_TYPE_FUTURE - Future or other instruments defined as a future in CQG, e.g. for some
   * exchanges, this includes forwards.
   */
  CQG_INSTRUMENT_TYPE_FUTURE = 1,
  /** CQG_INSTRUMENT_TYPE_CALL_OPTION - Call option. */
  CQG_INSTRUMENT_TYPE_CALL_OPTION = 2,
  /** CQG_INSTRUMENT_TYPE_PUT_OPTION - Put option. */
  CQG_INSTRUMENT_TYPE_PUT_OPTION = 3,
  UNRECOGNIZED = -1,
}

export function cQGInstrumentTypeFromJSON(object: any): CQGInstrumentType {
  switch (object) {
    case 1:
    case 'CQG_INSTRUMENT_TYPE_FUTURE':
      return CQGInstrumentType.CQG_INSTRUMENT_TYPE_FUTURE;
    case 2:
    case 'CQG_INSTRUMENT_TYPE_CALL_OPTION':
      return CQGInstrumentType.CQG_INSTRUMENT_TYPE_CALL_OPTION;
    case 3:
    case 'CQG_INSTRUMENT_TYPE_PUT_OPTION':
      return CQGInstrumentType.CQG_INSTRUMENT_TYPE_PUT_OPTION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CQGInstrumentType.UNRECOGNIZED;
  }
}

export function cQGInstrumentTypeToJSON(object: CQGInstrumentType): string {
  switch (object) {
    case CQGInstrumentType.CQG_INSTRUMENT_TYPE_FUTURE:
      return 'CQG_INSTRUMENT_TYPE_FUTURE';
    case CQGInstrumentType.CQG_INSTRUMENT_TYPE_CALL_OPTION:
      return 'CQG_INSTRUMENT_TYPE_CALL_OPTION';
    case CQGInstrumentType.CQG_INSTRUMENT_TYPE_PUT_OPTION:
      return 'CQG_INSTRUMENT_TYPE_PUT_OPTION';
    case CQGInstrumentType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Settlement method. */
export enum SettlementMethod {
  /** SETTLEMENT_METHOD_CASH - Cash settled. */
  SETTLEMENT_METHOD_CASH = 1,
  /** SETTLEMENT_METHOD_PHYSICAL - Physically delivered. */
  SETTLEMENT_METHOD_PHYSICAL = 2,
  UNRECOGNIZED = -1,
}

export function settlementMethodFromJSON(object: any): SettlementMethod {
  switch (object) {
    case 1:
    case 'SETTLEMENT_METHOD_CASH':
      return SettlementMethod.SETTLEMENT_METHOD_CASH;
    case 2:
    case 'SETTLEMENT_METHOD_PHYSICAL':
      return SettlementMethod.SETTLEMENT_METHOD_PHYSICAL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SettlementMethod.UNRECOGNIZED;
  }
}

export function settlementMethodToJSON(object: SettlementMethod): string {
  switch (object) {
    case SettlementMethod.SETTLEMENT_METHOD_CASH:
      return 'SETTLEMENT_METHOD_CASH';
    case SettlementMethod.SETTLEMENT_METHOD_PHYSICAL:
      return 'SETTLEMENT_METHOD_PHYSICAL';
    case SettlementMethod.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Exercise style. */
export enum ExerciseStyle {
  /** EXERCISE_STYLE_EUROPEAN - European exercise style. */
  EXERCISE_STYLE_EUROPEAN = 1,
  /** EXERCISE_STYLE_AMERICAN - American exercise style. */
  EXERCISE_STYLE_AMERICAN = 2,
  UNRECOGNIZED = -1,
}

export function exerciseStyleFromJSON(object: any): ExerciseStyle {
  switch (object) {
    case 1:
    case 'EXERCISE_STYLE_EUROPEAN':
      return ExerciseStyle.EXERCISE_STYLE_EUROPEAN;
    case 2:
    case 'EXERCISE_STYLE_AMERICAN':
      return ExerciseStyle.EXERCISE_STYLE_AMERICAN;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ExerciseStyle.UNRECOGNIZED;
  }
}

export function exerciseStyleToJSON(object: ExerciseStyle): string {
  switch (object) {
    case ExerciseStyle.EXERCISE_STYLE_EUROPEAN:
      return 'EXERCISE_STYLE_EUROPEAN';
    case ExerciseStyle.EXERCISE_STYLE_AMERICAN:
      return 'EXERCISE_STYLE_AMERICAN';
    case ExerciseStyle.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Pricing convention aka return type. */
export enum PricingConvention {
  /** PRICING_CONVENTION_PRICE - Price return. */
  PRICING_CONVENTION_PRICE = 1,
  /** PRICING_CONVENTION_GROSS - Gross return. */
  PRICING_CONVENTION_GROSS = 2,
  /** PRICING_CONVENTION_NET - Net return. */
  PRICING_CONVENTION_NET = 3,
  UNRECOGNIZED = -1,
}

export function pricingConventionFromJSON(object: any): PricingConvention {
  switch (object) {
    case 1:
    case 'PRICING_CONVENTION_PRICE':
      return PricingConvention.PRICING_CONVENTION_PRICE;
    case 2:
    case 'PRICING_CONVENTION_GROSS':
      return PricingConvention.PRICING_CONVENTION_GROSS;
    case 3:
    case 'PRICING_CONVENTION_NET':
      return PricingConvention.PRICING_CONVENTION_NET;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PricingConvention.UNRECOGNIZED;
  }
}

export function pricingConventionToJSON(object: PricingConvention): string {
  switch (object) {
    case PricingConvention.PRICING_CONVENTION_PRICE:
      return 'PRICING_CONVENTION_PRICE';
    case PricingConvention.PRICING_CONVENTION_GROSS:
      return 'PRICING_CONVENTION_GROSS';
    case PricingConvention.PRICING_CONVENTION_NET:
      return 'PRICING_CONVENTION_NET';
    case PricingConvention.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Source of closing price. */
export enum CloseSource {
  /** CLOSE_SOURCE_LAST_QUOTE - Last quote except settlement. */
  CLOSE_SOURCE_LAST_QUOTE = 1,
  /** CLOSE_SOURCE_SETTLEMENT - Settlement. */
  CLOSE_SOURCE_SETTLEMENT = 2,
  /** CLOSE_SOURCE_EXCHANGE_CLOSE - Exchange close. */
  CLOSE_SOURCE_EXCHANGE_CLOSE = 3,
  UNRECOGNIZED = -1,
}

export function closeSourceFromJSON(object: any): CloseSource {
  switch (object) {
    case 1:
    case 'CLOSE_SOURCE_LAST_QUOTE':
      return CloseSource.CLOSE_SOURCE_LAST_QUOTE;
    case 2:
    case 'CLOSE_SOURCE_SETTLEMENT':
      return CloseSource.CLOSE_SOURCE_SETTLEMENT;
    case 3:
    case 'CLOSE_SOURCE_EXCHANGE_CLOSE':
      return CloseSource.CLOSE_SOURCE_EXCHANGE_CLOSE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CloseSource.UNRECOGNIZED;
  }
}

export function closeSourceToJSON(object: CloseSource): string {
  switch (object) {
    case CloseSource.CLOSE_SOURCE_LAST_QUOTE:
      return 'CLOSE_SOURCE_LAST_QUOTE';
    case CloseSource.CLOSE_SOURCE_SETTLEMENT:
      return 'CLOSE_SOURCE_SETTLEMENT';
    case CloseSource.CLOSE_SOURCE_EXCHANGE_CLOSE:
      return 'CLOSE_SOURCE_EXCHANGE_CLOSE';
    case CloseSource.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Supported instrument group types. */
export enum InstrumentGroupType {
  /** INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY - Exchange strategies (a.k.a. User-defined strategies). */
  INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY = 1,
  UNRECOGNIZED = -1,
}

export function instrumentGroupTypeFromJSON(object: any): InstrumentGroupType {
  switch (object) {
    case 1:
    case 'INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY':
      return InstrumentGroupType.INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return InstrumentGroupType.UNRECOGNIZED;
  }
}

export function instrumentGroupTypeToJSON(object: InstrumentGroupType): string {
  switch (object) {
    case InstrumentGroupType.INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY:
      return 'INSTRUMENT_GROUP_TYPE_EXCHANGE_STRATEGY';
    case InstrumentGroupType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Request to resolve a symbol pattern or specific symbol identifier
 * to a specific contract with optional subscription for meta-data updates.
 * Symbol resolution rules:
 *   a) If the requested symbol points to a symbol prefix rather than a specific contract
 *      then it is resolved into the most active contract for this symbol prefix (e.g. 'EP' is resolved to 'EPU3').
 *      The update is sent when an active contract is changed (e.g. the update might contain the following 'EPZ3').
 *   b) If the requested symbol points to an expired contract SymbolResolutionReport is returned with 'deleted' field
 *      set to True.
 *   c) If the requested symbol points to a specific contract that is tradable right now then it is resolved to itself.
 *      Update is sent only when meta-data for such contract is changed or expired.
 *      In case of expiration SymbolResolutionReport is returned with 'deleted' field set to True.
 *   d) If requested symbol points to an option instrument without specific month and strike
 *      it is resolved to the lead (first non-spot) month of the underlying future or its own front month
 *      if the underlying contract is not a future (e.g. 'C.EP' is resolved to 'C.EPU131600').
 *      Update is sent only when the resolved option contract is expired
 *      (strike is not updated to follow underlying contract market price).
 *   e) If requested symbol is a symbol prefix that follows '?N' or '??N' (where N is number, e.g. EP?2)
 *      then it is resolved to Nth nearest contract.
 *      N = 1 means 'front' contract, N = 2 means the nearest contract that follows the front, etc.
 *      '?' and '??' are different for futures only
 *      and '?' means Nth nearest regular future and '??' means Nth nearest spot or regular future.
 *      Update is sent when the front contract expired
 *      and replaced with the following contract (relative position change).
 *      N = 0 is a special case that means 'most active' contract which works the same way as (a).
 *      If requested symbol is a symbol prefix that follows '?(-N)' or '??(-N)' then it is resolved to Nth previous
 *      contract.
 *   f) If the requested short symbol is resolved to multiple instruments, the returned instrument will be chosen
 *      based on supplied lists of preferred instrument types and preferred countries:
 *      * If both lists are supplied, the type preference will be applied before the country preference;
 *      * If only the type list is supplied, it will be applied before the default country preference;
 *      * If only the country list is supplied, the default type preference will be applied before
 *        the supplied country preference;
 *      * If neither list is supplied, the default type and country preferences are applied.
 *      For example, 'AMP' can be resolved to 'S.US.AMP' or 'S.AU.AMP' depending on preferred countries
 *      having preferred instrument types 'S'.
 * NOTE: Available and active contract lists are updated within 24 hours
 *       of an expiring contract's final trading session.
 *       Meta-data updates for affected contracts, when a subscription is present, are sent when the list updates occur.
 *       Initial resolution requests use the same lists, so the best way to avoid inconsistency
 *       and unnecessary server load is to subscribe and process updates when notified.
 */
export interface SymbolResolutionRequest {
  /**
   * Symbol pattern to resolve.
   * It is a required field.
   */
  symbol: string;
  /**
   * Comma-separated list of preferred instrument types.
   * Type is encoded as a single character:
   *    F - Future
   *    C - Call Option
   *    P - Put Option
   *    S - Equity
   *    T - Bond
   *    U - Strategy
   *    X - Cash and others
   * Example: 'F,C,P,X'
   */
  preferredTypes: string;
  /**
   * Comma-separated list of preferred countries.
   * Two-letter ISO 3166-1 country codes or some CQG-specific exchange codes.
   * Example: 'US,AU'
   */
  preferredCountries: string;
}

/** Symbol resolution result or update. */
export interface SymbolResolutionReport {
  /** Resolved contract meta-data. */
  contractMetadata: ContractMetadata | undefined;
  /** Contract is expired (used for updates). */
  deleted: boolean;
}

/** Request contract metadata by contract ID. */
export interface ContractMetadataRequest {
  /** Contract ID (ContractMetadata.contract_id) to request the metadata. */
  contractId: number;
}

/** Report with the requested contract metadata. */
export interface ContractMetadataReport {
  /** Requested metadata if successfully found. */
  contractMetadata: ContractMetadata | undefined;
}

/** Describes tick size and value, and the range of prices they apply in. */
export interface TickSizeByPrice {
  /** Size or a single market tick in correct price format. */
  tickSize: number;
  /**
   * Contract tick money value in the contract's currency.
   * Zero if not applicable (e.g. for indexes).
   */
  tickValue: number;
  /**
   * Boundary of a half-open price range.
   * Minimum for positive values but maximum for negative values.
   * - Negative value defines (prev-price <- boundary_price] range
   * - Positive value defines [boundary_price -> next-price) range
   * - Zero value defines (highest-negative-price <- 0] and [0 -> lowest-positive-price)
   * The price is in correct price format.
   */
  boundaryPrice: number;
}

/** Describes tick size and value applied within some range of dates. */
export interface TickSizeByDateTillExpiration {
  /**
   * Id of the record.  Starts from 0.  The record with id of 0 applies immediately before contract expiration, id of 1
   * applies before 0th, etc.
   */
  id: number;
  /** Size or a single market tick in correct price format. */
  tickSize: number;
  /**
   * Contract tick money value in the contract's currency.
   * Zero if not applicable (e.g. for indexes).
   */
  tickValue: number;
}

/** Metadata used for real-time and trading data conversion. */
export interface ConversionMetadata {
  /**
   * Identifier of the currency contract (ContractMetadata.contract_id)
   * used for price conversion.
   */
  currencyRateContractId: number;
  /**
   * Identifier of the currency contract (ContractMetadata.contract_id)
   * used for hedging conversion.
   */
  currencyHedgeContractId: number;
}

/**
 * Contract metadata.
 * Client must have a subscription on ContractMetadata updates in case its fields are used (e.g. its correct_price_scale
 * field when processing RealTimeMarketData). It can be done e.g. via ContractMetadataRequest.
 */
export interface ContractMetadata {
  /** Server assigned ID for a contract, it is not the same across sessions. */
  contractId: number;
  /** Full contract symbol. */
  contractSymbol: string;
  /** Full contract symbol in CQG dialect. */
  cqgContractSymbol: string;
  /**
   * Scale to convert prices from this protocol to correct prices
   * (correctPrice = price * correctPriceScale).
   */
  correctPriceScale: number;
  /**
   * Display Price Scale is a number that defines conversion of float correct price to
   * a 'native' integer display format and back.
   * Below is the table of possible scale values.
   * -------------------------------------------------------
   * | Scale |       Name            |    Description      |
   * -------------------------------------------------------
   * | 0     |One                     |Don't scale         |
   * | 1     |TenMinusOne             |Divide by 10        |
   * | 2     |TenMinusTwo             |Divide by 100       |
   * | 3     |TenMinusThree           |Divide by 1000      |
   * | 4     |TenMinusFour            |Divide by 10000     |
   * | 5     |TenMinusFive            |Divide by 100000    |
   * | 6     |TenMinusSix             |Divide by 1000000   |
   * | 7     |TenMinusSeven           |Divide by 10000000  |
   * | 8     |TenMinusEight           |Divide by 100000000 |
   * | 9     |TenMinusNine            |Divide by 1000000000|
   * | 10    |TenMinusTen             |Divide by 10^10     |
   * | 11    |TenMinusEleven          |Divide by 10^11     |
   * | 12    |TenMinusTwelve          |Divide by 10^12     |
   * | 13    |TenMinusThirteen        |Divide by 10^13     |
   * | 14    |TenMinusFourteen        |Divide by 10^14     |
   * | 15    |TenMinusFifteen         |Divide by 10^15     |
   * | 101   |Halves                  |Divide by 2         |
   * | 102   |Fourths                 |Divide by 4         |
   * | 103   |Eighths                 |Divide by 8         |
   * | 104   |Sixteenths              |Divide by 16        |
   * | 105   |ThirtySeconds           |Divide by 32        |
   * | 106   |SixtyFourths            |Divide by 64        |
   * | 107   |OneHundredTwentyEighths |Divide by 128       |
   * | 108   |TwoHundredFiftySixths   |Divide by 256       |
   * | 109   |FiveHundredTwelths      |Divide by 512       |
   * | 110   |OneThousandTwentyFourths|Divide by 1024      |
   * | 201   |HalfThirtySeconds       |Similar to 64ths    |
   * | 202   |QuarterThirtySeconds    |Similar to 128ths   |
   * | 203   |EighthThirtySeconds     |Similar to 256ths   |
   * | 204   |HalfSixtyFourths        |Similar to 128ths   |
   * | 205   |QuarterSixtyFourths     |Similar to 256ths   |
   * | 206   |SixteenthThirtySeconds  |Similar to 512ths   |
   * -------------------------------------------------------
   */
  displayPriceScale: number;
  /** Contract description. */
  description: string;
  /**
   * Use description instead.
   *
   * @deprecated
   */
  extendedDescription: string;
  /** Contract display name. */
  title: string;
  /** Size or a single market tick in correct price format. */
  tickSize: number;
  /**
   * Contract currency code.
   * Empty string if not applicable (e.g. for indexes).
   */
  currency: string;
  /**
   * Contract tick money value in the contract's currency.
   * Zero if not applicable (e.g. for indexes).
   */
  tickValue: number;
  /** CFI code (Classification of Financial Instruments, ISO 10962). */
  cfiCode: string;
  /** True if this contract is the most active one for derivatives. */
  isMostActive: boolean;
  /**
   * Contract trading date for derivatives
   * (local exchange date in time format, use date part only).
   */
  lastTradingDate: number;
  /**
   * Contract first notice date
   * (local exchange date in time format, use date part only).
   */
  firstNoticeDate: number;
  /** Underlying contract symbol (e.g. for options and equities). */
  underlyingContractSymbol: string;
  /** This field is associated with MarginStyle enum type. */
  marginStyle: number;
  /** Name of a group of contracts that share the same properties (e.g. commodity name for futures and options). */
  instrumentGroupName: string;
  /**
   * ID of a session information this contract is related to.
   * The field is set to -1 if session information is not available.
   */
  sessionInfoId: number;
  /** Market segment MIC (ISO 10383) of the contract (if available), e.g. 'GLBX'. */
  mic: string;
  /** Market segment MIC description (ISO 10383) of the contract (if available), e.g. 'CME GLOBEX'. */
  micDescription: string;
  /**
   * If a user has access to delayed data of the contract
   * then this parameter is set to the delay value in milliseconds.
   */
  marketDataDelay: number;
  /**
   * If user is not allowed to receive quotes then End of Day (EOD) data for this contract can be allowed.
   * In case of real-time market data subscriptions only level MARKET_VALUES is supported.
   * In case of time bar requests only Daily (or larger) bars are supported.
   * Note: Data for current and previous trading days are available only if corresponding trading day
   * was closed |end_of_day_delay| milliseconds ago.
   */
  endOfDayDelay: number;
  /** Instrument group name without the prefix. */
  shortInstrumentGroupName: string;
  /** Description of the instrument group. */
  instrumentGroupDescription: string;
  /**
   * Variable tick size by contract price (if available for the contract).
   * If variable tick sizes by contract price are available, tick_size and tick_value specify the minimal tick size
   * and value among these.
   * Only one of tick_sizes_by_price and tick_sizes_by_dte can be specified.
   */
  tickSizesByPrice: TickSizeByPrice[];
  /**
   * Variable tick size by date till expiration (if available for the contract).  Sorted by id in ascending order.
   * If variable tick sizes by DTE are available, tick_size and tick_value specify the tick size and value applicable at
   * the moment of symbol resolution.
   * Only one of tick_sizes_by_price and tick_sizes_by_dte can be specified.
   */
  tickSizesByDte: TickSizeByDateTillExpiration[];
  /** Specifies which rule of TickSizeByDateTillExpiration applies now. */
  tickSizesByDteId: number;
  /** Option strike display value. */
  strike: number;
  /** Option strike price value (directly in correct format). */
  strikePrice: number;
  /**
   * Dialect of the contract symbol.
   * 0 - CQG dialect.
   */
  dialectId: string;
  /** Two-letter exchange country code (ISO 3166-1 alpha 2). */
  countryCode: string;
  /** Strategy definition if the contract is of a synthetic or a user defined strategy. */
  strategyDefinition: StrategyDefinition | undefined;
  /**
   * The deliverable quantity of commodities or financial instruments underlying contract.
   * E.g. "1,000 U.S. Barrels (42,000) Gallons", "125,000 Euro"
   */
  contractSize: string;
  /**
   * Setting which regulates how to calculate contract open positions.
   * POSITION_TRACKING_TYPE_NET_POSITION, if not set.
   */
  positionTracking: number;
  /** Setting which requires whether every contract order has to specify SpeculationType instruction or not. */
  speculationTypeRequired: boolean;
  /**
   * Month letter and 2-digit year identifying the maturity month of the contract (if applicable).
   * Doesn't depend on dialect.
   * Month and corresponding letter:
   * January   F
   * February  G
   * March     H
   * April     J
   * May       K
   * June      M
   * July      N
   * August    Q
   * September U
   * October   V
   * November  X
   * December  Z
   */
  maturityMonthYear: string;
  /**
   * Contract's maturity date (e.g. for daily futures, when maturity_month_year is not enough).
   * (local exchange date in time format, use date part only).
   */
  maturityDate: number;
  /** Recommended display mode for prices. See the PriceDisplayMode description. */
  priceDisplayMode: PriceDisplayMode;
  /** Currency code. For forex contracts only. Specifies the currency bought or sold. */
  foreignCurrency: string;
  /**
   * Specifies a volume unit of a contract, similar to correct_price_scale.
   * If a message contains a volume as uint64 (like Quote or TimeBar), its correct volume is calculated as:
   *   correct_volume = Quote.volume * volume_scale.
   * If a message contains a volume as cqg.Decimal, it is the correct volume.
   * If a contract has no fixed scale, this value is 1, and only cqg.Decimal volumes must be used.
   *
   * @deprecated
   */
  volumeScale: Decimal | undefined;
  /**
   * Specifies the recommended representation of volumes to users:
   *  - negative numbers - -N - mean that N decimal digits should be shown.
   *  - positive numbers - N - mean that N digits can be hidden behind a unit prefix (or unit symbol),
   *    e.g. if N = 3, volumes can be shown as "12k".
   */
  volumeDisplayExponent: number;
  /** Trade volume is required to be a multiple of trade_size_increment. */
  tradeSizeIncrement: Decimal | undefined;
  /**
   * True if DOM views should be inverted: low numbers are at the top, high numbers are at the bottom.
   * This field is usually set for fixed income contracts with "prices are yields" model.
   */
  hasInvertedPriceLadder: boolean;
  /**
   * The denominator (numerator is 1) of the DOM ladder compression ratio
   * to compress the ladder to eliminate all the extra/non-quoting values.
   */
  domLadderCompressionRatio: number;
  /**
   * Whether to expect that prices may appear not on the nominal tick prices
   * (not products of any tick_size: ordinal, variable, etc.) to changes DOM UI behavior.
   * Format such prices according to display_price_scale rule.
   */
  expectOffTickPrices: boolean;
  /** Specifies whether exchange provides volume data or not. */
  hasExchangeVolume: boolean;
  /**
   * True if the contract supports yields and you can request them with MarketDataSubscription.include_yields.
   * Note: In case the contract has "prices are yields" model this field is false and yields are provided
   * through the price field.
   */
  hasYields: boolean;
  /**
   * The ISIN code acts an identifier for the securities.
   * And as such is the only common denominator securities identification number that is universally recognized.
   */
  isin: string;
  /**
   * Reference value for initial margin, expressed as money value in the contract's currency.
   * Note the value is informational only, not updated real-time, and does not
   * consider the account's portfolio or risk settings/parameters.
   */
  initialMargin: number;
  /**
   * Reference value for maintenance margin (required to maintain 1 lot position
   * in this contract), expressed as money value in the contract's currency.
   * Note the value is informational only, not updated real-time, and does not
   * consider the account's portfolio or risk settings/parameters.
   */
  maintenanceMargin: number;
  /** Contract size in the number of units (where defined). Unit is defined by contract_size_unit filed. */
  contractSizeInUnits: Decimal | undefined;
  /** Unit label for the contract size. */
  contractSizeUnit: Text | undefined;
  /**
   * Contract last delivery date
   * (local exchange date in time format, use date part only).
   */
  lastDeliveryDate: number;
  /** Optional custom contract parameters per associated OTC contributor (if any). */
  contributorParameters: ContributorParameters[];
  /** Type of listing period. This field is associated with PeriodType enum type. */
  listingPeriodType: number;
  /** Value of listing period in listing_period_type units. */
  listingPeriodValue: number;
  /** ID of the symbol the contract represents (Symbol.id attribute). */
  symbolId: string;
  /**
   * If contract metadata is "deleted", corresponding contract id can't be referred to in requests anymore
   * (this happens e.g. when published contract gets unpublished).
   */
  deleted: boolean;
  /** ID used for ContributorMetadataRequest. */
  contributorGroupId: number;
  /**
   * If this instrument is double-quoted the field contains a contract id
   * to request corresponding source contract metadata
   * (see ContractMetadataRequest and ContractMetadata.contract_id).
   */
  sourceContractId: number;
  /** Equity issuer. */
  issuer: string;
  /** Option contract's maturity ID. */
  optionMaturityId: string;
  /** Metadata used for real-time and trading data conversion. */
  conversionMetadata: ConversionMetadata | undefined;
  /** ID used for MarketStateMetadataRequest. */
  marketStateGroupId: number;
  /**
   * Settlement method.
   * This field is associated with SettlementMethod enum type.
   */
  settlementMethod: number;
  /**
   * Exercise style, for options only.
   * This field is associated with ExerciseStyle enum type.
   */
  exerciseStyle: number;
  /**
   * Pricing convention.
   * This field is associated with PricingConvention enum type.
   */
  pricingConvention: number;
  /** True if contract represents user-defined instrument. */
  isUserDefinedInstrument: boolean;
  /**
   * List of tick types used in bar building.
   * This field is associated with BarBuildingTickType enum type.
   * Note: BAR_BUILDING_TICK_TYPE_SETTLEMENT is used only in build time bars.
   */
  barBuildingTickTypes: number[];
  /** Describes what the contract is quoted in. E.g. "USD per Barrel", "Cents per Bushel" */
  quotedIn: string;
  /** Symbol ID (Symbol.id) of corresponding product if it was assigned to the contract. */
  productSymbolId: string;
  /**
   * Identifier of the contract (ContractMetadata.contract_id)
   * used for hedging, if applicable.
   * If not set, it is assumed to be the same as source contract.
   */
  hedgeWithContractId: number;
  /**
   * If a contract is a synthetic version of real one,
   * this field contains a ContractMetadata.contract_id of real contract.
   */
  actualFutureContractId: number;
  /** Exchange ID this instrument belongs to. */
  exchangeId: number;
  /** True if the contract supports continuation bars. */
  supportsContinuation: boolean;
  /**
   * Instrument business type identifier.
   * Note: this identifier is not guaranteed to be stable, so corresponding instrument
   * business type information should be obtained from results of SymbolCategoryListRequest
   * (see SymbolCategory.instrument_business_type_id field), instead of saving/using this id
   * between sessions to WebAPI.
   */
  instrumentBusinessTypeId: number;
  /**
   * Sources of closing price.
   * Closing price can be found in message MarketValues and historical bars (message TimeBar and other).
   * Corresponding field name in related messages is scaled_close_price.
   * Order of sources defines what is used as closing price if several sources are available (from highest to
   * lowest priority).
   * This field is associated with CloseSource enum type.
   */
  closeSources: number[];
}

/** Margin style (for options). */
export enum ContractMetadata_MarginStyle {
  /** MARGIN_STYLE_FUTURE - The premium is paid on exercise/ expiry (position is included to OTE calculation). */
  MARGIN_STYLE_FUTURE = 1,
  /** MARGIN_STYLE_PREMIUM - Premium is subtracted from account balance when order is filled (position is included to MVO/UPL calculation). */
  MARGIN_STYLE_PREMIUM = 2,
  UNRECOGNIZED = -1,
}

export function contractMetadata_MarginStyleFromJSON(object: any): ContractMetadata_MarginStyle {
  switch (object) {
    case 1:
    case 'MARGIN_STYLE_FUTURE':
      return ContractMetadata_MarginStyle.MARGIN_STYLE_FUTURE;
    case 2:
    case 'MARGIN_STYLE_PREMIUM':
      return ContractMetadata_MarginStyle.MARGIN_STYLE_PREMIUM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ContractMetadata_MarginStyle.UNRECOGNIZED;
  }
}

export function contractMetadata_MarginStyleToJSON(object: ContractMetadata_MarginStyle): string {
  switch (object) {
    case ContractMetadata_MarginStyle.MARGIN_STYLE_FUTURE:
      return 'MARGIN_STYLE_FUTURE';
    case ContractMetadata_MarginStyle.MARGIN_STYLE_PREMIUM:
      return 'MARGIN_STYLE_PREMIUM';
    case ContractMetadata_MarginStyle.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for contributor metadata. */
export interface ContributorMetadataRequest {
  /** Contributor group ID from ContractMetadata. */
  contributorGroupId: number;
}

/** Report with contributor metadata. */
export interface ContributorMetadataReport {
  /**
   * List of contributor metadata.
   * Note: ContributorMetadata list may be empty even for valid contributor_group_id.
   */
  contributorMetadata: ContributorMetadata[];
}

/** Contributor metadata. */
export interface ContributorMetadata {
  /**
   * ID used in TradeAttributes as buyer and seller, DetailedDOMOrder as contributor_id.
   * Unique for its Contributor Group ID.
   */
  contributorId: number;
  /** Contributor name. */
  name: string;
  /** Contributor description. */
  description: string;
  /** Contributor is deleted (used for updates). */
  deleted: boolean;
}

/** Request a list of option maturities by an underlying contract */
export interface OptionMaturityListRequest {
  underlyingContractId: number;
}

/**
 * Report with a list of option maturities.
 * When the list is updated by a subscription
 * (e.g. some option maturity is expired or a new one appears)
 * the list includes only new or deleted items.
 */
export interface OptionMaturityListReport {
  /** List of option maturities. */
  optionMaturities: OptionMaturityMetadata[];
}

/**
 * Option maturity metadata.
 * It represents a list of option strikes (puts and calls) with the same underlying instrument and expiration date.
 */
export interface OptionMaturityMetadata {
  /** Option maturity identifier. */
  id: string;
  /** Option maturity name. */
  name: string;
  /** Option maturity description. */
  description: string;
  /** CFI code (Classification of Financial Instruments, ISO 10962) if appropriate. */
  cfiCode: string;
  /** Deleted flag is used in updates when the option maturity is removed (e.g. expired). */
  deleted: boolean;
  /** Last trading date (local exchange date in time format, use date part only). */
  lastTradingDate: number;
  /** Last trading date and time. */
  lastTradingDateTimeUtcTimestamp: Date | undefined;
  /**
   * Month letter and 2-digit year identifying the maturity month.
   * Note: look at comment for maturity_month_year from ContractMetadata for further info.
   */
  maturityMonthYear: string;
  /** Name of a group of instruments that share the same properties (e.g. commodity name). */
  instrumentGroupName: string;
  /**
   * Whether the option maturity instrument group has option strikes.
   * It relates to InstrumentGroupRequest request.
   * Some option strikes can be related to option maturity, but they can be absent in the group.
   * E.g., option strikes range is changed and strikes out of the range don't appear in the group.
   */
  instrumentGroupEmpty: boolean;
  /** Listing period type. This field is associated with PeriodType enum type. */
  listingPeriodType: number;
  /** Listing period value in listing_period_type units. */
  listingPeriodValue: number;
  reserved1: boolean;
}

/** Request a group of instruments by an instrument group identifier. */
export interface InstrumentGroupRequest {
  /**
   * Identifier of instrument group. It can be one of:
   * * Option maturity id to get a group of option strikes (see OptionMaturityMetadata.id)
   * * Instrument group's name to get a group of futures (e.g. 'F.US.EP', see
   *   ContractMetadata.instrument_group_name or SecurityMetadata.source_instrument_group_name)
   */
  instrumentGroupId: string;
}

/** Report with a group of instruments. */
export interface InstrumentGroupReport {
  /** Instruments. */
  instruments: InstrumentGroupItem[];
}

/** Instrument group item. */
export interface InstrumentGroupItem {
  /** Instrument group item identifier. */
  id: string;
  /** Instrument name. The value matches ContractMetadata.contract_symbol. */
  name: string;
  /** Instrument description. */
  description: string;
  /** CFI code (Classification of Financial Instruments, ISO 10962) if appropriate. */
  cfiCode: string;
  /** Contract meta-data. */
  contractMetadata: ContractMetadata | undefined;
  /** Deleted flag is used in updates when the instrument is removed from the group (e.g. expired). */
  deleted: boolean;
  /**
   * Last trading date for derivatives if applicable.
   * (local exchange date in time format, use date part only).
   */
  lastTradingDate: number;
  /**
   * Month letter and 2-digit year identifying the maturity month of the instrument.
   * Note: look at comment for maturity_month_year from ContractMetadata for further info.
   */
  maturityMonthYear: string;
  /** Name of a group of instruments that share the same properties (e.g. commodity name for futures and options). */
  instrumentGroupName: string;
  reserved1: boolean;
  reserved2: string;
}

/** Request for Market State metadata. */
export interface MarketStateMetadataRequest {
  /** Market State Group ID from ContractMetadata. */
  marketStateGroupId: number;
}

/**
 * Market State metadata.
 * The first complete report contains initial snapshot, which may be saved as local state.
 * All subsequent messages contain updates that may be applied to the local state.
 * Subscribed requests in case of server-side communication issues will receive
 * an InformationReport with status_code "StatusCode.STATUS_CODE_DISCONNECTED".
 * After issues resolution subscribed requests will receive an InformationReport
 * with status_code "StatusCode.STATUS_CODE_SUBSCRIBED" and with a list of metadata updates, if there are any.
 */
export interface MarketStateMetadataReport {
  marketStateAttributeMetadata: MarketStateAttributeMetadata[];
}

/**
 * Market State attribute metadata
 * Note: To apply a metadata update one must follow rules:
 *  1. If an update contains a MarketStateAttributeMetadata with a combination of "name" and
 *     "level" fields values, which does not exist in the local state, then it must be added to it.
 *  2. If an update contains a MarketStateAttributeMetadata with a combination of "name" and
 *     "level fields values, which already exists in the local state, then:
 *     i. If the field "deleted" is present and set to true - this Attribute should be deleted from the local state.
 *     ii. Otherwise updates for value_metadata should be applied (see MarketStateValueMetadata comments).
 */
export interface MarketStateAttributeMetadata {
  /** Attribute name */
  name: string;
  /** Attribute level in attributes hierarchy */
  level: number;
  /** Attribute possible values metadata */
  valueMetadata: MarketStateValueMetadata[];
  /** If present and true - attribute should be deleted from metadata */
  deleted: boolean;
}

/**
 * Market State attribute value metadata.
 * Note: To apply a value metadata update one must follow rules:
 *  1. If a received update has MarketStateValueMetadata with the "value" field value, which does not exist
 *     in the local state, then this metadata must be added to it.
 *  2. If a received update has MarketStateValueMetadata with the "value" field value, which exists
 *     in the local state, then:
 *     i. If the field "deleted" present and set to true - this MarketStateValueMetadata should be deleted
 *        from the local state.
 *     ii. If the field "description" value is different from the value stored in the local state - it should be set
 *         to the new value.
 */
export interface MarketStateValueMetadata {
  /** Attribute value */
  value: string;
  /** Attribute value description */
  description: string;
  /** If present and true - attribute value metadata should be deleted */
  deleted: boolean;
}

/** Request for exchange metadata. */
export interface ExchangeMetadataRequest {}

/** Report with exchange metadata. */
export interface ExchangeMetadataReport {
  /** Requested exchange metadata. */
  exchangeMetadata: ExchangeMetadata[];
}

/** Exchange metadata. */
export interface ExchangeMetadata {
  /**
   * Exchange ID.
   * It is a required field.
   */
  exchangeId: number;
  /** This field is associated with ContributorType enum type. */
  contributorType: number;
  /** Exchange abbreviation. */
  abbreviation: string;
  /** Exchange name. */
  name: string;
  /** Description. */
  description: string;
  /** If present and true - exchange metadata should be deleted. */
  deleted: boolean;
}

/** Contributor type. */
export enum ExchangeMetadata_ContributorType {
  CONTRIBUTOR_TYPE_UNDEFINED = 0,
  /** CONTRIBUTOR_TYPE_US_EQUITY_STYLE - Contributor is a quote source. */
  CONTRIBUTOR_TYPE_US_EQUITY_STYLE = 1,
  /** CONTRIBUTOR_TYPE_OTC_STYLE - Contributor is a buyer. */
  CONTRIBUTOR_TYPE_OTC_STYLE = 2,
  /** CONTRIBUTOR_TYPE_CLUSTER_STYLE - Contributor is a seller. */
  CONTRIBUTOR_TYPE_CLUSTER_STYLE = 3,
  UNRECOGNIZED = -1,
}

export function exchangeMetadata_ContributorTypeFromJSON(object: any): ExchangeMetadata_ContributorType {
  switch (object) {
    case 0:
    case 'CONTRIBUTOR_TYPE_UNDEFINED':
      return ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_UNDEFINED;
    case 1:
    case 'CONTRIBUTOR_TYPE_US_EQUITY_STYLE':
      return ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_US_EQUITY_STYLE;
    case 2:
    case 'CONTRIBUTOR_TYPE_OTC_STYLE':
      return ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_OTC_STYLE;
    case 3:
    case 'CONTRIBUTOR_TYPE_CLUSTER_STYLE':
      return ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_CLUSTER_STYLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ExchangeMetadata_ContributorType.UNRECOGNIZED;
  }
}

export function exchangeMetadata_ContributorTypeToJSON(object: ExchangeMetadata_ContributorType): string {
  switch (object) {
    case ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_UNDEFINED:
      return 'CONTRIBUTOR_TYPE_UNDEFINED';
    case ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_US_EQUITY_STYLE:
      return 'CONTRIBUTOR_TYPE_US_EQUITY_STYLE';
    case ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_OTC_STYLE:
      return 'CONTRIBUTOR_TYPE_OTC_STYLE';
    case ExchangeMetadata_ContributorType.CONTRIBUTOR_TYPE_CLUSTER_STYLE:
      return 'CONTRIBUTOR_TYPE_CLUSTER_STYLE';
    case ExchangeMetadata_ContributorType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Request for group of instruments based on given list of securities. */
export interface InstrumentGroupBySecuritiesRequest {
  /**
   * IDs of the securities, can be from different exchanges.
   * Can be queried with ExchangeSecuritiesRequest.
   */
  securityIds: string[];
}

/** Report with a group of instruments. */
export interface InstrumentGroupBySecuritiesReport {
  /** List of instruments. */
  instruments: InstrumentGroupItem[];
}

/** Request for group of instruments based on given exchange ID and type of instruments. */
export interface InstrumentGroupByExchangeRequest {
  /**
   * Exchange ID.
   * It is a required field.
   */
  exchangeId: number;
  /**
   * This field is associated with InstrumentGroupType enum type.
   * It is a required field.
   */
  instrumentGroupType: number;
}

/** Report with a group of instruments. */
export interface InstrumentGroupByExchangeReport {
  /** List of instruments. */
  instruments: InstrumentGroupItem[];
}

/** Request for list of securities for a particular exchange and type of instruments. */
export interface ExchangeSecuritiesRequest {
  /**
   * Exchange ID.
   * It is a required field.
   */
  exchangeId: number;
  /**
   * This field is associated with InstrumentGroupType enum type.
   * It is a required field.
   */
  instrumentGroupType: number;
}

/** Exchange securities report. */
export interface ExchangeSecuritiesReport {
  /** List of securities. */
  exchangeSecurities: SecurityMetadata[];
}

/**
 * Metadata used for real-time and trading data processing for a group of contracts.
 * Note that for some contracts tick_size, tick_value or contract_size_in_units values on
 * ContractMetadata level might differ from corresponding values in ProcessingMetadata
 * (e.g., tick_size and tick_value for VTS contracts or contract_size_in_units for some option contracts).
 * In these cases ContractMetadata values should be used.
 */
export interface ProcessingMetadata {
  /** Currency code. */
  currency: string;
  /** Size or a single market tick in correct price format. */
  tickSize: number;
  /** Tick money value in corresponding currency. See ProcessingMetadata.currency. */
  tickValue: number;
  /** Contract size in the number of units (where defined). Unit is defined by contract_size_unit field. */
  contractSizeInUnits: Decimal | undefined;
  /** Unit label for the contract size. */
  contractSizeUnit: Text | undefined;
  /**
   * Security whose contracts should be used to fill PublishContract.currency_rate_contract_id.
   * Use InstrumentGroupRequest to query contracts by group name.
   */
  currencyRateInstrumentGroupName: string;
  /**
   * Security whose contracts should be used to fill PublishContract.currency_hedge_contractd_id.
   * Use InstrumentGroupRequest to query contracts by group name.
   */
  currencyHedgeInstrumentGroupName: string;
}

/** Security metadata. */
export interface SecurityMetadata {
  /**
   * Security ID.
   * It is a required field.
   */
  securityId: string;
  /**
   * Security name.
   * It is a required field.
   */
  name: string;
  /** Security description. */
  description: string;
  /** CFI code (Classification of Financial Instruments, ISO 10962). */
  cfiCode: string;
  /**
   * Deprecated and should not be used. Use ProcessingMetadata.currency instead.
   *
   * @deprecated
   */
  currency: string;
  /**
   * Deprecated and should not be used. Use ProcessingMetadata.tick_size instead.
   *
   * @deprecated
   */
  tickSize: number;
  /**
   * Deprecated and should not be used. Use ProcessingMetadata.tick_value instead.
   *
   * @deprecated
   */
  tickValue: number;
  /**
   * Deprecated and should not be used.
   *
   * @deprecated
   */
  periodType: number;
  /**
   * Deprecated and should not be used.
   *
   * @deprecated
   */
  periodValue: number;
  /**
   * Deprecated and should not be used. Use ProcessingMetadata.contract_size_in_units instead.
   *
   * @deprecated
   */
  contractSizeInUnits: Decimal | undefined;
  /**
   * Deprecated and should not be used. Use ProcessingMetadata.contract_size_unit instead.
   *
   * @deprecated
   */
  contractSizeUnit: Text | undefined;
  /** Optional custom security parameters per associated OTC contributor (if any). */
  contributorParameters: ContributorParameters[];
  /** ID of the symbol the security represents (Symbol.id attribute). */
  symbolId: string;
  /**
   * If this security represents a list of double-quoted contracts, the field contains identifier
   * to request list of corresponding source instruments (see InstrumentGroupRequest).
   */
  sourceInstrumentGroupName: string;
  /**
   * Metadata used for real-time and trading data processing.
   * There might be different metadata properties for different contracts within the security.
   * Note: This collection is empty when message received as a part of ExchangeSecuritiesReport.
   */
  processingMetadata: ProcessingMetadata[];
  /** Security is deleted (used for updates). */
  deleted: boolean;
}

function createBaseSymbolResolutionRequest(): SymbolResolutionRequest {
  return { symbol: '', preferredTypes: '', preferredCountries: '' };
}

export const SymbolResolutionRequest = {
  encode(message: SymbolResolutionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbol !== '') {
      writer.uint32(10).string(message.symbol);
    }
    if (message.preferredTypes !== '') {
      writer.uint32(18).string(message.preferredTypes);
    }
    if (message.preferredCountries !== '') {
      writer.uint32(26).string(message.preferredCountries);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolResolutionRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolResolutionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbol = reader.string();
          break;
        case 2:
          message.preferredTypes = reader.string();
          break;
        case 3:
          message.preferredCountries = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolResolutionRequest {
    return {
      symbol: isSet(object.symbol) ? String(object.symbol) : '',
      preferredTypes: isSet(object.preferredTypes) ? String(object.preferredTypes) : '',
      preferredCountries: isSet(object.preferredCountries) ? String(object.preferredCountries) : '',
    };
  },

  toJSON(message: SymbolResolutionRequest): unknown {
    const obj: any = {};
    message.symbol !== undefined && (obj.symbol = message.symbol);
    message.preferredTypes !== undefined && (obj.preferredTypes = message.preferredTypes);
    message.preferredCountries !== undefined && (obj.preferredCountries = message.preferredCountries);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolResolutionRequest>, I>>(base?: I): SymbolResolutionRequest {
    return SymbolResolutionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolResolutionRequest>, I>>(object: I): SymbolResolutionRequest {
    const message = createBaseSymbolResolutionRequest();
    message.symbol = object.symbol ?? '';
    message.preferredTypes = object.preferredTypes ?? '';
    message.preferredCountries = object.preferredCountries ?? '';
    return message;
  },
};

function createBaseSymbolResolutionReport(): SymbolResolutionReport {
  return { contractMetadata: undefined, deleted: false };
}

export const SymbolResolutionReport = {
  encode(message: SymbolResolutionReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(10).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(16).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SymbolResolutionReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSymbolResolutionReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        case 2:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SymbolResolutionReport {
    return {
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: SymbolResolutionReport): unknown {
    const obj: any = {};
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<SymbolResolutionReport>, I>>(base?: I): SymbolResolutionReport {
    return SymbolResolutionReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SymbolResolutionReport>, I>>(object: I): SymbolResolutionReport {
    const message = createBaseSymbolResolutionReport();
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseContractMetadataRequest(): ContractMetadataRequest {
  return { contractId: 0 };
}

export const ContractMetadataRequest = {
  encode(message: ContractMetadataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractMetadataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContractMetadataRequest {
    return { contractId: isSet(object.contractId) ? Number(object.contractId) : 0 };
  },

  toJSON(message: ContractMetadataRequest): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractMetadataRequest>, I>>(base?: I): ContractMetadataRequest {
    return ContractMetadataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContractMetadataRequest>, I>>(object: I): ContractMetadataRequest {
    const message = createBaseContractMetadataRequest();
    message.contractId = object.contractId ?? 0;
    return message;
  },
};

function createBaseContractMetadataReport(): ContractMetadataReport {
  return { contractMetadata: undefined };
}

export const ContractMetadataReport = {
  encode(message: ContractMetadataReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractMetadataReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractMetadataReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContractMetadataReport {
    return {
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
    };
  },

  toJSON(message: ContractMetadataReport): unknown {
    const obj: any = {};
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractMetadataReport>, I>>(base?: I): ContractMetadataReport {
    return ContractMetadataReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContractMetadataReport>, I>>(object: I): ContractMetadataReport {
    const message = createBaseContractMetadataReport();
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    return message;
  },
};

function createBaseTickSizeByPrice(): TickSizeByPrice {
  return { tickSize: 0, tickValue: 0, boundaryPrice: 0 };
}

export const TickSizeByPrice = {
  encode(message: TickSizeByPrice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tickSize !== 0) {
      writer.uint32(9).double(message.tickSize);
    }
    if (message.tickValue !== 0) {
      writer.uint32(17).double(message.tickValue);
    }
    if (message.boundaryPrice !== 0) {
      writer.uint32(25).double(message.boundaryPrice);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TickSizeByPrice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTickSizeByPrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.tickSize = reader.double();
          break;
        case 2:
          message.tickValue = reader.double();
          break;
        case 3:
          message.boundaryPrice = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TickSizeByPrice {
    return {
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      tickValue: isSet(object.tickValue) ? Number(object.tickValue) : 0,
      boundaryPrice: isSet(object.boundaryPrice) ? Number(object.boundaryPrice) : 0,
    };
  },

  toJSON(message: TickSizeByPrice): unknown {
    const obj: any = {};
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.tickValue !== undefined && (obj.tickValue = message.tickValue);
    message.boundaryPrice !== undefined && (obj.boundaryPrice = message.boundaryPrice);
    return obj;
  },

  create<I extends Exact<DeepPartial<TickSizeByPrice>, I>>(base?: I): TickSizeByPrice {
    return TickSizeByPrice.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TickSizeByPrice>, I>>(object: I): TickSizeByPrice {
    const message = createBaseTickSizeByPrice();
    message.tickSize = object.tickSize ?? 0;
    message.tickValue = object.tickValue ?? 0;
    message.boundaryPrice = object.boundaryPrice ?? 0;
    return message;
  },
};

function createBaseTickSizeByDateTillExpiration(): TickSizeByDateTillExpiration {
  return { id: 0, tickSize: 0, tickValue: 0 };
}

export const TickSizeByDateTillExpiration = {
  encode(message: TickSizeByDateTillExpiration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.tickSize !== 0) {
      writer.uint32(17).double(message.tickSize);
    }
    if (message.tickValue !== 0) {
      writer.uint32(25).double(message.tickValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TickSizeByDateTillExpiration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTickSizeByDateTillExpiration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.tickSize = reader.double();
          break;
        case 3:
          message.tickValue = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TickSizeByDateTillExpiration {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      tickValue: isSet(object.tickValue) ? Number(object.tickValue) : 0,
    };
  },

  toJSON(message: TickSizeByDateTillExpiration): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.tickValue !== undefined && (obj.tickValue = message.tickValue);
    return obj;
  },

  create<I extends Exact<DeepPartial<TickSizeByDateTillExpiration>, I>>(base?: I): TickSizeByDateTillExpiration {
    return TickSizeByDateTillExpiration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TickSizeByDateTillExpiration>, I>>(object: I): TickSizeByDateTillExpiration {
    const message = createBaseTickSizeByDateTillExpiration();
    message.id = object.id ?? 0;
    message.tickSize = object.tickSize ?? 0;
    message.tickValue = object.tickValue ?? 0;
    return message;
  },
};

function createBaseConversionMetadata(): ConversionMetadata {
  return { currencyRateContractId: 0, currencyHedgeContractId: 0 };
}

export const ConversionMetadata = {
  encode(message: ConversionMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currencyRateContractId !== 0) {
      writer.uint32(8).uint32(message.currencyRateContractId);
    }
    if (message.currencyHedgeContractId !== 0) {
      writer.uint32(16).uint32(message.currencyHedgeContractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConversionMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConversionMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.currencyRateContractId = reader.uint32();
          break;
        case 2:
          message.currencyHedgeContractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConversionMetadata {
    return {
      currencyRateContractId: isSet(object.currencyRateContractId) ? Number(object.currencyRateContractId) : 0,
      currencyHedgeContractId: isSet(object.currencyHedgeContractId) ? Number(object.currencyHedgeContractId) : 0,
    };
  },

  toJSON(message: ConversionMetadata): unknown {
    const obj: any = {};
    message.currencyRateContractId !== undefined && (obj.currencyRateContractId = Math.round(message.currencyRateContractId));
    message.currencyHedgeContractId !== undefined && (obj.currencyHedgeContractId = Math.round(message.currencyHedgeContractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<ConversionMetadata>, I>>(base?: I): ConversionMetadata {
    return ConversionMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConversionMetadata>, I>>(object: I): ConversionMetadata {
    const message = createBaseConversionMetadata();
    message.currencyRateContractId = object.currencyRateContractId ?? 0;
    message.currencyHedgeContractId = object.currencyHedgeContractId ?? 0;
    return message;
  },
};

function createBaseContractMetadata(): ContractMetadata {
  return {
    contractId: 0,
    contractSymbol: '',
    cqgContractSymbol: '',
    correctPriceScale: 0,
    displayPriceScale: 0,
    description: '',
    extendedDescription: '',
    title: '',
    tickSize: 0,
    currency: '',
    tickValue: 0,
    cfiCode: '',
    isMostActive: false,
    lastTradingDate: 0,
    firstNoticeDate: 0,
    underlyingContractSymbol: '',
    marginStyle: 0,
    instrumentGroupName: '',
    sessionInfoId: 0,
    mic: '',
    micDescription: '',
    marketDataDelay: 0,
    endOfDayDelay: 0,
    shortInstrumentGroupName: '',
    instrumentGroupDescription: '',
    tickSizesByPrice: [],
    tickSizesByDte: [],
    tickSizesByDteId: 0,
    strike: 0,
    strikePrice: 0,
    dialectId: '',
    countryCode: '',
    strategyDefinition: undefined,
    contractSize: '',
    positionTracking: 0,
    speculationTypeRequired: false,
    maturityMonthYear: '',
    maturityDate: 0,
    priceDisplayMode: 0,
    foreignCurrency: '',
    volumeScale: undefined,
    volumeDisplayExponent: 0,
    tradeSizeIncrement: undefined,
    hasInvertedPriceLadder: false,
    domLadderCompressionRatio: 0,
    expectOffTickPrices: false,
    hasExchangeVolume: false,
    hasYields: false,
    isin: '',
    initialMargin: 0,
    maintenanceMargin: 0,
    contractSizeInUnits: undefined,
    contractSizeUnit: undefined,
    lastDeliveryDate: 0,
    contributorParameters: [],
    listingPeriodType: 0,
    listingPeriodValue: 0,
    symbolId: '',
    deleted: false,
    contributorGroupId: 0,
    sourceContractId: 0,
    issuer: '',
    optionMaturityId: '',
    conversionMetadata: undefined,
    marketStateGroupId: 0,
    settlementMethod: 0,
    exerciseStyle: 0,
    pricingConvention: 0,
    isUserDefinedInstrument: false,
    barBuildingTickTypes: [],
    quotedIn: '',
    productSymbolId: '',
    hedgeWithContractId: 0,
    actualFutureContractId: 0,
    exchangeId: 0,
    supportsContinuation: false,
    instrumentBusinessTypeId: 0,
    closeSources: [],
  };
}

export const ContractMetadata = {
  encode(message: ContractMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractId !== 0) {
      writer.uint32(8).uint32(message.contractId);
    }
    if (message.contractSymbol !== '') {
      writer.uint32(18).string(message.contractSymbol);
    }
    if (message.cqgContractSymbol !== '') {
      writer.uint32(562).string(message.cqgContractSymbol);
    }
    if (message.correctPriceScale !== 0) {
      writer.uint32(25).double(message.correctPriceScale);
    }
    if (message.displayPriceScale !== 0) {
      writer.uint32(32).uint32(message.displayPriceScale);
    }
    if (message.description !== '') {
      writer.uint32(42).string(message.description);
    }
    if (message.extendedDescription !== '') {
      writer.uint32(346).string(message.extendedDescription);
    }
    if (message.title !== '') {
      writer.uint32(50).string(message.title);
    }
    if (message.tickSize !== 0) {
      writer.uint32(57).double(message.tickSize);
    }
    if (message.currency !== '') {
      writer.uint32(66).string(message.currency);
    }
    if (message.tickValue !== 0) {
      writer.uint32(73).double(message.tickValue);
    }
    if (message.cfiCode !== '') {
      writer.uint32(82).string(message.cfiCode);
    }
    if (message.isMostActive === true) {
      writer.uint32(88).bool(message.isMostActive);
    }
    if (message.lastTradingDate !== 0) {
      writer.uint32(96).sint64(message.lastTradingDate);
    }
    if (message.firstNoticeDate !== 0) {
      writer.uint32(104).sint64(message.firstNoticeDate);
    }
    if (message.underlyingContractSymbol !== '') {
      writer.uint32(114).string(message.underlyingContractSymbol);
    }
    if (message.marginStyle !== 0) {
      writer.uint32(120).uint32(message.marginStyle);
    }
    if (message.instrumentGroupName !== '') {
      writer.uint32(130).string(message.instrumentGroupName);
    }
    if (message.sessionInfoId !== 0) {
      writer.uint32(136).sint32(message.sessionInfoId);
    }
    if (message.mic !== '') {
      writer.uint32(146).string(message.mic);
    }
    if (message.micDescription !== '') {
      writer.uint32(354).string(message.micDescription);
    }
    if (message.marketDataDelay !== 0) {
      writer.uint32(160).sint64(message.marketDataDelay);
    }
    if (message.endOfDayDelay !== 0) {
      writer.uint32(472).sint64(message.endOfDayDelay);
    }
    if (message.shortInstrumentGroupName !== '') {
      writer.uint32(170).string(message.shortInstrumentGroupName);
    }
    if (message.instrumentGroupDescription !== '') {
      writer.uint32(178).string(message.instrumentGroupDescription);
    }
    for (const v of message.tickSizesByPrice) {
      TickSizeByPrice.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.tickSizesByDte) {
      TickSizeByDateTillExpiration.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    if (message.tickSizesByDteId !== 0) {
      writer.uint32(200).uint32(message.tickSizesByDteId);
    }
    if (message.strike !== 0) {
      writer.uint32(208).sint32(message.strike);
    }
    if (message.strikePrice !== 0) {
      writer.uint32(217).double(message.strikePrice);
    }
    if (message.dialectId !== '') {
      writer.uint32(226).string(message.dialectId);
    }
    if (message.countryCode !== '') {
      writer.uint32(234).string(message.countryCode);
    }
    if (message.strategyDefinition !== undefined) {
      StrategyDefinition.encode(message.strategyDefinition, writer.uint32(242).fork()).ldelim();
    }
    if (message.contractSize !== '') {
      writer.uint32(250).string(message.contractSize);
    }
    if (message.positionTracking !== 0) {
      writer.uint32(256).uint32(message.positionTracking);
    }
    if (message.speculationTypeRequired === true) {
      writer.uint32(264).bool(message.speculationTypeRequired);
    }
    if (message.maturityMonthYear !== '') {
      writer.uint32(274).string(message.maturityMonthYear);
    }
    if (message.maturityDate !== 0) {
      writer.uint32(424).sint64(message.maturityDate);
    }
    if (message.priceDisplayMode !== 0) {
      writer.uint32(280).int32(message.priceDisplayMode);
    }
    if (message.foreignCurrency !== '') {
      writer.uint32(314).string(message.foreignCurrency);
    }
    if (message.volumeScale !== undefined) {
      Decimal.encode(message.volumeScale, writer.uint32(322).fork()).ldelim();
    }
    if (message.volumeDisplayExponent !== 0) {
      writer.uint32(328).sint32(message.volumeDisplayExponent);
    }
    if (message.tradeSizeIncrement !== undefined) {
      Decimal.encode(message.tradeSizeIncrement, writer.uint32(338).fork()).ldelim();
    }
    if (message.hasInvertedPriceLadder === true) {
      writer.uint32(480).bool(message.hasInvertedPriceLadder);
    }
    if (message.domLadderCompressionRatio !== 0) {
      writer.uint32(360).uint32(message.domLadderCompressionRatio);
    }
    if (message.expectOffTickPrices === true) {
      writer.uint32(368).bool(message.expectOffTickPrices);
    }
    if (message.hasExchangeVolume === true) {
      writer.uint32(376).bool(message.hasExchangeVolume);
    }
    if (message.hasYields === true) {
      writer.uint32(488).bool(message.hasYields);
    }
    if (message.isin !== '') {
      writer.uint32(386).string(message.isin);
    }
    if (message.initialMargin !== 0) {
      writer.uint32(657).double(message.initialMargin);
    }
    if (message.maintenanceMargin !== 0) {
      writer.uint32(393).double(message.maintenanceMargin);
    }
    if (message.contractSizeInUnits !== undefined) {
      Decimal.encode(message.contractSizeInUnits, writer.uint32(402).fork()).ldelim();
    }
    if (message.contractSizeUnit !== undefined) {
      Text.encode(message.contractSizeUnit, writer.uint32(410).fork()).ldelim();
    }
    if (message.lastDeliveryDate !== 0) {
      writer.uint32(416).sint64(message.lastDeliveryDate);
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(434).fork()).ldelim();
    }
    if (message.listingPeriodType !== 0) {
      writer.uint32(440).uint32(message.listingPeriodType);
    }
    if (message.listingPeriodValue !== 0) {
      writer.uint32(448).uint32(message.listingPeriodValue);
    }
    if (message.symbolId !== '') {
      writer.uint32(458).string(message.symbolId);
    }
    if (message.deleted === true) {
      writer.uint32(464).bool(message.deleted);
    }
    if (message.contributorGroupId !== 0) {
      writer.uint32(496).sint32(message.contributorGroupId);
    }
    if (message.sourceContractId !== 0) {
      writer.uint32(504).uint32(message.sourceContractId);
    }
    if (message.issuer !== '') {
      writer.uint32(530).string(message.issuer);
    }
    if (message.optionMaturityId !== '') {
      writer.uint32(538).string(message.optionMaturityId);
    }
    if (message.conversionMetadata !== undefined) {
      ConversionMetadata.encode(message.conversionMetadata, writer.uint32(546).fork()).ldelim();
    }
    if (message.marketStateGroupId !== 0) {
      writer.uint32(552).sint32(message.marketStateGroupId);
    }
    if (message.settlementMethod !== 0) {
      writer.uint32(568).uint32(message.settlementMethod);
    }
    if (message.exerciseStyle !== 0) {
      writer.uint32(576).uint32(message.exerciseStyle);
    }
    if (message.pricingConvention !== 0) {
      writer.uint32(584).uint32(message.pricingConvention);
    }
    if (message.isUserDefinedInstrument === true) {
      writer.uint32(592).bool(message.isUserDefinedInstrument);
    }
    writer.uint32(602).fork();
    for (const v of message.barBuildingTickTypes) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.quotedIn !== '') {
      writer.uint32(610).string(message.quotedIn);
    }
    if (message.productSymbolId !== '') {
      writer.uint32(618).string(message.productSymbolId);
    }
    if (message.hedgeWithContractId !== 0) {
      writer.uint32(624).uint32(message.hedgeWithContractId);
    }
    if (message.actualFutureContractId !== 0) {
      writer.uint32(632).uint32(message.actualFutureContractId);
    }
    if (message.exchangeId !== 0) {
      writer.uint32(640).sint32(message.exchangeId);
    }
    if (message.supportsContinuation === true) {
      writer.uint32(648).bool(message.supportsContinuation);
    }
    if (message.instrumentBusinessTypeId !== 0) {
      writer.uint32(664).uint32(message.instrumentBusinessTypeId);
    }
    writer.uint32(674).fork();
    for (const v of message.closeSources) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contractId = reader.uint32();
          break;
        case 2:
          message.contractSymbol = reader.string();
          break;
        case 70:
          message.cqgContractSymbol = reader.string();
          break;
        case 3:
          message.correctPriceScale = reader.double();
          break;
        case 4:
          message.displayPriceScale = reader.uint32();
          break;
        case 5:
          message.description = reader.string();
          break;
        case 43:
          message.extendedDescription = reader.string();
          break;
        case 6:
          message.title = reader.string();
          break;
        case 7:
          message.tickSize = reader.double();
          break;
        case 8:
          message.currency = reader.string();
          break;
        case 9:
          message.tickValue = reader.double();
          break;
        case 10:
          message.cfiCode = reader.string();
          break;
        case 11:
          message.isMostActive = reader.bool();
          break;
        case 12:
          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          break;
        case 13:
          message.firstNoticeDate = longToNumber(reader.sint64() as Long);
          break;
        case 14:
          message.underlyingContractSymbol = reader.string();
          break;
        case 15:
          message.marginStyle = reader.uint32();
          break;
        case 16:
          message.instrumentGroupName = reader.string();
          break;
        case 17:
          message.sessionInfoId = reader.sint32();
          break;
        case 18:
          message.mic = reader.string();
          break;
        case 44:
          message.micDescription = reader.string();
          break;
        case 20:
          message.marketDataDelay = longToNumber(reader.sint64() as Long);
          break;
        case 59:
          message.endOfDayDelay = longToNumber(reader.sint64() as Long);
          break;
        case 21:
          message.shortInstrumentGroupName = reader.string();
          break;
        case 22:
          message.instrumentGroupDescription = reader.string();
          break;
        case 23:
          message.tickSizesByPrice.push(TickSizeByPrice.decode(reader, reader.uint32()));
          break;
        case 24:
          message.tickSizesByDte.push(TickSizeByDateTillExpiration.decode(reader, reader.uint32()));
          break;
        case 25:
          message.tickSizesByDteId = reader.uint32();
          break;
        case 26:
          message.strike = reader.sint32();
          break;
        case 27:
          message.strikePrice = reader.double();
          break;
        case 28:
          message.dialectId = reader.string();
          break;
        case 29:
          message.countryCode = reader.string();
          break;
        case 30:
          message.strategyDefinition = StrategyDefinition.decode(reader, reader.uint32());
          break;
        case 31:
          message.contractSize = reader.string();
          break;
        case 32:
          message.positionTracking = reader.uint32();
          break;
        case 33:
          message.speculationTypeRequired = reader.bool();
          break;
        case 34:
          message.maturityMonthYear = reader.string();
          break;
        case 53:
          message.maturityDate = longToNumber(reader.sint64() as Long);
          break;
        case 35:
          message.priceDisplayMode = reader.int32() as any;
          break;
        case 39:
          message.foreignCurrency = reader.string();
          break;
        case 40:
          message.volumeScale = Decimal.decode(reader, reader.uint32());
          break;
        case 41:
          message.volumeDisplayExponent = reader.sint32();
          break;
        case 42:
          message.tradeSizeIncrement = Decimal.decode(reader, reader.uint32());
          break;
        case 60:
          message.hasInvertedPriceLadder = reader.bool();
          break;
        case 45:
          message.domLadderCompressionRatio = reader.uint32();
          break;
        case 46:
          message.expectOffTickPrices = reader.bool();
          break;
        case 47:
          message.hasExchangeVolume = reader.bool();
          break;
        case 61:
          message.hasYields = reader.bool();
          break;
        case 48:
          message.isin = reader.string();
          break;
        case 82:
          message.initialMargin = reader.double();
          break;
        case 49:
          message.maintenanceMargin = reader.double();
          break;
        case 50:
          message.contractSizeInUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 51:
          message.contractSizeUnit = Text.decode(reader, reader.uint32());
          break;
        case 52:
          message.lastDeliveryDate = longToNumber(reader.sint64() as Long);
          break;
        case 54:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        case 55:
          message.listingPeriodType = reader.uint32();
          break;
        case 56:
          message.listingPeriodValue = reader.uint32();
          break;
        case 57:
          message.symbolId = reader.string();
          break;
        case 58:
          message.deleted = reader.bool();
          break;
        case 62:
          message.contributorGroupId = reader.sint32();
          break;
        case 63:
          message.sourceContractId = reader.uint32();
          break;
        case 66:
          message.issuer = reader.string();
          break;
        case 67:
          message.optionMaturityId = reader.string();
          break;
        case 68:
          message.conversionMetadata = ConversionMetadata.decode(reader, reader.uint32());
          break;
        case 69:
          message.marketStateGroupId = reader.sint32();
          break;
        case 71:
          message.settlementMethod = reader.uint32();
          break;
        case 72:
          message.exerciseStyle = reader.uint32();
          break;
        case 73:
          message.pricingConvention = reader.uint32();
          break;
        case 74:
          message.isUserDefinedInstrument = reader.bool();
          break;
        case 75:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.barBuildingTickTypes.push(reader.uint32());
            }
          } else {
            message.barBuildingTickTypes.push(reader.uint32());
          }
          break;
        case 76:
          message.quotedIn = reader.string();
          break;
        case 77:
          message.productSymbolId = reader.string();
          break;
        case 78:
          message.hedgeWithContractId = reader.uint32();
          break;
        case 79:
          message.actualFutureContractId = reader.uint32();
          break;
        case 80:
          message.exchangeId = reader.sint32();
          break;
        case 81:
          message.supportsContinuation = reader.bool();
          break;
        case 83:
          message.instrumentBusinessTypeId = reader.uint32();
          break;
        case 84:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.closeSources.push(reader.uint32());
            }
          } else {
            message.closeSources.push(reader.uint32());
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContractMetadata {
    return {
      contractId: isSet(object.contractId) ? Number(object.contractId) : 0,
      contractSymbol: isSet(object.contractSymbol) ? String(object.contractSymbol) : '',
      cqgContractSymbol: isSet(object.cqgContractSymbol) ? String(object.cqgContractSymbol) : '',
      correctPriceScale: isSet(object.correctPriceScale) ? Number(object.correctPriceScale) : 0,
      displayPriceScale: isSet(object.displayPriceScale) ? Number(object.displayPriceScale) : 0,
      description: isSet(object.description) ? String(object.description) : '',
      extendedDescription: isSet(object.extendedDescription) ? String(object.extendedDescription) : '',
      title: isSet(object.title) ? String(object.title) : '',
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      currency: isSet(object.currency) ? String(object.currency) : '',
      tickValue: isSet(object.tickValue) ? Number(object.tickValue) : 0,
      cfiCode: isSet(object.cfiCode) ? String(object.cfiCode) : '',
      isMostActive: isSet(object.isMostActive) ? Boolean(object.isMostActive) : false,
      lastTradingDate: isSet(object.lastTradingDate) ? Number(object.lastTradingDate) : 0,
      firstNoticeDate: isSet(object.firstNoticeDate) ? Number(object.firstNoticeDate) : 0,
      underlyingContractSymbol: isSet(object.underlyingContractSymbol) ? String(object.underlyingContractSymbol) : '',
      marginStyle: isSet(object.marginStyle) ? Number(object.marginStyle) : 0,
      instrumentGroupName: isSet(object.instrumentGroupName) ? String(object.instrumentGroupName) : '',
      sessionInfoId: isSet(object.sessionInfoId) ? Number(object.sessionInfoId) : 0,
      mic: isSet(object.mic) ? String(object.mic) : '',
      micDescription: isSet(object.micDescription) ? String(object.micDescription) : '',
      marketDataDelay: isSet(object.marketDataDelay) ? Number(object.marketDataDelay) : 0,
      endOfDayDelay: isSet(object.endOfDayDelay) ? Number(object.endOfDayDelay) : 0,
      shortInstrumentGroupName: isSet(object.shortInstrumentGroupName) ? String(object.shortInstrumentGroupName) : '',
      instrumentGroupDescription: isSet(object.instrumentGroupDescription) ? String(object.instrumentGroupDescription) : '',
      tickSizesByPrice: Array.isArray(object?.tickSizesByPrice)
        ? object.tickSizesByPrice.map((e: any) => TickSizeByPrice.fromJSON(e))
        : [],
      tickSizesByDte: Array.isArray(object?.tickSizesByDte)
        ? object.tickSizesByDte.map((e: any) => TickSizeByDateTillExpiration.fromJSON(e))
        : [],
      tickSizesByDteId: isSet(object.tickSizesByDteId) ? Number(object.tickSizesByDteId) : 0,
      strike: isSet(object.strike) ? Number(object.strike) : 0,
      strikePrice: isSet(object.strikePrice) ? Number(object.strikePrice) : 0,
      dialectId: isSet(object.dialectId) ? String(object.dialectId) : '',
      countryCode: isSet(object.countryCode) ? String(object.countryCode) : '',
      strategyDefinition: isSet(object.strategyDefinition) ? StrategyDefinition.fromJSON(object.strategyDefinition) : undefined,
      contractSize: isSet(object.contractSize) ? String(object.contractSize) : '',
      positionTracking: isSet(object.positionTracking) ? Number(object.positionTracking) : 0,
      speculationTypeRequired: isSet(object.speculationTypeRequired) ? Boolean(object.speculationTypeRequired) : false,
      maturityMonthYear: isSet(object.maturityMonthYear) ? String(object.maturityMonthYear) : '',
      maturityDate: isSet(object.maturityDate) ? Number(object.maturityDate) : 0,
      priceDisplayMode: isSet(object.priceDisplayMode) ? priceDisplayModeFromJSON(object.priceDisplayMode) : 0,
      foreignCurrency: isSet(object.foreignCurrency) ? String(object.foreignCurrency) : '',
      volumeScale: isSet(object.volumeScale) ? Decimal.fromJSON(object.volumeScale) : undefined,
      volumeDisplayExponent: isSet(object.volumeDisplayExponent) ? Number(object.volumeDisplayExponent) : 0,
      tradeSizeIncrement: isSet(object.tradeSizeIncrement) ? Decimal.fromJSON(object.tradeSizeIncrement) : undefined,
      hasInvertedPriceLadder: isSet(object.hasInvertedPriceLadder) ? Boolean(object.hasInvertedPriceLadder) : false,
      domLadderCompressionRatio: isSet(object.domLadderCompressionRatio) ? Number(object.domLadderCompressionRatio) : 0,
      expectOffTickPrices: isSet(object.expectOffTickPrices) ? Boolean(object.expectOffTickPrices) : false,
      hasExchangeVolume: isSet(object.hasExchangeVolume) ? Boolean(object.hasExchangeVolume) : false,
      hasYields: isSet(object.hasYields) ? Boolean(object.hasYields) : false,
      isin: isSet(object.isin) ? String(object.isin) : '',
      initialMargin: isSet(object.initialMargin) ? Number(object.initialMargin) : 0,
      maintenanceMargin: isSet(object.maintenanceMargin) ? Number(object.maintenanceMargin) : 0,
      contractSizeInUnits: isSet(object.contractSizeInUnits) ? Decimal.fromJSON(object.contractSizeInUnits) : undefined,
      contractSizeUnit: isSet(object.contractSizeUnit) ? Text.fromJSON(object.contractSizeUnit) : undefined,
      lastDeliveryDate: isSet(object.lastDeliveryDate) ? Number(object.lastDeliveryDate) : 0,
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
      listingPeriodType: isSet(object.listingPeriodType) ? Number(object.listingPeriodType) : 0,
      listingPeriodValue: isSet(object.listingPeriodValue) ? Number(object.listingPeriodValue) : 0,
      symbolId: isSet(object.symbolId) ? String(object.symbolId) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      contributorGroupId: isSet(object.contributorGroupId) ? Number(object.contributorGroupId) : 0,
      sourceContractId: isSet(object.sourceContractId) ? Number(object.sourceContractId) : 0,
      issuer: isSet(object.issuer) ? String(object.issuer) : '',
      optionMaturityId: isSet(object.optionMaturityId) ? String(object.optionMaturityId) : '',
      conversionMetadata: isSet(object.conversionMetadata) ? ConversionMetadata.fromJSON(object.conversionMetadata) : undefined,
      marketStateGroupId: isSet(object.marketStateGroupId) ? Number(object.marketStateGroupId) : 0,
      settlementMethod: isSet(object.settlementMethod) ? Number(object.settlementMethod) : 0,
      exerciseStyle: isSet(object.exerciseStyle) ? Number(object.exerciseStyle) : 0,
      pricingConvention: isSet(object.pricingConvention) ? Number(object.pricingConvention) : 0,
      isUserDefinedInstrument: isSet(object.isUserDefinedInstrument) ? Boolean(object.isUserDefinedInstrument) : false,
      barBuildingTickTypes: Array.isArray(object?.barBuildingTickTypes)
        ? object.barBuildingTickTypes.map((e: any) => Number(e))
        : [],
      quotedIn: isSet(object.quotedIn) ? String(object.quotedIn) : '',
      productSymbolId: isSet(object.productSymbolId) ? String(object.productSymbolId) : '',
      hedgeWithContractId: isSet(object.hedgeWithContractId) ? Number(object.hedgeWithContractId) : 0,
      actualFutureContractId: isSet(object.actualFutureContractId) ? Number(object.actualFutureContractId) : 0,
      exchangeId: isSet(object.exchangeId) ? Number(object.exchangeId) : 0,
      supportsContinuation: isSet(object.supportsContinuation) ? Boolean(object.supportsContinuation) : false,
      instrumentBusinessTypeId: isSet(object.instrumentBusinessTypeId) ? Number(object.instrumentBusinessTypeId) : 0,
      closeSources: Array.isArray(object?.closeSources) ? object.closeSources.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: ContractMetadata): unknown {
    const obj: any = {};
    message.contractId !== undefined && (obj.contractId = Math.round(message.contractId));
    message.contractSymbol !== undefined && (obj.contractSymbol = message.contractSymbol);
    message.cqgContractSymbol !== undefined && (obj.cqgContractSymbol = message.cqgContractSymbol);
    message.correctPriceScale !== undefined && (obj.correctPriceScale = message.correctPriceScale);
    message.displayPriceScale !== undefined && (obj.displayPriceScale = Math.round(message.displayPriceScale));
    message.description !== undefined && (obj.description = message.description);
    message.extendedDescription !== undefined && (obj.extendedDescription = message.extendedDescription);
    message.title !== undefined && (obj.title = message.title);
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.currency !== undefined && (obj.currency = message.currency);
    message.tickValue !== undefined && (obj.tickValue = message.tickValue);
    message.cfiCode !== undefined && (obj.cfiCode = message.cfiCode);
    message.isMostActive !== undefined && (obj.isMostActive = message.isMostActive);
    message.lastTradingDate !== undefined && (obj.lastTradingDate = Math.round(message.lastTradingDate));
    message.firstNoticeDate !== undefined && (obj.firstNoticeDate = Math.round(message.firstNoticeDate));
    message.underlyingContractSymbol !== undefined && (obj.underlyingContractSymbol = message.underlyingContractSymbol);
    message.marginStyle !== undefined && (obj.marginStyle = Math.round(message.marginStyle));
    message.instrumentGroupName !== undefined && (obj.instrumentGroupName = message.instrumentGroupName);
    message.sessionInfoId !== undefined && (obj.sessionInfoId = Math.round(message.sessionInfoId));
    message.mic !== undefined && (obj.mic = message.mic);
    message.micDescription !== undefined && (obj.micDescription = message.micDescription);
    message.marketDataDelay !== undefined && (obj.marketDataDelay = Math.round(message.marketDataDelay));
    message.endOfDayDelay !== undefined && (obj.endOfDayDelay = Math.round(message.endOfDayDelay));
    message.shortInstrumentGroupName !== undefined && (obj.shortInstrumentGroupName = message.shortInstrumentGroupName);
    message.instrumentGroupDescription !== undefined && (obj.instrumentGroupDescription = message.instrumentGroupDescription);
    if (message.tickSizesByPrice) {
      obj.tickSizesByPrice = message.tickSizesByPrice.map((e) => (e ? TickSizeByPrice.toJSON(e) : undefined));
    } else {
      obj.tickSizesByPrice = [];
    }
    if (message.tickSizesByDte) {
      obj.tickSizesByDte = message.tickSizesByDte.map((e) => (e ? TickSizeByDateTillExpiration.toJSON(e) : undefined));
    } else {
      obj.tickSizesByDte = [];
    }
    message.tickSizesByDteId !== undefined && (obj.tickSizesByDteId = Math.round(message.tickSizesByDteId));
    message.strike !== undefined && (obj.strike = Math.round(message.strike));
    message.strikePrice !== undefined && (obj.strikePrice = message.strikePrice);
    message.dialectId !== undefined && (obj.dialectId = message.dialectId);
    message.countryCode !== undefined && (obj.countryCode = message.countryCode);
    message.strategyDefinition !== undefined &&
      (obj.strategyDefinition = message.strategyDefinition ? StrategyDefinition.toJSON(message.strategyDefinition) : undefined);
    message.contractSize !== undefined && (obj.contractSize = message.contractSize);
    message.positionTracking !== undefined && (obj.positionTracking = Math.round(message.positionTracking));
    message.speculationTypeRequired !== undefined && (obj.speculationTypeRequired = message.speculationTypeRequired);
    message.maturityMonthYear !== undefined && (obj.maturityMonthYear = message.maturityMonthYear);
    message.maturityDate !== undefined && (obj.maturityDate = Math.round(message.maturityDate));
    message.priceDisplayMode !== undefined && (obj.priceDisplayMode = priceDisplayModeToJSON(message.priceDisplayMode));
    message.foreignCurrency !== undefined && (obj.foreignCurrency = message.foreignCurrency);
    message.volumeScale !== undefined &&
      (obj.volumeScale = message.volumeScale ? Decimal.toJSON(message.volumeScale) : undefined);
    message.volumeDisplayExponent !== undefined && (obj.volumeDisplayExponent = Math.round(message.volumeDisplayExponent));
    message.tradeSizeIncrement !== undefined &&
      (obj.tradeSizeIncrement = message.tradeSizeIncrement ? Decimal.toJSON(message.tradeSizeIncrement) : undefined);
    message.hasInvertedPriceLadder !== undefined && (obj.hasInvertedPriceLadder = message.hasInvertedPriceLadder);
    message.domLadderCompressionRatio !== undefined &&
      (obj.domLadderCompressionRatio = Math.round(message.domLadderCompressionRatio));
    message.expectOffTickPrices !== undefined && (obj.expectOffTickPrices = message.expectOffTickPrices);
    message.hasExchangeVolume !== undefined && (obj.hasExchangeVolume = message.hasExchangeVolume);
    message.hasYields !== undefined && (obj.hasYields = message.hasYields);
    message.isin !== undefined && (obj.isin = message.isin);
    message.initialMargin !== undefined && (obj.initialMargin = message.initialMargin);
    message.maintenanceMargin !== undefined && (obj.maintenanceMargin = message.maintenanceMargin);
    message.contractSizeInUnits !== undefined &&
      (obj.contractSizeInUnits = message.contractSizeInUnits ? Decimal.toJSON(message.contractSizeInUnits) : undefined);
    message.contractSizeUnit !== undefined &&
      (obj.contractSizeUnit = message.contractSizeUnit ? Text.toJSON(message.contractSizeUnit) : undefined);
    message.lastDeliveryDate !== undefined && (obj.lastDeliveryDate = Math.round(message.lastDeliveryDate));
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    message.listingPeriodType !== undefined && (obj.listingPeriodType = Math.round(message.listingPeriodType));
    message.listingPeriodValue !== undefined && (obj.listingPeriodValue = Math.round(message.listingPeriodValue));
    message.symbolId !== undefined && (obj.symbolId = message.symbolId);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.contributorGroupId !== undefined && (obj.contributorGroupId = Math.round(message.contributorGroupId));
    message.sourceContractId !== undefined && (obj.sourceContractId = Math.round(message.sourceContractId));
    message.issuer !== undefined && (obj.issuer = message.issuer);
    message.optionMaturityId !== undefined && (obj.optionMaturityId = message.optionMaturityId);
    message.conversionMetadata !== undefined &&
      (obj.conversionMetadata = message.conversionMetadata ? ConversionMetadata.toJSON(message.conversionMetadata) : undefined);
    message.marketStateGroupId !== undefined && (obj.marketStateGroupId = Math.round(message.marketStateGroupId));
    message.settlementMethod !== undefined && (obj.settlementMethod = Math.round(message.settlementMethod));
    message.exerciseStyle !== undefined && (obj.exerciseStyle = Math.round(message.exerciseStyle));
    message.pricingConvention !== undefined && (obj.pricingConvention = Math.round(message.pricingConvention));
    message.isUserDefinedInstrument !== undefined && (obj.isUserDefinedInstrument = message.isUserDefinedInstrument);
    if (message.barBuildingTickTypes) {
      obj.barBuildingTickTypes = message.barBuildingTickTypes.map((e) => Math.round(e));
    } else {
      obj.barBuildingTickTypes = [];
    }
    message.quotedIn !== undefined && (obj.quotedIn = message.quotedIn);
    message.productSymbolId !== undefined && (obj.productSymbolId = message.productSymbolId);
    message.hedgeWithContractId !== undefined && (obj.hedgeWithContractId = Math.round(message.hedgeWithContractId));
    message.actualFutureContractId !== undefined && (obj.actualFutureContractId = Math.round(message.actualFutureContractId));
    message.exchangeId !== undefined && (obj.exchangeId = Math.round(message.exchangeId));
    message.supportsContinuation !== undefined && (obj.supportsContinuation = message.supportsContinuation);
    message.instrumentBusinessTypeId !== undefined &&
      (obj.instrumentBusinessTypeId = Math.round(message.instrumentBusinessTypeId));
    if (message.closeSources) {
      obj.closeSources = message.closeSources.map((e) => Math.round(e));
    } else {
      obj.closeSources = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractMetadata>, I>>(base?: I): ContractMetadata {
    return ContractMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContractMetadata>, I>>(object: I): ContractMetadata {
    const message = createBaseContractMetadata();
    message.contractId = object.contractId ?? 0;
    message.contractSymbol = object.contractSymbol ?? '';
    message.cqgContractSymbol = object.cqgContractSymbol ?? '';
    message.correctPriceScale = object.correctPriceScale ?? 0;
    message.displayPriceScale = object.displayPriceScale ?? 0;
    message.description = object.description ?? '';
    message.extendedDescription = object.extendedDescription ?? '';
    message.title = object.title ?? '';
    message.tickSize = object.tickSize ?? 0;
    message.currency = object.currency ?? '';
    message.tickValue = object.tickValue ?? 0;
    message.cfiCode = object.cfiCode ?? '';
    message.isMostActive = object.isMostActive ?? false;
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.firstNoticeDate = object.firstNoticeDate ?? 0;
    message.underlyingContractSymbol = object.underlyingContractSymbol ?? '';
    message.marginStyle = object.marginStyle ?? 0;
    message.instrumentGroupName = object.instrumentGroupName ?? '';
    message.sessionInfoId = object.sessionInfoId ?? 0;
    message.mic = object.mic ?? '';
    message.micDescription = object.micDescription ?? '';
    message.marketDataDelay = object.marketDataDelay ?? 0;
    message.endOfDayDelay = object.endOfDayDelay ?? 0;
    message.shortInstrumentGroupName = object.shortInstrumentGroupName ?? '';
    message.instrumentGroupDescription = object.instrumentGroupDescription ?? '';
    message.tickSizesByPrice = object.tickSizesByPrice?.map((e) => TickSizeByPrice.fromPartial(e)) || [];
    message.tickSizesByDte = object.tickSizesByDte?.map((e) => TickSizeByDateTillExpiration.fromPartial(e)) || [];
    message.tickSizesByDteId = object.tickSizesByDteId ?? 0;
    message.strike = object.strike ?? 0;
    message.strikePrice = object.strikePrice ?? 0;
    message.dialectId = object.dialectId ?? '';
    message.countryCode = object.countryCode ?? '';
    message.strategyDefinition =
      object.strategyDefinition !== undefined && object.strategyDefinition !== null
        ? StrategyDefinition.fromPartial(object.strategyDefinition)
        : undefined;
    message.contractSize = object.contractSize ?? '';
    message.positionTracking = object.positionTracking ?? 0;
    message.speculationTypeRequired = object.speculationTypeRequired ?? false;
    message.maturityMonthYear = object.maturityMonthYear ?? '';
    message.maturityDate = object.maturityDate ?? 0;
    message.priceDisplayMode = object.priceDisplayMode ?? 0;
    message.foreignCurrency = object.foreignCurrency ?? '';
    message.volumeScale =
      object.volumeScale !== undefined && object.volumeScale !== null ? Decimal.fromPartial(object.volumeScale) : undefined;
    message.volumeDisplayExponent = object.volumeDisplayExponent ?? 0;
    message.tradeSizeIncrement =
      object.tradeSizeIncrement !== undefined && object.tradeSizeIncrement !== null
        ? Decimal.fromPartial(object.tradeSizeIncrement)
        : undefined;
    message.hasInvertedPriceLadder = object.hasInvertedPriceLadder ?? false;
    message.domLadderCompressionRatio = object.domLadderCompressionRatio ?? 0;
    message.expectOffTickPrices = object.expectOffTickPrices ?? false;
    message.hasExchangeVolume = object.hasExchangeVolume ?? false;
    message.hasYields = object.hasYields ?? false;
    message.isin = object.isin ?? '';
    message.initialMargin = object.initialMargin ?? 0;
    message.maintenanceMargin = object.maintenanceMargin ?? 0;
    message.contractSizeInUnits =
      object.contractSizeInUnits !== undefined && object.contractSizeInUnits !== null
        ? Decimal.fromPartial(object.contractSizeInUnits)
        : undefined;
    message.contractSizeUnit =
      object.contractSizeUnit !== undefined && object.contractSizeUnit !== null
        ? Text.fromPartial(object.contractSizeUnit)
        : undefined;
    message.lastDeliveryDate = object.lastDeliveryDate ?? 0;
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    message.listingPeriodType = object.listingPeriodType ?? 0;
    message.listingPeriodValue = object.listingPeriodValue ?? 0;
    message.symbolId = object.symbolId ?? '';
    message.deleted = object.deleted ?? false;
    message.contributorGroupId = object.contributorGroupId ?? 0;
    message.sourceContractId = object.sourceContractId ?? 0;
    message.issuer = object.issuer ?? '';
    message.optionMaturityId = object.optionMaturityId ?? '';
    message.conversionMetadata =
      object.conversionMetadata !== undefined && object.conversionMetadata !== null
        ? ConversionMetadata.fromPartial(object.conversionMetadata)
        : undefined;
    message.marketStateGroupId = object.marketStateGroupId ?? 0;
    message.settlementMethod = object.settlementMethod ?? 0;
    message.exerciseStyle = object.exerciseStyle ?? 0;
    message.pricingConvention = object.pricingConvention ?? 0;
    message.isUserDefinedInstrument = object.isUserDefinedInstrument ?? false;
    message.barBuildingTickTypes = object.barBuildingTickTypes?.map((e) => e) || [];
    message.quotedIn = object.quotedIn ?? '';
    message.productSymbolId = object.productSymbolId ?? '';
    message.hedgeWithContractId = object.hedgeWithContractId ?? 0;
    message.actualFutureContractId = object.actualFutureContractId ?? 0;
    message.exchangeId = object.exchangeId ?? 0;
    message.supportsContinuation = object.supportsContinuation ?? false;
    message.instrumentBusinessTypeId = object.instrumentBusinessTypeId ?? 0;
    message.closeSources = object.closeSources?.map((e) => e) || [];
    return message;
  },
};

function createBaseContributorMetadataRequest(): ContributorMetadataRequest {
  return { contributorGroupId: 0 };
}

export const ContributorMetadataRequest = {
  encode(message: ContributorMetadataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contributorGroupId !== 0) {
      writer.uint32(8).sint32(message.contributorGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContributorMetadataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributorMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contributorGroupId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContributorMetadataRequest {
    return { contributorGroupId: isSet(object.contributorGroupId) ? Number(object.contributorGroupId) : 0 };
  },

  toJSON(message: ContributorMetadataRequest): unknown {
    const obj: any = {};
    message.contributorGroupId !== undefined && (obj.contributorGroupId = Math.round(message.contributorGroupId));
    return obj;
  },

  create<I extends Exact<DeepPartial<ContributorMetadataRequest>, I>>(base?: I): ContributorMetadataRequest {
    return ContributorMetadataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContributorMetadataRequest>, I>>(object: I): ContributorMetadataRequest {
    const message = createBaseContributorMetadataRequest();
    message.contributorGroupId = object.contributorGroupId ?? 0;
    return message;
  },
};

function createBaseContributorMetadataReport(): ContributorMetadataReport {
  return { contributorMetadata: [] };
}

export const ContributorMetadataReport = {
  encode(message: ContributorMetadataReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.contributorMetadata) {
      ContributorMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContributorMetadataReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributorMetadataReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contributorMetadata.push(ContributorMetadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContributorMetadataReport {
    return {
      contributorMetadata: Array.isArray(object?.contributorMetadata)
        ? object.contributorMetadata.map((e: any) => ContributorMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ContributorMetadataReport): unknown {
    const obj: any = {};
    if (message.contributorMetadata) {
      obj.contributorMetadata = message.contributorMetadata.map((e) => (e ? ContributorMetadata.toJSON(e) : undefined));
    } else {
      obj.contributorMetadata = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContributorMetadataReport>, I>>(base?: I): ContributorMetadataReport {
    return ContributorMetadataReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContributorMetadataReport>, I>>(object: I): ContributorMetadataReport {
    const message = createBaseContributorMetadataReport();
    message.contributorMetadata = object.contributorMetadata?.map((e) => ContributorMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseContributorMetadata(): ContributorMetadata {
  return { contributorId: 0, name: '', description: '', deleted: false };
}

export const ContributorMetadata = {
  encode(message: ContributorMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contributorId !== 0) {
      writer.uint32(8).sint32(message.contributorId);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.deleted === true) {
      writer.uint32(32).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContributorMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributorMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.contributorId = reader.sint32();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        case 4:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ContributorMetadata {
    return {
      contributorId: isSet(object.contributorId) ? Number(object.contributorId) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: ContributorMetadata): unknown {
    const obj: any = {};
    message.contributorId !== undefined && (obj.contributorId = Math.round(message.contributorId));
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<ContributorMetadata>, I>>(base?: I): ContributorMetadata {
    return ContributorMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ContributorMetadata>, I>>(object: I): ContributorMetadata {
    const message = createBaseContributorMetadata();
    message.contributorId = object.contributorId ?? 0;
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseOptionMaturityListRequest(): OptionMaturityListRequest {
  return { underlyingContractId: 0 };
}

export const OptionMaturityListRequest = {
  encode(message: OptionMaturityListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.underlyingContractId !== 0) {
      writer.uint32(8).uint32(message.underlyingContractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionMaturityListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionMaturityListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.underlyingContractId = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionMaturityListRequest {
    return { underlyingContractId: isSet(object.underlyingContractId) ? Number(object.underlyingContractId) : 0 };
  },

  toJSON(message: OptionMaturityListRequest): unknown {
    const obj: any = {};
    message.underlyingContractId !== undefined && (obj.underlyingContractId = Math.round(message.underlyingContractId));
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionMaturityListRequest>, I>>(base?: I): OptionMaturityListRequest {
    return OptionMaturityListRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionMaturityListRequest>, I>>(object: I): OptionMaturityListRequest {
    const message = createBaseOptionMaturityListRequest();
    message.underlyingContractId = object.underlyingContractId ?? 0;
    return message;
  },
};

function createBaseOptionMaturityListReport(): OptionMaturityListReport {
  return { optionMaturities: [] };
}

export const OptionMaturityListReport = {
  encode(message: OptionMaturityListReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.optionMaturities) {
      OptionMaturityMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionMaturityListReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionMaturityListReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.optionMaturities.push(OptionMaturityMetadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionMaturityListReport {
    return {
      optionMaturities: Array.isArray(object?.optionMaturities)
        ? object.optionMaturities.map((e: any) => OptionMaturityMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OptionMaturityListReport): unknown {
    const obj: any = {};
    if (message.optionMaturities) {
      obj.optionMaturities = message.optionMaturities.map((e) => (e ? OptionMaturityMetadata.toJSON(e) : undefined));
    } else {
      obj.optionMaturities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionMaturityListReport>, I>>(base?: I): OptionMaturityListReport {
    return OptionMaturityListReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionMaturityListReport>, I>>(object: I): OptionMaturityListReport {
    const message = createBaseOptionMaturityListReport();
    message.optionMaturities = object.optionMaturities?.map((e) => OptionMaturityMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOptionMaturityMetadata(): OptionMaturityMetadata {
  return {
    id: '',
    name: '',
    description: '',
    cfiCode: '',
    deleted: false,
    lastTradingDate: 0,
    lastTradingDateTimeUtcTimestamp: undefined,
    maturityMonthYear: '',
    instrumentGroupName: '',
    instrumentGroupEmpty: false,
    listingPeriodType: 0,
    listingPeriodValue: 0,
    reserved1: false,
  };
}

export const OptionMaturityMetadata = {
  encode(message: OptionMaturityMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.cfiCode !== '') {
      writer.uint32(34).string(message.cfiCode);
    }
    if (message.deleted === true) {
      writer.uint32(56).bool(message.deleted);
    }
    if (message.lastTradingDate !== 0) {
      writer.uint32(64).sint64(message.lastTradingDate);
    }
    if (message.lastTradingDateTimeUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.lastTradingDateTimeUtcTimestamp), writer.uint32(162).fork()).ldelim();
    }
    if (message.maturityMonthYear !== '') {
      writer.uint32(74).string(message.maturityMonthYear);
    }
    if (message.instrumentGroupName !== '') {
      writer.uint32(82).string(message.instrumentGroupName);
    }
    if (message.instrumentGroupEmpty === true) {
      writer.uint32(136).bool(message.instrumentGroupEmpty);
    }
    if (message.listingPeriodType !== 0) {
      writer.uint32(144).uint32(message.listingPeriodType);
    }
    if (message.listingPeriodValue !== 0) {
      writer.uint32(152).uint32(message.listingPeriodValue);
    }
    if (message.reserved1 === true) {
      writer.uint32(40).bool(message.reserved1);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionMaturityMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionMaturityMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        case 4:
          message.cfiCode = reader.string();
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        case 8:
          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          break;
        case 20:
          message.lastTradingDateTimeUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.maturityMonthYear = reader.string();
          break;
        case 10:
          message.instrumentGroupName = reader.string();
          break;
        case 17:
          message.instrumentGroupEmpty = reader.bool();
          break;
        case 18:
          message.listingPeriodType = reader.uint32();
          break;
        case 19:
          message.listingPeriodValue = reader.uint32();
          break;
        case 5:
          message.reserved1 = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OptionMaturityMetadata {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      cfiCode: isSet(object.cfiCode) ? String(object.cfiCode) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      lastTradingDate: isSet(object.lastTradingDate) ? Number(object.lastTradingDate) : 0,
      lastTradingDateTimeUtcTimestamp: isSet(object.lastTradingDateTimeUtcTimestamp)
        ? fromJsonTimestamp(object.lastTradingDateTimeUtcTimestamp)
        : undefined,
      maturityMonthYear: isSet(object.maturityMonthYear) ? String(object.maturityMonthYear) : '',
      instrumentGroupName: isSet(object.instrumentGroupName) ? String(object.instrumentGroupName) : '',
      instrumentGroupEmpty: isSet(object.instrumentGroupEmpty) ? Boolean(object.instrumentGroupEmpty) : false,
      listingPeriodType: isSet(object.listingPeriodType) ? Number(object.listingPeriodType) : 0,
      listingPeriodValue: isSet(object.listingPeriodValue) ? Number(object.listingPeriodValue) : 0,
      reserved1: isSet(object.reserved1) ? Boolean(object.reserved1) : false,
    };
  },

  toJSON(message: OptionMaturityMetadata): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.cfiCode !== undefined && (obj.cfiCode = message.cfiCode);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.lastTradingDate !== undefined && (obj.lastTradingDate = Math.round(message.lastTradingDate));
    message.lastTradingDateTimeUtcTimestamp !== undefined &&
      (obj.lastTradingDateTimeUtcTimestamp = message.lastTradingDateTimeUtcTimestamp.toISOString());
    message.maturityMonthYear !== undefined && (obj.maturityMonthYear = message.maturityMonthYear);
    message.instrumentGroupName !== undefined && (obj.instrumentGroupName = message.instrumentGroupName);
    message.instrumentGroupEmpty !== undefined && (obj.instrumentGroupEmpty = message.instrumentGroupEmpty);
    message.listingPeriodType !== undefined && (obj.listingPeriodType = Math.round(message.listingPeriodType));
    message.listingPeriodValue !== undefined && (obj.listingPeriodValue = Math.round(message.listingPeriodValue));
    message.reserved1 !== undefined && (obj.reserved1 = message.reserved1);
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionMaturityMetadata>, I>>(base?: I): OptionMaturityMetadata {
    return OptionMaturityMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OptionMaturityMetadata>, I>>(object: I): OptionMaturityMetadata {
    const message = createBaseOptionMaturityMetadata();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.cfiCode = object.cfiCode ?? '';
    message.deleted = object.deleted ?? false;
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.lastTradingDateTimeUtcTimestamp = object.lastTradingDateTimeUtcTimestamp ?? undefined;
    message.maturityMonthYear = object.maturityMonthYear ?? '';
    message.instrumentGroupName = object.instrumentGroupName ?? '';
    message.instrumentGroupEmpty = object.instrumentGroupEmpty ?? false;
    message.listingPeriodType = object.listingPeriodType ?? 0;
    message.listingPeriodValue = object.listingPeriodValue ?? 0;
    message.reserved1 = object.reserved1 ?? false;
    return message;
  },
};

function createBaseInstrumentGroupRequest(): InstrumentGroupRequest {
  return { instrumentGroupId: '' };
}

export const InstrumentGroupRequest = {
  encode(message: InstrumentGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentGroupId !== '') {
      writer.uint32(10).string(message.instrumentGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instrumentGroupId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupRequest {
    return { instrumentGroupId: isSet(object.instrumentGroupId) ? String(object.instrumentGroupId) : '' };
  },

  toJSON(message: InstrumentGroupRequest): unknown {
    const obj: any = {};
    message.instrumentGroupId !== undefined && (obj.instrumentGroupId = message.instrumentGroupId);
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupRequest>, I>>(base?: I): InstrumentGroupRequest {
    return InstrumentGroupRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupRequest>, I>>(object: I): InstrumentGroupRequest {
    const message = createBaseInstrumentGroupRequest();
    message.instrumentGroupId = object.instrumentGroupId ?? '';
    return message;
  },
};

function createBaseInstrumentGroupReport(): InstrumentGroupReport {
  return { instruments: [] };
}

export const InstrumentGroupReport = {
  encode(message: InstrumentGroupReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instruments) {
      InstrumentGroupItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instruments.push(InstrumentGroupItem.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupReport {
    return {
      instruments: Array.isArray(object?.instruments) ? object.instruments.map((e: any) => InstrumentGroupItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: InstrumentGroupReport): unknown {
    const obj: any = {};
    if (message.instruments) {
      obj.instruments = message.instruments.map((e) => (e ? InstrumentGroupItem.toJSON(e) : undefined));
    } else {
      obj.instruments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupReport>, I>>(base?: I): InstrumentGroupReport {
    return InstrumentGroupReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupReport>, I>>(object: I): InstrumentGroupReport {
    const message = createBaseInstrumentGroupReport();
    message.instruments = object.instruments?.map((e) => InstrumentGroupItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInstrumentGroupItem(): InstrumentGroupItem {
  return {
    id: '',
    name: '',
    description: '',
    cfiCode: '',
    contractMetadata: undefined,
    deleted: false,
    lastTradingDate: 0,
    maturityMonthYear: '',
    instrumentGroupName: '',
    reserved1: false,
    reserved2: '',
  };
}

export const InstrumentGroupItem = {
  encode(message: InstrumentGroupItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.cfiCode !== '') {
      writer.uint32(34).string(message.cfiCode);
    }
    if (message.contractMetadata !== undefined) {
      ContractMetadata.encode(message.contractMetadata, writer.uint32(50).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(56).bool(message.deleted);
    }
    if (message.lastTradingDate !== 0) {
      writer.uint32(64).sint64(message.lastTradingDate);
    }
    if (message.maturityMonthYear !== '') {
      writer.uint32(74).string(message.maturityMonthYear);
    }
    if (message.instrumentGroupName !== '') {
      writer.uint32(82).string(message.instrumentGroupName);
    }
    if (message.reserved1 === true) {
      writer.uint32(40).bool(message.reserved1);
    }
    if (message.reserved2 !== '') {
      writer.uint32(90).string(message.reserved2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupItem {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.description = reader.string();
          break;
        case 4:
          message.cfiCode = reader.string();
          break;
        case 6:
          message.contractMetadata = ContractMetadata.decode(reader, reader.uint32());
          break;
        case 7:
          message.deleted = reader.bool();
          break;
        case 8:
          message.lastTradingDate = longToNumber(reader.sint64() as Long);
          break;
        case 9:
          message.maturityMonthYear = reader.string();
          break;
        case 10:
          message.instrumentGroupName = reader.string();
          break;
        case 5:
          message.reserved1 = reader.bool();
          break;
        case 11:
          message.reserved2 = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupItem {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      cfiCode: isSet(object.cfiCode) ? String(object.cfiCode) : '',
      contractMetadata: isSet(object.contractMetadata) ? ContractMetadata.fromJSON(object.contractMetadata) : undefined,
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
      lastTradingDate: isSet(object.lastTradingDate) ? Number(object.lastTradingDate) : 0,
      maturityMonthYear: isSet(object.maturityMonthYear) ? String(object.maturityMonthYear) : '',
      instrumentGroupName: isSet(object.instrumentGroupName) ? String(object.instrumentGroupName) : '',
      reserved1: isSet(object.reserved1) ? Boolean(object.reserved1) : false,
      reserved2: isSet(object.reserved2) ? String(object.reserved2) : '',
    };
  },

  toJSON(message: InstrumentGroupItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.cfiCode !== undefined && (obj.cfiCode = message.cfiCode);
    message.contractMetadata !== undefined &&
      (obj.contractMetadata = message.contractMetadata ? ContractMetadata.toJSON(message.contractMetadata) : undefined);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.lastTradingDate !== undefined && (obj.lastTradingDate = Math.round(message.lastTradingDate));
    message.maturityMonthYear !== undefined && (obj.maturityMonthYear = message.maturityMonthYear);
    message.instrumentGroupName !== undefined && (obj.instrumentGroupName = message.instrumentGroupName);
    message.reserved1 !== undefined && (obj.reserved1 = message.reserved1);
    message.reserved2 !== undefined && (obj.reserved2 = message.reserved2);
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupItem>, I>>(base?: I): InstrumentGroupItem {
    return InstrumentGroupItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupItem>, I>>(object: I): InstrumentGroupItem {
    const message = createBaseInstrumentGroupItem();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.cfiCode = object.cfiCode ?? '';
    message.contractMetadata =
      object.contractMetadata !== undefined && object.contractMetadata !== null
        ? ContractMetadata.fromPartial(object.contractMetadata)
        : undefined;
    message.deleted = object.deleted ?? false;
    message.lastTradingDate = object.lastTradingDate ?? 0;
    message.maturityMonthYear = object.maturityMonthYear ?? '';
    message.instrumentGroupName = object.instrumentGroupName ?? '';
    message.reserved1 = object.reserved1 ?? false;
    message.reserved2 = object.reserved2 ?? '';
    return message;
  },
};

function createBaseMarketStateMetadataRequest(): MarketStateMetadataRequest {
  return { marketStateGroupId: 0 };
}

export const MarketStateMetadataRequest = {
  encode(message: MarketStateMetadataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.marketStateGroupId !== 0) {
      writer.uint32(8).sint32(message.marketStateGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketStateMetadataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketStateMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.marketStateGroupId = reader.sint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketStateMetadataRequest {
    return { marketStateGroupId: isSet(object.marketStateGroupId) ? Number(object.marketStateGroupId) : 0 };
  },

  toJSON(message: MarketStateMetadataRequest): unknown {
    const obj: any = {};
    message.marketStateGroupId !== undefined && (obj.marketStateGroupId = Math.round(message.marketStateGroupId));
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketStateMetadataRequest>, I>>(base?: I): MarketStateMetadataRequest {
    return MarketStateMetadataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketStateMetadataRequest>, I>>(object: I): MarketStateMetadataRequest {
    const message = createBaseMarketStateMetadataRequest();
    message.marketStateGroupId = object.marketStateGroupId ?? 0;
    return message;
  },
};

function createBaseMarketStateMetadataReport(): MarketStateMetadataReport {
  return { marketStateAttributeMetadata: [] };
}

export const MarketStateMetadataReport = {
  encode(message: MarketStateMetadataReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.marketStateAttributeMetadata) {
      MarketStateAttributeMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketStateMetadataReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketStateMetadataReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.marketStateAttributeMetadata.push(MarketStateAttributeMetadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketStateMetadataReport {
    return {
      marketStateAttributeMetadata: Array.isArray(object?.marketStateAttributeMetadata)
        ? object.marketStateAttributeMetadata.map((e: any) => MarketStateAttributeMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MarketStateMetadataReport): unknown {
    const obj: any = {};
    if (message.marketStateAttributeMetadata) {
      obj.marketStateAttributeMetadata = message.marketStateAttributeMetadata.map((e) =>
        e ? MarketStateAttributeMetadata.toJSON(e) : undefined
      );
    } else {
      obj.marketStateAttributeMetadata = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketStateMetadataReport>, I>>(base?: I): MarketStateMetadataReport {
    return MarketStateMetadataReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketStateMetadataReport>, I>>(object: I): MarketStateMetadataReport {
    const message = createBaseMarketStateMetadataReport();
    message.marketStateAttributeMetadata =
      object.marketStateAttributeMetadata?.map((e) => MarketStateAttributeMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMarketStateAttributeMetadata(): MarketStateAttributeMetadata {
  return { name: '', level: 0, valueMetadata: [], deleted: false };
}

export const MarketStateAttributeMetadata = {
  encode(message: MarketStateAttributeMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.level !== 0) {
      writer.uint32(16).uint32(message.level);
    }
    for (const v of message.valueMetadata) {
      MarketStateValueMetadata.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(32).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketStateAttributeMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketStateAttributeMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.level = reader.uint32();
          break;
        case 3:
          message.valueMetadata.push(MarketStateValueMetadata.decode(reader, reader.uint32()));
          break;
        case 4:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketStateAttributeMetadata {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      level: isSet(object.level) ? Number(object.level) : 0,
      valueMetadata: Array.isArray(object?.valueMetadata)
        ? object.valueMetadata.map((e: any) => MarketStateValueMetadata.fromJSON(e))
        : [],
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: MarketStateAttributeMetadata): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.level !== undefined && (obj.level = Math.round(message.level));
    if (message.valueMetadata) {
      obj.valueMetadata = message.valueMetadata.map((e) => (e ? MarketStateValueMetadata.toJSON(e) : undefined));
    } else {
      obj.valueMetadata = [];
    }
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketStateAttributeMetadata>, I>>(base?: I): MarketStateAttributeMetadata {
    return MarketStateAttributeMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketStateAttributeMetadata>, I>>(object: I): MarketStateAttributeMetadata {
    const message = createBaseMarketStateAttributeMetadata();
    message.name = object.name ?? '';
    message.level = object.level ?? 0;
    message.valueMetadata = object.valueMetadata?.map((e) => MarketStateValueMetadata.fromPartial(e)) || [];
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseMarketStateValueMetadata(): MarketStateValueMetadata {
  return { value: '', description: '', deleted: false };
}

export const MarketStateValueMetadata = {
  encode(message: MarketStateValueMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== '') {
      writer.uint32(10).string(message.value);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    if (message.deleted === true) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketStateValueMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketStateValueMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.value = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketStateValueMetadata {
    return {
      value: isSet(object.value) ? String(object.value) : '',
      description: isSet(object.description) ? String(object.description) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: MarketStateValueMetadata): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.description !== undefined && (obj.description = message.description);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketStateValueMetadata>, I>>(base?: I): MarketStateValueMetadata {
    return MarketStateValueMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketStateValueMetadata>, I>>(object: I): MarketStateValueMetadata {
    const message = createBaseMarketStateValueMetadata();
    message.value = object.value ?? '';
    message.description = object.description ?? '';
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseExchangeMetadataRequest(): ExchangeMetadataRequest {
  return {};
}

export const ExchangeMetadataRequest = {
  encode(_: ExchangeMetadataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeMetadataRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ExchangeMetadataRequest {
    return {};
  },

  toJSON(_: ExchangeMetadataRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeMetadataRequest>, I>>(base?: I): ExchangeMetadataRequest {
    return ExchangeMetadataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeMetadataRequest>, I>>(_: I): ExchangeMetadataRequest {
    const message = createBaseExchangeMetadataRequest();
    return message;
  },
};

function createBaseExchangeMetadataReport(): ExchangeMetadataReport {
  return { exchangeMetadata: [] };
}

export const ExchangeMetadataReport = {
  encode(message: ExchangeMetadataReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.exchangeMetadata) {
      ExchangeMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeMetadataReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeMetadataReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeMetadata.push(ExchangeMetadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeMetadataReport {
    return {
      exchangeMetadata: Array.isArray(object?.exchangeMetadata)
        ? object.exchangeMetadata.map((e: any) => ExchangeMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExchangeMetadataReport): unknown {
    const obj: any = {};
    if (message.exchangeMetadata) {
      obj.exchangeMetadata = message.exchangeMetadata.map((e) => (e ? ExchangeMetadata.toJSON(e) : undefined));
    } else {
      obj.exchangeMetadata = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeMetadataReport>, I>>(base?: I): ExchangeMetadataReport {
    return ExchangeMetadataReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeMetadataReport>, I>>(object: I): ExchangeMetadataReport {
    const message = createBaseExchangeMetadataReport();
    message.exchangeMetadata = object.exchangeMetadata?.map((e) => ExchangeMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseExchangeMetadata(): ExchangeMetadata {
  return { exchangeId: 0, contributorType: 0, abbreviation: '', name: '', description: '', deleted: false };
}

export const ExchangeMetadata = {
  encode(message: ExchangeMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeId !== 0) {
      writer.uint32(8).sint32(message.exchangeId);
    }
    if (message.contributorType !== 0) {
      writer.uint32(16).uint32(message.contributorType);
    }
    if (message.abbreviation !== '') {
      writer.uint32(26).string(message.abbreviation);
    }
    if (message.name !== '') {
      writer.uint32(34).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(42).string(message.description);
    }
    if (message.deleted === true) {
      writer.uint32(48).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeId = reader.sint32();
          break;
        case 2:
          message.contributorType = reader.uint32();
          break;
        case 3:
          message.abbreviation = reader.string();
          break;
        case 4:
          message.name = reader.string();
          break;
        case 5:
          message.description = reader.string();
          break;
        case 6:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeMetadata {
    return {
      exchangeId: isSet(object.exchangeId) ? Number(object.exchangeId) : 0,
      contributorType: isSet(object.contributorType) ? Number(object.contributorType) : 0,
      abbreviation: isSet(object.abbreviation) ? String(object.abbreviation) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: ExchangeMetadata): unknown {
    const obj: any = {};
    message.exchangeId !== undefined && (obj.exchangeId = Math.round(message.exchangeId));
    message.contributorType !== undefined && (obj.contributorType = Math.round(message.contributorType));
    message.abbreviation !== undefined && (obj.abbreviation = message.abbreviation);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeMetadata>, I>>(base?: I): ExchangeMetadata {
    return ExchangeMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeMetadata>, I>>(object: I): ExchangeMetadata {
    const message = createBaseExchangeMetadata();
    message.exchangeId = object.exchangeId ?? 0;
    message.contributorType = object.contributorType ?? 0;
    message.abbreviation = object.abbreviation ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseInstrumentGroupBySecuritiesRequest(): InstrumentGroupBySecuritiesRequest {
  return { securityIds: [] };
}

export const InstrumentGroupBySecuritiesRequest = {
  encode(message: InstrumentGroupBySecuritiesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.securityIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupBySecuritiesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupBySecuritiesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupBySecuritiesRequest {
    return { securityIds: Array.isArray(object?.securityIds) ? object.securityIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: InstrumentGroupBySecuritiesRequest): unknown {
    const obj: any = {};
    if (message.securityIds) {
      obj.securityIds = message.securityIds.map((e) => e);
    } else {
      obj.securityIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupBySecuritiesRequest>, I>>(base?: I): InstrumentGroupBySecuritiesRequest {
    return InstrumentGroupBySecuritiesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupBySecuritiesRequest>, I>>(
    object: I
  ): InstrumentGroupBySecuritiesRequest {
    const message = createBaseInstrumentGroupBySecuritiesRequest();
    message.securityIds = object.securityIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseInstrumentGroupBySecuritiesReport(): InstrumentGroupBySecuritiesReport {
  return { instruments: [] };
}

export const InstrumentGroupBySecuritiesReport = {
  encode(message: InstrumentGroupBySecuritiesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instruments) {
      InstrumentGroupItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupBySecuritiesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupBySecuritiesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instruments.push(InstrumentGroupItem.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupBySecuritiesReport {
    return {
      instruments: Array.isArray(object?.instruments) ? object.instruments.map((e: any) => InstrumentGroupItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: InstrumentGroupBySecuritiesReport): unknown {
    const obj: any = {};
    if (message.instruments) {
      obj.instruments = message.instruments.map((e) => (e ? InstrumentGroupItem.toJSON(e) : undefined));
    } else {
      obj.instruments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupBySecuritiesReport>, I>>(base?: I): InstrumentGroupBySecuritiesReport {
    return InstrumentGroupBySecuritiesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupBySecuritiesReport>, I>>(object: I): InstrumentGroupBySecuritiesReport {
    const message = createBaseInstrumentGroupBySecuritiesReport();
    message.instruments = object.instruments?.map((e) => InstrumentGroupItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInstrumentGroupByExchangeRequest(): InstrumentGroupByExchangeRequest {
  return { exchangeId: 0, instrumentGroupType: 0 };
}

export const InstrumentGroupByExchangeRequest = {
  encode(message: InstrumentGroupByExchangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeId !== 0) {
      writer.uint32(8).sint32(message.exchangeId);
    }
    if (message.instrumentGroupType !== 0) {
      writer.uint32(16).uint32(message.instrumentGroupType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupByExchangeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupByExchangeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeId = reader.sint32();
          break;
        case 2:
          message.instrumentGroupType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupByExchangeRequest {
    return {
      exchangeId: isSet(object.exchangeId) ? Number(object.exchangeId) : 0,
      instrumentGroupType: isSet(object.instrumentGroupType) ? Number(object.instrumentGroupType) : 0,
    };
  },

  toJSON(message: InstrumentGroupByExchangeRequest): unknown {
    const obj: any = {};
    message.exchangeId !== undefined && (obj.exchangeId = Math.round(message.exchangeId));
    message.instrumentGroupType !== undefined && (obj.instrumentGroupType = Math.round(message.instrumentGroupType));
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupByExchangeRequest>, I>>(base?: I): InstrumentGroupByExchangeRequest {
    return InstrumentGroupByExchangeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupByExchangeRequest>, I>>(object: I): InstrumentGroupByExchangeRequest {
    const message = createBaseInstrumentGroupByExchangeRequest();
    message.exchangeId = object.exchangeId ?? 0;
    message.instrumentGroupType = object.instrumentGroupType ?? 0;
    return message;
  },
};

function createBaseInstrumentGroupByExchangeReport(): InstrumentGroupByExchangeReport {
  return { instruments: [] };
}

export const InstrumentGroupByExchangeReport = {
  encode(message: InstrumentGroupByExchangeReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instruments) {
      InstrumentGroupItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentGroupByExchangeReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentGroupByExchangeReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.instruments.push(InstrumentGroupItem.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InstrumentGroupByExchangeReport {
    return {
      instruments: Array.isArray(object?.instruments) ? object.instruments.map((e: any) => InstrumentGroupItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: InstrumentGroupByExchangeReport): unknown {
    const obj: any = {};
    if (message.instruments) {
      obj.instruments = message.instruments.map((e) => (e ? InstrumentGroupItem.toJSON(e) : undefined));
    } else {
      obj.instruments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentGroupByExchangeReport>, I>>(base?: I): InstrumentGroupByExchangeReport {
    return InstrumentGroupByExchangeReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InstrumentGroupByExchangeReport>, I>>(object: I): InstrumentGroupByExchangeReport {
    const message = createBaseInstrumentGroupByExchangeReport();
    message.instruments = object.instruments?.map((e) => InstrumentGroupItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseExchangeSecuritiesRequest(): ExchangeSecuritiesRequest {
  return { exchangeId: 0, instrumentGroupType: 0 };
}

export const ExchangeSecuritiesRequest = {
  encode(message: ExchangeSecuritiesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeId !== 0) {
      writer.uint32(8).sint32(message.exchangeId);
    }
    if (message.instrumentGroupType !== 0) {
      writer.uint32(16).uint32(message.instrumentGroupType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSecuritiesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSecuritiesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeId = reader.sint32();
          break;
        case 2:
          message.instrumentGroupType = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeSecuritiesRequest {
    return {
      exchangeId: isSet(object.exchangeId) ? Number(object.exchangeId) : 0,
      instrumentGroupType: isSet(object.instrumentGroupType) ? Number(object.instrumentGroupType) : 0,
    };
  },

  toJSON(message: ExchangeSecuritiesRequest): unknown {
    const obj: any = {};
    message.exchangeId !== undefined && (obj.exchangeId = Math.round(message.exchangeId));
    message.instrumentGroupType !== undefined && (obj.instrumentGroupType = Math.round(message.instrumentGroupType));
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeSecuritiesRequest>, I>>(base?: I): ExchangeSecuritiesRequest {
    return ExchangeSecuritiesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeSecuritiesRequest>, I>>(object: I): ExchangeSecuritiesRequest {
    const message = createBaseExchangeSecuritiesRequest();
    message.exchangeId = object.exchangeId ?? 0;
    message.instrumentGroupType = object.instrumentGroupType ?? 0;
    return message;
  },
};

function createBaseExchangeSecuritiesReport(): ExchangeSecuritiesReport {
  return { exchangeSecurities: [] };
}

export const ExchangeSecuritiesReport = {
  encode(message: ExchangeSecuritiesReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.exchangeSecurities) {
      SecurityMetadata.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSecuritiesReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSecuritiesReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.exchangeSecurities.push(SecurityMetadata.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ExchangeSecuritiesReport {
    return {
      exchangeSecurities: Array.isArray(object?.exchangeSecurities)
        ? object.exchangeSecurities.map((e: any) => SecurityMetadata.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExchangeSecuritiesReport): unknown {
    const obj: any = {};
    if (message.exchangeSecurities) {
      obj.exchangeSecurities = message.exchangeSecurities.map((e) => (e ? SecurityMetadata.toJSON(e) : undefined));
    } else {
      obj.exchangeSecurities = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeSecuritiesReport>, I>>(base?: I): ExchangeSecuritiesReport {
    return ExchangeSecuritiesReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExchangeSecuritiesReport>, I>>(object: I): ExchangeSecuritiesReport {
    const message = createBaseExchangeSecuritiesReport();
    message.exchangeSecurities = object.exchangeSecurities?.map((e) => SecurityMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProcessingMetadata(): ProcessingMetadata {
  return {
    currency: '',
    tickSize: 0,
    tickValue: 0,
    contractSizeInUnits: undefined,
    contractSizeUnit: undefined,
    currencyRateInstrumentGroupName: '',
    currencyHedgeInstrumentGroupName: '',
  };
}

export const ProcessingMetadata = {
  encode(message: ProcessingMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== '') {
      writer.uint32(10).string(message.currency);
    }
    if (message.tickSize !== 0) {
      writer.uint32(17).double(message.tickSize);
    }
    if (message.tickValue !== 0) {
      writer.uint32(25).double(message.tickValue);
    }
    if (message.contractSizeInUnits !== undefined) {
      Decimal.encode(message.contractSizeInUnits, writer.uint32(34).fork()).ldelim();
    }
    if (message.contractSizeUnit !== undefined) {
      Text.encode(message.contractSizeUnit, writer.uint32(42).fork()).ldelim();
    }
    if (message.currencyRateInstrumentGroupName !== '') {
      writer.uint32(50).string(message.currencyRateInstrumentGroupName);
    }
    if (message.currencyHedgeInstrumentGroupName !== '') {
      writer.uint32(58).string(message.currencyHedgeInstrumentGroupName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessingMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessingMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.currency = reader.string();
          break;
        case 2:
          message.tickSize = reader.double();
          break;
        case 3:
          message.tickValue = reader.double();
          break;
        case 4:
          message.contractSizeInUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 5:
          message.contractSizeUnit = Text.decode(reader, reader.uint32());
          break;
        case 6:
          message.currencyRateInstrumentGroupName = reader.string();
          break;
        case 7:
          message.currencyHedgeInstrumentGroupName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProcessingMetadata {
    return {
      currency: isSet(object.currency) ? String(object.currency) : '',
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      tickValue: isSet(object.tickValue) ? Number(object.tickValue) : 0,
      contractSizeInUnits: isSet(object.contractSizeInUnits) ? Decimal.fromJSON(object.contractSizeInUnits) : undefined,
      contractSizeUnit: isSet(object.contractSizeUnit) ? Text.fromJSON(object.contractSizeUnit) : undefined,
      currencyRateInstrumentGroupName: isSet(object.currencyRateInstrumentGroupName)
        ? String(object.currencyRateInstrumentGroupName)
        : '',
      currencyHedgeInstrumentGroupName: isSet(object.currencyHedgeInstrumentGroupName)
        ? String(object.currencyHedgeInstrumentGroupName)
        : '',
    };
  },

  toJSON(message: ProcessingMetadata): unknown {
    const obj: any = {};
    message.currency !== undefined && (obj.currency = message.currency);
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.tickValue !== undefined && (obj.tickValue = message.tickValue);
    message.contractSizeInUnits !== undefined &&
      (obj.contractSizeInUnits = message.contractSizeInUnits ? Decimal.toJSON(message.contractSizeInUnits) : undefined);
    message.contractSizeUnit !== undefined &&
      (obj.contractSizeUnit = message.contractSizeUnit ? Text.toJSON(message.contractSizeUnit) : undefined);
    message.currencyRateInstrumentGroupName !== undefined &&
      (obj.currencyRateInstrumentGroupName = message.currencyRateInstrumentGroupName);
    message.currencyHedgeInstrumentGroupName !== undefined &&
      (obj.currencyHedgeInstrumentGroupName = message.currencyHedgeInstrumentGroupName);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessingMetadata>, I>>(base?: I): ProcessingMetadata {
    return ProcessingMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProcessingMetadata>, I>>(object: I): ProcessingMetadata {
    const message = createBaseProcessingMetadata();
    message.currency = object.currency ?? '';
    message.tickSize = object.tickSize ?? 0;
    message.tickValue = object.tickValue ?? 0;
    message.contractSizeInUnits =
      object.contractSizeInUnits !== undefined && object.contractSizeInUnits !== null
        ? Decimal.fromPartial(object.contractSizeInUnits)
        : undefined;
    message.contractSizeUnit =
      object.contractSizeUnit !== undefined && object.contractSizeUnit !== null
        ? Text.fromPartial(object.contractSizeUnit)
        : undefined;
    message.currencyRateInstrumentGroupName = object.currencyRateInstrumentGroupName ?? '';
    message.currencyHedgeInstrumentGroupName = object.currencyHedgeInstrumentGroupName ?? '';
    return message;
  },
};

function createBaseSecurityMetadata(): SecurityMetadata {
  return {
    securityId: '',
    name: '',
    description: '',
    cfiCode: '',
    currency: '',
    tickSize: 0,
    tickValue: 0,
    periodType: 0,
    periodValue: 0,
    contractSizeInUnits: undefined,
    contractSizeUnit: undefined,
    contributorParameters: [],
    symbolId: '',
    sourceInstrumentGroupName: '',
    processingMetadata: [],
    deleted: false,
  };
}

export const SecurityMetadata = {
  encode(message: SecurityMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.securityId !== '') {
      writer.uint32(10).string(message.securityId);
    }
    if (message.name !== '') {
      writer.uint32(114).string(message.name);
    }
    if (message.description !== '') {
      writer.uint32(122).string(message.description);
    }
    if (message.cfiCode !== '') {
      writer.uint32(18).string(message.cfiCode);
    }
    if (message.currency !== '') {
      writer.uint32(26).string(message.currency);
    }
    if (message.tickSize !== 0) {
      writer.uint32(33).double(message.tickSize);
    }
    if (message.tickValue !== 0) {
      writer.uint32(41).double(message.tickValue);
    }
    if (message.periodType !== 0) {
      writer.uint32(48).uint32(message.periodType);
    }
    if (message.periodValue !== 0) {
      writer.uint32(56).uint32(message.periodValue);
    }
    if (message.contractSizeInUnits !== undefined) {
      Decimal.encode(message.contractSizeInUnits, writer.uint32(66).fork()).ldelim();
    }
    if (message.contractSizeUnit !== undefined) {
      Text.encode(message.contractSizeUnit, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.contributorParameters) {
      ContributorParameters.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.symbolId !== '') {
      writer.uint32(90).string(message.symbolId);
    }
    if (message.sourceInstrumentGroupName !== '') {
      writer.uint32(98).string(message.sourceInstrumentGroupName);
    }
    for (const v of message.processingMetadata) {
      ProcessingMetadata.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(128).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecurityMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecurityMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.securityId = reader.string();
          break;
        case 14:
          message.name = reader.string();
          break;
        case 15:
          message.description = reader.string();
          break;
        case 2:
          message.cfiCode = reader.string();
          break;
        case 3:
          message.currency = reader.string();
          break;
        case 4:
          message.tickSize = reader.double();
          break;
        case 5:
          message.tickValue = reader.double();
          break;
        case 6:
          message.periodType = reader.uint32();
          break;
        case 7:
          message.periodValue = reader.uint32();
          break;
        case 8:
          message.contractSizeInUnits = Decimal.decode(reader, reader.uint32());
          break;
        case 9:
          message.contractSizeUnit = Text.decode(reader, reader.uint32());
          break;
        case 10:
          message.contributorParameters.push(ContributorParameters.decode(reader, reader.uint32()));
          break;
        case 11:
          message.symbolId = reader.string();
          break;
        case 12:
          message.sourceInstrumentGroupName = reader.string();
          break;
        case 13:
          message.processingMetadata.push(ProcessingMetadata.decode(reader, reader.uint32()));
          break;
        case 16:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecurityMetadata {
    return {
      securityId: isSet(object.securityId) ? String(object.securityId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      description: isSet(object.description) ? String(object.description) : '',
      cfiCode: isSet(object.cfiCode) ? String(object.cfiCode) : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
      tickSize: isSet(object.tickSize) ? Number(object.tickSize) : 0,
      tickValue: isSet(object.tickValue) ? Number(object.tickValue) : 0,
      periodType: isSet(object.periodType) ? Number(object.periodType) : 0,
      periodValue: isSet(object.periodValue) ? Number(object.periodValue) : 0,
      contractSizeInUnits: isSet(object.contractSizeInUnits) ? Decimal.fromJSON(object.contractSizeInUnits) : undefined,
      contractSizeUnit: isSet(object.contractSizeUnit) ? Text.fromJSON(object.contractSizeUnit) : undefined,
      contributorParameters: Array.isArray(object?.contributorParameters)
        ? object.contributorParameters.map((e: any) => ContributorParameters.fromJSON(e))
        : [],
      symbolId: isSet(object.symbolId) ? String(object.symbolId) : '',
      sourceInstrumentGroupName: isSet(object.sourceInstrumentGroupName) ? String(object.sourceInstrumentGroupName) : '',
      processingMetadata: Array.isArray(object?.processingMetadata)
        ? object.processingMetadata.map((e: any) => ProcessingMetadata.fromJSON(e))
        : [],
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: SecurityMetadata): unknown {
    const obj: any = {};
    message.securityId !== undefined && (obj.securityId = message.securityId);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.cfiCode !== undefined && (obj.cfiCode = message.cfiCode);
    message.currency !== undefined && (obj.currency = message.currency);
    message.tickSize !== undefined && (obj.tickSize = message.tickSize);
    message.tickValue !== undefined && (obj.tickValue = message.tickValue);
    message.periodType !== undefined && (obj.periodType = Math.round(message.periodType));
    message.periodValue !== undefined && (obj.periodValue = Math.round(message.periodValue));
    message.contractSizeInUnits !== undefined &&
      (obj.contractSizeInUnits = message.contractSizeInUnits ? Decimal.toJSON(message.contractSizeInUnits) : undefined);
    message.contractSizeUnit !== undefined &&
      (obj.contractSizeUnit = message.contractSizeUnit ? Text.toJSON(message.contractSizeUnit) : undefined);
    if (message.contributorParameters) {
      obj.contributorParameters = message.contributorParameters.map((e) => (e ? ContributorParameters.toJSON(e) : undefined));
    } else {
      obj.contributorParameters = [];
    }
    message.symbolId !== undefined && (obj.symbolId = message.symbolId);
    message.sourceInstrumentGroupName !== undefined && (obj.sourceInstrumentGroupName = message.sourceInstrumentGroupName);
    if (message.processingMetadata) {
      obj.processingMetadata = message.processingMetadata.map((e) => (e ? ProcessingMetadata.toJSON(e) : undefined));
    } else {
      obj.processingMetadata = [];
    }
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<SecurityMetadata>, I>>(base?: I): SecurityMetadata {
    return SecurityMetadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SecurityMetadata>, I>>(object: I): SecurityMetadata {
    const message = createBaseSecurityMetadata();
    message.securityId = object.securityId ?? '';
    message.name = object.name ?? '';
    message.description = object.description ?? '';
    message.cfiCode = object.cfiCode ?? '';
    message.currency = object.currency ?? '';
    message.tickSize = object.tickSize ?? 0;
    message.tickValue = object.tickValue ?? 0;
    message.periodType = object.periodType ?? 0;
    message.periodValue = object.periodValue ?? 0;
    message.contractSizeInUnits =
      object.contractSizeInUnits !== undefined && object.contractSizeInUnits !== null
        ? Decimal.fromPartial(object.contractSizeInUnits)
        : undefined;
    message.contractSizeUnit =
      object.contractSizeUnit !== undefined && object.contractSizeUnit !== null
        ? Text.fromPartial(object.contractSizeUnit)
        : undefined;
    message.contributorParameters = object.contributorParameters?.map((e) => ContributorParameters.fromPartial(e)) || [];
    message.symbolId = object.symbolId ?? '';
    message.sourceInstrumentGroupName = object.sourceInstrumentGroupName ?? '';
    message.processingMetadata = object.processingMetadata?.map((e) => ProcessingMetadata.fromPartial(e)) || [];
    message.deleted = object.deleted ?? false;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
