/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'user_attribute_2';

/** User defined attribute. */
export interface UserAttribute {
  /** Attribute name. */
  name: string;
  /** Attribute value (optional for modification if attribute is deleted). */
  value: string;
  /** True if attribute has to be deleted during modify operation. */
  deleted: boolean;
}

/** Modification of order's user attributes. */
export interface ModifyUserAttributes {
  /** ID of the order chain. */
  chainOrderId: string;
  /** ID of the order's account. */
  accountId: number;
  /**
   * List of attributes to modify.
   * See Order.user_attribute for additional description.
   */
  userAttributes: UserAttribute[];
  /**
   * Flag used by the client for marking the user attributes checked/unchecked.
   * Empty means to leave the flag the same as it was.
   */
  isChecked: boolean;
}

function createBaseUserAttribute(): UserAttribute {
  return { name: '', value: '', deleted: false };
}

export const UserAttribute = {
  encode(message: UserAttribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    if (message.deleted === true) {
      writer.uint32(24).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAttribute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        case 3:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserAttribute {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      value: isSet(object.value) ? String(object.value) : '',
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: UserAttribute): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<UserAttribute>, I>>(base?: I): UserAttribute {
    return UserAttribute.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UserAttribute>, I>>(object: I): UserAttribute {
    const message = createBaseUserAttribute();
    message.name = object.name ?? '';
    message.value = object.value ?? '';
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseModifyUserAttributes(): ModifyUserAttributes {
  return { chainOrderId: '', accountId: 0, userAttributes: [], isChecked: false };
}

export const ModifyUserAttributes = {
  encode(message: ModifyUserAttributes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== '') {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    for (const v of message.userAttributes) {
      UserAttribute.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.isChecked === true) {
      writer.uint32(32).bool(message.isChecked);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyUserAttributes {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyUserAttributes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainOrderId = reader.string();
          break;
        case 2:
          message.accountId = reader.sint32();
          break;
        case 3:
          message.userAttributes.push(UserAttribute.decode(reader, reader.uint32()));
          break;
        case 4:
          message.isChecked = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ModifyUserAttributes {
    return {
      chainOrderId: isSet(object.chainOrderId) ? String(object.chainOrderId) : '',
      accountId: isSet(object.accountId) ? Number(object.accountId) : 0,
      userAttributes: Array.isArray(object?.userAttributes)
        ? object.userAttributes.map((e: any) => UserAttribute.fromJSON(e))
        : [],
      isChecked: isSet(object.isChecked) ? Boolean(object.isChecked) : false,
    };
  },

  toJSON(message: ModifyUserAttributes): unknown {
    const obj: any = {};
    message.chainOrderId !== undefined && (obj.chainOrderId = message.chainOrderId);
    message.accountId !== undefined && (obj.accountId = Math.round(message.accountId));
    if (message.userAttributes) {
      obj.userAttributes = message.userAttributes.map((e) => (e ? UserAttribute.toJSON(e) : undefined));
    } else {
      obj.userAttributes = [];
    }
    message.isChecked !== undefined && (obj.isChecked = message.isChecked);
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyUserAttributes>, I>>(base?: I): ModifyUserAttributes {
    return ModifyUserAttributes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ModifyUserAttributes>, I>>(object: I): ModifyUserAttributes {
    const message = createBaseModifyUserAttributes();
    message.chainOrderId = object.chainOrderId ?? '';
    message.accountId = object.accountId ?? 0;
    message.userAttributes = object.userAttributes?.map((e) => UserAttribute.fromPartial(e)) || [];
    message.isChecked = object.isChecked ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
