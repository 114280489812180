import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import './Wizard.scss';
import Button from '../common/Button/Button';
import InputWithLabel, { InputEventType } from '../common/Inputs/InputWithLabel/InputWithLabel';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { getBudget, IOperationCropWizard } from './Wizard';
import { BudgetType, IBudget, IBudgetsItem } from '../../types/IBudget';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/Store';
import { createSelector } from '@reduxjs/toolkit';
import { selectBudgets } from '../../store/Budgets/BudgetsSlice';
import { LoadingStatuses } from '../../store/LoadingStatuses';
import { fetchDefaultUniversityBudgets } from '../../store/Budgets/thunks';
import SelectWithLabel from '../common/SelectWithLabel/SelectWithLabel';
import { getSelectedUniversityBudgets, getUniversityBudgetsOptions } from '../Settings/CostsV2/UniversityMode';
import dayjs from 'dayjs';
import { isEmpty, sum } from 'lodash';
import { formatNumber } from '../../utils/formatNumber';
import { OperationEdit } from '../../types/Operation';
import { getCommodities } from '@harvestiq/constants';

const ESTIMATED_COST_LABEL = 'Production Costs';
const UNIVERSITY_BUDGET_LABEL = 'University Budget';

const makeSelectDefaultUniversityBudgets = () =>
  createSelector(selectBudgets, (budgets) =>
    budgets.filter(
      (budget) =>
        budget.budgetType === BudgetType.University &&
        budget.year === dayjs().year().toString() &&
        budget.operationId === null &&
        budget.isActive
    )
  );

interface WizardEstimatedCostProps {
  handleBack: () => void;
  openNextStep: () => void;
  onSkip: () => void;
  operation: OperationEdit;
  operationCrops: IOperationCropWizard[];
  budget: IBudget;
  setBudget: Dispatch<SetStateAction<IBudget>>;
}

export default function WizardEstimatedCost(props: WizardEstimatedCostProps) {
  const { budget, setBudget, operationCrops, operation, handleBack, openNextStep, onSkip } = props;
  const crops = getCommodities();
  const budgetStatus = useSelector((state: RootState) => state.budgets.status);
  const selectDefaultUniversityBudgets = useMemo(makeSelectDefaultUniversityBudgets, []);
  const defaultUniversityBudgets = useSelector((state: RootState) => selectDefaultUniversityBudgets(state));
  const [budgetType, setBudgetType] = useState<BudgetType | null>(budget.budgetType);
  const isMobileView = useMobileView(BREAKPOINTS.WIZARD_MOBILE);
  const currency = useCurrentCurrency();
  const dispatch = useDispatch();

  useEffect(() => {
    if (budgetStatus !== LoadingStatuses.Loading && budgetStatus !== LoadingStatuses.Failed && !defaultUniversityBudgets.length) {
      dispatch(fetchDefaultUniversityBudgets());
    }
  }, [dispatch, budgetStatus, defaultUniversityBudgets]);

  useEffect(() => {
    if (operationCrops.length && isEmpty(budget.cropBudgets) && budget.budgetType === BudgetType.Simple) {
      const initialBudget = getBudget(operationCrops, crops, operation, budget.budgetType || BudgetType.Simple);
      setBudget(initialBudget);
    }
  });

  const onChangeBudgetType = (budgetType: BudgetType) => {
    setBudgetType(budgetType);
    setBudget(getBudget(operationCrops, crops, operation, budgetType));
  };

  const onChangeSimpleModeHandler = (e: InputEventType, cropId: number) => {
    const newBudgetItem = {
      valueType: 2,
      value: Number(e.target.value),
      categoryName: 'Production Cost',
    };

    setBudget((previousBudget) => ({
      ...previousBudget,
      cropBudgets: {
        ...previousBudget.cropBudgets,
        [cropId]: [newBudgetItem],
      },
    }));
  };

  const onSelectBudgetHandler = (universityBudgetId: number, cropId: number) => {
    const defaultUniversityBudgetItems = defaultUniversityBudgets.find((budget) => budget.id === universityBudgetId)?.cropBudgets[
      cropId
    ];

    if (!defaultUniversityBudgetItems?.length) {
      return;
    }

    setBudget((previousBudget) => ({
      ...previousBudget,
      cropBudgets: {
        ...previousBudget.cropBudgets,
        [cropId]: [...defaultUniversityBudgetItems],
      },
    }));
  };

  return (
    <div id="wizard-step4" className="wizard-card wizard-step4">
      <div className="wizard-card-header">
        <h3>What is your estimated cost of production?</h3>
        <p />
      </div>
      <div className="fr-navbar">
        <ul>
          <li>
            <span
              className={'fr-navbar-item' + (budgetType === BudgetType.Simple ? ' fr-navbar-selected-item' : '')}
              onClick={() => onChangeBudgetType(BudgetType.Simple)}
            >
              Simple Cost
            </span>
          </li>
          <li>
            <span
              className={'fr-navbar-item' + (budgetType === BudgetType.University ? ' fr-navbar-selected-item' : '')}
              onClick={() => onChangeBudgetType(BudgetType.University)}
            >
              Use University Budget
            </span>
          </li>
        </ul>
      </div>
      {operationCrops.length && (
        <div>
          <div id="wizard-step4-body" className="wizard-card-body">
            <div className={`wizard-table${isMobileView ? '-mobile' : ''}`}>
              {!isMobileView && (
                <div className="wizard-table-head">
                  <div className="wizard-table-row">
                    <span className="wizard-table-cell">Crop</span>
                    <span className="wizard-table-cell">
                      {budgetType === BudgetType.Simple && ESTIMATED_COST_LABEL}
                      {budgetType === BudgetType.University && UNIVERSITY_BUDGET_LABEL}
                    </span>
                    {budgetType === BudgetType.University && <span className="wizard-table-cell">Total</span>}
                  </div>
                </div>
              )}
              <div className="wizard-table-body">
                {operationCrops
                  .filter((crop: IOperationCropWizard) => crop.selected)
                  .map((crop: IOperationCropWizard) => (
                    <div className="wizard-table-row" key={crop.name}>
                      <span className="wizard-table-cell">{crop.name}</span>
                      <span className="wizard-table-cell">
                        <div className="wizard-input-wrapper">
                          {budgetType === BudgetType.Simple && (
                            <InputWithLabel
                              label={isMobileView ? ESTIMATED_COST_LABEL : ''}
                              type={'number'}
                              min={0}
                              value={
                                budget.cropBudgets[crop.marketingCropType]
                                  ? budget.cropBudgets[crop.marketingCropType][0].value
                                  : ''
                              }
                              onChange={(e: InputEventType) => onChangeSimpleModeHandler(e, crop.marketingCropType)}
                              additional={{
                                rightSideComponent: <span className="wizard-right-side-component">{currency.symbol}/ac</span>,
                              }}
                            />
                          )}
                          {budgetType === BudgetType.University && (
                            <SelectWithLabel<number>
                              label={isMobileView ? UNIVERSITY_BUDGET_LABEL : ''}
                              options={getUniversityBudgetsOptions(defaultUniversityBudgets, crop.marketingCropType)}
                              selected={getSelectedUniversityBudgets(
                                defaultUniversityBudgets,
                                crop.marketingCropType,
                                budget.cropBudgets[crop.marketingCropType]
                              )}
                              onChange={(e) => onSelectBudgetHandler(e.value, crop.marketingCropType)}
                            />
                          )}
                        </div>
                      </span>
                      {budgetType === BudgetType.University && (
                        <span className="wizard-table-cell">
                          {formatNumber(getTotalCropBudget(budget.cropBudgets[crop.marketingCropType]), 2, currency)}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="wizard-card-footer">
            <Button value="Skip Wizard" className="fr-button wizard-skip-button" onClick={onSkip} />
            <div>
              <Button value="Back" className="fr-button backGround-none wizard-back-button" onClick={handleBack} />
              <Button value="Next" className="fr-button backGround-red" onClick={openNextStep} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const getTotalCropBudget = (cropBudgets: IBudgetsItem[]) => {
  return cropBudgets && cropBudgets.length ? sum(cropBudgets.map((cropBudget) => cropBudget.value || 0)) : 0;
};
