import styles from './SplitButton.module.scss';

import React from 'react';
import { SplitButtonProps as KSplitButtonProps } from '@progress/kendo-react-buttons/dist/npm/ListButton/models/ListButtonProps';
import { SplitButton as KSplitButton } from '@progress/kendo-react-buttons/dist/npm/ListButton/SplitButton';

interface SplitButtonProps extends KSplitButtonProps {}

function SplitButtonWithRef(props: SplitButtonProps, ref: React.LegacyRef<KSplitButton>) {
  const { className, ...otherProps } = props;
  return (
    <div className={`${styles['fr-split-button']} ${className}`}>
      <KSplitButton ref={ref} {...otherProps} />
    </div>
  );
}

const SplitButton = React.forwardRef(SplitButtonWithRef);
export default SplitButton;
