import styles from './Tooltip.module.scss';

import * as React from 'react';
import { Tooltip as KTooltip, TooltipProps as KTooltipProps } from '@progress/kendo-react-tooltip';

export interface TooltipProps extends KTooltipProps {
  maxWidth?: string | number;
}

// Note: doesn't currently support refs
export default function Tooltip(props: TooltipProps) {
  const {
    className,
    maxWidth = 400,
    anchorElement = 'target',
    position = 'auto',
    showCallout = false,
    parentTitle = true,
    ...otherProps
  } = props;
  return (
    <KTooltip
      className={`${styles['fr-tooltip']} ${className}`}
      style={{ maxWidth: `${maxWidth}px` }}
      anchorElement={anchorElement}
      position={position}
      showCallout={showCallout}
      parentTitle={parentTitle}
      {...otherProps}
    />
  );
}
