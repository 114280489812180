import { datadogRum } from '@datadog/browser-rum';
import { Pages } from '@farmersrisk/shared/constants/Pages';
import { isNil } from 'lodash';
import React, { Suspense } from 'react';
import { matchPath, Redirect, Route, Switch, useLocation, match } from 'react-router-dom';
import Loader from './components/common/Loader/Loader';
import { CQGProvider } from './components/CQG/CQGProvider';
import ProtectedHeader from './components/ProtectedHeader/ProtectedHeader';
import UpHeader from './components/ProtectedHeader/UpHeader/UpHeader';
import Trade from './components/Trade/Trade';
import Wizard from './components/Wizard/Wizard';
import config from './config';
import useAppContext from './customHooks/useAppContext';
import { useCheckForUpdates } from './customHooks/useCheckForUpdates';
import { useCurrentOperationIdFromUser } from './customHooks/useCurrentOperationIdFromUser';
import TradeRouter from './routes/TradeRouter';
import { AppContextProvider } from './shared/AppContext';
import { getImpersonatingConfig } from './shared/storageService';
import { IAdvisorSignup } from './types/IAdvisorSignup';
import { Operation } from './types/Operation';
import { User } from './types/User';
import DashboardRouter from './routes/DashboardRouter';
import AdvisorHubRouter from './routes/AdvisorHubRouter';

const MyPositionsPage = React.lazy(() => import('./pages/MyPositions/MyPositionsPage'));
const CashSalesRouter = React.lazy(() => import('./routes/CashSalesRouter'));
const CashBidsPage = React.lazy(() => import('./pages/CashBids/CashBidsPage'));
const ScenarioAnalysis = React.lazy(() => import('./components/ScenarioAnalysis/ScenarioAnalysis'));
const LivestockRouter = React.lazy(() => import('./routes/LivestockRouter'));
const LivestockLotRouter = React.lazy(() => import('./routes/LivestockLotRouter'));
const ReportsPage = React.lazy(() => import('./pages/Reports/ReportsPage'));
const SettingsRouter = React.lazy(() => import('./routes/SettingsRouter'));
const AccountPage = React.lazy(() => import('./components/Settings/Account/Account'));
const ElevatorsPage = React.lazy(() => import('./components/Elevators/Elevators'));
const FaqPage = React.lazy(() => import('./components/FAQ/FAQ'));

// Admin
const AdminPage = React.lazy(() => import('./pages/Admin/Admin'));

// DEV PAGES
const TradeInsightsPage = React.lazy(() => import('./pages/TradeInsights/TradeInsightsPage'));
const PnLPage = React.lazy(() => import('./pages/PnL/PnLPage'));
const NotFoundPage = React.lazy(
  () => import(/* @vite-ignore */ `./pages/NotFound/${config.env === 'development' ? 'not-found-dev' : 'not-found-others'}`)
);

// Avoid type errors with Pendo which is installed globally in index.html
declare let pendo: any;

type RRLocation = ReturnType<typeof useLocation>;

function wizardPathMatch(location: RRLocation) {
  const match: match<Partial<IAdvisorSignup>> | null = matchPath(location.pathname, {
    path: '/wizard/:advisorId?/:verificationCode?',
  });
  return match;
}

function shouldRedirectToWizard(location: RRLocation, user: User, currentOperation?: Operation) {
  return (user.isWizardCompleted === null || !currentOperation) && !wizardPathMatch(location);
}

function shouldRedirectToDashboard(location: RRLocation, user: User, currentOperation?: Operation) {
  return user.isWizardCompleted !== null && currentOperation && wizardPathMatch(location);
}

export default function ProtectedApp() {
  return (
    <AppContextProvider>
      <ProtectedAppInner></ProtectedAppInner>
    </AppContextProvider>
  );
}

function ProtectedAppInner() {
  const { isLoading, currentOperation, currentUser } = useAppContext();
  useCheckForUpdates();

  const location = useLocation();
  const impersonatingConfig = getImpersonatingConfig();
  const env = config.env;

  if (isLoading || !currentUser) {
    return <Loader></Loader>;
  }

  const trackingUser = impersonatingConfig?.adminUser || currentUser;

  if (config.dataDogEnabled && currentOperation?.id) {
    datadogRum.setUser({
      id: String(trackingUser.id),
      name: `${trackingUser.givenName} ${trackingUser.familyName}`,
      email: trackingUser.email ?? '',
      operationId: currentOperation.id,
      operationName: currentOperation.name,
    });
  }

  if (config.pendoEnabled && currentOperation?.id) {
    const pendoOpts = {
      visitor: {
        id: `${env}-${trackingUser.id}`,
        email: trackingUser.email || '',
        full_name: `${trackingUser.givenName} ${trackingUser.familyName}`,
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: `${env}-${currentOperation.id}`,
        name: currentOperation.name,
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback

        isPremium: currentOperation.isPremium ?? false,
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },

      cookieDomain: '.harvestiq.com',
    };

    pendo.initialize(pendoOpts);
  }

  // const datadogContext = datadogRum.getInternalContext();

  // window.zE.identify({
  //   name: `${user.givenName} ${user.familyName}`,
  //   email: user.email,
  //   organization: currentOperation?.name,
  // });

  // window.zE('webWidget', 'updateSettings', {
  //   webWidget: {
  //     contactForm: {
  //       suppress: history.location.pathname.includes('trade') ? true : false,
  //       fields: [
  //         { id: 9259982128027, prefill: { '*': currentOperation.id } },
  //         { id: 9268832266011, prefill: { '*': user.id } },
  //         { id: 9268926167963, prefill: { '*': currentOperation.name } },
  //         { id: 9269151151771, prefill: { '*': datadogContext?.session_id } },
  //       ],
  //     },
  //   },
  // });

  if (shouldRedirectToWizard(location, currentUser, currentOperation)) {
    let params: Partial<IAdvisorSignup> = {};
    const match = matchPath(location.pathname, {
      path: '/dashboard/:advisorId?/:verificationCode?',
    });
    if (!isNil(match)) {
      params = match.params;
    }
    const wizardPath =
      !isNil(params.advisorId) && !isNil(params.verificationCode)
        ? `/wizard/${params.advisorId}/${params.verificationCode}`
        : '/wizard';
    console.log('redirecting to wizard', wizardPath);
    return <Redirect to={wizardPath} from="*" />;
  }
  if (shouldRedirectToDashboard(location, currentUser, currentOperation)) {
    return <Redirect to="/dashboard" from="*" />;
  }

  const wizardMatch = wizardPathMatch(location);
  if (!isNil(wizardMatch)) {
    console.log('outside of router');
    return <Wizard advisorId={wizardMatch.params.advisorId} verificationCode={wizardMatch.params.verificationCode} />;
  }

  const canAdmin = currentUser.isAuth0Admin || impersonatingConfig?.isImpersonating;

  return (
    <CQGProvider>
      <UpHeader />
      <ProtectedHeader />
      <div className="flex-row app-body">
        <TradeRouter />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/operations/:operationId/*" component={useCurrentOperationIdFromUser} />
            <Route path="/dashboard">
              <DashboardRouter />
            </Route>
            <Route path="/my-positions/:hedgingCropType?/:pageTab?">
              <MyPositionsPage />
            </Route>
            <Route path="/cash-sales*">
              <CashSalesRouter />
            </Route>
            <Route path="/scenario-analysis/:operationCropId?">
              <ScenarioAnalysis />
            </Route>

            <Route path="/settings">
              <SettingsRouter />
            </Route>

            {canAdmin && (
              <Route path="/admin">
                <AdminPage />
              </Route>
            )}

            <Route path="/account">
              <AccountPage />
            </Route>
            <Route path="/trade" exact>
              <Trade />
            </Route>
            <Route path="/cash-bids">
              <CashBidsPage />
            </Route>
            <Route path="/crops/cash-sales*">
              <CashSalesRouter />
            </Route>
            <Route path="/crops/scenario-analysis/:operationCropId?">
              <ScenarioAnalysis />
            </Route>
            <Route path="/crops/cash-bids">
              <CashBidsPage />
            </Route>

            <Route path="/trade-insights/:symbol?">
              <TradeInsightsPage />
            </Route>
            <Route path="/elevators">
              <ElevatorsPage />
            </Route>
            <Route path="/reports">
              <ReportsPage />
            </Route>
            <Route path="/faq">
              <FaqPage />
            </Route>
            <Route path="/pnl">
              <PnLPage />
            </Route>

            <Route path={Pages.livestockLot.path}>
              <LivestockLotRouter />
            </Route>

            <Route path={Pages.livestock.path}>
              <LivestockRouter />
            </Route>

            <Route path={Pages.advisorHub.path}>
              <AdvisorHubRouter />
            </Route>

            {/* Here for debugging lazy load error handling */}
            <Route path="/not-found" component={NotFoundPage} />

            <Route path="*">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </CQGProvider>
  );
}
