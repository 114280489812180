import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Pages } from '@farmersrisk/shared/constants/Pages';

import SubNav, { SubNavItem } from '../components/SubNav/SubNav';
import useIsFeatureAccessible from '../customHooks/useIsFeatureAccessible';

const OperationsTab = React.lazy(() => import('../pages/AdvisorHub/OperationsTab'));
const CropsTab = React.lazy(() => import('../pages/AdvisorHub/CropsTab'));
const ReportsTab = React.lazy(() => import('../pages/AdvisorHub/ReportsTab'));

const items: SubNavItem[] = [
  {
    title: 'Operations',
    path: '/advisor-hub/operations',
  },
  {
    title: 'Crops',
    path: '/advisor-hub/crops',
  },
  {
    title: 'Reports',
    path: '/advisor-hub/reports',
  },
];

function AdvisorHubNavBar() {
  return (
    <div className="flex">
      <SubNav items={items} />
    </div>
  );
}

export default function AdvisorHubRouter() {
  const isAdvisorHubEnabled = useIsFeatureAccessible('isAdvisorHubEnabled');

  if (!isAdvisorHubEnabled) return <Redirect to="/dashboard" />;

  return (
    <div className="flex-column with-overflow">
      <AdvisorHubNavBar />

      <Route exact path={Pages.advisorHub.path}>
        <Redirect to={Pages.advisorHubCrops.path} />
      </Route>

      <Route exact path={Pages.advisorHubOperations.path}>
        <OperationsTab />
      </Route>

      <Route exact path={Pages.advisorHubCrops.path}>
        <CropsTab />
      </Route>

      <Route path={Pages.advisorHubReports.path}>
        <ReportsTab />
      </Route>
    </div>
  );
}
