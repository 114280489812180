import { ListItemProps } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { useAllHedgingCrops } from '../../../queries/operationCrops';
import { CommodityInformation, getTradeableCommodities } from '@harvestiq/constants';
import { SelectorTypes } from '../../common/SelectWithLabel/SelectWithLabel';
import CropSymbol from '../../common-next/CropSymbol/CropSymbol';
import DropDownList, { DropDownListProps } from '../../common-next/DropDownList/DropDownList';
import { ComponentWithElement } from '../../common-next';
import config from '../../../config';

const livestockTradingEnabledFF = config.featureFlags.livestockTradingEnabled ?? false;

export interface CommodityDropDownProps extends DropDownListProps {}

function CommodityDropDownWithRef(props: CommodityDropDownProps, ref: React.Ref<ComponentWithElement>) {
  const hedgingCropsQuery = useAllHedgingCrops();
  const tradeableCommodities = getTradeableCommodities();

  const hedgingCrops = hedgingCropsQuery.data || [];
  const optionData = livestockTradingEnabledFF ? tradeableCommodities : hedgingCrops;

  const optionItems = optionData.map((cmdty) => ({ label: cmdty.name, value: cmdty }));

  return (
    <DropDownList
      ref={ref}
      stretch
      label={props.label || 'Commodity'}
      valueRender={valueRender}
      itemRender={itemRender}
      data={optionItems}
      dataItemKey="value"
      textField="label"
      {...props}
    />
  );
}

const CommodityDropDown = React.forwardRef(CommodityDropDownWithRef);
export default CommodityDropDown;

// renders selected value
function valueRender(element: React.ReactElement<HTMLSpanElement>, value: SelectorTypes<CommodityInformation>) {
  if (!value) {
    return element;
  }

  const children = [
    <span key="name">{value.value.name}</span>,
    <span key="icon" className="ms-auto">
      <CropSymbol cropType={value.value.id} size={'small'} />
    </span>,
  ];

  return React.cloneElement(element, { ...element.props }, children);
}

// renders dropdown item
function itemRender(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) {
  const value = itemProps.dataItem;
  if (!value) {
    return li;
  }

  const children = [
    <span key="name">{value.value.name}</span>,
    <span key="icon" className="ms-auto">
      <CropSymbol cropType={value.value.id} size={'small'} />
    </span>,
  ];

  return React.cloneElement(li, { ...li.props }, children);
}
