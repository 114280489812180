import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import { LogLevel } from '.';

export class TradeLogger {
  static Name = 'cqgTradingLogger';
  static Contexts = [
    { key: 'context', value: 'trade' },
    { key: 'service', value: 'cqg' },
  ];
  env = 'development';
  enabled = false;

  constructor(env: string, enabled = true) {
    this.createLogger(env, enabled);
  }

  private createLogger(env: string, enabled = true) {
    this.env = env;
    this.enabled = enabled;
    const logger = datadogLogs.createLogger(TradeLogger.Name, {
      level: 'debug',
      handler: this.getHandlers(),
      context: datadogLogs.getGlobalContext(),
    });
    TradeLogger.Contexts.forEach((c) => {
      logger.setContextProperty(c.key, c.value);
    });
  }

  private getHandlers(): HandlerType[] {
    switch (this.env) {
      case 'ci':
        return [];
      case 'development':
        return ['console'];
      case 'test':
        return ['console', 'http'];
      case 'staging':
      case 'production':
      default:
        return ['http'];
    }
  }

  // Sanitization of cqgData is handled by the datadog init beforeSend function
  private log(level: LogLevel, message: string, context?: object) {
    const ddLogger = this.enabled ? datadogLogs.getLogger(TradeLogger.Name) : null;
    if (!ddLogger) {
      if (this.env === 'development') {
        if (this.enabled) {
          console.error(`Datadog Logger named ${TradeLogger.Name} was not found`);
        }
        console[level](`${TradeLogger.Name}:: ${message}`, context);
      }
      return;
    }
    ddLogger[level](message, context);
  }

  debug(message: string, context?: object) {
    this.log(LogLevel.debug, message, context);
  }

  info(message: string, context?: object) {
    this.log(LogLevel.info, message, context);
  }

  warn(message: string, context?: object) {
    this.log(LogLevel.warn, message, context);
  }

  error(message: string, context?: object) {
    this.log(LogLevel.error, message, context);
  }
}
