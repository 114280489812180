import React from 'react';
import './TabSwitcher.css';

export interface TabProps {
  active: boolean;
  name: string;
  onClick: () => void;
  tabClassName?: string;
  selectedClassName?: string;
}

function Tab(props: TabProps) {
  return (
    <span
      className={`${props.tabClassName || 'tab'}
     ${props.active ? props.selectedClassName || 'tab-active' : ''}`}
      onClick={props.onClick}
      data-testid={`tab-${props.name.toLowerCase()}`}
    >
      {props.name}
    </span>
  );
}

export default Tab;
