import * as React from 'react';
import styles from './TradeModal.module.scss';
import { FontAwesomeIcon, FontAwesomeIconProps, IconDefinition, faArrowUpRightFromSquare, faCircleArrowRight } from '../faIcons';
import { BuySellType } from '@farmersrisk/shared/enums';
import Button from '../common-next/Button/Button';
import { DialogProps } from '../common-next/Dialog/Dialog';
import DialogContent from '../common-next/Dialog/DialogContent';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import SteppedDialog from '../common-next/Dialog/SteppedDialog';
import DropDownList from '../common-next/DropDownList/DropDownList';
import Hint from '../common-next/Hint/Hint';
import Label from '../common-next/Label/Label';
import NumericTextBox from '../common-next/NumericTextBox/NumericTextBox';

const SIGNUP_LINK = 'https://portal.stonex.com/prefill/index/farmersrisk';

interface TradeModalTeaserProps extends Omit<DialogProps, 'children' | 'title' | 'closeIcon'> {
  onClose: () => void;
}

export default function TradeModalTeaser(props: TradeModalTeaserProps) {
  const arrowRightIcon: FontAwesomeIconProps = { icon: faCircleArrowRight };
  const quantity = { value: 'XYZ', label: 'Bushels' };
  const quantityHint = '1 total contract';
  const crop = { value: 'XYZ', label: 'Corn' };
  const instrument = { value: 'XYZ', label: 'Future' };
  const contract = { value: 'XYZ', label: `Dec - ${new Date().getFullYear().toString().slice(-2)}` };
  const orderType = { value: 'XYZ', label: 'Limit Trade' };
  const buySell = { value: BuySellType.Buy, label: 'Buy' };

  return (
    <SteppedDialog
      title="Create New Trade"
      className={`${styles['futures-trade-modal-wrapper']}`}
      actionsBarPosition="both"
      {...props}
    >
      <DialogContent>
        <form>
          <div className="k-overlay fr-overlay"></div>
          <div className="fr-overlay-teaser">
            <div>
              Interested in this feature? Start the process{' '}
              <a href={SIGNUP_LINK} target="blank" className="no-under">
                <span className="under">here</span>
                <span className="no-under">
                  {' '}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconDefinition} />
                </span>
              </a>
            </div>
          </div>
          <DialogContentRow slimTop className="pb-1">
            <DropDownList
              label="Order Type"
              dataItemKey="value"
              textField="label"
              value={orderType}
              required={true}
              className="col-12 col-md-6"
              stretch
            />
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <DropDownList
                  label="Quantity"
                  name="quantityUnit"
                  dataItemKey="value"
                  textField="label"
                  value={quantity}
                  required={true}
                  className="me-2"
                />
                <NumericTextBox
                  value={5000}
                  // empty label for row alignment
                  label=" "
                />
              </div>
            </div>
          </DialogContentRow>
          <DialogContentRow mute className="py-1">
            <Hint className="col d-flex justify-content-end">{quantityHint}</Hint>
          </DialogContentRow>
          <DialogContentRow className="pt-2">
            <DropDownList
              label="Crop"
              dataItemKey="value"
              textField="label"
              value={crop}
              required={true}
              className="col-12 col-md-6"
              stretch
            />
            <DropDownList
              label="Instrument Type"
              dataItemKey="value"
              textField="label"
              value={instrument}
              className="col-12 col-md-6"
              stretch
            />
          </DialogContentRow>
          <DialogContentRow className="pt-2 pb-0">
            <DropDownList
              label="Contract"
              dataItemKey="value"
              textField="label"
              value={contract}
              required={true}
              className="col-12 col-md-6"
              stretch
            />
            <DropDownList
              label="Buy/Sell"
              dataItemKey="value"
              textField="label"
              value={buySell}
              className="col-12 col-md-6"
              stretch
            />
          </DialogContentRow>
          <DialogContentRow feature center className="mt-4">
            <div className="col">
              <Label fontWeight="bold">Futures Price</Label>
              <Hint subtle className="mt-0">
                Estimated price. You will select your final price after reviewing order.
              </Hint>
              <Label fontWeight="bold" size="large" className="py-2">
                $6.14
              </Label>
            </div>
          </DialogContentRow>
          <DialogContentRow center className="pt-4">
            <Button className="col-4" themeColor="secondary" size="x-small">
              View Recent Trades
            </Button>
            <div className="col-4" />
            <Button
              themeColor="secondary"
              size="large"
              iconProps={arrowRightIcon}
              fontWeight="bold"
              className="text-end col-6 review"
            >
              Review Order
            </Button>
          </DialogContentRow>
        </form>
      </DialogContent>
    </SteppedDialog>
  );
}
