import React, { useCallback, useEffect } from 'react';
import RadioGroup, { RadioGroupHandle } from '../common-next/Radio/RadioGroup';
import RadioButton from '../common-next/Radio/RadioButton';
import DialogContentRow from '../common-next/Dialog/DialogContentRow';
import Hint from '../common-next/Hint/Hint';
import Label from '../common-next/Label/Label';
import { v4 as uuid } from 'uuid';
import { CommodityInformation, InstrumentType, isPut } from '@harvestiq/constants';
import { isEmpty, isNil } from 'lodash';
import Decimal from 'decimal.js';
import useCurrentCurrency from '../../customHooks/useCurrentCurrency';
import { formatNumber } from '../../utils/formatNumber';
import { isSpecialOption } from '../../utils/helpers';
import Loader from '../common/Loader/Loader';
import { OptionContext, OptionDisplay, TradeOrderContext, useTradeOrderContext } from './TradeOrderProvider';
import { useRealtimeQuote } from '../../queries/quotes';
import { IQuote } from '../../types/IQuote';
import { useRegularOptionsQuery, useSpecialOptionsQuery } from './queries';
import { SymbolParams, barchartSymbolUtils } from '@harvestiq/symbology';
import { getBarchartSymbol } from '@harvestiq/symbology';
import type { IGetFuturesOptionsResult } from '@harvestiq/external-clients/barchart';
import { getFuturesParamsForDate } from '@harvestiq/symbology';
import dayjs from '../../utils/dayjs';

interface TradeModalStepOneOptionsProps {
  isActive: boolean;
}

export default function TradeModalStepOneOptions(props: TradeModalStepOneOptionsProps) {
  const { isActive } = props;
  const orderContext = useTradeOrderContext();
  const currency = useCurrentCurrency();

  //const radioGroupRef = React.createRef<RadioGroupHandle>();

  // DERIVED STATE
  const selectedOption = orderContext.selectedOption;
  const commodity = orderContext.selectedCommodity?.value;
  const instrumentType = orderContext.instrumentType?.value;
  const root = commodity?.tradingCode; // ZC, ZS, etc.
  const optionPutOrCall = instrumentType === InstrumentType.Futures ? undefined : isPut(instrumentType) ? 'Put' : 'Call';
  const isSpecial = isSpecialOption(orderContext.instrumentType.value);

  // QUERIES
  const regularOptionsQry = useRegularOptionsQuery(
    {
      root: root,
      exchange: commodity?.barchartExchangeCode ?? 'CME',
      type: optionPutOrCall,
    },
    {
      enabled: isActive && !!root && instrumentType !== InstrumentType.Futures && !!commodity && !isSpecial,
    }
  );
  const regularOptions = regularOptionsQry.data || [];

  const specialOptionsQry = useSpecialOptionsQuery(
    {
      root: root ?? 'ZC',
      exchange: commodity?.barchartExchangeCode ?? 'CME',
      type: optionPutOrCall,
    },
    {
      enabled: isActive && !!root && instrumentType !== InstrumentType.Futures && !!commodity && isSpecial,
    }
  );
  const allSpecialOptions = specialOptionsQry.data || [];
  const strikesAndPremiums = getOptionStrikeLadder(orderContext);

  // determine At-the-money strike
  const underlyingFutureBarchartSymbol =
    selectedOption?.underlyingFutureBarchartSymbol ?? strikesAndPremiums[0]?.underlyingFutureBarchartSymbol;
  // At-the-money strike
  const underlyingFuturesQuoteQry = useRealtimeQuote({
    refetchInterval: false, // don't refetch ... just do this once
    barchartSymbol: underlyingFutureBarchartSymbol,
    enabled: isActive && !!underlyingFutureBarchartSymbol,
    convertFields: true,
  });

  // DERIVED STATE
  const underlyingFuturesQuote = underlyingFuturesQuoteQry.data;
  const atTheMoneyStrike = getAtTheMoneyStrike(underlyingFuturesQuote, strikesAndPremiums);
  const selectedStrike = orderContext.selectedOption?.strike ?? atTheMoneyStrike?.strike;
  const activeStrikePremiumIdx = strikesAndPremiums.findIndex((s) => selectedStrike && s.strike === selectedStrike);
  const activeStrikePremium: OptionDisplay | undefined =
    strikesAndPremiums[activeStrikePremiumIdx > -1 ? activeStrikePremiumIdx : 0];

  // This allows us to set the selected option to the At-the-money strike if one is not selected
  useEffect(() => {
    if (!isActive) return;

    if (!orderContext.selectedOption && activeStrikePremium && underlyingFuturesQuote) {
      // use the At-the-money strike if one is not selected
      orderContext.changeOrderValue('selectedOption', {
        ...activeStrikePremium,
      } satisfies OptionContext);
    }
  }, [isActive, activeStrikePremium, underlyingFuturesQuote]);

  let tradeValue = '--';
  let bidDisplay = '--';
  let askDisplay = '--';
  if (activeStrikePremium && orderContext.selectedCommodity && orderContext.instrumentType && orderContext.cqgSymbol) {
    tradeValue = formatNumber(new Decimal(activeStrikePremium.last).times(commodity?.standardSize ?? 1).toFixed(2), 2, currency);

    if (activeStrikePremium.bid && activeStrikePremium.ask) {
      bidDisplay = formatNumber(activeStrikePremium.bid, 5, currency);
      askDisplay = formatNumber(activeStrikePremium.ask, 5, currency);
    }
  }

  const optionsDataIsLoading = isSpecial ? !specialOptionsQry.data : !regularOptionsQry.data;
  const noData = !underlyingFuturesQuote || !selectedOption || !activeStrikePremium;

  const radioGroupRef = useCallback(
    ($radioGrpHandle: RadioGroupHandle) => {
      if (isActive && !optionsDataIsLoading && !noData && $radioGrpHandle && $radioGrpHandle.element) {
        const $el = $radioGrpHandle.element;

        const $checked = $el.querySelector('input[checked]') as HTMLInputElement | null;
        if ($checked && 'focus' in $checked) {
          $checked.focus();
          $checked.scrollIntoView({ block: 'center' });
        }
      }
    },
    [optionsDataIsLoading, noData, isActive]
  );

  return (
    <DialogContentRow feature>
      <div className="col">
        {activeStrikePremium ? (
          <Label fontWeight="bold">Strike Price: {formatNumber(activeStrikePremium.strike, 2, currency)}</Label>
        ) : (
          <Label fontWeight="bold">Strike Price</Label>
        )}
        <Hint subtle className="mt-0 mb-2">
          Select a strike price and review order to set your final Premium Price.
        </Hint>
        <div className="option-choices">
          {optionsDataIsLoading && <Loader height={228} />}
          {!optionsDataIsLoading && noData && <>No contracts found for this instrument type</>}
          {!optionsDataIsLoading && !noData && (
            <RadioGroup
              id="options-choices"
              //height={offsettingTrade ? 33 : 166}
              height={166}
              header={
                <>
                  {/* spacer for the radio button */}
                  <span style={{ width: '44px' }}></span>
                  <span style={{ flex: '1 1 20%' }}>Strike</span>
                  <span style={{ flex: '1 1 40%' }}>Last</span>
                  <span style={{ flex: '1 1 20%' }}>Vol</span>
                  {/* spacer for scrollbar */}
                  <span style={{ width: '10px' }}></span>
                </>
              }
              footer={
                <>
                  <Label fontWeight="light">
                    <b>Trade value:</b> {tradeValue}
                  </Label>
                  {activeStrikePremium.bid && activeStrikePremium.ask ? (
                    <Hint className="d-flex justify-content-center">
                      <Label fontWeight="light" className="px-2">
                        Bid: {bidDisplay}
                      </Label>
                      <Label fontWeight="light" className="px-2">
                        Ask: {askDisplay}
                      </Label>
                    </Hint>
                  ) : (
                    <Hint className="d-flex justify-content-center">No Current Bid/Ask</Hint>
                  )}
                </>
              }
              ref={radioGroupRef}
              data={strikesAndPremiums}
              value={activeStrikePremiumIdx}
              item={(e) => {
                const { children, className, ...liProps } = e;
                // decompose underlyingFutureBarchartSymbol and expirationDate so they aren't passed through on otherProps
                /* eslint-disable @typescript-eslint/no-unused-vars */
                const { value, underlyingFutureBarchartSymbol, expirationDate, ...otherProps } = children
                  ? (children as JSX.Element).props
                  : { label: '', value: 0, underlyingFutureBarchartSymbol: '', expirationDate: '' };
                const childClassName = otherProps.className;
                delete otherProps.className;
                const classNameEnhanced = `${className}${otherProps.checked ? ' strike-item-checked' : ''}`;
                const id = uuid();
                const strikeAndPremium = strikesAndPremiums[value];
                const isAtTheMoney = atTheMoneyStrike && atTheMoneyStrike.strike === strikeAndPremium.strike;

                const volDisplay = strikeAndPremium.volume ? strikeAndPremium.volume : '--';
                return (
                  <li
                    style={{
                      backgroundColor: isAtTheMoney ? '#18ac25' : undefined,
                      color: isAtTheMoney ? '#fff' : undefined,
                    }}
                    className={`strike-item ${classNameEnhanced}`}
                    {...liProps}
                  >
                    <RadioButton value={value} {...otherProps} id={id}>
                      <Label
                        fontWeight={otherProps.checked ? undefined : 'light'}
                        className={`strike-label ${childClassName}`}
                        {...otherProps}
                        editorId={id}
                      >
                        <span style={{ flex: '1 1 20%' }}>{formatNumber(strikeAndPremium.strike, 2, currency)}</span>
                        <span style={{ flex: '1 1 40%' }}>{formatNumber(strikeAndPremium.last, 5, currency)}</span>
                        <span style={{ flex: '1 1 20%' }}>{`${volDisplay}`}</span>
                      </Label>
                    </RadioButton>
                  </li>
                );
              }}
              onChange={(e) => {
                const idx = e.value;
                const selectedStrikeAndPremium = strikesAndPremiums[idx];
                orderContext.changeOrderValue('selectedOption', {
                  ...selectedStrikeAndPremium,
                } satisfies OptionDisplay);
              }}
            />
          )}
        </div>
      </div>
    </DialogContentRow>
  );

  function getOptionStrikeLadder(orderContext: TradeOrderContext) {
    let options;

    if (!commodity) {
      return [];
    }

    const contractSymbolParams: SymbolParams = {
      commodityId: commodity?.id ?? 0,
      instrumentType,
      contractMonth: orderContext.contractSelector?.value.contractMonth ?? 0,
      contractYear: orderContext.contractSelector?.value.contractYear ?? 0,
    };

    if (!isSpecialOption(orderContext.instrumentType.value)) {
      const underlyingFutureBarchartSymbol = getBarchartSymbol({
        ...contractSymbolParams,
        instrumentType: InstrumentType.Futures,
      });
      options = regularOptions.filter((option) => option.contract === underlyingFutureBarchartSymbol);
    } else if (!isEmpty(allSpecialOptions)) {
      const contractPrefix = getBarchartSymbol({
        ...contractSymbolParams,
        strikePrice: 5, // just a placeholder
      })?.split('|')[0];
      options = allSpecialOptions.filter((option) => contractPrefix && option.symbol.startsWith(contractPrefix));
    }
    if (options && options.length > 0) {
      const strikeAndPremiumData: OptionDisplay[] = options.map((option, idx) => {
        const x: OptionDisplay = {
          strike: new Decimal(option.strike).dividedBy(commodity.barchartPriceFactor).toNumber(),
          last: new Decimal(option.last ?? 0).dividedBy(commodity.barchartPriceFactor).toNumber(),
          volume: isNil(option.volume) ? null : new Decimal(option.volume).toNumber(),
          bid: isNil(option.bid) ? null : new Decimal(option.bid).dividedBy(100).toNumber(),
          ask: isNil(option.ask) ? null : new Decimal(option.ask).dividedBy(100).toNumber(),
          underlyingFutureBarchartSymbol:
            'underlying_future' in option ? option.underlying_future : getFallbackUnderlyingFuturesSymbol(option, commodity),
          expirationDate: option.expirationDate,
          value: idx,
        };

        return x;
      });

      return strikeAndPremiumData;
    }

    return [];
  }
}

const UNDERLYING_FUTURES_MAP = new Map<string, string>();
function getFallbackUnderlyingFuturesSymbol(option: IGetFuturesOptionsResult, commodity: CommodityInformation) {
  if (UNDERLYING_FUTURES_MAP.has(option.contract)) {
    return UNDERLYING_FUTURES_MAP.get(option.contract);
  }

  // FIXME This is wrong for weeklies that expire after the 15th of the month.
  const contractInfo = barchartSymbolUtils.getMonthYearFromContract(option.contract);
  const proxyContractDayjs = dayjs(`${contractInfo.contractYear}-${contractInfo.contractMonth + 1}-1`);

  const futuresParams = getFuturesParamsForDate(commodity, proxyContractDayjs);
  const fallbackUnderlyingFutureSymbol = getBarchartSymbol({
    ...futuresParams,
    instrumentType: InstrumentType.Futures,
  });

  if (!fallbackUnderlyingFutureSymbol) {
    throw new Error(`Could not find fallback underlying future symbol for option ${option.symbol}`);
  }

  UNDERLYING_FUTURES_MAP.set(option.contract, fallbackUnderlyingFutureSymbol);
  return fallbackUnderlyingFutureSymbol;
}

function getAtTheMoneyStrike(underlyingFuturesQuote: IQuote | undefined, strikesAndPremiums: OptionDisplay[]) {
  if (underlyingFuturesQuote && strikesAndPremiums) {
    const futuresPrice = underlyingFuturesQuote.lastPrice;
    const atTheMoneyStrike = strikesAndPremiums.reduce((prev, curr) => {
      const prevStrikePrice = prev.strike;
      const currStrikePrice = curr.strike;
      const prevDiff = Math.abs(futuresPrice - prevStrikePrice);
      const currDiff = Math.abs(futuresPrice - currStrikePrice);
      return prevDiff < currDiff ? prev : curr;
    }, strikesAndPremiums[0]);
    return atTheMoneyStrike;
  }
  return null;
}
