import './Trade.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentRole from '../../customHooks/useCurrentRole';
import Button from '../common/Button/Button';
import { LoadingStatuses } from '../../store/LoadingStatuses';
import { updateOperationMemberRoleConfigThunk } from '../../store/OperationMember/thunks';
import { IRole } from '../../types/IRole';
import { FontAwesomeIcon, farCheckCircle, IconDefinition } from '../faIcons';

export default function Trade() {
  const currentRole = useCurrentRole();
  const dispatch = useDispatch();
  const [requestState, setRequestState] = useState<LoadingStatuses>(LoadingStatuses.Idle);
  const subscribed = currentRole?.config?.alerts?.tradingReleased?.email || false;

  const handleAlertConfigSave = () => {
    if (currentRole) {
      const updatedRole: IRole = {
        ...currentRole,
        config: {
          ...currentRole.config,
          alerts: {
            ...currentRole.config?.alerts,
            tradingReleased: {
              email: true,
              sms: false,
            },
          },
        },
      };

      setRequestState(LoadingStatuses.Loading);
      dispatch(
        updateOperationMemberRoleConfigThunk(updatedRole, () => {
          setRequestState(LoadingStatuses.Idle);
        })
      );
    }
  };

  return (
    <div className="trade">
      <img className="trade-teaser-image" src={subscribed ? '/envelope-red.png' : '/box.png'} alt="Trade teaser" />
      <h1 className="trade-title">This feature is coming soon!</h1>
      <p className="trade-caption">
        {subscribed ? "You'll be notified when the feature is released" : 'We will notify you once this feature is ready'}
      </p>
      {!subscribed && (requestState === LoadingStatuses.Idle || requestState === LoadingStatuses.Loading) && (
        <Button
          value="Notify Me"
          className="fr-button backGround-red trade-teaser-notify-button"
          disabled={requestState === LoadingStatuses.Loading}
          onClick={handleAlertConfigSave}
        />
      )}
      {subscribed && (
        <div className="trade-teaser-subscribed">
          <FontAwesomeIcon icon={farCheckCircle as IconDefinition} />
          Thank you!
        </div>
      )}
    </div>
  );
}
