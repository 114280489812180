import { getInitialCropYear } from '../../shared/productionCycles';
import { IProductionCyclesPayload, SetSelectedYear } from './actions';

const initialState: IProductionCyclesPayload = {
  selectedYear: getInitialCropYear([]),
};

export default function productionCycles(state: IProductionCyclesPayload = initialState, action: SetSelectedYear) {
  switch (action.type) {
    case 'SET_SELECTED_YEAR':
      return { ...action.payload };
    default:
      return state;
  }
}
