import React from 'react';
import { SummaryItem } from '../../common/SummaryItem/SummaryItem';
import styles from './KpiBar.module.scss';

export type KpiBarType = {
  title: string;
  value: string;
};

type KpiBarProps = {
  data: KpiBarType[];
};

export const KpiBar = ({ data }: KpiBarProps) => {
  return (
    <>
      {data.map((col, index) => (
        <SummaryItem
          key={`item-${col.title}-${index}`}
          className={`${styles['kpi-container']} text-center card`}
          label={col.title}
          value={col.value}
        />
      ))}
    </>
  );
};
