import { NavLink } from 'react-router-dom';

import {
  faChartLine,
  faCommentDollar,
  faCrosshairs,
  faCrystalBall,
  faFileChartPie,
  faFileContract,
  faUserCog,
  faCow,
  FontAwesomeIcon,
  faSeedling,
} from '../../faIcons';
import config from '../../../config';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import useCurrentUser from '../../../customHooks/useCurrentUser';
import useMobileView, { BREAKPOINTS } from '../../../customHooks/useMobileView';
import { getImpersonatingConfig } from '../../../shared/storageService';
import useIsFeatureAccessible from '../../../customHooks/useIsFeatureAccessible';
import useAppContext from '../../../customHooks/useAppContext';
import './HeaderMenu.css';

import { Pages } from '@farmersrisk/shared/constants/Pages';

function HeaderMenu() {
  const impersonatingConfig = getImpersonatingConfig();
  const user = useCurrentUser();
  const appContext = useAppContext();
  const currentOperation = useCurrentOperation();
  const isTablet = useMobileView(BREAKPOINTS.TABLET_VIEW);

  const isAdmin = user.isAuth0Admin || impersonatingConfig?.isImpersonating;
  const isAdvisor = appContext.isAdvisor;

  const isCropsMenuV2Enabled = useIsFeatureAccessible('isCropsMenuV2Enabled');
  const isLivestockProfitabilityEnabled = useIsFeatureAccessible('isLivestockProfitabilityEnabled');
  const isAdvisorHubEnabled = useIsFeatureAccessible('isAdvisorHubEnabled');

  const navLinks: MenuItemProps[] = [
    {
      link: '/crops/cash-sales/summary',
      icon: faSeedling,
      name: 'Crops',
      isHide: !isCropsMenuV2Enabled,
    },
    {
      link: Pages.livestock.path,
      icon: faCow,
      name: 'Livestock',
      isHide: !isLivestockProfitabilityEnabled,
    },
    {
      link: '/cash-sales/summary',
      icon: faFileContract,
      name: 'Cash Sales',
      isHide: isCropsMenuV2Enabled || isLivestockProfitabilityEnabled,
    },
    {
      link: '/cash-bids',
      icon: faCommentDollar,
      name: 'Cash Bids',
      isHide: isCropsMenuV2Enabled || isLivestockProfitabilityEnabled,
    },
    {
      link: '/my-positions',
      icon: faCrosshairs,
      name: 'My Positions',
    },
    {
      link: '/scenario-analysis',
      icon: faChartLine,
      name: 'Scenarios',
      isHide: isCropsMenuV2Enabled || isLivestockProfitabilityEnabled,
    },
    {
      link: '/reports',
      icon: faFileChartPie,
      name: 'Reports',
      isHide: !currentOperation.isPremium,
    },
    {
      link: '/trade-insights',
      icon: faCrystalBall,
      name: 'Insights',
      isHide: !config.featureFlags.insightsEnabled,
    },
    {
      link: '/advisor-hub',
      icon: faCrystalBall,
      name: 'Advisor Hub',
      isHide: !isAdvisor || !isAdvisorHubEnabled,
    },
    {
      link: '/admin',
      icon: faUserCog,
      name: 'Admin',
      isHide: !isAdmin,
    },
  ];

  if (isTablet) {
    return null;
  }

  return (
    <div className="d-print-none header-menu">
      {navLinks
        .filter((config) => !config.isHide)
        .map((config) => (
          <MenuItem key={config.link} {...config} />
        ))}
    </div>
  );
}

export default HeaderMenu;

type MenuItemProps = {
  link: string;
  icon: any;
  name: string;
  isHide?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const { link, icon, name } = props;
  const isLapTop = useMobileView(BREAKPOINTS.SMALL_LAPTOP_VIEW);

  return (
    <NavLink
      key={link}
      to={link}
      className={`header-menu-item ${isLapTop ? 'header-menu-item-mobile' : ''}`}
      activeClassName="header-menu-selected"
    >
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
      {isLapTop ? '' : name}
    </NavLink>
  );
}
