import { CQGClient } from '@farmersrisk/cqg/cqgClient/CQGClient';
import { ClientContext, type CQGClientOptions } from '@farmersrisk/cqg/types';
import config from '../../config';
import { createLogger } from '../../loggers/index';
import { CQGHelpers } from './helpers';

const logger = createLogger('useCQGClient');
// TODO: add a way to force a logoff
//let devTimer = 0;

// !!SINGLETON!!
// useRef is not a singleton, it will create multiple instances if used in multiple components
let __CQG_CLIENT_INSTANCE__: CQGClient | undefined = undefined;

type InitCQGClientOptions = Partial<CQGClientOptions> & {
  brokerageAccountIds: string[];
  username: string;
  password: string;
  userId: number;
  operationId: number;
};

const DEFAULT_CLIENT_OPTS: Omit<
  InitCQGClientOptions,
  'operationId' | 'userId' | 'username' | 'password' | 'brokerageAccountIds'
> = {
  websocketAddress: config.trading.websocketAddress,
  keepAliveDuration: true,
  keepAliveInterval: config.trading.keepAliveInterval,
  env: config.env,
  version: config.version,
  dataDogEnabled: config.dataDogEnabled,
  logPingPong: config.trading.logPingPong,
};

export function getOrInitCQGClient(opts: InitCQGClientOptions) {
  if (__CQG_CLIENT_INSTANCE__) {
    return __CQG_CLIENT_INSTANCE__;
  }

  const mergedOps = {
    ...DEFAULT_CLIENT_OPTS,
    ...opts,
  };

  if (!mergedOps.operationId || !mergedOps.userId) {
    throw new Error('No current operation or user found.');
  }

  if (!mergedOps.username || !mergedOps.password) {
    throw new Error('No trading credentials found.');
  }

  const client = new CQGClient({
    ...DEFAULT_CLIENT_OPTS,
    ...opts,
    websocketAddress: mergedOps.websocketAddress ?? config.trading.websocketAddress,
  });
  logger.debug('creating new CQGClient...', { ...opts, password: '***' });
  client.setBrokerageAccountIds(opts.brokerageAccountIds);

  __CQG_CLIENT_INSTANCE__ = client;
  return __CQG_CLIENT_INSTANCE__;
}

export async function disposeClient() {
  if (__CQG_CLIENT_INSTANCE__) {
    logger.debug('disposing CQGClient...');
    await CQGHelpers.logoffAndClose(__CQG_CLIENT_INSTANCE__, ClientContext.OTHER, { removeListeners: true, immediate: true });

    __CQG_CLIENT_INSTANCE__ = undefined;
  }
}
