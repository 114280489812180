import { OrderStatus_Status } from '../cqgMessages/common/shared_1';
import { BarReportStatusCode, TimeAndSalesReport_ResultCode } from '../cqgMessages/WebAPI/historical_2';
import { MarketDataSubscriptionStatus_StatusCode } from '../cqgMessages/WebAPI/market_data_2';
import { TradeSubscriptionStatus_StatusCode, TradeSubscription_SubscriptionScope } from '../cqgMessages/WebAPI/trade_routing_2';
import {
  LoggedOff_LogoffReason,
  LogonResult_ResultCode,
  PasswordChangeResult_ResultCode,
  RestoreOrJoinSessionResult_ResultCode,
} from '../cqgMessages/WebAPI/user_session_2';
import { InformationReport_StatusCode } from '../cqgMessages/WebAPI/webapi_2';

type StatusMap = { [key: number]: string };

export const logonResultToString: StatusMap = {
  [LogonResult_ResultCode.RESULT_CODE_SUCCESS]: 'SUCCESS',
  [LogonResult_ResultCode.RESULT_CODE_FAILURE]: 'FAILURE',
  [LogonResult_ResultCode.RESULT_CODE_NO_ONETIME_PASSWORD]: 'NO_ONETIME_PASSWORD',
  [LogonResult_ResultCode.RESULT_CODE_PASSWORD_EXPIRED]: 'PASSWORD_EXPIRED',
  [LogonResult_ResultCode.RESULT_CODE_CONCURRENT_SESSION]: 'CONCURRENT_SESSION',
  [LogonResult_ResultCode.RESULT_CODE_REDIRECTED]: 'REDIRECTED',
  [LogonResult_ResultCode.RESULT_CODE_ROUTINE_ERROR]: 'ROUTINE_ERROR',
  [LogonResult_ResultCode.RESULT_CODE_ACCESS_TOKEN_EXPIRED]: 'ACCESS_TOKEN_EXPIRED',
};

export const logoffReasonToString: StatusMap = {
  [LoggedOff_LogoffReason.LOGOFF_REASON_REDIRECTED]: 'REDIRECTED',
  [LoggedOff_LogoffReason.LOGOFF_REASON_FORCED]: 'FORCED',
  [LoggedOff_LogoffReason.LOGOFF_REASON_REASSIGNED]: 'REASSIGNED',
  [LoggedOff_LogoffReason.LOGOFF_REASON_BY_REQUEST]: 'BY_REQUEST',
};

export const restoreOrJoinResultToString: StatusMap = {
  [RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_SUCCESS]: 'SUCCESS',
  [RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_FAILURE]: 'FAILURE',
  [RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_UNKNOWN_SESSION]: 'UNKNOWN_SESSION',
  [RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_ACCESS_DENIED]: 'ACCESS_DENIED',
  [RestoreOrJoinSessionResult_ResultCode.RESULT_CODE_INVALID_PARAMS]: 'INVALID_PARAMS',
};

export const passwordChangeResultToString: StatusMap = {
  [PasswordChangeResult_ResultCode.RESULT_CODE_SUCCESS]: 'SUCCESS',
  [PasswordChangeResult_ResultCode.RESULT_CODE_FAILURE]: 'FAILURE',
  [PasswordChangeResult_ResultCode.RESULT_CODE_ROUTINE_ERROR]: 'ROUTINE_FAILURE',
};

export const informationReportsStatusToString: StatusMap = {
  [InformationReport_StatusCode.STATUS_CODE_SUCCESS]: 'SUCCESS',
  [InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED]: 'SUBSCRIBED',
  [InformationReport_StatusCode.STATUS_CODE_DROPPED]: 'DROPPED',
  [InformationReport_StatusCode.STATUS_CODE_UPDATE]: 'UPDATE',
  [InformationReport_StatusCode.STATUS_CODE_DISCONNECTED]: 'DISCONNECTED',
  [InformationReport_StatusCode.STATUS_CODE_FAILURE]: 'FAILURE',
  [InformationReport_StatusCode.STATUS_CODE_INVALID_PARAMS]: 'INVALID_PARAMS',
  [InformationReport_StatusCode.STATUS_CODE_NOT_FOUND]: 'NOT_FOUND',
  [InformationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION]: 'REQUEST_RATE_LIMIT_VIOLATION',
  [InformationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION]: 'SUBSCRIPTION_LIMIT_VIOLATION',
  [InformationReport_StatusCode.STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION]: 'ACTIVE_REQUESTS_LIMIT_VIOLATION',
  [InformationReport_StatusCode.STATUS_CODE_TOO_LARGE_RESPONSE]: 'TOO_LARGE_RESPONSE',
};

export const tradeSubscriptionStatusToString: StatusMap = {
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS]: 'SUCCESS',
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED]: 'DISCONNECTED',
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE]: 'FAILURE',
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION]: 'SUBSCRIPTION_LIMIT_VIOLATION',
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PUBLICATION_ID]: 'INVALID_PUBLICATION_ID',
  [TradeSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION]: 'SUBSCRIBED_ACCOUNTS_LIMIT_VIOLATION',
};

export const tradeSubscriptionScopeToString: StatusMap = {
  [TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS]: 'ORDERS',
  [TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_POSITIONS]: 'POSITIONS',
  [TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_COLLATERAL]: 'COLLATERAL',
};

export const orderStatusToString: StatusMap = {
  [OrderStatus_Status.IN_TRANSIT]: 'IN_TRANSIT',
  [OrderStatus_Status.REJECTED]: 'REJECTED',
  [OrderStatus_Status.WORKING]: 'WORKING',
  [OrderStatus_Status.EXPIRED]: 'EXPIRED',
  [OrderStatus_Status.IN_CANCEL]: 'IN_CANCEL',
  [OrderStatus_Status.IN_MODIFY]: 'IN_MODIFY',
  [OrderStatus_Status.CANCELLED]: 'CANCELLED',
  [OrderStatus_Status.FILLED]: 'FILLED',
  [OrderStatus_Status.SUSPENDED]: 'SUSPENDED',
  [OrderStatus_Status.DISCONNECTED]: 'DISCONNECTED',
  [OrderStatus_Status.ACTIVEAT]: 'ACTIVEAT',
  [OrderStatus_Status.APPROVE_REQUIRED]: 'APPROVE_REQUIRED',
  [OrderStatus_Status.APPROVED_BY_EXCHANGE]: 'APPROVED_BY_EXCHANGE',
  [OrderStatus_Status.APPROVE_REJECTED]: 'APPROVE_REJECTED',
  [OrderStatus_Status.MATCHED]: 'MATCHED',
  [OrderStatus_Status.PARTIALLY_MATCHED]: 'PARTIALLY_MATCHED',
  [OrderStatus_Status.TRADE_BROKEN]: 'TRADE_BROKEN',
};

export const timeAndSalesResultToString: StatusMap = {
  [TimeAndSalesReport_ResultCode.RESULT_CODE_SUCCESS]: 'SUCCESS',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_DROPPED]: 'DROPPED',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_DISCONNECTED]: 'DISCONNECTED',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_FAILURE]: 'FAILURE',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_ACCESS_DENIED]: 'ACCESS_DENIED',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_FOUND]: 'NOT_FOUND',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_OUTSIDE_ALLOWED_RANGE]: 'OUTSIDE_ALLOWED_RANGE',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION]: 'ACTIVE_REQUESTS_LIMIT_VIOLATION',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_REQUEST_RATE_LIMIT_VIOLATION]: 'REQUEST_RATE_LIMIT_VIOLATION',
  [TimeAndSalesReport_ResultCode.RESULT_CODE_NOT_SUPPORTED]: 'NOT_SUPPORTED',
};

export const marketDataSubscriptionStatusToString: StatusMap = {
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUCCESS]: 'SUCCESS',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DISCONNECTED]: 'DISCONNECTED',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_FAILURE]: 'FAILURE',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_DELETED]: 'DELETED',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_ACCESS_DENIED]: 'ACCESS_DENIED',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_CONTRIBUTOR_REQUIRED]: 'CONTRIBUTOR_REQUIRED',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_INVALID_PARAMS]: 'INVALID_PARAMS',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_NOT_SUPPORTED]: 'NOT_SUPPORTED',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION]: 'SUBSCRIPTION_LIMIT_VIOLATION',
  [MarketDataSubscriptionStatus_StatusCode.STATUS_CODE_SUBSCRIPTION_RATE_LIMIT_VIOLATION]: 'SUBSCRIPTION_RATE_LIMIT_VIOLATION',
};

export const timeBarDataResultToString: StatusMap = {
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUCCESS]: 'SUCCESS',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIBED]: 'SUBSCRIBED',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_DROPPED]: 'DROPPED',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE]: 'UPDATE',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_DISCONNECTED]: 'DISCONNECTED',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALIDATED]: 'INVALIDATED',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_FAILURE]: 'FAILURE',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACCESS_DENIED]: 'ACCESS_DENIED',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_FOUND]: 'NOT_FOUND',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_OUTSIDE_ALLOWED_RANGE]: 'OUTSIDE_ALLOWED_RANGE',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_INVALID_PARAMS]: 'INVALID_PARAMS',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION]: 'ACTIVE_REQUESTS_LIMIT_VIOLATION',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION]: 'SUBSCRIPTION_LIMIT_VIOLATION',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION]: 'REQUEST_RATE_LIMIT_VIOLATION',
  [BarReportStatusCode.BAR_REPORT_STATUS_CODE_NOT_SUPPORTED]: 'NOT_SUPPORTED',
};
