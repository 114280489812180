import { CommodityInformation } from '@harvestiq/constants';
import { prepareExpirationMonthOptions } from './CropSymbols/cropFuturesSymbolLookup';
import { SymbolLookUpsStoreItem } from '../store/SymbolLookUps/symbolLookUpsSlice';

export type SymbolLookUpResponseItem = {
  name: string;
  symbol: string;
  exchange: string;
};

export const filterSymbolLookUpResults = (results: SymbolLookUpResponseItem[], tradingCode: string) => {
  return results.filter((res) => res.symbol.startsWith(tradingCode));
};

export type SelectorOptionsByTradingCode = { [cropTradingCode: string]: { label: string; value: string }[] };

export function getCropFutures(crops: CommodityInformation[], symbolLookUps: SymbolLookUpsStoreItem) {
  const newCropFutures: SelectorOptionsByTradingCode = {};
  crops.forEach((crop) => {
    newCropFutures[crop.tradingCode] = prepareExpirationMonthOptions(symbolLookUps[crop.tradingCode] || []);
  });
  return newCropFutures;
}
