export enum CropContractType {
  HTA = 1,
  BASIS = 2,
  FORWARD = 3,
  SPOT = 4,
  DELAYED_PRICE = 5,
}

export function getCropContractTypeDisplay(type?: CropContractType | null) {
  switch (type) {
    case CropContractType.HTA:
      return 'HTA';
    case CropContractType.BASIS:
      return 'Basis';
    case CropContractType.FORWARD:
      return 'Forward';
    case CropContractType.SPOT:
      return 'Spot';
    case CropContractType.DELAYED_PRICE:
      return 'Delayed Price';
    default:
      return '';
  }
}

export enum CropContractStatus {
  OPEN = 1,
  SETTLED = 2,
  PAID = 3,
  CANCELED = 4,
}

export function getCropContractStatusDisplay(status?: CropContractStatus | null) {
  switch (status) {
    case CropContractStatus.OPEN:
      return 'Open';
    case CropContractStatus.SETTLED:
      return 'Settled';
    case CropContractStatus.PAID:
      return 'Paid';
    case CropContractStatus.CANCELED:
      return 'Canceled';
    default:
      return '';
  }
}

export enum DeliveryStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
}

export function getDeliveryStatusDisplay(status?: DeliveryStatus | null) {
  switch (status) {
    case DeliveryStatus.NOT_STARTED:
      return 'Not Started';
    case DeliveryStatus.IN_PROGRESS:
      return 'In Progress';
    case DeliveryStatus.COMPLETE:
      return 'Complete';
    default:
      return '';
  }
}
