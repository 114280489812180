import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../types/Store';
import { fetchSymbolLookUps } from './thunks';
import ErrorToast from '../../components/Toast/ErrorToast';
import { ISymbolLookUp } from '../../types/ISymbolLookUp';
import { filterSymbolLookUpResults } from '../../models/getMarketData';
import { LoadingStatuses } from '../LoadingStatuses';

export type SymbolLookUpsStoreItem = {
  [symbol: string]: ISymbolLookUp[];
};

type SymbolLookUpsSliceInitialStateType = {
  symbolLookUps: SymbolLookUpsStoreItem;
  status: string;
  error: string | null;
};

const initialState: SymbolLookUpsSliceInitialStateType = {
  symbolLookUps: {},
  status: LoadingStatuses.Idle,
  error: null,
};

const slice = createSlice({
  name: 'symbolLookUps',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSymbolLookUps.pending.toString()]: (state, action) => {
      state.status = LoadingStatuses.Loading;
    },
    [fetchSymbolLookUps.fulfilled.toString()]: (state, action) => {
      state.status = LoadingStatuses.Idle;
      state.symbolLookUps = { ...state.symbolLookUps, ...prepareSymbolLookUps(action.payload, action.meta.arg) };
    },
    [fetchSymbolLookUps.rejected.toString()]: (state, action) => {
      state.status = LoadingStatuses.Failed;
      state.error = action.error.message;
      ErrorToast('Failed to load symbol look up!');
    },
  },
});

export function prepareSymbolLookUps(symbolLookUps: ISymbolLookUp[][], tradingCodes: string[]): SymbolLookUpsStoreItem {
  const result: SymbolLookUpsStoreItem = {};
  tradingCodes.forEach((code, index) => (result[code] = filterSymbolLookUpResults(symbolLookUps[index], code)));
  return result;
}

export default slice.reducer;

export const selectAllSymbolLookUps = (state: RootState) => state.symbolLookUps.symbolLookUps;
