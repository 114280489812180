import React from 'react';
import WarningRow from './WarningRow';

export default function CropsWarningRow(props: WarningRowProps) {
  const { selectedYear, message } = props;

  const displayMessage = selectedYear
    ? `No crops are saved for ${selectedYear}`
    : message
    ? message
    : 'No crops are enabled for this operation. You can do this in operation crops settings.';

  return <WarningRow message={displayMessage} linkTo="/settings/crops" linkCaption="Go to Crop Settings" />;
}

type WarningRowProps = {
  selectedYear?: string;
  operationCropFieldsMissingMessage?: string;
  message?: string;
};
