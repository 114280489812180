import { createTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import { type TRPCClientErrorLike, httpLink } from '@trpc/client';
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AppRouter } from '@farmersrisk/backend/api/trpcServer';
import config from '../config';
import { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import transformer from 'trpc-transformer';
import { getImpersonatingConfig, getStoredLastVisitedOperationId } from './storageService';

export const trpc = createTRPCReact<AppRouter>();

// infer the types for your router
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;
export type ReactQueryError = TRPCClientErrorLike<AppRouter>;

export function getTrpcClient({ getAccessTokenSilently }: { getAccessTokenSilently: () => Promise<string> }) {
  return trpc.createClient({
    transformer,
    links: [
      httpLink({
        url: `${config.backend.basePath}/trpc`,
        // You can pass any HTTP headers you wish here
        async headers() {
          const token = await getAccessTokenSilently();
          const currentOperationId = getStoredLastVisitedOperationId();
          const impersonatingConfig = getImpersonatingConfig();

          const headerKeyVals: Record<string, any> = {};

          if (token) {
            headerKeyVals['Authorization'] = `Bearer ${token}`;
          }

          if (currentOperationId) {
            headerKeyVals['x-current-operation-id'] = currentOperationId;
          }

          if (impersonatingConfig && impersonatingConfig.isImpersonating && impersonatingConfig.auth0Id) {
            // DEPRECATED: use the userId header instead
            headerKeyVals['ImpersonatedUser'] = impersonatingConfig.auth0Id;
          }
          if (impersonatingConfig && impersonatingConfig.isImpersonating && impersonatingConfig.userId) {
            headerKeyVals['x-impersonated-user-id'] = impersonatingConfig.userId;
          }

          return headerKeyVals;
        },
      }),
    ],
  });
}
