import { RoleType } from '../constants/Roles';
import { UnreachableCaseError } from '@harvestiq/utils';

// TODO: these types should be extracted from Role or IRole
// but the shared/BE repository files import DB things which the FE doesn't like
// so this logic can't be shared across everything using those parent types

export type PermissionRole = {
  roleType: RoleType;
  executeTrades: boolean;
  invitationAccepted?: boolean;
};

class PermissionsValidator {
  public static isOwner(role: { userId?: number } | undefined, operation: { userId: number } | undefined) {
    if (!role || !operation) {
      return false;
    }
    return operation.userId === role.userId;
  }

  public static isAdmin(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.hasRole(role, RoleType.Admin);
  }

  public static isAtLeastAdmin(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.isAtLeast(role, RoleType.Admin);
  }

  public static isEditor(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.hasRole(role, RoleType.Editor);
  }

  public static isAtLeastEditor(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.isAtLeast(role, RoleType.Editor);
  }

  public static isViewer(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.hasRole(role, RoleType.Viewer);
  }

  public static isAtLeastViewer(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.isAtLeast(role, RoleType.Viewer);
  }

  public static hasRole(role: PermissionRole, roleType: RoleType) {
    return this.invitationAccepted(role) && role.roleType === roleType;
  }

  public static isAtLeast(role: PermissionRole, roleType: RoleType) {
    if (!this.invitationAccepted(role)) {
      return false;
    }
    switch (roleType) {
      case RoleType.Admin:
        switch (role.roleType) {
          case RoleType.Admin:
            return true;
          case RoleType.Editor:
            return false;
          case RoleType.Viewer:
            return false;
          case RoleType.Advisor:
            return true;
          default:
            throw new UnreachableCaseError(role.roleType);
        }
        break;
      case RoleType.Editor:
        switch (role.roleType) {
          case RoleType.Admin:
            return true;
          case RoleType.Editor:
            return true;
          case RoleType.Viewer:
            return false;
          case RoleType.Advisor:
            return true;
          default:
            throw new UnreachableCaseError(role.roleType);
        }
        break;
      case RoleType.Viewer:
        switch (role.roleType) {
          case RoleType.Admin:
            return true;
          case RoleType.Editor:
            return true;
          case RoleType.Viewer:
            return true;
          case RoleType.Advisor:
            return true;
          default:
            throw new UnreachableCaseError(role.roleType);
        }
        break;
      case RoleType.Advisor:
        switch (role.roleType) {
          case RoleType.Admin:
            return false;
          case RoleType.Editor:
            return false;
          case RoleType.Viewer:
            return false;
          case RoleType.Advisor:
            return true;
          default:
            throw new UnreachableCaseError(role.roleType);
        }
        break;
      default:
        throw new UnreachableCaseError(roleType);
    }

    // shouldn't get here
    return false;
  }

  public static invitationAccepted(role: PermissionRole) {
    return role && role.invitationAccepted;
  }

  public static hasExecuteTrades(role: PermissionRole | undefined) {
    if (!role) {
      return false;
    }
    return this.invitationAccepted(role) && role.executeTrades;
  }
}

export default PermissionsValidator;
