import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductionCyclesType } from '../../../utils/productionCycle';

export type MyPositionInitialStateType = {
  productionCycles: ProductionCyclesType;
};

const initialState: MyPositionInitialStateType = {
  productionCycles: {},
};

const slice = createSlice({
  name: 'myPosition',
  initialState,
  reducers: {
    initializeMyPositionProductionCycles: (state, action: PayloadAction<ProductionCyclesType>) => ({
      ...state,
      productionCycles: action.payload,
    }),
    setMyPositionProductionCycle: (state, action: PayloadAction<ProductionCyclesType>) => ({
      ...state,
      productionCycles: { ...state.productionCycles, ...action.payload },
    }),
  },
});

export const { initializeMyPositionProductionCycles, setMyPositionProductionCycle } = slice.actions;

export default slice.reducer;
