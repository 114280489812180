import { IOperationCrop, IOperationCropV2 } from '../types/IOperationCrops';
import { ICropContract } from '../types/ICropContract';
import { filterMarketedCropContracts } from './RiskAssessment/riskAssessment';

export type MissingFields = {
  operationCrop: {
    acres: boolean;
  };
  price: {
    estimatedBasis: boolean;
    sellingPrice: boolean;
    bushelsContracted: boolean;
  };
  costs: {
    cropBudget: boolean;
  };
};

export function getMissingOperationCropFields(
  operationCrop: IOperationCrop | IOperationCropV2 | undefined,
  cropBudget?: number
): MissingFields {
  return {
    operationCrop: {
      acres: !Number(operationCrop?.acres),
    },
    price: {
      estimatedBasis: !Number(operationCrop?.price?.estimatedBasis),
      sellingPrice: !Number(operationCrop?.price?.sellingPrice),
      bushelsContracted: !Number(operationCrop?.price?.bushelsContracted),
    },
    costs: {
      cropBudget: !cropBudget,
    },
  };
}

export function missingFieldsToMessage(missingFields: any, messages: any) {
  let message = 'Missing ';

  const fields = Object.keys(missingFields)
    .filter((key) => missingFields[key])
    .map((key) => messages[key]);

  if (fields.length) {
    message += fields
      .reduce((msg, field, index) => {
        if (index === 0) {
          return field;
        }
        msg += (index === fields.length - 1 ? ' and ' : ', ') + field;
        return msg;
      }, '')
      .toLowerCase();
  } else {
    message = '';
  }

  return message;
}

export function getMessageFromOperationCropMissingFields(missingFields: any) {
  const operationCropFieldToFieldName: { [key: string]: string } = {
    productionCost: 'Production Costs',
    acres: 'Acres',
  };
  return missingFieldsToMessage(missingFields.operationCrop, operationCropFieldToFieldName);
}

export function getMessageFromPriceMissingFields(missingFields: any) {
  const priceFieldToFieldName: { [key: string]: string } = {
    estimatedBasis: 'Estimated Basis',
    sellingPrice: 'Weighted Average Selling Price',
    bushelsContracted: 'Bushels Contracted',
  };
  return missingFieldsToMessage(missingFields.price, priceFieldToFieldName);
}

export function getMessageFromCostsMissingFields(missingFields: any) {
  const costsFieldToFieldName: { [key: string]: string } = {
    cropBudget: 'Crop Costs',
  };
  return missingFieldsToMessage(missingFields.costs, costsFieldToFieldName);
}

export function areCrucialFieldsMissing(missingFields: MissingFields, cropContracts: ICropContract[]) {
  return (
    areOperationCropFieldsMissing(missingFields) ||
    arePriceFieldsMissing(missingFields, cropContracts) ||
    areCostsFieldsMissing(missingFields)
  );
}

export function areOperationCropFieldsMissing(missingFields: MissingFields) {
  const { operationCrop } = missingFields;
  const { acres } = operationCrop;
  return !!acres;
}

export function arePriceFieldsMissing(missingFields: MissingFields, cropContracts: ICropContract[]) {
  const { price } = missingFields;
  const { sellingPrice, bushelsContracted } = price;
  return contractedPartiallySet(sellingPrice, bushelsContracted) && !validCropContractsPresent(cropContracts);
}

export function areCostsFieldsMissing(missingFields: MissingFields) {
  const { costs } = missingFields;
  const { cropBudget } = costs;
  return cropBudget;
}

function contractedPartiallySet(sellingPrice: boolean, bushelsContracted: boolean) {
  return (!sellingPrice && bushelsContracted) || (!bushelsContracted && sellingPrice);
}

function validCropContractsPresent(cropContracts: ICropContract[]) {
  return !!filterMarketedCropContracts(cropContracts).length;
}
