import { ICashBid } from '../../types/ICashBid';
import { formatNumber } from '../../utils/formatNumber';
import { toNumberOrNull, toStringOrNull } from '../../utils/helpers';

export type CashBidMapperType = {
  key: string;
  format: (data: string | undefined) => string | null | number;
};

export const cashBidMappers: CashBidMapperType[] = [
  { key: 'id', format: (value: string | undefined) => Number(value || 0) },
  { key: 'locationId', format: (value: string | undefined) => Number(value || 0) },
  { key: 'distance', format: (value: string | undefined) => (value ? Number(value?.replace(/\D/gi, '')) : null) },
  { key: 'company', format: toStringOrNull },
  { key: 'city', format: toStringOrNull },
  { key: 'zip', format: toStringOrNull },
  { key: 'delivery_start', format: toStringOrNull },
  { key: 'delivery_end', format: toStringOrNull },
  { key: 'basis', format: (value: string | undefined) => (value ? formatNumber(Number(value) / 100, 2) : null) },
  { key: 'sym_root', format: toStringOrNull },
  { key: 'cashprice', format: toNumberOrNull },
  { key: 'symbol', format: toStringOrNull },
];

export function mapToCashBids(cashBid: any): ICashBid {
  const result: any = {
    ...cashBid,
  };

  cashBidMappers.forEach((item) => {
    result[item.key] = item.format(cashBid[item.key]);
  });

  return result as ICashBid;
}
export interface IGrainElevator {
  distance: string;
  company: string;
  locationId: number;
  location: string;
  facility_type: string;
  address: string;
  city: string;
  state: string;
  lng: number;
  lat: number;
  phone: string;
  url: string;
  zip: string;
  county: string;
  basisTimestamp: string;
  basisTimestampRaw: number;
  bids: IGrainBid[];
}

export interface IGrainBid {
  id: string;
  commodity?: string | null;
  symbol: string;
  delivery_start: string;
  delivery_end: string;
  basis: string;
  active: boolean;
  notes: string | null;
  sym_root: string;
  commodity_id: string;
  customer_commodity_id: string;
  commodity_display_name?: string | null;
  unitvalue: number;
  unitweight: number;
  currency: string;
  usesCurrencyConversion: boolean;
  deliveryMonth: string;
  deliveryYear: string;
  basismonth: string;
  timestamp: number;
  as_of: string;
  price: string;
  pricecwt: string;
  basiscwt: number;
  usesVolumeConversion: boolean;
  pricetonne: string;
  basistonne: number;
  change: string;
  rawchange: number;
  pctchange: string;
  cashprice: string;
  cashpricetonne: string;
  delivery_sort: string;
  delivery_start_raw: string;
  delivery_end_raw: string;
  basisSymbol: string;
  cashPriceSymbol: string;
  basisRollingSymbol: string;
  cashPriceRollingSymbol: string;
  nodeId: string;
}
