import { useEffect, useRef } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}
const scrollPositionsById: { [key: string]: ScrollPosition } = {};

export function useContainerScroll<T extends Element>(id: string, dependencyProps: any[] = []) {
  const scrollRef = useRef<T>(null);

  const handleScroll = () => {
    if (scrollRef.current && (scrollRef.current.scrollTop !== 0 || scrollRef.current?.scrollLeft !== 0)) {
      scrollPositionsById[id] = {
        x: scrollRef.current.scrollLeft,
        y: scrollRef.current.scrollTop,
      };
    }
  };

  // add event listener to window when component mounts
  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // restore scroll when dependencies change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollPositionsById[id] && scrollPositionsById[id].x;
      scrollRef.current.scrollTop = scrollPositionsById[id] && scrollPositionsById[id].y;
    }
  }, dependencyProps);

  return { scrollRef, scrollPositionsById };
}
