import { OperationMember } from '../../types/OperationMember';
import { PeopleActionTypes } from './types';

export default function accountMembers(state: OperationMember[] = [], action: PeopleActionTypes) {
  switch (action.type) {
    case 'GET_ACCOUNT_MEMBERS':
      return [...action.payload];
    case 'EDIT_ACCOUNT_MEMBER':
      return [...updateAccountMember(action.payload)];
    case 'REMOVE_ACCOUNT_MEMBER':
      return [...deleteAccountMember(action.payload)];
    case 'ADD_ACCOUNT_MEMBER':
      return [...state, action.payload];
    default:
      return state;
  }

  function updateAccountMember(operationMember: OperationMember) {
    const newState = [...state];
    const previousAccountMember: OperationMember | undefined = newState.find((am) => am.id === operationMember.id);

    if (previousAccountMember) {
      newState[newState.indexOf(previousAccountMember)] = { ...previousAccountMember, ...operationMember };
    }
    return newState;
  }

  function deleteAccountMember(roleId: number) {
    const newState = [...state];
    const accountMemberToDelete: OperationMember | undefined = newState.find((am) => am.role?.id === roleId);

    if (accountMemberToDelete) {
      newState.splice(newState.indexOf(accountMemberToDelete), 1);
      return newState;
    } else {
      return newState;
    }
  }
}
