import { BuySellType } from '@farmersrisk/shared/enums';
import { InstrumentType } from '@harvestiq/constants';
import { SynchronizationTypes } from './IStrategy';

export enum StrategyType {
  Option = 1,
  Futures = 2,
  Spreads = 3,
}

export enum PositionType {
  Option = 1,
  Futures = 2,
}

export enum OptionContractType {
  BuyPut = 1,
  SellPut = 2,
  BuyCall = 3,
  SellCall = 4,
}

export enum FuturesContractType {
  Buy = 1,
  Sell = 2,
}

export enum StrategyStatus {
  Open = 1,
  Closed = 2,
  Expired = 3,
}

export interface IPosition {
  id: number;
  strategyId: number;
  orderNumber: string;
  productionCycle?: string;
  type: PositionType;
  contractType: OptionContractType | FuturesContractType;
  status?: StrategyStatus;
  booked?: string | Date;
  tradeDate?: string | Date;
  futuresMonth?: number;
  futuresYear?: string;
  contracts?: number;
  quantitySold?: number;
  strikePrice?: number;
  futuresPrice?: number | null;
  expirationDate?: string | Date;
  commissionFees?: number;
  premiumCost?: number;
  gainLoss?: number;
  lastSpotPrice?: number | null;
  closingPrice?: number | null;
  volatility?: number;
  delta?: number;
  traceId: string;
  shouldMove?: boolean;
  instrumentType?: InstrumentType;
  buySellCode?: BuySellType;
  symbol?: string;
  originalCost?: string;
  isScenario?: boolean;
  createdAt?: string | Date | null;
  updatedAt?: string | Date | null;
  closedDate?: string | Date | null;
  originalDelta?: number;
  sourceFileType?: SynchronizationTypes | null;
  isSynchronized?: boolean;

  underlyingFuturesMonth?: number;
  underlyingFuturesYear?: string;
  [key: string]: any;
}
