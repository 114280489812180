import { OperationMember } from '../../types/OperationMember';
import { PeopleActionTypes } from './types';
import * as types from './constants';

export function getAccountMembersAction(accountMembers: OperationMember[]): PeopleActionTypes {
  return {
    type: types.GET_ACCOUNT_MEMBERS,
    payload: accountMembers,
  };
}

export function updateAccountMemberAction(accountMember: OperationMember): PeopleActionTypes {
  return {
    type: types.EDIT_ACCOUNT_MEMBER,
    payload: accountMember,
  };
}

export function removeAccountMemberAction(roleId: number): PeopleActionTypes {
  return {
    type: types.REMOVE_ACCOUNT_MEMBER,
    payload: roleId,
  };
}

export function addAccountMemberAction(accountMember: OperationMember): PeopleActionTypes {
  return {
    type: types.ADD_ACCOUNT_MEMBER,
    payload: accountMember,
  };
}
