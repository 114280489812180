/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { ApplePushNotif, Destination, GooglePushNotif, NamedValue, NotificationProperty, Text } from '../common/shared_1';
import { Timestamp } from '../google/protobuf/timestamp';

export const protobufPackage = 'rules_1';

export enum OrderEventFilterType {
  ORDER_EVENT_FILTER_TYPE_CONTRIBUTOR_ID = 1,
  UNRECOGNIZED = -1,
}

export function orderEventFilterTypeFromJSON(object: any): OrderEventFilterType {
  switch (object) {
    case 1:
    case 'ORDER_EVENT_FILTER_TYPE_CONTRIBUTOR_ID':
      return OrderEventFilterType.ORDER_EVENT_FILTER_TYPE_CONTRIBUTOR_ID;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrderEventFilterType.UNRECOGNIZED;
  }
}

export function orderEventFilterTypeToJSON(object: OrderEventFilterType): string {
  switch (object) {
    case OrderEventFilterType.ORDER_EVENT_FILTER_TYPE_CONTRIBUTOR_ID:
      return 'ORDER_EVENT_FILTER_TYPE_CONTRIBUTOR_ID';
    case OrderEventFilterType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Client rule (alert) messages.
 * Only one rule operation is allowed.
 */
export interface RuleRequest {
  /** Request identifier. */
  requestId: string;
  /**
   * Affects requests supporting subscription. It is ignored in case of other requests.
   * If present and set to true, the subscription will be established.
   * If present and set to false, the subscription will be dropped.
   * If omitted the request is not considered as subscription.
   * Note: this field is ignored by all requests except RuleListRequest and RuleEventSubscription one.
   */
  subscribe: boolean;
  /** Create or update a rule. */
  setRuleRequest: SetRuleRequest | undefined;
  /** Lightweight rule modification. */
  modifyRuleRequest: ModifyRuleRequest | undefined;
  /** Delete a rule. */
  deleteRuleRequest: DeleteRuleRequest | undefined;
  /** Get or subscribe to the rule list. */
  ruleListRequest: RuleListRequest | undefined;
  /** Request rule event history. */
  ruleEventHistoryRequest: RuleEventHistoryRequest | undefined;
  /** Subscription to rule events. */
  ruleEventSubscription: RuleEventSubscription | undefined;
  /** Creates or modifies a destination group. */
  createOrModifyDestinationGroupRequest: CreateOrModifyDestinationGroupRequest | undefined;
  /** List destination groups. */
  destinationGroupListRequest: DestinationGroupListRequest | undefined;
  /** Update destination expiration time. */
  updateDestinationExpirationTimeRequest: UpdateDestinationExpirationTimeRequest | undefined;
}

/**
 * Server rule (alert) messages.
 * Note: all optional fields may be missing (in case of RESULT_CODE_DISCONNECTED status for example).
 * Use request_id field to identify corresponding request.
 */
export interface RuleResult {
  /** ID of a corresponding request or subscription. */
  requestId: string;
  /**
   * Operation status.
   * This field is associated with ResultCode enum.
   */
  resultCode: number;
  /** Operation details. */
  details: Text | undefined;
  /** Result of set rule operation. */
  setRuleResult: SetRuleResult | undefined;
  /** Result of rule modification operation. */
  modifyRuleResult: ModifyRuleResult | undefined;
  /** Result of delete rule operation. */
  deleteRuleResult: DeleteRuleResult | undefined;
  /** Result of rule list request. */
  ruleListResult: RuleListResult | undefined;
  /** Result of rule event history request. */
  ruleEventHistoryResult: RuleEventHistoryResult | undefined;
  /** Result/updates for rule events subscription. */
  ruleEventSubscriptionStatus: RuleEventSubscriptionStatus | undefined;
  /** Result of create or modify destination group operation. */
  createOrModifyDestinationGroupResult: CreateOrModifyDestinationGroupResult | undefined;
  /** Result of list destination groups request. */
  destinationGroupListResult: DestinationGroupListResult | undefined;
  /** Result of update destination expiration time operation. */
  updateDestinationExpirationTimeResult: UpdateDestinationExpirationTimeResult | undefined;
}

export enum RuleResult_ResultCode {
  /** RESULT_CODE_SUCCESS - Success codes (0 - 100) */
  RESULT_CODE_SUCCESS = 0,
  /** RESULT_CODE_SUBSCRIBED - Request is processed and subscription is established. */
  RESULT_CODE_SUBSCRIBED = 1,
  /** RESULT_CODE_DROPPED - The subscription is dropped by the client. */
  RESULT_CODE_DROPPED = 2,
  /** RESULT_CODE_UPDATE - Unsolicited information update because of subscription. */
  RESULT_CODE_UPDATE = 3,
  /**
   * RESULT_CODE_DISCONNECTED - Some problem with subscription happened and updates will not be sent. Clients should not resubscribe
   * in this case: they will receive new RuleResult message with RESULT_CODE_SUBSCRIBED code
   * as soon as the problem is fixed.
   */
  RESULT_CODE_DISCONNECTED = 4,
  /**
   * RESULT_CODE_FAILURE - Failure codes (100+)
   * General failure.
   */
  RESULT_CODE_FAILURE = 101,
  UNRECOGNIZED = -1,
}

export function ruleResult_ResultCodeFromJSON(object: any): RuleResult_ResultCode {
  switch (object) {
    case 0:
    case 'RESULT_CODE_SUCCESS':
      return RuleResult_ResultCode.RESULT_CODE_SUCCESS;
    case 1:
    case 'RESULT_CODE_SUBSCRIBED':
      return RuleResult_ResultCode.RESULT_CODE_SUBSCRIBED;
    case 2:
    case 'RESULT_CODE_DROPPED':
      return RuleResult_ResultCode.RESULT_CODE_DROPPED;
    case 3:
    case 'RESULT_CODE_UPDATE':
      return RuleResult_ResultCode.RESULT_CODE_UPDATE;
    case 4:
    case 'RESULT_CODE_DISCONNECTED':
      return RuleResult_ResultCode.RESULT_CODE_DISCONNECTED;
    case 101:
    case 'RESULT_CODE_FAILURE':
      return RuleResult_ResultCode.RESULT_CODE_FAILURE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RuleResult_ResultCode.UNRECOGNIZED;
  }
}

export function ruleResult_ResultCodeToJSON(object: RuleResult_ResultCode): string {
  switch (object) {
    case RuleResult_ResultCode.RESULT_CODE_SUCCESS:
      return 'RESULT_CODE_SUCCESS';
    case RuleResult_ResultCode.RESULT_CODE_SUBSCRIBED:
      return 'RESULT_CODE_SUBSCRIBED';
    case RuleResult_ResultCode.RESULT_CODE_DROPPED:
      return 'RESULT_CODE_DROPPED';
    case RuleResult_ResultCode.RESULT_CODE_UPDATE:
      return 'RESULT_CODE_UPDATE';
    case RuleResult_ResultCode.RESULT_CODE_DISCONNECTED:
      return 'RESULT_CODE_DISCONNECTED';
    case RuleResult_ResultCode.RESULT_CODE_FAILURE:
      return 'RESULT_CODE_FAILURE';
    case RuleResult_ResultCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Rule definition contains one of the specific rules.
 * Only one specific rule can be specified.
 * The complete definition is expected regardless if these is a new rule or an update of existing one.
 * Note that overall serialized RuleDefinition message size is limited (100KB by default).
 */
export interface RuleDefinition {
  /**
   * Client ID of the rule.
   * It has to be unique for this client.
   */
  ruleId: string;
  /**
   * List of optional client rule tags.
   * See RuleEventSubscription.rule_tags and RuleEventHistoryRequest.rule_tags fields
   */
  ruleTags: string[];
  /** List of actions for rule execution. */
  actions: Action[];
  /** Order event rule definition. */
  orderEventRule: OrderEventRule | undefined;
  /** Condition rule definition. */
  conditionRule: ConditionRule | undefined;
  /**
   * True means the rule is in execution.
   * To enable or disable the rule the client updates the rule
   * with a corresponding values of this flag.
   */
  enabled: boolean;
  /**
   * List of custom rule attributes defined by client.
   * Note that full list of attributes has to be specified on rule's modification.
   */
  attributes: NamedValue[];
  /** If set to true the rule with given rule_id has been deleted. */
  deleted: boolean;
}

/** Possible actions for rule execution. */
export interface Action {
  /** List of destinations to notify. */
  destinations: Destination[];
  /** Destination group to notify. */
  destinationGroupId: string;
  /** Cancel all orders and liquidate all open positions. */
  goFlat: GoFlatAction | undefined;
}

/**
 * Cancel all orders (including parked) and liquidate all open positions.
 * The action is allowed only for condition rules with account variables.
 * The action is applied to an account which account variables triggered the condition rule.
 * It is required to specify at least one notification destination in a rule with 'go-flat' action.
 */
export interface GoFlatAction {}

/**
 * Order event notification rule.
 * Use it to get notifications for order events.
 */
export interface OrderEventRule {
  /**
   * Optional list of accounts.
   * All accounts of the client if not specified.
   *
   * @deprecated
   */
  accountIds: number[];
  /**
   * Optional list of order statuses that server will notify about.
   * The server will notify about all order statuses if not specified.
   * Associated with shared_1.OrderStatus.Status enum type.
   */
  orderStatuses: number[];
  /**
   * Optional list of transaction statuses that server will notify about.
   * The server will notify about all transaction statuses if not specified.
   * Note: if it's specified along with order_status field only notifications matching both filters will be sent.
   * Associated with shared_1.TransactionStatus.Status enum type.
   */
  transactionStatuses: number[];
  /** Custom order event filters. */
  filters: OrderEventFilter[];
}

/** Condition based rule. */
export interface ConditionRule {
  /** Rule triggering type, ONE_TIME by default. */
  triggeringType: number;
  /**
   * Expression that describes the condition.
   * A logical expression to evaluate.
   * If the expression evaluates to a numeric value then zero value is considered to be false,
   * true otherwise.
   */
  expression: Expression | undefined;
  /**
   * Title of notification to be sent on condition rule triggering.
   * The notification is sent without waiting for all rule's actions to be executed.
   * The length of this field is limited by 100 characters by default.
   */
  notificationTitle: TemplateText | undefined;
  /**
   * Body of notification to be sent on condition rule triggering.
   * The notification is sent without waiting for all rule's actions to be executed.
   * The length of this field is limited by 100 characters by default.
   * Note that actual notification might also include additional info, e.g. account information
   * for rules based on account variables.
   */
  notificationBody: TemplateText | undefined;
  /**
   * Condition rule triggering suppression period in seconds.
   * The rule is not triggered more often than once in this period.
   * Server side value is used if omitted in the rule definition (30 seconds by default).
   * Rules with account variables handles this suppression separately for each account.
   * Server has minimal allowed suppression period (5 seconds by default). It is used if lesser
   * value is specified.
   */
  suppressionPeriod: number;
}

/** Rule triggering type. */
export enum ConditionRule_TriggeringType {
  /**
   * TRIGGERING_TYPE_ONE_TIME - Triggers when the rule becomes true then disables the rule (see RuleDefinition.enabled field).
   * Not supported for rules with account variables.
   */
  TRIGGERING_TYPE_ONE_TIME = 0,
  /**
   * TRIGGERING_TYPE_AUTO - Triggers when the rule becomes true while it was false before or if it is initially true.
   * Any condition triggerings that happen after the previous one within a suppression period are ignored.
   * See suppression_period field.
   */
  TRIGGERING_TYPE_AUTO = 1,
  UNRECOGNIZED = -1,
}

export function conditionRule_TriggeringTypeFromJSON(object: any): ConditionRule_TriggeringType {
  switch (object) {
    case 0:
    case 'TRIGGERING_TYPE_ONE_TIME':
      return ConditionRule_TriggeringType.TRIGGERING_TYPE_ONE_TIME;
    case 1:
    case 'TRIGGERING_TYPE_AUTO':
      return ConditionRule_TriggeringType.TRIGGERING_TYPE_AUTO;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ConditionRule_TriggeringType.UNRECOGNIZED;
  }
}

export function conditionRule_TriggeringTypeToJSON(object: ConditionRule_TriggeringType): string {
  switch (object) {
    case ConditionRule_TriggeringType.TRIGGERING_TYPE_ONE_TIME:
      return 'TRIGGERING_TYPE_ONE_TIME';
    case ConditionRule_TriggeringType.TRIGGERING_TYPE_AUTO:
      return 'TRIGGERING_TYPE_AUTO';
    case ConditionRule_TriggeringType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Template text.
 * For rules with market variables "{contract_symbol}" occurrences if present are substituted with current
 * contract's symbol in CQG dialect.
 */
export interface TemplateText {
  text: string;
}

/**
 * Logical or arithmetical expression.
 * The depth of expression is limited by 10 levels.
 * Only variables of the same domain are allowed within a single expression (e.g. only account variables).
 */
export interface Expression {
  /**
   * Operator of the expression.
   * ADD operator is used by default.
   */
  operator: number;
  /**
   * Left-hand side operand.
   * Zero constant by default.
   */
  leftOperand: Operand | undefined;
  /**
   * Right-hand side operand.
   * Zero constant by default.
   * Not used for unary operations.
   */
  rightOperand: Operand | undefined;
}

/**
 * List of operators.
 * True is converted to 1 and False is converted to 0 if results of logical
 * expressions are included into arithmetic operations.
 * Zero is converted to False and Non Zero to True if logical value is expected.
 * Integer and decimal values are implicitly converted to double format.
 * Comparison between double operands is performed with epsilon precision calculated
 * as 2.2204460492503131e-016 * max (1, |operand1|, |operand2|).
 * Condition rule is reset to false (for AUTO rules):
 * - result of arithmetic operation is infinity or nan,
 * - required variable does not have a value (e.g. when AccountVariable with
 *   TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION type for an account without
 *   open positions),
 * - in case of any other error on rule calculation.
 */
export enum Expression_Operator {
  /** OPERATOR_ADD - Arithmetical operators. */
  OPERATOR_ADD = 0,
  /** OPERATOR_SUBTRACT - The result of left_operand - right_operand. */
  OPERATOR_SUBTRACT = 1,
  /** OPERATOR_MULTIPLY - The result of left_operand * right_operand. */
  OPERATOR_MULTIPLY = 2,
  /** OPERATOR_DIVIDE - The result of left_operand / right_operand. */
  OPERATOR_DIVIDE = 3,
  /** OPERATOR_LESS - Logical operators. */
  OPERATOR_LESS = 10,
  /** OPERATOR_LESS_EQUAL - True if left_operand is less than or equal to right_operand. */
  OPERATOR_LESS_EQUAL = 11,
  /** OPERATOR_EQUAL - True if left_operand is equal to right_operand. */
  OPERATOR_EQUAL = 12,
  /** OPERATOR_NOT_EQUAL - True if left_operand is not equal to right_operand. */
  OPERATOR_NOT_EQUAL = 18,
  /** OPERATOR_GREATER_EQUAL - True if left_operand is greater than or equal to right_operand. */
  OPERATOR_GREATER_EQUAL = 13,
  /** OPERATOR_GREATER - True if left_operand is greater than right_operand. */
  OPERATOR_GREATER = 14,
  /** OPERATOR_NOT - Inverts True/Non-zero to False and False/Zero to True in left_operand. */
  OPERATOR_NOT = 15,
  /** OPERATOR_AND - The result of logical AND operation. */
  OPERATOR_AND = 16,
  /** OPERATOR_OR - The result of logical OR operation. */
  OPERATOR_OR = 17,
  UNRECOGNIZED = -1,
}

export function expression_OperatorFromJSON(object: any): Expression_Operator {
  switch (object) {
    case 0:
    case 'OPERATOR_ADD':
      return Expression_Operator.OPERATOR_ADD;
    case 1:
    case 'OPERATOR_SUBTRACT':
      return Expression_Operator.OPERATOR_SUBTRACT;
    case 2:
    case 'OPERATOR_MULTIPLY':
      return Expression_Operator.OPERATOR_MULTIPLY;
    case 3:
    case 'OPERATOR_DIVIDE':
      return Expression_Operator.OPERATOR_DIVIDE;
    case 10:
    case 'OPERATOR_LESS':
      return Expression_Operator.OPERATOR_LESS;
    case 11:
    case 'OPERATOR_LESS_EQUAL':
      return Expression_Operator.OPERATOR_LESS_EQUAL;
    case 12:
    case 'OPERATOR_EQUAL':
      return Expression_Operator.OPERATOR_EQUAL;
    case 18:
    case 'OPERATOR_NOT_EQUAL':
      return Expression_Operator.OPERATOR_NOT_EQUAL;
    case 13:
    case 'OPERATOR_GREATER_EQUAL':
      return Expression_Operator.OPERATOR_GREATER_EQUAL;
    case 14:
    case 'OPERATOR_GREATER':
      return Expression_Operator.OPERATOR_GREATER;
    case 15:
    case 'OPERATOR_NOT':
      return Expression_Operator.OPERATOR_NOT;
    case 16:
    case 'OPERATOR_AND':
      return Expression_Operator.OPERATOR_AND;
    case 17:
    case 'OPERATOR_OR':
      return Expression_Operator.OPERATOR_OR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Expression_Operator.UNRECOGNIZED;
  }
}

export function expression_OperatorToJSON(object: Expression_Operator): string {
  switch (object) {
    case Expression_Operator.OPERATOR_ADD:
      return 'OPERATOR_ADD';
    case Expression_Operator.OPERATOR_SUBTRACT:
      return 'OPERATOR_SUBTRACT';
    case Expression_Operator.OPERATOR_MULTIPLY:
      return 'OPERATOR_MULTIPLY';
    case Expression_Operator.OPERATOR_DIVIDE:
      return 'OPERATOR_DIVIDE';
    case Expression_Operator.OPERATOR_LESS:
      return 'OPERATOR_LESS';
    case Expression_Operator.OPERATOR_LESS_EQUAL:
      return 'OPERATOR_LESS_EQUAL';
    case Expression_Operator.OPERATOR_EQUAL:
      return 'OPERATOR_EQUAL';
    case Expression_Operator.OPERATOR_NOT_EQUAL:
      return 'OPERATOR_NOT_EQUAL';
    case Expression_Operator.OPERATOR_GREATER_EQUAL:
      return 'OPERATOR_GREATER_EQUAL';
    case Expression_Operator.OPERATOR_GREATER:
      return 'OPERATOR_GREATER';
    case Expression_Operator.OPERATOR_NOT:
      return 'OPERATOR_NOT';
    case Expression_Operator.OPERATOR_AND:
      return 'OPERATOR_AND';
    case Expression_Operator.OPERATOR_OR:
      return 'OPERATOR_OR';
    case Expression_Operator.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Operand for the expression.
 * One and only one field is expected.
 */
export interface Operand {
  /** Another expression. */
  expression: Expression | undefined;
  /** Constant value. */
  constant: Constant | undefined;
  /** Market data based variable. */
  marketVariable: MarketVariable | undefined;
  /** Account based variable. */
  accountVariable: AccountVariable | undefined;
}

/** Constant value. */
export interface Constant {
  /**
   * double value.
   * Note that double operands are compared with epsilon precision. See Operator enum description.
   */
  doubleValue: number;
}

/**
 * Market data-based variable.
 * Number of rules with market variables (price alerts) is limited (10 by default).
 */
export interface MarketVariable {
  /**
   * Symbol pattern to resolve into a contract and use its market data.
   * Symbol must be specified in CQG dialect.
   * It is allowed to specify a relative symbol (e.g. "EP") so that the rule will switch to a new most active
   * contract on a rollover event.
   * If there is no need in contract switching an absolute symbol needs to be specified
   * (e.g. webapi_1.ContractMetadata.cqg_contract_symbol of already resolved metadata).
   * For option contracts only absolute symbols are allowed.
   * Rule becomes not enabled (see RuleDefinition.enabled field) when market data on current contract gets not
   * available.
   * Multiple market variables can use the same symbol pattern but only one symbol pattern
   * is allowed for a condition rule.
   * It is not allowed to use contracts with non-empty ContractMetadata.contributor_parameters.parameters lists
   * at the moment.
   * This is required field.
   */
  symbol: string;
  /**
   * Type of market variable.
   * This field is associated with MarketVariable.Type enum.
   * This is required field.
   */
  type: number;
}

/**
 * Type of market variable.
 * All price based values are provided in correct format for operations
 * in expressions (float point value). No currency conversion is applied to these values.
 * Some intermediate updates of market variables might be skipped from rule's expression evaluation, so
 * that if the condition gets True only for a short period of time, the rule might not trigger.
 * Expressions with variables that use last trade price or volume are evaluated starting from
 * the first trade occurred after the rule creation.
 */
export enum MarketVariable_Type {
  /** TYPE_LAST_TRADE_NET_CHANGE - Delta between the last trade and yesterday settlement: last_trade - yesterday_settlement. */
  TYPE_LAST_TRADE_NET_CHANGE = 1,
  /**
   * TYPE_LAST_TRADE_NET_CHANGE_PC - % delta between the last trade and yesterday settlement:
   * 100*(last_trade - yesterday_settlement)/yesterday_settlement.
   */
  TYPE_LAST_TRADE_NET_CHANGE_PC = 2,
  /** TYPE_LAST_TRADE_PRICE - Last trade price. */
  TYPE_LAST_TRADE_PRICE = 3,
  /** TYPE_LAST_TRADE_VOLUME - Last trade volume. */
  TYPE_LAST_TRADE_VOLUME = 4,
  /** TYPE_CONTRACT_TOTAL_VOLUME - Today's volume for the contract. */
  TYPE_CONTRACT_TOTAL_VOLUME = 5,
  /** TYPE_BID_ASK_SPREAD - Delta between best ask and best bid: ask - bid. */
  TYPE_BID_ASK_SPREAD = 6,
  /** TYPE_TODAYS_HIGH - Today's high. */
  TYPE_TODAYS_HIGH = 7,
  /** TYPE_TODAYS_LOW - Today's low. */
  TYPE_TODAYS_LOW = 8,
  UNRECOGNIZED = -1,
}

export function marketVariable_TypeFromJSON(object: any): MarketVariable_Type {
  switch (object) {
    case 1:
    case 'TYPE_LAST_TRADE_NET_CHANGE':
      return MarketVariable_Type.TYPE_LAST_TRADE_NET_CHANGE;
    case 2:
    case 'TYPE_LAST_TRADE_NET_CHANGE_PC':
      return MarketVariable_Type.TYPE_LAST_TRADE_NET_CHANGE_PC;
    case 3:
    case 'TYPE_LAST_TRADE_PRICE':
      return MarketVariable_Type.TYPE_LAST_TRADE_PRICE;
    case 4:
    case 'TYPE_LAST_TRADE_VOLUME':
      return MarketVariable_Type.TYPE_LAST_TRADE_VOLUME;
    case 5:
    case 'TYPE_CONTRACT_TOTAL_VOLUME':
      return MarketVariable_Type.TYPE_CONTRACT_TOTAL_VOLUME;
    case 6:
    case 'TYPE_BID_ASK_SPREAD':
      return MarketVariable_Type.TYPE_BID_ASK_SPREAD;
    case 7:
    case 'TYPE_TODAYS_HIGH':
      return MarketVariable_Type.TYPE_TODAYS_HIGH;
    case 8:
    case 'TYPE_TODAYS_LOW':
      return MarketVariable_Type.TYPE_TODAYS_LOW;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MarketVariable_Type.UNRECOGNIZED;
  }
}

export function marketVariable_TypeToJSON(object: MarketVariable_Type): string {
  switch (object) {
    case MarketVariable_Type.TYPE_LAST_TRADE_NET_CHANGE:
      return 'TYPE_LAST_TRADE_NET_CHANGE';
    case MarketVariable_Type.TYPE_LAST_TRADE_NET_CHANGE_PC:
      return 'TYPE_LAST_TRADE_NET_CHANGE_PC';
    case MarketVariable_Type.TYPE_LAST_TRADE_PRICE:
      return 'TYPE_LAST_TRADE_PRICE';
    case MarketVariable_Type.TYPE_LAST_TRADE_VOLUME:
      return 'TYPE_LAST_TRADE_VOLUME';
    case MarketVariable_Type.TYPE_CONTRACT_TOTAL_VOLUME:
      return 'TYPE_CONTRACT_TOTAL_VOLUME';
    case MarketVariable_Type.TYPE_BID_ASK_SPREAD:
      return 'TYPE_BID_ASK_SPREAD';
    case MarketVariable_Type.TYPE_TODAYS_HIGH:
      return 'TYPE_TODAYS_HIGH';
    case MarketVariable_Type.TYPE_TODAYS_LOW:
      return 'TYPE_TODAYS_LOW';
    case MarketVariable_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Account based variable.
 * Rule with account variables (account alert) is processed and evaluated separately for each available account.
 * Omnibus accounts, accounts that are members of account groups and accounts without statement support
 * (without trading_account_2.Account.supports_statements=True) are excluded from processing.
 * Note that there is a common limit on triggering rate among all user's rules with account variables,
 * 500 rule triggerings per hour per account by default. If it exceeds, further triggerings are suppressed
 * until rate lowers.
 * Note: Price based account variable will produce values in USD.
 */
export interface AccountVariable {
  /**
   * Type of account variable.
   * This is required field.
   * This field is associated with AccountVariable.Type enum.
   */
  type: number;
}

/** Type of account variable. */
export enum AccountVariable_Type {
  TYPE_UNSPECIFIED = 0,
  /** TYPE_TOTAL_MARGIN - Margin requirement calculated for worst-case based on open positions and working orders. */
  TYPE_TOTAL_MARGIN = 1,
  /** TYPE_POSITION_MARGIN - Margin requirement based on current positions only. */
  TYPE_POSITION_MARGIN = 2,
  /**
   * TYPE_PURCHASING_POWER - Available account funds including balance, realized profit (or loss), collateral and credits.
   * OTE and MVO are included regarding the account risk parameters.
   */
  TYPE_PURCHASING_POWER = 3,
  /**
   * TYPE_OTE - Open trade equity, or potential profit (or loss) from futures and future-style options positions
   * based on opening price of the position and the current future trade/best bid/best ask
   * (regarding to the risk account settings) or settlement price if trade is not available.
   */
  TYPE_OTE = 4,
  /** TYPE_OTE_UPL - OTE + UPL. */
  TYPE_OTE_UPL = 26,
  /** TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS - Absolute value of (OTE + UPL) if it is negative otherwise zero value. */
  TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS = 5,
  /**
   * TYPE_MVO - Market value of options calculated as the current market trade/best bid/best ask of the option
   * (regarding to the risk account settings) times the number of options
   * (positive for long options and negative for short options) in the portfolio.
   */
  TYPE_MVO = 6,
  /** TYPE_NLV - Net Liquidity Value. It includes current balance, OTE, MVO and collateral values. */
  TYPE_NLV = 7,
  /**
   * TYPE_MVF - Market value of futures calculated as the current market trade/best bid/best ask
   * (regarding to the risk account settings) times the number of futures
   * (positive for long and negative for short) in the portfolio.
   */
  TYPE_MVF = 8,
  /** TYPE_MARGIN_CREDIT - Allowable margin credit of the account. */
  TYPE_MARGIN_CREDIT = 9,
  /** TYPE_MARGIN_EXCESS - Margin excess. It consists of purchasing power minus total margin. */
  TYPE_MARGIN_EXCESS = 10,
  /** TYPE_CASH_EXCESS - Cash Excess. */
  TYPE_CASH_EXCESS = 11,
  /** TYPE_CURRENT_BALANCE - Current account's balance. */
  TYPE_CURRENT_BALANCE = 13,
  /** TYPE_PROFIT_LOSS - Realized profit/loss. */
  TYPE_PROFIT_LOSS = 14,
  /** TYPE_UNREALIZED_PROFIT_LOSS - Unrealized profit/loss for options. */
  TYPE_UNREALIZED_PROFIT_LOSS = 15,
  /** TYPE_OTE_UPL_AND_PL - Sum of OTE, UPL and realized profit/loss values. */
  TYPE_OTE_UPL_AND_PL = 16,
  /** TYPE_YESTERDAY_BALANCE - Cash balance from the last statement. */
  TYPE_YESTERDAY_BALANCE = 17,
  /** TYPE_YESTERDAY_OTE - Open trade equity from the last statement. */
  TYPE_YESTERDAY_OTE = 23,
  /** TYPE_YESTERDAY_MVO - Market value of options from the last statement. */
  TYPE_YESTERDAY_MVO = 24,
  /** TYPE_YESTERDAY_COLLATERAL - Collateral on Deposit. */
  TYPE_YESTERDAY_COLLATERAL = 12,
  /** TYPE_NET_CHANGE_PC - (profit_loss / abs(yesterday_balance)) in percentage. */
  TYPE_NET_CHANGE_PC = 25,
  /** TYPE_TOTAL_FILLED_QTY - Sum of all fill sizes for the current day. */
  TYPE_TOTAL_FILLED_QTY = 18,
  /** TYPE_TOTAL_FILLED_ORDERS - Count of filled orders for the current day. */
  TYPE_TOTAL_FILLED_ORDERS = 19,
  /** TYPE_LONG_OPEN_POSITIONS_QTY - Sum of position quantities among all long open positions on the account. */
  TYPE_LONG_OPEN_POSITIONS_QTY = 20,
  /** TYPE_SHORT_OPEN_POSITIONS_QTY - Sum of position quantities among all short open positions on the account. */
  TYPE_SHORT_OPEN_POSITIONS_QTY = 21,
  /**
   * TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION - Minimal value of days till contract expiration (in calendar days, not trading) among
   * all open positions on contracts with expiration date on the account.
   * It does not have a value when there are no such open positions on the account.
   */
  TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION = 22,
  UNRECOGNIZED = -1,
}

export function accountVariable_TypeFromJSON(object: any): AccountVariable_Type {
  switch (object) {
    case 0:
    case 'TYPE_UNSPECIFIED':
      return AccountVariable_Type.TYPE_UNSPECIFIED;
    case 1:
    case 'TYPE_TOTAL_MARGIN':
      return AccountVariable_Type.TYPE_TOTAL_MARGIN;
    case 2:
    case 'TYPE_POSITION_MARGIN':
      return AccountVariable_Type.TYPE_POSITION_MARGIN;
    case 3:
    case 'TYPE_PURCHASING_POWER':
      return AccountVariable_Type.TYPE_PURCHASING_POWER;
    case 4:
    case 'TYPE_OTE':
      return AccountVariable_Type.TYPE_OTE;
    case 26:
    case 'TYPE_OTE_UPL':
      return AccountVariable_Type.TYPE_OTE_UPL;
    case 5:
    case 'TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS':
      return AccountVariable_Type.TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS;
    case 6:
    case 'TYPE_MVO':
      return AccountVariable_Type.TYPE_MVO;
    case 7:
    case 'TYPE_NLV':
      return AccountVariable_Type.TYPE_NLV;
    case 8:
    case 'TYPE_MVF':
      return AccountVariable_Type.TYPE_MVF;
    case 9:
    case 'TYPE_MARGIN_CREDIT':
      return AccountVariable_Type.TYPE_MARGIN_CREDIT;
    case 10:
    case 'TYPE_MARGIN_EXCESS':
      return AccountVariable_Type.TYPE_MARGIN_EXCESS;
    case 11:
    case 'TYPE_CASH_EXCESS':
      return AccountVariable_Type.TYPE_CASH_EXCESS;
    case 13:
    case 'TYPE_CURRENT_BALANCE':
      return AccountVariable_Type.TYPE_CURRENT_BALANCE;
    case 14:
    case 'TYPE_PROFIT_LOSS':
      return AccountVariable_Type.TYPE_PROFIT_LOSS;
    case 15:
    case 'TYPE_UNREALIZED_PROFIT_LOSS':
      return AccountVariable_Type.TYPE_UNREALIZED_PROFIT_LOSS;
    case 16:
    case 'TYPE_OTE_UPL_AND_PL':
      return AccountVariable_Type.TYPE_OTE_UPL_AND_PL;
    case 17:
    case 'TYPE_YESTERDAY_BALANCE':
      return AccountVariable_Type.TYPE_YESTERDAY_BALANCE;
    case 23:
    case 'TYPE_YESTERDAY_OTE':
      return AccountVariable_Type.TYPE_YESTERDAY_OTE;
    case 24:
    case 'TYPE_YESTERDAY_MVO':
      return AccountVariable_Type.TYPE_YESTERDAY_MVO;
    case 12:
    case 'TYPE_YESTERDAY_COLLATERAL':
      return AccountVariable_Type.TYPE_YESTERDAY_COLLATERAL;
    case 25:
    case 'TYPE_NET_CHANGE_PC':
      return AccountVariable_Type.TYPE_NET_CHANGE_PC;
    case 18:
    case 'TYPE_TOTAL_FILLED_QTY':
      return AccountVariable_Type.TYPE_TOTAL_FILLED_QTY;
    case 19:
    case 'TYPE_TOTAL_FILLED_ORDERS':
      return AccountVariable_Type.TYPE_TOTAL_FILLED_ORDERS;
    case 20:
    case 'TYPE_LONG_OPEN_POSITIONS_QTY':
      return AccountVariable_Type.TYPE_LONG_OPEN_POSITIONS_QTY;
    case 21:
    case 'TYPE_SHORT_OPEN_POSITIONS_QTY':
      return AccountVariable_Type.TYPE_SHORT_OPEN_POSITIONS_QTY;
    case 22:
    case 'TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION':
      return AccountVariable_Type.TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountVariable_Type.UNRECOGNIZED;
  }
}

export function accountVariable_TypeToJSON(object: AccountVariable_Type): string {
  switch (object) {
    case AccountVariable_Type.TYPE_UNSPECIFIED:
      return 'TYPE_UNSPECIFIED';
    case AccountVariable_Type.TYPE_TOTAL_MARGIN:
      return 'TYPE_TOTAL_MARGIN';
    case AccountVariable_Type.TYPE_POSITION_MARGIN:
      return 'TYPE_POSITION_MARGIN';
    case AccountVariable_Type.TYPE_PURCHASING_POWER:
      return 'TYPE_PURCHASING_POWER';
    case AccountVariable_Type.TYPE_OTE:
      return 'TYPE_OTE';
    case AccountVariable_Type.TYPE_OTE_UPL:
      return 'TYPE_OTE_UPL';
    case AccountVariable_Type.TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS:
      return 'TYPE_OPEN_TRADE_LOSS_UNREALIZED_LOSS';
    case AccountVariable_Type.TYPE_MVO:
      return 'TYPE_MVO';
    case AccountVariable_Type.TYPE_NLV:
      return 'TYPE_NLV';
    case AccountVariable_Type.TYPE_MVF:
      return 'TYPE_MVF';
    case AccountVariable_Type.TYPE_MARGIN_CREDIT:
      return 'TYPE_MARGIN_CREDIT';
    case AccountVariable_Type.TYPE_MARGIN_EXCESS:
      return 'TYPE_MARGIN_EXCESS';
    case AccountVariable_Type.TYPE_CASH_EXCESS:
      return 'TYPE_CASH_EXCESS';
    case AccountVariable_Type.TYPE_CURRENT_BALANCE:
      return 'TYPE_CURRENT_BALANCE';
    case AccountVariable_Type.TYPE_PROFIT_LOSS:
      return 'TYPE_PROFIT_LOSS';
    case AccountVariable_Type.TYPE_UNREALIZED_PROFIT_LOSS:
      return 'TYPE_UNREALIZED_PROFIT_LOSS';
    case AccountVariable_Type.TYPE_OTE_UPL_AND_PL:
      return 'TYPE_OTE_UPL_AND_PL';
    case AccountVariable_Type.TYPE_YESTERDAY_BALANCE:
      return 'TYPE_YESTERDAY_BALANCE';
    case AccountVariable_Type.TYPE_YESTERDAY_OTE:
      return 'TYPE_YESTERDAY_OTE';
    case AccountVariable_Type.TYPE_YESTERDAY_MVO:
      return 'TYPE_YESTERDAY_MVO';
    case AccountVariable_Type.TYPE_YESTERDAY_COLLATERAL:
      return 'TYPE_YESTERDAY_COLLATERAL';
    case AccountVariable_Type.TYPE_NET_CHANGE_PC:
      return 'TYPE_NET_CHANGE_PC';
    case AccountVariable_Type.TYPE_TOTAL_FILLED_QTY:
      return 'TYPE_TOTAL_FILLED_QTY';
    case AccountVariable_Type.TYPE_TOTAL_FILLED_ORDERS:
      return 'TYPE_TOTAL_FILLED_ORDERS';
    case AccountVariable_Type.TYPE_LONG_OPEN_POSITIONS_QTY:
      return 'TYPE_LONG_OPEN_POSITIONS_QTY';
    case AccountVariable_Type.TYPE_SHORT_OPEN_POSITIONS_QTY:
      return 'TYPE_SHORT_OPEN_POSITIONS_QTY';
    case AccountVariable_Type.TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION:
      return 'TYPE_MIN_DAYS_TILL_POSITION_CONTRACT_EXPIRATION';
    case AccountVariable_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Rule related event.
 * It might be related to a triggering of a specific rule or be a general event
 * (e.g., indicating too high overall triggering rate).
 */
export interface RuleEvent {
  /** Event title. */
  title: Text | undefined;
  /** Event body. */
  body: Text | undefined;
  /**
   * Rule ID.
   * Filled when the event is related to specific rule.
   */
  ruleId: string;
  /** Optional notification specific information. */
  notificationProperties: NotificationProperty[];
  /** Event time. */
  whenUtcTimestamp: Date | undefined;
  /**
   * Optional error details.
   * Filled e.g., if some of rule's actions failed to execute on rule triggering.
   */
  errorDetails: Text | undefined;
  /** Rule tags. */
  ruleTags: string[];
}

/** Represents configured order rule filter for specific event. */
export interface OrderEventFilter {
  /**
   * Filter type.
   * This field is associated with EventFilterType enum.
   * It is required field.
   */
  filterType: number;
  /**
   * Custom filter value. (max length = 128).
   * If value is not set, it means subject filtration with empty (not set) value.
   */
  value: string;
}

/**
 * Create or update a rule.
 * Note that update of a condition rule may trigger rule actions if rule expression is evaluated to true initially,
 * even if the rule triggered in the past with the same expression and it is still true.
 * In this case you may use ModifyRuleRequest to avoid extra triggerings.
 */
export interface SetRuleRequest {
  /**
   * Definition of the rule.
   * The complete definition is expected
   * regardless if these are new rules of updates.
   */
  ruleDefinition: RuleDefinition | undefined;
}

/** Result of set rule operation. */
export interface SetRuleResult {}

/**
 * Lightweight rule modification.
 * The request contains part of rule definition that can be modified without affecting rule execution.
 */
export interface ModifyRuleRequest {
  /** Client ID of the rule. */
  ruleId: string;
  /**
   * List of actions for rule execution.
   * If present, the list of actions in rule definition will be replaced by the given actions.
   * If omitted (empty), this list of actions will not change.
   */
  actions: Action[];
  /**
   * Rule revision to check before executing the request.
   * The request will fail if the rule was modified and its rule revision changed.
   * Optional. If omitted, rule revision is not checked.
   * Internal field. Rule revisions are currently not available for external clients.
   */
  ruleRevision: number;
}

/** Result of rule modification operation. */
export interface ModifyRuleResult {}

/** Delete a rule. */
export interface DeleteRuleRequest {
  /** Rule to delete. */
  ruleId: string;
  /**
   * Rule revision to check before executing the request.
   * The request will fail if the rule was modified and its rule revision changed.
   * Optional. If omitted, rule revision is not checked.
   * Internal field. Rule revisions are currently not available for external clients.
   */
  ruleRevision: number;
}

/** Result of delete rule operation. */
export interface DeleteRuleResult {}

/** Get a list of rules. */
export interface RuleListRequest {}

/**
 * Result of rule list request.
 * If request does not contain 'subscribe' field or if it is set to true, this message contains all user's rules,
 * 'is_snapshot' field is set to true.
 * This message is also sent to notify about updated rules if there is active rule list subscription.
 * In this case message contains updated rules only, 'is_snapshot' field is omitted.
 * Note: If client received RESULT_CODE_DISCONNECTED result code for rule list subscription for some reason,
 * subscription will be restored later and client will receive a new RuleResult message, containing
 * RESULT_CODE_SUBSCRIBED result code and RuleListResult message with actual list of rules
 * and 'is_snapshot' field set to true.
 */
export interface RuleListResult {
  /**
   * Rule list.
   * Clients have to be ready to receive rules that they cannot recognize
   * and ignore them. They must not remove or update unknown rules since they
   * can be used by different applications or different versions of the same application.
   */
  ruleDefinitions: RuleDefinition[];
  /**
   * If set to true, rule_definitions contains all found rules.
   * If omitted, rule_definitions contain changed rules only.
   */
  isSnapshot: boolean;
}

/**
 * Request for rule event history.
 * The history is limited to 30 last days.
 */
export interface RuleEventHistoryRequest {
  /**
   * Start point for the history request, inclusive.
   * Maximum allowed history is returned if omitted.
   */
  fromUtcTimestamp: Date | undefined;
  /**
   * Stop point for the history request, inclusive.
   * History up till now is returned if omitted.
   */
  toUtcTimestamp: Date | undefined;
  /**
   * Filter events by rule tags.
   * Result contains events, triggered by rules with tags, containing at least one tag from this list.
   * All events are returned if omitted.
   */
  ruleTags: string[];
}

/** Result of rule event history request. */
export interface RuleEventHistoryResult {
  /** List of rule events. */
  ruleEvents: RuleEvent[];
  /**
   * True means that this is the last message for the request.
   * False or empty means that more messages are expected.
   */
  complete: boolean;
}

/**
 * Request subscription to rules events.
 * If RuleRequest message contains this request, it must contain "subscribe" field with true or false value.
 * Otherwise the message is considered as invalid.
 * Note: it does not response with events snapshot.
 * Instead, it starts to send the rule events triggered after subscription.
 * For events snapshot use RuleEventHistoryRequest.
 * Note: if RESULT_CODE_DISCONNECTED status is received while the subscription is already established,
 * client may lose some events, because they will not be resent later when RESULT_CODE_SUBSCRIBED status is received
 * again. It's necessary to use RuleEventHistoryRequest request to get events snapshot.
 */
export interface RuleEventSubscription {
  /**
   * Filter events by rule tags.
   * Result contains events, triggered by rules with tags, containing at least one tag from this list.
   * All events are returned if omitted.
   */
  ruleTags: string[];
}

/**
 * Response for RuleEventSubscription.
 * Note: if RuleEventHistoryRequest and RuleEventSubscription requests are sent with small interval between them,
 * their responses (RuleEventHistoryResult and RuleEventSubscriptionStatus) may contain some duplicated events.
 * To identify duplicates use
 * (RuleEvent.rule_id, RuleEvent.when_utc_timestamp, RuleEvent.notification_properties["event_id"]) fields.
 */
export interface RuleEventSubscriptionStatus {
  /** List of rule events. */
  ruleEvents: RuleEvent[];
}

/** Create or modify a destination group. */
export interface CreateOrModifyDestinationGroupRequest {
  /** Id of the group to modify or create if it does not exist. */
  destinationGroupId: string;
  /**
   * Destination to remove from the group.
   * If it is not found among the existing ones, it is ignored.
   * Destination is identified by the following set of parameters:
   * (device_token, app_id) for ApplePushNotif,
   * (registration_token, sender_id) for GooglePushNotif,
   * (recipients) for EmailNotif,
   * (phone_number) for SmsNotif.
   * Note: either this or destination_to_add_or_update field must be specified.
   */
  destinationToRemove: Destination | undefined;
  /**
   * Destination to add or update in the group.
   * Existing member in the group referring the same destination will be overwritten.
   * See comment of destination_to_remove field of how destinations are identified.
   */
  destinationToAddOrUpdate: Destination | undefined;
}

/** Result of create or modify destination group operation. */
export interface CreateOrModifyDestinationGroupResult {}

/** Lists destination groups. */
export interface DestinationGroupListRequest {}

/** Result of list destination group operation. */
export interface DestinationGroupListResult {
  /** List of available destination groups. */
  destinationGroups: DestinationGroup[];
}

/**
 * Group of destinations.
 * Multiple rules can have the same destination group. A group can be modified independently on rules which using it.
 */
export interface DestinationGroup {
  /** Id of the group. */
  destinationGroupId: string;
  /** List of destinations in the group. */
  destinations: Destination[];
}

/**
 * Update destination expiration time.
 * The request must be sent regularly (e.g., daily, after logon) for current user's device.
 * The request should be sent before or together with request that adds the destination to a rule or destination group.
 * Destination expiration time is set to 2 months by default (configurable) after receiving the request.
 * When destination expires, the server deletes it from rules and destination groups:
 *  * If the list of actions in a rule becomes emtpy, the server deletes the rule;
 *  * If a destination group becomes empty, the server leaves it as is.
 * Thus, the server never deletes a rule that has a destination group (including empty group)
 * or a destination that does not expire (e.g., email_notif or profile_sms_notif).
 */
export interface UpdateDestinationExpirationTimeRequest {
  /** APN destination (for iOS devices). */
  applePushNotif?: ApplePushNotif | undefined;
  /** GCM destination (for Android devices). */
  googlePushNotif?: GooglePushNotif | undefined;
}

/** Result of update destination expiration time operation. */
export interface UpdateDestinationExpirationTimeResult {}

function createBaseRuleRequest(): RuleRequest {
  return {
    requestId: '',
    subscribe: false,
    setRuleRequest: undefined,
    modifyRuleRequest: undefined,
    deleteRuleRequest: undefined,
    ruleListRequest: undefined,
    ruleEventHistoryRequest: undefined,
    ruleEventSubscription: undefined,
    createOrModifyDestinationGroupRequest: undefined,
    destinationGroupListRequest: undefined,
    updateDestinationExpirationTimeRequest: undefined,
  };
}

export const RuleRequest = {
  encode(message: RuleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.subscribe === true) {
      writer.uint32(80).bool(message.subscribe);
    }
    if (message.setRuleRequest !== undefined) {
      SetRuleRequest.encode(message.setRuleRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.modifyRuleRequest !== undefined) {
      ModifyRuleRequest.encode(message.modifyRuleRequest, writer.uint32(98).fork()).ldelim();
    }
    if (message.deleteRuleRequest !== undefined) {
      DeleteRuleRequest.encode(message.deleteRuleRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.ruleListRequest !== undefined) {
      RuleListRequest.encode(message.ruleListRequest, writer.uint32(34).fork()).ldelim();
    }
    if (message.ruleEventHistoryRequest !== undefined) {
      RuleEventHistoryRequest.encode(message.ruleEventHistoryRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.ruleEventSubscription !== undefined) {
      RuleEventSubscription.encode(message.ruleEventSubscription, writer.uint32(74).fork()).ldelim();
    }
    if (message.createOrModifyDestinationGroupRequest !== undefined) {
      CreateOrModifyDestinationGroupRequest.encode(
        message.createOrModifyDestinationGroupRequest,
        writer.uint32(50).fork()
      ).ldelim();
    }
    if (message.destinationGroupListRequest !== undefined) {
      DestinationGroupListRequest.encode(message.destinationGroupListRequest, writer.uint32(66).fork()).ldelim();
    }
    if (message.updateDestinationExpirationTimeRequest !== undefined) {
      UpdateDestinationExpirationTimeRequest.encode(
        message.updateDestinationExpirationTimeRequest,
        writer.uint32(90).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 10:
          message.subscribe = reader.bool();
          break;
        case 2:
          message.setRuleRequest = SetRuleRequest.decode(reader, reader.uint32());
          break;
        case 12:
          message.modifyRuleRequest = ModifyRuleRequest.decode(reader, reader.uint32());
          break;
        case 3:
          message.deleteRuleRequest = DeleteRuleRequest.decode(reader, reader.uint32());
          break;
        case 4:
          message.ruleListRequest = RuleListRequest.decode(reader, reader.uint32());
          break;
        case 5:
          message.ruleEventHistoryRequest = RuleEventHistoryRequest.decode(reader, reader.uint32());
          break;
        case 9:
          message.ruleEventSubscription = RuleEventSubscription.decode(reader, reader.uint32());
          break;
        case 6:
          message.createOrModifyDestinationGroupRequest = CreateOrModifyDestinationGroupRequest.decode(reader, reader.uint32());
          break;
        case 8:
          message.destinationGroupListRequest = DestinationGroupListRequest.decode(reader, reader.uint32());
          break;
        case 11:
          message.updateDestinationExpirationTimeRequest = UpdateDestinationExpirationTimeRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleRequest {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false,
      setRuleRequest: isSet(object.setRuleRequest) ? SetRuleRequest.fromJSON(object.setRuleRequest) : undefined,
      modifyRuleRequest: isSet(object.modifyRuleRequest) ? ModifyRuleRequest.fromJSON(object.modifyRuleRequest) : undefined,
      deleteRuleRequest: isSet(object.deleteRuleRequest) ? DeleteRuleRequest.fromJSON(object.deleteRuleRequest) : undefined,
      ruleListRequest: isSet(object.ruleListRequest) ? RuleListRequest.fromJSON(object.ruleListRequest) : undefined,
      ruleEventHistoryRequest: isSet(object.ruleEventHistoryRequest)
        ? RuleEventHistoryRequest.fromJSON(object.ruleEventHistoryRequest)
        : undefined,
      ruleEventSubscription: isSet(object.ruleEventSubscription)
        ? RuleEventSubscription.fromJSON(object.ruleEventSubscription)
        : undefined,
      createOrModifyDestinationGroupRequest: isSet(object.createOrModifyDestinationGroupRequest)
        ? CreateOrModifyDestinationGroupRequest.fromJSON(object.createOrModifyDestinationGroupRequest)
        : undefined,
      destinationGroupListRequest: isSet(object.destinationGroupListRequest)
        ? DestinationGroupListRequest.fromJSON(object.destinationGroupListRequest)
        : undefined,
      updateDestinationExpirationTimeRequest: isSet(object.updateDestinationExpirationTimeRequest)
        ? UpdateDestinationExpirationTimeRequest.fromJSON(object.updateDestinationExpirationTimeRequest)
        : undefined,
    };
  },

  toJSON(message: RuleRequest): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    message.setRuleRequest !== undefined &&
      (obj.setRuleRequest = message.setRuleRequest ? SetRuleRequest.toJSON(message.setRuleRequest) : undefined);
    message.modifyRuleRequest !== undefined &&
      (obj.modifyRuleRequest = message.modifyRuleRequest ? ModifyRuleRequest.toJSON(message.modifyRuleRequest) : undefined);
    message.deleteRuleRequest !== undefined &&
      (obj.deleteRuleRequest = message.deleteRuleRequest ? DeleteRuleRequest.toJSON(message.deleteRuleRequest) : undefined);
    message.ruleListRequest !== undefined &&
      (obj.ruleListRequest = message.ruleListRequest ? RuleListRequest.toJSON(message.ruleListRequest) : undefined);
    message.ruleEventHistoryRequest !== undefined &&
      (obj.ruleEventHistoryRequest = message.ruleEventHistoryRequest
        ? RuleEventHistoryRequest.toJSON(message.ruleEventHistoryRequest)
        : undefined);
    message.ruleEventSubscription !== undefined &&
      (obj.ruleEventSubscription = message.ruleEventSubscription
        ? RuleEventSubscription.toJSON(message.ruleEventSubscription)
        : undefined);
    message.createOrModifyDestinationGroupRequest !== undefined &&
      (obj.createOrModifyDestinationGroupRequest = message.createOrModifyDestinationGroupRequest
        ? CreateOrModifyDestinationGroupRequest.toJSON(message.createOrModifyDestinationGroupRequest)
        : undefined);
    message.destinationGroupListRequest !== undefined &&
      (obj.destinationGroupListRequest = message.destinationGroupListRequest
        ? DestinationGroupListRequest.toJSON(message.destinationGroupListRequest)
        : undefined);
    message.updateDestinationExpirationTimeRequest !== undefined &&
      (obj.updateDestinationExpirationTimeRequest = message.updateDestinationExpirationTimeRequest
        ? UpdateDestinationExpirationTimeRequest.toJSON(message.updateDestinationExpirationTimeRequest)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleRequest>, I>>(base?: I): RuleRequest {
    return RuleRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleRequest>, I>>(object: I): RuleRequest {
    const message = createBaseRuleRequest();
    message.requestId = object.requestId ?? '';
    message.subscribe = object.subscribe ?? false;
    message.setRuleRequest =
      object.setRuleRequest !== undefined && object.setRuleRequest !== null
        ? SetRuleRequest.fromPartial(object.setRuleRequest)
        : undefined;
    message.modifyRuleRequest =
      object.modifyRuleRequest !== undefined && object.modifyRuleRequest !== null
        ? ModifyRuleRequest.fromPartial(object.modifyRuleRequest)
        : undefined;
    message.deleteRuleRequest =
      object.deleteRuleRequest !== undefined && object.deleteRuleRequest !== null
        ? DeleteRuleRequest.fromPartial(object.deleteRuleRequest)
        : undefined;
    message.ruleListRequest =
      object.ruleListRequest !== undefined && object.ruleListRequest !== null
        ? RuleListRequest.fromPartial(object.ruleListRequest)
        : undefined;
    message.ruleEventHistoryRequest =
      object.ruleEventHistoryRequest !== undefined && object.ruleEventHistoryRequest !== null
        ? RuleEventHistoryRequest.fromPartial(object.ruleEventHistoryRequest)
        : undefined;
    message.ruleEventSubscription =
      object.ruleEventSubscription !== undefined && object.ruleEventSubscription !== null
        ? RuleEventSubscription.fromPartial(object.ruleEventSubscription)
        : undefined;
    message.createOrModifyDestinationGroupRequest =
      object.createOrModifyDestinationGroupRequest !== undefined && object.createOrModifyDestinationGroupRequest !== null
        ? CreateOrModifyDestinationGroupRequest.fromPartial(object.createOrModifyDestinationGroupRequest)
        : undefined;
    message.destinationGroupListRequest =
      object.destinationGroupListRequest !== undefined && object.destinationGroupListRequest !== null
        ? DestinationGroupListRequest.fromPartial(object.destinationGroupListRequest)
        : undefined;
    message.updateDestinationExpirationTimeRequest =
      object.updateDestinationExpirationTimeRequest !== undefined && object.updateDestinationExpirationTimeRequest !== null
        ? UpdateDestinationExpirationTimeRequest.fromPartial(object.updateDestinationExpirationTimeRequest)
        : undefined;
    return message;
  },
};

function createBaseRuleResult(): RuleResult {
  return {
    requestId: '',
    resultCode: 0,
    details: undefined,
    setRuleResult: undefined,
    modifyRuleResult: undefined,
    deleteRuleResult: undefined,
    ruleListResult: undefined,
    ruleEventHistoryResult: undefined,
    ruleEventSubscriptionStatus: undefined,
    createOrModifyDestinationGroupResult: undefined,
    destinationGroupListResult: undefined,
    updateDestinationExpirationTimeResult: undefined,
  };
}

export const RuleResult = {
  encode(message: RuleResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== '') {
      writer.uint32(10).string(message.requestId);
    }
    if (message.resultCode !== 0) {
      writer.uint32(16).uint32(message.resultCode);
    }
    if (message.details !== undefined) {
      Text.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    if (message.setRuleResult !== undefined) {
      SetRuleResult.encode(message.setRuleResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.modifyRuleResult !== undefined) {
      ModifyRuleResult.encode(message.modifyRuleResult, writer.uint32(106).fork()).ldelim();
    }
    if (message.deleteRuleResult !== undefined) {
      DeleteRuleResult.encode(message.deleteRuleResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.ruleListResult !== undefined) {
      RuleListResult.encode(message.ruleListResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.ruleEventHistoryResult !== undefined) {
      RuleEventHistoryResult.encode(message.ruleEventHistoryResult, writer.uint32(58).fork()).ldelim();
    }
    if (message.ruleEventSubscriptionStatus !== undefined) {
      RuleEventSubscriptionStatus.encode(message.ruleEventSubscriptionStatus, writer.uint32(90).fork()).ldelim();
    }
    if (message.createOrModifyDestinationGroupResult !== undefined) {
      CreateOrModifyDestinationGroupResult.encode(
        message.createOrModifyDestinationGroupResult,
        writer.uint32(66).fork()
      ).ldelim();
    }
    if (message.destinationGroupListResult !== undefined) {
      DestinationGroupListResult.encode(message.destinationGroupListResult, writer.uint32(82).fork()).ldelim();
    }
    if (message.updateDestinationExpirationTimeResult !== undefined) {
      UpdateDestinationExpirationTimeResult.encode(
        message.updateDestinationExpirationTimeResult,
        writer.uint32(98).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.requestId = reader.string();
          break;
        case 2:
          message.resultCode = reader.uint32();
          break;
        case 3:
          message.details = Text.decode(reader, reader.uint32());
          break;
        case 4:
          message.setRuleResult = SetRuleResult.decode(reader, reader.uint32());
          break;
        case 13:
          message.modifyRuleResult = ModifyRuleResult.decode(reader, reader.uint32());
          break;
        case 5:
          message.deleteRuleResult = DeleteRuleResult.decode(reader, reader.uint32());
          break;
        case 6:
          message.ruleListResult = RuleListResult.decode(reader, reader.uint32());
          break;
        case 7:
          message.ruleEventHistoryResult = RuleEventHistoryResult.decode(reader, reader.uint32());
          break;
        case 11:
          message.ruleEventSubscriptionStatus = RuleEventSubscriptionStatus.decode(reader, reader.uint32());
          break;
        case 8:
          message.createOrModifyDestinationGroupResult = CreateOrModifyDestinationGroupResult.decode(reader, reader.uint32());
          break;
        case 10:
          message.destinationGroupListResult = DestinationGroupListResult.decode(reader, reader.uint32());
          break;
        case 12:
          message.updateDestinationExpirationTimeResult = UpdateDestinationExpirationTimeResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleResult {
    return {
      requestId: isSet(object.requestId) ? String(object.requestId) : '',
      resultCode: isSet(object.resultCode) ? Number(object.resultCode) : 0,
      details: isSet(object.details) ? Text.fromJSON(object.details) : undefined,
      setRuleResult: isSet(object.setRuleResult) ? SetRuleResult.fromJSON(object.setRuleResult) : undefined,
      modifyRuleResult: isSet(object.modifyRuleResult) ? ModifyRuleResult.fromJSON(object.modifyRuleResult) : undefined,
      deleteRuleResult: isSet(object.deleteRuleResult) ? DeleteRuleResult.fromJSON(object.deleteRuleResult) : undefined,
      ruleListResult: isSet(object.ruleListResult) ? RuleListResult.fromJSON(object.ruleListResult) : undefined,
      ruleEventHistoryResult: isSet(object.ruleEventHistoryResult)
        ? RuleEventHistoryResult.fromJSON(object.ruleEventHistoryResult)
        : undefined,
      ruleEventSubscriptionStatus: isSet(object.ruleEventSubscriptionStatus)
        ? RuleEventSubscriptionStatus.fromJSON(object.ruleEventSubscriptionStatus)
        : undefined,
      createOrModifyDestinationGroupResult: isSet(object.createOrModifyDestinationGroupResult)
        ? CreateOrModifyDestinationGroupResult.fromJSON(object.createOrModifyDestinationGroupResult)
        : undefined,
      destinationGroupListResult: isSet(object.destinationGroupListResult)
        ? DestinationGroupListResult.fromJSON(object.destinationGroupListResult)
        : undefined,
      updateDestinationExpirationTimeResult: isSet(object.updateDestinationExpirationTimeResult)
        ? UpdateDestinationExpirationTimeResult.fromJSON(object.updateDestinationExpirationTimeResult)
        : undefined,
    };
  },

  toJSON(message: RuleResult): unknown {
    const obj: any = {};
    message.requestId !== undefined && (obj.requestId = message.requestId);
    message.resultCode !== undefined && (obj.resultCode = Math.round(message.resultCode));
    message.details !== undefined && (obj.details = message.details ? Text.toJSON(message.details) : undefined);
    message.setRuleResult !== undefined &&
      (obj.setRuleResult = message.setRuleResult ? SetRuleResult.toJSON(message.setRuleResult) : undefined);
    message.modifyRuleResult !== undefined &&
      (obj.modifyRuleResult = message.modifyRuleResult ? ModifyRuleResult.toJSON(message.modifyRuleResult) : undefined);
    message.deleteRuleResult !== undefined &&
      (obj.deleteRuleResult = message.deleteRuleResult ? DeleteRuleResult.toJSON(message.deleteRuleResult) : undefined);
    message.ruleListResult !== undefined &&
      (obj.ruleListResult = message.ruleListResult ? RuleListResult.toJSON(message.ruleListResult) : undefined);
    message.ruleEventHistoryResult !== undefined &&
      (obj.ruleEventHistoryResult = message.ruleEventHistoryResult
        ? RuleEventHistoryResult.toJSON(message.ruleEventHistoryResult)
        : undefined);
    message.ruleEventSubscriptionStatus !== undefined &&
      (obj.ruleEventSubscriptionStatus = message.ruleEventSubscriptionStatus
        ? RuleEventSubscriptionStatus.toJSON(message.ruleEventSubscriptionStatus)
        : undefined);
    message.createOrModifyDestinationGroupResult !== undefined &&
      (obj.createOrModifyDestinationGroupResult = message.createOrModifyDestinationGroupResult
        ? CreateOrModifyDestinationGroupResult.toJSON(message.createOrModifyDestinationGroupResult)
        : undefined);
    message.destinationGroupListResult !== undefined &&
      (obj.destinationGroupListResult = message.destinationGroupListResult
        ? DestinationGroupListResult.toJSON(message.destinationGroupListResult)
        : undefined);
    message.updateDestinationExpirationTimeResult !== undefined &&
      (obj.updateDestinationExpirationTimeResult = message.updateDestinationExpirationTimeResult
        ? UpdateDestinationExpirationTimeResult.toJSON(message.updateDestinationExpirationTimeResult)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleResult>, I>>(base?: I): RuleResult {
    return RuleResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleResult>, I>>(object: I): RuleResult {
    const message = createBaseRuleResult();
    message.requestId = object.requestId ?? '';
    message.resultCode = object.resultCode ?? 0;
    message.details = object.details !== undefined && object.details !== null ? Text.fromPartial(object.details) : undefined;
    message.setRuleResult =
      object.setRuleResult !== undefined && object.setRuleResult !== null
        ? SetRuleResult.fromPartial(object.setRuleResult)
        : undefined;
    message.modifyRuleResult =
      object.modifyRuleResult !== undefined && object.modifyRuleResult !== null
        ? ModifyRuleResult.fromPartial(object.modifyRuleResult)
        : undefined;
    message.deleteRuleResult =
      object.deleteRuleResult !== undefined && object.deleteRuleResult !== null
        ? DeleteRuleResult.fromPartial(object.deleteRuleResult)
        : undefined;
    message.ruleListResult =
      object.ruleListResult !== undefined && object.ruleListResult !== null
        ? RuleListResult.fromPartial(object.ruleListResult)
        : undefined;
    message.ruleEventHistoryResult =
      object.ruleEventHistoryResult !== undefined && object.ruleEventHistoryResult !== null
        ? RuleEventHistoryResult.fromPartial(object.ruleEventHistoryResult)
        : undefined;
    message.ruleEventSubscriptionStatus =
      object.ruleEventSubscriptionStatus !== undefined && object.ruleEventSubscriptionStatus !== null
        ? RuleEventSubscriptionStatus.fromPartial(object.ruleEventSubscriptionStatus)
        : undefined;
    message.createOrModifyDestinationGroupResult =
      object.createOrModifyDestinationGroupResult !== undefined && object.createOrModifyDestinationGroupResult !== null
        ? CreateOrModifyDestinationGroupResult.fromPartial(object.createOrModifyDestinationGroupResult)
        : undefined;
    message.destinationGroupListResult =
      object.destinationGroupListResult !== undefined && object.destinationGroupListResult !== null
        ? DestinationGroupListResult.fromPartial(object.destinationGroupListResult)
        : undefined;
    message.updateDestinationExpirationTimeResult =
      object.updateDestinationExpirationTimeResult !== undefined && object.updateDestinationExpirationTimeResult !== null
        ? UpdateDestinationExpirationTimeResult.fromPartial(object.updateDestinationExpirationTimeResult)
        : undefined;
    return message;
  },
};

function createBaseRuleDefinition(): RuleDefinition {
  return {
    ruleId: '',
    ruleTags: [],
    actions: [],
    orderEventRule: undefined,
    conditionRule: undefined,
    enabled: false,
    attributes: [],
    deleted: false,
  };
}

export const RuleDefinition = {
  encode(message: RuleDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== '') {
      writer.uint32(10).string(message.ruleId);
    }
    for (const v of message.ruleTags) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.actions) {
      Action.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.orderEventRule !== undefined) {
      OrderEventRule.encode(message.orderEventRule, writer.uint32(42).fork()).ldelim();
    }
    if (message.conditionRule !== undefined) {
      ConditionRule.encode(message.conditionRule, writer.uint32(58).fork()).ldelim();
    }
    if (message.enabled === true) {
      writer.uint32(48).bool(message.enabled);
    }
    for (const v of message.attributes) {
      NamedValue.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.deleted === true) {
      writer.uint32(72).bool(message.deleted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleDefinition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleId = reader.string();
          break;
        case 2:
          message.ruleTags.push(reader.string());
          break;
        case 4:
          message.actions.push(Action.decode(reader, reader.uint32()));
          break;
        case 5:
          message.orderEventRule = OrderEventRule.decode(reader, reader.uint32());
          break;
        case 7:
          message.conditionRule = ConditionRule.decode(reader, reader.uint32());
          break;
        case 6:
          message.enabled = reader.bool();
          break;
        case 8:
          message.attributes.push(NamedValue.decode(reader, reader.uint32()));
          break;
        case 9:
          message.deleted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleDefinition {
    return {
      ruleId: isSet(object.ruleId) ? String(object.ruleId) : '',
      ruleTags: Array.isArray(object?.ruleTags) ? object.ruleTags.map((e: any) => String(e)) : [],
      actions: Array.isArray(object?.actions) ? object.actions.map((e: any) => Action.fromJSON(e)) : [],
      orderEventRule: isSet(object.orderEventRule) ? OrderEventRule.fromJSON(object.orderEventRule) : undefined,
      conditionRule: isSet(object.conditionRule) ? ConditionRule.fromJSON(object.conditionRule) : undefined,
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
      attributes: Array.isArray(object?.attributes) ? object.attributes.map((e: any) => NamedValue.fromJSON(e)) : [],
      deleted: isSet(object.deleted) ? Boolean(object.deleted) : false,
    };
  },

  toJSON(message: RuleDefinition): unknown {
    const obj: any = {};
    message.ruleId !== undefined && (obj.ruleId = message.ruleId);
    if (message.ruleTags) {
      obj.ruleTags = message.ruleTags.map((e) => e);
    } else {
      obj.ruleTags = [];
    }
    if (message.actions) {
      obj.actions = message.actions.map((e) => (e ? Action.toJSON(e) : undefined));
    } else {
      obj.actions = [];
    }
    message.orderEventRule !== undefined &&
      (obj.orderEventRule = message.orderEventRule ? OrderEventRule.toJSON(message.orderEventRule) : undefined);
    message.conditionRule !== undefined &&
      (obj.conditionRule = message.conditionRule ? ConditionRule.toJSON(message.conditionRule) : undefined);
    message.enabled !== undefined && (obj.enabled = message.enabled);
    if (message.attributes) {
      obj.attributes = message.attributes.map((e) => (e ? NamedValue.toJSON(e) : undefined));
    } else {
      obj.attributes = [];
    }
    message.deleted !== undefined && (obj.deleted = message.deleted);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleDefinition>, I>>(base?: I): RuleDefinition {
    return RuleDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleDefinition>, I>>(object: I): RuleDefinition {
    const message = createBaseRuleDefinition();
    message.ruleId = object.ruleId ?? '';
    message.ruleTags = object.ruleTags?.map((e) => e) || [];
    message.actions = object.actions?.map((e) => Action.fromPartial(e)) || [];
    message.orderEventRule =
      object.orderEventRule !== undefined && object.orderEventRule !== null
        ? OrderEventRule.fromPartial(object.orderEventRule)
        : undefined;
    message.conditionRule =
      object.conditionRule !== undefined && object.conditionRule !== null
        ? ConditionRule.fromPartial(object.conditionRule)
        : undefined;
    message.enabled = object.enabled ?? false;
    message.attributes = object.attributes?.map((e) => NamedValue.fromPartial(e)) || [];
    message.deleted = object.deleted ?? false;
    return message;
  },
};

function createBaseAction(): Action {
  return { destinations: [], destinationGroupId: '', goFlat: undefined };
}

export const Action = {
  encode(message: Action, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.destinations) {
      Destination.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.destinationGroupId !== '') {
      writer.uint32(42).string(message.destinationGroupId);
    }
    if (message.goFlat !== undefined) {
      GoFlatAction.encode(message.goFlat, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Action {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          message.destinations.push(Destination.decode(reader, reader.uint32()));
          break;
        case 5:
          message.destinationGroupId = reader.string();
          break;
        case 6:
          message.goFlat = GoFlatAction.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Action {
    return {
      destinations: Array.isArray(object?.destinations) ? object.destinations.map((e: any) => Destination.fromJSON(e)) : [],
      destinationGroupId: isSet(object.destinationGroupId) ? String(object.destinationGroupId) : '',
      goFlat: isSet(object.goFlat) ? GoFlatAction.fromJSON(object.goFlat) : undefined,
    };
  },

  toJSON(message: Action): unknown {
    const obj: any = {};
    if (message.destinations) {
      obj.destinations = message.destinations.map((e) => (e ? Destination.toJSON(e) : undefined));
    } else {
      obj.destinations = [];
    }
    message.destinationGroupId !== undefined && (obj.destinationGroupId = message.destinationGroupId);
    message.goFlat !== undefined && (obj.goFlat = message.goFlat ? GoFlatAction.toJSON(message.goFlat) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Action>, I>>(base?: I): Action {
    return Action.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Action>, I>>(object: I): Action {
    const message = createBaseAction();
    message.destinations = object.destinations?.map((e) => Destination.fromPartial(e)) || [];
    message.destinationGroupId = object.destinationGroupId ?? '';
    message.goFlat = object.goFlat !== undefined && object.goFlat !== null ? GoFlatAction.fromPartial(object.goFlat) : undefined;
    return message;
  },
};

function createBaseGoFlatAction(): GoFlatAction {
  return {};
}

export const GoFlatAction = {
  encode(_: GoFlatAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GoFlatAction {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGoFlatAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GoFlatAction {
    return {};
  },

  toJSON(_: GoFlatAction): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GoFlatAction>, I>>(base?: I): GoFlatAction {
    return GoFlatAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GoFlatAction>, I>>(_: I): GoFlatAction {
    const message = createBaseGoFlatAction();
    return message;
  },
};

function createBaseOrderEventRule(): OrderEventRule {
  return { accountIds: [], orderStatuses: [], transactionStatuses: [], filters: [] };
}

export const OrderEventRule = {
  encode(message: OrderEventRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.accountIds) {
      writer.sint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.orderStatuses) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.transactionStatuses) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.filters) {
      OrderEventFilter.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEventRule {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEventRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.accountIds.push(reader.sint32());
            }
          } else {
            message.accountIds.push(reader.sint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.orderStatuses.push(reader.uint32());
            }
          } else {
            message.orderStatuses.push(reader.uint32());
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.transactionStatuses.push(reader.uint32());
            }
          } else {
            message.transactionStatuses.push(reader.uint32());
          }
          break;
        case 4:
          message.filters.push(OrderEventFilter.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderEventRule {
    return {
      accountIds: Array.isArray(object?.accountIds) ? object.accountIds.map((e: any) => Number(e)) : [],
      orderStatuses: Array.isArray(object?.orderStatuses) ? object.orderStatuses.map((e: any) => Number(e)) : [],
      transactionStatuses: Array.isArray(object?.transactionStatuses)
        ? object.transactionStatuses.map((e: any) => Number(e))
        : [],
      filters: Array.isArray(object?.filters) ? object.filters.map((e: any) => OrderEventFilter.fromJSON(e)) : [],
    };
  },

  toJSON(message: OrderEventRule): unknown {
    const obj: any = {};
    if (message.accountIds) {
      obj.accountIds = message.accountIds.map((e) => Math.round(e));
    } else {
      obj.accountIds = [];
    }
    if (message.orderStatuses) {
      obj.orderStatuses = message.orderStatuses.map((e) => Math.round(e));
    } else {
      obj.orderStatuses = [];
    }
    if (message.transactionStatuses) {
      obj.transactionStatuses = message.transactionStatuses.map((e) => Math.round(e));
    } else {
      obj.transactionStatuses = [];
    }
    if (message.filters) {
      obj.filters = message.filters.map((e) => (e ? OrderEventFilter.toJSON(e) : undefined));
    } else {
      obj.filters = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEventRule>, I>>(base?: I): OrderEventRule {
    return OrderEventRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderEventRule>, I>>(object: I): OrderEventRule {
    const message = createBaseOrderEventRule();
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.orderStatuses = object.orderStatuses?.map((e) => e) || [];
    message.transactionStatuses = object.transactionStatuses?.map((e) => e) || [];
    message.filters = object.filters?.map((e) => OrderEventFilter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConditionRule(): ConditionRule {
  return {
    triggeringType: 0,
    expression: undefined,
    notificationTitle: undefined,
    notificationBody: undefined,
    suppressionPeriod: 0,
  };
}

export const ConditionRule = {
  encode(message: ConditionRule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.triggeringType !== 0) {
      writer.uint32(8).uint32(message.triggeringType);
    }
    if (message.expression !== undefined) {
      Expression.encode(message.expression, writer.uint32(18).fork()).ldelim();
    }
    if (message.notificationTitle !== undefined) {
      TemplateText.encode(message.notificationTitle, writer.uint32(26).fork()).ldelim();
    }
    if (message.notificationBody !== undefined) {
      TemplateText.encode(message.notificationBody, writer.uint32(34).fork()).ldelim();
    }
    if (message.suppressionPeriod !== 0) {
      writer.uint32(40).uint32(message.suppressionPeriod);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConditionRule {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConditionRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.triggeringType = reader.uint32();
          break;
        case 2:
          message.expression = Expression.decode(reader, reader.uint32());
          break;
        case 3:
          message.notificationTitle = TemplateText.decode(reader, reader.uint32());
          break;
        case 4:
          message.notificationBody = TemplateText.decode(reader, reader.uint32());
          break;
        case 5:
          message.suppressionPeriod = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConditionRule {
    return {
      triggeringType: isSet(object.triggeringType) ? Number(object.triggeringType) : 0,
      expression: isSet(object.expression) ? Expression.fromJSON(object.expression) : undefined,
      notificationTitle: isSet(object.notificationTitle) ? TemplateText.fromJSON(object.notificationTitle) : undefined,
      notificationBody: isSet(object.notificationBody) ? TemplateText.fromJSON(object.notificationBody) : undefined,
      suppressionPeriod: isSet(object.suppressionPeriod) ? Number(object.suppressionPeriod) : 0,
    };
  },

  toJSON(message: ConditionRule): unknown {
    const obj: any = {};
    message.triggeringType !== undefined && (obj.triggeringType = Math.round(message.triggeringType));
    message.expression !== undefined && (obj.expression = message.expression ? Expression.toJSON(message.expression) : undefined);
    message.notificationTitle !== undefined &&
      (obj.notificationTitle = message.notificationTitle ? TemplateText.toJSON(message.notificationTitle) : undefined);
    message.notificationBody !== undefined &&
      (obj.notificationBody = message.notificationBody ? TemplateText.toJSON(message.notificationBody) : undefined);
    message.suppressionPeriod !== undefined && (obj.suppressionPeriod = Math.round(message.suppressionPeriod));
    return obj;
  },

  create<I extends Exact<DeepPartial<ConditionRule>, I>>(base?: I): ConditionRule {
    return ConditionRule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConditionRule>, I>>(object: I): ConditionRule {
    const message = createBaseConditionRule();
    message.triggeringType = object.triggeringType ?? 0;
    message.expression =
      object.expression !== undefined && object.expression !== null ? Expression.fromPartial(object.expression) : undefined;
    message.notificationTitle =
      object.notificationTitle !== undefined && object.notificationTitle !== null
        ? TemplateText.fromPartial(object.notificationTitle)
        : undefined;
    message.notificationBody =
      object.notificationBody !== undefined && object.notificationBody !== null
        ? TemplateText.fromPartial(object.notificationBody)
        : undefined;
    message.suppressionPeriod = object.suppressionPeriod ?? 0;
    return message;
  },
};

function createBaseTemplateText(): TemplateText {
  return { text: '' };
}

export const TemplateText = {
  encode(message: TemplateText, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== '') {
      writer.uint32(10).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemplateText {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemplateText();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.text = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TemplateText {
    return { text: isSet(object.text) ? String(object.text) : '' };
  },

  toJSON(message: TemplateText): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<TemplateText>, I>>(base?: I): TemplateText {
    return TemplateText.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TemplateText>, I>>(object: I): TemplateText {
    const message = createBaseTemplateText();
    message.text = object.text ?? '';
    return message;
  },
};

function createBaseExpression(): Expression {
  return { operator: 0, leftOperand: undefined, rightOperand: undefined };
}

export const Expression = {
  encode(message: Expression, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operator !== 0) {
      writer.uint32(8).uint32(message.operator);
    }
    if (message.leftOperand !== undefined) {
      Operand.encode(message.leftOperand, writer.uint32(18).fork()).ldelim();
    }
    if (message.rightOperand !== undefined) {
      Operand.encode(message.rightOperand, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Expression {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpression();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.operator = reader.uint32();
          break;
        case 2:
          message.leftOperand = Operand.decode(reader, reader.uint32());
          break;
        case 3:
          message.rightOperand = Operand.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Expression {
    return {
      operator: isSet(object.operator) ? Number(object.operator) : 0,
      leftOperand: isSet(object.leftOperand) ? Operand.fromJSON(object.leftOperand) : undefined,
      rightOperand: isSet(object.rightOperand) ? Operand.fromJSON(object.rightOperand) : undefined,
    };
  },

  toJSON(message: Expression): unknown {
    const obj: any = {};
    message.operator !== undefined && (obj.operator = Math.round(message.operator));
    message.leftOperand !== undefined &&
      (obj.leftOperand = message.leftOperand ? Operand.toJSON(message.leftOperand) : undefined);
    message.rightOperand !== undefined &&
      (obj.rightOperand = message.rightOperand ? Operand.toJSON(message.rightOperand) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Expression>, I>>(base?: I): Expression {
    return Expression.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Expression>, I>>(object: I): Expression {
    const message = createBaseExpression();
    message.operator = object.operator ?? 0;
    message.leftOperand =
      object.leftOperand !== undefined && object.leftOperand !== null ? Operand.fromPartial(object.leftOperand) : undefined;
    message.rightOperand =
      object.rightOperand !== undefined && object.rightOperand !== null ? Operand.fromPartial(object.rightOperand) : undefined;
    return message;
  },
};

function createBaseOperand(): Operand {
  return { expression: undefined, constant: undefined, marketVariable: undefined, accountVariable: undefined };
}

export const Operand = {
  encode(message: Operand, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expression !== undefined) {
      Expression.encode(message.expression, writer.uint32(10).fork()).ldelim();
    }
    if (message.constant !== undefined) {
      Constant.encode(message.constant, writer.uint32(18).fork()).ldelim();
    }
    if (message.marketVariable !== undefined) {
      MarketVariable.encode(message.marketVariable, writer.uint32(26).fork()).ldelim();
    }
    if (message.accountVariable !== undefined) {
      AccountVariable.encode(message.accountVariable, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Operand {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperand();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.expression = Expression.decode(reader, reader.uint32());
          break;
        case 2:
          message.constant = Constant.decode(reader, reader.uint32());
          break;
        case 3:
          message.marketVariable = MarketVariable.decode(reader, reader.uint32());
          break;
        case 4:
          message.accountVariable = AccountVariable.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Operand {
    return {
      expression: isSet(object.expression) ? Expression.fromJSON(object.expression) : undefined,
      constant: isSet(object.constant) ? Constant.fromJSON(object.constant) : undefined,
      marketVariable: isSet(object.marketVariable) ? MarketVariable.fromJSON(object.marketVariable) : undefined,
      accountVariable: isSet(object.accountVariable) ? AccountVariable.fromJSON(object.accountVariable) : undefined,
    };
  },

  toJSON(message: Operand): unknown {
    const obj: any = {};
    message.expression !== undefined && (obj.expression = message.expression ? Expression.toJSON(message.expression) : undefined);
    message.constant !== undefined && (obj.constant = message.constant ? Constant.toJSON(message.constant) : undefined);
    message.marketVariable !== undefined &&
      (obj.marketVariable = message.marketVariable ? MarketVariable.toJSON(message.marketVariable) : undefined);
    message.accountVariable !== undefined &&
      (obj.accountVariable = message.accountVariable ? AccountVariable.toJSON(message.accountVariable) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Operand>, I>>(base?: I): Operand {
    return Operand.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Operand>, I>>(object: I): Operand {
    const message = createBaseOperand();
    message.expression =
      object.expression !== undefined && object.expression !== null ? Expression.fromPartial(object.expression) : undefined;
    message.constant =
      object.constant !== undefined && object.constant !== null ? Constant.fromPartial(object.constant) : undefined;
    message.marketVariable =
      object.marketVariable !== undefined && object.marketVariable !== null
        ? MarketVariable.fromPartial(object.marketVariable)
        : undefined;
    message.accountVariable =
      object.accountVariable !== undefined && object.accountVariable !== null
        ? AccountVariable.fromPartial(object.accountVariable)
        : undefined;
    return message;
  },
};

function createBaseConstant(): Constant {
  return { doubleValue: 0 };
}

export const Constant = {
  encode(message: Constant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.doubleValue !== 0) {
      writer.uint32(9).double(message.doubleValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Constant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConstant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.doubleValue = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Constant {
    return { doubleValue: isSet(object.doubleValue) ? Number(object.doubleValue) : 0 };
  },

  toJSON(message: Constant): unknown {
    const obj: any = {};
    message.doubleValue !== undefined && (obj.doubleValue = message.doubleValue);
    return obj;
  },

  create<I extends Exact<DeepPartial<Constant>, I>>(base?: I): Constant {
    return Constant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Constant>, I>>(object: I): Constant {
    const message = createBaseConstant();
    message.doubleValue = object.doubleValue ?? 0;
    return message;
  },
};

function createBaseMarketVariable(): MarketVariable {
  return { symbol: '', type: 0 };
}

export const MarketVariable = {
  encode(message: MarketVariable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbol !== '') {
      writer.uint32(10).string(message.symbol);
    }
    if (message.type !== 0) {
      writer.uint32(16).uint32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketVariable {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketVariable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.symbol = reader.string();
          break;
        case 2:
          message.type = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MarketVariable {
    return {
      symbol: isSet(object.symbol) ? String(object.symbol) : '',
      type: isSet(object.type) ? Number(object.type) : 0,
    };
  },

  toJSON(message: MarketVariable): unknown {
    const obj: any = {};
    message.symbol !== undefined && (obj.symbol = message.symbol);
    message.type !== undefined && (obj.type = Math.round(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketVariable>, I>>(base?: I): MarketVariable {
    return MarketVariable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MarketVariable>, I>>(object: I): MarketVariable {
    const message = createBaseMarketVariable();
    message.symbol = object.symbol ?? '';
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseAccountVariable(): AccountVariable {
  return { type: 0 };
}

export const AccountVariable = {
  encode(message: AccountVariable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountVariable {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountVariable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountVariable {
    return { type: isSet(object.type) ? Number(object.type) : 0 };
  },

  toJSON(message: AccountVariable): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = Math.round(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountVariable>, I>>(base?: I): AccountVariable {
    return AccountVariable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountVariable>, I>>(object: I): AccountVariable {
    const message = createBaseAccountVariable();
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseRuleEvent(): RuleEvent {
  return {
    title: undefined,
    body: undefined,
    ruleId: '',
    notificationProperties: [],
    whenUtcTimestamp: undefined,
    errorDetails: undefined,
    ruleTags: [],
  };
}

export const RuleEvent = {
  encode(message: RuleEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== undefined) {
      Text.encode(message.title, writer.uint32(10).fork()).ldelim();
    }
    if (message.body !== undefined) {
      Text.encode(message.body, writer.uint32(18).fork()).ldelim();
    }
    if (message.ruleId !== '') {
      writer.uint32(26).string(message.ruleId);
    }
    for (const v of message.notificationProperties) {
      NotificationProperty.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.whenUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.whenUtcTimestamp), writer.uint32(42).fork()).ldelim();
    }
    if (message.errorDetails !== undefined) {
      Text.encode(message.errorDetails, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.ruleTags) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.title = Text.decode(reader, reader.uint32());
          break;
        case 2:
          message.body = Text.decode(reader, reader.uint32());
          break;
        case 3:
          message.ruleId = reader.string();
          break;
        case 4:
          message.notificationProperties.push(NotificationProperty.decode(reader, reader.uint32()));
          break;
        case 5:
          message.whenUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.errorDetails = Text.decode(reader, reader.uint32());
          break;
        case 7:
          message.ruleTags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleEvent {
    return {
      title: isSet(object.title) ? Text.fromJSON(object.title) : undefined,
      body: isSet(object.body) ? Text.fromJSON(object.body) : undefined,
      ruleId: isSet(object.ruleId) ? String(object.ruleId) : '',
      notificationProperties: Array.isArray(object?.notificationProperties)
        ? object.notificationProperties.map((e: any) => NotificationProperty.fromJSON(e))
        : [],
      whenUtcTimestamp: isSet(object.whenUtcTimestamp) ? fromJsonTimestamp(object.whenUtcTimestamp) : undefined,
      errorDetails: isSet(object.errorDetails) ? Text.fromJSON(object.errorDetails) : undefined,
      ruleTags: Array.isArray(object?.ruleTags) ? object.ruleTags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: RuleEvent): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? Text.toJSON(message.title) : undefined);
    message.body !== undefined && (obj.body = message.body ? Text.toJSON(message.body) : undefined);
    message.ruleId !== undefined && (obj.ruleId = message.ruleId);
    if (message.notificationProperties) {
      obj.notificationProperties = message.notificationProperties.map((e) => (e ? NotificationProperty.toJSON(e) : undefined));
    } else {
      obj.notificationProperties = [];
    }
    message.whenUtcTimestamp !== undefined && (obj.whenUtcTimestamp = message.whenUtcTimestamp.toISOString());
    message.errorDetails !== undefined &&
      (obj.errorDetails = message.errorDetails ? Text.toJSON(message.errorDetails) : undefined);
    if (message.ruleTags) {
      obj.ruleTags = message.ruleTags.map((e) => e);
    } else {
      obj.ruleTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleEvent>, I>>(base?: I): RuleEvent {
    return RuleEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleEvent>, I>>(object: I): RuleEvent {
    const message = createBaseRuleEvent();
    message.title = object.title !== undefined && object.title !== null ? Text.fromPartial(object.title) : undefined;
    message.body = object.body !== undefined && object.body !== null ? Text.fromPartial(object.body) : undefined;
    message.ruleId = object.ruleId ?? '';
    message.notificationProperties = object.notificationProperties?.map((e) => NotificationProperty.fromPartial(e)) || [];
    message.whenUtcTimestamp = object.whenUtcTimestamp ?? undefined;
    message.errorDetails =
      object.errorDetails !== undefined && object.errorDetails !== null ? Text.fromPartial(object.errorDetails) : undefined;
    message.ruleTags = object.ruleTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrderEventFilter(): OrderEventFilter {
  return { filterType: 0, value: '' };
}

export const OrderEventFilter = {
  encode(message: OrderEventFilter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filterType !== 0) {
      writer.uint32(8).uint32(message.filterType);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderEventFilter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderEventFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.filterType = reader.uint32();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderEventFilter {
    return {
      filterType: isSet(object.filterType) ? Number(object.filterType) : 0,
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: OrderEventFilter): unknown {
    const obj: any = {};
    message.filterType !== undefined && (obj.filterType = Math.round(message.filterType));
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderEventFilter>, I>>(base?: I): OrderEventFilter {
    return OrderEventFilter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderEventFilter>, I>>(object: I): OrderEventFilter {
    const message = createBaseOrderEventFilter();
    message.filterType = object.filterType ?? 0;
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseSetRuleRequest(): SetRuleRequest {
  return { ruleDefinition: undefined };
}

export const SetRuleRequest = {
  encode(message: SetRuleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleDefinition !== undefined) {
      RuleDefinition.encode(message.ruleDefinition, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRuleRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetRuleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleDefinition = RuleDefinition.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetRuleRequest {
    return {
      ruleDefinition: isSet(object.ruleDefinition) ? RuleDefinition.fromJSON(object.ruleDefinition) : undefined,
    };
  },

  toJSON(message: SetRuleRequest): unknown {
    const obj: any = {};
    message.ruleDefinition !== undefined &&
      (obj.ruleDefinition = message.ruleDefinition ? RuleDefinition.toJSON(message.ruleDefinition) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SetRuleRequest>, I>>(base?: I): SetRuleRequest {
    return SetRuleRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetRuleRequest>, I>>(object: I): SetRuleRequest {
    const message = createBaseSetRuleRequest();
    message.ruleDefinition =
      object.ruleDefinition !== undefined && object.ruleDefinition !== null
        ? RuleDefinition.fromPartial(object.ruleDefinition)
        : undefined;
    return message;
  },
};

function createBaseSetRuleResult(): SetRuleResult {
  return {};
}

export const SetRuleResult = {
  encode(_: SetRuleResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRuleResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetRuleResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetRuleResult {
    return {};
  },

  toJSON(_: SetRuleResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetRuleResult>, I>>(base?: I): SetRuleResult {
    return SetRuleResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetRuleResult>, I>>(_: I): SetRuleResult {
    const message = createBaseSetRuleResult();
    return message;
  },
};

function createBaseModifyRuleRequest(): ModifyRuleRequest {
  return { ruleId: '', actions: [], ruleRevision: 0 };
}

export const ModifyRuleRequest = {
  encode(message: ModifyRuleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== '') {
      writer.uint32(10).string(message.ruleId);
    }
    for (const v of message.actions) {
      Action.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.ruleRevision !== 0) {
      writer.uint32(24).uint64(message.ruleRevision);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyRuleRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyRuleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleId = reader.string();
          break;
        case 2:
          message.actions.push(Action.decode(reader, reader.uint32()));
          break;
        case 3:
          message.ruleRevision = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ModifyRuleRequest {
    return {
      ruleId: isSet(object.ruleId) ? String(object.ruleId) : '',
      actions: Array.isArray(object?.actions) ? object.actions.map((e: any) => Action.fromJSON(e)) : [],
      ruleRevision: isSet(object.ruleRevision) ? Number(object.ruleRevision) : 0,
    };
  },

  toJSON(message: ModifyRuleRequest): unknown {
    const obj: any = {};
    message.ruleId !== undefined && (obj.ruleId = message.ruleId);
    if (message.actions) {
      obj.actions = message.actions.map((e) => (e ? Action.toJSON(e) : undefined));
    } else {
      obj.actions = [];
    }
    message.ruleRevision !== undefined && (obj.ruleRevision = Math.round(message.ruleRevision));
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyRuleRequest>, I>>(base?: I): ModifyRuleRequest {
    return ModifyRuleRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ModifyRuleRequest>, I>>(object: I): ModifyRuleRequest {
    const message = createBaseModifyRuleRequest();
    message.ruleId = object.ruleId ?? '';
    message.actions = object.actions?.map((e) => Action.fromPartial(e)) || [];
    message.ruleRevision = object.ruleRevision ?? 0;
    return message;
  },
};

function createBaseModifyRuleResult(): ModifyRuleResult {
  return {};
}

export const ModifyRuleResult = {
  encode(_: ModifyRuleResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModifyRuleResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModifyRuleResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ModifyRuleResult {
    return {};
  },

  toJSON(_: ModifyRuleResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ModifyRuleResult>, I>>(base?: I): ModifyRuleResult {
    return ModifyRuleResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ModifyRuleResult>, I>>(_: I): ModifyRuleResult {
    const message = createBaseModifyRuleResult();
    return message;
  },
};

function createBaseDeleteRuleRequest(): DeleteRuleRequest {
  return { ruleId: '', ruleRevision: 0 };
}

export const DeleteRuleRequest = {
  encode(message: DeleteRuleRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ruleId !== '') {
      writer.uint32(10).string(message.ruleId);
    }
    if (message.ruleRevision !== 0) {
      writer.uint32(16).uint64(message.ruleRevision);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteRuleRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRuleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleId = reader.string();
          break;
        case 2:
          message.ruleRevision = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteRuleRequest {
    return {
      ruleId: isSet(object.ruleId) ? String(object.ruleId) : '',
      ruleRevision: isSet(object.ruleRevision) ? Number(object.ruleRevision) : 0,
    };
  },

  toJSON(message: DeleteRuleRequest): unknown {
    const obj: any = {};
    message.ruleId !== undefined && (obj.ruleId = message.ruleId);
    message.ruleRevision !== undefined && (obj.ruleRevision = Math.round(message.ruleRevision));
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteRuleRequest>, I>>(base?: I): DeleteRuleRequest {
    return DeleteRuleRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeleteRuleRequest>, I>>(object: I): DeleteRuleRequest {
    const message = createBaseDeleteRuleRequest();
    message.ruleId = object.ruleId ?? '';
    message.ruleRevision = object.ruleRevision ?? 0;
    return message;
  },
};

function createBaseDeleteRuleResult(): DeleteRuleResult {
  return {};
}

export const DeleteRuleResult = {
  encode(_: DeleteRuleResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteRuleResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRuleResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteRuleResult {
    return {};
  },

  toJSON(_: DeleteRuleResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteRuleResult>, I>>(base?: I): DeleteRuleResult {
    return DeleteRuleResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeleteRuleResult>, I>>(_: I): DeleteRuleResult {
    const message = createBaseDeleteRuleResult();
    return message;
  },
};

function createBaseRuleListRequest(): RuleListRequest {
  return {};
}

export const RuleListRequest = {
  encode(_: RuleListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): RuleListRequest {
    return {};
  },

  toJSON(_: RuleListRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleListRequest>, I>>(base?: I): RuleListRequest {
    return RuleListRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleListRequest>, I>>(_: I): RuleListRequest {
    const message = createBaseRuleListRequest();
    return message;
  },
};

function createBaseRuleListResult(): RuleListResult {
  return { ruleDefinitions: [], isSnapshot: false };
}

export const RuleListResult = {
  encode(message: RuleListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ruleDefinitions) {
      RuleDefinition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isSnapshot === true) {
      writer.uint32(16).bool(message.isSnapshot);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleListResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleDefinitions.push(RuleDefinition.decode(reader, reader.uint32()));
          break;
        case 2:
          message.isSnapshot = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleListResult {
    return {
      ruleDefinitions: Array.isArray(object?.ruleDefinitions)
        ? object.ruleDefinitions.map((e: any) => RuleDefinition.fromJSON(e))
        : [],
      isSnapshot: isSet(object.isSnapshot) ? Boolean(object.isSnapshot) : false,
    };
  },

  toJSON(message: RuleListResult): unknown {
    const obj: any = {};
    if (message.ruleDefinitions) {
      obj.ruleDefinitions = message.ruleDefinitions.map((e) => (e ? RuleDefinition.toJSON(e) : undefined));
    } else {
      obj.ruleDefinitions = [];
    }
    message.isSnapshot !== undefined && (obj.isSnapshot = message.isSnapshot);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleListResult>, I>>(base?: I): RuleListResult {
    return RuleListResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleListResult>, I>>(object: I): RuleListResult {
    const message = createBaseRuleListResult();
    message.ruleDefinitions = object.ruleDefinitions?.map((e) => RuleDefinition.fromPartial(e)) || [];
    message.isSnapshot = object.isSnapshot ?? false;
    return message;
  },
};

function createBaseRuleEventHistoryRequest(): RuleEventHistoryRequest {
  return { fromUtcTimestamp: undefined, toUtcTimestamp: undefined, ruleTags: [] };
}

export const RuleEventHistoryRequest = {
  encode(message: RuleEventHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.fromUtcTimestamp), writer.uint32(10).fork()).ldelim();
    }
    if (message.toUtcTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.toUtcTimestamp), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.ruleTags) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEventHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEventHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fromUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.toUtcTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.ruleTags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleEventHistoryRequest {
    return {
      fromUtcTimestamp: isSet(object.fromUtcTimestamp) ? fromJsonTimestamp(object.fromUtcTimestamp) : undefined,
      toUtcTimestamp: isSet(object.toUtcTimestamp) ? fromJsonTimestamp(object.toUtcTimestamp) : undefined,
      ruleTags: Array.isArray(object?.ruleTags) ? object.ruleTags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: RuleEventHistoryRequest): unknown {
    const obj: any = {};
    message.fromUtcTimestamp !== undefined && (obj.fromUtcTimestamp = message.fromUtcTimestamp.toISOString());
    message.toUtcTimestamp !== undefined && (obj.toUtcTimestamp = message.toUtcTimestamp.toISOString());
    if (message.ruleTags) {
      obj.ruleTags = message.ruleTags.map((e) => e);
    } else {
      obj.ruleTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleEventHistoryRequest>, I>>(base?: I): RuleEventHistoryRequest {
    return RuleEventHistoryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleEventHistoryRequest>, I>>(object: I): RuleEventHistoryRequest {
    const message = createBaseRuleEventHistoryRequest();
    message.fromUtcTimestamp = object.fromUtcTimestamp ?? undefined;
    message.toUtcTimestamp = object.toUtcTimestamp ?? undefined;
    message.ruleTags = object.ruleTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseRuleEventHistoryResult(): RuleEventHistoryResult {
  return { ruleEvents: [], complete: false };
}

export const RuleEventHistoryResult = {
  encode(message: RuleEventHistoryResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ruleEvents) {
      RuleEvent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.complete === true) {
      writer.uint32(16).bool(message.complete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEventHistoryResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEventHistoryResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleEvents.push(RuleEvent.decode(reader, reader.uint32()));
          break;
        case 2:
          message.complete = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleEventHistoryResult {
    return {
      ruleEvents: Array.isArray(object?.ruleEvents) ? object.ruleEvents.map((e: any) => RuleEvent.fromJSON(e)) : [],
      complete: isSet(object.complete) ? Boolean(object.complete) : false,
    };
  },

  toJSON(message: RuleEventHistoryResult): unknown {
    const obj: any = {};
    if (message.ruleEvents) {
      obj.ruleEvents = message.ruleEvents.map((e) => (e ? RuleEvent.toJSON(e) : undefined));
    } else {
      obj.ruleEvents = [];
    }
    message.complete !== undefined && (obj.complete = message.complete);
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleEventHistoryResult>, I>>(base?: I): RuleEventHistoryResult {
    return RuleEventHistoryResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleEventHistoryResult>, I>>(object: I): RuleEventHistoryResult {
    const message = createBaseRuleEventHistoryResult();
    message.ruleEvents = object.ruleEvents?.map((e) => RuleEvent.fromPartial(e)) || [];
    message.complete = object.complete ?? false;
    return message;
  },
};

function createBaseRuleEventSubscription(): RuleEventSubscription {
  return { ruleTags: [] };
}

export const RuleEventSubscription = {
  encode(message: RuleEventSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ruleTags) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEventSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEventSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleTags.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleEventSubscription {
    return { ruleTags: Array.isArray(object?.ruleTags) ? object.ruleTags.map((e: any) => String(e)) : [] };
  },

  toJSON(message: RuleEventSubscription): unknown {
    const obj: any = {};
    if (message.ruleTags) {
      obj.ruleTags = message.ruleTags.map((e) => e);
    } else {
      obj.ruleTags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleEventSubscription>, I>>(base?: I): RuleEventSubscription {
    return RuleEventSubscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleEventSubscription>, I>>(object: I): RuleEventSubscription {
    const message = createBaseRuleEventSubscription();
    message.ruleTags = object.ruleTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseRuleEventSubscriptionStatus(): RuleEventSubscriptionStatus {
  return { ruleEvents: [] };
}

export const RuleEventSubscriptionStatus = {
  encode(message: RuleEventSubscriptionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ruleEvents) {
      RuleEvent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEventSubscriptionStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEventSubscriptionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ruleEvents.push(RuleEvent.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RuleEventSubscriptionStatus {
    return {
      ruleEvents: Array.isArray(object?.ruleEvents) ? object.ruleEvents.map((e: any) => RuleEvent.fromJSON(e)) : [],
    };
  },

  toJSON(message: RuleEventSubscriptionStatus): unknown {
    const obj: any = {};
    if (message.ruleEvents) {
      obj.ruleEvents = message.ruleEvents.map((e) => (e ? RuleEvent.toJSON(e) : undefined));
    } else {
      obj.ruleEvents = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuleEventSubscriptionStatus>, I>>(base?: I): RuleEventSubscriptionStatus {
    return RuleEventSubscriptionStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RuleEventSubscriptionStatus>, I>>(object: I): RuleEventSubscriptionStatus {
    const message = createBaseRuleEventSubscriptionStatus();
    message.ruleEvents = object.ruleEvents?.map((e) => RuleEvent.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrModifyDestinationGroupRequest(): CreateOrModifyDestinationGroupRequest {
  return { destinationGroupId: '', destinationToRemove: undefined, destinationToAddOrUpdate: undefined };
}

export const CreateOrModifyDestinationGroupRequest = {
  encode(message: CreateOrModifyDestinationGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.destinationGroupId !== '') {
      writer.uint32(10).string(message.destinationGroupId);
    }
    if (message.destinationToRemove !== undefined) {
      Destination.encode(message.destinationToRemove, writer.uint32(18).fork()).ldelim();
    }
    if (message.destinationToAddOrUpdate !== undefined) {
      Destination.encode(message.destinationToAddOrUpdate, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrModifyDestinationGroupRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrModifyDestinationGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.destinationGroupId = reader.string();
          break;
        case 2:
          message.destinationToRemove = Destination.decode(reader, reader.uint32());
          break;
        case 3:
          message.destinationToAddOrUpdate = Destination.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrModifyDestinationGroupRequest {
    return {
      destinationGroupId: isSet(object.destinationGroupId) ? String(object.destinationGroupId) : '',
      destinationToRemove: isSet(object.destinationToRemove) ? Destination.fromJSON(object.destinationToRemove) : undefined,
      destinationToAddOrUpdate: isSet(object.destinationToAddOrUpdate)
        ? Destination.fromJSON(object.destinationToAddOrUpdate)
        : undefined,
    };
  },

  toJSON(message: CreateOrModifyDestinationGroupRequest): unknown {
    const obj: any = {};
    message.destinationGroupId !== undefined && (obj.destinationGroupId = message.destinationGroupId);
    message.destinationToRemove !== undefined &&
      (obj.destinationToRemove = message.destinationToRemove ? Destination.toJSON(message.destinationToRemove) : undefined);
    message.destinationToAddOrUpdate !== undefined &&
      (obj.destinationToAddOrUpdate = message.destinationToAddOrUpdate
        ? Destination.toJSON(message.destinationToAddOrUpdate)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrModifyDestinationGroupRequest>, I>>(
    base?: I
  ): CreateOrModifyDestinationGroupRequest {
    return CreateOrModifyDestinationGroupRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrModifyDestinationGroupRequest>, I>>(
    object: I
  ): CreateOrModifyDestinationGroupRequest {
    const message = createBaseCreateOrModifyDestinationGroupRequest();
    message.destinationGroupId = object.destinationGroupId ?? '';
    message.destinationToRemove =
      object.destinationToRemove !== undefined && object.destinationToRemove !== null
        ? Destination.fromPartial(object.destinationToRemove)
        : undefined;
    message.destinationToAddOrUpdate =
      object.destinationToAddOrUpdate !== undefined && object.destinationToAddOrUpdate !== null
        ? Destination.fromPartial(object.destinationToAddOrUpdate)
        : undefined;
    return message;
  },
};

function createBaseCreateOrModifyDestinationGroupResult(): CreateOrModifyDestinationGroupResult {
  return {};
}

export const CreateOrModifyDestinationGroupResult = {
  encode(_: CreateOrModifyDestinationGroupResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrModifyDestinationGroupResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrModifyDestinationGroupResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CreateOrModifyDestinationGroupResult {
    return {};
  },

  toJSON(_: CreateOrModifyDestinationGroupResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrModifyDestinationGroupResult>, I>>(base?: I): CreateOrModifyDestinationGroupResult {
    return CreateOrModifyDestinationGroupResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrModifyDestinationGroupResult>, I>>(_: I): CreateOrModifyDestinationGroupResult {
    const message = createBaseCreateOrModifyDestinationGroupResult();
    return message;
  },
};

function createBaseDestinationGroupListRequest(): DestinationGroupListRequest {
  return {};
}

export const DestinationGroupListRequest = {
  encode(_: DestinationGroupListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestinationGroupListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestinationGroupListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DestinationGroupListRequest {
    return {};
  },

  toJSON(_: DestinationGroupListRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DestinationGroupListRequest>, I>>(base?: I): DestinationGroupListRequest {
    return DestinationGroupListRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DestinationGroupListRequest>, I>>(_: I): DestinationGroupListRequest {
    const message = createBaseDestinationGroupListRequest();
    return message;
  },
};

function createBaseDestinationGroupListResult(): DestinationGroupListResult {
  return { destinationGroups: [] };
}

export const DestinationGroupListResult = {
  encode(message: DestinationGroupListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.destinationGroups) {
      DestinationGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestinationGroupListResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestinationGroupListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.destinationGroups.push(DestinationGroup.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestinationGroupListResult {
    return {
      destinationGroups: Array.isArray(object?.destinationGroups)
        ? object.destinationGroups.map((e: any) => DestinationGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DestinationGroupListResult): unknown {
    const obj: any = {};
    if (message.destinationGroups) {
      obj.destinationGroups = message.destinationGroups.map((e) => (e ? DestinationGroup.toJSON(e) : undefined));
    } else {
      obj.destinationGroups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DestinationGroupListResult>, I>>(base?: I): DestinationGroupListResult {
    return DestinationGroupListResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DestinationGroupListResult>, I>>(object: I): DestinationGroupListResult {
    const message = createBaseDestinationGroupListResult();
    message.destinationGroups = object.destinationGroups?.map((e) => DestinationGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDestinationGroup(): DestinationGroup {
  return { destinationGroupId: '', destinations: [] };
}

export const DestinationGroup = {
  encode(message: DestinationGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.destinationGroupId !== '') {
      writer.uint32(10).string(message.destinationGroupId);
    }
    for (const v of message.destinations) {
      Destination.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestinationGroup {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestinationGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.destinationGroupId = reader.string();
          break;
        case 2:
          message.destinations.push(Destination.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestinationGroup {
    return {
      destinationGroupId: isSet(object.destinationGroupId) ? String(object.destinationGroupId) : '',
      destinations: Array.isArray(object?.destinations) ? object.destinations.map((e: any) => Destination.fromJSON(e)) : [],
    };
  },

  toJSON(message: DestinationGroup): unknown {
    const obj: any = {};
    message.destinationGroupId !== undefined && (obj.destinationGroupId = message.destinationGroupId);
    if (message.destinations) {
      obj.destinations = message.destinations.map((e) => (e ? Destination.toJSON(e) : undefined));
    } else {
      obj.destinations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DestinationGroup>, I>>(base?: I): DestinationGroup {
    return DestinationGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DestinationGroup>, I>>(object: I): DestinationGroup {
    const message = createBaseDestinationGroup();
    message.destinationGroupId = object.destinationGroupId ?? '';
    message.destinations = object.destinations?.map((e) => Destination.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateDestinationExpirationTimeRequest(): UpdateDestinationExpirationTimeRequest {
  return { applePushNotif: undefined, googlePushNotif: undefined };
}

export const UpdateDestinationExpirationTimeRequest = {
  encode(message: UpdateDestinationExpirationTimeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applePushNotif !== undefined) {
      ApplePushNotif.encode(message.applePushNotif, writer.uint32(10).fork()).ldelim();
    }
    if (message.googlePushNotif !== undefined) {
      GooglePushNotif.encode(message.googlePushNotif, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDestinationExpirationTimeRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDestinationExpirationTimeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.applePushNotif = ApplePushNotif.decode(reader, reader.uint32());
          break;
        case 2:
          message.googlePushNotif = GooglePushNotif.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateDestinationExpirationTimeRequest {
    return {
      applePushNotif: isSet(object.applePushNotif) ? ApplePushNotif.fromJSON(object.applePushNotif) : undefined,
      googlePushNotif: isSet(object.googlePushNotif) ? GooglePushNotif.fromJSON(object.googlePushNotif) : undefined,
    };
  },

  toJSON(message: UpdateDestinationExpirationTimeRequest): unknown {
    const obj: any = {};
    message.applePushNotif !== undefined &&
      (obj.applePushNotif = message.applePushNotif ? ApplePushNotif.toJSON(message.applePushNotif) : undefined);
    message.googlePushNotif !== undefined &&
      (obj.googlePushNotif = message.googlePushNotif ? GooglePushNotif.toJSON(message.googlePushNotif) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateDestinationExpirationTimeRequest>, I>>(
    base?: I
  ): UpdateDestinationExpirationTimeRequest {
    return UpdateDestinationExpirationTimeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDestinationExpirationTimeRequest>, I>>(
    object: I
  ): UpdateDestinationExpirationTimeRequest {
    const message = createBaseUpdateDestinationExpirationTimeRequest();
    message.applePushNotif =
      object.applePushNotif !== undefined && object.applePushNotif !== null
        ? ApplePushNotif.fromPartial(object.applePushNotif)
        : undefined;
    message.googlePushNotif =
      object.googlePushNotif !== undefined && object.googlePushNotif !== null
        ? GooglePushNotif.fromPartial(object.googlePushNotif)
        : undefined;
    return message;
  },
};

function createBaseUpdateDestinationExpirationTimeResult(): UpdateDestinationExpirationTimeResult {
  return {};
}

export const UpdateDestinationExpirationTimeResult = {
  encode(_: UpdateDestinationExpirationTimeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDestinationExpirationTimeResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDestinationExpirationTimeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UpdateDestinationExpirationTimeResult {
    return {};
  },

  toJSON(_: UpdateDestinationExpirationTimeResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateDestinationExpirationTimeResult>, I>>(
    base?: I
  ): UpdateDestinationExpirationTimeResult {
    return UpdateDestinationExpirationTimeResult.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDestinationExpirationTimeResult>, I>>(
    _: I
  ): UpdateDestinationExpirationTimeResult {
    const message = createBaseUpdateDestinationExpirationTimeResult();
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
