import { sortBy } from 'lodash';
import { SelectorTypes } from '../components/common/SelectWithLabel/SelectWithLabel';

export const prepareOptionsForSelect = (data: any, noneItem: { value: any; label: any } | null = null): SelectorTypes<any>[] => {
  const result = [];

  if (noneItem) {
    result.push(noneItem);
  }

  for (const key in data) {
    result.push({ value: parseInt(key), label: data[key] });
  }
  return result;
};

export function buildYears(availableCropYears: string[] = [], futureYears = 3, pastYears = 5) {
  const yearsList = new Set(availableCropYears);
  const currentYear = new Date().getFullYear();
  const start = currentYear - pastYears;
  const end = currentYear + futureYears;
  for (let i = start; i <= end; i++) {
    yearsList.add(i.toString());
  }
  return Array.from(yearsList);
}

export const buildSelectOptions = (arr: string[]): SelectorTypes[] => {
  return arr.map((value) => {
    return { value: value, label: value };
  });
};

export const prepareYearsListForSelect = (years: string[]): SelectorTypes[] => {
  return buildSelectOptions(sortBy(years).reverse());
};

export function getSelectedByDefaultYear(yearList: string[]): string {
  const currentYear = new Date().getFullYear().toString();
  let result = '';

  if (yearList.includes(currentYear)) {
    result = currentYear;
  } else {
    result = Math.max(...yearList.map((year) => parseInt(year))).toString();
  }

  return result;
}
