/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import {
  AccountLogoff,
  AccountLogoffResult,
  AccountLogon,
  AccountLogonResult,
  AccountLogonRoutineClient,
  AccountLogonRoutineServer,
  AccountPasswordChange,
  AccountPasswordChangeResult,
  AccountPasswordChangeRoutineClient,
  AccountPasswordChangeRoutineServer,
} from './account_authorization_2';
import { ApiLimitReport, ApiLimitRequest } from './api_limit_2';
import {
  NonTimedBarReport,
  NonTimedBarRequest,
  TimeAndSalesReport,
  TimeAndSalesRequest,
  TimeBarReport,
  TimeBarRequest,
  VolumeProfileReport,
  VolumeProfileRequest,
} from './historical_2';
import { InstrumentDefinitionReport, InstrumentDefinitionRequest } from './instrument_definition_2';
import {
  AtTheMoneyStrikeReport,
  AtTheMoneyStrikeRequest,
  MarketDataSubscription,
  MarketDataSubscriptionStatus,
  OptionCalculationReport,
  OptionCalculationRequest,
  RealTimeMarketData,
  RFQReport,
  RFQRequest,
} from './market_data_2';
import {
  ContractMetadataReport,
  ContractMetadataRequest,
  ContributorMetadataReport,
  ContributorMetadataRequest,
  ExchangeMetadataReport,
  ExchangeMetadataRequest,
  ExchangeSecuritiesReport,
  ExchangeSecuritiesRequest,
  InstrumentGroupByExchangeReport,
  InstrumentGroupByExchangeRequest,
  InstrumentGroupBySecuritiesReport,
  InstrumentGroupBySecuritiesRequest,
  InstrumentGroupReport,
  InstrumentGroupRequest,
  MarketStateMetadataReport,
  MarketStateMetadataRequest,
  OptionMaturityListReport,
  OptionMaturityListRequest,
  SymbolResolutionReport,
  SymbolResolutionRequest,
} from './metadata_2';
import { MetadataAdminRequest, MetadataAdminResult } from './metadata_admin_2';
import {
  OrderEntitlementReport,
  OrderEntitlementRequest,
  OrderRequest,
  OrderRequestAck,
  OrderRequestReject,
  OrderStatus,
  OrderStatusReport,
  OrderStatusRequest,
} from './order_2';
import { OtcReport, OtcRequest } from './otc_1';
import { RuleRequest, RuleResult } from './rules_1';
import {
  AlgoStrategyDefinitionReport,
  AlgoStrategyDefinitionRequest,
  StrategyDefinitionReport,
  StrategyDefinitionRequest,
} from './strategy_2';
import {
  ProductSearchReport,
  ProductSearchRequest,
  SymbolCategoryListByInstrumentTypeReport,
  SymbolCategoryListByInstrumentTypeRequest,
  SymbolCategoryListReport,
  SymbolCategoryListRequest,
  SymbolCategoryReport,
  SymbolCategoryRequest,
  SymbolListReport,
  SymbolListRequest,
  SymbolReport,
  SymbolRequest,
} from './symbol_browsing_2';
import {
  AccountSummaryStatus,
  BrokerageTradingFeatureEntitlementReport,
  BrokerageTradingFeatureEntitlementRequest,
  CareAutoActivationParametersReport,
  CareAutoActivationParametersRequest,
  CollateralStatus,
  ExchangeBalanceStatus,
  ExchangePositionStatus,
  HistoricalOrdersReport,
  HistoricalOrdersRequest,
  PositionStatus,
  TradeSnapshotCompletion,
  TradeSubscription,
  TradeSubscriptionStatus,
} from './trade_routing_2';
import {
  AccountsReport,
  AccountsRequest,
  CurrencyRateSourcesReport,
  CurrencyRateSourcesRequest,
  CurrencyRatesReport,
  CurrencyRatesRequest,
  LastStatementBalancesReport,
  LastStatementBalancesRequest,
} from './trading_account_2';
import {
  SessionInformationReport,
  SessionInformationRequest,
  SessionTimeRangeReport,
  SessionTimeRangeRequest,
  TradingDayTimeRangeReport,
  TradingDayTimeRangeRequest,
} from './trading_session_2';
import {
  ConcurrentConnectionJoin,
  LoggedOff,
  Logoff,
  Logon,
  LogonResult,
  LogonRoutineClient,
  LogonRoutineServer,
  ObtainDemoCredentials,
  ObtainDemoCredentialsResult,
  PasswordChange,
  PasswordChangeResult,
  PasswordChangeRoutineClient,
  PasswordChangeRoutineServer,
  Ping,
  Pong,
  RestoreOrJoinSession,
  RestoreOrJoinSessionResult,
  SetCollapsingLevelRequest,
  SetCollapsingLevelResult,
  UserMessage,
  UserSessionStateRequest,
  UserSessionStateResult,
} from './user_session_2';

export const protobufPackage = 'WebAPI_2';

export enum ProtocolVersionMajor {
  /**
   * PROTOCOL_VERSION_MAJOR - Major number change is required in the next cases (new 'proto' file is necessary):
   * 1) Protocol is not binary backward compatible with previous versions.
   * 2) Protocol has lots of changes, which affect to clients' compilation.
   */
  PROTOCOL_VERSION_MAJOR = 2,
  UNRECOGNIZED = -1,
}

export function protocolVersionMajorFromJSON(object: any): ProtocolVersionMajor {
  switch (object) {
    case 2:
    case 'PROTOCOL_VERSION_MAJOR':
      return ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProtocolVersionMajor.UNRECOGNIZED;
  }
}

export function protocolVersionMajorToJSON(object: ProtocolVersionMajor): string {
  switch (object) {
    case ProtocolVersionMajor.PROTOCOL_VERSION_MAJOR:
      return 'PROTOCOL_VERSION_MAJOR';
    case ProtocolVersionMajor.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ProtocolVersionMinor {
  /**
   * PROTOCOL_VERSION_MINOR - Minor number is increased for binary backward compatible protocol versions when new messages and/ or fields are
   * added without removing/ changing any existing messages and fields (new 'proto' file is not created)
   */
  PROTOCOL_VERSION_MINOR = 201,
  UNRECOGNIZED = -1,
}

export function protocolVersionMinorFromJSON(object: any): ProtocolVersionMinor {
  switch (object) {
    case 201:
    case 'PROTOCOL_VERSION_MINOR':
      return ProtocolVersionMinor.PROTOCOL_VERSION_MINOR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProtocolVersionMinor.UNRECOGNIZED;
  }
}

export function protocolVersionMinorToJSON(object: ProtocolVersionMinor): string {
  switch (object) {
    case ProtocolVersionMinor.PROTOCOL_VERSION_MINOR:
      return 'PROTOCOL_VERSION_MINOR';
    case ProtocolVersionMinor.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Client to Server message, must include at least one field.
 * Logon, LogonRoutineClient, RestoreOrJoinSession or ObtainDemoCredentials are required to be the first
 * and the only message after connection.
 * Client messages rate is limited, 1000 messages per 10 seconds by default.
 * If this rate is exceeded then a user message is sent with details and the connection is closed.
 * NOTE: This and other limits mentioned in this protocol are not guaranteed, they are given for reference only
 * and are subject to change without prior notice.
 */
export interface ClientMsg {
  /** Session level messages from client. */
  logon: Logon | undefined;
  restoreOrJoinSession: RestoreOrJoinSession | undefined;
  logoff: Logoff | undefined;
  passwordChange: PasswordChange | undefined;
  obtainDemoCredentials: ObtainDemoCredentials | undefined;
  userSessionStateRequest: UserSessionStateRequest | undefined;
  ping: Ping | undefined;
  pong: Pong | undefined;
  logonRoutineClient: LogonRoutineClient | undefined;
  passwordChangeRoutineClient: PasswordChangeRoutineClient | undefined;
  setCollapsingLevelRequest: SetCollapsingLevelRequest | undefined;
  /**
   * Requests for different information with optional subscription for updates.
   * There are the following limits for information requests:
   * 1) Information request rate is limited, 1000 requests per 100 seconds by default.
   * 2) Number of simultaneous information subscriptions is limited,
   *    a separate limit per information request type is used.
   * 3) Number of overall information requests per session is limited,
   *    a separate limit per information request type is used.
   */
  informationRequests: InformationRequest[];
  /**
   * Trade routing subscriptions from client.
   * There are the following limits for trade routing subscriptions:
   * 1) Number of simultaneous subscriptions is limited, 200 by default.
   * 2) Total number of either explicitly or implicitly subscribed accounts is limited, 5000 by default.
   *    Any subscription that leads to exceeding this limit is failed.
   */
  tradeSubscriptions: TradeSubscription[];
  /**
   * Order requests.
   * Request rate is limited, 300 orders per 10 seconds by default.
   * This is total limit for order actions amount all accounts within the session.
   * There is also per account limit which is 10 order requests per second by default.
   */
  orderRequests: OrderRequest[];
  /**
   * Market data subscriptions from client.
   * Subscriptions number is limited, 200 simultaneous subscriptions by default.
   * Subscriptions on options strikes (puts and calls) of the same option maturity
   * are considered as a single subscription within the limit.
   */
  marketDataSubscriptions: MarketDataSubscription[];
  /**
   * Historical data requests.
   * Number of simultaneous historical requests in processing is limited, 10 by default
   * (all types of historical requests together).
   * Number of simultaneous historical requests with subscription for updates is limited, 50 by default
   * (all types of historical requests together).
   */
  timeAndSalesRequests: TimeAndSalesRequest[];
  timeBarRequests: TimeBarRequest[];
  volumeProfileRequests: VolumeProfileRequest[];
  nonTimedBarRequests: NonTimedBarRequest[];
  /**
   * Rules requests.
   * Number of simultaneous defined rules is limited, 100 by default.
   * Rule requests rate is limited, 30 per 60 seconds by default.
   */
  ruleRequests: RuleRequest[];
  /**
   * Messages for accounts that require separate authorization process.
   *
   * @deprecated
   */
  accountLogonRequests: AccountLogon[];
  accountPasswordChangeRequests: AccountPasswordChange[];
  accountLogoffRequests: AccountLogoff[];
  accountLogonRoutinesClient: AccountLogonRoutineClient[];
  accountPasswordChangeRoutinesClient: AccountPasswordChangeRoutineClient[];
  /**
   * Meta-data related requests is an administrative message to manipulate
   * Security and instrument settings.
   * It requires a special permission.
   * Request rate is limited, 100 requests per 100 seconds by default.
   */
  metadataAdminRequests: MetadataAdminRequest[];
  /**
   * OTC related requests.
   * Used to receive data from OTC and manipulate OTC instances.
   */
  otcRequests: OtcRequest[];
  /**
   * RFQ requests.
   * Request rate is limited, 10 requests per second per account by default.
   */
  rfqRequests: RFQRequest[];
  /**
   * Option calculation requests.
   * Number of simultaneous option calculation requests with subscription for updates is limited, 10 by default.
   */
  optionCalculationRequests: OptionCalculationRequest[];
}

/** Server to Client message, server can include different fields in a single message. */
export interface ServerMsg {
  /** Session level messages from server. */
  logonResult: LogonResult | undefined;
  restoreOrJoinSessionResult: RestoreOrJoinSessionResult | undefined;
  concurrentConnectionJoinResults: ConcurrentConnectionJoin[];
  loggedOff: LoggedOff | undefined;
  passwordChangeResult: PasswordChangeResult | undefined;
  userMessages: UserMessage[];
  obtainDemoCredentialsResults: ObtainDemoCredentialsResult[];
  userSessionStateResults: UserSessionStateResult[];
  ping: Ping | undefined;
  pong: Pong | undefined;
  logonRoutineServer: LogonRoutineServer | undefined;
  passwordChangeRoutineServer: PasswordChangeRoutineServer | undefined;
  setCollapsingLevelResults: SetCollapsingLevelResult[];
  /** Reports with requested information. */
  informationReports: InformationReport[];
  /** Trade routing messages from server. */
  orderRequestRejects: OrderRequestReject[];
  orderRequestAcks: OrderRequestAck[];
  tradeSubscriptionStatuses: TradeSubscriptionStatus[];
  tradeSnapshotCompletions: TradeSnapshotCompletion[];
  orderStatuses: OrderStatus[];
  positionStatuses: PositionStatus[];
  /** @deprecated */
  collateralStatuses: CollateralStatus[];
  accountSummaryStatuses: AccountSummaryStatus[];
  exchangePositionStatuses: ExchangePositionStatus[];
  exchangeBalanceStatuses: ExchangeBalanceStatus[];
  /** Market data messages from server. */
  marketDataSubscriptionStatuses: MarketDataSubscriptionStatus[];
  realTimeMarketData: RealTimeMarketData[];
  /** / Historical data. */
  timeAndSalesReports: TimeAndSalesReport[];
  timeBarReports: TimeBarReport[];
  volumeProfileReports: VolumeProfileReport[];
  nonTimedBarReports: NonTimedBarReport[];
  /** Results of rules requests. */
  ruleResults: RuleResult[];
  /**
   * Result messages for accounts that require separate authorization process.
   *
   * @deprecated
   */
  accountLogonResults: AccountLogonResult[];
  accountPasswordChangeResults: AccountPasswordChangeResult[];
  accountLogoffResults: AccountLogoffResult[];
  accountLogonRoutinesServer: AccountLogonRoutineServer[];
  accountPasswordChangeRoutinesServer: AccountPasswordChangeRoutineServer[];
  /** Results of Meta-data change request. */
  metadataAdminResults: MetadataAdminResult[];
  /** Results of OTC request. */
  otcReports: OtcReport[];
  /** RFQ reports. */
  rfqReports: RFQReport[];
  /** Results of option calculation request. */
  optionCalculationReports: OptionCalculationReport[];
}

/**
 * Request for information.
 * There must be only one request type
 * (e.g. accountsRequest and lastStatementBalancesRequest can not be sent at the same time).
 */
export interface InformationRequest {
  /**
   * ID of a request and optional subscription that should be unique enough to match responses
   * and updates with corresponding requests.
   */
  id: number;
  /**
   * If set to true then corresponding reports are also sent when requested information is changed during the session.
   * If set to false then subscription is dropped.
   * If omitted then data is returned without changing subscription status.
   */
  subscribe: boolean;
  /**
   * Request for authorized accounts for trading.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  accountsRequest: AccountsRequest | undefined;
  /**
   * Request symbol resolution with optional subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   * NOTE: subscription is essential to follow active contract switching.
   */
  symbolResolutionRequest: SymbolResolutionRequest | undefined;
  /**
   * Request for balances from the last statement for all authorized accounts.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  lastStatementBalancesRequest: LastStatementBalancesRequest | undefined;
  /**
   * Request for currency rates for all brokerages user's accounts belong to.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  currencyRatesRequest: CurrencyRatesRequest | undefined;
  /**
   * Request for currency rate source contracts for all brokerages user's accounts belong to.
   * Only one subscription is possible.
   * Request rate is limited, 100 per 24 hours by default.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  currencyRateSourcesRequest: CurrencyRateSourcesRequest | undefined;
  /**
   * Request for session information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * Number of simultaneous session information update subscriptions is limited, 200 by default.
   * Request is accepted only if the user is not subscribed for this information at the moment.
   */
  sessionInformationRequest: SessionInformationRequest | undefined;
  /**
   * Request for a list of historical orders (including all transactions) according to a search criteria.
   * Number of simultaneous requests in processing is limited, 1 by default.
   * The request depth is limited, 30 days by default.
   * Subscription is not supported for this request.
   */
  historicalOrdersRequest: HistoricalOrdersRequest | undefined;
  /**
   * Request a list of option maturities.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  optionMaturityListRequest: OptionMaturityListRequest | undefined;
  /**
   * Request a group of instruments.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  instrumentGroupRequest: InstrumentGroupRequest | undefined;
  /**
   * Request at-the-money strike for the option maturity.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   */
  atTheMoneyStrikeRequest: AtTheMoneyStrikeRequest | undefined;
  /**
   * Request strategy definition with no subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   */
  strategyDefinitionRequest: StrategyDefinitionRequest | undefined;
  /**
   * Request for session timerange information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * The request range is limited. 1 year/1000 sessions by default.
   * Subscription is NOT supported for this request, so subscribe flag will be ignored.
   */
  sessionTimerangeRequest: SessionTimeRangeRequest | undefined;
  /**
   * Request for trading day timerange information.
   * Number of simultaneous requests in processing is limited, 10 by default.
   * The request range is limited. 1 year/250 trading days by default.
   * Subscription is NOT supported for this request, so subscribe flag will be ignored.
   */
  tradingDayTimerangeRequest: TradingDayTimeRangeRequest | undefined;
  /**
   * Request of order entitlements.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  orderEntitlementRequest: OrderEntitlementRequest | undefined;
  /**
   * Request for a symbol category tree for a particular root. each category can have a list of sub-categories.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * There can be only one subscription per category root for updates.
   */
  symbolCategoryListRequest: SymbolCategoryListRequest | undefined;
  /**
   * Request for a specific category by its ID.
   * Number of simultaneous requests in processing is limited, 1000 by default.
   * Number of simultaneous update subscriptions is limited, 1000 by default.
   */
  symbolCategoryRequest: SymbolCategoryRequest | undefined;
  /**
   * Request for a list of category symbols matching input instrument client type.
   * Requests rate is limited, 30 per 60 seconds by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Subscription is not supported for this request.
   */
  symbolCategoryListByInstrumentTypeRequest: SymbolCategoryListByInstrumentTypeRequest | undefined;
  /**
   * Request for a list of symbols according to a specified filter.
   * Number of simultaneous requests in processing is limited, 150 by default.
   * Number of simultaneous update subscriptions is limited, 150 by default.
   */
  symbolListRequest: SymbolListRequest | undefined;
  /**
   * Request for a specific symbol by its ID.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 150 by default.
   * Subscription is not supported for this request.
   */
  symbolRequest: SymbolRequest | undefined;
  /** Request contract metadata by contract ID. */
  contractMetadataRequest: ContractMetadataRequest | undefined;
  /**
   * Request algo strategy definitions.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  algoStrategyDefinitionRequest: AlgoStrategyDefinitionRequest | undefined;
  /**
   * Request contributor metadata by contributor group ID.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  contributorMetadataRequest: ContributorMetadataRequest | undefined;
  /**
   * WebAPI user limits request.
   * Number of subscriptions and simultaneous requests in processing is limited, 1 by default.
   */
  apiLimitRequest: ApiLimitRequest | undefined;
  /**
   * Request for trading feature entitlements.
   * Number of simultaneous requests in processing is limited, 100 by default.
   * Subscription is not supported for this request.
   */
  brokerageTradingFeatureEntitlementRequest: BrokerageTradingFeatureEntitlementRequest | undefined;
  /**
   * Request for a single order status.
   * Request rate is limited, 5 per 5 seconds by default.
   * Subscription is not supported for this request.
   */
  orderStatusRequest: OrderStatusRequest | undefined;
  /**
   * Request for a list of product symbols matching the search criteria.
   * Requests rate is limited, 30 per 60 seconds by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Subscription is not supported for this request.
   */
  productSearchRequest: ProductSearchRequest | undefined;
  /**
   * Request MarketState metadata by MarketState group ID.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  marketStateMetadataRequest: MarketStateMetadataRequest | undefined;
  /**
   * Request (non-strategy) instrument definition with no subscription.
   * Request rate is limited, 10000 per 24 hours by default.
   */
  instrumentDefinitionRequest: InstrumentDefinitionRequest | undefined;
  /**
   * Request metadata of available exchanges.
   * Number of subscriptions and simultaneous requests in processing is limited, 1 per connection by default.
   */
  exchangeMetadataRequest: ExchangeMetadataRequest | undefined;
  /**
   * Request for a subscription on metadata for a group of instruments based on given list of securities.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * Multiple subscriptions on the same set of securities are prohibited.
   */
  instrumentGroupBySecuritiesRequest: InstrumentGroupBySecuritiesRequest | undefined;
  /**
   * Request for a subscription on metadata for a group of instruments of a particular exchange.
   * Request rate is limited, 10000 per 24 hours by default.
   * Number of simultaneous requests in processing is limited, 5 by default.
   * Number of simultaneous update subscriptions is limited, 100 by default.
   * Multiple subscriptions on the same exchange ID are prohibited.
   */
  instrumentGroupByExchangeRequest: InstrumentGroupByExchangeRequest | undefined;
  /**
   * Request for a list of securities for a particular exchange.
   * Request rate is limited, 300 per 1 hour by default.
   * Number of simultaneous requests in processing is limited, 300 by default.
   * Number of simultaneous update subscriptions is limited, 300 by default.
   */
  exchangeSecuritiesRequest: ExchangeSecuritiesRequest | undefined;
  /**
   * Request for care auto activation parameters for the user.
   * Only one subscription is possible.
   * Request is accepted only if the user is not subscribed
   * and there is no pending request for this information at the moment.
   */
  careAutoActivationParametersRequest: CareAutoActivationParametersRequest | undefined;
}

/**
 * Report with requested information and subscription updates.
 * It will contain only one report type at the same time (related to id).
 */
export interface InformationReport {
  /** Report ID is equal to a corresponding request and subscription ID. */
  id: number;
  /**
   * True means that requested data is complete at the moment,
   * false means more report messages are expected for completeness.
   */
  isReportComplete: boolean;
  /** This field is associated with StatusCode enum type. */
  statusCode: number;
  /** Possible details of status or failure. */
  textMessage: string;
  /** Report with trading accounts. */
  accountsReport: AccountsReport | undefined;
  /** Resolved symbol initial information or update. */
  symbolResolutionReport: SymbolResolutionReport | undefined;
  /** Report with balances from the last statement for all authorized accounts. */
  lastStatementBalancesReport: LastStatementBalancesReport | undefined;
  /** Report with currency rates per brokerage. */
  currencyRatesReport: CurrencyRatesReport | undefined;
  /** Report with currency rate source contracts. */
  currencyRateSourcesReport: CurrencyRateSourcesReport | undefined;
  /** Report with session information. */
  sessionInformationReport: SessionInformationReport | undefined;
  /** Report with a list of historical orders according to specified parameters. */
  historicalOrdersReport: HistoricalOrdersReport | undefined;
  /** Report with a list of option maturities. */
  optionMaturityListReport: OptionMaturityListReport | undefined;
  /** Report with a group of instruments. */
  instrumentGroupReport: InstrumentGroupReport | undefined;
  /** Report with at-the-money strikes. */
  atTheMoneyStrikeReport: AtTheMoneyStrikeReport | undefined;
  /**
   * Report with the defined strategy metadata.
   * This field can be filled in case of STATUS_CODE_FAILURE, if the exchange response contains a strategy
   * that differs significantly from the requested one.
   * Example: user wants to define UDS(A - B), but opposite UDS(B - A) already exists on exchange.
   */
  strategyDefinitionReport: StrategyDefinitionReport | undefined;
  /** Report with session timerange information. */
  sessionTimerangeReport: SessionTimeRangeReport | undefined;
  /** Report with trading day timerange information. */
  tradingDayTimerangeReport: TradingDayTimeRangeReport | undefined;
  /** Report with order entitlements. */
  orderEntitlementReport: OrderEntitlementReport | undefined;
  /** Report with a symbol category tree for a particular root. */
  symbolCategoryListReport: SymbolCategoryListReport | undefined;
  /** Report with a symbol category. */
  symbolCategoryReport: SymbolCategoryReport | undefined;
  /** Report with a list of matched category symbols. */
  symbolCategoryListByInstrumentTypeReport: SymbolCategoryListByInstrumentTypeReport | undefined;
  /** Report with a list of found symbols. */
  symbolListReport: SymbolListReport | undefined;
  /** Report with a symbol. */
  symbolReport: SymbolReport | undefined;
  /** Report with the requested contract metadata. */
  contractMetadataReport: ContractMetadataReport | undefined;
  /** Report with algo strategies definitions. */
  algoStrategyDefinitionReport: AlgoStrategyDefinitionReport | undefined;
  /** Report with contributor metadata. */
  contributorMetadataReport: ContributorMetadataReport | undefined;
  /** Report with WebAPI limit values. */
  apiLimitReport: ApiLimitReport | undefined;
  /** Report with trading feature entitlements. */
  brokerageTradingFeatureEntitlementReport: BrokerageTradingFeatureEntitlementReport | undefined;
  /** Report with an order status. */
  orderStatusReport: OrderStatusReport | undefined;
  /** Report with a list of found product symbols. */
  productSearchReport: ProductSearchReport | undefined;
  /** Report with Market State/Status metadata. */
  marketStateMetadataReport: MarketStateMetadataReport | undefined;
  /** Report with the defined (non-strategy) instrument metadata. */
  instrumentDefinitionReport: InstrumentDefinitionReport | undefined;
  /** Report with exchange metadata */
  exchangeMetadataReport: ExchangeMetadataReport | undefined;
  /** Report with a group of instruments for a particular set of securities. */
  instrumentGroupBySecuritiesReport: InstrumentGroupBySecuritiesReport | undefined;
  /** Report with a group of instruments for a particular exchnage securities. */
  instrumentGroupByExchangeReport: InstrumentGroupByExchangeReport | undefined;
  /** Report with a list of securities for a particular exchange. */
  exchangeSecuritiesReport: ExchangeSecuritiesReport | undefined;
  /** Report with a list of care auto activation parameters. */
  careAutoActivationParametersReport: CareAutoActivationParametersReport | undefined;
}

/** Optional status code for a request or subscription, status is only sent for initial status and status changes. */
export enum InformationReport_StatusCode {
  /**
   * STATUS_CODE_SUCCESS - / success codes  (0 - 99)
   * Request is processed without subscription
   * (subscription was not requested or can not be established, e.g. for deleted contracts).
   */
  STATUS_CODE_SUCCESS = 0,
  /** STATUS_CODE_SUBSCRIBED - Request is processed and subscription is established. */
  STATUS_CODE_SUBSCRIBED = 1,
  /** STATUS_CODE_DROPPED - The subscription is dropped by the client. */
  STATUS_CODE_DROPPED = 2,
  /** STATUS_CODE_UPDATE - Unsolicited information update because of subscription. */
  STATUS_CODE_UPDATE = 3,
  /**
   * STATUS_CODE_DISCONNECTED - Currently subscription is disconnected because of communication issues.
   * NOTE: Clients should not resubscribe in this case, the server will restore subscription with
   * sending SUBSCRIBED status once communication issues are resolved. Collection of items should
   * not be cleared once DISCONNECTED status is received, and further reports should be treated as
   * updates unless otherwise noted in request description.
   */
  STATUS_CODE_DISCONNECTED = 4,
  /**
   * STATUS_CODE_FAILURE - / failure codes (100+), subscription (if any) is dropped in case of failure.
   * General failure.
   */
  STATUS_CODE_FAILURE = 101,
  /** STATUS_CODE_INVALID_PARAMS - Request parameters are invalid. */
  STATUS_CODE_INVALID_PARAMS = 102,
  /** STATUS_CODE_NOT_FOUND - Requested information is not found. */
  STATUS_CODE_NOT_FOUND = 104,
  /** STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION - The request rate limit has been violated. */
  STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION = 105,
  /** STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION - The limit of subscriptions has been violated. */
  STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION = 106,
  /** STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION - The limit of active requests has been violated. */
  STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION = 107,
  /** STATUS_CODE_TOO_LARGE_RESPONSE - The response is too large to return. Try more specific request parameters to narrow response size if possible. */
  STATUS_CODE_TOO_LARGE_RESPONSE = 108,
  UNRECOGNIZED = -1,
}

export function informationReport_StatusCodeFromJSON(object: any): InformationReport_StatusCode {
  switch (object) {
    case 0:
    case 'STATUS_CODE_SUCCESS':
      return InformationReport_StatusCode.STATUS_CODE_SUCCESS;
    case 1:
    case 'STATUS_CODE_SUBSCRIBED':
      return InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED;
    case 2:
    case 'STATUS_CODE_DROPPED':
      return InformationReport_StatusCode.STATUS_CODE_DROPPED;
    case 3:
    case 'STATUS_CODE_UPDATE':
      return InformationReport_StatusCode.STATUS_CODE_UPDATE;
    case 4:
    case 'STATUS_CODE_DISCONNECTED':
      return InformationReport_StatusCode.STATUS_CODE_DISCONNECTED;
    case 101:
    case 'STATUS_CODE_FAILURE':
      return InformationReport_StatusCode.STATUS_CODE_FAILURE;
    case 102:
    case 'STATUS_CODE_INVALID_PARAMS':
      return InformationReport_StatusCode.STATUS_CODE_INVALID_PARAMS;
    case 104:
    case 'STATUS_CODE_NOT_FOUND':
      return InformationReport_StatusCode.STATUS_CODE_NOT_FOUND;
    case 105:
    case 'STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION':
      return InformationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION;
    case 106:
    case 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION':
      return InformationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION;
    case 107:
    case 'STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION':
      return InformationReport_StatusCode.STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION;
    case 108:
    case 'STATUS_CODE_TOO_LARGE_RESPONSE':
      return InformationReport_StatusCode.STATUS_CODE_TOO_LARGE_RESPONSE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return InformationReport_StatusCode.UNRECOGNIZED;
  }
}

export function informationReport_StatusCodeToJSON(object: InformationReport_StatusCode): string {
  switch (object) {
    case InformationReport_StatusCode.STATUS_CODE_SUCCESS:
      return 'STATUS_CODE_SUCCESS';
    case InformationReport_StatusCode.STATUS_CODE_SUBSCRIBED:
      return 'STATUS_CODE_SUBSCRIBED';
    case InformationReport_StatusCode.STATUS_CODE_DROPPED:
      return 'STATUS_CODE_DROPPED';
    case InformationReport_StatusCode.STATUS_CODE_UPDATE:
      return 'STATUS_CODE_UPDATE';
    case InformationReport_StatusCode.STATUS_CODE_DISCONNECTED:
      return 'STATUS_CODE_DISCONNECTED';
    case InformationReport_StatusCode.STATUS_CODE_FAILURE:
      return 'STATUS_CODE_FAILURE';
    case InformationReport_StatusCode.STATUS_CODE_INVALID_PARAMS:
      return 'STATUS_CODE_INVALID_PARAMS';
    case InformationReport_StatusCode.STATUS_CODE_NOT_FOUND:
      return 'STATUS_CODE_NOT_FOUND';
    case InformationReport_StatusCode.STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION:
      return 'STATUS_CODE_REQUEST_RATE_LIMIT_VIOLATION';
    case InformationReport_StatusCode.STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION:
      return 'STATUS_CODE_SUBSCRIPTION_LIMIT_VIOLATION';
    case InformationReport_StatusCode.STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION:
      return 'STATUS_CODE_ACTIVE_REQUESTS_LIMIT_VIOLATION';
    case InformationReport_StatusCode.STATUS_CODE_TOO_LARGE_RESPONSE:
      return 'STATUS_CODE_TOO_LARGE_RESPONSE';
    case InformationReport_StatusCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseClientMsg(): ClientMsg {
  return {
    logon: undefined,
    restoreOrJoinSession: undefined,
    logoff: undefined,
    passwordChange: undefined,
    obtainDemoCredentials: undefined,
    userSessionStateRequest: undefined,
    ping: undefined,
    pong: undefined,
    logonRoutineClient: undefined,
    passwordChangeRoutineClient: undefined,
    setCollapsingLevelRequest: undefined,
    informationRequests: [],
    tradeSubscriptions: [],
    orderRequests: [],
    marketDataSubscriptions: [],
    timeAndSalesRequests: [],
    timeBarRequests: [],
    volumeProfileRequests: [],
    nonTimedBarRequests: [],
    ruleRequests: [],
    accountLogonRequests: [],
    accountPasswordChangeRequests: [],
    accountLogoffRequests: [],
    accountLogonRoutinesClient: [],
    accountPasswordChangeRoutinesClient: [],
    metadataAdminRequests: [],
    otcRequests: [],
    rfqRequests: [],
    optionCalculationRequests: [],
  };
}

export const ClientMsg = {
  encode(message: ClientMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logon !== undefined) {
      Logon.encode(message.logon, writer.uint32(802).fork()).ldelim();
    }
    if (message.restoreOrJoinSession !== undefined) {
      RestoreOrJoinSession.encode(message.restoreOrJoinSession, writer.uint32(810).fork()).ldelim();
    }
    if (message.logoff !== undefined) {
      Logoff.encode(message.logoff, writer.uint32(818).fork()).ldelim();
    }
    if (message.passwordChange !== undefined) {
      PasswordChange.encode(message.passwordChange, writer.uint32(826).fork()).ldelim();
    }
    if (message.obtainDemoCredentials !== undefined) {
      ObtainDemoCredentials.encode(message.obtainDemoCredentials, writer.uint32(834).fork()).ldelim();
    }
    if (message.userSessionStateRequest !== undefined) {
      UserSessionStateRequest.encode(message.userSessionStateRequest, writer.uint32(842).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(858).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(866).fork()).ldelim();
    }
    if (message.logonRoutineClient !== undefined) {
      LogonRoutineClient.encode(message.logonRoutineClient, writer.uint32(874).fork()).ldelim();
    }
    if (message.passwordChangeRoutineClient !== undefined) {
      PasswordChangeRoutineClient.encode(message.passwordChangeRoutineClient, writer.uint32(882).fork()).ldelim();
    }
    if (message.setCollapsingLevelRequest !== undefined) {
      SetCollapsingLevelRequest.encode(message.setCollapsingLevelRequest, writer.uint32(890).fork()).ldelim();
    }
    for (const v of message.informationRequests) {
      InformationRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tradeSubscriptions) {
      TradeSubscription.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.orderRequests) {
      OrderRequest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.marketDataSubscriptions) {
      MarketDataSubscription.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.timeAndSalesRequests) {
      TimeAndSalesRequest.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.timeBarRequests) {
      TimeBarRequest.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.volumeProfileRequests) {
      VolumeProfileRequest.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.nonTimedBarRequests) {
      NonTimedBarRequest.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.ruleRequests) {
      RuleRequest.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.accountLogonRequests) {
      AccountLogon.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeRequests) {
      AccountPasswordChange.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.accountLogoffRequests) {
      AccountLogoff.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.accountLogonRoutinesClient) {
      AccountLogonRoutineClient.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeRoutinesClient) {
      AccountPasswordChangeRoutineClient.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.metadataAdminRequests) {
      MetadataAdminRequest.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.otcRequests) {
      OtcRequest.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.rfqRequests) {
      RFQRequest.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.optionCalculationRequests) {
      OptionCalculationRequest.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientMsg {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          message.logon = Logon.decode(reader, reader.uint32());
          break;
        case 101:
          message.restoreOrJoinSession = RestoreOrJoinSession.decode(reader, reader.uint32());
          break;
        case 102:
          message.logoff = Logoff.decode(reader, reader.uint32());
          break;
        case 103:
          message.passwordChange = PasswordChange.decode(reader, reader.uint32());
          break;
        case 104:
          message.obtainDemoCredentials = ObtainDemoCredentials.decode(reader, reader.uint32());
          break;
        case 105:
          message.userSessionStateRequest = UserSessionStateRequest.decode(reader, reader.uint32());
          break;
        case 107:
          message.ping = Ping.decode(reader, reader.uint32());
          break;
        case 108:
          message.pong = Pong.decode(reader, reader.uint32());
          break;
        case 109:
          message.logonRoutineClient = LogonRoutineClient.decode(reader, reader.uint32());
          break;
        case 110:
          message.passwordChangeRoutineClient = PasswordChangeRoutineClient.decode(reader, reader.uint32());
          break;
        case 111:
          message.setCollapsingLevelRequest = SetCollapsingLevelRequest.decode(reader, reader.uint32());
          break;
        case 1:
          message.informationRequests.push(InformationRequest.decode(reader, reader.uint32()));
          break;
        case 2:
          message.tradeSubscriptions.push(TradeSubscription.decode(reader, reader.uint32()));
          break;
        case 3:
          message.orderRequests.push(OrderRequest.decode(reader, reader.uint32()));
          break;
        case 4:
          message.marketDataSubscriptions.push(MarketDataSubscription.decode(reader, reader.uint32()));
          break;
        case 7:
          message.timeAndSalesRequests.push(TimeAndSalesRequest.decode(reader, reader.uint32()));
          break;
        case 8:
          message.timeBarRequests.push(TimeBarRequest.decode(reader, reader.uint32()));
          break;
        case 9:
          message.volumeProfileRequests.push(VolumeProfileRequest.decode(reader, reader.uint32()));
          break;
        case 14:
          message.nonTimedBarRequests.push(NonTimedBarRequest.decode(reader, reader.uint32()));
          break;
        case 10:
          message.ruleRequests.push(RuleRequest.decode(reader, reader.uint32()));
          break;
        case 11:
          message.accountLogonRequests.push(AccountLogon.decode(reader, reader.uint32()));
          break;
        case 12:
          message.accountPasswordChangeRequests.push(AccountPasswordChange.decode(reader, reader.uint32()));
          break;
        case 13:
          message.accountLogoffRequests.push(AccountLogoff.decode(reader, reader.uint32()));
          break;
        case 19:
          message.accountLogonRoutinesClient.push(AccountLogonRoutineClient.decode(reader, reader.uint32()));
          break;
        case 20:
          message.accountPasswordChangeRoutinesClient.push(AccountPasswordChangeRoutineClient.decode(reader, reader.uint32()));
          break;
        case 15:
          message.metadataAdminRequests.push(MetadataAdminRequest.decode(reader, reader.uint32()));
          break;
        case 16:
          message.otcRequests.push(OtcRequest.decode(reader, reader.uint32()));
          break;
        case 17:
          message.rfqRequests.push(RFQRequest.decode(reader, reader.uint32()));
          break;
        case 18:
          message.optionCalculationRequests.push(OptionCalculationRequest.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClientMsg {
    return {
      logon: isSet(object.logon) ? Logon.fromJSON(object.logon) : undefined,
      restoreOrJoinSession: isSet(object.restoreOrJoinSession)
        ? RestoreOrJoinSession.fromJSON(object.restoreOrJoinSession)
        : undefined,
      logoff: isSet(object.logoff) ? Logoff.fromJSON(object.logoff) : undefined,
      passwordChange: isSet(object.passwordChange) ? PasswordChange.fromJSON(object.passwordChange) : undefined,
      obtainDemoCredentials: isSet(object.obtainDemoCredentials)
        ? ObtainDemoCredentials.fromJSON(object.obtainDemoCredentials)
        : undefined,
      userSessionStateRequest: isSet(object.userSessionStateRequest)
        ? UserSessionStateRequest.fromJSON(object.userSessionStateRequest)
        : undefined,
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      logonRoutineClient: isSet(object.logonRoutineClient) ? LogonRoutineClient.fromJSON(object.logonRoutineClient) : undefined,
      passwordChangeRoutineClient: isSet(object.passwordChangeRoutineClient)
        ? PasswordChangeRoutineClient.fromJSON(object.passwordChangeRoutineClient)
        : undefined,
      setCollapsingLevelRequest: isSet(object.setCollapsingLevelRequest)
        ? SetCollapsingLevelRequest.fromJSON(object.setCollapsingLevelRequest)
        : undefined,
      informationRequests: Array.isArray(object?.informationRequests)
        ? object.informationRequests.map((e: any) => InformationRequest.fromJSON(e))
        : [],
      tradeSubscriptions: Array.isArray(object?.tradeSubscriptions)
        ? object.tradeSubscriptions.map((e: any) => TradeSubscription.fromJSON(e))
        : [],
      orderRequests: Array.isArray(object?.orderRequests) ? object.orderRequests.map((e: any) => OrderRequest.fromJSON(e)) : [],
      marketDataSubscriptions: Array.isArray(object?.marketDataSubscriptions)
        ? object.marketDataSubscriptions.map((e: any) => MarketDataSubscription.fromJSON(e))
        : [],
      timeAndSalesRequests: Array.isArray(object?.timeAndSalesRequests)
        ? object.timeAndSalesRequests.map((e: any) => TimeAndSalesRequest.fromJSON(e))
        : [],
      timeBarRequests: Array.isArray(object?.timeBarRequests)
        ? object.timeBarRequests.map((e: any) => TimeBarRequest.fromJSON(e))
        : [],
      volumeProfileRequests: Array.isArray(object?.volumeProfileRequests)
        ? object.volumeProfileRequests.map((e: any) => VolumeProfileRequest.fromJSON(e))
        : [],
      nonTimedBarRequests: Array.isArray(object?.nonTimedBarRequests)
        ? object.nonTimedBarRequests.map((e: any) => NonTimedBarRequest.fromJSON(e))
        : [],
      ruleRequests: Array.isArray(object?.ruleRequests) ? object.ruleRequests.map((e: any) => RuleRequest.fromJSON(e)) : [],
      accountLogonRequests: Array.isArray(object?.accountLogonRequests)
        ? object.accountLogonRequests.map((e: any) => AccountLogon.fromJSON(e))
        : [],
      accountPasswordChangeRequests: Array.isArray(object?.accountPasswordChangeRequests)
        ? object.accountPasswordChangeRequests.map((e: any) => AccountPasswordChange.fromJSON(e))
        : [],
      accountLogoffRequests: Array.isArray(object?.accountLogoffRequests)
        ? object.accountLogoffRequests.map((e: any) => AccountLogoff.fromJSON(e))
        : [],
      accountLogonRoutinesClient: Array.isArray(object?.accountLogonRoutinesClient)
        ? object.accountLogonRoutinesClient.map((e: any) => AccountLogonRoutineClient.fromJSON(e))
        : [],
      accountPasswordChangeRoutinesClient: Array.isArray(object?.accountPasswordChangeRoutinesClient)
        ? object.accountPasswordChangeRoutinesClient.map((e: any) => AccountPasswordChangeRoutineClient.fromJSON(e))
        : [],
      metadataAdminRequests: Array.isArray(object?.metadataAdminRequests)
        ? object.metadataAdminRequests.map((e: any) => MetadataAdminRequest.fromJSON(e))
        : [],
      otcRequests: Array.isArray(object?.otcRequests) ? object.otcRequests.map((e: any) => OtcRequest.fromJSON(e)) : [],
      rfqRequests: Array.isArray(object?.rfqRequests) ? object.rfqRequests.map((e: any) => RFQRequest.fromJSON(e)) : [],
      optionCalculationRequests: Array.isArray(object?.optionCalculationRequests)
        ? object.optionCalculationRequests.map((e: any) => OptionCalculationRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ClientMsg): unknown {
    const obj: any = {};
    message.logon !== undefined && (obj.logon = message.logon ? Logon.toJSON(message.logon) : undefined);
    message.restoreOrJoinSession !== undefined &&
      (obj.restoreOrJoinSession = message.restoreOrJoinSession
        ? RestoreOrJoinSession.toJSON(message.restoreOrJoinSession)
        : undefined);
    message.logoff !== undefined && (obj.logoff = message.logoff ? Logoff.toJSON(message.logoff) : undefined);
    message.passwordChange !== undefined &&
      (obj.passwordChange = message.passwordChange ? PasswordChange.toJSON(message.passwordChange) : undefined);
    message.obtainDemoCredentials !== undefined &&
      (obj.obtainDemoCredentials = message.obtainDemoCredentials
        ? ObtainDemoCredentials.toJSON(message.obtainDemoCredentials)
        : undefined);
    message.userSessionStateRequest !== undefined &&
      (obj.userSessionStateRequest = message.userSessionStateRequest
        ? UserSessionStateRequest.toJSON(message.userSessionStateRequest)
        : undefined);
    message.ping !== undefined && (obj.ping = message.ping ? Ping.toJSON(message.ping) : undefined);
    message.pong !== undefined && (obj.pong = message.pong ? Pong.toJSON(message.pong) : undefined);
    message.logonRoutineClient !== undefined &&
      (obj.logonRoutineClient = message.logonRoutineClient ? LogonRoutineClient.toJSON(message.logonRoutineClient) : undefined);
    message.passwordChangeRoutineClient !== undefined &&
      (obj.passwordChangeRoutineClient = message.passwordChangeRoutineClient
        ? PasswordChangeRoutineClient.toJSON(message.passwordChangeRoutineClient)
        : undefined);
    message.setCollapsingLevelRequest !== undefined &&
      (obj.setCollapsingLevelRequest = message.setCollapsingLevelRequest
        ? SetCollapsingLevelRequest.toJSON(message.setCollapsingLevelRequest)
        : undefined);
    if (message.informationRequests) {
      obj.informationRequests = message.informationRequests.map((e) => (e ? InformationRequest.toJSON(e) : undefined));
    } else {
      obj.informationRequests = [];
    }
    if (message.tradeSubscriptions) {
      obj.tradeSubscriptions = message.tradeSubscriptions.map((e) => (e ? TradeSubscription.toJSON(e) : undefined));
    } else {
      obj.tradeSubscriptions = [];
    }
    if (message.orderRequests) {
      obj.orderRequests = message.orderRequests.map((e) => (e ? OrderRequest.toJSON(e) : undefined));
    } else {
      obj.orderRequests = [];
    }
    if (message.marketDataSubscriptions) {
      obj.marketDataSubscriptions = message.marketDataSubscriptions.map((e) =>
        e ? MarketDataSubscription.toJSON(e) : undefined
      );
    } else {
      obj.marketDataSubscriptions = [];
    }
    if (message.timeAndSalesRequests) {
      obj.timeAndSalesRequests = message.timeAndSalesRequests.map((e) => (e ? TimeAndSalesRequest.toJSON(e) : undefined));
    } else {
      obj.timeAndSalesRequests = [];
    }
    if (message.timeBarRequests) {
      obj.timeBarRequests = message.timeBarRequests.map((e) => (e ? TimeBarRequest.toJSON(e) : undefined));
    } else {
      obj.timeBarRequests = [];
    }
    if (message.volumeProfileRequests) {
      obj.volumeProfileRequests = message.volumeProfileRequests.map((e) => (e ? VolumeProfileRequest.toJSON(e) : undefined));
    } else {
      obj.volumeProfileRequests = [];
    }
    if (message.nonTimedBarRequests) {
      obj.nonTimedBarRequests = message.nonTimedBarRequests.map((e) => (e ? NonTimedBarRequest.toJSON(e) : undefined));
    } else {
      obj.nonTimedBarRequests = [];
    }
    if (message.ruleRequests) {
      obj.ruleRequests = message.ruleRequests.map((e) => (e ? RuleRequest.toJSON(e) : undefined));
    } else {
      obj.ruleRequests = [];
    }
    if (message.accountLogonRequests) {
      obj.accountLogonRequests = message.accountLogonRequests.map((e) => (e ? AccountLogon.toJSON(e) : undefined));
    } else {
      obj.accountLogonRequests = [];
    }
    if (message.accountPasswordChangeRequests) {
      obj.accountPasswordChangeRequests = message.accountPasswordChangeRequests.map((e) =>
        e ? AccountPasswordChange.toJSON(e) : undefined
      );
    } else {
      obj.accountPasswordChangeRequests = [];
    }
    if (message.accountLogoffRequests) {
      obj.accountLogoffRequests = message.accountLogoffRequests.map((e) => (e ? AccountLogoff.toJSON(e) : undefined));
    } else {
      obj.accountLogoffRequests = [];
    }
    if (message.accountLogonRoutinesClient) {
      obj.accountLogonRoutinesClient = message.accountLogonRoutinesClient.map((e) =>
        e ? AccountLogonRoutineClient.toJSON(e) : undefined
      );
    } else {
      obj.accountLogonRoutinesClient = [];
    }
    if (message.accountPasswordChangeRoutinesClient) {
      obj.accountPasswordChangeRoutinesClient = message.accountPasswordChangeRoutinesClient.map((e) =>
        e ? AccountPasswordChangeRoutineClient.toJSON(e) : undefined
      );
    } else {
      obj.accountPasswordChangeRoutinesClient = [];
    }
    if (message.metadataAdminRequests) {
      obj.metadataAdminRequests = message.metadataAdminRequests.map((e) => (e ? MetadataAdminRequest.toJSON(e) : undefined));
    } else {
      obj.metadataAdminRequests = [];
    }
    if (message.otcRequests) {
      obj.otcRequests = message.otcRequests.map((e) => (e ? OtcRequest.toJSON(e) : undefined));
    } else {
      obj.otcRequests = [];
    }
    if (message.rfqRequests) {
      obj.rfqRequests = message.rfqRequests.map((e) => (e ? RFQRequest.toJSON(e) : undefined));
    } else {
      obj.rfqRequests = [];
    }
    if (message.optionCalculationRequests) {
      obj.optionCalculationRequests = message.optionCalculationRequests.map((e) =>
        e ? OptionCalculationRequest.toJSON(e) : undefined
      );
    } else {
      obj.optionCalculationRequests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientMsg>, I>>(base?: I): ClientMsg {
    return ClientMsg.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClientMsg>, I>>(object: I): ClientMsg {
    const message = createBaseClientMsg();
    message.logon = object.logon !== undefined && object.logon !== null ? Logon.fromPartial(object.logon) : undefined;
    message.restoreOrJoinSession =
      object.restoreOrJoinSession !== undefined && object.restoreOrJoinSession !== null
        ? RestoreOrJoinSession.fromPartial(object.restoreOrJoinSession)
        : undefined;
    message.logoff = object.logoff !== undefined && object.logoff !== null ? Logoff.fromPartial(object.logoff) : undefined;
    message.passwordChange =
      object.passwordChange !== undefined && object.passwordChange !== null
        ? PasswordChange.fromPartial(object.passwordChange)
        : undefined;
    message.obtainDemoCredentials =
      object.obtainDemoCredentials !== undefined && object.obtainDemoCredentials !== null
        ? ObtainDemoCredentials.fromPartial(object.obtainDemoCredentials)
        : undefined;
    message.userSessionStateRequest =
      object.userSessionStateRequest !== undefined && object.userSessionStateRequest !== null
        ? UserSessionStateRequest.fromPartial(object.userSessionStateRequest)
        : undefined;
    message.ping = object.ping !== undefined && object.ping !== null ? Ping.fromPartial(object.ping) : undefined;
    message.pong = object.pong !== undefined && object.pong !== null ? Pong.fromPartial(object.pong) : undefined;
    message.logonRoutineClient =
      object.logonRoutineClient !== undefined && object.logonRoutineClient !== null
        ? LogonRoutineClient.fromPartial(object.logonRoutineClient)
        : undefined;
    message.passwordChangeRoutineClient =
      object.passwordChangeRoutineClient !== undefined && object.passwordChangeRoutineClient !== null
        ? PasswordChangeRoutineClient.fromPartial(object.passwordChangeRoutineClient)
        : undefined;
    message.setCollapsingLevelRequest =
      object.setCollapsingLevelRequest !== undefined && object.setCollapsingLevelRequest !== null
        ? SetCollapsingLevelRequest.fromPartial(object.setCollapsingLevelRequest)
        : undefined;
    message.informationRequests = object.informationRequests?.map((e) => InformationRequest.fromPartial(e)) || [];
    message.tradeSubscriptions = object.tradeSubscriptions?.map((e) => TradeSubscription.fromPartial(e)) || [];
    message.orderRequests = object.orderRequests?.map((e) => OrderRequest.fromPartial(e)) || [];
    message.marketDataSubscriptions = object.marketDataSubscriptions?.map((e) => MarketDataSubscription.fromPartial(e)) || [];
    message.timeAndSalesRequests = object.timeAndSalesRequests?.map((e) => TimeAndSalesRequest.fromPartial(e)) || [];
    message.timeBarRequests = object.timeBarRequests?.map((e) => TimeBarRequest.fromPartial(e)) || [];
    message.volumeProfileRequests = object.volumeProfileRequests?.map((e) => VolumeProfileRequest.fromPartial(e)) || [];
    message.nonTimedBarRequests = object.nonTimedBarRequests?.map((e) => NonTimedBarRequest.fromPartial(e)) || [];
    message.ruleRequests = object.ruleRequests?.map((e) => RuleRequest.fromPartial(e)) || [];
    message.accountLogonRequests = object.accountLogonRequests?.map((e) => AccountLogon.fromPartial(e)) || [];
    message.accountPasswordChangeRequests =
      object.accountPasswordChangeRequests?.map((e) => AccountPasswordChange.fromPartial(e)) || [];
    message.accountLogoffRequests = object.accountLogoffRequests?.map((e) => AccountLogoff.fromPartial(e)) || [];
    message.accountLogonRoutinesClient =
      object.accountLogonRoutinesClient?.map((e) => AccountLogonRoutineClient.fromPartial(e)) || [];
    message.accountPasswordChangeRoutinesClient =
      object.accountPasswordChangeRoutinesClient?.map((e) => AccountPasswordChangeRoutineClient.fromPartial(e)) || [];
    message.metadataAdminRequests = object.metadataAdminRequests?.map((e) => MetadataAdminRequest.fromPartial(e)) || [];
    message.otcRequests = object.otcRequests?.map((e) => OtcRequest.fromPartial(e)) || [];
    message.rfqRequests = object.rfqRequests?.map((e) => RFQRequest.fromPartial(e)) || [];
    message.optionCalculationRequests =
      object.optionCalculationRequests?.map((e) => OptionCalculationRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerMsg(): ServerMsg {
  return {
    logonResult: undefined,
    restoreOrJoinSessionResult: undefined,
    concurrentConnectionJoinResults: [],
    loggedOff: undefined,
    passwordChangeResult: undefined,
    userMessages: [],
    obtainDemoCredentialsResults: [],
    userSessionStateResults: [],
    ping: undefined,
    pong: undefined,
    logonRoutineServer: undefined,
    passwordChangeRoutineServer: undefined,
    setCollapsingLevelResults: [],
    informationReports: [],
    orderRequestRejects: [],
    orderRequestAcks: [],
    tradeSubscriptionStatuses: [],
    tradeSnapshotCompletions: [],
    orderStatuses: [],
    positionStatuses: [],
    collateralStatuses: [],
    accountSummaryStatuses: [],
    exchangePositionStatuses: [],
    exchangeBalanceStatuses: [],
    marketDataSubscriptionStatuses: [],
    realTimeMarketData: [],
    timeAndSalesReports: [],
    timeBarReports: [],
    volumeProfileReports: [],
    nonTimedBarReports: [],
    ruleResults: [],
    accountLogonResults: [],
    accountPasswordChangeResults: [],
    accountLogoffResults: [],
    accountLogonRoutinesServer: [],
    accountPasswordChangeRoutinesServer: [],
    metadataAdminResults: [],
    otcReports: [],
    rfqReports: [],
    optionCalculationReports: [],
  };
}

export const ServerMsg = {
  encode(message: ServerMsg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logonResult !== undefined) {
      LogonResult.encode(message.logonResult, writer.uint32(802).fork()).ldelim();
    }
    if (message.restoreOrJoinSessionResult !== undefined) {
      RestoreOrJoinSessionResult.encode(message.restoreOrJoinSessionResult, writer.uint32(810).fork()).ldelim();
    }
    for (const v of message.concurrentConnectionJoinResults) {
      ConcurrentConnectionJoin.encode(v!, writer.uint32(890).fork()).ldelim();
    }
    if (message.loggedOff !== undefined) {
      LoggedOff.encode(message.loggedOff, writer.uint32(818).fork()).ldelim();
    }
    if (message.passwordChangeResult !== undefined) {
      PasswordChangeResult.encode(message.passwordChangeResult, writer.uint32(826).fork()).ldelim();
    }
    for (const v of message.userMessages) {
      UserMessage.encode(v!, writer.uint32(834).fork()).ldelim();
    }
    for (const v of message.obtainDemoCredentialsResults) {
      ObtainDemoCredentialsResult.encode(v!, writer.uint32(842).fork()).ldelim();
    }
    for (const v of message.userSessionStateResults) {
      UserSessionStateResult.encode(v!, writer.uint32(850).fork()).ldelim();
    }
    if (message.ping !== undefined) {
      Ping.encode(message.ping, writer.uint32(858).fork()).ldelim();
    }
    if (message.pong !== undefined) {
      Pong.encode(message.pong, writer.uint32(866).fork()).ldelim();
    }
    if (message.logonRoutineServer !== undefined) {
      LogonRoutineServer.encode(message.logonRoutineServer, writer.uint32(874).fork()).ldelim();
    }
    if (message.passwordChangeRoutineServer !== undefined) {
      PasswordChangeRoutineServer.encode(message.passwordChangeRoutineServer, writer.uint32(882).fork()).ldelim();
    }
    for (const v of message.setCollapsingLevelResults) {
      SetCollapsingLevelResult.encode(v!, writer.uint32(898).fork()).ldelim();
    }
    for (const v of message.informationReports) {
      InformationReport.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.orderRequestRejects) {
      OrderRequestReject.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.orderRequestAcks) {
      OrderRequestAck.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    for (const v of message.tradeSubscriptionStatuses) {
      TradeSubscriptionStatus.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tradeSnapshotCompletions) {
      TradeSnapshotCompletion.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.orderStatuses) {
      OrderStatus.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.positionStatuses) {
      PositionStatus.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.collateralStatuses) {
      CollateralStatus.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.accountSummaryStatuses) {
      AccountSummaryStatus.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.exchangePositionStatuses) {
      ExchangePositionStatus.encode(v!, writer.uint32(226).fork()).ldelim();
    }
    for (const v of message.exchangeBalanceStatuses) {
      ExchangeBalanceStatus.encode(v!, writer.uint32(234).fork()).ldelim();
    }
    for (const v of message.marketDataSubscriptionStatuses) {
      MarketDataSubscriptionStatus.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.realTimeMarketData) {
      RealTimeMarketData.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.timeAndSalesReports) {
      TimeAndSalesReport.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.timeBarReports) {
      TimeBarReport.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.volumeProfileReports) {
      VolumeProfileReport.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.nonTimedBarReports) {
      NonTimedBarReport.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.ruleResults) {
      RuleResult.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.accountLogonResults) {
      AccountLogonResult.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeResults) {
      AccountPasswordChangeResult.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.accountLogoffResults) {
      AccountLogoffResult.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.accountLogonRoutinesServer) {
      AccountLogonRoutineServer.encode(v!, writer.uint32(210).fork()).ldelim();
    }
    for (const v of message.accountPasswordChangeRoutinesServer) {
      AccountPasswordChangeRoutineServer.encode(v!, writer.uint32(218).fork()).ldelim();
    }
    for (const v of message.metadataAdminResults) {
      MetadataAdminResult.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    for (const v of message.otcReports) {
      OtcReport.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.rfqReports) {
      RFQReport.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    for (const v of message.optionCalculationReports) {
      OptionCalculationReport.encode(v!, writer.uint32(186).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerMsg {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerMsg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          message.logonResult = LogonResult.decode(reader, reader.uint32());
          break;
        case 101:
          message.restoreOrJoinSessionResult = RestoreOrJoinSessionResult.decode(reader, reader.uint32());
          break;
        case 111:
          message.concurrentConnectionJoinResults.push(ConcurrentConnectionJoin.decode(reader, reader.uint32()));
          break;
        case 102:
          message.loggedOff = LoggedOff.decode(reader, reader.uint32());
          break;
        case 103:
          message.passwordChangeResult = PasswordChangeResult.decode(reader, reader.uint32());
          break;
        case 104:
          message.userMessages.push(UserMessage.decode(reader, reader.uint32()));
          break;
        case 105:
          message.obtainDemoCredentialsResults.push(ObtainDemoCredentialsResult.decode(reader, reader.uint32()));
          break;
        case 106:
          message.userSessionStateResults.push(UserSessionStateResult.decode(reader, reader.uint32()));
          break;
        case 107:
          message.ping = Ping.decode(reader, reader.uint32());
          break;
        case 108:
          message.pong = Pong.decode(reader, reader.uint32());
          break;
        case 109:
          message.logonRoutineServer = LogonRoutineServer.decode(reader, reader.uint32());
          break;
        case 110:
          message.passwordChangeRoutineServer = PasswordChangeRoutineServer.decode(reader, reader.uint32());
          break;
        case 112:
          message.setCollapsingLevelResults.push(SetCollapsingLevelResult.decode(reader, reader.uint32()));
          break;
        case 1:
          message.informationReports.push(InformationReport.decode(reader, reader.uint32()));
          break;
        case 2:
          message.orderRequestRejects.push(OrderRequestReject.decode(reader, reader.uint32()));
          break;
        case 25:
          message.orderRequestAcks.push(OrderRequestAck.decode(reader, reader.uint32()));
          break;
        case 3:
          message.tradeSubscriptionStatuses.push(TradeSubscriptionStatus.decode(reader, reader.uint32()));
          break;
        case 4:
          message.tradeSnapshotCompletions.push(TradeSnapshotCompletion.decode(reader, reader.uint32()));
          break;
        case 5:
          message.orderStatuses.push(OrderStatus.decode(reader, reader.uint32()));
          break;
        case 6:
          message.positionStatuses.push(PositionStatus.decode(reader, reader.uint32()));
          break;
        case 7:
          message.collateralStatuses.push(CollateralStatus.decode(reader, reader.uint32()));
          break;
        case 24:
          message.accountSummaryStatuses.push(AccountSummaryStatus.decode(reader, reader.uint32()));
          break;
        case 28:
          message.exchangePositionStatuses.push(ExchangePositionStatus.decode(reader, reader.uint32()));
          break;
        case 29:
          message.exchangeBalanceStatuses.push(ExchangeBalanceStatus.decode(reader, reader.uint32()));
          break;
        case 8:
          message.marketDataSubscriptionStatuses.push(MarketDataSubscriptionStatus.decode(reader, reader.uint32()));
          break;
        case 9:
          message.realTimeMarketData.push(RealTimeMarketData.decode(reader, reader.uint32()));
          break;
        case 12:
          message.timeAndSalesReports.push(TimeAndSalesReport.decode(reader, reader.uint32()));
          break;
        case 13:
          message.timeBarReports.push(TimeBarReport.decode(reader, reader.uint32()));
          break;
        case 14:
          message.volumeProfileReports.push(VolumeProfileReport.decode(reader, reader.uint32()));
          break;
        case 19:
          message.nonTimedBarReports.push(NonTimedBarReport.decode(reader, reader.uint32()));
          break;
        case 15:
          message.ruleResults.push(RuleResult.decode(reader, reader.uint32()));
          break;
        case 16:
          message.accountLogonResults.push(AccountLogonResult.decode(reader, reader.uint32()));
          break;
        case 17:
          message.accountPasswordChangeResults.push(AccountPasswordChangeResult.decode(reader, reader.uint32()));
          break;
        case 18:
          message.accountLogoffResults.push(AccountLogoffResult.decode(reader, reader.uint32()));
          break;
        case 26:
          message.accountLogonRoutinesServer.push(AccountLogonRoutineServer.decode(reader, reader.uint32()));
          break;
        case 27:
          message.accountPasswordChangeRoutinesServer.push(AccountPasswordChangeRoutineServer.decode(reader, reader.uint32()));
          break;
        case 20:
          message.metadataAdminResults.push(MetadataAdminResult.decode(reader, reader.uint32()));
          break;
        case 21:
          message.otcReports.push(OtcReport.decode(reader, reader.uint32()));
          break;
        case 22:
          message.rfqReports.push(RFQReport.decode(reader, reader.uint32()));
          break;
        case 23:
          message.optionCalculationReports.push(OptionCalculationReport.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServerMsg {
    return {
      logonResult: isSet(object.logonResult) ? LogonResult.fromJSON(object.logonResult) : undefined,
      restoreOrJoinSessionResult: isSet(object.restoreOrJoinSessionResult)
        ? RestoreOrJoinSessionResult.fromJSON(object.restoreOrJoinSessionResult)
        : undefined,
      concurrentConnectionJoinResults: Array.isArray(object?.concurrentConnectionJoinResults)
        ? object.concurrentConnectionJoinResults.map((e: any) => ConcurrentConnectionJoin.fromJSON(e))
        : [],
      loggedOff: isSet(object.loggedOff) ? LoggedOff.fromJSON(object.loggedOff) : undefined,
      passwordChangeResult: isSet(object.passwordChangeResult)
        ? PasswordChangeResult.fromJSON(object.passwordChangeResult)
        : undefined,
      userMessages: Array.isArray(object?.userMessages) ? object.userMessages.map((e: any) => UserMessage.fromJSON(e)) : [],
      obtainDemoCredentialsResults: Array.isArray(object?.obtainDemoCredentialsResults)
        ? object.obtainDemoCredentialsResults.map((e: any) => ObtainDemoCredentialsResult.fromJSON(e))
        : [],
      userSessionStateResults: Array.isArray(object?.userSessionStateResults)
        ? object.userSessionStateResults.map((e: any) => UserSessionStateResult.fromJSON(e))
        : [],
      ping: isSet(object.ping) ? Ping.fromJSON(object.ping) : undefined,
      pong: isSet(object.pong) ? Pong.fromJSON(object.pong) : undefined,
      logonRoutineServer: isSet(object.logonRoutineServer) ? LogonRoutineServer.fromJSON(object.logonRoutineServer) : undefined,
      passwordChangeRoutineServer: isSet(object.passwordChangeRoutineServer)
        ? PasswordChangeRoutineServer.fromJSON(object.passwordChangeRoutineServer)
        : undefined,
      setCollapsingLevelResults: Array.isArray(object?.setCollapsingLevelResults)
        ? object.setCollapsingLevelResults.map((e: any) => SetCollapsingLevelResult.fromJSON(e))
        : [],
      informationReports: Array.isArray(object?.informationReports)
        ? object.informationReports.map((e: any) => InformationReport.fromJSON(e))
        : [],
      orderRequestRejects: Array.isArray(object?.orderRequestRejects)
        ? object.orderRequestRejects.map((e: any) => OrderRequestReject.fromJSON(e))
        : [],
      orderRequestAcks: Array.isArray(object?.orderRequestAcks)
        ? object.orderRequestAcks.map((e: any) => OrderRequestAck.fromJSON(e))
        : [],
      tradeSubscriptionStatuses: Array.isArray(object?.tradeSubscriptionStatuses)
        ? object.tradeSubscriptionStatuses.map((e: any) => TradeSubscriptionStatus.fromJSON(e))
        : [],
      tradeSnapshotCompletions: Array.isArray(object?.tradeSnapshotCompletions)
        ? object.tradeSnapshotCompletions.map((e: any) => TradeSnapshotCompletion.fromJSON(e))
        : [],
      orderStatuses: Array.isArray(object?.orderStatuses) ? object.orderStatuses.map((e: any) => OrderStatus.fromJSON(e)) : [],
      positionStatuses: Array.isArray(object?.positionStatuses)
        ? object.positionStatuses.map((e: any) => PositionStatus.fromJSON(e))
        : [],
      collateralStatuses: Array.isArray(object?.collateralStatuses)
        ? object.collateralStatuses.map((e: any) => CollateralStatus.fromJSON(e))
        : [],
      accountSummaryStatuses: Array.isArray(object?.accountSummaryStatuses)
        ? object.accountSummaryStatuses.map((e: any) => AccountSummaryStatus.fromJSON(e))
        : [],
      exchangePositionStatuses: Array.isArray(object?.exchangePositionStatuses)
        ? object.exchangePositionStatuses.map((e: any) => ExchangePositionStatus.fromJSON(e))
        : [],
      exchangeBalanceStatuses: Array.isArray(object?.exchangeBalanceStatuses)
        ? object.exchangeBalanceStatuses.map((e: any) => ExchangeBalanceStatus.fromJSON(e))
        : [],
      marketDataSubscriptionStatuses: Array.isArray(object?.marketDataSubscriptionStatuses)
        ? object.marketDataSubscriptionStatuses.map((e: any) => MarketDataSubscriptionStatus.fromJSON(e))
        : [],
      realTimeMarketData: Array.isArray(object?.realTimeMarketData)
        ? object.realTimeMarketData.map((e: any) => RealTimeMarketData.fromJSON(e))
        : [],
      timeAndSalesReports: Array.isArray(object?.timeAndSalesReports)
        ? object.timeAndSalesReports.map((e: any) => TimeAndSalesReport.fromJSON(e))
        : [],
      timeBarReports: Array.isArray(object?.timeBarReports)
        ? object.timeBarReports.map((e: any) => TimeBarReport.fromJSON(e))
        : [],
      volumeProfileReports: Array.isArray(object?.volumeProfileReports)
        ? object.volumeProfileReports.map((e: any) => VolumeProfileReport.fromJSON(e))
        : [],
      nonTimedBarReports: Array.isArray(object?.nonTimedBarReports)
        ? object.nonTimedBarReports.map((e: any) => NonTimedBarReport.fromJSON(e))
        : [],
      ruleResults: Array.isArray(object?.ruleResults) ? object.ruleResults.map((e: any) => RuleResult.fromJSON(e)) : [],
      accountLogonResults: Array.isArray(object?.accountLogonResults)
        ? object.accountLogonResults.map((e: any) => AccountLogonResult.fromJSON(e))
        : [],
      accountPasswordChangeResults: Array.isArray(object?.accountPasswordChangeResults)
        ? object.accountPasswordChangeResults.map((e: any) => AccountPasswordChangeResult.fromJSON(e))
        : [],
      accountLogoffResults: Array.isArray(object?.accountLogoffResults)
        ? object.accountLogoffResults.map((e: any) => AccountLogoffResult.fromJSON(e))
        : [],
      accountLogonRoutinesServer: Array.isArray(object?.accountLogonRoutinesServer)
        ? object.accountLogonRoutinesServer.map((e: any) => AccountLogonRoutineServer.fromJSON(e))
        : [],
      accountPasswordChangeRoutinesServer: Array.isArray(object?.accountPasswordChangeRoutinesServer)
        ? object.accountPasswordChangeRoutinesServer.map((e: any) => AccountPasswordChangeRoutineServer.fromJSON(e))
        : [],
      metadataAdminResults: Array.isArray(object?.metadataAdminResults)
        ? object.metadataAdminResults.map((e: any) => MetadataAdminResult.fromJSON(e))
        : [],
      otcReports: Array.isArray(object?.otcReports) ? object.otcReports.map((e: any) => OtcReport.fromJSON(e)) : [],
      rfqReports: Array.isArray(object?.rfqReports) ? object.rfqReports.map((e: any) => RFQReport.fromJSON(e)) : [],
      optionCalculationReports: Array.isArray(object?.optionCalculationReports)
        ? object.optionCalculationReports.map((e: any) => OptionCalculationReport.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerMsg): unknown {
    const obj: any = {};
    message.logonResult !== undefined &&
      (obj.logonResult = message.logonResult ? LogonResult.toJSON(message.logonResult) : undefined);
    message.restoreOrJoinSessionResult !== undefined &&
      (obj.restoreOrJoinSessionResult = message.restoreOrJoinSessionResult
        ? RestoreOrJoinSessionResult.toJSON(message.restoreOrJoinSessionResult)
        : undefined);
    if (message.concurrentConnectionJoinResults) {
      obj.concurrentConnectionJoinResults = message.concurrentConnectionJoinResults.map((e) =>
        e ? ConcurrentConnectionJoin.toJSON(e) : undefined
      );
    } else {
      obj.concurrentConnectionJoinResults = [];
    }
    message.loggedOff !== undefined && (obj.loggedOff = message.loggedOff ? LoggedOff.toJSON(message.loggedOff) : undefined);
    message.passwordChangeResult !== undefined &&
      (obj.passwordChangeResult = message.passwordChangeResult
        ? PasswordChangeResult.toJSON(message.passwordChangeResult)
        : undefined);
    if (message.userMessages) {
      obj.userMessages = message.userMessages.map((e) => (e ? UserMessage.toJSON(e) : undefined));
    } else {
      obj.userMessages = [];
    }
    if (message.obtainDemoCredentialsResults) {
      obj.obtainDemoCredentialsResults = message.obtainDemoCredentialsResults.map((e) =>
        e ? ObtainDemoCredentialsResult.toJSON(e) : undefined
      );
    } else {
      obj.obtainDemoCredentialsResults = [];
    }
    if (message.userSessionStateResults) {
      obj.userSessionStateResults = message.userSessionStateResults.map((e) =>
        e ? UserSessionStateResult.toJSON(e) : undefined
      );
    } else {
      obj.userSessionStateResults = [];
    }
    message.ping !== undefined && (obj.ping = message.ping ? Ping.toJSON(message.ping) : undefined);
    message.pong !== undefined && (obj.pong = message.pong ? Pong.toJSON(message.pong) : undefined);
    message.logonRoutineServer !== undefined &&
      (obj.logonRoutineServer = message.logonRoutineServer ? LogonRoutineServer.toJSON(message.logonRoutineServer) : undefined);
    message.passwordChangeRoutineServer !== undefined &&
      (obj.passwordChangeRoutineServer = message.passwordChangeRoutineServer
        ? PasswordChangeRoutineServer.toJSON(message.passwordChangeRoutineServer)
        : undefined);
    if (message.setCollapsingLevelResults) {
      obj.setCollapsingLevelResults = message.setCollapsingLevelResults.map((e) =>
        e ? SetCollapsingLevelResult.toJSON(e) : undefined
      );
    } else {
      obj.setCollapsingLevelResults = [];
    }
    if (message.informationReports) {
      obj.informationReports = message.informationReports.map((e) => (e ? InformationReport.toJSON(e) : undefined));
    } else {
      obj.informationReports = [];
    }
    if (message.orderRequestRejects) {
      obj.orderRequestRejects = message.orderRequestRejects.map((e) => (e ? OrderRequestReject.toJSON(e) : undefined));
    } else {
      obj.orderRequestRejects = [];
    }
    if (message.orderRequestAcks) {
      obj.orderRequestAcks = message.orderRequestAcks.map((e) => (e ? OrderRequestAck.toJSON(e) : undefined));
    } else {
      obj.orderRequestAcks = [];
    }
    if (message.tradeSubscriptionStatuses) {
      obj.tradeSubscriptionStatuses = message.tradeSubscriptionStatuses.map((e) =>
        e ? TradeSubscriptionStatus.toJSON(e) : undefined
      );
    } else {
      obj.tradeSubscriptionStatuses = [];
    }
    if (message.tradeSnapshotCompletions) {
      obj.tradeSnapshotCompletions = message.tradeSnapshotCompletions.map((e) =>
        e ? TradeSnapshotCompletion.toJSON(e) : undefined
      );
    } else {
      obj.tradeSnapshotCompletions = [];
    }
    if (message.orderStatuses) {
      obj.orderStatuses = message.orderStatuses.map((e) => (e ? OrderStatus.toJSON(e) : undefined));
    } else {
      obj.orderStatuses = [];
    }
    if (message.positionStatuses) {
      obj.positionStatuses = message.positionStatuses.map((e) => (e ? PositionStatus.toJSON(e) : undefined));
    } else {
      obj.positionStatuses = [];
    }
    if (message.collateralStatuses) {
      obj.collateralStatuses = message.collateralStatuses.map((e) => (e ? CollateralStatus.toJSON(e) : undefined));
    } else {
      obj.collateralStatuses = [];
    }
    if (message.accountSummaryStatuses) {
      obj.accountSummaryStatuses = message.accountSummaryStatuses.map((e) => (e ? AccountSummaryStatus.toJSON(e) : undefined));
    } else {
      obj.accountSummaryStatuses = [];
    }
    if (message.exchangePositionStatuses) {
      obj.exchangePositionStatuses = message.exchangePositionStatuses.map((e) =>
        e ? ExchangePositionStatus.toJSON(e) : undefined
      );
    } else {
      obj.exchangePositionStatuses = [];
    }
    if (message.exchangeBalanceStatuses) {
      obj.exchangeBalanceStatuses = message.exchangeBalanceStatuses.map((e) => (e ? ExchangeBalanceStatus.toJSON(e) : undefined));
    } else {
      obj.exchangeBalanceStatuses = [];
    }
    if (message.marketDataSubscriptionStatuses) {
      obj.marketDataSubscriptionStatuses = message.marketDataSubscriptionStatuses.map((e) =>
        e ? MarketDataSubscriptionStatus.toJSON(e) : undefined
      );
    } else {
      obj.marketDataSubscriptionStatuses = [];
    }
    if (message.realTimeMarketData) {
      obj.realTimeMarketData = message.realTimeMarketData.map((e) => (e ? RealTimeMarketData.toJSON(e) : undefined));
    } else {
      obj.realTimeMarketData = [];
    }
    if (message.timeAndSalesReports) {
      obj.timeAndSalesReports = message.timeAndSalesReports.map((e) => (e ? TimeAndSalesReport.toJSON(e) : undefined));
    } else {
      obj.timeAndSalesReports = [];
    }
    if (message.timeBarReports) {
      obj.timeBarReports = message.timeBarReports.map((e) => (e ? TimeBarReport.toJSON(e) : undefined));
    } else {
      obj.timeBarReports = [];
    }
    if (message.volumeProfileReports) {
      obj.volumeProfileReports = message.volumeProfileReports.map((e) => (e ? VolumeProfileReport.toJSON(e) : undefined));
    } else {
      obj.volumeProfileReports = [];
    }
    if (message.nonTimedBarReports) {
      obj.nonTimedBarReports = message.nonTimedBarReports.map((e) => (e ? NonTimedBarReport.toJSON(e) : undefined));
    } else {
      obj.nonTimedBarReports = [];
    }
    if (message.ruleResults) {
      obj.ruleResults = message.ruleResults.map((e) => (e ? RuleResult.toJSON(e) : undefined));
    } else {
      obj.ruleResults = [];
    }
    if (message.accountLogonResults) {
      obj.accountLogonResults = message.accountLogonResults.map((e) => (e ? AccountLogonResult.toJSON(e) : undefined));
    } else {
      obj.accountLogonResults = [];
    }
    if (message.accountPasswordChangeResults) {
      obj.accountPasswordChangeResults = message.accountPasswordChangeResults.map((e) =>
        e ? AccountPasswordChangeResult.toJSON(e) : undefined
      );
    } else {
      obj.accountPasswordChangeResults = [];
    }
    if (message.accountLogoffResults) {
      obj.accountLogoffResults = message.accountLogoffResults.map((e) => (e ? AccountLogoffResult.toJSON(e) : undefined));
    } else {
      obj.accountLogoffResults = [];
    }
    if (message.accountLogonRoutinesServer) {
      obj.accountLogonRoutinesServer = message.accountLogonRoutinesServer.map((e) =>
        e ? AccountLogonRoutineServer.toJSON(e) : undefined
      );
    } else {
      obj.accountLogonRoutinesServer = [];
    }
    if (message.accountPasswordChangeRoutinesServer) {
      obj.accountPasswordChangeRoutinesServer = message.accountPasswordChangeRoutinesServer.map((e) =>
        e ? AccountPasswordChangeRoutineServer.toJSON(e) : undefined
      );
    } else {
      obj.accountPasswordChangeRoutinesServer = [];
    }
    if (message.metadataAdminResults) {
      obj.metadataAdminResults = message.metadataAdminResults.map((e) => (e ? MetadataAdminResult.toJSON(e) : undefined));
    } else {
      obj.metadataAdminResults = [];
    }
    if (message.otcReports) {
      obj.otcReports = message.otcReports.map((e) => (e ? OtcReport.toJSON(e) : undefined));
    } else {
      obj.otcReports = [];
    }
    if (message.rfqReports) {
      obj.rfqReports = message.rfqReports.map((e) => (e ? RFQReport.toJSON(e) : undefined));
    } else {
      obj.rfqReports = [];
    }
    if (message.optionCalculationReports) {
      obj.optionCalculationReports = message.optionCalculationReports.map((e) =>
        e ? OptionCalculationReport.toJSON(e) : undefined
      );
    } else {
      obj.optionCalculationReports = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerMsg>, I>>(base?: I): ServerMsg {
    return ServerMsg.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ServerMsg>, I>>(object: I): ServerMsg {
    const message = createBaseServerMsg();
    message.logonResult =
      object.logonResult !== undefined && object.logonResult !== null ? LogonResult.fromPartial(object.logonResult) : undefined;
    message.restoreOrJoinSessionResult =
      object.restoreOrJoinSessionResult !== undefined && object.restoreOrJoinSessionResult !== null
        ? RestoreOrJoinSessionResult.fromPartial(object.restoreOrJoinSessionResult)
        : undefined;
    message.concurrentConnectionJoinResults =
      object.concurrentConnectionJoinResults?.map((e) => ConcurrentConnectionJoin.fromPartial(e)) || [];
    message.loggedOff =
      object.loggedOff !== undefined && object.loggedOff !== null ? LoggedOff.fromPartial(object.loggedOff) : undefined;
    message.passwordChangeResult =
      object.passwordChangeResult !== undefined && object.passwordChangeResult !== null
        ? PasswordChangeResult.fromPartial(object.passwordChangeResult)
        : undefined;
    message.userMessages = object.userMessages?.map((e) => UserMessage.fromPartial(e)) || [];
    message.obtainDemoCredentialsResults =
      object.obtainDemoCredentialsResults?.map((e) => ObtainDemoCredentialsResult.fromPartial(e)) || [];
    message.userSessionStateResults = object.userSessionStateResults?.map((e) => UserSessionStateResult.fromPartial(e)) || [];
    message.ping = object.ping !== undefined && object.ping !== null ? Ping.fromPartial(object.ping) : undefined;
    message.pong = object.pong !== undefined && object.pong !== null ? Pong.fromPartial(object.pong) : undefined;
    message.logonRoutineServer =
      object.logonRoutineServer !== undefined && object.logonRoutineServer !== null
        ? LogonRoutineServer.fromPartial(object.logonRoutineServer)
        : undefined;
    message.passwordChangeRoutineServer =
      object.passwordChangeRoutineServer !== undefined && object.passwordChangeRoutineServer !== null
        ? PasswordChangeRoutineServer.fromPartial(object.passwordChangeRoutineServer)
        : undefined;
    message.setCollapsingLevelResults =
      object.setCollapsingLevelResults?.map((e) => SetCollapsingLevelResult.fromPartial(e)) || [];
    message.informationReports = object.informationReports?.map((e) => InformationReport.fromPartial(e)) || [];
    message.orderRequestRejects = object.orderRequestRejects?.map((e) => OrderRequestReject.fromPartial(e)) || [];
    message.orderRequestAcks = object.orderRequestAcks?.map((e) => OrderRequestAck.fromPartial(e)) || [];
    message.tradeSubscriptionStatuses =
      object.tradeSubscriptionStatuses?.map((e) => TradeSubscriptionStatus.fromPartial(e)) || [];
    message.tradeSnapshotCompletions = object.tradeSnapshotCompletions?.map((e) => TradeSnapshotCompletion.fromPartial(e)) || [];
    message.orderStatuses = object.orderStatuses?.map((e) => OrderStatus.fromPartial(e)) || [];
    message.positionStatuses = object.positionStatuses?.map((e) => PositionStatus.fromPartial(e)) || [];
    message.collateralStatuses = object.collateralStatuses?.map((e) => CollateralStatus.fromPartial(e)) || [];
    message.accountSummaryStatuses = object.accountSummaryStatuses?.map((e) => AccountSummaryStatus.fromPartial(e)) || [];
    message.exchangePositionStatuses = object.exchangePositionStatuses?.map((e) => ExchangePositionStatus.fromPartial(e)) || [];
    message.exchangeBalanceStatuses = object.exchangeBalanceStatuses?.map((e) => ExchangeBalanceStatus.fromPartial(e)) || [];
    message.marketDataSubscriptionStatuses =
      object.marketDataSubscriptionStatuses?.map((e) => MarketDataSubscriptionStatus.fromPartial(e)) || [];
    message.realTimeMarketData = object.realTimeMarketData?.map((e) => RealTimeMarketData.fromPartial(e)) || [];
    message.timeAndSalesReports = object.timeAndSalesReports?.map((e) => TimeAndSalesReport.fromPartial(e)) || [];
    message.timeBarReports = object.timeBarReports?.map((e) => TimeBarReport.fromPartial(e)) || [];
    message.volumeProfileReports = object.volumeProfileReports?.map((e) => VolumeProfileReport.fromPartial(e)) || [];
    message.nonTimedBarReports = object.nonTimedBarReports?.map((e) => NonTimedBarReport.fromPartial(e)) || [];
    message.ruleResults = object.ruleResults?.map((e) => RuleResult.fromPartial(e)) || [];
    message.accountLogonResults = object.accountLogonResults?.map((e) => AccountLogonResult.fromPartial(e)) || [];
    message.accountPasswordChangeResults =
      object.accountPasswordChangeResults?.map((e) => AccountPasswordChangeResult.fromPartial(e)) || [];
    message.accountLogoffResults = object.accountLogoffResults?.map((e) => AccountLogoffResult.fromPartial(e)) || [];
    message.accountLogonRoutinesServer =
      object.accountLogonRoutinesServer?.map((e) => AccountLogonRoutineServer.fromPartial(e)) || [];
    message.accountPasswordChangeRoutinesServer =
      object.accountPasswordChangeRoutinesServer?.map((e) => AccountPasswordChangeRoutineServer.fromPartial(e)) || [];
    message.metadataAdminResults = object.metadataAdminResults?.map((e) => MetadataAdminResult.fromPartial(e)) || [];
    message.otcReports = object.otcReports?.map((e) => OtcReport.fromPartial(e)) || [];
    message.rfqReports = object.rfqReports?.map((e) => RFQReport.fromPartial(e)) || [];
    message.optionCalculationReports = object.optionCalculationReports?.map((e) => OptionCalculationReport.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInformationRequest(): InformationRequest {
  return {
    id: 0,
    subscribe: false,
    accountsRequest: undefined,
    symbolResolutionRequest: undefined,
    lastStatementBalancesRequest: undefined,
    currencyRatesRequest: undefined,
    currencyRateSourcesRequest: undefined,
    sessionInformationRequest: undefined,
    historicalOrdersRequest: undefined,
    optionMaturityListRequest: undefined,
    instrumentGroupRequest: undefined,
    atTheMoneyStrikeRequest: undefined,
    strategyDefinitionRequest: undefined,
    sessionTimerangeRequest: undefined,
    tradingDayTimerangeRequest: undefined,
    orderEntitlementRequest: undefined,
    symbolCategoryListRequest: undefined,
    symbolCategoryRequest: undefined,
    symbolCategoryListByInstrumentTypeRequest: undefined,
    symbolListRequest: undefined,
    symbolRequest: undefined,
    contractMetadataRequest: undefined,
    algoStrategyDefinitionRequest: undefined,
    contributorMetadataRequest: undefined,
    apiLimitRequest: undefined,
    brokerageTradingFeatureEntitlementRequest: undefined,
    orderStatusRequest: undefined,
    productSearchRequest: undefined,
    marketStateMetadataRequest: undefined,
    instrumentDefinitionRequest: undefined,
    exchangeMetadataRequest: undefined,
    instrumentGroupBySecuritiesRequest: undefined,
    instrumentGroupByExchangeRequest: undefined,
    exchangeSecuritiesRequest: undefined,
    careAutoActivationParametersRequest: undefined,
  };
}

export const InformationRequest = {
  encode(message: InformationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.subscribe === true) {
      writer.uint32(16).bool(message.subscribe);
    }
    if (message.accountsRequest !== undefined) {
      AccountsRequest.encode(message.accountsRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.symbolResolutionRequest !== undefined) {
      SymbolResolutionRequest.encode(message.symbolResolutionRequest, writer.uint32(34).fork()).ldelim();
    }
    if (message.lastStatementBalancesRequest !== undefined) {
      LastStatementBalancesRequest.encode(message.lastStatementBalancesRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.currencyRatesRequest !== undefined) {
      CurrencyRatesRequest.encode(message.currencyRatesRequest, writer.uint32(50).fork()).ldelim();
    }
    if (message.currencyRateSourcesRequest !== undefined) {
      CurrencyRateSourcesRequest.encode(message.currencyRateSourcesRequest, writer.uint32(330).fork()).ldelim();
    }
    if (message.sessionInformationRequest !== undefined) {
      SessionInformationRequest.encode(message.sessionInformationRequest, writer.uint32(58).fork()).ldelim();
    }
    if (message.historicalOrdersRequest !== undefined) {
      HistoricalOrdersRequest.encode(message.historicalOrdersRequest, writer.uint32(106).fork()).ldelim();
    }
    if (message.optionMaturityListRequest !== undefined) {
      OptionMaturityListRequest.encode(message.optionMaturityListRequest, writer.uint32(114).fork()).ldelim();
    }
    if (message.instrumentGroupRequest !== undefined) {
      InstrumentGroupRequest.encode(message.instrumentGroupRequest, writer.uint32(122).fork()).ldelim();
    }
    if (message.atTheMoneyStrikeRequest !== undefined) {
      AtTheMoneyStrikeRequest.encode(message.atTheMoneyStrikeRequest, writer.uint32(130).fork()).ldelim();
    }
    if (message.strategyDefinitionRequest !== undefined) {
      StrategyDefinitionRequest.encode(message.strategyDefinitionRequest, writer.uint32(138).fork()).ldelim();
    }
    if (message.sessionTimerangeRequest !== undefined) {
      SessionTimeRangeRequest.encode(message.sessionTimerangeRequest, writer.uint32(146).fork()).ldelim();
    }
    if (message.tradingDayTimerangeRequest !== undefined) {
      TradingDayTimeRangeRequest.encode(message.tradingDayTimerangeRequest, writer.uint32(154).fork()).ldelim();
    }
    if (message.orderEntitlementRequest !== undefined) {
      OrderEntitlementRequest.encode(message.orderEntitlementRequest, writer.uint32(162).fork()).ldelim();
    }
    if (message.symbolCategoryListRequest !== undefined) {
      SymbolCategoryListRequest.encode(message.symbolCategoryListRequest, writer.uint32(170).fork()).ldelim();
    }
    if (message.symbolCategoryRequest !== undefined) {
      SymbolCategoryRequest.encode(message.symbolCategoryRequest, writer.uint32(218).fork()).ldelim();
    }
    if (message.symbolCategoryListByInstrumentTypeRequest !== undefined) {
      SymbolCategoryListByInstrumentTypeRequest.encode(
        message.symbolCategoryListByInstrumentTypeRequest,
        writer.uint32(266).fork()
      ).ldelim();
    }
    if (message.symbolListRequest !== undefined) {
      SymbolListRequest.encode(message.symbolListRequest, writer.uint32(178).fork()).ldelim();
    }
    if (message.symbolRequest !== undefined) {
      SymbolRequest.encode(message.symbolRequest, writer.uint32(186).fork()).ldelim();
    }
    if (message.contractMetadataRequest !== undefined) {
      ContractMetadataRequest.encode(message.contractMetadataRequest, writer.uint32(202).fork()).ldelim();
    }
    if (message.algoStrategyDefinitionRequest !== undefined) {
      AlgoStrategyDefinitionRequest.encode(message.algoStrategyDefinitionRequest, writer.uint32(210).fork()).ldelim();
    }
    if (message.contributorMetadataRequest !== undefined) {
      ContributorMetadataRequest.encode(message.contributorMetadataRequest, writer.uint32(226).fork()).ldelim();
    }
    if (message.apiLimitRequest !== undefined) {
      ApiLimitRequest.encode(message.apiLimitRequest, writer.uint32(234).fork()).ldelim();
    }
    if (message.brokerageTradingFeatureEntitlementRequest !== undefined) {
      BrokerageTradingFeatureEntitlementRequest.encode(
        message.brokerageTradingFeatureEntitlementRequest,
        writer.uint32(242).fork()
      ).ldelim();
    }
    if (message.orderStatusRequest !== undefined) {
      OrderStatusRequest.encode(message.orderStatusRequest, writer.uint32(250).fork()).ldelim();
    }
    if (message.productSearchRequest !== undefined) {
      ProductSearchRequest.encode(message.productSearchRequest, writer.uint32(258).fork()).ldelim();
    }
    if (message.marketStateMetadataRequest !== undefined) {
      MarketStateMetadataRequest.encode(message.marketStateMetadataRequest, writer.uint32(274).fork()).ldelim();
    }
    if (message.instrumentDefinitionRequest !== undefined) {
      InstrumentDefinitionRequest.encode(message.instrumentDefinitionRequest, writer.uint32(282).fork()).ldelim();
    }
    if (message.exchangeMetadataRequest !== undefined) {
      ExchangeMetadataRequest.encode(message.exchangeMetadataRequest, writer.uint32(290).fork()).ldelim();
    }
    if (message.instrumentGroupBySecuritiesRequest !== undefined) {
      InstrumentGroupBySecuritiesRequest.encode(message.instrumentGroupBySecuritiesRequest, writer.uint32(298).fork()).ldelim();
    }
    if (message.instrumentGroupByExchangeRequest !== undefined) {
      InstrumentGroupByExchangeRequest.encode(message.instrumentGroupByExchangeRequest, writer.uint32(306).fork()).ldelim();
    }
    if (message.exchangeSecuritiesRequest !== undefined) {
      ExchangeSecuritiesRequest.encode(message.exchangeSecuritiesRequest, writer.uint32(314).fork()).ldelim();
    }
    if (message.careAutoActivationParametersRequest !== undefined) {
      CareAutoActivationParametersRequest.encode(message.careAutoActivationParametersRequest, writer.uint32(322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InformationRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInformationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.subscribe = reader.bool();
          break;
        case 3:
          message.accountsRequest = AccountsRequest.decode(reader, reader.uint32());
          break;
        case 4:
          message.symbolResolutionRequest = SymbolResolutionRequest.decode(reader, reader.uint32());
          break;
        case 5:
          message.lastStatementBalancesRequest = LastStatementBalancesRequest.decode(reader, reader.uint32());
          break;
        case 6:
          message.currencyRatesRequest = CurrencyRatesRequest.decode(reader, reader.uint32());
          break;
        case 41:
          message.currencyRateSourcesRequest = CurrencyRateSourcesRequest.decode(reader, reader.uint32());
          break;
        case 7:
          message.sessionInformationRequest = SessionInformationRequest.decode(reader, reader.uint32());
          break;
        case 13:
          message.historicalOrdersRequest = HistoricalOrdersRequest.decode(reader, reader.uint32());
          break;
        case 14:
          message.optionMaturityListRequest = OptionMaturityListRequest.decode(reader, reader.uint32());
          break;
        case 15:
          message.instrumentGroupRequest = InstrumentGroupRequest.decode(reader, reader.uint32());
          break;
        case 16:
          message.atTheMoneyStrikeRequest = AtTheMoneyStrikeRequest.decode(reader, reader.uint32());
          break;
        case 17:
          message.strategyDefinitionRequest = StrategyDefinitionRequest.decode(reader, reader.uint32());
          break;
        case 18:
          message.sessionTimerangeRequest = SessionTimeRangeRequest.decode(reader, reader.uint32());
          break;
        case 19:
          message.tradingDayTimerangeRequest = TradingDayTimeRangeRequest.decode(reader, reader.uint32());
          break;
        case 20:
          message.orderEntitlementRequest = OrderEntitlementRequest.decode(reader, reader.uint32());
          break;
        case 21:
          message.symbolCategoryListRequest = SymbolCategoryListRequest.decode(reader, reader.uint32());
          break;
        case 27:
          message.symbolCategoryRequest = SymbolCategoryRequest.decode(reader, reader.uint32());
          break;
        case 33:
          message.symbolCategoryListByInstrumentTypeRequest = SymbolCategoryListByInstrumentTypeRequest.decode(
            reader,
            reader.uint32()
          );
          break;
        case 22:
          message.symbolListRequest = SymbolListRequest.decode(reader, reader.uint32());
          break;
        case 23:
          message.symbolRequest = SymbolRequest.decode(reader, reader.uint32());
          break;
        case 25:
          message.contractMetadataRequest = ContractMetadataRequest.decode(reader, reader.uint32());
          break;
        case 26:
          message.algoStrategyDefinitionRequest = AlgoStrategyDefinitionRequest.decode(reader, reader.uint32());
          break;
        case 28:
          message.contributorMetadataRequest = ContributorMetadataRequest.decode(reader, reader.uint32());
          break;
        case 29:
          message.apiLimitRequest = ApiLimitRequest.decode(reader, reader.uint32());
          break;
        case 30:
          message.brokerageTradingFeatureEntitlementRequest = BrokerageTradingFeatureEntitlementRequest.decode(
            reader,
            reader.uint32()
          );
          break;
        case 31:
          message.orderStatusRequest = OrderStatusRequest.decode(reader, reader.uint32());
          break;
        case 32:
          message.productSearchRequest = ProductSearchRequest.decode(reader, reader.uint32());
          break;
        case 34:
          message.marketStateMetadataRequest = MarketStateMetadataRequest.decode(reader, reader.uint32());
          break;
        case 35:
          message.instrumentDefinitionRequest = InstrumentDefinitionRequest.decode(reader, reader.uint32());
          break;
        case 36:
          message.exchangeMetadataRequest = ExchangeMetadataRequest.decode(reader, reader.uint32());
          break;
        case 37:
          message.instrumentGroupBySecuritiesRequest = InstrumentGroupBySecuritiesRequest.decode(reader, reader.uint32());
          break;
        case 38:
          message.instrumentGroupByExchangeRequest = InstrumentGroupByExchangeRequest.decode(reader, reader.uint32());
          break;
        case 39:
          message.exchangeSecuritiesRequest = ExchangeSecuritiesRequest.decode(reader, reader.uint32());
          break;
        case 40:
          message.careAutoActivationParametersRequest = CareAutoActivationParametersRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InformationRequest {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      subscribe: isSet(object.subscribe) ? Boolean(object.subscribe) : false,
      accountsRequest: isSet(object.accountsRequest) ? AccountsRequest.fromJSON(object.accountsRequest) : undefined,
      symbolResolutionRequest: isSet(object.symbolResolutionRequest)
        ? SymbolResolutionRequest.fromJSON(object.symbolResolutionRequest)
        : undefined,
      lastStatementBalancesRequest: isSet(object.lastStatementBalancesRequest)
        ? LastStatementBalancesRequest.fromJSON(object.lastStatementBalancesRequest)
        : undefined,
      currencyRatesRequest: isSet(object.currencyRatesRequest)
        ? CurrencyRatesRequest.fromJSON(object.currencyRatesRequest)
        : undefined,
      currencyRateSourcesRequest: isSet(object.currencyRateSourcesRequest)
        ? CurrencyRateSourcesRequest.fromJSON(object.currencyRateSourcesRequest)
        : undefined,
      sessionInformationRequest: isSet(object.sessionInformationRequest)
        ? SessionInformationRequest.fromJSON(object.sessionInformationRequest)
        : undefined,
      historicalOrdersRequest: isSet(object.historicalOrdersRequest)
        ? HistoricalOrdersRequest.fromJSON(object.historicalOrdersRequest)
        : undefined,
      optionMaturityListRequest: isSet(object.optionMaturityListRequest)
        ? OptionMaturityListRequest.fromJSON(object.optionMaturityListRequest)
        : undefined,
      instrumentGroupRequest: isSet(object.instrumentGroupRequest)
        ? InstrumentGroupRequest.fromJSON(object.instrumentGroupRequest)
        : undefined,
      atTheMoneyStrikeRequest: isSet(object.atTheMoneyStrikeRequest)
        ? AtTheMoneyStrikeRequest.fromJSON(object.atTheMoneyStrikeRequest)
        : undefined,
      strategyDefinitionRequest: isSet(object.strategyDefinitionRequest)
        ? StrategyDefinitionRequest.fromJSON(object.strategyDefinitionRequest)
        : undefined,
      sessionTimerangeRequest: isSet(object.sessionTimerangeRequest)
        ? SessionTimeRangeRequest.fromJSON(object.sessionTimerangeRequest)
        : undefined,
      tradingDayTimerangeRequest: isSet(object.tradingDayTimerangeRequest)
        ? TradingDayTimeRangeRequest.fromJSON(object.tradingDayTimerangeRequest)
        : undefined,
      orderEntitlementRequest: isSet(object.orderEntitlementRequest)
        ? OrderEntitlementRequest.fromJSON(object.orderEntitlementRequest)
        : undefined,
      symbolCategoryListRequest: isSet(object.symbolCategoryListRequest)
        ? SymbolCategoryListRequest.fromJSON(object.symbolCategoryListRequest)
        : undefined,
      symbolCategoryRequest: isSet(object.symbolCategoryRequest)
        ? SymbolCategoryRequest.fromJSON(object.symbolCategoryRequest)
        : undefined,
      symbolCategoryListByInstrumentTypeRequest: isSet(object.symbolCategoryListByInstrumentTypeRequest)
        ? SymbolCategoryListByInstrumentTypeRequest.fromJSON(object.symbolCategoryListByInstrumentTypeRequest)
        : undefined,
      symbolListRequest: isSet(object.symbolListRequest) ? SymbolListRequest.fromJSON(object.symbolListRequest) : undefined,
      symbolRequest: isSet(object.symbolRequest) ? SymbolRequest.fromJSON(object.symbolRequest) : undefined,
      contractMetadataRequest: isSet(object.contractMetadataRequest)
        ? ContractMetadataRequest.fromJSON(object.contractMetadataRequest)
        : undefined,
      algoStrategyDefinitionRequest: isSet(object.algoStrategyDefinitionRequest)
        ? AlgoStrategyDefinitionRequest.fromJSON(object.algoStrategyDefinitionRequest)
        : undefined,
      contributorMetadataRequest: isSet(object.contributorMetadataRequest)
        ? ContributorMetadataRequest.fromJSON(object.contributorMetadataRequest)
        : undefined,
      apiLimitRequest: isSet(object.apiLimitRequest) ? ApiLimitRequest.fromJSON(object.apiLimitRequest) : undefined,
      brokerageTradingFeatureEntitlementRequest: isSet(object.brokerageTradingFeatureEntitlementRequest)
        ? BrokerageTradingFeatureEntitlementRequest.fromJSON(object.brokerageTradingFeatureEntitlementRequest)
        : undefined,
      orderStatusRequest: isSet(object.orderStatusRequest) ? OrderStatusRequest.fromJSON(object.orderStatusRequest) : undefined,
      productSearchRequest: isSet(object.productSearchRequest)
        ? ProductSearchRequest.fromJSON(object.productSearchRequest)
        : undefined,
      marketStateMetadataRequest: isSet(object.marketStateMetadataRequest)
        ? MarketStateMetadataRequest.fromJSON(object.marketStateMetadataRequest)
        : undefined,
      instrumentDefinitionRequest: isSet(object.instrumentDefinitionRequest)
        ? InstrumentDefinitionRequest.fromJSON(object.instrumentDefinitionRequest)
        : undefined,
      exchangeMetadataRequest: isSet(object.exchangeMetadataRequest)
        ? ExchangeMetadataRequest.fromJSON(object.exchangeMetadataRequest)
        : undefined,
      instrumentGroupBySecuritiesRequest: isSet(object.instrumentGroupBySecuritiesRequest)
        ? InstrumentGroupBySecuritiesRequest.fromJSON(object.instrumentGroupBySecuritiesRequest)
        : undefined,
      instrumentGroupByExchangeRequest: isSet(object.instrumentGroupByExchangeRequest)
        ? InstrumentGroupByExchangeRequest.fromJSON(object.instrumentGroupByExchangeRequest)
        : undefined,
      exchangeSecuritiesRequest: isSet(object.exchangeSecuritiesRequest)
        ? ExchangeSecuritiesRequest.fromJSON(object.exchangeSecuritiesRequest)
        : undefined,
      careAutoActivationParametersRequest: isSet(object.careAutoActivationParametersRequest)
        ? CareAutoActivationParametersRequest.fromJSON(object.careAutoActivationParametersRequest)
        : undefined,
    };
  },

  toJSON(message: InformationRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.subscribe !== undefined && (obj.subscribe = message.subscribe);
    message.accountsRequest !== undefined &&
      (obj.accountsRequest = message.accountsRequest ? AccountsRequest.toJSON(message.accountsRequest) : undefined);
    message.symbolResolutionRequest !== undefined &&
      (obj.symbolResolutionRequest = message.symbolResolutionRequest
        ? SymbolResolutionRequest.toJSON(message.symbolResolutionRequest)
        : undefined);
    message.lastStatementBalancesRequest !== undefined &&
      (obj.lastStatementBalancesRequest = message.lastStatementBalancesRequest
        ? LastStatementBalancesRequest.toJSON(message.lastStatementBalancesRequest)
        : undefined);
    message.currencyRatesRequest !== undefined &&
      (obj.currencyRatesRequest = message.currencyRatesRequest
        ? CurrencyRatesRequest.toJSON(message.currencyRatesRequest)
        : undefined);
    message.currencyRateSourcesRequest !== undefined &&
      (obj.currencyRateSourcesRequest = message.currencyRateSourcesRequest
        ? CurrencyRateSourcesRequest.toJSON(message.currencyRateSourcesRequest)
        : undefined);
    message.sessionInformationRequest !== undefined &&
      (obj.sessionInformationRequest = message.sessionInformationRequest
        ? SessionInformationRequest.toJSON(message.sessionInformationRequest)
        : undefined);
    message.historicalOrdersRequest !== undefined &&
      (obj.historicalOrdersRequest = message.historicalOrdersRequest
        ? HistoricalOrdersRequest.toJSON(message.historicalOrdersRequest)
        : undefined);
    message.optionMaturityListRequest !== undefined &&
      (obj.optionMaturityListRequest = message.optionMaturityListRequest
        ? OptionMaturityListRequest.toJSON(message.optionMaturityListRequest)
        : undefined);
    message.instrumentGroupRequest !== undefined &&
      (obj.instrumentGroupRequest = message.instrumentGroupRequest
        ? InstrumentGroupRequest.toJSON(message.instrumentGroupRequest)
        : undefined);
    message.atTheMoneyStrikeRequest !== undefined &&
      (obj.atTheMoneyStrikeRequest = message.atTheMoneyStrikeRequest
        ? AtTheMoneyStrikeRequest.toJSON(message.atTheMoneyStrikeRequest)
        : undefined);
    message.strategyDefinitionRequest !== undefined &&
      (obj.strategyDefinitionRequest = message.strategyDefinitionRequest
        ? StrategyDefinitionRequest.toJSON(message.strategyDefinitionRequest)
        : undefined);
    message.sessionTimerangeRequest !== undefined &&
      (obj.sessionTimerangeRequest = message.sessionTimerangeRequest
        ? SessionTimeRangeRequest.toJSON(message.sessionTimerangeRequest)
        : undefined);
    message.tradingDayTimerangeRequest !== undefined &&
      (obj.tradingDayTimerangeRequest = message.tradingDayTimerangeRequest
        ? TradingDayTimeRangeRequest.toJSON(message.tradingDayTimerangeRequest)
        : undefined);
    message.orderEntitlementRequest !== undefined &&
      (obj.orderEntitlementRequest = message.orderEntitlementRequest
        ? OrderEntitlementRequest.toJSON(message.orderEntitlementRequest)
        : undefined);
    message.symbolCategoryListRequest !== undefined &&
      (obj.symbolCategoryListRequest = message.symbolCategoryListRequest
        ? SymbolCategoryListRequest.toJSON(message.symbolCategoryListRequest)
        : undefined);
    message.symbolCategoryRequest !== undefined &&
      (obj.symbolCategoryRequest = message.symbolCategoryRequest
        ? SymbolCategoryRequest.toJSON(message.symbolCategoryRequest)
        : undefined);
    message.symbolCategoryListByInstrumentTypeRequest !== undefined &&
      (obj.symbolCategoryListByInstrumentTypeRequest = message.symbolCategoryListByInstrumentTypeRequest
        ? SymbolCategoryListByInstrumentTypeRequest.toJSON(message.symbolCategoryListByInstrumentTypeRequest)
        : undefined);
    message.symbolListRequest !== undefined &&
      (obj.symbolListRequest = message.symbolListRequest ? SymbolListRequest.toJSON(message.symbolListRequest) : undefined);
    message.symbolRequest !== undefined &&
      (obj.symbolRequest = message.symbolRequest ? SymbolRequest.toJSON(message.symbolRequest) : undefined);
    message.contractMetadataRequest !== undefined &&
      (obj.contractMetadataRequest = message.contractMetadataRequest
        ? ContractMetadataRequest.toJSON(message.contractMetadataRequest)
        : undefined);
    message.algoStrategyDefinitionRequest !== undefined &&
      (obj.algoStrategyDefinitionRequest = message.algoStrategyDefinitionRequest
        ? AlgoStrategyDefinitionRequest.toJSON(message.algoStrategyDefinitionRequest)
        : undefined);
    message.contributorMetadataRequest !== undefined &&
      (obj.contributorMetadataRequest = message.contributorMetadataRequest
        ? ContributorMetadataRequest.toJSON(message.contributorMetadataRequest)
        : undefined);
    message.apiLimitRequest !== undefined &&
      (obj.apiLimitRequest = message.apiLimitRequest ? ApiLimitRequest.toJSON(message.apiLimitRequest) : undefined);
    message.brokerageTradingFeatureEntitlementRequest !== undefined &&
      (obj.brokerageTradingFeatureEntitlementRequest = message.brokerageTradingFeatureEntitlementRequest
        ? BrokerageTradingFeatureEntitlementRequest.toJSON(message.brokerageTradingFeatureEntitlementRequest)
        : undefined);
    message.orderStatusRequest !== undefined &&
      (obj.orderStatusRequest = message.orderStatusRequest ? OrderStatusRequest.toJSON(message.orderStatusRequest) : undefined);
    message.productSearchRequest !== undefined &&
      (obj.productSearchRequest = message.productSearchRequest
        ? ProductSearchRequest.toJSON(message.productSearchRequest)
        : undefined);
    message.marketStateMetadataRequest !== undefined &&
      (obj.marketStateMetadataRequest = message.marketStateMetadataRequest
        ? MarketStateMetadataRequest.toJSON(message.marketStateMetadataRequest)
        : undefined);
    message.instrumentDefinitionRequest !== undefined &&
      (obj.instrumentDefinitionRequest = message.instrumentDefinitionRequest
        ? InstrumentDefinitionRequest.toJSON(message.instrumentDefinitionRequest)
        : undefined);
    message.exchangeMetadataRequest !== undefined &&
      (obj.exchangeMetadataRequest = message.exchangeMetadataRequest
        ? ExchangeMetadataRequest.toJSON(message.exchangeMetadataRequest)
        : undefined);
    message.instrumentGroupBySecuritiesRequest !== undefined &&
      (obj.instrumentGroupBySecuritiesRequest = message.instrumentGroupBySecuritiesRequest
        ? InstrumentGroupBySecuritiesRequest.toJSON(message.instrumentGroupBySecuritiesRequest)
        : undefined);
    message.instrumentGroupByExchangeRequest !== undefined &&
      (obj.instrumentGroupByExchangeRequest = message.instrumentGroupByExchangeRequest
        ? InstrumentGroupByExchangeRequest.toJSON(message.instrumentGroupByExchangeRequest)
        : undefined);
    message.exchangeSecuritiesRequest !== undefined &&
      (obj.exchangeSecuritiesRequest = message.exchangeSecuritiesRequest
        ? ExchangeSecuritiesRequest.toJSON(message.exchangeSecuritiesRequest)
        : undefined);
    message.careAutoActivationParametersRequest !== undefined &&
      (obj.careAutoActivationParametersRequest = message.careAutoActivationParametersRequest
        ? CareAutoActivationParametersRequest.toJSON(message.careAutoActivationParametersRequest)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InformationRequest>, I>>(base?: I): InformationRequest {
    return InformationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InformationRequest>, I>>(object: I): InformationRequest {
    const message = createBaseInformationRequest();
    message.id = object.id ?? 0;
    message.subscribe = object.subscribe ?? false;
    message.accountsRequest =
      object.accountsRequest !== undefined && object.accountsRequest !== null
        ? AccountsRequest.fromPartial(object.accountsRequest)
        : undefined;
    message.symbolResolutionRequest =
      object.symbolResolutionRequest !== undefined && object.symbolResolutionRequest !== null
        ? SymbolResolutionRequest.fromPartial(object.symbolResolutionRequest)
        : undefined;
    message.lastStatementBalancesRequest =
      object.lastStatementBalancesRequest !== undefined && object.lastStatementBalancesRequest !== null
        ? LastStatementBalancesRequest.fromPartial(object.lastStatementBalancesRequest)
        : undefined;
    message.currencyRatesRequest =
      object.currencyRatesRequest !== undefined && object.currencyRatesRequest !== null
        ? CurrencyRatesRequest.fromPartial(object.currencyRatesRequest)
        : undefined;
    message.currencyRateSourcesRequest =
      object.currencyRateSourcesRequest !== undefined && object.currencyRateSourcesRequest !== null
        ? CurrencyRateSourcesRequest.fromPartial(object.currencyRateSourcesRequest)
        : undefined;
    message.sessionInformationRequest =
      object.sessionInformationRequest !== undefined && object.sessionInformationRequest !== null
        ? SessionInformationRequest.fromPartial(object.sessionInformationRequest)
        : undefined;
    message.historicalOrdersRequest =
      object.historicalOrdersRequest !== undefined && object.historicalOrdersRequest !== null
        ? HistoricalOrdersRequest.fromPartial(object.historicalOrdersRequest)
        : undefined;
    message.optionMaturityListRequest =
      object.optionMaturityListRequest !== undefined && object.optionMaturityListRequest !== null
        ? OptionMaturityListRequest.fromPartial(object.optionMaturityListRequest)
        : undefined;
    message.instrumentGroupRequest =
      object.instrumentGroupRequest !== undefined && object.instrumentGroupRequest !== null
        ? InstrumentGroupRequest.fromPartial(object.instrumentGroupRequest)
        : undefined;
    message.atTheMoneyStrikeRequest =
      object.atTheMoneyStrikeRequest !== undefined && object.atTheMoneyStrikeRequest !== null
        ? AtTheMoneyStrikeRequest.fromPartial(object.atTheMoneyStrikeRequest)
        : undefined;
    message.strategyDefinitionRequest =
      object.strategyDefinitionRequest !== undefined && object.strategyDefinitionRequest !== null
        ? StrategyDefinitionRequest.fromPartial(object.strategyDefinitionRequest)
        : undefined;
    message.sessionTimerangeRequest =
      object.sessionTimerangeRequest !== undefined && object.sessionTimerangeRequest !== null
        ? SessionTimeRangeRequest.fromPartial(object.sessionTimerangeRequest)
        : undefined;
    message.tradingDayTimerangeRequest =
      object.tradingDayTimerangeRequest !== undefined && object.tradingDayTimerangeRequest !== null
        ? TradingDayTimeRangeRequest.fromPartial(object.tradingDayTimerangeRequest)
        : undefined;
    message.orderEntitlementRequest =
      object.orderEntitlementRequest !== undefined && object.orderEntitlementRequest !== null
        ? OrderEntitlementRequest.fromPartial(object.orderEntitlementRequest)
        : undefined;
    message.symbolCategoryListRequest =
      object.symbolCategoryListRequest !== undefined && object.symbolCategoryListRequest !== null
        ? SymbolCategoryListRequest.fromPartial(object.symbolCategoryListRequest)
        : undefined;
    message.symbolCategoryRequest =
      object.symbolCategoryRequest !== undefined && object.symbolCategoryRequest !== null
        ? SymbolCategoryRequest.fromPartial(object.symbolCategoryRequest)
        : undefined;
    message.symbolCategoryListByInstrumentTypeRequest =
      object.symbolCategoryListByInstrumentTypeRequest !== undefined && object.symbolCategoryListByInstrumentTypeRequest !== null
        ? SymbolCategoryListByInstrumentTypeRequest.fromPartial(object.symbolCategoryListByInstrumentTypeRequest)
        : undefined;
    message.symbolListRequest =
      object.symbolListRequest !== undefined && object.symbolListRequest !== null
        ? SymbolListRequest.fromPartial(object.symbolListRequest)
        : undefined;
    message.symbolRequest =
      object.symbolRequest !== undefined && object.symbolRequest !== null
        ? SymbolRequest.fromPartial(object.symbolRequest)
        : undefined;
    message.contractMetadataRequest =
      object.contractMetadataRequest !== undefined && object.contractMetadataRequest !== null
        ? ContractMetadataRequest.fromPartial(object.contractMetadataRequest)
        : undefined;
    message.algoStrategyDefinitionRequest =
      object.algoStrategyDefinitionRequest !== undefined && object.algoStrategyDefinitionRequest !== null
        ? AlgoStrategyDefinitionRequest.fromPartial(object.algoStrategyDefinitionRequest)
        : undefined;
    message.contributorMetadataRequest =
      object.contributorMetadataRequest !== undefined && object.contributorMetadataRequest !== null
        ? ContributorMetadataRequest.fromPartial(object.contributorMetadataRequest)
        : undefined;
    message.apiLimitRequest =
      object.apiLimitRequest !== undefined && object.apiLimitRequest !== null
        ? ApiLimitRequest.fromPartial(object.apiLimitRequest)
        : undefined;
    message.brokerageTradingFeatureEntitlementRequest =
      object.brokerageTradingFeatureEntitlementRequest !== undefined && object.brokerageTradingFeatureEntitlementRequest !== null
        ? BrokerageTradingFeatureEntitlementRequest.fromPartial(object.brokerageTradingFeatureEntitlementRequest)
        : undefined;
    message.orderStatusRequest =
      object.orderStatusRequest !== undefined && object.orderStatusRequest !== null
        ? OrderStatusRequest.fromPartial(object.orderStatusRequest)
        : undefined;
    message.productSearchRequest =
      object.productSearchRequest !== undefined && object.productSearchRequest !== null
        ? ProductSearchRequest.fromPartial(object.productSearchRequest)
        : undefined;
    message.marketStateMetadataRequest =
      object.marketStateMetadataRequest !== undefined && object.marketStateMetadataRequest !== null
        ? MarketStateMetadataRequest.fromPartial(object.marketStateMetadataRequest)
        : undefined;
    message.instrumentDefinitionRequest =
      object.instrumentDefinitionRequest !== undefined && object.instrumentDefinitionRequest !== null
        ? InstrumentDefinitionRequest.fromPartial(object.instrumentDefinitionRequest)
        : undefined;
    message.exchangeMetadataRequest =
      object.exchangeMetadataRequest !== undefined && object.exchangeMetadataRequest !== null
        ? ExchangeMetadataRequest.fromPartial(object.exchangeMetadataRequest)
        : undefined;
    message.instrumentGroupBySecuritiesRequest =
      object.instrumentGroupBySecuritiesRequest !== undefined && object.instrumentGroupBySecuritiesRequest !== null
        ? InstrumentGroupBySecuritiesRequest.fromPartial(object.instrumentGroupBySecuritiesRequest)
        : undefined;
    message.instrumentGroupByExchangeRequest =
      object.instrumentGroupByExchangeRequest !== undefined && object.instrumentGroupByExchangeRequest !== null
        ? InstrumentGroupByExchangeRequest.fromPartial(object.instrumentGroupByExchangeRequest)
        : undefined;
    message.exchangeSecuritiesRequest =
      object.exchangeSecuritiesRequest !== undefined && object.exchangeSecuritiesRequest !== null
        ? ExchangeSecuritiesRequest.fromPartial(object.exchangeSecuritiesRequest)
        : undefined;
    message.careAutoActivationParametersRequest =
      object.careAutoActivationParametersRequest !== undefined && object.careAutoActivationParametersRequest !== null
        ? CareAutoActivationParametersRequest.fromPartial(object.careAutoActivationParametersRequest)
        : undefined;
    return message;
  },
};

function createBaseInformationReport(): InformationReport {
  return {
    id: 0,
    isReportComplete: false,
    statusCode: 0,
    textMessage: '',
    accountsReport: undefined,
    symbolResolutionReport: undefined,
    lastStatementBalancesReport: undefined,
    currencyRatesReport: undefined,
    currencyRateSourcesReport: undefined,
    sessionInformationReport: undefined,
    historicalOrdersReport: undefined,
    optionMaturityListReport: undefined,
    instrumentGroupReport: undefined,
    atTheMoneyStrikeReport: undefined,
    strategyDefinitionReport: undefined,
    sessionTimerangeReport: undefined,
    tradingDayTimerangeReport: undefined,
    orderEntitlementReport: undefined,
    symbolCategoryListReport: undefined,
    symbolCategoryReport: undefined,
    symbolCategoryListByInstrumentTypeReport: undefined,
    symbolListReport: undefined,
    symbolReport: undefined,
    contractMetadataReport: undefined,
    algoStrategyDefinitionReport: undefined,
    contributorMetadataReport: undefined,
    apiLimitReport: undefined,
    brokerageTradingFeatureEntitlementReport: undefined,
    orderStatusReport: undefined,
    productSearchReport: undefined,
    marketStateMetadataReport: undefined,
    instrumentDefinitionReport: undefined,
    exchangeMetadataReport: undefined,
    instrumentGroupBySecuritiesReport: undefined,
    instrumentGroupByExchangeReport: undefined,
    exchangeSecuritiesReport: undefined,
    careAutoActivationParametersReport: undefined,
  };
}

export const InformationReport = {
  encode(message: InformationReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.isReportComplete === true) {
      writer.uint32(16).bool(message.isReportComplete);
    }
    if (message.statusCode !== 0) {
      writer.uint32(24).uint32(message.statusCode);
    }
    if (message.textMessage !== '') {
      writer.uint32(34).string(message.textMessage);
    }
    if (message.accountsReport !== undefined) {
      AccountsReport.encode(message.accountsReport, writer.uint32(42).fork()).ldelim();
    }
    if (message.symbolResolutionReport !== undefined) {
      SymbolResolutionReport.encode(message.symbolResolutionReport, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastStatementBalancesReport !== undefined) {
      LastStatementBalancesReport.encode(message.lastStatementBalancesReport, writer.uint32(58).fork()).ldelim();
    }
    if (message.currencyRatesReport !== undefined) {
      CurrencyRatesReport.encode(message.currencyRatesReport, writer.uint32(66).fork()).ldelim();
    }
    if (message.currencyRateSourcesReport !== undefined) {
      CurrencyRateSourcesReport.encode(message.currencyRateSourcesReport, writer.uint32(346).fork()).ldelim();
    }
    if (message.sessionInformationReport !== undefined) {
      SessionInformationReport.encode(message.sessionInformationReport, writer.uint32(74).fork()).ldelim();
    }
    if (message.historicalOrdersReport !== undefined) {
      HistoricalOrdersReport.encode(message.historicalOrdersReport, writer.uint32(122).fork()).ldelim();
    }
    if (message.optionMaturityListReport !== undefined) {
      OptionMaturityListReport.encode(message.optionMaturityListReport, writer.uint32(130).fork()).ldelim();
    }
    if (message.instrumentGroupReport !== undefined) {
      InstrumentGroupReport.encode(message.instrumentGroupReport, writer.uint32(138).fork()).ldelim();
    }
    if (message.atTheMoneyStrikeReport !== undefined) {
      AtTheMoneyStrikeReport.encode(message.atTheMoneyStrikeReport, writer.uint32(146).fork()).ldelim();
    }
    if (message.strategyDefinitionReport !== undefined) {
      StrategyDefinitionReport.encode(message.strategyDefinitionReport, writer.uint32(154).fork()).ldelim();
    }
    if (message.sessionTimerangeReport !== undefined) {
      SessionTimeRangeReport.encode(message.sessionTimerangeReport, writer.uint32(162).fork()).ldelim();
    }
    if (message.tradingDayTimerangeReport !== undefined) {
      TradingDayTimeRangeReport.encode(message.tradingDayTimerangeReport, writer.uint32(170).fork()).ldelim();
    }
    if (message.orderEntitlementReport !== undefined) {
      OrderEntitlementReport.encode(message.orderEntitlementReport, writer.uint32(178).fork()).ldelim();
    }
    if (message.symbolCategoryListReport !== undefined) {
      SymbolCategoryListReport.encode(message.symbolCategoryListReport, writer.uint32(186).fork()).ldelim();
    }
    if (message.symbolCategoryReport !== undefined) {
      SymbolCategoryReport.encode(message.symbolCategoryReport, writer.uint32(234).fork()).ldelim();
    }
    if (message.symbolCategoryListByInstrumentTypeReport !== undefined) {
      SymbolCategoryListByInstrumentTypeReport.encode(
        message.symbolCategoryListByInstrumentTypeReport,
        writer.uint32(282).fork()
      ).ldelim();
    }
    if (message.symbolListReport !== undefined) {
      SymbolListReport.encode(message.symbolListReport, writer.uint32(194).fork()).ldelim();
    }
    if (message.symbolReport !== undefined) {
      SymbolReport.encode(message.symbolReport, writer.uint32(202).fork()).ldelim();
    }
    if (message.contractMetadataReport !== undefined) {
      ContractMetadataReport.encode(message.contractMetadataReport, writer.uint32(218).fork()).ldelim();
    }
    if (message.algoStrategyDefinitionReport !== undefined) {
      AlgoStrategyDefinitionReport.encode(message.algoStrategyDefinitionReport, writer.uint32(226).fork()).ldelim();
    }
    if (message.contributorMetadataReport !== undefined) {
      ContributorMetadataReport.encode(message.contributorMetadataReport, writer.uint32(242).fork()).ldelim();
    }
    if (message.apiLimitReport !== undefined) {
      ApiLimitReport.encode(message.apiLimitReport, writer.uint32(250).fork()).ldelim();
    }
    if (message.brokerageTradingFeatureEntitlementReport !== undefined) {
      BrokerageTradingFeatureEntitlementReport.encode(
        message.brokerageTradingFeatureEntitlementReport,
        writer.uint32(258).fork()
      ).ldelim();
    }
    if (message.orderStatusReport !== undefined) {
      OrderStatusReport.encode(message.orderStatusReport, writer.uint32(266).fork()).ldelim();
    }
    if (message.productSearchReport !== undefined) {
      ProductSearchReport.encode(message.productSearchReport, writer.uint32(274).fork()).ldelim();
    }
    if (message.marketStateMetadataReport !== undefined) {
      MarketStateMetadataReport.encode(message.marketStateMetadataReport, writer.uint32(290).fork()).ldelim();
    }
    if (message.instrumentDefinitionReport !== undefined) {
      InstrumentDefinitionReport.encode(message.instrumentDefinitionReport, writer.uint32(298).fork()).ldelim();
    }
    if (message.exchangeMetadataReport !== undefined) {
      ExchangeMetadataReport.encode(message.exchangeMetadataReport, writer.uint32(306).fork()).ldelim();
    }
    if (message.instrumentGroupBySecuritiesReport !== undefined) {
      InstrumentGroupBySecuritiesReport.encode(message.instrumentGroupBySecuritiesReport, writer.uint32(314).fork()).ldelim();
    }
    if (message.instrumentGroupByExchangeReport !== undefined) {
      InstrumentGroupByExchangeReport.encode(message.instrumentGroupByExchangeReport, writer.uint32(322).fork()).ldelim();
    }
    if (message.exchangeSecuritiesReport !== undefined) {
      ExchangeSecuritiesReport.encode(message.exchangeSecuritiesReport, writer.uint32(330).fork()).ldelim();
    }
    if (message.careAutoActivationParametersReport !== undefined) {
      CareAutoActivationParametersReport.encode(message.careAutoActivationParametersReport, writer.uint32(338).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InformationReport {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInformationReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.uint32();
          break;
        case 2:
          message.isReportComplete = reader.bool();
          break;
        case 3:
          message.statusCode = reader.uint32();
          break;
        case 4:
          message.textMessage = reader.string();
          break;
        case 5:
          message.accountsReport = AccountsReport.decode(reader, reader.uint32());
          break;
        case 6:
          message.symbolResolutionReport = SymbolResolutionReport.decode(reader, reader.uint32());
          break;
        case 7:
          message.lastStatementBalancesReport = LastStatementBalancesReport.decode(reader, reader.uint32());
          break;
        case 8:
          message.currencyRatesReport = CurrencyRatesReport.decode(reader, reader.uint32());
          break;
        case 43:
          message.currencyRateSourcesReport = CurrencyRateSourcesReport.decode(reader, reader.uint32());
          break;
        case 9:
          message.sessionInformationReport = SessionInformationReport.decode(reader, reader.uint32());
          break;
        case 15:
          message.historicalOrdersReport = HistoricalOrdersReport.decode(reader, reader.uint32());
          break;
        case 16:
          message.optionMaturityListReport = OptionMaturityListReport.decode(reader, reader.uint32());
          break;
        case 17:
          message.instrumentGroupReport = InstrumentGroupReport.decode(reader, reader.uint32());
          break;
        case 18:
          message.atTheMoneyStrikeReport = AtTheMoneyStrikeReport.decode(reader, reader.uint32());
          break;
        case 19:
          message.strategyDefinitionReport = StrategyDefinitionReport.decode(reader, reader.uint32());
          break;
        case 20:
          message.sessionTimerangeReport = SessionTimeRangeReport.decode(reader, reader.uint32());
          break;
        case 21:
          message.tradingDayTimerangeReport = TradingDayTimeRangeReport.decode(reader, reader.uint32());
          break;
        case 22:
          message.orderEntitlementReport = OrderEntitlementReport.decode(reader, reader.uint32());
          break;
        case 23:
          message.symbolCategoryListReport = SymbolCategoryListReport.decode(reader, reader.uint32());
          break;
        case 29:
          message.symbolCategoryReport = SymbolCategoryReport.decode(reader, reader.uint32());
          break;
        case 35:
          message.symbolCategoryListByInstrumentTypeReport = SymbolCategoryListByInstrumentTypeReport.decode(
            reader,
            reader.uint32()
          );
          break;
        case 24:
          message.symbolListReport = SymbolListReport.decode(reader, reader.uint32());
          break;
        case 25:
          message.symbolReport = SymbolReport.decode(reader, reader.uint32());
          break;
        case 27:
          message.contractMetadataReport = ContractMetadataReport.decode(reader, reader.uint32());
          break;
        case 28:
          message.algoStrategyDefinitionReport = AlgoStrategyDefinitionReport.decode(reader, reader.uint32());
          break;
        case 30:
          message.contributorMetadataReport = ContributorMetadataReport.decode(reader, reader.uint32());
          break;
        case 31:
          message.apiLimitReport = ApiLimitReport.decode(reader, reader.uint32());
          break;
        case 32:
          message.brokerageTradingFeatureEntitlementReport = BrokerageTradingFeatureEntitlementReport.decode(
            reader,
            reader.uint32()
          );
          break;
        case 33:
          message.orderStatusReport = OrderStatusReport.decode(reader, reader.uint32());
          break;
        case 34:
          message.productSearchReport = ProductSearchReport.decode(reader, reader.uint32());
          break;
        case 36:
          message.marketStateMetadataReport = MarketStateMetadataReport.decode(reader, reader.uint32());
          break;
        case 37:
          message.instrumentDefinitionReport = InstrumentDefinitionReport.decode(reader, reader.uint32());
          break;
        case 38:
          message.exchangeMetadataReport = ExchangeMetadataReport.decode(reader, reader.uint32());
          break;
        case 39:
          message.instrumentGroupBySecuritiesReport = InstrumentGroupBySecuritiesReport.decode(reader, reader.uint32());
          break;
        case 40:
          message.instrumentGroupByExchangeReport = InstrumentGroupByExchangeReport.decode(reader, reader.uint32());
          break;
        case 41:
          message.exchangeSecuritiesReport = ExchangeSecuritiesReport.decode(reader, reader.uint32());
          break;
        case 42:
          message.careAutoActivationParametersReport = CareAutoActivationParametersReport.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InformationReport {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      isReportComplete: isSet(object.isReportComplete) ? Boolean(object.isReportComplete) : false,
      statusCode: isSet(object.statusCode) ? Number(object.statusCode) : 0,
      textMessage: isSet(object.textMessage) ? String(object.textMessage) : '',
      accountsReport: isSet(object.accountsReport) ? AccountsReport.fromJSON(object.accountsReport) : undefined,
      symbolResolutionReport: isSet(object.symbolResolutionReport)
        ? SymbolResolutionReport.fromJSON(object.symbolResolutionReport)
        : undefined,
      lastStatementBalancesReport: isSet(object.lastStatementBalancesReport)
        ? LastStatementBalancesReport.fromJSON(object.lastStatementBalancesReport)
        : undefined,
      currencyRatesReport: isSet(object.currencyRatesReport)
        ? CurrencyRatesReport.fromJSON(object.currencyRatesReport)
        : undefined,
      currencyRateSourcesReport: isSet(object.currencyRateSourcesReport)
        ? CurrencyRateSourcesReport.fromJSON(object.currencyRateSourcesReport)
        : undefined,
      sessionInformationReport: isSet(object.sessionInformationReport)
        ? SessionInformationReport.fromJSON(object.sessionInformationReport)
        : undefined,
      historicalOrdersReport: isSet(object.historicalOrdersReport)
        ? HistoricalOrdersReport.fromJSON(object.historicalOrdersReport)
        : undefined,
      optionMaturityListReport: isSet(object.optionMaturityListReport)
        ? OptionMaturityListReport.fromJSON(object.optionMaturityListReport)
        : undefined,
      instrumentGroupReport: isSet(object.instrumentGroupReport)
        ? InstrumentGroupReport.fromJSON(object.instrumentGroupReport)
        : undefined,
      atTheMoneyStrikeReport: isSet(object.atTheMoneyStrikeReport)
        ? AtTheMoneyStrikeReport.fromJSON(object.atTheMoneyStrikeReport)
        : undefined,
      strategyDefinitionReport: isSet(object.strategyDefinitionReport)
        ? StrategyDefinitionReport.fromJSON(object.strategyDefinitionReport)
        : undefined,
      sessionTimerangeReport: isSet(object.sessionTimerangeReport)
        ? SessionTimeRangeReport.fromJSON(object.sessionTimerangeReport)
        : undefined,
      tradingDayTimerangeReport: isSet(object.tradingDayTimerangeReport)
        ? TradingDayTimeRangeReport.fromJSON(object.tradingDayTimerangeReport)
        : undefined,
      orderEntitlementReport: isSet(object.orderEntitlementReport)
        ? OrderEntitlementReport.fromJSON(object.orderEntitlementReport)
        : undefined,
      symbolCategoryListReport: isSet(object.symbolCategoryListReport)
        ? SymbolCategoryListReport.fromJSON(object.symbolCategoryListReport)
        : undefined,
      symbolCategoryReport: isSet(object.symbolCategoryReport)
        ? SymbolCategoryReport.fromJSON(object.symbolCategoryReport)
        : undefined,
      symbolCategoryListByInstrumentTypeReport: isSet(object.symbolCategoryListByInstrumentTypeReport)
        ? SymbolCategoryListByInstrumentTypeReport.fromJSON(object.symbolCategoryListByInstrumentTypeReport)
        : undefined,
      symbolListReport: isSet(object.symbolListReport) ? SymbolListReport.fromJSON(object.symbolListReport) : undefined,
      symbolReport: isSet(object.symbolReport) ? SymbolReport.fromJSON(object.symbolReport) : undefined,
      contractMetadataReport: isSet(object.contractMetadataReport)
        ? ContractMetadataReport.fromJSON(object.contractMetadataReport)
        : undefined,
      algoStrategyDefinitionReport: isSet(object.algoStrategyDefinitionReport)
        ? AlgoStrategyDefinitionReport.fromJSON(object.algoStrategyDefinitionReport)
        : undefined,
      contributorMetadataReport: isSet(object.contributorMetadataReport)
        ? ContributorMetadataReport.fromJSON(object.contributorMetadataReport)
        : undefined,
      apiLimitReport: isSet(object.apiLimitReport) ? ApiLimitReport.fromJSON(object.apiLimitReport) : undefined,
      brokerageTradingFeatureEntitlementReport: isSet(object.brokerageTradingFeatureEntitlementReport)
        ? BrokerageTradingFeatureEntitlementReport.fromJSON(object.brokerageTradingFeatureEntitlementReport)
        : undefined,
      orderStatusReport: isSet(object.orderStatusReport) ? OrderStatusReport.fromJSON(object.orderStatusReport) : undefined,
      productSearchReport: isSet(object.productSearchReport)
        ? ProductSearchReport.fromJSON(object.productSearchReport)
        : undefined,
      marketStateMetadataReport: isSet(object.marketStateMetadataReport)
        ? MarketStateMetadataReport.fromJSON(object.marketStateMetadataReport)
        : undefined,
      instrumentDefinitionReport: isSet(object.instrumentDefinitionReport)
        ? InstrumentDefinitionReport.fromJSON(object.instrumentDefinitionReport)
        : undefined,
      exchangeMetadataReport: isSet(object.exchangeMetadataReport)
        ? ExchangeMetadataReport.fromJSON(object.exchangeMetadataReport)
        : undefined,
      instrumentGroupBySecuritiesReport: isSet(object.instrumentGroupBySecuritiesReport)
        ? InstrumentGroupBySecuritiesReport.fromJSON(object.instrumentGroupBySecuritiesReport)
        : undefined,
      instrumentGroupByExchangeReport: isSet(object.instrumentGroupByExchangeReport)
        ? InstrumentGroupByExchangeReport.fromJSON(object.instrumentGroupByExchangeReport)
        : undefined,
      exchangeSecuritiesReport: isSet(object.exchangeSecuritiesReport)
        ? ExchangeSecuritiesReport.fromJSON(object.exchangeSecuritiesReport)
        : undefined,
      careAutoActivationParametersReport: isSet(object.careAutoActivationParametersReport)
        ? CareAutoActivationParametersReport.fromJSON(object.careAutoActivationParametersReport)
        : undefined,
    };
  },

  toJSON(message: InformationReport): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.isReportComplete !== undefined && (obj.isReportComplete = message.isReportComplete);
    message.statusCode !== undefined && (obj.statusCode = Math.round(message.statusCode));
    message.textMessage !== undefined && (obj.textMessage = message.textMessage);
    message.accountsReport !== undefined &&
      (obj.accountsReport = message.accountsReport ? AccountsReport.toJSON(message.accountsReport) : undefined);
    message.symbolResolutionReport !== undefined &&
      (obj.symbolResolutionReport = message.symbolResolutionReport
        ? SymbolResolutionReport.toJSON(message.symbolResolutionReport)
        : undefined);
    message.lastStatementBalancesReport !== undefined &&
      (obj.lastStatementBalancesReport = message.lastStatementBalancesReport
        ? LastStatementBalancesReport.toJSON(message.lastStatementBalancesReport)
        : undefined);
    message.currencyRatesReport !== undefined &&
      (obj.currencyRatesReport = message.currencyRatesReport
        ? CurrencyRatesReport.toJSON(message.currencyRatesReport)
        : undefined);
    message.currencyRateSourcesReport !== undefined &&
      (obj.currencyRateSourcesReport = message.currencyRateSourcesReport
        ? CurrencyRateSourcesReport.toJSON(message.currencyRateSourcesReport)
        : undefined);
    message.sessionInformationReport !== undefined &&
      (obj.sessionInformationReport = message.sessionInformationReport
        ? SessionInformationReport.toJSON(message.sessionInformationReport)
        : undefined);
    message.historicalOrdersReport !== undefined &&
      (obj.historicalOrdersReport = message.historicalOrdersReport
        ? HistoricalOrdersReport.toJSON(message.historicalOrdersReport)
        : undefined);
    message.optionMaturityListReport !== undefined &&
      (obj.optionMaturityListReport = message.optionMaturityListReport
        ? OptionMaturityListReport.toJSON(message.optionMaturityListReport)
        : undefined);
    message.instrumentGroupReport !== undefined &&
      (obj.instrumentGroupReport = message.instrumentGroupReport
        ? InstrumentGroupReport.toJSON(message.instrumentGroupReport)
        : undefined);
    message.atTheMoneyStrikeReport !== undefined &&
      (obj.atTheMoneyStrikeReport = message.atTheMoneyStrikeReport
        ? AtTheMoneyStrikeReport.toJSON(message.atTheMoneyStrikeReport)
        : undefined);
    message.strategyDefinitionReport !== undefined &&
      (obj.strategyDefinitionReport = message.strategyDefinitionReport
        ? StrategyDefinitionReport.toJSON(message.strategyDefinitionReport)
        : undefined);
    message.sessionTimerangeReport !== undefined &&
      (obj.sessionTimerangeReport = message.sessionTimerangeReport
        ? SessionTimeRangeReport.toJSON(message.sessionTimerangeReport)
        : undefined);
    message.tradingDayTimerangeReport !== undefined &&
      (obj.tradingDayTimerangeReport = message.tradingDayTimerangeReport
        ? TradingDayTimeRangeReport.toJSON(message.tradingDayTimerangeReport)
        : undefined);
    message.orderEntitlementReport !== undefined &&
      (obj.orderEntitlementReport = message.orderEntitlementReport
        ? OrderEntitlementReport.toJSON(message.orderEntitlementReport)
        : undefined);
    message.symbolCategoryListReport !== undefined &&
      (obj.symbolCategoryListReport = message.symbolCategoryListReport
        ? SymbolCategoryListReport.toJSON(message.symbolCategoryListReport)
        : undefined);
    message.symbolCategoryReport !== undefined &&
      (obj.symbolCategoryReport = message.symbolCategoryReport
        ? SymbolCategoryReport.toJSON(message.symbolCategoryReport)
        : undefined);
    message.symbolCategoryListByInstrumentTypeReport !== undefined &&
      (obj.symbolCategoryListByInstrumentTypeReport = message.symbolCategoryListByInstrumentTypeReport
        ? SymbolCategoryListByInstrumentTypeReport.toJSON(message.symbolCategoryListByInstrumentTypeReport)
        : undefined);
    message.symbolListReport !== undefined &&
      (obj.symbolListReport = message.symbolListReport ? SymbolListReport.toJSON(message.symbolListReport) : undefined);
    message.symbolReport !== undefined &&
      (obj.symbolReport = message.symbolReport ? SymbolReport.toJSON(message.symbolReport) : undefined);
    message.contractMetadataReport !== undefined &&
      (obj.contractMetadataReport = message.contractMetadataReport
        ? ContractMetadataReport.toJSON(message.contractMetadataReport)
        : undefined);
    message.algoStrategyDefinitionReport !== undefined &&
      (obj.algoStrategyDefinitionReport = message.algoStrategyDefinitionReport
        ? AlgoStrategyDefinitionReport.toJSON(message.algoStrategyDefinitionReport)
        : undefined);
    message.contributorMetadataReport !== undefined &&
      (obj.contributorMetadataReport = message.contributorMetadataReport
        ? ContributorMetadataReport.toJSON(message.contributorMetadataReport)
        : undefined);
    message.apiLimitReport !== undefined &&
      (obj.apiLimitReport = message.apiLimitReport ? ApiLimitReport.toJSON(message.apiLimitReport) : undefined);
    message.brokerageTradingFeatureEntitlementReport !== undefined &&
      (obj.brokerageTradingFeatureEntitlementReport = message.brokerageTradingFeatureEntitlementReport
        ? BrokerageTradingFeatureEntitlementReport.toJSON(message.brokerageTradingFeatureEntitlementReport)
        : undefined);
    message.orderStatusReport !== undefined &&
      (obj.orderStatusReport = message.orderStatusReport ? OrderStatusReport.toJSON(message.orderStatusReport) : undefined);
    message.productSearchReport !== undefined &&
      (obj.productSearchReport = message.productSearchReport
        ? ProductSearchReport.toJSON(message.productSearchReport)
        : undefined);
    message.marketStateMetadataReport !== undefined &&
      (obj.marketStateMetadataReport = message.marketStateMetadataReport
        ? MarketStateMetadataReport.toJSON(message.marketStateMetadataReport)
        : undefined);
    message.instrumentDefinitionReport !== undefined &&
      (obj.instrumentDefinitionReport = message.instrumentDefinitionReport
        ? InstrumentDefinitionReport.toJSON(message.instrumentDefinitionReport)
        : undefined);
    message.exchangeMetadataReport !== undefined &&
      (obj.exchangeMetadataReport = message.exchangeMetadataReport
        ? ExchangeMetadataReport.toJSON(message.exchangeMetadataReport)
        : undefined);
    message.instrumentGroupBySecuritiesReport !== undefined &&
      (obj.instrumentGroupBySecuritiesReport = message.instrumentGroupBySecuritiesReport
        ? InstrumentGroupBySecuritiesReport.toJSON(message.instrumentGroupBySecuritiesReport)
        : undefined);
    message.instrumentGroupByExchangeReport !== undefined &&
      (obj.instrumentGroupByExchangeReport = message.instrumentGroupByExchangeReport
        ? InstrumentGroupByExchangeReport.toJSON(message.instrumentGroupByExchangeReport)
        : undefined);
    message.exchangeSecuritiesReport !== undefined &&
      (obj.exchangeSecuritiesReport = message.exchangeSecuritiesReport
        ? ExchangeSecuritiesReport.toJSON(message.exchangeSecuritiesReport)
        : undefined);
    message.careAutoActivationParametersReport !== undefined &&
      (obj.careAutoActivationParametersReport = message.careAutoActivationParametersReport
        ? CareAutoActivationParametersReport.toJSON(message.careAutoActivationParametersReport)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InformationReport>, I>>(base?: I): InformationReport {
    return InformationReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InformationReport>, I>>(object: I): InformationReport {
    const message = createBaseInformationReport();
    message.id = object.id ?? 0;
    message.isReportComplete = object.isReportComplete ?? false;
    message.statusCode = object.statusCode ?? 0;
    message.textMessage = object.textMessage ?? '';
    message.accountsReport =
      object.accountsReport !== undefined && object.accountsReport !== null
        ? AccountsReport.fromPartial(object.accountsReport)
        : undefined;
    message.symbolResolutionReport =
      object.symbolResolutionReport !== undefined && object.symbolResolutionReport !== null
        ? SymbolResolutionReport.fromPartial(object.symbolResolutionReport)
        : undefined;
    message.lastStatementBalancesReport =
      object.lastStatementBalancesReport !== undefined && object.lastStatementBalancesReport !== null
        ? LastStatementBalancesReport.fromPartial(object.lastStatementBalancesReport)
        : undefined;
    message.currencyRatesReport =
      object.currencyRatesReport !== undefined && object.currencyRatesReport !== null
        ? CurrencyRatesReport.fromPartial(object.currencyRatesReport)
        : undefined;
    message.currencyRateSourcesReport =
      object.currencyRateSourcesReport !== undefined && object.currencyRateSourcesReport !== null
        ? CurrencyRateSourcesReport.fromPartial(object.currencyRateSourcesReport)
        : undefined;
    message.sessionInformationReport =
      object.sessionInformationReport !== undefined && object.sessionInformationReport !== null
        ? SessionInformationReport.fromPartial(object.sessionInformationReport)
        : undefined;
    message.historicalOrdersReport =
      object.historicalOrdersReport !== undefined && object.historicalOrdersReport !== null
        ? HistoricalOrdersReport.fromPartial(object.historicalOrdersReport)
        : undefined;
    message.optionMaturityListReport =
      object.optionMaturityListReport !== undefined && object.optionMaturityListReport !== null
        ? OptionMaturityListReport.fromPartial(object.optionMaturityListReport)
        : undefined;
    message.instrumentGroupReport =
      object.instrumentGroupReport !== undefined && object.instrumentGroupReport !== null
        ? InstrumentGroupReport.fromPartial(object.instrumentGroupReport)
        : undefined;
    message.atTheMoneyStrikeReport =
      object.atTheMoneyStrikeReport !== undefined && object.atTheMoneyStrikeReport !== null
        ? AtTheMoneyStrikeReport.fromPartial(object.atTheMoneyStrikeReport)
        : undefined;
    message.strategyDefinitionReport =
      object.strategyDefinitionReport !== undefined && object.strategyDefinitionReport !== null
        ? StrategyDefinitionReport.fromPartial(object.strategyDefinitionReport)
        : undefined;
    message.sessionTimerangeReport =
      object.sessionTimerangeReport !== undefined && object.sessionTimerangeReport !== null
        ? SessionTimeRangeReport.fromPartial(object.sessionTimerangeReport)
        : undefined;
    message.tradingDayTimerangeReport =
      object.tradingDayTimerangeReport !== undefined && object.tradingDayTimerangeReport !== null
        ? TradingDayTimeRangeReport.fromPartial(object.tradingDayTimerangeReport)
        : undefined;
    message.orderEntitlementReport =
      object.orderEntitlementReport !== undefined && object.orderEntitlementReport !== null
        ? OrderEntitlementReport.fromPartial(object.orderEntitlementReport)
        : undefined;
    message.symbolCategoryListReport =
      object.symbolCategoryListReport !== undefined && object.symbolCategoryListReport !== null
        ? SymbolCategoryListReport.fromPartial(object.symbolCategoryListReport)
        : undefined;
    message.symbolCategoryReport =
      object.symbolCategoryReport !== undefined && object.symbolCategoryReport !== null
        ? SymbolCategoryReport.fromPartial(object.symbolCategoryReport)
        : undefined;
    message.symbolCategoryListByInstrumentTypeReport =
      object.symbolCategoryListByInstrumentTypeReport !== undefined && object.symbolCategoryListByInstrumentTypeReport !== null
        ? SymbolCategoryListByInstrumentTypeReport.fromPartial(object.symbolCategoryListByInstrumentTypeReport)
        : undefined;
    message.symbolListReport =
      object.symbolListReport !== undefined && object.symbolListReport !== null
        ? SymbolListReport.fromPartial(object.symbolListReport)
        : undefined;
    message.symbolReport =
      object.symbolReport !== undefined && object.symbolReport !== null
        ? SymbolReport.fromPartial(object.symbolReport)
        : undefined;
    message.contractMetadataReport =
      object.contractMetadataReport !== undefined && object.contractMetadataReport !== null
        ? ContractMetadataReport.fromPartial(object.contractMetadataReport)
        : undefined;
    message.algoStrategyDefinitionReport =
      object.algoStrategyDefinitionReport !== undefined && object.algoStrategyDefinitionReport !== null
        ? AlgoStrategyDefinitionReport.fromPartial(object.algoStrategyDefinitionReport)
        : undefined;
    message.contributorMetadataReport =
      object.contributorMetadataReport !== undefined && object.contributorMetadataReport !== null
        ? ContributorMetadataReport.fromPartial(object.contributorMetadataReport)
        : undefined;
    message.apiLimitReport =
      object.apiLimitReport !== undefined && object.apiLimitReport !== null
        ? ApiLimitReport.fromPartial(object.apiLimitReport)
        : undefined;
    message.brokerageTradingFeatureEntitlementReport =
      object.brokerageTradingFeatureEntitlementReport !== undefined && object.brokerageTradingFeatureEntitlementReport !== null
        ? BrokerageTradingFeatureEntitlementReport.fromPartial(object.brokerageTradingFeatureEntitlementReport)
        : undefined;
    message.orderStatusReport =
      object.orderStatusReport !== undefined && object.orderStatusReport !== null
        ? OrderStatusReport.fromPartial(object.orderStatusReport)
        : undefined;
    message.productSearchReport =
      object.productSearchReport !== undefined && object.productSearchReport !== null
        ? ProductSearchReport.fromPartial(object.productSearchReport)
        : undefined;
    message.marketStateMetadataReport =
      object.marketStateMetadataReport !== undefined && object.marketStateMetadataReport !== null
        ? MarketStateMetadataReport.fromPartial(object.marketStateMetadataReport)
        : undefined;
    message.instrumentDefinitionReport =
      object.instrumentDefinitionReport !== undefined && object.instrumentDefinitionReport !== null
        ? InstrumentDefinitionReport.fromPartial(object.instrumentDefinitionReport)
        : undefined;
    message.exchangeMetadataReport =
      object.exchangeMetadataReport !== undefined && object.exchangeMetadataReport !== null
        ? ExchangeMetadataReport.fromPartial(object.exchangeMetadataReport)
        : undefined;
    message.instrumentGroupBySecuritiesReport =
      object.instrumentGroupBySecuritiesReport !== undefined && object.instrumentGroupBySecuritiesReport !== null
        ? InstrumentGroupBySecuritiesReport.fromPartial(object.instrumentGroupBySecuritiesReport)
        : undefined;
    message.instrumentGroupByExchangeReport =
      object.instrumentGroupByExchangeReport !== undefined && object.instrumentGroupByExchangeReport !== null
        ? InstrumentGroupByExchangeReport.fromPartial(object.instrumentGroupByExchangeReport)
        : undefined;
    message.exchangeSecuritiesReport =
      object.exchangeSecuritiesReport !== undefined && object.exchangeSecuritiesReport !== null
        ? ExchangeSecuritiesReport.fromPartial(object.exchangeSecuritiesReport)
        : undefined;
    message.careAutoActivationParametersReport =
      object.careAutoActivationParametersReport !== undefined && object.careAutoActivationParametersReport !== null
        ? CareAutoActivationParametersReport.fromPartial(object.careAutoActivationParametersReport)
        : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
