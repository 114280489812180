import React from 'react';
import { sum } from 'lodash';
import { formatNumber } from '../../../utils/formatNumber';
import useMobileView, { BREAKPOINTS } from '../../../customHooks/useMobileView';
import { IBudget } from '../../../types/IBudget';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import Decimal from 'decimal.js';
import useCurrentProductionCycle from '../../../customHooks/useCurrentProductionCycle';
import useCropBudgets from '../../../customHooks/useBudget';
import { IOperationCropV2 } from '../../../types/IOperationCrops';
import { getUomDisplayAbbreviation, UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';

type BudgetTotalRowProps = {
  operationCrop?: IOperationCropV2;
  budget?: IBudget;
  isOverhead?: boolean;
};

const zero = new Decimal(0);

function BudgetTotalRow(props: BudgetTotalRowProps) {
  const { operationCrop, budget, isOverhead = false } = props;
  const uom = getUomDisplayAbbreviation(operationCrop?.uom ?? UnitOfMeasure.Bushel);
  const productionCycle = useCurrentProductionCycle();
  const isMobileView = useMobileView(BREAKPOINTS.MOBILE_VIEW);
  const currency = useCurrentCurrency();
  const cropBudgets = useCropBudgets(productionCycle, budget);

  if (!operationCrop) {
    return <></>;
  }

  const acres = operationCrop?.acres ?? zero;
  const totalUom = operationCrop?.relevantYieldUOMPerAcre ?? zero;

  const totalOverheadCosts = budget?.cropBudgets[0] ? sum(budget?.cropBudgets[0].map((budgetItem) => budgetItem.value || 0)) : 0;

  const cropBudget = cropBudgets[operationCrop.id] || 0;
  const cropBudgetPerAcreTotal = new Decimal(cropBudget).times(acres).toNumber();
  const cropBudgetPerUom = totalUom.greaterThan(zero) ? new Decimal(cropBudget).dividedBy(totalUom).toNumber() : 0;

  return (
    <div className={`budget-total-row${isMobileView ? '-mobile' : ''}`}>
      <span className="font-bold">
        {isOverhead && 'Operation'} {operationCrop.name} Costs:
        {!isOverhead && (
          <span className="font-normal">
            {operationCrop ? `(${formatNumber(acres)} ac / ${formatNumber(totalUom)} ${uom})` : `(0 ac / 0 ${uom})`}
          </span>
        )}
      </span>
      <span>
        Total:
        <span className="red-price font-bold">
          {formatNumber(isOverhead ? totalOverheadCosts : cropBudgetPerAcreTotal, 2, currency)}
        </span>
      </span>
      {!isOverhead && (
        <>
          <span>
            Per Acre:
            <span className="red-price font-bold">{formatNumber(cropBudget, 2, currency)}</span>
          </span>
          <span>
            {`Per ${uom}:`}
            <span className="red-price font-bold">
              {formatNumber(isOverhead || !totalUom ? '' : cropBudgetPerUom, 2, currency)}
            </span>
          </span>
        </>
      )}
    </div>
  );
}

export default BudgetTotalRow;
