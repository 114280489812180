import styles from './DialogContent.module.scss';

import * as React from 'react';
import { forwardRef } from 'react';

interface DialogContentProps extends Pick<React.HTMLProps<HTMLDivElement>, 'className' | 'children'> {}

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (props: DialogContentProps, ref?: React.Ref<HTMLDivElement>) => {
    const { className, ...otherProps } = props;
    const newClassName = className ? `${className} ${styles['fr-dialog-content']}` : styles['fr-dialog-content'];
    return (
      <div ref={ref} className={newClassName} {...otherProps}>
        <div className="container">{props.children}</div>
      </div>
    );
  }
);

export default DialogContent;
