import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

export const fetchSpecialOptions = createAsyncThunk('specialOptions/fetchSpecialOptions', async (cropSymbols: string[]) => {
  // Performance optimization - barchart doesn't do well on large, mixed exchange specialOptions requests...

  const promises = cropSymbols.map(async (cropSymbol): Promise<object[]> => {
    const response = await axios.get(`${config.backend.basePath}/market_data/get_special_options`, {
      params: {
        root: cropSymbol,
        type: 'Call',
      },
    });
    return response.data;
  });

  const dataResults = await Promise.all(promises);
  const specialOptionsResults = dataResults.flatMap((x) => x);
  return specialOptionsResults;
});
