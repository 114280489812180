import React from 'react';
import { formatNumber } from '../../../utils/formatNumber';
import useCurrentCurrency from '../../../customHooks/useCurrentCurrency';
import { IBudget } from '../../../types/IBudget';
import { getCropBudgets, getTotalCropCosts } from '../../../models/cropBudget';
import { useCurrentOperationCrops } from '../../../queries/operationCrops';

type SummaryProps = {
  editableBudget: IBudget;
};

function Summary(props: SummaryProps) {
  const { editableBudget } = props;
  const operationCropsQuery = useCurrentOperationCrops();
  const operationCrops = operationCropsQuery.data || [];
  const currency = useCurrentCurrency();

  const cropBudgets = getCropBudgets(editableBudget, operationCrops);

  return (
    <div className="card-wrapper">
      <div className="card">
        <div className="total-summary-row">
          <span className="font-bold">Total Costs:</span>
          <span className="color-red font-bold">{formatNumber(getTotalCropCosts(operationCrops, cropBudgets), 2, currency)}</span>
        </div>
      </div>
    </div>
  );
}

export default Summary;
