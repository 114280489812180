import * as types from './constants';
import { UserActionTypes } from './types';
import { User } from '../../types/User';
import { IRole } from '../../types/IRole';

export function addUser(user: User): UserActionTypes {
  return {
    type: types.ADD_USER,
    payload: user,
  };
}

export function updateUser(field: string, data: any): UserActionTypes {
  return {
    type: types.UPDATE_USER,
    payload: { [field]: data },
  };
}

export function updateUsersRole(role: IRole): UserActionTypes {
  return {
    type: types.UPDATE_USERS_ROLE,
    payload: role,
  };
}

export function editUserAction(user: User): UserActionTypes {
  return {
    type: types.EDIT_USER,
    payload: user,
  };
}
