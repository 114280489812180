import { FontAwesomeIcon, IconDefinition, faEye } from '../../faIcons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useAppContext from '../../../customHooks/useAppContext';
import { useCurrentOperation } from '../../../customHooks/useCurrentOperation';
import { getImpersonatingConfig } from '../../../shared/storageService';
import Button from '../../common/Button/Button';
import './UpHeader.css';

const UpHeader = () => {
  const impersonatingConfig = getImpersonatingConfig();
  const currentOperation = useCurrentOperation();
  const appContext = useAppContext();
  const history = useHistory();

  function stopImpersonating() {
    setTimeout(() => {
      history.push('/admin');
    }, 100);

    appContext.stopImpersonatingUser();
  }

  if (!impersonatingConfig?.isImpersonating) {
    return null;
  }

  return (
    <div className="app-protected-header up-header">
      <div>
        <span>
          <FontAwesomeIcon icon={faEye as IconDefinition} />
        </span>
        <span>Admin access to "{currentOperation.name}"</span>
      </div>
      <Button className="fr-button backGround-red" value="Back" onClick={() => stopImpersonating()} />
    </div>
  );
};

export default UpHeader;
