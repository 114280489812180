import React from 'react';
import './Loader.css';
import config from '../../../config';

export default function Loader(props: LoaderProps) {
  const { height = 'auto' } = props;
  const loaderHeight = typeof height === 'number' ? `${height}px` : height;

  return (
    <div className="loader" style={{ height: loaderHeight }} data-testid="loader">
      <img src={config.organization.logoAnimated} alt="Loading" />
    </div>
  );
}

Loader;

type LoaderProps = {
  height?: string | number;
};
