import { type CQGClient } from '@farmersrisk/cqg/cqgClient/CQGClient';
import React, { createContext, PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { Alert } from 'react-bootstrap';
import config from '../../config';
import { useCurrentOperation } from '../../customHooks/useCurrentOperation';
import useCurrentUser from '../../customHooks/useCurrentUser';
import { useTradingCredentials } from '../../queries/tradingCredentials';
import { errorReducer, ErrorState } from '../../store/CQG/errorReducer';
import { OrderState, orderStatusReducer } from '../../store/CQG/orderStatusReducer';
import { CQGService, getOrInitCQGService } from './service';
import useCanTrade from './useCanTrade';

export type CQGContext = {
  canTrade: boolean;
  state: OrderState;
  errorState: ErrorState;

  // REFACTOR: move to CQGService
  client: () => CQGClient | undefined;
  // resetClient?: () => CQGClient;
  service: CQGService | undefined;
  isReady: boolean;
};

export const CQGContext = createContext<CQGContext | undefined>(undefined);

export enum TradeExecutionIntent {
  INITIALIZE = 'INITIALIZE',
  RESOLVESYMBOL = 'RESOLVESYMBOL',
  PLACEORDER = 'PLACEORDER',
}

//let devTimer = 0;
const isDev = config.env === 'development';

export function CQGProvider(props: PropsWithChildren<unknown>) {
  const user = useCurrentUser();
  const operation = useCurrentOperation();
  const canTradeQry = useCanTrade();
  // this won't fire unless we know the user can trade && has a valid trading account config
  const tradingCredentialsQry = useTradingCredentials({ operationId: operation.id, enabled: canTradeQry.canTrade || false });

  // STATE
  const [state, dispatch] = useReducer(orderStatusReducer, { orders: {}, rejects: {}, contracts: {} });
  const [errorState, errorDispatch] = useReducer(errorReducer, { errors: [] });
  const [forcedLogoff, setForcedLogoff] = useState({ forced: false, message: '' });

  // DERIVED STATE
  const canTrade = canTradeQry.canTrade ?? false;
  const tradingTacs = canTradeQry.tradingAccountConfigs ?? [];
  const tradingCredentials = tradingCredentialsQry.data;

  // TODO: Support switching between trading accounts
  const tradingTac = tradingTacs[0] ?? undefined;
  const serviceQry = getOrInitCQGService({
    user,
    operation,
    username: tradingCredentials?.username ?? '',
    password: tradingCredentials?.password ?? '',
    tradingAccountNumbers: tradingTac?.accountNumbers ?? [],
    dispatch,
    errorDispatch,
    setForcedLogoff,
  });

  let service = serviceQry.data;

  // when error occurs: nothing
  // 11/7/22: CQGClient will emit errors but with retry logic we only want the `packages/frontend/src/components/CQG/helpers.ts`
  // to handle the `ErrorToast` responsibility so that multiple errors don't show up at once useEffect(() => {}, [errorState]);

  // constructor/destructor: handlers & state
  useEffect(() => {
    return () => {
      if (service) {
        service.dispose().catch((e) => {
          console.error('Error disposing CQGService', e);
        });
      }
    };
  }, []);

  let context: CQGContext | undefined = undefined;
  if (!user || !operation || !canTrade || !tradingTac || !tradingCredentials) {
    return <>{props.children}</>;
  }

  if (!service) {
    return <CQGContext.Provider value={undefined}>{props.children}</CQGContext.Provider>;
  }

  const isReady = user && operation && canTrade && tradingTac && tradingCredentials && service && service.client && true;

  context = {
    canTrade,
    client: () => service?.client,
    service,
    state,
    errorState,
    isReady,
  };

  return (
    <CQGContext.Provider value={context}>
      {isDev && forcedLogoff.forced && (
        <Alert variant="danger" className="text-center" style={{ zIndex: 20000 }}>
          <b>CQG logoff:</b> {forcedLogoff.message}
        </Alert>
      )}
      {props.children}
    </CQGContext.Provider>
  );
}
