import React, { useEffect, useState } from 'react';
import './Wizard.scss';
import Steps from '../common/Steps/Steps';
import WizardCreateOperation from './WizardCreateOperation';
import WizardFillCrops from './WizardFillCrops';
import WizardInsurance from './WizardInsurance';
import WizardEstimatedCost from './WizardEstimatedCost';
import WizardSold from './WizardSold';
import { useHistory } from 'react-router-dom';
import { updateUser } from '../../store/User/actions';
import { useDispatch } from 'react-redux';
import ErrorToast from '../Toast/ErrorToast';
import { getCurrentUserThunk } from '../../store/User/thunks';
import { IOperationCropV2 } from '../../types/IOperationCrops';
import { patchIsWizardCompleted, patchPrice, postNewOperation } from '../../requests/wizard';
import useMobileView, { BREAKPOINTS } from '../../customHooks/useMobileView';
import { CommodityInformation, CommodityId, getCommodities, InsuranceType } from '@harvestiq/constants';
import { getInsurancePrice, InsurancePrice } from '@farmersrisk/shared/constants/InsurancePrice';
import dayjs from 'dayjs';
import { createOldBudget, fetchBudgets } from '../../store/Budgets/thunks';
import { BudgetType, IBudget, IBudgetsItem } from '../../types/IBudget';
import { OperationEdit } from '../../types/Operation';
import { IAdvisorSignup } from '../../types/IAdvisorSignup';
import { AdvisorVerification } from './AdvisorVerification';
import { useAdvisorVerification } from '../../queries/advisorVerification';
import { isNil } from 'lodash';
import Decimal from 'decimal.js';
import { useCreateOperationCrop } from '../../queries/operationCrops';
import { convertFieldsToDecimal } from '../../utils/decimal';
import { getInitialCropYear } from '../../shared/productionCycles';
import useCurrentUser from '../../customHooks/useCurrentUser';
import { UnitOfMeasure } from '@farmersrisk/shared/constants/UOM';
import useAppContext from '../../customHooks/useAppContext';
import { setStoredCurrentOperationId } from '../../shared/storageService';
import config from '../../config';

export interface IOperationCropWizard extends IOperationCropV2 {
  selected: boolean;
  productionCost?: string;
}

const predefinedSize: { [key: number]: { acres: string; aph: string } } = {
  1: { acres: '500', aph: '200' },
  2: { acres: '500', aph: '55' },
  3: { acres: '500', aph: '90' },
  4: { acres: '500', aph: '65' },
  5: { acres: '500', aph: '65' },
};

const predefinedEstimatedCost: { [key: number]: string } = {
  1: '799',
  2: '582',
  3: '400',
  4: '376',
  5: '376',
};

const initialCropYear = getInitialCropYear([]);

function getSellingFromInsurance(cropId: number, year: number) {
  const springInsurance = getInsurancePrice(year, InsurancePrice.BASE, cropId)?.toString();
  const autumnInsurance = getInsurancePrice(year, InsurancePrice.HARVEST, cropId)?.toString();

  return autumnInsurance || springInsurance || null;
}

function getSellingPrice(cropId: number) {
  for (let year = dayjs().year(); year >= 2020; year--) {
    const price = getSellingFromInsurance(cropId, year);
    if (price) {
      return price.toString();
    }
  }
  return null;
}

const getInitialOperationCrops = (crops: CommodityInformation[]): IOperationCropWizard[] => {
  return crops.map((crop) => {
    const aph = predefinedSize[crop.id]?.aph ? new Decimal(predefinedSize[crop.id]?.aph) : null; // ''
    const acres = predefinedSize[crop.id]?.acres ? new Decimal(predefinedSize[crop.id]?.acres) : null; // '500'
    const sellingPrice = getSellingPrice(crop.id) || '';
    return {
      id: 0,
      operationId: 0,
      marketingCropType: crop.id,
      hedgingCropType: crop.id === CommodityId.SORGHUM ? CommodityId.CORN : crop.id,
      name: crop.name,
      selected: false,
      productionCycle: initialCropYear,
      insuranceType: InsuranceType.REVENUE,
      insurancePercent: new Decimal(80),
      acres: acres,
      aphUOMPerAcre: aph,
      actualYieldUOMPerAcre: null,
      projectedYieldUOMPerAcre: null,
      relevantYieldUOMPerAcre: aph || new Decimal(0),
      uom: (crop.uom as UnitOfMeasure) || UnitOfMeasure.Bushel,
      storageUOM: null,
      productionCost: predefinedEstimatedCost[crop.id] || undefined,
      hidden: false,
      price: {
        sellingPrice: sellingPrice || undefined,
        bushelsContracted: aph && acres ? aph.times(acres).times(0.25).toString() : '',
        operationCropId: 0,
        id: 0,
      },
    };
  });
};

export default function Wizard(props: Partial<IAdvisorSignup>) {
  // this component is rendered outside of react-router so you can't use useParams hook
  const isVerifiedQuery = useAdvisorVerification(props.advisorId, props.verificationCode);
  const history = useHistory();
  const user = useCurrentUser();
  const crops = getCommodities();
  const createOperationCrop = useCreateOperationCrop();
  const [activeStep, setActiveStep] = useState(1);
  const [operation, setOperation] = useState<OperationEdit & { advisorVerificationCode?: string }>({
    id: 0,
    userId: user.id || 0,
    name: `${user.givenName}'s Operation`,
    currencyId: 1,
    isDeleted: false,
    isPremium: false,
    tradingEnabled: false,
  });
  const [operationCrops, setOperationCrops] = useState<IOperationCropWizard[]>(getInitialOperationCrops(crops));
  const [budget, setBudget] = useState<IBudget>(getBudget(operationCrops, crops, operation, BudgetType.Simple));
  const dispatch = useDispatch();
  const appContext = useAppContext();

  const isMobile = useMobileView(BREAKPOINTS.MOBILE_VIEW);

  // set operation advisor information
  useEffect(() => {
    if (!isNil(isVerifiedQuery) && !isVerifiedQuery?.isLoading && !isVerifiedQuery?.isError) {
      console.log('setting advisor in op local state');
      setOperation({ ...operation, advisorId: Number(props.advisorId), advisorVerificationCode: props.verificationCode });
    }
  }, [isVerifiedQuery?.isLoading]);

  useEffect(() => {
    if (!operationCrops.length && crops.length) {
      setOperationCrops(getInitialOperationCrops(crops));
    }
  }, [operationCrops, crops, setOperationCrops]);

  const openNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const openPrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onChangeOperationCropHandler = (data: { [key: string]: any }, cropId: number, isPrices = false) => {
    if (isValidData(isPrices ? data.price : data)) {
      const transformedData = convertFieldsToDecimal(data);
      const newOperationCrops = operationCrops.map((crop: IOperationCropWizard) =>
        crop.marketingCropType === cropId ? { ...crop, ...transformedData } : crop
      );
      setOperationCrops(newOperationCrops);

      if (budget.budgetType === BudgetType.Simple) {
        const initialBudget = getBudget(newOperationCrops, crops, operation, budget.budgetType || BudgetType.Simple);
        setBudget((previousBudget) => ({
          ...previousBudget,
          ...initialBudget,
          cropBudgets: {
            ...initialBudget.cropBudgets,
            ...previousBudget.cropBudgets,
          },
        }));
      }
    }
  };

  const saveCrop = (crop: IOperationCropWizard): Promise<IOperationCropV2> => {
    return new Promise((resolve, reject) => {
      crop.insurancePercent = crop.aphUOMPerAcre ? crop.insurancePercent : null;
      crop.insuranceType = crop.aphUOMPerAcre ? crop.insuranceType : null;
      if (isNil(crop.insurancePercent) || isNil(crop.insuranceType)) {
        crop.insurancePercent = null;
        crop.insuranceType = null;
      }
      const { price, ...cropWithoutPrice } = crop;

      createOperationCrop
        .mutateAsync(cropWithoutPrice)
        .then((res) => {
          if (price?.bushelsContracted || price?.sellingPrice) {
            const price = {
              ...crop.price,
              id: res.price!.id,
              operationCropId: res.id,
            };

            patchPrice(price)
              .then((priceResponse) => resolve({ ...res, price: priceResponse.data }))
              .catch((err) => reject(err));
          } else {
            resolve({ ...res });
          }
        })
        .catch((err) => reject(err));
    });
  };

  const handleSave = () => {
    postNewOperation({ ...operation, name: operation.name || '', userId: user.id! })
      .then((res) => {
        const operation = res.data;
        setStoredCurrentOperationId(operation.id);
        Promise.all(
          operationCrops.filter((crop: IOperationCropWizard) => crop.selected).map((crop: IOperationCropWizard) => saveCrop(crop))
        )
          .then((cropsAndPrices) => {
            return patchIsWizardCompleted(true).then((response) => {
              return appContext
                .changeCurrentOperation(operation.id)
                .then(() => {
                  dispatch(createOldBudget({ ...budget, operationId: operation.id }));
                  dispatch(getCurrentUserThunk());
                  dispatch(updateUser('isWizardCompleted', response.data.isWizardCompleted));
                  dispatch(fetchBudgets(operation.id));
                  return appContext.changeCurrentOperation(operation.id);
                })
                .then(() => history.push('/dashboard'));
            });
          })
          .catch((err) => {
            console.error(err);
            ErrorToast();
          });
      })
      .catch((err) => {
        console.error(err);
        ErrorToast();
      });
  };

  return (
    <div id="wizard-container" className="flex-column wizard-container">
      <div className={`wizard-content-wrapper${isMobile ? '-mobile' : ''}`}>
        <img src={config.organization.logoWide} className="wizard-fr-logo" alt={config.organization.name} />
        <AdvisorVerification query={isVerifiedQuery} />
        <div className="page-stepper">
          <Steps current={activeStep} items={5} />
        </div>
        {activeStep === 1 && (
          <WizardCreateOperation
            openNextStep={openNextStep}
            operationName={operation.name ?? ''}
            setOperationName={(name) => setOperation({ ...operation, name })}
          />
        )}
        {activeStep === 2 && (
          <WizardFillCrops
            openNextStep={openNextStep}
            handleBack={openPrevStep}
            onSkip={handleSave}
            operationCrops={operationCrops}
            onChangeHandler={onChangeOperationCropHandler}
          />
        )}
        {activeStep === 3 && (
          <WizardInsurance
            openNextStep={openNextStep}
            handleBack={openPrevStep}
            onSkip={handleSave}
            operationCrops={operationCrops}
            onChangeHandler={onChangeOperationCropHandler}
          />
        )}
        {activeStep === 4 && (
          <WizardEstimatedCost
            openNextStep={openNextStep}
            handleBack={openPrevStep}
            onSkip={handleSave}
            operation={operation}
            operationCrops={operationCrops}
            budget={budget}
            setBudget={setBudget}
          />
        )}
        {activeStep === 5 && (
          <WizardSold
            handleBack={openPrevStep}
            handleSave={handleSave}
            onSkip={handleSave}
            operationCrops={operationCrops}
            onChangeHandler={onChangeOperationCropHandler}
          />
        )}
      </div>
    </div>
  );
}

export function getBudget(
  operationCrops: IOperationCropWizard[],
  crops: CommodityInformation[],
  currentOperation: OperationEdit,
  budgetType: BudgetType
): IBudget {
  const activeCrops = crops.filter(
    (crop) => operationCrops.find((operationCrop) => operationCrop.marketingCropType === crop.id)?.selected
  );
  const initialBudget = getInitialBudget(activeCrops, currentOperation, initialCropYear, budgetType);

  if (budgetType === BudgetType.Simple) {
    activeCrops.forEach((activeCrop) => {
      initialBudget.cropBudgets[activeCrop.id][0].value =
        Number(operationCrops.find((operationCrop) => operationCrop.marketingCropType === activeCrop.id)?.productionCost) || null;
    });
  }

  return initialBudget;
}

const isValidData = (data: { [key: string]: any }): boolean => {
  const positiveFields = [
    'acres',
    'aphUOMPerAcre',
    'actualYieldUOMPerAcre',
    'projectedYieldUOMPerAcre',
    'productionCost',
    'sellingPrice',
    'bushelsContracted',
    'insurancePercent',
  ];
  const ignoreFields = ['id', 'operationCropId', 'selected', 'insuranceType'];
  const entries = Object.entries(data);

  for (let [key, value] of entries) {
    if (ignoreFields.includes(key)) {
      continue;
    }
    if (!positiveFields.includes(key)) {
      return false;
    }
    if (value === '') {
      continue;
    }
    if (Number(value < 0)) {
      return false;
    }
  }

  return true;
};

function getInitialBudget(
  crops: CommodityInformation[],
  currentOperation: OperationEdit,
  productionCycle: string,
  budgetType: BudgetType | null
): IBudget {
  const cropBudgets = {} as { [key: number]: IBudgetsItem[] };
  const result = {
    id: 0,
    operationId: currentOperation.id,
    name: currentOperation.name + ' - ' + productionCycle,
    year: productionCycle,
    isActive: true,
    createdAt: '',
  };

  switch (budgetType) {
    case BudgetType.Simple:
      crops.forEach((crop) => {
        cropBudgets[crop.id] = [{ value: 0, valueType: 2, categoryName: 'Production Cost' }];
      });
      return {
        ...result,
        budgetType: 1,
        cropBudgets,
      };
    case BudgetType.University:
      return {
        ...result,
        budgetType: 2,
        cropBudgets,
      };
    case BudgetType.Custom:
      return {
        ...result,
        budgetType: BudgetType.Custom,
        isNew: true,
        cropBudgets: {},
      };
    default:
      return {} as IBudget;
  }
}
