import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStrategy } from '../../types/IStrategy';
import { LoadingStatuses } from '../LoadingStatuses';
import { RootState } from '../../types/Store';
import { cloneDeep } from 'lodash';

export type StrategyInitialStateType = {
  strategies: IStrategy[];
  scenarioAnalysisStrategies: IStrategy[];
  status: string;
  error: string | null;
};

const initialState: StrategyInitialStateType = {
  strategies: [],
  scenarioAnalysisStrategies: [],
  status: LoadingStatuses.Idle,
  error: null,
};

export interface IStrategyUpdate {
  id: number;
  isEnabled: boolean;
}

const slice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    changeLoadingStatus: (state, action: PayloadAction<string>) => ({ ...state, status: action.payload }),
    setStrategies: (state, action: PayloadAction<IStrategy[]>) => ({
      ...state,
      strategies: action.payload.filter(({ isScenario }) => !isScenario),
      scenarioAnalysisStrategies: action.payload.filter(({ isScenario }) => isScenario),
    }),
    addNewStrategy: (state, action: PayloadAction<IStrategy>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies']: [
        ...state[action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies'],
        {
          ...action.payload,
          isEnabled: action.payload.isScenario,
        },
      ],
    }),
    editStrategy: (state, action: PayloadAction<IStrategy>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies']: updateStrategy(
        state[action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies'],
        action.payload
      ),
    }),
    enableScenarioAnalysisStrategies: (state, action: PayloadAction<IStrategyUpdate[]>) => ({
      ...state,
      scenarioAnalysisStrategies: enableManyScenarioAnalysisStrategies(state.scenarioAnalysisStrategies, action.payload),
    }),
    deleteStrategy: (state, action: PayloadAction<IStrategy>) => ({
      ...state,
      [action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies']: filterDeletedStrategy(
        state[action.payload.isScenario ? 'scenarioAnalysisStrategies' : 'strategies'],
        action.payload
      ),
    }),
  },
});

function updateStrategy(state: IStrategy[], updatedStrategy: IStrategy) {
  const newState = cloneDeep(state);
  const previousStrategy: IStrategy = newState.find((strategy) => strategy.id === updatedStrategy.id) as IStrategy;

  if (previousStrategy) {
    newState[newState.indexOf(previousStrategy)] = { ...previousStrategy, ...updatedStrategy };
  }
  return newState;
}

function filterDeletedStrategy(state: IStrategy[], deletedStrategy: IStrategy) {
  return state.filter((strategy) => strategy.id !== deletedStrategy.id);
}

function enableManyScenarioAnalysisStrategies(state: IStrategy[], updates: IStrategyUpdate[]) {
  const newState = cloneDeep(state);

  updates.forEach((update) => {
    const previousStrategy: IStrategy = newState.find((strategy) => strategy.id === update.id) as IStrategy;

    if (previousStrategy) {
      newState[newState.indexOf(previousStrategy)] = { ...previousStrategy, ...update };
    }
  });

  return newState;
}

export const {
  changeLoadingStatus,
  addNewStrategy,
  setStrategies,
  editStrategy,
  deleteStrategy,
  enableScenarioAnalysisStrategies,
} = slice.actions;

export default slice.reducer;

export const selectStrategies = (state: RootState) => state.strategies.strategies;
export const selectScenarioAnalysisStrategies = (state: RootState) => state.strategies.scenarioAnalysisStrategies;
